import { fromEvent as observableFromEvent, merge as observableMerge, Observable, Subscription } from 'rxjs';

import { debounceTime, distinctUntilChanged, filter, map, merge, pluck, take, tap } from 'rxjs/operators';
import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/state/';
import { SearchStateInterface } from '../../ngrx/reducers/global-search.reducer';
import { Location } from '@angular/common';
import { GlobalSearchRememberBackUrlAction, GlobalSearchResetAction } from '../../ngrx/actions/global-search.actions';
import { RouterNavigateService } from '../../shared/services/router-navigate.service';
import { KeyCode } from '../../constants/';
import { AppUrls } from '../../app-urls';
import { Task } from '../../interfaces';
import { getLatestOpenedTasks } from '../../ngrx/reducers/opened-task-history.reducer';
import { AuthService } from '../../shared/services/app/auth.service';
import { PaywallService } from '../../libs/paywall/paywall.service';
import { Features } from '../../libs/paywall/features.constants';

@Component({
  selector: 'search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('input_search_box') inputElementRef;
  @ViewChild('recent_tasks_box') recentTasksElementRef;

  @Output() showBackButton = new EventEmitter();

  public _wideView = false;
  public query: any;
  public defaultValue = '';
  public onSearchPage: boolean = null;
  public subscriptions: Subscription[] = [];
  public filterParams: {};
  public sortParams: {};
  public recentTasks$;
  public isLinksAndTasksVisible = false;
  public isRecentTasksVisible = false;
  public currentUserId: number;
  public tasksBrowserFilterParams = { isEmptyQAvailable: '1', hideArchived: true, hideReleased: true };
  public taskBrowserSortParams = {
    sortOrder: 'desc',
    sortParam: 'createdAt'
  };
  public myTasksFilterParams = { ...this.tasksBrowserFilterParams };

  public searchPlaceholder = 'Start typing to search';

  public searchState$: Observable<SearchStateInterface>;

  public appUrls = AppUrls;

  constructor(
    private _store: Store<AppState>,
    private _routerNav: RouterNavigateService,
    private _location: Location,
    private _paywall: PaywallService,
    private _authService: AuthService
  ) {}

  get wideView() {
    return this._wideView;
  }
  set wideView(newValue: boolean) {
    if (this._wideView !== newValue) {
      this._wideView = newValue;
      this.showBackButton.emit(newValue);
    }
  }

  ngOnInit(): any {
    this.currentUserId = this._authService.activeUserId;
    this.myTasksFilterParams['users'] = [this.currentUserId];
    this.searchState$ = <Observable<SearchStateInterface>>this._store.pipe(pluck('search'), distinctUntilChanged());
    this.recentTasks$ = this._store.select(getLatestOpenedTasks).pipe(
      map((tasks: Task[]) =>
        tasks.slice(0, 7).map(task => ({
          text: task.taskKey ? task.taskKey + ': ' + task.title : task.title,
          link: AppUrls.getUrlTask(task.id)
        }))
      )
    );
    this.subscriptions.push(
      this._store
        .pipe(
          pluck('search'),
          distinctUntilChanged(),
          tap((state: SearchStateInterface) => {
            this.defaultValue = state.searchQuery;
            this.onSearchPage = state.onSearchPage;
            this.filterParams = state.filterParams;
            this.sortParams = state.sortParams;
          }),
          pluck('onSearchPage'),
          distinctUntilChanged()
        )
        .subscribe(_ => this.onBlur())
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  ngAfterViewInit() {
    this.subscriptions.push(
      this._store
        .pipe(
          pluck('search', 'searchQuery'),
          distinctUntilChanged(),
          merge(
            observableFromEvent(this.inputElementRef.nativeElement, 'input').pipe(
              pluck('target', 'value'),
              distinctUntilChanged()
            )
          ),
          debounceTime(100),
          filter((query: string) => query !== this.inputElementRef.nativeElement.value || !query || !query.length)
        )
        .subscribe(query => (this.inputElementRef.nativeElement.value = query))
    );

    this.subscriptions.push(
      observableFromEvent(this.inputElementRef.nativeElement, 'keyup').subscribe((event: KeyboardEvent) => {
        if (event.code === 'Escape') {
          this.inputElementRef.nativeElement.blur();
        }
      })
    );
    this.subscriptions.push(
      observableMerge(
        // when user is typing, we will start search only if he will stop typing
        observableFromEvent(this.inputElementRef.nativeElement, 'keyup').pipe(
          filter((event: KeyboardEvent) => {
            const str = event.code && event.code.substr(0, 3);
            return str === 'Key' || str === 'Dig'; // Key or Digit code
          }),
          debounceTime(400),
          map((event: KeyboardEvent) => this.inputElementRef.nativeElement.value)
        ),
        // if user press enter - start search immediately
        observableFromEvent(this.inputElementRef.nativeElement, 'keypress').pipe(
          // if Enter has pressed
          filter((event: KeyboardEvent) => KeyCode.KEY_ENTER === event.keyCode),
          map((event: KeyboardEvent) => this.inputElementRef.nativeElement.value)
        )
      )
        .pipe(
          distinctUntilChanged(
            // anyway, we have to start search if user wasn't on search page & he had pressed enter
            (x, y) => (this.onSearchPage ? x === y : false)
          )
        )
        .subscribe(() => {
          this.goSearch({ hideArchived: true, hideReleased: true });
        })
    );
  }

  hideRecentTasks() {
    this.isLinksAndTasksVisible = false;
  }

  onFocus() {
    if (this.inputElementRef.nativeElement.value === '') {
      this.recentTasks$.pipe(take(1), map((tasks: Task[]) => tasks.length > 0)).subscribe((isNotEmpty: boolean) => {
        this.isLinksAndTasksVisible = true;
        this.isRecentTasksVisible = isNotEmpty;
      });
    }
  }

  onBlur() {
    this.wideView = this.onSearchPage;
    if (this.recentTasksElementRef && !this.recentTasksElementRef.nativeElement.matches(':hover')) {
      this.hideRecentTasks();
    }
  }

  goSearch(filterParams = {}, sortParams?, goAnyway?, resetPrevParams?) {
    this.hideRecentTasks();
    if (!this.onSearchPage) {
      this._store.dispatch(new GlobalSearchRememberBackUrlAction(this._location.path()));
      this.wideView = true;
    } else if (goAnyway) {
      this.wideView = true;
    }
    if (resetPrevParams) {
      this._store.dispatch(new GlobalSearchResetAction());
    }
    this._routerNav.navigate(this.getSearchUrl(filterParams, sortParams, resetPrevParams));
  }

  onClickMyTasks() {
    if (this._paywall.isFeatureEnabled(Features.AdvancedSearch)) {
      this.goSearch(this.myTasksFilterParams, this.tasksBrowserFilterParams, true);
    } else {
      this._paywall.showPayWall(Features.AdvancedSearch);
    }
  }

  onClickMyTasksToDo() {
    if (this._paywall.isFeatureEnabled(Features.AdvancedSearch)) {
      this.goSearch(
        {
          ...this.myTasksFilterParams,
          status: ['todo']
        },
        {
          sortOrder: 'desc',
          sortParam: 'updatedAt'
        },
        true,
        true
      );
    } else {
      this._paywall.showPayWall(Features.AdvancedSearch);
    }
  }

  onClickMyTasksInProgress() {
    if (this._paywall.isFeatureEnabled(Features.AdvancedSearch)) {
      this.goSearch(
        {
          ...this.myTasksFilterParams,
          status: ['inprogress']
        },
        {
          sortOrder: 'desc',
          sortParam: 'updatedAt'
        },
        true,
        true
      );
    } else {
      this._paywall.showPayWall(Features.AdvancedSearch);
    }
  }

  onClickMyTasksDueDate() {
    if (this._paywall.isFeatureEnabled(Features.AdvancedSearch)) {
      this.goSearch(
        {
          ...this.myTasksFilterParams,
          status: ['todo', 'inprogress'],
          dueDate: 'any'
        },
        {
          sortOrder: 'asc',
          sortParam: 'dueDate'
        },
        true,
        true
      );
    } else {
      this._paywall.showPayWall(Features.AdvancedSearch);
    }
  }

  onClickMyTasksCompleted() {
    if (this._paywall.isFeatureEnabled(Features.AdvancedSearch)) {
      this.goSearch(
        {
          ...this.myTasksFilterParams,
          status: ['done']
        },
        {
          sortOrder: 'desc',
          sortParam: 'updatedAt'
        },
        true,
        true
      );
    } else {
      this._paywall.showPayWall(Features.AdvancedSearch);
    }
  }

  onClickCreatedByMe() {
    if (this._paywall.isFeatureEnabled(Features.AdvancedSearch)) {
      this.goSearch(
        {
          ...this.tasksBrowserFilterParams,
          creator: this.currentUserId
        },
        {
          sortOrder: 'desc',
          sortParam: 'updatedAt'
        },
        true,
        true
      );
    } else {
      this._paywall.showPayWall(Features.AdvancedSearch);
    }
  }

  onClose() {
    this.inputElementRef.nativeElement.value = '';
    this.inputElementRef.nativeElement.focus();
  }

  getSearchUrl(filterParams = {}, sortParams?, resetPrevParams?) {
    return this.appUrls.getUrlGlobalSearch(
      this._routerNav.prepareGlobalSearchParam(
        this.inputElementRef.nativeElement.value,
        this.onSearchPage && !resetPrevParams ? { ...this.filterParams, ...filterParams } : filterParams,
        sortParams ? sortParams : this.sortParams
      )
    );
  }
}
