<a-center-container-scrollable>
  <div a-popup class="width_900" [title]="'Prioritization Type'" [isHideCloseBtn]="false" (close)="onClose()">
    <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
      <form-errors></form-errors>
      <div class="radio_group">
        <div class="scoring_type">
          <label class="radio" [ngClass]="{selected: isSelected('eisen')}">
            <input type="radio" name="scoringType" formControlName="scoringType" [value]="'eisen'">
            <span class="header">Eisenhower Matrix</span>
            <span class="img eisenhower_matrix"></span>
            <span class="description">Decide on and prioritize tasks by urgency and importance, sorting out less urgent and important tasks which you should either delegate or not do at all.</span>
          </label>
          <div class="link_wrapper">
            <a href="https://university.hygger.io/prioritization-techniques/eisenhower-matrix-for-prioritization" target="_blank" rel="noopener">Learn More</a>
          </div>
        </div>
        <div class="scoring_type">
          <label class="radio" [ngClass]="{selected: isSelected('risk')}">
            <input type="radio" name="scoringType" formControlName="scoringType" [value]="'risk'">
            <span class="header">Value vs Risk Matrix</span>
            <span class="img value_risk"></span>
            <span class="description">Categorize potential new features by their expected business value and by the degree of risk associated with their implementation.</span>
          </label>
          <div class="link_wrapper">
            <a href="https://university.hygger.io/prioritization-techniques/value-vs-risk-model" target="_blank" rel="noopener">Learn More</a>
          </div>
        </div>
        <div class="scoring_type">
          <label class="radio" [ngClass]="{selected: isSelected(BoardScoringType.basic)}">
            <input type="radio" name="scoringType" formControlName="scoringType" [value]="BoardScoringType.basic">
            <span class="header">Value vs Effort Matrix</span>
            <span class="img value_effort"></span>
            <span class="description">Set the Value and Efforts factors for your tasks and then see them on Priority Matrix</span>
          </label>
          <div class="link_wrapper">
            <a href="https://university.hygger.io/prioritization-techniques/value-vs-effort" target="_blank" rel="noopener">Learn More</a>
          </div>
        </div>
        <div class="scoring_type">
          <label class="radio" [ngClass]="{selected: isSelected(BoardScoringType.advanced), locked: isLocked(BoardScoringType.advanced)}">
            <input type="radio" [attr.disabled]="isLocked(BoardScoringType.advanced)" name="scoringType" formControlName="scoringType" [value]="BoardScoringType.advanced">
            <span class="header">Weighted Scoring</span>
            <span class="img custom"></span>
            <span class="description">Manage the criteria for Value and Efforts factors. Final Score is calculated by the formula: (Sum of all Value criteria) minus (Sum of all Effort criteria)</span>
          </label>
          <div class="link_wrapper">
            <a href="https://hygger.io/blog/prioritize-product-features-criteria-weighted-scoring/" target="_blank" rel="noopener">Learn More</a>
          </div>
        </div>
        <div class="scoring_type">
          <label class="radio" [ngClass]="{selected: isSelected(BoardScoringType.ICE), locked: isLocked(BoardScoringType.ICE)}">
            <input type="radio" [attr.disabled]="isLocked(BoardScoringType.ICE)" name="scoringType" formControlName="scoringType" [value]="BoardScoringType.ICE">
            <span class="header">ICE</span>
            <span class="img ice"></span>
            <span class="description">Set the Impact, Confidence and Ease factors for your tasks. Final Score is calculated by the formula: Impact * Confidence * Ease</span>
          </label>
          <div class="link_wrapper">
            <a href="https://university.hygger.io/prioritization-techniques/ice-scoring" target="_blank" rel="noopener">Learn More</a>
          </div>
        </div>
        <div class="scoring_type">
          <label class="radio" [ngClass]="{selected: isSelected(BoardScoringType.RICE), locked: isLocked(BoardScoringType.RICE)}">
            <input type="radio" [attr.disabled]="isLocked(BoardScoringType.RICE)" name="scoringType" formControlName="scoringType" [value]="BoardScoringType.RICE">
            <span class="header">RICE</span>
            <span class="img rice"></span>
            <span class="description">Set the Reach, Impact, Confidence and Effort factors for your tasks. Final Score is calculated by the formula: Reach * Impact * Confidence / Effort</span>
          </label>
          <div class="link_wrapper">
            <a href="https://university.hygger.io/prioritization-techniques/rice-score-model" target="_blank" rel="noopener">Learn More</a>
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="buttons_block">
        <button a-button class="btn_primary button" [pending]="_formService.isPending$ | async" (click)="onSubmit()">
          Save & Continue
        </button>
      </div>
    </form>
  </div>
</a-center-container-scrollable>
<overlay (click)="onClose()"></overlay>
