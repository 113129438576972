import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Checklist, ChecklistItem } from '../../../interfaces';
import { isClickLink } from '../../../../helpers/links';

@Component({
  selector: 'checklist-item',
  templateUrl: './checklist-item.component.html',
  styleUrls: ['./checklist-item.component.scss']
})
export class ChecklistItemComponent implements OnInit {
  @Input() checkbox: ChecklistItem;
  @Input() checklist: Checklist;
  @Input() editPermissions = false;

  @Output() editCheckbox: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteCheckbox: EventEmitter<ChecklistItem> = new EventEmitter<ChecklistItem>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  public isEditMode = false;

  ngOnInit() {}

  onChangeCheckboxState() {
    if (!this.editPermissions) {
      return false;
    }

    const checkboxData = {
      id: this.checkbox.id,
      completed: +!this.checkbox.completed,
      checklist: this.checkbox.checklist,
      task: this.checkbox.task,
      company: this.checkbox.company,
      name: this.checkbox.name
    };

    this.editCheckbox.emit(checkboxData);
  }

  onEdit(event) {
    if (this.editPermissions && !isClickLink(event)) {
      this.isEditMode = true;
    }
  }

  onDeleteCheckbox() {
    this.deleteCheckbox.emit(this.checkbox);
  }

  onChangeCheckbox(formData: ChecklistItem) {
    this.isEditMode = false;

    const checkboxData = {
      id: this.checkbox.id,
      completed: +this.checkbox.completed,
      checklist: this.checkbox.checklist,
      task: this.checkbox.task,
      company: this.checkbox.company,
      name: formData.name
    };

    this.editCheckbox.emit(checkboxData);
  }

  onCancel() {
    this.isEditMode = false;
    this.cancel.emit({});
  }
}
