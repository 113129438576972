<h2 class="slider_title">Trusted by great teams worldwide</h2>
<div class="divider"></div>
<div class="slider_container" #slider_container>
	<!--div class="prev_btn" (click)="onClickPrev()"></div>
	<div class="next_btn" (click)="onClickNext()"></div-->
	<div class="clients_container">
		<div class="clients_inner" #clients_inner>
			<div class="slider_item {{client.className}}" *ngFor="let client of sliderClients" #slider_item></div>
		</div>
	</div>
</div>
<div class="without_slider">
  <div class="slider_item {{client.className}}" *ngFor="let client of sliderClients"></div>
</div>
