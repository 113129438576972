<div class="add_task_popup">
  <a-center-popup [title]="'Add Task'" (close)="onCloseAddTask()">
    <backlog-add-task-form
      [backlogScoreX]="backlogScoreX"
      [backlogScoreY]="backlogScoreY"
      [swimlanes]="swimlanes$ | async"
      [columns]="columns$ | async"
      [source]="source"
      (taskCreated)="onCloseAddTask()"
    ></backlog-add-task-form>
  </a-center-popup>
</div>
