import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { compose } from '@ngrx/store';
import { share } from '../../functions/util';
import { getEntitySelector } from '../../functions/selectors';
import { DEFAULT_COLORS_FILTER } from '../../state';
import { Actions } from '../../actions/task-filters/default-colors-filter.actions';
import { DefaultTasksColorsFilterActionTypes } from '../../actions/task-filters/default-colors-filter.actions';
import { TaskDefaultFilterActionTypes } from '../../actions/task-filters/task-default-filter.actions';

export interface DefaultColorsFilter {
  colors: string[];
}

const initState: DefaultColorsFilter = {
  colors: []
};

export function reducer(state = initState, action: Actions): DefaultColorsFilter {
  switch (action.type) {
    case DefaultTasksColorsFilterActionTypes.TOGGLE_COLOR: {
      const colors = [...state.colors];
      const color = action['payload'];
      const index = colors.findIndex(item => item === color);
      if (index > -1) {
        colors.splice(index, 1);
      } else {
        colors.push(color);
      }
      state = Object.assign({}, state, { colors: colors });
      return state;
    }

    case DefaultTasksColorsFilterActionTypes.RESET: {
      state = initState;
      return initState;
    }

    case TaskDefaultFilterActionTypes.SET: {
      if (action['payload'].activeFilters.defaultColorsFilter) {
        state = { colors: [...action['payload'].activeFilters.defaultColorsFilter] };
      } else {
        state = initState;
      }
      return state;
    }

    default: {
      return state;
    }
  }
}

const isActive = (state$: Observable<DefaultColorsFilter>) => state$.pipe(map(state => state.colors.length > 0));
const getColorsValues = (state$: Observable<DefaultColorsFilter>) => state$.pipe(map(state => state.colors));

export const getDefaultColorsFilterEntity = getEntitySelector(DEFAULT_COLORS_FILTER);
export const isActiveDefaultColorsFilter = compose(isActive, getDefaultColorsFilterEntity);
export const getDefaultColorsFilterValues = share(compose(getColorsValues, getDefaultColorsFilterEntity));
