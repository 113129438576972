<div a-context-popup
     [title]="'Repeat'"
     (close)="onCloseTaskLinks()"
>
  <sidebar-repeat-add-form
    [task]="task"
    (close)="onCloseTaskLinks()"
  ></sidebar-repeat-add-form>

</div>
