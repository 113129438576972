<a-center-popup (close)="onClose()" [title]="'Criteria Details'">
  <form [formGroup]="form" (submit)="onSubmit()">
    <form-errors></form-errors>
    <div class="form-group">
      <label for="name">Name</label>
      <input id="name" formControlName="name" [focus]="true" checkTouch placeholder="Criterion Name">
      <help-block [control]="form.get('name')" [messages]="{maxlength: 'Criterion name exceeds the maximum length (40 symbols)'}"></help-block>
    </div>
    <div class="form-group">
      <label>Description</label>
      <textarea autosize formControlName="description" checkTouch placeholder="Criterion Description"></textarea>
      <help-block [control]="form.get('description')" [messages]="{maxlength: 'Description exceeds the maximum length (255 symbols)'}"></help-block>
    </div>
    <div class="form-group">
      <label>Data type</label>
      <select formControlName="dataType">
        <option *ngFor="let dataType of dataTypeLabels" [value]="dataType.value">{{dataType.name}}</option>
      </select>
      <help-block [control]="form.get('dataType')"></help-block>
    </div>
    <div class="form-group">
      <label>Scoring Direction</label>
      <select formControlName="scoringDirection">
        <option [value]="ScoringDirection.HigherBetter">⬆ Higher values are better</option>
        <option [value]="ScoringDirection.LowerBetter">⬇ Lower values are better</option>
      </select>
      <help-block [control]="form.get('dataType')"></help-block>
    </div>
  </form>
  <div class="buttons-block">
    <button a-button (click)="onSubmit()" tabindex="0" class="btn_primary">{{isNew ? 'Create' : 'Update'}}</button>
    <button a-button (click)="onClose()" class='btn_secondary'>Cancel</button>
    <button a-button (click)="onDelete()" *ngIf="!isNew" class="btn_text">Delete</button>
  </div>
</a-center-popup>
<div
  a-confirmation-popup
  *ngIf="showConfirmDelete$ | async"
  confirmationTitle="Confirmation"
  (confirm)="onConfirmDeleting()"
  (close)="hideDeleteConfirm()"
>
Please confirm your intention to delete the criteria.
</div>
