<div class="collection_title" (click)="onOpenBoardsList()">
  <div class="arrow_state_icon" [class.closed_state]="!isVisibleBoards">
    <svg width="18" height="18">
      <use xlink:href="#triangle"></use>
    </svg>
  </div>
  <h3>{{project.name}}</h3>
  <div class="context_menu" *ngIf="canEditProject$ | async">
    <left-menu-context-menu
      [isOpened]="isContextMenuVisible"
      (open)="onSwitchContextMenu()"
      (close)="onSwitchContextMenu()"
    >
      <p (click)="onCloseLeftMenu()" [routerLink]="appUrls.getUrlProjectSettings(project.id)">Edit Project Settings</p>
      <p (click)="onCloseLeftMenu()" [routerLink]="appUrls.getUrlNewBoard()" [queryParams]="{projectId: project.id, goToNewBoard: true, source: 'leftMenu'}" >Add Board to Project</p>

    </left-menu-context-menu>
  </div>

</div>

<left-board-list *ngIf="isVisibleBoards && visibleBoards.length !== 0"
                 [boards]="visibleBoards"
                 [isSubCollection]="true"
></left-board-list>
