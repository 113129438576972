<h5 class="sidebar_block_title">Complete <span>{{form.get('percent').value}}%</span></h5>
<form [formGroup]="form">
  <form-errors></form-errors>
  <input type="range"
         formControlName="percent"
         min="0"
         [max]="100"
         step="1"
         [attr.disabled]="!editPermissions || null"
         (change)="onSubmit()"
  />
  <div class="divider"></div>
</form>
