import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Project } from '../../../../interfaces/index';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../../services/form-v2.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../ngrx/state/index';
import { PROJECT_PL } from '../../../../constants/index';
import { Observable, Observer } from 'rxjs/index';
import { HandleResponseAction } from '../../../../ngrx/actions/root.action';
import { fromProject } from '../../../../ngrx/reducers/project.reducer';
import { map } from 'rxjs/operators';

@Component({
  selector: 'project-status-editing',
  templateUrl: './project-status-editing.component.html',
  styleUrls: ['./project-status-editing.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectStatusEditingComponent implements OnInit {
  @Input() project: Project;

  @Output() close = new EventEmitter();

  private _closeAfterSubmit = true;

  public isImportVisible = false;
  public showLengthError = false;
  public allProjects$: Observable<Project[]>;

  formObserver: Observer<any> = {
    next: resp => {
      this._store.dispatch(new HandleResponseAction(resp));
      if (this.form.get('newStatus').value) {
        this.form.get('status').patchValue(this.form.get('newStatus').value);
        this.form.get('newStatus').patchValue('');
      }
      if (this._closeAfterSubmit === false) {
        this.form.get('deleteStatus').patchValue('');
        this.form.get('imported').patchValue([]);
        this.isImportVisible = false;
        this._closeAfterSubmit = true;
      } else {
        this.onClose();
      }
    },
    error: error => {
      console.log(error, 'project progress editing');
    },
    complete: () => {}
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.edit,
    entityToEdit: PROJECT_PL,
    formObserver: this.formObserver,
    prepareFormValue: formValue => {
      const oldStatuses = Array.isArray(this.project.statuses) ? this.project.statuses : [];
      const result = {
        id: formValue['id'],
        status: formValue['status']
      };
      if (formValue['newStatus'] && oldStatuses.indexOf(formValue['newStatus']) === -1) {
        result['status'] = formValue['newStatus'];
        result['statuses'] = [...oldStatuses, formValue['newStatus']];
      }
      if (formValue['imported'] && formValue['imported'].length) {
        if (!result['statuses']) {
          result['statuses'] = [...oldStatuses];
        }
        formValue['imported'].forEach(importedStatus => {
          if (result['statuses'].indexOf(importedStatus) === -1) {
            result['statuses'].push(importedStatus);
          }
        });
      }
      if (formValue['deleteStatus']) {
        result['statuses'] = [...oldStatuses.filter(s => s !== formValue['deleteStatus'])];
      }
      return result;
    }
  };

  form: FormGroup;

  constructor(public _formService: FormV2Service, private _fb: FormBuilder, private _store: Store<AppState>) {}

  ngOnInit() {
    this.allProjects$ = this._store
      .select(fromProject.getState)
      .pipe(
        map(state =>
          Object.values(state.entities).filter(
            entity => entity.id !== this.project.id && entity.statuses && entity.statuses.length
          )
        )
      );
    this.form = this._fb.group({
      id: [this.project.id],
      status: [this.project.status],
      newStatus: [''],
      deleteStatus: [''],
      imported: [[]]
    });
    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  onSubmit() {
    this.form.get('newStatus').patchValue('');
    this.form.get('imported').patchValue([]);
    this.showLengthError = false;
    this._formService.submit();
  }

  onClose() {
    this.close.emit();
  }

  onAddNewStatus() {
    const newStatus = this.form.get('newStatus');
    const newStatusValue = newStatus.value.trim();
    this.showLengthError = false;
    if (newStatusValue) {
      if (newStatusValue.length < 51) {
        newStatus.patchValue(newStatus.value.trim());
        this._closeAfterSubmit = false;
        this._formService.submit();
      } else {
        this.showLengthError = true;
      }
    }
  }

  onDeleteStatus(status) {
    this.form.get('deleteStatus').patchValue(status);
    if (status === this.form.get('status').value) {
      this.form.get('status').patchValue('');
    }
    this._closeAfterSubmit = false;
    this.onSubmit();
  }

  onImport() {
    this._closeAfterSubmit = false;
    this._formService.submit();
  }

  showImport() {
    this.isImportVisible = true;
  }
}
