
import {merge as observableMerge, fromEvent as observableFromEvent,  Observable ,  BehaviorSubject ,  Subscription } from 'rxjs';

import {distinctUntilChanged} from 'rxjs/operators';
import {
  Input,
  Directive,
  ViewContainerRef,
  ElementRef,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
  OnChanges,
  SimpleChanges
} from '@angular/core';

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective implements OnInit, OnChanges, OnDestroy {
  @Input() tooltip;

  @Input() eventUp = 'mouseenter';
  @Input() eventDown = 'mouseleave';
  @Input() hideWhen = false;

  /**
   * in this case show tooltip when flag is true, and hide when false
   * !IMPORTANT can't be undefined!
   * Undefined is default falue when we listen EventUp and EventDown and ingrore this property
   *
   * @type {boolean}
   */
  @Input() byFlagOnly: boolean;

  private byFlagOnly$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private subs: Subscription[] = [];

  constructor(
    private _viewContainer: ViewContainerRef,
    private _elementRef: ElementRef,
    private _cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    let sub: Subscription;
    if (this.byFlagOnly !== undefined) {
      sub = this.listenFlagOnly();
    } else {
      sub = this.listenEvents();
    }
    this.subs.push(sub);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['byFlagOnly']) {
      this.byFlagOnly$.next(this.byFlagOnly);
    }

    if (changes['hideWhen'] && this.hideWhen === true) {
      this._viewContainer.clear();
    }
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  private listenFlagOnly() {
    return this.byFlagOnly$.pipe(distinctUntilChanged()).subscribe(flag => this.toggleViewContainer(flag));
  }

  private listenEvents() {
    // use Observable event because @HostListener can not get dynamicaly eventName parameter
    const eventUp$ = observableFromEvent(this._elementRef.nativeElement, this.eventUp);
    const eventDown$ = observableFromEvent(this._elementRef.nativeElement, this.eventDown);

    return observableMerge(eventUp$, eventDown$).subscribe((event: Event) => {
      this.toggleViewContainer(event.type === this.eventUp);
      this._cd.markForCheck();
    });
  }

  private toggleViewContainer(toShow: boolean) {
    toShow ? this._viewContainer.createEmbeddedView(this.tooltip) : this._viewContainer.clear();
  }
}
