import { columnKinds, columnTypes } from '../app/constants';

export const isSubColumn = column => !!column.parent;
export const isParentColumn = column => !column.parent;

export const isDoneColumn = column => column.type === columnTypes.done;

export const isInProgressColumn = column => [columnTypes.inprogress, columnTypes.sub_inprogress].includes(column.type);

export const isSimpleColumn = column => column.kind === columnKinds.simple;
export const isCompositeColumn = column => column.kind === columnKinds.composite;
