import { Action } from '../unsafe-action';
import { ReportProjectsFilter } from '../../reducers/report-filters/report-projects-filter.reducer';

export enum ReportProjectFilterActionTypes {
  APPLY = '[REPORT_PROJECT_FILTER] APPLY',
  RESET = '[REPORT_PROJECT_FILTER] RESET'
}

export class ReportProjectFilterApplyAction implements Action {
  readonly type = ReportProjectFilterActionTypes.APPLY;

  constructor(public payload: ReportProjectsFilter) {}
}

export class ReportProjectFilterResetAction implements Action {
  readonly type = ReportProjectFilterActionTypes.RESET;
}

export type Actions = ReportProjectFilterApplyAction | ReportProjectFilterResetAction;
