import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { AppState } from '../../../ngrx/state';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { filter, map, take } from 'rxjs/operators';
import { TemplateCollection } from '../../../interfaces/boards-template';
import { fromBoardsTemplates } from '../../../ngrx/reducers/boards-template.reducer';
import { BoardsTemplate, Company } from '../../../interfaces';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/index';
import { CompanyService } from "../../services/app/company.service";

@Component({
  selector: 'board-template-chooser',
  templateUrl: './board-template-chooser.component.html',
  styleUrls: ['./board-template-chooser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoardTemplateChooserComponent implements OnInit, AfterViewInit {
  @Input() isSingleSelect = false;
  @Input() isOnBoarding = false;
  @Input() chooseTemplatesError = '';
  @Input() isSprintAvailable = true;
  @Output() changeSelection = new EventEmitter();
  public templateCollections$: Observable<TemplateCollection[]>;
  public chosenCollectionTemplates$: Observable<BoardsTemplate[]>;
  public selectedTemplates = [];
  public expandedCollection: string;
  public expandedCollection$ = new BehaviorSubject('');

  constructor(
    private _store: Store<AppState>,
    private _cd: ChangeDetectorRef,
    private _company: CompanyService
  ) {}

  ngOnInit() {
    if (this.isOnBoarding) {
      this.templateCollections$ = this._store.select(fromBoardsTemplates.getGrouped(this.isSprintAvailable)).pipe(
        map((cols: TemplateCollection[]) => {
          return cols
            .map((col: TemplateCollection) => ({
              name: col.name,
              templates: col.templates.filter(item => item.onBoarding)
            }))
            .filter(item => item.templates.length);
        })
      );
    } else {
      this.templateCollections$ = this._store.select(fromBoardsTemplates.getGrouped(this.isSprintAvailable));
    }
    this.chosenCollectionTemplates$ = combineLatest([this.templateCollections$, this.expandedCollection$]).pipe(
      map(([collections, expandedCollection]: [TemplateCollection[], string]) => {
        const chosenCol = collections.filter(item => item.name === expandedCollection)[0];
        return chosenCol ? chosenCol.templates : [];
      })
    );
  }

  ngAfterViewInit() {
    this.templateCollections$
      .pipe(filter(collections => collections && !!collections.length), take(1))
      .subscribe(collections => {
        if (this.isOnBoarding) {
          this._company.currentCompany$.pipe(take(1)).subscribe((company : Company) => {
            const types = {
              clients: 'Most Popular',
              internal: 'Most Popular',
              lists: 'Most Popular',
              sales: 'Marketing and Sales',
              'roadmap/backlog': 'Product Management',
              agile: 'Most Popular',
              marketing: 'Marketing and Sales',
              hr: 'HR'
            };

            if (company.projectsType
              && types.hasOwnProperty(company.projectsType)
              && collections.some(c => c.name === types[company.projectsType]))
            {
              this.onToggleCollection(types[company.projectsType]);
            } else {
              this.onToggleCollection(collections[0].name);
            }
          });
        } else {
          this.onToggleCollection(collections[0].name);
        }
        this._cd.detectChanges();
      });
  }

  onToggleCollection(name) {
    this.expandedCollection = name;
    this.expandedCollection$.next(this.expandedCollection);
  }

  onToggleBoard(event, id) {
    if (!this.isSingleSelect) {
      const index = this.selectedTemplates.findIndex(item => item === id);
      if (index > -1) {
        this.selectedTemplates.splice(index, 1);
      } else {
        this.selectedTemplates.push(id);
      }
    } else {
      this.selectedTemplates.splice(0, this.selectedTemplates.length, id);
    }
    this.changeSelection.emit(this.selectedTemplates);
  }

  isCollectionExpanded(name: string) {
    return this.expandedCollection === name;
  }

  isTemplateSelected(templateId) {
    return this.selectedTemplates.includes(templateId);
  }
}
