import { labelAction } from '../functions/action';
import { PartOfEntity, Collection, HttpQueryParam } from '../../interfaces';
import { COLLECTION } from '../../constants';
import { Action } from './unsafe-action';

export const CollectionActionTypes = {
  LOAD: labelAction(COLLECTION, 'LOAD'),
  GET: labelAction(COLLECTION, 'GET'),
  GET_COMPLETE: labelAction(COLLECTION, 'GET_COMPLETE'),
  GET_FAIL: labelAction(COLLECTION, 'GET_FAIL'),
  ADD: labelAction(COLLECTION, 'ADD'),
  ADD_COMPLETE: labelAction(COLLECTION, 'ADD_COMPLETE'),
  ADD_FAIL: labelAction(COLLECTION, 'ADD_FAIL'),
  EDIT: labelAction(COLLECTION, 'EDIT'),
  EDIT_COMPLETE: labelAction(COLLECTION, 'EDIT_COMPLETE'),
  EDIT_FAIL: labelAction(COLLECTION, 'EDIT_FAIL'),
  DELETE: labelAction(COLLECTION, 'DELETE'),
  DELETE_COMPLETE: labelAction(COLLECTION, 'DELETE_COMPLETE'),
  DELETE_FAIL: labelAction(COLLECTION, 'DELETE_FAIL'),
  MOVE: labelAction(COLLECTION, 'MOVE'),
  ASSIGN_BOARDS: labelAction(COLLECTION, 'ASSIGN_BOARDS')
};

export class CollectionGetAction implements Action {
  type = CollectionActionTypes.GET;

  constructor(public payload?: HttpQueryParam) {}
}

export class CollectionGetCompleteAction implements Action {
  type = CollectionActionTypes.GET_COMPLETE;

  constructor(public payload: Collection[]) {}
}

export class CollectionEditAction implements Action {
  type = CollectionActionTypes.EDIT;
  constructor(public payload: PartOfEntity) {}
}

export class CollectionAssignBoardsAction implements Action {
  type = CollectionActionTypes.ASSIGN_BOARDS;
  constructor(
    public payload: {
      id: number;
      boards: { add?: number[]; remove?: number[] };
    }
  ) {}
}

export class CollectionEditCompleteAction implements Action {
  type = CollectionActionTypes.EDIT_COMPLETE;

  constructor(public payload: Collection) {}
}

export class CollectionDeleteAction implements Action {
  type = CollectionActionTypes.DELETE;

  constructor(public payload: number) {}
}

export class CollectionDeleteCompleteAction implements Action {
  type = CollectionActionTypes.DELETE_COMPLETE;

  constructor(public payload?: any) {}
}

export class CollectionAddAction implements Action {
  type = CollectionActionTypes.ADD;

  constructor(public payload: PartOfEntity) {}
}

export class CollectionAddCompleteAction implements Action {
  type = CollectionActionTypes.ADD_COMPLETE;

  constructor(public payload: Collection) {}
}

export type CollectionActions =
  | CollectionGetAction
  | CollectionGetCompleteAction
  | CollectionEditAction
  | CollectionEditCompleteAction
  | CollectionDeleteAction
  | CollectionDeleteCompleteAction
  | CollectionAddAction
  | CollectionAddCompleteAction;
