import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { Board, Column, Label, PartOfEntity, Swimlane, Task, User, Version } from '../../interfaces';
import { Observable } from 'rxjs/Observable';
import { AppState } from '../../ngrx/state';
import { Store } from '@ngrx/store';
import { getTasksByUsersFilter, inBoard, isActiveTask } from '../../ngrx/reducers/task.reducer';
import { distinctUntilChanged, map, pluck, publishReplay, refCount, switchMap, take, tap } from 'rxjs/operators';
import {
  allPass,
  getVisibleScoringVal,
  isPresent,
  naturalSort,
  naturalSortComparator,
  sortByField
} from '../../../helpers';
import { IceFactors, RiceFactors, ScoringFactor } from '../backlog-board/constants/backlog.constants';
import { BehaviorSubject, combineLatest, fromEvent, Subscription } from 'rxjs/index';
import { AppUrls } from '../../app-urls';
import { BacklogChartService } from '../backlog-chart/backlog-chart.service';
import { boardType, NonBasicScoringTypes, ScaleTypeLabel, ScoringType } from '../../constants';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import { getVisibleTaskAxisScore } from '../../../helpers/task';
import { ScoringCriteria, SeparatedCriteria } from '../../interfaces/ScoringCriteria';
import { fromScoringCriteria } from '../../ngrx/reducers/scoring-criteria.reducer';
import { getVersionsList } from '../../ngrx/reducers/version.reducer';
import { fromLabels } from '../../ngrx/reducers/label.reducer';
import { fromUsers } from '../../ngrx/reducers/user.reducer';
import { TaskAssignUsersAction, TaskDeleteAction, TaskEditAction } from '../../ngrx/actions/task.actions';
import { BoardAssignUsersAction } from '../../ngrx/actions/board.actions';
import {
  getTaskPossibleUsers,
  getTaskProjectOnlyUsers,
  getTaskUsers,
  getVersionsListByBoardId
} from '../../ngrx/functions/crossed.selector';
import { fromColumns } from '../../ngrx/reducers/column.reducer';
import { fromSwimlanes } from '../../ngrx/reducers/swimlane.reducer';
import { GlobalHotkeysService } from '../../libs/global-hotkeys/global-hotkeys.service';
import { RouterNavigateService } from '../../shared/services/router-navigate.service';
import {
  GuiStateBatchEditHide,
  GuiStateBatchEditShow,
  GuiStateQuickTaskEditShow
} from '../../ngrx/actions/gui-state-memorized.actions';
import { CustomField, CustomFieldTypes, fromCustomFields } from '../../ngrx/reducers/custom-field.reducer';
import { fromTasksCustomFields, TasksCustomField } from '../../ngrx/reducers/tasks-custom-field.reducer';
import { SecondsToDatePipe } from '../../shared/pipes';
import { isString } from 'util';
import { fromGuiState } from '../../ngrx/reducers/gui-state-memorized.reducer';
import { BoardInProjectAccess } from '../../interfaces/board';

interface CalculatedTask {
  id: number;
  title: string;
  users?: User[] | {}[];
  labels?: Label[] | {}[];
  version?: number;
  backlogScoreX?: string | number;
  backlogScoreY?: string | number;
  backlogScoreXSort?: number;
  backlogScoreYSort?: number;
  iceImpact?: string | number;
  iceConfidence?: string | number;
  iceEase?: string | number;
  riceReach?: string | number;
  riceImpact?: string | number;
  riceConfidence?: string | number;
  riceEffort?: string | number;
  confidence?: number;
  scoringValuesMap?: any;
  score?: string | number;
  sortField?: string;
  startDate: number;
  dueDate: number;
  column: string;
  swimlane: string;
}

interface BoardWeights {
  valueWeight: number;
  effortsWeight: number;
}

const replaceNotFinite = (replacer, array, prop, subProp?) => {
  if (subProp) {
    return array.map(item => {
      if (!item[prop][subProp] || Number.isFinite(item[prop][subProp].value)) {
        return item;
      } else {
        item[prop][subProp].value = replacer;
        return item;
      }
    });
  } else {
    return array.map(item => {
      if (Number.isFinite(item[prop])) {
        return item;
      } else {
        item[prop] = replacer;
        return item;
      }
    });
  }
};

const nonAdvancedSort = (infin, calcTasks, activeSort) => {
  const sortParam =
    activeSort.param === 'backlogScoreX' || activeSort.param === 'backlogScoreY'
      ? activeSort.param + 'Sort'
      : activeSort.param;

  if (!activeSort.isTextSort) {
    replaceNotFinite(infin, calcTasks, sortParam);
    calcTasks.sort((a, b) => b[sortParam] - a[sortParam]);
    return replaceNotFinite('?', calcTasks, sortParam);
  } else if (!activeSort.arraySortParam) {
    return calcTasks.sort((a, b) => {
      if (!a[sortParam] || !b[sortParam]) {
        return (+!!a[sortParam] - +!!b[sortParam]) * infin;
      } else {
        return naturalSortComparator(sortParam)(b, a);
      }
    });
  } else {
    calcTasks.forEach(
      calcTask =>
        (calcTask.sortField = calcTask[sortParam].reduce((acc, item) => acc + item[activeSort.arraySortParam], ''))
    );
    return calcTasks.sort((a, b) => {
      if (!a.sortField || !b.sortField) {
        return (+!!a.sortField - +!!b.sortField) * infin;
      } else {
        return naturalSortComparator('sortField')(b, a);
      }
    });
  }
};

const advancedSort = (infin, calcTasks, activeSort, tasksCustomFieldsMap?) => {
  if (activeSort.param === 'confidence') {
    replaceNotFinite(infin, calcTasks, 'confidence');
    calcTasks.sort((a, b) => b.confidence - a.confidence);
    return replaceNotFinite('?', calcTasks, 'confidence');
  } else if (activeSort.param === 'score') {
    replaceNotFinite(infin, calcTasks, 'score');
    calcTasks.sort((a, b) => +b.score - +a.score);
    return replaceNotFinite('?', calcTasks, 'score');
  } else if (isString(activeSort.param) && activeSort.param.slice(0, 3) === 'cf:') {
    const cfId = +activeSort.param.slice(3);
    const replacer = activeSort.isTextSort ? '' : infin;
    calcTasks.forEach(
      item =>
        (item['cfSortValue'] =
          tasksCustomFieldsMap[cfId] && tasksCustomFieldsMap[cfId][item.id]
            ? tasksCustomFieldsMap[cfId][item.id].value
            : replacer)
    );
    if (activeSort.isTextSort) {
      return calcTasks.sort((a, b) => {
        if (!a.cfSortValue || !b.cfSortValue) {
          return (+!!a.cfSortValue - +!!b.cfSortValue) * infin;
        } else {
          return naturalSortComparator('cfSortValue')(b, a);
        }
      });
    } else {
      return calcTasks.sort((a, b) => +b.cfSortValue - +a.cfSortValue);
    }
  } else {
    replaceNotFinite(infin, calcTasks, 'scoringValuesMap', activeSort.param);
    calcTasks.sort((a, b) => {
      const aCompareVal =
        a.scoringValuesMap.hasOwnProperty(activeSort.param) &&
        Number.isFinite(a.scoringValuesMap[activeSort.param].value)
          ? a.scoringValuesMap[activeSort.param].value
          : infin;
      const bCompareVal =
        b.scoringValuesMap.hasOwnProperty(activeSort.param) &&
        Number.isFinite(b.scoringValuesMap[activeSort.param].value)
          ? b.scoringValuesMap[activeSort.param].value
          : infin;
      return bCompareVal === aCompareVal ? 0 : bCompareVal - aCompareVal; // for preventing case of Infinity - Infinity => NaN
    });
    return replaceNotFinite('?', calcTasks, 'scoringValuesMap', activeSort.param);
  }
};

const createMapFromArr = arr =>
  arr.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});

@Component({
  selector: 'board-table-view',
  templateUrl: './board-table-view.component.html',
  styleUrls: ['../board-versions-view/board-versions-view.component.scss', './board-table-view.component.scss'],
  providers: [SecondsToDatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoardTableViewComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() board$: Observable<Board>;
  @Input() isNotGuest: boolean;
  public boardId$: Observable<number>;
  public tasks$: Observable<Task[]>;
  public boardType = boardType;
  public type$: Observable<ScoringType>;
  public calculatedTasks$: Observable<CalculatedTask[]>;
  public filterValue$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public activeSort$: BehaviorSubject<any> = new BehaviorSubject('');
  public getTaskLink = AppUrls.getUrlTask;
  public isScoreEditing$ = new BehaviorSubject(false);
  public editingTask$ = new BehaviorSubject(null);
  public editingTitle = {
    id: NaN,
    height: NaN,
    title: '',
    originalTitle: ''
  };
  public editingFactor$ = new BehaviorSubject(null);
  public editingCriterion$ = new BehaviorSubject(null);
  public editingAxis$ = new BehaviorSubject(null);
  public higlightTimeout;
  public factors$;
  public versions$: Observable<Version[]>;
  public showVersion$: Observable<'yes' | 'no'>;
  public showSwimlanes$: Observable<'yes' | 'no'>;
  public labels$: Observable<Label[]>;
  public users$: Observable<User[]>;
  public columns$: Observable<Column[]>;
  public swimlanes$: Observable<Swimlane[]>;
  subs: Subscription[] = [];
  public ScoringType = ScoringType;

  public iceFactors = IceFactors;
  public riceFactors = RiceFactors;

  public boardCriteria$: Observable<SeparatedCriteria>;
  public crsMap;
  public versionsMap: { [id: number]: Version };
  public labelsMap: { [id: number]: Label };
  public usersMap: { [id: number]: User };
  public columnsMap: { [id: number]: Column };
  public swimlanesMap: { [id: number]: Swimlane };
  public boardWeights$: Observable<BoardWeights>;
  public criteriaValues$: Observable<ScoringCriteria[]>;
  public criteriaEfforts$: Observable<ScoringCriteria[]>;

  public customFields$: Observable<CustomField[]>;
  public tasksCustomFields$: Observable<TasksCustomField[]>;
  public customFieldsMap: { [id: number]: CustomField };
  public tasksCustomFieldsMap: {
    [custmFieldId: number]: { [taskId: number]: { visibleValue: string; value: number | string } };
  };

  public isPublicBoard$: Observable<boolean>;
  public taskUsers$: Observable<User[]>;
  public possibleUsers$: Observable<User[]>;
  public projectOnlyUsers$: Observable<User[]>;
  public boardVersions$: Observable<Version[]>;
  public isMembersPopupVisible$ = new BehaviorSubject(false);
  public isLabelsPopupVisible$ = new BehaviorSubject(false);
  public isVersionPopupVisible$ = new BehaviorSubject(false);
  public isStartDatePopupVisible$ = new BehaviorSubject(false);
  public isDueDatePopupVisible$ = new BehaviorSubject(false);
  public isColumnPopupVisible$ = new BehaviorSubject(false);
  public editCustomFieldTaskId$ = new BehaviorSubject(0);
  public tasksCustomFieldClientRect;
  public editingCustomFieldValue: TasksCustomField | PartOfEntity;
  public editingCustomField: CustomField;

  public NonBasicScoringTypes = NonBasicScoringTypes;

  public showNewTaskForm$ = new BehaviorSubject(false);
  public taskToDelete: Task;

  public isBatchEdit: boolean;
  public selectedTasksMap: { [id: number]: boolean } = {};

  public CustomFieldTypes = CustomFieldTypes;

  constructor(
    private _elRef: ElementRef,
    private _store: Store<AppState>,
    private _renderer: Renderer2,
    private _globalHotKeys: GlobalHotkeysService,
    private _backLogChartService: BacklogChartService,
    private _routerNav: RouterNavigateService,
    private _toDate: SecondsToDatePipe
  ) {}

  ngOnInit() {
    this.subs.push(
      this._store.select(fromGuiState.getBatchEditParams).subscribe(params => {
        if (params) {
          this.isBatchEdit = true;
        }
        this.selectedTasksMap = (params || { ids: [] }).ids.reduce((acc, item) => {
          acc[item] = true;
          return acc;
        }, {});
      })
    );
    this.boardId$ = this.board$.pipe(map(board => (board ? board.id : 0)));
    this.isPublicBoard$ = this.board$.pipe(map(board => board && board.access === BoardInProjectAccess.public));

    this.swimlanes$ = this.boardId$.pipe(switchMap(id => this._store.select(fromSwimlanes.getByBoardId(id))));
    this.showSwimlanes$ = this.swimlanes$.pipe(
      map(swimlanes => (Array.isArray(swimlanes) && swimlanes.length > 1 ? 'yes' : 'no'))
    );

    this.showVersion$ = this.board$.pipe(
      map(
        board =>
          board && board.projectsIds && board.projectsIds.length && board.type !== boardType.sprint ? 'yes' : 'no'
      )
    );

    this.versions$ = this._store.select(getVersionsList);
    this.columns$ = this._store.select(fromColumns.getListWithFullNames);
    this.labels$ = this.boardId$.pipe(
      switchMap(id => this._store.select(fromLabels.getByBoardId(id))),
      map(labels => {
        let i = 1;
        return labels.map(label => {
          if (!label.name) {
            const result = { ...label };
            result.name = 'Untitled Label ' + i;
            i++;
            return result;
          }
          return label;
        });
      })
    );
    this.users$ = this.board$.pipe(
      switchMap(board => this._store.select(fromUsers.getByIds(board.usersIds ? board.usersIds : [])))
    );
    this.type$ = this.board$.pipe(pluck('scoringType'));

    this.customFields$ = this.boardId$.pipe(
      switchMap(id => this._store.select(fromCustomFields.getByBoardId(id))),
      publishReplay(1),
      refCount()
    );
    this.tasksCustomFields$ = this._store.select(fromTasksCustomFields.getAll).pipe(publishReplay(1), refCount());

    this.subs.push(
      this.type$.pipe(distinctUntilChanged()).subscribe(() => this.resetSort()),
      this.versions$.subscribe(versions => {
        this.versionsMap = createMapFromArr(versions);
      }),
      this.labels$.subscribe(labels => {
        this.labelsMap = createMapFromArr(labels);
      }),
      this.swimlanes$.subscribe(swimlanes => {
        this.swimlanesMap = createMapFromArr(swimlanes);
      }),
      this.users$.subscribe(users => {
        this.usersMap = createMapFromArr(users);
      }),
      this.columns$.subscribe(columns => {
        this.columnsMap = createMapFromArr(columns);
      }),
      this.customFields$.subscribe(customFields => {
        this.customFieldsMap = createMapFromArr(customFields);
      }),
      combineLatest(this.customFields$, this.tasksCustomFields$).subscribe(([customFields, tasksCustomFields]) => {
        this.tasksCustomFieldsMap = customFields.reduce((acc, item) => {
          acc[item.id] = {};
          return acc;
        }, {});
        tasksCustomFields.forEach(item => {
          if (!this.tasksCustomFieldsMap[item.customField]) {
            return;
          }
          this.tasksCustomFieldsMap[item.customField][item.task] = {
            visibleValue:
              this.customFieldsMap[item.customField].type === CustomFieldTypes.date
                ? this._toDate.transform(item.value, 'dueDate')
                : item.value,
            value: item.value
          };
        });
      })
    );

    this.boardCriteria$ = this.boardId$.pipe(
      switchMap(id => this._store.select(fromScoringCriteria.getByBoard(id))),
      map(crs =>
        crs.reduce(
          (acc, item) => {
            if (item.factor === ScoringFactor.Value) {
              acc[ScoringFactor.Value].push(item);
            } else {
              acc[ScoringFactor.Efforts].push(item);
            }
            return acc;
          },
          { [ScoringFactor.Value]: [], [ScoringFactor.Efforts]: [] }
        )
      )
    );

    this.boardWeights$ = this.board$.pipe(
      map(board => ({ valueWeight: board.advancedValueWeight, effortsWeight: board.advancedEffortWeight }))
    );

    this.criteriaValues$ = this.boardCriteria$.pipe(
      map((sepCrs: SeparatedCriteria) => (sepCrs ? sepCrs[ScoringFactor.Value] : []).filter(isPresent)),
      publishReplay(1),
      refCount()
    );

    this.criteriaEfforts$ = this.boardCriteria$.pipe(
      map((sepCrs: SeparatedCriteria) => (sepCrs ? sepCrs[ScoringFactor.Efforts] : []).filter(isPresent)),
      publishReplay(1),
      refCount()
    );

    this.tasks$ = this.boardId$.pipe(
      distinctUntilChanged(),
      switchMap(boardId =>
        this._store.pipe(
          getTasksByUsersFilter(
            allPass([inBoard(boardId), task => task.type !== 'group' && !task.linkToTask, isActiveTask])
          )
        )
      ),
      map(sortByField('position', 'asc')),
      publishReplay(1),
      refCount()
    );

    this.factors$ = this.board$.pipe(
      map(board => {
        if (board.scoringType === ScoringType.ICE) {
          return this.iceFactors;
        } else if (board.scoringType === ScoringType.RICE) {
          return this.riceFactors;
        } else if (!NonBasicScoringTypes.includes(board.scoringType)) {
          return [
            {
              type: board.backlogScoreXType,
              prop: 'backlogScoreX',
              label: board.backlogScoreXLabel || 'Efforts',
              editInfo: ScaleTypeLabel[board.backlogScoreXType]
            },
            {
              type: board.backlogScoreYType,
              prop: 'backlogScoreY',
              label: board.backlogScoreYLabel || 'Value',
              editInfo: ScaleTypeLabel[board.backlogScoreYType]
            }
          ];
        }
      })
    );

    this.calculatedTasks$ = combineLatest([
      this.tasks$,
      this.type$,
      this.board$,
      this.boardCriteria$,
      this.boardWeights$
    ]).pipe(
      tap(
        ([tasks, scoringType, board, sepCrs, boardWeights]: [
          Task[],
          ScoringType,
          Board,
          SeparatedCriteria,
          BoardWeights
        ]) => {
          if (scoringType === ScoringType.advanced) {
            this.crsMap = {};
            sepCrs[ScoringFactor.Value].forEach(cr => (this.crsMap[cr.id] = cr));
            sepCrs[ScoringFactor.Efforts].forEach(cr => (this.crsMap[cr.id] = cr));
          }
        }
      ),
      map(
        ([tasks, type, board, sepCrs, boardWeights]: [
          Task[],
          ScoringType,
          Board,
          SeparatedCriteria,
          BoardWeights
        ]): CalculatedTask[] =>
          tasks.map((task: Task): CalculatedTask => {
            const result: CalculatedTask = {
              id: task.id,
              title: task.title,
              version: task.version,
              labels: (task.labelsIds || []).map(id => this.labelsMap[id] || {}),
              users: (task.usersIds || []).map(id => this.usersMap[id] || { email: '@' }),
              startDate: task.startDate,
              dueDate: task.dueDate,
              column: this.columnsMap[task.column] ? this.columnsMap[task.column].name : '',
              swimlane: this.swimlanesMap[task.swimlane] ? this.swimlanesMap[task.swimlane].name : ''
            };
            switch (type) {
              case ScoringType.ICE: {
                result.iceImpact = Number.isFinite(task.iceImpact) ? task.iceImpact : '?';
                result.iceConfidence = Number.isFinite(task.iceConfidence) ? task.iceConfidence : '?';
                result.iceEase = Number.isFinite(task.iceEase) ? task.iceEase : '?';
                result.score = this.getICEScore(result);
                break;
              }
              case ScoringType.RICE: {
                result.riceReach = Number.isFinite(task.riceReach) ? task.riceReach : '?';
                result.riceImpact = Number.isFinite(task.riceImpact) ? task.riceImpact : '?';
                result.riceConfidence = Number.isFinite(task.riceConfidence) ? task.riceConfidence : '?';
                result.riceEffort = Number.isFinite(task.riceEffort) ? task.riceEffort : '?';
                result.score = this.getRICEScore(result);
                break;
              }
              case ScoringType.advanced: {
                result.confidence = task.confidence;
                result.scoringValuesMap = this.getScoringValuesMap(task);
                result.score = this.getTaskAdvancedScore(result, sepCrs, boardWeights);
                break;
              }
              case ScoringType.basic: {
                result.backlogScoreX = getVisibleTaskAxisScore(task.backlogScoreX, board.backlogScoreXType);
                result.backlogScoreY = getVisibleTaskAxisScore(task.backlogScoreY, board.backlogScoreYType);
                result.backlogScoreXSort = task.backlogScoreX;
                result.backlogScoreYSort = task.backlogScoreY;
                break;
              }
            }
            return result;
          })
      ),
      switchMap((calcTasks: CalculatedTask[]) =>
        this.filterValue$.pipe(
          map(filterVal => {
            if (filterVal) {
              filterVal = filterVal.toUpperCase();
              return calcTasks.filter(item => item.title.toUpperCase().indexOf(filterVal) > -1);
            }
            return calcTasks;
          })
        )
      ),
      switchMap(calcTasks => this.activeSort$.pipe(map(activeSort => this.sortCalcTasks(calcTasks, activeSort)))),
      publishReplay(1),
      refCount()
    );

    this.subs.push(
      this.calculatedTasks$.subscribe(() => {
        setTimeout(() => this.setHeight());
      })
    );
  }

  ngAfterViewInit() {
    this.setHeight();
    this.subs.push(
      fromEvent(window, 'resize').subscribe(_ => {
        this.setHeight();
      })
    );

    this.subs.push(
      this._backLogChartService.lastCreatedByMeTaskId$.pipe(distinctUntilChanged()).subscribe(id => {
        setTimeout(() => {
          const el = document.querySelector('[data-task-id="' + id + '"]');
          if (!el) {
            return;
          }
          scrollIntoViewIfNeeded(el, {
            duration: 100,
            boundary: this._elRef.nativeElement,
            centerIfNeeded: true
          });
          this.highlightScrolledTask(id, el);
          this._backLogChartService.lastCreatedByMeTaskId$.next(0);
        });
      })
    );
  }

  setHeight() {
    if (this._elRef.nativeElement) {
      const hostRect = this._elRef.nativeElement.getBoundingClientRect();
      const availableViewport = window.innerHeight - hostRect.top - 32;
      if (this._elRef.nativeElement.scrollHeight >= availableViewport) {
        this._elRef.nativeElement.style.height = availableViewport - 2 + 'px';
      }
    }
  }

  applySortBy(event, sortParam, isAdvanced?: boolean, isTextSort?: boolean, arraySortParam?: string) {
    this.activeSort$.pipe(take(1)).subscribe(activeSort => {
      const direction = event.target.dataset['sortArrow']
        ? event.target.dataset['sortArrow']
        : activeSort.param === sortParam && activeSort.direction === 'asc' ? 'desc' : 'asc';

      return this.activeSort$.next({
        param: sortParam,
        direction: direction,
        isAdvanced: isAdvanced,
        isTextSort: isTextSort,
        arraySortParam: arraySortParam
      });
    });
  }

  sortCalcTasks(
    calcTasks: CalculatedTask[],
    activeSort: {
      param: string;
      direction: 'desc' | 'asc';
      isAdvanced?: boolean;
      isTextSort?: boolean;
      arraySortParam?: string;
    }
  ) {
    if (!activeSort) {
      activeSort = {
        param: 'title',
        direction: 'desc',
        isTextSort: true
      };
    }
    const infin = activeSort.direction === 'asc' ? -Infinity : Infinity;
    let result;
    result = activeSort.isAdvanced
      ? advancedSort(infin, calcTasks, activeSort, this.tasksCustomFieldsMap)
      : nonAdvancedSort(infin, calcTasks, activeSort);
    return activeSort.direction === 'desc' ? result.reverse() : result;
  }

  getICEScore(taskFactors) {
    const result = taskFactors.iceImpact * taskFactors.iceConfidence * taskFactors.iceEase;
    return Number.isFinite(result) ? result : '?';
  }

  getRICEScore(taskFactors) {
    const result =
      taskFactors.riceReach * taskFactors.riceImpact * (taskFactors.riceConfidence / 100) / taskFactors.riceEffort;
    return Number.isFinite(result) ? Math.round(result) : '?';
  }

  getTaskAdvancedScore(calcTask: CalculatedTask, sepCrs: SeparatedCriteria, boardWeights: BoardWeights) {
    const values = sepCrs[ScoringFactor.Value].reduce((acc, item) => {
      if (calcTask.scoringValuesMap[item.id] && Number.isFinite(calcTask.scoringValuesMap[item.id].value)) {
        acc += item.scoringDirection * (item.weight / 100) * calcTask.scoringValuesMap[item.id].value;
      } else {
        return NaN;
      }
      return acc;
    }, 0);
    const efforts = sepCrs[ScoringFactor.Efforts].reduce((acc, item) => {
      if (calcTask.scoringValuesMap[item.id]) {
        acc += item.scoringDirection * (item.weight / 100) * calcTask.scoringValuesMap[item.id].value;
      } else {
        return NaN;
      }
      return acc;
    }, 0);
    const score = Math.round(
      (values * (boardWeights.valueWeight / 100) + efforts * (boardWeights.effortsWeight / 100)) *
        (calcTask.confidence / 100)
    );
    return Number.isFinite(score) ? score : '?';
  }

  getScoringValuesMap(task) {
    return task.scoringValues.reduce((acc, item) => {
      if (item && item.criterion) {
        acc[item.criterion] = {
          value: item.value,
          visibleValue: this.crsMap[item.criterion]
            ? getVisibleScoringVal(item.value, this.crsMap[item.criterion].dataType)
            : '?'
        };
      }
      return acc;
    }, {});
  }

  resetSort() {
    this.activeSort$.next('');
  }

  onChangeFilter(event) {
    this.filterValue$.next(event.currentTarget.value);
  }

  onResetFilter(input) {
    if (input) {
      input.value = '';
      input.focus();
    }
    this.filterValue$.next('');
  }

  onTaskRowClick(event) {
    this.onSaveTaskTitle();
    if (event.target.parentElement && event.target.parentElement.dataset.hasOwnProperty('task')) {
      const taskId = +event.target.parentElement.dataset.task;
      if (!this.isNotGuest) {
        this._routerNav.navigateToTask(taskId);
        return;
      }
      if (event.target.dataset.hasOwnProperty('factor') || event.target.dataset.hasOwnProperty('criterion')) {
        combineLatest([this.tasks$, this.type$])
          .pipe(take(1))
          .subscribe(([tasks, type]: any[]) => {
            const taskToEdit = tasks.filter(item => item.id === taskId)[0];
            if (taskToEdit) {
              this.editingTask$.next(taskToEdit);
              switch (type) {
                case ScoringType.ICE:
                case ScoringType.RICE: {
                  this.editingFactor$.next(event.target.dataset.factor);
                  break;
                }
                case ScoringType.advanced: {
                  const cr =
                    event.target.dataset.criterion === 'confidence' ? 'confidence' : +event.target.dataset.criterion;
                  this.editingCriterion$.next(cr);
                  break;
                }
                default: {
                  this.editingAxis$.next(event.target.dataset.factor === 'backlogScoreX' ? 'x' : 'y');
                  break;
                }
              }
              this.isScoreEditing$.next(true);
            }
          });
      } else if (event.target.dataset.hasOwnProperty('field')) {
        this.tasks$.pipe(take(1)).subscribe(tasks => {
          const taskToEdit = tasks.filter(item => item.id === taskId)[0];
          if (taskToEdit) {
            this.editingTask$.next(taskToEdit);
            switch (event.target.dataset.field) {
              case 'users': {
                this.taskUsers$ = this._store.select(getTaskUsers(taskId)).pipe(map(naturalSort('fullname')));
                this.possibleUsers$ = this._store.select(getTaskPossibleUsers(taskId));
                this.projectOnlyUsers$ = this._store.select(getTaskProjectOnlyUsers(taskId));

                this.isMembersPopupVisible$.next(true);
                break;
              }
              case 'labels': {
                this.isLabelsPopupVisible$.next(true);
                break;
              }
              case 'version': {
                this.boardVersions$ = this._store
                  .select(getVersionsListByBoardId(taskToEdit.board))
                  .pipe(
                    map(versions =>
                      naturalSort('fullname')(versions.filter(v => !v.released || v.id === taskToEdit.version))
                    )
                  );
                this.isVersionPopupVisible$.next(true);
                break;
              }
              case 'startDate': {
                this.isStartDatePopupVisible$.next(true);
                break;
              }
              case 'dueDate': {
                this.isDueDatePopupVisible$.next(true);
                break;
              }
              case 'column': {
                this.isColumnPopupVisible$.next(true);
                break;
              }
              case 'swimlane': {
                this.isColumnPopupVisible$.next(true);
                break;
              }
            }
          }
        });
      } else if (event.target.dataset.hasOwnProperty('customField')) {
        this.editCustomFieldTaskId$.next(taskId);
        this.tasksCustomFieldClientRect = event.target.getBoundingClientRect();
        const cfId = +event.target.dataset['customField'];
        const values = this.tasksCustomFieldsMap[cfId][taskId];
        this.editingCustomFieldValue = {
          task: taskId,
          customField: cfId,
          value: values ? values.value + '' : ''
        };
        this.editingCustomField = this.customFieldsMap[cfId];
      }
    }
  }

  onTitleClick(event, id, title) {
    event.stopPropagation();
    if (!this.isNotGuest) {
      this._routerNav.navigateToTask(id);
      return;
    }
    this._globalHotKeys.isIgnoreKeyBoardEvents = true;
    if (this.editingTitle.id && this.editingTitle.title.trim() !== this.editingTitle.originalTitle.trim()) {
      this._store.dispatch(new TaskEditAction({ id: this.editingTitle.id, title: this.editingTitle.title }));
    }
    this.editingTitle.height = event.target.getBoundingClientRect().height;
    this.editingTitle.id = id;
    this.editingTitle.title = title;
    this.editingTitle.originalTitle = title;
  }

  onOpenQuickActions(event, taskId) {
    event.stopPropagation();
    this._store.dispatch(
      new GuiStateQuickTaskEditShow({
        id: taskId,
        rect: event.currentTarget.parentElement.getBoundingClientRect(),
        isExactRect: true
      })
    );
  }

  onOpenDeleteConfirm(e, task) {
    e.preventDefault();
    e.stopPropagation();
    this.taskToDelete = task;
  }

  onDeleteTask() {
    this._store.dispatch(new TaskDeleteAction(this.taskToDelete));
    this.onCloseDeletePopup();
  }

  onCloseDeletePopup() {
    this.taskToDelete = null;
  }

  onComplexCellClick(event) {
    event.stopPropagation();
    const newEvent = { ...event };
    newEvent.target = event.currentTarget;
    this.onTaskRowClick(newEvent);
  }

  onCloseEditingScoring() {
    this.isScoreEditing$.next(false);
    this.editingTask$.next(null);
    this.editingCriterion$.next(null);
    this.editingFactor$.next(null);
  }

  onCloseMembersPopup() {
    this.isMembersPopupVisible$.next(false);
  }

  onCloseLabelsPopup() {
    this.isLabelsPopupVisible$.next(false);
  }

  onCloseVersionPopup() {
    this.isVersionPopupVisible$.next(false);
  }

  onCloseStartDatePopup() {
    this.isStartDatePopupVisible$.next(false);
  }

  onCloseDueDatePopup() {
    this.isDueDatePopupVisible$.next(false);
  }

  onCloseColumnPopup() {
    this.isColumnPopupVisible$.next(false);
  }

  onCloseCustomFieldPopup() {
    this.editCustomFieldTaskId$.next(0);
  }

  highlightScrolledTask(id, element) {
    this._renderer.addClass(element, 'scrollHighlightClass');
    this.higlightTimeout = setTimeout(() => {
      const el = document.querySelector('[data-task-id="' + id + '"]');
      if (el) {
        this._renderer.removeClass(el, 'scrollHighlightClass');
      }
    }, 3000);
  }

  onUpdateUsersList(userListUpdate) {
    if (!this.isNotGuest) {
      return;
    }
    const task = this.editingTask$.value;
    if (task) {
      this._store.dispatch(new TaskAssignUsersAction({ id: task.id, users: userListUpdate }));
    }
  }

  onAddUserToBoard(user: User) {
    if (!this.isNotGuest) {
      return;
    }
    const task = this.editingTask$.value;
    if (task) {
      this._store.dispatch(new BoardAssignUsersAction({ id: task.board, users: { add: [user.id] } }));
    }
  }

  onSaveTaskTitle() {
    if (!this.isNotGuest) {
      return;
    }
    if (this.editingTitle.id) {
      if (this.editingTitle.title.trim() !== this.editingTitle.originalTitle.trim()) {
        this._store.dispatch(new TaskEditAction({ id: this.editingTitle.id, title: this.editingTitle.title }));
      }
      this.onCancelTitleEdit();
    }
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  onCancelTitleEdit() {
    this._globalHotKeys.isIgnoreKeyBoardEvents = false;
    this.editingTitle.id = NaN;
    this.editingTitle.title = '';
    this.editingTitle.originalTitle = '';
    this.editingTitle.height = NaN;
  }

  openNewTaskForm() {
    if (!this.isNotGuest) {
      return;
    }
    this.showNewTaskForm$.next(true);
  }

  closeNewTaskForm() {
    this.showNewTaskForm$.next(false);
  }

  toggleBatchEdit() {
    this.isBatchEdit = !this.isBatchEdit;
    if (!this.isBatchEdit) {
      this.selectedTasksMap = {};
      this._store.dispatch(new GuiStateBatchEditHide());
    }
  }

  toggleTaskSelection(event, id) {
    event.stopPropagation();
    event.preventDefault();
    this.selectedTasksMap[id] = !this.selectedTasksMap[id];
    const ids = Object.keys(this.selectedTasksMap)
      .filter(item => this.selectedTasksMap[item])
      .map(item => +item);
    if (ids.length > 100) {
      this.selectedTasksMap[id] = false;
      return;
    }
    if (ids.length) {
      const left = event.target.parentElement.parentElement.getBoundingClientRect().right;
      const top = document.getElementsByTagName('board-table-view')[0].getBoundingClientRect().top;
      this._store.dispatch(new GuiStateBatchEditShow({ ids, left, top }));
    } else {
      this._store.dispatch(new GuiStateBatchEditHide());
    }
  }

  ngOnDestroy() {
    this._store.dispatch(new GuiStateBatchEditHide());
    this._globalHotKeys.isIgnoreKeyBoardEvents = false;
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
    if (this.higlightTimeout) {
      clearTimeout(this.higlightTimeout);
    }
  }
}
