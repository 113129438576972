
import {never as observableNever,  Observable } from 'rxjs';

import {catchError, take, map, distinctUntilChanged, pluck, filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { AuthService } from '../../shared/services/app/auth.service';
import { AppState } from '../state/';
import { Store } from '@ngrx/store';
import { getBrowsersTimeZoneName, isNotPresent, isPresent } from '../../../helpers';
import { PatchEntityAction } from '../actions/root.action';
import { USER_PL } from '../../constants';

@Injectable()
export class ActiveUserEffects {
  @Effect()
  storingTimeZone$ = this._authService.activeUser$.pipe(
    filter(isPresent),
    pluck('timezone'),
    distinctUntilChanged(),
    filter(isNotPresent),
    map(getBrowsersTimeZoneName),
    filter(isPresent),
    take(1),
    map(
      timezone =>
        new PatchEntityAction({
          entityName: USER_PL,
          data: {
            id: this._authService.activeUserId,
            timezone
          }
        })
    ),
    catchError(err => {
      console.warn('failed to store users timezone', err);
      return observableNever();
    }),);

  constructor(private _store: Store<AppState>, private _authService: AuthService) {}
}
