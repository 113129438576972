import { filter, distinctUntilChanged } from 'rxjs/operators';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { Task } from '../../../interfaces';
import { getTaskUrl } from '../../../ngrx/reducers/task.reducer';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { TaskEditAction } from '../../../ngrx/actions/task.actions';
import { BacklogInplaceEditComponent } from '../backlog-inplace-edit/backlog-inplace-edit.component';
import { BacklogChartService } from '../backlog-chart.service';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';

@Component({
  selector: 'task-list-item',
  templateUrl: './task-list-item.component.html',
  styleUrls: ['./task-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskListItemComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() task: Task;
  @Input() draggable: 0 | 1 = 1;
  @Input() itemPoints = false;
  @Input() scaleTypeX;
  @Input() scaleTypeY;

  @ViewChild('backlog_score_x_inplace') inplaceScoreX: BacklogInplaceEditComponent;
  @HostBinding('class.edited') isEdited = false;

  public taskUrl$;
  public scoreYField = 'backlogScoreY';
  public scoreXField = 'backlogScoreX';
  public higlightTimeout;

  constructor(
    private _store: Store<AppState>,
    private _renderer: Renderer2,
    private _elR: ElementRef,
    private _backLogChartService: BacklogChartService
  ) {}

  ngOnInit() {
    this.taskUrl$ = this._store.pipe(getTaskUrl(this.task));
  }

  ngAfterViewInit() {
    this._backLogChartService.lastCreatedByMeTaskId$
      .pipe(distinctUntilChanged(), filter(id => id === this.task.id))
      .subscribe(_ => {
        scrollIntoViewIfNeeded(this._elR.nativeElement, {
          duration: 300,
          boundary: this._elR.nativeElement.parentNode
        });
        this.highlightScrolledTask();
        this._backLogChartService.lastCreatedByMeTaskId$.next(0);
      });
  }

  ngOnDestroy() {
    if (this.higlightTimeout) {
      clearTimeout(this.higlightTimeout);
    }
  }

  onSaveBacklogValue(inplaceValue: number, fieldName: string) {
    this.inplaceScoreX.onEditToggle();
    this.onSaveNewValues(inplaceValue, fieldName);
  }

  onSaveNewValues(inplaceValue: number, fieldName: string) {
    const taskEditData = {
      id: this.task.id,
      [this.scoreYField]: fieldName === this.scoreYField ? inplaceValue : this.task.backlogScoreY,
      [this.scoreXField]: fieldName === this.scoreXField ? inplaceValue : this.task.backlogScoreX
    };

    if (!this.isEdited) {
      this.isEdited = true;
    }
    this.inplaceScoreX.scrollToView();
    this._store.dispatch(new TaskEditAction(taskEditData));
    this.inplaceScoreX.detectChanges();
    // remove class edited from host on delay of 2 seconds
    setTimeout(_ => {
      this.isEdited = false;
      console.log(this.isEdited, 'EDITED');
    }, 2000);
  }

  highlightScrolledTask() {
    this._renderer.addClass(this._elR.nativeElement, 'scrollHighlightClass');
    this.higlightTimeout = setTimeout(() => {
      this._renderer.removeClass(this._elR.nativeElement, 'scrollHighlightClass');
    }, 3000);
  }
}
