import { Injectable } from '@angular/core';
import { AtlazApiV2Service } from './atlaz-api/v2/atlaz-api-v2.service';
import { Observable } from 'rxjs';
@Injectable()
export class WorklogService {
  constructor(private apiV2: AtlazApiV2Service) {}

  addWorklog(taskId: number, loggedTime: number, logDate: number, logComment?: string): Observable<any> {
    const requestParamenters = {
      task: taskId,
      loggedTime: loggedTime,
      logDate,
      logComment: ''
    };

    if (!!logComment) {
      requestParamenters.logComment = logComment;
    }
    return this.apiV2.post('worklogs', requestParamenters);
  }

  editWorklog(logId: number, loggedTime: number, comment: string): Observable<any> {
    const requestParamenters = {
      loggedTime: !!loggedTime ? loggedTime : 0,
      logComment: !!comment ? comment : '',
      id: logId
    };
    return this.apiV2.patch('worklogs', requestParamenters);
  }
}
