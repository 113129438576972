export const NOTIFICATION_TYPES = {
  TYPE_MENTION: 'MENTION',
  TYPE_COMMENT: 'COMMENT',
  TYPE_DUE24H: 'DUE24H',
  TYPE_DUE: 'DUE',
  TYPE_TASK_COLUMN_CHANGE: 'TASK_COLUMN_CHANGE',
  TYPE_TASK_ARCHIVED: 'TASK_ARCHIVED',
  TYPE_TASK_ADD_USER: 'TASK_ADD_USER',

  TYPE_TASK_DESCRIPTION_CHANGE: 'TASK_DESCRIPTION_CHANGE',
  TYPE_TASK_MEMBERS_DELETE_ME: 'TASK_MEMBERS_DELETE_ME',
  TYPE_TASK_CHECKLISTS_ITEMS_ADDED: 'TASK_CHECKLISTS_ITEMS_ADDED',
  TYPE_TASK_CHECKLISTS_ITEMS_CHANGED: 'TASK_CHECKLISTS_ITEMS_CHANGED',
  TYPE_TASK_CHECKLISTS_ITEMS_REMOVED: 'TASK_CHECKLISTS_ITEMS_REMOVED',
  TYPE_TASK_ATTACHMENTS_ADDED: 'TASK_ATTACHMENTS_ADDED',
  TYPE_PROJECT_ADDED_ME: 'PROJECT_ADDED_ME',
  TYPE_PROJECT_REMOVED_ME: 'PROJECT_REMOVED_ME',
  TYPE_BOARD_ADDED_ME: 'BOARD_ADDED_ME',
  TYPE_BOARD_REMOVED_ME: 'BOARD_REMOVED_ME'
};
