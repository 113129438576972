import { empty as observableEmpty } from 'rxjs';

import { catchError, distinctUntilChanged, map, pluck, share, switchMap, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { GetCompleteAction } from '../actions/root.action';
import { GlobalSearchActionTypes, GlobalSearchHandleResponseAction } from '../actions/global-search.actions';
import { AppState, ESInterface } from '../state';
import { Store } from '@ngrx/store';
import { Task } from '../../interfaces';
import { ASC, SearchStateInterface } from '../reducers/global-search.reducer';

import * as moment from 'moment-mini-ts';
import { defaultErrorHandler } from './root.effect';
import { RouterNavigateService } from '../../shared/services/router-navigate.service';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { JsonApiSingeModelResponse, jsonApiToEntityState } from '../../shared/services/app/web-socket/http-response';
import { TaskModel } from '../../shared/services/app/models/task';
import { SEARCH } from '../../path.routing';
import { defaultExpand } from '../../constants';

interface SearchRequestParams {
  limit: number;
  offset: number;
  q?: string;
  filterParams?: {};
  replaceResult: boolean;
  expand?: string;
}

export const ALL = 'all';
export const ANY = 'any';
export const NONE = 'none';
export const TODAY = 'today';
export const DAYS7 = '7day';
export const DAYS30 = '30day';
export const OVERDUE = 'overDue';

@Injectable()
export class GlobalSearchEffects {
  @Effect()
  loadMore$ = this.actions$.ofType(GlobalSearchActionTypes.LOAD_MORE).pipe(
    switchMap(({ type, payload }: { type: string; payload: any }) => {
      let lastSearchState: SearchStateInterface;
      this._store
        .pipe(pluck('search'), distinctUntilChanged())
        .subscribe((state: SearchStateInterface) => (lastSearchState = state))
        .unsubscribe();

      // if we can't load more, we won't do this!
      if (lastSearchState.nextOffset > lastSearchState.count) {
        return observableEmpty();
      }

      const params = {
        q: lastSearchState.searchQuery,
        limit: 9,
        offset: lastSearchState.nextOffset,
        replaceResult: false,
        filterParams: lastSearchState.filterParams,
        sort: lastSearchState.sortParams
      };
      return this.sendSearchRequest(params).pipe(catchError(defaultErrorHandler(type, payload)));
    })
  );

  @Effect()
  startSearch$ = this.actions$.ofType(GlobalSearchActionTypes.START_SEARCH).pipe(
    switchMap(({ type, payload }: { type: string; payload: any }) => {
      console.log('startSearch$', payload);
      if (payload['searchQuery'].length > 0 || (payload.filterParams && payload.filterParams.isEmptyQAvailable)) {
        const params = {
          limit: 9,
          offset: 0,
          replaceResult: true,
          filterParams: payload.filterParams,
          sort: payload.sortParams
        };
        if (payload['searchQuery'].length > 0) {
          params['q'] = payload.searchQuery;
        }
        return this.sendSearchRequest(params).pipe(catchError(defaultErrorHandler(type, payload)));
      }
      this._store.dispatch(
        new GlobalSearchHandleResponseAction({
          resultIds: [],
          count: 0,
          nextOffset: 0,
          replaceResult: true
        })
      );
      return observableEmpty();
    })
  );

  @Effect({ dispatch: false })
  searchResponse$ = this.actions$.ofType(GlobalSearchActionTypes.HANDLE_SEARCH_RESPONSE).pipe(share());

  @Effect({ dispatch: false })
  goBack$ = this.actions$
    .ofType(GlobalSearchActionTypes.STOP_SEARCH_AND_GO_BACK)
    .pipe(
      switchMap(_ =>
        this._store.pipe(
          pluck('search', 'backUrl'),
          take(1),
          map((backUrl: string) => (backUrl && backUrl.length > 0 ? backUrl : '/')),
          tap(backUrl => this._routerNav.navigateByUrl(backUrl))
        )
      )
    );

  constructor(
    private actions$: Actions,
    private _store: Store<AppState>,
    private _routerNav: RouterNavigateService,
    private _apiV2: AtlazApiV2Service
  ) {}

  private filterParamsToRequestParams(filterParams) {
    const params = {};
    ['scoreMin', 'scoreMax', 'projects', 'boards', 'users', 'groups', 'doneDateStart', 'doneDateEnd'].forEach(
      paramName => {
        if (filterParams[paramName]) {
          params[paramName] = filterParams[paramName];
        }
      }
    );

    if (filterParams['status']) {
      params['statuses'] = Array.isArray(filterParams['status'])
        ? filterParams['status']
        : filterParams['status'].split(',');
    }

    if (filterParams['hideArchived']) {
      params['archived'] = '0';
    }

    if (filterParams['hideReleased']) {
      params['released'] = '0';
    }

    if (filterParams['version']) {
      params['versions[]'] = filterParams['version'];
    }

    if (filterParams['creator']) {
      params['creator'] = filterParams['creator'];
    }

    switch (filterParams['dueDate']) {
      case NONE: {
        params['dueDateStart'] = 0;
        params['dueDateEnd'] = 0;
        break;
      }
      case TODAY: {
        params['dueDateStart'] = moment()
          .utc()
          .startOf('day')
          .format('X');
        params['dueDateEnd'] = moment()
          .utc()
          .endOf('day')
          .format('X');
        break;
      }
      case DAYS7: {
        params['dueDateStart'] = moment()
          .utc()
          .startOf('day')
          .format('X');
        params['dueDateEnd'] = moment()
          .utc()
          .add(7, 'days')
          .endOf('day')
          .format('X');
        break;
      }
      case DAYS30: {
        params['dueDateStart'] = moment()
          .utc()
          .startOf('day')
          .format('X');
        params['dueDateEnd'] = moment()
          .utc()
          .add(30, 'days')
          .endOf('day')
          .format('X');
        break;
      }
      case OVERDUE: {
        params['overdue'] = 1;
        break;
      }
      case ANY: {
        params['dueDateStart'] = 1;
        params['dueDateEnd'] = moment()
          .utc()
          .add(5000, 'days')
          .endOf('day')
          .format('X');
        break;
      }
      default: {
      }
    }
    console.log('params', params);
    return Object.keys(params).reduce((acc, key) => {
      if (
        (params[key] && !Array.isArray(params[key])) ||
        (params[key] && Array.isArray(params[key]) && params[key].length > 0)
      ) {
        acc[key] = params[key];
      }
      return acc;
    }, {});
  }

  private sendSearchRequest(params: SearchRequestParams) {
    let httpParams = {};
    httpParams = Object.assign(httpParams, this.filterParamsToRequestParams(params.filterParams), {
      limit: params.limit,
      offset: params.offset,
      expand: defaultExpand[SEARCH]
    });
    if (params.q) {
      httpParams['q'] = params.q;
    }

    if (params['sort']['sortParam']) {
      httpParams['sort'] = (params['sort']['sortOrder'] === ASC ? '' : '-') + params['sort']['sortParam'];
    }

    return this._apiV2.get(SEARCH, httpParams).pipe(
      map((response: JsonApiSingeModelResponse<TaskModel>) => {
        const entities = <{ [id: string]: ESInterface<Task> }>jsonApiToEntityState(response);

        this._store.dispatch(new GetCompleteAction(entities));
        const resultIds = entities['tasks'] ? entities['tasks'].ids : [];
        return new GlobalSearchHandleResponseAction({
          resultIds: resultIds,
          count: response.meta.total,
          nextOffset: params.offset + params.limit,
          replaceResult: params.replaceResult
        });
      })
    );
  }
}
