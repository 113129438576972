
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as component from '../actions/component.actions';
import { setToLocalStorage } from '../../../helpers/index';
import { Action } from '../actions/unsafe-action';

@Injectable()
export class ComponentEffects {
  @Effect({ dispatch: false })
  saveSticky = this._actions$
    .ofType(component.STICKY_LEFT_MENU).pipe(
    map((action: Action) => setToLocalStorage(component.STICKY_LEFT_MENU_FEATURE)(action.payload)));

  constructor(private _actions$: Actions) {}
}
