import { combineLatest as observableCombineLatest, Observable, Subscription } from 'rxjs';

import { filter, map, pluck, startWith, switchMap } from 'rxjs/operators';
import { getColumnById } from '../../../ngrx/reducers/column.reducer';
import { getTaskBreadCrumbs } from '../../../../helpers/task';
import { isSubColumn } from '../../../../helpers/column';
import { isPresent } from '../../../../helpers';
import { KeyCode } from '../../../constants';
import { AppState } from '../../../ngrx/state';
import { HotKey } from '../../../shared/directives';
import { AppUrls } from '../../../app-urls';
import { Board, Column, Task } from '../../../interfaces';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { getBoardById } from '../../../ngrx/reducers/board.reducer';
import { getSwimlaneById } from '../../../ngrx/reducers/swimlane.reducer';
import { isNil } from 'ramda';
import { setFocusToFocusableParent } from '../../../../helpers/event';

@Component({
  selector: 'task-title-inplace-edit',
  templateUrl: './task-title-inplace-edit.component.html',
  styleUrls: ['./task-title-inplace-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskTitleInplaceEditComponent implements OnChanges, OnDestroy {
  @Input() task: Task;
  @Input() submitOnEnter = false;
  @Input() initialValue: string;
  @Input() editPermissions = false;
  @Input() isOnNoBoardPage: boolean;

  @Output() formSubmit: EventEmitter<string> = new EventEmitter<string>();
  @Output() resetFocus: EventEmitter<any> = new EventEmitter();

  public inlineForm: FormGroup;
  public isEdit = false;
  public taskBreadCrumbs$: Observable<string>;
  public subs: Subscription[] = [];
  public appUrls = AppUrls;

  constructor(private _fb: FormBuilder, private _store: Store<AppState>, private _elRef: ElementRef) {}

  get hotKeys(): HotKey[] | boolean {
    return this.submitOnEnter ? [{ keyCode: KeyCode.KEY_ENTER }, { keyCode: KeyCode.KEY_TAB }] : false;
  }

  onHotKey() {
    if (this.submitOnEnter) {
      this.onSaveChanges();
    }
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
    this.onSaveChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('task') && this.task) {
      const board$ = <Observable<Board>>this._store.pipe(getBoardById(this.task.board));

      const swimlane$ = this._store.pipe(getSwimlaneById(this.task.swimlane));

      const mapToColumnById = id => <Observable<Column>>this._store.pipe(getColumnById(id));

      const column$ = mapToColumnById(this.task.column);

      const parentColumn$ = column$.pipe(
        filter(isPresent),
        filter(isSubColumn),
        pluck('parent'),
        switchMap(mapToColumnById)
      );

      /**
       * T - column
       * T2 - SubColumn
       */
      const columnComposite$ = observableCombineLatest(
        parentColumn$.pipe(startWith(null)),
        column$,
        (parentColumn, taskColumn) => (isPresent(parentColumn) ? [parentColumn, taskColumn] : [taskColumn, null])
      );

      this.taskBreadCrumbs$ = observableCombineLatest(
        board$,
        swimlane$.pipe(startWith(null)),
        columnComposite$.pipe(map(composite => composite[0])),
        columnComposite$.pipe(map(composite => composite[1]))
      ).pipe(
        filter(([board, swimlane, column, subColumn]) => [board, swimlane, column].every(isPresent)),
        map(
          ([board, swimlane, column, subColumn]) =>
            isNil(board.swimlanesIds) ? '' : getTaskBreadCrumbs(this.task, board, swimlane, column, subColumn)
        )
      );
    }

    this.initForm();
  }

  initForm() {
    const value = this.initialValue;
    this.inlineForm = this._fb.group({
      name: [value, Validators.compose([Validators.required, Validators.maxLength(1000)])]
    });
  }

  onEditToggle() {
    if (!this.editPermissions) {
      return false;
    }
    if (this.isEdit && this._elRef && this._elRef.nativeElement) {
      setFocusToFocusableParent(this._elRef.nativeElement);
    }
    this.isEdit = !this.isEdit;
  }

  onSaveChanges() {
    const newValue = this.inlineForm.get('name').value.trim();

    if (!this.inlineForm.valid || newValue === '' || newValue === this.initialValue) {
      this.inlineForm.setValue({ name: this.initialValue });
      this.onEditToggle();
      return false;
    }

    this.isEdit = newValue === this.initialValue;
    if (!this.isEdit && this._elRef && this._elRef.nativeElement) {
      setFocusToFocusableParent(this._elRef.nativeElement);
    }
    this.formSubmit.emit(newValue);
  }
}
