import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { compose } from '@ngrx/store';
import { Action } from '../../actions/unsafe-action';
import { QuickFilter, Task } from '../../../interfaces';
import { boardType, ScoringType } from '../../../constants';
import { share } from '../../functions/util';
import { getEntitySelector } from '../../functions/selectors';
import { filterDuplicateItem, getActiveUserId } from '../../../../helpers';
import { DEFAULT_FILTER_STATE } from '../../state';
import { TaskDefaultFilterActionTypes } from '../../actions/task-filters/task-default-filter.actions';
import * as moment from 'moment-mini-ts';
import { BoardViewMode } from '../gui-state-memorized.reducer';

export const FILTER_OVERDUE = 'Overdue Tasks';
export const FILTER_USER = 'My Tasks';
export const FILTER_NOTIFICATIONS = 'Notifications';
export const FILTER_RECENTLY_UPDATED = 'Recently Updated';
export const FILTER_LABELS = 'Labels';
export const FILTER_MEMBERS = 'Members';
export const FILTER_COLUMNS = 'Columns';
export const FILTER_SWIMLANES = 'Swimlanes';
export const FILTER_VERSIONS = 'Versions';
export const FILTER_PROJECTS = 'Projects';
export const FILTER_STATUSES = 'Statuses';
export const FILTER_COLORS = 'Colors';
export const FILTER_QUADS = 'Quadrants';
export const FILTER_TIME_ON_BOARD = 'Time on board';
export const FILTER_TIME_ON_COLUMN = 'Time in column';

export const SECONDS_IN_ONE_DAY = 86400;

/**
 * ATLAZ-1549 https://hq.atlaz.io/boards/1(popup:tasks/62066)
 * @deprecated use No Value no no Efforts filter
 * @type {string}
 */
export const FILTER_NO_SCORE = 'No Score';
export const FILTER_NO_EFFORTS = 'No Efforts';
export const FILTER_NO_VALUE = 'No Value';

const memberFilterBuilder = (membersIds: number[]) => {
  return (task: Task) => task.usersIds.filter(userId => membersIds.includes(userId)).length > 0;
};

/**
 * @deprecated notifications filter
 * https://a.atlaz.io/b/46573/t/ATLAZ-6544
 */
const notificationsFilterBuilder = () => {
  return (task: Task, notifications) => {
    if (notifications) {
      return notifications.hasOwnProperty(task.id);
    }
  };
};

const recentlyUpdatedFilterBuilder = () => {
  return (task: Task) => {
    const currentTime = +moment().unix();
    const filterStartTime = currentTime - SECONDS_IN_ONE_DAY;
    return task.updatedAt >= filterStartTime;
  };
};

const userFilterBuilder = (userId: number) => {
  return (task: Task) => {
    if (task.usersIds) {
      return task.usersIds.includes(userId);
    }
  };
};

const overdueFilterBuilder = () => {
  const currentDate = Math.round(Date.now() / 1000);

  return (task: Task) => task.doneDate === 0 && task.dueDate !== 0 && task.dueDate <= currentDate;
};

/**
 * @deprecated use No Value and No Efforts filter
 * @returns {(task:Task)=>boolean}
 */
const noScoreFilterBuilder = () => {
  return (task: Task) => !task.backlogScoreX || !task.backlogScoreY;
};

const noEffortsFilterBuilder = () => {
  return (task: Task) => !task.backlogScoreX;
};

const noValueFilterBuilder = () => {
  return (task: Task) => !task.backlogScoreY;
};

function quickFilterBuilder(quickFilter: QuickFilter) {
  const quickFilterMembers = quickFilter.fields.members;

  return (task: Task) => task.usersIds.findIndex(userId => quickFilterMembers.includes(userId)) >= 0;
}

const filtersOrder = {
  [FILTER_RECENTLY_UPDATED]: 1,
  [FILTER_USER]: 5,
  [FILTER_OVERDUE]: 10,
  [FILTER_NO_VALUE]: 10,
  [FILTER_NO_EFFORTS]: 11,
  [FILTER_COLUMNS]: 15,
  [FILTER_SWIMLANES]: 20,
  [FILTER_PROJECTS]: 24,
  [FILTER_VERSIONS]: 25,
  [FILTER_MEMBERS]: 50,
  [FILTER_LABELS]: 55,
  [FILTER_STATUSES]: 60,
  [FILTER_COLORS]: 65,
  [FILTER_QUADS]: 70,
  [FILTER_TIME_ON_BOARD]: 75,
  [FILTER_TIME_ON_COLUMN]: 80
};

const sortFilters = ar => ar.sort((a, b) => (filtersOrder[a] || 0) - (filtersOrder[b] || 0));

const globalFilters = [FILTER_RECENTLY_UPDATED, FILTER_USER, FILTER_MEMBERS, FILTER_LABELS];

export const filtersByBoard = {
  [boardType.kanban]: [FILTER_OVERDUE, FILTER_PROJECTS],
  [boardType.roadmap]: [
    FILTER_STATUSES,
    FILTER_OVERDUE,
    FILTER_COLORS,
    FILTER_PROJECTS
  ],
  [boardType.sprint]: [],
  [boardType.list]: [FILTER_STATUSES]
};

const filtersByScoring = {
  [ScoringType.off]: [],
  [ScoringType.basic]: [FILTER_QUADS],
  [ScoringType.advanced]: [],
  [ScoringType.ICE]: [],
  [ScoringType.RICE]: []
};

export const filtersByView = {
  [BoardViewMode.board]: [FILTER_VERSIONS],
  [BoardViewMode.table]: [FILTER_COLUMNS, FILTER_SWIMLANES, FILTER_VERSIONS],
  [BoardViewMode.version]: [FILTER_COLUMNS, FILTER_SWIMLANES],
  [BoardViewMode.priorityChart]: [FILTER_COLUMNS, FILTER_SWIMLANES, FILTER_STATUSES, FILTER_VERSIONS]
};

export const filtersForbiddenForBoard = {
  [boardType.roadmap]: [FILTER_COLUMNS],
  [boardType.sprint]: [FILTER_VERSIONS]
};

export const UncustomizableDefaultFilters = [
  FILTER_RECENTLY_UPDATED,
  FILTER_OVERDUE,
  FILTER_USER,
  FILTER_NO_VALUE,
  FILTER_NO_EFFORTS
];

interface TaskDefaultFilters {
  ids: string[];
  activeIds: string[];
  filters: {};
}

const getInitialState = (): TaskDefaultFilters => {
  return {
    ids: sortFilters([...globalFilters]),
    activeIds: [],
    filters: {
      [FILTER_RECENTLY_UPDATED]: recentlyUpdatedFilterBuilder(),
      [FILTER_OVERDUE]: overdueFilterBuilder(),
      [FILTER_USER]: userFilterBuilder(getActiveUserId()),
      [FILTER_NO_VALUE]: noValueFilterBuilder(),
      [FILTER_NO_EFFORTS]: noEffortsFilterBuilder()
    }
  };
};

export function reducer(state = getInitialState(), action: Action): TaskDefaultFilters {
  switch (action.type) {
    case TaskDefaultFilterActionTypes.INIT: {
      const { boardType, scoringType, boardView } = action.payload;
      let additionalFilters = [
        ...(filtersByBoard[boardType] || []),
        ...(filtersByScoring[scoringType] || []),
        ...(filtersByView[boardView] || [])
      ].filter(filterDuplicateItem);
      if (filtersForbiddenForBoard[boardType]) {
        additionalFilters = additionalFilters.filter(item => !filtersForbiddenForBoard[boardType].includes(item));
      }
      const filterIDS = sortFilters([...globalFilters, ...additionalFilters]);

      return {
        ids: filterIDS,
        activeIds: state.activeIds,
        filters: state.filters
      };
    }

    case TaskDefaultFilterActionTypes.ADD_FILTER: {
      const { filterID } = action.payload;
      if (state.activeIds.includes(filterID)) {
        return state;
      } else {
        return {
          ids: state.ids,
          activeIds: [...state.activeIds, filterID],
          filters: state.filters
        };
      }
    }

    case TaskDefaultFilterActionTypes.REMOVE_FILTER: {
      const { filterID } = action.payload;

      return {
        ids: state.ids,
        activeIds: state.activeIds.filter(filterId => filterId !== filterID),
        filters: state.filters
      };
    }

    case TaskDefaultFilterActionTypes.TOGGLE: {
      const { filterID } = action.payload;

      const activeIds =
        state.activeIds.indexOf(filterID) > -1
          ? state.activeIds.filter(id => id !== filterID)
          : [...state.activeIds, filterID];

      return {
        ids: state.ids,
        activeIds: activeIds,
        filters: state.filters
      };
    }

    case TaskDefaultFilterActionTypes.RESET: {
      return getInitialState();
    }

    case TaskDefaultFilterActionTypes.SET: {
      const boardType = action.payload.type;
      const scoringType = action.payload.scoringType;
      const boardView = action.payload.boardView;
      const activeFilters = action.payload.activeFilters || {};
      let additionalFilters = [
        ...(filtersByBoard[boardType] || []),
        ...(filtersByScoring[scoringType] || []),
        ...(filtersByView[boardView] || [])
      ].filter(filterDuplicateItem);
      if (filtersForbiddenForBoard[boardType]) {
        additionalFilters = additionalFilters.filter(item => !filtersForbiddenForBoard[boardType].includes(item));
      }
      const filterIDS = sortFilters([...globalFilters, ...additionalFilters]);

      const filters = (activeFilters.uncustomizable ? [...activeFilters.uncustomizable] : []).filter(
        item => filtersOrder[item] !== undefined
      );

      return {
        ids: filterIDS,
        activeIds: sortFilters(filters),
        filters: state.filters
      };
    }

    default: {
      return state;
    }
  }
}

export const taskDefaultFilter = getEntitySelector(DEFAULT_FILTER_STATE);
export const getTaskDefaultFilterList = share(compose(listSelector, taskDefaultFilter));
export const getTaskDefaultFilterFunctions = share(compose(activeFunctionsSelector, taskDefaultFilter));

function listSelector(state$: Observable<TaskDefaultFilters>): Observable<{ name: string; isActive: boolean }[]> {
  return state$.pipe(
    map((filterState: TaskDefaultFilters) =>
      filterState.ids.map(filterID => ({ name: filterID, isActive: filterState.activeIds.includes(filterID) }))
    )
  );
}

function activeFunctionsSelector(state$: Observable<TaskDefaultFilters>): Observable<any[]> {
  return state$.pipe(
    map((filterState: TaskDefaultFilters) =>
      filterState.activeIds.map(filterID => {
        return { id: filterID, func: filterState.filters[filterID] };
      })
    )
  );
}
