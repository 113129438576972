import { Component, Input } from '@angular/core';
import { Project, Task } from '../../../interfaces';

@Component({
  selector: 'task-title',
  template: `{{taskTitle}}`
})
export class TaskTitleComponent {
  @Input() task: Task;
  @Input() project: Project;
  @Input() boardName: string;
  @Input() columnName: string;

  get taskTitle() {
    let result = '';
    if (this.task) {
      result +=
        (this.boardName ? this.boardName + ' - ' : '') +
        this.task.title +
        (this.columnName ? ' - ' + this.columnName : '') +
        (this.project ? ' - ' + this.project.shortName.toUpperCase() + '-' + this.task.numberInProject : '');
    }
    return result;
  }
}
