
import {tap, switchMap, distinctUntilChanged, combineLatest, map, pluck} from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { STATUS_CODES } from '../../../permissions/interfaces/page-status.interface';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { SprintBoard } from '../../../interfaces';
import { consoleLog, isPresent } from '../../../../helpers';
import { getBoardById } from '../../../ngrx/reducers/board.reducer';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state/';
import { SegmentService } from '../../../atlaz-bnp/services/intergations/segment/segment.service';

@Component({
  templateUrl: './released-sprints-page.component.html',
  styleUrls: ['./released-sprints-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReleasedSprintsPageComponent implements OnInit {
  public isOkStatus$: Observable<boolean>;
  public board$: Observable<SprintBoard>;
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _segmet: SegmentService,
    private _store: Store<AppState>
  ) {}

  ngOnInit() {
    this._segmet.track('ReleasedSprintReportVisited');
    this.board$ = this._activatedRoute.params.pipe(
      pluck('boardId'),
      distinctUntilChanged(),
      switchMap(id => <Observable<SprintBoard>>this._store.pipe((getBoardById(id)))),);
    this.isOkStatus$ = this._activatedRoute.data.pipe(
      tap(consoleLog('pageStatus')),
      pluck('pageStatus', 'statusCode'),
      map(status => STATUS_CODES.OK === status),
      combineLatest(this.board$.pipe(map(isPresent)), (a, b) => a && b),);
  }
}
