import { Entities } from '../../../../ngrx/state/';

export function createMapWithArray<E>(entities: Entities<E>, fieldKey, fieldValue) {
  return (map = {}, item) => {
    const key = entities[item][fieldKey];
    const value = entities[item][fieldValue];
    if (map[key]) {
      map[key] = !map[key].includes(value) ? [...map[key], value] : map[key];
    } else {
      map[key] = [value];
    }
    return map;
  };
}
