import { Directive, HostListener, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/state/app-state';
import { defaultCollectionTypes } from '../../constants/default-collections';
import { DefaultCollectionEditAction } from '../../ngrx/actions/default-collection.actions';

@Directive({
  selector: '[favoriteToggler]'
})
export class FavoriteTogglerDirective {
  @Input() boardId: number;
  @Input() isFavorite: boolean;

  @HostListener('click')
  onToggleFavorite() {
    this.toggleFavorite();
  }

  constructor(private _store: Store<AppState>) {}

  private toggleFavorite() {
    const data = { add: [], remove: [] };
    data[this.isFavorite ? 'remove' : 'add'] = [this.boardId];
    this._store.dispatch(new DefaultCollectionEditAction({ id: defaultCollectionTypes.favorites, boards: data }));
  }
}
