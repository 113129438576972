import { NgModule } from '@angular/core';

import { AtlazGuiModule } from '../../../atlaz-gui/atlaz-gui.module';
import { SharedModule } from '../../../shared/shared.module';
import { GitTaskBlockTitleComponent } from './components/git-task-block-title/git-task-block-title.component';
import { GitTaskCommitComponent } from './components/git-task-commit/git-task-commit.component';
import { GitTaskPullRequestComponent } from './components/git-task-pullrequest/git-task-pullrequest.component';
import { GithubTaskCommitComponent } from './components/github-task-commit-list/github-task-commit-list.component';
import { GithubTaskPullRequestListComponent } from './components/github-task-pull-request-list/github-task-pull-request-list.component';
import { GithubColor } from './pipes/github-color.pipe';
import { BitbucketTaskCommitComponent } from './components/bitbucket-task-commit-list/bitbucket-task-commit-list.component';
import { BitbucketTaskPullRequestListComponent } from './components/bitbucket-task-pull-request-list/bitbucket-task-pull-request-list.component';
import { GitlabTaskCommitComponent } from './components/gitlab-task-commit-list/gitlab-task-commit-list.component';
import { GitlabTaskPullRequestListComponent } from './components/gitlab-task-pull-request-list/gitlab-task-pull-request-list.component';

export const SHARED_COMPONENTS = [
  GithubTaskCommitComponent,
  GithubTaskPullRequestListComponent,
  GitTaskBlockTitleComponent,
  BitbucketTaskCommitComponent,
  BitbucketTaskPullRequestListComponent,
  GitlabTaskCommitComponent,
  GitlabTaskPullRequestListComponent
];

@NgModule({
  imports: [SharedModule, AtlazGuiModule],
  declarations: [...SHARED_COMPONENTS, GitTaskCommitComponent, GitTaskPullRequestComponent, GithubColor],
  exports: [...SHARED_COMPONENTS]
})
export class GitPublicModule {}
