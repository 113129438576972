<div class="wrapper" [routerLink]="taskUrl">
  <div class="task_content">
    <div class="task_status_wrapper">
      <div class="task_status_indicator" [class.active]="task.doneDate" (click)="onCompleteTask($event)">
        <svg>
          <use xlink:href="#check"></use>
        </svg>
      </div>
    </div>
    <div class="field_wrapper title">
      <div class="title_text_wrapper">{{task.title}}</div>
      <div (click)="onEdit($event)">
        <svg>
          <use xlink:href="#pencil"></use>
        </svg>
      </div>
      <div (click)="onShowDeleteConfirmationDialog($event)">
        <svg class="bucket">
          <use xlink:href="#bucket"></use>
        </svg>
      </div>
    </div>
    <div *ngFor="let field of customFields" class="field_wrapper custom_field" (click)="onOpenFieldEdit($event, field)">
        <span *ngIf="field.type !== CustomFieldTypes.date">{{customFieldsValues[field.id] ? customFieldsValues[field.id].value : ''}}</span>
        <span class="date" *ngIf="field.type === CustomFieldTypes.date">{{(customFieldsValues[field.id] ? +customFieldsValues[field.id].value : 0) | toDate: 'dueDate'}}</span>
    </div>
    <div class="field_wrapper due_date" (click)="onOpenFieldEdit($event, 'dueDate')">
      {{task.dueDate | toDate: 'dueDate'}}
    </div>
    <div class="field_wrapper labels" (click)="onOpenFieldEdit($event, 'labels')">
      <list-board-labels [labels]="taskLabels" [isNotGuest]="isNotGuest"></list-board-labels>
    </div>
    <div class="field_wrapper members">
      <list-board-members [members]="taskUsers" [isNotGuest]="isNotGuest" (edit)="onOpenMembersEdit($event)"></list-board-members>
    </div>
    <div *ngFor="let scoringColumn of scoringColumns" (click)="onEditScoringCriterion($event, scoringColumn)" class="field_wrapper scoring_field">
      <span>{{getScoringValue(task, scoringColumn)}}</span>
    </div>
    <div *ngIf="scoringType === ScoringType.advanced" class="field_wrapper scoring_field" (click)="onEditScoringCriterion($event, 'confidence')">
      <span>{{task.confidence}}</span>
    </div>
    <div *ngIf="scoringType !== ScoringType.off && scoringType !== ScoringType.basic" (click)="onEditScoringCriterion($event)" class="field_wrapper scoring_field">
      <span>{{getTaskResultScore(task)}}</span>
    </div>
    <div class="field_wrapper settings_column">

    </div>
  </div>
</div>
