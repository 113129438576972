import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { CustomValidators } from '../../../shared/validators/custom-validators/index';
import { KeyCode } from '../../../constants/key-code';
import { isValidEmail } from '../../../shared/validators/custom-validators/email-validator';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'many-at-once',
  templateUrl: './many-at-once.component.html',
  styleUrls: ['./many-at-once.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManyAtOnceComponent implements OnInit {
  @ViewChild('emailsInput') emailsInput: ElementRef;

  @Output() emailsList = new EventEmitter();

  public manyEmails = [];
  public formValue: FormControl;
  public currentEmailNotValid = false;
  public emailFocus$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  private handleNextInput = false;

  constructor(private _fb: FormBuilder) {}

  ngOnInit() {
    this.formValue = new FormControl('', CustomValidators.composeOr([CustomValidators.empty, CustomValidators.email]));
  }

  onSetInputFocus() {
    this.emailFocus$.next(true);
  }

  onRemoveEmailTag(value: string) {
    this.manyEmails = this.manyEmails.filter(email => email !== value);
    this.emailsList.emit(this.manyEmails);
  }
  onPaste() {
    this.handleNextInput = true;
  }
  onInput(value: string) {
    if (this.handleNextInput) {
      this.handleNextInput = false;
      value = (value || '').trim();
      if (value.match(/[\s,;]/)) {
        // list has been copied
        const list = value.split(/[\s,;]+/);
        const preparedValue = list
          .reduce((acc, email) => {
            isValidEmail(email) ? this.addEmail(email) : acc.push(email);
            return acc;
          }, [])
          .join(', ');
        this.formValue.patchValue(preparedValue);
      }
    }
  }

  onAddEmailEvent(event: KeyboardEvent, value: string) {
    if (event.keyCode === KeyCode.KEY_ENTER || event.keyCode === KeyCode.KEY_SPACE) {
      this.onAddEmail(value);
      event.preventDefault();
    }
  }
  onAddEmail(value: string) {
    if (!this.formValue.valid) {
      this.currentEmailNotValid = true;
      return;
    }
    this.currentEmailNotValid = false;
    this.addEmail(value);
    this.emailsInput.nativeElement.value = '';
  }

  addEmail(value) {
    if (!this.manyEmails.includes(value) && value.length) {
      this.manyEmails.push(value);
    }
    this.emailsList.emit(this.manyEmails);
  }
}
