import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BillingPlan } from '@atlaz/billing/interfaces/billing-plan';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/index';
import { AppState } from '../../../../ngrx/state';
import { fromBillingPlans } from '@atlaz/billing/store/plan/plan.reducer';
import { BillingPlanName } from '@atlaz/billing/constants/billing-subscription';

@Component({
  selector: 'pricing-plans',
  templateUrl: './pricing-plans.component.html',
  styleUrls: ['./pricing-plans.component.scss']
})
export class PricingPlansComponent {
  @Input() isMonthly = true;
  @Input() isUnpaidSubscription = true;
  @Output() onUpgrade = new EventEmitter<boolean>();

  public basicPlan$: Observable<BillingPlan>;
  public proPlan$: Observable<BillingPlan>;
  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this.basicPlan$ = this._store.select(fromBillingPlans.get(BillingPlanName.Basic));
    this.proPlan$ = this._store.select(fromBillingPlans.get(BillingPlanName.Premium));
  }

  onToggleMonthly() {
    this.isMonthly = !this.isMonthly;
  }

  onUpgradePlan() {
    this.onUpgrade.emit(this.isMonthly);
  }
}
