export const blockWidth = 20;
export const TAKS_TREE = 'taskTree';
export const HIDDEN_ITEMS = 'hiddenItems';

export enum RoadMapAddFormTypes {
  task = 'task',
  group = 'group',
  taskLink = 'taskLink',
  project = 'project'
}

export enum ScrollDirection {
  horizontal = 0,
  vertical = 1
}

export enum RoadMapScale {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Quarter = 'Quarter',
  Year = 'Year'
}
