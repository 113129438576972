import { AppState, ESInterface } from '../state';
import { HYGGER_THEME_PL } from '../../constants';
import { BoardTheme } from '../../interfaces/board-theme';
import { createSelector } from 'reselect';

export namespace fromBoardThemes {
  export const getState = (store: AppState) => {
    return <ESInterface<BoardTheme>>store[HYGGER_THEME_PL];
  };
  export const getById = id => (store: AppState) => getState(store).entities[id];
  export const getAll = createSelector(getState, themesState =>
    themesState.ids.map((id: number) => themesState.entities[id])
  );
}
