
import {take, filter} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { SprintBoard } from '../../../interfaces';
import { ActivatedRoute } from '@angular/router';
import { getBoardById } from '../../../ngrx/reducers/board.reducer';
import { Observable } from 'rxjs';
import { RouterNavigateService } from '../../../shared/services/router-navigate.service';

@Component({
  selector: 'release-sprint-popup',
  templateUrl: './release-sprint-popup.component.html',
  styleUrls: ['./release-sprint-popup.component.scss']
})
export class ReleaseSprintPopupComponent implements OnInit {
  public board$: Observable<SprintBoard>;
  public boardId: number;

  constructor(
    private _store: Store<AppState>,
    private _route: ActivatedRoute,
    private _routerNav: RouterNavigateService
  ) {}
  ngOnInit() {
    this.boardId = +this._route.snapshot.params['boardId'];
    this.board$ = <Observable<SprintBoard>>this._store.pipe(
      (getBoardById(this.boardId)),
      filter(x => !!x),
      take(1),);
  }

  onClose() {
    this._routerNav.deactivatePopupOutlet();
  }
}
