
import {interval as observableInterval, fromEvent as observableFromEvent,  BehaviorSubject ,  Subject ,  Observable } from 'rxjs';

import {takeUntil, tap, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RoadMapItem } from '../interfaces/roadmap.interface';
import { SyncRoadmapGuiStateService } from './sync-roadmap-gui-state-service';
import { getEventOffsetX } from '../../../../helpers/event';
import { AppState } from '../../../ngrx/state/';
import { Store } from '@ngrx/store';
import { fromRoadmapBoard } from '../store/roadmap-board.reducer';
import { animationFrame } from 'rxjs/scheduler/animationFrame';

const nothing = () => null;

@Injectable()
export class MoveTaskService {
  public busy = false;
  public showMoveOverlay$ = new BehaviorSubject(false);
  public mouseMove$: Observable<MouseEvent> = new Subject<MouseEvent>();

  constructor(private _syncService: SyncRoadmapGuiStateService, private _store: Store<AppState>) {}

  registerMouseMoveSubj(sub: Observable<MouseEvent>) {
    this.mouseMove$ = sub;
  }

  start(initialEvent: MouseEvent, item: RoadMapItem, initialItemPosition) {
    let chartWidth = 0;
    this._store
      .select(fromRoadmapBoard.chart).pipe(
      take(1))
      .subscribe(xs => (chartWidth = xs.width));

    const itemWidth = initialItemPosition.rightPx - initialItemPosition.leftPx;
    const maxLeftPx = Math.max(chartWidth - itemWidth, 0);
    const minLeftPx = 0;

    this._syncService.disableBodySelection();
    this.showMoveOverlay$.next(true);
    this.busy = true;
    // odd start value

    const stop$ = observableFromEvent(document, 'mouseup');

    const initialCursorOffsetX = initialItemPosition.leftPx + getEventOffsetX(initialEvent);

    let actualCursorOffset = 0;
    const position$ = new BehaviorSubject(initialItemPosition);

    // to highlight actual line
    this.mouseMove$.pipe(take(1)).subscribe(() => this._syncService.hoverItem$.next({ id: item.id, type: item.type }));

    this.mouseMove$.pipe(takeUntil(stop$)).subscribe((event: MouseEvent) => {
      actualCursorOffset = getEventOffsetX(event);
    });
    let lastEmittedValue = 0;
    observableInterval(1, animationFrame).pipe(
      takeUntil(stop$))
      .subscribe(() => {
        if (Math.abs(lastEmittedValue - actualCursorOffset) > 0.1) {
          lastEmittedValue = actualCursorOffset;
          const leftPx = Math.max(
            minLeftPx,
            Math.min(maxLeftPx, initialItemPosition.leftPx + actualCursorOffset - initialCursorOffsetX)
          );
          position$.next({
            leftPx,
            rightPx: leftPx + itemWidth
          });
        }
      });

    return position$.pipe(takeUntil(stop$),tap(nothing, nothing, () => this.stop()),);
  }

  stop() {
    console.warn('stop moving task on timesheet');
    this.busy = false;
    this._syncService.enableBodySelection();
    this.showMoveOverlay$.next(false);
  }
}
