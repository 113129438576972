
import {refCount, publishReplay, distinctUntilChanged, map, pluck, filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CompanyService } from '../../../shared/services/app/company.service';
import { defaultValue, isEquals, isPresent } from '../../../../helpers';
import { defaultWorkingDays } from '../constants/working-days';
import { Moment } from 'moment-mini-ts';
import * as moment from 'moment-mini-ts';
import { isDayOff, isWorkingDay } from '../utils/working-days';

@Injectable()
export class WorkingDaysService {
  public readonly workingDays$ = this._company.currentCompany$.pipe(
    filter(isPresent),
    pluck('workingDays'),
    map(defaultValue(defaultWorkingDays)),
    distinctUntilChanged(isEquals),
    publishReplay(1),
    refCount(),);

  private _workingDays;

  get workingDays() {
    return this._workingDays;
  }

  constructor(private _company: CompanyService) {
    this.init();
  }

  private init() {
    this.workingDays$.subscribe(v => (this._workingDays = v));
  }

  isDayOff(day: Moment) {
    return isDayOff(day, this.workingDays);
  }

  isWorkingDay(day: Moment) {
    return isWorkingDay(day, this.workingDays);
  }

  workingDaysBetween(ts1: number, ts2: number) {
    const startTs = Math.min(ts1, ts2);
    const endTs = Math.max(ts1, ts2);
    const day = moment.unix(startTs);
    let workingDaysCount = 0;
    do {
      if (this.isWorkingDay(day)) {
        workingDaysCount++;
      }
      day.add({ day: 1 });
    } while (day.unix() < endTs);
    return workingDaysCount;
  }

  workingDaysCount() {
    return Object.keys(this.workingDays).reduce((acc, item) => acc + this.workingDays[item], 0);
  }
}
