import { map } from 'rxjs/operators';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { Board, Collection, Project } from '../../interfaces';
import { AppUrls } from '../../app-urls';
import { ComponentSetVisibleAction } from '../../ngrx/actions/component.actions';
import { AppState, LEFT_MENU } from '../../ngrx/state';
import { Store } from '@ngrx/store';
import { DefaultCollection } from '../../interfaces/default-collection';
import { Observable } from 'rxjs';
import { getProjectsByBoard } from '../../ngrx/reducers/board.reducer';
import { SearchInLeftMenuService } from '../../shared/services/app/search-in-left-menu.service';
import { fromBoardThemes } from '../../ngrx/reducers/board-theme.reducer';
import { BoardTheme } from '../../interfaces/board-theme';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'left-board-list',
  templateUrl: './left-board-list.component.html',
  styleUrls: ['./left-board-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeftBoardListComponent implements OnChanges, OnDestroy {
  @Input() collection: Collection | DefaultCollection;
  @Input() boards: Board[];
  @Input() isSubCollection: boolean;

  public projects: { [id: number]: Observable<string> } = {};
  public appUrls = AppUrls;

  public boardThemesMap: { [id: number]: BoardTheme } = {};
  private sub: Subscription;

  constructor(
    private _store: Store<AppState>,
    private _searchInLeftMenuService: SearchInLeftMenuService,
    private _cd: ChangeDetectorRef
  ) {}
  ngOnInit() {
    this.sub = this._store.select(fromBoardThemes.getAll).subscribe(all => {
      this.boardThemesMap = all.reduce((acc, item) => {
        if (item) {
          acc[item.id] = item.headerColor;
        }
        return acc;
      }, {});
      this._cd.detectChanges();
    });
  }
  ngOnChanges() {
    this.projects = this.boards.reduce((acc, board: Board) => {
      acc[board.id] = this._store.pipe(
        getProjectsByBoard(board.id),
        map((projects: Project[]) => projects.map(project => project.shortName.trim()).join(', '))
      );
      return acc;
    }, {});
  }

  onCloseLeftMenu() {
    this._searchInLeftMenuService.setStringSearch = '';
    this._store.dispatch(new ComponentSetVisibleAction({ name: LEFT_MENU, visible: false }));
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
