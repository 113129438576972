<div class="search_trigger" (click)="onOpenSearch()"></div>
<div class="search_container"
       [@openSearchInLeftMenuState]="state"
       (@openSearchInLeftMenuState.start)="animationStarted($event)"
       (@openSearchInLeftMenuState.done)="animationDone($event)"
>
  <search-input
    *ngIf="isSearchOpened"
    (closeSearch)="onCloseSearch()"
    (chageSearch)="onChangeSearch($event)"
    [searchIcon]="searchIcon"
    [closeButton]="closeButton"
    [placeholder]="placeholder"
    [clickOut]="isSearchOpened && closeOnClickOut"
    (clickOutEvent)="onClickOut()"
  ></search-input>
</div>


