import { Injectable } from '@angular/core';
import { IntercomDummyService } from './intercom-dummy.service';

@Injectable()
export class IntercomService extends IntercomDummyService {
  get intercom() {
    return window['Intercom']
      ? window['Intercom']
      : () => {
          console.warn('Intercom is missed');
        };
  }
}
