import { createSelector } from 'reselect';

import { prop } from '../../../../../helpers/index';
import { BITBUCKET_COMMIT_PL } from '../../../../constants/index';
import { RootActions, RootActionTypes } from '../../../../ngrx/actions/root.action';
import { insertReplaceState, removeOne } from '../../../../ngrx/functions/reducer';
import { ESInterface, getEmptyESState } from '../../../../ngrx/state/index';
import { createCommonSelectors, getByIds } from '../../../../util/store/index';
import { ESBitbucketCommit, BitbucketCommit } from '../../models/git-commit';
import * as bitbucketCompany from '../bitbucket-company/bitbucket-company.actions';
import { createMapWithArray } from '../util';
import * as bitbucketCommit from './bitbucket-commit.actions';

export interface State extends ESBitbucketCommit {
  taskCommits: {
    [taskId: number]: string[];
  };
  isLoading: boolean;
}

const initialState: State = {
  ...getEmptyESState<BitbucketCommit>(),
  taskCommits: {},
  isLoading: false
};

export function reducer(
  state = initialState,
  action: bitbucketCommit.Actions | bitbucketCompany.Actions | RootActions
): State {
  switch (action.type) {
    case bitbucketCommit.GET_TASK_COMMIT: {
      return { ...state, isLoading: true };
    }
    case bitbucketCommit.UPDATED: {
      return state;
    }

    case bitbucketCommit.GET_TASK_COMMIT_COMPLETE: {
      const taskCommits = {
        ...state.taskCommits,
        [action.payload.taskId]: action.payload.entities.ids
      };
      return <State>{
        ...state,
        taskCommits: taskCommits,
        isLoading: false
      };
    }

    case bitbucketCommit.GET_TASK_COMMIT_FAIL: {
      return { ...state, isLoading: false };
    }

    case bitbucketCommit.DELETE_TASK_COMMIT: {
      const newState = removeOne(state, action.payload.id);
      const taskCommits = newState.taskCommits[action.payload.taskId] || [];
      newState.taskCommits = {
        ...newState.taskCommits,
        [action.payload.taskId]: taskCommits.filter(cId => cId !== action.payload.id)
      };
      return newState;
    }

    case bitbucketCompany.REMOVE_ALL_COMPLETE: {
      return initialState;
    }

    case RootActionTypes.GET_COMPLETE: {
      if (BITBUCKET_COMMIT_PL in action.payload) {
        const updatedState: ESInterface<BitbucketCommit> = action.payload[BITBUCKET_COMMIT_PL];
        const taskCommits = updatedState.ids.reduce(
          createMapWithArray(updatedState.entities, 'task', 'id'),
          Object.assign({}, state.taskCommits)
        );
        const newESState = <ESBitbucketCommit>insertReplaceState(<any>state, <any>updatedState);
        return <State>{
          ...newESState,
          taskCommits,
          isLoading: false
        };
      }

      return state;
    }

    default: {
      return state;
    }
  }
}

export const { getState, getEntities, getIds, getAll, isEmpty } = createCommonSelectors<BitbucketCommit, State>(
  BITBUCKET_COMMIT_PL
);

export const getTaskCommitMap = createSelector(getState, prop('taskCommits'));

export const getTaskCommitIds = createSelector(getTaskCommitMap, state => taskId => state[taskId] || []);
export const getTaskCommits = createSelector(
  getTaskCommitIds,
  getEntities,
  (commitIdsFn, entities) => (taskId: number) => getByIds(commitIdsFn(taskId), entities)
);

const isLoading = createSelector(getState, state => state.isLoading);

export const getTaskCommitsLoading = createSelector(isLoading, getTaskCommitIds, (isLoadingFlag, idsFn) => taskId =>
  isLoadingFlag && idsFn(taskId).length === 0
);
