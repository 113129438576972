
import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { SegmentActionTypes } from '../actions/segment.actions';
import { SegmentService } from '../../atlaz-bnp/services/intergations/segment/segment.service';
import { Action } from '../actions/unsafe-action';

@Injectable()
export class SegmentEffects {
  constructor(private _actions$: Actions, private _segment: SegmentService) {}

  @Effect({ dispatch: false })
  trackTaskCreated$ = this._actions$.ofType(SegmentActionTypes.TASK_CREATED).pipe(tap(({ payload }: Action) => {
    this._segment.track('TaskCreated', payload);
  }));

  @Effect({ dispatch: false })
  trackTaskOpened$ = this._actions$.ofType(SegmentActionTypes.TASK_OPENED).pipe(tap(({ payload }: Action) => {
    this._segment.track('TaskOpened', payload);
  }));

  @Effect({ dispatch: false })
  trackBoardOpened$ = this._actions$.ofType(SegmentActionTypes.BOARD_OPENED).pipe(tap(({ payload }: Action) => {
    this._segment.track('BoardOpened', payload);
  }));

  @Effect({ dispatch: false })
  trackRoadmapTaskStatusChanged = this._actions$
    .ofType(SegmentActionTypes.ROADMAP_TASK_STATUS_CHANGED).pipe(
    tap(({ payload }: Action) => {
      this._segment.track('RoadmapTaskStatusChanged', payload);
    }));
}
