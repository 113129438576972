<form [formGroup]='collectionForm'>
  <div class='popup_form_group'>
    <label for='name'>Collection Name *</label>
    <input [formControl]='collectionForm.get("name")' id='name' #name='ngForm'
           [focus]="true"
           [ngClass]="{'input_error': !collectionForm.valid}"
           autocomplete="off"
           (keyup.esc)="onCLosePopup()"
           placeholder='Example "Hygger Collection 1.0"'/>
  </div>
  <div class="popup_form_group">
    <label>Boards</label>
    <div class="tags_wrapper">
      <div *ngFor="let board of boardsControl" class="board_tag">
        <p>{{board.name}}</p>
        <div class="remove_board_control" (click)="removeBoard(board)"></div>
      </div>
      <div class="add_btn" (click)="onManageBoardsToggle()"></div>
    </div>

    <div a-context-popup [title]="'Manage Boards'"
         (close)="onManageBoardsToggle()"
         *ngIf="isManageBoardsVisible"
         class="boards_popup"
    >
      <collection-board-list
        [selectedCollection]="collection"
        [selectedBoards]="selectedBoards"
        (manageBoardsCloseEvent)="onManageBoardsToggle()"
        (addBoardEvent)="addBoard($event)"
        (removeBoardEvent)="removeBoard($event)"
      ></collection-board-list>
    </div>
  </div>
  <div class="popup_form_group">
    <button a-button [disabled]='!collectionForm.valid'
            [pending]="formService.isPending$ | async"
            tabindex="0"
            (click)="onSubmit()"
            class="btn_primary"
    >Save</button>
    <button a-button (click)="onCLosePopup()" tabindex="0" class='btn_secondary'>Cancel</button>
    <button a-button (click)="onDeleteCollectionToggle()" tabindex="0" class='btn_text'>Delete</button>
  </div>

</form>

<div a-confirmation-popup
     confirmationTitle="Delete collection?"
     *ngIf="isDeleteCollectionVisible"
     actionButtonText="Delete"
     (confirm)="onDeleteCollection()"
     (close)="onDeleteCollectionToggle()"
>
  All collection boards will not be deleted.
</div>
