import { Entity, HttpQueryParam } from '../../../../interfaces';
import { Action } from '../../../../ngrx/actions/unsafe-action';
import { ESInterface } from '../../../../ngrx/state';
import { JiraProject } from './jira-company.reducer';

export const GET_GET = '[jira-company.actions] GET_GET';
export const GET_INDEX = '[jira-company.actions] GET_INDEX';
export const SET_ACTIVE = '[jira-company.actions] SET_ACTIVE';
export const UPDATED = '[jira-company.actions] UPDATED';
export const GET_INDEX_COMPLETE = '[jira-company.actions] GET_INDEX_COMPLETE';
export const GET_INDEX_FAIL = '[jira-company.actions] GET_INDEX_FAIL';
export const REMOVE_ALL_COMPLETE = '[jira-company.actions] REMOVE_ALL_COMPLETE';
export const REMOVE_ALL = '[jira-company.actions] REMOVE_ALL';
export const CLEAR_SETTINGS = '[jira-company.actions] CLEAR_ALL';

export class Get implements Action {
  readonly type = GET_GET;

  constructor(public payload: { id: number; queryParams?: HttpQueryParam }) {}
}

export class GetIndex implements Action {
  readonly type = GET_INDEX;

  constructor(public payload?: HttpQueryParam) {}
}

export class GetIndexComplete implements Action {
  readonly type = GET_INDEX_COMPLETE;

  constructor(public payload: ESJiraCompany) {}
}

export class GetIndexFail implements Action {
  readonly type = GET_INDEX_FAIL;
}

export class SetActive implements Action {
  readonly type = SET_ACTIVE;

  constructor(public payload: { number: number }) {}
}

export class Updated implements Action {
  readonly type = UPDATED;

  constructor(public payload: ESJiraCompany) {}
}

export class RemoveAllComplete implements Action {
  readonly type = REMOVE_ALL_COMPLETE;
  constructor(payload?: any) {}
}

export class RemoveAll implements Action {
  readonly type = REMOVE_ALL;
  constructor() {}
}

export class ClearSettings implements Action {
  readonly type = CLEAR_SETTINGS;
}

export interface JiraCompany extends Entity {
  jiraProject: string;
  jiraProjects: { [id: string]: JiraProject[] };
  creator: number;
  userName: string;
  updatedAt: number;
  createdAt: number;
  clouds: string;
  cloud: string;
}

export interface JiraCloud {
  id: string;
  name: string;
}

export type ESJiraCompany = ESInterface<JiraCompany>;

// prettier-ignore
export type Actions
  = Get
  | GetIndex
  | GetIndexFail
  | GetIndexComplete
  | SetActive
  | Updated
  | RemoveAllComplete
  | RemoveAll
  | ClearSettings
  ;
