import { Injectable } from '@angular/core';
import { SegmentBaseService } from './segment-base.service';

@Injectable()
export class SegmentService extends SegmentBaseService {
  getInstance() {
    if (!window['analytics']) {
      console.warn('Segment is missed');
      window['analytics'] = {};
      [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on'
      ].forEach(mehtod => {
        window['analytics'][mehtod] = () => null;
      });
    }
    return window['analytics'];
  }
}
