import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ElementRef,
  HostListener,
  ViewChild,
  AfterContentInit,
  Input
} from '@angular/core';

const defaultPadding = 5;
declare const window;

@Component({
  selector: '[a-context-menu-legasy]',
  template: `
    <div class="content" #context>
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./a-context-menu-legasy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContextMenuLegasyComponent implements AfterContentInit {
  @ViewChild('context') public contextElement: ElementRef;

  @Input() position: 'right' | 'left' = 'left';

  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(private _eref: ElementRef) {}

  doClose() {
    this.close.emit(null);
  }

  @HostListener('click', ['$event'])
  onClick(event) {
    if (event.target === this._eref.nativeElement) {
      event.stopPropagation();
      event.stopImmediatePropagation();
      this.doClose();
      return false;
    }
  }

  doCheckPosition() {
    const { bottom, right, top, left } = this.contextElement.nativeElement.getBoundingClientRect();
    if (
      top - defaultPadding < 0 ||
      right + defaultPadding > window.innerWidth ||
      left - defaultPadding < 0 ||
      bottom + defaultPadding > window.innerHeight
    ) {
      this.contextElement.nativeElement.scrollIntoView(false);
    }
  }

  ngAfterContentInit() {
    this.doCheckPosition();
  }
}
