import { Pipe, PipeTransform } from '@angular/core';
import { MENTIONS_PATTERN } from '../../../helpers/mention';

@Pipe({
  name: 'markdownMention'
})
export class MarkdownMentionPipe implements PipeTransform {
  transform(value: string, args?: any): string {
    return value
      .split('````')
      .map((str, i) => {
        if (i % 2) {
          return str;
        } else {
          return str.replace(MENTIONS_PATTERN, '**&nbsp;$&**');
        }
      })
      .join('````');
  }
}
