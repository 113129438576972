import { Action } from './unsafe-action';
import { Board, HttpQueryParam, PartOfEntity } from '../../interfaces';
import { BOARD } from '../../constants';
import { labelAction } from '../functions/action';

export const BoardActionTypes = {
  OPEN: labelAction(BOARD, 'OPEN BOARD'),
  LOAD: labelAction(BOARD, 'LOAD'),
  GET: labelAction(BOARD, 'GET'),
  GET_COMPLETE: labelAction(BOARD, 'GET_COMPLETE'),
  GET_FAIL: labelAction(BOARD, 'GET_FAIL'),
  ADD: labelAction(BOARD, 'ADD'),
  ADD_COMPLETE: labelAction(BOARD, 'ADD_COMPLETE'),
  ADD_FAIL: labelAction(BOARD, 'ADD_FAIL'),
  EDIT: labelAction(BOARD, 'EDIT'),
  REOPEN: labelAction(BOARD, 'REOPEN'),
  REOPEN_COMPLETE: labelAction(BOARD, 'REOPEN_COMPLETE'),
  REOPEN_FAILED: labelAction(BOARD, 'REOPEN_FAILED'),
  EDIT_COMPLETE: labelAction(BOARD, 'EDIT_COMPLETE'),
  EDIT_FAIL: labelAction(BOARD, 'EDIT_FAIL'),
  DELETE: labelAction(BOARD, 'DELETE'),
  DELETE_COMPLETE: labelAction(BOARD, 'DELETE_COMPLETE'),
  DELETE_FAIL: labelAction(BOARD, 'DELETE_FAIL'),
  ASSIGN_USERS: labelAction(BOARD, 'ASSIGN_USERS'),
  ASSIGN_GROUPS: labelAction(BOARD, 'ASSIGN_GROUPS'),
  ASSIGN_USERS_GROUPS: labelAction(BOARD, 'ASSIGN_USERS_GROUPS'),
  ASSIGN_COLLECTIONS: labelAction(BOARD, 'ASSIGN_COLLECTIONS'),
  ASSIGN_PROJECTS: labelAction(BOARD, 'ASSIGN_PROJECTS'),
  OPEN_SCORING_SETTINGS: labelAction(BOARD, 'OPEN_SCORING_SETTINGS'),
  ROADMAP_PDF_GENERATED: labelAction(BOARD, 'ROADMAP_PDF_GENERATED')
};

export class BoardLoadAction implements Action {
  type = BoardActionTypes.LOAD;

  constructor(public payload?: HttpQueryParam) {}
}

// init loading data to open board
export class BoardOpenAction implements Action {
  type = BoardActionTypes.OPEN;

  constructor(public payload: number) {
    this.payload = +payload;
  }
}

export class BoardGetAction implements Action {
  type = BoardActionTypes.GET;

  constructor(public payload?: HttpQueryParam) {}
}

export class BoardGetCompleteAction implements Action {
  type = BoardActionTypes.GET_COMPLETE;

  constructor(public payload: Board[]) {}
}

export class BoardEditAction implements Action {
  type = BoardActionTypes.EDIT;
  constructor(public payload: PartOfEntity) {}
}

export class BoardReopenAction implements Action {
  type = BoardActionTypes.REOPEN;
  constructor(public payload: number) {}
}

export class BoardReopenCompleteAction implements Action {
  type = BoardActionTypes.REOPEN_COMPLETE;
  constructor(public payload: any) {}
}

export class BoardReopenFailedAction implements Action {
  type = BoardActionTypes.REOPEN_FAILED;
  constructor(public payload: any) {}
}

export class BoardEditCompleteAction implements Action {
  type = BoardActionTypes.EDIT_COMPLETE;

  constructor(public payload: Board) {}
}

export class BoardDeleteAction implements Action {
  type = BoardActionTypes.DELETE;

  constructor(public payload: number) {}
}

export class BoardDeleteCompleteAction implements Action {
  type = BoardActionTypes.DELETE_COMPLETE;

  constructor(public payload?: any) {}
}

export class BoardAddAction implements Action {
  type = BoardActionTypes.ADD;

  constructor(public payload: { newBoardData: PartOfEntity; goToNewBoardFlag: boolean }) {}
}

export class BoardAddCompleteAction implements Action {
  type = BoardActionTypes.ADD_COMPLETE;

  constructor(public payload: Board) {}
}

export class BoardAssignUsersAction implements Action {
  type = BoardActionTypes.ASSIGN_USERS;

  constructor(public payload: { id: number; users: { add?: number[]; remove?: number[] } }) {}
}

export class BoardAssignGroupsAction implements Action {
  type = BoardActionTypes.ASSIGN_GROUPS;

  constructor(public payload: { id: number; groups: { add?: number[]; remove?: number[] } }) {}
}

export class BoardAssignCollectionsAction implements Action {
  type = BoardActionTypes.ASSIGN_COLLECTIONS;

  constructor(public payload: { id: number; collections: { add?: number[]; remove?: number[] } }) {}
}

export class BoardAssignProjectsAction implements Action {
  type = BoardActionTypes.ASSIGN_PROJECTS;

  constructor(public payload: { id: number; projects: { add?: number[]; remove?: number[] } }) {}
}

export class BoardAssignUserAndGroupsAction implements Action {
  type = BoardActionTypes.ASSIGN_USERS_GROUPS;

  constructor(
    public payload: {
      id: number;
      groups?: { add?: number[]; remove?: number[] };
      users?: { add?: number[]; remove?: number[] };
    }
  ) {}
}

export class BoardOpenScoringSettingsAction implements Action {
  type = BoardActionTypes.OPEN_SCORING_SETTINGS;

  constructor() {}
}

export class RoadMapPDFGenerated implements Action {
  type = BoardActionTypes.ROADMAP_PDF_GENERATED;

  constructor(public payload) {}
}

export type BoardActions =
  | BoardGetAction
  | BoardLoadAction
  | BoardGetCompleteAction
  | BoardEditAction
  | BoardEditCompleteAction
  | BoardDeleteAction
  | BoardDeleteCompleteAction
  | BoardAddAction
  | BoardAddCompleteAction
  | BoardAssignUsersAction
  | BoardAssignGroupsAction
  | BoardAssignUserAndGroupsAction
  | BoardAssignCollectionsAction
  | BoardOpenScoringSettingsAction
  | RoadMapPDFGenerated
  | BoardAssignProjectsAction;
