import { SlackBoard } from '../../models/slack-boards';
import { Action } from '../../../../../ngrx/actions/unsafe-action';

export const GET_INDEX = '[SLACK ALERT] get board info';
export const SAVE_SLACK_BOARD = '[SLACK_BOARD] save board data';
export const REMOVE = '[slack-board.actions] delete slack board alerts';
export const GET_INDEX_COMPLETE = '[slack-board.actions] after gettings slack boards from server';
export const REMOVE_COMPLETED = '[slack-board.actions] event after server removing';
export const EDIT = '[slack-board.actions] edit alerts';

export class GetIndex implements Action {
  readonly type = GET_INDEX;

  constructor(public payload?: { board: number }) {}
}

export class GetIndexCompleted implements Action {
  readonly type = GET_INDEX_COMPLETE;
  constructor(public payload: any) {}
}

export class SaveNew implements Action {
  readonly type = SAVE_SLACK_BOARD;

  constructor(public payload: Partial<SlackBoard>) {}
}

export class Edit implements Action {
  readonly type = EDIT;

  constructor(public payload: Partial<SlackBoard>) {}
}

export class Remove implements Action {
  readonly type = REMOVE;

  constructor(public payload: { id: number }) {}
}

export class RemoveCompleted implements Action {
  readonly type = REMOVE_COMPLETED;

  constructor(public payload: { id: number }) {}
}

export type Actions = GetIndex | GetIndexCompleted | SaveNew | Remove | Edit | RemoveCompleted;
