import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { Action } from '../actions/unsafe-action';
import { compose } from '@ngrx/store';
import { Board, Swimlane } from '../../interfaces';
import { BOARD_PL, boardType, EstimationType, GROUP_PL, IDS, PROJECT_PL, ScoringType, USER_PL } from '../../constants';
import { isEqualType, replaceIds } from '../../../helpers';
import { AppState, ESInterface } from '../state';
import { BoardActionTypes } from '../actions/board.actions';
import { LabelsActionTypes } from '../actions/label.actions';
import { editEntityState } from '../functions/reducer';
import {
  getAllEntitiesAsArray,
  getEntitiesByIds,
  getEntityByIdSelector,
  getEntityByIdsSelector,
  getEntitySelector,
  getSelectedEntity,
  getSelectedEntityId,
  mapEntityField
} from '../functions/selectors';
import { CollectionActionTypes } from '../actions/collection.actions';
import { share } from '../functions/util';
import { createSelector, defaultMemoize } from 'reselect';
import { NaturalSort as NaturalSortLib } from 'angular2-natural-sort';
import { Observable } from 'rxjs';
import { SwimlaneActionTypes } from '../actions/swimlane.actions';
import { BoardInProjectAccess } from '../../interfaces/board';

const initialState: ESInterface<Board> = {
  ids: [],
  entities: {},
  selectedEntityId: null
};

export function reducer(state = initialState, action: Action): ESInterface<Board> {
  switch (action.type) {
    case LabelsActionTypes.ADD_COMPLETE: {
      if (state.entities[action.payload.board]) {
        const currentLabelsIds = state.entities[action.payload.board].labelsIds || [];

        return editEntityState(state, {
          id: action.payload.board,
          labelsIds: [...currentLabelsIds, action.payload.id]
        });
      }
      break;
    }

    case LabelsActionTypes.DELETE: {
      if (state.entities[action.payload.board]) {
        return editEntityState(state, {
          id: action.payload.board,
          labelsIds: state.entities[action.payload.board].labelsIds.filter(id => id !== action.payload.id)
        });
      }
      break;
    }

    case BoardActionTypes.EDIT: {
      return editEntityState(state, action.payload);
    }

    case BoardActionTypes.ASSIGN_USERS: {
      const boardId: number = action.payload.id;

      const userIds = replaceIds(state.entities[boardId].usersIds, action.payload.users);
      return editEntityState(state, { id: boardId, usersIds: userIds });
    }

    case BoardActionTypes.ASSIGN_GROUPS: {
      const boardId: number = action.payload.id;
      const groupIds = replaceIds(state.entities[boardId].groupsIds, action.payload.groups);
      return editEntityState(state, { id: boardId, groupsIds: groupIds });
    }

    case BoardActionTypes.ASSIGN_PROJECTS: {
      const boardId: number = action.payload.id;

      const projectIds = replaceIds(state.entities[boardId].projectsIds, action.payload.projects);
      return editEntityState(state, { id: boardId, projectsIds: projectIds });
    }

    case BoardActionTypes.ASSIGN_USERS_GROUPS: {
      const boardId: number = action.payload.id;
      let newState: ESInterface<Board> = Object.assign({}, state);

      if (action.payload.hasOwnProperty('groups')) {
        const groupIds = replaceIds(newState.entities[boardId].groupsIds, action.payload.groups);
        newState = editEntityState(newState, { id: boardId, groupsIds: groupIds });
      }

      if (action.payload.hasOwnProperty('users')) {
        const usersIds = replaceIds(newState.entities[boardId].usersIds, action.payload.users);
        newState = editEntityState(newState, { id: boardId, usersIds: usersIds });
      }

      return newState;
    }

    case CollectionActionTypes.ASSIGN_BOARDS: {
      let newState: ESInterface<Board> = Object.assign({}, state);
      const payload: { id: number; boards: { add?: number[]; remove?: number[] } } = action.payload;

      if (payload.boards.hasOwnProperty('add')) {
        payload.boards.add.forEach(boardId => {
          newState = editEntityState(newState, { id: boardId, collection: payload.id });
        });
      }
      if (payload.boards.hasOwnProperty('remove')) {
        payload.boards.remove.forEach(boardId => {
          newState = editEntityState(newState, { id: boardId, collection: null });
        });
      }

      return newState;
    }

    case SwimlaneActionTypes.DELETE: {
      const newState = excludeSwimlaneIdInBoards(action.payload, state);
      return newState;
    }

    default: {
      return state;
    }
  }
}

export const isBoardType = (type: string) => (state$: Observable<Board>): Observable<boolean> =>
  state$.pipe(map(isEqualType(type)), distinctUntilChanged());

export const isBacklogBoard = isBoardType(boardType.backlog);
export const isKanbanBoard = isBoardType(boardType.kanban);

export const getBoardState = getEntitySelector(BOARD_PL);
export const getAllBoards = share(compose(getAllEntitiesAsArray, getBoardState));
export const getBoardById = id => share(compose(getEntityByIdSelector<Board>(id), getEntitySelector(BOARD_PL)));
export const getBoardByIds = (ids: number[] = []) =>
  share(compose(getEntityByIdsSelector(ids), getEntitySelector(BOARD_PL)));

export const getSelectedBoard = share(compose(getSelectedEntity, getBoardState));
export const getSelectedBoardId = share(compose(getSelectedEntityId, getBoardState));
export const isBacklogBoardBuilder = (boardId: number) => compose(isBacklogBoard, getBoardById(boardId));
export const isKanbanBoardBuilder = (boardId: number) => compose(isKanbanBoard, getBoardById(boardId));

export const getBoardUserIds = share(compose(mapEntityField(USER_PL + IDS, []), getSelectedBoard));

export const getBoardGroupIds = compose(mapEntityField(GROUP_PL + IDS, []), getSelectedBoard);

export const getBoardProjectIds = compose(mapEntityField(PROJECT_PL + IDS, []), getSelectedBoard);

export const filterClosedBoards = (boards: Board[]): Board[] => boards.filter(board => !board.closed);

export function getProjectsByBoard(boardId) {
  return share((state$: Observable<AppState>) =>
    state$.pipe(
      getBoardById(boardId),
      mapEntityField(PROJECT_PL + IDS, []),
      switchMap(projectIds => state$.pipe(getEntitiesByIds(PROJECT_PL, projectIds)))
    )
  );
}

/**
 * selectors that uses reselect library
 */
export const boardsState = (store: AppState) => <ESInterface<Board>>store[BOARD_PL];

export const isActiveBoard = board => board && !board.closed;

export const getAllBoardsByState = createSelector(boardsState, state => state.ids.map(id => state.entities[id]));

export namespace fromBoards {
  export const get = id => state => boardsState(state).entities[id];

  export const getSelectedBoard = createSelector(boardsState, state => state.entities[state.selectedEntityId]);

  export const isSelectedBoardPublic = defaultMemoize(
    createSelector(getSelectedBoard, (board: Board) => board && board.access === BoardInProjectAccess.public)
  );

  export const isBacklogSelected = defaultMemoize(
    createSelector(getSelectedBoard, (board: Board) => board && board.type === boardType.backlog)
  );

  export const isBasicScoringSelected = defaultMemoize(
    createSelector(getSelectedBoard, (board: Board) => board && board.scoringType === ScoringType.basic)
  );

  export const isScoringOff = defaultMemoize(
    createSelector(getSelectedBoard, (board: Board) => board && board.scoringType === ScoringType.off)
  );

  export const getAllAvailable = createSelector(getAllBoardsByState, boards =>
    boards.filter((board: Board) => !board.closed).sort((a: Board, b: Board) => NaturalSortLib.SORT(a.name, b.name))
  );
}

export const getShowEstimateValuesByBoardId = defaultMemoize(boardId =>
  createSelector(boardsState, (state: ESInterface<Board>) => {
    const board = state.entities[boardId];
    const result = {
      isShowHoursEstimate: true,
      isShowPointsEstimate: true
    };
    if (board && board.sprintStartDate) {
      result.isShowHoursEstimate = board.estimatedBy !== EstimationType.storyPoints;
      result.isShowPointsEstimate = board.estimatedBy !== EstimationType.hours;
    }
    return result;
  })
);

export const injectSwimlanesIdsInBoards = (swimlanes, newState) => {
  newState.boards = { ...newState.boards };
  newState.boards.entities = { ...newState.boards.entities };
  Object.values(swimlanes.entities).forEach((swimlane: Swimlane) => {
    const board: Board = newState.boards.entities[swimlane.board];
    if (board && Array.isArray(board.swimlanesIds) && !board.swimlanesIds.includes(swimlane.id)) {
      newState.boards.entities[board.id] = {
        ...newState.boards.entities[board.id],
        swimlanesIds: [...board.swimlanesIds, swimlane.id]
      };
    }
  });
  return newState;
};

export const excludeSwimlaneIdInBoards = (swimlaneId, newState) => {
  newState = { ...newState };
  newState.entities = { ...newState.entities };
  const boardId = newState.ids.find(
    id => Array.isArray(newState.entities[id].swimlanesIds) && newState.entities[id].swimlanesIds.includes(swimlaneId)
  );
  if (boardId) {
    newState.entities[boardId] = {
      ...newState.entities[boardId],
      swimlanesIds: [...newState.entities[boardId].swimlanesIds.filter(id => id !== swimlaneId)]
    };
  }
  return newState;
};
