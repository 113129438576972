<button a-button class="btn_billing" *ngIf="(upgradeNeeded$ | async) && !(isLongTrial$ | async)" (click)="onNavigateToAddPaymentMethod()">
  Upgrade Now
</button>

<button a-button class="btn_billing" *ngIf="renewNeeded$ | async" [pending]="_formService.isPending$ | async" (click)="doRenew()">
	Renew
</button>

<button a-button class="btn_billing" *ngIf="(isLocked$ | async) && !isUnlockHidden" [pending]="_formService.isPending$ | async" (click)="onClickUnlock()">
  UNLOCK
</button>

<payment-success-popup
		*ngIf="isPaymentSuccessPopupVisible"
		(closePopup)="onCloseSuccessPopup()"
></payment-success-popup>
<payment-denied-popup
		*ngIf="isPaymentDeniedPopupVisible"
		(closePopup)="onCloseDeniedPaymentPupop()"
		(resubmitForm)="doRenew()"
		(changePaymentMethod)="onNavigateToChangeCard()"
		[lastCardNumbers]="lastCardNumbers"
></payment-denied-popup>
