<div class="axis_item">
  <div class="block_header">
    Quadrants Names
    <div class="edit_link" (click)="onOpenPopUp('kvValues')">Change</div>
  </div>
  <table class="quad_names">
    <tr>
      <td>{{board.kvValues[0] || QuadNames[0]}}</td>
      <td>{{board.kvValues[1] || QuadNames[1]}}</td>
    </tr>
    <tr>
      <td>{{board.kvValues[3] || QuadNames[3]}}</td>
      <td>{{board.kvValues[2] || QuadNames[2]}}</td>
    </tr>
  </table>
</div>
<div class="axis_item">
  <div class="block_header">Horizontal Axis</div>
  <div>
    <div>
      <h5>Name</h5>
      <span>{{board.backlogScoreXLabel}}</span>
      <div class="edit_link" (click)="onOpenPopUp('backlogScoreXLabel')">Change</div>
    </div>
    <div>
      <h5>Scale Type</h5>
      <span>{{ScaleTypeLabel[board.backlogScoreXType]}}</span>
      <div class="edit_link" (click)="onOpenPopUp('backlogScoreXType')">Change</div>
    </div>
  </div>
</div>
<div [ngClass]="{fixed_to_top: editingField === 'kvValues'}" *ngIf="isEditing$ | async"
     a-context-popup
     [title]="popupTitle"
     (close)="onClose()"
>
  <form [formGroup]="form">
    <form-errors></form-errors>
    <div class="row" *ngIf="editingField === 'backlogScoreYLabel'">
      <label>Name (12 symbols max)</label>
      <input
        [focus]="true"
        autocomplete="off"
        type="text"
        placeholder="Value"
        formControlName="backlogScoreYLabel"
        (keydown.enter)="onSubmitScales()"
      >
      <help-block [control]="form.get('backlogScoreYLabel')" [messages]="messages"></help-block>
    </div>
    <div class="row" *ngIf="editingField === 'backlogScoreXLabel'">
      <label>Name (12 symbols max)</label>
      <input
        [focus]="true"
        autocomplete="off"
        type="text"
        placeholder="Efforts"
        formControlName="backlogScoreXLabel"
        (keydown.enter)="onSubmitScales()"
      >
      <help-block [control]="form.get('backlogScoreXLabel')" [messages]="messages"></help-block>
    </div>
    <div class="row" *ngIf="editingField === 'backlogScoreYType'">
      <label for="y-name">Scale type</label>
      <select id="y-name" formControlName="backlogScoreYType">
        <option [value]="ScaleType.basic">{{ScaleTypeLabel[ScaleType.basic]}}</option>
        <option [value]="ScaleType.poker">{{ScaleTypeLabel[ScaleType.poker]}}</option>
        <option [value]="ScaleType.shirt">{{ScaleTypeLabel[ScaleType.shirt]}}</option>
      </select>
    </div>
    <div class="row" *ngIf="editingField === 'backlogScoreXType'">
      <label for="x-name">Scale type</label>
      <select id="x-name" formControlName="backlogScoreXType">
        <option [value]="ScaleType.basic">{{ScaleTypeLabel[ScaleType.basic]}}</option>
        <option [value]="ScaleType.poker">{{ScaleTypeLabel[ScaleType.poker]}}</option>
        <option [value]="ScaleType.shirt">{{ScaleTypeLabel[ScaleType.shirt]}}</option>
      </select>
    </div>
    <div class="row" *ngIf="editingField === 'kvValues'">
      <div class="quad_items">
        <div class="quad_item">
          <input
            autocomplete="off"
            type="text"
            placeholder="Big Bets"
            formControlName="kvValues0"
            (keydown.enter)="onSubmitQuads()"
          >
          <help-block [control]="form.get('kvValues0')" [messages]="quadMessages"></help-block>
        </div>
        <div class="quad_item">
          <input
            autocomplete="off"
            type="text"
            placeholder="Quick Wins"
            formControlName="kvValues1"
            (keydown.enter)="onSubmitQuads()"
          >
          <help-block [control]="form.get('kvValues1')" [messages]="quadMessages"></help-block>
        </div>
        <div class="quad_item">
          <input
            autocomplete="off"
            type="text"
            placeholder="Time Sinks"
            formControlName="kvValues3"
            (keydown.enter)="onSubmitQuads()"
          >
          <help-block [control]="form.get('kvValues3')" [messages]="quadMessages"></help-block>
        </div>
        <div class="quad_item">
          <input
            autocomplete="off"
            type="text"
            placeholder="Maybes"
            formControlName="kvValues2"
            (keydown.enter)="onSubmitQuads()"
          >
          <help-block [control]="form.get('kvValues2')" [messages]="quadMessages"></help-block>
        </div>
      </div>
    </div>
    <div class="row">
      <button a-button
              class="btn_primary"
              [pending]="_formService.isPending$ | async"
              (click)="onSubmitScales()"
      >
        Save
      </button>
      <button a-button class="btn_text" (click)="onClose()" type="button">Cancel</button>
    </div>
  </form>

</div>
<div class="axis_item last_item">
  <div class="block_header">Vertical Axis</div>
  <div>
    <h5>Name</h5>
    <span>{{board.backlogScoreYLabel}}</span>
    <div class="edit_link" (click)="onOpenPopUp('backlogScoreYLabel')">Change</div>
  </div>
  <div>
    <h5>Scale Type</h5>
    <span>{{ScaleTypeLabel[board.backlogScoreYType]}}</span>
    <div class="edit_link" (click)="onOpenPopUp('backlogScoreYType')">Change</div>
  </div>
</div>
