import { of as observableOf, Observable } from 'rxjs';
import { publishReplay, catchError, pluck, map, refCount } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BugTrackerService } from '@atlaz/core/services/bag-tracker.service';
import { defaultValue } from '../../../helpers/index';
import { HttpClient } from '@angular/common/http';

export interface PhoneCode {
  name: string;
  code: string;
  dialCode: string;
}

@Injectable()
export class PhoneCodesService {
  private _phoneCodes$: Observable<PhoneCode[]>;

  constructor(private http: HttpClient, private _bugTracker: BugTrackerService) {}

  public getCountryByCode(code): Observable<string> {
    if (!code) {
      return observableOf('');
    }
    return this.getPhoneCodes().pipe(
      map(arr => arr.find(item => item.code === code)),
      pluck('name'),
      map(defaultValue('')),
      catchError(err => {
        this._bugTracker.warn(`Unable to find phone code '${code}'`);
        this._bugTracker.error(err);
        return observableOf('');
      }),
      publishReplay(1),
      refCount()
    );
  }

  public getPhoneCodes(): Observable<PhoneCode[]> {
    if (!!this._phoneCodes$) {
      return this._phoneCodes$;
    }

    this._phoneCodes$ = this.http.get('/assets/phone-codes.json').pipe(
      map((list: Array<any>) =>
        list.sort((a: PhoneCode, b: PhoneCode) => (a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1))
      ),
      catchError(e => {
        console.log(e, 'error when get phone codes');
        return observableOf([]);
      }),
      publishReplay(1),
      refCount()
    );
    return this._phoneCodes$;
  }

  getCountriesInputOptions() {
    return this.getPhoneCodes().pipe(
      map(codes =>
        codes.map((pCode: PhoneCode) => {
          return { text: pCode.name, value: pCode.code };
        })
      ),
      publishReplay(1),
      refCount()
    );
  }
}
