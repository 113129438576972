import { Injectable } from '@angular/core';
import { AuthService } from '../../../shared/services/app/auth.service';

@Injectable()
export class OpenedStateLeftMenuItemsService {
  public localStoreKeys = {
    OPENED_PROJECT_LIST: 'closedProjectList-',
    OPENED_COLLECTIONS_LIST: 'closedCollectionList-'
  };

  constructor(private _authService: AuthService) {}

  getOpenedProjectsList(key) {
    const list = window.localStorage.getItem(key + this._authService.activeUserId);
    return list ? list.split(',') : [];
  }

  setOpenedProjectList(key, list) {
    window.localStorage.setItem(key + this._authService.activeUserId, list.toString());
  }

  deleteOpenedProjectsListFromLocalStorage(key) {
    window.localStorage.removeItem(key + this._authService.activeUserId);
  }

  issetOpenedProjectsList(key) {
    const list = this.getOpenedProjectsList(key);
    return list.length > 0;
  }

  save(state, key, id) {
    const openedItemsList = this.getOpenedProjectsList(key);

    if (!state) {
      if (!openedItemsList.includes(id.toString())) {
        openedItemsList.push(id.toString());
        this.setOpenedProjectList(key, openedItemsList);
      }
    } else {
      if (openedItemsList.includes(id.toString())) {
        const index = openedItemsList.indexOf(id.toString());
        openedItemsList.splice(index, 1);
        this.setOpenedProjectList(key, openedItemsList);

        if (!this.issetOpenedProjectsList(key)) {
          this.deleteOpenedProjectsListFromLocalStorage(key);
        }
      }
    }
  }
}
