import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'link-button',
  templateUrl: './link-button.component.html',
  styleUrls: ['./link-button.component.scss']
})
export class LinkButtonComponent {
  @Input() disabled: boolean;
  @Input() pending: boolean;
  @Input() buttonTabIndex: string;
  @Output() click = new EventEmitter<MouseEvent>();

  get isDisabled() {
    return this.disabled || this.pending;
  }

  onClick(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    if (!this.isDisabled) {
      this.click.emit(event);
    }
  }
}
