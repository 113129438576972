import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { KeyCode } from '../../constants/';
import { setFocusToFocusableParent } from '../../../helpers/event';

@Component({
  selector: '[a-popup]',
  templateUrl: './a-popup.component.html',
  styleUrls: ['./a-popup.component.scss']
})
export class APopupComponent implements AfterViewInit, OnDestroy {
  @Input() title: string;
  @Input() setFocus = true;
  @Input() isHideCloseBtn = false;
  @Output() open: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  private escListener = (event: KeyboardEvent) => {
    if (event.keyCode === KeyCode.KEY_ESCAPE) {
      if (event.preventDefault) {
        event.preventDefault();
      }
      event.stopPropagation();
      event.stopImmediatePropagation();
      this.onCancelContextPopup(event);
      return false;
    }
  };

  private missClickListener = event => {
    if (event.target === this._elRef.nativeElement) {
      if (event.preventDefault) {
        event.preventDefault();
      }
      event.stopPropagation();
      event.stopImmediatePropagation();
      this.onCancelContextPopup(event);
      return false;
    }
  };

  get isTemplateTitle() {
    return typeof this.title === 'object';
  }

  constructor(private _elRef: ElementRef) {}

  ngAfterViewInit() {
    if (this.setFocus) {
      this._elRef.nativeElement.tabIndex = 1;
      this._elRef.nativeElement.focus();
    }

    window.addEventListener('keydown', this.escListener, true);
    window.addEventListener('mousedown', this.missClickListener, true);
    this.open.emit();
  }

  ngOnDestroy() {
    window.removeEventListener('keydown', this.escListener, true);
    window.removeEventListener('mousedown', this.missClickListener, true);
    if (this.setFocus) {
      setFocusToFocusableParent(this._elRef.nativeElement);
    }
  }

  onCancelContextPopup(event) {
    if (event.preventDefault) {
      event.preventDefault();
    }
    event.stopPropagation();
    this.close.emit(event);
  }
}
