import { Injectable } from '@angular/core';
import { SlackBoardAdditional } from '../../settings/slack-alert/models/slack-boards';

@Injectable()
export class SlackBoardAdditionalService {
  public boardAlertDependencies = {
    'atlaz:boardAddMember': ['atlaz:boardRemoveMember'],

    'atlaz:boardAddProject': ['atlaz:boardRemoveProject'],

    'atlaz:boardAddCollection': ['atlaz:boardRemoveCollection'],

    'atlaz:columnArchived': ['atlaz:columnUnarchived'],

    // don't use it yet
    // 'atlaz:tasksAttachmentCreate': [
    // 'atlaz:tasksAttachmentDelete'
    // ],

    'atlaz:taskAddLabel': ['atlaz:taskRemoveLabel'],

    'atlaz:taskAddUser': ['atlaz:taskRemoveUser'],

    'atlaz:taskWorkLogCreated': ['atlaz:taskWorkLogChanged', 'atlaz:taskWorkLogDeleted'],

    'atlaz:taskChecklistCreated': ['atlaz:taskChecklistDeleted'],

    'atlaz:taskChecklistItemCreated': ['atlaz:taskChecklistItemDeleted'],

    'atlaz:swimlaneCreated': ['atlaz:swimlaneDeleted'],

    'atlaz:filterCreated': ['atlaz:filterDeleted']
  };

  public updateDependentFields(boardAdditional: Partial<SlackBoardAdditional>) {
    const reducer = this.addDependency(this.boardAlertDependencies);
    const newValue = Object.keys(this.boardAlertDependencies).reduce(reducer, { ...boardAdditional });
    return Object.keys(newValue).reduce((acc, i) => Object.assign(acc, { [i]: Number(newValue[i]) }), newValue);
  }

  private addDependency(boardAlertDependencies) {
    return (source, dependencyKey) => {
      const dependency = boardAlertDependencies[dependencyKey];
      const alertWithDependencies = source[dependencyKey];
      if (alertWithDependencies === undefined) {
        return source;
      }

      const dependencyToAdd = dependency.reduce((acc, i) => Object.assign(acc, { [i]: alertWithDependencies }), {});

      return Object.assign(source, dependencyToAdd);
    };
  }
}
