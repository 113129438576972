import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { RoadmapColors, RoadmapDefaultColor, RoadMapItem } from '../../../interfaces/roadmap.interface';
import { ScrollDirection } from '../../../constants/roadmap.constants';
import { ResizeTaskService, RESIZING } from '../../../services/resize-task.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../ngrx/state';
import { mouseButtons } from '../../../../../constants/mouse-buttons';
import { MoveTaskService } from '../../../services/move-task.service';
import { RoadmapDragService } from '../../../services/roadmap-drag.service';
import { ReplaySubject } from 'rxjs';
import { SegmentService } from '../../../../../atlaz-bnp/services/intergations/segment/segment.service';
import { MoveTask, ResizeTask } from '../../../store/roadmap-board.action';
import { Observable } from 'rxjs/Observable';
import { TaskEditAction } from '../../../../../ngrx/actions/task.actions';
import { fromLabels } from '../../../../../ngrx/reducers/label.reducer';
import { Label } from '../../../../../interfaces';
import { createMapFromArr } from '../../../../../../helpers';

@Component({
  selector: 'timesheet-task-preview',
  templateUrl: './timesheet-task-preview.component.html',
  styleUrls: ['./timesheet-task-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimesheetTaskPreviewComponent implements OnInit {
  private _item: RoadMapItem;
  private item$ = new ReplaySubject<RoadMapItem>(1);

  public barTitle$: Observable<string>;
  public backgroundColor$: Observable<string>;
  public doneStatus$: Observable<number>;
  public isColorPickerVisible = false;
  public availableColors = RoadmapColors;
  public defaultColor = RoadmapDefaultColor;
  public labels$: Observable<{ [prop: string]: Label }>;

  @Input()
  set item(value) {
    this._item = value;
    this.item$.next(value);
  }

  @Input() itemPositions;
  @Input() isNotGuest;

  @Output() startMoving = new EventEmitter();
  @Output() endMoving = new EventEmitter();

  get item() {
    return this._item;
  }

  private tempPosition;

  get storedPosition() {
    return this.itemPositions[this.item.id];
  }

  get itemPosition() {
    return this.tempPosition ? this.tempPosition : this.storedPosition;
  }

  public resizing = RESIZING.no;
  public RESIZING = RESIZING;

  private onEndMove = () => {
    console.warn('onEndMove');
    this.endMoving.emit();
    this._segment.track('RoadmapTaskMoved');
    this._store.dispatch(
      new MoveTask({ itemId: this.item.id, offset: this.tempPosition.leftPx - this.storedPosition.leftPx })
    );
    this.tempPosition = undefined;
  };

  private updatePosition = position => {
    this.tempPosition = position;
    this._cd.markForCheck();
    this._cd.detectChanges();
  };

  private endResize = () => {
    console.warn('handling stop');
    if (this.storedPosition.leftPx !== this.tempPosition.leftPx) {
      this._segment.track('RoadmapStartDateSet');
      this._store.dispatch(new ResizeTask({ itemId: this.item.id, leftPx: this.tempPosition.leftPx }));
    } else if (this.storedPosition.rightPx !== this.tempPosition.rightPx) {
      this._segment.track('RoadmapEndDateSet');
      this._store.dispatch(new ResizeTask({ itemId: this.item.id, rightPx: this.tempPosition.rightPx }));
    }
    this.tempPosition = undefined;
    this.resizing = RESIZING.no;
    this._cd.markForCheck();
    this._cd.detectChanges();
  };

  constructor(
    private _taskResizeService: ResizeTaskService,
    private _taskMoveService: MoveTaskService,
    private _roadmapDragService: RoadmapDragService,
    private _cd: ChangeDetectorRef,
    private _elementRef: ElementRef,
    private _store: Store<AppState>,
    private _segment: SegmentService
  ) {}

  ngOnInit() {
    this.labels$ = this.item$.pipe(
      switchMap(item => this._store.select(fromLabels.getByBoardId(item.task.board))),
      map(labels => {
        let i = 1;
        return createMapFromArr(
          labels.map(label => {
            if (!label.name) {
              const result = { ...label };
              result.name = 'Untitled Label ' + i;
              i++;
              return result;
            }
            return label;
          })
        );
      })
    );
    this.backgroundColor$ = this.item$.pipe(map(item => item.task.roadmapColor || ''));
    this.doneStatus$ = this.item$.pipe(map((item: RoadMapItem) => item.task.percent));

    this.barTitle$ = this.item$.pipe(map(item => item.title), distinctUntilChanged());
  }

  @HostBinding('class.short-view') isShortView = true;

  @HostBinding('class.resizing-right')
  get isRightResizing() {
    return this.resizing === RESIZING.right;
  }

  @HostBinding('class.resizing-left')
  get isLeftResizing() {
    return this.resizing === RESIZING.left;
  }

  @HostBinding('style.width.px')
  get right() {
    return this.itemPosition.rightPx - this.itemPosition.leftPx;
  }

  @HostBinding('style.left.px')
  get marginLeft() {
    return this.itemPosition.leftPx;
  }

  onStartResize(event: MouseEvent, prop) {
    if (event.button !== mouseButtons.left) {
      return false;
    }
    event.preventDefault();
    event.stopPropagation();
    this.resizing = prop;
    this._taskResizeService
      .start(event, this.item, prop, this.itemPosition)
      // this stream completes when mouseup is fired
      .subscribe(this.updatePosition, () => null, this.endResize);
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(initialEvent: MouseEvent) {
    if (!this.isNotGuest) {
      return;
    }
    this._roadmapDragService
      .detectDragDirection(initialEvent)
      .subscribe(({ direction }: { event: MouseEvent; direction: ScrollDirection }) => {
        if (direction === ScrollDirection.horizontal) {
          console.warn('start move');
          this.startMoving.emit();
          this._taskMoveService
            .start(initialEvent, this.item, this.itemPosition)
            .subscribe(this.updatePosition, () => null, this.onEndMove);
        } else {
          console.warn('start drag');
          this._roadmapDragService.start(initialEvent, this._elementRef.nativeElement, this.item);
        }
      });
  }

  onClickOut() {
    this.onToggleColorPicker();
    this._cd.detectChanges();
  }

  onToggleColorPicker() {
    this.isColorPickerVisible = !this.isColorPickerVisible;
    if (this._elementRef.nativeElement) {
      this.isColorPickerVisible
        ? this._elementRef.nativeElement.classList.add('color_picker_visible')
        : this._elementRef.nativeElement.classList.remove('color_picker_visible');
    }
  }

  setBgColor(color) {
    if (!this.isNotGuest) {
      return;
    }
    this._segment.track('RoadmapColorSet');
    this._store.dispatch(
      new TaskEditAction({ id: this._item.task.id, roadmapColor: this._item.task.roadmapColor === color ? '' : color })
    );
    this.onToggleColorPicker();
  }
}
