import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../ngrx/state';
import { CompanyService } from '../../../../shared/services/app/company.service';

@Component({
  selector: 'companies-sub-menu',
  templateUrl: './companies-sub-menu.component.html',
  styleUrls: ['./companies-sub-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompaniesSubMenuComponent {
  @Input() companies$;

  constructor(private _store: Store<AppState>, private _company: CompanyService) {}

  get activeCompanyId() {
    return this._company.id;
  }
}
