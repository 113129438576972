<a-center-popup
  [title]="'Add Group'"
  (close)="onClose()"
>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <form-errors></form-errors>

    <div class="form_group">
      <label for="swimlane-title">Group Title</label>
      <div>
        <input [focus]="true" id="swimlane-title" type="text" formControlName="name" (keydown.enter)="onSubmit()">
      </div>
      <help-block [control]="form.get('name')"></help-block>
    </div>

    <button a-button class="btn_primary" [pending]="pending$ | async" type="submit">Add</button>
    <button a-button class="btn_text" (click)="onClose()">Cancel</button>
  </form>
</a-center-popup>

