<div class="controls">
  <div class="icon" (click)="onCloseLeftMenu()" [style.visibility]="(stickyFlagEnabled$ | async) ? 'hidden' : 'visible'">
    <svg width="24" height="24">
      <use xlink:href="#close"></use>
    </svg>
  </div>

  <h2>Menu</h2>

  <div class="icon" [ngClass]="{sticky: stickyFlagEnabled$ | async}" (click)="onToggleSticky()">
    <svg width="24" height="24">
      <use xlink:href="#pin"></use>
    </svg>
  </div>
</div>

<div class="search_wrapper">
  <search-in-left-menu (onEsc)="onCloseLeftMenu()"
  ></search-in-left-menu>
</div>

