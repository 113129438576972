import { Component } from '@angular/core';
import { RouterNavigateService } from '../../../../shared/services/router-navigate.service';

@Component({
  selector: 'import-started-popup',
  templateUrl: './import-started-popup.component.html',
  styleUrls: ['./import-started-popup.component.scss']
})
export class ImportStartedPopupComponent {
  constructor(private _routerNav: RouterNavigateService) {}

  onClose() {
    this._routerNav.deactivatePopupOutlet();
  }
}
