<div *ngIf="!emptyBar">
  <div *ngIf="templateFormat == 'short' && estimate > 0">
    <div class="progress_line" [ngStyle]="estimateStyle">
      <div class="inner" [ngStyle]="loggedTimeStyle"></div>
    </div>
  </div>


  <div *ngIf="!templateFormat" class="estimate">
    <div class="timeline_container" *ngIf="estimate > 0">
      <div class="time_wrapper" (click)="onOpenEstimate()">
        <div class="tl_text">Hours Estimate</div>
        <div class="estimate_num">{{estimate | toHms}}</div>
      </div>
      <div class="timeline" [ngStyle]="estimateStyle">
        <div class="timing" [ngStyle]="loggedTimeStyle"></div>
      </div>
      <div class="time_wrapper_logged_time">
        <div class="tl_text">Logged Time</div>
        <div class="logged_num" [ngClass]="{'over_estimated': loggedTime > estimate}">{{loggedTime | toHms}}</div>
      </div>
    </div>

    <div class="logged_time" *ngIf="estimate == 0">
      <span class="logged_time_value">{{loggedTime | toHms}}</span>
    </div>

  </div>
</div>
