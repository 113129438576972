<div [ngClass]="{sidebar_block: true, editable: editPermissions}">
  <div [ngClass]="{duedate_overlay_link: true, editable: editPermissions}" (click)="onOpenDuedatePopup()"></div>
  <div class="task_sidebar_edit_link" *ngIf="editPermissions" (click)="onOpenDuedatePopup()">
    Edit
  </div>
  <h5 class="sidebar_block_title">Due Date</h5>
  <div class="duedate_date"
       [ngClass]="{
          'overdone': isTaskOverdone && !task.doneDate,
          'near_overdone': isTaskCloseToOverdone && !task.doneDate
       }"
  >
    {{task.dueDate | toDate: 'dueDate'}}
    <span *ngIf="daysLeft >= 0">({{daysLeft}} days left)</span>
  </div>

</div>
<sidebar-duedate-popup *ngIf="isEditDuePopupVisible$ | async"
                       [isOuterSubmit]="true"
                       [task]="task"
                       (onClosePopup)="onCloseDuedatePopup()"
                       (onSubmitValue)="onChangeDuedate($event)"
></sidebar-duedate-popup>
<div class="divider"></div>
