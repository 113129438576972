import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, ESInterface } from '../../../../ngrx/state/';
import { getEntitySelector } from '../../../../ngrx/functions/selectors';
import { BOARD_PL } from '../../../../constants/';
import { BehaviorSubject } from 'rxjs';
import { Board } from '../../../../interfaces/';

@Component({
  selector: 'board-input-picker',
  templateUrl: './board-input-picker.component.html',
  styleUrls: ['./board-input-picker.component.scss']
})
export class BoardInputPickerComponent implements OnInit, OnDestroy {
  @Input() selectedBoards: BehaviorSubject<number[]>;
  @Input() className: string;
  @Input() popupClassName = 'boards_pick_popup';

  public boardsEntities: { [id: number]: Board };
  public showAddPopUp = false;

  public boardsState: ESInterface<Board>;
  public subscription;

  constructor(private _store: Store<AppState>) {}

  ngOnInit(): any {
    this.subscription = this._store.pipe((getEntitySelector(BOARD_PL))).subscribe((state: ESInterface<Board>) => {
      this.boardsState = state;
      this.boardsEntities = state.entities;
    });
  }

  ngOnDestroy(): any {
    this.subscription.unsubscribe();
  }

  get selectedBoards$() {
    return this.selectedBoards;
  }

  onRemoveMember(userId) {
    this.selectedBoards$.next(this.selectedBoards$.getValue().filter(existsBoard => existsBoard !== userId));
  }
}
