export const BILLING = 'billing';
export const THERE_D_SECURE_POPUP = '3d-secure-popup';
export const THERE_D_SECURE_BACK_URL = '3d-secure-back-url';
export const PRICING = 'pricing';
export const PAYMENT_FORM = 'new-subscription';
export const EDIT_ADDITIONAL_DATA_FORM = 'edit-subscription';
export const CHANGE_CARD = 'change-card';

export const ROUTER_LINKS = {
  pricing: ['/', BILLING, PRICING],
  newSubscription: ['/', BILLING, PAYMENT_FORM],
  editSubscription: ['/', BILLING, EDIT_ADDITIONAL_DATA_FORM],
  changeCard: ['/', BILLING, CHANGE_CARD],
  [THERE_D_SECURE_BACK_URL]: ['/', BILLING, THERE_D_SECURE_BACK_URL],
  [THERE_D_SECURE_POPUP]: ['/', THERE_D_SECURE_POPUP]
};
