import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { RouterNavigateService } from '../../../../../shared/services/router-navigate.service';
import { INTEGRATION_LIST, IntegrationNames } from '../../../../core/integraition-list';
import { getHarvestBoardByBoard } from '../../../../../ngrx/reducers/harvest-board.reducer';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../ngrx/state';
import { AtlazApiV2Service } from '../../../../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { HarvestBoard } from '../../../../../interfaces/harvest-board';
import { HARVEST_BOARD_PL } from '../../../../../constants';
import { HandleResponseAction, RemoveEntityFromStoreAction } from '../../../../../ngrx/actions/root.action';
import { fromCamelToDash } from '../../../../../../helpers';
import { Features } from '../../../../../libs/paywall/features.constants';
import { PaywallService } from '../../../../../libs/paywall/paywall.service';
import { ToastrService } from 'ngx-toastr';
import { switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'rmenu-integration-list',
  templateUrl: './rmenu-integration-list.component.html',
  styleUrls: ['./rmenu-integration-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RmenuIntegrationListComponent {
  @Input() boardId: number;
  @Input() divider;
  public integrationList = INTEGRATION_LIST.filter(i => !i.isGlobal);

  constructor(
    private _routerNav: RouterNavigateService,
    private _store: Store<AppState>,
    private _apiV2: AtlazApiV2Service,
    private _toastr: ToastrService,
    private _paywall: PaywallService
  ) {}

  onOpenIntegration(integrationName: string) {
    switch (integrationName) {
      case IntegrationNames.Slack: {
        return this._routerNav.navigateToSlackIndex(this.boardId);
      }
      case IntegrationNames.Github: {
        return this._routerNav.navigateToGithubSettings();
      }
      case IntegrationNames.Bitbucket: {
        return this._routerNav.navigateToBitbucketSettings();
      }
      case IntegrationNames.Gitlab: {
        return this._routerNav.navigateToGitlabSettings();
      }
      case IntegrationNames.Harvest: {
        this.toggleHarvestIntegration();
      }
    }
  }

  private toggleHarvestIntegration() {
    if (this._paywall.isFeatureEnabled(Features.Harvest)) {
      this._store
        .select(getHarvestBoardByBoard(this.boardId))
        .pipe(
          take(1),
          switchMap((harvestBoard: HarvestBoard) => {
            if (harvestBoard.enabled) {
              return this._apiV2.deleteRequest([fromCamelToDash(HARVEST_BOARD_PL), harvestBoard.id]);
            } else {
              return this._apiV2.post(fromCamelToDash(HARVEST_BOARD_PL), { board: this.boardId });
            }
          })
        )
        .subscribe(
          resp => {
            if (resp) {
              this._store.dispatch(new HandleResponseAction(resp));
              this._toastr.success('Time Tracking with Harvest was enabled.');
            } else {
              this._store.dispatch(
                new RemoveEntityFromStoreAction({
                  entityName: HARVEST_BOARD_PL,
                  predicate: entity => entity.board === this.boardId
                })
              );
              this._toastr.success('Time Tracking with Harvest was disabled.');
            }
          },
          error => console.error(error)
        );
    } else {
      this._paywall.showPayWall(Features.Harvest);
    }
  }
}
