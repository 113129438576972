<ng-container [ngSwitch]="(pageStatus$ | async)?.statusCode">
  <div class="error_page" *ngSwitchCase="STATUS_CODES.FORBIDDEN">
    <div class="error_page_name">403</div>
    <img src="/assets/img/403-error.png" alt="" class="error_page_img">
    <div class="error_page_title">Sorry!</div>
    <div class="error_page_text">The access to this task is forbidden!</div>
  </div>
  <div class="error_page" *ngSwitchCase="STATUS_CODES.NOT_FOUND">
    <div class="error_page_name">404</div>
    <img src="/assets/img/404-error.png" class="error_page_img">
    <div class="error_page_title">Whoops!</div>
    <div class="error_page_text">The task you are looking for doesn't exist!</div>
  </div>
  <task-expanded *ngSwitchCase="STATUS_CODES.OK"></task-expanded>
  <div class="error_page" *ngSwitchCase="STATUS_CODES.CONNECTION_PROBLEM">
    <div class="error_page_name">&nbsp;</div>
    <img src="/assets/img/404-error.png" class="error_page_img">
    <div class="error_page_title">Whoops!</div>
    <div class="error_page_text">Looks like you have some problems with internet connection. Please, refresh the webpage.</div>
  </div>
  <div class="error_page" *ngSwitchCase="undefined">
    <a-loader></a-loader>
  </div>
  <!--<div class="error_page" *ngSwitchCase="undefined">-->
    <!--<div class="error_page_name">Whoops!</div>-->
    <!--<img src="/assets/img/404-error.png" class="error_page_img">-->
    <!--<div class="error_page_title">Sorry!</div>-->
    <!--<div class="error_page_text">Sorry, we have encountered some issues while opening the task and are already working on fixing it. Please, refresh the webpage.</div>-->
  <!--</div>-->
  <div class="error_page" *ngSwitchDefault>
    <div class="error_page_name">500</div>
    <img src="/assets/img/404-error.png" alt="" class="error_page_img">
    <div class="error_page_title">Sorry!</div>
    <div class="error_page_text">Sorry, we have encountered some issues on our server and are already working on fixing it.  Please, refresh the webpage in a few minutes.</div>
  </div>
</ng-container>
