import { Component, Input } from '@angular/core';
import { SprintBoard } from '../../interfaces/boards/sprint-board';

@Component({
  selector: 'released-sprint-right-menu',
  templateUrl: './released-sprint-right-menu.component.html'
})
export class ReleasedSprintRightMenuComponent {
  @Input() board: SprintBoard;
}
