import { map, publishReplay, refCount, take } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { TaskActivity, User } from '../../../interfaces';
import { activityType } from '../../../constants';
import { PartOfEntity } from '../../../interfaces/part-of-entity';
import { AuthService } from '../../../shared/services/app/auth.service';
import { TaskUnsavedDataService } from '../../shared/services/task-unsaved-data.service';
import { getMentionsFromText, issetMentionsInText, mentionsWithoutUser } from '../../../../helpers/mention';
import { filterDuplicateItem } from '../../../../helpers/array';
import { fromExternalUsers } from '../../../ngrx/reducers/external-user.reducer';
import { fromUsers } from '../../../ngrx/reducers/user.reducer';
import { AppState } from '../../../ngrx/state';
import { Store } from '@ngrx/store';
import { of } from 'rxjs/internal/observable/of';
import { ExternalUser } from '../../../interfaces/external-user';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';

@Component({
  selector: 'activity-detail',
  templateUrl: './activity-detail.component.html',
  styleUrls: ['./activity-detail.component.scss']
})
export class ActivityDetailComponent implements OnInit {
  @Input() taskActivity: TaskActivity;
  @Input() boardUsers: Observable<User[]>;
  @Input() replays: TaskActivity[] = [];
  @Input() editPermissions = false;

  @Output() saveComment: EventEmitter<any> = new EventEmitter();
  @Output() deleteComment: EventEmitter<TaskActivity> = new EventEmitter<TaskActivity>();
  @Output() resetFocus = new EventEmitter();

  public isDeleteCommentVisible = false;
  public isEditForm = false;
  public isReplyForm = false;
  public isReplyAllForm = false;
  public canReplayAll = false;
  public commentUser$: Observable<User>;
  public externalUser$: Observable<ExternalUser | null>;
  public visibleName$: Observable<string>;

  public isComment: boolean;
  public activeUserId: number;
  public isUnsavedComment = false;

  constructor(
    private _auth: AuthService,
    private _taskUnsavedDataService: TaskUnsavedDataService,
    private _store: Store<AppState>
  ) {}

  ngOnInit() {
    this.isComment = this.taskActivity.type === activityType.comment;
    this.commentUser$ = this._store
      .select(fromUsers.getById(this.taskActivity.user))
      .pipe(publishReplay(1), refCount());

    this.externalUser$ = this.taskActivity.externalUser
      ? this._store.select(fromExternalUsers.getById(this.taskActivity.externalUser))
      : of(null);
    this.visibleName$ = combineLatest(this.commentUser$, this.externalUser$).pipe(
      map(([user, externalUser]) => (externalUser ? externalUser.name : user ? user.fullname : ''))
    );

    this.activeUserId = this._auth.activeUserId;
    this.initUnsavedEditCommentStatus();

    this.commentUser$.pipe(take(1)).subscribe(user => {
      this.initReplayControl(user);
    });
  }

  initReplayControl(user: User) {
    const mentionsInText = issetMentionsInText(this.taskActivity.preparedText, [this._auth.activeUser]);
    if (mentionsInText) {
      const commentMention = this.taskActivity.externalUser ? '' : '@' + user.nickname;
      const mentionsFromText = getMentionsFromText(this.taskActivity.preparedText);
      const combineMentions = [commentMention, ...mentionsFromText]
        .filter(mentionsWithoutUser(this._auth.activeUser))
        .filter(filterDuplicateItem);

      this.canReplayAll = combineMentions.length > 1;
    }
  }

  initUnsavedEditCommentStatus() {
    if (this.taskActivity && this.taskActivity.type === 'comment') {
      this._taskUnsavedDataService.taskUnsavedEditComments
        .pipe(take(1))
        .subscribe(value => (this.isUnsavedComment = !!value[this.taskActivity.id]));
    }
  }

  onSaveComment(commentData: any) {
    this.emitSaveComment(commentData);
  }

  onToggleDeleteComment() {
    this.isDeleteCommentVisible = !this.isDeleteCommentVisible;
  }

  onDeleteComment() {
    this.emitDeleteComment(this.taskActivity);
  }

  onClickOut() {
    this.initUnsavedEditCommentStatus();
    this.isEditForm = false;
  }

  onDiscardUnsavedEditComment() {
    this._taskUnsavedDataService.clearUnsavedEditComment(this.taskActivity.id);
    this.initUnsavedEditCommentStatus();
  }

  emitSaveComment(commentData: PartOfEntity) {
    this.saveComment.emit(commentData);
  }

  emitDeleteComment(taskComment: TaskActivity) {
    this.deleteComment.emit(taskComment);
  }

  onChildChangedFocus(event) {
    this.resetFocus.emit(event);
  }

  onClickReply() {
    this.isReplyForm = true;
  }

  onClickReplyALL() {
    this.isReplyForm = true;
    this.isReplyAllForm = true;
  }

  onCancelReplyForm() {
    this.isReplyForm = false;
    this.isReplyAllForm = false;
  }
}
