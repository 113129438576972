import { getEnvironmentParam } from '../../../environments/helper';
import {
  BITBUCKET_COMPANY_PL,
  GITHUB_COMPANY_PL,
  GITLAB_COMPANY_PL,
  HARVEST_BOARD_PL,
  INTERCOM_COMPANY_PL,
  JIRA_COMPANY_PL,
  SLACK_COMPANY_PL
} from '../../constants';

export enum IntegrationNames {
  Slack = 'slack',
  Github = 'github',
  Bitbucket = 'bitbucket',
  Gitlab = 'gitlab',
  Intercom = 'intercom',
  Jira = 'jira',
  Harvest = 'harvest'
}

export const INTEGRATION_MAP = {
  slack: {
    name: IntegrationNames.Slack,
    logo: getEnvironmentParam('pathToAssets') + '/ico-slack-72.png',
    label: 'Slack',
    entityCode: SLACK_COMPANY_PL
  },
  github: {
    name: IntegrationNames.Github,
    logo: getEnvironmentParam('pathToAssets') + '/ico-github-mark-64.png',
    label: 'GitHub',
    entityCode: GITHUB_COMPANY_PL
  },
  bitbucket: {
    name: IntegrationNames.Bitbucket,
    logo: getEnvironmentParam('pathToAssets') + '/ico-bitbucket-blue.svg',
    label: 'Bitbucket',
    entityCode: BITBUCKET_COMPANY_PL
  },
  gitlab: {
    name: IntegrationNames.Gitlab,
    logo: getEnvironmentParam('pathToAssets') + '/ico-gitlab.svg',
    label: 'GitLab',
    entityCode: GITLAB_COMPANY_PL
  },
  intercom: {
    name: IntegrationNames.Intercom,
    logo: getEnvironmentParam('pathToAssets') + '/ico-intercom.svg',
    label: 'Intercom',
    entityCode: INTERCOM_COMPANY_PL,
    isGlobal: true
  },
  jira: {
    name: IntegrationNames.Jira,
    logo: getEnvironmentParam('pathToAssets') + '/ico-jira.svg',
    label: 'Jira',
    entityCode: JIRA_COMPANY_PL,
    isGlobal: true
  },
  harvest: {
    name: IntegrationNames.Harvest,
    logo: getEnvironmentParam('pathToAssets') + '/ico-harvest.png',
    label: 'Harvest',
    entityCode: HARVEST_BOARD_PL
  }
};

export const INTEGRATION_LIST = Object.keys(INTEGRATION_MAP).reduce(addItem(INTEGRATION_MAP), []);

function addItem(source) {
  return (acc: any[], itemId) => [...acc, source[itemId]];
}
