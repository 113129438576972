<div class="color_block" (click)="isThemeChoosing = true">
  <h4>Background Color</h4>
  <span class="current_color" [style.background-color]="this.currentColor$ | async"></span>
  <span class="edit_link">Edit</span>
</div>

<div a-context-popup
     class="duedate_popup"
     className="medium_popup"
     *ngIf="isThemeChoosing"
     [title]="'Board Colors'"
     (close)="onCloseThemeChoosingPopup()"
>
  <div class="themes-list">
    <div class="theme-item" *ngFor="let theme of (allThemes$ | async)" (click)="onSetBoardTheme(theme)" [style.background-color]="theme.headerColor"></div>
  </div>
</div>
