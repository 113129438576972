import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PermissionsService } from '../../../permissions/permissions.service';
import { PaywallService } from '../../../libs/paywall/paywall.service';
import { Features } from '../../../libs/paywall/features.constants';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'quick-add',
  templateUrl: './quick-add.component.html',
  styleUrls: ['./quick-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickAddComponent {
  public addTaskPopup = false;
  public addBoardPopup = false;
  public addProjectPopup = false;
  public isMenuVisible = false;
  public isSuperUser$: Observable<boolean> = this._permissions.isSuperUser$;

  constructor(private _permissions: PermissionsService, private _paywall: PaywallService) {}

  onClickAddButton() {
    this.onToggleMenu();
  }

  onToggleMenu() {
    this.isMenuVisible = !this.isMenuVisible;
  }

  onClickAddTask() {
    if (this._paywall.isFeatureEnabled(Features.CanAddTask)) {
      this.addTaskPopup = true;
    } else {
      this._paywall.showPayWall(Features.CanAddTask);
    }
  }

  onClickAddBoard() {
    this.addBoardPopup = true;
  }

  onClickAddProject() {
    this.addProjectPopup = true;
  }

  onCloseAddTaskPopup() {
    this.addTaskPopup = false;
  }

  onCloseAddBoardPopup() {
    this.addBoardPopup = false;
  }

  onCloseAddProjectPopup() {
    this.addProjectPopup = false;
  }
}
