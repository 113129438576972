import { distinctUntilChanged, map, take } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState, LEFT_MENU } from '../../../ngrx/state';
import { boardType, PROJECT_PL } from '../../../constants';
import { GetCompleteAction } from '../../../ngrx/actions/root.action';
import { RouterNavigateService } from '../../services/router-navigate.service';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observer, Observable, Subscription } from 'rxjs';
import * as ROUTER_PATH from '../../../path.routing';
import { SegmentService } from '../../../atlaz-bnp/services/intergations/segment/segment.service';
import { FormComponent, FormSaveType, FormServiceParams, FormV2Service } from '../../services/form-v2.service';
import { JsonApiSingeModelResponse, jsonApiToEntityState } from '../../services/app/web-socket/http-response';
import { ComponentListenClickoutAction } from '../../../ngrx/actions/component.actions';
import { ModelValidators } from '../../validators/model-validators';
import { NavigateToOnboardingTaskService } from '../../services/navigate-to-onboarding-task.service';
import { fromBoardsTemplates } from '../../../ngrx/reducers/boards-template.reducer';
import { BoardsTemplate } from '../../../interfaces';
import { fromBoards } from '../../../ngrx/reducers/board.reducer';

@Component({
  selector: 'create-new-project-form',
  templateUrl: './create-new-project-form.component.html',
  styleUrls: ['./create-new-project-form.component.scss'],
  providers: [NavigateToOnboardingTaskService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateNewProjectFormComponent implements OnInit, FormComponent, OnDestroy {
  @Input() submit$: Observable<any>;
  @Input() source = 'leftMenu';
  @Input() autoFocus = true;
  @Output() isValidForm: EventEmitter<boolean> = new EventEmitter();
  @Output() closePopup: EventEmitter<boolean> = new EventEmitter();

  public goToProjectSettings$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public selectedTemplatesIds;
  public form: FormGroup;
  public formObserver: Observer<any> = {
    next: (resp: JsonApiSingeModelResponse<any>) => {
      this._store.dispatch(new ComponentListenClickoutAction({ name: LEFT_MENU, listenClickout: true }));
      const project = resp.data;
      this._store.dispatch(new GetCompleteAction(jsonApiToEntityState(resp)));
      if (Array.isArray(this.selectedTemplatesIds)) {
        let templateNames = '';
        this._store
          .select(fromBoardsTemplates.getAll)
          .pipe(
            map(allTemplates => allTemplates.filter(template => this.selectedTemplatesIds.includes(template.id))),
            take(1)
          )
          .subscribe(templates => {
            templateNames = templates.map((item: BoardsTemplate) => item.name).join();
          });
        this._segment.onboardingTemplatesChosen(templateNames, this.selectedTemplatesIds.join());
        this._segment.projectCreated('onboardingTemplates');
        this._store
          .select(fromBoards.getAllAvailable)
          .pipe(map(boards => boards.filter(board => board.isPreset && board.type === boardType.kanban)), take(1))
          .subscribe(boards => {
            if (Array.isArray(boards) && boards.length) {
              this._routerNav.navigateToBoard(boards[0].id).then(() => {
                //this.subs.push(this._onboardingNavigateService.checkTaskToOpen().subscribe());
                this._routerNav.deactivatePopupOutlet();
              });
            } else {
              this._routerNav.navigateToOverview().then(() => {
                //this.subs.push(this._onboardingNavigateService.checkTaskToOpen().subscribe());
                this._routerNav.deactivatePopupOutlet();
              });
            }
          });
      } else if (this.goToProjectSettings$.getValue()) {
        this.onToBackWithRedirect(project.id);
        this._segment.projectCreated(this._activatedRoute.snapshot.queryParams['source'] || this.source);
      } else {
        this.onToBack();
        this._segment.projectCreated(this._activatedRoute.snapshot.queryParams['source'] || this.source);
      }
    },
    error: () => {},
    complete: () => {}
  };
  public formServiceParams: FormServiceParams = {
    saveType: FormSaveType.add,
    entityToEdit: PROJECT_PL,
    formObserver: this.formObserver,
    prepareFormValue: formValue => {
      if (this.selectedTemplatesIds) {
        formValue = { ...formValue, withTemplates: this.selectedTemplatesIds };
      }
      return formValue;
    }
  };
  public subscriptions: Subscription[] = [];

  constructor(
    private _store: Store<AppState>,
    private _fb: FormBuilder,
    private _routerNav: RouterNavigateService,
    private _segment: SegmentService,
    private _activatedRoute: ActivatedRoute,
    public _formService: FormV2Service,
    private _onboardingNavigateService: NavigateToOnboardingTaskService
  ) {}

  ngOnInit() {
    this.initForm();
    const sub = this.submit$.subscribe(templatesIds => {
      if (templatesIds) {
        this.selectedTemplatesIds = templatesIds;
      }
      this.onSubmit();
    });

    this.subscriptions.push(sub);

    const isPreventTransition = this._activatedRoute.snapshot.queryParams['preventTransition'];

    if (isPreventTransition) {
      this.goToProjectSettings$.next(false);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  initForm() {
    this.form = this._fb.group({
      name: ['', ModelValidators.project.name],
      shortName: ['', ModelValidators.project.shortName]
    });

    this._formService.initFormParams(this.form, this.formServiceParams);

    const sub = this.form.statusChanges
      .pipe(distinctUntilChanged())
      .subscribe(_ => this.isValidForm.emit(this.form.valid));

    this.subscriptions.push(sub);
  }

  onSubmit() {
    this._formService.markAsDirty();
    if (this.form.invalid) {
      return;
    }
    this._formService.submit();
  }

  onToBack() {
    this.closePopup.emit(true);
  }

  onToBackWithRedirect(projectId) {
    this.closePopup.emit(true);
    this._routerNav.navigate([
      {
        outlets: {
          primary: [ROUTER_PATH.PROJECT, projectId, ROUTER_PATH.SETTINGS, ROUTER_PATH.BOARDS],
          popup: null
        }
      }
    ]);
  }
}
