<form [formGroup]="checkboxForm"
      [clickOut]="true"
      (clickOutEvent)="onCancel()"
>
  <div class="form_group">
    <link-task-input
      #taskInput
      formControlName="name"
      [blackList]="[taskId]"
      [isMultipleUsage]="true"
      [enableRoadmapTasks]="true"
      [isOpenTasksOnBlankPage]="true"
      tabindex="-1"
    ></link-task-input>
  </div>
  <div class="submit_buttons">
    <button type="button" a-button class="btn_primary" (click)="onSubmit()">Save</button>
    <button type="button" a-button class="btn_text" (click)="onCancel()">Cancel</button>
  </div>
</form>
