import { take, filter, tap, switchMap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { PhoneCodesService } from '../../shared/services/phone-codes.service';
import { AppState } from '../../ngrx/state/app-state';
import * as fromBillingSubscription from '@atlaz/billing/store/subscription/subscription.reducer';
import { isPresent } from '../../../helpers/object';
import { BillingPublicApiService } from '@atlaz/billing/shared/billing-public-api.service';
import { RouterNavigateService } from '../../shared/services/router-navigate.service';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';

@Injectable()
export class PaymentFormGuardService implements CanActivate {
  constructor(
    private _store: Store<AppState>,
    private _routerNav: RouterNavigateService,
    private _billingService: BillingPublicApiService,
    private _phoneCodesService: PhoneCodesService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // we'll just wait until all data are ready
    return this._billingService.hasAccess$.pipe(
      tap(hasAccess => {
        if (!hasAccess) {
          return this._routerNav.navigateToOverview();
        }
      }),
      switchMap(v =>
        combineLatest([
          this._store.select(fromBillingSubscription.getCompanyBillingSubscription).pipe(filter(isPresent), take(1)),
          this._phoneCodesService.getPhoneCodes().pipe(take(1))
        ]).pipe(map(([a, b]) => !!(a && b)))
      )
    );
  }
}
