import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { PaywallService } from '../paywall.service';
import * as ROUTER_PATH from '../../../path.routing';
import { Features } from '../features.constants';

const urlToFeatureMap = {
  [ROUTER_PATH.SLACK]: Features.Slack,
  [ROUTER_PATH.GITHUB_SETTINGS]: Features.Github,
  [ROUTER_PATH.GITLAB_SETTINGS]: Features.Gitlab,
  [ROUTER_PATH.BITBUCKET_SETTINGS]: Features.Bitbucket,
  [ROUTER_PATH.TIMESHEET]: Features.TimesheetReport,
  [ROUTER_PATH.BACKLOG_CHART]: Features.PriorityChart,
  [ROUTER_PATH.SCORING_TABLE + '/:boardId']: Features.PriorityChart,
  [ROUTER_PATH.VELOCITY_REPORT + '/:boardId']: Features.SprintsVelocityChart,
  [ROUTER_PATH.SPRINT_BOARD_HISTORY + '/:boardId']: Features.SprintsHistory
};

@Injectable()
export class FeaturePayWallGuardService implements CanActivate {
  constructor(private _paywall: PaywallService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const path = route.routeConfig.path;
    const featureName = urlToFeatureMap[path];
    if (!this._paywall.isFeatureEnabled(featureName)) {
      this._paywall.showPayWall(featureName);
    }
    return this._paywall.isFeatureEnabled(featureName);
  }
}
