import { throwError as observableThrowError, BehaviorSubject, Observer, Observable } from 'rxjs';

import { refCount, publishReplay, map } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BugTrackerService } from '@atlaz/core/services/bag-tracker.service';
import { HttpQueryParam } from '../../interfaces';
import { AtlazApiV2Service, patchPath } from './atlaz-api/v2/atlaz-api-v2.service';
import { FormStatuses } from '../../constants/';
import { isObject } from '../../../helpers/';
import { ServerError, ServerErrorResponse } from '../../interfaces/server-error';

export enum FormSaveType {
  add = 1,
  edit = 2,
  remove = 3
}

export interface FormServiceParams {
  formObserver: Observer<any>;
  saveType: FormSaveType | '';
  entityToEdit: string | any[];

  prepareFormValue?: (formValue: {}) => any;
  httpQueryParams?: HttpQueryParam;
}

export interface FormComponent {
  _formService: FormV2Service;

  form: FormGroup;
  formServiceParams: FormServiceParams;

  onSubmit: () => any;
}

@Injectable()
export class FormV2Service implements OnDestroy {
  public entityToEdit: any[];

  public serverError: ServerErrorResponse;

  /**
   * general list of errors
   */
  public errors: string[];

  public formServiceObserver: Observer<any> = {
    next: x => {},

    error: (error: ServerErrorResponse) => {
      // mark as error
      this.markAsError();
      this.setFormFieldsError(error);
      this.serverError = error;
      this.markAsError();
    },

    complete: () => {
      // somtething
      this.markAsAvailable();
      console.log('form observer complete');
    }
  };

  protected observerContainer = [this.formServiceObserver];

  protected _formStatus$: BehaviorSubject<number> = new BehaviorSubject(FormStatuses.available);

  protected _form: FormGroup;
  protected _formParams: FormServiceParams;

  protected requestDetails = {};

  constructor(protected _atlazApiV2: AtlazApiV2Service, private _bugTracker: BugTrackerService) {}

  ngOnDestroy() {
    console.log('FormService Destroy');
  }

  initFormParams(form: FormGroup, formServiceParams: FormServiceParams) {
    this._form = form;
    this._formParams = formServiceParams;
    this.resetObserversToDefault();
    this.registerObserver(formServiceParams.formObserver);
  }

  get isPending$(): Observable<boolean> {
    return this._formStatus$.pipe(map(this.isPending));
  }

  get isError$(): Observable<boolean> {
    return this._formStatus$.pipe(map(this.isError));
  }

  get isAvailable$(): Observable<boolean> {
    return this._formStatus$.pipe(map(this.isAvailable));
  }

  get pendingSnapshot() {
    return this.isPending(this._formStatus$.getValue());
  }

  get availableSnapshot() {
    return this.isAvailable(this._formStatus$.getValue());
  }

  get errorSnapshot() {
    return this.isError(this._formStatus$.getValue());
  }

  submit() {
    if (this.pendingSnapshot) {
      return false;
    }

    this.markAsPending();
    const submitData$ = this.sendRequest().pipe(publishReplay(1), refCount());

    // we don't need unsubscribe because submitData$ stream will be complete after receirving response
    this.observerContainer.forEach(observer => submitData$.subscribe(observer));
  }

  registerObserver(observer: Observer<any>) {
    this.observerContainer = [...this.observerContainer, observer];
  }

  unRegisterObserver(observer: Observer<any>) {
    this.observerContainer = this.observerContainer.filter(item => item !== observer);
  }

  resetObserversToDefault() {
    this.observerContainer = [this.formServiceObserver];
  }

  markAsAvailable() {
    this.markAs(FormStatuses.available);
  }

  markAsError() {
    this.markAs(FormStatuses.error);
  }

  markAsPending() {
    this.markAs(FormStatuses.pending);
  }

  markAsDirty() {
    Object.keys(this._form.controls).map(controlName => {
      this._form.get(controlName).markAsDirty();
      this._form.get(controlName).markAsTouched();
      this._form.get(controlName).updateValueAndValidity();
    });
  }

  protected sendRequest() {
    const value = this.prepareFormValue();
    const httpQueryParams = this._formParams.httpQueryParams || {};

    const path = patchPath(this._formParams.entityToEdit, httpQueryParams);

    switch (this._formParams.saveType) {
      case FormSaveType.add: {
        this.requestDetails = {
          request: 'post',
          v2: true,
          url: path,
          payload: value
        };
        return this._atlazApiV2.post(path, value);
      }

      case FormSaveType.edit: {
        this.requestDetails = {
          request: 'patch',
          v2: true,
          url: path,
          payload: value
        };
        try {
          if (!value.hasOwnProperty('id')) {
            throw new Error(`Invalid form fields. Form must contain id property`);
          }
          return this._atlazApiV2.patch(path, value);
        } catch (e) {
          console.error(e);
          return observableThrowError(e);
        }
      }
      case FormSaveType.remove: {
        try {
          if (!value.hasOwnProperty('id')) {
            throw new Error(`Invalid form fields. Form must contain id property`);
          }
          const removePath = patchPath(path, value.id);
          this.requestDetails = {
            request: 'delete',
            v2: true,
            url: removePath,
            payload: value
          };
          return this._atlazApiV2.deleteRequest(removePath);
        } catch (e) {
          console.error(e);
          return observableThrowError(e);
        }
      }

      default: {
        return observableThrowError(
          new Error('unexpected value of saveType property. Only "add" "edit" types allowed')
        );
      }
    }
  }

  protected prepareFormValue() {
    return this._formParams.hasOwnProperty('prepareFormValue')
      ? this._formParams.prepareFormValue(this._form.value)
      : this._form.value;
  }

  protected isPending(status): boolean {
    return FormStatuses.pending === status;
  }

  protected isError(status): boolean {
    return FormStatuses.error === status;
  }

  protected isAvailable(status): boolean {
    return FormStatuses.available === status;
  }

  protected markAs(status: number) {
    this._formStatus$.next(status);
  }

  protected logError(err, message) {
    this._bugTracker.warn(err, { message, ...this.requestDetails });
  }

  public normalizeServerErrorResponse(originalError) {
    if (!originalError || !isObject(originalError)) {
      this.logError(originalError, 'Internal Server error');
      return {
        message: 'Internal Server error. Please contact support if the problem persists.'
      };
    }
    const error = originalError.error;
    if (!error || (!error.errors && !error.message)) {
      this.logError(originalError, 'Unknown Server error');
      return {
        message: 'Unknown error. Please contact support if the problem persists.'
      };
    }
    return error;
  }

  protected setFormFieldsError(originalError: ServerErrorResponse) {
    const error = this.normalizeServerErrorResponse(originalError);
    this.errors = [];
    if (error.hasOwnProperty('errors')) {
      this.markAsInvalidFormFields(error['errors']);
    } else if (error.hasOwnProperty('message') && error['message']) {
      this.errors.push(error['message']);
    }
  }

  protected markAsInvalidFormFields(errors: ServerError[]) {
    errors.forEach(this.markAsInvalidFormField.bind(this));
  }

  protected markAsInvalidFormField(error: ServerError) {
    const field = this._form.get(error.field);
    this.errors.push(error.message);
    if (!!field) {
      field.markAsTouched();
      field.setErrors({ serverError: true });
    }
  }
}
