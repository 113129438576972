import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable ,  Subject } from 'rxjs';
import { AppState, runParametricalSelector } from '../../../../../ngrx/state/';
import { IntegrationNames } from '../../../../core/integraition-list';
import * as fromIntegration from '../../../../core/store/integration.reducer';
import { GitlabCommit } from '../../../models/git-commit';
import * as gitlabCommit from '../../../store/gitlab-commit/gitlab-commit.actions';
import * as fromGitlabCommit from '../../../store/gitlab-commit/gitlab-commit.reducer';

@Component({
  selector: 'gitlab-task-commit-list',
  templateUrl: './gitlab-task-commit-list.component.html',
  styleUrls: ['./gitlab-task-commit-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GitlabTaskCommitComponent implements OnInit, OnDestroy {
  @Input() taskId$: Observable<number>;

  public taskGitCommits$: Observable<GitlabCommit[]>;
  public isInstalled$: Observable<boolean> = this._store.select(fromIntegration.getIsInstalledGitlab);
  public name = IntegrationNames.Gitlab;

  private destroy$ = new Subject();

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this.taskGitCommits$ = runParametricalSelector(this._store.select(fromGitlabCommit.getTaskCommits), this.taskId$);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  onRemove(commit: GitlabCommit) {
    this._store.dispatch(new gitlabCommit.DeleteTaskCommit({ id: commit.id, taskId: commit.task }));
  }
}
