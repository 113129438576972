<input class="quick_search_members"
       [focus]="true"
       placeholder="Search Members..."
       #input_seacrh_members
       (keyup)="0"
>
<div class="users_wrapper">
  <div *ngFor="let user of members | usersNameFilter: input_seacrh_members.value"
       class="user_title"
       (click)="onSelectMember(user)"
  >
    <div class="avatar_container">
      <a-avatar
        [user]="user"
      ></a-avatar>
    </div>
    <div class="member_info">
      <div class="member_info_name"> {{user.firstname}} {{user.lastname}}</div>
      <div class="member_info_text">@{{user.nickname}}</div>
    </div>
    <span class="check_label" *ngIf="selectedMemberId === user.id"></span>
  </div>
</div>

