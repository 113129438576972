<a-center-container>
  <div a-popup
       title="Delete your current payment details"
       (close)="onDismiss()"
       class="popup"
  >
    <p class="message">
      You will not be able to renew your subscription until you add new payment details.
    </p>
    <div>
      <div a-button class="btn_warn" [pending]="_formService.isPending$ | async" (click)="onSubmit()">Delete</div>
      <div a-button class="btn_secondary" (click)="onDismiss()">Cancel</div>
    </div>
  </div>
  <a-overlay (click)="onDismiss()"></a-overlay>
</a-center-container>
