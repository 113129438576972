import { AppState, ESInterface } from '../state';
import { COMPANY_FEATURE_PL } from '../../constants';
import { createSelector } from 'reselect';
import { CompanyFeatures } from '../../interfaces/company-features';

export namespace fromCompanyFeatures {
  export const companyFeaturesState = (store: AppState) => store[COMPANY_FEATURE_PL];
  export const get = createSelector(
    companyFeaturesState,
    (features: ESInterface<CompanyFeatures>) => features.entities[features.ids[0]]
  );

  export const enabledFeatures = (store: AppState) => get(store).features;

  export const isFeatureEnabled = featureName => (store: AppState) => enabledFeatures(store)[featureName];
}
