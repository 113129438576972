import { Board, Column, RoadMapFields, Swimlane, Task } from '../../../interfaces';
import { RoadMapAddFormTypes, RoadMapScale } from '../constants/roadmap.constants';
import { WorkingDays } from '@atlaz/working-days/interfaces/working-days.interface';
import { RoadmapCells, RoadmapCellsHeaders } from '../chart/interfaces/chart.interfaces';

export interface RoadMapAddFormInterface {
  parent: number;
  type: RoadMapAddFormTypes;
  insertAfter?: number;
}

export interface RoadmapChart {
  startTs: number;
  endTs: number;
  cells: RoadmapCells[];
  cellsHeaders: RoadmapCellsHeaders[];
  cellsMap: {
    [dayId: string]: RoadmapCells;
  };
  todayLeftPx: number;
  // in px
  width: number;
  itemPositions: {
    [itemId: number]: { leftPx: number; rightPx: number };
  };
}

export interface RoadMap {
  id: number;
  board: Board;
  addForm: boolean | RoadMapAddFormInterface;
  defaultColumn: Column;
  defaultColumnId: number;
  defaultSwimlaneId: number;
  defaultSwimlane: Swimlane;
  minStartDate: number;
  maxEndDate: number;
  tasksLoaded: boolean;
  taskTree: RoadMapItem[];
  hiddenItems: HiddenItems;
  chart: RoadmapChart;
  workingDays?: WorkingDays;
  minChartWidthPx: number;
  scale: RoadMapScale;
  today: string;
  forPrint?: boolean;
  weekStartDate: number;
}

export interface RoadMapItem {
  id: number;
  parent: number;
  position: number;
  shortName: string;
  url: string | any[];
  roadMapLinkedTaskFields?: RoadMapFields;
  type: string;
  title: string;
  collapsed?: boolean;
  collapsedState: string;
  task: Task;
  isLink: boolean;
  isProject: boolean;
  taskLinkObject?: Task;
  durationDays: number;
  startDate: number;
  endDate: number;
  columnArchived: number;
  archived: number;
  released?: number;
  projectArchived?: number;
  boardClosed?: number;
  subItems: RoadMapItem[];
}

export interface HiddenItems {
  [id: number]: boolean;
}

export const RoadmapColors = [
  '#4f9ee3',
  '#ffca28',
  '#ff8c00',
  '#ff1a36',
  '#00bcf2',
  '#8e69df',
  '#9ccc65',
  '#f30092',
  '#6c7e9a'
];
export const RoadmapDefaultColor = '#00b294';
export const RoadmapDefaultChildColor = '#4f9ee3';
