export enum progressTypes {
  tasksProgress = 'TASKS_PROGRESS',
  pointsProgress = 'POINTS_PROGRESS',
  hoursProgress = 'HOURS_PROGRESS',
  hoursCount = 'HOURS_COUNT',
  daysLeft = 'DAYS_LEFT'
}
export const progressTypesArray = [
  progressTypes.tasksProgress,
  progressTypes.pointsProgress,
  progressTypes.hoursProgress,
  progressTypes.hoursCount,
  progressTypes.daysLeft
];
