
import {interval as observableInterval,  Subscription ,  Observable } from 'rxjs';
import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-mini-ts';
import { DateTimeFormatterService } from '../../../libs/date-time-formatter/services/date-time-formatter.service';

const getTimeAgo = (unixTimeStamp: number, format: 'long' | 'short', _dateTimeFormatter: DateTimeFormatterService) => {
  const secondsDiff = moment().unix() - unixTimeStamp;

  switch (true) {
    case secondsDiff < 45:
      return 'just now';
    case secondsDiff < 89 * 45:
      return moment.unix(unixTimeStamp).fromNow();
    default:
      let momentObj = moment.unix(unixTimeStamp);
      let dateFormat = _dateTimeFormatter.getSuitableDateFormat(momentObj);
      if (format === 'long') {
        if (momentObj.isSame(moment(), 'day')) {
          // date - to today
          dateFormat = '[Today]';
        }
        if (momentObj.isSame(moment().add({ d: -1 }), 'day')) {
          // date - to yesterday
          dateFormat = '[Yesterday]';
        }
      } else {
        if (momentObj.isSame(moment(), 'day')) {
          // no date just time
          dateFormat = '[]';
        }
      }
      return momentObj.format(dateFormat + ' [at] ' + _dateTimeFormatter.TIME_FORMAT);
  }
};

@Pipe({
  name: 'timeAgo',
  pure: false
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {
  private _unixTimeStamp;
  private _format;

  private _actualValue = '';

  private sub: Subscription;

  constructor(private _dateTimeFormatter: DateTimeFormatterService, private _cd: ChangeDetectorRef) {
    this.initTicks();
  }

  initTicks() {
    this.sub = observableInterval(10 * 1000).subscribe(() => this.tick());
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  getNextValue() {
    return getTimeAgo(this._unixTimeStamp, this._format, this._dateTimeFormatter);
  }

  //calc an actual value
  tick() {
    const newValue = this.getNextValue();
    if (newValue !== this._actualValue) {
      this._actualValue = newValue;
      this._cd.markForCheck();
    }
  }

  transform(seconds: number, format: 'long' | 'short' = 'long') {
    if (this._unixTimeStamp !== seconds || format !== this._format) {
      this._unixTimeStamp = seconds;
      this._format = format;
      this._actualValue = this.getNextValue();
    }
    return this._actualValue;
  }
}
