<div class="plus_btn" *ngIf="isNotGuest" (click)="onOpenEditPopup($event)">
  <svg>
    <use xlink:href="#add"></use>
  </svg>
</div>
<ng-container *ngIf="members.length < 4;else multi">
  <a-avatar class="small" *ngFor="let member of members" [user]="member"></a-avatar>
</ng-container>
<ng-template #multi>
  <a-avatar class="small" [user]="members[0]"></a-avatar>
  <a-avatar class="small" [user]="members[1]"></a-avatar>
  <a-avatar class="small" [user]="members[2]"></a-avatar>
  <a-multiple-avatars [users]="membersTail" [showDetails]="false"></a-multiple-avatars>
</ng-template>
