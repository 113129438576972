import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy } from '@angular/core';
import { Board } from '../../interfaces/board';
import { SearchInLeftMenuService } from '../../shared/services/app/search-in-left-menu.service';
import { OpenedStateLeftMenuItemsService } from '../shared/services/opened-state-left-menu-items.service';

const UNASSIGNED_BOARDS_LIST_ID = 'unassigned-boards';

@Component({
  selector: 'unassigned-boards-list',
  templateUrl: './unassigned-boards-list.component.html',
  styleUrls: ['./unassigned-boards-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnassignedBoardsListComponent implements OnInit, OnDestroy {
  @Input() unassignBoards: Board[];

  public isVisibleBoards = false;
  public subscription;
  public openedStateCollectionListKey: string;

  constructor(
    private _searchInLeftMenuService: SearchInLeftMenuService,
    private _openedStateLeftMenuItemsService: OpenedStateLeftMenuItemsService
  ) {}

  ngOnInit() {
    this.openedStateCollectionListKey = this._openedStateLeftMenuItemsService.localStoreKeys.OPENED_COLLECTIONS_LIST;
    this.initIsVisibleBoards();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initIsVisibleBoards() {
    this.subscription = this._searchInLeftMenuService.isSearchActive$.subscribe(state => {
      this.isVisibleBoards = state;
      if (!state) {
        const list = this._openedStateLeftMenuItemsService.getOpenedProjectsList(this.openedStateCollectionListKey);
        if (!list.includes(UNASSIGNED_BOARDS_LIST_ID)) {
          this.isVisibleBoards = true;
        }
      }
    });
  }

  onOpenBoardsList() {
    this.isVisibleBoards = !this.isVisibleBoards;
    this._openedStateLeftMenuItemsService.save(
      this.isVisibleBoards,
      this.openedStateCollectionListKey,
      UNASSIGNED_BOARDS_LIST_ID
    );
  }
}
