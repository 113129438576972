<span [tooltip]="count_tooltip">{{counterText$ | async}}</span>

<ng-template #count_tooltip>
  <div class="tooltip" [ngSwitch]="currentMode">
    <p *ngSwitchCase="modes.Count">Number of tasks. Click to view the sum of estimated hours.</p>
    <p *ngSwitchCase="modes.Hours">Sum of estimated hours. Click to view the sum of story points.</p>
    <p *ngSwitchCase="modes.Points">Sum of estimated story points. Click to view the number of tasks.</p>
    <p *ngSwitchDefault>Number of tasks. Click to view the sum of estimated hours.</p>
  </div>
</ng-template>
