<a-center-container>
	<div a-popup [title]="'Subscription successfully registered'" (close)="onClosePopup()">
		<div class="popup_inner_container">
			<p>Thanks for choosing Hygger!<br><br>
				Your subscription has been successfully registered.<br><br>
				We've sent the subscription details to the email address you provided.</p>
			<button a-button class="btn_primary" (click)="onClosePopup()">Ok</button>
		</div>
	</div>
</a-center-container>
<overlay (click)="onClosePopup()"></overlay>
