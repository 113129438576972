import { Action } from './unsafe-action';
import { labelAction } from '../functions/action';

export const SegmentActionTypes = {
  TASK_CREATED: labelAction('segment', 'TASK_CREATED'),
  TASK_OPENED: labelAction('segment', 'TASK_OPENED'),
  BOARD_OPENED: labelAction('segment', 'BOARD_OPENED'),
  ROADMAP_TASK_STATUS_CHANGED: labelAction('segment', 'ROADMAP_TASK_STATUS_CHANGED')
};

export class SegmentTrackTaskCreatedAction implements Action {
  type = SegmentActionTypes.TASK_CREATED;

  constructor(public payload?: any) {
    this.payload = Object.assign(
      {
        source: 'board',
        taskType: 'native'
      },
      payload
    );
  }
}

export class SegmentTrackTaskOpenedAction implements Action {
  type = SegmentActionTypes.TASK_OPENED;

  constructor(public payload?: any) {}
}

export class SegmentTrackBoardOpenedAction implements Action {
  type = SegmentActionTypes.BOARD_OPENED;

  constructor(public payload?: any) {}
}

export class SegmentTrackRoadmapTaskStatusChanged implements Action {
  type = SegmentActionTypes.ROADMAP_TASK_STATUS_CHANGED;

  constructor(public payload?: any) {}
}
