
import {fromEvent as observableFromEvent,  Observable } from 'rxjs';

import { switchMap, debounceTime, map, startWith, take } from 'rxjs/operators';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import {
  Timesheet,
  TimesheetDate,
  TimesheetDateProject,
  TimesheetDateTask,
  TimesheetDateUser,
  TimesheetLog,
  TimesheetProject,
  TimesheetTask,
  TimesheetUser
} from '../timesheet';
import { TableRow } from './timesheet-table';
import { WorklogService } from '../../shared/services/worklog.service';
import { getDateByTimestamp, TimesheetService } from '../timesheet.service';
import { AuthService } from '../../shared/services/app/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/state/app-state';
import * as fromComponent from '../../ngrx/reducers/component.reducer';
import { WorkLogFormData } from '../../shared/components/work-log-form/work-log-form';
import { getTaskUrl } from '../../ngrx/reducers/task.reducer';

const DATA_CELL_WIDTH = 61;
const DATA_TABLE_MAX_WIDTH = 800;
const PAGE_WIDTH_DIFF = 30;
const TITLE_ROW_LEFT_PADDING = 20;
const TITLE_PROJECT_ROW_LEFT_PADDING = 0;
const TITLE_USER_ROW_LEFT_PADDING = 30;
const TITLE_TASK_ROW_LEFT_PADDING = 60;
const TITLE_LOG_ROW_LEFT_PADDING = 90;
const TOTAL_COLUMN_WIDTH = 60;
const DATA_CELL_BORDER_WIDTH = 1;
const TITLE_USER_AVATAR_WIDTH = 30;

@Component({
  selector: 'timesheet-table',
  templateUrl: './timesheet-talble.component.html',
  styleUrls: ['./timesheet-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimesheetTableComponent implements OnChanges, AfterViewInit {
  @Input() data: Timesheet;
  @Input() showTasks: boolean;
  @Input() showLogs: boolean;
  @Input() loading: boolean;
  @Input() logDataLeftInitialScroll = 0;
  @Output() scrollLogData: EventEmitter<number> = new EventEmitter();

  public isStyckyLeftMenu$: Observable<0 | 1>;
  public width$: Observable<number>;

  public tableRows: Array<TableRow>;
  public noData: boolean;
  public showLogworkPopUp = false;
  public dblClickedLeft: number = null;
  public dblClickedTop: number = null;
  public dblClickedRow: TableRow = null;
  public dblClickedRowIndex: number = null;
  public dblClickedDay: TimesheetDate = null;
  public dblClickedLogData: TimesheetLog = null;

  constructor(
    private worklogService: WorklogService,
    private cd: ChangeDetectorRef,
    private timesheetService: TimesheetService,
    private authService: AuthService,
    private _store: Store<AppState>,
    private elementRef: ElementRef
  ) {
    this.tableRows = [];
    this.noData = true;
    this.showLogs = true;
    this.loading = false;
  }

  ngAfterViewInit() {
    this.isStyckyLeftMenu$ = this._store.select(fromComponent.isStickyLeftMenu);
    this.width$ = this.isStyckyLeftMenu$.pipe(
      debounceTime(0),
      switchMap(() =>
        observableFromEvent(window, 'resize').pipe(
          debounceTime(200),
          startWith(0),
          map(() => this.elementRef.nativeElement.offsetWidth)
        )
      ),
      map((fullWidth: number) => fullWidth - 360 - 115),);
  }

  ngOnChanges() {
    if (this.showTasks === false) {
      this.showLogs = false;
    }
    if (!!this.data && !!this.data.projects) {
      if (this.data.projects.length > 0) {
        this.prepareTableRows();
        this.noData = false;
      } else {
        this.data = null;
      }
    } else {
      this.noData = true;
    }
  }

  onCloseLogworkPopUp() {
    this.showLogworkPopUp = false;
  }

  onLogDataScroll($event) {
    this.scrollLogData.emit($event.target.scrollLeft);
  }

  onSubmitWorklog(logData: WorkLogFormData) {
    if (logData.editMode === false) {
      this.worklogService
        .addWorklog(logData.payload.taskId, logData.loggedTime, logData.logDate, logData.logComment)
        .subscribe(newLog => {
          newLog.data.attributes.logWorkDate = getDateByTimestamp(newLog.data.attributes.logDate);
          const day: TimesheetDate = this.data.days.get(newLog.data.attributes.logWorkDate);

          if (day instanceof TimesheetDate) {
            if (this.showLogs) {
              this.insertNewLogRow(
                newLog.data.id,
                newLog.data.relationships.user.data.id,
                newLog.data.relationships.task.data.id,
                logData.payload.projectId,
                logData.payload.dblClickedRowIndex,
                logData.payload.projectIndex,
                newLog.data.attributes.logComment
              );
              this.insertNewLog(this.data, logData.payload.projectIndex, newLog.data);
            }

            this.updateTableAfterEditing(
              day,
              logData.payload.projectId,
              newLog.data.relationships.user.data.id,
              newLog.data.relationships.task.data.id,
              newLog.data.id,
              newLog.data.attributes.loggedTime
            );

            this.cd.markForCheck();
          }
        });
    } else {
      this.worklogService
        .editWorklog(logData.payload.logId, logData.loggedTime, logData.logComment)
        .subscribe(updatedLog => {
          updatedLog.data.attributes.logWorkDate = getDateByTimestamp(updatedLog.data.attributes.logDate);
          const day: TimesheetDate = this.data.days.get(updatedLog.data.attributes.logWorkDate);

          if (day instanceof TimesheetDate) {
            this.updateTableAfterEditing(
              day,
              logData.payload.projectId,
              updatedLog.data.relationships.user.data.id,
              updatedLog.data.relationships.task.data.id,
              updatedLog.data.id,
              updatedLog.data.attributes.loggedTime
            );

            this.tableRows[logData.payload.dblClickedRowIndex].title = this.composeLogRowTitle(
              updatedLog.data.attributes.logComment
            );
            this.tableRows[logData.payload.dblClickedRowIndex].tooltip = updatedLog.data.attributes.logComment;

            const log: TimesheetLog = this.timesheetService.getTimehseetLogData(
              this.data,
              logData.payload.projectIndex,
              updatedLog.data.relationships.user.data.id,
              updatedLog.data.relationships.task.data.id,
              updatedLog.data.id
            );
            if (log instanceof TimesheetLog) {
              log.comment = updatedLog.data.attributes.logComment;
              log.loggedTime = this.timesheetService.secondsToHours(updatedLog.data.attributes.loggedTime);
            }

            this.cd.markForCheck();
          }
        });
    }
  }

  onDataCellDblClick($event, row, rowIndex, day) {
    this.showLogworkPopUp = false;
    this.dblClickedLogData = null;
    if (row.type === 'log') {
      const logData: TimesheetLog = this.timesheetService.getTimehseetLogData(
        this.data,
        row.projectIndex,
        row.userId,
        row.taskId,
        row.logId
      );
      if (logData !== null && logData.logWorkDate === day.date) {
        this.dblClickedLogData = logData;
      }
    }

    if (this.authService.activeUserId === row.userId) {
      this.dblClickedLeft = $event.x;
      this.dblClickedTop = $event.y;
      this.dblClickedRow = row;
      this.dblClickedDay = day;
      this.dblClickedRowIndex = rowIndex;

      if (row.type === 'task' || (row.type === 'log' && this.dblClickedLogData !== null)) {
        this.showLogworkPopUp = true;
      }
    }
  }

  getDayProjectTotal(projectId: number, day: TimesheetDate): string {
    const dayProject: TimesheetDateProject = day.projects.get(projectId);
    return dayProject instanceof TimesheetDateProject ? this.roundTime(dayProject.total) : '';
  }

  getDayProjectUserTotal(projectId: number, userId: number, day: TimesheetDate): string {
    const dayProject: TimesheetDateProject = day.projects.get(projectId);
    if (dayProject instanceof TimesheetDateProject === false) {
      return '';
    }
    const dayProjectUser: TimesheetDateUser = dayProject.users.get(userId);
    return dayProjectUser instanceof TimesheetDateUser ? this.roundTime(dayProjectUser.total) : '';
  }

  getDayProjectUserTaskTotal(projectId: number, userId: number, taskId: number, day: TimesheetDate): string {
    const dayProject: TimesheetDateProject = day.projects.get(projectId);
    if (dayProject instanceof TimesheetDateProject === false) {
      return '';
    }
    const dayProjectUser: TimesheetDateUser = dayProject.users.get(userId);
    if (dayProjectUser instanceof TimesheetDateUser === false) {
      return '';
    }
    const dayProjectUserTask: TimesheetDateTask = dayProjectUser.tasks.get(taskId);
    if (dayProjectUserTask instanceof TimesheetDateTask === false) {
      return '';
    }

    return dayProjectUserTask.total ? this.roundTime(dayProjectUserTask.total) : '';
  }

  getDayProjectUserTaskLogValue(
    projectId: number,
    userId: number,
    taskId: number,
    day: TimesheetDate,
    logId: number
  ): string {
    const dayProject: TimesheetDateProject = day.projects.get(projectId);
    if (dayProject instanceof TimesheetDateProject === false) {
      return '';
    }
    const dayProjectUser: TimesheetDateUser = dayProject.users.get(userId);
    if (dayProjectUser instanceof TimesheetDateUser === false) {
      return '';
    }
    const dayProjectUserTask: TimesheetDateTask = dayProjectUser.tasks.get(taskId);
    if (dayProjectUserTask instanceof TimesheetDateTask === false) {
      return '';
    }

    return dayProjectUserTask.logs[logId] !== undefined ? this.roundTime(dayProjectUserTask.logs[logId]) : '';
  }

  roundTime(time: number): string {
    if (time % Math.round(time) > 0 && time > 999) {
      time = Math.round(time);
    }
    return time.toString();
  }

  getDataRowWidth(type) {
    const dataTableWidth: number = this.data.sortedDays.length * (DATA_CELL_WIDTH + DATA_CELL_BORDER_WIDTH);
    let titleLeftPadding: number = TITLE_ROW_LEFT_PADDING;
    switch (type) {
      case 'project':
        titleLeftPadding = TITLE_ROW_LEFT_PADDING + TITLE_PROJECT_ROW_LEFT_PADDING;
        break;
      case 'user':
        titleLeftPadding = TITLE_ROW_LEFT_PADDING + TITLE_USER_ROW_LEFT_PADDING + TITLE_USER_AVATAR_WIDTH;
        break;
      case 'task':
        titleLeftPadding = TITLE_ROW_LEFT_PADDING + TITLE_TASK_ROW_LEFT_PADDING;
        break;
      case 'log':
        titleLeftPadding = TITLE_ROW_LEFT_PADDING + TITLE_LOG_ROW_LEFT_PADDING;
        break;
    }
    if (dataTableWidth > DATA_TABLE_MAX_WIDTH + PAGE_WIDTH_DIFF) {
      return DATA_TABLE_MAX_WIDTH + TOTAL_COLUMN_WIDTH + PAGE_WIDTH_DIFF + titleLeftPadding;
    }
    return dataTableWidth + titleLeftPadding + TOTAL_COLUMN_WIDTH + PAGE_WIDTH_DIFF;
  }

  private composeLogRowTitle(comment: string): string {
    return !!comment ? comment : 'No comment';
  }

  private insertNewLog(ts: Timesheet, projectIndex: number, logData: any) {
    const task: TimesheetTask = this.timesheetService.getTimesheetTaskData(
      ts,
      projectIndex,
      logData.relationships.user.data.id,
      logData.relationships.task.data.id
    );
    if (task instanceof TimesheetTask) {
      const log = this.timesheetService.createLog(logData);
      task.logs.set(log.id, log);
    }
  }

  private updateTableAfterEditing(
    day: TimesheetDate,
    projectId: number,
    userId: number,
    taskId: number,
    logId: number,
    loggedTime: number
  ) {
    const difference = this.updateDayData(day, projectId, userId, taskId, logId, loggedTime);

    let rowIndex = this.dblClickedRowIndex;
    do {
      if (this.tableRows[rowIndex].type !== 'log') {
        this.tableRows[rowIndex].total = this.timesheetService.removeExtraDecimals(
          this.tableRows[rowIndex].total + difference
        );
      }
      rowIndex--;
    } while (rowIndex >= 0 && this.tableRows[rowIndex].projectId === this.dblClickedRow.projectId);

    this.data.total = this.timesheetService.removeExtraDecimals(this.data.total + difference);
  }

  private updateDayData(
    day: TimesheetDate,
    projectId: number,
    userId: number,
    taskId: number,
    logId: number,
    loggedTime: number
  ): number {
    let dayProject: TimesheetDateProject = day.projects.get(projectId);
    if (dayProject instanceof TimesheetDateProject === false) {
      dayProject = new TimesheetDateProject();
      day.projects.set(projectId, dayProject);
    }

    let dayProjectUser: TimesheetDateUser = dayProject.users.get(userId);
    if (dayProjectUser instanceof TimesheetDateUser === false) {
      dayProjectUser = new TimesheetDateUser();
      dayProject.users.set(userId, dayProjectUser);
    }

    let dayProjectUserTask: TimesheetDateTask = dayProjectUser.tasks.get(taskId);
    if (dayProjectUserTask instanceof TimesheetDateTask === false) {
      dayProjectUserTask = new TimesheetDateTask();
      dayProjectUser.tasks.set(taskId, dayProjectUserTask);
    }

    const loggedHours = this.timesheetService.secondsToHours(loggedTime);
    let difference = loggedHours;
    const log = dayProjectUserTask.logs[logId];
    if (log !== undefined) {
      difference = difference - log;
    }

    dayProjectUserTask.logs[logId] = loggedHours;
    dayProjectUserTask.total = this.timesheetService.removeExtraDecimals(dayProjectUserTask.total + difference);
    dayProjectUser.total = this.timesheetService.removeExtraDecimals(dayProjectUser.total + difference);
    dayProject.total = this.timesheetService.removeExtraDecimals(dayProject.total + difference);
    day.total = this.timesheetService.removeExtraDecimals(day.total + difference);

    return difference;
  }

  private insertNewLogRow(
    logId: number,
    userId: number,
    taskId: number,
    projectId: number,
    rowIndex: number,
    projectIndex: number,
    comment: string
  ) {
    const insertIndex = this.findIndexForAdding(rowIndex);
    const logRow = new TableRow();
    logRow.type = 'log';
    logRow.projectId = projectId;
    logRow.projectIndex = projectIndex;
    logRow.taskId = taskId;
    logRow.userId = userId;
    logRow.logId = logId;
    logRow.title = this.composeLogRowTitle(comment);
    logRow.tooltip = comment;
    this.tableRows.splice(insertIndex, 0, logRow);
  }

  private findIndexForAdding(clickedIndex): number {
    let index = clickedIndex;
    do {
      index = index + 1;
    } while (index < this.tableRows.length && this.tableRows[index].type === 'log');
    return index;
  }

  private prepareTableRows() {
    this.tableRows = [];
    this.data.projects.forEach((project: TimesheetProject, projectIndex: number) => {
      const projectRow = new TableRow();
      projectRow.type = 'project';
      projectRow.title = project.name;
      projectRow.projectIndex = projectIndex;
      projectRow.projectId = project.id;
      projectRow.total = project.total;
      projectRow.tooltip = project.name;
      this.tableRows.push(projectRow);
      project.sortedUsers.forEach((user: TimesheetUser) => {
        const userRow = new TableRow();
        userRow.type = 'user';
        userRow.title = user.name;
        userRow.projectIndex = projectIndex;
        userRow.userId = user.id;
        userRow.projectId = project.id;
        userRow.total = user.total;
        userRow.tooltip = user.name;
        this.tableRows.push(userRow);
        if (this.showTasks) {
          user.sortedTasks.forEach((task: TimesheetTask) => {
            const taskRow = new TableRow();
            taskRow.type = 'task';
            taskRow.title = task.title;
            this._store.pipe(getTaskUrl(task), take(1)).subscribe((taskUrl : string) => {
              taskRow.taskUrl = taskUrl;
            });
            taskRow.projectIndex = projectIndex;
            taskRow.userId = user.id;
            taskRow.taskId = task.id;
            taskRow.projectId = project.id;
            taskRow.total = task.total;
            taskRow.tooltip =
              (Number.isInteger(task.numberInProject) && !!project.shortName
                ? project.shortName + '-' + task.numberInProject.toString() + ' '
                : '') + task.title;
            this.tableRows.push(taskRow);
            if (this.showLogs) {
              task.sortedLogs.forEach((log: TimesheetLog) => {
                const logRow = new TableRow();
                logRow.type = 'log';
                logRow.projectId = project.id;
                logRow.projectIndex = projectIndex;
                logRow.taskId = log.taskId;
                logRow.userId = log.userId;
                logRow.logId = log.id;
                logRow.logTitle = log.title;
                logRow.title = this.composeLogRowTitle(log.comment);
                logRow.tooltip = log.comment;
                this.tableRows.push(logRow);
              });
            }
          });
        }
      });
    });
  }
}
