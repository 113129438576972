
import {distinctUntilChanged, pluck, map, switchMap} from 'rxjs/operators';
import { Component, OnInit, Input, Output, OnChanges, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Board } from '../../interfaces/board';
import { Observable } from 'rxjs';
import { Label, LabelTag } from '../../interfaces/label';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/state/app-state';
import { LABEL_PL } from '../../constants/entity';
import { getLabels } from '../../ngrx/reducers/label.reducer';
import { createFilterByBoardId } from '../../../helpers/index';
import { labelsToLabelTag } from '../../../helpers/labels';

@Component({
  selector: 'label-list',
  templateUrl: 'label-list.component.html',
  styleUrls: ['./label-list.component.scss']
})
export class LabelListComponent implements OnInit, OnChanges {
  @Input() board: Observable<Board>;
  @Input() selectedLabels: number[] | null;
  @Output() changeSelected = new EventEmitter<{ add?: number[]; remove?: number[] }>();

  public labels$: Observable<Label[]>;
  public tags$: Observable<LabelTag[]>;
  public isSelected: {};

  public popUpVisible = false;
  public editedLabel: Label | null = null;

  constructor(private _store: Store<AppState>, private _cd: ChangeDetectorRef) {}

  get board$(): Observable<Board> {
    return this.board;
  }

  ngOnChanges() {
    this.isSelected = {};
    if (this.selectedLabels) {
      this.selectedLabels.forEach(id => {
        this.isSelected[id] = true;
      });
    }
  }

  ngOnInit() {
    this.labels$ = this.board$.pipe(
      pluck('id'),
      distinctUntilChanged(),
      map(createFilterByBoardId),
      switchMap(f => getLabels(this._store)(f)),);
    this.tags$ = this.labels$.pipe(map(labelsToLabelTag));
  }

  onClickEditLabel(id) {
    let label;
    this._store.pipe(
      pluck(LABEL_PL, 'entities'),
      distinctUntilChanged(),)
      .subscribe(labels => (label = labels[id]))
      .unsubscribe();
    this.editLabel(label);
  }

  onClickOnLabel(id) {
    if (!this.selectedLabels) {
      return this.onClickEditLabel(id);
    }
    const modify = this.isSelected[id] ? { remove: [id], add: [] } : { add: [id], remove: [] };
    this.changeSelected.emit(modify);
  }

  editLabel(label: Label) {
    this.editedLabel = label;
    this.popUpVisible = true;
  }

  createLabel() {
    delete this.editedLabel;
    this.popUpVisible = true;
  }

  onCloseEditPopUp() {
    this.popUpVisible = false;
    this._cd.markForCheck();
  }
}
