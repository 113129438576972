import { createSelector } from '@ngrx/store';
import { AppState, REPORT_PROJECT_FILTER } from '../../state';
import * as reportProjectFilter from '../../actions/report-filters/report-projects-filter.action';
import { ReportProjectFilterActionTypes } from '../../actions/report-filters/report-projects-filter.action';

export interface ReportProjectsFilter {
  selectedAll: boolean,
  projectsIds: number[];
}

const initState: ReportProjectsFilter = {
  selectedAll: false,
  projectsIds: []
};

export function reducer(
  state = initState,
  action: reportProjectFilter.Actions
): ReportProjectsFilter {
  switch (action.type) {
    case ReportProjectFilterActionTypes.APPLY: {
      state = action['payload'];
      return state;
    }

    case ReportProjectFilterActionTypes.RESET: {
      state = initState;
      return initState;
    }

    default: {
      return state;
    }
  }
}

export namespace fromReportProject {
  export const getState = (state: AppState) =>
    <ReportProjectsFilter>state[REPORT_PROJECT_FILTER];

  export const isActive = createSelector(
    getState,
    state => state.projectsIds.length > 0
  );
  export const getIds = createSelector(
    getState,
    state => state.projectsIds
  );
  export const isSelectedAll = createSelector(
    getState,
    state => state.selectedAll
  );
}
