import { from as observableFrom, NEVER, Observable, of as observableOf } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, pluck, switchMap, take, tap } from 'rxjs/operators';
import { HandleResponseAction, RemoveEntitiesFromStoreAction } from '../../../ngrx/actions/root.action';
import { TaskActionTypes } from '../../../ngrx/actions/task.actions';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import {
  OpenedTaskActionTypes,
  OpenedTaskDataChangedAction,
  OpenedTaskInitAction
} from '../actions/opened-task.action';
import { getTaskById } from '../../../ngrx/reducers/task.reducer';
import { getBoardById } from '../../../ngrx/reducers/board.reducer';
import { isPresent, objectIfEmpty } from '../../../../helpers';
import { VERSION_PL } from '../../../constants/';
import { AuthService } from '../../../shared/services/app/auth.service';
import { AtlazApiV2Service } from '../../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { Router } from '@angular/router';
import { AppPages } from '../../../constants';
import { Board } from '../../../interfaces';
import { SegmentTrackTaskOpenedAction } from '../../../ngrx/actions/segment.actions';
import * as loadedData from '../../../loaded-data/store/loaded-data.actions';
import { RouterNavigateService } from '../../../shared/services/router-navigate.service';
import { Action } from '../../../ngrx/actions/unsafe-action';
import { ToastrService } from 'ngx-toastr';
import { fromOpenedTask, OpenedTask } from '../reducers/opened-task.reducer';

const catcher = <T>(err, caught: Observable<T>): Observable<T> => {
  console.error(err);
  return caught;
};

@Injectable()
export class OpenedTasksEffects {
  @Effect()
  close$ = this.actions$.ofType(OpenedTaskActionTypes.CLOSE).pipe(
    switchMap(() => this._routerNav.deactivateOpenedTaskOutlet()),
    tap(() => {
      this._store
        .select(fromOpenedTask.getState)
        .pipe(
          map((task: OpenedTask) => task && task.highlightAfterClosing),
          filter(isPresent),
          switchMap(() => this._store.select(AppPages.Task, 'id')),
          take(1),
          filter(isPresent)
        )
        .subscribe(id => {
          window.location.hash = String(id);
        });
    }),
    map(() => new OpenedTaskInitAction())
  );

  @Effect()
  taskChanged$ = this.actions$.ofType(OpenedTaskActionTypes.OPEN).pipe(
    pluck('payload'),
    switchMap(id =>
      this._store.pipe(
        pluck(AppPages.Task, 'lastOpenedLinkTask'),
        take(1),
        map(lastOpenedLinkTask => [id, lastOpenedLinkTask])
      )
    ),
    switchMap(([taskId, openedByLink]): Observable<Action> => {
      if (!taskId) {
        return observableOf(new OpenedTaskInitAction());
      }

      const task$ = this._store.pipe(getTaskById(taskId));
      const board$ = task$.pipe(
        map(objectIfEmpty),
        pluck('board'),
        distinctUntilChanged(),
        switchMap(boardId => this._store.pipe(getBoardById(boardId))),
        catchError(catcher)
      );

      return board$.pipe(
        filter(isPresent),
        take(1),
        switchMap((board: Board) => {
          const taskType = openedByLink === taskId ? 'link' : 'native';
          return observableFrom([
            new OpenedTaskDataChangedAction({ lastOpenedLinkTask: 0 }),
            new SegmentTrackTaskOpenedAction({ boardType: board.type, taskType: taskType })
          ]);
        })
      );
    })
  );

  @Effect()
  removeTask$ = this.actions$.ofType(OpenedTaskActionTypes.DELETE).pipe(
    switchMap(({ type, payload: taskId }: { type: string; payload: number }) => {
      return this._atlazApi.deleteRequest(['tasks', taskId]).pipe(
        map(
          _ =>
            new RemoveEntitiesFromStoreAction([
              {
                entityName: 'tasks',
                predicate: model => model.id === taskId
              }
            ])
        ),
        catchError(error => {
          console.warn('OpenedTaskActionTypes.DELETE');
          console.log('task id: ', taskId);
          console.error(error);
          this._toastr.error('An error occurred. Task was not deleted');
          return NEVER;
        })
      );
    })
  );

  @Effect()
  assignProject$ = this.actions$.ofType(TaskActionTypes.EDIT).pipe(
    filter(({ type, payload }: Action) => 'project' in payload && payload.project > 0),
    switchMap(({ type, payload }: { type: string; payload: { project: number } }) =>
      this._atlazApi.get(VERSION_PL, { project: payload.project })
    ),
    catchError(error => {
      console.warn('OpenedTaskActionTypes.ASSIGN_PROJECT');
      console.error(error);
      this._toastr.error('An error occurred. versions for project were not loaded');
      return NEVER;
    }),
    map(response => new HandleResponseAction(response))
  );

  @Effect()
  addToLoaded = this.actions$
    .ofType(OpenedTaskActionTypes.MARK_AS_LOADED)
    .pipe(map(({ type, payload }: Action) => new loadedData.MarkAsLoadedTask({ taskId: payload })));

  constructor(
    private actions$: Actions,
    private _router: Router,
    private _store: Store<AppState>,
    private _authService: AuthService,
    private _atlazApi: AtlazApiV2Service,
    private _routerNav: RouterNavigateService,
    private _toastr: ToastrService
  ) {}
}
