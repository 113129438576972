
import {map} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { compose } from '@ngrx/store';
import { getEntitySelector } from '../../functions/selectors';
import { DEFAULT_COLUMNS_FILTER } from '../../state';
import * as defaultColumnsFilter from '../../actions/task-filters/default-columns-filter.actions';
import { DefaultTasksColumnsFilterActionTypes } from '../../actions/task-filters/default-columns-filter.actions';
import { TaskDefaultFilterActionTypes } from '../../actions/task-filters/task-default-filter.actions';

export interface DefaultColumnsFilter {
  columnsIds: number[];
}

const initState: DefaultColumnsFilter = {
  columnsIds: []
};

export function reducer(state = initState, action: defaultColumnsFilter.Actions): DefaultColumnsFilter {
  switch (action.type) {
    case DefaultTasksColumnsFilterActionTypes.TOGGLE_ITEM_ID: {
      let columnsIds: number[];
      columnsIds = action['payload'];
      state = Object.assign({}, state, { columnsIds: columnsIds });
      return state;
    }

    case DefaultTasksColumnsFilterActionTypes.RESET: {
      return initState;
    }

    case TaskDefaultFilterActionTypes.SET: {
      if (action['payload'].activeFilters.defaultColumnsFilter) {
        state = { columnsIds: [...action['payload'].activeFilters.defaultColumnsFilter] };
      } else {
        state = initState;
      }
      return state;
    }

    default: {
      return state;
    }
  }
}

const isActive = (state$: Observable<DefaultColumnsFilter>) => state$.pipe(map(state => state.columnsIds.length > 0));
const getColumnsIds = (state$: Observable<DefaultColumnsFilter>) => state$.pipe(map(state => state.columnsIds));

export const getDefaultColumnsFilterEntity = getEntitySelector(DEFAULT_COLUMNS_FILTER);
export const isActiveDefaultColumnsFilter = compose(isActive, getDefaultColumnsFilterEntity);
export const getDefaultColumnsFilterIds = compose(getColumnsIds, getDefaultColumnsFilterEntity);
