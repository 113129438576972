import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'dropdown-options-setter',
  templateUrl: './dropdown-options-setter.component.html',
  styleUrls: ['./dropdown-options-setter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownOptionsSetterComponent),
      multi: true
    }
  ]
})
export class DropdownOptionsSetterComponent implements OnInit, ControlValueAccessor {
  @Input() dragType: string;
  @Input() isWhite: boolean;
  @Output() startDrag = new EventEmitter();
  public chosenItems: string[];
  public editItemIndex = -1;
  public dragOverId = -1;
  public dragStartId = -1;

  public propagateChange = _ => {};
  public propagateTouch = () => {};
  constructor() {}

  public writeValue(value) {
    if (!value) {
      this.chosenItems = [];
    } else {
      if (Array.isArray(value)) {
        this.chosenItems = value;
      } else {
        try {
          this.chosenItems = JSON.parse(value);
        } catch (e) {
          console.log('DropdownOptionsSetterComponent writeValue error', e);
          this.chosenItems = [];
        }
      }
    }
    this.propagateChange(this.chosenItems);
    this.propagateTouch();
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn) {
    this.propagateTouch = fn;
  }

  ngOnInit() {}

  onDelete(index) {
    const newValue = [...this.chosenItems];
    newValue.splice(index, 1);
    this.writeValue(newValue);
  }

  onAddEmptyItem() {
    this.chosenItems = this.chosenItems.filter(item => !!item);
    this.chosenItems.push('');
    this.editItemIndex = this.chosenItems.length - 1;
  }

  onChangeItem(event, index) {
    this.editItemIndex = -1;
    this.chosenItems[index] = event.target.value;
    this.writeValue(this.chosenItems.filter(item => !!item));
  }

  setEditTo(index) {
    if (this.editItemIndex !== index) {
      this.editItemIndex = index;
    }
  }

  onDragStart(event, item, index) {
    event.stopPropagation();
    this.startDrag.emit('ddOption');
    this.editItemIndex = -1;
    event.dataTransfer.setData('text', item);
    this.dragStartId = index;
  }

  onDragEnd() {
    if (this.dragType !== 'ddOption') {
      return;
    }
    this.editItemIndex = -1;
    const newValue = [...this.chosenItems];
    newValue.splice(this.dragStartId, 1);
    newValue.splice(this.dragOverId, 0, this.chosenItems[this.dragStartId]);
    this.dragOverId = -1;
    this.chosenItems = newValue;
    this.startDrag.emit('');
    this.writeValue(newValue);
  }

  onDragOver(event, index) {
    event.preventDefault();
    if (this.dragOverId !== index && this.dragType === 'ddOption') {
      this.dragOverId = index;
    }
  }
}
