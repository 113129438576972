import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Version } from '../../../../interfaces';

@Component({
  selector: 'sidebar-version-list',
  templateUrl: './sidebar-version-list.component.html',
  styleUrls: ['./sidebar-version-list.component.scss']
})
export class SidebarVersionListComponent implements OnInit {
  @Input() selected: number | number[];
  @Input() versions: Version[];
  @Input() multiply = false;

  @Output() selectVersion = new EventEmitter();

  public selectedVersion: number;

  public selectedVersions: { [id: number]: boolean } = {};

  constructor() {}

  get selectedArray() {
    return <number[]>this.selected;
  }

  ngOnInit() {
    if (this.multiply) {
      this.selectedVersions = this.selectedArray.reduce((acc, id) => {
        acc[id] = true;
        return acc;
      }, {});
    } else {
      this.selectedVersion = <number>this.selected;
      this.selectedVersions = {
        [this.selectedVersion]: true
      };
    }
  }

  onSelectVersion(version: Version) {
    if (this.multiply) {
      this.selectedVersions[version.id] = !this.selectedVersions[version.id];
      const result = Object.keys(this.selectedVersions).reduce((acc, key) => {
        if (this.selectedVersions[key]) {
          acc.push(+key);
        }
        return acc;
      }, []);
      this.selectVersion.emit(result);
    } else {
      this.selectedVersion = this.selectedVersion === version.id ? 0 : version.id;
      this.selectedVersions = {
        [this.selectedVersion]: true
      };
      this.selectVersion.emit(this.selectedVersion);
    }
  }
}
