import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Board, Project } from '../../interfaces';
import { AppState } from '../../ngrx/state/app-state';

@Component({
  selector: 'right-menu-projects',
  templateUrl: './right-menu-projects.component.html',
  styleUrls: ['./right-menu-projects.component.scss']
})
export class RightMenuProjectsComponent {
  @Input() projects: Project[];
  @Input() boardProjects: Project[];
  @Input() selectedBoard: Board;
  @Input() boardId: number;

  public isProjectsPopupVisible = false;

  constructor(private _store: Store<AppState>) {}

  onCloseProjectsPopup() {
    this.isProjectsPopupVisible = false;
  }

  projectsPopupToggle() {
    this.isProjectsPopupVisible = !this.isProjectsPopupVisible;
  }
}
