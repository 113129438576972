import { distinctUntilChanged, map } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { trackById } from '../../../../../helpers/index';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../ngrx/state/app-state';
import { GuiStateToggleGroupVisibility } from '../../../../ngrx/actions/gui-state-memorized.actions';
import { COLLAPSED } from '../../store/roadmap-board.effect';
import { SyncRoadmapGuiStateService } from '../../services/sync-roadmap-gui-state-service';
import { DraggedActualPositions, RoadmapDragService } from '../../services/roadmap-drag.service';
import { HiddenItems, RoadMapItem } from '../../interfaces/roadmap.interface';
import { defaultDragOverHandler } from '../../directives/roadmap-drop-container.directive';
import {
  RoadmapBoardChangeTitleAction,
  RoadmapBoardDeleteGroupAction,
  RoadmapBoardOpenAddFormAction
} from '../../store/roadmap-board.action';
import { RoadmapScrollService } from '../../services/roadmap-scroll.service';
import { BehaviorSubject } from 'rxjs';
import { RoadMapAddFormTypes } from '../../constants/roadmap.constants';
import { isNotPresent } from '../../../../../helpers/object';

export const defaultDragOverAddButtonHandler = (
  event: MouseEvent,
  draggedItem: RoadMapItem,
  nativeElement: any,
  item: RoadMapItem
) => {
  if (draggedItem.type === 'group') {
    return {
      id: item.id,
      item,
      position: DraggedActualPositions.after
    };
  }
  const { top, height } = nativeElement.getBoundingClientRect();
  return {
    id: item.id,
    item,
    position: top + height / 2 > event.pageY ? DraggedActualPositions.insideLast : DraggedActualPositions.after
  };
};

export const defaultDragOverHeader = (
  event: MouseEvent,
  draggedItem: RoadMapItem,
  nativeElement: any,
  item: RoadMapItem
) => {
  if (draggedItem.type === 'group') {
    return defaultDragOverHandler(event, draggedItem, nativeElement, item);
  }
  const { top, height } = nativeElement.getBoundingClientRect();
  if (item.collapsed) {
    let position;
    if (top <= event.pageY && event.pageY < top + height / 4) {
      position = DraggedActualPositions.before;
    } else if (top + height * 3 / 4 <= event.pageY && event.pageY < top + height) {
      position = DraggedActualPositions.after;
    } else {
      position = DraggedActualPositions.insideFirst;
    }
    return {
      id: item.id,
      item,
      position
    };
  }
  return {
    id: item.id,
    item,
    position: top + height / 2 > event.pageY ? DraggedActualPositions.before : DraggedActualPositions.insideFirst
  };
};

@Component({
  selector: 'named-swimlane',
  templateUrl: './named-swimlane.component.html',
  styleUrls: ['./named-swimlane.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NamedSwimlaneComponent implements OnInit {
  @ViewChild('header') headerRef: ElementRef;

  @Input() item: any;
  @Input() hiddenItems: HiddenItems = {};
  @Input() isNotGuest: boolean;

  public isHoverOnHeader$ = this._syncRoadmapGuiStateService.hoverItem$.pipe(
    map(hoveredItem => hoveredItem.id === this.item.id && hoveredItem.type === 'group'),
    distinctUntilChanged()
  );
  public isHoverOnPush$ = this._syncRoadmapGuiStateService.hoverItem$.pipe(
    map(hoveredItem => hoveredItem.id === this.item.id && hoveredItem.type === 'push'),
    distinctUntilChanged()
  );

  public RoadMapAddFormTypes = RoadMapAddFormTypes;

  public addFormParams$;
  public addFormVisible$;

  public addFormHidden$;

  public showContextMenu$ = new BehaviorSubject(false);
  public showDeleteConfirmDialog$ = new BehaviorSubject(false);
  public editTitle$ = new BehaviorSubject(false);

  get collapsedState() {
    return this.item.collapsedState;
  }

  get collapsed() {
    return this.item.collapsedState === COLLAPSED;
  }

  public trackById = trackById;

  get title() {
    return this.item.title;
  }

  public defaultDragOverHeader = defaultDragOverHeader;
  public defaultDragOverAddButtonHandler = defaultDragOverAddButtonHandler;

  public editingTaskPercentId: number;

  constructor(
    private _cd: ChangeDetectorRef,
    private _store: Store<AppState>,
    private _scrollService: RoadmapScrollService,
    private _roadmapDragService: RoadmapDragService,
    private _syncRoadmapGuiStateService: SyncRoadmapGuiStateService
  ) {}

  @HostBinding('class.collapsed-container')
  get isHidden() {
    return this.hiddenItems[this.item.id];
  }

  ngOnInit() {
    this.addFormVisible$ = this.addFormParams$ = this._syncRoadmapGuiStateService.addFormParams(this.item.id);
    this.addFormHidden$ = this.addFormVisible$.pipe(map(isNotPresent));
  }

  onOpenPushForm(type: RoadMapAddFormTypes) {
    if (!this.isNotGuest) {
      return;
    }
    this._store.dispatch(
      new RoadmapBoardOpenAddFormAction({
        parent: this.item.id,
        type
      })
    );
  }
  onAddFormClickOut($event) {
    this._syncRoadmapGuiStateService.handleAddFormMissClick($event);
  }

  toggleCollapsedState() {
    if (this.editTitle$.getValue() === false) {
      this._store.dispatch(new GuiStateToggleGroupVisibility(this.item.id));
      this._scrollService.syncScrollPosition();
    }
  }

  onMouseEnter(itemId, itemType) {
    this._syncRoadmapGuiStateService.hoverItem$.next({ id: itemId, type: itemType });
  }

  onMouseOut() {
    this._syncRoadmapGuiStateService.clearHoverItem();
  }

  onMouseDown(initialEvent: MouseEvent) {
    if (!this.isNotGuest) {
      return;
    }
    this._roadmapDragService.detectDragDirection(initialEvent).subscribe(({ event }) => {
      this._roadmapDragService.start(event, this.headerRef.nativeElement, this.item);
    });
  }

  onDelete(groupId: number): void {
    if (!this.isNotGuest) {
      return;
    }
    this._store.dispatch(new RoadmapBoardDeleteGroupAction(this.item.id));
    this.onCloseDeleteConfirmDialog();
  }

  onHideContextMenu() {
    this.showContextMenu$.next(false);
  }

  onShowDeleteConfirmationDialog(e?) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.showDeleteConfirmDialog$.next(true);
  }

  onCloseDeleteConfirmDialog() {
    this.showDeleteConfirmDialog$.next(false);
  }

  onConfirmationDialogClick($event) {
    $event.stopImmediatePropagation();
  }

  onEditTitle(e?) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.onHideContextMenu();
    this.editTitle$.next(true);
  }

  onAddNew(type, insertAfter = this.item.id) {
    if (!this.isNotGuest) {
      return;
    }
    this._store.dispatch(
      new RoadmapBoardOpenAddFormAction({
        parent: this.item.id,
        insertAfter,
        type
      })
    );
  }

  onChangeTitle(newTitle: string) {
    if (!this.isNotGuest) {
      return;
    }
    this.editTitle$.next(false);
    if (this.title !== newTitle) {
      this._store.dispatch(
        new RoadmapBoardChangeTitleAction({ id: this.item.id, title: newTitle, oldTitle: this.title })
      );
    }
  }

  onCloseEdit() {
    this.editTitle$.next(false);
  }

  setEditingTaskPercentId(value) {
    if (!this.isNotGuest) {
      return;
    }
    this.editingTaskPercentId = value;
    this._cd.detectChanges();
  }
}
