import { Action } from './unsafe-action';
import { labelAction } from '../functions/action';
import { HttpQueryParam, PartOfEntity } from '../../interfaces';
import { SWIMLANE } from '../../constants';

export const SwimlaneActionTypes = {
  LOAD: labelAction(SWIMLANE, 'LOAD'),
  GET: labelAction(SWIMLANE, 'GET'),
  EDIT: labelAction(SWIMLANE, 'EDIT'),
  DELETE: labelAction(SWIMLANE, 'DELETE'),
  DELETE_COMPLETE: labelAction(SWIMLANE, 'DELETE_COMPLETE'),
  DELETE_FAIL: labelAction(SWIMLANE, 'DELETE_FAIL')
};

export class SwimlaneLoadAction implements Action {
  type = SwimlaneActionTypes.LOAD;

  constructor(public payload: number) {}
}

export class SwimlaneGetAction implements Action {
  type = SwimlaneActionTypes.GET;

  constructor(public payload?: HttpQueryParam) {}
}

export class SwimlaneEditAction implements Action {
  type = SwimlaneActionTypes.EDIT;

  constructor(public payload: PartOfEntity) {}
}

export class SwimlaneDeleteAction implements Action {
  type = SwimlaneActionTypes.DELETE;

  constructor(public payload: number) {}
}

export class SwimlaneDeleteCompleteAction implements Action {
  type = SwimlaneActionTypes.DELETE_COMPLETE;

  constructor(public payload?: any) {}
}

export type SwimlaneActions =
  | SwimlaneGetAction
  | SwimlaneLoadAction
  | SwimlaneEditAction
  | SwimlaneDeleteAction
  | SwimlaneDeleteCompleteAction;
