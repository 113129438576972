<div *ngFor="let archivedTask of archivedItems" class="menu_tabs_content_item">

  <task-preview class="task_card hide_quick_edit"
                [task]="archivedTask"
                (click)="onCloseRightMenu()"
  ></task-preview>

  <div class="menu_tabs_content_link delete_hover" (click)="onToggleDeleteTask(archivedTask.id)">Delete</div>
  <div a-context-popup
       [title]="'Delete Archived Task'"
       *ngIf="isDeleteTaskVisible && archivedTask.id === deleteArchivedTaskId"
       (close)="onToggleDeleteTask(archivedTask.id)"
       class="delete_task_confirm"
  >
    <p class="delete_task_message">
      The changes can not be reverted
    </p>
    <div a-button class="btn_warn" (click)="onDelete(archivedTask)">Delete</div>
    <div a-button (click)="onToggleDeleteTask(archivedTask.id)" class="btn_secondary">Cancel</div>
  </div>
  <div class="menu_tabs_content_link" (click)="onUnArchive(archivedTask)">Unarchive</div>

</div>
