
import {map} from 'rxjs/operators';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debug } from 'util';

const defaultErrorMessages: { [validator: string]: any } = {
  invalidDateFormat: 'Invalid date Format',
  email: 'Please enter a valid email address.',
  required: 'Field is required!'
};

interface FormError {
  code: string;
  details: string;
  message: string;
}

@Component({
  selector: 'help-block',
  templateUrl: './help-block.component.html',
  styleUrls: ['./help-block.component.scss']
})
export class HelpBlockComponent implements OnChanges {
  /**
   * custom error messages for this form control
   * @type {{}}
   */
  @Input() messages: { [validator: string]: any } = {};

  @Input() control: FormControl;

  @Input() countLimit = 1;

  public errors$: Observable<FormError[]>;

  public errorMessages = defaultErrorMessages;

  ngOnChanges(changes: SimpleChanges) {
    this.errorMessages = this.messages ? { ...defaultErrorMessages, ...this.messages } : defaultErrorMessages;
    this.errors$ = this.control.valueChanges.pipe(
      map(() => (this.control.errors ? Object.keys(this.control.errors).slice(0, this.countLimit) : [])),
      map(errors =>
        errors.map(errorCode => {
          // form service sets such errors
          if (!this.errorMessages[errorCode] && errorCode !== 'serverError') {
            console.warn(`There's no message for errorCode '${errorCode}'`);
          }
          return {
            code: errorCode,
            details: this.control.errors[errorCode] === true ? 'true' : JSON.stringify(errorCode),
            message: this.errorMessages[errorCode]
          };
        })
      ),);
  }
}
