<ng-container *ngIf="isEdit;else view">
  <!--<select-->
    <!--#item-->
    <!--[(ngModel)]="task.percent"-->
    <!--(change)="onChange(item.value)"-->
  <!--&gt;-->
    <!--<option *ngFor="let percent of percentValues" [value]="percent">{{percent}}</option>-->
  <!--</select>-->
  <input [focus]="true" type="number" step="50" min="0" max="100" #item [(ngModel)]="task.percent" (change)="onChange(item.value)" />
  <overlay tabindex="-1" (click)="onClose()"></overlay>
</ng-container>

<ng-template #view>
  {{task.percent}}
</ng-template>
