<svg viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg"
     xmlns:xlink="http://www.w3.org/1999/xlink">
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g transform="translate(-63.000000, -666.000000)">
      <g transform="translate(0.000000, 120.000000)">
        <g transform="translate(0.000000, 528.000000)">
          <g transform="translate(50.000000, 6.000000)">
            <g transform="translate(10.000000, 9.000000)">
              <polygon points="0 0 18 0 18 18 0 18"></polygon>
              <polygon class="color" fill-rule="nonzero"
                       points="14.25 9.75 9.75 9.75 9.75 14.25 8.25 14.25 8.25 9.75 3.75 9.75 3.75 8.25 8.25 8.25 8.25 3.75 9.75 3.75 9.75 8.25 14.25 8.25"></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
