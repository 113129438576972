import { createSelector } from 'reselect';

import { prop } from '../../../../../helpers/index';
import { GITHUB_PULL_REQUEST_PL } from '../../../../constants/index';
import { RootActions, RootActionTypes } from '../../../../ngrx/actions/root.action';
import { insertReplaceState, removeOne } from '../../../../ngrx/functions/reducer';
import { getEmptyESState } from '../../../../ngrx/state/index';
import { createCommonSelectors, getByIds } from '../../../../util/store/index';
import { ESGithubPullRequest, GithubPullRequest } from '../../models/git-pull-request';
import * as githubCompany from '../github-company/github-company.actions';
import { createMapWithArray } from '../util';
import * as githubPullRequest from './github-pull-request.actions';

export interface State extends ESGithubPullRequest {
  taskPullRequests: {
    [taskId: number]: number[];
  };
  isLoading: boolean;
}

const initialState: State = {
  ...getEmptyESState<GithubPullRequest>(),
  taskPullRequests: {},
  isLoading: false
};

export function reducer(
  state = initialState,
  action: githubPullRequest.Actions | githubCompany.Actions | RootActions
): State {
  switch (action.type) {
    case githubPullRequest.GET_TASK_PULL_REQUEST: {
      return { ...state, isLoading: true };
    }
    case githubPullRequest.UPDATED: {
      return state;
    }

    case githubPullRequest.GET_TASK_PULL_REQUEST_COMPLETE: {
      const taskPullRequests = {
        ...state.taskPullRequests,
        [action.payload.taskId]: action.payload.entities.ids
      };
      return {
        ...state,
        taskPullRequests: taskPullRequests,
        isLoading: false
      };
    }

    case githubPullRequest.DELETE_TASK_PULL_REQUEST: {
      const newState = removeOne(state, action.payload.id);
      const taskPullRequests = newState.taskPullRequests[action.payload.task] || [];
      newState.taskPullRequests = {
        ...newState.taskPullRequests,
        [action.payload.task]: taskPullRequests.filter(cId => cId !== action.payload.id)
      };
      return newState;
    }

    case githubCompany.REMOVE_ALL_COMPLETE: {
      return initialState;
    }

    case RootActionTypes.GET_COMPLETE: {
      if (GITHUB_PULL_REQUEST_PL in action.payload) {
        const updatedState: ESGithubPullRequest = action.payload[GITHUB_PULL_REQUEST_PL];
        const taskPRs = updatedState.ids.reduce(
          createMapWithArray(updatedState.entities, 'task', 'id'),
          Object.assign({}, state.taskPullRequests)
        );
        const newESState = <State>insertReplaceState(state, updatedState);
        return {
          ...newESState,
          taskPullRequests: taskPRs,
          isLoading: false
        };
      }

      return state;
    }

    default: {
      return state;
    }
  }
}

export const { getState, getEntities, getIds, getAll, isEmpty } = createCommonSelectors<GithubPullRequest, State>(
  GITHUB_PULL_REQUEST_PL
);

export const getTaskPullRequestMap = createSelector(getState, prop('taskPullRequests'));

export const getTaskPullRequestIds = createSelector(getTaskPullRequestMap, state => taskId => state[taskId] || []);
export const getTaskPullRequests = createSelector(
  getTaskPullRequestIds,
  getEntities,
  (commitIdsFn, entities) => (taskId: number) => getByIds(commitIdsFn(taskId), entities)
);

const isLoading = createSelector(getState, state => state.isLoading);

export const getTaskPullRequestsLoading = createSelector(
  isLoading,
  getTaskPullRequestIds,
  (isLoadingFlag, idsFn) => taskId => isLoadingFlag && idsFn(taskId).length === 0
);
