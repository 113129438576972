import { Component, ChangeDetectionStrategy } from '@angular/core';
import { accountServiceUrl } from '../../../helpers/get-api-url';

@Component({
  selector: 'company-access-denied',
  templateUrl: './company-access-denied.component.html',
  styleUrls: ['./company-access-denied.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyAccessDeniedComponent {
  public accountServiceUrl = accountServiceUrl;
}
