
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { TasksSavedListResetAction } from '../actions/task-filters/tasks-saved-list.actions';
import { DefaultTasksSwimlanesFilterActionTypes } from '../actions/task-filters/default-swimlanes-filter.actions';

@Injectable()
export class DefaultTasksSwimlanesFilterEffects {
  @Effect()
  DefaultColumnsFilterToggleItemAction = this._actions$
    .ofType(DefaultTasksSwimlanesFilterActionTypes.TOGGLE_ITEM_ID).pipe(
    map(_ => new TasksSavedListResetAction()));

  constructor(private _actions$: Actions) {}
}
