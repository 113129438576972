<svg viewBox="0 0 5 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g  stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g  transform="translate(-18.000000, -291.000000)" fill-rule="nonzero" fill="#455A64">
      <g  transform="translate(0.000000, 120.000000)">
        <g  transform="translate(0.000000, 152.000000)">
          <g  transform="translate(20.000000, 24.000000) rotate(-90.000000) translate(-20.000000, -24.000000) translate(8.000000, 12.000000)">
            <polygon  points="7 10 12 15 17 10"></polygon>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
