import { label } from '../functions/util';
import { Action } from './unsafe-action';

export const STICKY_LEFT_MENU_FEATURE = 'stickyLeftMenuFeature';

export const ComponentActionTypes = {
  SET_VISIBLE: label('[COMPONENT] SET_VISIBLE'),
  LISTEN_CLICKOUT: label('[COMPONENT] LISTEN_CLICKOUT'),
  TOGGLE: label('[COMPONENT] TOGGLE')
};

export const STICKY_LEFT_MENU = '[COMPONENT] toggle sticky left menu';

export class ComponentSetVisibleAction implements Action {
  type = ComponentActionTypes.SET_VISIBLE;

  constructor(public payload: { name: string; visible: boolean }) {}
}
export class ComponentListenClickoutAction implements Action {
  type = ComponentActionTypes.LISTEN_CLICKOUT;

  constructor(public payload: { name: string; listenClickout: boolean }) {}
}

export class ComponentToggleAction implements Action {
  type = ComponentActionTypes.TOGGLE;

  constructor(public payload: { name: string }) {}
}

export class LeftMenuStickyAction implements Action {
  readonly type = STICKY_LEFT_MENU;

  constructor(public payload: 0 | 1) {}
}
