<div class="pseudo_select" (click)="onToggleDropdown()" #pseudoSelectEl>
  <span class="pseudo_select_value" [ngClass]="{placeholder: selectedItemsLabels === placeholderText}">{{selectedItemsLabels}}</span>
</div>
<div *ngIf="this.isMultiselectOpened$ | async" class="dropdown_wrap">
  <div class="dropdown"
       [style.width.px]="pseudoSelectEl.clientWidth"
       [showBoundToEl]="{nativeElement: pseudoSelectEl}"
       [boundElementTopPadding]="pseudoSelectEl.clientHeight">
    <input type="text"
           *ngIf="searchable"
           [focus]="true"
           #inputTag
           (keyup)="onKeyUp($event)"
           (keydown)="onKeyDown($event)"
           (keypress)="onKeyPress($event)"
    >
    <div class="items-list" *ngIf="(possibleItems$ | async).length;else noItems" #ulEl>
      <p *ngIf="!filterValue" (click)="onSelectAll()"
         [attr.data-index]="-1"
         [ngClass]="{'hover': highlightedItemIndex === -1}"
      >
        All
      </p>
      <p *ngFor="let item of (possibleItems$ | async);let i = index"
         (click)="onSelectItem(item, true)"
         [attr.data-index]="i"
         [ngClass]="{'hover': i === highlightedItemIndex}"
      >
        {{item.label}}
        <span *ngIf="selected[item.id]" class="select_marker"></span>
      </p>
    </div>
  </div>
  <div class="overlay" (click)="onToggleDropdown()"></div>
</div>

<ng-template #noItems>
  <p (click)="onToggleDropdown()">No items</p>
</ng-template>
