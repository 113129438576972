<div a-context-controll
     #contextControlEl
     class="content_menu"
     orientation="vertical"
     [menuSlim]="true"
     [menuTop]="true"
     [menuRight]="true"
     [isMenuVisible]="visibleMenuVersionId === version.id && !isMenuHidden"
     (click)="onToggleVersionMenu($event)"
>
  <p a-context-menu-item (click)="onEdit($event)">Edit</p>
  <p a-context-menu-item (click)="onDelete($event)">Delete</p>
</div>


<div a-confirmation-popup *ngIf="isDeleting"
     [confirmationTitle]="'Delete Version'"
     [actionButtonText]="'Delete'"
     (close)="onDeclineDelete()"
     (confirm)="onConfirmDelete(version.id)"
>
  <p>Are you sure you want to delete this version?</p>
</div>

<div a-context-popup
  *ngIf="isEditing"
  [title]="'Edit Version'"
  (close)="onDeclineEdit()">
  <form [formGroup]="versionForm" (ngSubmit)="onConfirmEdit()">
    <div class="row">
      <label for="version-name">Version name</label>
      <input type="text" id="version-name" name="version-name" [formControlName]="'versionName'">
      <help-block [control]="versionForm.get('versionName')" [messages]="{'maxlength': 'Maximum length of 50 symbols is exceed'}"></help-block>
    </div>
    <div class="row">
      <label for="planned-date">Planned date</label>
      <a-datetime-picker id="planned-date" [formControlName]="'plannedDate'" name="planned-date" [isClearBtnEnabled]="true"></a-datetime-picker>
    </div>
    <button a-button class="btn btn_primary" type="submit" (ngSubmit)="onConfirmEdit()">Save</button>
    <button a-button class="btn btn_text" type="button" (click)="onDeclineEdit()">Cancel</button>
  </form>
</div>
