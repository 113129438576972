import { Task } from '../app/interfaces';
import { ScoringDataType, ScoringSizes } from '../app/board/backlog-board/constants/backlog.constants';

export const calculateBacklogScore = (efforts: number, value: number): number => {
  return efforts === 0 || value === 0 ? null : value - efforts;
};

export const hasScore = (task: Task): boolean => {
  return task.backlogScore !== null && (!!task.backlogScoreY && !!task.backlogScoreX);
};

export const backlogFields = (scoreX, scoreY, score) => ({
  backlogScoreX: scoreX,
  backlogScoreY: scoreY,
  backlogScore: score
});

export const noScoreFields = () => backlogFields(0, 0, null);

export const getVisibleScoringVal = (val, type) => {
  if (val === '?') {
    return '?';
  }
  switch (type) {
    case ScoringDataType.YesNo: {
      return val === 0 ? 'No' : 'Yes';
    }
    case ScoringDataType.Sizes: {
      return ScoringSizes[val] || '?';
    }
    case ScoringDataType.USD: {
      return '$' + val;
    }
    default: {
      return val;
    }
  }
};
