
import {tap, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { PermissionsService } from '../permissions.service';
import { Observable } from 'rxjs';

@Injectable()
export class ProjectMemberGuard implements CanActivate {
  constructor(private _permissions: PermissionsService, private _router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._permissions
      .projectMember(route.params['projectId']).pipe(
      take(1),
      tap(isProjectMember => (!isProjectMember ? this._router.navigate(['/403']) : null)),);
  }
}
