import * as C from './entity';
import {
  BOARD,
  BOARD_PL,
  COLLECTION_PL,
  COLUMN,
  COLUMN_PL,
  CUSTOM_FIELD_PL,
  FILTER_PL,
  GROUP_PL,
  HARVEST_BOARD,
  LABEL_PL,
  PROJECT,
  PROJECT_PL,
  SUBSCRIBER_PL,
  SWIMLANE_PL,
  USER_PL,
  VERSION_PL
} from './entity';
import { SEARCH } from '../path.routing';
import { OPENED_TASK } from '../ngrx/state/constants'; //do not touch this import

export const ESTIMATE = 'estimate';
export const STORY_POINTS = 'storyPoints';

export const LINK_TO_TASK = 'linkToTask';

export const TASK_LINK_EXPANDS = ['linkToTask', 'linkToTask.users.id', 'linkToTask.labels'];

export const defaultExpand = {
  [SEARCH]: [
    PROJECT,
    USER_PL,
    COLUMN,
    COLUMN + '.parent',
    BOARD_PL,
    BOARD_PL + '.' + LABEL_PL,
    BOARD + '.' + SWIMLANE_PL,
    'tasksCustomFields.customFields'
  ],
  [C.TASK_PL]: [
    C.USER_PL,
    C.LABEL_PL,
    C.SUBSCRIBER_PL,
    C.SPRINT_TASK,
    ...TASK_LINK_EXPANDS,
    C.TASKS_LINK_PL,
    'cardTimes',
    'tasksCustomFields.customFields'
  ],
  [OPENED_TASK]: [
    C.TASKS_ATTACHMENT_PL,
    C.WORKLOG_PL,
    C.TASK_ACTIVITY_PL,
    C.TASK_ACTIVITY_PL + '.' + C.EXTERNAL_USER,
    C.TASKS_LINK_PL,
    C.CHECKLIST_PL,
    C.CHECKLIST_PL + '.' + C.CHECKLISTS_ITEM_PL,
    C.GITHUB_COMMIT_PL,
    C.GITHUB_PULL_REQUEST_PL,
    C.BITBUCKET_COMMIT_PL,
    C.BITBUCKET_PULL_REQUEST_PL,
    C.GITLAB_COMMIT_PL,
    C.GITLAB_PULL_REQUEST_PL,
    BOARD + '.' + HARVEST_BOARD,
    BOARD + '.' + USER_PL,
    'cardTimes'
  ],
  [BOARD]: [
    USER_PL,
    GROUP_PL,
    PROJECT_PL,
    PROJECT_PL + '.' + VERSION_PL,
    SWIMLANE_PL,
    SUBSCRIBER_PL,
    COLUMN_PL,
    COLUMN_PL + '.' + SUBSCRIBER_PL,
    LABEL_PL,
    COLLECTION_PL + '.id',
    FILTER_PL,
    HARVEST_BOARD,
    CUSTOM_FIELD_PL
  ],
  [C.COLLECTION_PL]: [C.BOARD_PL],
  [C.PROJECT_PL]: [C.USER_PL, C.BOARD_PL],
  [C.BOARD_PL]: [C.USER_PL],
  [C.CHECKLIST_PL]: [C.CHECKLISTS_ITEM_PL],
  [C.VERSION_PL]: [C.TASK_PL],
  [C.NOTIFICATION_PL]: [C.TASK],
  [C.VERSION_PL]: [C.TASK_PL]
};

export function getDefaultExpand(entityPlCode: string) {
  return defaultExpand.hasOwnProperty(entityPlCode) ? defaultExpand[entityPlCode].join(',') : '';
}
