<div class="title_block" *ngIf="attachmentList.length > 0">
  <div class="attach_icon"></div>
  <h2>ATTACHMENTS
    <span (click)="onToggleTitleMenu()">
        <div a-context-menu class="slim title_menu"
             *ngIf="isTitleMenuVisible"
            (close)="onToggleTitleMenu()"
        >
            <p a-context-menu-item>Download All</p>
        </div>
    </span>
  </h2>
</div>
<div class="attach_items_container" *ngIf="attachmentList.length > 0 || processedFiles.length > 0">

  <div *ngIf="editPermissions" class="attachments_item_add" (click)="onSwitchAttachmentsPopUp()"></div>

  <attachment-list *ngIf="processedFiles.length > 0"
                   [editPermissions]="editPermissions"
                   [taskAttachments]="processedFiles">
  </attachment-list>

  <attachment-list [taskAttachments]="attachmentList"
                   [editPermissions]="editPermissions"
  ></attachment-list>
  <add-attachments
    *ngIf="isAddAttachmentPopUpVisible"
    (close)="onSwitchAttachmentsPopUp()"
    [taskId]="taskId"
  ></add-attachments>
</div>

<a *ngIf="(countHiddenItems$ | async) > 0" (click)="onToggleShortMode()" class="show_more">
  {{ (listShortMode$ | async) ? 'View All Attachments ( ' + (countHiddenItems$ | async) + ' items hidden )' : 'Show fewer attachments'}}
</a>
<div class="exceed_overlay" [class.show_exceed]="fileExceeds$ | async">
  <div class="size_exceed">
    <p>The uploaded file exceeds the maximum allowed size of {{maxFileSize | convertFileSize : 'MB'}}MB.</p>
    <button (click)="onHideExceedPopup()">OK</button>
  </div>
</div>
<input #uploadnputAttach hidden type="file" multiple="multiple" (change)="onUploadFilesFromWindow($event)">
<file-drag-and-drop
  (filesForUpload)="onUploadFilesFromDragAndDrop($event)"
></file-drag-and-drop>
