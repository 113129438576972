<div>
  <a a-context-menu-item
     [href]="company.link"
     target="_blank"
     rel="noopener"
     *ngFor="let company of companies$ | async;"
     [class.checked]="activeCompanyId === company.id"
  >
    {{company.name}}
  </a>
</div>
