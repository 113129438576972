import { FormControl, ValidatorFn } from '@angular/forms';

export function validateIntegerFactory(): ValidatorFn {
  return (c: FormControl) => {
    const trimmedValue = typeof c.value === 'string' ? c.value.trim() : c.value;

    return Number.isInteger(trimmedValue) ||
      (!isNaN(parseFloat(trimmedValue)) &&
        isFinite(trimmedValue) &&
        parseInt(trimmedValue, 10).toString() === trimmedValue)
      ? null
      : {
          integerValidator: {
            valid: false
          }
        };
  };
}
