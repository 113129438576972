<input type="text" [focus]="true" placeholder="Search projects..." (keyup)="0" #input_search_projects/>
<div class="projects_container">
  <div *ngFor="let project of projects | searchByField: 'name': input_search_projects.value"
       class="project_title"
       (click)="onSelectProject(project)"
  >
    <p>
      {{project.name}}
      <span class="check_label" *ngIf="isSelectedProject(project)"></span>
    </p>
  </div>
</div>
