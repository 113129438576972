import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataSyncActionTypes } from '../actions/data-sync.action';

import {
  prepareObjectState,
  SocketEventName,
  SocketService
} from '../../shared/services/app/web-socket/web-socket.service';
import { DataSyncService } from '../../shared/services/app/web-socket/data-sync.service';
import { Model } from '../../shared/services/app/models/';
import { GetCompleteAction, RemoveEntitiesByIdsAction } from '../actions/root.action';
import { WebSocketAnswer, WebSocketDeleteAnswer } from '../reducers/data-sync.reducer';
import { CompanyService } from '../../shared/services/app/company.service';
import { modelsToEntityState } from '../../shared/services/app/web-socket/http-response';
import { map } from 'rxjs/operators';
import { AppState } from '../state';
import { Store } from '@ngrx/store';

export type DiffAnswer = [SocketEventName, WebSocketAnswer];

@Injectable()
export class DataSyncEffects {
  @Effect({ dispatch: false })
  loadDiff$ = this.actions$.ofType(DataSyncActionTypes.LOAD_DIFF).pipe(
    map(({ type, payload }: { type: string; payload: { time: number } }) => {
      this._dataSync.loadDiff({ fromTime: payload.time, limit: 300, offset: 0, company: this._companyService.id });
    })
  );

  @Effect()
  objectState$ = this.actions$
    .ofType(DataSyncActionTypes.OBJECT_STATE)
    .pipe(
      map(
        ({ type, payload }: { type: string; payload: Model<any>[] }) =>
          new GetCompleteAction(modelsToEntityState(payload))
      )
    );

  @Effect()
  objectDelete$ = this.actions$.ofType(DataSyncActionTypes.OBJECT_DELETE).pipe(
    map(
      ({ type, payload }: { type: string; payload: WebSocketDeleteAnswer[] }) => new RemoveEntitiesByIdsAction(payload)
      // new RemoveEntitiesFromStoreAction(payload.map(toRemovePayload))
    )
  );

  @Effect({ dispatch: false })
  queueJob$ = this.actions$.ofType(DataSyncActionTypes.QUEUE_JOB).pipe(
    map(({ type, payload }: { type: string; payload: WebSocketAnswer[] }) => {
      const jobResult = prepareObjectState(payload);
      this._socket.initLoadingOfJobContent(jobResult, this._store);
    })
  );

  constructor(
    private actions$: Actions,
    private _companyService: CompanyService,
    private _dataSync: DataSyncService,
    private _socket: SocketService,
    private _store: Store<AppState>
  ) {}
}
