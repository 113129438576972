import { Pred } from 'ramda';
import { defaultMemoize } from 'reselect';
import { Observable } from 'rxjs';

import { isObject, isPresent } from '../../../helpers';
import { Entity } from '../../interfaces';
import { combineLatest, distinctUntilChanged, map } from 'rxjs/operators';

export interface BaseState {
  [prop: string]: any;
}

export interface AppState extends BaseState {
  [entityCode: string]: BaseState | EntityStateModel<Entity>;
}

export interface Entities<T> {
  [id: number]: T;
  [id: string]: T;
}

export interface ESInterface<T> {
  ids: number[];
  entities: Entities<T>;
  selectedEntityId?: number;
}

export const entityStateToArray = (state: ESInterface<Entity>): Entity[] =>
  <Entity[]>state.ids.map(id => state.entities[id]);

export const arrayToEntities = (list: Entity[]) =>
  list.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});

export class EntityStateModel<T> {
  constructor(public ids: number[] = [], public entities: Entities<T> = {}, public selectedEntityId: number = null) {}
}

export class EntityState<T extends Entity> implements ESInterface<T> {
  public ids: number[];
  public entities: Entities<T> = {};

  static fromArray<T extends Entity>(array: T[]): EntityState<T> {
    const ids = [];
    const entities = array.filter(isPresent).reduce((acc, item) => {
      acc[item.id] = item;
      ids.push(item.id);
      return acc;
    }, {});
    return new EntityState<T>(ids, entities);
  }

  static fromState<T extends Entity>(state: ESInterface<T>): EntityState<T> {
    const entities = state.ids.reduce((acc, id) => {
      acc[id] = state.entities[id];
      return acc;
    }, {});
    return new EntityState<T>(state.ids, entities);
  }

  static fromObject<T extends Entity>(obj: { [id: number]: T }): EntityState<T> {
    const ids = [];
    const entities = Object.keys(obj).reduce((acc, id) => {
      acc[id] = obj[id];
      ids.push(id);
      return acc;
    }, {});
    return new EntityState<T>(ids, entities);
  }

  static from<T extends Entity>(source: any) {
    if (Array.isArray(source)) {
      return EntityState.fromArray(source);
    }
    if (isObject(source)) {
      if (source.ids && Array.isArray(source.ids) && source.entities && isObject(source.entities)) {
        return EntityState.fromState(source);
      }
      return EntityState.fromObject(source);
    }
    throw new Error('unknow source type');
  }

  private constructor(ids, entities) {
    this.ids = ids;
    this.entities = entities;
  }

  public forEach(callback: Function) {
    this.ids.forEach(id => callback(this.entities[id]));
  }

  public reduce(reducer: Function, defaultValue) {
    return this.ids.reduce((acc, id) => reducer(acc, this.entities[id]), defaultValue);
  }

  public filter(predicate: Pred) {
    return EntityState.fromState({
      ids: this.ids.filter(id => predicate(this.entities[id])),
      entities: this.entities
    });
  }

  public find(predicate: Pred) {
    return this.get(this.ids.find(id => predicate(this.entities[id])));
  }

  public sort(compareFn) {
    return EntityState.fromArray(this.toArray().sort(compareFn));
  }

  public getSortedIds(compareFn) {
    return this.toArray()
      .sort(compareFn)
      .map(xs => xs['id']);
  }

  /**
   * don't use it if you copied one's code
   * use very Careful - it mutates current object
   * @param {T} item
   */
  public add(item: T) {
    this.entities[item.id] = item;
    this.ids.push(item.id);
  }

  public get(id) {
    return this.entities[id];
  }

  public toArray(): T[] {
    return <T[]>entityStateToArray(this);
  }

  public clone(deep = false) {
    return deep ? new EntityState<T>([...this.ids], { ...this.entities }) : new EntityState<T>(this.ids, this.entities);
  }
}

export function getEmptyESState<S>(): ESInterface<S> {
  return {
    ids: [],
    entities: {}
  };
}

export function runParametricalSelector<T, K>(selector$: Observable<(K) => T>, parameter: Observable<K> | K) {
  if (typeof parameter === 'object' && parameter['subscribe'] !== undefined) {
    return selector$.pipe(
      combineLatest(<Observable<K>>parameter, defaultMemoize((selectorFn, taskId) => selectorFn(taskId))),
      distinctUntilChanged()
    );
  } else {
    return selector$.pipe(map(selectorFn => selectorFn(parameter)));
  }
}
