import { OnInit, Directive, ElementRef, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';

import * as d3 from 'd3';

@Directive({
  selector: '[d3DragDrop]'
})
export class D3DragDirective implements OnInit, AfterViewInit {
  constructor(private _element: ElementRef) {}

  @Input()
  set d3DragDrop(value) {
    this.dragProps = Object.assign({}, this.dragProps, value);
  }

  @Output() d3DragStart = new EventEmitter<any>();
  @Output() d3Drag = new EventEmitter<any>();
  @Output() d3DragEnd = new EventEmitter<any>();

  /**
   * key - d3 prop(like filter, container etc)
   * @type {{}}
   */
  private dragProps = {};

  ngOnInit(): any {}

  ngAfterViewInit() {
    const drag = d3
      .drag()
      .on('start', (data, index, nodeList) => this.d3DragStart.emit(this.getDragDropEvent()))
      .on('drag', (data, index, nodeList) => this.d3Drag.emit(this.getDragDropEvent()))
      .on('end', (data, index, nodeList) => this.d3DragEnd.emit(this.getDragDropEvent()));
    this.appendDragProps(drag);

    d3.select(this._element.nativeElement).call(drag);
  }

  getDragDropEvent() {
    return d3.event;
  }

  private appendDragProps(dragBehavior) {
    for (const prop in this.dragProps) {
      if (typeof dragBehavior[prop] === 'function') {
        dragBehavior[prop](this.dragProps[prop]);
      }
    }
  }
}
