import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'board-label',
  template: `
    <span
      class="sprint_in_progress"
      *ngIf="sprintStartDate" 
      a-tooltip="Sprint '{{sprintName}}' is running" 
      position="bottom-right"
    ></span>
    <h3 class="board_label_title" title="{{boardName}}">
      {{boardName}}
    </h3>
    <p *ngIf="sprintReleased">
      <span class="released_icon"></span>
      Released
    </p>`,
  styleUrls: ['./board-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoardLabelComponent {
  @Input() name = '';
  @Input() sprintName = '';
  @Input() sprintStartDate = 0;
  @Input() sprintReleased = 0;

  get boardName() {
    return this.sprintStartDate ? this.name + ' / ' + this.sprintName : this.name;
  }
}
