import { Action } from './unsafe-action';
import { labelAction } from '../functions/action';
import { PartOfEntity, User, HttpQueryParam } from '../../interfaces';
import { USER } from '../../constants';
import { Entities } from '../state';
import { UsersCompany } from '../../interfaces/users-compnay';

export const UserActionTypes = {
  LOAD: labelAction(USER, 'LOAD'),
  GET: labelAction(USER, 'GET'),
  GET_INDEX: labelAction(USER, 'GET_INDEX'),
  GET_COMPLETE: labelAction(USER, 'GET_COMPLETE'),
  GET_FAIL: labelAction(USER, 'GET_FAIL'),
  ADD: labelAction(USER, 'ADD'),
  ADD_COMPLETE: labelAction(USER, 'ADD_COMPLETE'),
  ADD_FAIL: labelAction(USER, 'ADD_FAIL'),
  EDIT: labelAction(USER, 'EDIT'),
  EDIT_COMPLETE: labelAction(USER, 'EDIT_COMPLETE'),
  EDIT_FAIL: labelAction(USER, 'EDIT_FAIL'),
  DELETE: labelAction(USER, 'DELETE'),
  DELETE_COMPLETE: labelAction(USER, 'DELETE_COMPLETE'),
  DELETE_FAIL: labelAction(USER, 'DELETE_FAIL'),
  MOVE: labelAction(USER, 'MOVE'),
  SYNC_WITH_USER_COMPANY: labelAction(USER, 'SYNC_WITH_USER_COMPANY'),
  GET_DELETED_USERS: labelAction(USER, 'get deleted users')
};

export class UserLoadAction implements Action {
  type = UserActionTypes.LOAD;

  constructor(public payload: number) {}
}

export class UserGetAction implements Action {
  type = UserActionTypes.GET;

  constructor(public payload?: HttpQueryParam) {}
}

export class UserGetIndexAction implements Action {
  type = UserActionTypes.GET_INDEX;

  constructor(public payload?: HttpQueryParam) {}
}

export class UserGetCompleteAction implements Action {
  type = UserActionTypes.GET_COMPLETE;

  constructor(public payload?: User[]) {}
}

export class UserEditAction implements Action {
  type = UserActionTypes.EDIT;
  constructor(public payload: PartOfEntity) {}
}

export class UserEditCompleteAction implements Action {
  type = UserActionTypes.EDIT_COMPLETE;

  constructor(public payload: User) {}
}

export class UserDeleteAction implements Action {
  type = UserActionTypes.DELETE;

  constructor(public payload: User) {}
}

export class UserDeleteCompleteAction implements Action {
  type = UserActionTypes.DELETE_COMPLETE;

  constructor(public payload?: any) {}
}

export class UserAddAction implements Action {
  type = UserActionTypes.ADD;

  constructor(public payload: PartOfEntity) {}
}

export class UserAddCompleteAction implements Action {
  type = UserActionTypes.ADD_COMPLETE;

  constructor(public payload: any) {}
}

export class UserSyncWithUserCompanyAction implements Action {
  type = UserActionTypes.SYNC_WITH_USER_COMPANY;

  constructor(public payload: Entities<UsersCompany>) {}
}

export class GetDeletedUsers implements Action {
  readonly type = UserActionTypes.GET_DELETED_USERS;

  constructor(public payload?: HttpQueryParam) {}
}

export type UserActions =
  | UserGetAction
  | UserLoadAction
  | UserGetCompleteAction
  | UserEditAction
  | UserEditCompleteAction
  | UserDeleteAction
  | UserDeleteCompleteAction
  | UserAddAction
  | UserAddCompleteAction
  | GetDeletedUsers;
