import { BehaviorSubject, combineLatest, Observable, of as observableOf } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  pluck,
  publishReplay,
  refCount,
  switchMap,
  take,
  withLatestFrom
} from 'rxjs/operators';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Store } from '@ngrx/store';
import { getTaskBySlug, taskTitle } from '../../../ngrx/reducers/task.reducer';
import { AppState } from '../../../ngrx/state';
import { Board, Column, Project, Swimlane, Task } from '../../../interfaces';
import { isPresent } from '../../../../helpers';
import { BOARD, BOARD_PL, COLUMN, COLUMN_PL, PROJECT, PROJECT_PL, SWIMLANE, SWIMLANE_PL } from '../../../constants';
import { getColumnById } from '../../../ngrx/reducers/column.reducer';
import { AtlazApiV2Service } from '../../services/atlaz-api/v2/atlaz-api-v2.service';
import { getTaskKey } from '../../../../helpers/task';
import { TaskDetailPageRelatedDataService } from '../../../task/task-detail-page/services/task-detail-page-related-data.service';

const pluckBoard = (x: Task) => x.board;
const pluckColumn = (x: Task) => x.column;
const pluckSwimlane = (x: Task) => x.swimlane;
const pluckProject = (x: Task) => x.project;

const hasProject = (t: Task) => !!t && t.project > 0;
const hasParent = (c: Column) => !!c && c.parent > 0;
const haveToLoadParent = (c, parent) => hasParent(c) && !parent;

@Component({
  selector: 'smart-task-link',
  template: `
    <smart-link *ngIf="!(loaded$ | async)" [value]="value"></smart-link>
    <smart-task-link-inner
      *ngIf="(loaded$ | async)"
      [link]="value"
      [taskKey]="taskKey$ | async"
      [task]="task$ | async"
      [board]="board$ | async"
      [column]="column$ | async"
      [swimlane]="swimlane$ | async"
      [parentColumn]="parentColumn$ | async"
      [directLink]="directLink"
    >
    </smart-task-link-inner>
  `,
  styleUrls: ['./smart-task-link-inner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartTaskLinkComponent implements OnInit, AfterViewChecked {
  @Input() value = '';

  @Input()
  set props(props) {
    this.taskSlug$.next(props['taskSlug']);
  }

  @Input() directLink = true;
  @Output() update = new EventEmitter();

  title$: Observable<string>;
  taskKey$: Observable<string>;
  task$: Observable<Task>;
  board$: Observable<Board>;
  column$: Observable<Column>;
  swimlane$: Observable<Swimlane>;
  project$: Observable<Project>;
  parentColumn$: Observable<Column>;
  loaded$: Observable<boolean>;

  public taskSlug$ = new BehaviorSubject(null);

  constructor(
    protected _store: Store<AppState>,
    protected _apiV2: AtlazApiV2Service,
    protected _detailLoader: TaskDetailPageRelatedDataService
  ) {}

  get taskSlug() {
    return this.taskSlug$.getValue();
  }

  getEntity(entity, idCompute) {
    return this.task$.pipe(
      switchMap(
        (task: Task) =>
          task
            ? this._store.pipe(pluck(entity, 'entities', idCompute(task)), distinctUntilChanged())
            : observableOf(null)
      ),
      publishReplay(1),
      refCount()
    );
  }

  ngOnInit(): any {
    this.task$ = <Observable<Task>>this.taskSlug$.pipe(
      filter(isPresent),
      distinctUntilChanged(),
      switchMap(slug => this._store.pipe(getTaskBySlug(slug))),
      publishReplay(1),
      refCount()
    );

    this.board$ = this.getEntity(BOARD_PL, pluckBoard);
    this.column$ = this.getEntity(COLUMN_PL, pluckColumn);
    this.swimlane$ = this.getEntity(SWIMLANE_PL, pluckSwimlane);
    this.project$ = this.getEntity(PROJECT_PL, pluckProject);

    this.parentColumn$ = this.column$.pipe(
      switchMap(
        (column: Column) =>
          column && column.parent ? this._store.pipe(getColumnById(column.parent)) : observableOf(null)
      )
    );

    this.task$
      .pipe(
        take(1),
        withLatestFrom(this.board$, this.column$, this.parentColumn$, this.swimlane$, this.project$),
        filter(
          ([task, board, column, parentColumn, swimlane, project]) =>
            !task ||
            !board ||
            !column ||
            !swimlane ||
            (hasProject(task) && !project) ||
            haveToLoadParent(column, parentColumn)
        )
      )
      .subscribe(([task, board, column, parentColumn, swimlane, project]) => {
        const expands = [
          !board ? BOARD : null,
          hasProject(task) && !project ? PROJECT : null,
          !column || !parentColumn ? COLUMN : null,
          !column || haveToLoadParent(column, parentColumn) ? COLUMN + '.parent' : null,
          !swimlane ? SWIMLANE : null
        ].filter(isPresent);
        this._detailLoader.loadTaskWithContext(this.taskSlug, expands);
      });

    this.title$ = taskTitle(this.task$, this.project$);
    this.taskKey$ = combineLatest(this.task$, this.project$).pipe(map(([task, project]) => getTaskKey(task, project)));

    this.loaded$ = combineLatest(
      this.task$,
      this.project$,
      this.board$,
      this.swimlane$,
      this.column$,
      this.parentColumn$
    ).pipe(
      map(
        ([task, project, board, swimlane, column, parentColumn]) =>
          !!task &&
          (!hasProject(task) || !!project) &&
          !!board &&
          !!swimlane &&
          !!column &&
          !haveToLoadParent(column, parentColumn)
      )
    );
  }

  ngAfterViewChecked() {
    this.update.emit();
  }
}
