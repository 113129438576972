import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../ngrx/state';
import { AuthService } from '../../../../services/app/auth.service';
import { Router } from '@angular/router';
import { RouterNavigateService } from '../../../../services/router-navigate.service';
import { PaywallService } from '../../../../../libs/paywall/paywall.service';
import { Column, Project, Task, User, Version } from '../../../../../interfaces';
import { Observable, of, Subscription } from 'rxjs';
import { AContextControllComponent } from '../../../../../atlaz-gui/a-context-controll/a-context-controll.component';
import { AppUrls } from '../../../../../app-urls';
import { GuiStateBatchEditHide } from '../../../../../ngrx/actions/gui-state-memorized.actions';
import { fromColumns, getGlobalDoneColumnsByBoardId } from '../../../../../ngrx/reducers/column.reducer';
import { map, publishReplay, refCount, switchMap, take } from 'rxjs/operators';
import { createMapFromArr, naturalSort } from '../../../../../../helpers';
import {
  getSelectedBoardUsers,
  getTaskPossibleUsers,
  getVersionsListByBoardId
} from '../../../../../ngrx/functions/crossed.selector';
import { fromBoards, getProjectsByBoard } from '../../../../../ngrx/reducers/board.reducer';
import { Features } from '../../../../../libs/paywall/features.constants';
import {
  TaskBatchAssignUsersAction,
  TaskBatchDeleteAction,
  TaskEditAction
} from '../../../../../ngrx/actions/task.actions';
import { BoardAssignUsersAction } from '../../../../../ngrx/actions/board.actions';
import { OpenedTaskDataChangedAction } from '../../../../../task/ngrx/actions/opened-task.action';

@Component({
  selector: 'content-menu-batch',
  templateUrl: './content-menu-batch.component.html',
  styleUrls: ['../content-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentMenuBatchComponent implements OnInit, OnDestroy {
  @Input() batchTasksInput$: Observable<Task[]>;
  @Input() editPermissions: boolean;
  @Input() boardId: number;

  @ViewChild('popup') popup: AContextControllComponent;

  public batchTasks$: Observable<Task[]>;
  public batchTaskIds: number[];
  public task: Task;

  public isDeleteTaskVisible = false;
  public isContentMenuVisible = true;
  public isMoveTaskMenuVisible = false;
  public isPushTaskMenuVisible = false;
  public isCopyTaskMenuVisible = false;
  public isAddMembersPopupVisible = false;
  public isRemoveMembersPopupVisible = false;
  public isProjectsPopupVisible = false;
  public isVersionsPopupVisible = false;
  public isAddLabelsPopupVisible = false;
  public isRemoveLabelsPopupVisible = false;
  public isArchiveTaskVisible = false;

  public isPublicBoard$: Observable<boolean>;
  public boardProjects$: Observable<Project[]>;
  public boardVersions$: Observable<Version[]>;
  public possibleUsers$: Observable<User[]>;
  public notDoneTasks$: Observable<Task[]>;
  public isMarkAsDoneVisible$: Observable<boolean>;
  public isMarkAsDoneErr = false;
  public showDueDatePopUp = false;
  public showStartDatePopUp = false;

  public appUrls = AppUrls;

  subs: Subscription[] = [];

  constructor(
    private _store: Store<AppState>,
    private _userAuthService: AuthService,
    private _router: Router,
    private _routerNav: RouterNavigateService,
    private _paywall: PaywallService
  ) {}

  ngOnInit() {
    this.batchTasks$ = this.batchTasksInput$.pipe(map(tasks => tasks.slice(0, 100)), publishReplay(1), refCount());
    this.subs.push(
      this.batchTasks$.subscribe(tasks => {
        this.batchTaskIds = tasks.map(task => task.id);
        this.task = tasks[0];
        if (!tasks.length) {
          this._store.dispatch(new GuiStateBatchEditHide());
          this.isContentMenuVisible = false;
        }
      })
    );

    this.isPublicBoard$ = this._store.select(fromBoards.isSelectedBoardPublic);

    this.notDoneTasks$ = this.batchTasks$.pipe(
      switchMap(tasks => {
        const ids = tasks.map(task => task.column);
        return this._store.select(fromColumns.getByIds(ids)).pipe(map((columns: Column[]) => ({ tasks, columns })));
      }),
      map(({ tasks, columns }) => {
        const columnsMap = createMapFromArr(columns);
        return tasks.filter(
          task => !task.archived && columnsMap[task.column] && columnsMap[task.column].type !== 'done'
        );
      })
    );

    this.isMarkAsDoneVisible$ = this.notDoneTasks$.pipe(map(tasks => !!tasks.length));

    this.possibleUsers$ = this.batchTasks$.pipe(
      switchMap(
        tasks =>
          tasks[0] && tasks.every(task => task.project === tasks[0].project)
            ? this._store.select(getTaskPossibleUsers(tasks[0].id))
            : this._store.select(getSelectedBoardUsers).pipe(map(naturalSort('fullname')))
      )
    );

    this.boardProjects$ = <Observable<Project[]>>this._store.pipe(getProjectsByBoard(this.boardId));
    this.boardVersions$ = this._store
      .select(getVersionsListByBoardId(this.boardId))
      .pipe(map(versions => versions.filter(item => !item.released)));
  }

  onToggleDeleteTask() {
    this.isDeleteTaskVisible = !this.isDeleteTaskVisible;
  }

  onToggleArchiveTask() {
    this.isArchiveTaskVisible = !this.isArchiveTaskVisible;
  }

  onSwitchMoveTaskMenu() {
    if (!this.isMoveTaskMenuVisible && !this.batchTaskIds.length) {
      return;
    }
    this.isMoveTaskMenuVisible = !this.isMoveTaskMenuVisible;
  }

  onSwitchCopyTaskMenu() {
    if (this._paywall.isFeatureEnabled(Features.CanAddTask)) {
      this.isCopyTaskMenuVisible = !this.isCopyTaskMenuVisible;
    } else {
      this._paywall.showPayWall(Features.CanAddTask);
    }
  }

  onSwitchPushTaskMenu() {
    if (this._paywall.isFeatureEnabled(Features.CanAddTask)) {
      if (!this.isPushTaskMenuVisible && !this.batchTaskIds.length) {
        return;
      }
      this.isPushTaskMenuVisible = !this.isPushTaskMenuVisible;
    } else {
      this._paywall.showPayWall(Features.CanAddTask);
    }
  }

  onArchiveTask() {
    const batchData = { id: this.batchTaskIds.join(','), archived: true };
    this._store.dispatch(new TaskEditAction(batchData));
    this.isContentMenuVisible = false;
    this._store.dispatch(new GuiStateBatchEditHide());
  }

  onDeleteTask() {
    this._store.dispatch(new TaskBatchDeleteAction(this.batchTaskIds));
    this.isContentMenuVisible = false;
    this._store.dispatch(new GuiStateBatchEditHide());
    return;
  }

  onSwitchAddMembers() {
    if (!this.isAddMembersPopupVisible && !this.batchTaskIds.length) {
      return;
    }
    this.isAddMembersPopupVisible = !this.isAddMembersPopupVisible;
  }

  onSwitchRemoveMembers() {
    if (!this.isRemoveMembersPopupVisible && !this.batchTaskIds.length) {
      return;
    }
    this.isRemoveMembersPopupVisible = !this.isRemoveMembersPopupVisible;
  }

  onSwitchProjects() {
    if (!this.isProjectsPopupVisible && !this.batchTaskIds.length) {
      return;
    }
    this.isProjectsPopupVisible = !this.isProjectsPopupVisible;
  }

  onSwitchVersions() {
    if (!this.isVersionsPopupVisible && !this.batchTaskIds.length) {
      return;
    }
    this.isVersionsPopupVisible = !this.isVersionsPopupVisible;
  }

  onUpdateUsersList(userListUpdate) {
    this._store.dispatch(new TaskBatchAssignUsersAction({ ids: this.batchTaskIds, users: userListUpdate }));
  }

  onAddUserToBoard(user: User) {
    this._store.dispatch(new BoardAssignUsersAction({ id: this.boardId, users: { add: [user.id] } }));
  }

  onSwitchAddLabels() {
    if (!this.isAddLabelsPopupVisible && !this.batchTaskIds.length) {
      return;
    }
    this.isAddLabelsPopupVisible = !this.isAddLabelsPopupVisible;
  }

  onSwitchRemoveLabels() {
    if (!this.isRemoveLabelsPopupVisible && !this.batchTaskIds.length) {
      return;
    }
    this.isRemoveLabelsPopupVisible = !this.isRemoveLabelsPopupVisible;
  }

  onMarkAsDone() {
    let payloadId;
    this.notDoneTasks$
      .pipe(
        switchMap(tasks => {
          payloadId = tasks.map(item => item.id).join(',');
          return tasks[0] ? this._store.select(getGlobalDoneColumnsByBoardId(tasks[0].board)) : of([]);
        }),
        take(1)
      )
      .subscribe((columns: Column[]) => {
        const targetCol = <Column>columns.reduce(
          (acc, item) => {
            if (!item.archived && item.position > acc.position) {
              return item;
            } else {
              return acc;
            }
          },
          { position: -1 }
        );
        if (targetCol && targetCol.id && !targetCol.archived) {
          this._store.dispatch(new TaskEditAction({ id: payloadId, column: targetCol.id, insertBeforeTask: 0 }));
          this._store.dispatch(new OpenedTaskDataChangedAction({ highlightAfterClosing: false }));
        } else {
          this.onToggleMarkAsDoneErr();
        }
        this.isContentMenuVisible = false;
        this._store.dispatch(new GuiStateBatchEditHide());
      });
  }

  onToggleMarkAsDoneErr() {
    this.isMarkAsDoneErr = !this.isMarkAsDoneErr;
  }

  onSwitchDueDate() {
    if (!this.showDueDatePopUp && !this.batchTaskIds.length) {
      return;
    }
    this.showDueDatePopUp = !this.showDueDatePopUp;
  }

  onSwitchStartDate() {
    if (!this.showStartDatePopUp && !this.batchTaskIds.length) {
      return;
    }
    this.showStartDatePopUp = !this.showStartDatePopUp;
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  noop() {}
}
