<ul [class.subcollection]="isSubCollection">
  <li *ngFor="let board of boards" routerLinkActive="board_selected"
      [class.with_projects]="!!collection"
  >
    <a class="board_link" (click)="onCloseLeftMenu()" [routerLink]="appUrls.getUrlBoard(board.id)">
      <svg class="board_icon" [attr.fill]="boardThemesMap[board.theme]" width="18" height="18">
        <use [attr.xlink:href]="'#' + board.type + '-board'"></use>
      </svg>
      <span class="board_name">{{board.name}}</span>
    </a>
    <left-favorite-star class="favorite" [boardId]="board.id"></left-favorite-star>
  </li>
</ul>
