import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, LEFT_MENU, RIGHT_MENU } from '../../ngrx/state';
import {
  GuiStateQuickTaskEditShow,
  GuiStateSetHotkeysTargetTask
} from '../../ngrx/actions/gui-state-memorized.actions';
import { Observable } from 'rxjs/Observable';
import { Column, Swimlane, Task } from 'app/interfaces';
import { fromTask, getTasksByUsersFilter, isActiveTask } from '../../ngrx/reducers/task.reducer';
import { map, publishReplay, refCount, switchMap, take } from 'rxjs/operators';
import { getSelectedBoardColumns, getSelectedBoardSwimlanes } from '../../ngrx/functions/crossed.selector';
import { columnTypes } from '../../constants';
import { fromGuiState } from '../../ngrx/reducers/gui-state-memorized.reducer';
import { TaskAssignSubscriberAction, TaskAssignUsersAction, TaskEditAction } from '../../ngrx/actions/task.actions';
import { Router } from '@angular/router';
import { BOARDS } from '../../path.routing';
import { fromBoards } from '../../ngrx/reducers/board.reducer';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { AuthService } from '../../shared/services/app/auth.service';
import { ComponentToggleAction } from '../../ngrx/actions/component.actions';
import { FILTER_USER } from '../../ngrx/reducers/task-filters/default-task-filter.reducer';
import { PermissionsService } from '../../permissions/permissions.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalHotkeysService {
  public ignoreMouseEvents = false;
  public isIgnoreKeyBoardEvents = false;
  private _filteredTasks$: Observable<Task[]>;
  private _selectedBoardColumns$: Observable<Column[]>;
  private _selectedBoardSwimlanes$: Observable<Swimlane[]>;
  private _selectedBoardId: number | null;
  private _availableForNavColumns: Column[];
  private _availableForMoveColumns: Column[];
  public currentTask$: Observable<Task>;
  private _currentTask: Task;
  private _filteredTasks: Task[] = [];
  private _hiddenSwimlanesMap$: Observable<{ [id: number]: boolean }>;
  public listener;

  constructor(
    private _store: Store<AppState>,
    private _router: Router,
    private _authService: AuthService,
    private _permissions: PermissionsService
  ) {
    const selectedBoard$ = this._store.select(fromBoards.getSelectedBoard);

    selectedBoard$.subscribe(board => {
      this._selectedBoardId = board ? board.id : null;
    });
    this._filteredTasks$ = this._store.pipe(
      getTasksByUsersFilter(isActiveTask),
      map(tasks => tasks.filter(item => item.board === this._selectedBoardId)),
      publishReplay(1),
      refCount()
    );

    this.currentTask$ = this._store
      .select(fromGuiState.getHotkeysTargetTask)
      .pipe(switchMap(id => this._store.select(fromTask.taskById(id))));

    this._selectedBoardColumns$ = this._store.select(getSelectedBoardColumns).pipe(
      map((columns: Column[]) => {
        columns = columns || [];
        const nonArchiveColumns = columns.filter(item => !item.archived);
        const subColumnsMap = {};
        const columnsWithoutSubColumns = nonArchiveColumns.filter(item => {
          if (item.type !== columnTypes.sub_inprogress && item.type !== columnTypes.sub_done) {
            return true;
          } else {
            subColumnsMap[item.id] = item; //side effect
            return false;
          }
        });
        return columnsWithoutSubColumns.sort((a, b) => a.position - b.position).reduce((acc, item) => {
          if (item.subColumnsIds.length) {
            const subC0 = { ...subColumnsMap[item.subColumnsIds[0]] };
            const subC1 = { ...subColumnsMap[item.subColumnsIds[1]] };
            subC0.position = item.position;
            subC1.position = item.position + 0.5;
            acc.push(subC0, subC1);
          } else {
            acc.push(item);
          }
          return acc;
        }, []);
      })
    );

    this._selectedBoardSwimlanes$ = this._store.select(getSelectedBoardSwimlanes);

    this._hiddenSwimlanesMap$ = this._store.select(fromGuiState.getHiddenSwimlanes);

    combineLatest(
      this._filteredTasks$,
      this._selectedBoardColumns$,
      this._selectedBoardSwimlanes$,
      this._hiddenSwimlanesMap$,
      this.currentTask$
    ).subscribe(([storeTasks, storeColumns, storeSwims, storeHiddenSwims, currentTask]) => {
      const tasks = storeTasks || [];
      const columns = storeColumns || [];
      const swims = storeSwims || [];
      const hiddenSwims = storeHiddenSwims || {};
      const swimsPositionMap = swims.reduce((acc, swim) => {
        acc[swim.id] = swim.position;
        return acc;
      }, {});
      const colPositionMap = columns.reduce((acc, col) => {
        acc[col.id] = col.position;
        return acc;
      }, {});
      this._filteredTasks = tasks.filter(task => !hiddenSwims[task.swimlane]).sort((a, b) => {
        if (b.swimlane !== a.swimlane) {
          return swimsPositionMap[a.swimlane] - swimsPositionMap[b.swimlane];
        } else if (b.column !== a.column) {
          return colPositionMap[a.column] - colPositionMap[b.column];
        } else {
          return a.position - b.position;
        }
      });
      this._currentTask = currentTask;
      this._availableForMoveColumns = columns;
      this._availableForNavColumns = columns.filter(column =>
        this._filteredTasks.some(
          task => task.column === column.id && (!currentTask || task.swimlane === currentTask.swimlane)
        )
      );
      if (currentTask && !tasks.find(item => item.id === currentTask.id)) {
        this.clearTarget();
      }
    });
    this._permissions.isNotGuest$.pipe(take(1)).subscribe(isNotGuest => {
      if (!isNotGuest) {
        return;
      }
      window.addEventListener('keydown', event => {
        const activeEl = document.activeElement;
        if (activeEl === document.body && !this.isIgnoreKeyBoardEvents) {
          switch (event.code) {
            case 'Slash':
            case 'NumpadDivide': {
              event.preventDefault();
              document.getElementById('search-box-input').focus();
              break;
            }
            case 'KeyB': {
              event.preventDefault();
              const searchInputEl = document.getElementById('search_input_left_menu');
              if (searchInputEl) {
                searchInputEl.focus();
              }
              this._store.dispatch(new ComponentToggleAction({ name: LEFT_MENU }));
              break;
            }
            case 'KeyW': {
              event.preventDefault();
              if (document.getElementById('right_menu_root')) {
                this._store.dispatch(new ComponentToggleAction({ name: RIGHT_MENU }));
              }
              break;
            }
            case 'KeyQ': {
              event.preventDefault();
              const filterEl = document.querySelector('[data-default-filter-name="' + FILTER_USER + '"]');
              if (filterEl) {
                filterEl.dispatchEvent(
                  new MouseEvent('click', {
                    view: window,
                    bubbles: true,
                    cancelable: true
                  })
                );
              }
              break;
            }
          }
          if (this._currentTask && this._currentTask.id) {
            switch (event.code) {
              case 'Period': {
                this.moveTaskToRight(event.shiftKey);
                break;
              }
              case 'Comma': {
                this.moveTaskToLeft(event.shiftKey);
                break;
              }
              case 'ArrowUp': {
                event.preventDefault();
                this.selectTaskAbove();
                this._scrollToSelectedElement();
                break;
              }
              case 'ArrowDown': {
                event.preventDefault();
                this.selectTaskBelow();
                this._scrollToSelectedElement();
                break;
              }
              case 'ArrowLeft': {
                event.preventDefault();
                this.selectTaskToLeft();
                this._scrollToSelectedElement();
                break;
              }
              case 'ArrowRight': {
                event.preventDefault();
                this.selectTaskToRight();
                this._scrollToSelectedElement();
                break;
              }
              case 'KeyE': {
                event.preventDefault();
                this._store.dispatch(
                  new GuiStateQuickTaskEditShow({
                    id: this._currentTask.id,
                    rect: document.querySelector('.hotkeys-target .quick_actions').getBoundingClientRect()
                  })
                );
                this.clearTarget();
                break;
              }
              case 'KeyC': {
                event.preventDefault();
                const data = { id: this._currentTask.id, archived: !this._currentTask.archived };
                this.selectTaskBelow();
                this._store.dispatch(new TaskEditAction(data));
                break;
              }
              case 'Enter':
              case 'NumpadEnter': {
                event.preventDefault();
                const el = document.querySelector('.hotkeys-target a.task_link');
                if (el) {
                  el.dispatchEvent(
                    new MouseEvent('click', {
                      view: window,
                      bubbles: true,
                      cancelable: true
                    })
                  );
                }
                break;
              }
              case 'Space': {
                event.preventDefault();
                this._toggleUserMembership();
                break;
              }
              case 'KeyS': {
                event.preventDefault();
                this._toggleUserSubscription();
                break;
              }
              case 'KeyN': {
                event.preventDefault();
                const filterEl = document.querySelector(
                  '[data-col-swim-coord="' + this._currentTask.column + '_' + this._currentTask.swimlane + '"]'
                );
                if (filterEl) {
                  filterEl.dispatchEvent(
                    new MouseEvent('click', {
                      view: window,
                      bubbles: true,
                      cancelable: true
                    })
                  );
                }
                break;
              }
              case 'KeyM': {
                event.preventDefault();
                this._store.dispatch(
                  new GuiStateQuickTaskEditShow({
                    id: this._currentTask.id,
                    rect: document.querySelector('.hotkeys-target .quick_actions').getBoundingClientRect(),
                    activate: 'members'
                  })
                );
                break;
              }
              case 'KeyD': {
                event.preventDefault();
                this._store.dispatch(
                  new GuiStateQuickTaskEditShow({
                    id: this._currentTask.id,
                    rect: document.querySelector('.hotkeys-target .quick_actions').getBoundingClientRect(),
                    activate: 'due_date'
                  })
                );
                break;
              }
            }
          } else if (this._router.isActive(BOARDS, false) && this._router.routerState.root.children.length) {
            switch (event.code) {
              case 'ArrowRight':
              case 'ArrowLeft':
              case 'ArrowDown':
              case 'ArrowUp': {
                if (this._availableForNavColumns.length) {
                  event.preventDefault();
                  const firstTaskIdInFirstCol = this._filteredTasks.find(
                    task => task.column === this._availableForNavColumns[0].id
                  ).id;
                  this.setTargetTask(firstTaskIdInFirstCol);
                  this._scrollToSelectedElement();
                }
              }
            }
          }
        } else if (activeEl && activeEl.classList.contains('task_global_container')) {
          switch (event.code) {
            case 'KeyT': {
              event.preventDefault();
              document.getElementById('opened-task-title').dispatchEvent(
                new MouseEvent('click', {
                  view: window,
                  bubbles: true,
                  cancelable: true
                })
              );
              break;
            }
          }
        }
      });
    });
  }

  public setTargetTaskByMouse(id) {
    if (!this.ignoreMouseEvents) {
      this.setTargetTask(id);
    }
  }

  public clearTargetByMouse() {
    if (!this.ignoreMouseEvents) {
      this.clearTarget();
    }
  }

  public setTargetTask(id) {
    this._store.dispatch(new GuiStateSetHotkeysTargetTask(id));
  }

  public clearTarget() {
    this._store.dispatch(new GuiStateSetHotkeysTargetTask(null));
  }

  public moveTaskToRight(toTop = false) {
    this._startIgnoringMouseEvents();
    const i = this._getCurrentColumnMoveIndex();
    if (i > -1 && i < this._availableForMoveColumns.length - 1) {
      const payload = {
        id: this._currentTask.id,
        column: this._availableForMoveColumns[i + 1].id,
        swimlane: this._currentTask.swimlane
      };
      if (toTop) {
        payload['insertBeforeTask'] = 0;
      }
      this._store.dispatch(new TaskEditAction(payload));
    }
  }

  public moveTaskToLeft(toTop = false) {
    this._startIgnoringMouseEvents();
    const i = this._getCurrentColumnMoveIndex();
    if (i > 0 && this._availableForMoveColumns.length !== 1) {
      const payload = {
        id: this._currentTask.id,
        column: this._availableForMoveColumns[i - 1].id,
        swimlane: this._currentTask.swimlane
      };
      if (toTop) {
        payload['insertBeforeTask'] = 0;
      }
      this._store.dispatch(new TaskEditAction(payload));
    }
  }

  public selectTaskAbove() {
    this._startIgnoringMouseEvents();
    const columnTasks = this._filteredTasks.filter(task => task.column === this._currentTask.column);
    const i = columnTasks.findIndex(item => item.id === this._currentTask.id);
    if (i > 0 && columnTasks.length !== 1) {
      this.setTargetTask(columnTasks[i - 1].id);
    }
  }

  public selectTaskBelow() {
    this._startIgnoringMouseEvents();
    const columnTasks = this._filteredTasks.filter(task => task.column === this._currentTask.column);
    const i = columnTasks.findIndex(item => item.id === this._currentTask.id);
    if (i > -1 && i < columnTasks.length - 1) {
      this.setTargetTask(columnTasks[i + 1].id);
    }
  }

  public selectTaskToLeft() {
    this._startIgnoringMouseEvents();
    const taskIndex = this._getIndexInColumnForSwimlane();
    const currentColumnIndex = this._getCurrentColumnNavIndex();
    if (currentColumnIndex > 0 && this._availableForNavColumns.length !== 1) {
      const leftSiblings = this._filteredTasks.filter(
        task =>
          task.column === this._availableForNavColumns[currentColumnIndex - 1].id &&
          task.swimlane === this._currentTask.swimlane
      );
      const leftTaskIndex = taskIndex > leftSiblings.length - 1 ? leftSiblings.length - 1 : taskIndex;
      this.setTargetTask(leftSiblings[leftTaskIndex].id);
    }
  }

  public selectTaskToRight() {
    this._startIgnoringMouseEvents();
    const taskIndex = this._getIndexInColumnForSwimlane();
    const currentColumnIndex = this._getCurrentColumnNavIndex();
    if (currentColumnIndex > -1 && currentColumnIndex < this._availableForNavColumns.length - 1) {
      const rightSiblings = this._filteredTasks.filter(
        task =>
          task.column === this._availableForNavColumns[currentColumnIndex + 1].id &&
          task.swimlane === this._currentTask.swimlane
      );
      const rightTaskIndex = taskIndex > rightSiblings.length - 1 ? rightSiblings.length - 1 : taskIndex;
      this.setTargetTask(rightSiblings[rightTaskIndex].id);
    }
  }

  private _getCurrentColumnNavIndex() {
    return this._availableForNavColumns.findIndex(column => this._currentTask.column === column.id);
  }

  private _getCurrentColumnMoveIndex() {
    return this._availableForMoveColumns.findIndex(column => this._currentTask.column === column.id);
  }

  private _getIndexInColumnForSwimlane() {
    return this._filteredTasks
      .filter(task => task.column === this._currentTask.column && task.swimlane === this._currentTask.swimlane)
      .findIndex(item => item.id === this._currentTask.id);
  }

  private _scrollToSelectedElement() {
    this._startIgnoringMouseEvents();
    setTimeout(() => {
      let taskEl = document.getElementsByClassName('hotkeys-target')[0];
      let wrapEl = document.getElementById('columns_wrapper');
      if (taskEl && wrapEl) {
        const taskRect = taskEl.getBoundingClientRect();
        const wrapRect = wrapEl.getBoundingClientRect();
        if (taskRect.top < wrapRect.top) {
          wrapEl.scrollTop = wrapEl.scrollTop - (wrapRect.top - taskRect.top) - 30;
        }
        if (taskRect.bottom > wrapRect.bottom) {
          wrapEl.scrollTop = wrapEl.scrollTop - (wrapRect.bottom - taskRect.bottom) + 20;
        }
        if (taskRect.right > wrapRect.right) {
          wrapEl.scrollLeft = wrapEl.scrollLeft - (wrapRect.right - taskRect.right) + 20;
        }
        if (taskRect.left < wrapRect.left) {
          wrapEl.scrollLeft = wrapEl.scrollLeft - (wrapRect.left - taskRect.left);
        }
      }
    });
  }

  private _startIgnoringMouseEvents() {
    if (!this.ignoreMouseEvents) {
      this.ignoreMouseEvents = true;
      this.listener = this.mouseListener.bind(this);
      window.addEventListener('mousemove', this.listener);
    }
  }

  private _toggleUserMembership() {
    const userId = this._authService.activeUserId;
    const isDeleting = this._currentTask.usersIds && this._currentTask.usersIds.includes(userId);
    const userListUpdate = { [isDeleting ? 'remove' : 'add']: [userId] };
    this._store.dispatch(new TaskAssignUsersAction({ id: this._currentTask.id, users: userListUpdate }));
  }

  private _toggleUserSubscription() {
    const userId = this._authService.activeUserId;
    const isDeleting = this._currentTask.subscribersIds && this._currentTask.subscribersIds.includes(userId);
    const userListUpdate = { [isDeleting ? 'remove' : 'add']: [userId] };
    this._store.dispatch(new TaskAssignSubscriberAction({ id: this._currentTask.id, subscribers: userListUpdate }));
  }

  public mouseListener() {
    this.ignoreMouseEvents = false;
    this.clearTarget();
    window.removeEventListener('mousemove', this.listener);
  }
}
