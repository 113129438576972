import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { TaskExpandedComponent } from './task-expanded.component';
import { ChecklistFormComponent } from './task-checklist/checklist-form/checklist-form.component';
import { ChecklistDetailComponent } from './task-checklist/checklist-detail/checklist-detail.component';
import { ChecklistItemFormComponent } from './task-checklist/checklist-item-form/checklist-item-form.component';
import { ChecklistItemComponent } from './task-checklist/checklist-item/checklist-item.component';
import { ChecklistProgressComponent } from './task-checklist/checklist-progress/checklist-progress.component';
import { TaskChecklistComponent } from './task-checklist/task-checklist.component';
import { TaskEstimateComponent } from './task-form/task-estimate/task-estimate.component';
import { TaskDueDateComponent } from './sidebar/sidebar-duedate/task-duedate/task-duedate.component';
import { SidebarRepeatAddPopupComponent } from './sidebar/sidebar-repeat/sidebar-repeat-add-popup/sidebar-repeat-add-popup.component';
import { SidebarRepeatAddFormComponent } from './sidebar/sidebar-repeat/sidebar-repeat-add-form/sidebar-repeat-add-form.component';
import { TaskLogworkComponent } from './task-form/task-logwork/task-logwork.component';
import { TaskSidebarService } from './shared/services/task-sidebar.service';
import { ScoreFormComponent } from './score-form/score-form.component';
import { TimeTrackingProgressComponent } from './time-traking/time-tracking-progress.component';
import { TaskDescriptionComponent } from './task-description/task-description.component';
import { TaskAttachmentsComponent } from './task-attachments/task-attachments.component';
import { TaskAttachmentItemComponent } from './task-attachments/attachment-item/attachment-item.component';
import { AttachmentListComponent } from './task-attachments/attachment-list/attachment-list.component';
import { FileDragAndDropComponent } from './task-attachments/file-drag-and-drop/file-drag-and-drop.component';
import { ActivityDetailComponent } from './task-activity/activity-detail/activity-detail.component';
import { CommentFormComponent } from './task-activity/comment-form/comment-form.component';
import { TaskActivityComponent } from './task-activity/task-activity.component';
import { LogWorkComponent } from './task-activity/log-work/log-work.component';
import { TaskDetailPopupComponent } from './task-detail-popup.component';
import { TaskUnsavedDataService } from './shared/services/task-unsaved-data.service';
import { EffectsModule } from '@ngrx/effects';
import { OpenedTasksEffects } from './ngrx/effects/opened-task.effect';
import { TaskRoutingModule } from './task-routing.module';
import { AtlazGuiModule } from '../atlaz-gui/atlaz-gui.module';
import { AddButtonBlockComponent } from './sidebar/add-button-block/add-button-block.component';
import { SidebarDuedateComponent } from './sidebar/sidebar-duedate/sidebar-duedate.component';
import { SidebarTimeTrackingComponent } from './sidebar/sidebar-time-tracking/sidebar-time-tracking.component';
import { SidebarEstimatePopupComponent } from './sidebar/sidebar-time-tracking/sidebar-estimate-popup/sidebar-estimate-popup.component';
import { SidebarLogworkPopupComponent } from './sidebar/sidebar-time-tracking/sidebar-logwork-popup/sidebar-logwork-popup.component';
import { SidebarVersionComponent } from './sidebar/sidebar-version/sidebar-version.component';
import { SidebarLabelsComponent } from './sidebar/sidebar-labels/sidebar-labels.component';
import { TaskKeyComponent } from './content/task-key/task-key.component';
import { SidebarBacklogComponent } from './sidebar/sidebar-backlog/sidebar-backlog.component';
import { AddAttachmentsComponent } from './task-attachments/add-attachments/add-attachments.component';
import { ADragModule } from '../shared/a-drag/a-drag.module';
import { TaskDetailPageComponent } from './task-detail-page/task-detail-page.component';
import { TaskPermissionPagesComponent } from './task-detail-page/task-permission-pages/task-permission-pages.component';
import { TaskTitleInplaceEditComponent } from './components/task-title-inplace-edit/task-title-inplace-edit.component';
import { ChecklistItemMenuComponent } from './task-checklist/checklist-item/checklist-item-menu/checklist-item-menu.component';
import { GitPublicModule } from '../integrations/git/public/public.module';
import { MarkdownModule } from 'ngx-markdown-hyggerio';
import { MarkdownToolbarComponent } from './markdown-toolbar/markdown-toolbar.component';
import { MarkdownGuideComponent } from './markdown-guide/markdown-guide.component';
import { MarkdownSmartTaskLinkComponent } from './markdown-toolbar/markdown-smart-task-link.component';
import { SidebarStoryPointsComponent } from './sidebar/sidebar-story-points/sidebar-story-points.component';
import { SidebarStartDateComponent } from './sidebar/sidebar-start-date/sidebar-start-date.component';
import { TaskStartDateComponent } from './sidebar/sidebar-start-date/task-start-date/task-start-date.component';
import { TaskLinksAddPopupComponent } from './task-links/task-links-add-popup/task-links-add-popup.component';
import { TaskLinksAddFormComponent } from './task-links/task-links-add-form/task-links-add-form.component';
import { RelativeTaskLinksComponent } from './task-links/relative-task-links/relative-task-links.component';
import { TaskLinkItemComponent } from './task-links/task-link-item/task-link-item.component';
import { TaskLinkDeleteFormComponent } from './task-links/task-link-delete-form/task-link-delete-form.component';
import { ChecklistTaskItemFormComponent } from './task-checklist/checklist-task-item-form/checklist-task-item-form.component';
import { SidebarHarvestComponent } from './sidebar/sidebar-harvest/sidebar-harvest.component';
import { HarvestWidgetComponent } from './sidebar/sidebar-harvest/harvest-widget/harvest-widget.component';
import { SidebarEditCriterionValueComponent } from './sidebar/sidebar-backlog/sidebar-edit-criterion-value/sidebar-edit-criterion-value.component';
import { SidebarEditConfidenceComponent } from './sidebar/sidebar-backlog/sidebar-edit-confidence/sidebar-edit-confidence.component';
import { SidebarEditFactorComponent } from './sidebar/sidebar-backlog/sidebar-edit-factor/sidebar-edit-factor.component';
import { DependenciesAddFormComponent } from './task-links/dependencies-add-form/dependencies-add-form.component';
import { SidebarCompletenessComponent } from './sidebar/sidebar-completeness/sidebar-completeness.component';
import { SidebarProjectStatusComponent } from './sidebar/sidebar-project-status/sidebar-project-status.component';
import { SidebarCustomFieldsComponent } from './sidebar/sidebar-custom-fields/sidebar-custom-fields.component';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([OpenedTasksEffects]),
    SharedModule,
    TaskRoutingModule,
    AtlazGuiModule,
    ADragModule,
    MarkdownModule,
    GitPublicModule
  ],
  declarations: [
    MarkdownSmartTaskLinkComponent,
    TaskExpandedComponent,
    TaskDetailPageComponent,
    TaskPermissionPagesComponent,
    ChecklistFormComponent,
    ChecklistDetailComponent,
    ChecklistProgressComponent,
    ChecklistItemComponent,
    ChecklistItemFormComponent,
    TaskChecklistComponent,
    TaskEstimateComponent,
    TaskDueDateComponent,
    SidebarRepeatAddPopupComponent,
    SidebarRepeatAddFormComponent,
    TaskLogworkComponent,
    ScoreFormComponent,
    TimeTrackingProgressComponent,
    TaskDescriptionComponent,
    TaskAttachmentsComponent,
    TaskAttachmentItemComponent,
    AttachmentListComponent,
    FileDragAndDropComponent,
    ActivityDetailComponent,
    CommentFormComponent,
    TaskActivityComponent,
    LogWorkComponent,
    TaskDetailPopupComponent,

    SidebarDuedateComponent,
    SidebarTimeTrackingComponent,

    AddButtonBlockComponent,
    SidebarEstimatePopupComponent,
    SidebarLogworkPopupComponent,
    SidebarVersionComponent,
    SidebarLabelsComponent,
    SidebarBacklogComponent,
    AddAttachmentsComponent,
    TaskKeyComponent,
    TaskTitleInplaceEditComponent,
    MarkdownToolbarComponent,
    MarkdownGuideComponent,
    ChecklistItemMenuComponent,
    SidebarStoryPointsComponent,
    SidebarStartDateComponent,
    TaskStartDateComponent,
    TaskLinksAddPopupComponent,
    TaskLinksAddFormComponent,
    RelativeTaskLinksComponent,
    TaskLinkItemComponent,
    TaskLinkDeleteFormComponent,
    ChecklistTaskItemFormComponent,
    HarvestWidgetComponent,
    SidebarHarvestComponent,
    SidebarEditCriterionValueComponent,
    SidebarEditConfidenceComponent,
    SidebarEditFactorComponent,
    DependenciesAddFormComponent,
    SidebarCompletenessComponent,
    SidebarProjectStatusComponent,
    SidebarCustomFieldsComponent
  ],
  providers: [TaskSidebarService, TaskUnsavedDataService],
  exports: [TaskExpandedComponent, TaskDetailPageComponent]
})
export class TaskModule {}
