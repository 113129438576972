import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { compose } from '@ngrx/store';
import { share } from '../../functions/util';
import { getEntitySelector } from '../../functions/selectors';
import { DEFAULT_QUADS_FILTER } from '../../state';
import { Actions } from '../../actions/task-filters/default-quads-filter.actions';
import { DefaultTasksQuadsFilterActionTypes } from '../../actions/task-filters/default-quads-filter.actions';
import { TaskDefaultFilterActionTypes } from '../../actions/task-filters/task-default-filter.actions';

export interface DefaultQuadsFilter {
  quads: number[];
}

const initState: DefaultQuadsFilter = {
  quads: []
};

export function reducer(state = initState, action: Actions): DefaultQuadsFilter {
  switch (action.type) {
    case DefaultTasksQuadsFilterActionTypes.TOGGLE_QUAD: {
      const quads = [...state.quads];
      const quad = action['payload'];
      const index = quads.findIndex(item => item === quad);
      if (index > -1) {
        quads.splice(index, 1);
      } else {
        quads.push(quad);
      }
      state = Object.assign({}, state, { quads: quads });
      return state;
    }

    case DefaultTasksQuadsFilterActionTypes.RESET: {
      state = initState;
      return initState;
    }

    case TaskDefaultFilterActionTypes.SET: {
      if (action['payload'].activeFilters.defaultQuadsFilter) {
        state = { quads: [...action['payload'].activeFilters.defaultQuadsFilter] };
      } else {
        state = initState;
      }
      return state;
    }

    default: {
      return state;
    }
  }
}

const isActive = (state$: Observable<DefaultQuadsFilter>) => state$.pipe(map(state => state.quads.length > 0));
const getQuadsValues = (state$: Observable<DefaultQuadsFilter>) => state$.pipe(map(state => state.quads));

export const getDefaultQuadsFilterEntity = getEntitySelector(DEFAULT_QUADS_FILTER);
export const isActiveDefaultQuadsFilter = compose(isActive, getDefaultQuadsFilterEntity);
export const getDefaultQuadsFilterValues = share(compose(getQuadsValues, getDefaultQuadsFilterEntity));
