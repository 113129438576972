import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observer } from 'rxjs';
import { CustomValidators } from '../../../../shared/validators/custom-validators/';

import { USER_PL } from '../../../../constants/';

import {
  FormComponent,
  FormSaveType,
  FormServiceParams,
  FormV2Service
} from '../../../../shared/services/form-v2.service';
import { HandleResponseAction } from '../../../../ngrx/actions/root.action';
import { AppState, LEFT_MENU } from '../../../../ngrx/state/';
import { RouterNavigateService } from '../../../../shared/services/router-navigate.service';
import { ComponentListenClickoutAction } from '../../../../ngrx/actions/component.actions';
import {
  ROLE_COMPANY_ADMIN,
  ROLE_COMPANY_EMPLOYEE,
  ROLE_COMPANY_GUEST,
  ROLE_COMPANY_OWNER,
  ROLE_COMPANY_TITLES
} from '../../../../permissions/interfaces/roles';

@Component({
  selector: 'invite-member-popup',
  templateUrl: './invite-member-popup.component.html',
  styleUrls: ['./invite-member-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormV2Service]
})
export class InviteMemberPopupComponent implements OnInit, FormComponent {
  @Output() close = new EventEmitter();
  public showSent = false;

  public roles: { role: string; name: string }[] = [
    { role: ROLE_COMPANY_EMPLOYEE, name: ROLE_COMPANY_TITLES[ROLE_COMPANY_EMPLOYEE] },
    { role: ROLE_COMPANY_ADMIN, name: ROLE_COMPANY_TITLES[ROLE_COMPANY_ADMIN] },
    { role: ROLE_COMPANY_OWNER, name: ROLE_COMPANY_TITLES[ROLE_COMPANY_OWNER] },
    { role: ROLE_COMPANY_GUEST, name: ROLE_COMPANY_TITLES[ROLE_COMPANY_GUEST] }
  ];

  private _availableRoles = this.roles.map(item => item.role);

  public form: FormGroup;

  formObserver: Observer<any> = {
    next: resp => {
      this.showSent = true;
      this._store.dispatch(new HandleResponseAction(resp));
    },
    error: error => {},
    complete: () => {}
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.add,
    entityToEdit: USER_PL,
    formObserver: this.formObserver
  };

  constructor(
    private _fb: FormBuilder,
    private _store: Store<AppState>,
    public _formService: FormV2Service,
    private _routerNav: RouterNavigateService
  ) {}

  ngOnInit() {
    this.form = this._fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      role: [ROLE_COMPANY_EMPLOYEE, CustomValidators.includes(this._availableRoles)]
    });

    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  onClose() {
    this._store.dispatch(new ComponentListenClickoutAction({ name: LEFT_MENU, listenClickout: true }));
    this._routerNav.deactivatePopupOutlet();
  }

  onSubmit() {
    this._formService.markAsDirty();
    if (this.form.valid) {
      this._formService.submit();
    }
    return false;
  }
}
