
import {distinctUntilChanged, pluck} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { DataSyncActionTypes } from '../actions/data-sync.action';
import { AppState, DATA_SYNC } from '../state';
import { Observable } from 'rxjs';
import { Model } from '../../shared/services/app/models';
import { Action } from '../actions/unsafe-action';

export interface WebSocketEvent {
  channel: string;
  serverTime: number;
}

export interface WebSocketAnswer extends WebSocketEvent {
  data: Model<any>[];
}

export interface WebSocketDeleteAnswer extends WebSocketEvent {
  object: string;
  ids: number[];
  action: string;
  clientUUID: string;
}

export interface DataSyncConnectionStatus {
  isSocketConnected: boolean;
  isOnline: boolean;
}

export interface ObjectState {
  models: Model<any>[];
  serverTime: number;
}

export interface ObjectDelete {
  serverTime: number;
  deleteData: WebSocketDeleteAnswer[];
}

export interface State extends DataSyncConnectionStatus {
  /**
   * @param time utc in milliseconds
   */
  time: number;
  lastReset: number;
}

export const initialState: State = {
  time: 0,
  isSocketConnected: false,
  isOnline: false,
  lastReset: 0
};

export function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case DataSyncActionTypes.SET_LAST_UPDATED_TIME: {
      /**
       * we always should return new state
       * @see DataSyncService#emulateSocket
       */
      const updateTime = action.payload.time > 0 ? action.payload : { time: state.time };
      return { ...state, ...updateTime };
    }
    case DataSyncActionTypes.UPDATE_CONNECTION_STATUS: {
      return { ...state, ...action.payload };
    }

    default: {
      return state;
    }
  }
}

export const dataSyncState: (state$: Store<AppState>) => Observable<State> = state$ =>
  state$.pipe(pluck<AppState, State>(DATA_SYNC),distinctUntilChanged(),);
