import { createCommonSelectors } from '../../util/store';
import { TASKS_CUSTOM_FIELD_PL } from '../../constants';
import { Entity } from '../../interfaces';
import { ESInterface } from '../state';
import { createSelector } from 'reselect';

const initialState: ESInterface<TasksCustomField> = {
  ids: [],
  entities: {},
  selectedEntityId: null
};

interface State {
  ids: number[];
  entities: { [id: number]: TasksCustomField };
}

export interface TasksCustomField extends Entity {
  task: number;
  customField: number;
  value: string;
}

export namespace fromTasksCustomFields {
  export const { getState, getEntities, getIds, getAll, isEmpty } = createCommonSelectors<TasksCustomField, State>(
    TASKS_CUSTOM_FIELD_PL
  );

  export const getByTaskId = id =>
    createSelector(
      getAll,
      taskCustomFields => (taskCustomFields ? taskCustomFields.filter(item => item.task === id) : [])
    );
}
