import { createSelector } from 'reselect';

import { prop, getProperty } from '../../../../helpers';
import { AppState } from '../../../ngrx/state';
import { IntegrationNames } from '../integraition-list';
import * as integrations from './integration.action';

export const INTEGRATIONS_FEATURE = 'integrationsFeature';

export interface IntegrationsState {
  installed: {
    [key: string]: boolean;
  };
}

const initialState: IntegrationsState = {
  installed: {}
};

export function reducer(state = initialState, action: integrations.Actions): IntegrationsState {
  switch (action.type) {
    case integrations.INTEGRATION_STATUS_UPDATED: {
      const installed = { ...state.installed, [action.payload.name]: action.payload.isInstalled };
      return { ...state, installed };
    }

    default: {
      return state;
    }
  }
}

export const reducers = {
  [INTEGRATIONS_FEATURE]: reducer
};

export const getState = (store: AppState) => <IntegrationsState>store[INTEGRATIONS_FEATURE];
export const getInstallStatus = createSelector(getState, prop('installed'));

export const isInstalledIntegration = createSelector(getInstallStatus, state => name => getProperty(state, name));
export const getIsInstalledGithub = createSelector(getInstallStatus, state => state[IntegrationNames.Github]);
export const getIsInstalledBitbucket = createSelector(getInstallStatus, state => state[IntegrationNames.Bitbucket]);
export const getIsInstalledGitlab = createSelector(getInstallStatus, state => state[IntegrationNames.Gitlab]);
export const getIsInstalledIntercom = createSelector(getInstallStatus, state => state[IntegrationNames.Intercom]);
