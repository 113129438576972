
    <input #nameFilterRef name="name filter" [focus]="true" placeholder="Search for Members" (keyup)="0">
    <div class="list_inner">
      <div *ngFor="let user of usersState$ | async | usersNameFilter: nameFilterRef.value"
           class="user_title"
           (click)="onSelectUser(user.id)"
      >
        <div class="avatar_container">
          <a-avatar
            [user]="user"
          ></a-avatar>
        </div>
        <div class="member_info">
          <div class="member_info_name"> {{user.firstname}} {{user.lastname}}</div>
          <div class="member_info_text">@{{user.nickname}}</div>
        </div>
        <span class="check_label" *ngIf="isSelectedUser(user.id)"></span>
      </div>
    </div>
  