import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Task } from '../../../../interfaces/index';
import { KeyCode } from '../../../../constants/index';
import * as moment from 'moment';
import MaterialDateTimePicker from 'material-datetime-picker';
import { AppState } from '../../../../ngrx/state/index';
import { DateTimeFormatterService } from '../../../../libs/date-time-formatter/services/date-time-formatter.service';
import { Store } from '@ngrx/store';
import { TaskEditAction } from '../../../../ngrx/actions/task.actions';
import { CompanyService } from '../../../services/app/company.service';

@Component({
  selector: 'sidebar-duedate-popup',
  templateUrl: './sidebar-duedate-popup.component.html',
  styleUrls: ['./sidebar-duedate-popup.component.scss']
})
export class SidebarDuedatePopupComponent implements OnInit, OnDestroy {
  @Input() task: Task;
  @Input() isOuterSubmit: boolean;
  @Input() isBatchActions: boolean;
  @Input() batchTasks: Task[];
  @Output() onClosePopup = new EventEmitter();
  @Output() onSubmitValue = new EventEmitter();

  get payloadId() {
    return this.isBatchActions ? this.batchTasks.map(item => item.id).join(',') : this.task.id;
  }

  private picker;

  constructor(
    private _store: Store<AppState>,
    private _dateTimeFormatter: DateTimeFormatterService,
    private _company: CompanyService
  ) {}

  ngOnInit() {
    const picker = new MaterialDateTimePicker({
      default: this.task.dueDate
        ? moment.unix(this.task.dueDate)
        : moment()
            .add(1, 'days')
            .set({ hour: 12, minute: 0, second: 0, millisecond: 0 }),
      twentyFourHours: this._dateTimeFormatter.is24HoursFormat,
      format: this._dateTimeFormatter.DATE_FORMAT,
      weekStart: this._company.weekStartDay
    })
      .on('submit', val => {
        if (this.isOuterSubmit) {
          this.onSubmitValue.emit(val.unix());
        } else {
          this._store.dispatch(
            new TaskEditAction({
              id: this.payloadId,
              dueDate: val.unix()
            })
          );
        }
      })

      .on('open', () => {
        document.body.addEventListener('mousedown', this.clickListener);
        document.body.addEventListener('keydown', this.keyListener, true);
        if (this.task.dueDate) {
          setTimeout(() => {
            const deleteBtn = document.createElement('a');
            deleteBtn.innerHTML = 'Clear';
            deleteBtn.className = 'c-btn c-btn--flat';
            deleteBtn.style.marginRight = '80px';
            deleteBtn.style.color = 'red';
            const parentEl = document.getElementsByClassName('modal-btns')[0];
            parentEl.insertBefore(deleteBtn, parentEl.firstChild);
            deleteBtn.addEventListener('click', this.clearBtnListener);
          });
        }
      })

      .on('close', () => {
        document.body.removeEventListener('mousedown', this.clickListener);
        document.body.removeEventListener('mousedown', this.clearBtnListener);
        document.body.removeEventListener('keydown', this.keyListener, true);
        this.picker = undefined;
        this.onClosePopup.emit();
      });
    picker.open();
    this.picker = picker;
  }

  private clickListener = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
  };

  private clearBtnListener = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    if (this.isOuterSubmit) {
      this.onSubmitValue.emit(0);
    } else {
      this._store.dispatch(
        new TaskEditAction({
          id: this.payloadId,
          dueDate: 0
        })
      );
      this.picker.close();
    }
  };

  private keyListener = (event: KeyboardEvent) => {
    if (event.keyCode === KeyCode.KEY_ESCAPE) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      if (this.picker) {
        this.picker.close();
      }
    }
  };

  ngOnDestroy() {
    if (this.picker) {
      this.picker.close();
    }
  }
}
