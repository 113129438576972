
    <smart-link *ngIf="!(loaded$ | async)" [value]="value"></smart-link>
    <smart-task-link-inner
      *ngIf="(loaded$ | async)"
      [link]="value"
      [taskKey]="taskKey$ | async"
      [task]="task$ | async"
      [board]="board$ | async"
      [column]="column$ | async"
      [swimlane]="swimlane$ | async"
      [parentColumn]="parentColumn$ | async"
      [directLink]="directLink"
    >
    </smart-task-link-inner>
  