import { interval as observableInterval, BehaviorSubject, Observable, Subject } from 'rxjs';

import {
  combineLatest,
  takeUntil,
  distinctUntilChanged,
  map,
  refCount,
  publishReplay,
  take,
  filter
} from 'rxjs/operators';
import { Injectable, NgZone } from '@angular/core';
import { Entity } from '../../interfaces';
import { isEqualId, isPresent } from '../../../helpers';

import { Pred } from 'ramda';

export interface DragItem {
  hash: string;
  position: {
    before?: number | string;
    after?: number | string;
  };
  initial: boolean;
  metaData: {};
  id: number | string;
  item: {};
  type: string;
}

const removeDragging = (list: any[], target) => {
  if (list.find(isEqualId(target.id))) {
    return list.filter(xs => xs.id !== target.id);
  }
  return list;
};

const injectDragging = (list: any[], target) => {
  let inserted = false;

  const newList = list.reduce((acc, item) => {
    if (target.position.before === item.id) {
      acc.push(target.item);
      inserted = true;
    }
    acc.push(item);
    if (target.position.after === item.id) {
      acc.push(target.item);
      inserted = true;
    }
    return acc;
  }, []);
  if (!inserted) {
    return [...newList, target.item];
  }
  return newList;
};

const calcAxisPosition = (posProp, sizeProp, eventProp) => (event, rect) =>
  rect[posProp] + rect[sizeProp] / 2 - event[eventProp] > 0 ? 'before' : 'after';

const axisHandlerY = calcAxisPosition('top', 'height', 'clientY');
const axisHandlerX = calcAxisPosition('left', 'width', 'clientX');
const axisHandler = axis => (axis === 'x' ? axisHandlerX : axisHandlerY);

@Injectable()
export class ADragService {
  public finallyMoved$ = new Subject();

  public dragging$: BehaviorSubject<DragItem> = new BehaviorSubject(null);
  public stop$ = this.dragging$.pipe(filter(x => !x));

  private lastRenderedHash;
  private actualDragging;

  private dropContainersMap: { [index: string]: Map<any, any> } = {};

  constructor(private _zone: NgZone) {
    this.initCursor();
  }

  initCursor() {
    if (document.body && document.body.classList) {
      this.dragging$.pipe(map(isPresent), distinctUntilChanged()).subscribe(dragging => {
        dragging
          ? document.body.classList.add('forced_move_cursor')
          : document.body.classList.remove('forced_move_cursor');
      });
    }
  }

  getDropContainersMap(itemType) {
    if (!this.dropContainersMap[itemType]) {
      this.dropContainersMap[itemType] = new Map();
    }
    return this.dropContainersMap[itemType];
  }

  filterItems<T extends Entity>(
    items$: Observable<T[]>,
    itemTypes,
    matchEntitiesList: Pred = _ => true
  ): Observable<T[]> {
    const filterStream = (items: Entity[], dragging: DragItem) => {
      if (!dragging || dragging.type !== itemTypes) {
        return items;
      }
      if (dragging.initial) {
        return matchEntitiesList(dragging.metaData) ? [...items] : items;
      }
      items = removeDragging(items, dragging);
      if (matchEntitiesList(dragging.metaData)) {
        return injectDragging(items, dragging);
      }
      return items;
    };

    return items$.pipe(
      combineLatest(this.dragging$, filterStream),
      distinctUntilChanged(),
      publishReplay(1),
      refCount()
    );
  }

  getDraggingItemId(type: string): Observable<number> {
    return this.dragging$.pipe(map((x: DragItem) => (x && x.type === type ? +x.id : 0)), distinctUntilChanged());
  }

  start(item: Entity, type, metaData) {
    this.actualDragging = {
      hash: 'initial' + item.id + type,
      position: {},
      initial: true,
      metaData: metaData,
      id: item.id,
      item: item,
      type
    };

    this.dragging$.next(this.actualDragging);

    this.initDragOverQueue();
  }

  forcedRerenderNextFrame() {
    this.lastRenderedHash = 'forcedRerenderNextFrame';
  }

  initDragOverQueue() {
    this.forcedRerenderNextFrame();
    observableInterval(200)
      .pipe(takeUntil(this.stop$))
      .subscribe(_ => {
        if (this.lastRenderedHash !== this.actualDragging.hash) {
          this.dragging$.next(this.actualDragging);
          this.lastRenderedHash = this.actualDragging.hash;
        }
      });
  }

  stop() {
    let lastValue;
    this.dragging$
      .pipe(take(1), filter(isPresent), filter(value => !value.initial))
      .subscribe(value => (lastValue = value));
    this.dragging$.next(null);
    if (lastValue) {
      this.finallyMoved$.next(lastValue);
    }
  }

  dragOver(event, item, type, metaData, axis: 'x' | 'y' = 'x', rect?) {
    if (!item || !item.id) {
      return this.dragOverContainer(type, metaData);
    }
    this.customDragOverItem(item, type, metaData, axisHandler(axis)(event, rect));
  }

  customDragOverItem(item, type, metaData, position: 'before' | 'after') {
    if (this.actualDragging && this.actualDragging.type === type && item.id !== this.actualDragging.id) {
      this.actualDragging = {
        hash: position + item.id + type,
        position: { [position]: item.id },
        metaData: metaData,
        initial: false,
        id: this.actualDragging.id,
        item: this.actualDragging.item,
        type
      };
    }
  }

  dragOverContainer(type, metaData) {
    if (this.actualDragging && this.actualDragging.type === type) {
      const hash = Object.keys(metaData)
        .map(key => key + metaData[key])
        .join('');
      this.actualDragging = Object.assign({}, this.actualDragging, { metaData, position: {}, initial: false, hash });
    }
  }
}
