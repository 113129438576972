import {
  BehaviorSubject,
  combineLatest as observableCombineLatest,
  merge as observableMerge,
  Observable,
  Subscription
} from 'rxjs';

import {
  distinctUntilChanged,
  filter,
  map,
  merge,
  mergeMap,
  pluck,
  publishReplay,
  refCount,
  switchMap,
  switchMapTo,
  take,
  takeUntil
} from 'rxjs/operators';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren
} from '@angular/core';

import { ScrollService } from '../../shared/dragula/scroll.service';

import { Board, Column, Swimlane, Task } from '../../interfaces';
import {
  both,
  compareArrays,
  compareObjectByKeys,
  comparePlainObjects,
  invertBoolean,
  isPresent,
  sortByField
} from '../../../helpers';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/state';
import { GuiStateChangeSwimlaneVisibility } from '../../ngrx/actions/gui-state-memorized.actions';

import { getTasksByUsersFilter, inBoard, isActiveTask } from '../../ngrx/reducers/task.reducer';
import { getSwimlanesByBoard } from '../../ngrx/reducers/swimlane.reducer';
import { getColumnsByBoard } from '../../ngrx/reducers/column.reducer';
import { getHiddenSwimlanesState } from '../../ngrx/reducers/gui-state-memorized.reducer';
import { isCompositeColumn, isDoneColumn } from '../../../helpers/column';
import { countTasksAndEstimatesInSwimlanes, taskByColumnIdsFilter } from '../../../helpers/task';
import { COLUMN_PL, columnKinds, SWIMLANE_PL, TASK_PL } from '../../constants';
import { ADragService } from '../../shared/a-drag/a-drag.service';
import { PermissionsService } from '../../permissions/permissions.service';
import { SegmentService } from '../../atlaz-bnp/services/intergations/segment/segment.service';
import { platform } from '../../../helpers/platform';
import { SwimlaneCounter } from '../../interfaces/swimlane';
import { fromBoards } from '../../ngrx/reducers/board.reducer';
import { AuthService } from '../../shared/services/app/auth.service';
import { fromUsers } from '../../ngrx/reducers/user.reducer';
import { fromLabels } from '../../ngrx/reducers/label.reducer';
import { getScoreMapByTasksFromSameBoard } from '../../ngrx/functions/crossed.selector';
import { fromNotifications } from '../../ngrx/reducers/notification.reducer';
import { BoardInProjectAccess } from '../../interfaces/board';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';

@Component({
  selector: 'column-list',
  templateUrl: './column-list.component.html',
  viewProviders: [ScrollService],
  styleUrls: ['./column-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnListComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() boardId: number;
  @Input() boardType: string;
  @Input() createColumnPopupShown = false;

  @ViewChild('columns_wrapper') columnsWrapper: ElementRef;
  @ViewChild('columns_inner') columnsInner: ElementRef;
  @ViewChild('add_column_trigger') addColumnTrigger: ElementRef;
  @ViewChildren('top_axis_scroll,right_axis_scroll,bottom_axis_scroll,left_axis_scroll')
  axisScroll: QueryList<ElementRef>;

  public allowMouseScroll$: Observable<boolean> = new BehaviorSubject(true);
  public isColumnDragging = false;

  public swimlanes$: Observable<Swimlane[]>;
  public swimlanesLength$: Observable<number>;
  public columns$: Observable<Column[]>;
  public columnsByTypes$: Observable<string[]>;
  public tasks$: Observable<Task[]>;
  public collapsedSwimlane$: Observable<{ [id: number]: boolean }>;
  public expandedSwimlanesCount$: Observable<number>;
  public draggingColumnId$: Observable<number>;
  public subs: Subscription[] = [];
  public insertNewColumnAfter: number;
  public addColumnPopupAnchorEl: ElementRef;

  public board$;
  public activeUserId$;
  public boardLabelsMap$;
  public boardUsersMap$;
  public newNotifyMap$;
  public scoreMap$;
  public isNotGuest$ = this._permissions.isNotGuest$;

  public dragItemType = COLUMN_PL;

  /**
   * { [swimlaneID]: tasks count and estimation sum in this swimlane }
   */
  public tasksSwCount$: Observable<{ [id: number]: SwimlaneCounter }>;

  public columnKinds = columnKinds;

  public trackById = (index, item) => item.id + '-' + index;
  public columnPermissions$: Observable<boolean>;
  public boardId$ = new BehaviorSubject(this.boardId);
  public isIE = false;
  private detached = false;

  constructor(
    private _store: Store<AppState>,
    private _authService: AuthService,
    private _scrollService: ScrollService,
    private _cd: ChangeDetectorRef,
    private _dragService: ADragService,
    private _segment: SegmentService,
    private _permissions: PermissionsService,
    private _renderer: Renderer2
  ) {}

  private detach() {
    this._cd.detach();
    this.detached = true;
  }

  private reattach() {
    this._cd.reattach();
    this.detached = false;
  }

  ngOnInit(): any {
    this.allowMouseScroll$ = this._dragService.dragging$.pipe(map(invertBoolean), distinctUntilChanged());

    // select all board columns !! don't use this instance of stream directly, use this.columns$ instead !!
    const columns$ = this.boardId$.pipe(
      switchMap(boardId => this._store.pipe(getColumnsByBoard(boardId))),
      map(sortByField('position', 'asc')),
      publishReplay(1),
      refCount()
    );

    // inject dragging column into columns stream
    this.columns$ = this._dragService
      .filterItems<Column>(columns$, this.dragItemType)
      .pipe(publishReplay(1), refCount());

    this.columnsByTypes$ = this.columns$.pipe(map(columns => columns.map(column => column.kind)));

    // select all board swimlanes !! don't use this instance of stream directly, use this.swimlanes$ instead !!
    const swimlanes$ = this.boardId$.pipe(
      switchMap(boardId => this._store.pipe(getSwimlanesByBoard(boardId))),
      map(sortByField('position', 'asc')),
      publishReplay(1),
      refCount()
    );

    // inject dragging swimlane into columns stream
    this.swimlanes$ = this._dragService
      .filterItems<Swimlane>(swimlanes$, SWIMLANE_PL)
      .pipe(publishReplay(1), refCount());

    // calculation swimlanes count to hide the header of swimlane if there's the only one
    this.swimlanesLength$ = this.swimlanes$.pipe(map(swimlanes => swimlanes.length));

    // getting all tasks on board with applied users filters
    this.tasks$ = this.boardId$.pipe(
      switchMap(boardId => this._store.pipe(getTasksByUsersFilter(both(inBoard(boardId), isActiveTask)))),
      publishReplay(1),
      refCount()
    );

    // get task's count for swimlanes
    this.tasksSwCount$ = this.getTaskCount();

    // getting dragging column to set styles for it
    this.draggingColumnId$ = this._dragService.getDraggingItemId(COLUMN_PL);

    // calculating collaped swimlane state
    this.collapsedSwimlane$ = observableCombineLatest(
      this.swimlanes$,
      this._store.pipe(getHiddenSwimlanesState),
      this.tasksSwCount$
    ).pipe(
      map(
        ([swimlanes, memoizedHiddenSwimlanesState, tasksSwCount]: [Array<Swimlane>, any, any]) =>
          swimlanes.length > 1
            ? swimlanes.reduce((acc, swimlane) => {
                acc[swimlane.id] = memoizedHiddenSwimlanesState.hasOwnProperty(swimlane.id)
                  ? memoizedHiddenSwimlanesState[swimlane.id]
                  : !tasksSwCount[swimlane.id];
                return acc;
              }, {})
            : swimlanes.reduce((acc, swimlane) => {
                acc[swimlane.id] = false;
                return acc;
              }, {})
      )
    );

    this.expandedSwimlanesCount$ = this.collapsedSwimlane$.pipe(
      map(collapsedState => Object.values(collapsedState).filter(value => (value ? !value : true)).length)
    );

    // this line improve performance while dragging something by reducing re-rendering operations
    this.subs.push(this._dragService.dragging$.subscribe(dragging => (dragging ? this.detach() : this.reattach())));

    // this code improve performance while dragging something by re-rendering just in case of changing position of dragging item
    const sub = observableMerge(
      this._dragService.getDraggingItemId(this.dragItemType),
      this._dragService.getDraggingItemId(SWIMLANE_PL)
    )
      .pipe(filter(isPresent), switchMapTo(this.columns$.pipe(merge(this.swimlanes$))))
      .subscribe(_ => {
        this._cd.detectChanges();
      });
    this.subs.push(sub);

    this.columnPermissions$ = combineLatest(
      this._permissions.projectMember(undefined),
      this._permissions.isNotGuest$
    ).pipe(map(([v1, v2]) => v1 && v2));
    this.isIE = platform.name === 'IE';

    this.board$ = this.boardId$.pipe(
      switchMap(id => this._store.select(fromBoards.get(id))),
      distinctUntilChanged(
        (a, b) =>
          compareObjectByKeys(
            [
              'id',
              'scoringType',
              'backlogScoreXLabel',
              'backlogScoreYLabel',
              'backlogScoreYType',
              'backlogScoreXType',
              'showProps'
            ]
          )(a, b) &&
          compareArrays(a.usersIds, b.usersIds) &&
          compareArrays(a.labelsIds, b.labelsIds)
      ),
      filter(isPresent),
      publishReplay(1),
      refCount()
    );

    this.activeUserId$ = this._authService.activeUserId$;

    this.boardUsersMap$ = this.board$.pipe(
      switchMap(
        (board: Board) =>
          board.access !== BoardInProjectAccess.public
            ? this._store.select(fromUsers.getMapByIds((board && board.usersIds) || []))
            : this._store
                .select(fromUsers.getState)
                .pipe(map(usersState => (usersState && usersState.entities ? usersState.entities : {})))
      )
    );

    this.boardLabelsMap$ = this.board$.pipe(
      switchMap((board: Board) => this._store.select(fromLabels.getMapByIds((board && board.labelsIds) || [])))
    );

    this.newNotifyMap$ = this.boardId$.pipe(
      filter(isPresent),
      switchMap(id => this._store.select(fromNotifications.getUnseenMapByBoardId(id)))
    );

    this.scoreMap$ = this.tasks$.pipe(
      switchMap(tasks => this._store.select(getScoreMapByTasksFromSameBoard(tasks))),
      distinctUntilChanged(comparePlainObjects)
    );
  }

  ngOnChanges() {
    this.boardId$.next(this.boardId);
  }

  getTaskCount(): Observable<{ [swimlaneId: number]: SwimlaneCounter }> {
    return observableCombineLatest(this.columns$, this.tasks$).pipe(
      map(([columns, tasks]: [Column[], Task[]]) => {
        const inProgressColumnsIds = columns.reduce((inPrColumns, column) => {
          if (!isDoneColumn(column)) {
            inPrColumns = inPrColumns.concat(column.id);
          }
          if (isCompositeColumn(column)) {
            inPrColumns = inPrColumns.concat(column.subColumnsIds);
          }
          return inPrColumns;
        }, []);

        const inProgressTasks = tasks.filter(taskByColumnIdsFilter(inProgressColumnsIds));
        return countTasksAndEstimatesInSwimlanes(inProgressTasks);
      })
    );
  }

  onChangeSwimlaneVisibility(swimlane: Swimlane) {
    this.collapsedSwimlane$.pipe(pluck(String(swimlane.id)), take(1)).subscribe(collapsed => {
      this._segment.track(collapsed ? 'SwimlaneOpened' : 'SwimlaneClosed');
      this._store.dispatch(
        new GuiStateChangeSwimlaneVisibility({
          id: swimlane.id,
          flag: !collapsed
        })
      );
    });
    if (this.detached) {
      this._cd.detectChanges();
    }
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  ngAfterViewInit() {
    this._scrollService.initSettings(
      this.axisScroll.toArray(),
      this.columnsWrapper.nativeElement,
      this.columnsWrapper.nativeElement
    );
    this.subs.push(
      this._dragService.dragging$
        .pipe(
          distinctUntilChanged((a, b) => a && b && a.id === b.id),
          filter(isPresent),
          filter(x => [TASK_PL, SWIMLANE_PL, COLUMN_PL].includes(x.type)),
          mergeMap(draggingItem =>
            observableMerge(
              ...this._scrollService.scrollByDirections({
                horizontal: true,
                vertical: draggingItem.type !== COLUMN_PL
              })
            ).pipe(takeUntil(this._dragService.dragging$.pipe(filter(invertBoolean))))
          ),
          publishReplay(1),
          refCount()
        )
        .subscribe()
    );
  }

  onToggleCreateColumnOrSwimlanePopup(visibility: boolean, bindingEl?: ElementRef, insertAfter?: number) {
    this.createColumnPopupShown = visibility;
    this.insertNewColumnAfter = insertAfter || 0;
    // render modal window in the right corner of column header or add column button
    this.addColumnPopupAnchorEl = bindingEl ? bindingEl : this.addColumnTrigger;
  }

  onToggleAllSwimlanes(isCollapse) {
    this.swimlanes$.pipe(take(1)).subscribe((swimlanes: Swimlane[]) => {
      swimlanes.forEach(swimlane => {
        this._segment.track(isCollapse ? 'SwimlaneOpened' : 'SwimlaneClosed');
        this._store.dispatch(
          new GuiStateChangeSwimlaneVisibility({
            id: swimlane.id,
            flag: isCollapse
          })
        );
      });
    });
  }

  onDoubleClick(event) {
    this._permissions.isNotGuest$.pipe(take(1)).subscribe(isNotGuest => {
      if (event.target.dataset['columnCreateOnDclick'] && isNotGuest) {
        let insertAfterColumn = 0;
        const htmlElIndex = Array.from(document.getElementsByTagName('column-header')).findIndex(
          (htmlEl: HTMLElement) => {
            const rect = htmlEl.getBoundingClientRect();
            return rect.left + rect.width > event.clientX;
          }
        );
        if (htmlElIndex > -1) {
          this.columns$.pipe(take(1)).subscribe(columns => {
            insertAfterColumn = columns[htmlElIndex].id;
          });
        }
        const pseudoEl = {
          nativeElement: {
            getBoundingClientRect() {
              return {
                left: event.clientX,
                top: Math.min(window.innerHeight - 320, event.clientY),
                width: 0
              };
            }
          }
        };
        this.onToggleCreateColumnOrSwimlanePopup(true, pseudoEl, insertAfterColumn);
      }
    });
  }
}
