<a-center-popup [title]="'Invite by Link'" class="big_popup" (close)="onClose()">
  <div class="invite_link_container" *ngIf="loaded$ | async">
    <ng-container *ngIf="(tokenExists$ | async)">
      <invite-link-form></invite-link-form>
      <button  a-button class="btn_primary" (click)="onClose()">Ok</button>
      <button  a-button class="btn_text" (click)="onShowDeactivatePopup()">Deactivate Link</button>
    </ng-container>
    <create-invite-link *ngIf="!(tokenExists$ | async)" (tokenCreated)="onTokenCreate()"></create-invite-link>
    <div class="invite_people_email">
      <a [routerLink]="massInviteLink" [skipLocationChange]="true">Invite people by email...</a>
    </div>
  </div>
</a-center-popup>

<div a-confirmation-popup *ngIf="showDeactivateConfirmation$ | async"
     confirmationTitle="Confirmation"
     actionButtonText="DEACTIVATE"
     (confirm)="onTokenDelete()"
     (close)="onCloseDeactivatePopup()"
>
  Please confirm your intention. No one will be able to use this link to join your company.
</div>
