import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  HostBinding,
  HostListener
} from '@angular/core';

@Component({
  selector: '[a-button]',
  templateUrl: './a-button.component.html',
  styleUrls: ['./a-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AButtonComponent {
  @Input() disabled: boolean;
  @Input() pending: boolean;

  /**
   * avaliable classes for button:
   *                  'btn_primary'
   *                  'btn_warn'
   *                  'btn_text'
   *                  'btn_secondary'
   *                  'btn_small_contour'
   *
   * @type {string}
   */

  @HostBinding('class.loader')
  get isPending() {
    return this.pending;
  }
  @HostBinding('attr.disabled')
  get isDisabled() {
    return this.disabled ? '' : undefined;
  }

  @HostListener('click', ['$event'])
  clickBtnEvent(event: MouseEvent) {
    if (this.disabled || this.pending) {
      if (event.preventDefault) {
        event.preventDefault();
      }
      event.stopPropagation();
      event.stopImmediatePropagation();
      return false;
    }
  }
}
