import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthService } from '../../shared/services/app/auth.service';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../shared/services/form-v2.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/state';
import { BehaviorSubject, Observer, Subscription } from 'rxjs/index';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BoardActionTypes } from '../../ngrx/actions/board.actions';
import { Action } from '../../ngrx/actions/unsafe-action';
import { Actions } from '@ngrx/effects';
import { fromRoadmapBoard } from '../../board/roadmap-board/store/roadmap-board.reducer';
import { map } from 'rxjs/operators';
import { RoadMapScale } from '../../board/roadmap-board/constants/roadmap.constants';

@Component({
  selector: 'pdf-export-popup',
  templateUrl: './pdf-export-popup.component.html',
  styleUrls: ['./pdf-export-popup.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfExportPopupComponent implements OnInit, OnDestroy {
  @Input() boardId: number;
  @Output() close = new EventEmitter();

  public filterValue: string;
  public storageKey: string;

  public isPending$ = new BehaviorSubject(false);
  public paperOriginalHeight = 0;
  public scale = RoadMapScale.Day;
  public landscalablePaper = ['letter', 'legal', 'A4', 'A3', 'A2', 'A1', 'A0'];

  public subs: Subscription[] = [];

  formObserver: Observer<any> = {
    next: resp => {
      this.subs.push(
        this._action$.ofType(BoardActionTypes.ROADMAP_PDF_GENERATED).subscribe((action: Action) => {
          window.open(action.payload.params.downloadUrl, '_blank');
          this.isPending$.next(false);
          this.onClose();
        })
      );
    },
    error: error => {
      this.isPending$.next(false);
      console.log(error, 'error on pdf export request');
    },
    complete: () => {
      console.log('complete pdf export request. Awaiting of queue job');
    }
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.add,
    entityToEdit: 'roadmap-pdf-generator',
    formObserver: this.formObserver,
    prepareFormValue: formValue => {
      let paperFormat = formValue['paperFormat'];
      if (paperFormat === 'original') {
        const chartWidth = document.querySelector('day-items').scrollWidth + 580;
        const chartHeight = this.paperOriginalHeight;
        paperFormat = chartWidth + 'x' + chartHeight;
      }

      const returnObj = {
        boardId: this.boardId,
        storageKey: this.storageKey,
        filterValue: this.filterValue,
        paperFormat: paperFormat,
        scale: this.scale,
        userId: this._authService.activeUserId,
        isLandscape: this.landscalablePaper.includes(formValue['paperFormat']) ? formValue['isLandscape'] : false
      };
      return {
        boardConfig: btoa(JSON.stringify(returnObj))
      };
    }
  };

  form: FormGroup;
  constructor(
    private _authService: AuthService,
    public _formService: FormV2Service,
    private _store: Store<AppState>,
    private _fb: FormBuilder,
    private _action$: Actions
  ) {}

  ngOnInit() {
    this.subs.push(
      this._store
        .select(fromRoadmapBoard.getChartItemsPositionsMap)
        .pipe(map(items => Object.keys(items).length))
        .subscribe(n => {
          this.paperOriginalHeight = n * 56 + 40;
        }),
      this._store.select(fromRoadmapBoard.getScale).subscribe(v => (this.scale = v))
    );
    this.storageKey = this._authService.activeUserId + ':boardFilters:' + this.boardId;
    this.filterValue = localStorage.getItem(this.storageKey);

    this.form = this._fb.group({
      paperFormat: ['original'],
      isLandscape: [true]
    });

    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  onSubmit() {
    this.isPending$.next(true);
    this._formService.submit();
  }

  onClose() {
    this.close.emit();
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
