import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Task } from '../../../interfaces';

@Component({
  selector: 'sidebar-time-tracking',
  templateUrl: './sidebar-time-tracking.component.html',
  styleUrls: ['./sidebar-time-tracking.component.scss']
})
export class SidebarTimeTrackingComponent {
  @Input() task: Task;
  @Input() editPermissions = false;
  @Output() resetFocus = new EventEmitter();

  public isEstimatePopupVisible = false;
  public isLogworkPopupVisible = false;

  onOpenEstimatePopup() {
    if (!this.editPermissions) {
      return false;
    }
    this.isEstimatePopupVisible = true;
  }
  onCloseEstimatePopup() {
    this.isEstimatePopupVisible = false;
  }
  onOpenLogWorkPopup() {
    if (!this.editPermissions) {
      return false;
    }
    this.isLogworkPopupVisible = true;
  }
  onCloseLogworkPopup() {
    this.isLogworkPopupVisible = false;
  }
  onChildChangedFocus() {
    this.resetFocus.emit({});
  }
}
