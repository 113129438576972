import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { of } from 'rxjs';
import { map ,  switchMap } from 'rxjs/operators';

import { toDashedFromCamelCase } from '../../../../../helpers/';
import { GITLAB_REPOSITORY_PL } from '../../../../constants/';
import { GetCompleteAction } from '../../../../ngrx/actions/root.action';
import { AppState } from '../../../../ngrx/state/';
import {
  JsonApiSingeModelResponse,
  jsonApiToEntityState
} from '../../../../shared/services/app/web-socket/http-response';
import { AtlazApiV2Service } from '../../../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { ESGitlabRepository } from '../../models/git-repository';
import * as gitlabRepo from '../gitlab-repository/gitlab-repository.actions';
import { getFirstId } from '../gitlab-company/gitlab-company.reducer';
import { JobStatusService } from '../../../../shared/services/app/job-status.service';

@Injectable()
export class GitlabRepositoryEffects {
  private gitlabRepoKey = toDashedFromCamelCase(GITLAB_REPOSITORY_PL);

  readonly defaultErrorHandler = (action, err) => {
    console.error('effect', action, err);
    return new gitlabRepo.GetIndexFail();
  };

  @Effect()
  getMany$ = this.data.fetch(gitlabRepo.GET_INDEX, {
    run: (action, store) =>
      this.api
        .get([this.gitlabRepoKey], { gitlabCompany: getFirstId(store), runInBackground: 1 })
        .pipe(
          switchMap(
            (resp: JsonApiSingeModelResponse<any>) =>
              this._jobs.isJob(resp) ? this._jobs.trackJobStatus(resp) : of(resp)
          ),
          map(jsonApiToEntityState),
          switchMap((state: ESGitlabRepository) =>
            of(...[new GetCompleteAction(state), new gitlabRepo.GetIndexComplete(state)])
          )
        ),
    onError: this.defaultErrorHandler
  });

  constructor(
    private data: DataPersistence<AppState>,
    private api: AtlazApiV2Service,
    private _jobs: JobStatusService
  ) {}
}
