<input [focus]="true" placeholder="Search Members..." (keyup)="0" #input_search_members/>
<button
  a-button
  *ngIf="showInvite && (isSuperUser$ | async)"
  [routerLink]="appUrls.getUrlMassInviteMembers()"
  [queryParams]="{ boardId: boardId }"
  [skipLocationChange]="true"
  type="button"
  class="invite_link btn_primary"
>Invite New Members</button>
<div class="users_wrapper">
  <div *ngFor="let user of boardUsers | usersNameFilter: input_search_members.value"
       class="user_title"
       (click)="onSelectUser(user)"
  >
    <div class="avatar_container">
      <a-avatar
        [user]="user"
      ></a-avatar>
    </div>
    <div class="member_info">
      <div class="member_info_name"> {{user.firstname}} {{user.lastname}}</div>
      <div class="member_info_text">@{{user.nickname}}</div>
    </div>
    <span class="check_label" *ngIf="checkSelectedUser[user.id]"></span>
  </div>
  <div *ngFor="let user of nonSelectedUsers | usersNameFilter: input_search_members.value"
       class="user_title"
       (click)="onSelectUser(user)"
  >
    <div class="avatar_container">
      <a-avatar
        [user]="user"
      ></a-avatar>
    </div>
    <div class="member_info">
      <div class="member_info_name"> {{user.firstname}} {{user.lastname}}</div>
      <div class="member_info_text">@{{user.nickname}}</div>
    </div>
    <span class="check_label" *ngIf="checkSelectedUser[user.id]"></span>
  </div>
</div>
