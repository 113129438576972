import { distinctUntilChanged, filter, map, pluck, switchMap, take } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';

import { Board, PartOfEntity } from '../../../interfaces';
import { AppState, COMPONENT, RIGHT_MENU } from '../../../ngrx/state';

import { ComponentToggleAction } from '../../../ngrx/actions/component.actions';
import { BoardEditAction } from '../../../ngrx/actions/board.actions';
import { boardType, ScoringType } from '../../../constants';
import { AppUrls } from '../../../app-urls';
import { comparePlainObjects, isEqualType } from '../../../../helpers';
import { PermissionsService } from '../../../permissions/permissions.service';
import { isPresent } from '../../../../helpers/';
import { SegmentService } from '../../../atlaz-bnp/services/intergations/segment/segment.service';
import { BoardFilterInfo } from '../../../interfaces/board';
import { BoardViewMode, fromGuiState } from '../../../ngrx/reducers/gui-state-memorized.reducer';

@Component({
  selector: 'board-sub-header',
  templateUrl: './board-sub-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./board-sub-header.component.scss']
})
export class BoardSubHeaderComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('header') headerElementRef;

  @Input() board: Board;

  public appUrls = AppUrls;

  public isChangeBoardNameVisible = false;
  public isRightMenuVisible$: Observable<boolean>;

  public hasPermission$: Observable<boolean>;

  public selectedBoardId$ = new BehaviorSubject(0);
  public selectedBoard$: BehaviorSubject<Board> = new BehaviorSubject(null);
  public boardInfo$: Observable<BoardFilterInfo>;
  public isBoardViewMode$: Observable<boolean>;
  public ScoringType = ScoringType;
  public isNotGuest$ = this._permissions.isNotGuest$;

  static _getBoardType(board: Board) {
    return board.type !== boardType.backlog || boardType.backlog === ScoringType.basic
      ? board.type
      : board.type + ScoringType.basic;
  }

  constructor(
    private _store: Store<AppState>,
    private _segment: SegmentService,
    private _permissions: PermissionsService
  ) {}

  get boardId() {
    return this.board.id;
  }

  get isBackLog() {
    return isEqualType(boardType.backlog)(this.board);
  }

  get isSprint() {
    return isEqualType(boardType.sprint)(this.board);
  }

  get isRoadMap() {
    return isEqualType(boardType.roadmap)(this.board);
  }

  get isOpenedBoard() {
    return !this.board.closed;
  }

  get isClosed() {
    return !!this.board.closed;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.selectedBoard$.next(this.board);
    this.selectedBoardId$.next(this.board ? this.board.id : 0);
  }

  ngOnInit(): any {
    this.boardInfo$ = this.selectedBoard$.pipe(
      filter(isPresent),
      switchMap((board: Board) =>
        this._store.select(fromGuiState.getBoardViewMode(board.id)).pipe(
          map(mode => ({
            id: board.id,
            type: board.type,
            scoringType: board.scoringType,
            boardView: mode || BoardViewMode.board,
            backlogScoreXLabel: board.backlogScoreXLabel,
            backlogScoreYLabel: board.backlogScoreYLabel,
            showProps: board.showProps,
          })),
          distinctUntilChanged(comparePlainObjects)
        )
      )
    );

    this.isRightMenuVisible$ = <Observable<boolean>>this._store.pipe(
      pluck(COMPONENT, RIGHT_MENU, 'visible'),
      distinctUntilChanged()
    );
    this.isBoardViewMode$ = this.selectedBoard$.pipe(
      filter(isPresent),
      switchMap(board => this._store.select(fromGuiState.getBoardViewMode(board.id))),
      map(v => !v)
    );
    this.hasPermission$ = this._permissions.canEditBoardName(this.board.projectsIds);
  }

  ngOnDestroy() {}

  onToggleRightMenu() {
    this.isNotGuest$.pipe(take(1)).subscribe(isNotGuest => {
      if (isNotGuest) {
        this._store.dispatch(new ComponentToggleAction({ name: RIGHT_MENU }));
      }
    });
  }

  onOpenRenameBoardPopup() {
    this.hasPermission$.pipe(take(1), filter(isPresent)).subscribe(_ => (this.isChangeBoardNameVisible = true));
  }

  onRenameBoard(boardData: PartOfEntity) {
    this._store.dispatch(new BoardEditAction(boardData));
    this.onHideRenameBoard();
  }

  onHideRenameBoard() {
    this.isChangeBoardNameVisible = false;
  }
}
