import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AButtonComponent } from './a-button/a-button.component';
import { AContextControllComponent } from './a-context-controll/a-context-controll.component';
import { ContextMenuLegasyComponent } from './context-menu/a-context-menu-legasy.component';
import { AContextPopupComponent } from './a-context-popup/a-context-popup.component';
import { AContextMenuItemComponent } from './context-menu/a-context-menu-item.component';
import { AEditableInlineComponent } from './a-editable/a-editable-inline/a-editable-inline.component';
import { AEditableSelectComponent } from './a-editable/a-editable-select/a-editable-select.component';
import { ALoaderComponent } from './a-loader/a-loader.component';
import { AAvatarComponent } from './a-avatar/a-avatar.component';
import { AConfirmationPopupComponent } from './a-confirmation-popup/a-confirmation-popup.component';
import { APopupComponent } from './a-popup/a-popup.component';
import { AContextContainerComponent } from './a-context-container/a-context-container.component';
import { ACenterContainerComponent } from './a-center-container/a-center-container.component';
import { ACenterPopupComponent } from './a-center-popup/a-center-popup.component';
import { AInfiniteLoaderComponent } from './a-infinite-loader/a-infinite-loader.component';
import { ATagComponent } from './a-tag/a-tag.component';
import { AFixedContainerComponent } from './a-fixed-container/a-fixed-container.component';
import { AtlazIconsModule } from '../atlaz-icons/atlaz-icons.module';
import { AMultipleAvatarsComponent } from './a-multiple-avatars/a-multiple-avatars.component';
import { AMenuComponent } from './a-menu/a-menu.component';
import { APortalOutletComponent } from './a-portal/a-portal-outlet/a-portal-outlet.component';
import { AShowInPortalComponent } from './a-portal/a-show-in-portal/a-show-in-portal.component';
import { ContextMenuComponent } from './context-menu/a-context-menu.component';
import { AOverlayComponent } from './a-overlay/a-overlay.component';
import { AContextMenuContentComponent } from './a-popup/a-context-menu-content/a-context-menu-content.component';
import { ATooltipDirective } from './a-tooltip/a-tooltip.directive';
import { ADatetimePickerComponent } from './a-datetime-picker/a-datetime-picker.component';
import { ASearchableSelectComponent } from './a-searchable-select/a-searchable-select.component';
import { FormsModule } from '@angular/forms';
import { AMultiselectComponent } from './a-multiselect/a-multiselect.component';
import { ElementFocusDirective } from './element-focus/element.focus.directive';
import { ShowBoundToElDirective } from './show-bound-to-element/show-bound-to-el.directive';
import { AToggleComponent } from './a-toggle/a-toggle.component';
import { ClickOutDirective } from './click-out/click-out.directive';
import { ACenterContainerScrollableComponent } from './a-center-container-scrollable/a-center-container-scrollable.component';
import { ADateTimeInputlessComponent } from './a-date-time-inputless/a-date-time-inputless.component';

const sharedComponents = [
  AOverlayComponent,
  AShowInPortalComponent,
  APortalOutletComponent,
  AMenuComponent,
  AButtonComponent,
  ALoaderComponent,
  ATagComponent,
  AInfiniteLoaderComponent,
  AAvatarComponent,
  AContextControllComponent,
  ContextMenuLegasyComponent,
  ContextMenuComponent,
  AContextMenuContentComponent,
  AContextPopupComponent,
  AConfirmationPopupComponent,
  ALoaderComponent,
  APopupComponent,
  AToggleComponent,
  AContextContainerComponent,
  ACenterContainerComponent,
  ACenterPopupComponent,
  AContextMenuItemComponent,
  AEditableInlineComponent,
  AEditableSelectComponent,
  AFixedContainerComponent,
  ADatetimePickerComponent,
  ASearchableSelectComponent,
  AMultipleAvatarsComponent,
  AMultiselectComponent,
  ACenterContainerScrollableComponent,
  ADateTimeInputlessComponent
];

const sharedDirectives = [ATooltipDirective, ElementFocusDirective, ShowBoundToElDirective, ClickOutDirective];

@NgModule({
  imports: [CommonModule, AtlazIconsModule, FormsModule],
  declarations: [...sharedComponents, ...sharedDirectives],
  exports: [...sharedComponents, ...sharedDirectives, AtlazIconsModule]
})
export class AtlazGuiModule {}
