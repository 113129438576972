import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterNavigateService } from '../shared/services/router-navigate.service';
import { POPUP_OUTLET, OPENED_TASK_OUTLET, TASKS } from '../path.routing';

/**
 * Is usig for redirect from Popup outlet to primary outlet
 * @link https://hq.atlaz.io/boards/1(popup:tasks/65867)
 */
@Component({
  template: ``
})
export class TaskDetailPopupComponent {
  constructor(private _route: ActivatedRoute, private _router: Router, private _routerNav: RouterNavigateService) {
    const taskId = this._route.snapshot.params['taskId'];
    console.log('redirect');
    this._router.navigate(
      [
        '/',
        {
          outlets: {
            [POPUP_OUTLET]: null,
            [OPENED_TASK_OUTLET]: [TASKS, taskId]
          }
        }
      ],
      { replaceUrl: true }
    );
  }
}
