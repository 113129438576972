
import {map} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { compose } from '@ngrx/store';
import { share } from '../../functions/util';

import { DEFAULT_MEMBERS_FILTER } from '../../state';
import { getEntitySelector } from '../../functions/selectors';
import { DefaultTasksMembersFilterActionTypes } from '../../actions/task-filters/default-members-filter.actions';
import { TaskDefaultFilterActionTypes } from '../../actions/task-filters/task-default-filter.actions';
import { Action } from '../../actions/unsafe-action';

export interface DefaultMembersFilter {
  membersIds: number[];
}

const initState: DefaultMembersFilter = {
  membersIds: []
};

export function reducer(state = initState, action: Action): DefaultMembersFilter {
  switch (action.type) {
    case DefaultTasksMembersFilterActionTypes.TOGGLE_MEMBER_ID: {
      let memberIds: number[];
      if (action.payload === undefined) {
        memberIds = [];
      } else {
        memberIds = !state.membersIds.includes(action.payload)
          ? [action.payload]
          : state.membersIds.filter(id => id !== action.payload);
      }

      state = Object.assign({}, state, { membersIds: memberIds });

      return state;
    }

    case DefaultTasksMembersFilterActionTypes.RESET: {
      state = initState;

      return initState;
    }

    case TaskDefaultFilterActionTypes.SET: {
      if (action['payload'].activeFilters.defaultMembersFilter) {
        state = { membersIds: [...action['payload'].activeFilters.defaultMembersFilter] };
      } else {
        state = initState;
      }
      return state;
    }

    default: {
      return state;
    }
  }
}

const isActive = (state$: Observable<DefaultMembersFilter>) => state$.pipe(map(state => state.membersIds.length > 0));
const getMembersIds = (state$: Observable<DefaultMembersFilter>) => state$.pipe(map(state => state.membersIds));

export const getDefaultMembersFilterEntity = getEntitySelector(DEFAULT_MEMBERS_FILTER);
export const isActiveDefaultMembersFilter = compose(isActive, getDefaultMembersFilterEntity);
export const getDefaultMembersFilterIds = share(compose(getMembersIds, getDefaultMembersFilterEntity));
