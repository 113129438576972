export const toDashedFromCamelCase = (str: string) => {
  return str.replace(/([A-Z])/g, function($1) {
    return '-' + $1.toLowerCase();
  });
};

export const truncate = (str: string, targetLength: number): string =>
  str.length > targetLength ? str.substr(0, targetLength - 1) + '...' : str;

export function toNumber(arg) {
  if (typeof arg === 'number') {
    return arg;
  }
  return Number(arg);
}
