import { distinctUntilChanged, take, combineLatest, map } from 'rxjs/operators';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Task } from '../../../interfaces';
import { Observable, BehaviorSubject } from 'rxjs';
import { CounterModes, getNextCounterMode, secondsToText } from '../../../../helpers/task';
import { UsersCacheService } from '@atlaz/core/services/users-cache.service';

const KEY_LOCAL_STORE_COLUMNS_COUNTERS = 'columnCountersState';

@Component({
  selector: 'column-tasks-counter',
  templateUrl: './column-tasks-counter.component.html',
  styleUrls: ['./column-tasks-counter.component.scss']
})
export class ColumnTasksCounterComponent implements OnInit {
  @Input() columnId: number;
  @Input() tasks$: Observable<Task[]>;

  public modes = CounterModes;
  public currentMode$: BehaviorSubject<CounterModes>;
  public currentMode;
  public columnTasks$: Observable<Task[]>;
  public counterText$;
  public columnTasksLength$: Observable<number>;
  public columnTasksEstimateSum$: Observable<string>;
  public columnTasksPointsSum$: Observable<string>;
  constructor(private _usersCacheService: UsersCacheService) {}

  ngOnInit() {
    this.currentMode$ = new BehaviorSubject(CounterModes.Count);

    this._usersCacheService
      .get(KEY_LOCAL_STORE_COLUMNS_COUNTERS)
      .pipe(take(1))
      .subscribe(state => {
        const currentState = state || {};
        this.currentMode = currentState[this.columnId] ? currentState[this.columnId] : CounterModes.Count;
        this.currentMode$.next(this.currentMode);
      });

    this.columnTasks$ = this.tasks$.pipe(
      map((tasks: Task[]) => tasks.filter((task: Task) => task.column === this.columnId)),
      distinctUntilChanged()
    );

    this.columnTasksLength$ = this.columnTasks$.pipe(map((tasks: Task[]) => tasks.length));

    this.columnTasksEstimateSum$ = this.columnTasks$.pipe(
      map((tasks: Task[]) => tasks.map(task => task.estimate).reduce((acc, item) => acc + item, 0)),
      distinctUntilChanged(),
      map(estimate => secondsToText(estimate))
    );

    this.columnTasksPointsSum$ = this.columnTasks$.pipe(
      map((tasks: Task[]) => tasks.map(task => task.storyPoints).reduce((acc, item) => acc + item, 0)),
      distinctUntilChanged(),
      map(points => points + 'sp')
    );

    this.counterText$ = this.currentMode$.pipe(
      combineLatest(
        this.columnTasksLength$,
        this.columnTasksEstimateSum$,
        this.columnTasksPointsSum$,
        (v1, v2, v3, v4) => {
          switch (v1) {
            case CounterModes.Count: {
              return v2;
            }
            case CounterModes.Hours: {
              return v3;
            }
            case CounterModes.Points: {
              return v4;
            }
            default: {
              return v2;
            }
          }
        }
      )
    );
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event.stopPropagation();
    this.currentMode = getNextCounterMode(this.currentMode);
    this.currentMode$.next(this.currentMode);
    this._usersCacheService
      .get(KEY_LOCAL_STORE_COLUMNS_COUNTERS)
      .pipe(take(1))
      .subscribe(state => {
        const previousState = state || {};
        this._usersCacheService.set(KEY_LOCAL_STORE_COLUMNS_COUNTERS, {
          ...previousState,
          [this.columnId]: this.currentMode
        });
      });
  }
}
