import { Action } from '../../ngrx/actions/unsafe-action';

export const ADD_LOADED_TASK = '[loaded-pages.actions] add loaded task';
export const ADD_LOADED = '[loaded-pages.actions] add loaded';
export const ADD_LOADED_BOARD = '[loaded-pages.actions] add loaded board';
export const REMOVE_LOADED_TASK = '[loaded-pages.actions] remove loaded deleted users';
export const REMOVE_LOADED_BOARD = '[loaded-pages.actions] remove loaded deleted board';
export const REMOVE_LOADED = '[loaded-pages.actions] remove loaded';
export const ADD_LOADED_DELETED_USERS = '[loaded-pages.actions] add loaded deleted users';
export const LOADED_DATA_RESET = '[loaded-pages.actions] reset';

export class MarkAsLoadedTask implements Action {
  readonly type = ADD_LOADED_TASK;

  constructor(public payload: { taskId: number }) {}
}

export class MarkAsLoadedBoard implements Action {
  readonly type = ADD_LOADED_BOARD;

  constructor(public payload: { boardId: number }) {}
}

export class MarkAsLoadedDeletedUsers implements Action {
  readonly type = ADD_LOADED_DELETED_USERS;
}

export class MarkAsUnloadedTask implements Action {
  readonly type = REMOVE_LOADED_TASK;

  constructor(public payload: { taskId: number }) {}
}

export class MarkAsUnloadedBoard implements Action {
  readonly type = REMOVE_LOADED_BOARD;

  constructor(public payload: { boardId: number }) {}
}

export class MarkAsLoaded implements Action {
  readonly type = ADD_LOADED;

  constructor(public payload: { name: string; id: number }) {}
}

export class MarkAsUnloaded implements Action {
  readonly type = REMOVE_LOADED;

  constructor(public payload: { name: string; id: number }) {}
}

export class LoadedDataReset implements Action {
  readonly type = LOADED_DATA_RESET;
}

// prettier-ignore
export type Actions
  = MarkAsLoadedTask
  | MarkAsLoadedBoard
  | MarkAsUnloadedTask
  | MarkAsUnloadedBoard
  | MarkAsUnloaded
  | MarkAsLoaded
  | MarkAsLoadedDeletedUsers
  | LoadedDataReset
  ;
