<div class="overlay" #overlay data-column-create-on-dclick="true"></div>
<div class="content">
<add-new-task
  *ngIf="canAddNewTask$ | async"
  [addAttrForHotKeys]="true"
  [column]="column"
  [swimlaneId]="swimlane.id"
  initialValue="Add a task..."
  (addTaskEvent)="onAddTask($event)"
  class="new_task_form"
></add-new-task>

<wip-limits *ngIf="isWippedColumn"
            [column]="column"
            [withDetails]="true"
>
</wip-limits>
<column-capacity-indicator class="capacity_block"
                           *ngIf="hasCapasity && column.type != 'subcolumn_done'"
                           [column]="rootColumn"
                           [boardTasks]="boardTasks$ | async"
></column-capacity-indicator>

<div class="task_bag">
  <task-preview-dumb
		#task_previews
    *ngFor="let task of tasks$ | async;trackBy: trackById"
    [item]="task"
    [itemType]="dragItemType"
    [metaData]="{column: task.column, swimlane: task.swimlane}"
    aDraggableItem="true"
    [attr.draggable-disabled]="!canEditTasks[task.id]"
    axis="y"
    class="task_card"
    [task]="task"
    [showEstimates]="showEstimates$ | async"
    [board]="board"
    [boardUsersMap]="boardUsersMap"
    [boardLabelsMap]="boardLabelsMap"
    [activeUserId]="activeUserId"
    [score]="scoreMap[task.id]"
    [newNotifyMap]="newNotifyMap"
    [attr.task]="task.id"
    [class.gu-transit]="draggingItemId === task.id"
    [class.hotkeys-target]="hotKeysTargetId === task.id"
    (mouseenter)="setTaskAsHotKeysTarget(task.id)"
    (mouseleave)="clearHotKeysTarget(task.id)"
    ></task-preview-dumb>
</div>

<add-new-task
  *ngIf="(tasks$ | async).length && (canAddNewTask$ | async)"
  [column]="column"
  [swimlaneId]="swimlane.id"
  [isTaskLastInSwimlane]="true"
  initialValue="Add a task..."
  (addTaskEvent)="onAddTask($event)"
  class="new_task_form"
></add-new-task>
</div>
