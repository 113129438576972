import { Action } from '../unsafe-action';

export enum DefaultTasksColorsFilterActionTypes {
  TOGGLE_COLOR = '[DEFAULT_COLORS_FILTER] TOGGLE_COLOR',
  RESET = '[DEFAULT_COLORS_FILTER] RESET'
}

export class DefaultColorFilterToggleColorsAction implements Action {
  readonly type = DefaultTasksColorsFilterActionTypes.TOGGLE_COLOR;

  constructor(public payload: string) {}
}

export class DefaultColorsFilterResetAction implements Action {
  readonly type = DefaultTasksColorsFilterActionTypes.RESET;
}

// prettier-ignore
export type Actions
  = DefaultColorFilterToggleColorsAction
  | DefaultColorsFilterResetAction
  ;
