import { createSelector } from 'reselect';
import { isPresent, prop } from '../../../../helpers';
import { AppPages } from '../../../constants';
import { Checklist } from '../../../interfaces';
import { AppState } from '../../../ngrx/state';
import { OpenedTaskActionTypes } from '../actions/opened-task.action';
import { Action } from '../../../ngrx/actions/unsafe-action';
import { getTasksState } from '../../../ngrx/reducers/task.reducer';
import { boardsState } from '../../../ngrx/reducers/board.reducer';
import { fromProject } from '../../../ngrx/reducers/project.reducer';
import { getVersionsState } from '../../../ngrx/reducers/version.reducer';
import { fromUsers } from '../../../ngrx/reducers/user.reducer';
import { fromLabels } from '../../../ngrx/reducers/label.reducer';
import { fromColumns } from '../../../ngrx/reducers/column.reducer';

export interface OpenedTask {
  id: number;
  newCreatedCheckList: Checklist;
  lastOpenedLinkTask: number;
  taskToDeleteId: number;
  listenClickOut: number;
  version: number;
  highlightAfterClosing: boolean;
}

const initialState: OpenedTask = {
  id: 0,
  newCreatedCheckList: null,
  lastOpenedLinkTask: 0,
  taskToDeleteId: 0,
  listenClickOut: 1,
  version: null,
  highlightAfterClosing: true
};

export function openedTaskReducer(state = initialState, action: Action): OpenedTask {
  switch (action.type) {
    case OpenedTaskActionTypes.INITIAL:
      return initialState;
    case OpenedTaskActionTypes.OPEN:
      return { ...state, ...{ id: action.payload } };
    case OpenedTaskActionTypes.DATA_CHANGED:
      return { ...state, ...action.payload };
    case OpenedTaskActionTypes.DELETE:
      return { ...state, ...{ taskToDeleteId: action.payload } };
    default: {
      return state;
    }
  }
}

// function prop: OpenedTask;
export namespace fromOpenedTask {
  export const getState = (store: AppState) => <OpenedTask>store[AppPages.Task];
  export const getId = createSelector(getState, prop('id'));
  export const getTask = createSelector(getId, getTasksState, (id, tasksState) => tasksState.entities[id]);
  export const getBoard = createSelector(
    getTask,
    boardsState,
    (task, boardsState) => (task ? boardsState.entities[task.board] : null)
  );
  export const getProject = createSelector(
    getTask,
    fromProject.getState,
    (task, projectsState) => (task ? projectsState.entities[task.project] : null)
  );
  export const getVersion = createSelector(
    getTask,
    getVersionsState,
    (task, versionsState) => (task ? versionsState.entities[task.version] : null)
  );
  export const getColumn = createSelector(
    getTask,
    fromColumns.getState,
    (task, columnState) => (task ? columnState.entities[task.column] : null)
  );
  export const getBoardUsers = createSelector(getBoard, fromUsers.getState, (board, usersState) => {
    return board && board.usersIds
      ? board.usersIds.map(id => usersState.entities[id]).filter(user => user && !user.deleted)
      : [];
  });
  export const getTaskUsers = createSelector(getTask, fromUsers.getState, (task, usersState) => {
    return task && task.usersIds ? task.usersIds.map(id => usersState.entities[id]).filter(isPresent) : [];
  });

  export const getTaskLabels = createSelector(getTask, fromLabels.getState, (task, labelsState) => {
    return task && task.labelsIds ? task.labelsIds.map(id => labelsState.entities[id]).filter(isPresent) : [];
  });
  export const getProjectVersions = createSelector(getProject, getVersionsState, (project, versionsState) => {
    return project
      ? versionsState.ids
          .map(id => versionsState.entities[id])
          .filter(v => v && v.project === project.id && !v.released)
      : [];
  });
  export const getSubscribersIds = createSelector(
    getTask,
    task => (task && task.subscribersIds ? task.subscribersIds : [])
  );
}
