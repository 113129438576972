import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'time-tracking-progress',
  templateUrl: './time-tracking-progress.component.html',
  styleUrls: ['./time-tracking-progress.component.scss']
})
export class TimeTrackingProgressComponent implements OnChanges {
  @Input() estimate: number;
  @Input() loggedTime: number;
  @Input() widthInPx: number;

  // can Be 'short' or nothing
  @Input() templateFormat: string;

  @Output() openEstimatePopup = new EventEmitter();
  @Output() openLogworkPopup = new EventEmitter();

  public estimateCalculated: number;
  public widthCalculated: number;

  public emptyBar: boolean;
  public estimateStyle: Object;
  public loggedTimeStyle: Object;

  ngOnChanges(changes: SimpleChanges) {
    this.emptyBar = this.estimate + this.loggedTime === 0;

    this.estimateCalculated = this.estimate;

    if (this.loggedTime / this.estimate < 1) {
      this.widthCalculated = this.loggedTime / this.estimate;
    } else {
      this.widthCalculated = this.estimate / this.loggedTime;
      this.estimateCalculated = this.estimate - this.loggedTime;
    }
    this.widthCalculated = this.widthCalculated * 100;

    this.setStyles();
  }

  setStyles() {
    this.estimateStyle = {
      background: this.loggedTime < this.estimate ? '#cfd8dc' : '#e53935',
      width: this.widthInPx + 'px'
    };

    this.loggedTimeStyle = {
      width: this.widthCalculated + '%'
    };
  }

  onOpenEstimate() {
    this.openEstimatePopup.emit();
  }
  onOpenLogwork() {
    this.openLogworkPopup.emit();
  }
}
