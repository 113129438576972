
import {catchError, switchMap, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { FILTER_PL } from '../../constants';
import { HttpQueryParam, PartOfEntity } from '../../interfaces';

import { QuickFilterActionTypes, QuickFilterDeleteCompleteAction } from '../actions/quick-filter.actions';
import { defaultErrorHandler } from './root.effect';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { HandleResponseAction } from '../actions/root.action';

@Injectable()
export class QuickFilterEffects {
  @Effect()
  getQuickFilterList$ = this.actions$
    .ofType(QuickFilterActionTypes.GET).pipe(
    switchMap(({ type, payload: httpParams }: { type: string; payload: HttpQueryParam }) => {
      return this._atlazApi
        .get(FILTER_PL, httpParams).pipe(
        map(resp => new HandleResponseAction(resp)),
        catchError(defaultErrorHandler(type, httpParams)),);
    }));

  @Effect()
  addQuickFilter$ = this.actions$
    .ofType(QuickFilterActionTypes.ADD).pipe(
    switchMap(({ type, payload }: { type: string; payload: PartOfEntity }) => {
      return this._atlazApi
        .post(FILTER_PL, payload).pipe(
        map(resp => new HandleResponseAction(resp)),
        catchError(defaultErrorHandler(type, payload)),);
    }));

  @Effect({ dispatch: false })
  editQuickFilter$ = this.actions$
    .ofType(QuickFilterActionTypes.EDIT).pipe(
    switchMap(({ type, payload }: { type: string; payload: PartOfEntity }) => {
      return this._atlazApi
        .patch(FILTER_PL, payload).pipe(
        map(resp => new HandleResponseAction(resp)),
        catchError(defaultErrorHandler(type, payload)),);
    }));

  @Effect()
  deleteQuickFilter$ = this.actions$
    .ofType(QuickFilterActionTypes.DELETE).pipe(
    switchMap(({ type, payload: filterId }: { type: string; payload: number }) => {
      return this._atlazApi
        .deleteRequest([FILTER_PL, filterId]).pipe(
        map(() => new QuickFilterDeleteCompleteAction()),
        catchError(defaultErrorHandler(type, filterId)),);
    }));

  constructor(private actions$: Actions, private _atlazApi: AtlazApiV2Service) {}
}
