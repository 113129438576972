<div a-context-popup
     class="duedate_popup"
     [title]="'Edit Labels'"
     (close)="onCloseLabelsPopup()"
>
  <sidebar-labels-list
    [labels]="labels$ | async"
    [task]="task"
    [isBatchActions]="isBatchActions"
    (batchToggleItem)="onToggleBatchItem($event)"
  ></sidebar-labels-list>
</div>
