import { take } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, HostListener, Input, OnInit } from '@angular/core';
import { SwimlaneCounter } from '../../../interfaces/swimlane';
import { UsersCacheService } from '@atlaz/core/services/users-cache.service';
import { CounterModes, getNextCounterMode } from '../../../../helpers/task';

const KEY_LOCAL_STORE_SWIMLANES_COUNTERS = 'swimlaneCountersState';

@Component({
  selector: 'swimlane-tasks-counter',
  templateUrl: './swimlane-tasks-counter.component.html',
  styleUrls: ['./swimlane-tasks-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwimlaneTasksCounterComponent implements OnInit {
  @Input() countedTasks: SwimlaneCounter;
  @Input() swimlaneId: number;

  public modes = CounterModes;
  public currentMode = CounterModes.Count;

  constructor(private _usersCacheService: UsersCacheService) {}

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event.stopPropagation();
    this.currentMode = getNextCounterMode(this.currentMode);
    this._usersCacheService
      .get(KEY_LOCAL_STORE_SWIMLANES_COUNTERS)
      .pipe(take(1))
      .subscribe(state => {
        const previousState = state || {};
        this._usersCacheService.set(KEY_LOCAL_STORE_SWIMLANES_COUNTERS, {
          ...previousState,
          [this.swimlaneId]: this.currentMode
        });
      });
  }

  ngOnInit() {
    this._usersCacheService
      .get(KEY_LOCAL_STORE_SWIMLANES_COUNTERS)
      .pipe(take(1))
      .subscribe(state => {
        const currentState = state || {};
        this.currentMode = currentState[this.swimlaneId] ? currentState[this.swimlaneId] : CounterModes.Count;
      });
  }
}
