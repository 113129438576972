import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs/index';
import { RoadmapColors, RoadmapDefaultColor } from '../../../board/roadmap-board/interfaces/roadmap.interface';
import { AppState } from '../../../ngrx/state';
import { Store } from '@ngrx/store';
import { getDefaultColorsFilterValues } from '../../../ngrx/reducers/task-filters/default-colors-filter.reducer';
import {
  DefaultColorFilterToggleColorsAction,
  DefaultColorsFilterResetAction
} from '../../../ngrx/actions/task-filters/default-colors-filter.actions';
import { map } from 'rxjs/operators';

@Component({
  selector: 'default-colors-filter',
  templateUrl: './default-colors-filter.component.html',
  styleUrls: ['./default-colors-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultColorsFilterComponent implements OnInit {
  @Input() boardId$: Observable<number>;
  subs: Subscription[] = [];

  public availableColors = [RoadmapDefaultColor, ...RoadmapColors];
  public selectedColors$: Observable<string[]>;
  public colorsWithStatus$: Observable<{ color: string; isActive: boolean }[]>;
  public showColorsPopUp = false;

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this.selectedColors$ = this._store.pipe(getDefaultColorsFilterValues);
    this.subs.push(
      this.selectedColors$.subscribe(colors => {
        colors && !colors.length && this._store.dispatch(new DefaultColorsFilterResetAction());
      })
    );
    this.colorsWithStatus$ = this.selectedColors$.pipe(
      map(selectedColors =>
        this.availableColors.map(color => ({ color: color, isActive: selectedColors.includes(color) }))
      )
    );
  }

  onToggleColor(color) {
    this._store.dispatch(new DefaultColorFilterToggleColorsAction(color));
  }

  onShowColorsPopup() {
    this.showColorsPopUp = true;
  }
  onHideColorsPopup() {
    this.showColorsPopUp = false;
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
