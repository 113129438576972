import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { Label, Task, User } from '../../../../interfaces';
import { AppUrls } from '../../../../app-urls';
import { isPresent } from '../../../../../helpers';
import { CustomField, CustomFieldTypes } from '../../../../ngrx/reducers/custom-field.reducer';
import { TasksCustomField } from '../../../../ngrx/reducers/tasks-custom-field.reducer';
import { ScoringType } from '../../../../constants';

@Component({
  selector: 'list-board-task-item',
  templateUrl: './list-board-task-item.component.html',
  styleUrls: ['./list-board-task-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListBoardTaskItemComponent implements OnChanges {
  @Input() task;
  @Input() scoringType: ScoringType;
  @Input() scoringColumns: any[];
  @Input() isNotGuest: boolean;
  @Input() boardUsersMap;
  @Input() boardLabelsMap;
  @Input() customFields: CustomField[];
  @Input() customFieldsValues: { [fieldId: number]: TasksCustomField };
  @Input() isEditing: boolean;

  @Output() editingStart = new EventEmitter();
  @Output() editingEnd = new EventEmitter();
  @Output() completeTask = new EventEmitter();
  @Output() editScoring = new EventEmitter();
  @Output() openQuickActions = new EventEmitter();

  @Output() showDeleteDialog = new EventEmitter();

  public taskUrl;
  public taskUsers: User[];
  public taskLabels: Label[];

  public CustomFieldTypes = CustomFieldTypes;
  public ScoringType = ScoringType;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('task')) {
      this.taskUrl = AppUrls.getUrlTask(this.task.taskKey ? this.task.taskKey : this.task.id);
    }
    if ((changes.hasOwnProperty('task') || changes.hasOwnProperty('boardUsersMap')) && this.boardUsersMap) {
      this.taskUsers = this.task.usersIds ? this.task.usersIds.map(id => this.boardUsersMap[id]).filter(isPresent) : [];
    }
    if ((changes.hasOwnProperty('task') || changes.hasOwnProperty('boardLabelsMap')) && this.boardLabelsMap) {
      this.taskLabels = this.task.labelsIds
        ? this.task.labelsIds.map(id => this.boardLabelsMap[id]).filter(isPresent)
        : [];
    }
  }

  onCompleteTask(event) {
    event.stopPropagation();
    this.completeTask.emit(this.task);
  }

  onEdit(event) {
    this.openQuickActions.emit({ event, id: this.task.id });
  }

  onOpenMembersEdit(clientRect) {
    this.editingStart.emit({ id: this.task.id, field: 'members', clientRect: clientRect });
  }

  onOpenFieldEdit(event, field) {
    event.stopPropagation();
    this.editingStart.emit({ id: this.task.id, field: field, clientRect: event.target.getBoundingClientRect() });
  }

  getScoringValue(task: Task, scoringColumn) {
    switch (this.scoringType) {
      case ScoringType.ICE: {
        return task[scoringColumn.prop] || '?';
      }
      case ScoringType.RICE: {
        return task[scoringColumn.prop] || '?';
      }
      case ScoringType.basic: {
        return task[scoringColumn.factor] || '?';
      }
      case ScoringType.advanced: {
        return (
          (task['scoringValuesMap'] &&
            task['scoringValuesMap'][scoringColumn.id] &&
            task['scoringValuesMap'][scoringColumn.id]['visibleValue']) ||
          '?'
        );
      }
      default: {
        return '?';
      }
    }
  }

  getTaskResultScore(task) {
    let score;
    if (this.scoringType === ScoringType.ICE) {
      score = parseInt(task.iceImpact) * parseInt(task.iceConfidence) * parseInt(task.iceEase);
    } else if (this.scoringType === ScoringType.RICE) {
      score = Math.round(
        parseInt(task.riceReach) *
          parseFloat(task.riceImpact) *
          (parseInt(task.riceConfidence) / 100) /
          parseInt(task.riceEffort)
      );
    } else {
      score = task.score;
    }
    return Number.isFinite(score) ? score : '?';
  }

  onEditScoringCriterion($event, scoringColumn?) {
    $event.stopPropagation();
    this.editScoring.emit({ task: this.task, scoringColumn: scoringColumn });
  }

  onShowDeleteConfirmationDialog(e?) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.showDeleteDialog.emit(this.task);
  }
}
