<div class="project_container">
  <div class="project_tag"
       *ngFor="let projectId of selectedProjects$ | async">
    <p>{{projectsEntities[projectId]?.shortName}}</p>
    <div *ngIf="!disabledProjects.includes(projectId)" (click)="onRemoveProject(projectId)"
         class="remove_project_control"></div>
  </div>
  <div *ngIf="!hideAddProjectBtn"
       class="add_btn"
       (click)="onOpenPopup()"
       [ngClass]="{'invalid_project': !validation}"
  >
  </div>
</div>
<div a-context-popup
     [title]="'Projects'"
     *ngIf="showAddPopUp"
     class="projects_popup"
     (close)="onClosePopup()"
>
  <project-list-independent [multiple]="multiple"
                            [selectedProjects]="selectedProjects$"
                            [disabledProjects]="disabledProjects">
  </project-list-independent>
</div>
