import { map, switchMap, pluck, refCount, publishReplay, distinctUntilChanged } from 'rxjs/operators';
import { Component, OnInit, Input, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { DefaultCollection } from '../../interfaces/default-collection';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/state';
import { DEFAULT_COLLECTION_PL } from '../../constants';
import { Board } from '../../interfaces';
import { filterClosedBoards, getBoardByIds } from '../../ngrx/reducers/board.reducer';
import { defaultCollectionTypes } from '../../constants/default-collections';
import { naturalSort } from '../../../helpers';
import { SearchInLeftMenuService } from '../../shared/services/app/search-in-left-menu.service';
import { OpenedStateLeftMenuItemsService } from '../shared/services/opened-state-left-menu-items.service';

@Component({
  selector: 'left-default-collection-item',
  templateUrl: './left-default-collection-item.component.html',
  styleUrls: ['./left-default-collection-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeftDefaultCollectionItemComponent implements OnInit, OnDestroy {
  @Input() id;

  public collection$: Observable<DefaultCollection>;
  public boards$: Observable<Board[]>;
  public isVisibleBoards: boolean;
  public subs: Subscription[] = [];
  public openedStateCollectionListKey: string;
  public isReverseDefaultState: boolean;

  constructor(
    private _store: Store<AppState>,
    private _searchInLeftMenuService: SearchInLeftMenuService,
    private _openedStateLeftMenuItemsService: OpenedStateLeftMenuItemsService
  ) {}

  ngOnInit(): any {
    this.isReverseDefaultState = this.id === defaultCollectionTypes.favorites;
    this.collection$ = this._store.pipe(
      pluck<AppState, DefaultCollection>(DEFAULT_COLLECTION_PL, 'entities', this.id),
      distinctUntilChanged()
    );
    this.boards$ = this.collection$.pipe(
      switchMap(
        collection =>
          collection
            ? this._store.pipe(
                getBoardByIds(collection.boardsIds),
                map(collection.type === defaultCollectionTypes.favorites ? naturalSort('name') : x => x.reverse())
              )
            : []
      ),
      map(filterClosedBoards),
      publishReplay(1),
      refCount()
    );

    this.subs.push(
      this.boards$.subscribe(result => {
        this.isVisibleBoards = this.isReverseDefaultState && !!result.length;
        this.openedStateCollectionListKey = this._openedStateLeftMenuItemsService.localStoreKeys.OPENED_COLLECTIONS_LIST;
      })
    );

    this.subs.push(
      this.boards$
        .pipe(switchMap(() => this._searchInLeftMenuService.isSearchActive$))
        .subscribe(state => this.initIsVisibleBoards(state))
    );
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  onOpenBoardsList() {
    this.isVisibleBoards = !this.isVisibleBoards;
    this._openedStateLeftMenuItemsService.save(
      this.isReverseDefaultState ? !this.isVisibleBoards : this.isVisibleBoards,
      this.openedStateCollectionListKey,
      this.isReverseDefaultState ? '!' + this.id : this.id
    );
  }

  initIsVisibleBoards(state: boolean) {
    this.isVisibleBoards = this.isReverseDefaultState ? !state : state;
    if (!state) {
      const list = this._openedStateLeftMenuItemsService.getOpenedProjectsList(this.openedStateCollectionListKey);
      if (!list.includes(this.isReverseDefaultState ? '!' + this.id : this.id.toString())) {
        this.isVisibleBoards = !this.isReverseDefaultState;
      }
    }
  }
}
