<div a-context-popup [title]="'Projects'"
     (close)="onAssignProjectAndClosePopup()"
>
  <right-menu-project-list
    [multiple]="isMultiSelect"
    [required]="selectedBoard?.type === boardTypes.sprint"
    [projects]="projects"
    [selectedProjects]="boardProjects">
  </right-menu-project-list>
</div>
