import { Action } from './unsafe-action';
import { Company } from '../../interfaces';

export enum CurrentCompany {
  UPDATED = '[CURRENT COMPANY] UPDATED'
}

export class CurrentCompanyUpdatedAction implements Action {
  readonly type = CurrentCompany.UPDATED;

  constructor(public payload: Company) {}
}
