import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'defaultValue'
})
export class DefaultValuePipe implements PipeTransform {
  transform(value: any, defaultValue: any): any {
    return !!value ? value : defaultValue;
  }
}
