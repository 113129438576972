import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Project } from '../../../../interfaces';

@Component({
  selector: 'projects-filter-list',
  templateUrl: './projects-filter-list.component.html',
  styleUrls: ['./projects-filter-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectsFilterListComponent implements OnInit {
  @Input() projects: Project[];
  @Input() selectedProjects: Project[];

  @Output() addProject = new EventEmitter();
  @Output() removeProject = new EventEmitter();

  public checkSelectedProject: { [id: number]: boolean } = {};

  constructor() {}

  ngOnInit() {
    this.selectedProjects.forEach((project: Project) => {
      this.checkSelectedProject[project.id] = true;
    });
  }

  onSetFilter(project) {
    if (!this.checkSelectedProject[project.id]) {
      this.checkSelectedProject[project.id] = true;
      this.addProject.emit(project);
    } else {
      this.checkSelectedProject[project.id] = false;
      this.removeProject.emit(project);
    }
  }
}
