import { Component, ChangeDetectionStrategy } from '@angular/core';

import { Board } from '../../../interfaces';
import { DropdownFilterComponent } from './dropdown-filter.component';

@Component({
  selector: 'board-filter',
  templateUrl: './dropdown-filter.component.html',
  styleUrls: ['./task-feed-filter.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoardFilterComponent extends DropdownFilterComponent<Board> {
  constructor() {
    super();
    super.initFields('Boards', 'name', 'name');
  }
}
