import { TaskAttachmentsActionTypes } from '../actions/task-attachments.actions';
import { AppState, entityStateToArray, ESInterface } from '../state';
import { TaskAttachment } from '../../interfaces';
import { addEntityState, deleteEntityState, editEntityState, selectEntity } from '../functions/reducer';
import { getAllEntitiesAsArray, getEntitySelector } from '../functions/selectors';
import { compose } from '@ngrx/store';
import { TASKS_ATTACHMENT_PL } from '../../constants';
import { ChecklistActions } from '../actions/checklist.actions';
import { createSelector } from 'reselect';
import { fromCompanyFeatures } from './company-features.reducer';
import { Features } from '../../libs/paywall/features.constants';

const initialState: ESInterface<TaskAttachment> = {
  ids: [],
  entities: {},
  selectedEntityId: null
};

export function reducer(state = initialState, action: ChecklistActions): ESInterface<TaskAttachment> {
  switch (action.type) {
    case TaskAttachmentsActionTypes.ADD: {
      return selectEntity(state, action.payload.file);
    }

    case TaskAttachmentsActionTypes.ADD_COMPLETE: {
      return addEntityState(state, action.payload, true);
    }

    case TaskAttachmentsActionTypes.DELETE: {
      return deleteEntityState(state, action.payload.id);
    }

    case TaskAttachmentsActionTypes.MAKE_COVER: {
      return editEntityState(state, action.payload);
    }

    default: {
      return state;
    }
  }
}

export const getAllTaskAttachments = compose(
  getAllEntitiesAsArray,
  getEntitySelector<TaskAttachment>(TASKS_ATTACHMENT_PL)
);

export namespace fromTaskAttachments {
  export const getState = (store: AppState) => <ESInterface<TaskAttachment>>store[TASKS_ATTACHMENT_PL];
  export const get = id => (store: AppState) => getState(store).entities[id];

  export const paidAttachmentTypesFilter = createSelector(fromCompanyFeatures.enabledFeatures, features => {
    const storageToFilter = [];
    if (!features[Features.Dropbox]) {
      storageToFilter.push('dropbox');
    }
    if (!features[Features.GoogleDrive]) {
      storageToFilter.push('googledrive');
    }
    return storageToFilter.length ? (attach: TaskAttachment) => !storageToFilter.includes(attach.storage) : _ => true;
  });

  export const getAll = createSelector(
    getState,
    paidAttachmentTypesFilter,
    (attachments: ESInterface<TaskAttachment>, paidAttachmentTypesFilter) =>
      entityStateToArray(attachments)
        .filter(paidAttachmentTypesFilter)
        .sort((a: TaskAttachment, b: TaskAttachment) => a.createdAt - b.createdAt)
  );

  export const getByTask = (taskId: number) =>
    createSelector(getAll, (attachments: TaskAttachment[]) => attachments.filter(attach => attach.task === taskId));
}
