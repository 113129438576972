import { map } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, LEFT_MENU } from '../../ngrx/state/';
import { RouterNavigateService } from '../../shared/services/router-navigate.service';
import { ComponentListenClickoutAction } from '../../ngrx/actions/component.actions';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../shared/services/form-v2.service';
import { Observer } from 'rxjs';
import { MASS_INVITE } from '../../path.routing';
import { CustomValidators } from '../../shared/validators/custom-validators';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { naturalSort } from '../../../helpers';
import { HandleResponseAction } from '../../ngrx/actions/root.action';
import { AppUrls } from '../../app-urls';
import { ActivatedRoute } from '@angular/router';
import { Board } from '../../interfaces';
import { filterClosedBoards, getAllBoards } from '../../ngrx/reducers/board.reducer';
import {
  ROLE_COMPANY_ADMIN,
  ROLE_COMPANY_EMPLOYEE,
  ROLE_COMPANY_GUEST,
  ROLE_COMPANY_OWNER,
  ROLE_COMPANY_TITLES
} from '../../permissions/interfaces/roles';

@Component({
  templateUrl: './mass-invite.component.html',
  styleUrls: ['./mass-invite.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MassInviteComponent implements OnInit {
  public roles: { role: string; name: string }[] = [
    { role: ROLE_COMPANY_EMPLOYEE, name: ROLE_COMPANY_TITLES[ROLE_COMPANY_EMPLOYEE] },
    { role: ROLE_COMPANY_ADMIN, name: ROLE_COMPANY_TITLES[ROLE_COMPANY_ADMIN] },
    { role: ROLE_COMPANY_OWNER, name: ROLE_COMPANY_TITLES[ROLE_COMPANY_OWNER] },
    { role: ROLE_COMPANY_GUEST, name: ROLE_COMPANY_TITLES[ROLE_COMPANY_GUEST] }
  ];

  private _availableRoles = this.roles.map(item => item.role);

  public form: FormGroup;
  public userEmails: string[];
  public boardsInitValue: number[];
  public manyEmails = [];
  public areInvitesSent = false;
  public isManyAtOnceActive = false;
  public inviteUrl = AppUrls.getUrlInviteLinkPopup();
  public projectId: number;
  public allBoards$;

  public formObserver: Observer<any> = {
    next: resp => {
      this._store.dispatch(new HandleResponseAction(resp));
      this.areInvitesSent = true;
    },
    error: error => {},
    complete: () => {}
  };

  public formServiceParams: FormServiceParams = {
    saveType: FormSaveType.add,
    entityToEdit: MASS_INVITE,
    formObserver: this.formObserver,
    prepareFormValue: formValue => {
      const result = this.isManyAtOnceActive ? { emails: this.manyEmails.toString() } : formValue;
      if (this.projectId) {
        result['project'] = this.projectId;
      }
      return result;
    }
  };

  constructor(
    public _formService: FormV2Service,
    private _store: Store<AppState>,
    private _fb: FormBuilder,
    private _routerNav: RouterNavigateService,
    private _activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.allBoards$ = this._store.pipe(
      getAllBoards,
      map(filterClosedBoards),
      map(naturalSort('name')),
      map((boards: Board[]) => boards.map((board: Board) => ({ label: board.name, id: board.id })))
    );

    const boardId = +this._activatedRoute.snapshot.queryParams['boardId'];
    this.projectId = +this._activatedRoute.snapshot.queryParams['projectId'];
    this.boardsInitValue = boardId ? [boardId] : [];
    this.initForm();
  }

  initForm() {
    this.form = this._fb.group({
      users: this._fb.array([])
    });
    this.addMemberFields();
    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  initMemberFields() {
    return this._fb.group({
      email: ['', CustomValidators.composeOr([CustomValidators.empty, Validators.email])],
      role: [ROLE_COMPANY_EMPLOYEE, CustomValidators.includes(this._availableRoles)],
      boards: [this.boardsInitValue]
    });
  }

  onToggleManyAtOnce() {
    this.initForm();
    this._formService.markAsAvailable();
    this.isManyAtOnceActive = !this.isManyAtOnceActive;
  }

  onSetManyEmails(emails: string[]) {
    this.manyEmails = emails;
  }

  addMemberFields() {
    const control = <FormArray>this.form.controls['users'];
    const addControl = this.initMemberFields();

    control.push(addControl);
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }
    if (this.isManyAtOnceActive && !this.manyEmails.length) {
      this._formService.errors = ['Enter at least one email'];
      this._formService.markAsError();
      return;
    }
    const usersData = this.form.value.users;
    const isEmptyEmail = !usersData.filter(user => user.email.length).length;

    if (this.isManyAtOnceActive) {
      this.userEmails = this.manyEmails;
    } else {
      this.userEmails = usersData.filter(user => user.email.length).map(user => user.email);
    }

    if (isEmptyEmail && !this.isManyAtOnceActive) {
      this._formService.errors = ['Enter at least one email'];
      this._formService.markAsError();
      return;
    }

    const formUsers = <FormArray>this.form.controls['users'];
    for (let i = 0; i < formUsers.length; i++) {
      if (!formUsers.at(i).value['email'] || !formUsers.at(i).valid) {
        formUsers.removeAt(i);
        i--;
      }
    }

    this._formService.markAsAvailable();
    this._formService.submit();
  }

  onClose() {
    this._store.dispatch(new ComponentListenClickoutAction({ name: LEFT_MENU, listenClickout: true }));
    this._routerNav.deactivatePopupOutlet();
  }

  onCloseOnButton() {
    if (this.isManyAtOnceActive) {
      this.onToggleManyAtOnce();
      return;
    }
    this.onClose();
  }
}
