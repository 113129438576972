import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'a-tag',
  templateUrl: './a-tag.component.html',
  styleUrls: ['./a-tag.component.scss']
})
export class ATagComponent {
  @Input() title = '';
  @Output() remove = new EventEmitter();

  onRemove() {
    this.remove.emit();
  }
}
