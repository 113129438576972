import { Action } from './unsafe-action';
import { labelAction } from '../functions/action';
import { USER_COMPANY } from '../../constants';
import { UsersCompany } from '../../interfaces/users-compnay';
import { ESInterface } from '../state';

export const UserCompanyActionTypes = {
  UPDATED: labelAction(USER_COMPANY, 'UPDATED'),
  EDIT: labelAction(USER_COMPANY, 'EDIT'),
  EDIT_COMPLETE: labelAction(USER_COMPANY, 'EDIT_COMPLETE')
};

export class UserCompanyUpdatedAction implements Action {
  type = UserCompanyActionTypes.UPDATED;

  constructor(public payload: ESInterface<UsersCompany>) {}
}

export class UserCompanyEditAction implements Action {
  type = UserCompanyActionTypes.EDIT;

  constructor(public payload: Partial<UsersCompany>) {}
}

export class UserCompanyEditCompleteAction implements Action {
  type = UserCompanyActionTypes.EDIT_COMPLETE;

  constructor(public payload: UsersCompany) {}
}

export type Action = UserCompanyUpdatedAction | UserCompanyEditAction | UserCompanyEditCompleteAction;
