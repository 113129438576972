
import {debounceTime, tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../app/auth.service';

import * as ROUTER_PATH from '../../../path.routing';
import { RouterNavigateService } from '../router-navigate.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private _authService: AuthService, private _navigate: RouterNavigateService, private _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this._authService.hasAuthInfo) {
      this._authService.checkAccount = true;
      this._authService.fetchAccessToken().subscribe(() => null, () => this._authService.isLoggedIn$.next(false));
    }

    return this._authService.isLoggedIn$.pipe(
      tap(isLogged => {
        if (isLogged === false) {
          this._navigate.navigateToLogin();
        }
        /**
         * Debounce Time is required to extract user data before component start rendering to get data synchronously
         */
      }),
      debounceTime(0),);
  }

  navigateToLogout() {
    this._router.navigate([ROUTER_PATH.LOGOUT]);
  }
}
