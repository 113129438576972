import { Action } from '../actions/unsafe-action';

export function createReducer(initialState, stateKey) {
  return (state = initialState, action: Action) => reduceAction(state, action, stateKey)();
}

function reduceAction(state, action, stateKey): Function {
  if (typeof action.getNewState === 'function' && action.stateKey === stateKey) {
    return () => action.getNewState(state);
  } else {
    return () => state;
  }
}
