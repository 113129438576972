import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AtlazGuiModule } from '../../../atlaz-gui/atlaz-gui.module';
import { BoardQuickFiltersComponent } from '../../../board/board-quick-filters/board-quick-filters.component';
import { DefaultMembersFilterComponent } from '../default-members-filter/default-members-filter.component';
import { DefaultLabelsFilterComponent } from '../default-labels-filter/default-labels-filter.component';
import { LabelsFilterListComponent } from '../default-labels-filter/labels-filter-list/labels-filter-list.component';
import { AppContainersModule } from '../../containers.module';
import { SharedModule } from '../../../shared/shared.module';
import { DefaultColumnsFilterComponent } from '../default-columns-filter/default-columns-filter.component';
import { ColumnsFilterListComponent } from '../default-columns-filter/columns-filter-list/columns-filter-list.component';
import { DefaultSwimlanesFilterComponent } from '../default-swimlanes-filter/default-swimlanes-filter.component';
import { SwimlanesFilterListComponent } from '../default-swimlanes-filter/swimlanes-filter-list/swimlanes-filter-list.component';
import { StatusesFilterListComponent } from '../default-statuses-filter/statuses-filter-list/statuses-filter-list.component';
import { DefaultStatusesFilterComponent } from '../default-statuses-filter/default-statuses-filter.component';
import { DefaultColorsFilterComponent } from '../default-colors-filter/default-colors-filter.component';
import { BoardViewSelectorComponent } from '../../../board/board-quick-filters/board-view-selector/board-view-selector.component';
import { DefaultQuadsFilterComponent } from '../default-quads-filter/default-quads-filter.component';
import { DefaultVersionsFilterComponent } from '../default-versions-filter/default-versions-filter.component';
import { VersionsFilterListComponent } from '../default-versions-filter/versions-filter-list/versions-filter-list.component';
import { DefaultProjectsFilterComponent } from '../default-projects-filter/default-projects-filter.component';
import { ProjectsFilterListComponent } from '../default-projects-filter/projects-filter-list/projects-filter-list.component';
import { GroupBySelectorComponent } from '../../../board/board-quick-filters/group-by-selector/group-by-selector.component';
import { DefaultTimeOnBoardFilterComponent } from "../default-time-on-board-filter/default-time-on-board-filter.component";
import { DefaultTimeOnColumnFilterComponent } from "../default-time-on-column-filter/default-time-on-column-filter.component";
import { TimeOnBoardFormComponent } from "../default-time-on-board-filter/time-on-board-form/time-on-board-form.component";
import { TimeOnColumnFormComponent } from "../default-time-on-column-filter/time-on-column-form/time-on-column-form.component";

const sharedDeclarations = [
  BoardQuickFiltersComponent,
  DefaultMembersFilterComponent,
  DefaultLabelsFilterComponent,
  DefaultColumnsFilterComponent,
  DefaultSwimlanesFilterComponent,
  DefaultVersionsFilterComponent,
  DefaultProjectsFilterComponent,
  DefaultTimeOnBoardFilterComponent,
  TimeOnBoardFormComponent,
  DefaultTimeOnColumnFilterComponent,
  TimeOnColumnFormComponent,
  DefaultStatusesFilterComponent,
  DefaultColorsFilterComponent,
  DefaultQuadsFilterComponent,
  ColumnsFilterListComponent,
  SwimlanesFilterListComponent,
  VersionsFilterListComponent,
  ProjectsFilterListComponent,
  StatusesFilterListComponent,
  LabelsFilterListComponent,
  BoardViewSelectorComponent,
  GroupBySelectorComponent
];

@NgModule({
  imports: [CommonModule, RouterModule, AtlazGuiModule, AppContainersModule, SharedModule],
  declarations: sharedDeclarations,
  exports: sharedDeclarations,
  providers: []
})
export class QuickFiltersModule {}
