import { NgModule } from '@angular/core';
import { BurndownChartPageComponent } from './burndown-chart.component';
import { BurndownChartBodyComponent } from './chart/body/burndown-chart-body.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { BurndownChartWrapper } from './chart/chart-wrapper/burndown-chart-wrapper';
import { ModeViewPickerComponent } from './mode-view-picker/mode-view-picker.component';
import { AtlazGuiModule } from '../atlaz-gui/atlaz-gui.module';

@NgModule({
  imports: [CommonModule, SharedModule, AtlazGuiModule],
  exports: [BurndownChartWrapper, ModeViewPickerComponent],
  declarations: [BurndownChartPageComponent, BurndownChartBodyComponent, BurndownChartWrapper, ModeViewPickerComponent],
  providers: []
})
export class BurndownChartModule {}
