import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'header-info-menu',
  templateUrl: './header-info-menu.component.html',
  styleUrls: ['./header-info-menu.component.scss']
})
export class HeaderInfoMenuComponent {
  @Output() closeMenu = new EventEmitter<boolean>();

  onCloseMenu() {
    this.closeMenu.emit(false);
  }

  onFeatureRequest() {
    if (window['Intercom']) {
      window['Intercom']('showNewMessage');
    }
    this.closeMenu.emit(false);
  }
}
