import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '../../../interfaces';
import { AppState } from '../../../ngrx/state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { UsersCompany } from '../../../interfaces/users-compnay';
import { fromUserCompanies } from '../../../ngrx/reducers/user-company.reducer';
import { filter, publishReplay, refCount } from 'rxjs/operators';
import { isPresent } from '../../../../helpers';
import { ROLE_COMPANY_GUEST } from '../../../permissions/interfaces/roles';

const combineFromFullName = user => (user.firstname ? user.firstname[0] : '') + (user.lastname ? user.lastname[0] : '');

const combineFromEmail = user => {
  const emailLogin = (user.email || '').split('@')[0];
  let result = '';

  if (emailLogin.length === 1) {
    result = emailLogin[0];
  } else {
    result = emailLogin.slice(0, 2);
  }

  return result;
};

@Component({
  selector: 'user-preview',
  templateUrl: './user-preview.component.html',
  styleUrls: ['./user-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserPreviewComponent implements OnInit {
  @Input() user: User;
  @Input() isBoard: boolean;
  @Input() canRemove = false;

  @Output() closePopupEvent = new EventEmitter<User>();
  @Output() removeFromTask = new EventEmitter();

  public userCompany$: Observable<UsersCompany>;
  public ROLE_COMPANY_GUEST = ROLE_COMPANY_GUEST;

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    if (this.user) {
      this.userCompany$ = this._store
        .select(fromUserCompanies.getById(this.user.userCompanyId))
        .pipe(filter(isPresent), publishReplay(1), refCount());
    }
  }

  onClosePopup() {
    this.closePopupEvent.emit(this.user);
  }

  onRemove() {
    this.removeFromTask.emit(this.user.id);
    this.onClosePopup();
  }

  get preview200Url() {
    return this.user && this.user.photo && this.user.photo.previews && this.user.photo.previews.w200
      ? this.user.photo.previews.w200.path
      : '';
  }

  get initials() {
    if (this.user) {
      return this.user.firstname || this.user.lastname ? combineFromFullName(this.user) : combineFromEmail(this.user);
    }
    return '';
  }
}
