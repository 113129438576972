import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {
  transform(value: string, substr, newSubstr): any {
    if (value) {
      return value.replace(substr, newSubstr);
    } else {
      return value;
    }
  }
}
