import { take, filter, map } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AppState } from '../../../../ngrx/state/app-state';
import { Store } from '@ngrx/store';
import { fromRoadmapBoard } from '../../store/roadmap-board.reducer';
import { RoadMapScale } from '../../constants/roadmap.constants';
import { Observable } from 'rxjs';
import * as roadmap from '../../store/roadmap-board.action';
import { GuiStateSetRoadMapZoomMode } from '../../../../ngrx/actions/gui-state-memorized.actions';

const nextValues = {
  [RoadMapScale.Day]: RoadMapScale.Week,
  [RoadMapScale.Week]: RoadMapScale.Month,
  [RoadMapScale.Month]: RoadMapScale.Quarter,
  [RoadMapScale.Quarter]: RoadMapScale.Year
};

const prevValues = {
  [RoadMapScale.Year]: RoadMapScale.Quarter,
  [RoadMapScale.Quarter]: RoadMapScale.Month,
  [RoadMapScale.Week]: RoadMapScale.Day,
  [RoadMapScale.Month]: RoadMapScale.Week
};

const labels = {
  [RoadMapScale.Day]: 'Days',
  [RoadMapScale.Week]: 'Weeks',
  [RoadMapScale.Month]: 'Months',
  [RoadMapScale.Quarter]: 'Quarters',
  [RoadMapScale.Year]: 'Years'
};

@Component({
  selector: 'roadmap-scale-controls',
  templateUrl: './roadmap-scale-controls.component.html',
  styleUrls: ['./roadmap-scale-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoadmapScaleControlsComponent implements OnInit {
  @Input() boardId: number;
  public disabledZoomIn$: Observable<boolean>;
  public disableZoomOut$: Observable<boolean>;
  public label$: Observable<string>;

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this.disabledZoomIn$ = this._store.select(fromRoadmapBoard.getScale).pipe(map(scale => !nextValues[scale]));
    this.disableZoomOut$ = this._store.select(fromRoadmapBoard.getScale).pipe(map(scale => !prevValues[scale]));
    this.label$ = this._store.select(fromRoadmapBoard.getScale).pipe(map(scale => labels[scale]));
  }

  onZoomOut() {
    this._store
      .select(fromRoadmapBoard.getScale)
      .pipe(take(1), filter(scale => !!prevValues[scale]))
      .subscribe(scale => {
        this._store.dispatch(new roadmap.SetScale(prevValues[scale]));
        this._store.dispatch(new GuiStateSetRoadMapZoomMode({ id: this.boardId, scale: prevValues[scale] }));
      });
  }
  onZoomIn() {
    this._store
      .select(fromRoadmapBoard.getScale)
      .pipe(take(1), filter(scale => !!nextValues[scale]))
      .subscribe(scale => {
        this._store.dispatch(new roadmap.SetScale(nextValues[scale]));
        this._store.dispatch(new GuiStateSetRoadMapZoomMode({ id: this.boardId, scale: nextValues[scale] }));
      });
  }
}
