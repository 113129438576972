
import {never as observableNever,  Observable ,  BehaviorSubject ,  Subject ,  Subscription } from 'rxjs';

import {takeUntil, catchError} from 'rxjs/operators';
import { Injectable, Input, OnDestroy } from '@angular/core';
import { AtlazApiV2Service } from '../atlaz-api/v2/atlaz-api-v2.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state/app-state';
import { JsonApiSingeModelResponse, jsonApiToEntityState } from '../app/web-socket/http-response';
import { GetCompleteAction } from '../../../ngrx/actions/root.action';

@Injectable()
export class PaginationLoaderService implements OnDestroy {
  @Input()
  set controller(contorller) {
    if (contorller !== this._controller) {
      this.stop$.next();
    }
    this._controller = contorller;
  }

  @Input()
  set queryParams(queryParams) {
    this.stop$.next();
    this._httpParams = Object.assign({}, queryParams);
  }

  get queryParams() {
    return this._httpParams;
  }

  @Input()
  set limit(limit) {
    if (limit !== this._limit) {
      this.stop$.next();
    }
    this._limit = limit;
  }

  @Input()
  set loadAll(loadAll) {
    if (loadAll !== this._loadAll) {
      this.stop$.next();
    }
    this._loadAll = loadAll;
  }

  public pending$ = new BehaviorSubject(true);
  public hasMore$ = new BehaviorSubject(true);

  private _controller;
  private _httpParams;
  private _loadAll = false;
  private _limit = 100;
  private _offset = 0;
  private _localPending = false;
  private sub: Subscription;

  private stop$ = new Subject();

  constructor(private _atlazApi: AtlazApiV2Service, private _store: Store<AppState>) {
    this.sub = this.stop$.subscribe(() => {
      this._offset = 0;
      this._localPending = false;
    });
  }

  loadMore() {
    if (!this._controller || !this._httpParams) {
      console.warn('controller, httpParams both are required');
      return;
    }
    if (this._localPending) {
      console.warn('request is already in progress');
      return;
    }

    this._localPending = true;
    this.pending$.next(true);
    const httpParams = Object.assign({}, this._httpParams);
    httpParams.limit = this._limit + 1;
    if (this._offset) {
      httpParams.offset = this._offset;
    }

    this.sub = this._atlazApi
      .get(this._controller, httpParams).pipe(
      catchError(error => {
        this._localPending = false;
        this._loadAll = false;
        this.pending$.next(false);
        this.hasMore$.next(false);
        console.warn('EFFECT PAGINATION LOADING: ', this._controller);
        console.log('PAYLOAD: ', httpParams);
        console.error(error);
        return observableNever();
      }),
      takeUntil(this.stop$),)
      .subscribe((resp: JsonApiSingeModelResponse<any>) => {
        this._localPending = false;
        const count = resp.data.length;

        if (!count) {
          this.hasMore$.next(false);
          this.pending$.next(false);
          return;
        }

        this._offset += this._limit;

        const entities = jsonApiToEntityState(resp);
        this._store.dispatch(new GetCompleteAction(entities));

        if (httpParams.limit === count) {
          resp = Object.assign({}, resp);
          resp.data = resp.data.slice(0, -1);
          this.hasMore$.next(true);
        } else {
          this.hasMore$.next(false);
        }

        if (this._loadAll && this.hasMore$.getValue()) {
          this.loadMore();
        } else {
          this.pending$.next(false);
        }
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.stop$.next();
  }
}
