import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Board, Task } from '../../../../interfaces/';
import { AppUrls } from '../../../../app-urls';
import { Router } from '@angular/router';
import { CompanyService } from '../../../../shared/services/app/company.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'harvest-widget',
  templateUrl: './harvest-widget.component.html',
  styleUrls: ['./harvest-widget.component.scss']
})
export class HarvestWidgetComponent implements OnInit, OnDestroy {
  @Input() task: Task;
  @Input() board: Board;
  @Output() close = new EventEmitter();
  public iFrameSrc: SafeResourceUrl;
  harvestUrl: string;
  companyName: string;
  companyUrl: string;
  listener = this.listenHarvestCancel.bind(this);
  constructor(private _companyService: CompanyService, private _router: Router, private _sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.companyName = this._companyService.name;
    this.companyUrl = this._companyService.getPortalUrl();
    this.harvestUrl =
      'https://platform.harvestapp.com/platform/timer?app_name=' +
      encodeURIComponent(this.companyName) +
      '&closable=true&permalink=' +
      encodeURIComponent(AppUrls.getDirectTaskUrl(this._router, this.task.id, this.task.board)) +
      '&external_item_id=' +
      this.task.id +
      '&external_item_name=' +
      encodeURIComponent(this.task.title) +
      '&external_group_id=' +
      this.board.id +
      '&external_group_name=' +
      encodeURIComponent(this.board.name);
    this.iFrameSrc = this._sanitizer.bypassSecurityTrustResourceUrl(this.harvestUrl);
    window.addEventListener('message', this.listener);
  }

  onClose() {
    this.close.emit();
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.listener);
  }

  private listenHarvestCancel(event) {
    console.log(event.data.type);
    if (event.origin === 'https://platform.harvestapp.com') {
      if (event.data.type === 'frame:close') {
        this.onClose();
      } else if (event.data.type === 'frame:resize') {
        document.querySelector('iframe').style.height = event.data.value + 'px';
      }
    }
  }
}
