import { Injectable } from '@angular/core';
import { isProductionMode } from '../../../../../environments/helper';

interface Instance {
  push: Function;
}

const fakeInstance = {
  push: () => null
};

const debugInstance = {
  push: event => console.log('GoogleAnalyticsService', event)
};

@Injectable()
export class GoogleAnalyticsService {
  private instance: Instance;

  constructor() {
    if (isProductionMode()) {
      if (window['dataLayer']) {
        this.instance = window['dataLayer'];
      } else {
        console.warn('dataLayer is missed');
        this.instance = fakeInstance;
      }
    } else {
      this.instance = debugInstance;
    }
  }

  get getInstance() {
    return this.instance;
  }
}
