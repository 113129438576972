import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ScrollbarComponent } from './scrollbar/scrollbar.component';
import { CustomScrollsComponent } from './custom-scrolls/custom-scrolls.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [ScrollbarComponent, CustomScrollsComponent],
  exports: [CustomScrollsComponent]
})
export class CustomScrollsModule {}
