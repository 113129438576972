import { Action } from '../../../../../ngrx/actions/unsafe-action';

export const GET_INDEX = '[slack-company.actions] get slack company info';
export const GET_INDEX_COMPLETE = '[slack-company.actions] received slack companies';
export const REMOVE_ALL = '[slack-company.actions] remove all companies from server';

export class GetIndex implements Action {
  readonly type = GET_INDEX;

  constructor(public payload?: any) {}
}

export class GetIndexCompleted implements Action {
  readonly type = GET_INDEX_COMPLETE;

  constructor(public payload: any) {}
}

export class RemoveAll implements Action {
  readonly type = REMOVE_ALL;

  constructor(public payload?: any) {}
}

export const GET = '[slack-company.actions] get slack company by id';
export class Get implements Action {
  readonly type = GET;

  constructor(public payload: number) {}
}

export type Actions = GetIndex | Get | GetIndexCompleted | RemoveAll;
