import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Board } from '../../../interfaces';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../../shared/services/form-v2.service';
import { BOARD_PL, ScaleType, ScaleTypeLabel, QuadNames } from '../../../constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observer } from 'rxjs/Observer';
import { HandleResponseAction } from '../../../ngrx/actions/root.action';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Component({
  selector: 'priority-chart-scales-settings',
  templateUrl: './priority-chart-scales-settings.component.html',
  styleUrls: ['./priority-chart-scales-settings.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriorityChartScalesSettingsComponent implements OnInit {
  @Input() board: Board;

  public QuadNames = QuadNames;
  public ScaleType = ScaleType;
  public ScaleTypeLabel = ScaleTypeLabel;
  public isEditing$ = new BehaviorSubject(false);
  public form: FormGroup;
  public popupTitle: string;
  public editingField: string;
  public messages = { maxlength: 'Maximum length of 12 symbols is exceed' };
  public quadMessages = { maxlength: 'Maximum length of 30 symbols is exceed' };

  private formObserver: Observer<any> = {
    next: response => {
      this._store.dispatch(new HandleResponseAction(response));
      this.onClose();
    },

    error: error => {
      console.log(error, 'error on PriorityChartScalesSettingsComponent');
    },

    complete: () => {}
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.edit,
    entityToEdit: BOARD_PL,
    formObserver: this.formObserver,
    prepareFormValue: formValue => {
      return {
        id: formValue['id'],
        backlogScoreXLabel: formValue['backlogScoreXLabel'],
        backlogScoreYLabel: formValue['backlogScoreYLabel'],
        backlogScoreXType: formValue['backlogScoreXType'],
        backlogScoreYType: formValue['backlogScoreYType'],
        kvValues: [
          formValue['kvValues0'] || this.QuadNames[0],
          formValue['kvValues1'] || this.QuadNames[1],
          formValue['kvValues2'] || this.QuadNames[2],
          formValue['kvValues3'] || this.QuadNames[3]
        ]
      };
    }
  };

  constructor(private _fb: FormBuilder, public _formService: FormV2Service, private _store: Store<AppState>) {}

  ngOnInit() {
    this.form = this._fb.group({
      id: [this.board.id],
      backlogScoreXLabel: [
        this.board.backlogScoreXLabel,
        Validators.compose([Validators.required, Validators.maxLength(12)])
      ],
      backlogScoreYLabel: [
        this.board.backlogScoreYLabel,
        Validators.compose([Validators.required, Validators.maxLength(12)])
      ],
      backlogScoreXType: [this.board.backlogScoreXType],
      backlogScoreYType: [this.board.backlogScoreYType],
      kvValues0: [
        this.board.kvValues[0] || this.QuadNames[0],
        Validators.compose([Validators.required, Validators.maxLength(30)])
      ],
      kvValues1: [
        this.board.kvValues[1] || this.QuadNames[1],
        Validators.compose([Validators.required, Validators.maxLength(30)])
      ],
      kvValues2: [
        this.board.kvValues[2] || this.QuadNames[2],
        Validators.compose([Validators.required, Validators.maxLength(30)])
      ],
      kvValues3: [
        this.board.kvValues[3] || this.QuadNames[3],
        Validators.compose([Validators.required, Validators.maxLength(30)])
      ]
    });
    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  onSubmitScales() {
    this.form.get('backlogScoreXLabel').patchValue(this.form.get('backlogScoreXLabel').value.trim());
    this.form.get('backlogScoreYLabel').patchValue(this.form.get('backlogScoreYLabel').value.trim());
    this.form.markAsTouched();
    if (this.form.invalid) {
      return;
    }
    this._formService.submit();
  }

  onSubmitQuads() {
    this.form.get('kvValues0').patchValue(this.form.get('kvValues0').value.trim());
    this.form.get('kvValues1').patchValue(this.form.get('kvValues1').value.trim());
    this.form.get('kvValues2').patchValue(this.form.get('kvValues2').value.trim());
    this.form.get('kvValues3').patchValue(this.form.get('kvValues3').value.trim());
    this.form.markAsTouched();
    if (this.form.invalid) {
      return;
    }
    this._formService.submit();
  }

  onClose() {
    this.isEditing$.next(false);
    this.form.get('backlogScoreXLabel').patchValue(
      this.form
        .get('backlogScoreXLabel')
        .value.trim()
        .slice(0, 12)
    );
    this.form.get('backlogScoreYLabel').patchValue(
      this.form
        .get('backlogScoreYLabel')
        .value.trim()
        .slice(0, 12)
    );
  }

  onOpenPopUp(field) {
    this.editingField = field;
    switch (field) {
      case 'backlogScoreXLabel': {
        this.popupTitle = 'Edit horizontal axis name';
        break;
      }
      case 'backlogScoreYLabel': {
        this.popupTitle = 'Edit vertical axis name';
        break;
      }
      case 'backlogScoreXType': {
        this.popupTitle = 'Change horizontal axis scale type';
        break;
      }
      case 'backlogScoreYType': {
        this.popupTitle = 'Change vertical axis scale type';
        break;
      }
      case 'kvValues': {
        this.popupTitle = 'Set quadrant names';
        break;
      }
    }
    this.isEditing$.next(true);
  }
}
