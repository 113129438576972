
import {interval as observableInterval, fromEvent as observableFromEvent,  Observable ,  Subject } from 'rxjs';

import {takeUntil, map, distinctUntilChanged, filter, mergeMap, debounceTime, withLatestFrom, tap} from 'rxjs/operators';
import { ElementRef, Injectable, OnDestroy } from '@angular/core';

interface AllowedAxis {
  vertical: boolean;
  horizontal: boolean;
}

@Injectable()
export class ScrollService {
  private MAX_SCROLL_PIXEL: number = 12 * 2;
  private AVR_SCROLL_PIXEL: number = 6 * 2;
  private MIN_SCROLL_PIXEL: number = 3 * 2;

  private scrollDirections: Array<ElementRef>;
  private vScrollContainer: HTMLElement;
  private hScrollContainer: HTMLElement;

  public checkScrollEvents$ = new Subject();

  fireCheck() {
    this.checkScrollEvents$.next();
  }

  initSettings(scrollDirections: Array<ElementRef>, vScrollContainer: HTMLElement, hScrollContainer: HTMLElement) {
    this.scrollDirections = scrollDirections;
    this.vScrollContainer = vScrollContainer;
    this.hScrollContainer = hScrollContainer;
  }

  public scrollByDirections(allowedAxis: AllowedAxis = { vertical: true, horizontal: true }): Array<Observable<any>> {
    const axisStreams = this.scrollDirections.map((axis, index) => {
      const doScrollMethod: Function = this.getDoScrollMethods(allowedAxis)[index];
      const ruleScrollMethod: Function = this.getRuleScrollMethods(allowedAxis)[index];

      return this.createAxisStream(axis, doScrollMethod, ruleScrollMethod);
    });

    return axisStreams;
  }

  public createAxisStream(axis: ElementRef, doScrollMethod: Function, ruleScrollMethod: Function): Observable<any> {
    const mouseMoveStream: Observable<boolean> = observableFromEvent(document, 'mousemove').pipe(
      map(mouseEvent => ruleScrollMethod.apply(this, [axis, mouseEvent])),
      distinctUntilChanged((a, b) => a[0] === b[0]),);

    const isActive = mouseMoveStream.pipe(filter(allowScrolling => allowScrolling[0] === true),debounceTime(200),);

    const isInActive = mouseMoveStream.pipe(filter(allowScrolling => allowScrolling[0] === false));

    const scrollStreamInterval = observableInterval(1).pipe(
      withLatestFrom(mouseMoveStream, (_, a) => a[1]),
      tap(speed => doScrollMethod.apply(this, [speed])),);

    return isActive.pipe(mergeMap(_ => scrollStreamInterval.pipe(takeUntil(isInActive))));
  }

  private getRuleScrollMethods(allowedAxis: AllowedAxis): Array<Function> {
    return [this.ruleScrollTop, this.ruleScrollRight, this.ruleScrollBottom, this.ruleScrollLeft];
  }

  private ruleScrollTop(axis, mouseEvent): [boolean, number] {
    const rect = axis.nativeElement.getBoundingClientRect();
    const speed = this.scrollSpeed(mouseEvent.clientY, rect.bottom, rect.height);
    return [mouseEvent.clientY <= rect.bottom, speed];
  }

  private ruleScrollRight(axis, mouseEvent): [boolean, number] {
    const rect = axis.nativeElement.getBoundingClientRect();
    const speed = this.scrollSpeed(mouseEvent.clientX, rect.left, rect.width);
    return [mouseEvent.clientX >= rect.left, speed];
  }

  private ruleScrollBottom(axis, mouseEvent): [boolean, number] {
    const rect = axis.nativeElement.getBoundingClientRect();
    const speed = this.scrollSpeed(mouseEvent.clientY, rect.top, rect.height);

    return [mouseEvent.clientY >= rect.top, speed];
  }

  private ruleScrollLeft(axis, mouseEvent): [boolean, number] {
    const rect = axis.nativeElement.getBoundingClientRect();
    const speed = this.scrollSpeed(mouseEvent.clientX, rect.right, rect.width);

    return [mouseEvent.clientX <= rect.right, speed];
  }

  private getDoScrollMethods(allowedAxis: AllowedAxis): Array<Function> {
    return [this.scrollTop, this.scrollRight, this.scrollBottom, this.scrollLeft];
  }

  private scrollTop(speed) {
    this.vScrollContainer.scrollTop -= speed;
  }

  private scrollRight(speed) {
    this.hScrollContainer.scrollLeft += speed;
  }

  private scrollBottom(speed) {
    this.vScrollContainer.scrollTop += speed;
  }

  private scrollLeft(speed) {
    this.hScrollContainer.scrollLeft -= speed;
  }

  private scrollSpeed(mouseEvent, side, volume): number {
    if (Math.abs(mouseEvent - side) <= volume / 2) {
      return this.MIN_SCROLL_PIXEL;
    } else if (Math.abs(mouseEvent - side) >= volume / 2 && Math.abs(mouseEvent - side) <= volume) {
      return this.AVR_SCROLL_PIXEL;
    }

    return this.MAX_SCROLL_PIXEL;
  }
}
