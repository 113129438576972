import { Action } from './unsafe-action';
import { labelAction } from '../functions/action';
import { CUSTOM_FIELD } from '../../constants';

export const CustomFieldActionTypes = {
  MOVE: labelAction(CUSTOM_FIELD, 'MOVE'),
  DELETE: labelAction(CUSTOM_FIELD, 'DELETE'),
  DELETE_COMPLETE: labelAction(CUSTOM_FIELD, 'DELETE COMPLETE')
};

export class CustomFieldMoveAction implements Action {
  type = CustomFieldActionTypes.MOVE;

  constructor(public payload: { id: number; insertBefore?: number; insertAfter?: number }) {}
}

export class CustomFieldDeleteAction implements Action {
  type = CustomFieldActionTypes.DELETE;

  constructor(public payload: number) {}
}

export class CustomFieldDeleteCompleteAction implements Action {
  type = CustomFieldActionTypes.DELETE_COMPLETE;

  constructor(public payload: number) {}
}

export type CustomFieldActions = CustomFieldDeleteAction | CustomFieldMoveAction;
