import { DATE_INTERVAL_TYPE } from '../app/constants/date-interval-types';
import { DateInterval } from '../app/interfaces/date-interval';

function calculateCurrentTime(): number {
  const currentDate = new Date();

  currentDate.setHours(0, 0, 0, 0);
  return Math.floor(currentDate.getTime() / 1000);
}

function calculatePreviousTime(): number {
  const previousDate = new Date();

  previousDate.setMonth(new Date().getMonth() - 1);
  previousDate.setDate(1);
  previousDate.setHours(0, 0, 0, 0);

  return Math.floor(previousDate.getTime() / 1000);
}

function calculateYesterdayTime(): number {
  const yesterdayDate = new Date();

  yesterdayDate.setDate(new Date().getDate() - 1);
  yesterdayDate.setHours(0, 0, 0, 0);

  return Math.floor(yesterdayDate.getTime() / 1000);
}

function calculateMonthTime(): number {
  const monthDate = new Date();

  monthDate.setDate(1);
  monthDate.setHours(0, 0, 0, 0);

  return Math.floor(monthDate.getTime() / 1000);
}

export function filterValueForDateInterval(dateStart: number, dateEnd: number) {
  return value => {
    const startFlag: boolean = value.createdAt >= dateStart;
    const endFlag: boolean = dateEnd === null || value.createdAt < dateEnd;
    return startFlag && endFlag;
  };
}

export const DATE_INTERVAL: DateInterval[] = [
  {
    name: DATE_INTERVAL_TYPE.DATE_INTERVAL_TODAY,
    dateStart: calculateCurrentTime(),
    dateEnd: null
  },
  {
    name: DATE_INTERVAL_TYPE.DATE_INTERVAL_YESTERDAY,
    dateStart: calculateYesterdayTime(),
    dateEnd: calculateCurrentTime()
  },
  {
    name: DATE_INTERVAL_TYPE.DATE_INTERVAL_THIS_MONTH,
    dateStart: calculateMonthTime(),
    dateEnd: calculateYesterdayTime()
  },
  {
    name: DATE_INTERVAL_TYPE.DATE_INTERVAL_LAST_MONTH,
    dateStart: calculatePreviousTime(),
    dateEnd: calculateMonthTime()
  }
];

export function getTimestampDaysList(interval: 'today' | 'today_end' | 'yesterday' | 'last_week' | 'last_month') {
  const todaysDate = new Date();
  todaysDate.setHours(0, 0, 0);

  switch (interval) {
    case 'today': {
      return Math.floor(todaysDate.getTime() / 1000);
    }
    case 'today_end': {
      const todaysDateEnd = new Date();
      todaysDateEnd.setHours(23, 59, 59);
      return Math.floor(todaysDateEnd.getTime() / 1000);
    }
    case 'yesterday': {
      return Math.floor(todaysDate.getTime() / 1000 - 3600 * 24);
    }
    case 'last_week': {
      return Math.floor(todaysDate.getTime() / 1000 - 3600 * 24 * 7);
    }
    case 'last_month': {
      return Math.floor(todaysDate.getTime() / 1000 - 3600 * 24 * 30);
    }
    default: {
      return Math.floor(todaysDate.getTime() / 1000);
    }
  }
}

export function getDateInterval(interval: 'today' | 'yesterday' | 'last_week' | 'last_month') {
  let result: [number, number];

  switch (interval) {
    case 'today':
      result = [getTimestampDaysList('today'), getTimestampDaysList('today_end')];
      break;
    case 'yesterday':
      result = [getTimestampDaysList('yesterday'), getTimestampDaysList('today')];
      break;
    case 'last_week':
      result = [getTimestampDaysList('last_week'), getTimestampDaysList('today_end')];
      break;
    case 'last_month':
      result = [getTimestampDaysList('last_month'), getTimestampDaysList('today_end')];
      break;
  }
  return result;
}
