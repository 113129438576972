
import {fromEvent as observableFromEvent,  Subscription ,  Observable } from 'rxjs';
import { AfterViewInit, Directive, ElementRef, Input, NgZone, OnDestroy } from '@angular/core';
import { getScrollDelta, mouseWheelEventName } from '../../../helpers/event';
import { isDesktopSafari } from '../../../helpers/platform';
import { ScrollService } from '../dragula/scroll.service';

@Directive({
  selector: '[syncHeaderScrollPosition]'
})
export class SyncHeaderScrollPositionDirective implements AfterViewInit, OnDestroy {
  @Input() scrollSourceRef;
  private subs: Subscription[] = [];

  constructor(private elementRef: ElementRef, private _zone: NgZone, private _scrollService: ScrollService) {}

  ngAfterViewInit() {
    this._zone.runOutsideAngular(() => {
      let sub;
      sub = observableFromEvent(this.scrollSourceRef, mouseWheelEventName).subscribe((e: MouseWheelEvent) => {
        const { deltaX, deltaY } = getScrollDelta(e);
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        this.scrollSourceRef.scrollLeft += deltaX;
        this.scrollSourceRef.scrollTop += deltaY;
        this.elementRef.nativeElement.scrollLeft = this.scrollSourceRef.scrollLeft;
        // safari render more than one time per animation frame
        // safari fire scroll events with delay after event had been performed
        if (isDesktopSafari) {
          this._scrollService.fireCheck();
        }
      });
      this.subs.push(sub);

      if (isDesktopSafari) {
        sub = this._scrollService.checkScrollEvents$.subscribe(() => {
          this.elementRef.nativeElement.scrollLeft = this.scrollSourceRef.scrollLeft;
        });
        this.subs.push(sub);
      }

      sub = observableFromEvent(this.scrollSourceRef, 'scroll').subscribe((e: any) => {
        this.elementRef.nativeElement.scrollLeft = this.scrollSourceRef.scrollLeft;
        this._scrollService.fireCheck();
      });
      this.subs.push(sub);
    });
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
