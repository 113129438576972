<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div>
    <form-errors></form-errors>
  </div>
  <div>
    <label for="sprint-name">Name</label>
    <input id="sprint-name" type="text" [focus]="true" checkTouch formControlName="sprintName">
    <help-block [control]="form.get('sprintName')" [messages]="customErrorMessages"></help-block>
  </div>
  <div class="divider"></div>
  <div *ngIf="board.sprintStartDate">
    <div class="label">
      Start Date
    </div>
    <div class="date">
      {{board.sprintStartDate | toDate }}
    </div>
  </div>
  <div class="divider"></div>

  <div>
    <label for="sprint-end-date">End date</label>
    <a-datetime-picker id="sprint-end-date" checkTouch formControlName="sprintEndDate"></a-datetime-picker>
    <help-block [control]="form.get('sprintEndDate')" [messages]="customErrorMessages"></help-block>
  </div>
  <div class="divider"></div>

  <div>
    <label for="sprint-estimation"><icon-info a-tooltip="You can estimate tasks using Story Points and Time - both separately and together"></icon-info>Estimation</label>
    <select id="sprint-estimation" formControlName="estimatedBy" checkTouch>
      <option [value]="estimationType.storyPoints">Story Points</option>
      <option [value]="estimationType.hours">Hours</option>
      <option [value]="estimationType.any">Story Points and Hours</option>
    </select>
  </div>

  <div class="form-submit-buttons">
    <button a-button class="btn_primary" (click)="onSubmit()" [pending]="_formService.isPending$ | async">Save Changes</button>
  </div>
</form>
