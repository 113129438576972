
import {distinctUntilChanged, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BacklogTooltip } from './custom-types/tooltip-data';
import { TaskDragging } from './custom-types/task-dragging';
import { toBoolean } from '../../../helpers';

@Injectable()
export class BacklogChartService {
  readonly groupTaskDialog$ = new BehaviorSubject<BacklogTooltip>(null);

  readonly backlogDraggingTask$ = new BehaviorSubject<TaskDragging>(null);
  readonly draggingTaskId$ = new BehaviorSubject<number>(0);

  readonly lastCreatedByMeTaskId$ = new BehaviorSubject<number>(0);

  get taskWasDragged$() {
    return this.backlogDraggingTask$.pipe(map(toBoolean));
  }

  get isOpenGroupDialog$() {
    return this.groupTaskDialog$.pipe(map(toBoolean),distinctUntilChanged(),);
  }

  public closeDialog() {
    this.groupTaskDialog$.next(null);
  }

  public getNewDragHistory(isInside: boolean) {
    const draggingTask = this.backlogDraggingTask$.getValue();
    if (!draggingTask) {
      return [isInside];
    }

    const dragHistory = this.backlogDraggingTask$.getValue()['isInsideInChartHistory'] || [];

    if (dragHistory[dragHistory.length - 1] !== isInside) {
      return [...dragHistory, isInside];
    }

    return dragHistory;
  }
}
