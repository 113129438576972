import {
  BehaviorSubject,
  combineLatest as observableCombineLatest,
  Observable,
  of as observableOf,
  Subscription
} from 'rxjs';

import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  pluck,
  publishReplay,
  refCount,
  skip,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { AppUrls } from '../../../app-urls';
import { Board, Column, Task, User } from '../../../interfaces';
import {
  allPass,
  getFromLocalStorage,
  hasScore,
  isEquals,
  isPresent,
  setToLocalStorage,
  trackById
} from '../../../../helpers';

import { getTaskById, getTasksByUsersFilter, inBoard, isActiveTask } from '../../../ngrx/reducers/task.reducer';
import { AppState, RIGHT_MENU } from '../../../ngrx/state';
import { getColumnEntities } from '../../../ngrx/reducers/column.reducer';
import { BacklogChartService } from '../backlog-chart.service';
import { getBacklogTaskKey, sortOriginOrder, taskSorter, taskSorterFactory } from '../../helpers';
import { fromBoards, getBoardById } from '../../../ngrx/reducers/board.reducer';

import * as d3 from 'd3';
import { BacklogChartComponent } from '../chart/backlog-chart.component';

import { SegmentService } from '../../../atlaz-bnp/services/intergations/segment/segment.service';
import { getSwimlaneEntities } from '../../../ngrx/reducers/swimlane.reducer';
import { TaskEditAction } from '../../../ngrx/actions/task.actions';
import { BoardQuickFiltersComponent } from '../../board-quick-filters/board-quick-filters.component';
import { UsersCacheService } from '@atlaz/core/services/users-cache.service';
import { TopMenuContainerComponent } from '../../../containers/header/top-menu-container/top-menu-container.component';
import { SelectEntityAction } from '../../../ngrx/actions/root.action';
import { BOARD_PL, BoardFirstOpenType, ScaleType, ScoringType } from '../../../constants';
import { BoardFilterInfo } from '../../../interfaces/board';
import { getVisibleTaskAxisScore } from '../../../../helpers/task';
import { BoardEditAction } from '../../../ngrx/actions/board.actions';
import {
  GuiStateOpenBoardTypeSelectorAction,
  GuiStateSetBoardViewMode
} from '../../../ngrx/actions/gui-state-memorized.actions';
import { BoardViewMode, fromGuiState } from '../../../ngrx/reducers/gui-state-memorized.reducer';
import { RouterNavigateService } from '../../../shared/services/router-navigate.service';
import { AuthService } from '../../../shared/services/app/auth.service';
import { ComponentToggleAction } from '../../../ngrx/actions/component.actions';
import { PermissionsService } from '../../../permissions/permissions.service';

interface SortOrderType {
  name: string;
  value: string;
  sortFn: taskSorter | taskSorterFactory;
}

const byTitleSorter = (a, b) => {
  if (a['title'] > b['title']) {
    return 1;
  } else if (a['title'] < b['title']) {
    return -1;
  } else {
    return 0;
  }
};

const sortBacklogTask = (field, order) => tasks =>
  tasks.slice().sort((a, b) => {
    const direction = order === 'asc' ? 1 : -1;
    let result = 0;
    const aFieldName = typeof a[field] === 'string' ? a[field].trim() : a[field];
    const bFieldName = typeof b[field] === 'string' ? b[field].trim() : b[field];

    if (aFieldName < bFieldName && aFieldName !== 0) {
      result = -1 * direction;
    } else if (aFieldName > bFieldName && aFieldName !== 0 && bFieldName !== 0) {
      result = direction;
    } else if (aFieldName === 0) {
      result = 1;
    } else if (bFieldName === 0) {
      result = -1;
    } else {
      result = byTitleSorter(a, b);
    }
    return result;
  });

const sortNotRated = order => tasks =>
  tasks.slice().sort((a, b) => {
    const direction = order === 'asc' ? 1 : -1;
    let result = 0;
    const aFieldName = +a['backlogScoreY'] + +a['backlogScoreX'];
    const bFieldName = +b['backlogScoreY'] + +b['backlogScoreX'];

    switch (true) {
      // first A when backlogScoreY and Efforts are equal 0
      case aFieldName === 0 && bFieldName > 0: {
        result = -1;
        break;
      }
      // first B when backlogScoreY and Efforts are equal 0
      case aFieldName > 0 && bFieldName === 0: {
        result = 1;
        break;
      }
      // sort by title when both not scored
      case aFieldName === 0 && bFieldName === 0: {
        result = byTitleSorter(a, b);
        break;
      }
      // first A when A have only value and B have efforts
      case a['backlogScoreY'] > 0 && +a['backlogScoreX'] === 0 && b['backlogScoreX'] > 0: {
        result = -1;
        break;
      }

      // first B when B have only value and A have efforts
      case +a['backlogScoreX'] > 0 && b['backlogScoreY'] > 0 && b['backlogScoreX'] === 0: {
        result = 1;
        break;
      }

      // first element that have minimal Value when both have only backlogScoreY. if equal sort by title
      case a['backlogScoreY'] > 0 && +a['backlogScoreX'] === 0 && b['backlogScoreY'] > 0 && b['backlogScoreX'] === 0: {
        result = +a['backlogScoreY'] - +b['backlogScoreY'];
        result = result === 0 ? byTitleSorter(a, b) : result;
        break;
      }

      // first element that have minimal Efforts when both have only backlogScoreX. if equal sort by title
      case a['backlogScoreX'] > 0 && +a['backlogScoreY'] === 0 && b['backlogScoreX'] > 0 && b['backlogScoreY'] === 0: {
        result = a['backlogScoreX'] - b['backlogScoreX'];
        result = result === 0 ? byTitleSorter(a, b) : result;
        break;
      }

      // first A when A have only effort and B have efforts and value
      case +a['backlogScoreX'] > 0 && +a['backlogScoreY'] === 0 && bFieldName > 0: {
        result = -1;
        break;
      }

      // first B when B have only effort and A have efforts and value
      case aFieldName > 0 && +b['backlogScoreX'] > 0 && +b['backlogScoreY'] === 0: {
        result = 1;
        break;
      }

      // sort by backlogScoreY
      default: {
        result = +a['backlogScoreY'] - +b['backlogScoreY'];
        result = result === 0 ? byTitleSorter(a, b) : result;
        break;
      }
    }

    return result * direction;
  });

const sortOrderTypes: SortOrderType[] = [
  { name: 'original', value: 'Original Order', sortFn: sortOriginOrder },
  { name: 'newfirst', value: 'New First', sortFn: sortBacklogTask('id', 'desc') },
  { name: 'lvalue', value: 'Low Value', sortFn: sortBacklogTask('backlogScoreY', 'asc') },
  { name: 'hvalue', value: 'High Value', sortFn: sortBacklogTask('backlogScoreY', 'desc') },
  { name: 'lefforts', value: 'Low Efforts', sortFn: sortBacklogTask('backlogScoreX', 'asc') },
  { name: 'hefforts', value: 'High Efforts', sortFn: sortBacklogTask('backlogScoreX', 'desc') },
  { name: 'norate', value: 'Not Rated', sortFn: sortNotRated('asc') }
];

const groupTasks = (tasks): { scored: Task[]; noscored: Task[]; groupedByEffortValue: Map<string, Task[]> } => {
  return tasks.reduce(
    (acc, task) => {
      if (hasScore(task)) {
        const groupKey = getBacklogTaskKey(task);
        acc.scored.push(task);
        acc.groupedByEffortValue.get(groupKey)
          ? acc.groupedByEffortValue.get(groupKey).push(task)
          : acc.groupedByEffortValue.set(groupKey, [task]);
      } else {
        acc.noscored.push(task);
      }
      return acc;
    },
    { scored: [], noscored: [], groupedByEffortValue: new Map() }
  );
};

const getIdsFromColumns = (columns: Column[]) => {
  return columns.reduce((ids, column) => {
    if (column.subColumnsIds && column.subColumnsIds.length > 0) {
      ids.push(...column.subColumnsIds);
    } else {
      ids.push(column.id);
    }
    return ids;
  }, []);
};

const SORT_TYPE = 'backlogSelectedSortOrderType';
const getSortType = () => {
  const value = getFromLocalStorage(SORT_TYPE);
  return !!value ? sortOrderTypes.find(item => item.name === value) || sortOrderTypes[0] : sortOrderTypes[0];
};

const COLUMN_FILTER = 'backlogColumnFilter';
const getColumnFilter = () => {
  const value = getFromLocalStorage(COLUMN_FILTER);
  return !!value ? value.split(',') : [];
};

@Component({
  templateUrl: './backlog-chart-page.component.html',
  styleUrls: ['./backlog-chart-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BacklogChartPageComponent implements OnInit, OnDestroy {
  @ViewChild(BacklogChartComponent) backlogChartComp: BacklogChartComponent;
  @ViewChild('topMenu') topMenu: TopMenuContainerComponent;
  @ViewChildren('zoomButton') zoomButtons: QueryList<ElementRef>;

  public getVisibleTaskAxisScore = getVisibleTaskAxisScore;
  public activeUser$: Observable<User> = this._authService.activeUser$;
  public listItemDragProps = {
    filter: () => {
      return +d3.event.target.getAttribute('draggable') > 0;
    },
    container: () => this.backlogChartComp.svgChart.nativeElement
  };

  public formbacklogScoreX = 0;
  public formbacklogScoreY = 0;

  public trackById = trackById;
  public appUrls = AppUrls;
  public isAddTaskVisible = false;
  public isFilterOrderVisible = false;
  public sortOrderTypes$: Observable<SortOrderType[]>;

  public boardId$: Observable<number>;
  public boardInfo$: Observable<BoardFilterInfo>;
  public board$: Observable<Board>;
  public boardTasks$: Observable<Task[]>;
  public tasksByBoard$: Observable<Task[]>;

  public noscoredTasks$: Observable<Task[]>;
  public scoredTasks$: Observable<Task[]>;
  public groupByEffortValue$: Observable<Map<string, Task[]>>;

  public tasksForTooltip$: Observable<Task[]>;
  public isGroupedTooltip$: Observable<number>;

  public subs: Subscription[] = [];
  public boardName$: Observable<string>;
  public scoreXLabel$: Observable<string>;
  public scoreYLabel$: Observable<string>;
  public scoreXType$: Observable<ScaleType>;
  public scoreYType$: Observable<ScaleType>;
  public sortType$ = new BehaviorSubject(getSortType());
  public isVisibleTooltip$: Observable<boolean>;
  public containerTop;
  public isShowHelpText = false;
  public axisXType: ScaleType;
  public axisYType: ScaleType;
  public isNotGuest$: Observable<boolean>;

  get windowWidth() {
    return window.innerWidth;
  }

  get windowHeight() {
    return window.innerHeight;
  }

  /**
   * to prevent from initial crash when button hasn't created and hasn't been touched yet.
   * @returns {QueryList<ElementRef> | Array}
   */
  get getZoomButtons() {
    return this.zoomButtons || [];
  }

  constructor(
    private _route: ActivatedRoute,
    private _store: Store<AppState>,
    private _segment: SegmentService,
    public _backlogChart: BacklogChartService,
    private _usersCacheService: UsersCacheService,
    private _cd: ChangeDetectorRef,
    private _routerNav: RouterNavigateService,
    private _authService: AuthService,
    private _permissions: PermissionsService
  ) {}

  ngOnInit(): any {
    this.isNotGuest$ = this._permissions.isNotGuest$;
    this.subs.push(
      this.sortType$.pipe(skip(1), pluck('name'), distinctUntilChanged()).subscribe(setToLocalStorage(SORT_TYPE))
    );

    this.subs.push(
      this.sortType$
        .pipe(distinctUntilChanged(), skip(1))
        .subscribe(sortType => this._segment.track('BacklogChartTasksSorted', { type: sortType.value }))
    );

    this._segment.track('AhaMoment', { aha_place: 'matrix' });

    this.boardId$ = <Observable<number>>this._route.params.pipe(map(params => +params['boardId']));

    this.subs.push(
      this.boardId$.subscribe(boardId =>
        this._store.dispatch(new SelectEntityAction({ id: boardId, entityName: BOARD_PL }))
      )
    );

    this.boardInfo$ = this.boardId$.pipe(
      switchMap(id =>
        observableCombineLatest(
          this._store.select(fromBoards.get(id)),
          this._store.select(fromGuiState.getBoardViewMode(id)).pipe(distinctUntilChanged())
        )
      ),
      map(([board, mode]) => ({
        id: board.id,
        type: board.type,
        scoringType: ScoringType.basic,
        boardView: mode || BoardViewMode.board,
        backlogScoreXLabel: board.backlogScoreXLabel,
        backlogScoreYLabel: board.backlogScoreYLabel,
        backlogScoreXType: board.backlogScoreXType,
        backlogScoreYType: board.backlogScoreYType,
        showProps: board.showProps
      })),
      tap(boardInfo => {
        this.axisXType = boardInfo['backlogScoreXType'];
        this.axisYType = boardInfo['backlogScoreYType'];
      })
    );

    this.sortOrderTypes$ = this.boardInfo$.pipe(
      map(info =>
        sortOrderTypes.map(item => {
          switch (item.name) {
            case 'lvalue': {
              item.value = 'Low ' + info.backlogScoreYLabel;
              return item;
            }
            case 'hvalue': {
              item.value = 'High ' + info.backlogScoreYLabel;
              return item;
            }
            case 'lefforts': {
              item.value = 'Low ' + info.backlogScoreXLabel;
              return item;
            }
            case 'hefforts': {
              item.value = 'High ' + info.backlogScoreXLabel;
              return item;
            }
            default: {
              return item;
            }
          }
        })
      )
    );

    this.tasksByBoard$ = this.boardId$.pipe(
      switchMap(boardId =>
        this._store.pipe(
          getTasksByUsersFilter(
            allPass([inBoard(boardId), task => !task.linkToTask && task.type !== 'group', isActiveTask])
          )
        )
      )
    );

    const sortFn$ = <Observable<(tasks: Task[]) => Task[]>>this.sortType$.pipe(
      switchMap(sortType => this.sortTypeFactory(sortType))
    );

    this.boardTasks$ = observableCombineLatest(this.tasksByBoard$, sortFn$).pipe(
      map(([tasks, sortFn]) => sortFn(tasks))
    );

    const boardTasksForms$ = this.boardTasks$.pipe(map(groupTasks), publishReplay(1), refCount());

    const draggingTaskId$ = this._backlogChart.taskWasDragged$.pipe(
      switchMap(wasDragged => (wasDragged ? this._backlogChart.draggingTaskId$ : observableOf(0)))
    );

    this.scoredTasks$ = boardTasksForms$.pipe(
      combineLatest(draggingTaskId$),
      map(
        ([tasks, draggingTaskId]) =>
          draggingTaskId > 0 ? tasks.scored.filter(task => task.id !== draggingTaskId) : tasks.scored
      ),
      combineLatest(this._route.fragment, (tasks: Task[], taskKey: string): Task[] => {
        if (taskKey) {
          const i = tasks.findIndex(item => item.taskKey === taskKey || item.id === +taskKey);
          return i > -1 ? [...tasks.slice(0, i), ...tasks.slice(i + 1), tasks[i]] : tasks;
        } else {
          return tasks;
        }
      })
    );

    this.noscoredTasks$ = boardTasksForms$.pipe(map(tasks => tasks.noscored));
    this.groupByEffortValue$ = boardTasksForms$.pipe(map(tasks => tasks.groupedByEffortValue));

    this.tasksForTooltip$ = this._backlogChart.groupTaskDialog$.pipe(
      filter(isPresent),
      map(tooltipData => ({ backlogScoreY: tooltipData.backlogScoreY, backlogScoreX: tooltipData.backlogScoreX })),
      distinctUntilChanged(isEquals),
      switchMap(tooltipData =>
        this.groupByEffortValue$.pipe(map(groupOfTask => groupOfTask.get(getBacklogTaskKey(tooltipData))))
      )
    );

    this.isGroupedTooltip$ = this.tasksForTooltip$.pipe(map(tasks => (tasks && tasks.length > 1 ? 1 : 0)));
    this.board$ = this.boardId$.pipe(switchMap(id => this._store.pipe(getBoardById(id))));

    this.subs.push(
      this.board$.subscribe((board: Board) => {
        if (!board.showPopup) {
          switch (board.boardTemplateViewType) {
            case BoardFirstOpenType.unset: {
              this._store.dispatch(new BoardEditAction({ id: board.id, showPopup: 1 }));
              this._routerNav.navigateToBoard(board.id);
              break;
            }
            case BoardFirstOpenType.chooser: {
              this._routerNav.navigateToScoringTypeChooser().then(() => {
                this._store.dispatch(new BoardEditAction({ id: board.id, showPopup: 1 }));
              });
              break;
            }
            case BoardFirstOpenType.scoring: {
              this._store.dispatch(new BoardEditAction({ id: board.id, showPopup: 1 }));
              if (board.scoringType === ScoringType.basic) {
                this.activeUser$.pipe(take(1)).subscribe(user => {
                  if (user.createdAt - Date.now() / 1000 < 86400 && board.isPreset) {
                    this._store.dispatch(new GuiStateOpenBoardTypeSelectorAction(true));
                  }
                });
                this._store.dispatch(new GuiStateSetBoardViewMode({ id: board.id, mode: BoardViewMode.priorityChart }));
              } else {
                this._store.dispatch(new GuiStateSetBoardViewMode({ id: board.id, mode: BoardViewMode.table }));
                this._routerNav.navigateToBoard(board.id);
              }
              break;
            }
          }
        }
      })
    );
    this.boardName$ = this.board$.pipe(map(board => board.name));
    this.scoreXLabel$ = this.board$.pipe(map(board => board.backlogScoreXLabel));
    this.scoreYLabel$ = this.board$.pipe(map(board => board.backlogScoreYLabel));
    this.scoreXType$ = this.board$.pipe(map(board => board.backlogScoreXType));
    this.scoreYType$ = this.board$.pipe(map(board => board.backlogScoreYType));

    this.isVisibleTooltip$ = this._backlogChart.isOpenGroupDialog$.pipe(
      combineLatest(
        this.isGroupedTooltip$.pipe(
          switchMap(isGroup => (isGroup ? observableOf(true) : this._backlogChart.draggingTaskId$.pipe(map(id => !id))))
        ),
        (...args) => args.every(isPresent)
      ),
      publishReplay(1),
      refCount()
    );

    const sub = this._backlogChart.draggingTaskId$
      .pipe(filter(draggingTaskId => !!draggingTaskId), distinctUntilChanged(), withLatestFrom(this.isGroupedTooltip$))
      .subscribe(([draggingTaskId, isGrouped]) => {
        if (!isGrouped) {
          this._backlogChart.closeDialog();
        }
      });

    this.subs.push(sub);

    this._route.fragment
      .pipe(filter(isPresent), switchMap(_ => this.boardInfo$), take(1))
      .subscribe(boardInfo => this._usersCacheService.clear(BoardQuickFiltersComponent.getCacheKey(boardInfo.id)));
  }

  private sortTypeFactory(orderType: SortOrderType) {
    switch (orderType.name) {
      case 'original': {
        return this._store.pipe(
          getColumnEntities,
          combineLatest(this._store.pipe(getSwimlaneEntities)),
          map(([columnEnties, swimlaneEntities]) => {
            const sortFactory = <taskSorterFactory>orderType.sortFn;
            return sortFactory(columnEnties, swimlaneEntities);
          })
        );
      }

      default: {
        return observableOf(orderType.sortFn);
      }
    }
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  onToggleFilterOrderMenu() {
    this.isFilterOrderVisible = !this.isFilterOrderVisible;
  }

  onSortOnOrder(sortType: SortOrderType) {
    this.sortType$.next(sortType);

    this.onToggleFilterOrderMenu();
  }

  onToggleAddTask() {
    this._backlogChart.groupTaskDialog$
      .pipe(take(1), map(x => x || { backlogScoreY: 0, backlogScoreX: 0 }))
      .subscribe(({ backlogScoreY, backlogScoreX }) => {
        this.formbacklogScoreX = backlogScoreX;
        this.formbacklogScoreY = backlogScoreY;
      });
    this._backlogChart.closeDialog();
    this.isAddTaskVisible = !this.isAddTaskVisible;
  }

  onUpdateTask(taskData) {
    const hasChangesbacklogScoreYs = (task: Task) =>
      task.backlogScoreX !== taskData.backlogScoreX ||
      task.backlogScoreY !== taskData.backlogScoreY ||
      task.backlogScore !== taskData.backlogScore;

    const sub = this._store
      .pipe(
        getTaskById(taskData.id),
        take(1),
        filter(hasChangesbacklogScoreYs),
        tap((task: Task) => {
          if (!task.backlogScoreY || !task.backlogScoreX) {
            // was in list
            if (taskData.backlogScoreX && taskData.backlogScoreY) {
              // fire moved to chart
              this._segment.track('BacklogChartTaskFromListToChart');
            }
          } else {
            // was at chart
            if (taskData.backlogScoreX === 0 && taskData.backlogScoreY === 0) {
              // fire moved from chart to list
              this._segment.track('BacklogChartTaskFromChartToList');
            } else {
              // moved on chart
              this._segment.track('BacklogChartTaskMovedOnChart');
            }
          }
        })
      )
      .subscribe(task =>
        this._store.dispatch(
          new TaskEditAction({
            id: taskData.id,
            backlogScoreY: taskData.backlogScoreY,
            backlogScoreX: taskData.backlogScoreX
          })
        )
      );
    this.subs.push(sub);
  }

  onDragStart(dragEvent) {
    this.backlogChartComp.onDragStart(dragEvent);
  }

  onDrag(dragEvent) {
    this.backlogChartComp.onDrag(dragEvent);
  }

  onDragEnd(dragEvent) {
    this.backlogChartComp.onDragEnd(dragEvent);
  }

  public onResetZoom() {
    this.backlogChartComp.onResetZoom();
  }

  public onZoomClick(direction = 1) {
    this.backlogChartComp.onZoomClick(direction);
  }

  onCloseDialog() {
    if (!this._backlogChart.draggingTaskId$.getValue()) {
      this._backlogChart.closeDialog();
    }
  }

  onHeightChanged(top) {
    this.containerTop = top;
  }

  onToggleHelp() {
    this.isShowHelpText = !this.isShowHelpText;
    setTimeout(() => {
      this.onHeightChanged(this.topMenu._elRef.nativeElement.offsetHeight);
      this._cd.detectChanges();
      window.dispatchEvent(new Event('resize'));
    }, 210);
  }

  onToggleRightMenu() {
    this.isNotGuest$.pipe(take(1)).subscribe(isNotGuest => {
      if (isNotGuest) {
        this._store.dispatch(new ComponentToggleAction({ name: RIGHT_MENU }));
      }
    });
  }

  onResetSearch(input) {
    if (input) {
      input.value = '';
      input.focus();
    }
  }
}
