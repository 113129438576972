import { Directive, Input, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[initialScroll]'
})
export class InitialScrollDirective implements AfterViewInit {
  @Input() scrollLeft = 0;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    this.elementRef.nativeElement.scrollLeft = this.scrollLeft;
  }
}
