import { takeUntil } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SocketService } from '../../shared/services/app/web-socket/web-socket.service';
import { AppState } from '../../ngrx/state';
import { AtlazDevtoolsService } from '../../../helpers/devtools';
import { isStickyLeftMenu } from '../../ngrx/reducers/component.reducer';
import { Subject } from 'rxjs';
import { APortalService } from '../../atlaz-gui/a-portal/a-portal.service';
import { IntegrationsService } from '../services/intergations/integrations.service';
import { IntercomService } from '../services/intergations/intercom/intercom.service';

@Component({
  selector: 'atlaz-bnp',
  templateUrl: './atlaz-bnp.component.html',
  styleUrls: ['./atlaz-bnp.component.scss'],
  providers: [APortalService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AtlazBnpComponent implements OnInit, OnDestroy {
  @HostBinding('class.sticky') stickyClass = false;

  readonly destroy$ = new Subject();

  constructor(
    private _socket: SocketService,
    private _devService: AtlazDevtoolsService,
    private _store: Store<AppState>,
    private _integtaions: IntegrationsService,
    private _intercom: IntercomService
  ) {}

  ngOnInit(): any {
    this.saveTreloToken(location.href);
    this._store
      .select(isStickyLeftMenu)
      .pipe(takeUntil(this.destroy$))
      .subscribe(flag => (this.stickyClass = flag));
    this._devService.init();
    this._socket.init();
    this._integtaions.init();
    this._intercom.showChatIcon();
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this._intercom.hideChatIcon();
  }

  public saveTreloToken(url: string) {
    const results = new RegExp('[\\#]token=([^&#]*)').exec(url);
    let token;

    results == null ? (token = null) : (token = results[1]);

    if (token) {
      localStorage.setItem('trelloToken', token);
    }
  }
}
