import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { GitCommit } from '../../../models/git-commit';

@Component({
  selector: 'git-task-commit',
  templateUrl: './git-task-commit.component.html',
  styleUrls: ['./git-task-commit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GitTaskCommitComponent implements OnChanges {
  @Input() taskCommits: GitCommit[];

  @Output() removeCommit = new EventEmitter<GitCommit>();

  public sortedTaskCommits: GitCommit[];

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('taskCommits') && changes['taskCommits'].currentValue) {
      this.sortedTaskCommits = changes['taskCommits'].currentValue.sort((a, b) => b.createdAt - a.createdAt);
    } else {
      this.sortedTaskCommits = [];
    }
  }

  onRemove(commit: GitCommit) {
    this.removeCommit.emit(commit);
  }
}
