
import {map, tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../app/auth.service';

import { RouterNavigateService } from '../router-navigate.service';

@Injectable()
export class UnauthorizedGuard implements CanActivate {
  constructor(private _authService: AuthService, private _navigate: RouterNavigateService, private _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._authService.isLoggedIn$.pipe(
      tap(isLogged => {
        if (isLogged === true) {
          this._navigate.navigateToOverview();
        }
      }),
      map(xs => !xs),);
  }
}
