import { Component, ChangeDetectionStrategy, Input, OnChanges } from '@angular/core';
import { User } from '../../../../interfaces/user';
import { ROLE_PROJECT_TITLES, RoleProject } from '../../../../permissions/interfaces/roles';
import { PermissionsService } from '../../../../permissions/permissions.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../ngrx/state/app-state';
import { ProjectChangeRoleAction, ProjectEditAction } from '../../../../ngrx/actions/project.actions';

@Component({
  selector: 'user-project-role-select',
  templateUrl: './user-project-role-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProjectRoleSelectComponent implements OnChanges {
  @Input() user: User;
  @Input() projectId: number;
  @Input() roleInProject: string;

  public options = ROLE_PROJECT_TITLES;
  public isSuperUser: string;

  constructor(public permissions: PermissionsService, private _store: Store<AppState>) {}

  ngOnChanges() {
    this.isSuperUser = PermissionsService.isSuperUser(this.user.role)
      ? PermissionsService.getUserRoleName(this.user.role)
      : '';
  }

  public onSaveNewRole(user: User, newRole: RoleProject) {
    this._store.dispatch(
      new ProjectChangeRoleAction({
        id: this.projectId,
        requestPayload: {
          user: {
            id: user.id,
            role: newRole
          }
        },
        queryParams: { expand: 'members,admins' }
      })
    );
  }
}
