import { switchMap, distinctUntilChanged, pluck, tap, map } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Board, SprintBoard, Version } from '../../../interfaces';
import { AppUrls } from '../../../app-urls';
import { EstimationType } from '../../../constants';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { SprintsTasks } from '../../../interfaces/sprints-tasks';
import { getSprintTasksByBoardId, getVersionsByBoardId } from '../../../ngrx/reducers/version.reducer';
import { WorkingDaysService } from '@atlaz/working-days/services/working-days.service';
import { VelocityVersion } from '../../../interfaces/version';
import { getSprintsTaskCompletedSec } from '../../../../helpers';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';

@Component({
  selector: 'velocity-report',
  templateUrl: './velocity-report.component.html',
  styleUrls: ['./velocity-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VelocityReportComponent implements OnInit {
  @Input() board$: Observable<SprintBoard>;

  public appUrls = AppUrls;
  public boardUrl$: Observable<string | any[]>;
  public sprintsTasksWithVersion$: Observable<SprintsTasks[]>;
  public boardVersions$: Observable<Version[]>;
  public availableOptions$: Observable<Array<string>>;
  public boardId$: Observable<number>;
  public chartData$;
  public reportType$: BehaviorSubject<string> = new BehaviorSubject(EstimationType.storyPoints);
  public reportType: string = EstimationType.storyPoints;
  public estimationType = EstimationType;
  public estimationTypeLabels = {
    [EstimationType.hours]: 'Hours',
    [EstimationType.storyPoints]: 'Story Points'
  };
  public averageValues$;
  public isShowHelpText = false;

  constructor(private _store: Store<AppState>, private _workingDaysService: WorkingDaysService) {}

  ngOnInit() {
    this.boardId$ = <Observable<number>>this.board$.pipe(pluck('id'), distinctUntilChanged());
    this.boardUrl$ = this.board$.pipe(map(board => AppUrls.getUrlBoard(board.id)));
    this.sprintsTasksWithVersion$ = this.board$.pipe(
      switchMap((board: Board) => this._store.select(getSprintTasksByBoardId(board.id))),
      map((sprintsTasks: SprintsTasks[]) => sprintsTasks.filter((item: SprintsTasks) => !!item.version))
    );
    this.boardVersions$ = this.board$.pipe(
      switchMap((board: Board) => this._store.select(getVersionsByBoardId(board.id))),
      map((versions: Version[]) =>
        versions.filter((version: Version) => version.sprintsTasksIds && version.sprintsTasksIds.length)
      )
    );
    this.availableOptions$ = this.boardVersions$.pipe(
      map(this.getAvailableOptionsFromVersions),
      tap(types => {
        this.reportType = types.includes(this.estimationType.storyPoints) ? this.reportType : types[0];
        this.reportType$.next(this.reportType);
      })
    );

    this.chartData$ = combineLatest(this.sprintsTasksWithVersion$, this.boardVersions$, this.reportType$).pipe(
      map(([sprintTasks, versions, reportType]: [SprintsTasks[], Version[], EstimationType]) => {
        const dataByVersion = this.groupSprintTasksByVersion(sprintTasks);
        return versions
          .filter(
            (version: Version) =>
              version && (version.estimatedBy === EstimationType.any || version.estimatedBy === reportType)
          )
          .map((version: Version) => ({
            storyPoints: dataByVersion[version.id].storyPoints,
            hours: Math.round(dataByVersion[version.id].estimate / 3600),
            storyPointsDone: dataByVersion[version.id].storyPointsDone,
            hoursDone: Math.round(dataByVersion[version.id].estimateDone / 3600),
            version: version.name,
            createdAt: version.createdAt
          }))
          .sort((a, b) => a.createdAt - b.createdAt);
      })
    );
    this.averageValues$ = combineLatest(this.chartData$, this.boardVersions$).pipe(
      map(([chartData, versions]: [VelocityVersion[], Version[]]) => {
        const releasedCount =
          this.reportType === EstimationType.storyPoints
            ? chartData.reduce((acc, item) => acc + item.storyPointsDone, 0)
            : chartData.reduce((acc, item) => acc + item.hoursDone, 0);
        const sprintsCount = chartData.length;
        const weeksCount =
          versions
            .filter(
              (version: Version) =>
                version && (version.estimatedBy === EstimationType.any || version.estimatedBy === this.reportType)
            )
            .reduce(
              (acc, item) => acc + this._workingDaysService.workingDaysBetween(item.startDate, item.releasedDate),
              0
            ) / this._workingDaysService.workingDaysCount();

        if (this.reportType === EstimationType.hours) {
          return {
            perSprint: sprintsCount ? (releasedCount / sprintsCount).toFixed() + 'h' : '-',
            perWeek: weeksCount ? (releasedCount / weeksCount).toFixed() + 'h' : '-'
          };
        } else {
          return {
            perSprint: sprintsCount ? (releasedCount / sprintsCount).toFixed() : '-',
            perWeek: weeksCount ? (releasedCount / weeksCount).toFixed() : '-'
          };
        }
      })
    );
  }

  onReportTypeChange() {
    this.reportType$.next(this.reportType);
  }

  getAvailableOptionsFromVersions(versions) {
    const result = {
      [EstimationType.hours]: false,
      [EstimationType.storyPoints]: false
    };
    versions.filter(version => version && version.estimatedBy).forEach((version: Version) => {
      if (version.estimatedBy === EstimationType.any) {
        result[EstimationType.hours] = true;
        result[EstimationType.storyPoints] = true;
      } else {
        result[version.estimatedBy] = true;
      }
    });
    return Object.keys(result).filter((key: EstimationType) => result[key]);
  }
  groupSprintTasksByVersion(sprintTasks: SprintsTasks[]) {
    return sprintTasks.reduce((chartData, sprintsTask) => {
      if (!chartData[sprintsTask.version]) {
        chartData[sprintsTask.version] = {
          storyPoints: 0,
          estimate: 0,
          storyPointsDone: 0,
          estimateDone: 0
        };
      }
      const data = chartData[sprintsTask.version];
      data.storyPoints += sprintsTask.storyPoints;
      data.estimate += sprintsTask.remainingEstimate;
      if (sprintsTask.doneDate) {
        data.storyPointsDone += sprintsTask.storyPoints;
      }
      data.estimateDone += getSprintsTaskCompletedSec(sprintsTask);
      return chartData;
    }, {});
  }
  onToggleHelp() {
    this.isShowHelpText = !this.isShowHelpText;
  }
}
