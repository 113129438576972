<app-header></app-header>
<http-request-errors></http-request-errors>
<left-menu></left-menu>
<router-outlet></router-outlet>
<a-loader></a-loader>
<router-outlet name="popup"></router-outlet>
<router-outlet name="opened-task"></router-outlet>
<a-portal-outlet></a-portal-outlet>
<paywall-layout></paywall-layout>
<app-overflow-elements></app-overflow-elements>

<svg style="display: none">
  <symbol id="triangle" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M7 10L12 15L17 10H7Z"/>
  </symbol>
  <symbol id="drag-icon" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7 4C6.45 4 6 4.45 6 5C6 5.55 6.45 6 7 6C7.55 6 8 5.55 8 5C8 4.45 7.55 4 7 4ZM7 8C6.45 8 6 8.45 6 9C6 9.55 6.45 10 7 10C7.55 10 8 9.55 8 9C8 8.45 7.55 8 7 8ZM7 12C6.45 12 6 12.45 6 13C6 13.55 6.45 14 7 14C7.55 14 8 13.55 8 13C8 12.45 7.55 12 7 12ZM11 12C10.45 12 10 12.45 10 13C10 13.55 10.45 14 11 14C11.55 14 12 13.55 12 13C12 12.45 11.55 12 11 12ZM11 8C10.45 8 10 8.45 10 9C10 9.55 10.45 10 11 10C11.55 10 12 9.55 12 9C12 8.45 11.55 8 11 8ZM11 4C10.45 4 10 4.45 10 5C10 5.55 10.45 6 11 6C11.55 6 12 5.55 12 5C12 4.45 11.55 4 11 4Z"/>
  </symbol>
  <symbol id="add" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" d="M10 6H6V10H4V6H0V4H4V0H6V4H10V6Z"/>
  </symbol>
  <symbol id="add-inverted" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 2C5.136 2 2 5.136 2 9C2 12.864 5.136 16 9 16C12.864 16 16 12.864 16 9C16 5.136 12.864 2 9 2ZM12.5 9.7H9.7V12.5H8.3V9.7H5.5V8.3H8.3V5.5H9.7V8.3H12.5V9.7Z"/>
  </symbol>
  <symbol id="bucket" viewBox="0 0 12 14" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M1.5 12.25C1.5 13.075 2.175 13.75 3 13.75H9C9.825 13.75 10.5 13.075 10.5 12.25V3.25H1.5V12.25ZM11.25 1H8.625L7.875 0.25H4.125L3.375 1H0.75V2.5H11.25V1Z"/>
  </symbol>
  <symbol  id="pencil" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 15.6894V18.5019H7.8125L16.1075 10.2069L13.295 7.39438L5 15.6894ZM18.2825 8.03187C18.575 7.73937 18.575 7.26688 18.2825 6.97438L16.5275 5.21937C16.235 4.92688 15.7625 4.92688 15.47 5.21937L14.0975 6.59187L16.91 9.40438L18.2825 8.03187Z"/>
  </symbol>
  <symbol id="check" viewBox="0 0 14 10" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.2002 9.50001L0.700195 5.00001L1.9692 3.73101L5.2002 6.95301L12.0312 0.122009L13.3002 1.40001L5.2002 9.50001Z" fill="currentColor"/>
  </symbol>
  <symbol id="date-type" viewBox="0 0 24 18" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.75 9H12V12.75H15.75V9ZM15 0.75V2.25H9V0.75H7.5V2.25H6.75C5.9175 2.25 5.2575 2.925 5.2575 3.75L5.25 14.25C5.25 15.075 5.9175 15.75 6.75 15.75H17.25C18.075 15.75 18.75 15.075 18.75 14.25V3.75C18.75 2.925 18.075 2.25 17.25 2.25H16.5V0.75H15ZM17.25 14.25H6.75V6H17.25V14.25Z"/>
  </symbol>
  <symbol id="select-type" viewBox="0 0 24 18" xmlns="http://www.w3.org/2000/svg">
    <polygon fill="none" points="24,18 0,18 0,0 24,0 "/>
    <path class="st1" d="M4,4h16c1.1,0,2,0.9,2,2v6c0,1.1-0.9,2-2,2H4c-1.1,0-2-0.9-2-2V6C2,4.9,2.9,4,4,4z M4,5 C3.4,5,3,5.4,3,6v6c0,0.6,0.4,1,1,1h8c0.6,0,1-0.4,1-1V6c0-0.6-0.4-1-1-1H4z M15.5,8l2,2l2-2H15.5z"/>
  </symbol>
  <symbol id="text-type" viewBox="0 0 24 18" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 6H8.63574V7.48158H6.74488V12H4.89086V7.48158H3V6ZM9.06139 6H11.1037L12.1678 7.84584L13.1992 6H15.221L13.3547 8.90587L15.397 12H13.3138L12.131 10.0723L10.9441 12H8.87312L10.9441 8.87312L9.06139 6ZM15.573 6H21.2087V7.48158H19.3179V12H17.4638V7.48158H15.573V6Z"/>
  </symbol>
  <symbol id="number-type" viewBox="0 0 24 18" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.89175 10.0984L4.13201 8.92937H3V7.65413H4.38614L4.71419 6H6.03102L5.69373 7.65413H6.70561L7.03828 6H8.35049L8.02706 7.65413H8.66007V8.92937H7.76832L7.53267 10.0984H8.66007V11.3736H7.27855L6.9505 13H5.63366L5.9571 11.3736H4.94983L4.61716 13H3.30957L3.64686 11.3736H3V10.0984H3.89175ZM5.20396 10.0984H6.21584L6.45611 8.92937H5.44884L5.20396 10.0984ZM10.1386 10.0984L10.3789 8.92937H9.24686V7.65413H10.633L10.9611 6H12.2779L11.9406 7.65413H12.9525L13.2851 6H14.5974L14.2739 7.65413H14.9069V8.92937H14.0152L13.7795 10.0984H14.9069V11.3736H13.5254L13.1974 13H11.8805L12.204 11.3736H11.1967L10.864 13H9.55644L9.89373 11.3736H9.24686V10.0984H10.1386ZM11.4508 10.0984H12.4627L12.703 8.92937H11.6957L11.4508 10.0984ZM16.3855 10.0984L16.6257 8.92937H15.4937V7.65413H16.8799L17.2079 6H18.5248L18.1875 7.65413H19.1993L19.532 6H20.8442L20.5208 7.65413H21.1538V8.92937H20.262L20.0264 10.0984H21.1538V11.3736H19.7723L19.4442 13H18.1274L18.4508 11.3736H17.4436L17.1109 13H15.8033L16.1406 11.3736H15.4937V10.0984H16.3855ZM17.6977 10.0984H18.7096L18.9498 8.92937H17.9426L17.6977 10.0984Z"/>
  </symbol>
  <symbol id="pin" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8622 17.5193C14.5342 17.6565 14.1562 17.5863 13.8993 17.3403L6.66508 10.1005C6.41491 9.84284 6.34271 9.46038 6.48169 9.12904C6.62066 8.7977 6.9439 8.58162 7.30273 8.58019H10.3555L13.4084 3.56432C13.5677 3.28842 13.836 3.09273 14.1471 3.02546C14.4582 2.9582 14.7832 3.02561 15.042 3.21109L20.7893 8.95885C20.9745 9.21807 21.0418 9.54356 20.9746 9.85514C20.9075 10.1667 20.7121 10.4354 20.4366 10.595L15.4116 13.6469V16.7073C15.4058 17.0633 15.1901 17.382 14.8622 17.5193ZM7.93211 14.0567L3 21L9.93817 16.0658L7.93211 14.0567Z"/>
  </symbol>
  <symbol id="close" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"/>
  </symbol>
  <symbol id="home" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 15V10.5H10.5V15H14.25V9H16.5L9 2.25L1.5 9H3.75V15H7.5Z"/>
  </symbol>
  <symbol id="vertical-dots" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 6C9.825 6 10.5 5.325 10.5 4.5C10.5 3.675 9.825 3 9 3C8.175 3 7.5 3.675 7.5 4.5C7.5 5.325 8.175 6 9 6ZM9 7.5C8.175 7.5 7.5 8.175 7.5 9C7.5 9.825 8.175 10.5 9 10.5C9.825 10.5 10.5 9.825 10.5 9C10.5 8.175 9.825 7.5 9 7.5ZM9 12C8.175 12 7.5 12.675 7.5 13.5C7.5 14.325 8.175 15 9 15C9.825 15 10.5 14.325 10.5 13.5C10.5 12.675 9.825 12 9 12Z"/>
  </symbol>
  <symbol id="bell" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 16.875C9.825 16.875 10.5 16.2 10.5 15.375H7.5C7.5 16.2 8.1675 16.875 9 16.875ZM13.5 12.375V8.625C13.5 6.3225 12.27 4.395 10.125 3.885V3.375C10.125 2.7525 9.6225 2.25 9 2.25C8.3775 2.25 7.875 2.7525 7.875 3.375V3.885C5.7225 4.395 4.5 6.315 4.5 8.625V12.375L3 13.875V14.625H15V13.875L13.5 12.375Z"/>
  </symbol>
  <symbol id="my-tasks" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.25 2.25H11.115C10.8 1.38 9.975 0.75 9 0.75C8.025 0.75 7.2 1.38 6.885 2.25H3.75C2.925 2.25 2.25 2.925 2.25 3.75V14.25C2.25 15.075 2.925 15.75 3.75 15.75H14.25C15.075 15.75 15.75 15.075 15.75 14.25V3.75C15.75 2.925 15.075 2.25 14.25 2.25ZM9 2.25C9.4125 2.25 9.75 2.5875 9.75 3C9.75 3.4125 9.4125 3.75 9 3.75C8.5875 3.75 8.25 3.4125 8.25 3C8.25 2.5875 8.5875 2.25 9 2.25ZM7.5 12.75L4.5 9.75L5.5575 8.6925L7.5 10.6275L12.4425 5.685L13.5 6.75L7.5 12.75Z"/>
  </symbol>
  <symbol id="kanban-board" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 3C2 2.44772 2.44772 2 3 2H5C5.55228 2 6 2.44772 6 3V11C6 11.5523 5.55228 12 5 12H3C2.44772 12 2 11.5523 2 11V3ZM7 3C7 2.44772 7.44772 2 8 2H10C10.5523 2 11 2.44772 11 3V15C11 15.5523 10.5523 16 10 16H8C7.44772 16 7 15.5523 7 15V3ZM13 2C12.4477 2 12 2.44772 12 3V7C12 7.55228 12.4477 8 13 8H15C15.5523 8 16 7.55228 16 7V3C16 2.44772 15.5523 2 15 2H13Z"/>
  </symbol>
  <symbol id="roadmap-board" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 3C2 2.44772 2.44772 2 3 2H11C11.5523 2 12 2.44772 12 3V5C12 5.55228 11.5523 6 11 6H3C2.44772 6 2 5.55228 2 5V3ZM8 8C8 7.44772 8.44772 7 9 7H15C15.5523 7 16 7.44772 16 8V10C16 10.5523 15.5523 11 15 11H9C8.44772 11 8 10.5523 8 10V8ZM6 12C5.44772 12 5 12.4477 5 13V15C5 15.5523 5.44772 16 6 16H12C12.5523 16 13 15.5523 13 15V13C13 12.4477 12.5523 12 12 12H6Z"/>
  </symbol>
  <symbol id="sprint-board" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.2716 14.1216H10.642V14.0629C11.561 13.6997 12.3695 13.1115 13.002 12.3649H14.2716V11.4865L16 13.2432L14.2716 15V14.1216ZM8.91367 2.71934C11.8141 2.9436 14.0988 5.40669 14.0988 8.41216C14.0988 11.5361 11.6304 14.074 8.56799 14.121L2.58339 14.1217C2.26122 14.1217 2.00003 13.8563 2 13.5288C2 13.5288 2 13.5288 2 13.5287L2.00018 12.9578C2.0002 12.6303 2.26135 12.3649 2.5835 12.3649L8.39516 12.3649C8.42389 12.3645 8.45269 12.3649 8.48157 12.3649C10.6293 12.3649 12.3704 10.5952 12.3704 8.41216C12.3704 6.3776 10.8581 4.70206 8.91367 4.48358V5.51351L7.1853 3.75676L8.91367 2V2.71934ZM4.12255 12.0135C3.33593 11.0311 2.86436 9.77759 2.86436 8.41216C2.86436 5.54914 4.93763 3.17831 7.64139 2.76614L6.66678 3.75676L7.48659 4.59002C5.82087 5.03681 4.59274 6.57871 4.59274 8.41216C4.59274 10.0134 5.52952 11.3923 6.87643 12.0135H4.12255Z"/>
  </symbol>
  <symbol id="list-board" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 10V8H2V10H4ZM4 14V12H2V14H4ZM4 6V4H2V6H4ZM16 10V8H5V10H16ZM16 14V12H5V14H16ZM5 6H16V4H5V6Z"/>
  </symbol>
  <symbol id="report" viewBox="0 0 18 21" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13 0H5V4H13V0ZM2 2H4V5H14V2H16C17.1 2 18 3 18 4.22222V18.7778C18 20 17.1 21 16 21H2C0.9 21 0 20 0 18.7778V4.22222C0 3 0.9 2 2 2ZM6 18H4V11H6V18V18ZM10 18H8V8H10V18V18ZM14 18H12V14H14V18V18Z"/>
  </symbol>
  <symbol id="star" viewBox="0 0 30 31" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5 11.5L15 2L17.5 11.5H28.5L20.5 18L24.5 29.5L15 22L5.5 29.5L9.5 18L1.5 11.5H12.5Z" stroke="black"/>
  </symbol>
  <symbol id="search">
    <path d="M602.531,549.736l-184.31-185.368c26.679-37.72,42.528-83.729,42.528-133.548C460.75,103.35,357.997,0,231.258,0C104.518,0,1.765,103.35,1.765,230.82c0,127.47,102.753,230.82,229.493,230.82c49.53,0,95.271-15.944,132.78-42.777l184.31,185.366c7.482,7.521,17.292,11.291,27.102,11.291c9.812,0,19.62-3.77,27.083-11.291C617.496,589.188,617.496,564.777,602.531,549.736z M355.9,319.763l-15.042,21.273L319.7,356.174c-26.083,18.658-56.667,28.526-88.442,28.526c-84.365,0-152.995-69.035-152.995-153.88c0-84.846,68.63-153.88,152.995-153.88s152.996,69.034,152.996,153.88C384.271,262.769,374.462,293.526,355.9,319.763z"/>
  </symbol>
  <symbol id="angle-up">
    <path d="M604.501,440.509L325.398,134.956c-5.331-5.357-12.423-7.627-19.386-7.27c-6.989-0.357-14.056,1.913-19.387,7.27		L7.499,440.509c-9.999,10.024-9.999,26.298,0,36.323s26.223,10.024,36.222,0l262.293-287.164L568.28,476.832		c9.999,10.024,26.222,10.024,36.221,0C614.5,466.809,614.5,450.534,604.501,440.509z"/>
  </symbol>
  <symbol id="angle-down">
    <path d="M604.501,134.782c-9.999-10.05-26.222-10.05-36.221,0L306.014,422.558L43.721,134.782		c-9.999-10.05-26.223-10.05-36.222,0s-9.999,26.35,0,36.399l279.103,306.241c5.331,5.357,12.422,7.652,19.386,7.296		c6.988,0.356,14.055-1.939,19.386-7.296l279.128-306.268C614.5,161.106,614.5,144.832,604.501,134.782z"/>
  </symbol>
  <symbol id="clear">
    <path d="M44.373,7.603c-10.137-10.137-26.632-10.138-36.77,0c-10.138,10.138-10.137,26.632,0,36.77s26.632,10.138,36.77,0C54.51,34.235,54.51,17.74,44.373,7.603z M36.241,36.241c-0.781,0.781-2.047,0.781-2.828,0l-7.425-7.425l-7.778,7.778c-0.781,0.781-2.047,0.781-2.828,0c-0.781-0.781-0.781-2.047,0-2.828l7.778-7.778l-7.425-7.425c-0.781-0.781-0.781-2.048,0-2.828c0.781-0.781,2.047-0.781,2.828,0l7.425,7.425l7.071-7.071c0.781-0.781,2.047-0.781,2.828,0c0.781,0.781,0.781,2.047,0,2.828l-7.071,7.071l7.425,7.425C37.022,34.194,37.022,35.46,36.241,36.241z"/>
  </symbol>
  <symbol id="export" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1228 14.1228V6.36842L8.84357 8.63384C8.50334 8.97201 7.95364 8.97118 7.61443 8.63197C7.27492 8.29246 7.27443 7.74215 7.61335 7.40204L11.2917 3.71083C11.6825 3.31865 12.3175 3.31865 12.7083 3.71083L16.3866 7.40204C16.7256 7.74215 16.7251 8.29246 16.3856 8.63197C16.0464 8.97118 15.4967 8.97201 15.1564 8.63384L12.8772 6.36842V14.1228C12.8772 14.6073 12.4845 15 12 15C11.5155 15 11.1228 14.6073 11.1228 14.1228ZM19 14C19 13.4477 19.4477 13 20 13C20.5523 13 21 13.4477 21 14V20.078C21 20.6303 20.5523 21.078 20 21.078H4C3.44772 21.078 3 20.6303 3 20.078V14C3 13.4477 3.44772 13 4 13C4.55228 13 5 13.4477 5 14V19H19V14Z"/>
  </symbol>
</svg>
