import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';

import { Notification, User } from '../../../../interfaces';

import { Store } from '@ngrx/store';
import { AppState } from '../../../../ngrx/state';
import { MarkAsTypes, NotificationMarkAsAction } from '../../../../ngrx/actions/notification.actions';
import { getUserById } from '../../../../ngrx/reducers/user.reducer';

@Component({
  selector: 'subnotification-preview',
  templateUrl: './subnotification-preview.component.html',
  styleUrls: ['./subnotification-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubNotificationPreviewComponent implements OnChanges {
  @Input() subNotification: Notification;
  @Input() isArchivedFilterActive: boolean;

  public user$: Observable<User>;

  constructor(private _store: Store<AppState>) {}

  ngOnChanges() {
    this.user$ = <Observable<User>>this._store.pipe((getUserById(this.subNotification.creator)));
  }
  onArchiveNotification() {
    this._store.dispatch(
      new NotificationMarkAsAction({ markType: MarkAsTypes.archive, notificationIds: [this.subNotification.id] })
    );
  }
}
