<div class="label_invite_link">
  <span>Invite Link</span>
  <span class="icon_quote" [a-tooltip]="'Anyone having this link will be able to join your company. The link is valid for 30 days and for 1000 people.'"
    [a-tooltip-z-index]="99999"></span>
</div>
<div class="input_link">
  <img *ngIf="isLoad$ | async" src="/assets/img/rolling-blue.gif" class="loader">
  <input type="text" class="input" [value]="inviteLink$ | async" />
  <button a-button class="btn_copy_link" (click)="onCopyToClipboard()">Copy Link</button>
</div>
