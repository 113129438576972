import { Action } from '../unsafe-action';

export enum DefaultTasksSwimlanesFilterActionTypes {
  TOGGLE_ITEM_ID = '[DEFAULT_SWIMLANES_FILTER] TOGGLE_SWIMLANE_IDS',
  RESET = '[DEFAULT_SWIMLANES_FILTER] RESET'
}

export class DefaultTaskSwimlanesFilterToggleItemAction implements Action {
  readonly type = DefaultTasksSwimlanesFilterActionTypes.TOGGLE_ITEM_ID;

  constructor(public payload: number[]) {}
}

export class DefaultSwimlanesFilterResetAction implements Action {
  readonly type = DefaultTasksSwimlanesFilterActionTypes.RESET;
}

// prettier-ignore
export type Actions
  = DefaultTaskSwimlanesFilterToggleItemAction
  | DefaultSwimlanesFilterResetAction
  ;
