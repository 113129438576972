import {
  from,
  interval as observableInterval,
  merge as observableMerge,
  never as observableNever,
  Observable,
  of as observableOf
} from 'rxjs';

import {
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  mergeMap,
  pluck,
  publishReplay,
  refCount,
  startWith,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  ApplyCurrentScale,
  RoadMapActionTypes,
  RoadMapDataChangedAction,
  RoadMapHideItemsAction,
  RoadMapInitAction,
  RoadMapTaskTreeChangedAction,
  RoadMapTodayDateChangedAction,
  RoadMapWeekStartChangedAction,
  RoadMapWorkingDaysChangedAction
} from './roadmap-board.action';
import {
  EditModelAction,
  HandleResponseAction,
  NoopAction,
  PatchEntityAction
} from '../../../ngrx/actions/root.action';
import { BugTrackerService } from '@atlaz/core/services/bag-tracker.service';
import { AppState, COLLAPSED_GROUPS, EntityState, ESInterface, GUI_STATE_MEMORIZED } from '../../../ngrx/state';
import { AtlazApiV2Service } from '../../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { getBoardById } from '../../../ngrx/reducers/board.reducer';
import { getUsersTasksFilter, inBoard, isActiveTask } from '../../../ngrx/reducers/task.reducer';
import { both, defaultValue, fromCamelToDash, isEqualType, isNotPresent, isPresent, sortBy } from '../../../../helpers';
import { Project, Task } from '../../../interfaces';
import { getColumnsByBoard } from '../../../ngrx/reducers/column.reducer';
import { columnTypes, LINK_TO_TASK, PROJECT_PL, TASK_PL } from '../../../constants';
import { getSwimlanesByBoard } from '../../../ngrx/reducers/swimlane.reducer';
import { RoadMapItem } from '../interfaces/roadmap.interface';
import * as moment from 'moment-mini-ts';
import { RoadMapAddFormTypes, TAKS_TREE } from '../constants/roadmap.constants';
import { ResizeTaskService } from '../services/resize-task.service';
import { DraggedActualPositions, RoadmapDragService, RoadmapDropEvent } from '../services/roadmap-drag.service';
import { SegmentService } from '../../../atlaz-bnp/services/intergations/segment/segment.service';
import { AppUrls } from '../../../app-urls';
import { WorkingDaysService } from '@atlaz/working-days/services/working-days.service';
import { BACKEND_DATE_FORMAT } from '../../../libs/date-time-formatter/constants/date-time-formats';
import { fromRoadmapBoard } from './roadmap-board.reducer';
import { getDayByPx, getPrevWorkingDay } from '../chart/utils/chart-cells';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from '../../../shared/services/app/company.service';

export const ONE_DAY = 24 * 3600;

const PositionAscComparer = sortBy('position', 'asc');

export const COLLAPSED = 'COLLAPSED';
export const EXPANDED = 'EXPANDED';

const extractDatesRangeFromSubItems = (tree: RoadMapItem[]) => {
  let minStartDate = tree[0] ? tree[0].startDate : 0;
  let maxEndDate = tree[0] ? tree[0].endDate : 0;

  const walk = tree => {
    tree.forEach(item => {
      if (item.startDate && minStartDate > item.startDate) {
        minStartDate = item.startDate;
      }
      if (item.endDate && maxEndDate < item.endDate) {
        maxEndDate = item.endDate;
      }
      if (item.subItems.length) {
        walk(item.subItems);
      }
    });
  };

  walk(tree);

  return [minStartDate, maxEndDate];
};

const patchRoadMapItem = (item, data) => {
  const actions = [];
  actions.push(
    new PatchEntityAction({
      entityName: TASK_PL,
      data,
      httpParams: { expand: LINK_TO_TASK }
    })
  );
  if (item.isLink) {
    actions.push(
      new EditModelAction({
        entityName: TASK_PL,
        data: { ...data, id: item.task.id }
      })
    );
  }
  return from(actions);
};

const buildTaskTree = (boardId: number, forPrint: boolean) => (
  tasksState: EntityState<Task>,
  projects: ESInterface<Project>,
  collapsedGroupsStage: { [id: number]: any },
  filterFn: (task: Task, linkTask?: Task) => boolean
): any => {
  const today = moment
    .utc()
    .hour(12)
    .minutes(0);
  // create containers for all items
  const rawItems = tasksState
    .filter(both(inBoard(boardId), isActiveTask))
    .reduce((acc, task: Task) => {
      let taskToFilter = task;
      let taskLink;
      if (task.linkToTask && tasksState.get(task.linkToTask)) {
        taskToFilter = tasksState.get(task.linkToTask);
        taskLink = task;
      } else if (task.linkToTask) {
        return acc;
      }
      if (
        taskToFilter.type !== RoadMapAddFormTypes.group &&
        !filterFn({ ...taskToFilter, parent: taskLink ? taskLink.parent : taskToFilter.parent }, taskLink)
      ) {
        return acc;
      }
      const item: RoadMapItem = {
        id: task.id,
        title: task.title,
        type: task.type,
        // backend has such a validation
        // however it doesn't prevent him from sending us such a strange tasks, with parent to themselves
        parent: task.parent === task.id ? null : task.parent,
        startDate: 0,
        endDate: 0,
        durationDays: 0,
        shortName:
          task.project && task.numberInProject && projects.entities[task.project]
            ? projects.entities[task.project].shortName + '-' + task.numberInProject
            : '',
        collapsed: !!collapsedGroupsStage[task.id],
        collapsedState: collapsedGroupsStage[task.id] ? COLLAPSED : EXPANDED,
        task,
        url: undefined,
        isLink: false,
        isProject: false,
        archived: 0,
        columnArchived: 0,
        position: task.position,
        subItems: []
      };
      if (task.linkToProject) {
        item.isProject = true;
      }
      // get original for task link
      if (task.linkToTask && tasksState.get(task.linkToTask)) {
        // it's required for deleting task link
        item['taskLinkObject'] = task;
        task = tasksState.get(task.linkToTask);
        item.task = task;
        item.isLink = true;
        item.title = task.title;
        // in case when parent task hasn't been updated in store yet
        item.roadMapLinkedTaskFields = task.roadMapLinkedTaskFields || {
          boardName: '',
          columnName: '',
          columnParentName: '',
          columnParentType: '',
          columnType: '',
          taskShortName: ''
        };
        item.shortName = item.roadMapLinkedTaskFields.taskShortName || '';
      }

      item.archived = task.archived;
      item.released = task.released;
      item.boardClosed = task.boardClosed;
      item.projectArchived = task.projectArchived;
      item.columnArchived = task.columnArchived;

      if (task.dueDate) {
        item.endDate = task.dueDate;
        item.startDate = task.startDate && task.startDate < item.endDate ? task.startDate : item.endDate - 1;
      } else {
        item.startDate = task.startDate || today.unix();
        item.endDate = item.startDate + 1;
      }

      item.durationDays = Math.floor(1 + (item.endDate - item.startDate) / ONE_DAY);
      item.url = AppUrls.taskUrl(item.task);
      acc.add(item);

      return acc;
    }, EntityState.fromArray([]))
    .filter(
      item => !item.released && !item.archived && !item.boardClosed && !item.columnArchived && !item.projectArchived
    );

  // attaching all child nodes for their parents
  let itemsState = rawItems.reduce((acc, item) => {
    if (item.parent) {
      const parent = acc.get(item.parent);
      if (parent) {
        parent.subItems.push(item);
      }
    }

    return acc;
  }, rawItems);

  itemsState = itemsState.reduce((acc, item) => {
    if (item.subItems.length) {
      const [startDate, endDate] = extractDatesRangeFromSubItems(item.subItems);
      item.startDate = startDate;
      item.endDate = endDate;
      item.durationDays = Math.floor(1 + (item.endDate - item.startDate) / ONE_DAY);
    }
    return acc;
  }, itemsState);

  const minStartDate = itemsState.reduce((acc, item) => {
    if (item.startDate && item.startDate < acc) {
      return item.startDate;
    }
    return acc;
  }, today.unix());

  const maxEndDate = itemsState.reduce(
    (acc, item) => {
      if (item.endDate && item.endDate > acc) {
        return item.endDate;
      }
      return acc;
    },
    today
      .add({ day: 1 })
      .hour(12)
      .minutes(0)
      .unix()
  );

  const resultList = itemsState
    .toArray()
    // don't forget to sort child nodes
    .map(item => (item.subItems.length ? { ...item, ...{ items: item.subItems.sort(PositionAscComparer) } } : item))
    .sort(PositionAscComparer);

  // filtering children nodes
  return {
    [TAKS_TREE]: resultList.filter(item => !item.parent),
    minStartDate,
    maxEndDate,
    forPrint
  };
};

@Injectable()
export class RoadmapBoardEffect {
  readonly defaultErrorHandler = <T>(err, caught: Observable<T>): Observable<any> => {
    this._bugTracker.error('roadmap-board-effect', err);
    console.error(err);
    return observableOf(new NoopAction());
  };

  @Effect()
  openedBoardChanges$ = this.actions$.ofType(RoadMapActionTypes.OPEN, RoadMapActionTypes.CLOSE).pipe(
    pluck('payload'),
    distinctUntilChanged(),
    startWith({ id: 0, forPrint: false }),
    switchMap((payload: { id: number; forPrint: boolean }) => {
      if (!payload || !payload.id) {
        return observableOf(new RoadMapInitAction());
      }

      const board$ = this._store.pipe(
        getBoardById(payload.id),
        publishReplay(1),
        refCount(),
        catchError(this.defaultErrorHandler)
      );
      const tasks$ = this._store.pipe(
        pluck(TASK_PL),
        distinctUntilChanged(),
        map(EntityState.from),
        switchMap(
          value =>
            this._dragService.busy$.getValue()
              ? this._dragService.busy$.pipe(filter(isNotPresent), mapTo(value))
              : observableOf(value)
        ),
        filter(() => !this._taskResizeService.busy),
        combineLatest(
          this._store.pipe(pluck(PROJECT_PL), distinctUntilChanged()),
          this._store.pipe(pluck(GUI_STATE_MEMORIZED, COLLAPSED_GROUPS), distinctUntilChanged(), map(defaultValue({}))),
          this._store.pipe(getUsersTasksFilter),
          buildTaskTree(payload.id, payload.forPrint)
        )
      );

      const columns$ = this._store.pipe(getColumnsByBoard(payload.id), publishReplay(1), refCount());

      const defaultColumn$ = columns$.pipe(
        map(xs => xs.find(isEqualType(columnTypes.todo)) || xs[0]),
        filter(isPresent)
      );

      // get first swimlane
      // we suppose that there's always the only swimlane at roadmap board
      const defaultSwimlane$ = this._store.pipe(getSwimlanesByBoard(payload.id), map(xs => xs[0]), filter(isPresent));

      return observableMerge(
        board$.pipe(map(board => new RoadMapDataChangedAction({ board }))),
        tasks$.pipe(map(taskTree => new RoadMapTaskTreeChangedAction(taskTree))),
        defaultColumn$.pipe(map(defaultColumn => new RoadMapDataChangedAction({ defaultColumn }))),
        defaultSwimlane$.pipe(map(defaultSwimlane => new RoadMapDataChangedAction({ defaultSwimlane }))),
        defaultColumn$.pipe(
          pluck('id'),
          distinctUntilChanged(),
          map(defaultColumnId => new RoadMapDataChangedAction({ defaultColumnId }))
        ),
        defaultSwimlane$.pipe(
          pluck('id'),
          distinctUntilChanged(),
          map(defaultSwimlaneId => new RoadMapDataChangedAction({ defaultSwimlaneId }))
        )
      ).pipe(catchError(this.defaultErrorHandler));
    })
  );

  @Effect()
  taskSearchChanged$ = this.actions$.ofType(RoadMapActionTypes.TASK_SEARCH_CHANGED).pipe(
    pluck('payload'),
    distinctUntilChanged(),
    startWith(''),
    switchMap((filterString: string) => {
      filterString = filterString.toLowerCase().trim();
      if (filterString === '') {
        return observableOf(new RoadMapHideItemsAction([]));
      }

      const getItemsToHide = (acc: number[], item: RoadMapItem): number[] => {
        if (
          item.title.toLowerCase().indexOf(filterString) === -1 &&
          item.shortName.toLowerCase().indexOf(filterString) === -1
        ) {
          const toHide = item.subItems.reduce(getItemsToHide, []);
          if (toHide.length === item.subItems.length) {
            acc.push(item.id);
          } else {
            acc = [...acc, ...toHide];
          }
        }
        return acc;
      };

      return this._store
        .select(fromRoadmapBoard.getItemsTree)
        .pipe(map((taskTree: RoadMapItem[]) => new RoadMapHideItemsAction(taskTree.reduce(getItemsToHide, []))));
    })
  );

  @Effect()
  onDrop$ = this._dragService.onDrop$.pipe(
    filter((event: RoadmapDropEvent) => !!event.item),
    map((event: RoadmapDropEvent) => {
      console.warn('drop');
      const data = {
        id: event.item.id,
        parent: event.whereTo.item.parent || 0
      };

      switch (event.whereTo.position) {
        case DraggedActualPositions.after:
          data['position'] = event.whereTo.item.position + 1;
          data['insertAfterTask'] = event.whereTo.item.id;
          break;
        case DraggedActualPositions.before:
          data['position'] = event.whereTo.item.position - 1;
          data['insertBeforeTask'] = event.whereTo.item.id;
          break;
        case DraggedActualPositions.insideFirst:
          const first = event.whereTo.item.subItems[0];
          data['parent'] = event.whereTo.item.id;
          if (first) {
            data['position'] = first.position - 1;
            data['insertBeforeTask'] = first.id;
          }
          break;
        case DraggedActualPositions.insideLast:
          const last = event.whereTo.item.subItems[event.whereTo.item.subItems.length - 1];
          if (last) {
            data['position'] = last.position + 1;
            data['insertAfterTask'] = last.id;
          }
          data['parent'] = event.whereTo.item.id;
          break;
        default: {
          console.warn('unknown drop position!');
        }
      }

      return new PatchEntityAction({ entityName: TASK_PL, data });
    }),
    debounceTime(0),
    tap(() => this._segment.track('RoadmapTaskMovedInList')),
    tap(this._dragService.afterDropCleanUp)
  );

  @Effect()
  onDeleteGroup$ = this.actions$.ofType(RoadMapActionTypes.DELETE_GROUP).pipe(
    pluck('payload'),
    map((groupId: number) => {
      const data = {
        id: groupId,
        archived: 1
      };

      return new PatchEntityAction({ entityName: TASK_PL, data });
    })
  );

  @Effect()
  onChangeTitle$ = this.actions$.ofType(RoadMapActionTypes.CHANGE_TITLE).pipe(
    pluck('payload'),
    mergeMap((data: any) => {
      const path = [fromCamelToDash(TASK_PL)];
      const newData = { id: data.id, title: data.title };
      const oldData = { id: data.id, title: data.oldTitle };

      this._store.dispatch(new EditModelAction({ entityName: TASK_PL, data: newData }));

      return this._atlazApi.patch(path, newData).pipe(
        map(response => new HandleResponseAction(response)),
        catchError(error => {
          this._store.dispatch(
            new EditModelAction({
              entityName: TASK_PL,
              data: oldData
            })
          );

          console.warn('RoadMapActionTypes.CHANGE_TITLE');
          console.error(error);
          this._toastr.error('An error occurred. Title has not been changed.');
          return observableNever();
        })
      );
    })
  );

  @Effect()
  onChangeTaskStatus$ = this.actions$.ofType(RoadMapActionTypes.CHANGE_TASK_STATUS).pipe(
    pluck('payload'),
    mergeMap((data: any) => {
      const path = [fromCamelToDash(TASK_PL)];
      const newData = { id: data.id, column: data.status };
      const oldData = { id: data.id, column: data.oldStatus };
      this._store.dispatch(new EditModelAction({ entityName: TASK_PL, data: newData }));

      return this._atlazApi.patch(path, newData).pipe(
        map(response => new HandleResponseAction(response)),
        catchError(error => {
          this._store.dispatch(
            new EditModelAction({
              entityName: TASK_PL,
              data: oldData
            })
          );

          console.warn('RoadMapActionTypes.CHANGE_TASK_STATUS');
          console.error(error);
          this._toastr.error('An error occured. Status has not been changed.');
          return observableNever();
        })
      );
    })
  );

  @Effect()
  resizeTask$ = this.actions$.ofType(RoadMapActionTypes.RESIZE_TASK).pipe(
    pluck('payload'),
    withLatestFrom(this._store, (a: { itemId: number; leftPx?: number; rightPx?: number }, store) =>
      Object.assign({ store }, a)
    ),
    switchMap(({ itemId, leftPx, rightPx, store }) => {
      const item = fromRoadmapBoard.getItem(store)(itemId);
      const chart = fromRoadmapBoard.chart(store);

      let data = { id: item.id };
      if (leftPx === undefined) {
        // right resize
        data['dueDate'] =
          item.endDate +
          moment(getDayByPx(chart.cells, rightPx)).unix() -
          moment
            .unix(item.endDate)
            .startOf('day')
            .unix();
      } else {
        if (!item.task.dueDate) {
          data['dueDate'] = item.endDate;
        }
        // left resize
        data['startDate'] =
          item.startDate +
          moment(getDayByPx(chart.cells, leftPx)).unix() -
          moment
            .unix(item.startDate)
            .startOf('day')
            .unix();
      }
      return patchRoadMapItem(item, data);
    })
  );

  @Effect()
  moveTask$ = this.actions$.ofType(RoadMapActionTypes.MOVE_TASK).pipe(
    pluck('payload'),
    withLatestFrom(this._store, (a: { itemId: number; offset: number }, store) => Object.assign({ store }, a)),
    switchMap(({ itemId, offset, store }) => {
      const item = fromRoadmapBoard.getItem(store)(itemId);
      const chart = fromRoadmapBoard.chart(store);

      const position = chart.itemPositions[item.id];
      const startDiff =
        moment(getDayByPx(chart.cells, position.leftPx + offset)).unix() -
        moment
          .unix(item.startDate)
          .startOf('day')
          .unix();

      const endDiff =
        moment(
          getPrevWorkingDay(getDayByPx(chart.cells, position.rightPx + offset), this._workingDays.workingDays)
        ).unix() -
        moment
          .unix(item.endDate)
          .startOf('day')
          .unix();

      let data = {
        id: item.id,
        startDate: item.startDate + startDiff,
        dueDate: item.endDate + endDiff
      };
      return patchRoadMapItem(item, data);
    })
  );

  @Effect()
  todayEasy$ = observableInterval(60 * 1000).pipe(
    map(() => moment()),
    startWith(moment()),
    map(day => day.format(BACKEND_DATE_FORMAT)),
    distinctUntilChanged(),
    map(todayId => new RoadMapTodayDateChangedAction(todayId))
  );

  @Effect()
  workingDays$ = this._workingDays.workingDays$.pipe(
    map(workingDays => new RoadMapWorkingDaysChangedAction(workingDays))
  );

  @Effect()
  weekStart$ = this._company.currentCompany$.pipe(
    map(company => company.weekStart || 0),
    distinctUntilChanged(),
    map(weekStart => new RoadMapWeekStartChangedAction(weekStart))
  );

  @Effect()
  scale$ = this.actions$
    .ofType(RoadMapActionTypes.SET_SCALE)
    .pipe(debounceTime(400), map(() => new ApplyCurrentScale()));

  constructor(
    private actions$: Actions,
    private _store: Store<AppState>,
    private _workingDays: WorkingDaysService,
    private _bugTracker: BugTrackerService,
    private _dragService: RoadmapDragService,
    private _taskResizeService: ResizeTaskService,
    private _atlazApi: AtlazApiV2Service,
    private _toastr: ToastrService,
    private _segment: SegmentService,
    private _company: CompanyService
  ) {}
}
