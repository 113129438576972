<form [formGroup]="form" class="add_version_popup">
  <form-errors></form-errors>
  <div>
    <label for="version-name">Version name</label>
    <input id="version-name"
           [focus]="true"
           [formControl]="form.get('name')"
    >
    <help-block [control]="form.get('name')" [messages]="{maxlength: 'Version name exceeds the maximum length (50 symbols)'}"></help-block>
  </div>
  <div *ngIf="showProjectsSelector">
    <label for="project-id">Project</label>
    <select id="project-id" formControlName="project">
      <option *ngFor="let prj of (projects$ | async)" [ngValue]="prj.id">{{prj.name}}</option>
    </select>
    <help-block [control]="form.get('project')"></help-block>
  </div>
  <div>
    <label for="planned-date">Planned date</label>
    <a-datetime-picker id="planned-date" formControlName="plannedDate" name="plannedDate" [isClearBtnEnabled]="true"></a-datetime-picker>
  </div>
  <br><br>
  <div>
    <button a-button class="btn_primary" type="button" [pending]="_formService.isPending$ | async" (click)="onSubmit()">Add</button>
    <button a-button class="btn_text" type="button" (click)="onClose()">Cancel</button>
  </div>
</form>
