import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable ,  Observer } from 'rxjs';
import { FormComponent, FormSaveType, FormServiceParams, FormV2Service } from '../../shared/services/form-v2.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HandleResponseAction } from '../../ngrx/actions/root.action';
import { SWIMLANE_PL } from '../../constants';
import { Store } from '@ngrx/store';
import { Swimlane } from '../../interfaces';
import { AppState } from '../../ngrx/state';

@Component({
  selector: 'swimlane-edit-from',
  templateUrl: './swimlane-edit-from.component.html',
  styleUrls: ['./swimlane-edit-from.component.scss']
})
export class SwimlaneEditFromComponent implements OnInit, FormComponent, OnDestroy {
  @Input() submit$: Observable<any>;
  @Input() boardId: number;
  @Input() swimlane: Swimlane;

  @Output() close = new EventEmitter();

  public form: FormGroup;

  formObserver: Observer<any> = {
    next: resp => {
      this._store.dispatch(new HandleResponseAction(resp));
      this.close.emit();
    },
    error: error => {},
    complete: () => {}
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.add,
    entityToEdit: SWIMLANE_PL,
    formObserver: this.formObserver
  };

  public messages = {
    required: 'Enter name'
  };

  private sub;

  constructor(private _fb: FormBuilder, private _store: Store<AppState>, public _formService: FormV2Service) {}

  ngOnInit() {
    const fields = {
      name: ['', Validators.required],
      board: [this.boardId]
    };

    if (this.swimlane) {
      this.formServiceParams.saveType = FormSaveType.edit;
      fields['id'] = [this.swimlane ? this.swimlane.id : 0];
      fields['name'][0] = this.swimlane.name;
      fields['board'][0] = this.swimlane.board;
    }

    this.form = this._fb.group(fields);

    this._formService.initFormParams(this.form, this.formServiceParams);

    if (this.submit$) {
      this.sub = this.submit$.subscribe(() => this.onSubmit());
    }
  }

  onSubmit() {
    this._formService.markAsDirty();
    if (this.form.valid) {
      this._formService.submit();
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
