<div class="box">
  <input #input_search_box [attr.placeholder]="searchPlaceholder"
         id="search-box-input"
         [class.wide_view]="_wideView"
         [defaultValue]="searchState$ | async | getProperty : 'searchQuery'"
         (blur)="onBlur()"
         (focus)="onFocus()">
  <div class="cross_button" *ngIf="wideView" (click)="onClose()"></div>
  <div *ngIf="isLinksAndTasksVisible" class="recent_tasks" #recent_tasks_box (click)="hideRecentTasks()">
    <span class="subheader">Quick Links:</span>
    <p class="link_img link_img_my_tasks">
      My Tasks :
      <span class="link_text" (click)="onClickMyTasksToDo()">To Do</span> |
      <span class="link_text" (click)="onClickMyTasksInProgress()">In Progress</span> |
      <span class="link_text overdue" (click)="onClickMyTasksDueDate()">Overdue and due</span> |
      <span class="link_text" (click)="onClickMyTasksCompleted()">Completed</span> |
      <span class="link_text" (click)="onClickCreatedByMe()">Created by me</span>
    </p>
    <p (click)="goSearch(tasksBrowserFilterParams, taskBrowserSortParams, true)" class="link_img link_img_task_browser">Tasks Browser</p>
    <div *ngIf="isRecentTasksVisible" >
      <span class="subheader">Recent Tasks:</span>
      <p *ngFor="let task of recentTasks$ | async" [routerLink]="task.link">
        {{task.text}}
      </p>
    </div>
    <div class="overlay"></div>
  </div>
</div>
