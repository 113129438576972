import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textBreak'
})
export class TextBreakPipe implements PipeTransform {
  transform(value) {
    const result = value.replace(/\r?\n/g, '<br/>');

    return result;
  }
}
