<div class="form_info">
  <p class="error" *ngIf="chooseTemplatesError">{{chooseTemplatesError}}</p>
  <p class="info_text">
    Board templates allow you to quickly create a board with specific process. You can customize parts of it later.
  </p>
</div>
<div class="templates_chooser">
  <div class="collections_list">
    <ul>
      <li *ngFor="let collection of (templateCollections$ | async)" (click)="onToggleCollection(collection.name)"
          [ngClass]="{expanded: isCollectionExpanded(collection.name)}"
      >
        {{collection.name}}
      </li>
    </ul>
  </div>
  <div class="collection_items">
    <ul [ngClass]="{'radio': isSingleSelect}">
      <li *ngFor="let template of (chosenCollectionTemplates$ | async)" (click)="onToggleBoard($event, template.id)"
          [ngClass]="{selected: isTemplateSelected(template.id)}"
      >
        <p class="template_name">{{template.name}}</p>
        <p class="template_desc">{{template.description}}</p>
      </li>
    </ul>
  </div>
</div>
