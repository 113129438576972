const TAG_LINK = 'a';

export function isClickLink(event) {
  if (!event) {
    return false;
  }

  const targetTagName = event.target.tagName.toLowerCase();
  return targetTagName === TAG_LINK;
}
