import { ESGitlabRepository } from '../../models/git-repository';
import { Action } from '../../../../ngrx/actions/unsafe-action';

export const UPDATED = '[gitlab-repository.actions] UPDATED';
export const GET_INDEX = '[gitlab-repository.actions] GET_INDEX';
export const GET_INDEX_COMPLETE = '[gitlab-repository.actions] GET_INDEX_COMPLETE';
export const GET_INDEX_FAIL = '[gitlab-repository.actions] GET_INDEX_FAIL';

export class GetIndex implements Action {
  readonly type = GET_INDEX;
}

export class Updated implements Action {
  readonly type = UPDATED;

  constructor(public payload: ESGitlabRepository) {}
}

export class GetIndexComplete implements Action {
  readonly type = GET_INDEX_COMPLETE;

  constructor(public payload: ESGitlabRepository) {}
}

export class GetIndexFail implements Action {
  readonly type = GET_INDEX_FAIL;
}

// prettier-ignore
export type Actions
  = GetIndex
  | Updated
  | GetIndexComplete
  | GetIndexFail
  ;
