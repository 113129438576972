<sidebar-estimate-popup
  *ngIf="isEstimatePopupVisible"
  [task]="task"
  (close)="onCloseEstimatePopup()"
  (resetFocus)="onChildChangedFocus()"
></sidebar-estimate-popup>

<sidebar-logwork-popup
  *ngIf="editPermissions && isLogworkPopupVisible"
  [task]="task"
  (close)="onCloseLogworkPopup()"
  (resetFocus)="onChildChangedFocus()"
></sidebar-logwork-popup>
<div [ngClass]="{sidebar_block: true, editable: editPermissions}" *ngIf="task.estimate || task.loggedTime">
  <div *ngIf="task.estimate && editPermissions" class="task_sidebar_edit_link" (click)="onOpenEstimatePopup()">
    Change Hours Estimate
  </div>
  <h5 class="sidebar_block_title">Time Tracking</h5>
  <time-tracking-progress
    [ngClass]="{editable: editPermissions}"
    [estimate]="task.estimate"
    [loggedTime]="task.loggedTime"
    [widthInPx]="260"
    (changeEstimateLinkEvent)="onOpenEstimatePopup()"
    (openEstimatePopup)="onOpenEstimatePopup()"
    (openLogworkPopup)="onOpenLogWorkPopup()"
    [templateFormat]="''"
  ></time-tracking-progress>

  <button a-button *ngIf="editPermissions" class="btn_secondary" (click)="onOpenLogWorkPopup()">
    <span class="time"></span>
    Log Work
  </button>

</div>
<div class="divider" *ngIf="task.estimate || task.loggedTime"></div>
