<div class="popup_wrap">
  <div class="popup_header">
    <span>Hygger import goes in background</span>
    <close-button (click)="onClose()"></close-button>
    <div class="divider"></div>
  </div>
  <div class="popup_content">
    <div class="divider"></div>
    <p>You can safely leave the page.</p>
    <p>We will notify you when import is complete.</p>
    <button a-button (click)="onClose()" class="btn btn_primary">OK, got It</button>
  </div>
</div>
<overlay (click)="onClose()"></overlay>
