import { distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { UserActionTypes } from '../actions/user.actions';
import { AppState, EntityState, ESInterface } from '../state';
import { Project, Task, User } from '../../interfaces';
import {
  getAllEntitiesAsArray,
  getEntitiesByIds,
  getEntityByIdSelector,
  getEntityByIdsSelector,
  getEntitySelector
} from '../functions/selectors';
import { addEntityState, editEntityState } from '../functions/reducer';
import { Observable } from 'rxjs';
import { USER_PL } from '../../constants';
import { compose } from '@ngrx/store';
import { getBoardUserIds } from './board.reducer';
import { Action } from '../actions/unsafe-action';
import { both, compareArrays } from '../../../helpers';
import { share } from '../functions/util';
import { createSelector } from 'reselect';

const initialState: ESInterface<User> = {
  ids: [],
  entities: {},
  selectedEntityId: null
};

export function reducer(state = initialState, action: Action): ESInterface<User> {
  switch (action.type) {
    case UserActionTypes.ADD_COMPLETE: {
      return addEntityState(state, action.payload, false);
    }

    case UserActionTypes.DELETE: {
      const user = action.payload;
      return editEntityState(state, { id: user.id, deleted: 1 });
    }

    case UserActionTypes.EDIT: {
      return editEntityState(state, action.payload);
    }

    default: {
      return state;
    }
  }
}

export const isNotBot = (user: User) => !user.isBot;
export const isActiveUser = (user: User) => Boolean(!user.deleted && user.confirmed);
export const isUnconfirmedUser = (user: User) => Boolean(!user.deleted && !user.confirmed);
export const activeUsersFilter = (users: User[]) => users.filter(both(isActiveUser, isNotBot));
export const unconfirmedUsersFilter = (users: User[]) => users.filter(both(isUnconfirmedUser, isNotBot));

export function filterActiveUsers<T>(state$: Observable<User[]>): Observable<User[]> {
  return state$.pipe(map((state: User[]) => state.filter(isActiveUser)), map(filterBots));
}

export function filterNotDeletedUsers<T>(state$: Observable<User[]>): Observable<User[]> {
  return state$.pipe(map((state: User[]) => state.filter(user => !user.deleted)));
}

export const filterBots = (users: User[]) => users.filter(isNotBot);

export const getAllUsers = share(compose(getAllEntitiesAsArray, getEntitySelector(USER_PL)));
export const getAllActiveUsers = share(compose(filterActiveUsers, getAllEntitiesAsArray, getEntitySelector(USER_PL)));
export const getUserById = id => share(compose(getEntityByIdSelector(id), getEntitySelector(USER_PL)));

export const getBoardUsers = share(compose(filterNotDeletedUsers, boardUsersSelector));

function boardUsersSelector(state$: Observable<AppState>) {
  return state$.pipe(
    getBoardUserIds,
    switchMap(usersIds => state$.pipe(getEntitiesByIds(USER_PL, usersIds))),
    distinctUntilChanged(compareArrays)
  );
}

export const getUsersByIds = ids => compose(getEntityByIdsSelector<User>(ids), getEntitySelector(USER_PL));

/**
 * selectors that uses reselect library
 */
export const userState = (store: AppState) => <ESInterface<User>>store[USER_PL];
export const userEntities = createSelector(userState, state => state.entities);

export const injectUsersCompanyAttributes = (
  updatedUsers: ESInterface<Task>,
  updatedUsersCompanies: ESInterface<Project>,
  currentCompanyId: number,
  newState: any
) => {
  const handledUsersMap = {};
  const usersCompaniesNewState = EntityState.from(newState.usersCompanies);
  if (!updatedUsers) {
    newState.users = { ...newState.users };
    newState.users.entities = { ...newState.users.entities };
  }
  const updateUser = (userId, userCompanyId?) => {
    if (handledUsersMap[userId]) {
      return;
    }

    const userCompany = userCompanyId
      ? usersCompaniesNewState.get(userCompanyId)
      : usersCompaniesNewState.find(uc => uc.company === currentCompanyId && uc.user === userId);

    const user = newState.users.entities[userId];

    if (!user) {
      return;
    }

    if (!userCompany) {
      // no uc - assume that user is deleted
      newState.users.entities[user.id] = {
        ...user,
        role: '',
        nickname: '',
        company: userCompanyId,
        deleted: 1,
        confirmed: 0
      };
      return;
    }

    // update user

    newState.users.entities[user.id] = {
      ...user,
      userCompanyId: userCompany.id,
      role: userCompany.role,
      nickname: userCompany.nickname || '',
      company: userCompany.company,
      deleted: userCompany.deleted,
      confirmed: userCompany.confirmed
    };

    // update finished

    handledUsersMap[userId] = true;
  };

  if (updatedUsersCompanies) {
    EntityState.from(updatedUsersCompanies)
      .filter(userCompany => userCompany.company === currentCompanyId)
      .forEach(userCompany => updateUser(userCompany.user, userCompany.id));
  }

  if (updatedUsers) {
    updatedUsers.ids.forEach(userId => updateUser(userId));
  }

  return newState;
};

export namespace fromUsers {
  export const getState = (store: AppState) => {
    return <ESInterface<User>>store[USER_PL];
  };
  export const getById = id => (store: AppState) => getState(store).entities[id];
  export const getByIds = ids =>
    createSelector(getState, state => {
      const result: { [prop: number]: User } = {};
      ids.forEach(id => {
        if (state.entities[id]) {
          result[id] = state.entities[id]; //filter duplicates
        }
      });
      return <User[]>Object.values(result);
    });
  export const getMapByIds = ids =>
    createSelector(getState, state =>
      ids.reduce((acc, item) => {
        if (state.entities[item]) {
          acc[item] = state.entities[item];
        }
        return acc;
      }, {})
    );

  export const getAllActive = createSelector(getState, state =>
    Object.values(state.entities).filter(user => !user.deleted)
  );
}
