import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'markdownBreak'
})
export class MarkdownBreakPipe implements PipeTransform {
  transform(value) {
    return typeof value === 'string' ? value.replace(/([^ ]|[^ ] )\n/g, '$1  \n') : value;
  }
}
