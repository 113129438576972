import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppHeaderComponent } from './app-header/app-header.component';
import { AppBodyComponent } from './app-body/app-body.component';
import { SubHeaderComponent } from './sub-header/sub-header.component';
import { AppBodyInnerComponent } from './app-body-inner/app-body-inner.component';
import { ContextPopupComponent } from './context-popup/context-popup.component';
import { SharedModule } from '../shared/shared.module';
import { ProfileMenuComponent } from './header/profile-menu/profile-menu.component';
import { GlobalSearchModule } from '../global-search/global-search.module';
import { AtlazGuiModule } from '../atlaz-gui/atlaz-gui.module';
import { CompaniesSubMenuComponent } from './header/profile-menu/companies-sub-menu/companies-sub-menu.component';
import { DefaultFilterMemberListComponent } from './header/default-filter-member-list/default-filter-member-list.component';
import { BillingModule } from '@atlaz/billing/billing.module';
import { LabelCheckSvgComponent } from './header/default-labels-filter/labels-filter-list/label-check-svg/label-check-svg.component';
import { HeaderInfoMenuComponent } from './header/header-info-menu/header-info-menu.component';
import { HeaderInfoSvgComponent } from './header/header-info-menu/header-info-menu-icons/header-info-svg.component';
import { HeaderHelpSvgComponent } from './header/header-info-menu/header-info-menu-icons/header-help-svg.component';
import { HeaderRoadmapSvgComponent } from './header/header-info-menu/header-info-menu-icons/header-roadmap-svg.component';
import { HeaderFeatureSvgComponent } from './header/header-info-menu/header-info-menu-icons/header-feature-svg.component';
import { HeaderBugSvgComponent } from './header/header-info-menu/header-info-menu-icons/header-bug-svg.component';
import { BillingSharedModule } from '@atlaz/billing/shared/components/billing-shared.module';
import { TaskModule } from '../task/task.module';
import { HeaderUniversitySvgComponent } from './header/header-info-menu/header-info-menu-icons/header-university-svg.component';
import { QuickAddComponent } from './app-header/quick-add/quick-add.component';
import { NewBoardContextPopupComponent } from './app-header/quick-add/new-board-context-popup/new-board-context-popup.component';
import { NewProjectContextPopupComponent } from './app-header/quick-add/new-project-context-popup/new-project-context-popup.component';

const components = [
  AppHeaderComponent,
  AppBodyComponent,
  SubHeaderComponent,
  AppBodyInnerComponent,
  ContextPopupComponent,
  ProfileMenuComponent,
  CompaniesSubMenuComponent,
  DefaultFilterMemberListComponent,
  LabelCheckSvgComponent,
  HeaderInfoMenuComponent,
  HeaderInfoSvgComponent,
  HeaderHelpSvgComponent,
  HeaderUniversitySvgComponent,
  HeaderRoadmapSvgComponent,
  HeaderFeatureSvgComponent,
  HeaderBugSvgComponent,
  QuickAddComponent,
  NewBoardContextPopupComponent,
  NewProjectContextPopupComponent
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    AtlazGuiModule,
    BillingModule,
    GlobalSearchModule,
    BillingSharedModule,
    TaskModule
  ],
  declarations: components,
  exports: components
})
export class AppContainersModule {}
