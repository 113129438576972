<ng-template [ngIf]="(collection$ | async) && (collection$ | async).boardsIds.length">
  <div class="collection_title" (click)="onOpenBoardsList()">
    <h3>{{(collection$ | async).name}}</h3>
    <div class="arrow_state_icon" [ngClass]="{'closed_state': !isVisibleBoards}">
      <svg width="18" height="18">
        <use xlink:href="#triangle"></use>
      </svg>
    </div>
  </div>
  <left-board-list *ngIf="isVisibleBoards"
                   [collection]="(collection$ | async)"
                   [boards]="boards$ | async"
  ></left-board-list>
</ng-template>
