import { distinctUntilChanged, map, pluck, publishReplay, refCount } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { AuthService } from '../../shared/services/app/auth.service';

import { PartOfEntity, Task, TaskActivity, User, WorkLog } from '../../interfaces';
import { AppState } from '../../ngrx/state';
import { getAllTaskActivities } from '../../ngrx/reducers/task-activity.reducer';
import { activityType, USER_COMPANY_PL } from '../../constants';
import {
  TaskActivityAddAction,
  TaskActivityDeleteAction,
  TaskActivityEditAction
} from '../../ngrx/actions/task-activity.actions';
import { GetDeletedUsers } from '../../ngrx/actions/user.actions';
import { getTaskWorkLogs } from '../../ngrx/reducers/worklog.reducer';
import { createFilterByTaskId, filter, sortByField } from '../../../helpers';
import { TaskSidebarService } from '../shared/services/task-sidebar.service';
import { ChecklistAddAction } from '../../ngrx/actions/checklist.actions';
import { SegmentService } from '../../atlaz-bnp/services/intergations/segment/segment.service';
import * as fromLoadedPages from '../../loaded-data/store/loaded-data.reducer';

export const isComment = activity => activity.type === activityType.comment;

@Component({
  selector: 'task-activity',
  templateUrl: './task-activity.component.html',
  styleUrls: ['./task-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskActivityComponent implements OnInit {
  @Input() taskId: number;
  @Input() task: Task;
  @Input() boardUsers: Observable<User[]>;
  @Input() editPermissions = false;
  @Input() editCommentsPermissions = false;
  @Input() isNotGuest = false;
  @Output() resetFocus = new EventEmitter();

  public isLogworkVisible = false;
  public isChecklistPopupVisible = false;
  public isAddAttachmentPopUpVisible = false;

  public replays$: Observable<TaskActivity>;
  public taskActivities$: Observable<TaskActivity[]>;
  public commentsOnly$: Observable<TaskActivity[]>;
  public logWorks$: Observable<WorkLog[]>;
  public isLoaded$: Observable<boolean>;

  constructor(
    private _authService: AuthService,
    private _store: Store<AppState>,
    private _segment: SegmentService,
    private _taskSidebarService: TaskSidebarService
  ) {}

  get boardUsers$() {
    return this.boardUsers;
  }

  ngOnInit(): any {
    this.isLoaded$ = this._store
      .select(fromLoadedPages.wasOpenedTaskFn)
      .pipe(map(isLoadedFn => !!isLoadedFn(this.taskId)));

    this.getDeletedUserList();

    const allTaskActivities$ = <Observable<TaskActivity[]>>this._store.pipe(
      getAllTaskActivities,
      map(createFilterByTaskId(this.taskId)),
      map(sortByField('createdAt', 'desc')),
      publishReplay(1),
      refCount()
    );

    const isParent = activity => !activity.parent;
    const onlyParent = filter(isParent);
    const activityGroup$ = allTaskActivities$.pipe(map(this.groupActivities), publishReplay(1), refCount());
    this.taskActivities$ = allTaskActivities$.pipe(map(onlyParent));
    this.commentsOnly$ = <Observable<TaskActivity[]>>activityGroup$.pipe(
      pluck('comment'),
      distinctUntilChanged(),
      publishReplay(1),
      refCount()
    );
    this.replays$ = <Observable<TaskActivity>>activityGroup$.pipe(
      pluck('replays'),
      distinctUntilChanged(),
      publishReplay(1),
      refCount()
    );

    this.logWorks$ = <Observable<WorkLog[]>>this._store.pipe(
      getTaskWorkLogs(this.taskId),
      map(workLogs =>
        workLogs.sort((a: WorkLog, b: WorkLog) => {
          if (a.logDate === b.logDate) {
            return a.createdAt > b.createdAt ? -1 : 1;
          }
          return a.logDate > b.logDate ? -1 : 1;
        })
      ),
      publishReplay(1),
      refCount()
    );
  }

  onSaveComment(commentData: any) {
    this.saveComment(commentData);
  }

  onDeleteComment(comment: TaskActivity) {
    this.deleteComment(comment);
  }

  getDeletedUserList() {
    this._store.dispatch(new GetDeletedUsers({ deleted: 1, confirmed: 1, expand: USER_COMPANY_PL, limit: 500 }));
  }

  onChildChangedFocus(event) {
    this.resetFocus.emit(event);
  }

  public saveComment(partialComment: PartOfEntity) {
    if (partialComment.hasOwnProperty('id')) {
      this.editComment(partialComment);
    } else {
      this.addComment(partialComment);
    }
  }

  public addComment(commentData: PartOfEntity) {
    this._store.dispatch(new TaskActivityAddAction(commentData));
    this._segment.commentCreated(commentData.parent !== 0);
  }

  public editComment(commentData: PartOfEntity) {
    this._store.dispatch(new TaskActivityEditAction(commentData));
  }

  public deleteComment(comment: TaskActivity) {
    this._store.dispatch(new TaskActivityDeleteAction(comment));
  }

  public groupActivities(activities: TaskActivity[]) {
    const addReplay = (replays: { [parentId: number]: TaskActivity[] }, replay: TaskActivity) =>
      (replays[replay.parent] = replays.hasOwnProperty(replay.parent) ? [replay, ...replays[replay.parent]] : [replay]);

    return activities.reduce(
      (group, activity) => {
        if (!isComment(activity)) {
          return group;
        }
        if (+activity.parent > 0) {
          addReplay(group.replays, activity);
        } else {
          group.comment.push(activity);
        }
        return group;
      },
      { comment: [], replays: {} }
    );
  }

  onSwitchLogwork() {
    this.isLogworkVisible = !this.isLogworkVisible;
  }
  onSwitchAttachmentsPopUp() {
    this.isAddAttachmentPopUpVisible = !this.isAddAttachmentPopUpVisible;
  }
  onSwitchChecklist() {
    this.isChecklistPopupVisible = !this.isChecklistPopupVisible;
  }

  onAddChecklist(checkListData: PartOfEntity) {
    this.onSwitchChecklist();
    this._store.dispatch(new ChecklistAddAction(checkListData));
  }
}
