<div a-context-popup
     class="duedate_popup"
     [title]="title"
     (close)="onCloseEstimatePopup()"
>
  <task-estimate
    [isStoryPointsMode]="isStoryPointsMode"
    [task]="task"
    (close)="onCloseEstimatePopup()"
    (resetFocus)="onChildChangedFocus($event)"
  ></task-estimate>
</div>
