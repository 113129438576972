export const SEPARATOR = '/';
export const SEP = SEPARATOR;

export const BOARDS = 'b';
export const TASKS = 't';
export const LOGIN = 'login';
export const LOGIN_ERROR = 'login-error';
export const ACCESS_TO_COMPANY_DENIED = 'company-access-denied';
export const LOGOUT = 'logout';
export const COLLECTIONS = 'collections';
export const COLLECTION_SETTINGS = 'collection-settings';
export const IMPORT = 'import';
export const EXPORT = 'export';
export const IMPORT_BACK_URL = 'import-return';
export const COLUMN = 'column';
export const INVITE = 'invite-member';
export const JIRA_INVITE_MEMBERS = 'jira-invite-members';

export const SPRINT_START = 'sprint-start';
export const SPRINT_STOP = 'stop-sprint';
export const SPRINT_RELEASE = 'release-sprint';
export const CLOSED_BOARD = 'closed-board';
export const PUSH_ALL_TASKS = 'push-all-tasks';
export const BACKLOG_CRITERIA = 'backlog-criteria';
export const IMPORT_HAS_STARTED = 'import-has-started';
export const REPORTING = 'reporting';
export const COMPLETED = 'completed';
export const IN_PROGRESS = 'in-progress';
export const TIMESHEET = 'timesheet';
export const TEAM_POINTS = 'team-points';
export const TIME_TRACKED = 'time-tracked';
export const COMPANY_SETTINGS = 'company-settings';
export const GENERAL = 'general';
export const MEMBERS = 'members';
export const GROUPS = 'groups';
export const PROJECTS = 'projects';
export const INTEGRATIONS = 'integrations';
export const SETTINGS = 'settings';
export const PROJECT = 'project';
export const VERSIONS = 'versions';
export const VERSION = 'version';
export const CLOSED = 'closed';
export const SEARCH = 'search';
export const WIP = 'wip';
export const BILLING = 'billing';

export const NEW = 'new';
export const COPY = 'copy';
export const CAPACITY = 'capactiy';

export const BACKLOG_CHART = 'backlog-chart';
export const SCORING_TABLE = 'scoring-table';
export const BURNDOWN_CHART = 'burndown-chart';
export const SPRINT_BOARD_HISTORY = 'released-sprints';
export const SPRINT_RELEASE_REPORT = 'release-report';
export const VELOCITY_REPORT = 'velocity-report';

export const GET_STARTED = 'get-started';
export const CREATE_NEW_PROJECT = 'create-new-project';
export const INVITE_MEMBERS = 'invite-members';
export const COPY_COLUMNS = 'copy-columns';
export const MASS_INVITE = 'mass-invite';
export const COPY_BOARDS = 'copy-boards';
export const TASKS_MASS_MOVE = 'tasks-mass-move';
export const INITIATE_BOARDS = 'initiate-boards';
export const SCORING_TYPE_CHOOSER = 'scoring-type-chooser';

/**
 * in atlaz-bnp.component we use literal because issue
 * @link https://github.com/angular/angular/issues/12522
 */
export const OPENED_TASK_OUTLET = 'opened-task';
export const POPUP_OUTLET = 'popup';

export const SLACK = 'slack';
export const SLACK_NEW = 'new';
export const SLACK_EDIT = 'edit';
export const SLACK_ADD = 'add-team';

export const INVITE_LINK_POPUP = 'invite-link';

export const ADD_INTEGRATION = 'add';
export const GITHUB_SETTINGS = 'github';
export const BITBUCKET_SETTINGS = 'bitbucket';
export const GITLAB_SETTINGS = 'gitlab';
export const INTERCOM_SETTINGS = 'intercom';
export const JIRA_SETTINGS = 'jira';
export const GIT_MANATE_REPOS = 'manage-repo';
export const LOAD_ERROR_PAGE = 'load-error-page';

export const PDF_EXPORT = 'pdf-export';

export const REPORT_TASKS_USERS = 'report-tasks-users';
export const REPORT_TEAM_POINTS = 'report-team-points';
export const REPORT_TIME_TRACKED = 'report-time-tracked';
export const REPORT_TASKS = 'report-tasks';
