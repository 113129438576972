<div class="search_filter" [ngClass]="{sticky_filter: stickyFlagEnabled$ | async}">
  <div class="search_bg"></div>
  <search-filter class="filter_container"></search-filter>
  <div class="result_container">
    <div *ngIf="searchQuery.length === 0 && !isEmptySearchQAvailable">
      You should type anything to start search.
    </div>
    <div *ngIf="searchQuery.length > 0 || isEmptySearchQAvailable">
      <h2>Search results: {{searchState.count}}</h2>
      <sort-order class="pull_right"></sort-order>
    </div>
    <search-result *ngIf="showSearchResults"
                   [searchQuery]="searchQuery"
                   [errorMessage]="searchState.errorMessage"
                   [resultIds]="searchState.result">
    </search-result>
    <div>
      <!--  show more or pending block -->
      <div *ngIf="searchState.pending" class="loading_message">
        <a-loader></a-loader>
      </div>
      <!-- show more if have results, & aren't showed all results -->
      <button a-button *ngIf="canShowMore" (click)="onClickLoadMore()" class="btn_primary">Show more</button>
    </div>
  </div>
</div>
