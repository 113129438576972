import { Component, ChangeDetectionStrategy } from '@angular/core';

import { Project } from '../../../interfaces';

import { DropdownFilterComponent } from './dropdown-filter.component';

@Component({
  selector: 'project-filter',
  templateUrl: './dropdown-filter.component.html',
  styleUrls: ['./task-feed-filter.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectFilterComponent extends DropdownFilterComponent<Project> {
  constructor() {
    super();
    super.initFields('Projects', 'shortName', 'name');
  }
}
