import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'smart-link',
  template: `<a [href]="href" target="_blank" rel="noopener">{{value}}</a>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartLinkComponent implements OnChanges {
  @Input() value = '';

  public href = '';
  private notAllowChars = [',', '.', '!', '?', "'", '"', '*', ')', '(', ']', '[', '}', '{'];

  ngOnChanges() {
    const lastChar = this.value.substr(this.value.length - 1);
    this.href = this.notAllowChars.includes(lastChar) ? this.value.slice(0, -1) : this.value;
  }
}
