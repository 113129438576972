import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Board, Task } from '../../../interfaces';
import { ScaleType, ScaleTypeLabel, TASK_PL } from '../../../constants';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../../shared/services/form-v2.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { Observer, Subscription } from 'rxjs/index';
import { JsonApiSingeModelResponse } from '../../../shared/services/app/web-socket/http-response';
import { HandleResponseAction } from '../../../ngrx/actions/root.action';

@Component({
  selector: 'edit-basic-scoring',
  templateUrl: './edit-basic-scoring.component.html',
  styleUrls: ['./edit-basic-scoring.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditBasicScoringComponent implements OnInit {
  @Input() task: Task;
  @Input() focusOn: string;
  @Input() board: Board;

  @Output() close = new EventEmitter();

  public ScaleType = ScaleType;
  public ScaleTypeLabel = ScaleTypeLabel;

  formObserver: Observer<any> = {
    next: (resp: JsonApiSingeModelResponse<any>) => {
      this._store.dispatch(new HandleResponseAction(resp));
      this.onClose();
    },

    error: () => {},

    complete: () => {}
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.edit,
    entityToEdit: [TASK_PL],
    formObserver: this.formObserver
  };

  form: FormGroup;
  subs: Subscription[] = [];

  constructor(private _fb: FormBuilder, private _store: Store<AppState>, public _formService: FormV2Service) {}

  ngOnInit() {
    this.form = this._fb.group({
      id: this.task.id,
      backlogScoreX: this.task.backlogScoreX,
      backlogScoreY: this.task.backlogScoreY
    });

    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  onSubmit() {
    this.form.markAsTouched();
    this.checkValidation('backlogScoreX');
    this.checkValidation('backlogScoreY');
    this._formService.submit();
  }

  checkValidation(formField) {
    const validationValue = +this.form.value[formField];

    if (validationValue > 100) {
      this.form[formField].patchValue(100);
    } else if (validationValue < 0) {
      this.form[formField].patchValue(Math.abs(validationValue));
    }
  }

  onClose() {
    this.close.emit();
  }
}
