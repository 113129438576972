import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import * as BillingUrl from '@atlaz/billing/util/billing-url';
import { THERE_D_SECURE_POPUP } from '@atlaz/billing/util/billing-url';

import { accountServiceUrl, removeEmptyProperties } from '../../../helpers';
import { AppUrls } from '../../app-urls';
import { skipLocationChange as slackSkipLocationChange } from '../../integrations/settings/slack-alert/config';
import { skipLocationChange as githubSkipLocationChange } from '../../integrations/settings/github-settings/config';
import { skipLocationChange as bitbucketLocationChange } from '../../integrations/settings/bitbucket-settings/config';
import { skipLocationChange as gitlabLocationChange } from '../../integrations/settings/gitlab-settings/config';
import * as PATH from '../../path.routing';
import { LOGIN, OPENED_TASK_OUTLET, POPUP_OUTLET, SEP } from '../../path.routing';
import { BillingPlanName } from '@atlaz/billing/constants/billing-subscription';

export const getAppReturnUrl = () =>
  document.location.href.substr(0, document.location.href.length - document.location.pathname.length);

@Injectable()
export class RouterNavigateService {
  public navigated = this._router.navigated;
  public navigateByUrl = this._router.navigateByUrl.bind(this._router);

  constructor(private _router: Router) {}

  public navigateToOverview() {
    return this.navigate(AppUrls.getUrlOverview());
  }

  public navigateToOverviewWithBoardsCreating() {
    return this.navigateToOverview().then(() => this.navigate(AppUrls.getUrlOverviewWithBoardsCreating()));
  }

  public navigateToSearch(searchQuery: string, filterParams?: {}, sortParams?: {}) {
    filterParams = this.prepareGlobalSearchParam(searchQuery, filterParams, sortParams);
    return this.navigate(AppUrls.getUrlGlobalSearch(filterParams));
  }

  public prepareGlobalSearchParam(searchQuery: string, filterParams?: {}, sortParams?: {}) {
    filterParams = filterParams ? Object.assign({}, filterParams) : {};
    filterParams['query'] = searchQuery;
    filterParams = Object.assign(filterParams, sortParams);
    filterParams = removeEmptyProperties(filterParams);

    return filterParams;
  }

  public navigateToBoards(): Promise<boolean> {
    return this.navigate(AppUrls.getUrlBoards());
  }

  public navigateToCloseBoard(boardName: string) {
    return this._router.navigate(AppUrls.getUrlClosedBoard(boardName), { skipLocationChange: true });
  }

  public navigateToBoard(boardId: number): Promise<boolean> {
    return this.navigate(AppUrls.getUrlBoard(boardId));
  }

  public navigateToScoringTypeChooser() {
    return this.navigate(AppUrls.getUrlScoringTypeChooser());
  }

  public navigateToProjectSettings(projectId: number) {
    return this.navigate(AppUrls.getUrlProjectSettings(projectId));
  }

  public navigateToTask(taskId, extras?: NavigationExtras) {
    return this._router.navigate(AppUrls.getUrlTask(taskId), extras);
  }

  public navigateImportStartedPopup() {
    return this._router.navigate(AppUrls.getImportStartedPopup(), { skipLocationChange: true });
  }

  public deactivatePopupOutlet() {
    return this._router.navigate(AppUrls.getUrlDeactivateOutlet(POPUP_OUTLET));
  }

  public deactivateOpenedTaskOutlet() {
    return this._router.navigate(AppUrls.getUrlDeactivateOutlet(OPENED_TASK_OUTLET));
  }

  public navigateToWIPForm(boardId: number, columnId: number) {
    return this._router.navigate(AppUrls.getUrlWipForm(boardId, columnId));
  }

  public navigateToPushAllTasks(columnId: number) {
    return this._router.navigate(AppUrls.getUrlPushAllTasks(columnId));
  }

  public navigate(urlParameters: any[] | any, extras?: NavigationExtras): Promise<boolean> {
    if (typeof urlParameters === 'string') {
      urlParameters = urlParameters.split('/');
    }
    return this._router.navigate(urlParameters, extras);
  }

  public navigateToPricing() {
    return this._router.navigate(BillingUrl.ROUTER_LINKS.pricing, { skipLocationChange: true });
  }
  public navigateEditPaymentDetails(isMonthly, plan?) {
    return this._router.navigate(BillingUrl.ROUTER_LINKS.newSubscription, {
      skipLocationChange: true,
      queryParams: {
        isMonthly: !!isMonthly,
        plan: plan || BillingPlanName.Basic
      }
    });
  }
  public navigateToEditBillingSubscribtion() {
    return this._router.navigate(BillingUrl.ROUTER_LINKS.editSubscription, { skipLocationChange: true });
  }
  public navigateToChangeCardFrom() {
    return this._router.navigate(BillingUrl.ROUTER_LINKS.changeCard, { skipLocationChange: true });
  }
  public navigate3DSecurePopup(metadata?: any) {
    return this._router.navigate([PATH.SEP, { outlets: { [PATH.POPUP_OUTLET]: [THERE_D_SECURE_POPUP] } }], {
      skipLocationChange: true,
      queryParams: metadata ? { ...metadata } : undefined
    });
  }

  public navigateToLoginAccessError() {
    return this._router.navigate(AppUrls.getUrlLoginAccessError(), { skipLocationChange: true });
  }

  public navigateToCompanySettings() {
    return this._router.navigate([PATH.COMPANY_SETTINGS]);
  }

  public navigateToBilling() {
    return this._router.navigate([PATH.COMPANY_SETTINGS, BillingUrl.BILLING]);
  }

  public navigateToForbidden() {
    return this._router.navigate([403]);
  }

  public navigateToLoginError() {
    return this._router.navigate(AppUrls.getUrlLoginError(), { skipLocationChange: true });
  }

  public navigateToLogin() {
    window.location.href =
      accountServiceUrl +
      '/login?return_url=' +
      encodeURIComponent(getAppReturnUrl() + SEP + LOGIN) +
      '&return_path=' +
      encodeURIComponent(document.location.pathname);
  }

  public navigateToLogout() {
    window.location.href = accountServiceUrl + '/logout';
  }

  public navigateToSlackIndex(boardId: number) {
    this._router.navigate(AppUrls.getUrlSlackIndex(boardId), { skipLocationChange: slackSkipLocationChange });
  }

  public navigateToNewSlackAlert(boardId: number) {
    this._router.navigate(AppUrls.getUrlNewSlackAlert(boardId), { skipLocationChange: slackSkipLocationChange });
  }

  public navigateToAddSlackTeam(boardId: number) {
    this._router.navigate(AppUrls.getUrlAddSlackTeam(boardId), { skipLocationChange: slackSkipLocationChange });
  }

  public navigateToGithubSettings() {
    this._router.navigate(AppUrls.getUrlGithubSettingsIndex(), { skipLocationChange: githubSkipLocationChange });
  }

  public navigateToGithubAddCompany() {
    this._router.navigate(AppUrls.getUrlGithubSettingsAddCompany(), { skipLocationChange: githubSkipLocationChange });
  }

  public navigateToManageRepos() {
    this._router.navigate(AppUrls.getUrlGithubManageRepos(), { skipLocationChange: githubSkipLocationChange });
  }

  public navigateToBitbucketSettings() {
    this._router.navigate(AppUrls.getUrlBitbucketSettingsIndex(), {
      skipLocationChange: bitbucketLocationChange
    });
  }

  public navigateToBitbucketAddCompany() {
    this._router.navigate(AppUrls.getUrlBitbucketSettingsAddCompany(), {
      skipLocationChange: bitbucketLocationChange
    });
  }

  public navigateToGitlabSettings() {
    this._router.navigate(AppUrls.getUrlGitlabSettingsIndex(), {
      skipLocationChange: gitlabLocationChange
    });
  }

  public navigateToIntercomSettings(queryParams = {}) {
    this._router.navigate(AppUrls.getUrlIntercomSettingsIndex(), {
      skipLocationChange: true,
      queryParams: queryParams
    });
  }

  public navigateToJiraSettings(queryParams = {}) {
    this._router.navigate(AppUrls.getUrlJiraSettingsIndex(), {
      skipLocationChange: true,
      queryParams: queryParams
    });
  }

  public navigateToGitlabAddCompany() {
    this._router.navigate(AppUrls.getUrlGitlabSettingsAddCompany(), {
      skipLocationChange: gitlabLocationChange
    });
  }

  public navigateToIntercomAddCompany(queryParams = {}) {
    this._router.navigate(AppUrls.getUrlIntercomSettingsAddCompany(), {
      skipLocationChange: true,
      queryParams: queryParams
    });
  }

  public navigateToJiraAddCompany(queryParams = {}) {
    this._router.navigate(AppUrls.getUrlJiraSettingsAddCompany(), {
      skipLocationChange: true,
      queryParams: queryParams
    });
  }

  public navigateToGitlabManageRepos() {
    this._router.navigate(AppUrls.getUrlGitlabManageRepos(), { skipLocationChange: gitlabLocationChange });
  }

  public navigateToPriorityChart(bordId, extras?) {
    return this._router.navigate(AppUrls.getUrlBacklogChart(bordId), extras);
  }
}
