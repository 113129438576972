import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges
} from '@angular/core';
import * as d3 from 'd3';
import * as moment from 'moment-mini-ts';
import { Moment } from 'moment-mini-ts';
import { BehaviorSubject } from 'rxjs';
import { trackById } from '../../../../helpers/';
import { DATE_KEY } from '../../burndown-chart.component';
import { WorkingDaysService } from '@atlaz/working-days/services/working-days.service';
import { BACKEND_DATE_FORMAT } from '../../../libs/date-time-formatter/constants/date-time-formats';
import { EstimationType } from '../../../constants';
import { BurnEvent, BurnEventType } from '../chart-wrapper/burndown-chart-wrapper';
import { SprintsTasks } from '../../../interfaces/sprints-tasks';

const enumerateDaysBetweenDates = (startDate: Moment, endDate: Moment): string[] => {
  const now = startDate;
  const dates = [];

  while (now.isBefore(endDate, 'day') || now.isSame(endDate, 'day')) {
    dates.push(now.format(DATE_KEY));
    now.add(1, 'days');
  }
  return dates;
};

export interface DataLineType {
  logDate: Date;
  date: string;
  burn: number;
  value: number;
  burnEvent?: BurnEvent;
  sprintsTask?: SprintsTasks;
  userId?: number;
}

interface LineValues {
  date: Date;
  // hours or story points
  value: number;
}

@Component({
  selector: 'burndown-chart-body',
  templateUrl: './burndown-chart-body.component.html',
  styleUrls: ['./burndown-chart-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BurndownChartBodyComponent implements OnChanges, AfterContentInit {
  @Input() mode = EstimationType.storyPoints;
  @Input() sumToBurn: number;
  @Input() startTs: number;
  @Input() endTs: number;
  @Input() releasedTs: number;

  @Input() burnEvents: BurnEvent[];

  public BurnEventType = BurnEventType;

  actualLineData: DataLineType[];
  public groupedActivitiesByDate: DataLineType[] = [];
  private groupedActivitiesByDateMap = {};
  idealLineData: DataLineType[] = [];

  public readonly trackById = trackById;

  public selectedActivity;
  public isOpenActivityTooltip$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public tooltipPositionClass;
  public topPxActivityTooltip = 0;
  public leftPxActivityTooltip = 0;
  public tasksActivityTooltipData;

  public defaultWidth = 960;
  public defaultHeight = 440;

  public margin = { top: 10, right: 20, bottom: 60, left: 53 };
  public width = this.defaultWidth - this.margin.left - this.margin.right;
  public height = this.defaultHeight - this.margin.top - this.margin.bottom;
  public svg;

  public xScale = d3
    .scaleUtc()
    .domain([0, 0])
    .range([0, this.width]);
  public yScale = d3
    .scaleLinear()
    .domain([0, 0])
    .range([this.height, 0]);

  public idealLine;
  public actualLine;

  public dayOffs = [];
  private initialized = false;

  get yAxisLabel() {
    return this.mode === EstimationType.hours ? 'Hours' : 'Story Points';
  }

  constructor(private _workingDays: WorkingDaysService, private _cd: ChangeDetectorRef) {}

  updateChart() {
    this.updateIdealLine();
    this.updateActualLine();
    this.initChart();
    this.visualizationChartData();
  }
  ngOnChanges() {
    if (this.initialized) {
      this.updateChart();
    }
  }

  ngAfterContentInit() {
    console.log('ngAfterContentInit');
    setTimeout(() => {
      this.initialized = true;
      this._cd.markForCheck();
      this.updateChart();
      this._cd.detectChanges();
    });
  }

  initChart() {
    this.svg = d3.select('.chart svg');
  }

  updateActualLine() {
    let sum = this.sumToBurn;
    const values = this.burnEvents.map((event: BurnEvent) => {
      sum -= event.burn;
      return {
        burnEvent: event,
        logDate: moment(event.date).toDate(),
        sprintsTask: event.sprintsTask,
        date: event.date,
        burn: event.burn,
        value: sum
      };
    });

    this.groupedActivitiesByDateMap = values.reduce((acc, burnEvent) => {
      if (!acc[burnEvent.date]) {
        acc[burnEvent.date] = [];
      }
      acc[burnEvent.date].push(burnEvent);
      return acc;
    }, {});

    this.groupedActivitiesByDate = Object.keys(this.groupedActivitiesByDateMap).map(
      key => this.groupedActivitiesByDateMap[key][this.groupedActivitiesByDateMap[key].length - 1]
    );

    values.unshift({
      date: moment
        .unix(this.startTs)
        .startOf('day')
        .format(BACKEND_DATE_FORMAT),
      logDate: moment
        .unix(this.startTs)
        .startOf('day')
        .toDate(),
      burn: 0,
      burnEvent: undefined,
      sprintsTask: undefined,
      value: this.sumToBurn
    });

    this.actualLineData = values;
  }

  updateIdealLine() {
    const result = [];
    const sprintStartDate = moment.unix(this.startTs);
    const sprintEndDate = moment.unix(this.endTs);
    const sprintDaysList = enumerateDaysBetweenDates(sprintStartDate, sprintEndDate);

    const checkDayOff = (date, index) => {
      const momentTime = moment(date);
      const previousDay = index === 0 ? index : index - 1;
      const datePreviousDay = moment(sprintDaysList[previousDay]);
      return (
        index !== 0 &&
        ((this._workingDays.isWorkingDay(momentTime) && this._workingDays.isWorkingDay(datePreviousDay)) ||
          (this._workingDays.isDayOff(momentTime) && this._workingDays.isWorkingDay(datePreviousDay)))
      );
    };

    const filteringDaysList = sprintDaysList.filter(checkDayOff);
    const step = this.sumToBurn / filteringDaysList.length;

    let lastHourToSec = this.sumToBurn;

    sprintDaysList.forEach((value, index) => {
      let hour = lastHourToSec;
      const date = new Date(+moment(value));

      if (checkDayOff(value, index)) {
        lastHourToSec -= step;
        hour = lastHourToSec;
      }

      result.push({ logDate: date, value: hour });
    });

    this.idealLineData = result;
  }

  visualizationChartData() {
    if (this.svg) {
      const calculatedEndTs = moment
        .unix(this.releasedTs ? this.releasedTs : Math.max(this.endTs, moment().unix()))
        .endOf('day')
        .unix();

      const dateEndXScale = moment.unix(calculatedEndTs).startOf('day');

      this.yScale = d3
        .scaleLinear()
        .domain([0, this.sumToBurn])
        .range([this.height, 0]);

      this.xScale = d3
        .scaleUtc()
        .domain([
          moment
            .unix(this.startTs)
            .startOf('day')
            .toDate(),
          dateEndXScale.toDate()
        ])
        .range([0, this.width]);

      const yAxis = d3
        .axisLeft(this.yScale)
        .tickSizeOuter(0)
        .ticks(Math.round(this.sumToBurn > 10 ? 10 : Math.max(1, this.sumToBurn)));
      const xAxis = d3
        .axisBottom(this.xScale)
        .ticks(d3.timeDay, 1)
        .tickFormat(d3.timeFormat('%d %b'))
        .tickSize(10)
        .tickSizeOuter(0);

      const gridLines = d3.axisLeft(this.yScale).tickSize(-this.width);

      const idealLineGen = d3
        .line<DataLineType>()
        .x(d => this.xScale(d.logDate))
        .y(d => this.yScale(d.value));

      const actualLineGen = d3
        .line<DataLineType>()
        .x(d => this.xScale(d.logDate))
        .y(d => this.yScale(d.value))
        .curve(d3.curveStepAfter);

      this.svg.select('.g-x-axis').call(xAxis);

      let dayOffs = [];
      let day = moment.unix(this.startTs).startOf('day');
      do {
        if (this._workingDays.isDayOff(day)) {
          dayOffs.push(day);
        }
        day = day.clone().add({ d: 1 });
      } while (day.unix() < calculatedEndTs);

      this.dayOffs = dayOffs.map(day => {
        return {
          id: day.format(BACKEND_DATE_FORMAT),
          x: this.xScale(day.toDate()),
          width:
            this.xScale(
              day
                .clone()
                .add({ d: 1 })
                .toDate()
            ) - this.xScale(day.toDate())
        };
      });

      this.svg.select('.g-y-axis').call(yAxis);
      this.svg.select('.g-grid-lines').call(gridLines);

      this.svg.select('.g-grid-lines path').attr('style', 'opacity: 0');
      this.svg.selectAll('.g-y-axis path').attr('style', 'stroke: #78909c');
      this.svg.selectAll('.g-x-axis path').attr('style', 'stroke: #78909c');
      this.svg
        .selectAll('.g-grid-lines line')
        .attr('style', 'stroke: #cfd8dc')
        .attr('y1', '1')
        .attr('y2', '1');
      this.svg.selectAll('.g-x-axis text').attr('style', 'fill: #97aab3');
      this.svg.selectAll('.g-x-axis line').attr('style', 'stroke: #cfd8dc');
      this.svg.selectAll('.g-y-axis text').attr('style', 'fill: #97aab3');
      this.svg
        .selectAll('.g-y-axis line')
        .attr('style', 'stroke: #cfd8dc')
        .attr('y1', '1')
        .attr('y2', '1');
      this.svg.selectAll('.g-grid-lines text').remove();

      if (this.sumToBurn > 0) {
        this.idealLine = idealLineGen(this.idealLineData);
      }

      this.actualLine = actualLineGen(this.actualLineData);
    }
  }

  onClickOpen(event, activity) {
    this.selectedActivity = activity;

    this.topPxActivityTooltip = event.pageY;
    this.leftPxActivityTooltip = event.pageX;

    this.tooltipPositionClass = [event.pageX > this.width / 2 ? 'right' : 'left', 'activity-tooltip'].join(' ');

    if (this.groupedActivitiesByDateMap[activity.date]) {
      this.isOpenActivityTooltip$.next(true);
      this.tasksActivityTooltipData = this.groupedActivitiesByDateMap[activity.date];
    }
  }

  onClickOut() {
    this.selectedActivity = null;
    this.isOpenActivityTooltip$.next(false);
  }
}
