import { Injectable } from '@angular/core';
import { BoardTheme } from '../../interfaces/board-theme';

export const DefaultTheme = {
  id: 0,
  object: 'hyggerTheme',
  name: 'deafult',
  headerColor: '#1E88E5',
  boardColor: '#498DD8'
};

@Injectable({
  providedIn: 'root'
})
export class BoardThemeManagerService {
  constructor() {}

  public setTheme(theme: BoardTheme) {
    const _theme = theme || DefaultTheme;
    const currentThemeStyleEl = document.getElementById('board-theme-style');
    const themeStyles = `
    app-header .header_wrapper {background-color: ${_theme.headerColor} !important;}
    column-list .column_wrapper {background-color: ${_theme.boardColor} !important;}
    `;
    if (currentThemeStyleEl) {
      currentThemeStyleEl.innerHTML = themeStyles;
    } else {
      const sheet = document.createElement('style');
      sheet.setAttribute('id', 'board-theme-style');
      sheet.innerHTML = themeStyles;
      document.body.appendChild(sheet);
    }
  }

  public resetTheme() {
    const el = document.getElementById('board-theme-style');
    if (el) {
      document.body.removeChild(el);
    }
  }
}
