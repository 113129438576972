
import {never as observableNever, of as observableOf,  Observable } from 'rxjs';

import {map, catchError, switchMap, take, tap, mergeMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import {
  HandleResponseAction,
  RootActionTypes,
  GetByIdsPayload,
  GetCompleteAction,
  PatchEntityPosition,
  PatchEntityAction
} from '../actions/root.action';

import * as overviewActivity from '../overview-activity/overview-activity.actions';
import { getEntityById } from '../functions/selectors';
import { Entity } from '../../interfaces';
import { Store } from '@ngrx/store';
import { AppState } from '../state';
import { JsonApiModelsResponse, jsonApiToEntityState } from '../../shared/services/app/web-socket/http-response';
import { OVERVIEW_ACTIVITY_PL, TASK_PL, USER_COMPANY_PL } from '../../constants/entity';
import { UserCompanyUpdatedAction } from '../actions/user-company.actions';
import { EntityState } from '../state/app-state';
import { AtlazApiV2Service, getByIdsPath } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { fromCamelToDash } from '../../../helpers/index';
import * as task from '../actions/task.actions';
import { Action } from '../actions/unsafe-action';

@Injectable()
export class RootEffects {
  @Effect()
  getEntity = this.actions$
    .ofType(RootActionTypes.GET).pipe(
    tap((action: Action) => console.log('ROOT ACTION GET ENTITY', action.payload)),
    mergeMap(({ type, payload }: { type: string; payload: { entity: string; id: number; expand?: string } }) => {
      const params = !!payload.expand ? { expand: payload.expand } : {};
      return this._atlazV2Api
        .get([payload.entity, params]).pipe(
        map(resp => new HandleResponseAction(resp)),
        catchError(defaultErrorHandler(type, payload)),);
    }),);

  @Effect({ dispatch: false })
  getCompleteAction$ = this.actions$
    .ofType(RootActionTypes.GET_COMPLETE).pipe(
    tap(({ type, payload: state }: { type: string; payload: EntityState<any> }) => {
      if (state.hasOwnProperty(USER_COMPANY_PL)) {
        this._store.dispatch(new UserCompanyUpdatedAction(state[USER_COMPANY_PL]));
      }
      if (state[TASK_PL]) {
        this._store.dispatch(new task.TaskUpdated(state[TASK_PL]));
      }
      if (state[OVERVIEW_ACTIVITY_PL]) {
        this._store.dispatch(new overviewActivity.UpdatedState(state[OVERVIEW_ACTIVITY_PL]));
      }
    }));

  @Effect()
  getEntitiesByIds = this.actions$
    .ofType(RootActionTypes.GET_BY_IDS).pipe(
    tap((action: Action) => console.log('ROOT ACTION GET ENTITY BY IDS', action.payload)),
    mergeMap(({ type, payload }: { type: string; payload: GetByIdsPayload }) => {
      return this._atlazV2Api
        .get(getByIdsPath(payload.entity, payload.ids, payload.entity), payload.queryParams).pipe(
        map((data: JsonApiModelsResponse<any>) => new GetCompleteAction(jsonApiToEntityState(data))),
        catchError(defaultErrorHandler(type, payload)),);
    }),);
  @Effect()
  patchPosition = this.actions$.ofType(RootActionTypes.PATCH_ENTITY_POSITION).pipe(mergeMap((action: PatchEntityPosition) => {
    const { value, entityPl, insertSuffix } = action.payload;
    const closestItemId = value.position.before || value.position.after;
    const posOddValue = value.position.before ? -1 : 1;
    const newValues = Object.assign(
      {
        id: +value.id
      },
      value.metaData
    );

    if (value.position.before) {
      newValues['insertBefore' + insertSuffix] = value.position.before;
    }
    if (value.position.after) {
      newValues['insertAfter' + insertSuffix] = value.position.after;
    }

    return observableOf(closestItemId).pipe(
      switchMap(
        id =>
          id
            ? this._store.pipe(
                (getEntityById(entityPl, id)),
                take(1),
                map((closest: Entity) => (closest ? closest['position'] + posOddValue : 0)),)
            : observableOf(0)
      ),
      map(position => {
        newValues['position'] = position;
        return newValues;
      }),
      map(
        valuesToPatch =>
          new PatchEntityAction({
            entityName: entityPl,
            data: valuesToPatch
          })
      ),
      catchError(defaultErrorHandler(action.type, action.payload)),);
  }));

  @Effect()
  patchEntity = this.actions$
    .ofType(RootActionTypes.PATCH_ENTITY).pipe(
    mergeMap(({ type, payload }: { type: string; payload: any }) => {
      const id: number = payload.data.id;
      const entityName: string = payload.entityName;
      const path = [fromCamelToDash(entityName)];
      if (payload.httpParams) {
        path.push(payload.httpParams);
      }

      return this._atlazV2Api
        .patch(path, payload.data).pipe(
        map(response => new HandleResponseAction(response)),
        catchError(defaultErrorHandler(type, payload)),);
    }));
  /**
   * works with api v2
   */
  @Effect()
  handleResponse$ = this.actions$
    .ofType(RootActionTypes.HANDLE_RESPONSE).pipe(
    map((action: Action) => new GetCompleteAction(jsonApiToEntityState(action.payload))));

  constructor(private actions$: Actions, private _store: Store<AppState>, private _atlazV2Api: AtlazApiV2Service) {}
}

export function defaultErrorHandler(actionType: string, payload: any) {
  return (error: any) => {
    console.warn('EFFECT ERROR AT ACTION: ', actionType);
    console.log('PAYLOAD: ', payload);
    console.error(error);
    return observableNever();
  };
}
