
import {never as observableNever, of as observableOf,  Observable } from 'rxjs';

import {take, switchMap, map, distinctUntilChanged, publishReplay, refCount, filter} from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { Notification, Project, Task, User } from '../../../../interfaces';
import { AppUrls } from '../../../../app-urls';

import { Store } from '@ngrx/store';
import { AppState } from '../../../../ngrx/state';
import { MarkAsTypes, NotificationMarkAsAction } from '../../../../ngrx/actions/notification.actions';

import { getNotificationIds } from '../notifications-list/notifications-list.component';
import { getUserById } from '../../../../ngrx/reducers/user.reducer';
import { getTaskById, getTaskUrl, taskTitle } from '../../../../ngrx/reducers/task.reducer';
import * as R from 'ramda';
import { isNil } from 'ramda';
import { BOARD, IMPORT, TASK, TASK_PL } from '../../../../constants';
import { GetAction } from '../../../../ngrx/actions/root.action';
import { getFeatureAvailability, isPresent } from '../../../../../helpers';
import { getProjectById } from '../../../../ngrx/reducers/project.reducer';

const COMMENT = 'COMMENT';
const MENTION = 'MENTION';

interface ActivityUrl {
  link: any;
  fragment?: number;
}

@Component({
  selector: 'notification-preview',
  templateUrl: './notification-preview.component.html',
  styleUrls: ['./notification-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationPreviewComponent implements OnChanges {
  @Input() notification: Notification;
  @Input() isArchivedFilterActive: boolean;

  public task$: Observable<Task>;
  public taskUrl$: Observable<string>;
  public url$: Observable<ActivityUrl>;
  public hasUrl$: Observable<boolean>;

  public isGroupNotificationOpened = false;
  public user$: Observable<User>;
  public title$: Observable<string>;
  public appUrls = AppUrls;

  public project$: Observable<Project>;

  public subNotificationsUsersIds: number[] = [];

  public ENTITIES = {
    TASK: TASK,
    BOARD: BOARD,
    IMPORT: IMPORT
  };

  private showTaskKey = getFeatureAvailability('overviewTaskNames');

  constructor(private _store: Store<AppState>) {}

  ngOnChanges() {
    const subNotes = this.notification.subNotifications;
    if (subNotes) {
      this.subNotificationsUsersIds = R.uniq(subNotes.map(subNotification => subNotification.creator));
    }

    this.user$ = <Observable<User>>this._store.pipe((getUserById(this.notification.creator)));
    this.task$ = this.getTask();
    this.taskUrl$ = this.task$.pipe(switchMap((task: Task) => this._store.pipe((getTaskUrl(task)))));
    this.project$ = this.task$.pipe(switchMap(
      (task: Task) => (task ? this._store.pipe((getProjectById(task.project))) : observableOf(null))
    ));
    this.title$ = this.showTaskKey
      ? taskTitle(this.task$, this.project$)
      : this.task$.pipe(
          map(task => (task ? task.title : '')),
          distinctUntilChanged(),
          publishReplay(1),
          refCount(),);
    this.url$ = this.buildUrl();
    this.hasUrl$ = this.url$.pipe(map(isPresent));
  }

  buildUrl(): Observable<string | any[] | any> {
    switch (this.notification.entityType) {
      case TASK:
        return this.task$.pipe(filter(isPresent),map((task: Task) => {
          return {
            link: AppUrls.getUrlTask(task.taskKey || task.id),
            fragment:
              this.notification.type === COMMENT || this.notification.type === MENTION
                ? 'comment' + this.notification.comment.toString()
                : undefined
          };
        }),);
      case BOARD:
        return observableOf({ link: AppUrls.getUrlBoard(this.notification.entityId) });
    }
    return observableOf(undefined);
  }

  onExpandNotification() {
    this.isGroupNotificationOpened = !this.isGroupNotificationOpened;
  }

  onArchiveNotification() {
    const notifIds = getNotificationIds([this.notification]);
    this._store.dispatch(new NotificationMarkAsAction({ markType: MarkAsTypes.archive, notificationIds: notifIds }));
  }

  getTask() {
    if (this.notification.entityType !== TASK) {
      return observableNever();
    }

    const task$ = this._store.pipe((getTaskById(this.notification.entityId)));

    task$.pipe(
      filter(isNil),
      take(1),)
      .subscribe(ids => this._store.dispatch(new GetAction({ entity: TASK_PL, id: this.notification.entityId })));

    return task$;
  }

  markAsSeen() {
    const ids = (this.notification.subNotifications
      ? [this.notification, ...this.notification.subNotifications]
      : [this.notification]
    ).map(x => x['id']);
    this._store.dispatch(new NotificationMarkAsAction({ markType: MarkAsTypes.seen, notificationIds: ids }));
  }
}
