<div class="task_filters_container" *ngIf="rightMenuContent">

  <div class="content_title" (click)="onToggleActivityMenu()">
    <span>{{activityType$ | async}}</span>
    <div class="ico_arrow_open"></div>
    <div a-context-menu class="slim inbox_context" *ngIf="isActivityMenuVisible" (close)="onToggleActivityMenu()">
      <p *ngFor="let type of activityTypes" a-context-menu-item (click)="onSelectActivityType(type)">{{type.value}}</p>
    </div>
  </div>
  <div class="filter_icon" (click)="onFiltersToggle()" [ngClass]="{filter_icon_active: isFilterVisible}"></div>
  <overlay class="transparent" *ngIf="isFilterVisible"></overlay>
  <div class="filter_container" [ngClass]="{show_filters: isFilterVisible}" [clickOut]="isFilterVisible" (clickOutEvent)="onFiltersToggle()">
    <div class="triangle_with_shadow"></div>

    <h3>Time Period</h3>
    <div class="filter_date_type">
      <p (click)="onToggleFilterDateMenu()">{{dateIntervalFilter$ | async}}</p>
      <div a-context-menu-legasy *ngIf="isFilterDateVisible" class="filters_dates" (close)="onToggleFilterDateMenu()">
        <p *ngFor="let date of filterDateTypes" (click)="onSelectFilterDateType(date)">{{date.value}}</p>
      </div>
    </div>

    <div *ngIf="(dateFromToFormVisible$ | async) || (isPickADateFilter$ | async)" class="date_pickers">
      <div>
        <h3>From</h3>
        <a-datetime-picker [time]="false"
                           [isClearBtnEnabled]="true"
                           [showPlaceholder]="true"
                           [outerUpdater$]="dateFromFilter$"
                           [defaultDate]="dateFromFilter$ | async"
                           (change)="onApplyDateFromFilter($event)"
        ></a-datetime-picker>
      </div>

      <div>
        <h3>To</h3>
        <a-datetime-picker
          [time]="false"
          [isClearBtnEnabled]="true"
          [showPlaceholder]="true"
          [defaultDate]="dateToFilter$ | async"
          [outerUpdater$]="dateToFilter$"
          (change)="onApplyDateToFilter($event)"
        ></a-datetime-picker>
      </div>
    </div>

    <project-filter [items]="projects$ | async" [selectedItemIds$]="projectFilter$" (changeDropDownFilter)="onApplyProjectFilter($event)"
      class="project_filter"></project-filter>
    <board-filter [items]="boards$ | async" [selectedItemIds$]="boardFilter$" (changeDropDownFilter)="onApplyBoardFilter($event)"
      class="project_filter"></board-filter>
    <user-filter [groupId$]="groupFilter$" [userIds$]="usersFilter$" (selectItems)="onApplyUserFilter($event)"></user-filter>

    <div *ngIf="isAppliedFilter$ | async" class="clear_container">
      <button (click)="onClearFilters()">Clear Filter</button>
    </div>
  </div>
</div>
<div class="task_list_container">
  <task-list [rightMenuContent]="rightMenuContent" [activities]="overviewActivities$ | async"></task-list>
  <button *ngIf="loadMore$ | async" (click)="onLoadMore()" class="more_tasks_btn">Show More...</button>

  <div class="message_container" *ngIf="!(reloading$ | async) && !(overviewActivities$ | async).length">
    <div class="no_completed_results">
      <div [ngClass]="{icon: true, no_filters_results: (isAppliedFilter$ | async)}"></div>
      <p>{{activityEmptyState$ | async}}</p>
      <div *ngIf="isAppliedFilter$ | async" class="reset_btn" (click)="onClearFilters()">RESET FILTER</div>
    </div>
  </div>
  <div class="message_container" *ngIf="reloading$ | async">
    <a-loader></a-loader>
  </div>

</div>
