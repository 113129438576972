<div class="add_task_btn_wrapper" *ngIf="!isAdding;else addingForm">
  <a class="add_task_btn" (click)="onOpenAddingForm()">
  <span class="add_task_btn_icon">
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 6H6V10H4V6H0V4H4V0H6V4H10V6Z" fill="currentColor"/>
    </svg>
  </span>
    Add Task
  </a>
</div>
<ng-template #addingForm>
  <list-board-add-task-form [toTop]="toTop" [boardId]="boardId" [group]="group" (close)="onCloseAddingForm()"></list-board-add-task-form>
</ng-template>
