
import {pluck, switchMap, combineLatest, take, distinctUntilChanged, tap, map} from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable ,  Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state/';
import { Label, LabelTag } from '../../../interfaces/';
import { isPresent, objectToArray } from '../../../../helpers';
import { labelsToLabelTag } from '../../../../helpers/labels';
import {
  DefaultLabelFilterToggleLabelsAction,
  DefaultLabelsFilterResetAction
} from '../../../ngrx/actions/task-filters/default-labels-filter.action';
import { getActiveTasksByBoardIdWithLinked } from '../../../ngrx/reducers/task.reducer';
import { LABEL_PL } from '../../../constants';
import { ESInterface } from '../../../ngrx/state';
import { Task } from '../../../interfaces';
import { getDefaultLabelsFilterIds } from '../../../ngrx/reducers/task-filters/default-labels-filter.reducer';

@Component({
  selector: 'default-labels-filter',
  templateUrl: 'default-labels-filter.component.html',
  styleUrls: ['./default-labels-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultLabelsFilterComponent implements OnInit, OnDestroy {
  @Input() boardId$: Observable<number>;

  subs: Subscription[] = [];

  public showLabelsPopUp = false;

  public labels$: Observable<ESInterface<Label>>;
  public labelTags$: Observable<LabelTag[]>;
  public selectedLabelTags$: Observable<LabelTag[]>;
  public selectedLabelsIds$: Observable<number[]>;
  public storeLabelsFilterValue$: Observable<number[]>;

  constructor(private _store: Store<AppState>) {}

  ngOnInit(): void {
    this.storeLabelsFilterValue$ = this._store.pipe((getDefaultLabelsFilterIds));
    this.labels$ = this._store.pipe(pluck<AppState, ESInterface<Label>>(LABEL_PL),distinctUntilChanged(),);

    // List of available to select items
    this.labelTags$ = this.labels$.pipe(
      combineLatest(
        this.boardId$.pipe(switchMap(id => this._store.select(getActiveTasksByBoardIdWithLinked(id)))),
        (labels: ESInterface<Label>, tasks: Task[]) => {
          const labelsOnBoard = (boardLabels, task: Task) => {
            if (task.labelsIds && task.labelsIds.length > 0) {
              task.labelsIds.forEach(id => (boardLabels[id] = labels.entities[id]));
            }

            return boardLabels;
          };
          const filterLabels = tasks.reduce(labelsOnBoard, {});

          return objectToArray(filterLabels);
        }
      ),
      map(list => list.filter(isPresent)),
      map(labelsToLabelTag),);

    // List of selected items, some of them might be unavailable to select (there is no tasks on the board with such label)
    this.selectedLabelTags$ = this.labels$.pipe(
      combineLatest(this.storeLabelsFilterValue$, (all: ESInterface<Label>, selectedIds: number[]) => ({
        selectedIds: selectedIds,
        selectedLabels: all.ids.filter(id => selectedIds.includes(id)).map(id => all.entities[id])
      })),
      tap(({ selectedIds, selectedLabels }) => {
        if (selectedIds.length && !selectedLabels.length) {
          this._store.dispatch(new DefaultLabelsFilterResetAction());
        }
      }),
      map(({ selectedLabels }) => labelsToLabelTag(selectedLabels)),);

    this.selectedLabelsIds$ = this.selectedLabelTags$.pipe(map((tags: LabelTag[]) => tags.map((tag: LabelTag) => tag.id)));
  }

  onAddLabel(label: LabelTag) {
    this.selectedLabelsIds$.pipe(take(1)).subscribe(ids => {
      this._store.dispatch(new DefaultLabelFilterToggleLabelsAction([...ids, label.id]));
    });
  }

  onRemoveLabel(label: LabelTag) {
    this.selectedLabelsIds$.pipe(take(1)).subscribe(ids => {
      this._store.dispatch(new DefaultLabelFilterToggleLabelsAction(ids.filter(id => id !== label.id)));
    });
  }

  onShowLabelsPopup() {
    this.showLabelsPopUp = true;
  }

  onHideLabelsPopup() {
    this.showLabelsPopUp = false;
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
