import { GlobalSearchActionTypes, GlobalSearchActions } from '../actions/global-search.actions';

// sort order values
export const ASC = 'asc';
export const DESC = 'desc';

export interface SearchStateInterface {
  sortParams: {
    sortParam: string;
    sortOrder: string;
  };
  errorMessage: string;
  searchQuery: string;
  filterParams: {};
  backUrl: string;
  result: number[];
  count: number;
  nextOffset: number;
  onSearchPage: boolean;
  pending: boolean;
  completed: boolean;
}

const initialState: SearchStateInterface = {
  sortParams: {
    sortParam: '',
    sortOrder: ASC
  },
  errorMessage: '',
  searchQuery: '',
  filterParams: {},
  backUrl: '',
  result: [],
  count: 0,
  nextOffset: 0,
  onSearchPage: false,
  pending: false,
  completed: false
};

export function reducer(state = initialState, action: GlobalSearchActions): SearchStateInterface {
  switch (action.type) {
    case GlobalSearchActionTypes.START_SEARCH: {
      return Object.assign({}, initialState, {
        searchQuery: action.payload.searchQuery,
        filterParams: action.payload.filterParams,
        sortParams: action.payload.sortParams,
        pending: true,
        onSearchPage: true,
        // save backUrl if someone already had to set it
        backUrl: state.backUrl
      });
    }

    case GlobalSearchActionTypes.LOAD_MORE: {
      return Object.assign({}, state, {
        pending: true
      });
    }

    case GlobalSearchActionTypes.SEARCH_REMEMBER_BACK_URL: {
      return Object.assign({}, state, {
        backUrl: action.payload
      });
    }
    case GlobalSearchActionTypes.STOP_SEARCH_AND_GO_BACK:
    case GlobalSearchActionTypes.LEAVE_SEARCH_PAGE: {
      return Object.assign({}, initialState, {
        // save backUrl if someone already had to set it
        backUrl: state.backUrl
      });
    }

    case GlobalSearchActionTypes.HANDLE_SEARCH_RESPONSE: {
      return Object.assign({}, state, {
        result: action.payload.replaceResult
          ? action.payload.resultIds
          : [...state.result, ...action.payload.resultIds],
        count: action.payload.count,
        nextOffset: action.payload.nextOffset,
        pending: false,
        completed: true
      });
    }
    default: {
      return state;
    }
  }
}
