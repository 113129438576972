import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'close-button',
  template: `<div tabindex="0" (click)="close.emit(true)"></div>`,
  styleUrls: ['./close-button.component.scss']
})
export class CloseButtonComponent {
  @Output() close = new EventEmitter();
}
