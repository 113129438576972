<list-board-header [board]="board"
                   [groupsLength]="(groups$ | async)?.length"
                   [isNotGuest]="isNotGuest"
                   [groupWidth]="groupWidth$ | async"
                   [customFields]="customFields$ | async"
                   [scoringColumns]="scoringColumns$ | async"
                   [isGroupedBySwimlane]="isGroupedBySwimlane$ | async"
                   (openFieldsManager)="onOpenFieldsManager()"
></list-board-header>
<list-board-content [board]="board"
                    [isNotGuest]="isNotGuest"
                    [doneColumnId]="doneColumnId"
                    [allGroups]="groups$ | async"
                    [groupWidth]="groupWidth$ | async"
                    [customFields]="customFields$ | async"
                    [groupedTasks]="groupedTasks$ | async"
                    [boardUsersMap]="boardUsersMap$ | async"
                    [boardLabelsMap]="boardLabelsMap$ | async"
                    [scoringColumns]="scoringColumns$ | async"
                    [isGroupedBySwimlane]="isGroupedBySwimlane$ | async"
                    (completeTask)="onCompleteTask($event)"
></list-board-content>
<board-fields-manager *ngIf="isFieldManagerVisible" (close)="onCloseFieldsManager()" [isNotGuest]="isNotGuest" [board]="board"></board-fields-manager>
