import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Label } from '../../../interfaces';

@Component({
  selector: 'labels-cell',
  template: `<span *ngFor="let label of labels" [ngClass]="label.color">{{label.name}}</span>`,
  styleUrls: ['labels-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelsCellComponent {
  @Input() labels: Label[];
}
