<div class="projects_block collections_block">
  <div class="edit_link" (click)="collectionsPopupToggle()">Edit</div>
  <div class="overlay_layer" (click)="collectionsPopupToggle()"></div>
  <h4>Collections</h4>
  <right-menu-collections-popup
    *ngIf="isCollectionsPopupVisible"
    [boardCollections]="boardCollections"
    [collections]="collections"
    [selectedBoard]="selectedBoard"
    [boardId]="boardId"
    (close)="onCloseCollectionsPopup()"
  ></right-menu-collections-popup>
  <div class="form_additional_block">
    <div class="projects_avatars_container">
      <div *ngFor="let col of boardCollections;" class="project_avatar">
        <p>{{col.name}}</p>
      </div>
    </div>
  </div>
</div>
