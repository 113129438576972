import { map, pluck, take, withLatestFrom } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { BillingPublicApiService } from '@atlaz/billing/shared/billing-public-api.service';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../../../shared/services/form-v2.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { JsonApiSingeModelResponse } from '../../../../shared/services/app/web-socket/http-response';
import {
  BillingPlanName,
  SUBSCRIPTION_ACTIONS,
  SUBSCRIPTION_PATH
} from '@atlaz/billing/constants/billing-subscription';
import { HandleResponseAction } from '../../../../ngrx/actions/root.action';
import { Observer } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../ngrx/state';
import { BillingSubscriptionEntity } from '@atlaz/billing/interfaces/billing-subscription';
import { RouterNavigateService } from '../../../../shared/services/router-navigate.service';
import { COMPANY_STATUS } from '@atlaz/billing/constants/company';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'billing-actions-buttons',
  templateUrl: './billing-actions-buttons.component.html',
  providers: [FormV2Service]
})
export class BillingActionsButtonsComponent implements OnInit {
  @Input() isUnlockHidden;

  formObserver: Observer<any> = {
    next: (resp: JsonApiSingeModelResponse<any>) => {
      this._store.dispatch(new HandleResponseAction(resp));
      this.onShowSuccessPaymentPupop();
    },

    error: error => {
      this.onShowDeniedPaymentPupop();
    },

    complete: () => {}
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.edit,
    entityToEdit: SUBSCRIPTION_PATH,
    formObserver: this.formObserver,
    prepareFormValue: (formValue: any) => formValue
  };

  public billingSub: BillingSubscriptionEntity;
  public isLongTrial$: Observable<boolean>;
  public isPaymentSuccessPopupVisible = false;
  public isPaymentDeniedPopupVisible = false;

  public form: FormGroup;
  readonly upgradeNeeded$ = this._billingService.upgradeNeeded$;
  readonly renewNeeded$ = this._billingService.renewNeeded$;
  readonly isLocked$ = this._billingService.isLocked$;
  readonly hasFreeSubscription$ = this._billingService.hasFreeSubscription$;
  readonly isTrial$ = this._billingService.companyStatus$.pipe(map(s => s === COMPANY_STATUS.TRIAL));

  constructor(
    private _billingService: BillingPublicApiService,
    public _formService: FormV2Service,
    private _fb: FormBuilder,
    private _store: Store<AppState>,
    private _routerNav: RouterNavigateService
  ) {}

  ngOnInit() {
    this._billingService.billingSubscription$.pipe(take(1)).subscribe(billingSub => {
      this.form = this._fb.group({
        id: billingSub.id,
        action: SUBSCRIPTION_ACTIONS.renew
      });
      this._formService.initFormParams(this.form, this.formServiceParams);
    });

    this.isLongTrial$ = this._billingService.billingSubscription$.pipe(
      map(sub => sub.isTrial && sub.trialEnd * 1000 - Date.now() > 86400000 * 90)
    );
  }

  doRenew() {
    this._formService.submit();
  }

  onShowSuccessPaymentPupop() {
    this.isPaymentSuccessPopupVisible = true;
  }
  onCloseSuccessPopup() {
    this.isPaymentSuccessPopupVisible = false;
  }
  onShowDeniedPaymentPupop() {
    this.isPaymentDeniedPopupVisible = true;
  }
  onCloseDeniedPaymentPupop() {
    this.isPaymentDeniedPopupVisible = false;
  }
  onNavigateToChangeCard() {
    this._routerNav.navigateToChangeCardFrom();
  }

  onNavigateToAddPaymentMethod() {
    this._routerNav.navigateToPricing();
  }

  onClickUnlock() {
    this._billingService.hasCard$
      .pipe(take(1), withLatestFrom(this._billingService.billingSubscription$.pipe(pluck('plan'))))
      .subscribe(
        ([hasCard, plan]) =>
          hasCard && plan !== BillingPlanName.Unpaid ? this.doRenew() : this.onNavigateToAddPaymentMethod()
      );
  }
}
