
import {map, distinctUntilChanged, pluck} from 'rxjs/operators';
import { AppState, ESInterface } from '../state';
import { Observable } from 'rxjs';
import { WORKLOG_PL, ENTITY_PL } from '../../constants';
import { WorkLog } from '../../interfaces';
import { runReducer } from '../functions/util';
import { Action } from '../actions/unsafe-action';
import { WorkLogActionTypes } from '../actions/worklog.actions';
import { deleteEntityState } from '../functions/reducer';

const initialState: ESInterface<WorkLog> = {
  ids: [],
  entities: {},
  selectedEntityId: null
};

const reducerAction = {
  [WorkLogActionTypes.DELETE_COMPLETE]: (state, payload: WorkLog) => deleteEntityState(state, payload.id),
  [WorkLogActionTypes.DELETE]: (state, payload: WorkLog) => deleteEntityState(state, payload.id)
};

export function reducer(state = initialState, action: Action): ESInterface<WorkLog> {
  return runReducer(reducerAction, state, action);
}

export const getTaskWorkLogs = taskId => (state$: Observable<AppState>) =>
  state$.pipe(
    pluck(WORKLOG_PL),
    distinctUntilChanged(),
    map((workLogsState: ESInterface<WorkLog>) =>
      workLogsState.ids.filter(id => taskId === workLogsState.entities[id].task).map(id => workLogsState.entities[id])
    ),);
