<task-cover *ngIf="task?.coverAttachmentId" [task]="task" type="preview"></task-cover>
<div *ngIf="hasNotifications$ | async" class="notification_icon"></div>
<div class="label_tags" *ngIf="task.labelsIds || task.taskKey" draggable="false" [routerLink]="taskUrl$ | async">
  <span class="task_name" *ngIf="task.taskKey">{{task.taskKey}}</span>
  <short-labels-list [labelIds]="task.labelsIds"></short-labels-list>
</div>
<p class="card_title">{{task.title}}</p>

<div class="info_block">
  <div class="indicator_block" *ngIf="task.dueDate > 0">
    <div class="duedate_container" [ngClass]="{'overdone': isTaskOverdone && !task.doneDate, 'near_overdone': isTaskCloseToOverdone && !task.doneDate}">
      <div class="time"></div>
      <div class="date">{{task.dueDate | toDate:'short'}}</div>
    </div>
  </div>
  <div class="indicator_block" *ngIf="isSubscribe">
    <div class="subscribe"></div>
  </div>
  <div class="indicator_block" *ngIf="task.description != null && task.description.length > 0">
    <div class="descript"></div>
  </div>

  <div class="indicator_block" *ngIf="task.commentsCount > 0">
    <div class="comments"></div>
    <div class="num_comments">{{task.commentsCount}}</div>
  </div>


  <div class="indicator_block" *ngIf="task.attachmentsCount > 0">
    <div class="attachments"></div>
    <div class="attachments_num">{{task.attachmentsCount}}</div>
  </div>

  <div class="indicator_block" *ngIf="task.checklistsCount && task.checklistsItemsCount">
    <div class="checklists"></div>
    <div class="num_checklists">
      {{task.completedChecklistsItemsCount}} / {{task.checklistsItemsCount}}
    </div>
  </div>


  <div class="indicator_block" *ngIf="task.estimate && showEstimates.isShowHoursEstimate">
    <div class="estimate">
      <div *ngIf="task.estimate >= task.loggedTime" class="est_icon"></div>
      <div *ngIf="task.estimate < task.loggedTime" class="est_icon_overdone"></div>
      <p>{{task.estimate | toHms}}</p>
    </div>
  </div>

  <div class="indicator_block" *ngIf="task.storyPoints && showEstimates.isShowPointsEstimate">
    <div class="story_points"><span></span>{{task.storyPoints}}</div>
  </div>

</div>
<div class="preview_users_block">
  <div class="person_avatar" *ngFor="let user of taskUsers$ | async">
    <a-avatar class="user_avatar" [user]="user"></a-avatar>
  </div>
</div>
<div class="score_container" *ngIf="!(isScoringOff$ | async)">
  <div class="advanced_score" *ngIf="!(isBasicScoring$ | async);else basicScoring">
    {{score$ | async}}
  </div>
</div>
<a class="task_link" #taskLink draggable="false" [routerLink]="taskUrl$ | async"></a>
<div class="quick_actions" (click)="onOpenQuickActions($event)"></div>

<ng-template #basicScoring>
  <div class="value_block">
    {{scoreYLabel$ | async}}
    <span>{{scoreYVisible$ | async}}</span>
  </div>
  <div class="divider"></div>
  <div class="efforts_block">
    {{scoreXLabel$ | async}}
    <span>{{scoreXVisible$ | async}}</span>
  </div>
</ng-template>
