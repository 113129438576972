import { Observable, of as observableOf } from 'rxjs';

import { distinctUntilChanged, filter, map, pluck, publishReplay, refCount, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { AppState } from '../ngrx/state/app-state';
import { getPermissionState } from './ngrx/permission.reducer';
import {
  ROLE_COMPANY_ADMIN,
  ROLE_COMPANY_GUEST,
  ROLE_COMPANY_OWNER,
  ROLE_COMPANY_SUPER_OWNER,
  ROLE_COMPANY_TITLES,
  ROLE_PROJECT_ADMIN,
  RoleCompany
} from './interfaces/roles';
import { isPresent } from '../../helpers/object';
import { Task } from '../interfaces/task';
import { Column } from '../interfaces/column';
import { Board } from '../interfaces/board';
import { getProjectById } from '../ngrx/reducers/project.reducer';
import { AuthService } from '../shared/services/app/auth.service';

@Injectable()
export class PermissionsService {
  readonly isSuperUser$: Observable<boolean> = this._store.pipe(
    getPermissionState,
    pluck('companyRole'),
    distinctUntilChanged(),
    filter(isPresent),
    map(PermissionsService.isSuperUser),
    publishReplay(1),
    refCount()
  );

  readonly isAnyProjectAdmin$ = this._store.pipe(
    getPermissionState,
    map(state => state.projects && state.projects.some(project => project.projectRole === ROLE_PROJECT_ADMIN)),
    publishReplay(1),
    refCount()
  );

  readonly isNotGuest$: Observable<boolean> = this._store.pipe(
    getPermissionState,
    pluck('companyRole'),
    distinctUntilChanged(),
    filter(isPresent),
    map(role => role !== ROLE_COMPANY_GUEST),
    publishReplay(1),
    refCount()
  );

  readonly isSomeOfProjectsAdmin$ = (ids: number[]) =>
    this._store.pipe(
      getPermissionState,
      map(
        state =>
          state.projects &&
          state.projects.some(project => project.projectRole === ROLE_PROJECT_ADMIN && ids.includes(project.id))
      ),
      publishReplay(1),
      refCount()
    );

  static isSuperUser(userRole: RoleCompany) {
    return [ROLE_COMPANY_SUPER_OWNER, ROLE_COMPANY_OWNER, ROLE_COMPANY_ADMIN].includes(userRole);
  }

  static getUserRoleName(userRole: RoleCompany) {
    return ROLE_COMPANY_TITLES[userRole];
  }

  constructor(private _store: Store<AppState>, private _authService: AuthService) {}

  public isProjectAdmin(projectId: number) {
    return this._store.pipe(
      getProjectById(projectId),
      filter(isPresent),
      map(project => Array.isArray(project.adminsIds) && project.adminsIds.includes(this._authService.activeUserId)),
      distinctUntilChanged(),
      publishReplay(1),
      refCount()
    );
  }

  public canEditBoard(boardId): Observable<boolean> {
    return this._store.pipe(
      getPermissionState,
      map(state => state.boards && state.boards.some(board => board.id === boardId && board.editPermissions)),
      publishReplay(1),
      refCount()
    );
  }

  public canEditBoardName(projectIds?: number[]): Observable<boolean> {
    return this.isNotGuest$;
  }

  public canRunImport() {
    return this.isSuperUser$;
  }

  public isBoardMember(boardId: number): Observable<boolean> {
    return observableOf(true);
  }

  public canEditTask(task: Task): Observable<boolean> {
    return this.isNotGuest$;
  }

  public canEditTasks(tasks$: Observable<Task[]>): Observable<{ [taskId: number]: boolean }> {
    return this.isNotGuest$.pipe(
      switchMap(
        isGuest =>
          isGuest
            ? tasks$.pipe(
                map(tasks =>
                  tasks.reduce((acc, item) => {
                    acc[item.id] = true;
                    return acc;
                  }, {})
                )
              )
            : observableOf(false)
      )
    );
  }

  public canEditColumn(columns: Column): Observable<boolean> {
    return this.isNotGuest$;
  }

  public projectMember(projectId: number): Observable<boolean> {
    return observableOf(true);
  }

  public memberProjectAdminPermissions(board: Board) {
    return observableOf(true);
  }

  public canCloseBoard(board: Board) {
    return this.isNotGuest$;
  }

  public boardProjectMember(board: number) {
    return observableOf(true);
  }
}
