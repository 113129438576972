import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { boardType } from '../../../constants';

@Component({
  selector: 'board-type-radio-chooser',
  templateUrl: './board-type-radio-chooser.component.html',
  styleUrls: ['./board-type-radio-chooser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoardTypeRadioChooserComponent implements OnInit {
  @Input() initialBoardType: string;
  @Output() changeSelection = new EventEmitter();
  @Input() isSprintAvailable = true;
  public currentValue: string;
  public boardType = boardType;
  constructor() {}

  ngOnInit() {
    this.currentValue = this.initialBoardType || boardType.kanban;
  }

  onSelectType(type) {
    this.currentValue = type;
    this.changeSelection.emit(type);
  }
}
