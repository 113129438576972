import { ActiveImportActionTypes } from '../action/active-import.action';
import { ActiveImport } from './active-import.interface';
import { IMPORT_STATUS } from '../../constants/import-status';
import { IMPORT_TYPES } from '../../constants/import-type';
import { Action } from '../../../../../ngrx/actions/unsafe-action';

const initialState: ActiveImport = {
  getTrelloTokenUrl: '',
  import: null,
  selectingProjects: false,
  preFetching: false,
  status: IMPORT_STATUS.LOADING,
  type: '',
  failed: true,
  inProgress: false
};

export function activeImportReducer(state = initialState, action: Action): ActiveImport {
  switch (action.type) {
    case ActiveImportActionTypes.INITIAL:
      return initialState;
    case ActiveImportActionTypes.DATA_CHANGED:
      return { ...state, ...action.payload };

    default: {
      return state;
    }
  }
}
