import { Action } from '../unsafe-action';

export enum ReportPeriodFilterActionTypes {
  SELECT = '[REPORT_PERIOD_FILTER] SELECT',
  RESET = '[REPORT_PERIOD_FILTER] RESET'
}

export class ReportPeriodFilterSelectAction implements Action {
  readonly type = ReportPeriodFilterActionTypes.SELECT;

  constructor(public payload: any) {}
}

export class ReportPeriodFilterResetAction implements Action {
  readonly type = ReportPeriodFilterActionTypes.RESET;
}

export type Actions
  = ReportPeriodFilterSelectAction
  | ReportPeriodFilterResetAction
  ;
