
import {from as observableFrom,  of ,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx/src/data-persistence';

import { toDashedFromCamelCase } from '../../../../../helpers/';
import { BITBUCKET_COMMIT_PL } from '../../../../constants/';
import { GetCompleteAction, UndoAction } from '../../../../ngrx/actions/root.action';
import { AppState } from '../../../../ngrx/state/';
import { getEmptyESState } from '../../../../ngrx/state/';
import { jsonApiToEntityState } from '../../../../shared/services/app/web-socket/http-response';
import { AtlazApiV2Service } from '../../../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import * as bitbucketCommit from './bitbucket-commit.actions';
import { NoopAction } from '../../../../ngrx/actions/root.action';
import { BitbucketCommit } from '../../models/git-commit';
import { map ,  switchMap ,  mapTo } from 'rxjs/operators';
import { Features } from '../../../../libs/paywall/features.constants';
import { PaywallService } from '../../../../libs/paywall/paywall.service';

@Injectable()
export class BitbucketCommitEffects {
  private key = toDashedFromCamelCase(BITBUCKET_COMMIT_PL);

  readonly defaultErrorHandler = (action, error) => {
    console.error('effect', action, error);
  };

  @Effect()
  getByTask$ = this._paywall.isFeatureEnabled(Features.Bitbucket)
    ? this.data.fetch(bitbucketCommit.GET_TASK_COMMIT, {
        run: (action: bitbucketCommit.GetTaskCommit, store) =>
          this.api.get([this.key], action.payload).pipe(
            map(jsonApiToEntityState),
            switchMap(state => {
              const actions = [
                new GetCompleteAction(state),
                new bitbucketCommit.GetTaskCommitComplete({
                  taskId: action.payload.task,
                  entities: state[BITBUCKET_COMMIT_PL] || getEmptyESState<BitbucketCommit>()
                })
              ];
              return of(...actions);
            })
          ),
        onError: (action, error) => {
          this.defaultErrorHandler(action, error);
          return new bitbucketCommit.GetTaskCommitFail();
        }
      })
    : observableFrom([]);

  @Effect()
  remove$ = this._paywall.isFeatureEnabled(Features.Bitbucket)
    ? this.data.optimisticUpdate(bitbucketCommit.DELETE_TASK_COMMIT, {
        run: (action: bitbucketCommit.DeleteTaskCommit, store) =>
          this.api.deleteRequest([this.key, action.payload.id]).pipe(mapTo(new NoopAction())),
        undoAction: (action, error) => {
          this.defaultErrorHandler(action, error);
          return new UndoAction(action);
        }
      })
    : observableFrom([]);

  constructor(
    private api: AtlazApiV2Service,
    private data: DataPersistence<AppState>,
    private _paywall: PaywallService
  ) {}
}
