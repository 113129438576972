import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TaskActivity, User } from '../../../interfaces';
import { filterDuplicateItem, isPresent, plainTextToHtml } from '../../../../helpers';
import { HotKey, HotSpaceAndEnter } from '../../../shared/directives';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {
  KEY_LOCAL_STORE_UNSAVED_EDIT_COMMENTS,
  KEY_LOCAL_STORE_UNSAVED_INPUT_FIELDS,
  KEY_LOCAL_STORE_UNSAVED_REPLAYS_COMMENTS,
  TASK_COMMENT_INPUT,
  TaskUnsavedDataService
} from '../../shared/services/task-unsaved-data.service';
import {
  getMentionsFromText,
  issetMentionsInText,
  MENTIONS_PATTERN,
  mentionsWithoutUser
} from '../../../../helpers/mention';
import { AuthService } from '../../../shared/services/app/auth.service';
import { setFocusToFocusableParent } from '../../../../helpers/event';
import { Actions } from '@ngrx/effects';
import { AddFromCommentPayload, TaskAttachmentsActionTypes } from '../../../ngrx/actions/task-attachments.actions';

const ROOT_COMMENT = 0;

@Component({
  selector: 'comment-form',
  templateUrl: './comment-form.component.html',
  styleUrls: ['./comment-form.component.scss']
})
export class CommentFormComponent implements OnInit, OnDestroy {
  @ViewChild('comment') commentTextArea: ElementRef;

  @Input() taskId: number;
  @Input() mentions: Observable<User[]>;
  @Input() taskComment: TaskActivity;
  @Input() taskReply: TaskActivity;
  @Input() isReplyAll: boolean;
  @Input() commentUser: User;
  @Input() isEditForm: boolean;

  @Output() submitForm: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() resetFocus = new EventEmitter();

  public fireSelectEvent$ = new BehaviorSubject(null);
  public selectedMention = -1;
  public filteredMentions = [];
  public checkSize$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public commentForm: FormGroup;
  public isAddAttachmentPopUpVisible = false;
  public subs: Subscription[] = [];

  constructor(
    private _fb: FormBuilder,
    private _taskUnsavedDataService: TaskUnsavedDataService,
    private _auth: AuthService,
    private actions$: Actions
  ) {}

  get mentions$() {
    return this.mentions;
  }

  get postHotKeys(): HotKey[] {
    return HotSpaceAndEnter;
  }

  ngOnInit(): any {
    this.initForm();
    this.subs.push(
      this.actions$
        .ofType(TaskAttachmentsActionTypes.ADD_FROM_COMMENT_COMPLETE)
        .subscribe(({ type, payload }: { type: string; payload: AddFromCommentPayload }) => {
          if (payload.commentKey === this.getCommentKey()) {
            const newValue = CommentFormComponent.addLinkToText(
              this.commentForm.get('text').value,
              payload.name,
              payload.path,
              payload.fileType
            );
            this.onPatchValue({ newValue: newValue, cursorPosition: newValue.length });
            this.checkSize$.next(true);
          }
        })
    );
  }

  getCommentKey() {
    return (this.taskComment ? this.taskComment.id : 'new') + (this.taskReply ? '-' + this.taskReply.id : '');
  }

  static addLinkToText(text, linkName, linkUrl, fileType) {
    return (text ? text + ' ' : '') + (fileType === 'image' ? '!' : '') + '[' + linkName + '](' + linkUrl + ') ';
  }

  onEscEvent(event: KeyboardEvent) {
    if (this.selectedMention > -1) {
      event.stopPropagation();
      this.onCloseMention();
    } else {
      event.stopPropagation();
      this.clearCommentForm();
      setFocusToFocusableParent(this.commentTextArea.nativeElement);
      this.resetFocus.emit({});
    }
  }

  onSubmit() {
    if (this.commentForm.valid) {
      this.submitForm.emit(this.commentForm.value);
      this.commentForm.reset();
      this.commentForm.get('task').patchValue(this.taskId);
      this.resetFocus.emit({});
      this.checkSize$.next(true);
    }
  }

  onSubmitSpace(event) {
    event.preventDefault();
    this.onSubmit();
  }

  onCancel(event: MouseEvent) {
    if (this.isEditForm) {
      this.close.emit();
      this.clearCommentForm();
    } else {
      event.stopPropagation();
      event.preventDefault();
      this.clearCommentForm();
    }
    this.resetFocus.emit({});
  }

  clearCommentForm() {
    this.commentForm.reset();
    this.cancel.emit(true);
    this.clearUnsavedComment();
    this.checkSize$.next(true);
  }

  onCloseMention() {
    this.selectedMention = -1;
  }

  onSelectedMention(selectedMention: number) {
    this.selectedMention = selectedMention;
  }

  onFilteredMentions(mentions: User[]) {
    this.filteredMentions = mentions;
  }

  onPatchValue({ newValue, cursorPosition }) {
    this.commentForm.get('text').patchValue(newValue);
    if (document.activeElement !== this.commentTextArea.nativeElement) {
      this.commentTextArea.nativeElement.focus();
    }
    this.commentTextArea.nativeElement.setSelectionRange(cursorPosition, cursorPosition);
  }

  onAddMention(user: User) {
    this.fireSelectEvent$.next(user);
  }

  initForm() {
    this.commentForm = this._fb.group(this.getCommentFields());

    let sub;

    switch (true) {
      case !!this.taskReply:
        sub = this.commentForm.valueChanges.subscribe(value =>
          this._taskUnsavedDataService.setUnsavedReplayComment(this.taskReply.id, value.text)
        );
        break;
      case !!this.taskComment:
        sub = this.commentForm.valueChanges.subscribe(value =>
          this._taskUnsavedDataService.setUnsavedEditComment(this.taskComment.id, value.text)
        );
        break;
      default:
        sub = this.commentForm.valueChanges.subscribe(value =>
          this._taskUnsavedDataService.taskUnsavedInputFields[TASK_COMMENT_INPUT].next(value.text)
        );
        break;
    }
  }

  clearUnsavedComment() {
    switch (true) {
      case !!this.taskReply:
        this._taskUnsavedDataService.clearUnsavedReplayComment(this.taskReply.id);
        break;
      case !!this.taskComment:
        this._taskUnsavedDataService.clearUnsavedEditComment(this.taskComment.id);
        break;
      default:
        this._taskUnsavedDataService.clearInput(TASK_COMMENT_INPUT);
        break;
    }
  }

  onSwitchAttachmentsPopUp() {
    this.isAddAttachmentPopUpVisible = !this.isAddAttachmentPopUpVisible;
  }

  public getCommentFields(): any {
    switch (true) {
      case !!this.taskReply:
        return this.getReplyFields();
      case !!this.taskComment:
        return this.getEditFields();
      default:
        return this.getNewFields();
    }
  }

  public getNewFields(): any {
    let text: string;

    const saved = this._taskUnsavedDataService.getFromLocalStore(KEY_LOCAL_STORE_UNSAVED_INPUT_FIELDS, this.taskId);
    text = (saved && saved[TASK_COMMENT_INPUT]) || '';
    this._taskUnsavedDataService.taskUnsavedInputFields[TASK_COMMENT_INPUT].next(text);
    return {
      text: [text, Validators.required],
      task: [this.taskId],
      parent: [ROOT_COMMENT]
    };
  }

  public getEditFields(): any {
    let text: string;
    const saved = this._taskUnsavedDataService.getFromLocalStore(KEY_LOCAL_STORE_UNSAVED_EDIT_COMMENTS, this.taskId);
    text = saved && saved[this.taskComment.id] ? saved[this.taskComment.id] : this.taskComment.text;

    return {
      id: [this.taskComment.id],
      task: [this.taskId],
      text: [plainTextToHtml(text), Validators.required]
    };
  }

  public getReplyFields(): any {
    const issetMentions = text => issetMentionsInText(text, [this.commentUser, this._auth.activeUser]);

    const commentUserIsCurrentUser = this.commentUser && this.commentUser.id === this._auth.activeUser.id;
    const replyAll = this.isReplyAll && issetMentions(this.taskReply.text);
    const mentionsFromText = getMentionsFromText(this.taskReply.text).filter(
      mentionsWithoutUser(this._auth.activeUser)
    );

    let text: string;
    let mentions = this.commentUser && !commentUserIsCurrentUser ? ['@' + this.commentUser.nickname] : [];

    const combineMentions = replyAll || (mentions.length === 0 && mentionsFromText.length === 1);

    if (combineMentions) {
      mentions = [...mentions, ...mentionsFromText].filter(filterDuplicateItem);
    }

    const saved = this._taskUnsavedDataService.getFromLocalStore(KEY_LOCAL_STORE_UNSAVED_REPLAYS_COMMENTS, this.taskId);

    const issetValue = saved && saved[this.taskReply.id];

    if (issetValue) {
      text = saved[this.taskReply.id] || '';

      const shouldAddMentions = this.isReplyAll && !issetMentions(text);

      if (shouldAddMentions) {
        text = this.appendMentionsToText(text, mentions);
      }
    } else {
      text = this.mentionsString(mentions) + ' ';
    }

    this._taskUnsavedDataService.setUnsavedReplayComment(this.taskReply.id, text);

    return {
      parent: [isPresent(this.taskReply.parent) ? this.taskReply.parent : this.taskReply.id],
      task: [this.taskId],
      text: [text, Validators.required]
    };
  }

  private mentionsString = mentions => mentions.filter(filterDuplicateItem).join(' ');

  private appendMentionsToText(text, mentions) {
    let result = text;
    const mentionsFromText = getMentionsFromText(text).filter(mentionsWithoutUser(this._auth.activeUser));
    const isAppendMentions = mentionsFromText.length <= 1 && mentions.length >= 1;

    if (isAppendMentions) {
      const mentionsText = issetMentionsInText(text, [this._auth.activeUser]);
      result = mentionsText
        ? text.replace(MENTIONS_PATTERN, this.mentionsString(mentions))
        : this.mentionsString(mentions) + ' ' + text;

      this._taskUnsavedDataService.setUnsavedReplayComment(this.taskReply.id, text);
    }

    return result;
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
    switch (true) {
      case !!this.taskReply:
        this._taskUnsavedDataService.saveByKey(
          this.taskId,
          KEY_LOCAL_STORE_UNSAVED_REPLAYS_COMMENTS,
          this.taskReply.id
        );
        break;
      case !!this.taskComment:
        this._taskUnsavedDataService.saveByKey(this.taskId, KEY_LOCAL_STORE_UNSAVED_EDIT_COMMENTS);
        break;
      default:
        this._taskUnsavedDataService.saveByKey(this.taskId, KEY_LOCAL_STORE_UNSAVED_INPUT_FIELDS);
    }
  }
}
