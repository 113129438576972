<a-center-container-scrollable>
  <div a-popup class="width_720" [title]="'Manage Fields'" (close)="onClose()">
    <div class="manager_wrapper">
      <div class="add_field">
        <button *ngIf="!isAddingField;else addingCustomField" a-button class="btn_primary" (click)="onStartAddingField()">Add Custom Field</button>
      </div>
      <div class="drag_to_top" (dragover)="onDragOver($event, 0, -1)"></div>
      <ul class="fields_list">
        <li class="fields_item" *ngFor="let item of (customFields$ | async);let i = index"
            [attr.draggable]="item.id !== editingItemId ? true : null"
            (dragstart)="onDragStart($event, item)"
            (dragover)="onDragOver($event, item.id, i)"
            (dragend)="onDragEnd()"
            [style.order]="i"

        >
          <ng-container *ngIf="item.id !== editingItemId;else editingCustomField">
            <div class="drag_btn">
              <svg>
                <use xlink:href="#drag-icon"></use>
              </svg>
            </div>
            <div class="item_text" [class.hidden]="item.id === dragStartId">
              <div class="item_name">{{item.name}}</div>
              <div class="item_description">{{item.description}}</div>
            </div>
            <div class="item_type">
              <svg>
                <use [attr.xlink:href]="'#' + item.type + '-type'"></use>
              </svg>
            </div>
            <button class="edit_btn" (click)="onEditItem(item)">Edit</button>
          </ng-container>
        </li>
        <li class="drag_pointer" *ngIf="dragPointerOrder > -2" [style.order]="dragPointerOrder"></li>
      </ul>
    </div>
  </div>
</a-center-container-scrollable>
<overlay (click)="onClose()"></overlay>

<ng-template #addingCustomField>
  <new-custom-field [boardId]="board.id" [dragType]="dragType" (startDrag)="onStartExternalDrag($event)" (close)="onEndAddingField()"></new-custom-field>
</ng-template>

<ng-template #editingCustomField>
  <new-custom-field class="edit_form" [customField]="editingItem" [boardId]="board.id" [dragType]="dragType" (startDrag)="onStartExternalDrag($event)" (close)="onEditingEnd()"></new-custom-field>
</ng-template>
