import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { boardType } from '../../../constants';
import { ModelValidators } from '../../../shared/validators/model-validators';
import { BehaviorSubject, Subscription } from 'rxjs';
import { compareArrays, isEmpty, isObject } from '../../../../helpers';
import { ActivatedRoute } from '@angular/router';

const availableTypes = [
  { value: boardType.kanban, text: 'Kanban' },
  { value: boardType.sprint, text: 'Sprint' },
  { value: boardType.roadmap, text: 'Roadmap' },
  { value: boardType.list, text: 'List' },
  { value: boardType.backlog, text: 'Backlog (Kanban + Prioritization)' }
];

@Component({
  selector: 'new-board-form',
  templateUrl: './new-board-form.component.html',
  styleUrls: ['./new-board-form.component.scss']
})
export class NewBoardFormComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() fromTemplate: number;
  @Input() initialValues = {};
  @Input() isTypeSelectorHidden? = false;
  @Input() isWithTasksAvailable? = false;
  @Input()
  set availableTypes(types: string[]) {
    if (!isEmpty(types)) {
      this._typeOptions = availableTypes.filter(option => types.includes(option.value));
    }
  }
  @Output() formChange = new EventEmitter();
  @Output() submit = new EventEmitter();
  @ViewChild('boardName') boardNameInput: ElementRef;

  public disabledProjects: number[] = [];
  boardTypes = boardType;
  _typeOptions = [];
  public subs: Subscription[] = [];

  public projectErrorMessages = {
    invalidProjectsCount: 'Select single project'
  };

  public selectedProjects$: BehaviorSubject<number[]> = new BehaviorSubject([]);
  public selectedCollections$: BehaviorSubject<number[]> = new BehaviorSubject([]);
  constructor(private _fb: FormBuilder, private _activatedRoute: ActivatedRoute) {
    this._typeOptions = availableTypes.slice();
  }

  ngOnChanges() {
    if (isObject(this.initialValues) && this.form) {
      Object.keys(this.initialValues).forEach(prop => {
        if (!this.form.get(prop).value) {
          this.form.get(prop).patchValue(this.initialValues[prop]);
        }
      });
    }
  }

  ngOnInit(): any {
    const defaultProjectId = +this._activatedRoute.snapshot.queryParams['projectId'];

    if (defaultProjectId) {
      this.selectedProjects$.next([defaultProjectId]);
      this.disabledProjects = [defaultProjectId];
    }

    this.form = this._fb.group({
      name: ['', ModelValidators.board.name],
      type: [boardType.kanban, Validators.required],
      projects: [
        [],
        (c: FormControl) =>
          this.form && this.form.get('type') && this.form.get('type').value === boardType.sprint
            ? Validators.required(c) || c.value.length !== 1 ? { invalidProjectsCount: true } : null
            : Validators.nullValidator(c)
      ],
      collections: [[]],
      template: [this.fromTemplate],
      ...(this.isWithTasksAvailable && { withTasks: [true] })
    });
    if (isObject(this.initialValues)) {
      Object.keys(this.initialValues).forEach(prop => {
        this.form.get(prop).patchValue(this.initialValues[prop]);
      });
    }
    this.formChange.emit(this.form);
    this.subs.push(
      this.selectedProjects$.subscribe(
        value =>
          compareArrays(this.form.get('projects').value, value)
            ? null
            : this.form.get('projects').patchValue(value) || this.form.get('projects').markAsDirty()
      )
    );
    this.subs.push(
      this.selectedCollections$.subscribe(
        value =>
          compareArrays(this.form.get('collections').value, value)
            ? null
            : this.form.get('collections').patchValue(value) || this.form.get('collections').markAsDirty()
      )
    );
  }

  ngAfterViewInit() {
    this.boardNameInput.nativeElement.select();
  }

  onSubmit() {
    this.submit.emit();
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
