<div class="default_statuses_filter"
     (click)="onShowStatusesPopup()"
     [ngClass]="{'active': showStatusesPopUp}"
>
  Statuses
</div>

<div *ngFor="let status of selectedStatuses$ | async"
     class="status_filter_tag"
     (click)="onRemoveStatus(status)"
>
  <p class="status_title">
    {{status.name}}
  </p>
  <div class="clear_block"></div>
</div>

<div a-context-popup *ngIf="showStatusesPopUp"
     [title]="'Status'"
     class="default_statuses_list_popup"
     (close)="onHideStatusesPopup()"
>
  <statuses-filter-list
    [statuses]="boardStatuses$ | async"
    [selectedStatuses]="selectedStatuses$ | async"
    (addStatus)="onAddStatus($event)"
    (removeStatus)="onRemoveStatus($event)"
  ></statuses-filter-list>
</div>
