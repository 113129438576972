import { Component, Input, OnChanges } from '@angular/core';
import { Task } from '../../interfaces';
import * as moment from 'moment-mini-ts';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'task-time-in-progress',
  template: `<ng-template [ngIf]="(message$ | async)?.length">{{message$ | async}} In Progress</ng-template>`
})
export class TaskTimeInProgressComponent implements OnChanges {
  @Input() task: Task;

  message$ = new BehaviorSubject('');

  nextTick;

  ngOnChanges() {
    this.tick();
  }

  ngOnDestroy() {
    clearTimeout(this.nextTick);
  }

  tick() {
    const seconds = this.task.lastInProgress
      ? Math.max(+moment.utc().format('X') - this.task.lastInProgress, 0) + this.task.timeInProgress
      : this.task.timeInProgress;

    clearTimeout(this.nextTick);
    this.nextTick = setTimeout(() => this.tick(), 60 * 1000);

    if (seconds < 60 * 5) {
      return this.message$.next('');
    }

    let result: string;
    const maxMinuteInSec = 59 * 60;
    const maxHoursInSec = 23 * 3600;

    switch (true) {
      case seconds <= maxMinuteInSec:
        const minutes = Math.floor(seconds / 60);
        result = moment.duration(minutes, 'minutes').humanize();
        break;
      case seconds > maxMinuteInSec && seconds <= maxHoursInSec:
        const hours = Math.floor(seconds / 3600);
        result = moment.duration(hours, 'hours').humanize();
        break;
      case seconds > maxHoursInSec:
        const day = Math.floor(seconds / 24 / 3600);
        result = moment.duration(day, 'days').humanize();
        break;
    }

    this.message$.next(result);
  }
}
