import { Component, Input } from '@angular/core';
import { Task, Label } from '../../../interfaces';

@Component({
  selector: 'sidebar-labels',
  templateUrl: './sidebar-labels.component.html',
  styleUrls: ['./sidebar-labels.component.scss']
})
export class SidebarLabelsComponent {
  @Input() taskLabels: Label[];
  @Input() task: Task;
  @Input() editPermissions = false;

  public isLabelsPopupVisible = false;

  onOpenLabelsPopup() {
    if (!this.editPermissions) {
      return false;
    }
    this.isLabelsPopupVisible = true;
  }
  onCloseLabelsPopup() {
    this.isLabelsPopupVisible = false;
  }
}
