
import {combineLatest as observableCombineLatest,  Observable } from 'rxjs';

import {refCount, publishReplay, distinctUntilChanged} from 'rxjs/operators';
import { GroupActionTypes } from '../actions/group.actions';
import { ESInterface, AppState } from '../state';
import { Group } from '../../interfaces';
import { addEntityState, deleteEntityState, editEntityState } from '../functions/reducer';
import {
  getEntityByIdSelector,
  getEntitySelector,
  getAllEntities,
  getAllEntitiesAsArray
} from '../functions/selectors';
import { GROUP_PL } from '../../constants';
import { compose } from '@ngrx/store';
import { getBoardGroupIds } from './board.reducer';
import { replaceIds, compareArrays } from '../../../helpers';
import { Action } from '../actions/unsafe-action';
import { share } from '../functions/util';

export const EVERYONE_ID = 'everyone';
export const EVERYONE_NAME = 'Everyone';

const initialState: ESInterface<Group> = {
  ids: [],
  entities: {},
  selectedEntityId: null
};

export function reducer(state = initialState, action: Action): ESInterface<Group> {
  switch (action.type) {
    case GroupActionTypes.ADD_COMPLETE: {
      return addEntityState(state, action.payload, false);
    }

    case GroupActionTypes.DELETE: {
      return <ESInterface<Group>>deleteEntityState(state, action.payload);
    }

    case GroupActionTypes.EDIT: {
      return editEntityState(state, action.payload);
    }

    case GroupActionTypes.ASSIGN_USERS: {
      const groupId = action.payload.id;
      const usersIds = replaceIds(state.entities[groupId].usersIds, action.payload.users);

      return editEntityState(state, { id: groupId, usersIds: usersIds });
    }

    default: {
      return state;
    }
  }
}

export const getGroupEntities = compose(getAllEntities, getEntitySelector(GROUP_PL));
export const getAllGroups = share(compose(getAllEntitiesAsArray, getEntitySelector(GROUP_PL)));
export const getGroupById = (id: number | string) =>
  share(compose(getEntityByIdSelector<Group>(id), getEntitySelector(GROUP_PL)));

export function getBoardGroups(state$: Observable<AppState>) {
  return observableCombineLatest(
    getGroupEntities(state$),
    getBoardGroupIds(state$),
    (groups: { [id: number]: Group }, groupIds: number[]) => {
      console.log(groups, groupIds, 'getBoardGroups');
      return groupIds.map(groupId => groups[groupId]);
    }
  ).pipe(
    distinctUntilChanged(compareArrays),
    publishReplay(1),
    refCount(),);
}
