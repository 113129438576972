
import {delay, filter, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';
import { platform } from '../../../helpers/platform';
import { Subject ,  Observable ,  ReplaySubject } from 'rxjs';
import { isPresent } from '../../../helpers';

@Directive({
  selector: '[focus]'
})
export class ElementFocusDirective implements AfterViewInit, OnDestroy {
  @Input()
  set focus(value: boolean | Observable<boolean>) {
    if (typeof value === 'object' && value && value.takeUntil) {
      value.pipe(takeUntil(this.destroy$)).subscribe(v => this.focus$.next(v));
    } else {
      this.focus$.next(!!value);
    }
  }

  private destroy$ = new Subject();
  private focus$ = new ReplaySubject(1);

  @HostListener('blur')
  onBlur() {
    this.focus$.next(false);
  }

  constructor(private _element: ElementRef) {}

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  ngAfterViewInit() {
    if ((platform.name === 'IE' || platform.name === 'Safari') && this._element.nativeElement.value) {
      const positionCaret = this._element.nativeElement.value.length;
      if (this._element.nativeElement.setSelectionRange) {
        this._element.nativeElement.setSelectionRange(positionCaret, positionCaret);
      }
    }

    this.focus$.pipe(
      distinctUntilChanged(),
      filter(isPresent),
      takeUntil(this.destroy$),
      delay(250),)
      .subscribe(this.setFocus.bind(this));
  }

  private setFocus() {
    this._element.nativeElement.focus();
  }
}
