import * as codes from './entity';

export const ENTITY_CODES = [
  codes.COMPANY,
  codes.PROJECT,
  codes.USER,
  codes.BOARD,
  codes.COLLECTION,
  codes.COLUMN,
  codes.TASK,
  codes.SCORING_CRITERIA,
  codes.SPRINT_TASK,
  codes.GROUP,
  codes.CLIENT,
  codes.TAG,
  codes.VERSION,
  codes.FILTER,
  codes.USER_COMPANY,
  codes.COMPANY,
  codes.LABEL,
  codes.NOTIFICATION,
  codes.CHECKLIST,
  codes.CHECKLISTS_ITEM,
  codes.SWIMLANE,
  codes.RECENTLY_COMPLETED_TASK,
  codes.TASKS_ATTACHMENT,
  codes.INTEGRATIONS_DATA,
  codes.BOARD_ACTIVITY,
  codes.WORKLOG,
  codes.ACTIVITY,
  codes.IMPORT,
  codes.COMMENT,
  codes.HARVEST_BOARD,
  codes.BOARDS_TEMPLATE
];

export const ENTITY_PL_CODES = [
  codes.COMPANY_PL,
  codes.PROJECT_PL,
  codes.USER_PL,
  codes.BOARD_PL,
  codes.COLUMN_PL,
  codes.COLLECTION_PL,
  codes.TASK_PL,
  codes.SCORING_CRITERIA_PL,
  codes.SPRINT_TASK_PL,
  codes.LABEL_PL,
  codes.GROUP_PL,
  codes.CLIENT_PL,
  codes.TAG_PL,
  codes.VERSION_PL,
  codes.FILTER_PL,
  codes.USER_COMPANY_PL,
  codes.COMPANY_PL,
  codes.NOTIFICATION_PL,
  codes.CHECKLIST_PL,
  codes.CHECKLISTS_ITEM_PL,
  codes.SWIMLANE_PL,
  codes.RECENTLY_COMPLETED_TASK_PL,
  codes.TASKS_ATTACHMENT_PL,
  codes.WORKLOG_PL,
  codes.SUBSCRIBER_PL,
  codes.TASK_ACTIVITY_PL,
  codes.COMMENT_PL,
  codes.IMPORT_PL,
  codes.BOARD_ACTIVITY_PL,
  codes.COMPANY_FEATURE_PL,
  codes.RECENT_TASK_PL,
  codes.TASKS_LINKS_TYPE_PL,
  codes.TASKS_LINK_PL,
  codes.QUICK_BACKUP_PL,
  codes.HARVEST_BOARD_PL,
  codes.BOARDS_TEMPLATE_PL
];
