
import {startWith, debounceTime, merge} from 'rxjs/operators';
import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { Subject ,  Subscription } from 'rxjs';

@Directive({
  selector: '[outOfViewport]'
})
export class OutOfViewPortDirective implements AfterViewInit, OnDestroy {
  @Input() outOfViewport = 'outOfViewport';
  @Input() outOfViewportX = 'outOfViewportX';
  @Input() checkEvent$ = new Subject();

  private subject$ = new Subject();
  private listener = e => this.subject$.next(e);

  subs: Subscription[] = [];

  constructor(private _elementRef: ElementRef) {}

  ngAfterViewInit() {
    window.addEventListener('scroll', this.listener, true);
    this.subs.push(
      this.subject$.pipe(
        merge(this.checkEvent$),
        debounceTime(100),
        startWith(null),)
        .subscribe(() => this.doubleCheck())
    );
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.listener);
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  doubleCheck() {
    this._elementRef.nativeElement.classList.remove(this.outOfViewport);
    const rect = this._elementRef.nativeElement.getBoundingClientRect();
    const windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    if (rect.bottom > windowHeight) {
      this._elementRef.nativeElement.classList.add(this.outOfViewport);
    }
    if (this.outOfViewportX) {
      const windowWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      if (rect.right > windowWidth) {
        this._elementRef.nativeElement.classList.add(this.outOfViewportX);
      }
    }
  }
}
