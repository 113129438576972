import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';

import { Task } from '../../../../interfaces';
import { BacklogChartService } from '../../backlog-chart.service';
import { truncate } from '../../../../../helpers';
import { platform } from '../../../../../helpers/platform';

@Component({
  selector: 'g[task-circle]',
  templateUrl: './task-circle.component.html',
  styleUrls: ['./task-circle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskCircleComponent {
  @ViewChild('taskCircle') taskCircle: ElementRef;

  @Input() task: Task;
  @Input() highlight: boolean;
  @Input() countInGroup: number;
  @Input() xScale;
  @Input() yScale;

  get transform() {
    return this.translate(this.xScale(this.task.backlogScoreX), this.yScale(this.task.backlogScoreY));
  }

  get title() {
    return truncate(this.task.title, 17);
  }

  get longTitle() {
    return truncate(this.task.title.slice(17), 17);
  }

  get isGrouped() {
    return this.countInGroup > 1;
  }

  public circleAttrY = platform.name === 'Chrome' ? -4 : -1;

  constructor(public _backlogChart: BacklogChartService) {}

  public translate(x: number, y: number): string {
    return `translate(${x}, ${y})`;
  }

  onShowTooltip(event: MouseEvent) {
    const element = <HTMLElement>event.target;
    const r = element.getBoundingClientRect();

    const dialogData = this._backlogChart.groupTaskDialog$.getValue();
    const isEqualEfforts = dialogData && dialogData.backlogScoreX === this.task.backlogScoreX;
    const isEqualValue = dialogData && dialogData.backlogScoreY === this.task.backlogScoreY;
    const notEqualBacklogValue = !(isEqualEfforts && isEqualValue);

    if (!this._backlogChart.draggingTaskId$.getValue() && notEqualBacklogValue) {
      this._backlogChart.groupTaskDialog$.next({
        rect: r,
        backlogScoreX: this.task.backlogScoreX,
        backlogScoreY: this.task.backlogScoreY
      });
    }
  }

  onHideTooltip() {
    if (!this.isGrouped) {
      this._backlogChart.closeDialog();
    }
  }
}
