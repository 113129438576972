import { createSelector } from 'reselect';

import { GITHUB_ASSOCIATED_REPOSITORY_PL } from '../../../../constants/index';
import { removeOne } from '../../../../ngrx/functions/reducer';
import { getEmptyESState } from '../../../../ngrx/state/index';
import { createCommonSelectors } from '../../../../util/store/index';
import { ESGithubAssociatedRepository, GithubAssociatedRepository } from '../../models/git-repository';
import * as githubCompany from '../github-company/github-company.actions';
import * as githubAssociatedRepository from './github-associated-repository.actions';

export interface State extends ESGithubAssociatedRepository {
  isLoading: boolean;
}

const initialState: State = {
  ...getEmptyESState<GithubAssociatedRepository>(),
  isLoading: false
};

export function reducer(
  state = initialState,
  action: githubAssociatedRepository.Actions | githubCompany.Actions
): State {
  switch (action.type) {
    case githubAssociatedRepository.GET_INDEX: {
      return { ...state, isLoading: true };
    }

    case githubAssociatedRepository.GET_INDEX_FAIL:
    case githubAssociatedRepository.GET_INDEX_COMPLETE: {
      return { ...state, isLoading: false };
    }

    case githubAssociatedRepository.REMOVE: {
      return removeOne(state, action.payload.id);
    }

    case githubCompany.CLEAR_SETTINGS:
    case githubCompany.REMOVE_ALL_COMPLETE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const { getState, getEntities, getIds, getAll, isEmpty } = createCommonSelectors<
  GithubAssociatedRepository,
  State
>(GITHUB_ASSOCIATED_REPOSITORY_PL);

export const isLoading = createSelector(getState, state => state.isLoading);

export const getAssociatedRepoMap = createSelector(getAll, repos =>
  repos.reduce((map, repo) => Object.assign(map, { [repo.repositoryId]: repo }), {})
);
