<ng-container>
  <form>
    <div>
      <label for="number-more-than">Between</label>
      <input #moreThanInput id="number-more-than" type="number" min="0" step="1" [(ngModel)]="moreThan" [ngModelOptions]="{standalone: true}">
      <label for="less-more-than">and</label>
      <input #lessThanInput id="less-more-than" type="number" min="0" step="1" [(ngModel)]="lessThan" [ngModelOptions]="{standalone: true}">
      days
    </div>
    <div class="form-actions">
      <button a-button class="btn_primary button" type="submit" (click)="onSubmit()">Save</button>
      <button a-button class="btn_text button" type="button" (click)="onCancel()">Cancel</button>
    </div>
  </form>
</ng-container>
