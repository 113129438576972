import { createCommonSelectors } from '../../../../util/store/index';
import { getEmptyESState } from '../../../../ngrx/state/index';
import { GITHUB_COMPANY_PL } from '../../../../constants/index';
import { ESGithubCompany, GithubCompany } from '../../models/git-company';

import * as githubCompany from './github-company.actions';
import { createSelector } from 'reselect';
import { prop } from '../../../../../helpers/index';

export interface State extends ESGithubCompany {
  isLoading: boolean;
}

const initialState: State = {
  ...getEmptyESState<GithubCompany>(),
  isLoading: true
};

export function reducer(state = initialState, action: githubCompany.Actions): State {
  switch (action.type) {
    case githubCompany.GET_INDEX: {
      return { ...state, isLoading: true };
    }

    case githubCompany.GET_INDEX_FAIL:
    case githubCompany.GET_INDEX_COMPLETE: {
      return { ...state, isLoading: false };
    }

    case githubCompany.CLEAR_SETTINGS:
    case githubCompany.REMOVE_ALL_COMPLETE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const { getState, getEntities, getIds, getAll, isEmpty } = createCommonSelectors<GithubCompany, State>(
  GITHUB_COMPANY_PL
);

export const getFirstId = createSelector(getIds, ids => ids[0]);
export const isLoading = createSelector(getState, prop('isLoading'));

export const isFirstLoading = createSelector(isEmpty, isLoading, (e, l) => l && e);
