<form [formGroup]="form">
  <form-errors></form-errors>
  <div class="row">
    <div *ngFor="let statusItem of project.statuses" class="radio_wrapper">
      <label class="inline_label">
        <input type="radio" [formControlName]="'status'" [value]="statusItem" name="status">
        <span class="radio_state"></span>
        <span class="radio_value">{{statusItem}}</span>
      </label>
      <span class="status_deleter" (click)="onDeleteStatus(statusItem)"></span>
    </div>

  </div>

  <div class="row">
    <label for="project-add-status">Add new Status</label>
    <div class="add_new_status">
      <input autocomplete="off" id="project-add-status" [formControlName]="'newStatus'" type="text" maxlength="50"/>
      <button a-button class="btn_text" (click)="onAddNewStatus()">Add</button>
    </div>
    <div *ngIf="showLengthError" class="error">
      Maximum length of 50 symbols is exceed
    </div>
  </div>

  <div *ngIf="(allProjects$ | async)?.length" class="row">
    <span *ngIf="!isImportVisible" class="link_text" (click)="showImport()">Import from another project</span>
    <div *ngIf="isImportVisible">
      <label for="import-from-prj">Project</label>
      <div class="add_new_status">
        <div *ngIf="(allProjects$ | async)?.length" class="import_inputs">
          <select id="import-from-prj" [formControlName]="'imported'">
            <option *ngFor="let prj of (allProjects$ | async)" [ngValue]="prj.statuses">{{prj.name}}</option>
          </select>
          <button a-button class="btn_text" (click)="onImport()">Import</button>
        </div>
        <div *ngIf="!(allProjects$ | async)?.length">
          You have nothing to import from other projects
        </div>
      </div>
    </div>
  </div>

  <div class="buttons_container">
    <button a-button class="btn_primary"
            [pending]="(_formService.isPending$ | async)"
            (click)="onSubmit()">
      Save
    </button>
    <button a-button class="btn_text" (click)="onClose()">Cancel</button>
  </div>
</form>
