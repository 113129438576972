import { Component, OnInit, Input, OnChanges, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Project, Board } from '../../interfaces';
import { AppUrls } from '../../app-urls';
import { Store } from '@ngrx/store';
import { AppState, LEFT_MENU } from '../../ngrx/state';
import { ComponentSetVisibleAction } from '../../ngrx/actions/component.actions';
import { SearchInLeftMenuService } from '../../shared/services/app/search-in-left-menu.service';
import { OpenedStateLeftMenuItemsService } from '../shared/services/opened-state-left-menu-items.service';
import { Observable } from 'rxjs/Observable';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { PermissionsService } from '../../permissions/permissions.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'left-project-item',
  templateUrl: './left-project-item.component.html',
  styleUrls: ['./left-project-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeftProjectItemComponent implements OnInit, OnChanges, OnDestroy {
  @Input() project: Project;
  @Input() visibleBoards: Board[];

  public isVisibleBoards = false;
  public appUrls = AppUrls;
  public openedStateProjectListKey: string;
  public canEditProject$: Observable<boolean>;
  private _projectId$: BehaviorSubject<number> = new BehaviorSubject(this.project ? this.project.id : 0);

  public subscription;
  public isNotGuest$ = this._permissions.isNotGuest$;

  public isContextMenuVisible: boolean;

  constructor(
    private _store: Store<AppState>,
    private _searchInLeftMenuService: SearchInLeftMenuService,
    private _openedStateLeftMenuItemsService: OpenedStateLeftMenuItemsService,
    private _permissions: PermissionsService
  ) {}

  ngOnInit(): any {
    this.openedStateProjectListKey = this._openedStateLeftMenuItemsService.localStoreKeys.OPENED_PROJECT_LIST;
    this.initIsVisibleBoards();
    this.canEditProject$ = combineLatest(
      this.isNotGuest$,
      this._permissions.isSuperUser$,
      this._projectId$.pipe(switchMap(id => this._permissions.isSomeOfProjectsAdmin$([id])))
    ).pipe(map(([a, b, c]) => a && (b || c)));
  }

  ngOnChanges() {
    this._projectId$.next(this.project ? this.project.id : 0);
    this.initIsVisibleBoards();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onCloseLeftMenu() {
    this._store.dispatch(new ComponentSetVisibleAction({ name: LEFT_MENU, visible: false }));
  }

  onSwitchContextMenu() {
    this.isContextMenuVisible = !this.isContextMenuVisible;
  }

  onOpenBoardsList() {
    this.isVisibleBoards = !this.isVisibleBoards;
    this._openedStateLeftMenuItemsService.save(this.isVisibleBoards, this.openedStateProjectListKey, this.project.id);
  }

  initIsVisibleBoards() {
    this.subscription = this._searchInLeftMenuService.isSearchActive$.subscribe(state => {
      this.isVisibleBoards = state;
      if (!state) {
        const list = this._openedStateLeftMenuItemsService.getOpenedProjectsList(this.openedStateProjectListKey);
        if (!list.includes(this.project.id.toString())) {
          this.isVisibleBoards = true;
        }
      }
    });
  }
}
