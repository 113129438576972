import { InjectionToken, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule, ActionReducerMap } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './effects/user.effect';
import { RootEffects } from './effects/root.effect';
import { ChecklistEffects } from './effects/checklist.effect';
import { ProjectEffects } from './effects/project.effect';
import { BoardEffects } from './effects/board.effect';
import { SwimlaneEffects } from './effects/swimlane.effect';
import { ColumnEffects } from './effects/column.effect';
import { TaskEffects } from './effects/task.effect';
import { GroupEffects } from './effects/group.effect';
import { GlobalSearchEffects } from './effects/global-search.effect';
import { QuickFilterEffects } from './effects/quick-filter.effect';
import { VersionEffects } from './effects/version.effect';
import { TaskAttachmentsEffects } from './effects/task-attachments.effect';
import { NotificationEffects } from './effects/notification.effect';
import { LabelEffects } from './effects/label.effect';
import { GuiStateMemorizedEffects } from './effects/gui-state-memorized.effect';
import { BoardActivityEffects } from './effects/board-activity.effect';
import { WorkLogEffects } from './effects/worklog.effect';
import { OverviewActivityEffects } from './overview-activity/overview-activity.effect';
import { TaskDefaultFilterEffects } from './effects/task-default-filter.effect';
import { TaskQuickFilterEffects } from './effects/task-quick-filter.effect';
import { DefaultTasksMembersFilterEffects } from './effects/default-members-filter.effect';
import { CollectionEffects } from './effects/collection.effect';
import { DefaultCollectionEffects } from './effects/default-collection.effect';
import { DataSyncEffects } from './effects/data-sync.effect';
import { ActiveImportEffects } from '../company-settings/settings/import/ngrx/effects/active-import.effect';
import { TaskActivityEffects } from './effects/task-activity.effect';
import { UserCompanyEffect } from './effects/user-company.effect';
import { ComponentEffects } from './effects/component.effects';
import { SegmentEffects } from './effects/segment.effects';
import { DefaultTasksLabelsFilterEffects } from './effects/default-labels-filter.effect';
import { OpenedTaskHistoryEffect } from './effects/opened-task-history.effect';
import { CurrentCompanyEffect } from './effects/current-company.effect';
import { ActiveUserEffects } from './effects/active-user.effects';
import { DefaultTasksColumnsFilterEffects } from './effects/default-columns-filter.effects';
import { DefaultTasksSwimlanesFilterEffects } from './effects/default-swimlanes-filter.effects';
import { InitialDataEffects } from './effects/initial-data.effects';
import { AppState } from './state';
import { getReducers, metaReducers } from './reducers';
import { CustomFieldEffects } from './effects/custom-field.effects';
import { DefaultTasksTimeOnBoardFilterEffects } from "./effects/default-time-on-board-filter.effects";
import { DefaultTasksTimeOnColumnFilterEffects } from "./effects/default-time-on-column-filter.effects";
import { CompanyDomainEffects } from './effects/company-domain.effect';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<AppState>>('Registered Reducers');

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(REDUCER_TOKEN, { metaReducers }),
    // disabled by default, enable
    // StoreDevtoolsModule.instrument(),
    EffectsModule.forRoot([
      RootEffects,
      ProjectEffects,
      CollectionEffects,
      GuiStateMemorizedEffects,
      UserEffects,
      UserCompanyEffect,
      ActiveUserEffects,
      ActiveImportEffects,
      GroupEffects,
      SwimlaneEffects,
      BoardEffects,
      GlobalSearchEffects,
      LabelEffects,
      WorkLogEffects,
      DefaultCollectionEffects,
      ColumnEffects,
      TaskEffects,
      QuickFilterEffects,
      VersionEffects,
      ChecklistEffects,
      TaskAttachmentsEffects,
      NotificationEffects,
      BoardActivityEffects,
      OverviewActivityEffects,
      TaskDefaultFilterEffects,
      TaskQuickFilterEffects,
      DefaultTasksMembersFilterEffects,
      DefaultTasksLabelsFilterEffects,
      DefaultTasksColumnsFilterEffects,
      DefaultTasksSwimlanesFilterEffects,
      DefaultTasksTimeOnBoardFilterEffects,
      DefaultTasksTimeOnColumnFilterEffects,
      DataSyncEffects,
      OpenedTaskHistoryEffect,
      CurrentCompanyEffect,
      TaskActivityEffects,
      ComponentEffects,
      InitialDataEffects,
      SegmentEffects,
      CustomFieldEffects,
      CompanyDomainEffects
    ])
  ],
  declarations: [],
  providers: [
    {
      provide: REDUCER_TOKEN,
      deps: [],
      useFactory: getReducers
    }
  ],
  exports: []
})
export class NgrxModule {}
