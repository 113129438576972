import { distinctUntilChanged, pluck } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { PermissionActionTypes, PERMISSIONS } from './permission.action';
import { RoleCompany, RoleProject } from '../interfaces/roles';
import { AppState } from '../../ngrx/state';
import { Observable } from 'rxjs';
import { compose } from '@ngrx/store';
import { Action } from '../../ngrx/actions/unsafe-action';
import { st } from '@angular/core/src/render3';

const initialState = {};

interface Permissions<T> {
  [id: string]: T;
}

interface PermissionState {
  id: string;
  type: string;
  companyRole: RoleCompany;

  boards: BoardPermissions[];
  projects: ProjectPermissions[];
}

interface BoardPermissions {
  editPermissions: boolean;
  joinToBoard: boolean;
  changeBoardClosed: boolean;
  changeBoardProjects: boolean;
  id: number;
  type: string;
}

interface ProjectPermissions {
  editPermissions: boolean;
  projectRole: RoleProject;
  id: number;
  type: string;
}

export function reducer(state = initialState, action: Action): any {
  switch (action.type) {
    case PermissionActionTypes.GET_COMPLETE: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
}

export const getPermissionState: (store$: Store<AppState>) => Observable<PermissionState> = state$ =>
  state$.pipe(pluck<AppState, PermissionState>(PERMISSIONS), distinctUntilChanged());
