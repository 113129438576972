import { Action } from "./unsafe-action";
import { HttpQueryParam, PartOfEntity } from "../../interfaces";

export enum CompanyDomainTypes {
  GET = '[COMPANY DOMAIN] GET',
  EDIT = '[COMPANY DOMAIN] EDIT',
  EDIT_COMPLETE = '[COMPANY DOMAIN] EDIT COMPLETE',
}

export class CompanyDomainGetAction implements Action {
  readonly type = CompanyDomainTypes.GET;

  constructor(public payload?: HttpQueryParam) {}
}

export class CompanyDomainEditAction implements Action {
  type = CompanyDomainTypes.EDIT;

  constructor(public payload: PartOfEntity) {}
}

// prettier-ignore
export type CompanyDomainActions =
  | CompanyDomainGetAction
  | CompanyDomainEditAction;