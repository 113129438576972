
import {map, filter, switchMap, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { isNotPresent } from '../../../../helpers/index';
import { AppState } from '../../../ngrx/state/app-state';
import { RouterNavigateService } from '../../../shared/services/router-navigate.service';
import * as githubAssocRepo from '../../git/store/github-associated-repository/github-associated-repository.actions';
import * as githubCompany from '../../git/store/github-company/github-company.actions';
import * as fromGithubCompany from '../../git/store/github-company/github-company.reducer';
import * as githubRepo from '../../git/store/github-repository/github-repository.actions';

@Injectable()
export class GithubCompanyExistsGuard implements CanActivate {
  constructor(private routerNav: RouterNavigateService, private store: Store<AppState>) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.store.dispatch(new githubCompany.GetIndex());

    const loadedEvent$ = this.store.select(fromGithubCompany.isFirstLoading).pipe(filter(isNotPresent));
    const isEmpty$ = loadedEvent$.pipe(switchMap(_ => this.store.select(fromGithubCompany.isEmpty)),take(1),);

    return isEmpty$.pipe(map(this.navigateSideEffect.bind(this)));
  }

  private navigateSideEffect(isEmpty: boolean): true {
    if (isEmpty) {
      this.routerNav.navigateToGithubAddCompany();
      return true;
    }

    this.store.dispatch(new githubAssocRepo.GetIndex());
    this.store.dispatch(new githubRepo.GetIndex());

    return true;
  }
}
