<right-menu-container [showBackArrow]="false" [title]="'Menu'">
  <div class="menu_item">
    <right-menu-members
      class="right_menu_block"
      [boardId]="boardId"
      [users]="users$ | async"
      [boardUsers]="boardUsers$ | async"
      [boardProjects]="boardProjects$ | async"
      [selectedBoard$]="selectedBoard$"
    ></right-menu-members>
  </div>
  <div class="menu_item">
    <right-menu-board-color
      class="menu_item"
      [board$]="selectedBoard$"
    ></right-menu-board-color>
  </div>
  <div class="menu_item">
    <right-menu-advanced-scoring [board]="selectedBoard$ | async" (showAdvancedScoringSettings)="showAdvancedScoringSettings($event)">
    </right-menu-advanced-scoring>
    <div class="divider"></div>
  </div>
  <div class="menu_item">
    <right-menu-custom-fields [board]="selectedBoard$ | async"></right-menu-custom-fields>
  </div>
  <div class="menu_item">

    <right-menu-projects
      [boardProjects]="boardProjects$ | async"
      [projects]="projects$ | async"
      [boardId]="boardId"
      [selectedBoard]="selectedBoard$ | async"
    ></right-menu-projects>
  </div>
  <div class="menu_item">

    <right-menu-collections
      [boardCollections]="boardCollections$ | async"
      [collections]="collections$ | async"
      [boardId]="boardId"
      [selectedBoard]="selectedBoard$ | async"
    ></right-menu-collections>
  </div>
  <!-- SPRINT LIST OF OPTIONS -->
  <div class="menu_item" *ngIf="showSprintMenu$ | async">
    <div class="menu_links">
      <div class="menu_item_title">Current Sprint Info</div>

      <div [routerLink]="appUrls.getUrlBurndownChart(boardId)"
           class="menu_item_link"
      >
        Burndown Chart
      </div>
      <div (click)="isSprintBoundariesSubMenuVisible = true"
           class="menu_item_link"
      >
        Sprint Settings
      </div>
      <div class="menu_item_link" (click)="goToReleaseSprint()">
        Release Sprint
      </div>
      <div class="menu_item_link" (click)="goToStopSprint()"
      >
        Stop Sprint
      </div>
    </div>
    <div class="divider"></div>

  </div>
  <!-- SPRINT LIST OF OPTIONS -->
  <div class="menu_item" *ngIf="isSprintBoard$ | async">
    <div class="menu_links">
      <div class="menu_item_title">History</div>

      <div [routerLink]="appUrls.getUrlSprintBoardHistory(boardId)"
           class="menu_item_link"
      >
        Released Sprints
      </div>
      <div [routerLink]="appUrls.getUrlVelocityReport(boardId)"
           class="menu_item_link"
      >
        Velocity Report
      </div>
    </div>
    <div class="divider"></div>

  </div>
  <!-- MAJOR RIGHT MENU LIST OF OPTIONS -->
  <div class="menu_item">

    <div class="menu_links">
      <div class="menu_item_title">More</div>

      <div (click)="isFiltersRightSubMenuVisible = true" class="menu_item_link" *ngIf="!(isRoadmapBoard$ | async)">
        Quick Filters
      </div>
      <right-menu-show-props class="menu_item_link" [board]="selectedBoard$ | async"></right-menu-show-props>
      <div (click)="onCreateSwimlaneToggle()" class="menu_item_link" *ngIf="!(isRoadmapBoard$ | async)">
        Create Swimlane
      </div>
      <div (click)="isLabelsRightSubMenuVisible = true" class="menu_item_link">
        Labels
      </div>
      <div (click)="isReleasedRightSubMenuVisible = true" class="menu_item_link"
           *ngIf="!((selectedBoard$ | async)?.type === boardTypes.sprint)"
      >
        Released Tasks
      </div>
      <div (click)="isArchivedRightSubMenuVisible = true" class="menu_item_link">
        Archived Items
      </div>
      <div (click)="onSubscribe()" class="menu_item_link" [class.check]="isUserSubscribedOnBoard$ | async">
        {{(isUserSubscribedOnBoard$ | async) ? 'Unsubscribe' : 'Subscribe'}}
      </div>
      <div class="menu_item_link" [routerLink]="appUrls.getUrlCopyBoard(boardId)">
        Copy Board
      </div>
      <div (click)="isExportRightSubMenuVisible = true" class="menu_item_link">Export</div>
      <div (click)="isCloseBoardVisible = true" class="menu_item_link" *ngIf="canCloseBoard$ | async">
        Close Board
      </div>
    </div>
    <div class="divider"></div>
    <div class="menu_links">
        <ng-template #divider>
          <div divider class="divider"></div>
        </ng-template>
        <rmenu-integration-list [boardId]="boardId" [divider]="divider"></rmenu-integration-list>

    </div>


  </div>
  <!-- ACTIVITY SECTION -->
  <div class="menu_item" *ngIf="isShowActivities;else loadActivites">
    <div class="board_activities_block">
      <board-activity></board-activity>
    </div>
  </div>
  <!-- ACTIVITY SECTION -->

  <create-swimlane-popup
    [boardId]="boardId"
    *ngIf="isCreateSwimlaneVisible"
    (close)="onCreateSwimlaneToggle()"
  ></create-swimlane-popup>
  <div a-confirmation-popup
       confirmationTitle="Close board"
       *ngIf="isCloseBoardVisible"
       actionButtonText="Close"
       (confirm)="onCloseBoard()"
       (close)="onHideCloseBoardPopup()"
  >
    You can reopen the board in Project Settings section.
  </div>

</right-menu-container>

<!-- Start of Quick filters popup -->
<right-menu-container
  *ngIf="isFiltersRightSubMenuVisible"
  (close)="isFiltersRightSubMenuVisible = false"
  [title]="'Quick Filters'"
>
  <div class="menu_item quick_filters">
    <div class="menu_links">

      <div class="menu_item_link" *ngFor="let qfilter of quickFilters$ | async">
        <span class="" (click)="onOpenQuickFilterForm(qfilter)">{{qfilter.name}}</span>
        <div class="edit_icon" (click)="onOpenQuickFilterForm(qfilter)"></div>
        <div class="delete_icon" (click)="onToggleDeleteQfilterPopup(qfilter.id)"></div>

        <div a-confirmation-popup
             *ngIf="isDeleteQfilterVisible === qfilter.id"
             [confirmationTitle]="'Delete quick filter ' + qfilter.name + ' ?'"
             [actionButtonText]="'Delete'"
             (confirm)="onDeleteQfilter(qfilter.id)"
             (close)="onToggleDeleteQfilterPopup(qfilter.id)">
          The change cannot be reverted.
        </div>
      </div>
      <div class="menu_item_link menu_label_item" (click)="onOpenQuickFilterForm()">
        <div class="ico_add_label"></div>
        <div class="menu_label_create">Add Filter</div>
      </div>
    </div>
  </div>
</right-menu-container>
<right-menu-container
  *ngIf="isQuickFilterMenuVisible"
  (close)="isQuickFilterMenuVisible = false"
  [title]="selectedFilter !== null ? 'Edit Quick Filter' : 'Add Quick Filters'"
>
  <div *ngIf="isQuickFilterMenuVisible">
    <quickfilter-form
      [boardUsers]="boardUsers$ | async"
      [versions]="versions$ | async"
      [quickFilter]="selectedFilter"
      (addFilterEvent)="onAddQFilter($event);"
      (replaceFilterEvent)="onReplaceQFilter($event)"
    ></quickfilter-form>
  </div>
</right-menu-container>
<!-- End of Quick filters popup-->

<!-- Start of Export popup -->
<right-menu-container
  *ngIf="isExportRightSubMenuVisible"
  (close)="isExportRightSubMenuVisible = false"
  [title]="'Export'">
  <div class="menu_list">
    <p class="menu_item_link" *ngIf="(selectedBoard$ | async)?.type === boardTypes.roadmap" (click)="onTogglePDFExport()">Export as PDF</p>
    <a href="{{exportCSVUrl}}" class="menu_item_link" target="_blank" rel="noopener" download>Export as CSV</a>
    <p class="menu_item_link" (click)="onToggleSyncCalendar()">Sync to Calendar</p>
    <icalendar-sync-popup [boardId]="this.boardId" *ngIf="isCalendarSyncPopupVisible" (close)="onToggleSyncCalendar()"></icalendar-sync-popup>
    <pdf-export-popup [boardId]="this.boardId" *ngIf="isPDFPopupVisible" (close)="onTogglePDFExport()"></pdf-export-popup>
  </div>

</right-menu-container>
<!-- End of Export popup -->

<!-- Start of Labels popup -->
<right-menu-container
  *ngIf="isLabelsRightSubMenuVisible"
  (close)="isLabelsRightSubMenuVisible = false"
  [title]="'Labels'"
>
  <label-list [board]="selectedBoard$">
  </label-list>
</right-menu-container>
<!-- End of Labels popup -->

<!-- Start of Released tasks popup -->
<right-menu-container
  *ngIf="isReleasedRightSubMenuVisible"
  (close)="isReleasedRightSubMenuVisible = false"
  [title]="'Released Tasks'"
>
  <released-tasks-list [boardId]="(selectedBoard$ | async)?.id"
  ></released-tasks-list>
</right-menu-container>
<!-- End of Released tasks popup-->

<!-- Start of Archieve Items popup-->
<right-menu-container
  *ngIf="isArchivedRightSubMenuVisible"
  (close)="isArchivedRightSubMenuVisible = false"
  [title]="'Archived Items'"
>
  <archived-items></archived-items>
</right-menu-container>
<!-- End of Archieved Items popup -->

<!-- Start of Sprint boundaries popup -->
<right-menu-container
  *ngIf="isSprintBoundariesSubMenuVisible"
  (close)="isSprintBoundariesSubMenuVisible = false"
  [title]="'Sprint Settings'"
>
  <sprint-boundaries [board]="(selectedBoard$ | async)"></sprint-boundaries>
</right-menu-container>
<!-- End of Sprint boundaries popup -->
<right-menu-container
  *ngIf="showAdvancedScoringSettings$ | async"
  [confirmLeave]="confirmationToHideAdvancedScoringSettings"
  (close)="hideAdvancedScoringSettings()"
  [title]="'Prioritization Settings'"
  [ngClass]="{'no_bottom_padding': true}"
>
  <right-menu-advanced-scoring-settings [board]="selectedBoard$ | async" (confirmCloseFn)="updateConfirmationFn($event)"></right-menu-advanced-scoring-settings>
</right-menu-container>

<ng-template #loadActivites>
  <span class="show_activities" (click)="isShowActivities = true">LOAD BOARD ACTIVITY</span>
</ng-template>
