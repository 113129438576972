import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Version } from '../../../../interfaces/version';

@Component({
  selector: 'version-tag',
  styleUrls: ['./version-tag.component.scss'],
  template: `
        <p>{{version?.name}}</p>
        <div (click)="onRemove()" class="remove_version_control"></div>
`
})
export class VersionTagComponent implements OnInit {
  @Input() version: Version;
  @Output() remove: EventEmitter<number> = new EventEmitter<number>();

  ngOnInit(): any {}

  onRemove() {
    this.remove.emit(this.version.id);
  }
}
