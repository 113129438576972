import { Action } from '../../actions/unsafe-action';
import { TasksSavedListActionType } from '../../actions/task-filters/tasks-saved-list.actions';
import { getEntitySelector } from '../../functions/selectors';
import { TASKS_SAVED_LIST } from '../../state';

export interface TasksSavedList {
  tasksIds: number[];
}

const initState: TasksSavedList = {
  tasksIds: []
};

export function reducer(state = initState, action: Action): TasksSavedList {
  switch (action.type) {
    case TasksSavedListActionType.SET_TASKS_IDS: {
      const stateData = state.tasksIds.slice();
      const payload = Array.isArray(action.payload) ? [...action.payload] : [action.payload];

      payload.forEach(id => {
        if (stateData.indexOf(id) === -1) {
          stateData.push(id);
        }
      });

      return Object.assign({}, state, { tasksIds: stateData });
    }

    case TasksSavedListActionType.RESET: {
      return initState;
    }

    default: {
      return state;
    }
  }
}

export const getTasksSavedListEntity = getEntitySelector(TASKS_SAVED_LIST);
