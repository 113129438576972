<ng-container [ngSwitch]="currentMode">
  <span *ngSwitchCase="modes.Count" [tooltip]="count_tooltip">
      {{countedTasks ? countedTasks.count : 'No tasks in this swimlane'}}
  </span>
  <span *ngSwitchCase="modes.Hours" [tooltip]="count_tooltip">
    {{countedTasks ? countedTasks.hours : 'No estimated tasks in this swimlane'}}
  </span>
  <span *ngSwitchCase="modes.Points" [tooltip]="count_tooltip">
    {{countedTasks ? countedTasks.points : 'No estimated tasks in this swimlane'}}
  </span>
  <span *ngSwitchDefault [tooltip]="count_tooltip">
      {{countedTasks ? countedTasks.count : 'No tasks in this swimlane'}}
  </span>
</ng-container>
<ng-template #count_tooltip>
  <div class="tooltip" [ngSwitch]="currentMode">
    <p *ngSwitchCase="modes.Count">Number of tasks. Click to view<br>the sum of estimated hours.</p>
    <p *ngSwitchCase="modes.Hours">Sum of estimated hours. Click<br>to view the sum of story points.</p>
    <p *ngSwitchCase="modes.Points">Sum of estimated story points.<br>Click to view the number of tasks.</p>
    <p *ngSwitchDefault>Number of tasks. Click to view<br>the sum of estimated hours.</p>
  </div>
</ng-template>
