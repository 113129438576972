import { Action } from '../unsafe-action';
import { ReportBoardsFilter } from '../../reducers/report-filters/report-boards-filter.reducer';

export enum ReportBoardFilterActionTypes {
  APPLY = '[REPORT_BOARD_FILTER] APPLY',
  RESET = '[REPORT_BOARD_FILTER] RESET'
}

export class ReportBoardFilterApplyAction implements Action {
  readonly type = ReportBoardFilterActionTypes.APPLY;

  constructor(public payload: ReportBoardsFilter) {}
}

export class ReportBoardFilterResetAction implements Action {
  readonly type = ReportBoardFilterActionTypes.RESET;
}

export type Actions = ReportBoardFilterApplyAction | ReportBoardFilterResetAction;
