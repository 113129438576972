<div class="access_toggle" *ngIf="boardProjects && boardProjects.length && (canManageAccess$ | async)">
  <span class="edit_link" (click)="onChangeBoardAccess()">Make {{(isPublicBoard$ | async) ? 'private' : 'public'}} to project</span>
  <icon-info [position]="'top-left'" a-tooltip="<div style='text-align:left'>Public board is accessible to all members of all projects linked to that board.<br>Private board is accessible only to board's members.</div>"></icon-info>
</div>
<div class="wrapper" (click)="onOpenMemberPopup()">
  <h4>Members</h4>
  <div class="form_additional_block" (click)="onPreventClick($event)">
    <div class="members_avatars_container">
      <user-labels
        [users]="boardUsers"
        (onOpenPopupEvent)="onOpenMemberPopup()"
        (removeUser)="onRemoveUserFromBoard($event)"
        [isBoard]="true"
      ></user-labels>
    </div>
  </div>
</div>
<right-menu-users-popup
  *ngIf="isMembersPopupVisible"
  (close)="onCloseMembersPopup()"
  (changeUsersList)="onUsersChanged($event)"
  [isShowHelpText]="true"
  [boardProjects]="boardProjects"
  [boardUsers]="boardUsers"
  [users]="users"
  [boardId]="boardId"
></right-menu-users-popup>
