<span
  class="add_task_link"
  [attr.data-col-swim-coord]="column.id + '_' + swimlaneId"
  [hidden]="isFormContainerVisible"
  (click)="onEditToggle()"
>
  {{initialValue}}
</span>
<div *ngIf="isFormContainerVisible"
     [clickOut]="isFormContainerVisible && selectedMention === -1" (clickOutEvent)="onClickOut()"
  class="add_task_container">

  <form [formGroup]="inlineForm">
    <div class="position-relative">
      <div a-popup
           class="mention_popup"
           *ngIf="selectedMention > -1"
           [title]="'Members'"
           [setFocus]="false"
           (open)="onOpenMentionsPopup()"
           (close)="onCloseMentionsPopup()"
      >
        <member-list
          [stopChildEventPropagation]="mouseWheelEventName"
          [focusedUserNumber]="selectedMention"
          [members]="filteredMentions"
          [withNameFilter]="true"
          (onSelectMemberEvent)="onAddMention($event)"
        >
        </member-list>
      </div>
    </div>
    <textarea
      autosize
      [checkSize$]="checkSize$"
      type="text"
      [formControl]="inlineForm.get('title')"
      #taskTitleInput
      cols="3" rows="1"
      (keydown.esc)="onCancelNewTask()"
      [mentionsPicker]="mentions$"
      [fireSelectEvent]="fireSelectEvent$"
      (selectedMention)="onSelectedMention($event)"
      (filteredMentions)="onFilteredMentions($event)"
      (patchValue)="onPatchValue($event)"

      [hotKeys]="postHotKeys"
      (hotKey)="onSaveChanges(selectedMention)"
    ></textarea>
    <div
      a-button
      tabindex="0"
      (keydown)="onAddButtonKeyDown($event, selectedMention)"
      class="btn_primary save"
      [disabled]="!inlineForm.valid"
      (click)="onSaveChanges(selectedMention)"
    >
      Add
    </div>
    <div
      a-button
      tabindex="0"
      (keydown)="onCancelButtonKeyDown($event)"
      (click)="onCancelNewTask()"
      class="btn_text cancel"
    >
      Cancel
    </div>
  </form>

</div>
