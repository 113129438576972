import { of as observableOf, from as observableFrom, Observable, BehaviorSubject } from 'rxjs';

import { tap, map, switchMap, catchError } from 'rxjs/operators';
import { HttpQueryParam } from '../../interfaces';
import { Actions, Effect } from '@ngrx/effects';
import { BOARD_PL, INITIAL_DATA, TASK } from '../../constants';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { toDashedFromCamelCase } from '../../../helpers';
import { Injectable } from '@angular/core';
import { HandleResponseAction } from '../actions/root.action';
import { AuthService } from '../../shared/services/app/auth.service';
import {
  InitialDataActionTypes,
  InitialDataGetAction,
  InitialDataGetCompleteAction
} from '../actions/initial-data.actions';
import { PermissionGetCompleteAction } from '../../permissions/ngrx/permission.action';
import { PermissionModel } from '../../permissions/interfaces/permission.model';
import * as PATH from '../../path.routing';
import { Router } from '@angular/router';
import { SetPlans } from '@atlaz/billing/store/plan/plan.actions';

const splitDataFn = response => {
  const permissionsState = <PermissionModel>{
    id: 0,
    type: 'userPermissionsForCompany',
    companyRole: 'employee',
    boards: [],
    projects: []
  };

  const responseWithoutPermissions = { data: response.data, included: [] };

  response.included.forEach(entity => {
    switch (entity.type) {
      case 'boardsPermission': {
        permissionsState.boards.push({
          id: entity.id,
          type: entity.type,
          ...entity.attributes
        });
        break;
      }
      case 'projectsPermission': {
        permissionsState.projects.push({
          id: entity.id,
          type: entity.type,
          ...entity.attributes
        });
        break;
      }
      case 'userPermissionsForCompany': {
        permissionsState.id = entity.id;
        permissionsState.type = entity.type;
        permissionsState.companyRole = entity.attributes.companyRole;
        break;
      }
      default: {
        responseWithoutPermissions.included.push(entity);
      }
    }
  });
  return { data: responseWithoutPermissions, permissions: permissionsState };
};

@Injectable()
export class InitialDataEffects {
  public initialized$ = new BehaviorSubject<boolean>(false);

  @Effect() private init$ = this._authService.authIsReady$.pipe(map(() => new InitialDataGetAction()));

  @Effect()
  private loadCompanyFeatures$ = this.actions$.ofType(InitialDataActionTypes.GET).pipe(
    switchMap(({ type, payload }: { type: string; payload?: HttpQueryParam }) => {
      return this._atlazApiV2
        .get([toDashedFromCamelCase(INITIAL_DATA), { expand: TASK + ',' + BOARD_PL, ...(payload || {}) }])
        .pipe(
          switchMap(resp => {
            const splitData = splitDataFn(resp);
            return observableFrom([
              new SetPlans(resp.meta.plans),
              new PermissionGetCompleteAction(splitData.permissions),
              new HandleResponseAction(splitData.data),
              new InitialDataGetCompleteAction()
            ]);
          }),
          catchError(error => {
            console.warn('InitialData loading');
            console.error(error);
            this._router.navigate([PATH.LOAD_ERROR_PAGE], { skipLocationChange: true });
            return observableOf({ type: 'InitialData loading error', payload: error });
          })
        );
    })
  );

  @Effect()
  private initHandled$ = this.actions$
    .ofType(InitialDataActionTypes.GET_COMPLETE)
    .pipe(tap(_ => this.initialized$.next(true)), switchMap(_ => observableFrom([])));

  constructor(
    private actions$: Actions,
    private _authService: AuthService,
    private _atlazApiV2: AtlazApiV2Service,
    private _router: Router
  ) {}
}
