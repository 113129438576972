import { FormControl, Validators } from '@angular/forms';
import { RegExpValidator } from '../custom-validators/regexp-validator';
export const project = {
  name: Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
  shortName: Validators.compose([
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(5),
    (value: FormControl) => RegExpValidator.regExpIsValid('^[a-zA-Zа-яА-Я0-9-_ ]+$', value)
  ])
};
