import { Injectable } from '@angular/core';
import { AuthService } from '../../../../shared/services/app/auth.service';
import { CompanyService } from '../../../../shared/services/app/company.service';
import { platform } from '../../../../../helpers/platform';

interface SegmentInstance {
  track: Function;
  identify: Function;
  page: Function;
  group: Function;
}

@Injectable()
export abstract class SegmentBaseService {
  constructor(protected _authService: AuthService, protected _companyService: CompanyService) {}

  abstract getInstance(): SegmentInstance;

  group(id, traits) {
    this.getInstance().group(id, traits);
  }

  identify(id, traits) {
    this.getInstance().identify(id, traits);
  }

  track(eventName, data = {}) {
    this.getInstance().track(eventName, { ...data, ...this.getUserInfo() });
  }

  getUserInfo() {
    return {
      userId: this._authService.activeUserId,
      email: this._authService.activeUser ? this._authService.activeUser.email : '',
      companyId: this._companyService.id,
      companyName: this._companyService.name,
      companySize: this._companyService.companySize,
      softwarePreferences: this._companyService.softwarePreferences,
      client: 'Web App',
      os: platform.os.toString(),
      browser: platform.name + ' ' + platform.version
    };
  }

  fireUrlHasBeenChanged() {
    this.getInstance().page();
  }

  boardCreated(type: string, source: string, templateName?: string, templateId?: number) {
    if (templateId) {
      this.track('BoardCreated', { type, source, templateName, templateId });
    } else {
      this.track('BoardCreated', { type, source });
    }
  }

  onboardingProjectCreated() {
    this.track('OnboardingProjectCreated');
  }

  onboardingTemplatesChosen(templateNames?: string, templateIds?: string) {
    this.track('OnboardingTemplatesChosen', { templateNames, templateIds });
  }

  onboardingMembersInvited() {
    this.track('OnboardingMembersInvited');
  }

  projectCreated(source: string) {
    this.track('ProjectCreated', { source });
  }

  collectionCreated() {
    this.track('CollectionCreated');
  }

  commentCreated(isReply) {
    this.track('CommentCreated', {
      level: isReply ? 'second' : 'first'
    });
  }

  boardFilterUsed(filterType) {
    this.track('BoardFilterUsed', { filterType });
  }

  startTrelloImport(boardsCount, tasksCount) {
    this.track('TrelloImportStarted', {
      boardsCount,
      tasksCount
    });
  }

  startJiraImport(projectsCount, tasksCount) {
    this.track('JiraImportStarted', {
      projectsCount,
      tasksCount
    });
  }
}
