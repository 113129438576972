<div [ngClass]="{title_block: true, editable: editPermissions}" *ngIf="!isEdit">
  <div (click)="onEditToggle()" class="text">{{value}}</div>
</div>
<div class="checklist_title_icon"></div>
<div class="edit_animate_container" [class.show_edit_animate_container]="isEdit">
  <div *ngIf="isEdit"
       [clickOut]="isEdit"
       (clickOutEvent)="onEditToggle()"
       [attr.draggable-disabled]="true"
  >
    <form [formGroup]="inlineForm" (ngSubmit)="onSaveChanges()">
      <textarea
        autosize
        type="text"
        [selectText]="true"
        [hotKeys]="hotKeys"
        (hotKey)="onHotKey()"
        [formControl]="inlineForm.get('name')"
        #inlineInput
        cols="3" rows="1"
      ></textarea>
    </form>
  </div>
</div>
