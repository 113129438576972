<div a-context-popup [title]="'Edit ' + (isGroup ? 'Group' : 'Swimlane')" (close)="onClose()">
  <swimlane-edit-from [submit$]="submit$" [swimlane]="swimlane"></swimlane-edit-from>
  <div class="buttons-block">
    <button a-button [pending]="_formService.isPending$ | async" (click)="submit$.next()" class="btn_primary">Save
    </button>
    <button a-button class="btn_text" (click)="onClose()">Cancel</button>
    <button a-button class="btn_text" *ngIf="!isDeleteUnavailable" (click)="onToggleDeleteSwimlaneConfirm()">Delete</button>
  </div>
</div>

<div a-confirmation-popup
     *ngIf="isDeleteSwimlaneConfirmVisible"
     [confirmationTitle]="'Delete ' + (isGroup ? 'Group' : 'Swimlane')"
     actionButtonText="Delete"
     (confirm)="onDeleteSwimlane()"
     (close)="onToggleDeleteSwimlaneConfirm()"
>
  {{isGroup ? 'Group' : 'Swimlane'}} <b>{{swimlane.name}}</b> will be deleted. All tasks will be moved to other {{isGroup ? 'group' : 'swimlane'}}.
</div>

