<div class="member_tag">
  <div class="member-container">
    <div class="member_tag"
         *ngFor="let memberId of selectedUsers$ | async"
         (click)="onRemoveMember(memberId)">
      <a-avatar
        [user]="usersEntities[memberId]"
      ></a-avatar>
    </div>
    <div class="add_btn" (click)="showAddPopUp = true"></div>
  </div>
</div>

<div a-context-popup *ngIf="showAddPopUp" (close)="showAddPopUp = false" [title]="'Members'">
  <user-list-independent [selectedUsers]="selectedUsers$"></user-list-independent>
</div>
