import { AppState, EntityState, ESInterface } from '../state';
import { SCORING_CRITERIA_PL } from '../../constants';
import { ScoringCriteria } from '../../interfaces/ScoringCriteria';
import { createSelector } from 'reselect';

export namespace fromScoringCriteria {
  export const getState = (store: AppState) => {
    return <ESInterface<ScoringCriteria>>store[SCORING_CRITERIA_PL];
  };
  export const getById = id => (store: AppState) => getState(store).entities[id];
  export const getByBoard = id =>
    createSelector(
      getState,
      (state: ESInterface<ScoringCriteria>) =>
        <ScoringCriteria[]>EntityState.fromState<ScoringCriteria>(state)
          .filter(cr => cr.board === id)
          .sort((a, b) => a.position - b.position)
          .toArray()
    );
}
