
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from '../../shared/services/app/auth.service';

@Injectable()
export class UsersCacheService {
  private localStore = window.localStorage;

  constructor(private _authService: AuthService) {}

  async set(key, value) {
    try {
      this.localStore.setItem(this.userCacheKey(key), JSON.stringify(value));
    } catch (e) {
      console.log('UsersCacheService: Failed to set value by key ', this.userCacheKey(key), e);
    }
  }

  get(key) {
    try {
      const data = this.localStore.getItem(this.userCacheKey(key));
      return observableOf(JSON.parse(data));
    } catch (e) {
      console.log('UsersCacheService: Failed to get cache by key ' + this.userCacheKey(key), e);
      return observableOf(undefined);
    }
  }

  async clear(key) {
    try {
      this.localStore.removeItem(this.userCacheKey(key));
    } catch (e) {
      console.log('UsersCacheService: Failed to clear value by key ', this.userCacheKey(key), e);
    }
  }

  userCacheKey(key) {
    return this._authService.activeUserId + ':' + key;
  }
}
