<form [formGroup]="commentForm">
    <label>
      <div a-context-popup
           *ngIf="selectedMention > -1"
           [title]="'Members'"
           [setFocus]="false"
           (close)="onCloseMention()"
           class="context_mention">
        <member-list
          [focusedUserNumber]="selectedMention"
          [members]="filteredMentions"
          [withNameFilter]="true"
          (onSelectMemberEvent)="onAddMention($event)"
        >
        </member-list>
      </div>
      <textarea
        autosize
        [checkSize$]="checkSize$"
        [hotKeys]="postHotKeys"
        (hotKey)="onSubmit()"
        [formControl]="commentForm.get('text')"
        [focus]="isEditForm || !!taskReply"
        (keydown.esc)="onEscEvent($event)"

        [mentionsPicker]="mentions$"
        [fireSelectEvent]="fireSelectEvent$"
        (selectedMention)="onSelectedMention($event)"
        (filteredMentions)="onFilteredMentions($event)"
        (patchValue)="onPatchValue($event)"
        #comment
        [selectionRange]="{start: commentForm.get('text').value?.length, end: commentForm.get('text').value?.length}"
        cols="3"
        rows="1"
        placeholder="Write a Сomment..."
        tabindex="1"
      ></textarea>
        <!--
        COMMENTED OUT DUE TO INSUFFICIENT FUNCTIONALITY
        <div class="ico_mention" *ngIf="comment.value.trim().length > 0 || taskReply || isEditForm"></div>
        -->
      <div class="attachment-popup-wrapper">
        <add-attachments
          class="task-activity"
          *ngIf="isAddAttachmentPopUpVisible"
          (close)="onSwitchAttachmentsPopUp()"
          [taskId]="taskId"
          [commentKey]="getCommentKey()"
        ></add-attachments>
      </div>
      <div class="attachment" (click)="onSwitchAttachmentsPopUp()"></div>
    </label>

    <div a-button class="btn_primary"
         *ngIf="comment.value.trim().length > 0 || taskReply || isEditForm"
         (click)="onSubmit()"
         (keyup.enter)="onSubmit()"
         (keydown.space)="onSubmitSpace($event)"
         tabindex="2"
    >{{isEditForm ? 'Save Comment' : 'Add Сomment'}}</div>
    <div a-button *ngIf="comment.value.trim().length > 0 || taskReply || isEditForm" class="btn_text"
         (click)="onCancel($event)">Cancel
    </div>
</form>
