import { ErrorHandler, NgModule } from '@angular/core';
import { RollbarErrorHandler } from './services/rollbar-error-handler';
import { BugTrackerService } from './services/bag-tracker.service';
import * as Rollbar from 'rollbar';
import { NxModule } from '@nrwl/nx';
import { environment } from '../../environments/environment';
import { ROLLBAR_TOKEN, RollbarDymmy } from './services/rollbar';
import { IntegrationsModule } from '../integrations/core/integrations.module';
import { UsersCacheService } from '@atlaz/core/services/users-cache.service';
import { UnsavedFormChangesService } from '@atlaz/core/services/unsaved-form-changes.service';

export function RollbarFactory() {
  return environment.rollbarConfig ? new Rollbar(<Rollbar.Configuration>environment.rollbarConfig) : new RollbarDymmy();
}

@NgModule({
  imports: [IntegrationsModule.forRoot(), NxModule.forRoot()],
  providers: [
    {
      provide: ROLLBAR_TOKEN,
      useFactory: RollbarFactory
    },
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    BugTrackerService,
    UsersCacheService,
    UnsavedFormChangesService
  ]
})
export class CoreModule {}
