
import {refCount, combineLatest, publishReplay, map} from 'rxjs/operators';
import { Component, OnInit, Input, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { Column } from '../../interfaces/column';
import { AppState } from '../../ngrx/state/app-state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getTaskIdsByColumn } from '../../ngrx/reducers/task.reducer';

@Component({
  selector: 'wip-limits',
  templateUrl: './wip-limits.component.html',
  styleUrls: ['./wip-limits.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WipLimitsComponent implements OnChanges {
  @Input() column: Column;
  @Input() withDetails = true;

  public count$: Observable<number>;
  public overLimit$: Observable<boolean>;
  public lessLimit$: Observable<boolean>;
  public inLimit$: Observable<boolean>;

  constructor(private _store: Store<AppState>) {}

  ngOnChanges(): any {
    this.count$ = this._store.pipe(
      (getTaskIdsByColumn(this.column.id)),
      map((ids: number[]) => ids.length),
      publishReplay(1),
      refCount(),);

    this.overLimit$ = this.count$.pipe(map(
      (count: number) => this.column.maxTasksCount && count > this.column.maxTasksCount
    ));

    this.lessLimit$ = this.count$.pipe(map(
      (count: number) => this.column.minTasksCount && this.column.minTasksCount > count
    ));

    this.inLimit$ = this.lessLimit$.pipe(combineLatest(this.overLimit$, (a, b) => !a && !b));
  }
}
