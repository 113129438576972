import { map } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { Notification } from '../../../../../../interfaces/notification';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../ngrx/state/app-state';
import { getAllBoards } from '../../../../../../ngrx/reducers/board.reducer';
import { Board } from '../../../../../../interfaces/board';
import { getAllProjects } from '../../../../../../ngrx/reducers/project.reducer';
import { Project } from '../../../../../../interfaces/project';
import { naturalSort } from '../../../../../../../helpers/array';
import { propOr } from 'ramda';

@Component({
  selector: 'import-notification-preview',
  templateUrl: './import-notification-preview.component.html',
  styleUrls: ['./import-notification-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportNotificationPreviewComponent implements OnChanges {
  @Input() notification: Notification;

  public projects$;
  public boards$;
  public boardsByProjects$;

  get boardIds(): number[] {
    return this.notification.relatedData['boardsIds'] || [];
  }

  get projectIds(): number[] {
    return this.notification.relatedData['projectsIds'] || [];
  }

  constructor(private _store: Store<AppState>) {}

  ngOnChanges() {
    this.projects$ = this._store.pipe(
      getAllProjects,
      map((all: Project[]) => all.filter(project => this.projectIds.includes(project.id))),
      map(naturalSort('name'))
    );

    this.boards$ = this._store.pipe(
      getAllBoards,
      map((all: Board[]) => all.filter(board => this.boardIds.includes(board.id))),
      map(naturalSort('name'))
    );

    this.boardsByProjects$ = this.boards$.pipe(
      map((all: Board[]) =>
        all.reduce((acc, board) => {
          let added = false;

          const projectsIds = propOr<Array<number>, Object, Array<number>>([], 'projectsIds', board);
          projectsIds.forEach(projectId => {
            if (this.projectIds.includes(projectId)) {
              acc[projectId] = [...(acc[projectId] || []), board.id];
              added = true;
            }
          });

          if (!added) {
            acc[0] = [...(acc[0] || []), board.id];
          }

          return acc;
        }, {})
      )
    );
  }
}
