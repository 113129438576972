<form [formGroup]="addTaskForm">
  <label for="columns">Column</label>
  <select formControlName="column" id="columns" tabindex="2">
    <option *ngFor="let column of columns" [value]="column.id">{{column.name}}</option>
  </select>
  <br><br>

  <div *ngIf="swimlanes && swimlanes.length > 1">
    <label for="swimlanes">Swimlane</label>
    <select formControlName="swimlane" id="swimlanes" tabindex="2">
      <option *ngFor="let swimlane of swimlanes" [value]="swimlane.id">{{swimlane.name}}</option>
    </select>
    <br><br>
  </div>

  <label for="title">Title</label>
  <textarea autosize formControlName="title" id="title" [focus]="true" checkTouch></textarea>
  <help-block [control]="addTaskForm.get('title')" [messages]="{'maxlength': 'Maximum length of 1000 symbols is exceed', 'PatternValidator': 'Field is required!'}"></help-block>

  <button a-button class="btn_primary" (click)="onSubmit()"
          [pending]="(_formService.isPending$ | async)"
  >Add Task</button>
</form>
