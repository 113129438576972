import { Pipe, PipeTransform } from '@angular/core';
import { plainTextToHtml } from '../../../helpers/html-helper';

@Pipe({
  name: 'textToHtml'
})
export class TextToHtmlPipe implements PipeTransform {
  transform(value, trimLimit: number = 0) {
    return plainTextToHtml(value, trimLimit);
  }
}
