import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noBrackets'
})
export class NoBracketsPipe implements PipeTransform {
  transform(value): string {
    const result = value.replace(/\[|]/g, '');

    return result;
  }
}
