import { FormControl, ValidatorFn } from '@angular/forms';

export function validateEmptyFactory(): ValidatorFn {
  return (c: FormControl) => {
    return !c.value && (typeof c.value === 'string' && c.value.length === 0)
      ? null
      : {
          emptyValidator: {
            valid: false
          }
        };
  };
}
