import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'smart-logged-time',
  template: '<b>{{time}}</b>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartLoggedTimeComponent implements OnInit {
  @Input() seconds: number;

  public time: string;

  ngOnInit() {
    this.time = this.humanizeLoggedTime();
  }

  toString(hours: number, minutes: number, seconds: number): string {
    const hoursLabel = hours !== 0 ? hours + 'h' : '';
    const minutesLabel = minutes !== 0 ? minutes + 'm' : '';
    const secondsLabel = seconds !== 0 ? seconds + 's' : '';

    return `${hoursLabel} ${minutesLabel} ${secondsLabel}`;
  }

  humanizeLoggedTime(): string {
    const directionSign = this.seconds > 0 ? '' : '-';

    const hours = Math.floor(Math.abs(this.seconds) / 3600);
    const minutes = Math.ceil(Math.abs(this.seconds) / 60) % 60;
    this.seconds = Math.ceil(Math.abs(this.seconds) % 60);

    const result = this.toString(hours, minutes, this.seconds);

    return directionSign + result;
  }
}
