
import {fromEvent as observableFromEvent,  Subscription ,  Observable } from 'rxjs';

import {debounceTime, pluck, distinctUntilChanged, map} from 'rxjs/operators';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state/app-state';
import { SearchStateInterface, ASC, DESC } from '../../../ngrx/reducers/global-search.reducer';
import { RouterNavigateService } from '../../../shared/services/router-navigate.service';

@Component({
  selector: 'sort-order',
  templateUrl: './sort-order.component.html',
  styleUrls: ['./sort-order.component.scss']
})
export class SortOrderComponent implements OnInit, OnDestroy {
  @ViewChild('selectRef') selectRef: ElementRef;
  @ViewChild('orderButtonRef') orderButtonRef: ElementRef;

  sortOrder$;
  sortParam$;

  subscriptions = [];
  searchState;

  constructor(private _store: Store<AppState>, private _routerNav: RouterNavigateService) {}

  ngOnInit(): any {
    this.subscriptions.push(
      this._store.pipe(
        pluck('search'),
        distinctUntilChanged(),)
        .subscribe((searchState: SearchStateInterface) => (this.searchState = searchState))
    );

    this.sortOrder$ = this._store.pipe(
      pluck('search'),
      distinctUntilChanged(),
      map((searchState: SearchStateInterface) => searchState.sortParams.sortOrder),);
    this.sortParam$ = this._store.pipe(
      pluck('search'),
      distinctUntilChanged(),
      map((searchState: SearchStateInterface) => searchState.sortParams.sortParam),
      map(v => (v ? v : '')),);

    this.subscriptions.push(
      observableFromEvent(this.orderButtonRef.nativeElement, 'click').subscribe(value => {
        this._routerNav.navigateToSearch(this.searchState.searchQuery, this.searchState.filterParams, {
          sortParam: this.searchState.sortParams.sortParam,
          sortOrder: this.searchState.sortParams.sortOrder === DESC ? ASC : DESC
        });
      })
    );

    this.subscriptions.push(
      observableFromEvent(this.selectRef.nativeElement, 'change').pipe(
        map((event: Event) => event.target['value']),
        debounceTime(400),)
        .subscribe(value => {
          this._routerNav.navigateToSearch(this.searchState.searchQuery, this.searchState.filterParams, {
            sortParam: value,
            sortOrder: value === '' ? ASC : DESC
          });
        })
    );
  }

  ngOnDestroy(): any {
    this.subscriptions.forEach((subscribtion: Subscription) => subscribtion.unsubscribe());
  }
}
