import { combineLatest as observableCombineLatest, Observable, BehaviorSubject, Subscription, Subject } from 'rxjs';

import { filter, switchMap, map, distinctUntilChanged, scan, startWith } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/state';

import { BoardActivity } from '../../interfaces';

import { getSelectedBoardId } from '../../ngrx/reducers/board.reducer';
import { getBoardActivityByBoard } from '../../ngrx/reducers/board-activity.reducer';
import { BoardActivityActionTypes, BoardActivityGetAction } from '../../ngrx/actions/board-activity.actions';
import { isEquals } from '../../../helpers';
import { BOARD } from '../../constants';
import { Actions } from '@ngrx/effects';

interface BoardActivityRequest {
  board: number;
  limit: number;
  offset: number;
}

const OFFSET = 'offset';

@Component({
  selector: 'board-activity',
  templateUrl: './board-activity.component.html',
  styleUrls: ['./board-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoardActivityComponent implements OnInit, OnDestroy {
  public defaultLimit = 15;

  public requiredParams = [BOARD];

  public initParams = {
    [BOARD]: null,
    limit: this.defaultLimit,
    offset: 0,
    sort: '-createdAt'
  };

  public boardId$: Observable<number>;
  public offset$: BehaviorSubject<number> = new BehaviorSubject(0);

  public loadAction$: Subject<any> = new Subject();
  public requestParams$: Observable<BoardActivityRequest>;
  public boardActivities$: Observable<BoardActivity[]>;

  public subs: Subscription[] = [];
  public endOffset = false;

  public scroll = false;
  public showLoader = false;

  constructor(private _store: Store<AppState>, private actions$: Actions) {}

  ngOnInit() {
    this.scroll = !!window.localStorage.getItem('scroll');
    this.boardId$ = this._store.pipe(getSelectedBoardId);
    this.boardActivities$ = <Observable<BoardActivity[]>>this.boardId$.pipe(
      switchMap(boardId => this._store.pipe(getBoardActivityByBoard(boardId))),
      map((result: BoardActivity[]) =>
        result.sort(
          (a, b) => (a.createdAt === b.createdAt ? (a.id > b.id ? -1 : 1) : a.createdAt > b.createdAt ? -1 : 1)
        )
      )
    );

    this.subs.push(
      observableCombineLatest(
        this.boardActivities$.pipe(map(activities => activities.length), distinctUntilChanged()),
        this.offset$,
        (itemsCount, offset) => itemsCount < offset + this.defaultLimit
      ).subscribe(offset => (this.endOffset = offset))
    );

    this.requestParams$ = this.loadAction$.pipe(
      startWith(this.initParams),
      scan((acc, requestParam) => Object.assign({}, acc, requestParam)),
      distinctUntilChanged(isEquals)
    );

    // we should subscribe before any observeRequestParams
    this.runGetAction();

    this.observeRequestParams(this.boardId$, BOARD);
    this.observeRequestParams(this.offset$, OFFSET);

    this.subs.push(
      this.actions$.ofType(BoardActivityActionTypes.GET_COMPLETE).subscribe(() => {
        this.showLoader = false;
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  onLoadMore() {
    console.log('load');
    this.endOffset = true;
    this.offset$.next(this.offset$.getValue() + this.defaultLimit);
  }

  public observeRequestParams(source$: Observable<any>, param: string) {
    this.subs.push(source$.subscribe(data => this.loadAction$.next({ [param]: data })));
  }

  public runGetAction() {
    const sub = this.requestParams$
      .pipe(filter(requestParams => this.requiredParams.every(key => !!requestParams[key])))
      .subscribe(httpParams => {
        this.showLoader = true;
        this._store.dispatch(new BoardActivityGetAction(httpParams));
      });

    this.subs.push(sub);
  }
}
