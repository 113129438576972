import { Component } from '@angular/core';

@Component({
  selector: 'header-info-icon-svg',
  template: `
	  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20px" height="20px">
		  <path fill="#fff" d="M10 0c5.5 0 10 4.5 10 10s-4.5 10-10 10S0 15.5 0 10 4.5 0 10 0zm0 18c4.4 0 8-3.6 8-8s-3.6-8-8-8-8 3.6-8 8 3.6 8 8 8zm-1-3V9h2v6H9zm0-8V5h2v2H9z"/>
	  </svg>
  `
})
export class HeaderInfoSvgComponent {}
