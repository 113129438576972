import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: '[app-body-inner]',
  template: `
    <ng-content></ng-content>
  `,
  styleUrls: ['app-body-inner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppBodyInnerComponent {}
