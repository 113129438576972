<div [ngClass]="{sidebar_block: true, editable: editPermissions}" #wrapper (click) = "onWrapperClick($event)">
  <h5 *ngIf="!hideHeader" [ngClass]="headerClassName || 'members_title'">Members</h5>
  <user-labels
    class="user_labels"
    [users]="selectedUsers"
    [editPermissions]="editPermissions"
    [isSmallItems]="isSmallItems"
    (onOpenPopupEvent)="onOpenMemberPopup()"
    (removeUser)="onRemoveUserFromTask($event)"
  ></user-labels>
  <div class="members_link_overlay" (click)="onOpenMemberPopup()"></div>
  <members-popup
    *ngIf="isMembersPopupVisible"
    (addUserToBoard)="onAddUserToBoard($event)"
    (close)="onCloseMembersPopup()"
    (update) = "onUpdateUsersList($event)"
    [possibleUsers]="possibleUsers"
    [selectedUsers]="selectedUsers"
    [projectOnlyUsers]="projectOnlyUsers"
    [isPublicBoard]="isPublicBoard"
  ></members-popup>
</div>
<div class="divider"></div>
