import { pluck } from 'rxjs/operators';
import { Component } from '@angular/core';
import { PageStatus, STATUS_CODES } from '../../interfaces/page-status.interface';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'page-with-permissions-wrapper',
  templateUrl: './page-with-permissions-wrapper.component.html'
})
export class PageWithPermissionsWrapperComponent {
  public STATUS_CODES = STATUS_CODES;
  public pageStatus$: Observable<PageStatus>;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.pageStatus$ = this.route.data.pipe(pluck('pageStatus'));
  }
}
