import { Entity, HttpQueryParam } from '../../../../interfaces';
import { Action } from '../../../../ngrx/actions/unsafe-action';
import { ESInterface } from '../../../../ngrx/state';

export const GET_GET = '[intercom-company.actions] GET_GET';
export const GET_INDEX = '[intercom-company.actions] GET_INDEX';
export const SET_ACTIVE = '[intercom-company.actions] SET_ACTIVE';
export const UPDATED = '[intercom-company.actions] UPDATED';
export const GET_INDEX_COMPLETE = '[intercom-company.actions] GET_INDEX_COMPLETE';
export const GET_INDEX_FAIL = '[intercom-company.actions] GET_INDEX_FAIL';
export const REMOVE_ALL_COMPLETE = '[intercom-company.actions] REMOVE_ALL_COMPLETE';
export const REMOVE_ALL = '[intercom-company.actions] REMOVE_ALL';
export const CLEAR_SETTINGS = '[intercom-company.actions] CLEAR_ALL';

export class Get implements Action {
  readonly type = GET_GET;

  constructor(public payload: { id: number; queryParams?: HttpQueryParam }) {}
}

export class GetIndex implements Action {
  readonly type = GET_INDEX;

  constructor(public payload?: HttpQueryParam) {}
}

export class GetIndexComplete implements Action {
  readonly type = GET_INDEX_COMPLETE;

  constructor(public payload: ESIntercomCompany) {}
}

export class GetIndexFail implements Action {
  readonly type = GET_INDEX_FAIL;
}

export class SetActive implements Action {
  readonly type = SET_ACTIVE;

  constructor(public payload: { number: number }) {}
}

export class Updated implements Action {
  readonly type = UPDATED;

  constructor(public payload: ESIntercomCompany) {}
}

export class RemoveAllComplete implements Action {
  readonly type = REMOVE_ALL_COMPLETE;
  constructor(payload?: any) {}
}

export class RemoveAll implements Action {
  readonly type = REMOVE_ALL;
  constructor() {}
}

export class ClearSettings implements Action {
  readonly type = CLEAR_SETTINGS;
}

export interface IntercomCompany extends Entity {
  userName: string;
  company: number;
  creator: number;
  board: number;
  column: number;
  swimlane: number;
  updatedAt: number;
  createdAt: number;
}

export type ESIntercomCompany = ESInterface<IntercomCompany>;

// prettier-ignore
export type Actions
  = Get
  | GetIndex
  | GetIndexFail
  | GetIndexComplete
  | SetActive
  | Updated
  | RemoveAllComplete
  | RemoveAll
  | ClearSettings
  ;
