
import {catchError, switchMap, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { BOARD_PL, IDS, LABEL_PL } from '../../constants';
import { Label, PartOfEntity } from '../../interfaces';
import { LabelsActionTypes, LabelsAddCompleteAction } from './../actions/label.actions';
import { GetCompleteAction } from '../actions/root.action';
import { defaultErrorHandler } from './root.effect';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { jsonApiToEntityState, jsonApiToSingleEntity } from '../../shared/services/app/web-socket/http-response';

export let labelsColorWeight = {};

const labelsResponseHandler = (response, boardId) => {
  if (response.order && response.order.data) {
    labelsColorWeight = {};
    response.order.data.forEach((value, index) => {
      labelsColorWeight[value] = index;
    });
  }
  const entities = jsonApiToEntityState(response);

  // inject to board labels ids
  // it's a dirty hack but i couldn't do it another way
  entities[BOARD_PL] = {
    ids: [boardId],
    entities: {}
  };
  entities[BOARD_PL]['entities'][boardId] = {
    [LABEL_PL + IDS]: entities[LABEL_PL] ? entities[LABEL_PL].ids : []
  };
  return entities;
};

@Injectable()
export class LabelEffects {
  @Effect()
  loadCollection$ = this.actions$
    .ofType(LabelsActionTypes.LOAD).pipe(
    switchMap(({ type, payload: boardId }: { type: string; payload: number }) => {
      const httpParams = {
        board: boardId,
        expand: 'order',
        sort: ['color', 'name'].join(',')
      };
      return this._atlazApi
        .get(LABEL_PL, httpParams).pipe(
        map(dataList => {
          const entities = labelsResponseHandler(dataList, boardId);
          return new GetCompleteAction(entities);
        }),
        catchError(defaultErrorHandler(type, boardId)),);
    }));

  @Effect()
  create$ = this.actions$
    .ofType(LabelsActionTypes.ADD).pipe(
    switchMap(({ type, payload }: { type: string; payload: PartOfEntity }) => {
      return this._atlazApi
        .post(LABEL_PL, payload).pipe(
        map((response: any) => {
          return new LabelsAddCompleteAction(jsonApiToSingleEntity(response));
        }),
        catchError(defaultErrorHandler(type, payload)),);
    }));

  @Effect({ dispatch: false })
  update$ = this.actions$
    .ofType(LabelsActionTypes.UPDATE).pipe(
    switchMap(({ type, payload }: { type: string; payload: PartOfEntity }) => {
      return this._atlazApi.patch(LABEL_PL, payload).pipe(catchError(defaultErrorHandler(type, payload)));
    }));

  @Effect({ dispatch: false })
  delete$ = this.actions$
    .ofType(LabelsActionTypes.DELETE).pipe(
    switchMap(({ type, payload }: { type: string; payload: Label }) => {
      return this._atlazApi.deleteRequest([LABEL_PL, payload.id]).pipe(catchError(defaultErrorHandler(type, payload)));
    }));

  constructor(private actions$: Actions, protected _atlazApi: AtlazApiV2Service) {}
}
