<div class="fixed-fist-column">
  <div class="header">
    <div class="title" (click)="onTitleClick()">
      <span>Tasks</span>
      <search-button
        [placeholder]="''"
        [openFromParent$]="openSearch$"
        (changeSearch)="onSearchStringChanged($event)"
        (openSearch)="onOpenSearch()"
      ></search-button>
    </div>
    <div class="title_cell">
      % Complete
    </div>
  </div>
  <div class="body">
    <div class="scrollable-body-inner" multiplyScrollSynchro #scrollableBodyInner>
      <ng-container *ngFor="let item of items$ | async;trackBy : trackById;">
        <ng-container [ngSwitch]="item.type">
          <named-swimlane *ngSwitchCase="'group'" [item]="item" [hiddenItems]="hiddenItems$ | async" [isNotGuest]="isNotGuest"></named-swimlane>
          <task-roadmap-preview class="item-container full_width_preview"
                                *ngSwitchDefault
                                [isNotGuest]="isNotGuest"
                                [item]="item"
                                [task]="item.task"
                                [hiddenItems]="hiddenItems$ | async"
                                (editTaskPercent)="setEditingTaskPercentId($event)"
                                (openAddForm)="onAddNew($event.type, $event.insertAfter)"
                                [isEditPercent]="item.task.id === editingTaskPercentId"
          >
          </task-roadmap-preview>
        </ng-container>
      </ng-container>

      <div class="add-task-form-container"
           [class.show_hover]="(isHoverFakeDayItems$ | async)"
           *ngIf="(addFormVisible$ | async)"
           (mouseenter)="onMouseEnterFooterContent()"
           (mouseleave)="onMouseLeaveFooterContent()"
           [clickOut]="true"
           (clickOutEvent)="onAddFormClickOut($event)"
      >
        <add-task-form [params]="addFormParams$ | async"></add-task-form>
      </div>
      <div class="push-block" [style.height.px]="scrollbarWidth"></div>
    </div>
  </div>
</div>
<div class="timesheet-container" #timesheetContainer>
  <div class="header">
    <div class="scrollable-header-inner" multiplyScrollSynchro>
      <div>
        <div class="content" [style.width.px]="minWidth + scrollbarWidth">
          <div *ngFor="let cell of cellsHeaders$ | async; trackBy : trackById;"
               class="cell-group-header"
               [style.left.px]="cell.leftPx"
               [style.width.px]="cell.widthPx"
          >
            {{cell.label}}
          </div>
          <div *ngFor="let cell of cells$ | async; trackBy : trackById;"
               class="cell-header"
               [cellHighlight]="cell.id"
               [style.left.px]="cell.leftPx"
               [style.width.px]="cell.widthPx"
               [class.today]="cell.isToday"
               [class.separator]="cell.isSeparator"
          >
            {{cell.label}}
          </div>
        </div>
        <div class="push-block"></div>
      </div>
    </div>
  </div>
  <div class="body" #scrollablecontent>
    <div class="content" [style.width.px]="minWidth"
         [mouseScroll]="true"
         [verticallScrollContainer]="scrollablecontent"
         [horizontalScrollContainer]="scrollablecontent">
      <div class="timesheet-overlay"
           #timesheetOverlay
           [class.visible]="overlayVisible$ | async"
           [class.resize]="showResizeOverlay$ | async"
           [class.move]="showMoveOverlay$ | async"
      ></div>
      <div class="background-grid">
        <div *ngFor="let cell of cells$ | async; trackBy : trackById;"
             class="cell"
             [cellHighlight]="cell.id"
             [style.left.px]="cell.leftPx"
             [style.width.px]="cell.widthPx"
             [class.today]="cell.isToday"
             [class.separator]="cell.isSeparator"
          >
        </div>
      </div>
      <day-items class="root_items_container"
                 [items]="items$ | async"
                 [itemPositions$]="itemPositions$"
                 [hiddenItems]="hiddenItems$ | async"
                 [isNotGuest]="isNotGuest"
                 [hideEmptyRows]="forPrint"
      ></day-items>
      <div *ngIf="(addFormVisible$ | async)"
           [class.fake_day_items]="true"
           [class.hover_fake_day_items]="(isHoverFakeDayItems$ | async)"
           (mouseenter)="onMouseEnterFooterContent()"
           (mouseleave)="onMouseLeaveFooterContent()"
      ></div>
    </div>
  </div>
</div>
<div class="footer">
  <add-button *ngIf="isNotGuest" [addClass]="'dont_close_add_form'" (click)="onOpenPushForm(RoadMapAddFormTypes.group)">Group</add-button>
  <add-button *ngIf="isNotGuest" [addClass]="'dont_close_add_form'" (click)="onOpenPushForm(RoadMapAddFormTypes.task)">Task</add-button>
  <add-button *ngIf="isNotGuest" [addClass]="'dont_close_add_form'" (click)="onOpenPushForm(RoadMapAddFormTypes.taskLink)">Link</add-button>
  <add-button *ngIf="isNotGuest" [addClass]="'dont_close_add_form'" (click)="onOpenPushForm(RoadMapAddFormTypes.project)">Project</add-button>
</div>


<a-center-popup
  [className]="'big_popup'"
  *ngIf="createNew$ | async"
  [title]="'Insert'"
  (close)="onPopupFormClose()"
>
  <div class="popup_form_wrapper">
    <add-task-form [params]="createNew$ | async"></add-task-form>
  </div>
</a-center-popup>
