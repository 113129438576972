import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'icon-warn',
  templateUrl: './icon-warn.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconWarnComponent implements OnInit {
  @Input() width = '40px';
  @Input() height = '34px';
  @Input() color = '#D0021B';

  constructor() {}

  ngOnInit() {}
}
