import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx/src/nx.module';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { fromCamelToDash } from '../../../../../helpers/';
import { JIRA_COMPANY_PL } from '../../../../constants/';
import { GetCompleteAction } from '../../../../ngrx/actions/root.action';
import { AppState } from '../../../../ngrx/state';
import { jsonApiToEntityState } from '../../../../shared/services/app/web-socket/http-response';
import { AtlazApiV2Service } from '../../../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { IntegrationNames } from '../../../core/integraition-list';
import * as integrations from '../../../core/store/integration.action';
import { removeByIdsFn } from '../../../settings/slack-alert/util/uninstall';
import * as jiraCompany from './jira-company.actions';
import { ESJiraCompany } from './jira-company.actions';
import * as fromJiraCompany from './jira-company.reducer';

@Injectable()
export class JiraCompanyEffects {
  private key = fromCamelToDash(JIRA_COMPANY_PL);

  defaultErrorHandler(action, error) {
    console.error('effect', action, error);
  }

  @Effect()
  getMany$ = this.data.fetch(jiraCompany.GET_INDEX, {
    run: (action, store) =>
      this.api.get([this.key], action.payload).pipe(
        map(jsonApiToEntityState),
        switchMap((state: ESJiraCompany) => {
          let id;
          const actions: any[] = [
            new GetCompleteAction(state),
            new jiraCompany.GetIndexComplete(state[JIRA_COMPANY_PL])
          ];
          if ((id = this.getFirstId(state[JIRA_COMPANY_PL]))) {
            actions.push(new integrations.IntegrationStatusUpdated({ name: IntegrationNames.Jira, isInstalled: true }));
            actions.push(new jiraCompany.SetActive(id));
          }

          return from(actions);
        })
      ),
    onError: this.defaultErrorHandler
  });

  @Effect()
  removeAll$ = this.data.pessimisticUpdate(jiraCompany.REMOVE_ALL, {
    run: (action, store) => {
      const ids = fromJiraCompany.getIds(store);
      return this.remover(ids).pipe(
        switchMap(_ =>
          from([
            new jiraCompany.RemoveAllComplete(),
            new integrations.IntegrationStatusUpdated({ name: IntegrationNames.Jira, isInstalled: false })
          ])
        )
      );
    },
    onError: (action, error) => {
      this.defaultErrorHandler(action, error);
    }
  });

  constructor(private data: DataPersistence<AppState>, private api: AtlazApiV2Service) {}

  private remover = removeByIdsFn(this.api, this.key);

  private getFirstId(state: ESJiraCompany) {
    return state && state.ids[0];
  }
}
