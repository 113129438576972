
import {combineLatest, map, tap, pluck, distinctUntilChanged, switchMap} from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { consoleLog } from '../../../../../helpers';
import { SprintBoard, Version } from '../../../../interfaces';
import { AppState } from '../../../../ngrx/state/app-state';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { STATUS_CODES } from '../../../../permissions/interfaces/page-status.interface';
import { Store } from '@ngrx/store';
import { getVersionById } from '../../../../ngrx/reducers/version.reducer';

@Component({
  selector: 'release-report-page',
  templateUrl: './release-report-page.component.html',
  styleUrls: ['./release-report-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReleaseReportPageComponent implements OnInit {
  public isOkStatus$: Observable<boolean>;
  public board$: Observable<SprintBoard>;
  public version$: Observable<Version>;

  constructor(private _activatedRoute: ActivatedRoute, private _store: Store<AppState>) {}

  ngOnInit() {
    this.version$ = this._activatedRoute.params.pipe(
      pluck('id'),
      distinctUntilChanged(),
      switchMap(id => <Observable<Version>>this._store.pipe((getVersionById(id)))),);
    this.isOkStatus$ = this._activatedRoute.data.pipe(
      tap(consoleLog('pageStatus')),
      pluck('pageStatus', 'statusCode'),
      map(status => STATUS_CODES.OK === status),
      combineLatest(this.version$, (isOk, version) => !!(isOk && version && version.board && version.released)),);
  }
}
