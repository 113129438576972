import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { SearchComponent } from './search-component/search.component';
import { SearchBoxComponent } from './search-box/search-box.component';
import { SearchResultComponent } from './search-result/search-result.component';
import { SearchResultPreviewComponent } from './search-result/search-result-preview/search-result-preview.component';
import { SearchFilterComponent } from './search-filter/search-filter.component';
import { VersionFilterComponent } from './search-filter/version-filter/version-filter.component';
import { VersionTagComponent } from './search-filter/version-filter/version-tag/version-tag.component';
import { SortOrderComponent } from './search-result/sort-order/sort-order.component';
import { AtlazGuiModule } from '../atlaz-gui/atlaz-gui.module';
import { MarkdownModule } from 'ngx-markdown-hyggerio';

// routing

// components

@NgModule({
  imports: [CommonModule, SharedModule, AtlazGuiModule, MarkdownModule],
  declarations: [
    SearchResultPreviewComponent,
    SearchResultComponent,
    SearchComponent,
    SearchFilterComponent,
    VersionFilterComponent,
    VersionTagComponent,
    SortOrderComponent,
    SearchBoxComponent
  ],
  exports: [SearchBoxComponent, SearchComponent]
})
export class GlobalSearchModule {}
