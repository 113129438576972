<div [ngClass]="{'default_members_filter': true, 'single_filter': true }"
     [@defaultMembersFilterAnimation]="state$ | async"
>
 <div (click)="onShowPopup()">
    <div class="avatar_container"
         [@defaultMembersAvatarAnimation]="state$ | async">
      <a-avatar *ngIf="(localUserFilter$ | async)"
                [user]="localUserFilter$ | async"
      ></a-avatar>
    </div>
    <span>{{visibleUserName$ | async}}</span>
    <div class="clear_block" [@defaultMembersClearAnimation]="state$ | async" (click)="onClearFilter($event)"></div>
  </div>
</div>

<div a-context-popup *ngIf="showAddPopUp"
     [title]="'Filter by Member'"
     class="default_members_list_popup"
     (close)="onHidePopup($event)"
>
  <default-filter-member-list
    [members]="members$ | async"
    [selectedMemberId]="(localUserFilter$ | async)?.id"
    (selectMember)="onSelectMember($event)"
  ></default-filter-member-list>
</div>



