<div #wrapper (click)="onWrapperClick($event)" class="wrapper" [ngClass]="{'clickable': editPermissions, small: isSmallItems}">
  <div *ngIf="editPermissions" (click)="onOpenPopup()" class="add_btn"></div>
  <div *ngFor="let user of users; let i = index" class="user_labels">
    <div (mouseenter)="onOpenUserPreview(i)" (mouseleave)="onCloseAllUserPreviews()" class="avatar_container">
      <a-avatar
        [user]="user"
        [openPreviewOnClick]="false"
        [class.small]="isSmallItems"
      ></a-avatar>
      <a-context-container *ngIf="userPreviewIndex == i">
        <user-preview
          [user] = "user"
          [isBoard]="isBoard"
          [canRemove]="editPermissions"
          [clickOut]="userPreviewIndex === i"
          (clickOutEvent)="onCloseAllUserPreviews()"
          (closePopupEvent)="onCloseAllUserPreviews()"
          (removeFromTask)="onRemoveUserFromTask($event)"
        ></user-preview>
      </a-context-container>
    </div>
  </div>
</div>
