import { LABEL_PL } from './../../constants';
import { Action } from '../actions/unsafe-action';
import { AppState, ESInterface } from '../state';
import { Label } from '../../interfaces';
import { Selector, share } from '../functions/util';
import {
  getAllEntities,
  getAllEntitiesAsArray,
  getEntityByIdSelector,
  getEntityByIdsSelector,
  getEntitySelector
} from '../functions/selectors';
import { LabelsActionTypes } from '../actions/label.actions';
import { addEntityState, deleteEntityState, editEntityState } from '../functions/reducer';
import { labelsColorWeight } from '../effects/label.effect';
import { identity } from '../../../helpers';
import { compose } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { createSelector } from 'reselect';

const initialState: ESInterface<Label> = {
  ids: [],
  entities: {},
  selectedEntityId: null
};

export function reducer(state = initialState, action: Action): ESInterface<Label> {
  switch (action.type) {
    case LabelsActionTypes.ADD_COMPLETE: {
      return addEntityState(state, action.payload, false);
    }

    case LabelsActionTypes.DELETE: {
      return deleteEntityState(state, action.payload.id);
    }

    case LabelsActionTypes.UPDATE: {
      return editEntityState(state, action.payload);
    }

    default: {
      return state;
    }
  }
}

export const compareLabels = (a: Label, b: Label) => {
  if (a.color === b.color) {
    if (a.name === b.name) {
      return 0;
    }
    return a.name > b.name ? 1 : -1;
  }
  return labelsColorWeight[a.color] > labelsColorWeight[b.color] ? 1 : -1;
};

export const getLabelEntities = share(compose(getAllEntities, getEntitySelector(LABEL_PL)));
export const getAllLabels: Selector<AppState, Label[]> = <Selector<AppState, Label[]>>share(
  compose(getAllEntitiesAsArray, getEntitySelector(LABEL_PL))
);

export const getLabels = store$ => (filterFn: any = identity) => store$.pipe(getAllLabels, map(filterFn));

export const getLabelById = id => share(compose(getEntityByIdSelector(id), getEntitySelector(LABEL_PL)));
export const getLabelByIds = ids => share(compose(getEntityByIdsSelector(ids), getEntitySelector(LABEL_PL)));

export namespace fromLabels {
  export const getState = (store: AppState) => {
    return <ESInterface<Label>>store[LABEL_PL];
  };

  export const getById = id => createSelector(getState, state => state.entities[id]);

  export const getAsArray = createSelector(getState, state => Object.values(state.entities));

  export const getByBoardId = boardId =>
    createSelector(getState, state => Object.values(state.entities).filter(label => label && label.board === boardId));

  export const getMapByIds = ids =>
    createSelector(getState, state =>
      ids.reduce((acc, item) => {
        if (state.entities[item]) {
          acc[item] = state.entities[item];
        }
        return acc;
      }, {})
    );
}
