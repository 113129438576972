import { Observable, of as observableOf } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { Board, Project, Task } from '../../../interfaces';
import { getTaskById } from '../../../ngrx/reducers/task.reducer';
import { inputChanged, isPresent } from '../../../../helpers';
import { getProjectById } from '../../../ngrx/reducers/project.reducer';
import { getBoardById } from '../../../ngrx/reducers/board.reducer';
import { fromColumns } from '../../../ngrx/reducers/column.reducer';
import { TaskDetailPageRelatedDataService } from '../../task-detail-page/services/task-detail-page-related-data.service';
import { of } from 'rxjs/internal/observable/of';

@Component({
  selector: 'task-title-wrapper',
  template: `
    <task-title *ngIf="ready$ | async"
                [task]="task$ | async"
                [project]="project$ | async"
                [boardName]="boardName$ | async"
                [columnName]="columnName$ | async"
    ></task-title>`
})
export class TaskTitleWrapperComponent implements OnChanges {
  @Input() taskId: number;

  public task$: Observable<Task>;
  public project$: Observable<Project>;
  public board$: Observable<Board>;
  public boardName$: Observable<string>;
  public columnName$: Observable<string>;
  public ready$: Observable<boolean>;

  constructor(private _detailLoader: TaskDetailPageRelatedDataService, private _store: Store<AppState>) {}

  ngOnChanges(changes: SimpleChanges) {
    if (inputChanged('taskId')(changes)) {
      this.task$ = this._store.pipe(getTaskById(this.taskId));
      this.project$ = this.task$.pipe(
        switchMap(
          task =>
            task && task.numberInProject && task.project
              ? this._store.pipe(getProjectById(task.project))
              : observableOf(null)
        )
      );
      this.board$ = this.task$.pipe(switchMap(task => (task ? this._store.pipe(getBoardById(task.board)) : of(null))));

      this.task$.pipe(filter(isPresent), take(1)).subscribe((task: Task) => {
        const boardId = task.board;
        if (boardId) {
          this._detailLoader.loadBoard(boardId, task.id);
        }
      });

      this.boardName$ = this.board$.pipe(map(board => (board ? board.name : '')));
      this.columnName$ = this.task$.pipe(
        switchMap(task => (task ? this._store.select(fromColumns.getColumnFullNameById(task.column)) : of(null)))
      );

      this.ready$ = this.task$.pipe(
        switchMap(
          (task: Task) =>
            task && task.numberInProject && task.project
              ? this.project$.pipe(map(isPresent))
              : observableOf(task).pipe(map(isPresent))
        )
      );
    }
  }
}
