<div class="text_message" *ngIf="(currentType$ | async) !== progressTypes.daysLeft else daysLeft">
  <div class="title" [ngClass]="{'title_center': (currentType$ | async) === progressTypes.hoursCount}">
    {{message$ | async}}
  </div>
  <div class="counter">
    {{counter$ | async}}
  </div>
  <div class="overestimate">
    {{overEstimate$ | async}}
  </div>
</div>


<div class="next" (click)="onNextType()"></div>
<div class="prev" (click)="onPrevType()"></div>
<div *ngIf="(showProgressBar$ | async)" class="progress_bar" >
  <div class="progress" [ngStyle]="progressStyle$ | async">
  </div>
</div>



<ng-template #daysLeft>
  <div class="text_message">
    <sprint-end-countdown-timer [boardId]="boardId">
    </sprint-end-countdown-timer>
  </div>
</ng-template>
