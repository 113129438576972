import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable ,  Subject } from 'rxjs';
import { AppState, runParametricalSelector } from '../../../../../ngrx/state/';
import { IntegrationNames } from '../../../../core/integraition-list';
import * as fromIntegration from '../../../../core/store/integration.reducer';
import { BitbucketPullRequest } from '../../../models/git-pull-request';
import * as fromAssocBitbucketRepo from '../../../store/bitbucket-associated-repository/bitbucket-associated-repository.reducer';
import * as bitbucketPullRequest from '../../../store/bitbucket-pull-request/bitbucket-pull-request.actions';
import * as fromBitbucketPullrRequest from '../../../store/bitbucket-pull-request/bitbucket-pull-request.reducer';

@Component({
  selector: 'bitbucket-task-pull-request-list',
  templateUrl: './bitbucket-task-pull-request-list.component.html',
  styleUrls: ['./bitbucket-task-pull-request-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BitbucketTaskPullRequestListComponent implements OnInit, OnDestroy {
  @Input() taskId$: Observable<number>;

  public pullRequests$: Observable<BitbucketPullRequest[]>;
  public isInstalled$: Observable<boolean> = this._store.select(fromIntegration.getIsInstalledBitbucket);
  public asscocRepoList$ = this._store.select(fromAssocBitbucketRepo.getEntities);
  public name = IntegrationNames.Bitbucket;

  private destroy$ = new Subject();

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this.pullRequests$ = runParametricalSelector(
      this._store.select(fromBitbucketPullrRequest.getTaskPullRequests),
      this.taskId$
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  onRemove(item: BitbucketPullRequest) {
    this._store.dispatch(new bitbucketPullRequest.DeleteTaskPullRequest({ id: item.id, task: item.task }));
  }
}
