import { createSelector } from 'reselect';

import * as fromSlackCompany from './slack-company/slack-company.reducer';
import * as fromSlackChannel from './slack-channel/slack-channel.reducer';
import * as fromSlackBoard from './slack-board/slack-board.reducer';

import * as slackAlert from './slack-alert.action';
import * as slackBoard from './slack-board/slack-board.actions';
import * as slackCompany from './slack-company/slack-company.actions';

import {
  SLACK_BOARD,
  SLACK_CHANNEL,
  SLACK_COMPANY,
  SLACK_COMPANY_PL,
  SLACK_CHANNEL_PL,
  SLACK_BOARD_PL
} from '../../../../constants/entity';
import { SET_DEFAULT_TEAM } from './slack-alert.action';
import { AppState } from '../../../../ngrx/state/app-state';
import { getProperty } from '../../../../../helpers/index';
import { SlackBoard } from '../models/slack-boards';
import { SlackChannel } from '../models/slack-channel';
import { getDefaultAdditionalData } from './slack-board/default-additional-data';

export const SLACK_ALERT_FEATURE = 'slack_alert_feature';

export interface SlackAlertState {
  currentBoardId: number;
  currentSlackTeamId: number;
  loadingSlackBoards: boolean;
  loadingSlackCompanies: boolean;
}

const initialState: SlackAlertState = {
  currentBoardId: undefined,
  currentSlackTeamId: undefined,
  loadingSlackBoards: true,
  loadingSlackCompanies: true
};

export function reducer(
  state = initialState,
  action: slackAlert.Actions | slackBoard.Actions | slackCompany.Actions
): SlackAlertState {
  switch (action.type) {
    case slackAlert.SET_ACTIVE_BOARD: {
      return { ...state, currentBoardId: action.payload };
    }
    case slackAlert.SET_DEFAULT_TEAM: {
      return { ...state, currentSlackTeamId: action.payload };
    }

    case slackBoard.GET_INDEX: {
      return { ...state, loadingSlackBoards: true };
    }
    case slackBoard.GET_INDEX_COMPLETE: {
      return { ...state, loadingSlackBoards: false };
    }
    case slackCompany.GET_INDEX: {
      return { ...state, loadingSlackCompanies: true };
    }

    case slackCompany.GET_INDEX_COMPLETE: {
      return { ...state, loadingSlackCompanies: false };
    }

    case slackAlert.CLEAR: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const reducers = {
  [SLACK_ALERT_FEATURE]: reducer,
  [SLACK_COMPANY_PL]: fromSlackCompany.reducer,
  [SLACK_CHANNEL_PL]: fromSlackChannel.reducer,
  [SLACK_BOARD_PL]: fromSlackBoard.reducer
};

export const getState = (store: AppState) => <SlackAlertState>store[SLACK_ALERT_FEATURE];

export const getCurrentSlackTeamId = createSelector(getState, state => getProperty(state, 'currentSlackTeamId'));

export const getCurrentBoardId = createSelector(getState, state => getProperty(state, 'currentBoardId'));

export const getTeamChannels = createSelector(fromSlackChannel.getAll, getCurrentSlackTeamId, (channels, teamId) =>
  channels.filter(isBelongTeam(teamId))
);

export const getTeamFirstChannel = createSelector(getTeamChannels, channels => channels[0]);

export const getDefaultBoardAlert = createSelector(
  getCurrentSlackTeamId,
  getTeamFirstChannel,
  getCurrentBoardId,
  createDefaultSlackBoard
);

export const isBoardLoadingFirst = createSelector(
  getState,
  fromSlackBoard.isEmpty,
  (state, isEmpty) => state.loadingSlackBoards && isEmpty
);

export const isCompanyLoadingFirst = createSelector(
  getState,
  fromSlackCompany.isEmpty,
  (state, isEmpty) => state.loadingSlackCompanies && isEmpty
);

export const isLoading = createSelector(
  isBoardLoadingFirst,
  isCompanyLoadingFirst,
  (loadingBoard, loadingCompany) => loadingBoard || loadingCompany
);

function isBelongTeam(teamId: number) {
  return <T extends { slackCompany: number }>(source: T) => source.slackCompany === teamId;
}

function createDefaultSlackBoard(teamId, channel: SlackChannel, boardId): Partial<SlackBoard> {
  return {
    slackCompany: teamId,
    board: boardId,
    slackChannelId: getProperty(channel, 'id'),
    slackChannelName: getProperty(channel, 'name'),
    additionalData: getDefaultAdditionalData()
  };
}
