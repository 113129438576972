import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Notification, User } from '../../../../interfaces';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../ngrx/state';
import { MarkAsTypes, NotificationMarkAsAction } from '../../../../ngrx/actions/notification.actions';
import { Observable } from 'rxjs';
import { DateInterval } from '../../../../interfaces/date-interval';
import { DATE_INTERVAL, filterValueForDateInterval } from '../../../../../helpers/date-interval';
import { compose, sortByField } from '../../../../../helpers';
import { NOTIFICATION_TYPES, TASK } from '../../../../constants';

export function getNotificationIds(notificationsByDate: Notification[]): number[] {
  const notifIds = notificationsByDate.reduce((acc, curValue) => {
    if (curValue.subNotifications) {
      acc.push(...getNotificationIds(curValue.subNotifications));
    } else {
      acc.push(curValue.id);
    }
    return acc;
  }, []);

  return notifIds;
}

const sortNotifications = (notifications: Notification[]) =>
  notifications.sort((a, b) => (a.createdAt === b.createdAt ? b.id - a.id : b.createdAt - a.createdAt));

const getNotificationGroupKey = (notification: Notification) => {
  switch (notification.entityType) {
    case TASK:
      return notification.type === NOTIFICATION_TYPES.TYPE_MENTION ||
        notification.type === NOTIFICATION_TYPES.TYPE_TASK_ADD_USER ||
        notification.type === NOTIFICATION_TYPES.TYPE_TASK_MEMBERS_DELETE_ME
        ? notification.id
        : notification.entityType + notification.entityId;
    default:
      return notification.id;
  }
};

const groupNotifications = (notifications: Notification[]): Notification[] => {
  const grouped = notifications.reduce((acc, notification) => {
    const group = getNotificationGroupKey(notification);
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(notification);
    return acc;
  }, {});
  return Object.keys(grouped).map(key => {
    const groupNotification = { ...grouped[key][0] };
    if (grouped[key].length > 1) {
      groupNotification.subNotifications = grouped[key];
    }
    return groupNotification;
  });
};

@Component({
  selector: 'notifications-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsListComponent implements OnChanges {
  @Input() notificationsList: Notification[];
  @Input() isArchivedFilterActive: boolean;

  public allUsers$: Observable<User[]>;

  public notificationsByDate: DateInterval[];
  public dateIntervals: DateInterval[] = DATE_INTERVAL;

  public trackById = (index, item) => {
    return item.id + '-' + index;
  };

  constructor(private _store: Store<AppState>) {}

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.hasOwnProperty('notificationsList') && this.notificationsList !== null) {
      this.notificationsByDate = this.groupNotificationsByInterval(this.notificationsList);
    }
  }

  groupNotificationsByInterval(notifications: Notification[]): DateInterval[] {
    let dateIntervals = this.dateIntervals.slice();

    const filterByDateInterval = compose(sortByField('id', 'desc'), (dateInterval: any) =>
      notifications.filter(filterValueForDateInterval(dateInterval.dateStart, dateInterval.dateEnd))
    );
    dateIntervals.forEach(dateInterval => {
      dateInterval.notifications = compose(
        sortNotifications,
        groupNotifications,
        sortNotifications,
        filterByDateInterval
      )(dateInterval);
    });

    dateIntervals = dateIntervals.filter(dateInterval => dateInterval.notifications.length > 0);
    return dateIntervals;
  }

  onArchiveAllToDate(dateInterval) {
    const notifIds = getNotificationIds(dateInterval.notifications);

    this._store.dispatch(new NotificationMarkAsAction({ markType: MarkAsTypes.archive, notificationIds: notifIds }));
  }
}
