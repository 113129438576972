import { Component, Input } from '@angular/core';
import { Task } from '../../../interfaces';

@Component({
  selector: 'sidebar-harvest',
  templateUrl: './sidebar-harvest.component.html',
  styleUrls: ['./sidebar-harvest.component.scss']
})
export class SidebarHarvestComponent {
  @Input() task: Task;
  @Input() board: Task;
  public iFrameVisible = false;
  constructor() {}

  onTogglePopUp() {
    this.iFrameVisible = !this.iFrameVisible;
  }
}
