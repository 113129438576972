
import {take, merge, debounceTime, pluck} from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormComponent,
  FormSaveType,
  FormServiceParams,
  FormV2Service
} from '../../../../shared/services/form-v2.service';
import { Observer ,  Subscription } from 'rxjs';
import { JsonApiSingeModelResponse } from '../../../../shared/services/app/web-socket/http-response';
import { HandleResponseAction } from '../../../../ngrx/actions/root.action';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../ngrx/state';
import { CompanyService } from '../../../../shared/services/app/company.service';
import { COMPANY_PL } from '../../../../constants';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'working-days',
  templateUrl: './working-days.component.html',
  styleUrls: ['./working-days.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkingDaysComponent implements OnInit, OnDestroy, FormComponent {
  formObserver: Observer<any> = {
    next: (resp: JsonApiSingeModelResponse<any>) => {
      this._store.dispatch(new HandleResponseAction(resp));
    },

    error: originalError => {
      const error = this._formService.normalizeServerErrorResponse(originalError);
      this._toastr.error(error.message);
    },

    complete: () => {}
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.edit,
    entityToEdit: [COMPANY_PL],
    formObserver: this.formObserver,
    prepareFormValue: formValue => {
      return {
        id: this._companyService.id,
        workingDays: formValue
      };
    }
  };

  form: FormGroup;
  subs: Subscription[] = [];

  /**
   *
   export interface WorkingDays {
  mon: number;
  tue: number;
  wed: number;
  thu: number;
  fri: number;
  sat: number;
  sun: number;
}
   * @type {Array}
   */
  days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
  labels = {
    mon: 'Monday',
    tue: 'Tuesday',
    wed: 'Wednesday',
    thu: 'Thursday',
    fri: 'Friday',
    sat: 'Saturday',
    sun: 'Sunday'
  };

  /**
   * {[day:sting]: number}
   */
  get checked() {
    return this.form.value;
  }

  constructor(
    private _fb: FormBuilder,
    public _formService: FormV2Service,
    private _store: Store<AppState>,
    private _companyService: CompanyService,
    private _toastr: ToastrService
  ) {}

  ngOnInit() {
    const workingDays$ = this._companyService.currentCompany$.pipe(pluck('workingDays'));
    workingDays$.pipe(take(1)).subscribe(intitalValues => {
      const config = this.days.reduce((acc, day) => {
        acc[day] = intitalValues[day];
        return acc;
      }, {});
      this.form = this._fb.group(config);
    });

    // to prevent blinking gui
    this.subs.push(
      this.form.valueChanges.pipe(
        merge(workingDays$),
        debounceTime(1000),)
        .subscribe(value =>
          Object.keys(value)
            .filter(key => this.form.value[key] !== value[key])
            .forEach(key => this.form.get(key).setValue(value[key]))
        )
    );

    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  onClick(day) {
    this.form.get(day).patchValue(+!this.form.get(day).value);
    this.onSubmit();
  }

  onSubmit() {
    this.form.markAsTouched();
    if (this.form.invalid) {
      return;
    }
    this._formService.submit();
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
