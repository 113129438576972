
import {switchMap, map} from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../ngrx/state';
import { Observable } from 'rxjs';
import { Board, Project } from '../interfaces';
import { ActivatedRoute } from '@angular/router';
import { AppUrls } from '../app-urls';
import { BACKEND_DATE_FORMAT } from '../libs/date-time-formatter/constants/date-time-formats';
import { EstimationType } from '../constants';
import { getBoardById } from '../ngrx/reducers/board.reducer';
import { SegmentService } from '../atlaz-bnp/services/intergations/segment/segment.service';

export const DATE_KEY = BACKEND_DATE_FORMAT;

@Component({
  templateUrl: './burndown-chart.component.html',
  styleUrls: ['./burndown-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BurndownChartPageComponent implements OnInit {
  public boardId$: Observable<number>;
  public board$: Observable<Board>;
  public hidePicker$: Observable<boolean>;
  public projects$: Observable<Project>;
  public mode = EstimationType.storyPoints;
  public appUrls = AppUrls;
  public isShowQuestionPopup = false;

  constructor(private _store: Store<AppState>, private _route: ActivatedRoute, private _segmet: SegmentService) {}

  ngOnInit() {
    this._segmet.track('BurndownChartVisited');
    this.boardId$ = <Observable<number>>this._route.params.pipe(map(params => +params['boardId']));
    this.board$ = this.boardId$.pipe(switchMap(id => this._store.pipe((getBoardById(id)))));
    this.hidePicker$ = this.board$.pipe(map(board => board.estimatedBy !== EstimationType.any));
  }

  onToggleQuestionPopup() {
    this.isShowQuestionPopup = !this.isShowQuestionPopup;
  }
}
