
import {interval as observableInterval, of as observableOf,  Observable } from 'rxjs';

import {distinctUntilChanged, map, switchMap, filter, withLatestFrom, startWith} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { dataSyncState } from '../../ngrx/reducers/data-sync.reducer';
import { fromOpenedTask } from '../../task/ngrx/reducers/opened-task.reducer';
import { BoardOpenAction } from '../../ngrx/actions/board.actions';
import { getSelectedBoardId } from '../../ngrx/reducers/board.reducer';
import { LoadedDataReset } from '../../loaded-data/store/loaded-data.actions';
import { TaskDetailPageResolver } from '../../task/task-detail-page/services/task-detail-page.resolver';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/state';

@Injectable()
export class ReloadOnConnectionChangedService {
  constructor(private _store: Store<AppState>, private _taskDetailPageResolver: TaskDetailPageResolver) {}

  public init() {
    this._store.pipe(
      (dataSyncState),
      map(dataSync => dataSync.isOnline && dataSync.isSocketConnected),
      distinctUntilChanged(),
      switchMap(isOnline => (isOnline ? observableOf(0) : observableInterval(300000).pipe(startWith(-1)))),
      filter(v => v > -1), // skip if connection has just been interrupted
      withLatestFrom(
        this._store.pipe((getSelectedBoardId)),
        this._store.select(fromOpenedTask.getState).pipe(map(openedTask => openedTask['id'])),
        (_, boardId, taskId) => ({ _, boardId, taskId })
      ),)
      .subscribe(
        ids => {
          this.reloadData(ids);
        },
        error => {
          console.error('Error in ReloadOnConnectionChangedService (dataSyncState stream)', error);
        }
      );
  }

  public reloadData({ boardId, taskId }) {
    if (boardId || taskId) {
      this._store.dispatch(new LoadedDataReset());
    }
    if (boardId) {
      this._store.dispatch(new BoardOpenAction(boardId));
    }
    if (taskId) {
      this._taskDetailPageResolver.loadTaskRequest(taskId).subscribe(_ => {});
    }
  }
}
