
import {combineLatest as observableCombineLatest,  Observable } from 'rxjs';

import {take, filter} from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { OpenedTaskDataChangedAction } from '../../ngrx/actions/opened-task.action';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { LinkTaskInputComponent } from '../../../shared/components/link-task-input/link-task-input.component';
import { AppUrls } from '../../../app-urls';
import { Router } from '@angular/router';
import { getTaskById, getTaskBySlug } from '../../../ngrx/reducers/task.reducer';
import { isPresent } from '../../../../helpers';

@Component({
  selector: 'checklist-task-item-form',
  templateUrl: './checklist-task-item-form.component.html',
  styleUrls: ['./checklist-task-item-form.component.scss']
})
export class ChecklistTaskItemFormComponent implements OnInit {
  @Input() taskId: number;
  @Input() checklistId: number;
  @Output() submitForm: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @ViewChild('taskInput') taskInput: LinkTaskInputComponent;

  public checkboxForm: FormGroup;

  constructor(private _fb: FormBuilder, private _store: Store<AppState>, private _router: Router) {}

  ngOnInit() {
    this.checkboxForm = this._fb.group({
      name: ['', Validators.required]
    });
  }

  onSubmit() {
    if (this.checkboxForm.valid) {
      observableCombineLatest(
        this._store.pipe((getTaskById(this.checkboxForm.value.name))),
        this._store.pipe((getTaskBySlug(this.checkboxForm.value.name))),
        (taskById, taskBySlug) => (taskBySlug ? taskBySlug : taskById)
      ).pipe(
        filter(isPresent),
        take(1),)
        .subscribe(task => {
          const directUrl = AppUrls.getDirectTaskUrl(this._router, task.id, task.board);
          this.taskInput.clearField();
          this.checkboxForm.get('name').patchValue('');
          this.submitForm.emit({ name: directUrl });
          this.cancel.emit();
        });
    }
  }

  onCancel() {
    this.cancel.emit();
    this._store.dispatch(new OpenedTaskDataChangedAction({ newCreatedCheckList: null }));
  }
}
