import { OVERVIEW_ACTIVITY_PL } from '../../constants';

import { ESInterface } from '../state';
import { OverviewActivity } from '../../interfaces/overview-activity';
import { AppState } from '../state/app-state';

import * as overviewActivity from './overview-activity.actions';
import { HttpQueryParam } from '../../interfaces/http-query';

import * as R from 'ramda';
import { OVERVIEW_FILTER } from '../../constants/entity';
import { toNumber } from '../../../helpers/string';
import { OverviewFilter, OverviewFilterFields, OverviewFilterTimeInterval } from '../../interfaces/overview-filters';
import { getDateInterval } from '../../../helpers/date-interval';
import { overviewFilterFns, acceptedTypes } from './overview-activity-filter';
import { sortBy } from '../../../helpers/array';
import { isPresent } from '../../../helpers/object';
import { createSelector } from 'reselect';

export const defaultFilterValues = {
  timeFrom: 0,
  timeTo: 0,
  timeInterval: <OverviewFilterTimeInterval>'all_time',
  boardsIds: [],
  groupsIds: [],
  projectsIds: [],
  usersIds: []
};

export const initFilterState: OverviewFilter = {
  ...defaultFilterValues,
  activity: 'all',
  createdAt: 0,
  companyId: 0,
  userId: 0,
  updatedAt: 0,
  object: OVERVIEW_FILTER,
  id: undefined
};

export const initQueryParams: HttpQueryParam = {
  expand: 'users.id,columnFrom,columnTo,columnFromParent,columnToParent',
  limit: 7,
  offset: 0,
  sort: '-createdAt'
};

export interface OverviewState extends ESInterface<OverviewActivity> {
  filteredIds: number[];
  queryParams: HttpQueryParam;
  filter: OverviewFilter;
  loadMore: boolean;
  loading: boolean;
}

export const initialState: OverviewState = {
  ids: [],
  entities: {},
  selectedEntityId: undefined,
  filteredIds: [],
  queryParams: initQueryParams,
  filter: initFilterState,
  loadMore: false,
  loading: true
};

export enum EmptyStateMessageType {
  noActivities = 1,
  noCompletedTasks = 2,
  noItemsByFilter = 3
}

const FILTER_KEYS = [
  'activity',
  'timeFrom',
  'timeTo',
  'boardsIds',
  'projectsIds',
  'groupsIds',
  'usersIds',
  'timeInterval'
];

export function reducer(state = initialState, action: overviewActivity.Actions): OverviewState {
  switch (action.type) {
    case overviewActivity.GET: {
      return { ...state, loading: true };
    }

    case overviewActivity.CLEAR: {
      return { ...state, ids: [], filteredIds: [], entities: {}, queryParams: initQueryParams };
    }

    case overviewActivity.EDIT_FILTERS: {
      const newFilters = { ...state.filter, ...action.payload };

      return { ...state, filter: newFilters };
    }
    case overviewActivity.UPDATED_FILTERS: {
      return { ...state, filter: action.payload };
    }
    case overviewActivity.GET_COMPLETED: {
      const loadMore = action.payload.itemsCount === state.queryParams.limit;
      return { ...state, loadMore, loading: false };
    }
    case overviewActivity.LOAD_MORE: {
      const newOffset = +state.queryParams.offset + +state.queryParams.limit;
      const newQueryParams = { ...state.queryParams, offset: newOffset };
      return { ...state, queryParams: newQueryParams };
    }

    case overviewActivity.UPDATED_STATE: {
      const activeFilters = getActiveFilters(state.filter);
      const filteredIds = applyFilter(activeFilters, state.filteredIds, action.payload)
        .map(id => state.entities[id])
        .filter(isPresent)
        .sort(sortBy('createdAt', 'desc'))
        .map(activity => activity.id);
      return { ...state, filteredIds };
    }

    default: {
      return state;
    }
  }
}

export const getAllActivities = (store: AppState) => {
  const overviewState = <OverviewState>store[OVERVIEW_ACTIVITY_PL];
  return overviewState.filteredIds.map(id => overviewState.entities[id]).filter(isPresent);
};

export const getActivityTypeFilter = (store: AppState) => {
  const overviewState = <OverviewState>store[OVERVIEW_ACTIVITY_PL];
  return overviewState.filter.activity;
};

export const getActivityQueryParams = (store: AppState) => {
  const overviewState = <OverviewState>store[OVERVIEW_ACTIVITY_PL];
  const queryParams = { ...overviewState.queryParams, ...overviewState.filter };

  const excludedFields: Array<keyof OverviewFilter> = [
    'id',
    'object',
    'createdAt',
    'updatedAt',
    'groupsIds',
    'companyId',
    'userId'
  ];

  /**
   * using in query params for http request
   * original field will be remove below
   * should return object: {[property: string]: value}
   */
  const transformFields = {
    activity: value => ({
      activityType: value === 'completed_tasks' ? 'dones' : 'all'
    }),
    timeInterval: (value: OverviewFilterTimeInterval) => {
      if (value && value !== 'pick_a_date' && value !== 'all_time') {
        const interval = getDateInterval(value);
        return { createdAtStart: interval[0], createdAtEnd: interval[1] };
      } else {
        return {};
      }
    },
    timeFrom: value => (value ? { createdAtStart: value } : {}),
    timeTo: value => (value ? { createdAtEnd: value } : {})
  };

  Object.keys(initFilterState).forEach((key: keyof OverviewFilter) => {
    const isEmpty = R.isEmpty(queryParams[key]);
    const isExcludedField = excludedFields.includes(key);

    const isTransformingValue = transformFields.hasOwnProperty(key);

    if (isTransformingValue) {
      const valueToPrepare = queryParams[key];
      Object.assign(queryParams, transformFields[key](valueToPrepare));
    }

    if (isEmpty || isExcludedField || isTransformingValue) {
      delete queryParams[key];
    }
  });
  return queryParams;
};

export const getOverviewState = (store: AppState) => <OverviewState>store[OVERVIEW_ACTIVITY_PL];
export const getOverviewIds = createSelector(getOverviewState, state => state.ids);

export const getLoadMore = createSelector(getOverviewState, state => state.loadMore);

export const isLoading = createSelector(getOverviewState, state => state.loading);

export const isReloading = createSelector(
  isLoading,
  getOverviewIds,
  (loading, ids) => ids.length === 0 && loading === true
);

export const getProjectFilter = createSelector(getOverviewState, state => state.filter.projectsIds.map(toNumber));

export const getBoardsFilter = createSelector(getOverviewState, state => state.filter.boardsIds.map(toNumber));

export const getDateFromFilter = createSelector(getOverviewState, state => toNumber(state.filter.timeFrom));

export const getDateToFilter = createSelector(getOverviewState, state => toNumber(state.filter.timeTo));

export const getDateIntervalFilter = createSelector(getOverviewState, state => state.filter.timeInterval);

export const getGroupFilter = createSelector(getOverviewState, state => state.filter.groupsIds[0]);

export const getUsersFilter = createSelector(
  getOverviewState,
  state => (state.filter.groupsIds.length === 0 ? state.filter.usersIds : [])
);

export const isAppliedLeastOneFilter = createSelector(getOverviewState, state => {
  const filters = state.filter;

  // we don't check filter by activity, because for it we have
  return FILTER_KEYS.filter(filterName => filterName !== 'activity').some((key: keyof OverviewFilterFields) =>
    isEnabledFilter(key, filters)
  );
});

export const getEmptyStateMessageType = createSelector(
  getOverviewState,
  isAppliedLeastOneFilter,
  (state, appliedFilter) => {
    if (state.ids.length !== 0) {
      return null;
    }
    if (appliedFilter) {
      return EmptyStateMessageType.noItemsByFilter;
    }
    if (state.filter.activity === 'completed_tasks') {
      return EmptyStateMessageType.noCompletedTasks;
    }
    return EmptyStateMessageType.noActivities;
  }
);

export function getActiveFilters(filters: OverviewFilterFields): { filterName: string; filterValue: any }[] {
  return (
    FILTER_KEYS
      // we don't filter by group, we filter by users in group, when group filter enabled, usersFilter enabled too
      .filter(key => key !== 'groupsIds')
      .filter((key: keyof OverviewFilterFields) => isEnabledFilter(key, filters))
      .map(filterName => ({ filterName: filterName, filterValue: filters[filterName] }))
  );
}

export function isEnabledFilter(filterName: keyof OverviewFilterFields, filters: OverviewFilterFields) {
  return !isDefaultFilter(filterName, filters);
}

export function isDefaultFilter(filterName: keyof OverviewFilterFields, filters: OverviewFilterFields) {
  switch (filterName) {
    case 'activity': {
      return filters.activity === 'all';
    }
    case 'timeTo': {
      return filters.timeTo === 0;
    }
    case 'timeFrom': {
      return filters.timeFrom === 0;
    }
    case 'projectsIds': {
      return filters.projectsIds.length === 0;
    }
    case 'groupsIds': {
      return filters.groupsIds.length === 0;
    }
    case 'usersIds': {
      return filters.usersIds.length === 0;
    }
    case 'boardsIds': {
      return filters.boardsIds.length === 0;
    }
    case 'timeInterval': {
      // we don't filter by dateInterval pick_a_date, when this filter enabled timeFrom, timeTo enabled too.
      return filters.timeInterval === 'all_time' || filters.timeInterval === 'pick_a_date';
    }
    default: {
      return true;
    }
  }
}

export function applyFilter(activeFilters, previousIds, activityState: ESInterface<OverviewActivity>) {
  return activityState.ids.reduce((nextIds, id) => {
    const activity = activityState.entities[id];

    const filtersIsTrue = filtersSatisfied(activeFilters, activity);
    const isExists = previousIds.includes(id);

    if (acceptedTypes(activity) === false) {
      return nextIds;
    }

    if (filtersIsTrue && !isExists) {
      nextIds = [...nextIds, activity.id];
    }
    if (!filtersIsTrue && isExists) {
      nextIds = nextIds.filter(itemId => itemId !== activity.id);
    }

    return nextIds;
  }, previousIds);
}

export function filtersSatisfied(activeFilters: { filterName: keyof OverviewFilter; filterValue: any }[], activity) {
  return activeFilters.every(filter => overviewFilterFns[filter.filterName](activity, filter.filterValue));
}
