import { BehaviorSubject, fromEvent as observableFromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, take, withLatestFrom } from 'rxjs/operators';
import { Injectable, NgZone } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state/';
import { RoadmapBoardClosesAddFormAction } from '../store/roadmap-board.action';
import { RoadMapAddFormInterface } from '../interfaces/roadmap.interface';
import { isNotPresent } from '../../../../helpers/';
import { fromRoadmapBoard } from '../store/roadmap-board.reducer';
import { RoadmapCells } from '../chart/interfaces/chart.interfaces';
import { getEventOffsetX } from '../../../../helpers/event';
import { animationFrame } from 'rxjs/scheduler/animationFrame';
import { fromOpenedTask } from '../../../task/ngrx/reducers/opened-task.reducer';

interface HoveredItem {
  id: number;
  type: string;
}

@Injectable()
export class SyncRoadmapGuiStateService {
  public hoverItem$ = new BehaviorSubject<HoveredItem>({ id: 0, type: '' });
  public openPushForm$;

  public roadmapUpdated$ = new Subject();

  public hoveredCellId$ = new BehaviorSubject('');

  private sub;

  constructor(private _store: Store<AppState>, private _zone: NgZone) {
    this.openPushForm$ = this._store
      .select(fromRoadmapBoard.getAddForm)
      .pipe(map((addFormParams: any): number | boolean => (addFormParams === false ? false : addFormParams.parent)));
    this.hoveredCellId$.subscribe();
  }

  private mouseWheelDisableFunc = function(e) {
    e.preventDefault();
  };

  doWhenGUIReady(callback: any) {
    this.roadmapUpdated$.pipe(take(1), debounceTime(1, animationFrame)).subscribe(callback);
  }

  clearHoverItem() {
    this.hoverItem$.next({ id: 0, type: '' });
  }

  addFormParams(itemId, inPopup?) {
    return this._store.select(fromRoadmapBoard.getAddForm).pipe(
      map((addFormParams: RoadMapAddFormInterface) => {
        if (!addFormParams) {
          return;
        }
        if (inPopup) {
          return addFormParams.insertAfter ? addFormParams : false;
        } else {
          return !addFormParams.insertAfter && addFormParams.parent === itemId ? addFormParams : false;
        }
      })
    );
  }

  handleAddFormMissClick(event: UIEvent) {
    this._store
      .pipe(select(fromOpenedTask.getId), distinctUntilChanged(), take(1), filter(isNotPresent))
      .subscribe(() => {
        const target = <HTMLElement>event.target;
        if (!target.className || !target.className.search || target.className.search('dont_close_add_form') === -1) {
          this._store.dispatch(new RoadmapBoardClosesAddFormAction());
        }
      });
  }

  disableNativeScrolling() {
    // TODO: make it safe
    window.addEventListener('mousewheel', this.mouseWheelDisableFunc, true);
  }

  enableNativeScrolling() {
    // TODO: make it safe
    window.removeEventListener('mousewheel', this.mouseWheelDisableFunc, true);
  }

  disableBodySelection() {
    // TODO: make it safe
    document.body.classList.add('unselectable');
  }

  enableBodySelection() {
    // TODO: make it safe
    document.body.classList.remove('unselectable');
  }

  registerChartElement(chartEl: HTMLElement) {
    this._zone.runOutsideAngular(() => {
      this.sub = observableFromEvent(chartEl, 'mousemove')
        .pipe(withLatestFrom(this._store.select(fromRoadmapBoard.getChartCells)))
        .subscribe(([event, cells]) => {
          const eventX = getEventOffsetX(<MouseEvent>event);
          const cell = cells.find(
            (cell: RoadmapCells) => cell.leftPx <= eventX && eventX <= cell.leftPx + cell.widthPx
          );
          const nextV = cell ? cell.id : '';
          if (nextV !== this.hoveredCellId$.getValue()) {
            this.hoveredCellId$.next(nextV);
          }
        });
    });
  }

  unRegisterChartElement() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
