
import {fromEvent as observableFromEvent, merge as observableMerge,  Observable } from 'rxjs';

import {map, startWith, distinctUntilChanged, publishReplay, refCount, filter, mapTo} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { consoleLog } from '../../../../helpers/index';

const getNetworkStatusMessage = isOnline => (isOnline ? 'You are online 😀' : 'You are offline 🙀');

@Injectable()
export class NetworkConnectionService {
  readonly isOnline$: Observable<boolean> = observableMerge(
    observableFromEvent(window, 'online').pipe(mapTo(true)),
    observableFromEvent(window, 'offline').pipe(mapTo(false))
  ).pipe(
    startWith(navigator.onLine),
    distinctUntilChanged(),
    publishReplay(1),
    refCount(),);

  readonly online$ = this.isOnline$.pipe(filter(online => true));
  readonly offline$ = this.isOnline$.pipe(filter(online => false));

  public logNetworkStatus() {
    this.isOnline$.pipe(map(getNetworkStatusMessage)).subscribe(consoleLog());
  }

  constructor() {
    this.logNetworkStatus();
  }
}
