import { distinctUntilChanged, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Action } from '../actions/unsafe-action';
import { isEquals } from '../../../helpers';
import { Notification } from '../../interfaces';
import { NOTIFICATION_PL, TASK } from '../../constants';

import { share } from '../functions/util';
import { getEntitiesByFields } from '../functions/selectors';
import { insertReplaceState } from '../functions/reducer';
import { NotificationActionTypes } from '../actions/notification.actions';
import { ESInterface, AppState } from '../state';
import { createSelector } from 'reselect';

const markAsActions = {
  seen: notification => Object.assign({}, notification, { seen: 1 }),
  unseen: notification => Object.assign({}, notification, { seen: 0 }),
  archive: notification => Object.assign({}, notification, { archived: 1 }),
  unarchive: notification => Object.assign({}, notification, { archived: 0 })
};

const initialState: ESInterface<Notification> = {
  ids: [],
  entities: {},
  selectedEntityId: null
};

export function reducer(state = initialState, action: Action): ESInterface<Notification> {
  switch (action.type) {
    case NotificationActionTypes.MARK_AS: {
      const notifIds: number[] = <number[]>action.payload.notificationIds;
      const markAsType: string = action.payload.markType;
      let doMarkFunction: (task: Notification) => Notification;

      if (notifIds.length === 0) {
        return state;
      }

      try {
        doMarkFunction = markAsActions[markAsType];
      } catch (e) {
        console.error(`Function For mark type ${markAsType} not found at markAsActions Object`);
        return state;
      }

      const newEntities = notifIds.reduce((acc, id) => {
        acc[id] = doMarkFunction(state.entities[id]);
        return acc;
      }, {});

      const newState = <ESInterface<Notification>>{
        entities: newEntities,
        ids: [],
        selectedEntityId: state.selectedEntityId
      };

      return insertReplaceState(state, newState);
    }

    default: {
      return state;
    }
  }
}

export const getTaskNotificationIds = share(taskNotificationIdsSelector());
export const getSeenTaskNotificationIds = share(taskNotificationIdsSelector({ archived: 0, seen: 0 }));
export const getUnseenUserNotificationsCount = share(userNotificationsCountSelector({ archived: 0, seen: 0 }));

function taskNotificationIdsSelector(
  filter: {} = { archived: 0 }
): (state$: Observable<AppState>) => Observable<{ [taskId: number]: number[] }> {
  return (state$: Observable<AppState>) =>
    state$.pipe(
      getEntitiesByFields(NOTIFICATION_PL, filter),
      map(notifications => {
        return notifications.reduce((acc, curValue: Notification) => {
          if (curValue.entityType === TASK) {
            acc[curValue.entityId] = acc[curValue.entityId] ? [...acc[curValue.entityId], curValue.id] : [curValue.id];
          }
          return acc;
        }, {});
      })
    );
}
function userNotificationsCountSelector(
  filter: {} = { archived: 0, seen: 0 }
): (state$: Observable<AppState>) => Observable<number> {
  return (state$: Observable<AppState>) =>
    state$.pipe(getEntitiesByFields(NOTIFICATION_PL, filter), map(notifications => notifications.length));
}

/**
 *
 * @param filterParams
 * @returns { [taskId: number]: Notification[]
 */
export function getTaskNotifications(
  filterParams = {}
): (state$: Observable<AppState>) => Observable<{ [taskId: number]: Notification[] }> {
  const filterFields = Object.assign({}, { archived: 0, entityType: TASK }, filterParams);

  return share((state$: Observable<AppState>) =>
    state$.pipe(
      getEntitiesByFields(NOTIFICATION_PL, filterFields),
      map(notifications => {
        return notifications.reduce((acc, curValue: Notification) => {
          acc[curValue.entityId] = acc[curValue.entityId] ? [...acc[curValue.entityId], curValue] : [curValue];
          return acc;
        }, {});
      }),
      distinctUntilChanged(isEquals)
    )
  );
}

export namespace fromNotifications {
  export const getState = (store: AppState) => <ESInterface<Notification>>store[NOTIFICATION_PL];

  export const getUnseenMapByBoardId = (boardId: number) =>
    createSelector(getState, state =>
      state.ids
        .filter(id => {
          const n = state.entities[id];
          return n && !n.seen && n.archived && n.relatedData && n.relatedData.board === boardId;
        })
        .reduce((acc, id) => {
          acc[state.entities[id].relatedData.task] = true;
          return acc;
        }, {})
    );
}
