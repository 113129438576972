import { version } from './version';
import { board } from './board';
import { user } from './user';
import { project } from './project';

export const ModelValidators = {
  version: version,
  board: board,
  user: user,
  project: project
};
