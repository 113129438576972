import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ListBoardGroup } from '../list-board-details.component';

@Component({
  selector: 'list-board-add-task',
  templateUrl: './list-board-add-task.component.html',
  styleUrls: ['./list-board-add-task.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListBoardAddTaskComponent {
  @Input() boardId: number;
  @Input() group: ListBoardGroup;
  @Input() toTop: boolean;

  public isAdding: boolean;

  constructor() {}

  onOpenAddingForm() {
    this.isAdding = true;
  }

  onCloseAddingForm() {
    this.isAdding = false;
  }
}
