import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CompanyService } from './services/app/company.service';
import { EmailValidator, RegExpValidator } from './validators/custom-validators';
import { UploadFileService } from './services/app/upload-file.service';
import { RouterNavigateService } from './services/router-navigate.service';
import { SearchInLeftMenuService } from './services/app/search-in-left-menu.service';
import { WorklogService } from './services/worklog.service';

import {
  AbsPipe,
  BoldMentionTextPipe,
  ConvertFileSizePipe,
  HasUserRelationPipe,
  LinkFromTextPipe,
  LoggedTimePipe,
  MarkdownBreakPipe,
  NoBracketsPipe,
  NoUserRelationPipe,
  SearchByFieldPipe,
  SecondsToDatePipe,
  SecondsToHmsPipe,
  TextBreakPipe,
  TextToHtmlPipe
} from './pipes';
import { AutosizeDirective, HotKeyDirective, StopChildEventPropagationDirective, TooltipDirective } from './directives';
import {
  CloseButtonComponent,
  CreateNewProjectFormComponent,
  CreateNewProjectPopupButtonsComponent,
  CreateNewProjectPopupComponent,
  InplaceEditComponent,
  MemberListComponent,
  MemberListWithGroupListComponent,
  ProjectListComponent,
  SearchButtonComponent,
  SearchInputComponent,
  UserAvatarWrapperComponent,
  UserLabelsComponent,
  UserPreviewComponent
} from './components';
import { ScrollService } from './dragula/scroll.service';
import { UserInputPickerComponent } from './components/entity-pickers/user-input-picker/user-input-picker.component';
import { TaskPopupComponent } from '../task/shared/task-popup/task-popup.component';
import { UserListIndependentComponent } from './components/member/user-list-independent.component';
import { FilterEntitiesByFieldPipe } from './pipes/filter-entities-by-field.pipe';
import { ProjectInputPickerComponent } from './components/entity-pickers/project-input-picker/project-input-picker.component';
import { ProjectListIndependentComponent } from './components/project/project-list-independent.component';
import { BoardInputPickerComponent } from './components/entity-pickers/board-input-picker/board-input-picker.component';
import { BoardListIndependentComponent } from './components/board/board-list-independent.component';
import { GetPropertyPipe } from './pipes/get-property.pipe';
import { VersionListIndependentComponent } from './components/version-list/version-list-independent.component';
import { LabelTagComponent } from './components/label-tag/label-tag.component';
import { GeneralErrorMessageComponent } from './components/general-error-message/general-error-message.component';

import { OverlayComponent } from './components/overlay/overlay.component';
import { CollectionInputPickerComponent } from './components/entity-pickers/collection-input-picker/collection-input-picker.component';
import { CollectionListIndependentComponent } from './components/entity-pickers/collection-input-picker/collection-list-independent.component';
import { TabComponent } from './components/tabs/tab.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { MentionDirective } from './directives/mention.directive';
import { TopMenuContainerComponent } from '../containers/header/top-menu-container/top-menu-container.component';
import { MouseScrollDirective } from './directives/mouse-scroll.directive';
import { TaskTimeInProgressComponent } from '../task/task-time-in-progress/task-time-in-progress.component';
import { ValidationHintComponent } from './components/forms/validation-hint/validation-hint.component';
import { LinkButtonComponent } from './components/link-button/link-button.component';
import { NewBoardFormComponent } from '../board/new-board/new-board-form/new-board-form.component';
import { SmartTextModule } from './smart-text/smart-text.module';
import { TimeAgoPipe } from './components/time-ago/time-ago.pipe';
import { LabelListComponent } from '../right-menu/label-list/label-list-component';
import { TaskPreviewComponent } from './components/task-preview/task-preview.component';
import { TaskCoverComponent } from '../task/task-cover/task-cover.component';
import { ShortLabelsListComponent } from '../right-menu/label-list/short-labels-list/short-labels-list.component';
import { ADragModule } from './a-drag/a-drag.module';
import { NetworkConnectionService } from './services/app/network-connection.service';
import { AtlazApiV2Service } from './services/atlaz-api/v2/atlaz-api-v2.service';
import { MyDatePickerModule } from 'mydatepicker';
import { FormErrorsComponent } from './components/forms/form-errors/form-errors.component';
import { AtlazGuiModule } from '../atlaz-gui/atlaz-gui.module';
import { CheckTouchDirective } from './directives/check-touch.directive';
import { HelpBlockComponent } from './components/forms/help-block/help-block.component';
import { ScrollSynchroDirective } from './directives/scroll-synchro.directive';
import { SizeSynchroDirective } from './directives/size-synchro.directive';
import { InitialScrollDirective } from './directives/initial-scroll.directive';
import { NotFoundComponent } from './40x-page/a-not-found/a-not-found.component';
import { ForbiddenComponent } from './40x-page/a-forbidden/a-forbidden.component';
import { TaskTitleComponent } from '../task/shared/task-title/task-title.component';
import { TaskTitleWrapperComponent } from '../task/shared/task-title/task-title-wrapper.component';
import { SidebarLabelsEditPopupComponent } from './components/task/sidebar-labels-edit-popup/sidebar-labels-edit-popup.component';
import { ElementSelectTextDirective } from './directives/element.select-text.directive';
import { PageWithPermissionsWrapperComponent } from '../permissions/components/page-with-permissions-wrapper/page-with-permissions-wrapper.component';
import { DropdownSearchListComponent } from './components/dropdown-search-list/dropdown-search-list.component';
import { SidebarVersionPopupComponent } from './components/task/sidebar-version-popup/sidebar-version-popup.component';
import { SidebarVersionListComponent } from './components/task/sidebar-version-list/sidebar-version-list.component';
import { WorkLogFormComponent } from './components/work-log-form/work-log-form.component';
import { ContentCopyLinkComponent } from '../task/content/content-copy-link/content-copy-link.component';
import { SwimlaneEditPopupComponent } from '../right-menu/swimlane-edit-popup/swimlane-edit-popup.component';
import { SwimlaneEditFromComponent } from '../right-menu/swimlane-edit-from/swimlane-edit-from.component';
import { CreateSwimlanePopupComponent } from '../right-menu/create-swimlane-popup/create-swimlane-popup.component';
import { UserCompanyRoleSelectComponent } from './components/user-role/user-company-role-select/user-company-role-select.component';
import { UserProjectRoleSelectComponent } from './components/user-role/user-project-role-select/user-project-role-select.component';
import { SelectionRangeDirective } from './directives/selection-range.directive';
import { FavoriteTogglerDirective } from './directives/favorite-toggler.directive';
import { DayLeftPipe } from './pipes/day-left.pipe';
import { UserMultipleAvatarsWrapperComponent } from './components/user-multiple-avatars-wrapper/user-multiple-avatars-wrapper.component';
import { ContextMenuTriggerDirective } from './context-menu/context-menu-trigger.directive';
import { SyncHeaderScrollPositionDirective } from './directives/sync-header-scroll-position.directive';
import { DigitsOnlyInputDirective } from './directives/digits-only-input.directive';
import { DefaultValuePipe } from './pipes/default-value.pipe';
import { ProxyRouteComponent } from './components/proxy-route/proxy-route.component';
import { MembersComponent } from './members/members.component';
import { MembersPopupComponent } from './members/members-popup/members-popup.component';
import { MembersListComponent } from './members/members-list/members-list.component';
import { ReplacePipe } from './pipes/replace.pipe';
import { ScrollToElementDirective } from './directives/scroll-to-element.directive';
import { CommentUrlPipe } from './pipes/comment-url.pipe';
import { RollingLoaderSvgComponent } from './components/rolling-loader-svg/rolling-loader-svg.component';
import { UsersNameFilterPipe } from './pipes/users-filter.pipe';
import { UserNameWrapperComponent } from './user-name-wrapper/user-name-wrapper.component';
import { HeightBindDirective } from './directives/height-bind.directive';
import { BoardsTypeFilterComponent } from './components';
import { BoardsListComponent } from './components';
import { ALoadErrorComponent } from './40x-page/a-load-error/a-load-error.component';
import { AddProjectVersionFormComponent } from './components/project/add-project-version-form/add-project-version-form.component';
import { LinkTaskInputComponent } from './components/link-task-input/link-task-input.component';
import { OutOfViewPortDirective } from './directives/out-of-view-port.directive';
import { CopyTextComponent } from './components/copy-text/copy-text.component';
import { NoConnectionComponent } from './40x-page/a-no-connection/a-no-connection.component';
import { ServerErrorComponent } from './40x-page/a-server-error/a-server-error.component';
import { FrontErrorComponent } from './40x-page/a-front-error/a-front-error.component';
import { MarkdownMentionPipe } from './pipes/markdown-mention.pipe';
import { NewCollectionComponent } from './components/new-collection/new-collection.component';
import { JobStatusService } from './services/app/job-status.service';
import { BoardTemplateChooserComponent } from './components/board-template-chooser/board-template-chooser.component';
import { ContentMenuComponent } from './components/task/content-menu/content-menu.component';
import { TaskActionPopupComponent } from './components/task/task-action-popup/task-action-popup.component';
import { SidebarLabelsListComponent } from './components/task/sidebar-labels-list/sidebar-labels-list.component';
import { SidebarLabelsPopupComponent } from './components/task/sidebar-labels-popup/sidebar-labels-popup.component';
import { SidebarDuedatePopupComponent } from './components/task/sidebar-duedate-popup/sidebar-duedate-popup.component';
import { PushToJiraFormComponent } from './components/task/task-action-popup/push-to-jira-form/push-to-jira-form.component';
import { GlobalAddTaskFormComponent } from './components/task/global-add-task-form/global-add-task-form.component';
import { NewBoardCombinedComponent } from './components/new-board-combined/new-board-combined.component';
import { BoardTypeRadioChooserComponent } from './components/board-type-radio-chooser/board-type-radio-chooser.component';
import { SidebarStartDatePopupComponent } from './components/task/sidebar-start-date-popup/sidebar-start-date-popup.component';
import { TaskPreviewDumbComponent } from './components/task-preview-dumb/task-preview-dumb.component';
import { NewVersionComponent } from './components/new-version/new-version.component';
import { SecondsToAlternativeDhmPipe } from './pipes/seconds-to-alternative-dhm.pipe';
import { ProjectStatusEditingComponent } from './components/project/project-status-editing/project-status-editing.component';
import { TasksCustomFieldEditComponent } from './components/task/tasks-custom-field-edit/tasks-custom-field-edit.component';
import { SidebarProjectComponent } from './components/task/sidebar-project/sidebar-project.component';
import { SidebarProjectListComponent } from './components/task/sidebar-project/sidebar-projects-list/sidebar-projects-list.component';
import { SidebarProjectPopupComponent } from './components/task/sidebar-project/sidebar-project-popup/sidebar-project-popup.component';
import { EditIceRiceScoringComponent } from '../board/backlog-scoring/edit-ice-rice-scoring/edit-ice-rice-scoring.component';
import { EditTaskScoringComponent } from '../board/backlog-scoring/edit-task-scoring/edit-task-scoring.component';
import { EditBasicScoringComponent } from '../board/backlog-scoring/edit-basic-scoring/edit-basic-scoring.component';
import { DateDiffPipe } from './pipes/date-diff.pipe';
import { ContentMenuBatchComponent } from './components/task/content-menu/content-menu-batch/content-menu-batch.component';

const SHARED_DECLARATIONS = [
  // components
  TaskTitleWrapperComponent,
  TaskTitleComponent,
  TaskTimeInProgressComponent,
  InplaceEditComponent,
  NewBoardFormComponent,
  NewCollectionComponent,
  CloseButtonComponent,
  LinkButtonComponent,
  UserAvatarWrapperComponent,
  MemberListComponent,
  MemberListWithGroupListComponent,
  ProjectListComponent,
  UserLabelsComponent,
  UserPreviewComponent,
  TaskPopupComponent,
  GeneralErrorMessageComponent,
  FormErrorsComponent,
  UserListIndependentComponent,
  BoardListIndependentComponent,
  VersionListIndependentComponent,
  BoardInputPickerComponent,
  ProjectListIndependentComponent,
  ProjectInputPickerComponent,
  CollectionInputPickerComponent,
  CollectionListIndependentComponent,
  UserInputPickerComponent,
  LabelTagComponent,
  TabComponent,
  TabsComponent,
  HelpBlockComponent,
  ValidationHintComponent,
  SwimlaneEditPopupComponent,
  SwimlaneEditFromComponent,
  CreateSwimlanePopupComponent,
  OverlayComponent,
  CreateNewProjectFormComponent,
  CreateNewProjectPopupComponent,
  CreateNewProjectPopupButtonsComponent,
  TopMenuContainerComponent,
  LabelListComponent,
  TaskPreviewComponent,
  TaskPreviewDumbComponent,
  TaskCoverComponent,
  ShortLabelsListComponent,
  SidebarProjectComponent,
  SidebarProjectListComponent,
  SidebarProjectPopupComponent,
  SidebarLabelsPopupComponent,
  SidebarLabelsEditPopupComponent,
  SidebarLabelsListComponent,
  SidebarVersionPopupComponent,
  SidebarVersionListComponent,
  NotFoundComponent,
  ForbiddenComponent,
  ALoadErrorComponent,
  NoConnectionComponent,
  ServerErrorComponent,
  FrontErrorComponent,
  PageWithPermissionsWrapperComponent,
  DropdownSearchListComponent,
  WorkLogFormComponent,
  ContentCopyLinkComponent,
  UserCompanyRoleSelectComponent,
  UserProjectRoleSelectComponent,
  UserNameWrapperComponent,
  UserMultipleAvatarsWrapperComponent,
  SearchInputComponent,
  SearchButtonComponent,
  ContextMenuTriggerDirective,
  MembersComponent,
  MembersPopupComponent,
  MembersListComponent,
  ProxyRouteComponent,
  RollingLoaderSvgComponent,
  BoardsTypeFilterComponent,
  BoardsListComponent,
  AddProjectVersionFormComponent,
  LinkTaskInputComponent,
  CopyTextComponent,
  ContentMenuComponent,
  ContentMenuBatchComponent,
  TaskActionPopupComponent,
  SidebarDuedatePopupComponent,
  SidebarStartDatePopupComponent,
  GlobalAddTaskFormComponent,
  ProjectStatusEditingComponent,
  TasksCustomFieldEditComponent,
  EditTaskScoringComponent,
  EditIceRiceScoringComponent,
  EditBasicScoringComponent,

  // pipes
  BoldMentionTextPipe,
  LinkFromTextPipe,
  LoggedTimePipe,
  NoBracketsPipe,
  SearchByFieldPipe,
  HasUserRelationPipe,
  FilterEntitiesByFieldPipe,
  UsersNameFilterPipe,
  NoUserRelationPipe,
  SecondsToDatePipe,
  SecondsToHmsPipe,
  DateDiffPipe,
  GetPropertyPipe,
  TextBreakPipe,
  MarkdownBreakPipe,
  MarkdownMentionPipe,
  TextToHtmlPipe,
  ConvertFileSizePipe,
  AbsPipe,
  TimeAgoPipe,
  DayLeftPipe,
  DefaultValuePipe,
  ReplacePipe,
  CommentUrlPipe,

  // directives
  AutosizeDirective,
  DigitsOnlyInputDirective,
  HeightBindDirective,
  HotKeyDirective,
  MentionDirective,
  ElementSelectTextDirective,
  MouseScrollDirective,
  CheckTouchDirective,
  TooltipDirective,
  ScrollSynchroDirective,
  SyncHeaderScrollPositionDirective,
  SizeSynchroDirective,
  InitialScrollDirective,
  SizeSynchroDirective,
  SelectionRangeDirective,
  FavoriteTogglerDirective,
  StopChildEventPropagationDirective,
  ScrollToElementDirective,
  StopChildEventPropagationDirective,
  OutOfViewPortDirective,
  BoardTemplateChooserComponent,
  PushToJiraFormComponent,
  NewBoardCombinedComponent,
  BoardTypeRadioChooserComponent
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ADragModule,
    SmartTextModule,
    MyDatePickerModule,
    FormsModule,
    RouterModule,
    AtlazGuiModule
  ],
  declarations: [
    ...SHARED_DECLARATIONS,
    SelectionRangeDirective,
    BoardsListComponent,
    NewVersionComponent,
    SecondsToAlternativeDhmPipe
  ],
  exports: [
    ...SHARED_DECLARATIONS,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    SmartTextModule,
    MyDatePickerModule
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        CompanyService,
        AtlazApiV2Service,
        RegExpValidator,
        EmailValidator,
        ScrollService,
        RouterNavigateService,
        UploadFileService,
        NetworkConnectionService,
        UploadFileService,
        SearchInLeftMenuService,
        WorklogService,
        JobStatusService
      ]
    };
  }
}
