<div class="collections_wrapper" [class.closed_state]="!isVisibleBoards">
  <div class="collection_title" (click)="onOpenBoardsList()">
    <h3>BOARDS</h3>
    <div class="arrow_state_icon" [class.closed_state]="!isVisibleBoards">
      <svg width="18" height="18">
        <use xlink:href="#triangle"></use>
      </svg>
    </div>
  </div>
  <left-board-list
    *ngIf="isVisibleBoards"
    [boards]="unassignBoards"
  ></left-board-list>
</div>
