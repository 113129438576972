import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { Board, PartOfEntity } from '../../interfaces';
import { ModelValidators } from '../../shared/validators/model-validators';

@Component({
  selector: 'board-rename',
  templateUrl: 'board-rename.component.html',
  styleUrls: ['board-rename.component.scss']
})
export class BoardRenameComponent implements OnInit {
  @Input() board: Board;

  @Output() submitForm: EventEmitter<PartOfEntity> = new EventEmitter<PartOfEntity>();
  @Output() cancelForm = new EventEmitter();

  public boardForm: FormGroup;

  constructor(private _fb: FormBuilder) {}

  ngOnInit(): any {
    this.boardForm = this._fb.group({
      name: [this.board !== null ? this.board.name : '', ModelValidators.board.name],
      id: [this.board !== null ? this.board.id : 0]
    });
  }

  onSubmit() {
    if (this.boardForm.valid === true) {
      this.submitForm.emit(this.boardForm.value);
    }
  }

  onCancel() {
    this.cancelForm.emit();
  }
}
