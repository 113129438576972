import { Component } from '@angular/core';

@Component({
  selector: 'a-load-error',
  templateUrl: './a-load-error.component.html',
  styleUrls: ['../40x-page.component.scss']
})
export class ALoadErrorComponent {
  constructor() {}
}
