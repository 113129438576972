import { combineLatest as observableCombineLatest, Observable, Subscription } from 'rxjs';

import { startWith, filter, map, pluck, distinctUntilChanged, switchMap, withLatestFrom } from 'rxjs/operators';
import { Component, Injectable, Input, OnDestroy, OnInit } from '@angular/core';
import { AppState, ESInterface } from '../../../ngrx/state';
import { Store } from '@ngrx/store';
import { getTaskById, getTaskUrl, taskTitle } from '../../../ngrx/reducers/task.reducer';
import { Board, Column, Entity, Project, Swimlane, Task } from '../../../interfaces';
import { PROJECT_PL } from '../../../constants';
import { getColumnEntities } from '../../../ngrx/reducers/column.reducer';
import { getTaskBreadCrumbs } from '../../../../helpers/task';
import { isPresent } from '../../../../helpers';
import { getSwimlaneById } from '../../../ngrx/reducers/swimlane.reducer';
import { getBoardById } from '../../../ngrx/reducers/board.reducer';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Component({
  selector: 'search-result-preview',
  templateUrl: './search-result-preview.component.html',
  styleUrls: ['./search-result-preview.component.scss']
})
@Injectable()
export class SearchResultPreviewComponent implements OnInit, OnDestroy {
  @Input() taskId: number;
  subs: Subscription[] = [];
  task: Task;
  task$;
  isTaskDeleted$: Observable<boolean>;
  projectName$;
  project$;
  taskTitle$;
  column$;
  subColumn$;
  isUnavailableTaskPopupOpened: boolean;
  public swimlane$: Observable<Swimlane>;
  public board$: Observable<Board>;
  public taskBreadCrumbs$: Observable<string>;
  public taskUrl$: Observable<string>;
  public isTaskOverdone$ = new BehaviorSubject(false);
  public isTaskCloseToOverdone$ = new BehaviorSubject(false);

  constructor(private _store: Store<AppState>) {}

  ngOnInit(): any {
    this.task$ = this._store.pipe(getTaskById(this.taskId), filter(task => !!task));
    this.subs.push(this.task$.subscribe((task: Task) => (this.task = task)));
    this.isTaskDeleted$ = this._store.pipe(getTaskById(this.taskId), map(task => !task));
    this.subs.push(
      this.task$.subscribe((task: Task) => {
        const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
        const diffTime = task.dueDate - currentTimeInSeconds;
        const secondsInOneDay = 86400;

        this.isTaskOverdone$.next(currentTimeInSeconds > task.dueDate && !task.doneDate);
        this.isTaskCloseToOverdone$.next(diffTime < secondsInOneDay && diffTime > 0 && !task.doneDate);
      })
    );

    this.project$ = this.task$.pipe(
      switchMap((task: Task) =>
        this._store.pipe(pluck(PROJECT_PL, 'entities', String(task.project)), distinctUntilChanged())
      )
    );
    this.board$ = this.task$.pipe(switchMap((task: Task) => this._store.pipe(getBoardById(task.board))));
    this.column$ = this.task$.pipe(
      withLatestFrom(this._store.pipe(getColumnEntities), (task: Task, columns: ESInterface<Column>) => {
        const parentColumnId = columns[task.column] && columns[task.column].parent ? columns[task.column].parent : null;
        return parentColumnId
          ? columns[parentColumnId] // return parent column
          : columns[task.column]; // target column isn't composit - it's required column
      })
    );
    this.subColumn$ = this.task$.pipe(
      withLatestFrom(
        this._store.pipe(getColumnEntities),
        // if target column has parent column, it's subcolumn!
        (task: Task, columns: ESInterface<Column>) =>
          columns[task.column] && columns[task.column].parent ? columns[task.column] : null
      )
    );
    this.swimlane$ = this.task$.pipe(switchMap((task: Task) => this._store.pipe(getSwimlaneById(task.swimlane))));

    this.taskTitle$ = taskTitle(this.task$, this.project$);
    this.projectName$ = this.project$.pipe(map((project: Project) => (project ? project.shortName : null)));
    this.taskUrl$ = this._store.pipe(getTaskUrl(this.task));
    this.taskBreadCrumbs$ = observableCombineLatest([
      this.task$,
      this.board$,
      this.swimlane$.pipe(startWith(null)),
      this.column$,
      this.subColumn$
    ]).pipe(
      filter(([task, board, swimlane, column, subColumn]) => [task, board, swimlane, column].every(isPresent)),
      map(([task, board, swimlane, column, subColumn]) => {
        return getTaskBreadCrumbs(
          task,
          Array.isArray(board.swimlanesIds) ? board : { ...board, swimlanesIds: [] },
          swimlane,
          column,
          subColumn
        );
      })
    );
  }

  onToggleUnavailableTaskPopup(): void {
    this.isUnavailableTaskPopupOpened = !this.isUnavailableTaskPopupOpened;
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
