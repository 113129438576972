
import { mergeMap, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { COMPANY_DOMAINS_PL } from '../../constants';
import { AppState } from '../state/app-state';
import { defaultErrorHandler } from './root.effect';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { HandleResponseAction } from '../actions/root.action';
import { CompanyDomainTypes} from "../actions/company-domain.actions";
import { fromCamelToDash } from "../../../helpers";

@Injectable()
export class CompanyDomainEffects {
  @Effect()
  editDomain$ = this.actions$
    .ofType(CompanyDomainTypes.EDIT).pipe(
      mergeMap(({ type, payload }: { type: string; payload: any }) => {
        return this._atlazApiV2
          .patch([fromCamelToDash(COMPANY_DOMAINS_PL)], payload).pipe(
            map(resp => new HandleResponseAction(resp)),
            catchError(defaultErrorHandler(type, payload)),);
      }));

  constructor(private actions$: Actions, private _store: Store<AppState>, protected _atlazApiV2: AtlazApiV2Service) {}
}
