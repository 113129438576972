<div *ngIf="selectedProjects$ | async | getProperty : 'length'">
  <h3>Version</h3>
  <div class="version_container">
      <version-tag
        *ngFor="let versionsId of selectedVersionsIds$ | async"
        [version]="versions$ | async | getProperty : versionsId"
        (remove)="onRemove($event)"
      >
      </version-tag>
      <div class="add_btn" (click)="showAddPopUp=!showAddPopUp"></div>
  </div>
</div>

<div *ngIf="showAddPopUp" a-context-popup [title]="'Versions'" (close)="showAddPopUp = false">
  <version-list-independent [selectedVersions]="selectedVersionsIds$"></version-list-independent>
</div>
