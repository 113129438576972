import { Action } from './unsafe-action';
import { PartOfEntity, Checklist, ChecklistItem } from '../../interfaces';
import { CHECKLISTS_ITEM } from '../../constants';
import { labelAction } from '../functions/action';

export const ChecklistItemActionTypes = {
  GET: labelAction(CHECKLISTS_ITEM, 'GET'),
  GET_COMPLETE: labelAction(CHECKLISTS_ITEM, 'GET_COMPLETE'),
  ADD: labelAction(CHECKLISTS_ITEM, 'ADD'),
  ADD_COMPLETE: labelAction(CHECKLISTS_ITEM, 'ADD_COMPLETE'),
  EDIT: labelAction(CHECKLISTS_ITEM, 'EDIT'),
  EDIT_COMPLETE: labelAction(CHECKLISTS_ITEM, 'EDIT_COMPLETE'),
  DELETE: labelAction(CHECKLISTS_ITEM, 'DELETE'),
  DELETE_COMPLETE: labelAction(CHECKLISTS_ITEM, 'DELETE_COMPLETE'),
  MOVE: labelAction(CHECKLISTS_ITEM, 'MOVE')
};

export class GetChecklistItemAction implements Action {
  type = ChecklistItemActionTypes.GET;

  constructor(public payload: number) {}
}

export class ChecklistItemGetCompleteAction implements Action {
  type = ChecklistItemActionTypes.GET_COMPLETE;

  constructor(public payload: ChecklistItem[]) {}
}

export class ChecklistItemEditAction implements Action {
  type = ChecklistItemActionTypes.EDIT;

  constructor(public payload: PartOfEntity) {}
}

export class ChecklistItemMoveAction implements Action {
  type = ChecklistItemActionTypes.MOVE;

  constructor(public payload: { checkboxId: number; nextCheckboxId: number; checklistItem: ChecklistItem }) {}
}

export class ChecklistItemEditCompleteAction implements Action {
  type = ChecklistItemActionTypes.EDIT_COMPLETE;

  constructor(public payload: Checklist) {}
}

export class ChecklistItemDeleteAction implements Action {
  type = ChecklistItemActionTypes.DELETE;

  constructor(public payload: ChecklistItem) {}
}

export class ChecklistItemDeleteCompleteAction implements Action {
  type = ChecklistItemActionTypes.DELETE_COMPLETE;

  constructor(public payload: ChecklistItem) {}
}

export class ChecklistItemAddAction implements Action {
  type = ChecklistItemActionTypes.ADD;

  constructor(public payload: PartOfEntity) {}
}

export class ChecklistItemAddCompleteAction implements Action {
  type = ChecklistItemActionTypes.ADD_COMPLETE;

  constructor(public payload: ChecklistItem) {}
}

export type ChecklistItemActions =
  | ChecklistItemGetCompleteAction
  | ChecklistItemEditAction
  | ChecklistItemEditCompleteAction
  | GetChecklistItemAction
  | ChecklistItemDeleteAction
  | ChecklistItemDeleteCompleteAction
  | ChecklistItemAddAction
  | ChecklistItemAddCompleteAction
  | ChecklistItemMoveAction;
