import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'a-editable-select',
  template: `
    <a-editable-inline *ngIf="!isEdit; else elseBlock"
                       [isEditable]="isEditable"
                       (pencilClick)="onStartEdit()"
    ><p>{{currentTitle}}</p></a-editable-inline>
    <ng-template #elseBlock>
      <select (change)="onSelectNewValue($event.target['value'])"
              [value]="value"
              [clickOut]="isEdit" (clickOutEvent)="onEndEdit()"
      >
        <option *ngFor="let itemKey of optionKeys"
                [value]="itemKey"
        >{{options[itemKey]}}</option>
      </select>
    </ng-template>
  `,
  styleUrls: ['./a-editable-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AEditableSelectComponent {
  /**
   *
   * object like { [value
   * @param
   */
  @Input() options: { [valueKey: string]: string };
  @Input() value: any;
  @Input() isEditable: boolean;
  @Input() optionsFilter: (option: string) => boolean;

  @Output() selectNewValue = new EventEmitter();

  public isEdit = false;

  get currentTitle() {
    return this.options !== undefined ? this.options[this.value] : '';
  }

  get optionKeys(): any[] {
    const keys = this.options !== undefined ? Object.keys(this.options) : [];

    return this.optionsFilter !== undefined ? keys.filter(this.optionsFilter) : keys;
  }

  onStartEdit() {
    this.isEdit = true;
  }

  onEndEdit() {
    this.isEdit = false;
  }

  onSelectNewValue(newValue: string | number) {
    this.selectNewValue.emit(newValue);
    this.onEndEdit();
  }
}
