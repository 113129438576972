<div class="default_quads_filter"
     (click)="onShowQuadsPopup()"
     [ngClass]="{'active': showQuadsPopUp}"
>
  Quadrants
</div>

<div *ngFor="let quad of selectedQuadsWithNames$ | async"
     class="quad_filter_tag"
     (click)="onToggleQuad(quad.index)"
>
  {{quad.name}}
  <div class="clear_block"></div>
</div>

<div a-context-popup [className]="'thin_popup'" *ngIf="showQuadsPopUp"
     [title]="'Quadrants'"
     class="default_quads_list_popup"
     (close)="onHideQuadsPopup()"
>
  <div class="quad_list_container">
    <div *ngFor="let item of (quadsWithStatus$ | async);let i = index"
         class="quad_list_item"
         [ngClass]="{active: item.isActive}"
         (click)="onToggleQuad(i)"
    >{{item.name}}</div>
  </div>
</div>
