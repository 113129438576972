import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OPENED_TASK_OUTLET, POPUP_OUTLET, TASKS } from '../path.routing';
import { TaskDetailPageRelatedDataService } from './task-detail-page/services/task-detail-page-related-data.service';
import { TaskDetailPageNavigateService } from './task-detail-page/services/task-detail-page-navigate.service';
import { TaskDetailPopupComponent } from './task-detail-popup.component';
import { TaskDetailPageResolver } from './task-detail-page/services/task-detail-page.resolver';
import { TaskDetailPageComponent } from './task-detail-page/task-detail-page.component';
import * as PATH from '../path.routing';
import { ProxyRouteComponent } from '../shared/components/proxy-route/proxy-route.component';
import { TaskDeletingWatcherService } from './task-detail-page/services/task-deleting-watcher.service';

const taskRouting: Routes = [
  {
    path: TASKS + '/:taskSlug',
    component: TaskDetailPageComponent,
    outlet: OPENED_TASK_OUTLET
  },
  { path: 'tasks/:taskId', component: TaskDetailPopupComponent, outlet: POPUP_OUTLET },
  {
    path: 'attach',
    outlet: PATH.POPUP_OUTLET,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: 'app/attach-slideshow/attach-slideshow.module#AttachSlideshowModule'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(taskRouting)],
  exports: [RouterModule],
  declarations: [],
  providers: [
    TaskDetailPageRelatedDataService,
    TaskDetailPageNavigateService,
    TaskDetailPageResolver,
    TaskDeletingWatcherService
  ]
})
export class TaskRoutingModule {}
