import { of as observableOf, Observable, BehaviorSubject, Subscription } from 'rxjs';

import {
  tap,
  pluck,
  distinctUntilChanged,
  debounceTime,
  refCount,
  publishReplay,
  startWith,
  switchMap,
  map,
  combineLatest
} from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SprintBoard, Version } from '../../../interfaces';
import { AppUrls } from '../../../app-urls';
import { getEntitiesByFields } from '../../../ngrx/functions/selectors';
import { EstimationType, SPRINT_TASK_PL, VERSION_PL } from '../../../constants';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { PaginationLoaderService } from '../../../shared/services/paginataion-loader/paginataion-loader.service';
import { RemoveEntitiesFromStoreAction } from '../../../ngrx/actions/root.action';
import { scrollbarWidth } from '../../../../helpers/scroll';
import { WorkingDaysService } from '@atlaz/working-days/services/working-days.service';
import { compareArrays, getSprintsTaskCompletedSec, sortByField } from '../../../../helpers';
import { SprintsTasks } from '../../../interfaces/sprints-tasks';

@Component({
  selector: 'released-sprints',
  templateUrl: './released-sprints.component.html',
  styleUrls: ['./released-sprints.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PaginationLoaderService]
})
export class ReleasedSprintsComponent implements OnInit, OnDestroy {
  @Input() board$: Observable<SprintBoard>;

  public boardUrl$: Observable<string | any[]>;
  public versions$: Observable<Version[]>;
  public sprintsTasks$: Observable<SprintsTasks[]>;
  public empty$: Observable<boolean>;
  public noMatch$: Observable<boolean>;
  public showLoader$: Observable<boolean>;

  public boardId$: Observable<number>;
  public boardId;
  public projectId;
  public appUrls = AppUrls;
  public openedMenuId: number;

  public scrollbarWidth = scrollbarWidth;

  subs: Subscription[] = [];

  private query$ = new BehaviorSubject('');

  constructor(
    private _store: Store<AppState>,
    public _paginatorService: PaginationLoaderService,
    private _workingDays: WorkingDaysService
  ) {}

  ngOnInit() {
    this.boardId$ = <Observable<number>>this.board$.pipe(pluck('id'), distinctUntilChanged());
    const query$ = this.query$.pipe(debounceTime(400), startWith(''), distinctUntilChanged());

    const isEmpty$ = this._paginatorService.pending$.pipe(
      switchMap(pending => (pending ? observableOf(false) : this.versions$.pipe(map(x => x.length === 0)))),
      distinctUntilChanged()
    );
    this.empty$ = query$.pipe(switchMap(query => (query.length === 0 ? isEmpty$ : observableOf(false))));
    this.noMatch$ = query$.pipe(switchMap(query => (query.length > 0 ? isEmpty$ : observableOf(false))));
    this.showLoader$ = this._paginatorService.pending$.pipe(
      combineLatest(this._paginatorService.hasMore$, (a, b) => !a && b)
    );

    this.subs.push(
      this.board$.subscribe(board => {
        this.boardId = board.id;
        // sprintBoard have the only project
        this.projectId = (board.projectsIds || [])[0];
      })
    );

    this.boardUrl$ = this.board$.pipe(map(board => AppUrls.getUrlBoard(board.id)));
    this.sprintsTasks$ = <Observable<SprintsTasks[]>>this._store.pipe(
      getEntitiesByFields(SPRINT_TASK_PL, { board: this.boardId }),
      distinctUntilChanged(compareArrays)
    );

    this.versions$ = <Observable<Version[]>>this._store.pipe(
      getEntitiesByFields(VERSION_PL, { released: 1, board: this.boardId }),
      distinctUntilChanged(compareArrays),
      combineLatest(this.sprintsTasks$, (versions, sprintsTasks) => {
        return versions.map(version => ({
          ...version,
          storyPointsCompleted: this.getVersionCompletedPoints(version, sprintsTasks),
          hoursCompleted: this.getVersionCompletedHours(version, sprintsTasks),
          workingDaysDuration: this._workingDays.workingDaysBetween(version.startDate, version.releasedDate)
        }));
      }),
      map(sortByField('releasedDate', 'desc')),
      publishReplay(1),
      refCount()
    );

    this.subs.push(
      query$
        .pipe(
          tap(_ =>
            this._store.dispatch(
              new RemoveEntitiesFromStoreAction([
                {
                  entityName: VERSION_PL,
                  predicate: model => model.board === this.boardId
                }
              ])
            )
          )
        )
        .subscribe(query => {
          this._paginatorService.controller = VERSION_PL;
          this._paginatorService.queryParams = {
            'q[name]': query,
            released: 1,
            project: this.projectId,
            board: this.boardId,
            expand: SPRINT_TASK_PL
          };
          this._paginatorService.loadMore();
        })
    );
  }

  onChange(value) {
    this.query$.next(String(value));
  }

  onLoadMore() {
    this._paginatorService.loadMore();
  }

  onToggleContextMenu(event, id) {
    event.stopPropagation();
    if (event.target.className === 'overlay') {
      event.preventDefault();
    }
    this.openedMenuId = this.openedMenuId === id ? 0 : id;
  }

  getVersionCompletedPoints(version: Version, sprintsTasks: SprintsTasks[]) {
    return version && version.estimatedBy === EstimationType.hours
      ? '-'
      : sprintsTasks
          .filter(sprintTasks => sprintTasks.version === version.id)
          .reduce((acc, item) => acc + (item.doneDate && item.storyPoints ? item.storyPoints : 0), 0);
  }

  getVersionCompletedHours(version: Version, sprintsTasks: SprintsTasks[]) {
    return version && version.estimatedBy === EstimationType.storyPoints
      ? '-'
      : Math.round(
          sprintsTasks
            .filter(sprintTasks => sprintTasks.version === version.id)
            .reduce((acc, item) => acc + getSprintsTaskCompletedSec(item), 0) / 3600
        );
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
