import { Action } from './unsafe-action';
import { HttpQueryParam, PartOfEntity } from '../../interfaces';
import { DragItem } from '../../shared/a-drag/a-drag.service';
import { labelAction } from '../functions/action';
import { WebSocketDeleteAnswer } from '../reducers/data-sync.reducer';

export enum RootActionTypes {
  NOOP = '[META_ACTION] NOOP',
  GET = '[META_ACTION] GET',
  GET_BY_IDS = '[META_ACTION] GET_BY_IDS',
  GET_COMPLETE = '[META_ACTION] GET_COMPLETE',
  HANDLE_RESPONSE = '[META_ACTION] HANDLE_RESPONSE',
  SELECT_ENTITY = '[META_ACTION] SELECT_ENTITY',
  EDIT_MODEL = '[META_ACTION] EDIT_MODEL',
  PATCH_ENTITY = '[META_ACTION] PATCH_ENTITY',
  PATCH_ENTITY_POSITION = '[META_ACTION] PATCH_ENTITY_POSITION',
  REMOVE_ENTITY_FROM_STORE = '[META_ACTION] REMOVE_ENTITY_FROM_STORE',
  REMOVE_ENTITIES_FROM_STORE = '[META_ACTION] REMOVE_ENTITIES_FROM_STORE',
  REMOVE_ENTITIES_BY_IDS = '[META_ACTION] REMOVE_ENTITIES_BY_IDS'
}

export const UNDO_ACITON = '[ROOT] UNDO action to revert state back';

export interface GetByIdsPayload {
  entity: string;
  ids: number[];
  queryParams?: HttpQueryParam;
}

export interface GetPayload {
  entity: string;
  id: number;
  expand?: string;
}

export interface RemoveEntityPayload {
  entityName: string;
  predicate: (Entity) => boolean;
}

export class GetAction implements Action {
  readonly type = RootActionTypes.GET;

  constructor(public payload: any) {}
}

export class GetByIdsAction implements Action {
  readonly type = RootActionTypes.GET_BY_IDS;

  constructor(public payload: GetByIdsPayload) {}
}
/** works with api v2 */
export class HandleResponseAction implements Action {
  readonly type = RootActionTypes.HANDLE_RESPONSE;
  /** works with api v2 */
  constructor(public payload: any) {}
}

/** works with api v2 */
export const defaultResponseHandler = resp => new HandleResponseAction(resp);

export class GetCompleteAction implements Action {
  readonly type = RootActionTypes.GET_COMPLETE;

  constructor(public payload: any) {}
}

export class SelectEntityAction implements Action {
  readonly type = RootActionTypes.SELECT_ENTITY;

  constructor(public payload: { entityName: string; id: number }) {}
}

export class EditModelAction implements Action {
  readonly type = RootActionTypes.EDIT_MODEL;

  constructor(public payload: { data: PartOfEntity; entityName: string }) {}
}

export class PatchEntityAction implements Action {
  readonly type = RootActionTypes.PATCH_ENTITY;

  constructor(public payload: { data: PartOfEntity; entityName: string; httpParams?: {} }) {}
}

export class PatchEntityPosition implements Action {
  readonly type = RootActionTypes.PATCH_ENTITY_POSITION;

  constructor(public payload: { value: DragItem; entityPl: string; insertSuffix: string }) {}
}

export class RemoveEntityFromStoreAction implements Action {
  readonly type = RootActionTypes.REMOVE_ENTITY_FROM_STORE;

  constructor(public payload: RemoveEntityPayload) {}
}

export class RemoveEntitiesFromStoreAction implements Action {
  readonly type = RootActionTypes.REMOVE_ENTITIES_FROM_STORE;

  constructor(public payload: RemoveEntityPayload[]) {}
}

export class RemoveEntitiesByIdsAction implements Action {
  readonly type = RootActionTypes.REMOVE_ENTITIES_BY_IDS;

  constructor(public payload: WebSocketDeleteAnswer[]) {}
}

export class NoopAction implements Action {
  readonly type = RootActionTypes.NOOP;

  constructor(public payload?: any) {}
}

export class UndoAction implements Action {
  readonly type = UNDO_ACITON;

  constructor(public payload: Action) {}
}

export type RootActions = GetAction | GetCompleteAction | NoopAction;
