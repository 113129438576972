import { isIEorEdge } from './platform';

const DEFAULT_LINE_HEIGHT = 35;

export const getEventPath = (event: Event): HTMLElement[] => {
  if (event['path']) {
    return event['path'];
  }
  if (event['composedPath']) {
    return event['composedPath']();
  }
  const path = [];
  let node = <HTMLElement>event.target;
  do {
    path.push(node);
  } while ((node = node.parentElement));
  return path;
};

let wheelEventName = 'onmousewheel';

if ('onwheel' in document || isIEorEdge) {
  // IE9+, FF17+, Ch31+
  wheelEventName = 'wheel';
} else if ('onmousewheel' in document) {
  // устаревший вариант события
  wheelEventName = 'mousewheel';
} else {
  // Firefox < 17
  wheelEventName = 'MozMousePixelScroll';
}

export const mouseWheelEventName = wheelEventName;

export const getEventOffsetX = (event: MouseEvent) => event.offsetX || event.layerX || 0;
export const getEventOffsetY = (event: MouseEvent) => event.offsetY || event.layerY || 0;

export const getScrollDelta = (event: MouseWheelEvent) => {
  const deltaX = event.deltaMode === event.DOM_DELTA_LINE ? event.deltaX * DEFAULT_LINE_HEIGHT : event.deltaX;
  const deltaY = event.deltaMode === event.DOM_DELTA_LINE ? event.deltaY * DEFAULT_LINE_HEIGHT : event.deltaY;

  return { deltaX, deltaY };
};

export const setFocusToFocusableParent = element => {
  let parentWithTabIndex = element.parentNode;
  while (parentWithTabIndex && parentWithTabIndex.tabIndex < 0 && parentWithTabIndex.nodeName !== 'BODY') {
    parentWithTabIndex = parentWithTabIndex.parentNode;
  }
  if (parentWithTabIndex) {
    parentWithTabIndex.focus();
  }
};
