<a-center-popup
  [title]="'Add New Version'"
  (close)="onCancel()"
>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <form-errors></form-errors>
    <div class="row" *ngIf="(availableProjects$ | async)?.length > 1">
      <label for="project">Project</label>
      <select id="project" [formControlName]="'project'">
        <option *ngFor="let project of availableProjects$ | async" [ngValue]="project.id">{{project.name}}</option>
      </select>
    </div>
    <div class="row">
      <label for="name">Version name</label>
      <input [formControlName]="'name'" id="name"
             [focus]="true"
             autocomplete="off"
             checkTouch
             placeholder="1.0.0"/>
      <help-block [control]="form.get('name')" [messages]="{'maxlength': 'Maximum length of 50 symbols is exceed'}"></help-block>
    </div>
    <div class="row">
      <label for="date">Planned date</label>
      <a-datetime-picker [formControlName]="'plannedDate'" id="date" [isClearBtnEnabled]="true"></a-datetime-picker>
    </div>
    <button a-button type="submit" class="btn_primary">Add</button>
    <button a-button type="button" (click)="onCancel()" class='btn_text'>Cancel</button>
  </form>
</a-center-popup>
