import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, ESInterface } from '../../../../ngrx/state/app-state';
import { getEntitySelector } from '../../../../ngrx/functions/selectors';
import { COLLECTION_PL } from '../../../../constants/entity';
import { BehaviorSubject } from 'rxjs';
import { Collection } from '../../../../interfaces/collection';

@Component({
  selector: 'collection-input-picker',
  templateUrl: './collection-input-picker.component.html',
  styleUrls: ['./collection-input-picker.component.scss']
})
export class CollectionInputPickerComponent implements OnInit, OnDestroy {
  @Input() selectedCollections$: BehaviorSubject<number[]>;
  @Input() className = 'collection_pick_popup';

  public collectionState: ESInterface<Collection>;

  public showAddPopUp = false;
  public subscription;
  public collectionEntities: { [id: number]: Collection };

  constructor(private _store: Store<AppState>) {}

  ngOnInit(): any {
    this.subscription = this._store.pipe(
      (getEntitySelector(COLLECTION_PL)))
      .subscribe((state: ESInterface<Collection>) => {
        this.collectionState = state;
        this.collectionEntities = state.entities;
      });
  }

  ngOnDestroy(): any {
    this.subscription.unsubscribe();
  }

  onRemoveMember(collectionId) {
    this.selectedCollections$.next(
      this.selectedCollections$.getValue().filter(existsCollection => existsCollection !== collectionId)
    );
  }

  onOpenPopup() {
    this.showAddPopUp = true;
  }

  onClosePopup() {
    this.showAddPopUp = false;
  }
}
