
import {from as observableFrom, of as observableOf, empty as observableEmpty,  BehaviorSubject ,  Observable } from 'rxjs';

import {take, switchMap, filter, mapTo, pluck, combineLatest, catchError, startWith, switchMapTo, skip, map, distinctUntilChanged} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState, EntityState } from '../../../../../ngrx/state';
import { AuthService } from '../../../../../shared/services/app/auth.service';
import { AtlazApiV2Service } from '../../../../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import {
  ActiveImportActionTypes,
  ActiveImportCancelAction,
  ActiveImportConfirmAction,
  ActiveImportDataChangedAction
} from '../action/active-import.action';
import {
  JsonApiModelsResponse,
  jsonApiToEntityState
} from '../../../../../shared/services/app/web-socket/http-response';
import { GetCompleteAction } from '../../../../../ngrx/actions/root.action';
import { ACTIVE_IMPORT, IMPORT_PL, USER_PL } from '../../../../../constants';
import { Import } from '../../../../../interfaces/import';
import { isPresent } from '../../../../../../helpers';
import { IMPORT_STATUS } from '../../constants/import-status';
import { PermissionsService } from '../../../../../permissions/permissions.service';
import { Router } from '@angular/router';
import { AppUrls } from '../../../../../app-urls';
import { HandleResponseAction } from 'app/ngrx/actions/root.action';
import { Action } from '../../../../../ngrx/actions/unsafe-action';
import { ToastrService } from 'ngx-toastr';

const catcher = <T>(err, caught: Observable<T>): Observable<T> => {
  console.error(err);
  return observableEmpty();
};

@Injectable()
export class ActiveImportEffects {
  /**
   * this mean, that failed import will be canceled if user isn't at import page
   * @type {BehaviorSubject}
   */
  public autoCancel$ = new BehaviorSubject(true);
  private canceling$ = new BehaviorSubject(false);

  @Effect()
  importAutoCanceler$ = this.autoCancel$.pipe(
    switchMap(
      autoCancelAllowed =>
        autoCancelAllowed ? this._store.pipe(pluck(ACTIVE_IMPORT, 'failed'),distinctUntilChanged(),) : observableOf(false)
    ),
    filter(isPresent),
    mapTo(new ActiveImportCancelAction()),);

  @Effect()
  init$ = this._authService.userInActiveCompany$.pipe(
    filter(isPresent),
    switchMap(() => this._permissions.canRunImport()),
    filter(isPresent),
    switchMap(
      loggedId =>
        loggedId ? observableOf(null) : this.actions$.ofType(ActiveImportActionTypes.INITIAL).pipe(startWith(null))
    ),
    switchMap(() => this._atlazApi.get('import')),
    switchMap((resp: JsonApiModelsResponse<any>) =>
      observableFrom([
        new ActiveImportDataChangedAction({
          getTrelloTokenUrl: resp.meta.getTrelloTokenUrl,
          status: IMPORT_STATUS.NO_ACTIVE
        }),
        new GetCompleteAction(jsonApiToEntityState(resp))
      ])
    ),
    catchError(catcher),);

  @Effect()
  bindingActive$ = this.actions$
    .ofType(ActiveImportActionTypes.INITIAL).pipe(
    startWith(null),
    switchMapTo(
      this._store.pipe(
        pluck(IMPORT_PL),
        distinctUntilChanged(),
        filter(isPresent),
        map(EntityState.fromState),
        map(xs => xs.filter((imp: Import) => !(imp.canceled || imp.confirmed))),
        map(xs => (xs.ids[0] ? xs.get(xs.ids[0]) : null)),)
    ),
    skip(1),
    distinctUntilChanged(),
    combineLatest(this.canceling$, (imp: Import, canceling: boolean) => {
      return new ActiveImportDataChangedAction({
        import: imp,
        selectingProjects: imp && imp.projects && !imp.inProgress && !imp.failed && !imp.hasDataToImport,
        inProgress: imp && imp.hasDataToImport && !imp.failed,
        preFetching: imp && (imp.prepare && (!imp.hasDataToImport || !imp.projects)) && !imp.failed,
        failed: imp && imp.failed,
        type: imp ? imp.type : '',
        status: canceling ? IMPORT_STATUS.LOADING : imp ? IMPORT_STATUS.ACTIVE : IMPORT_STATUS.NO_ACTIVE
      });
    }),
    catchError(catcher),);

  @Effect({ dispatch: false })
  compliteImport$ = this.actions$
    .ofType(ActiveImportActionTypes.INITIAL).pipe(
    startWith(null),
    switchMapTo(this._store.pipe(pluck(ACTIVE_IMPORT, 'import'),distinctUntilChanged(),)),
    filter(isPresent),
    filter((imp: Import) => imp.complete && imp.type === 'jira' && !imp.confirmed),
    switchMap(activeImport => {
      return this._atlazApi
        .get(USER_PL, { importId: activeImport.id, getAllUsers: 1 }).pipe(
        switchMap((resp: JsonApiModelsResponse<any>) => {
          const noEmptyFromApi = resp.data && resp.data.length > 0;
          if (noEmptyFromApi) {
            this._store.dispatch(new HandleResponseAction(resp));
            this._router.navigate(AppUrls.getUrlJiraInviteMembers());
          } else {
            this._store.dispatch(new ActiveImportConfirmAction({ id: activeImport.id }));
          }
          return resp.data;
        }));
    }),);

  @Effect({ dispatch: false })
  confirmImport$ = this.actions$
    .ofType(ActiveImportActionTypes.CONFIRM_IMPORT).pipe(
    switchMap(({ type, payload: imp }: Action) => {
      return this._atlazApi.patch(['import', imp.id], { action: 'confirm' });
    }),
    catchError(catcher),
    map(res => this._router.navigate(AppUrls.getUrlOverview())),);

  @Effect({ dispatch: false })
  cancelingEffect$ = this.actions$
    .ofType(ActiveImportActionTypes.CANCEL_IMPORT).pipe(
    switchMapTo(this._store.pipe(pluck(ACTIVE_IMPORT, 'import'),take(1),)),
    filter(isPresent),
    map(imp => {
      this.canceling$.next(true);
      this._atlazApi.patch(['import', imp.id, { importType: imp.type }], { action: 'cancel' }).subscribe(
        (resp: JsonApiModelsResponse<any>) => {
          this._store.dispatch(new GetCompleteAction(jsonApiToEntityState(resp)));
          this.canceling$.next(false);
        },
        () => {
          this._toastr.error('Canceling import has failed. If this replays, please call support.');
          this.canceling$.next(false);
        }
      );
    }),);

  constructor(
    private actions$: Actions,
    private _store: Store<AppState>,
    private _toastr: ToastrService,
    private _authService: AuthService,
    private _permissions: PermissionsService,
    private _atlazApi: AtlazApiV2Service,
    private _router: Router
  ) {}
}
