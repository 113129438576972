import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Directive({
  selector: '[scrollToElement]'
})
export class ScrollToElementDirective implements AfterViewInit {
  @Input() scrollToElement: string | number;

  private urlFragment: string;

  constructor(private _eref: ElementRef, private _route: ActivatedRoute) {}

  ngAfterViewInit() {
    this.urlFragment = this._route.snapshot.fragment;

    if (typeof this.scrollToElement === 'number') {
      this.scrollToElement = this.scrollToElement.toString();
    }

    if (this.scrollToElement === this.urlFragment) {
      setTimeout(_ => {
        this._eref.nativeElement.scrollIntoView();
      }, 0);
    }
  }
}
