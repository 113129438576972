
import {from as observableFrom,  Observable } from 'rxjs';

import {map, takeWhile, pluck, debounceTime, tap, switchMap, catchError, distinctUntilChanged, filter, mergeMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import { HttpQueryParam } from '../../interfaces';
import { USER_PL } from '../../constants';

import { HandleResponseAction, NoopAction } from '../actions/root.action';
import {
  UserActionTypes,
  UserDeleteCompleteAction,
  UserGetCompleteAction,
  UserGetIndexAction
} from '../actions/user.actions';
import { defaultErrorHandler } from './root.effect';
import { User } from '../../interfaces/user';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { AppState } from '../state/app-state';
import { Store } from '@ngrx/store';
import { USER_COMPANY_PL } from '../../constants/entity';
import { toDashedFromCamelCase } from '../../../helpers/string';
import { CompanyService } from '../../shared/services/app/company.service';
import { AuthService } from '../../shared/services/app/auth.service';
import { isPresent } from '../../../helpers/object';
import * as fromLodadedData from '../../loaded-data/store/loaded-data.reducer';
import * as loadedData from '../../loaded-data/store/loaded-data.actions';
import { PaginationLoaderService } from '../../shared/services/paginataion-loader/paginataion-loader.service';

@Injectable()
export class UserEffects {
  @Effect()
  getBoards$ = this._authService.isLoggedIn$.pipe(
    distinctUntilChanged(),
    filter(isPresent),
    map(
      _ =>
        new UserGetIndexAction({
          deleted: 0,
          expand: USER_COMPANY_PL
        })
    ),);

  @Effect()
  getUsers$ = this.actions$
    .ofType(UserActionTypes.GET).pipe(
    switchMap(({ type, payload }: { type: string; payload: HttpQueryParam }) => {
      return this._atlazApiV2
        .get(USER_PL, payload).pipe(
        map(response => {
          return observableFrom([new UserGetCompleteAction(), new HandleResponseAction(response)]);
        }),
        catchError(defaultErrorHandler(type, payload)),);
    }));
  @Effect({ dispatch: false })
  getUsersGet$ = this.actions$
    .ofType(UserActionTypes.GET_INDEX).pipe(
    pluck('payload'),
    tap((payload: HttpQueryParam) => {
      const paginatorService = new PaginationLoaderService(this._atlazApiV2, this._store);

      paginatorService.controller = USER_PL;
      paginatorService.limit = 500;
      paginatorService.queryParams = payload;
      paginatorService.loadAll = true;
      paginatorService.loadMore();
      if (payload.deleted) {
        paginatorService.hasMore$.pipe(
          takeWhile(isPresent))
          .subscribe(() => this._store.dispatch(new loadedData.MarkAsLoadedDeletedUsers()));
      }
    }),);

  @Effect()
  editUser$ = this.actions$
    .ofType(UserActionTypes.EDIT).pipe(
    mergeMap(({ type, payload: userPart }: { type: string; payload: Partial<User> }) => {
      const userId = userPart.id;

      return this._atlazApiV2
        .patch(USER_PL, userPart).pipe(
        map(resp => new HandleResponseAction(resp)),
        catchError(defaultErrorHandler(type, userPart)),);
    }));

  @Effect()
  deleteUser$ = this.actions$
    .ofType(UserActionTypes.DELETE).pipe(
    switchMap(({ type, payload: user }: { type: string; payload: User }) => {
      return this._atlazApiV2
        .deleteRequest([toDashedFromCamelCase(USER_COMPANY_PL), user.userCompanyId]).pipe(
        map(_ => new UserDeleteCompleteAction()),
        catchError(defaultErrorHandler(type, user)),);
    }));

  @Effect()
  getDeletedUsers$ = this.actions$
    .ofType(UserActionTypes.GET_DELETED_USERS).pipe(
    // do it async for better performance
    debounceTime(0),
    switchMap(({ type, payload }: { type: string; payload: HttpQueryParam }) =>
      this._store
        .select(fromLodadedData.wasLoadedDeletedUsers).pipe(
        map(loaded => (loaded ? new NoopAction() : new UserGetIndexAction({ ...payload, deleted: 1 }))))
    ),);

  constructor(
    private actions$: Actions,
    private _store: Store<AppState>,
    private _company: CompanyService,
    private _authService: AuthService,
    private _atlazApiV2: AtlazApiV2Service
  ) {}
}
