import { Action } from './unsafe-action';
import { labelAction } from '../functions/action';
import { HttpQueryParam, PartOfEntity, TaskAttachment } from '../../interfaces';
import { TASKS_ATTACHMENT, TASKS_ATTACHMENT_PL } from '../../constants';

export const TaskAttachmentsActionTypes = {
  GET: labelAction(TASKS_ATTACHMENT, 'GET'),
  GET_COMPLETE: labelAction(TASKS_ATTACHMENT, 'GET_COMPLETE'),
  ADD: labelAction(TASKS_ATTACHMENT, 'ADD'),
  ADD_COMPLETE: labelAction(TASKS_ATTACHMENT, 'ADD_COMPLETE'),
  ADD_FROM_COMMENT_COMPLETE: labelAction(TASKS_ATTACHMENT, 'ADD_FROM_COMMENT_COMPLETE'),
  DELETE: labelAction(TASKS_ATTACHMENT, 'DELETE'),
  DELETE_COMPLETE: labelAction(TASKS_ATTACHMENT, 'DELETE_COMPLETE'),
  MAKE_COVER: labelAction(TASKS_ATTACHMENT_PL, 'MAKE_COVER')
};

export class TaskAttachmentsGetAction implements Action {
  type = TaskAttachmentsActionTypes.GET;

  constructor(public payload?: HttpQueryParam) {}
}

export class TaskAttachmentsGetCompleteAction implements Action {
  type = TaskAttachmentsActionTypes.GET_COMPLETE;

  constructor(public payload: TaskAttachment[]) {}
}

export class TaskAttachmentsDeleteAction implements Action {
  type = TaskAttachmentsActionTypes.DELETE;

  constructor(public payload: TaskAttachment) {}
}

export class TaskAttachmentsDeleteCompleteAction implements Action {
  type = TaskAttachmentsActionTypes.DELETE_COMPLETE;

  constructor(public payload?: any) {}
}

export class TaskAttachmentsAddAction implements Action {
  type = TaskAttachmentsActionTypes.ADD;

  constructor(public payload: { file: PartOfEntity; fromComment?: string }) {}
}

export class TaskAttachmentsAddCompleteAction implements Action {
  type = TaskAttachmentsActionTypes.ADD_COMPLETE;

  constructor(public payload: TaskAttachment) {}
}

export interface AddFromCommentPayload {
  path: string;
  name: string;
  commentKey: string;
  fileType: string;
}

export class TaskAttachmentsAddFromCommentCompleteAction implements Action {
  type = TaskAttachmentsActionTypes.ADD_FROM_COMMENT_COMPLETE;

  constructor(public payload: AddFromCommentPayload) {}
}

export class TaskAttachmentMakeCoverAction implements Action {
  type = TaskAttachmentsActionTypes.MAKE_COVER;

  constructor(public payload: any) {}
}

export type TaskAttachmentsActionTypes =
  | TaskAttachmentsGetAction
  | TaskAttachmentsGetCompleteAction
  | TaskAttachmentsDeleteAction
  | TaskAttachmentsDeleteCompleteAction
  | TaskAttachmentsAddAction
  | TaskAttachmentsAddCompleteAction
  | TaskAttachmentsAddFromCommentCompleteAction
  | TaskAttachmentMakeCoverAction;
