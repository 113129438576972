import { AppState, Entities, ESInterface } from '../../ngrx/state/app-state';
import { createSelector, Selector, defaultMemoize } from 'reselect';

/**
 *
 * @description
 * Creates common selectors such as
 * getState: state From Store
 * getEntities: entities from EsInterface. ex. Entities<Task>
 * getIds: ids from EsInterface. ex. number[]
 * getAll: getArray with all entities from EsInterface. ex. Task[]
 *
 */

type Id = string | number;

function propEntities<K, E>(state: K): Entities<E>;
function propEntities(state) {
  return state.entities;
}

function propIds<S>(state: S): Id[];
function propIds(state) {
  return state.ids;
}

export function getById<T>(entities: Entities<T>): (id: Id) => T;
export function getById(entities) {
  return id => entities[id];
}

export function getByIds<E>(ids: Id[], entities: Entities<E>): E[];
export function getByIds(ids, entities) {
  return ids.map(getById(entities));
}
export function isEmpty(state: Id[]): boolean {
  return state.length === 0;
}

export interface CommonSelectors<E, U extends ESInterface<E> = ESInterface<E>> {
  getState: Selector<AppState, U>;
  getEntities: Selector<AppState, Entities<E>>;
  getIds: Selector<AppState, Id[]>;
  getAll: Selector<AppState, E[]>;
  isEmpty: Selector<AppState, boolean>;
}

export function createCommonSelectors<E, U extends ESInterface<E> = ESInterface<E>>(stateKey): CommonSelectors<E, U> {
  const getState = defaultMemoize((store: AppState) => <U>store[stateKey]);
  const getEntities = createSelector(getState, state => propEntities<U, E>(state));

  const getIds = createSelector(getState, propIds);

  const getAll = createSelector(getIds, getEntities, (ids, entities) => getByIds<E>(ids, entities));
  const getIsEmpty = createSelector(getIds, isEmpty);

  return {
    getState: getState,
    getEntities: getEntities,
    getIds: getIds,
    getAll: getAll,
    isEmpty: getIsEmpty
  };
}
