<page-with-permissions-wrapper>
<div class="chart-container">
  <div class="chart_header">
    <div class="flex_wrapper">
      <div  class="chart_title" *ngIf="(board$ | async)">
        <div class="chart_back" [routerLink]="appUrls.getUrlBoard(boardId$ | async)"></div>
        <div class="chart_name"><span>{{(board$ | async)?.sprintName}}</span> / Burndown Chart <mode-view-picker [(mode)]="mode" [estimatedBy]="(board$ | async).estimatedBy" [class.hidden]="hidePicker$ | async"></mode-view-picker></div>
      </div>
      <div class="legend_link" [ngClass]="{'hovered': isShowQuestionPopup}" (click)="onToggleQuestionPopup()">
        <span class="icon_quote"></span>
        How to Read this Report?
        <span class="icon_expand" [ngClass]="{'expanded': isShowQuestionPopup}"></span>
      </div>
    </div>
    <div class="legend_body" [ngClass]="{'expanded': isShowQuestionPopup}">
      <div class="info_block">
        <div class="image green_line"></div>
        <h4>Remaining effort</h4>
        <p>This line represents the actual amount of remaining estimates.</p>
      </div>

      <div class="info_block">
        <div class="image gray_line"></div>
        <h4>Ideal burndown</h4>
        <p>A line from the total sum of all tasks’ estimates to the zero estimates<br>
          at the end time of sprint.</p>
      </div>

      <div class="info_block">
        <div class="image dot"></div>
        <h4>Activity</h4>
        <p>A certain action performed on board</p>
      </div>
    </div>
  </div>
  <burndown-chart-wrapper
    *ngIf="(board$ | async)"
    [boardId]="boardId$ | async"
    [mode]="mode"
  ></burndown-chart-wrapper>
</div>
</page-with-permissions-wrapper>
