import { share } from '../functions/util';
import {
  getEntityByIdSelector,
  getEntitySelector,
} from '../functions/selectors';
import { COMPANY_DOMAINS_PL } from '../../constants';
import { compose } from '@ngrx/store';
import { CompanyDomain } from "../../interfaces/company-domain";
import { ESInterface } from "../state";
import { CompanyDomainActions, CompanyDomainTypes } from "../actions/company-domain.actions";
import { editEntityState } from "../functions/reducer";

const initialState: ESInterface<CompanyDomain> = {
  ids: [],
  entities: {},
  selectedEntityId: null
};

export function reducer(state = initialState, action : CompanyDomainActions): ESInterface<CompanyDomain> {
  switch (action.type) {
    case CompanyDomainTypes.EDIT: {
      return editEntityState(state, action.payload);
    }
    default:
      return state;
  }
}

export const getCompanyDomainById = id => share(compose(getEntityByIdSelector<CompanyDomain>(id), getEntitySelector(COMPANY_DOMAINS_PL)));
