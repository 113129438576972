
import {take, debounceTime, filter} from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { UsersCacheService } from '@atlaz/core/services/users-cache.service';
import { FormGroup } from '@angular/forms';
import { isPresent } from '../../../helpers';
import { Subscription } from 'rxjs';

interface Config {
  allowedFields?: string[];
  ignoredFields?: string[];
  autoSave?: boolean;
  debounceTime?: number;
}

const defaultConfig: Config = {
  allowedFields: [],
  ignoredFields: [],
  autoSave: true,
  debounceTime: 350
};

@Injectable()
export class UnsavedFormChangesService implements OnDestroy {
  private form: FormGroup;
  private key: string;
  private config = defaultConfig;
  private subs: Subscription;

  constructor(private _usersCacheService: UsersCacheService) {}

  init(form: FormGroup, key, config: Config = defaultConfig) {
    this.form = form;
    this.key = key;
    this.config = { ...defaultConfig, ...config };

    this._usersCacheService
      .get(key).pipe(
      take(1),
      filter(isPresent),)
      .subscribe(cache => {
        Object.keys(cache).forEach(cacheKey => {
          const allowed = this.config.allowedFields;
          const ignored = this.config.ignoredFields;

          const isSetAllowedFields = allowed.length > 0;
          const isSetIgnoredFields = ignored.length > 0;

          const checkRules =
            (!isSetAllowedFields && !isSetIgnoredFields) ||
            (isSetAllowedFields && allowed.includes(cacheKey)) ||
            (isSetIgnoredFields && !ignored.includes(cacheKey));

          if (checkRules) {
            if (this.form.get(cacheKey)) {
              this.form.get(cacheKey).patchValue(cache[cacheKey]);
            } else {
              console.log('control not found', cacheKey);
            }
          }
        });
      });

    if (this.config.autoSave) {
      this.subs = this.form.valueChanges.pipe(debounceTime(this.config.debounceTime)).subscribe(values => this.save());
    }
  }

  save() {
    let values = this.form.value;

    if (this.config.allowedFields.length > 0) {
      values = this.config.allowedFields.reduce((obj, field) => {
        obj[field] = values[field];
        return obj;
      }, {});
    } else if (this.config.ignoredFields.length > 0) {
      values = this.config.ignoredFields.reduce((obj, field) => {
        delete obj[field];
        return obj;
      }, {});
    }

    this._usersCacheService.set(this.key, values);
  }

  flush() {
    this._usersCacheService.clear(this.key);
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }
}
