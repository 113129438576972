<div *ngIf="hasNotifications" class="notification_icon"></div>
<div *ngIf="task.coverAttachmentId && task.covers" class="preview"><img [src]="task.covers.preview"></div>
<div class="label_tags" *ngIf="task.labelsIds || task.taskKey" draggable="false" [routerLink]="taskUrl">
  <span class="task_name">{{task.taskKey}}</span>
  <span class="label_tag" *ngFor="let label of taskLabels" [ngClass]="label.color" [title]="label.name"></span>
</div>
<p class="card_title">{{task.title}}</p>

<div class="info_block">
  <div class="indicator_block" *ngIf="task.dueDate > 0">
    <div class="duedate_container" [ngClass]="{'overdone': isTaskOverdone, 'near_overdone': isTaskCloseToOverdone}">
      <div class="time"></div>
      <div class="date">{{task.dueDate | toDate:'short'}}</div>
    </div>
  </div>

  <div class="indicator_block" *ngIf="isSubscribe">
    <div class="subscribe"></div>
  </div>

  <div class="indicator_block" *ngIf="task.description">
    <div class="descript"></div>
  </div>

  <div class="indicator_block" *ngIf="task.commentsCount > 0">
    <div class="comments"></div>
    <div class="num_comments">{{task.commentsCount}}</div>
  </div>

  <div class="indicator_block" *ngIf="task.attachmentsCount > 0">
    <div class="attachments"></div>
    <div class="attachments_num">{{task.attachmentsCount}}</div>
  </div>

  <div class="indicator_block" *ngIf="task.checklistsCount && task.checklistsItemsCount">
    <div class="checklists"></div>
    <div class="num_checklists">
      {{task.completedChecklistsItemsCount}} / {{task.checklistsItemsCount}}
    </div>
  </div>

  <div class="indicator_block" *ngIf="task.estimate && showEstimates.isShowHoursEstimate">
    <div class="estimate">
      <div *ngIf="task.estimate >= task.loggedTime" class="est_icon"></div>
      <div *ngIf="task.estimate < task.loggedTime" class="est_icon_overdone"></div>
      <p>{{task.estimate | toHms}}</p>
    </div>
  </div>

  <div class="indicator_block" *ngIf="task.storyPoints && showEstimates.isShowPointsEstimate">
    <div class="story_points"><span></span>{{task.storyPoints}}</div>
  </div>

  <div *ngIf="task.cardTimes && board.showProps !== '00'" class="card_times">
    <span *ngIf="task.cardTimes.boards && board.showProps[0] === '1'">Time on board {{task.cardTimes.boards[task.board] | toAdhm}}</span>
    <span *ngIf="task.cardTimes.columns && board.showProps[1] === '1'">Time in column {{task.cardTimes.columns[task.column] | toAdhm}}</span>
  </div>
</div>

<div class="preview_users_block">
  <div class="person_avatar" *ngFor="let user of taskUsers">
    <a-avatar class="user_avatar" [user]="user"></a-avatar>
  </div>
</div>
<div class="score_container" *ngIf="!isScoringOff">
  <div class="advanced_score" *ngIf="!isBasicScoring;else basicScoring">
    {{score}}
  </div>
</div>
<a class="task_link" #taskLink draggable="false" [routerLink]="taskUrl"></a>
<div class="quick_actions" (click)="onOpenQuickActions($event)"></div>

<ng-template #basicScoring>
  <div class="value_block">
    {{board.backlogScoreYLabel}}
    <span>{{scoreYVisible}}</span>
  </div>
  <div class="divider"></div>
  <div class="efforts_block">
    {{board.backlogScoreXLabel}}
    <span>{{scoreXVisible}}</span>
  </div>
</ng-template>
