
import {distinctUntilChanged, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';

export const taskPopupCodes = {
  SHOW_NONE: 'taskShowNone',
  MEMBER: 'taskMember',
  PROJECT: 'taskProject',
  CHECKLIST: 'taskChecklist',
  ESTIMATE: 'taskEstimate',
  LOGWORK: 'taskLogwork',
  DUEDATE: 'taskDuedate',
  VERSION: 'taskVersion',
  COPY: 'taskCopy',
  PUSH: 'taskPush',
  MOVE: 'taskMove',
  ATTACHMENT: 'taskAttachment',
  LABELS: 'taskLabels',
  MORE: 'taskMore',
  SCORE: 'taskScore'
};

@Injectable()
export class TaskSidebarService {
  public relatedData: any;
  private state$: BehaviorSubject<string> = new BehaviorSubject<string>(taskPopupCodes.SHOW_NONE);

  getState(): BehaviorSubject<string> {
    return this.state$;
  }

  setState(state: string, relatedData?: any): BehaviorSubject<string> {
    this.state$.next(state);
    this.relatedData = relatedData;
    return this.state$;
  }

  isActive(stateToCheck): Observable<boolean> {
    return this.state$.pipe(map(state => state === stateToCheck),distinctUntilChanged(),);
  }
}
