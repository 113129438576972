import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

interface MultiselectItem {
  id: number | string;
  label: string;
}

@Component({
  selector: 'a-multiselect',
  templateUrl: './a-multiselect.component.html',
  styleUrls: ['./a-multiselect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AMultiselectComponent),
      multi: true
    }
  ]
})
export class AMultiselectComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() items = [];
  @Input() selectAllOnInit = true;
  @Input() searchable = false;
  @Input() selectedOnTop = false;
  @Input() placeholderText = '';
  @ViewChild('inputTag') inputEl: ElementRef;
  @ViewChild('ulEl') ulEl: ElementRef;
  @ViewChild('pseudoSelectEl') pseudoSelectEl: ElementRef;

  public selectedItemsIds: Array<number | string> = [];
  public selected: MultiselectItem = { id: undefined, label: undefined };
  public selectedItemsLabels = this.placeholderText;
  public isMultiselectOpened$ = new BehaviorSubject(false);
  public possibleItems$ = new BehaviorSubject([]);
  public filterValue = '';
  public itemsOrder = {};
  public highlightedItemIndex = -2;
  private propagateChange = (_: any) => {};
  private propagateTouch = () => {};

  constructor() {}

  private setValue(selectedItemsIds: Array<number | string>, emitChanges = true) {
    if (selectedItemsIds) {
      this.selectedItemsIds = selectedItemsIds;
      this.selected = this.selectedItemsIds.reduce(
        (acc, id) => {
          acc[id] = true;
          return acc;
        },
        { id: undefined, label: undefined }
      );
    }
    this.selectedItemsLabels =
      this.selectedItemsIds.length === this.items.length
        ? 'All'
        : this.items
            .filter(item => this.selected[item.id])
            .map(item => item.label)
            .join(', ') || this.placeholderText;
    if (emitChanges) {
      this.propagateChange(this.selectedItemsIds);
      this.propagateTouch();
    }
  }

  public writeValue(selectedItemsIds: string | Array<number>) {
    if (Array.isArray(selectedItemsIds)) {
      this.selectedItemsIds = selectedItemsIds;
    } else {
      this.selectedItemsIds = selectedItemsIds ? selectedItemsIds.split(',') : [];
    }
    this.setValue(this.selectedItemsIds);
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn) {
    this.propagateTouch = fn;
  }

  ngOnInit() {
    if (this.selectAllOnInit) {
      this.onSelectAll();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isAllItemsShouldBeReselected(changes)) {
      this.setValue(changes.items.currentValue.map(xs => xs.id));
    }
    if (changes.items) {
      const sortedItems = this.selectedOnTop
        ? this.sortItemsAfterOutsideChanges(changes.items.currentValue)
        : changes.items.currentValue;
      this.possibleItems$.next(sortedItems || []);
    }
  }

  onToggleDropdown() {
    this.propagateTouch();
    const nextValue = !this.isMultiselectOpened$.getValue();
    if (nextValue) {
      const sortedItems = this.selectedOnTop ? this.initialSort() : this.items;
      this.possibleItems$.next(sortedItems);
    } else {
      this.highlightedItemIndex = -2;
    }
    this.isMultiselectOpened$.next(nextValue);
  }

  onSelectItem(item: MultiselectItem, resetHighlight = false) {
    const newValue = this.selected[item.id]
      ? this.selectedItemsIds.filter(id => id !== item.id)
      : [...this.selectedItemsIds, item.id];
    this.setValue(newValue);
    if (this.searchable) {
      this.inputEl.nativeElement.focus();
    }
    if (resetHighlight) {
      this.highlightedItemIndex = this.filterValue ? -1 : -2;
    }
  }

  onSelectAll() {
    const newValue = this.selectedItemsIds.length !== this.items.length ? this.items.map(xs => xs.id) : [];
    this.setValue(newValue);
  }

  isAllItemsShouldBeReselected(changes: SimpleChanges) {
    return (
      changes.items &&
      !changes.items.firstChange &&
      changes.items.currentValue.length !== changes.items.previousValue.length &&
      this.selectedItemsIds.length === changes.items.previousValue.length
    );
  }

  onKeyUp(event) {
    event.preventDefault();
    event.stopPropagation();
    switch (event.key) {
      case 'ArrowRight':
      case 'ArrowLeft':
      case 'ArrowDown':
      case 'ArrowUp':
      case 'Shift':
      case 'Enter':
      case 'Tab':
      case ' ':
        break;
      case 'Escape':
        this.onChangeFilter('');
        this.inputEl.nativeElement.value = '';
        this.onToggleDropdown();
        break;
      default:
        this.onChangeFilter(event.target.value);
    }
  }

  onKeyPress(event) {
    if (event.key === ' ') {
      if (this.highlightedItemIndex > -1) {
        event.preventDefault();
      } else if (this.highlightedItemIndex === -1 && !this.filterValue) {
        event.preventDefault();
      }
    }
  }

  onKeyDown(event) {
    const maxIndex = this.possibleItems$.getValue().length - 1;
    if (maxIndex > -1) {
      switch (event.key) {
        case 'Enter': // Clear and close popup
          this.filterValue = '';
          this.onToggleDropdown();
          break;
        case 'ArrowDown': // Select value below in the list
          if (this.highlightedItemIndex < maxIndex) {
            this.highlightedItemIndex++;
            this.arrowHandlerHelper();
          }
          break;
        case 'ArrowUp': // Select value higher in the list
          if (this.highlightedItemIndex > +this.filterValue - 1) {
            this.highlightedItemIndex--;
            this.arrowHandlerHelper(true);
          } else if (this.highlightedItemIndex === +this.filterValue - 1) {
            this.highlightedItemIndex--;
            this.ulEl.nativeElement.parentNode.scrollTop = 0;
          }
          break;
        case ' ':
          if (this.highlightedItemIndex > -1) {
            event.preventDefault();
            this.onSelectItem(this.possibleItems$.getValue()[this.highlightedItemIndex]);
          } else if (this.highlightedItemIndex === -1 && !this.filterValue) {
            event.preventDefault();
            this.onSelectAll();
          }
          break;
        default:
          break;
      }
    }
  }

  sortItemsAfterOutsideChanges(items) {
    items.sort((a, b) => {
      const oneHasOrder = +this.itemsOrder[b.id] - +this.itemsOrder[a.id];
      if (oneHasOrder !== 0) {
        return oneHasOrder;
      } else {
        return a.label.localeCompare(b.label);
      }
    });
  }

  initialSort() {
    const result = this.items.sort((a, b) => {
      const oneSelected = +this.selectedItemsIds.includes(b.id) - +this.selectedItemsIds.includes(a.id);
      if (oneSelected !== 0) {
        return oneSelected;
      } else {
        return a.label.localeCompare(b.label);
      }
    });
    this.itemsOrder = result.reduce((item, acc, i) => (acc[item.id] = i), {});
    return result;
  }

  private onChangeFilter(newValue: string) {
    this.filterValue = newValue;
    this.highlightedItemIndex = this.filterValue ? -1 : -2;
    this.possibleItems$.next(
      this.items.filter(item => item.label.toUpperCase().indexOf(this.filterValue.toUpperCase()) > -1)
    );
  }

  arrowHandlerHelper(isUp?: boolean) {
    const wrapper = this.ulEl.nativeElement;
    const li = this.ulEl.nativeElement.children[this.highlightedItemIndex + +!this.filterValue];
    if (isUp) {
      if (wrapper.scrollTop > li.offsetTop) {
        wrapper.scrollTop -= li.offsetHeight;
      }
    } else {
      if (wrapper.scrollTop + wrapper.clientHeight < li.offsetTop + li.offsetHeight) {
        wrapper.scrollTop += li.offsetHeight;
      }
    }
  }
}
