<div class="row_content">
  <shared-smart-task-link [task]="taskId" *ngIf="!externalLink;else externalLinkTemplate"></shared-smart-task-link>

  <div a-context-controll
       orientation="vertical"
       class="task_link_content_menu"
       [menuSlim]="true"
       [menuRight]="true"
       [menuTop]="true"
       [isMenuVisible]="isContentMenuVisible"
       (click)="onToggleContentMenu()"
       *ngIf="editPermissions"
  >
    <p a-context-menu-item (click)="onToggleDeletePopup()" class="delete_link">Delete Task Link</p>
  </div>
</div>

<div a-context-popup *ngIf="isDeletePopupVisible"
     [title]="'Delete Task Link'"
     (close)="onToggleDeletePopup()"
>
  <task-link-delete-form
    [id]="taskLinkId"
    (cancel)="onToggleDeletePopup()"
    (success)="onToggleDeletePopup()"
  ></task-link-delete-form>
</div>

<ng-template #externalLinkTemplate>
  <a target="_blank" [class]="'external-link ' + externalLink.type" [href]="externalLink.externalLink">{{externalLink.externalTitle + ' - ' + externalLink.externalStatus}}</a>
</ng-template>
