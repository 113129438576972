<ul>
  <li (click)="onSelectType(boardType.kanban)" [class.selected]="currentValue === boardType.kanban">
    <div class="icon">
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" stroke-width="1" fill="white" fill-rule="evenodd">
          <path clip-rule="evenodd" d="M8 9C8 8.44772 8.44772 8 9 8H13C13.5523 8 14 8.44772 14 9V19C14 19.5523 13.5523 20 13 20H9C8.44772 20 8 19.5523 8 19V9ZM15 9C15 8.44772 15.4477 8 16 8H20C20.5523 8 21 8.44772 21 9V27C21 27.5523 20.5523 28 20 28H16C15.4477 28 15 27.5523 15 27V9ZM23 8C22.4477 8 22 8.44772 22 9V15C22 15.5523 22.4477 16 23 16H27C27.5523 16 28 15.5523 28 15V9C28 8.44772 27.5523 8 27 8H23Z"></path>
        </g>
      </svg>

    </div>
    <p class="type_name">Kanban</p>
    <p class="type_desc">Organize your work like sticky notes on a board.</p>
  </li>
  <li (click)="onSelectType(boardType.list)" [class.selected]="currentValue === boardType.list">
    <div class="icon">
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill="white" fill-rule="evenodd" clip-rule="evenodd" d="M10.8571 19.4V16.6H8V19.4H10.8571ZM10.8571 25V22.2H8V25H10.8571ZM10.8571 13.8V11H8V13.8H10.8571ZM28 19.4V16.6H12.2857V19.4H28ZM28 25V22.2H12.2857V25H28ZM12.2857 13.8H28V11H12.2857V13.8Z"></path>
      </svg>
    </div>
    <p class="type_name">List</p>
    <p class="type_desc">Simply organize your tasks in a list.</p>
  </li>
  <li (click)="onSelectType(boardType.roadmap)" [class.selected]="currentValue === boardType.roadmap">
    <div class="icon">
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" fill="currentColor" fill-rule="evenodd">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8 9C8 8.44772 8.44772 8 9 8H19C19.5523 8 20 8.44772 20 9V13C20 13.5523 19.5523 14 19 14H9C8.44772 14 8 13.5523 8 13V9ZM16 16C16 15.4477 16.4477 15 17 15H27C27.5523 15 28 15.4477 28 16V20C28 20.5523 27.5523 21 27 21H17C16.4477 21 16 20.5523 16 20V16ZM13 22C12.4477 22 12 22.4477 12 23V27C12 27.5523 12.4477 28 13 28H23C23.5523 28 24 27.5523 24 27V23C24 22.4477 23.5523 22 23 22H13Z" fill="white"></path>
        </g>
      </svg>
    </div>
    <p class="type_name">Timeline</p>
    <p class="type_desc">Schedule your tasks and visualize the required steps.</p>
  </li>
  <li *ngIf="isSprintAvailable" (click)="onSelectType(boardType.sprint)" [class.selected]="currentValue === boardType.sprint">
    <div class="icon left1">
      <svg width="36" height="36" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" fill="white" fill-rule="evenodd">
          <path d="M27.037037,27.4444444 L20.8148148,27.4444444 L20.8148148,27.3454269 C22.3902619,26.7327527 23.7762206,25.7407062 24.8604967,24.4814815 L27.037037,24.4814815 L27.037037,23 L30,25.962963 L27.037037,28.9259259 L27.037037,27.4444444 Z M17.8518519,8.21325159 C22.8240353,8.59149224 26.7407407,12.7457557 26.7407407,17.8148148 C26.7407407,23.0836417 22.5092436,27.3641494 17.2592593,27.443328 L6.99980171,27.4445448 C6.44751696,27.4446012 5.99975599,26.9969317 5.99969958,26.4446469 C5.99969958,26.4445906 5.99969957,26.4445342 5.99969958,26.4444779 L6,25.4814815 C6.00003696,24.9292229 6.44774139,24.4815484 7,24.4815484 L16.962963,24.4814815 C17.0122159,24.4809418 17.0616005,24.4814815 17.1111111,24.4814815 C20.7930095,24.4814815 23.7777778,21.4967132 23.7777778,17.8148148 C23.7777778,14.3832936 21.1851485,11.5573134 17.8518519,11.1888361 L17.8518519,12.9259259 L14.8888889,9.96296296 L17.8518519,7 L17.8518519,8.21325159 Z M9.6384077,23.8888889 C8.28988945,22.2318764 7.48148148,20.1177525 7.48148148,17.8148148 C7.48148148,12.9860161 11.0357024,8.98735007 15.6707825,8.29217849 L14,9.96296296 L15.4053927,11.3683556 C12.5498496,12.1219201 10.4444444,14.7224956 10.4444444,17.8148148 C10.4444444,20.5155415 12.0503808,22.8411817 14.359407,23.8888889 L9.6384077,23.8888889 Z"></path>
        </g>
      </svg>
    </div>
    <p class="type_name">Sprint</p>
    <p class="type_desc">Break-up your large projects into Sprints and track their progress.</p>
  </li>
</ul>
