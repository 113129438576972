import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, ESInterface } from '../../../ngrx/state/app-state';
import { getEntitySelector } from '../../../ngrx/functions/selectors';
import { VERSION_PL } from '../../../constants/entity';
import { BehaviorSubject } from 'rxjs';
import { Version } from '../../../interfaces/version';

@Component({
  selector: 'version-list-independent',
  templateUrl: './version-list-independent.component.html',
  styleUrls: ['./version-list.component.scss']
})
export class VersionListIndependentComponent implements OnInit, OnDestroy {
  @Input() selectedVersions: BehaviorSubject<number[]>;

  public versionsState: ESInterface<Version>;
  public subscription;

  constructor(private _store: Store<AppState>) {}

  get selectedVersions$() {
    return this.selectedVersions;
  }

  ngOnInit(): any {
    this.subscription = this._store.pipe((getEntitySelector(VERSION_PL))).subscribe((state: ESInterface<Version>) => {
      this.versionsState = state;
    });
  }

  ngOnDestroy(): any {
    this.subscription.unsubscribe();
  }

  isSelectedVersion(versionId) {
    this.selectedVersions$.getValue();
    return this.selectedVersions$.getValue().includes(versionId);
  }

  onSelectVersion(versionId) {
    let currentValue = this.selectedVersions$.getValue();
    if (currentValue.includes(versionId)) {
      this.selectedVersions$.next(currentValue.filter(existsVersion => existsVersion !== versionId));
    } else {
      currentValue = [...currentValue];
      currentValue.push(versionId);
      this.selectedVersions$.next(currentValue);
    }
  }
}
