<div class="pricing_faq" [style.background-color]="bgColor || ''">
  <h1 class="h1_like pricing_title">FAQ</h1>
  <div class="divider_title"></div>
  <div class="pricing_faq_list_wrap">
    <div class="pricing_faq_list">
      <div class="pricing_faq_item">
        <div class="pricing_faq_subtitle">How does Hygger's pricing work?</div>
        <p class="pricing_faq_text">
          The price of {{(basicPlan$ | async)?.title}} plan:<br>
          •&nbsp;&nbsp;Annual subscription: ${{(basicPlan$ | async)?.price.year}} / user (20% discount or 2 months for free).<br>
          •&nbsp;&nbsp;Monthly subscription: ${{(basicPlan$ | async)?.price.month}} / user.
        </p>
        <p class="pricing_faq_text">
          The price of {{(proPlan$ | async)?.title}} plan:<br>
          •&nbsp;&nbsp;Annual subscription: ${{(proPlan$ | async)?.price.year}} / user (20% discount or 2 months for free).<br>
          •&nbsp;&nbsp;Monthly subscription: ${{(proPlan$ | async)?.price.month}} / user.
        </p>
      </div>
      <div class="pricing_faq_item">
        <div class="pricing_faq_subtitle">Which members are included in subscription cost?</div>
        <p class="pricing_faq_text">
          You pay only for active users. Active user is a user who has confirmed their email after registration or invitation to a Company. Users, that were removed from a company, or unconfirmed ones are not included in the subscription cost.
        </p>
      </div>
      <div class="pricing_faq_item">
        <div class="pricing_faq_subtitle">How is the annual subscription cost calculated?</div>
        <p class="pricing_faq_text">
          You can save 20% with an annual membership, which means you will use Hygger for free during 2 months.
        </p>
      </div>
    </div>
    <div class="pricing_faq_list">
      <div class="pricing_faq_item">
        <div class="pricing_faq_subtitle">Can I try Hygger for free?</div>
        <p class="pricing_faq_text">
          You can use Hygger for free for the first 50 tasks after signing up. During that period you can fully explore all the capabilities and features of the platform.
        </p>
      </div>
      <div class="pricing_faq_item">
        <div class="pricing_faq_subtitle">How is the price for new users calculated?</div>
        <p class="pricing_faq_text">
          The price for a new member in a company will be charged proportionally for the remainder of the current billing period.
        </p>
      </div>
      <div class="pricing_faq_item">
        <div class="pricing_faq_subtitle">How do I cancel membership?</div>
        <p class="pricing_faq_text">
          You can cancel your membership in Hygger any time you want - just contact us. However, we won’t give refunds for cancellation or plan downgrades.
        </p>
      </div>
    </div>
  </div>
</div>
