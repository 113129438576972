import { Action } from '../actions/unsafe-action';
import { actionWrapper, runReducer } from '../functions/util';
import { AppState, COMPONENT, LEFT_MENU, OPENED_TASK, QUICK_FILTER, RIGHT_MENU } from '../state';
import { ComponentActionTypes, STICKY_LEFT_MENU, STICKY_LEFT_MENU_FEATURE } from '../actions/component.actions';
import { getFromLocalStorage } from '../../../helpers';
import { createSelector } from 'reselect';
import { getCurrentCompany } from './current-company.reducer';

export interface State {
  [componentName: string]: any;
}

export const initialState = {
  [LEFT_MENU]: {
    visible: false,
    listenClickout: true,
    sticky: +getFromLocalStorage(STICKY_LEFT_MENU_FEATURE)
  },
  [RIGHT_MENU]: {
    visible: false
  },
  [QUICK_FILTER]: {
    visible: false
  },
  [OPENED_TASK]: {
    listenClickout: true
  }
};

const reducerAction = {
  [ComponentActionTypes.SET_VISIBLE]: actionWrapper(setVisible),
  [ComponentActionTypes.LISTEN_CLICKOUT]: actionWrapper(setClickOut),
  [ComponentActionTypes.TOGGLE]: actionWrapper(toggleVisibility),
  [STICKY_LEFT_MENU]: actionWrapper(setSticky)
};

export function reducer(state = initialState, action: Action): State {
  return runReducer(reducerAction, state, action);
}

function setVisible(state: State, payload) {
  return Object.assign({}, state, { [payload.name]: setComponentVisibility(state[payload.name], payload.visible) });
}
function setClickOut(state: State, payload) {
  return Object.assign({}, state, {
    [payload.name]: setComponentClickOut(state[payload.name], payload.listenClickout)
  });
}

function toggleVisibility(state: State, payload) {
  return Object.assign({}, state, {
    [payload.name]: setComponentVisibility(state[payload.name], !state[payload.name].visible)
  });
}

function setSticky(state: State, payload) {
  const newLeftMenuState = { ...state[LEFT_MENU], visible: true, sticky: payload };
  return { ...state, [LEFT_MENU]: newLeftMenuState };
}

function setComponentVisibility(componentState, isVisible: boolean) {
  return Object.assign({}, componentState, { visible: isVisible });
}
function setComponentClickOut(componentState, clickOut: boolean) {
  return Object.assign({}, componentState, { listenClickout: clickOut });
}

export const isVisibleLeftMenu = (store: AppState) => {
  if (getCurrentCompany(store) && getCurrentCompany(store).isActive) {
    const leftMenu = store[COMPONENT][LEFT_MENU];
    return leftMenu.visible;
  }
  return false;
};

export const isStickyLeftMenu = (store: AppState) => {
  if (getCurrentCompany(store) && getCurrentCompany(store).isActive) {
    const leftMenu = store[COMPONENT][LEFT_MENU];
    return leftMenu.sticky;
  }
  return false;
};

export const getState = (store: AppState) => <State>store[COMPONENT];
const getOpenedTask = createSelector(getState, state => state[OPENED_TASK]);

export const isTaskClickOutListens = createSelector(getOpenedTask, state => state.listenClickout);
