<a-center-container>
  <div a-popup [title]="'Push All Tasks'" (close)="onClose()">
    <form-errors></form-errors>
    <label class="radio">
      <input type="radio" name='target' [(ngModel)]="target" value="NEW_BOARD">
      <span>Push into new Board</span>
    </label>
    <new-board-form [(form)]="form" *ngIf="target !== EXIST_ONE" [availableTypes]="availableTypes"></new-board-form>
    <label class="radio">
      <input type="radio" name='target' [(ngModel)]="target" value="EXIST_ONE">
      <span>Push into Existing One</span>
    </label>

    <push-all-tasks-exist-board-form   [(form)]="form"  *ngIf="target === EXIST_ONE"></push-all-tasks-exist-board-form>
    <div class="buttons_block">
      <button a-button class="btn_primary button" [pending]="_formService.isPending$ | async" (click)="onSubmit()">
        Push Tasks
      </button>
      <button a-button type="button" (click)="onClose()" class="btn_text">
        Cancel
      </button>
    </div>
  </div>
</a-center-container>
<overlay (click)="onClose()"></overlay>
