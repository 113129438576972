
import {combineLatest as observableCombineLatest,  BehaviorSubject ,  Observable ,  Subscription } from 'rxjs';

import {debounceTime} from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { difference } from '../../../../helpers/';
import { VERSION_PL } from '../../../constants/';
import { HandleResponseAction } from '../../../ngrx/actions/root.action';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state/';
import { Version } from '../../../interfaces/';
import { getAllVersions, getVersionEntities } from '../../../ngrx/reducers/version.reducer';
import { AtlazApiV2Service } from '../../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { JsonApiSingeModelResponse } from '../../../shared/services/app/web-socket/http-response';

@Component({
  selector: 'version-filter',
  templateUrl: './version-filter.component.html',
  styleUrls: ['./version-filter.component.scss']
})
export class VersionFilterComponent implements OnInit, OnDestroy {
  @Input() selectedProjects: BehaviorSubject<number[]>;
  @Input() selectedVersionsIds: BehaviorSubject<number[]>;

  public showAddPopUp: boolean;
  projectsSubscription: Subscription;

  lastSelectedProjects = [];

  loadedVersions: { [id: string]: boolean } = {};

  versions: Version[] = [];

  availableVersionIds$: Observable<number[]>;
  versions$: Observable<{ [id: number]: Version }>;

  constructor(protected _atlazApi: AtlazApiV2Service, private _store: Store<AppState>) {}

  get selectedProjects$() {
    return this.selectedProjects;
  }

  get selectedVersionsIds$() {
    return this.selectedVersionsIds;
  }

  ngOnInit(): any {
    this.versions$ = <Observable<{ [id: number]: Version }>>this._store.pipe((getVersionEntities));

    this.availableVersionIds$ = <Observable<number[]>>observableCombineLatest(
      this.selectedProjects$,
      this._store.pipe((getAllVersions)),
      (projectsIds: number[], versions: Version[]) =>
        versions.filter((version: Version) => projectsIds.includes(version.project)).map(version => version.id)
    );
    this.projectsSubscription = this.selectedProjects$.pipe(debounceTime(400)).subscribe(values => {
      const diff = difference(values, this.lastSelectedProjects);
      this.lastSelectedProjects = values;
      if (diff.length) {
        // load versions
        this.loadVersions(diff);
      }
    });
  }

  ngOnDestroy(): any {
    this.projectsSubscription.unsubscribe();
  }

  loadVersions(projectIds: number[]) {
    projectIds.forEach(projectId => {
      this.versionLoad(projectId);
    });
  }

  versionLoad(projectId) {
    if (this.loadedVersions[projectId]) {
      return;
    }
    this._atlazApi.get(VERSION_PL, { project: projectId }).subscribe((resp: JsonApiSingeModelResponse<any>) => {
      this.loadedVersions[projectId] = true;
      this._store.dispatch(new HandleResponseAction(resp));
    });
  }

  onSelect(value) {
    this.selectedVersionsIds$.next([...this.selectedVersionsIds$.getValue(), ...[value]]);
  }

  onRemove(userId) {
    this.selectedVersionsIds$.next(this.selectedVersionsIds$.getValue().filter(existsUser => existsUser !== userId));
  }
}
