import { combineLatest, map, switchMap, scan, filter, take, distinctUntilChanged } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { Notification } from '../../../interfaces';

import { AppState } from '../../../ngrx/state';
import { NOTIFICATION_PL, TASK } from '../../../constants';
import {
  MarkAsTypes,
  NotificationGetAction,
  NotificationMarkAsAction
} from '../../../ngrx/actions/notification.actions';
import { getEntitiesByFields } from '../../../ngrx/functions/selectors';
import { compareArrays } from '../../../../helpers';
import { CompanyService } from '../../../shared/services/app/company.service';
import { getUnseenUserNotificationsCount } from '../../../ngrx/reducers/notification.reducer';

const INBOX = 'Inbox';
const ARCHIVE = 'Archive';

@Component({
  selector: 'notifications-inbox',
  templateUrl: './notifications-inbox.component.html',
  styleUrls: ['./notifications-inbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsInboxComponent implements OnInit {
  public showArchived$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public notifications$: Observable<Notification[]>;
  public notificationsCount$: Observable<string>;
  public isInboxMenuVisible = false;
  public inboxFilterTitle = INBOX;
  public notificationsLimit = 10;
  public endOffset = false;
  public offset = 0;
  public archivedNotifications$: Observable<Notification[]>;
  public isArchiveSelectDropdownVisible = false;

  constructor(private _store: Store<AppState>, private _company: CompanyService) {}

  ngOnInit() {
    // if we use take(1) we can skip save subscription
    this.showArchived$.pipe(filter(archived => !!archived), take(1)).subscribe(_ => this.runGetNotificationAction(0));

    this.notifications$ = <Observable<Notification[]>>this.showArchived$.pipe(
      switchMap(archiveFlag => {
        return this._store.pipe(
          getEntitiesByFields(NOTIFICATION_PL, { archived: +archiveFlag, company: this._company.id }),
          distinctUntilChanged(compareArrays),
          scan((acc, curValue) => {
            this.endOffset = acc.length > 0 && acc.length === curValue.length;
            this.offset = curValue.length;

            return curValue;
          })
        );
      })
    );

    this.notificationsCount$ = this._store.pipe(
      getUnseenUserNotificationsCount,
      map(count => (count ? ' (' + (count > 99 ? 99 + '+' : count) + ')' : '')),
      combineLatest(this.showArchived$, (count, doNotShow) => (doNotShow ? '' : count))
    );

    this.initArchivedNotifications();
  }

  onArchiveTab() {
    this.inboxFilterTitle = ARCHIVE;
    this.showArchived$.next(true);
  }

  onInboxTab() {
    this.inboxFilterTitle = INBOX;
    this.showArchived$.next(false);
  }

  onToggleInboxMenu() {
    this.isInboxMenuVisible = !this.isInboxMenuVisible;
  }

  onLoadMore() {
    this.endOffset = true;
    this.runGetNotificationAction();
  }

  onOpenArchiveSelect() {
    this.isArchiveSelectDropdownVisible = !this.isArchiveSelectDropdownVisible;
  }

  onArchiveAllNotifications() {
    const notifIds = [];
    const sub = this.notifications$.pipe(filter(archived => !!archived)).subscribe(notifications =>
      notifications.map(notification => {
        notifIds.push(notification.id);
        if (notification.subNotifications) {
          notification.subNotifications.map(subNotification => notifIds.push(subNotification.id));
        }
      })
    );

    sub.unsubscribe();
    if (notifIds.length) {
      this._store.dispatch(new NotificationMarkAsAction({ markType: MarkAsTypes.archive, notificationIds: notifIds }));
    }
    this.isArchiveSelectDropdownVisible = false;
  }

  public runGetNotificationAction(offset = this.offset) {
    const data: any = {
      archived: +this.showArchived$.getValue(),
      sort: '-createdAt',
      expand: TASK
    };

    if (this.showArchived$.getValue()) {
      data.limit = this.notificationsLimit;
      data.offset = offset;
    }

    this._store.dispatch(new NotificationGetAction(data));
  }

  initArchivedNotifications() {
    this.archivedNotifications$ = <Observable<Notification[]>>this.notifications$.pipe(
      map(notifications => notifications.filter(notification => notification.archived))
    );
  }
}
