import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable ,  Subject } from 'rxjs';
import { AppState, runParametricalSelector } from '../../../../../ngrx/state/';
import * as fromIntegration from '../../../../core/store/integration.reducer';
import { GithubPullRequest } from '../../../models/git-pull-request';
import * as githubPullRequest from '../../../store/github-pull-request/github-pull-request.actions';
import * as fromGithubPullrRequest from '../../../store/github-pull-request/github-pull-request.reducer';
import * as fromAssocGithubRepo from '../../../store/github-associated-repository/github-associated-repository.reducer';
import { IntegrationNames } from '../../../../core/integraition-list';

@Component({
  selector: 'github-task-pull-request-list',
  templateUrl: './github-task-pull-request-list.component.html',
  styleUrls: ['./github-task-pull-request-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GithubTaskPullRequestListComponent implements OnInit, OnDestroy {
  @Input() taskId$: Observable<number>;

  public pullRequests$: Observable<GithubPullRequest[]>;
  public isInstalled$: Observable<boolean> = this._store.select(fromIntegration.getIsInstalledGithub);
  public asscocRepoList$ = this._store.select(fromAssocGithubRepo.getEntities);
  public name = IntegrationNames.Github;

  private destroy$ = new Subject();

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this.pullRequests$ = runParametricalSelector(
      this._store.select(fromGithubPullrRequest.getTaskPullRequests),
      this.taskId$
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  onRemove(item: GithubPullRequest) {
    this._store.dispatch(new githubPullRequest.DeleteTaskPullRequest({ id: item.id, task: item.task }));
  }
}
