import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boldMentionText'
})
export class BoldMentionTextPipe implements PipeTransform {
  transform(value) {
    const result = value.replace(/(\B@[a-z0-9_.-]+)/gi, ' <b>$1</b>');

    return result;
  }
}
