<!--suppress ALL -->
<div [ngStyle]="{'width.px': defaultWidth}" class="report-container" #chartContainer>
  <svg id="velocity-report" [attr.width]="width + margin.left + margin.right" [attr.height]="height + margin.top + margin.bottom">
    <svg:g class="g-x-axis"
           [attr.transform]="'translate(' + margin.left + ', ' + (+margin.top + +height) + ')'"></svg:g>
    <svg:g class="g-x-axis-label"
           [attr.transform]="'translate(' + (width/2) + ', ' + (height + margin.top + 40) + ')'">
      <text>Versions</text>
    </svg:g>
    <svg:g class="g-y-axis" [attr.transform]="'translate(' + margin.left + ', ' + margin.top + ')'"></svg:g>
    <svg:g class="g-y-axis-label" [attr.transform]="'translate(' + (margin.left - 40) + ', ' + (height / 2) + ')'">
      <text [attr.transform]="'rotate(-90)'">{{chartSettings.yLabel}}</text>
    </svg:g>

    <g *ngFor="let versionBar of (chartItemsScales$ | async)"
       (mouseover)="onHoverVersion(versionBar)"
       (mouseout)="onLeaveVersion()"
    >
      <rect class="version-bar"
            fill = "#999999"
            [attr.x]="versionBar.allX"
            [attr.y]="versionBar.allY"
            [attr.width]="versionBar.allWidth"
            [attr.height]="versionBar.allHeight"
      ></rect>
      <rect class="version-bar"
            fill = "#333333"
            [attr.x]="versionBar.doneX"
            [attr.y]="versionBar.doneY"
            [attr.width]="versionBar.doneWidth"
            [attr.height]="versionBar.doneHeight"
      ></rect>
    </g>
  </svg>
</div>
<div class="report-tooltip" *ngIf="tooltip.visible" [ngStyle]="{'top.px': tooltip.y, 'left.px': tooltip.x}">
  <p>
    Estimated:
    <span>{{tooltip.estimated}}</span>
  </p>
  <p>
    Completed:
    <span>{{tooltip.completed}}</span>
  </p>
</div>
