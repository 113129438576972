import { Component, OnInit, Input } from '@angular/core';
import { getUserById } from '../../../ngrx/reducers/user.reducer';
import { User } from '../../../interfaces/user';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state/app-state';
import { Observable } from 'rxjs';

@Component({
  selector: 'user-avatar-wrapper',
  template: `<a-avatar [user]="user$ | async"></a-avatar>`
})
export class UserAvatarWrapperComponent implements OnInit {
  user$: Observable<User>;

  @Input() userId: number;
  @Input() className: string;

  constructor(private _store: Store<AppState>) {}

  ngOnInit(): any {
    this.user$ = <Observable<User>>this._store.pipe((getUserById(this.userId)));
  }
}
