import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'markdown-guide',
  templateUrl: './markdown-guide.component.html',
  styleUrls: ['./markdown-guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: true
})
export class MarkdownGuideComponent {
  @Output() closePopup = new EventEmitter();
  constructor() {}

  onClose() {
    this.closePopup.emit(true);
  }
}
