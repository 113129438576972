import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'a-fixed-container',
  templateUrl: './a-fixed-container.component.html',
  styleUrls: ['./a-fixed-container.component.scss']
})
export class AFixedContainerComponent implements OnChanges {
  @Input() left: number;
  @Input() top: number;

  public style = {};

  constructor() {}

  ngOnChanges() {
    this.style = {
      position: 'fixed',
      'top.px': this.top,
      'left.px': this.left
    };
  }
}
