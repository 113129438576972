<span class="icon" [class.archived]="task.archived"></span>
<a *ngIf="directLink; else routerLink" [href]="link" target="_blank" rel="noopener" [class.done]="isDone" [class.archived]="task.archived">
  <ng-template [ngTemplateOutlet]="link_content"></ng-template>
</a>

<ng-template #routerLink>
  <a [routerLink]="link" [class.done]="isDone" [class.archived]="task.archived">
    <ng-template [ngTemplateOutlet]="link_content"></ng-template>
  </a>
</ng-template>

<ng-template #link_content>
  {{(taskKey ? taskKey + ' - ' : '') + board.name + ' - ' + task.title + (swimlane && board.swimlanesIds?.length > 1 ? ' - ' + swimlane.name : '')}} -
  <span *ngIf="inComposite; else simpleColumn">
    {{parentColumn.name}} ({{column.name}})
  </span>
  <ng-template #simpleColumn>{{column.name}}</ng-template>
  <span *ngIf="task.archived"> - Archived</span>
  {{task.estimate || task.loggedTime ? ' (' + (task.estimate ? (Math.round(+task.estimate/360)/10) + 'h' : '-') + '/' + (Math.round(+task.loggedTime/360)/10) + 'h)': ''}}
</ng-template>
