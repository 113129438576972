import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromBillingSubscription from '@atlaz/billing/store/subscription/subscription.reducer';
import { AppState } from '../../../ngrx/state/app-state';

@Component({
  selector: 'change-card',
  templateUrl: './change-card.component.html',
  styleUrls: ['./change-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeCardComponent implements OnInit {
  public billingSub$;

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this.billingSub$ = this._store.select(fromBillingSubscription.getCompanyBillingSubscription);
  }
}
