import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../app/auth.service';

import { RouterNavigateService } from '../router-navigate.service';
import { debug } from 'util';
import { AtlazDevtoolsService } from '../../../../helpers/devtools';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/internal/observable/of';

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(
    private _authService: AuthService,
    private _navigate: RouterNavigateService,
    private _devTool: AtlazDevtoolsService,
    private _router: Router
  ) {}

  goToDefaultPage() {
    this._navigate.navigateToOverview();
    return false;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!route.fragment) {
      return this.goToDefaultPage();
    }

    const fragments = route.fragment.split('&').reduce((acc, pair: string) => {
      const [key, value] = pair.split('=');
      acc[key] = value ? decodeURIComponent(value) : value;
      return acc;
    }, {});
    if (!fragments['return_path']) {
      return this.goToDefaultPage();
    }

    this._authService.fetchAccessToken().subscribe(
      () => {
        // navigate to return url
        console.warn('login successful');
        this._navigate.navigateByUrl(fragments['return_path']).catch(() => {
          this.goToDefaultPage();
        });
      },
      err => {
        this._devTool.logAuthError(err);

        if (err.status === 403) {
          return this._navigate.navigateToLoginAccessError();
        }
        let errorMessage;
        try {
          //  noinspection TsLint
          errorMessage = err.errors.map(x => x.message);
        } catch (e) {
          if (!errorMessage || !errorMessage.length) {
            //  noinspection TsLint
            errorMessage = ["There's some error occured."];
          }
        }
        this._authService.authErrors = errorMessage;
        // navigate to error page
        console.warn('login error');
        this._navigate.navigateToLoginError();
      }
    );

    return true;
  }
}
