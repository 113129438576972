
import {map} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { compose } from '@ngrx/store';
import { getEntitySelector } from '../../functions/selectors';
import { DEFAULT_STATUSES_FILTER } from '../../state';
import * as defaultStatusesFilter from '../../actions/task-filters/default-statuses-filter.actions';
import { DefaultTasksStatusesFilterActionTypes } from '../../actions/task-filters/default-statuses-filter.actions';
import { TaskDefaultFilterActionTypes } from '../../actions/task-filters/task-default-filter.actions';

export interface DefaultStatusesFilter {
  statusesTypes: string[];
}

const initState: DefaultStatusesFilter = {
  statusesTypes: []
};

export function reducer(state = initState, action: defaultStatusesFilter.Actions): DefaultStatusesFilter {
  switch (action.type) {
    case DefaultTasksStatusesFilterActionTypes.TOGGLE_STATUS: {
      let statusesTypes: string[];
      statusesTypes = action['payload'];
      state = Object.assign({}, state, { statusesTypes: statusesTypes });
      return state;
    }

    case DefaultTasksStatusesFilterActionTypes.RESET: {
      return initState;
    }

    case TaskDefaultFilterActionTypes.SET: {
      if (action['payload'].activeFilters.defaultStatusesFilter) {
        state = { statusesTypes: [...action['payload'].activeFilters.defaultStatusesFilter] };
      } else {
        state = initState;
      }
      return state;
    }

    default: {
      return state;
    }
  }
}

const isActive = (state$: Observable<DefaultStatusesFilter>) => state$.pipe(map(state => state.statusesTypes.length > 0));
const getStatusesIds = (state$: Observable<DefaultStatusesFilter>) => state$.pipe(map(state => state.statusesTypes));

export const getDefaultStatusesFilterEntity = getEntitySelector(DEFAULT_STATUSES_FILTER);
export const isActiveDefaultStatusesFilter = compose(isActive, getDefaultStatusesFilterEntity);
export const getDefaultStatusesFilterIds = compose(getStatusesIds, getDefaultStatusesFilterEntity);
