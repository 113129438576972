import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormV2Service } from '../../shared/services/form-v2.service';
import { Subject } from 'rxjs';
import { Swimlane } from '../../interfaces/swimlane';
import { SwimlaneDeleteAction } from '../../ngrx/actions/swimlane.actions';
import { AppState } from '../../ngrx/state/app-state';
import { Store } from '@ngrx/store';

@Component({
  selector: 'swimlane-edit-popup',
  templateUrl: './swimlane-edit-popup.component.html',
  styleUrls: ['./swimlane-edit-popup.component.scss'],
  providers: [FormV2Service]
})
export class SwimlaneEditPopupComponent implements AfterViewInit {
  @Input() swimlane: Swimlane;
  @Input() isGroup: boolean;
  @Input() isDeleteUnavailable: boolean;
  @Output() close = new EventEmitter();

  public submit$ = new Subject();
  public isDeleteSwimlaneConfirmVisible = false;

  constructor(public _formService: FormV2Service, private _store: Store<AppState>) {}

  ngAfterViewInit() {
    this._formService.registerObserver({
      next: () => this.onClose(),
      error: () => null,
      complete: () => null
    });
  }

  onToggleDeleteSwimlaneConfirm() {
    this.isDeleteSwimlaneConfirmVisible = !this.isDeleteSwimlaneConfirmVisible;
  }
  onDeleteSwimlane() {
    this.onClose();
    this._store.dispatch(new SwimlaneDeleteAction(this.swimlane.id));
  }
  onClose() {
    this.close.emit();
  }
}
