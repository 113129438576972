
import {catchError, filter, map, switchMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { HttpQueryParam } from '../../interfaces';
import { BOARD_PL, COLLECTION_PL } from '../../constants';
import {
  CollectionActionTypes,
  CollectionDeleteCompleteAction,
  CollectionGetAction
} from '../actions/collection.actions';
import { HandleResponseAction } from '../actions/root.action';
import { defaultErrorHandler } from './root.effect';
import { AuthService } from '../../shared/services/app/auth.service';
import { isPresent } from '../../../helpers';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';

@Injectable()
export class CollectionEffects {
  @Effect()
  init$ = this._auth.userInActiveCompany$.pipe(
    filter(isPresent),
    map(() => new CollectionGetAction({ sort: 'name,id', expand: [BOARD_PL].toString() })),);

  @Effect()
  loadCollection$ = this.actions$
    .ofType(CollectionActionTypes.GET).pipe(
    switchMap(({ type, payload: httpParams }: { type: string; payload: HttpQueryParam }) => {
      return this._atlazApi
        .get(COLLECTION_PL, httpParams).pipe(
        map(resp => new HandleResponseAction(resp)),
        catchError(defaultErrorHandler(type, httpParams)),);
    }));

  @Effect()
  deleteCollection$ = this.actions$
    .ofType(CollectionActionTypes.DELETE).pipe(
    switchMap(({ type, payload: collectionId }: { type: string; payload: number }) => {
      return this._atlazApi
        .deleteRequest([COLLECTION_PL, collectionId]).pipe(
        map(_ => new CollectionDeleteCompleteAction()),
        catchError(defaultErrorHandler(type, collectionId)),);
    }));

  @Effect({ dispatch: false })
  editCollection$ = this.actions$
    .ofType(CollectionActionTypes.EDIT, CollectionActionTypes.ASSIGN_BOARDS).pipe(
    switchMap(({ type, payload }: { type: string; payload: any }) => {
      return this._atlazApi
        .patch(COLLECTION_PL, payload).pipe(
        map(resp => new HandleResponseAction(resp)),
        catchError(defaultErrorHandler(type, payload)),);
    }));

  constructor(private actions$: Actions, private _auth: AuthService, private _atlazApi: AtlazApiV2Service) {}
}
