<a-center-popup>
  <a-center-container>
    <div a-popup [title]="'Invite JIRA Members to Hygger'" class="popup" (close)="onPopUpCloseEvent($event)">
      <div class="invite_members_container">
        <div><form-errors></form-errors></div>
        <div class="invite_members_container_header">
          <div class="float_left">
            <input type="checkbox" (click)="checkAll($event.target.checked)">
          </div>
          <div class="float_left member_header">
            <span>Members</span>
          </div>
          <div class="email_header">
            <span>Email</span>
          </div>
        </div>
        <div class="members_list">
          <div *ngFor="let member of members$ | async; let i=index" class="member_list_item">
            <div class="float_left member_checkbox">
              <input type="checkbox" (click)="onClickCheckbox(member.id, $event.target.checked)" [checked]="isCheckedAll">
            </div>
            <div class="float_left member_name">
              <a-avatar [user]="member"></a-avatar>
              <div class="member_info">
                <p class="p_fullname">{{ member.fullname }}</p>
                <p class="p_nickname">@{{ member.nickname }}</p>
                </div>
            </div>
            <div class="member_email">{{ member.email }}</div>
          </div>
        </div>
        <div class="btns_popup">
          <button a-button class="btn_primary" (click)="onSubmit()" [disabled]="form.value.inviteUsers.length === 0">Invite</button>
          <button a-button class="btn_secondary" (click)="onSkip()">Skip</button>
        </div>
      </div>
    </div>
  </a-center-container>
</a-center-popup>
