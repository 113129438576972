import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../../shared/services/form-v2.service';
import { Task } from '../../../interfaces';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { FactorType, IceFactors, RiceFactors } from '../../backlog-board/constants/backlog.constants';
import { AppState } from '../../../ngrx/state';
import { Observer, Subscription } from 'rxjs/index';
import { JsonApiSingeModelResponse } from '../../../shared/services/app/web-socket/http-response';
import { HandleResponseAction } from '../../../ngrx/actions/root.action';
import { ScoringType, TASK_PL } from '../../../constants';

@Component({
  selector: 'edit-ice-rice-scoring',
  templateUrl: './edit-ice-rice-scoring.component.html',
  styleUrls: ['./edit-ice-rice-scoring.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditIceRiceScoringComponent implements OnInit {
  @Input() task: Task;
  @Input() focusOn: number | string;
  @Input() type: ScoringType;

  @Output() close = new EventEmitter();

  public factors;
  public iceFactors = IceFactors;
  public riceFactors = RiceFactors;
  public ScoringType = ScoringType;
  public FactorType = FactorType;

  formObserver: Observer<any> = {
    next: (resp: JsonApiSingeModelResponse<any>) => {
      this._store.dispatch(new HandleResponseAction(resp));
      this.onClose();
    },

    error: () => {},

    complete: () => {}
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.edit,
    entityToEdit: [TASK_PL],
    formObserver: this.formObserver,
    prepareFormValue: formValue =>
      Object.keys(formValue).reduce((acc, item) => {
        acc[item] = formValue[item] === null ? false : formValue[item];
        return acc;
      }, {})
  };

  form: FormGroup;
  subs: Subscription[] = [];

  constructor(
    private _fb: FormBuilder,
    private _store: Store<AppState>,
    public _formService: FormV2Service,
    private _cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.factors = this.type === ScoringType.ICE ? this.iceFactors : this.riceFactors;
    const formConfig = this.factors.reduce(
      (acc, item) => {
        const val = parseFloat(this.task[item.prop]);
        acc[item.prop] = Number.isFinite(val) ? val : null;
        return acc;
      },
      { id: this.task.id }
    );
    this.form = this._fb.group(formConfig);

    this._formService.initFormParams(this.form, this.formServiceParams);
    this._cd.detectChanges();
  }

  onValidateType(type, formPropName) {
    formPropName = formPropName + '';
    if (this.form.get(formPropName).value === null || this.form.get(formPropName).value === '?') {
      return;
    }
    switch (type) {
      case FactorType.ice: {
        const val = parseInt(this.form.get(formPropName).value) || 0;
        this.form.get(formPropName).patchValue(val < 0 ? 0 : val > 10 ? 10 : val);
        break;
      }
      case FactorType.percent: {
        const val = parseInt(this.form.get(formPropName).value) || 0;
        this.form.get(formPropName).patchValue(val < 0 ? 0 : val > 100 ? 100 : val);
        break;
      }
      case FactorType.generalNum: {
        this.form.get(formPropName).patchValue(parseInt(this.form.get(formPropName).value) || 0);
        break;
      }
    }
  }

  onSubmit() {
    this.form.markAsTouched();
    this.factors.forEach(item => this.onValidateType(item.type, item.prop));
    this._formService.submit();
  }

  onClose() {
    this.close.emit();
  }
}
