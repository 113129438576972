<div *ngFor="let dateInterval of tasksByDate;trackBy: trackByName" class="time_stamp_wrapper">
  <h3 *ngIf="rightMenuContent">{{dateInterval.name}}</h3>

  <div *ngFor="let activity of dateInterval.activities;trackBy: trackById" class="time_stamp_container">
      <task-feed-preview
        [activity]="activity"
      ></task-feed-preview>
  </div>
</div>

