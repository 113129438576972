<a-context-container>
  <div a-popup
       [title]="isEdit ? 'Edit Label' : 'Create New Label'"
       (close)="onCloseLabelsEditPopup()"
  >
    <div class="label_color_wrap">
      <h3>Title</h3>
      <input #labelName [(ngModel)]="name" [focus]="true"
             (keydown)="onSaveOnKey($event)"
             [hotKeys]="postHotKeys"
             (hotKey)="onCreateNewLabel()"
      >
      <h3>Color</h3>
      <div class="label_color_list">
        <div class="label_color_item check" *ngFor="let color of colors" [ngClass]="color" (click)="selectColor(color)">
          <div class="label_check" *ngIf="color === selectedColor"></div>
        </div>
      </div>
      <div class="btns_block">
        <button a-button class="btn_primary save" (click)="onCreateNewLabel()">{{isEdit ? 'Save' : 'Create'}}</button>
        <button a-button class="btn_secondary remove" *ngIf="isEdit" (click)="onToggleDeleteConfirm()">Delete</button>
      </div>
    </div>
  </div>
  <div a-popup class="delete_label_confirm" [title]="'Delete Label'" *ngIf="isDeleteConfirm"
       (close)="onToggleDeleteConfirm()"
  >
    <p class="delete_message">The changes can not be reverted.</p>
    <div a-button class="btn_warn" (click)="onRemoveLabel()">Delete</div>
    <div a-button (click)="onToggleDeleteConfirm()" class="btn_secondary">Cancel</div>
  </div>
</a-context-container>
