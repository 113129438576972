import { Component, Input, OnInit } from '@angular/core';
import { Board } from '../../interfaces';
import { AppState } from '../../ngrx/state';
import { Store } from '@ngrx/store';
import { BoardEditAction } from '../../ngrx/actions/board.actions';

@Component({
  selector: 'right-menu-show-props',
  templateUrl: './right-menu-show-props.component.html',
  styleUrls: ['./right-menu-show-props.component.scss']
})
export class RightMenuShowPropsComponent implements OnInit {
  @Input() board: Board;
  public showPopup = false;
  constructor(private _store: Store<AppState>) {}

  ngOnInit() {}

  onClose() {
    this.showPopup = false;
  }

  onOpen() {
    this.showPopup = true;
  }

  onPropClick(prop) {
    const arr = this.board.showProps.split('');
    arr[prop] = arr[prop] === '1' ? '0' : '1';
    this._store.dispatch(new BoardEditAction({ id: this.board.id, showProps: arr.join('') }));
  }
}
