import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dayLeft'
})
export class DayLeftPipe implements PipeTransform {
  transform(value: any, timestamp?): any {
    if (!timestamp) {
      timestamp = Math.floor(Date.now() / 1000);
    }

    const oneDaySeconds = 3600 * 24;

    const valueInDays = Math.ceil(value / oneDaySeconds);
    const timestampDays = Math.floor(timestamp / oneDaySeconds);

    return valueInDays - timestampDays;
  }
}
