
import {of as observableOf,  Observable } from 'rxjs';

import {switchMap, mergeMap, catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { VERSION_PL, TASK_PL } from '../../constants';
import { HttpQueryParam, PartOfEntity } from '../../interfaces';
import { AppState } from '../state/app-state';
import { NoopAction } from '../actions/root.action';
import { TaskAssignVersionAction, TaskLoadAction } from '../actions/task.actions';
import { VersionDeleteCompleteAction, VersionActionTypes } from '../actions/version.actions';
import { defaultErrorHandler } from './root.effect';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { HandleResponseAction } from '../actions/root.action';

@Injectable()
export class VersionEffects {
  @Effect()
  getVersions$ = this.actions$
    .ofType(VersionActionTypes.GET).pipe(
    mergeMap(({ type, payload }: { type: string; payload: HttpQueryParam }) => {
      return this._atlazApiV2
        .get(VERSION_PL, payload).pipe(
        map(resp => new HandleResponseAction(resp)),
        catchError(defaultErrorHandler(type, payload)),);
    }));
  @Effect()
  getGetVersions$ = this.actions$
    .ofType(VersionActionTypes.GET_GET).pipe(
    mergeMap(({ type, payload }: { type: string; payload: HttpQueryParam }) => {
      return this._atlazApiV2
        .get(VERSION_PL, payload).pipe(
        map(resp => new HandleResponseAction(resp)),
        catchError(defaultErrorHandler(type, payload)),);
    }));

  @Effect()
  addVersion$ = this.actions$
    .ofType(VersionActionTypes.ADD).pipe(
    switchMap(({ type, payload }: { type: string; payload: PartOfEntity }) => {
      return this._atlazApiV2
        .post([VERSION_PL, { expand: TASK_PL }], payload).pipe(
        map(resp => new HandleResponseAction(resp)),
        catchError(defaultErrorHandler(type, payload)),);
    }));

  @Effect()
  loadVersion$ = this.actions$
    .ofType(VersionActionTypes.LOAD).pipe(
    switchMap(({ type, payload }: { type: string; payload: HttpQueryParam }) => {
      if (!payload) {
        return observableOf(new NoopAction());
      }
      const id: number = +payload.id;

      return this._atlazApiV2
        .get(VERSION_PL, payload).pipe(
        map(resp => new HandleResponseAction(resp)),
        catchError(defaultErrorHandler(type, payload)),);
    }));

  @Effect({ dispatch: false })
  editVersion$ = this.actions$
    .ofType(VersionActionTypes.EDIT, VersionActionTypes.ASSIGN_TASK).pipe(
    switchMap(({ type, payload }: { type: string; payload: any }) => {
      this.updateTasks(payload);

      return this._atlazApiV2
        .patch([VERSION_PL, { expand: TASK_PL }], payload).pipe(
        map(resp => {
          this.taskLoadData(payload);
          return new HandleResponseAction(resp);
        }),
        catchError(defaultErrorHandler(type, payload)),);
    }));

  @Effect()
  deleteVersion$ = this.actions$
    .ofType(VersionActionTypes.DELETE).pipe(
    switchMap(({ type, payload: versionId }: { type: string; payload: number }) => {
      return this._atlazApiV2
        .deleteRequest([VERSION_PL, versionId]).pipe(
        map(_ => new VersionDeleteCompleteAction()),
        catchError(defaultErrorHandler(type, versionId)),);
    }));

  updateTasks(httpParams) {
    if (httpParams.tasks) {
      if (httpParams.tasks.add) {
        httpParams.tasks.add.forEach(taskID =>
          this._store.dispatch(new TaskAssignVersionAction({ id: taskID, version: httpParams.id }))
        );
      }
      if (httpParams.tasks.remove) {
        httpParams.tasks.remove.forEach(taskID =>
          this._store.dispatch(new TaskAssignVersionAction({ id: taskID, version: 0 }))
        );
      }
    }
  }

  taskLoadData(httpParams) {
    if (httpParams.tasks) {
      if (httpParams.tasks.add) {
        httpParams.tasks.add.forEach(taskId =>
          this._store.dispatch(new TaskLoadAction({ id: taskId, params: { expand: 'users,activities,labels' } }))
        );
      }
    }
  }

  constructor(private actions$: Actions, private _store: Store<AppState>, protected _atlazApiV2: AtlazApiV2Service) {}
}
