import { of as observableOf, interval as observableInterval, Observable, BehaviorSubject } from 'rxjs';

import {
  refCount,
  switchMapTo,
  publishReplay,
  filter,
  take,
  startWith,
  combineLatest,
  switchMap,
  distinctUntilChanged,
  pluck,
  map
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromBillingSubscription from '@atlaz/billing/store/subscription/subscription.reducer';
import { AppState } from '../../ngrx/state/';
import { PermissionsService } from '../../permissions/permissions.service';
import { isNotPresent, isPresent } from '../../../helpers/';
import { CompanyService } from '../../shared/services/app/company.service';
import { RouterNavigateService } from '../../shared/services/router-navigate.service';
import {
  BillingSubscriptionAttributes,
  BillingSubscriptionEntity
} from '@atlaz/billing/interfaces/billing-subscription';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import {
  BILLING_SUBSCRIPTION_STATUS,
  BillingPlanName,
  DefaultStripeId
} from '@atlaz/billing/constants/billing-subscription';
import { COMPANY_STATUS } from '@atlaz/billing/constants/company';
import { ONE_DAY } from '../../board/roadmap-board/store/roadmap-board.effect';
import { Company } from '../../interfaces/';
import { getEnvironmentParam } from '../../../environments/helper';
import { environment } from '../../../environments/environment';
import { ROUTER_LINKS, THERE_D_SECURE_BACK_URL } from '@atlaz/billing/util/billing-url';
import { HttpClient } from '@angular/common/http';

const PLAN_CALCULATOR_PATH = 'calculation-cost-plans';

@Injectable()
export class BillingPublicApiService {
  readonly hasAccess$ = this._permissions.isSuperUser$.pipe(
    combineLatest(
      this._companyService.currentCompany$.pipe(pluck('isDemo')),
      (hasRights, isDemo) => hasRights && !isDemo
    ),
    switchMap(hasAccess =>
      observableInterval(1000).pipe(startWith(0), map(() => hasAccess && environment.billing.enabled))
    ),
    distinctUntilChanged()
  );

  readonly billingSubscription$: Observable<BillingSubscriptionEntity> = this._store
    .select(fromBillingSubscription.getCompanyBillingSubscription)
    .pipe(filter(isPresent), publishReplay(1), refCount());

  readonly isLocked$ = this._companyService.companyBlocked$;
  readonly companyStatus$ = this.billingSubscription$.pipe(
    switchMap(
      sub =>
        sub.isTrial ? observableOf(COMPANY_STATUS.TRIAL) : this._companyService.currentCompany$.pipe(pluck('status'))
    )
  );

  readonly hasCard$ = this.billingSubscription$.pipe(
    map(sub => sub.cardData && typeof sub.cardData === 'object' && !sub.deactivated)
  );
  readonly upgradeNeeded$ = this.billingSubscription$.pipe(
    switchMap(
      sub =>
        sub.plan === 'UNPAID' || sub.isTrial
          ? this.hasCard$.pipe(map(isNotPresent))
          : observableOf(sub.status === BILLING_SUBSCRIPTION_STATUS.AWAITING_FOR_PAYMENTS_DETAILS)
    )
  );
  readonly renewNeeded$ = this._companyService.currentCompany$.pipe(
    map((comp: Company) => comp.status === COMPANY_STATUS.AWAITING_FOR_PAYMENT)
  );
  readonly unlocking$ = new BehaviorSubject(false);
  // to check this value every minute
  readonly dayToTrialEnd$ = observableInterval(60 * 1000).pipe(
    startWith(null),
    switchMapTo(this.billingSubscription$),
    map(sub => (sub.isTrial ? Math.ceil((sub.trialEnd - Date.now() / 1000) / ONE_DAY) : 0))
  );

  readonly totalPrice$ = this.billingSubscription$.pipe(map(sub => parseFloat(String(sub.totalPrice))));
  readonly hasActivePrepaidSubscription$ = this.billingSubscription$.pipe(map(sub => sub.status === 'active'));
  readonly hasFreeSubscription$ = this.totalPrice$.pipe(map(totalPrice => totalPrice < 0.001));
  readonly hasActiveSubscription$ = this.hasFreeSubscription$.pipe(
    switchMap(free => (free ? observableOf(false) : this.billingSubscription$.pipe(map(sub => !sub.deactivated))))
  );

  readonly hasStripeSub$ = this.billingSubscription$.pipe(
    map((sub: BillingSubscriptionEntity) => sub.stripeId !== DefaultStripeId)
  );

  readonly canChoosePlan$ = this.billingSubscription$.pipe(
    switchMap(
      (sub: BillingSubscriptionAttributes) =>
        sub.isTrial ? this.hasCard$.pipe(map(isNotPresent)) : this.hasStripeSub$.pipe(map(isNotPresent))
    )
  );
  readonly chargeIsRequired$ = this._companyService.currentCompany$.pipe(
    map(comp => comp.status === COMPANY_STATUS.AWAITING_FOR_PAYMENT || comp.status === COMPANY_STATUS.BLOCKED),
    combineLatest(
      this.billingSubscription$,
      (a, b: BillingSubscriptionEntity) => a && b.plan !== BillingPlanName.Unpaid
    )
  );

  constructor(
    private _store: Store<AppState>,
    private _navigate: RouterNavigateService,
    private _companyService: CompanyService,
    private _api: AtlazApiV2Service,
    // used just for public api !
    private _http: HttpClient,
    private _permissions: PermissionsService
  ) {
    this.initialRedirect();
  }

  initialRedirect() {
    this._companyService.companyBlocked$
      .pipe(distinctUntilChanged(), filter(isPresent), switchMapTo(this.hasAccess$.pipe(take(1))))
      .subscribe(hasAccess => {
        if (this.isUnavailableRoute()) {
          hasAccess ? this._navigate.navigateToCompanySettings() : this._navigate.navigateToForbidden();
        }
      });

    this._companyService.companyBlocked$
      .pipe(
        distinctUntilChanged(),
        filter(isPresent),
        take(1),
        switchMapTo(this._companyService.isActive$.pipe(filter(isPresent), take(1))),
        switchMapTo(this.hasAccess$.pipe(take(1)))
      )
      .subscribe(hasAccess => (hasAccess ? null : this._navigate.navigateToOverview()));
  }

  isUnavailableRoute() {
    let result = true;
    const location = '/' + window.location.pathname;
    [ROUTER_LINKS[THERE_D_SECURE_BACK_URL].join('/'), ROUTER_LINKS['pricing'].join('/')].forEach(route => {
      if (location.indexOf(route) === 0) {
        result = false;
      }
    });

    return result;
  }

  getPrice(userCount: number): Observable<number> {
    return this._http
      .get(getEnvironmentParam('publicApiUrl') + '/' + [PLAN_CALCULATOR_PATH, userCount].join('/'))
      .pipe(map(data => <number>data['meta']['calculationForPlan']['totalCost']));
  }
}
