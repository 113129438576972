import { Action } from './unsafe-action';
import { labelAction } from '../functions/action';
import { PartOfEntity, TaskActivity, HttpQueryParam } from '../../interfaces';
import { ACTIVITY } from '../../constants';

export const TaskActivityActionTypes = {
  LOAD: labelAction(ACTIVITY, 'LOAD'),
  GET: labelAction(ACTIVITY, 'GET'),
  GET_COMPLETE: labelAction(ACTIVITY, 'GET_COMPLETE'),
  GET_FAIL: labelAction(ACTIVITY, 'GET_FAIL'),
  ADD: labelAction(ACTIVITY, 'ADD'),
  ADD_COMPLETE: labelAction(ACTIVITY, 'ADD_COMPLETE'),
  ADD_FAIL: labelAction(ACTIVITY, 'ADD_FAIL'),
  EDIT: labelAction(ACTIVITY, 'EDIT'),
  EDIT_COMPLETE: labelAction(ACTIVITY, 'EDIT_COMPLETE'),
  EDIT_FAIL: labelAction(ACTIVITY, 'EDIT_FAIL'),
  DELETE: labelAction(ACTIVITY, 'DELETE'),
  DELETE_COMPLETE: labelAction(ACTIVITY, 'DELETE_COMPLETE'),
  DELETE_FAIL: labelAction(ACTIVITY, 'DELETE_FAIL')
};

export class TaskActivityLoadAction implements Action {
  type = TaskActivityActionTypes.LOAD;

  constructor(public payload: { id: number; params?: HttpQueryParam }) {}
}

export class TaskActivityGetAction implements Action {
  type = TaskActivityActionTypes.GET;

  constructor(public payload?: HttpQueryParam) {}
}

export class TaskActivityGetCompleteAction implements Action {
  type = TaskActivityActionTypes.GET_COMPLETE;

  constructor(public payload: TaskActivity[]) {}
}

export class TaskActivityEditAction implements Action {
  type = TaskActivityActionTypes.EDIT;
  constructor(public payload: PartOfEntity) {}
}

export class TaskActivityEditCompleteAction implements Action {
  type = TaskActivityActionTypes.EDIT_COMPLETE;

  constructor(public payload: TaskActivity) {}
}

export class TaskActivityDeleteAction implements Action {
  type = TaskActivityActionTypes.DELETE;

  constructor(public payload: TaskActivity) {}
}

export class TaskActivityDeleteCompleteAction implements Action {
  type = TaskActivityActionTypes.DELETE_COMPLETE;

  constructor(public payload?: any) {}
}

export class TaskActivityAddAction implements Action {
  type = TaskActivityActionTypes.ADD;

  constructor(public payload: PartOfEntity) {}
}

export class TaskActivityAddCompleteAction implements Action {
  type = TaskActivityActionTypes.ADD_COMPLETE;

  constructor(public payload: TaskActivity) {}
}

export type TaskActivityActions =
  | TaskActivityGetAction
  | TaskActivityLoadAction
  | TaskActivityGetCompleteAction
  | TaskActivityEditAction
  | TaskActivityEditCompleteAction
  | TaskActivityDeleteAction
  | TaskActivityDeleteCompleteAction
  | TaskActivityAddAction
  | TaskActivityAddCompleteAction;
