
    <span
      class="sprint_in_progress"
      *ngIf="sprintStartDate" 
      a-tooltip="Sprint '{{sprintName}}' is running" 
      position="bottom-right"
    ></span>
    <h3 class="board_label_title" title="{{boardName}}">
      {{boardName}}
    </h3>
    <p *ngIf="sprintReleased">
      <span class="released_icon"></span>
      Released
    </p>