<a-center-container *ngIf="!areInvitesSent">
  <div a-popup [title]="'Invite Members'" (close)="onClose()" class="mass_invite_popup">
    <form-errors></form-errors>
    <div class="mass_invite_inner">
      <div class="field_titles" *ngIf="!isManyAtOnceActive">
        <label>Email</label>
        <label>Role</label>
        <label>Boards</label>
      </div>

	    <many-at-once *ngIf="isManyAtOnceActive" (emailsList)="onSetManyEmails($event)"></many-at-once>

      <form [formGroup]="form" class="mass_invite_form">
        <div *ngIf="!isManyAtOnceActive">
          <div *ngFor="let memberField of form.controls['users']['controls']; let last = last" class="form_fields_wrapp">
            <div class="field_block">
              <input [formControl]="memberField.get('email')" checkTouch [focus]="last" placeholder="name@domain.com">
              <help-block [control]="memberField.get('email')"
                          [messages]="{ 'email' : 'Email address is invalid' }"
              ></help-block>
            </div>
            <div class="field_block">
              <select name="role" id="role" [formControl]="memberField.get('role')">
                <option *ngFor="let role of roles" [value]="role.role">{{role.name}}</option>
              </select>
            </div>
            <div class="field_block">
              <a-multiselect
                class="fixed"
                [searchable]="true"
                [selectedOnTop]="true"
                [items]="allBoards$ | async"
                [selectAllOnInit]="false"
                [placeholderText]="'No Boards'"
                [formControl]="memberField.get('boards')"
              ></a-multiselect>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="controlls_block" *ngIf="!isManyAtOnceActive">
      <div class="add_fields_control" (click)="addMemberFields()">
        <div class="icon"></div>
        <span>Add Member</span>
      </div>
      <p>or</p>
      <span (click)="onToggleManyAtOnce()">Add Many at Once</span>
    </div>

    <div class="buttons_block">
      <button a-button class="btn_primary"
              [pending]="_formService.isPending$ | async"
              (click)="onSubmit()"
      >
        Invite
      </button>
      <button a-button class="btn_secondary" (click)="onCloseOnButton()">Cancel</button>
      <a [routerLink]="inviteUrl" class="invite_link" [skipLocationChange]="true">Get an invitation link to share</a>
    </div>

  </div>
</a-center-container>
<a-center-popup *ngIf="areInvitesSent"
                [title]="'Invitation Sent Successfully'"
                class="confirm_popup"
                (close)="onClose()"
>
  <div class="confirm_popup_inner">
    <h4>Invitation {{userEmails.length > 1 ? 'have' : 'has'}} been sent to</h4>
    <p *ngFor="let email of userEmails; let last = last">
      {{email}}
      <span *ngIf="!last">,</span>
      <span *ngIf="last">.</span>
    </p>
  </div>
  <button a-button class="btn_primary" (click)="onClose()">Ok</button>
</a-center-popup>
<overlay (click)="onClose()" *ngIf="!areInvitesSent"></overlay>
