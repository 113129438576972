<a-center-popup *ngIf="showPopUp" [title]="'Start Sprint'" (close)="onClose()" >
  <form *ngIf="showPopUp" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-group">
    <form-errors></form-errors>
    </div>
    <div class="form-group">
      <div class="label_sprint">Name</div>
      <div>
        <input formControlName="sprintName" checkTouch [focus]="true" />
        <help-block [control]="form.get('sprintName')" [messages]="customErrorMessages"></help-block>
      </div>
    </div>
    <div class="form-group">
      <div class="label_sprint">Duration</div>
      <select formControlName="duration" checkTouch>
        <option value="1">1 week</option>
        <option value="2">2 week</option>
        <option value="3">3 week</option>
        <option value="4">4 week</option>
        <option [value]="CUSTOM_DURATION">custom</option>
      </select>
    </div>
    <div class="form-group">
      <div class="label_sprint">End Date</div>
      <div class="picker">
        <a-datetime-picker checkTouch formControlName="sprintEndDate" name="sprintEndDate"></a-datetime-picker>
        <help-block [control]="form.get('sprintEndDate')" [messages]="customErrorMessages"></help-block>
      </div>
    </div>
    <div class="form-group">
      <div class="label_sprint"><icon-info a-tooltip="You can estimate tasks using Story Points and Time - both separately and together"></icon-info>Estimate tasks using</div>
      <select formControlName="estimatedBy" checkTouch>
        <option [value]="estimationType.storyPoints">Story Points</option>
        <option [value]="estimationType.hours">Hours</option>
        <option [value]="estimationType.any">Story Points and Hours</option>
      </select>
    </div>
    <div class="divider"></div>
    <button a-button
         (click)="onSubmit()"
         [pending]="_formService.isPending$ | async"
         class="btn_primary"
    >Start
    </button>
  </form>
</a-center-popup>
