import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getProperty'
})
export class GetPropertyPipe implements PipeTransform {
  transform(value: { [prop: string]: any }, fieldName: string) {
    return !value || !fieldName ? value : value[fieldName];
  }
}
