import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services/app/auth.service';
import { AppUrls } from '../../app-urls';

@Component({
  selector: 'login-error',
  templateUrl: './login-error.component.html',
  styleUrls: ['./login-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginErrorComponent {
  public AppUrls = AppUrls;

  get errors() {
    return this._authService.authErrors;
  }

  constructor(public _authService: AuthService) {}
}
