import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasUserRelation'
})
export class HasUserRelationPipe implements PipeTransform {
  transform(value, userId) {
    return !value || !userId
      ? value
      : value.filter(item => item && item['usersIds'] && item['usersIds'].indexOf(userId) > -1);
  }
}
