export const ROLE_COMPANY_SUPER_OWNER = 'super_owner';
export const ROLE_COMPANY_OWNER = 'owner';
export const ROLE_COMPANY_ADMIN = 'admin';
export const ROLE_COMPANY_EMPLOYEE = 'employee';
export const ROLE_COMPANY_GUEST = 'guest';

export const ROLE_PROJECT_MEMBER = 'project-member';
export const ROLE_PROJECT_ADMIN = 'project-admin';

export type RoleCompany = 'super_owner' | 'owner' | 'admin' | 'employee' | 'guest';

export type RoleProject = 'project-member' | 'project-admin';

export const ROLE_COMPANY_TITLES: { [role: string]: string } = {
  super_owner: 'Company Owner',
  owner: 'Company Owner',
  admin: 'Company Admin',
  employee: 'Regular Employee',
  guest: 'Guest'
};

export const ROLE_PROJECT_TITLES: { [role: string]: string } = {
  [ROLE_PROJECT_MEMBER]: 'Project Member',
  [ROLE_PROJECT_ADMIN]: 'Project Admin'
};

export const getCompanyRoleTitle = (role: RoleCompany) => ROLE_COMPANY_TITLES[role] || '';
export const getCompanyRoleTitles = () => Object.keys(ROLE_COMPANY_TITLES).map(key => ROLE_COMPANY_TITLES[key]);
