<div class="menu_wrap"
     tabindex="0"
     #wrap
     [@rightMenuAnimation]="state"
     (@rightMenuAnimation.done)="animationDone($event)"
>
  <div class="menu_header">
    <div class="ico_back_menu" *ngIf="showBackArrow" (click)="onClickBack()"></div>
    <h3 class="menu_title">{{title}}</h3>
    <close-button (click)="onCloseRightMenu()" class="ico_close_menu"></close-button>
    <div class="divider"></div>
  </div>
  <div class="menu_list">
    <ng-content></ng-content>
  </div>
</div>
