
import {of as observableOf,  Observable ,  Subscription } from 'rxjs';

import {publishReplay, map, filter, switchMap, startWith, distinctUntilChanged, refCount} from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Board, Column, Swimlane, Task } from '../../interfaces';
import { Store } from '@ngrx/store';
import { FormComponent, FormSaveType, FormServiceParams, FormV2Service } from '../../shared/services/form-v2.service';
import { AppState } from '../../ngrx/state';
import { RouterNavigateService } from '../../shared/services/router-navigate.service';
import { fromBoards, getBoardById } from '../../ngrx/reducers/board.reducer';
import { getSwimlanesByBoard } from '../../ngrx/reducers/swimlane.reducer';
import { getTargetColumns } from '../../ngrx/reducers/column.reducer';
import { ColumnGetAction } from '../../ngrx/actions/column.actions';
import { SwimlaneGetAction } from '../../ngrx/actions/swimlane.actions';
import { boardType, COLUMN_PL } from '../../constants';
import { TASKS_MASS_MOVE } from '../../path.routing';
import { UnsavedFormChangesService } from '@atlaz/core/services/unsaved-form-changes.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'mass-tasks-actions-form',
  templateUrl: './mass-tasks-actions-form.component.html',
  styleUrls: ['./mass-tasks-actions-form.component.scss'],
  providers: [FormV2Service, UnsavedFormChangesService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MassTasksActionComponent implements OnInit, FormComponent, OnDestroy, OnChanges {
  @Input() actionName: 'move' | 'archive';
  @Input() boardId: number;
  @Input() column: Column;
  @Input() tasks: Task[];
  @Input() currentBoardSwimlanes: Swimlane[] = [];
  @Output() close = new EventEmitter();

  subs: Subscription[] = [];

  public targetBoards$: Observable<Board[]>;
  public targetSwimlanes$: Observable<Swimlane[]>;
  public showTargetSwimlanes$: Observable<boolean>;
  public targetColumns$: Observable<Column[]>;
  public form: FormGroup;
  public formServiceParams: FormServiceParams;
  public availablePositions = [{ name: 'Top', value: 'top' }, { name: 'Bottom', value: 'bottom' }];
  public allCurrentBoardSwimlanes = [];

  constructor(
    private _store: Store<AppState>,
    private _toastr: ToastrService,
    private _routerNav: RouterNavigateService,
    private _fb: FormBuilder,
    public _formService: FormV2Service,
    private _unsavedFormChangesService: UnsavedFormChangesService
  ) {}

  ngOnInit() {
    this.form = this._fb.group({
      subColumn: [this.defaultSubColumn],
      swimlane: [
        this.currentBoardSwimlanes.map(xs => xs.id),
        (c: FormControl) => (c.value.length === 0 ? { emptyArray: true } : null)
      ],
      ...(this.actionName === 'move' && {
        targetBoard: [this.boardId],
        targetSwimlane: [''],
        targetColumn: [this.defaultTargetColumn],
        targetPosition: [this.defaultTargetPosition]
      })
    });

    const unsavedFormChangesConfig = {
      allowedFields: ['targetBoard', 'targetSwimlane', 'targetColumn', 'targetPosition'],
      autoSave: false
    };

    this._unsavedFormChangesService.init(this.form, this.unsavedCacheKey(), unsavedFormChangesConfig);

    if (this.actionName === 'move') {
      this.targetBoards$ = this._store
        .select(fromBoards.getAllAvailable).pipe(
        map((boards: Board[]) => boards.filter(board => board.type !== boardType.roadmap)));

      const targetBoardId$ = this.form
        .get('targetBoard')
        .valueChanges.pipe(distinctUntilChanged(),
        startWith(this.form.value.targetBoard),
        switchMap((boardId: number) => this._store.pipe((getBoardById(+boardId)))),
        switchMap(board => {
          if (!board['columnsIds']) {
            this._store.dispatch(
              new ColumnGetAction({
                board: board.id,
                sort: 'position'
              })
            );
          }
          if (!board['swimlanesIds']) {
            this._store.dispatch(
              new SwimlaneGetAction({
                board: board.id
              })
            );
          }
          return observableOf(board.id);
        }),
        publishReplay(1),
        refCount(),);

      this.targetColumns$ = targetBoardId$.pipe(switchMap(boardId =>
        this._store.pipe((getTargetColumns(+boardId, 'position')))
      ));

      this.targetSwimlanes$ = targetBoardId$.pipe(switchMap(boardId => this._store.pipe((getSwimlanesByBoard(+boardId)))));

      const pickDefaultValue = (list$: Observable<any[]>, control: AbstractControl) => {
        return list$.pipe(filter((list: any[]) => list.length > 0)).subscribe((list: any[]) => {
          control.setValue(
            // == is important here, in option value in doc may be string value instead of integer
            list.some(item => control.value == item.id) ? list.find(item => control.value == item.id).id : list[0].id
          );
        });
      };

      this.subs.push(pickDefaultValue(this.targetSwimlanes$, this.form.get('targetSwimlane')));
      this.subs.push(pickDefaultValue(this.targetColumns$, this.form.get('targetColumn')));
      this.showTargetSwimlanes$ = this.targetSwimlanes$.pipe(map((swimlanes: Swimlane[]) => swimlanes.length > 1));
    }

    switch (this.actionName) {
      case 'archive':
        this.formServiceParams = {
          formObserver: {
            next: _ => {
              this.onClose();
            },
            error: _ => {
              this._toastr.success('Tasks could not be archived');
            },
            complete: () => {
              this._toastr.success('Tasks were archived successfully');
              this._routerNav.deactivatePopupOutlet();
            }
          },
          saveType: FormSaveType.edit,
          entityToEdit: COLUMN_PL,
          prepareFormValue: (formValue: any) => {
            return {
              id: formValue.subColumn ? formValue.subColumn : this.column.id,
              cards: [],
              swimlanesForArchived: formValue.swimlane ? formValue.swimlane : this.allCurrentBoardSwimlanes
            };
          }
        };
        break;
      case 'move':
      default:
        this.formServiceParams = {
          formObserver: {
            next: _ => {},
            error: _ => {},
            complete: () => {
              this._toastr.success('The moving of tasks started. This may take a few seconds');
              this.onClose();
              this._routerNav.deactivatePopupOutlet();
            }
          },
          saveType: FormSaveType.edit,
          entityToEdit: TASKS_MASS_MOVE,
          prepareFormValue: (formValue: any) => {
            return {
              id: formValue.subColumn ? formValue.subColumn : this.column.id,
              fromSwimlanesIds: formValue.swimlane ? formValue.swimlane : this.allCurrentBoardSwimlanes,
              toColumnId: formValue.targetColumn,
              toSwimlaneId: formValue.targetSwimlane,
              toPosition: formValue.targetPosition
            };
          }
        };
        break;
    }
    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.currentBoardSwimlanes) {
      this.allCurrentBoardSwimlanes = simpleChanges.currentBoardSwimlanes.currentValue.map(
        (swimlane: Swimlane) => swimlane.id
      );
    }
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  onSubmit() {
    if (this.form.valid) {
      this._unsavedFormChangesService.save();
      this._formService.submit();
    }
  }

  onClose() {
    this.close.emit();
  }

  private unsavedCacheKey() {
    return ['mass-tasks-action', this.actionName, this.boardId].join(':');
  }

  get currentSubColumns() {
    const subColumns = this.column.subColumns ? this.column.subColumns.data : [];
    return subColumns.filter(subC => {
      return !!this.tasks.find(task => task.column === subC.id);
    });
  }

  get defaultSubColumn() {
    return this.currentSubColumns[0] ? this.currentSubColumns[0].id : 0;
  }

  get defaultTargetPosition() {
    return this.availablePositions[0].value;
  }

  get defaultTargetColumn() {
    return this.column.subColumnsIds.length ? this.column.subColumnsIds[0] : this.column.id;
  }
}
