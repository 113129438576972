<button class="btn_green" (click)="onSwitchMenu()">Add...</button>
<div a-context-menu (close)="onSwitchMenu()" class="slim context_menu" *ngIf="showMenu" (click)="hideMenu()">
  <p a-context-menu-item (click)="onSwitchStartDate()">Start Date</p>
  <p a-context-menu-item (click)="onSwitchDueDate()">Due Date</p>
  <p a-context-menu-item (click)="onSwitchRepeat()">Repeat</p>
  <p a-context-menu-item *ngIf="isHoursEstimateAvailableForBoard" (click)="onOpenEstimatePopUpInHours()">Hours Estimate</p>
  <p a-context-menu-item *ngIf="isPointsEstimateAvailableForBoard" (click)="onOpenEstimatePopUpInPoints()">Story Points</p>
  <p a-context-menu-item (click)="onSwitchLogwork()">Log Work</p>
  <p a-context-menu-item [divider]="true"></p>
  <p a-context-menu-item (click)="onSwitchChecklistMenu()">Checklist</p>
  <p a-context-menu-item (click)="onSwitchAttachmentsPopUp()">Attachment</p>
  <p a-context-menu-item (click)="onSwitchLabelsPopUp()">Labels</p>
  <ng-container *ngIf="isNotSprint">
    <p a-context-menu-item [divider]="true"></p>
    <p a-context-menu-item (click)="onSwitchProjects()">Project</p>
    <p a-context-menu-item *ngIf="task.project" (click)="onSwitchVersions()">Version</p>
  </ng-container>
  <p a-context-menu-item (click)="onSwitchTaskLinkPopUp()">Task Link</p>
  <p a-context-menu-item *ngIf="isRoadmapBoard || isTaskOpenOnRoadmapThatLinked" (click)="onSwitchDependenciesPopUp()">Dependency</p>
</div>

<sidebar-start-date-popup
  *ngIf="showStartDatePopUp"
  (onClosePopup)="onSwitchStartDate()"
  [task]="task"
></sidebar-start-date-popup>
<sidebar-duedate-popup
  *ngIf="showDueDatePopUp"
  (onClosePopup)="onSwitchDueDate()"
  [task]="task"
></sidebar-duedate-popup>
<sidebar-estimate-popup
  *ngIf="showEstimatePopUp"
  (close)="onCloseEstimatePopUp()"
  [task]="task"
  [isStoryPointsMode]="setEstimateInStoryPoints"
></sidebar-estimate-popup>
<sidebar-logwork-popup
  *ngIf="showLogworkPopUp"
  [task]="task"
  (close)="onSwitchLogwork()"
></sidebar-logwork-popup>
<add-attachments
  *ngIf="showAttachmentsPopUp"
  (close)="onSwitchAttachmentsPopUp()"
  [taskId]="task.id"
></add-attachments>
<sidebar-labels-popup
  *ngIf="showLabelsPopUp"
  (close)="onSwitchLabelsPopUp()"
  [task]="task"
></sidebar-labels-popup>
<sidebar-project-popup
  *ngIf="showProjectsPopUp"
  (close)="onSwitchProjects()"
  [boardProjects]="boardProjects"
  [task]="task"
></sidebar-project-popup>
<sidebar-version-popup
  *ngIf="showVersionsPopUp"
  [versions]="projectVersions$ | async"
  [task]="task"
  (close)="onSwitchVersions()"
></sidebar-version-popup>

<task-links-add-popup
  *ngIf="showTaskLinkPopUp"
  (close)="onSwitchTaskLinkPopUp()"
  [taskId]="task.id"
  [boardId]="task.board"
></task-links-add-popup>

<sidebar-repeat-add-popup
  *ngIf="showRepeatPopUp"
  (close)="onSwitchRepeat()"
  [task]="task"
></sidebar-repeat-add-popup>

<task-links-add-popup
  *ngIf="showDependenciesPopUp"
  (close)="onSwitchDependenciesPopUp()"
  [isAddDependencies]="true"
  [taskId]="task.id"
  [boardId]="(selectedBoard$ | async) || task.board"
></task-links-add-popup>

<div a-context-popup *ngIf="showChecklistPopUp" (close)="onSwitchChecklistMenu()" [title]="'Add Checklist'">
  <checklist-form
          [task]="task"
          (addChecklist)="onAddCheckList($event)"
  ></checklist-form>
</div>
