<a-center-popup
  [title]="'Edit Scoring'"
  (close)="onClose()"
>
  <form [formGroup]="form">
    <div class="row">
      <label class="label">{{board.backlogScoreYLabel + ' (' + ScaleTypeLabel[board.backlogScoreYType] + ')'}}</label>
      <div [ngSwitch]="board.backlogScoreYType">
        <input *ngSwitchCase="ScaleType.basic"
               formControlName="backlogScoreY"
               type="number"
               min="0" max="100"
               [focus]="focusOn === 'y'"
        >
        <select *ngSwitchCase="ScaleType.poker"
                [focus]="focusOn === 'y'"
                formControlName="backlogScoreY"
        >
          <option [value]="0">?</option>
          <option [value]="1">1</option>
          <option [value]="2">2</option>
          <option [value]="3">3</option>
          <option [value]="5">5</option>
          <option [value]="8">8</option>
          <option [value]="13">13</option>
          <option [value]="20">20</option>
          <option [value]="40">40</option>
          <option [value]="100">100</option>
        </select>
        <select *ngSwitchCase="ScaleType.shirt"
                [focus]="focusOn === 'y'"
                formControlName="backlogScoreY"
        >
          <option [value]="0">?</option>
          <option [value]="1">S</option>
          <option [value]="2">M</option>
          <option [value]="3">L</option>
          <option [value]="4">XL</option>
          <option [value]="5">XXL</option>
        </select>
      </div>
    </div>
    <div class="row">
      <label class="label">{{board.backlogScoreXLabel + ' (' + ScaleTypeLabel[board.backlogScoreXType] + ')'}}</label>
      <div [ngSwitch]="board.backlogScoreXType">
        <input *ngSwitchCase="ScaleType.basic"
               formControlName="backlogScoreX"
               [focus]="focusOn === 'x'"
               type="number"
               min="0" max="100"
        >
        <select *ngSwitchCase="ScaleType.poker"
                [focus]="focusOn === 'x'"
                formControlName="backlogScoreX"
        >
          <option [value]="0">?</option>
          <option [value]="1">1</option>
          <option [value]="2">2</option>
          <option [value]="3">3</option>
          <option [value]="5">5</option>
          <option [value]="8">8</option>
          <option [value]="13">13</option>
          <option [value]="20">20</option>
          <option [value]="40">40</option>
          <option [value]="100">100</option>
        </select>
        <select *ngSwitchCase="ScaleType.shirt"
                [focus]="focusOn === 'x'"
                formControlName="backlogScoreX"
        >
          <option [value]="0">?</option>
          <option [value]="1">S</option>
          <option [value]="2">M</option>
          <option [value]="3">L</option>
          <option [value]="4">XL</option>
          <option [value]="5">XXL</option>
        </select>
      </div>
    </div>

    <div class="buttons_anti_scroll_wrapper">
      <button type="submit" a-button class="btn_primary"
              [pending]="(_formService.isPending$ | async)"
              (click)="onSubmit()">
        Save
      </button>
      <button type="button" a-button class="btn_text"
              (click)="onClose()">
        Cancel
      </button>
    </div>
  </form>
</a-center-popup>
