import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Board, Task } from '../../../interfaces';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../../shared/services/form-v2.service';
import { boardType, columnTypes, TASK_PL } from '../../../constants';
import { HandleResponseAction } from '../../../ngrx/actions/root.action';
import { Observer } from 'rxjs/index';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { JsonApiSingeModelResponse } from '../../../shared/services/app/web-socket/http-response';
import { getColumnsByBoard } from '../../../ngrx/reducers/column.reducer';
import { take } from 'rxjs/operators';

@Component({
  selector: 'sidebar-completeness',
  templateUrl: './sidebar-completeness.component.html',
  styleUrls: ['./sidebar-completeness.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarCompletenessComponent implements OnInit {
  @Input() task: Task;
  @Input() board: Board;
  @Input() editPermissions: boolean;

  formObserver: Observer<any> = {
    next: (resp: JsonApiSingeModelResponse<any>) => {
      this._store.dispatch(new HandleResponseAction(resp));
    },
    error: error => {
      console.log(error, 'error in percent input on opened task');
    },
    complete: () => {}
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.edit,
    entityToEdit: TASK_PL,
    formObserver: this.formObserver
  };

  form: FormGroup;

  constructor(private _fb: FormBuilder, public _formService: FormV2Service, private _store: Store<AppState>) {}

  ngOnInit() {
    this.form = this._fb.group({
      id: [this.task.id],
      percent: [this.task.percent || 0],
      column: [this.task.column]
    });
    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  onSubmit() {
    if (this.board.type !== boardType.roadmap) {
      this._formService.submit();
    } else {
      this._store.pipe(getColumnsByBoard(this.board.id), take(1)).subscribe(columns => {
        if (this.form.get('percent').value === 100) {
          const doneColumn = columns.filter(column => column.type === columnTypes.done)[0];
          this.form.get('column').patchValue(doneColumn.id);
        }
        this._formService.submit();
      });
    }
  }
}
