<div [ngClass]="{sidebar_block: true, editable: editPermissions}">
  <div [ngClass]="{version_overlay_link: true, editable: editPermissions}" (click)="onOpenVersionPopup()"></div>
  <div class="task_sidebar_edit_link" (click)="onOpenVersionPopup()">
    Edit
  </div>
  <h5 class="sidebar_block_title">Version</h5>

  <div class="version_title">{{version.name}}</div>

  <sidebar-version-popup
    *ngIf="editPermissions && isVersionPopupVisible"
    [versions]="projectVersions$ | async"
    [task]="task"
    (close)="onCloseVersionPopup()"
  ></sidebar-version-popup>

</div>
<div class="divider"></div>

