import { Component, ChangeDetectionStrategy, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

const defaultPadding = 5;
declare const window;

@Component({
  selector: '[a-context-menu]',
  template: `
    <a-context-container>
      <a-menu>
        <ng-content></ng-content>
      </a-menu>
    </a-context-container>
    <div class="overlay"><div></div></div>
  `,
  styleUrls: ['./a-context-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContextMenuComponent {
  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(private _eref: ElementRef) {}

  doClose() {
    this.close.emit(null);
  }

  @HostListener('click', ['$event'])
  onClick(event) {
    if (event.target === this._eref.nativeElement) {
      event.stopPropagation();
      event.stopImmediatePropagation();
      this.doClose();
      return false;
    }
  }
}
