/**
 * @deprecated
 * use DateTimeFormatterService instead
 */
export const DATES_FORMATS = {
  DATETIME_FORMAT_INPUT: 'M.D.YYYY h:mm A',
  DATE_FORMAT_INPUT: 'MM.DD.YYYY',
  DATETIME_FORMAT_OUTPUT: 'MMM D, YYYY [at] h:mm A',
  DATETIME_FORMAT_OUTPUT_WITHOUT_YEAR: 'MMM D [at] h:mm A',
  DATETIME_FORMAT_THIS_YEAR_OUTPUT: 'MMM D [at] h:mm A',
  DATE_FORMAT_OUTPUT: 'MMM D, YYYY',
  TIME_FORMAT: 'hh:mm A',
  OVERVIEW_ACTIVITY_TIME_FORMAT: '[at] h:mm A'
};
