<form [formGroup]="form"
      (ngSubmit)="onSubmit()"
>
  <form-errors></form-errors>
  <div class="form_group">
    <label for="link-type">Link Type</label>
    <select id="link-type" formControlName="type">
      <option *ngFor="let type of  possibleTypes$ | async" [ngValue]="type.id">{{type.name}}</option>
    </select>
  </div>
  <div class="form_group">
    <span class="label">Tasks</span>
    <div class="form_group" formArrayName="inwardTasks" *ngFor="let item of form.get('inwardTasks')['controls']; let i = index;">
      <!--[initialFocusFalse]="!i"-->
      <link-task-input
        formControlName="{{i}}"
        [initialFocusFalse]="false"
        [blackList]="[taskId]"
        [isMultipleUsage]="true"
        [enableRoadmapTasks]="true"
        [isOpenTasksOnBlankPage]="true"
        [wideWidth]="true"
        [enableCreateBtn]="true"
        tabindex="-1"
        (createNewTask)="onOpenCreateTaskPopup($event)"
      ></link-task-input>
    </div>
    <help-block [control]="form.get('inwardTasks')" [messages]="{arrayNotEmpty: 'Choose at least one task.'}"></help-block>
    <span class="pseudo_link" (click)="onAddTaskInput()">+ Add Task</span>
  </div>
  <br>
  <button a-button class="btn_primary button" type="submit" (click)="onSubmit()">Save</button>
  <button a-button class="btn_text button" type="button" (click)="onCancel()">Cancel</button>
</form>

<div *ngIf="createTaskPopup"
     class="add-task-popup"
     a-context-popup
     [attr.draggable-disabled]="true"
     [title]="'Add New Task'"
     (close)="onCloseCreateTaskPopup()"
>
  <global-add-task-form
    [hideSaveAndEdit]="true"
    [showColumn]="true"
    [showSwimlane]="true"
    [cacheKey]="'addTaskFromTaskLink'"
    (close)="onCloseCreateTaskPopup()"
    (taskCreated)="onTaskCreated($event)"
  ></global-add-task-form>
</div>
