<form [formGroup]="form">
  <div class="composite_row">
    <div class="wide_block">
      <label for="name">Field Name</label>
      <input [focus]="true" id="name" name="name" [formControlName]="'name'">
    </div>
    <div>
      <label for="type">Field Type</label>
      <select id="type" name="type" [formControlName]="'type'">
        <option [ngValue]="CustomFieldTypes.text">Text</option>
        <option [ngValue]="CustomFieldTypes.number">Number</option>
        <option [ngValue]="CustomFieldTypes.select">Drop-down</option>
        <option [ngValue]="CustomFieldTypes.date">Date</option>
      </select>
    </div>
  </div>
  <div class="description_row" *ngIf="isDescription;else toggler">
    <label for="description">Description</label>
    <textarea autosize [formControlName]="'description'" name="description" id="description" [focus]="true" checkTouch></textarea>
    <help-block [control]="form.get('description')"></help-block>
  </div>
  <dropdown-options-setter [isWhite]="!!customField" [dragType]="dragType" (startDrag)="onStartDrag($event)" *ngIf="form.get('type').value === CustomFieldTypes.select" [formControlName]="'values'"></dropdown-options-setter>
  <div class="row_btn">
    <button a-button class="btn_primary" type="submit" (click)="onSubmit()" [pending]="_formService.isPending$ | async">{{customField ? 'Save' : 'Add'}}</button>
    <button a-button class="btn_text" type="button" (click)="onClose()">Cancel</button>
    <button *ngIf="!!customField" a-button class="btn_text del_btn" type="button" (click)="onDelete()">
      <svg width="12" height="14">
        <use xlink:href="#bucket"></use>
      </svg>
    </button>
  </div>
</form>

<ng-template #toggler>
  <div>
    <span class="description_toggler" (click)="onShowDescription()">Add Description</span>
  </div>
</ng-template>


<div a-confirmation-popup
     [confirmationTitle]="'Delete Custom Field'"
     [actionButtonText]="'Delete'"
     [isPending]="isDeletePending"
     *ngIf="isDeleting"
     (confirm)="onConfirmAction()"
     (close)="onCloseConfirmPopup()"
>
  <p>Are you sure you want to delete this custom field?</p>
</div>
