import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WORKLOG_PL } from '../../../constants';
import { formatTimeToSecond, getFormattingHtmlContent, secondsToEdit } from '../../../../helpers';
import { CustomValidators } from '../../../shared/validators/custom-validators';
import * as moment from 'moment-mini-ts';
import { HotKey, HotSpaceAndEnter } from '../../../shared/directives';
import { WorkLog } from '../../../interfaces';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../../shared/services/form-v2.service';
import { Observer } from 'rxjs';
import { HandleResponseAction } from '../../../ngrx/actions/root.action';
import { JsonApiSingeModelResponse } from '../../../shared/services/app/web-socket/http-response';
import { AppState } from '../../../ngrx/state';
import { Store } from '@ngrx/store';
import { SegmentService } from '../../../atlaz-bnp/services/intergations/segment/segment.service';

@Component({
  selector: 'task-logwork',
  templateUrl: './task-logwork.component.html',
  styleUrls: ['./task-logwork.component.scss'],
  providers: [FormV2Service]
})
export class TaskLogworkComponent implements OnInit {
  @Input() taskId: number;
  @Input() worklog: WorkLog;
  @Output() closePopupEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  formObserver: Observer<any> = {
    next: (resp: JsonApiSingeModelResponse<any>) => {
      this._store.dispatch(new HandleResponseAction(resp));
      this._segment.track('WorkLogAdded', { timeValue: this.logWorkForm.value.time });
      this.onClose();
    },
    error: error => {},
    complete: () => {}
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.add,
    entityToEdit: WORKLOG_PL,
    formObserver: this.formObserver,
    prepareFormValue: () => {
      const workLogData = {
        loggedTime: formatTimeToSecond(this.logWorkForm.value.time),
        logDate: this.logWorkForm.value.logDate.unix(),
        logComment: this.logWorkForm.value.comment
      };

      if (this.worklog) {
        workLogData['id'] = this.worklog.id;
      } else {
        workLogData['task'] = this.taskId;
      }

      return workLogData;
    }
  };

  public error = false;
  public logWorkForm: FormGroup;

  constructor(
    private _fb: FormBuilder,
    public _formService: FormV2Service,
    private _store: Store<AppState>,
    private _segment: SegmentService
  ) {}

  get postHotKeys(): HotKey[] {
    return HotSpaceAndEnter;
  }

  ngOnInit(): any {
    this.initLogWorkForm();
  }

  initLogWorkForm() {
    let initialData;
    if (this.worklog) {
      initialData = {
        logDate: moment.unix(this.worklog.logDate),
        time: this.worklog.loggedTime ? secondsToEdit(this.worklog.loggedTime) : '',
        comment: getFormattingHtmlContent(this.worklog.logComment)
      };
    } else {
      initialData = { logDate: moment(), time: '', comment: '' };
    }

    this.logWorkForm = this._fb.group({
      logDate: [initialData.logDate, CustomValidators.composeAnd([Validators.required])],
      time: [initialData.time, Validators.required],
      comment: [initialData.comment]
    });

    this.formServiceParams.saveType = this.worklog ? FormSaveType.edit : FormSaveType.add;
    this._formService.initFormParams(this.logWorkForm, this.formServiceParams);
  }

  onSubmit() {
    this._formService.markAsDirty();
    if (formatTimeToSecond(this.logWorkForm.value.time) === 0) {
      this.logWorkForm.get('time').setErrors([{ parseTimeFailed: true }]);
    }
    if (this.logWorkForm.invalid) {
      return false;
    }
    this._formService.submit();
    return false;
  }
  onClose() {
    this.closePopupEvent.emit(true);
  }
}
