import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { boardType } from '../../../constants';

@Component({
  selector: 'boards-type-filter',
  templateUrl: './boards-type-filter.component.html',
  styleUrls: ['./boards-type-filter.component.scss']
})
export class BoardsTypeFilterComponent implements OnInit {
  @Output() pick = new EventEmitter<string[]>();
  @Output() select = new EventEmitter<string>();

  boardType = boardType;

  all = true;
  noOne = false;
  hasFilter = false;
  visible = false;
  selectValue = 'all';

  value: { [type: string]: boolean } = {};

  public ACTIVE = 'ACTIVE';
  public CLOSED = 'CLOSED';

  ngOnInit(): any {
    Object.keys(this.boardType).map(type => (this.value[type] = true));
  }

  onChange(event: UIEvent) {
    event.stopPropagation();
    event.preventDefault();
    const input = <HTMLInputElement>event.target;
    this.value[input.name] = input.checked;
    this.emmit();
  }

  emmit() {
    this.all = Object.keys(this.value).every(type => this.value[type]);
    this.noOne = !Object.keys(this.value).some(type => this.value[type]);
    this.hasFilter = (!this.all && !this.noOne) || this.selectValue !== 'all';
    this.pick.emit(Object.keys(this.value).filter(type => this.value[type]));
  }
  onToggle() {
    this.visible = !this.visible;
  }

  onToggleAll() {
    Object.keys(this.boardType).map(type => (this.value[type] = !this.all));
    this.emmit();
  }

  onSelectValue(value: string) {
    this.emmit();
    this.select.emit(value);
  }
}
