<input (keyup)="0" #input_search_box class="menu_form_input"
       (input)="onChange($event.target.value)"
       [focus]="(tasks$ | async).length > 0"
       [ngClass]="{hide_element: !(tasks$ | async).length && (input_search_box.value.length === 0)}"
       placeholder="Search task...">
<div class="center" *ngIf="!((tasks$ | async)?.length) && !(_paginatorService.pending$ | async)  && (input_search_box.value.length === 0)">
  <p>There are no released tasks.</p>
</div>
<div class="center" *ngIf="!((tasks$ | async)?.length) && !(_paginatorService.pending$ | async) && (input_search_box.value.length !== 0)">
  <p>No results</p>
</div>
<div *ngFor="let task of tasks$ | async">
  <task-preview class="hide_quick_edit" [task]="task"></task-preview>
</div>
<div class="center load_more">
  <a-infinite-loader
    *ngIf="_paginatorService.hasMore$ | async"
    [pending]="_paginatorService.pending$ | async"
    (loadMore)="onLoadMore()"
  >
  </a-infinite-loader>
</div>

