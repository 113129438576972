<div class="resize" *ngIf="isNotGuest" (mousedown)="onStartResize($event, RESIZING.left)"><icon-resize color="#fff"></icon-resize></div>
<div class="overlay" [style.background-color]="backgroundColor$ | async" draggable="false"></div>
<div class="overlay done_status" [style.width.%]="doneStatus$ | async" draggable="false"></div>
<div class="bar_title" [routerLink]="item.url" draggable="false" [title]="barTitle$ | async">{{barTitle$ | async}}</div>
<div class="color_picker_activator" *ngIf="isNotGuest" (click)="onToggleColorPicker()"></div>
<div class="color_picker" *ngIf="isColorPickerVisible">
  <div class="click_out_wrap" [clickOut]="true" (clickOutEvent)="onClickOut()" [isClearClickOutStack]="true">
    <div class="color_picker_item"
      [style.background-color]="defaultColor"
      [ngClass]="{active: !item.task.roadmapColor}"
      (click)="setBgColor('')"
    ></div>
    <div *ngFor="let color of availableColors"
         class="color_picker_item"
         [style.background-color]="color"
         (click)="setBgColor(color)"
         [ngClass]="{active: item.task.roadmapColor === color}"
    ></div>
  </div>
</div>
<div class="resize" *ngIf="isNotGuest" (mousedown)="onStartResize($event, RESIZING.right)"><icon-resize color="#fff"></icon-resize></div>
