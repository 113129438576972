
import {map, filter, switchMap, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { isNotPresent } from '../../../../helpers';
import { AppState } from '../../../ngrx/state';
import { RouterNavigateService } from '../../../shared/services/router-navigate.service';
import * as gitlabAssocRepo from '../../git/store/gitlab-associated-repository/gitlab-associated-repository.actions';
import * as gitlabCompany from '../../git/store/gitlab-company/gitlab-company.actions';
import * as fromGitlabCompany from '../../git/store/gitlab-company/gitlab-company.reducer';
import * as gitlabRepo from '../../git/store/gitlab-repository/gitlab-repository.actions';

@Injectable()
export class GitlabCompanyExistsGuard implements CanActivate {
  constructor(private routerNav: RouterNavigateService, private store: Store<AppState>) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.store.dispatch(new gitlabCompany.GetIndex());

    const loadedEvent$ = this.store.select(fromGitlabCompany.isFirstLoading).pipe(filter(isNotPresent));
    const isEmpty$ = loadedEvent$.pipe(switchMap(_ => this.store.select(fromGitlabCompany.isEmpty)),take(1),);

    return isEmpty$.pipe(map(this.navigateSideEffect.bind(this)));
  }

  private navigateSideEffect(isEmpty: boolean): true {
    if (isEmpty) {
      this.routerNav.navigateToGitlabAddCompany();
      return true;
    }

    this.store.dispatch(new gitlabAssocRepo.GetIndex());
    this.store.dispatch(new gitlabRepo.GetIndex());

    return true;
  }
}
