import { ESGithubPullRequest } from '../../models/git-pull-request';
import { Action } from '../../../../ngrx/actions/unsafe-action';

export const UPDATED = '[github-pullrequest.actions] UPDATED';
export const GET_TASK_PULL_REQUEST = '[github-pullrequest.actions] GET_TASK_PULL_REQUEST';
export const GET_TASK_PULL_REQUEST_COMPLETE = '[github-pullrequest.actions] GET_TASK_PULL_REQUEST_COMPLETE';
export const DELETE_TASK_PULL_REQUEST = '[github-pullrequest.actions] DELETE_TASK_PULL_REQUEST';
export const GET_TASK_PULLREQUEST_FAIL = '[github-pull-request.actions] GET_TASK_PULLREQUEST_FAIL';

export class GetTaskPullRequest implements Action {
  readonly type = GET_TASK_PULL_REQUEST;

  constructor(public payload: { task: number }) {}
}

export class Updated implements Action {
  readonly type = UPDATED;

  constructor(public payload: ESGithubPullRequest) {}
}

export class GetTaskPullRequestComplete implements Action {
  readonly type = GET_TASK_PULL_REQUEST_COMPLETE;

  constructor(public payload: { taskId: number; entities: ESGithubPullRequest }) {}
}

export class GetTaskPullRequestFail implements Action {
  readonly type = GET_TASK_PULLREQUEST_FAIL;
}

export class DeleteTaskPullRequest implements Action {
  readonly type = DELETE_TASK_PULL_REQUEST;

  constructor(public payload: { id: number; task: number }) {}
}

// prettier-ignore
export type Actions
  = GetTaskPullRequest
  | Updated
  | GetTaskPullRequestComplete
  | DeleteTaskPullRequest
  | GetTaskPullRequestFail
  ;
