import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../../services/form-v2.service';
import { Observer } from 'rxjs';
import { VERSION_PL } from '../../../../constants';
import { fromProject } from '../../../../ngrx/reducers/project.reducer';
import { AppState } from '../../../../ngrx/state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { Project } from '../../../../interfaces';

@Component({
  selector: 'add-project-version-form',
  templateUrl: './add-project-version-form.component.html',
  styleUrls: ['./add-project-version-form.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddProjectVersionFormComponent implements OnInit {
  @Input() project: number;
  @Input() possibleProjects: number[];
  @Output() close = new EventEmitter();

  public showProjectsSelector = false;
  public projects$: Observable<Project[]>;
  public form: FormGroup;

  formObserver: Observer<any> = {
    next: resp => {
      this.onClose(resp.data.id);
    },
    error: error => {},
    complete: () => {}
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.add,
    entityToEdit: VERSION_PL,
    formObserver: this.formObserver,
    prepareFormValue: formValue => {
      const result = {
        name: formValue['name'],
        project: formValue['project']
      };
      if (this.form.value.plannedDate) {
        result['plannedDate'] = this.form.value.plannedDate.unix();
      }
      return result;
    }
  };

  constructor(private _fb: FormBuilder, public _formService: FormV2Service, private _store: Store<AppState>) {}

  ngOnInit() {
    this.showProjectsSelector = Array.isArray(this.possibleProjects) && this.possibleProjects.length > 1;
    this.projects$ = this._store.select(fromProject.getByIds(this.possibleProjects));
    this.form = this._fb.group({
      plannedDate: [null],
      project: [this.project, Validators.required],
      name: ['', Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(50)])]
    });
    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  onSubmit() {
    this._formService.markAsDirty();
    if (this.form.valid) {
      return this._formService.submit();
    }
  }

  onClose(versionId?: number) {
    this.close.emit(versionId);
  }
}
