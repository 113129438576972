<div *ngIf="(taskLinks$ | async).length > 0">
  <h2>LINKED TASKS ({{(linksArray$ | async)?.length}})</h2>
  <div *ngFor="let linkType of (taskLinks$ | async)">
    <h3>{{linkType.name}} ({{linkType.links.length}})</h3>
    <task-link-item *ngFor="let link of linkType.links" [taskLinkId]="link.id" [taskId]="link.task" [externalLink]="link.externalLink" [editPermissions]="editPermissions"></task-link-item>
  </div>
  <span *ngIf="editPermissions" class="pseudo_link" (click)="onToggleAddPopUp()">+ Add Task</span>
  <task-links-add-popup
    *ngIf="showAddPopUp && editPermissions"
    (close)="onToggleAddPopUp()"
    [taskId]="taskId"
    [boardId]="boardId"
  ></task-links-add-popup>
</div>
