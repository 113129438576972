import { createSelector } from '@ngrx/store';
import { AppState, REPORT_MEMBER_FILTER } from '../../state';
import * as reportMemberFilter from '../../actions/report-filters/report-members-filter.action';
import { ReportMemberFilterActionTypes } from '../../actions/report-filters/report-members-filter.action';

export interface ReportMembersFilter {
  selectedAll: boolean,
  membersIds: number[];
}

const initState: ReportMembersFilter = {
  selectedAll: false,
  membersIds: []
};

export function reducer(
  state = initState,
  action: reportMemberFilter.Actions
): ReportMembersFilter {
  switch (action.type) {
    case ReportMemberFilterActionTypes.APPLY: {
      state = action['payload'];
      return state;
    }

    case ReportMemberFilterActionTypes.RESET: {
      state = initState;
      return initState;
    }

    default: {
      return state;
    }
  }
}

export namespace fromReportMember {
  export const getState = (state: AppState) =>
    <ReportMembersFilter>state[REPORT_MEMBER_FILTER];

  export const isActive = createSelector(
    getState,
    state => state.membersIds.length > 0
  );
  export const getIds = createSelector(
    getState,
    state => state.membersIds
  );
  export const isSelectedAll = createSelector(
    getState,
    state => state.selectedAll
  );
}
