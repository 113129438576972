import { Action } from '../unsafe-action';
import { DaysRange } from "./task-default-filter.actions";

export enum DefaultTasksTimeOnColumnFilterActionTypes {
  TOGGLE_ITEM_ID = '[DEFAULT_TIME_ON_COLUMN_FILTER] TOGGLE_TIME_ON_COLUMN',
  RESET = '[DEFAULT_TIME_ON_COLUMN_FILTER] RESET'
}

export class DefaultTaskTimeOnColumnFilterToggleAction implements Action {
  readonly type = DefaultTasksTimeOnColumnFilterActionTypes.TOGGLE_ITEM_ID;

  constructor(public payload: DaysRange) {}
}

export class DefaultTimeOnColumnFilterResetAction implements Action {
  readonly type = DefaultTasksTimeOnColumnFilterActionTypes.RESET;
}

// prettier-ignore
export type Actions
  = DefaultTaskTimeOnColumnFilterToggleAction
  | DefaultTimeOnColumnFilterResetAction
  ;
