import { FormControl, ValidatorFn } from '@angular/forms';

export function includesValidator(values: any[]): ValidatorFn {
  return (c: FormControl) => {
    return values.includes(c.value)
      ? null
      : {
          includesValidator: {
            valid: false
          }
        };
  };
}
