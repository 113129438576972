export enum Features {
  AdvancedPrioritization = 'ADVANCED_PRIORITIZATION',
  AdvancedSearch = 'ADVANCED_SEARCH',
  Backlog = 'BACKLOG_BOARD',
  Bitbucket = 'BITBUCKET_INTEGRATION',
  BrandedWorkspace = 'BRANDED_WORKSPACE',
  CanAddMember = 'CAN_ADD_MEMBER',
  CanAddTask = 'CAN_ADD_TASK',
  CanUploadAttachment = 'CAN_UPLOAD_ATTACHMENT',
  CanChangeAuthenticationSettings = 'CAN_CHANGE_AUTHENTICATION_SETTINGS',
  Dropbox = 'DROPBOX_INTEGRATION',
  GoogleDrive = 'GDRIVE_INTEGRATION',
  Github = 'GITHUB_INTEGRATION',
  Gitlab = 'GITLAB_INTEGRATION',
  Harvest = 'HARVEST',
  Ice = 'ICE_ICE',
  Rice = 'ICE_RICE',
  ICalendar = 'I_CALENDAR',
  MultipleProjects = 'MULTIPLE_PROJECTS',
  PriorityChart = 'PRIORITY_CHARTS',
  Roadmap = 'ROADMAP_BOARD',
  SprintsHistory = 'RELEASED_SPRINTS',
  Slack = 'SLACK_INTEGRATION',
  TimesheetReport = 'TIMESHEET_REPORT',
  SprintsVelocityChart = 'VELOCITY_REPORT'
}
