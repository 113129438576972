import { DefaultUrlSerializer, PRIMARY_OUTLET, UrlSegmentGroup, UrlTree } from '@angular/router';

import * as PATH from './path.routing';
import { OPENED_TASK_OUTLET } from './path.routing';

export class AtlazBnPUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    const tree = super.parse(url);
    if (tree.root.children[PRIMARY_OUTLET]) {
      const primaryOutlet = tree.root.children[PRIMARY_OUTLET];
      if (primaryOutlet.segments.length > 1) {
        const popupOutletIndex = primaryOutlet.segments.findIndex(
          (segment, index, all) => segment.path === PATH.TASKS && index < all.length
        );
        if (popupOutletIndex !== -1) {
          tree.root.children[OPENED_TASK_OUTLET] = new UrlSegmentGroup(
            primaryOutlet.segments.splice(popupOutletIndex, 2),
            {}
          );
        }
      }
    }
    return tree;
  }

  serialize(tree: UrlTree): string {
    const outlets = Object.keys(tree.root.children).reduce((acc, outletName) => {
      if (outletName !== OPENED_TASK_OUTLET) {
        acc[outletName] = tree.root.children[outletName];
      }
      return acc;
    }, {});
    const popupOutlet = tree.root.children[OPENED_TASK_OUTLET];
    const myRoot = new UrlSegmentGroup([], outlets);
    if (popupOutlet) {
      if (!tree.root.children[PRIMARY_OUTLET]) {
        myRoot.children[PRIMARY_OUTLET] = new UrlSegmentGroup(popupOutlet.segments, {});
      } else {
        myRoot.children[PRIMARY_OUTLET] = new UrlSegmentGroup(
          [...myRoot.children[PRIMARY_OUTLET].segments, ...popupOutlet.segments],
          {}
        );
      }
    }
    const myTree = Object.assign({}, tree);
    myTree.root = myRoot;
    return super.serialize(myTree);
  }
}
