<div class="checklist">
  <inplace-edit [initialValue]="checklist.name"
                [displayValue]="(displayChecklistName$ | async)"
                [validationMessage]="'Please enter correct checklist name'"
                (formSubmit)="onChangeChecklistTitle($event)"
                [isEditable]="editPermissions"
                [submitOnEnter]="true"
                aDraggableItem="true"
                [attr.draggable-disabled]="!editPermissions"
                [item]="checklist"
                [itemType]="dragCheckListType"
                axis="y"
                [class.gu-transit]="(draggingCheckListId$ | async) === checklist.id"
                [class.no-hover]="(draggingCheckListId$ | async)"
                class="checklist_inplace">
  </inplace-edit>
  <div class="details">
    <div class="checklist_control_container">
      <h4 *ngIf="editPermissions" (click)="onToggleDeleteConfirm()">Delete</h4>
      <h4 (click)="onHideCompleted()"
          class="hide_items"
      >
        <ng-template
          [ngIf]="hideCompleted$ | async" [ngIfElse]="hide_message"
        >Show completed items ({{itemsCompletedCount$ | async}})
        </ng-template>
        <ng-template #hide_message>Hide completed items</ng-template>
      </h4>
      <div a-context-popup class="delete_checklist_confirm" [title]="'Delete Checklist'" *ngIf="isDeleteConfirm"
           (close)="onToggleDeleteConfirm()"
      >
        <p class="delete_message">The changes can not be reverted.</p>
        <div a-button class="btn_warn" (click)="onDeleteChecklist()">Delete</div>
        <div a-button (click)="onToggleDeleteConfirm()" class="btn_secondary">Cancel</div>
      </div>
    </div>

    <checklist-progress
      [checkboxesCount]="itemsCount$ | async"
      [completedCheckboxes]="itemsCompletedCount$ | async"
    ></checklist-progress>

    <div class="checkbox_container">
      <div *ngFor="let checklistItem of visibleItems$ | async"
           [attr.checkbox]="checklistItem.id"
           class="checklist_item_container"
           aDraggableItem="true"
           [attr.draggable-disabled]="!editPermissions"
           [item]="checklistItem"
           [itemType]="dragItemType"
           [metaData]="{checklist: checklist.id}"
           axis="y"
           [class.gu-transit]="(draggingItemId$ | async) === checklistItem.id"
      >
        <checklist-item
          [checkbox]="checklistItem"
          [checklist]="checklist"
          [class.gu-transit]="(draggingItemId$ | async) === checklistItem.id"
          [class.no-hover]="(draggingItemId$ | async)"
          [editPermissions]="editPermissions"
          (editCheckbox)="onEditCheckbox($event)"
          (deleteCheckbox)="onDeleteCheckbox($event)"
        ></checklist-item>
      </div>
    </div>

    <div class="add_item" (click)="addItemForm = true" *ngIf="editPermissions && !addItemForm && !addItemTaskForm">Add an item</div>
    <div class="add_item" (click)="addItemTaskForm = true" *ngIf="editPermissions && !addItemForm && !addItemTaskForm">Add a task</div>

    <div class="add_checkbox_form_container"
         *ngIf="addItemForm"
    >
      <checklist-item-form
        [checklistId]="checklist.id"
        (submitForm)="onAddCheckbox($event)"
        (cancel)="onCancel()"
      ></checklist-item-form>
    </div>

    <div class="add_checkbox_task_form_container"
         *ngIf="addItemTaskForm"
    >
      <checklist-task-item-form
        [taskId]="checklist.task"
        [checklistId]="checklist.id"
        (submitForm)="onAddCheckbox($event)"
        (cancel)="onCancelTask()"
      ></checklist-task-item-form>
    </div>
  </div>
</div>
