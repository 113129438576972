import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { BOARD_ACTIVITY_PL } from '../../constants';

import { BoardActivityActionTypes, BoardActivityGetCompleteAction } from '../actions/board-activity.actions';
import { HttpQueryParam } from '../../interfaces';
import { defaultErrorHandler } from './root.effect';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { HandleResponseAction } from '../actions/root.action';
import { toDashedFromCamelCase } from '../../../helpers/string';
import { AppState } from '../state';
import { Store } from '@ngrx/store';

@Injectable()
export class BoardActivityEffects {
  @Effect()
  getList$ = this.actions$.ofType(BoardActivityActionTypes.GET).pipe(
    switchMap(({ type, payload: httpParams }: { type: string; payload: HttpQueryParam }) => {
      return this._atlazApi
        .get(toDashedFromCamelCase(BOARD_ACTIVITY_PL), httpParams)
        .pipe(
          tap(() => this._store.dispatch(new BoardActivityGetCompleteAction())),
          map(resp => new HandleResponseAction(resp)),
          catchError(defaultErrorHandler(type, httpParams))
        );
    })
  );

  constructor(private actions$: Actions, private _atlazApi: AtlazApiV2Service, private _store: Store<AppState>) {}
}
