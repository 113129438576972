<a [attr.draggable]="draggable"
   [attr.taskId]="task.id"
   [routerLink]="taskUrl$ | async">
  <div
    [attr.draggable]="draggable"
    [attr.taskId]="task.id"
    [class.task_title]="!itemPoints"
    [class.task_title_for_points]="itemPoints"
  >
    {{task.title}}
  </div>
</a>
<div class="score_indicator">
  <backlog-inplace-edit
    class="indicator_value"
    (formSubmit)="onSaveBacklogValue($event, scoreYField)"
    [scaleType]="scaleTypeY"
    [initialValue]="task.backlogScoreY"
    [isEditable]="draggable"
    [submitOnEnter]="true"
  >
  </backlog-inplace-edit>
  <backlog-inplace-edit
    #backlog_score_x_inplace
    class="indicator_value"
    (formSubmit)="onSaveNewValues($event, scoreXField)"
    [scaleType]="scaleTypeX"
    [initialValue]="task.backlogScoreX"
    [isEditable]="draggable"
    [submitOnEnter]="true"
  >
  </backlog-inplace-edit>
</div>
