import { take } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormComponent,
  FormSaveType,
  FormServiceParams,
  FormV2Service
} from '../../../../shared/services/form-v2.service';
import { Observer } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../ngrx/state';
import { BOARD_PL, BoardFirstOpenType, boardType, ScoringType } from '../../../../constants';
import { SegmentService } from '../../../../atlaz-bnp/services/intergations/segment/segment.service';
import { Board } from '../../../../interfaces';
import {
  JsonApiSingeModelResponse,
  jsonApiToSingleEntity
} from '../../../../shared/services/app/web-socket/http-response';
import { RouterNavigateService } from '../../../../shared/services/router-navigate.service';
import { fromBoardsTemplates } from '../../../../ngrx/reducers/boards-template.reducer';
import { HandleResponseAction } from '../../../../ngrx/actions/root.action';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { AppUrls } from '../../../../app-urls';

const TEMPLATE_CHOOSER_ERR_TEXT = 'No template chosen';
@Component({
  selector: 'new-board-context-popup',
  templateUrl: './new-board-context-popup.component.html',
  styleUrls: ['./new-board-context-popup.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * @deprecated use {@link NewBoardCombinedComponent} instead
 */
export class NewBoardContextPopupComponent implements OnInit, FormComponent {
  @Input() fromTemplateOnInit = false;
  @Input() source = 'header';
  @Output() close = new EventEmitter();

  public title: string;
  public selectedTemplate: number;
  public showTemplateChooseErr$ = new BehaviorSubject('');
  public isTemplateChoosing$ = new BehaviorSubject(this.fromTemplateOnInit);

  formObserver: Observer<any> = {
    next: (resp: JsonApiSingeModelResponse<any>) => {
      this._store.dispatch(new HandleResponseAction(resp));
      const board: Board = jsonApiToSingleEntity(resp);
      if (this.form.value.template) {
        this._store
          .select(fromBoardsTemplates.getById(+this.form.value.template))
          .pipe(take(1))
          .subscribe(template => {
            this._segment.boardCreated(board.type, this.source, template.name, template.id);
          });
      } else {
        this._segment.boardCreated(board.type, this.source);
      }

      if (board.type === boardType.backlog && board.boardTemplateViewType === BoardFirstOpenType.scoring) {
        this._routerNav
          .navigate(
            board.scoringType === ScoringType.basic
              ? AppUrls.getUrlBacklogChart(board.id)
              : AppUrls.getUrlBacklogScoring(board.id)
          )
          .then(() => this.onClose());
      } else {
        this._routerNav.navigateToBoard(board.id).then(() => this.onClose());
      }
    },
    error: _ => {},
    complete: () => {}
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.add,
    entityToEdit: BOARD_PL,
    formObserver: this.formObserver,
    prepareFormValue: formValue => {
      const result = {
        name: formValue['name'],
        collections: formValue['collections'],
        projects: formValue['projects']
      };
      if (formValue['template']) {
        result['template'] = formValue['template'];
      } else {
        result['type'] = formValue['type'];
      }
      return result;
    }
  };

  form: FormGroup;

  constructor(
    public _formService: FormV2Service,
    private _store: Store<AppState>,
    private _segment: SegmentService,
    private _routerNav: RouterNavigateService
  ) {}

  ngOnInit() {
    this.isTemplateChoosing$.next(this.fromTemplateOnInit);
  }

  onClose() {
    this.close.emit();
  }

  onSubmit() {
    this._formService.initFormParams(this.form, this.formServiceParams);
    this._formService.markAsDirty();
    this.form.markAsDirty();
    if (this.form.valid) {
      this._formService.submit();
    }
  }

  onChangeSelectedTemplate(templates: number[]) {
    this.selectedTemplate = Array.isArray(templates) ? templates[0] : undefined;
  }

  onUseTemplates() {
    if (this.selectedTemplate) {
      this.showTemplateChooseErr$.next('');
      this.isTemplateChoosing$.next(false);
    } else {
      this.showTemplateChooseErr$.next(TEMPLATE_CHOOSER_ERR_TEXT);
    }
  }
}
