import { Action } from '../unsafe-action';

export enum DefaultTasksVersionsFilterActionTypes {
  TOGGLE_ITEM_ID = '[DEFAULT_VERSIONS_FILTER] TOGGLE_VERSION_IDS',
  RESET = '[DEFAULT_VERSIONS_FILTER] RESET'
}

export class DefaultTaskVersionsFilterToggleItemAction implements Action {
  readonly type = DefaultTasksVersionsFilterActionTypes.TOGGLE_ITEM_ID;

  constructor(public payload: number[]) {}
}

export class DefaultVersionsFilterResetAction implements Action {
  readonly type = DefaultTasksVersionsFilterActionTypes.RESET;
}

// prettier-ignore
export type Actions
  = DefaultTaskVersionsFilterToggleItemAction
  | DefaultVersionsFilterResetAction
  ;
