import { distinctUntilChanged, map, pluck, take } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AppUrls } from '../../app-urls';
import { AppState, LEFT_MENU } from '../../ngrx/state/';
import { ComponentSetVisibleAction } from '../../ngrx/actions/component.actions';
import { Store } from '@ngrx/store';
import { SearchInLeftMenuService } from '../../shared/services/app/search-in-left-menu.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Collection } from '../../interfaces';
import { getUnseenUserNotificationsCount } from '../../ngrx/reducers/notification.reducer';
import { Board } from '../../interfaces/';
import { GlobalSearchRememberBackUrlAction, GlobalSearchResetAction } from '../../ngrx/actions/global-search.actions';
import { Features } from '../../libs/paywall/features.constants';
import { Location } from '@angular/common';
import { PaywallService } from '../../libs/paywall/paywall.service';
import { RouterNavigateService } from '../../shared/services/router-navigate.service';
import { AuthService } from '../../shared/services/app/auth.service';
import { OpenedStateLeftMenuItemsService } from '../shared/services/opened-state-left-menu-items.service';
import { Subscription } from 'rxjs/index';
import { PermissionsService } from '../../permissions/permissions.service';

const ALL_COLLECTIONS = 'all-collections';
const ALL_PROJECTS = 'all-projects';

@Component({
  selector: 'left-menu-body',
  templateUrl: './left-menu-body.component.html',
  styleUrls: ['./left-menu-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeftMenuBodyComponent implements OnInit, OnDestroy {
  @Input() isNotGuest: boolean;
  @Output() editCollection = new EventEmitter();

  public appUrls = AppUrls;
  public collections$: Observable<[{ collection: Collection; visibleBoard: Board[] }]>;
  public projects$: Observable<Observable<[{ project: Collection; visibleBoard: Board[] }]>>;
  public unassign$: Observable<Board[]>;

  public maxNotificationsCount = 99;

  public isSearchActive$: Observable<boolean>;
  public isMatchingSearch$: Observable<boolean>;
  public stringSearch$: BehaviorSubject<string>;
  public notificationsCount$: Observable<number | string>;

  public isCollectionsOpened: boolean;
  public isProjectsOpened: boolean;

  public openedStateCollectionListKey: string;
  public openedStateProjectListKey: string;

  public isSuperUser$: Observable<boolean>;

  public subs: Subscription[] = [];

  constructor(
    private _store: Store<AppState>,
    private _searchInLeftMenuService: SearchInLeftMenuService,
    private _openedStateLeftMenuItemsService: OpenedStateLeftMenuItemsService,
    private _routerNav: RouterNavigateService,
    private _location: Location,
    private _authService: AuthService,
    private _paywall: PaywallService,
    private _permissions: PermissionsService
  ) {}

  ngOnInit(): any {
    this.isSuperUser$ = this._permissions.isSuperUser$;
    this.collections$ = this._searchInLeftMenuService.collections$;
    this.projects$ = this._searchInLeftMenuService.projects$;
    this.unassign$ = this._searchInLeftMenuService.unassign$;
    this.isSearchActive$ = this._searchInLeftMenuService.isSearchActive$;
    this.isMatchingSearch$ = this._searchInLeftMenuService.isMatchingSearch$;
    this.stringSearch$ = this._searchInLeftMenuService.stringSearch$;
    this.notificationsCount$ = this._store.pipe(
      getUnseenUserNotificationsCount,
      map(
        notificationCount =>
          notificationCount <= this.maxNotificationsCount ? notificationCount : this.maxNotificationsCount + '+'
      )
    );

    this.openedStateCollectionListKey = this._openedStateLeftMenuItemsService.localStoreKeys.OPENED_COLLECTIONS_LIST;
    this.openedStateProjectListKey = this._openedStateLeftMenuItemsService.localStoreKeys.OPENED_PROJECT_LIST;
    this.initIsVisibleBoards();
  }

  initIsVisibleBoards() {
    this.subs.push(
      this._searchInLeftMenuService.isSearchActive$.subscribe(state => {
        this.isCollectionsOpened = state;
        if (!state) {
          const list = this._openedStateLeftMenuItemsService.getOpenedProjectsList(this.openedStateCollectionListKey);
          if (!list.includes(ALL_COLLECTIONS)) {
            this.isCollectionsOpened = true;
          }
        }
      }),
      this._searchInLeftMenuService.isSearchActive$.subscribe(state => {
        this.isProjectsOpened = state;
        if (!state) {
          const list = this._openedStateLeftMenuItemsService.getOpenedProjectsList(this.openedStateProjectListKey);
          if (!list.includes(ALL_PROJECTS)) {
            this.isProjectsOpened = true;
          }
        }
      })
    );
  }

  onSetVisibleLeftMenu(isVisible: boolean = false) {
    this._store.dispatch(new ComponentSetVisibleAction({ name: LEFT_MENU, visible: isVisible }));
  }

  onToggleCollections() {
    this.isCollectionsOpened = !this.isCollectionsOpened;
    this._openedStateLeftMenuItemsService.save(
      this.isCollectionsOpened,
      this.openedStateCollectionListKey,
      ALL_COLLECTIONS
    );
  }

  onToggleProjects() {
    this.isProjectsOpened = !this.isProjectsOpened;
    this._openedStateLeftMenuItemsService.save(this.isProjectsOpened, this.openedStateProjectListKey, ALL_PROJECTS);
  }

  onClickMyTasksToDo() {
    this.onSetVisibleLeftMenu(false);
    if (this._paywall.isFeatureEnabled(Features.AdvancedSearch)) {
      this.goSearch(
        {
          isEmptyQAvailable: '1',
          hideArchived: true,
          hideReleased: true,
          status: ['todo'],
          users: [this._authService.activeUserId]
        },
        {
          sortOrder: 'desc',
          sortParam: 'updatedAt'
        },
        true
      );
    } else {
      this._paywall.showPayWall(Features.AdvancedSearch);
    }
  }

  goSearch(filterParams = {}, sortParams?, resetPrevParams?) {
    this._store.pipe(pluck('search'), distinctUntilChanged(), pluck('onSearchPage'), take(1)).subscribe(v => {
      if (!v) {
        this._store.dispatch(new GlobalSearchRememberBackUrlAction(this._location.path()));
      }
      if (resetPrevParams) {
        this._store.dispatch(new GlobalSearchResetAction());
      }
      this._routerNav.navigate(this.getSearchUrl(filterParams, sortParams));
    });
  }

  getSearchUrl(filterParams = {}, sortParams?) {
    return this.appUrls.getUrlGlobalSearch(this._routerNav.prepareGlobalSearchParam('', filterParams, sortParams));
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
