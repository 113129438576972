import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';

import { GithubPullRequest, GitPullRequest } from '../../../models/git-pull-request';
import { GithubAssociatedRepository } from '../../../models/git-repository';

@Component({
  selector: 'git-task-pullrequest',
  templateUrl: './git-task-pullrequest.component.html',
  styleUrls: ['./git-task-pullrequest.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GitTaskPullRequestComponent implements OnChanges {
  @Input() taskPullRequests: GitPullRequest[];
  @Input() assocRepoMap: { [id: number]: GithubAssociatedRepository };

  @Output() removeItem = new EventEmitter<GitPullRequest>();

  public sortedTaskPullRequests: GitPullRequest[];

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('taskPullRequests') && changes['taskPullRequests'].currentValue) {
      this.sortedTaskPullRequests = changes['taskPullRequests'].currentValue.sort((a, b) => b.createdAt - a.createdAt);
    } else {
      this.sortedTaskPullRequests = [];
    }
  }

  onOpenPr(pr: GithubPullRequest) {
    window.open(pr.htmlUrl, '_blank');
  }
  onRemove(item: GithubPullRequest, event: MouseEvent) {
    this.removeItem.emit(item);
    event.stopPropagation();
    return false;
  }
}
