import { distinctUntilChanged, filter, map, pluck, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import {
  GuiStateInit,
  GuiStateMemorizedActionTypes,
  GuiStateSetHotkeysTargetTask
} from '../actions/gui-state-memorized.actions';
import { AppState, GUI_STATE_MEMORIZED } from '../state';
import { AuthService } from '../../shared/services/app/auth.service';

// select all GuiStateMemorizedActionTypes except INIT and SET_HOTKEYS_TARGET_TASK
export const GuiStateStoreTriggers = Object.keys(GuiStateMemorizedActionTypes)
  .map(key => GuiStateMemorizedActionTypes[key])
  .filter(v => v !== GuiStateMemorizedActionTypes.INIT && v !== GuiStateMemorizedActionTypes.SET_HOTKEYS_TARGET_TASK);

@Injectable()
export class GuiStateMemorizedEffects {
  get localStorageKey() {
    return [GUI_STATE_MEMORIZED, this._authService.activeUserId].join('-');
  }

  @Effect({ dispatch: false })
  storeValues$ = this.actions$.pipe(
    filter(action => GuiStateStoreTriggers.includes(action.type)),
    switchMap(_ => {
      return this._store.pipe(
        pluck(GUI_STATE_MEMORIZED),
        distinctUntilChanged(),
        map(value => {
          try {
            localStorage.setItem(this.localStorageKey, JSON.stringify(value));
          } catch (e) {
            console.warn('some problems with localStorage');
          }
        })
      );
    })
  );

  @Effect()
  setCurrentTask$ = this.actions$
    .ofType(GuiStateMemorizedActionTypes.QUICK_TASK_EDIT_HIDE)
    .pipe(map(({ type, payload }: { type: string; payload: number }) => new GuiStateSetHotkeysTargetTask(payload)));

  constructor(private actions$: Actions, private _store: Store<AppState>, private _authService: AuthService) {
    this._authService.isLoggedIn$.pipe(distinctUntilChanged()).subscribe(isLoggedIn => {
      let newValue = {};
      if (isLoggedIn) {
        try {
          newValue = JSON.parse(localStorage.getItem(this.localStorageKey));
        } catch (e) {
          console.warn('some problems with localStorage or storied value');
        } finally {
          if (!newValue) {
            newValue = {};
          }
        }
      }
      this._store.dispatch(new GuiStateInit(newValue));
    });
  }
}
