import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Project, Task } from '../../../interfaces';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { Observable } from 'rxjs/Observable';
import { map, switchMap } from 'rxjs/operators';
import { fromProject } from '../../../ngrx/reducers/project.reducer';
import { PermissionsService } from '../../../permissions/permissions.service';
import { of } from 'rxjs/internal/observable/of';

@Component({
  selector: 'sidebar-project-status',
  templateUrl: './sidebar-project-status.component.html',
  styleUrls: ['./sidebar-project-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarProjectStatusComponent implements OnInit {
  @Input() task$: Observable<Task>;

  public editPermissions$: Observable<boolean>;
  public isPopupVisible = false;
  public project$: Observable<Project>;
  public projectStatus$: Observable<string>;

  constructor(private _store: Store<AppState>, private _permissions: PermissionsService) {}

  ngOnInit() {
    this.project$ = this.task$.pipe(switchMap(task => this._store.select(fromProject.getById(task.linkToProject))));
    this.projectStatus$ = this.project$.pipe(
      map(proj => {
        if (proj.status) {
          return proj.status;
        } else {
          switch (proj.progress) {
            case 0: {
              return 'To Do';
            }
            case 100: {
              return 'Done';
            }
            default: {
              return 'In Progress';
            }
          }
        }
      })
    );
    this.editPermissions$ = this.project$.pipe(
      switchMap(prj => (this._permissions.isProjectAdmin(prj.id) ? of(true) : this._permissions.isSuperUser$))
    );
  }

  onOpenPopup() {
    this.isPopupVisible = true;
  }

  onClose() {
    this.isPopupVisible = false;
  }
}
