import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Project } from '../../../interfaces';
import { AppState } from '../../../ngrx/state';
import { Store } from '@ngrx/store';
import { fromProject } from '../../../ngrx/reducers/project.reducer';
import { map, take } from 'rxjs/operators';
import { isPresent } from '../../../../helpers';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../services/form-v2.service';
import { Observer, Subscription } from 'rxjs/index';
import { HandleResponseAction } from '../../../ngrx/actions/root.action';
import { VERSION_PL } from '../../../constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RouterNavigateService } from '../../services/router-navigate.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'new-version',
  templateUrl: './new-version.component.html',
  styleUrls: ['./new-version.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewVersionComponent implements OnInit {
  @Input() projects: number[];

  public availableProjects$: Observable<Project[]>;

  formObserver: Observer<any> = {
    next: resp => {
      this._store.dispatch(new HandleResponseAction(resp));
    },
    error: _ => {},
    complete: () => {
      this.onCancel();
    }
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.add,
    entityToEdit: [VERSION_PL],
    formObserver: this.formObserver,
    prepareFormValue: formValue => {
      return {
        name: formValue['name'],
        project: formValue['project'],
        plannedDate: formValue['plannedDate'] ? formValue['plannedDate'].unix() : 0
      };
    }
  };

  form: FormGroup;
  subs: Subscription[] = [];

  constructor(
    private _store: Store<AppState>,
    private _routerNav: RouterNavigateService,
    private _fb: FormBuilder,
    public _formService: FormV2Service,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.availableProjects$ = this._store
      .select(fromProject.getByIds(this.projects || this._route.snapshot.params['projects'].split(',').map(i => +i)))
      .pipe(map(prjs => prjs.filter(isPresent)));

    this.availableProjects$.pipe(take(1)).subscribe(prjs => {
      this.form = this._fb.group({
        name: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
        project: [prjs[0] ? prjs[0].id : 0, Validators.required],
        plannedDate: [0]
      });
      this._formService.initFormParams(this.form, this.formServiceParams);
    });
  }

  onCancel() {
    this._routerNav.deactivatePopupOutlet();
  }

  onSubmit() {
    this._formService.markAsDirty();
    if (this.form.invalid) {
      return;
    }
    this._formService.submit();
  }
}
