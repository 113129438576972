import { HttpQueryParam } from '../../../../interfaces';
import { ESBitbucketCompany } from '../../models/git-company';
import { Action } from '../../../../ngrx/actions/unsafe-action';

export const GET_GET = '[bitbucket-company.actions] GET_GET';
export const GET_INDEX = '[bitbucket-company.actions] GET_INDEX';
export const SET_ACTIVE = '[bitbucket-company.actions] SET_ACTIVE';
export const UPDATED = '[bitbucket-company.actions] UPDATED';
export const GET_INDEX_COMPLETE = '[bitbucket-company.actions] GET_INDEX_COMPLETE';
export const GET_INDEX_FAIL = '[bitbucket-company.actions] GET_INDEX_FAIL';
export const REMOVE_ALL_COMPLETE = '[bitbucket-company.actions] REMOVE_ALL_COMPLETE';
export const REMOVE_ALL = '[bitbucket-company.actions] REMOVE_ALL';
export const CLEAR_SETTINGS = '[bitbucket-company.actions] CLEAR_ALL';

export class Get implements Action {
  readonly type = GET_GET;

  constructor(public payload: { id: number; queryParams?: HttpQueryParam }) {}
}

export class GetIndex implements Action {
  readonly type = GET_INDEX;

  constructor(public payload?: HttpQueryParam) {}
}

export class GetIndexComplete implements Action {
  readonly type = GET_INDEX_COMPLETE;

  constructor(public payload: ESBitbucketCompany) {}
}

export class GetIndexFail implements Action {
  readonly type = GET_INDEX_FAIL;
}

export class SetActive implements Action {
  readonly type = SET_ACTIVE;

  constructor(public payload: { number: number }) {}
}

export class Updated implements Action {
  readonly type = UPDATED;

  constructor(public payload: ESBitbucketCompany) {}
}

export class RemoveAllComplete implements Action {
  readonly type = REMOVE_ALL_COMPLETE;
  constructor(payload?: any) {}
}

export class RemoveAll implements Action {
  readonly type = REMOVE_ALL;
}

export class ClearSettings implements Action {
  readonly type = CLEAR_SETTINGS;
}

// prettier-ignore
export type Actions
  = Get
  | GetIndex
  | GetIndexFail
  | GetIndexComplete
  | SetActive
  | Updated
  | RemoveAllComplete
  | RemoveAll
  | ClearSettings
  ;
