import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppState, runParametricalSelector } from '../../../../../ngrx/state';
import { IntegrationNames } from '../../../../core/integraition-list';
import * as fromIntegration from '../../../../core/store/integration.reducer';
import { isHarvestInstalledForBoard } from '../../../../../ngrx/reducers/harvest-board.reducer';

@Component({
  selector: 'integration-label',
  templateUrl: './integration-label.component.html',
  styleUrls: ['./integration-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntegrationLabelComponent implements OnInit {
  @Input() srcPath: string;
  @Input() text: string;
  @Input() width: string;
  @Input() height: string;
  @Input() boardId: number;
  @Input() integrationName: IntegrationNames;
  public rightText$: Observable<string>;

  get lineHeight() {
    return { 'line-height': this.width + 'px' };
  }

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    if (this.integrationName === IntegrationNames.Harvest) {
      this.rightText$ = this._store
        .select(isHarvestInstalledForBoard(this.boardId))
        .pipe(map(isInstalled => (isInstalled ? 'Uninstall' : 'Install')));
    } else {
      const isInstalled$ = this.getIsInstalled(this.integrationName);
      this.rightText$ = isInstalled$.pipe(map(isInstalled => (isInstalled ? 'Settings' : 'Install')));
    }
  }

  private getIsInstalled(name): Observable<boolean> {
    return runParametricalSelector(this._store.select(fromIntegration.isInstalledIntegration), name);
  }
}
