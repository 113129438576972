<!--suppress ALL -->
<div #chartContainer class="chart" tabindex="-1">
  <svg d3Responsivefy [attr.width]="defaultWidth" [attr.height]="defaultHeight"
       [d3DragDrop]="circleDragProps"
       (d3DragStart)="onDragStart($event)"
       (d3Drag)="onDrag($event)"
       (d3DragEnd)="onDragEnd($event)"
       class="root_svg"
  >
    <defs>
      <radialGradient id="exampleGradient">
        <stop offset="0%" stop-color="blue"/>
        <stop offset="95%" stop-color="white"/>
      </radialGradient>
    </defs>


    <svg:g class="g-chart" [attr.transform]="'translate(' + margin.left + ', ' + margin.top + ')'">
      <svg:g #svgChart class="g-zoom">

        <svg:g *ngFor="let rect of (rectData$ | async)"
               [attr.transform]="'translate(' + rect.x + ', ' + rect.y + ')'"
        >
          <svg:rect class="quater" [attr.id]="rect.id" [attr.width]="width / 2" [attr.height]="height / 2"/>
          <svg:text class="quater_title" [attr.x]="'11'" [attr.y]="height / 2 - 11">
            {{rect.title}}
          </svg:text>
        </svg:g>

        <svg:g task-circle *ngFor="let task of tasks; trackBy: trackById"
               [highlight]="shouldHighlight(task)"
               (click)="onSVGClick(task.id)"
               class="g-task_circle"
               [task] = "task"
               [attr.taskId]="task.id"
               [countInGroup]="groupedTasks.get(task.backlogScoreX + '-' + task.backlogScoreY).length"
               [xScale] = "xScale"
               [yScale] = "yScale"
        >
        </svg:g>
      </svg:g>
    </svg:g>

    <rect x="-5" [attr.y]="defaultHeight - 35" [attr.width]="defaultWidth" height="50" fill="#ffffff" />
    <svg:g class="g-x-axis" [d3Axis]="xAxis" [attr.transform]="'translate(' + margin.left + ', ' + (+margin.top + +height) + ')'"></svg:g>
    <svg:g class="g-x-axis-label" [attr.transform]="'translate(' + (width / 2 + 13) + ', ' + defaultHeight + ')'">
      <text>{{axisXLabel}}</text>
    </svg:g>


    <rect x="-5" y="10" width="40" [attr.height]="defaultHeight" fill="#ffffff" />
    <svg:g class="g-y-axis" [d3Axis]="yAxis" [attr.transform]="'translate(' + margin.left + ', ' + margin.top + ')'"></svg:g>
    <svg:g class="g-y-axis-label" [attr.transform]="'translate(' + 10 + ', ' + (height / 2 + 33) + ')'">
      <text [attr.transform]="'rotate(270)'">{{axisYLabel}}</text>
    </svg:g>
  </svg>
</div>
