import { Action } from './unsafe-action';
import { label } from '../functions/util';
import { labelAction } from '../functions/action';
import { RoadMapScale } from '../../board/roadmap-board/constants/roadmap.constants';

export const GuiStateMemorizedActionTypes = {
  INIT: label('[GUI state] Init'),
  CHANGE_EXPANDED_SWIMLANE_STATE: label('[GUI state] change swimlane visibility'),
  TOGGLE_SWIMLANE_VISIBILITY: label('[GUI state] toggle swimlane visibility'),
  TOGGLE_VERSION_VISIBILITY: label('[GUI state] toggle version visibility'),
  TOGGLE_GROUP_VISIBILITY: label('[GUI state] toggle group visibility'),
  TOGGLE_QUICK_FILTERS_VISIBILITY: label('[GUI state] toggle quick filters visibility'),
  TOGGLE_HIDE_COMPLETED: labelAction('GUI state', 'TOGGLE_HIDE_COMPLETED'),
  STORE_SPRINT_PROGRESS_TYPE: labelAction('GUI state', 'STORE_SPRINT_PROGRESS_TYPE'),
  QUICK_TASK_EDIT_SHOW: labelAction('GUI state', 'QUICK_TASK_EDIT_SHOW'),
  QUICK_TASK_EDIT_HIDE: labelAction('GUI state', 'QUICK_TASK_EDIT_HIDE'),
  BATCH_EDIT_SHOW: labelAction('GUI state', 'BATCH_EDIT_SHOW'),
  BATCH_EDIT_HIDE: labelAction('GUI state', 'BATCH_EDIT_HIDE'),
  SET_HOTKEYS_TARGET_TASK: labelAction('GUI state', 'SET_HOTKEYS_TARGET_TASK'),
  SET_BOARD_VIEW_MODE: labelAction('GUI state', 'SET_BOARD_VIEW_MODE'),
  SET_BOARD_GROUPING: labelAction('GUI state', 'SET_BOARD_GROUPING'),
  SET_ROADMAP_ZOOM_MODE: labelAction('GUI state', 'SET_ROADMAP_ZOOM_MODE'),
  IS_OPEN_BOARD_TYPE_SELECTOR: labelAction('GUI state', 'IS_OPEN_BOARD_TYPE_SELECTOR')
};

export class GuiStateInit implements Action {
  type = GuiStateMemorizedActionTypes.INIT;

  constructor(public payload: {}) {}
}

export class GuiStateToggleSwimlaneVisibility implements Action {
  type = GuiStateMemorizedActionTypes.TOGGLE_SWIMLANE_VISIBILITY;

  constructor(public payload: number) {}
}

export class GuiStateToggleVersionVisibility implements Action {
  type = GuiStateMemorizedActionTypes.TOGGLE_VERSION_VISIBILITY;

  constructor(public payload: number) {}
}

export class GuiStateToggleGroupVisibility implements Action {
  type = GuiStateMemorizedActionTypes.TOGGLE_GROUP_VISIBILITY;

  constructor(public payload: number) {}
}

export class GuiStateChangeSwimlaneVisibility implements Action {
  type = GuiStateMemorizedActionTypes.CHANGE_EXPANDED_SWIMLANE_STATE;

  constructor(public payload: { id: number; flag: boolean }) {}
}

export class GuiStateToggleQuickFiltersVisibility implements Action {
  type = GuiStateMemorizedActionTypes.TOGGLE_QUICK_FILTERS_VISIBILITY;

  constructor(public payload: number) {}
}

export class GuiStateToggleCheckListHideCompleted implements Action {
  type = GuiStateMemorizedActionTypes.TOGGLE_HIDE_COMPLETED;

  constructor(public payload: number) {}
}

export class GuiStateStoreSprintProgressType implements Action {
  type = GuiStateMemorizedActionTypes.STORE_SPRINT_PROGRESS_TYPE;

  constructor(public payload: string) {}
}

export class GuiStateQuickTaskEditShow implements Action {
  type = GuiStateMemorizedActionTypes.QUICK_TASK_EDIT_SHOW;

  constructor(
    public payload: { id: number; rect: ClientRect; activate?: 'members' | 'due_date'; isExactRect?: boolean }
  ) {}
}

export class GuiStateQuickTaskEditHide implements Action {
  type = GuiStateMemorizedActionTypes.QUICK_TASK_EDIT_HIDE;

  constructor(public payload: number) {}
}

export class GuiStateBatchEditShow implements Action {
  type = GuiStateMemorizedActionTypes.BATCH_EDIT_SHOW;

  constructor(public payload: { ids: number[]; top: number; left: number }) {}
}

export class GuiStateBatchEditHide implements Action {
  type = GuiStateMemorizedActionTypes.BATCH_EDIT_HIDE;

  constructor() {}
}

export class GuiStateSetHotkeysTargetTask implements Action {
  type = GuiStateMemorizedActionTypes.SET_HOTKEYS_TARGET_TASK;

  constructor(public payload: null | number) {}
}

export class GuiStateSetBoardViewMode implements Action {
  type = GuiStateMemorizedActionTypes.SET_BOARD_VIEW_MODE;

  constructor(public payload: { id: number; mode: string }) {}
}

export class GuiStateSetBoardGrouping implements Action {
  type = GuiStateMemorizedActionTypes.SET_BOARD_GROUPING;

  constructor(public payload: { id: number; mode: string }) {}
}

export class GuiStateSetRoadMapZoomMode implements Action {
  type = GuiStateMemorizedActionTypes.SET_ROADMAP_ZOOM_MODE;

  constructor(public payload: { id: number; scale: RoadMapScale }) {}
}

export class GuiStateOpenBoardTypeSelectorAction implements Action {
  type = GuiStateMemorizedActionTypes.IS_OPEN_BOARD_TYPE_SELECTOR;

  constructor(public payload: boolean) {}
}
