<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="form-group">
    <label [attr.for]="'board_name'">Board Name</label>
    <input
      #boardName
      placeholder="Example: Development"
      id="board_name"
      formControlName="name"
      [focus]="true"
      checkTouch
    />
    <help-block
      [control]="form.get('name')"
      [messages]="{'maxlength': 'Maximum length of 50 symbols is exceed'}"
    >
    </help-block>
  </div>
  <div class="form-group" *ngIf="isWithTasksAvailable">
    <label class="unstyled">
      <input type="checkbox" formControlName="withTasks" class="checkbox"/>
      Copy with Tasks
    </label>
  </div>
  <div class="form-group" *ngIf="!isTypeSelectorHidden && !fromTemplate">
    <label [attr.for]="'board_type'">Board Type</label>
    <select id="board_type" formControlName="type">
      <option *ngFor="let option of _typeOptions" [value]="option.value">{{option.text}}</option>
    </select>
    <help-block [control]="form.get('type')"></help-block>
  </div>
  <div class="form-group">
    <label>Put board to Collections</label>
      <collection-input-picker
        [selectedCollections$]="selectedCollections$"
        className="new_board_collection_input_picker"
      >
      </collection-input-picker>
    <help-block [control]="form.get('collections')"></help-block>
  </div>

  <div class="form-group">
    <label>Assign board to Projects</label>
      <project-input-picker
        [multiple]="boardTypes.sprint !== form.get('type').value"
        [selectedProjects]="selectedProjects$"
        [disabledProjects]="disabledProjects"
        className="new_board_project_input_picker"
        [validation]="form.get('projects').valid"
      >
      </project-input-picker>
    <help-block [control]="form.get('projects')" [messages]="projectErrorMessages"></help-block>
  </div>
</form>
