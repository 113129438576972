import { RoadMapScale } from '../../constants/roadmap.constants';
import * as moment from 'moment-mini-ts';
import { Moment, unitOfTime } from 'moment-mini-ts';
import { isDayOff, isWorkingDay } from '@atlaz/working-days/utils/working-days';
import { WorkingDays } from '@atlaz/working-days/interfaces/working-days.interface';
import { defaultMemoize } from 'reselect';

import { RoadMap, RoadmapChart, RoadMapItem } from '../../interfaces/roadmap.interface';
import { objectToArray } from '../../../../../helpers';
import { RoadmapCells, RoadmapCellsHeaders } from '../interfaces/chart.interfaces';
import { BACKEND_DATE_FORMAT } from '../../../../libs/date-time-formatter/constants/date-time-formats';

const cellFilters = {
  [RoadMapScale.Day]: (workingDays: WorkingDays) => (day: Moment) => isWorkingDay(day, workingDays),
  [RoadMapScale.Week]: (_: WorkingDays) => _ => true,
  [RoadMapScale.Month]: (_: WorkingDays) => _ => true,
  [RoadMapScale.Quarter]: (_: WorkingDays) => _ => true,
  [RoadMapScale.Year]: (_: WorkingDays) => _ => true
};

const cellLabels = {
  [RoadMapScale.Day]: (day: Moment) => day.format('D'),
  [RoadMapScale.Week]: (day: Moment) => day.format('w [week]'),
  [RoadMapScale.Month]: (day: Moment) => day.format('MMMM'),
  [RoadMapScale.Quarter]: (day: Moment) => day.format('[Q]Q'),
  [RoadMapScale.Year]: (day: Moment) => day.format('YYYY')
};

const cellStepName = {
  [RoadMapScale.Day]: 'day',
  [RoadMapScale.Week]: 'week',
  [RoadMapScale.Month]: 'month',
  [RoadMapScale.Quarter]: 'quarter',
  [RoadMapScale.Year]: 'year'
};

const roundStepByName = {
  [RoadMapScale.Day]: 'month',
  [RoadMapScale.Week]: 'month',
  [RoadMapScale.Month]: 'year',
  [RoadMapScale.Quarter]: 'quarter',
  [RoadMapScale.Year]: 'year'
};

const getGroupsSeparatorFn = {
  [RoadMapScale.Day]: (day: Moment, weekStartDay) =>
    day
      .clone()
      .add({ d: -weekStartDay })
      .startOf('week')
      .format(BACKEND_DATE_FORMAT),
  [RoadMapScale.Week]: (_1: Moment, _2) => '',
  [RoadMapScale.Month]: (day: Moment, _) =>
    day
      .clone()
      .startOf('year')
      .format(BACKEND_DATE_FORMAT),
  [RoadMapScale.Quarter]: (day: Moment, _) =>
    day
      .clone()
      .startOf('year')
      .format(BACKEND_DATE_FORMAT),
  [RoadMapScale.Year]: (_1: Moment, _2) => ''
};

const yearGroupId = moment.unix(0).format(BACKEND_DATE_FORMAT);
const getGroupsIdFn = {
  [RoadMapScale.Day]: (day: Moment) =>
    day
      .clone()
      .startOf('month')
      .format(BACKEND_DATE_FORMAT),
  [RoadMapScale.Week]: (day: Moment) =>
    day
      .clone()
      .startOf('month')
      .format(BACKEND_DATE_FORMAT),
  [RoadMapScale.Month]: (day: Moment) =>
    day
      .clone()
      .startOf('year')
      .format(BACKEND_DATE_FORMAT),
  [RoadMapScale.Quarter]: (day: Moment) =>
    day
      .clone()
      .startOf('year')
      .format(BACKEND_DATE_FORMAT),
  [RoadMapScale.Year]: (day: Moment) => yearGroupId
};

const calcHeaderFn = {
  [RoadMapScale.Day]: (day: Moment) => {
    return {
      id: day.startOf('month').format(BACKEND_DATE_FORMAT),
      label: day.startOf('month').format('MMMM'),
      leftPx: 0,
      widthPx: 0
    };
  },
  [RoadMapScale.Week]: (day: Moment) => {
    return {
      id: day.startOf('month').format(BACKEND_DATE_FORMAT),
      label: day.startOf('month').format('MMMM'),
      leftPx: 0,
      widthPx: 0
    };
  },
  [RoadMapScale.Month]: (day: Moment) => {
    return {
      id: day.startOf('year').format(BACKEND_DATE_FORMAT),
      label: day.startOf('year').format('YYYY'),
      leftPx: 0,
      widthPx: 0
    };
  }
};

const cellWidthsPx = {
  [RoadMapScale.Day]: 20,
  [RoadMapScale.Week]: 70,
  [RoadMapScale.Month]: 62,
  [RoadMapScale.Quarter]: 100,
  [RoadMapScale.Year]: 366
};

const getClosestWorkingDay = (startDay: string, workingDays, step = 1): string => {
  const day = moment(startDay);
  do {
    day.add({ d: step });
  } while (isDayOff(day, workingDays));
  return day.format(BACKEND_DATE_FORMAT);
};

const getNextWorkingDay = (day: string, workingDays): string => getClosestWorkingDay(day, workingDays, 1);
export const getPrevWorkingDay = (day: string, workingDays): string => getClosestWorkingDay(day, workingDays, -1);

const calcItemPositions = (scale, chart: RoadmapChart, items: RoadMapItem[], workingDays) => {
  const cellsMap = chart.cellsMap;
  const stepName = <unitOfTime.StartOf>cellStepName[scale];
  const positionReducer = (acc, item) => {
    const startDay = moment
      .unix(item.startDate)
      .startOf(stepName)
      .format(BACKEND_DATE_FORMAT);
    const endDay = moment
      .unix(item.endDate)
      .startOf(stepName)
      .format(BACKEND_DATE_FORMAT);
    const startCell = cellsMap[startDay]
      ? cellsMap[startDay]
      : cellsMap[getNextWorkingDay(startDay, workingDays)] || cellsMap[getPrevWorkingDay(startDay, workingDays)];
    const endCell = cellsMap[endDay]
      ? cellsMap[endDay]
      : cellsMap[getPrevWorkingDay(endDay, workingDays)] || Object.values(cellsMap)[Object.values(cellsMap).length - 1];

    const leftPx =
      scale === RoadMapScale.Day
        ? startCell.leftPx
        : startCell.leftPx +
          startCell.widthPx *
            startCell.workingDaysBefore[
              moment
                .unix(item.startDate)
                .startOf('day')
                .format(BACKEND_DATE_FORMAT)
            ] /
            startCell.daysCapacity;
    const rightPx =
      scale === RoadMapScale.Day
        ? endCell.leftPx + endCell.widthPx
        : endCell.leftPx +
          endCell.widthPx *
            (endCell.workingDaysBefore[
              moment
                .unix(item.endDate)
                .startOf('day')
                .format(BACKEND_DATE_FORMAT)
            ] +
              1) /
            endCell.daysCapacity;

    acc[item.id] = {
      leftPx,
      rightPx
    };
    acc = item.subItems.reduce(positionReducer, acc);
    return acc;
  };
  return items.reduce(positionReducer, {});
};

export const getDayByPx = (cells: RoadmapCells[], offset): string => {
  const cell = cells.find(cell => cell.leftPx <= offset && offset <= cell.leftPx + cell.widthPx);
  if (!cell) {
    console.log(offset, cells);
    throw new Error('unkown offset');
  }
  if (cell.type === RoadMapScale.Day) {
    return cell.id;
  }
  const cellOffset = offset - cell.leftPx;
  const cellsWorkingDayNumber = Math.ceil(cell.daysCapacity * cellOffset / cell.widthPx);

  return Object.keys(cell.workingDaysBefore).find(
    (day, index, all) =>
      (cell.workingDaysBefore[all[index + 1]] === cell.workingDaysBefore[day] + 1 &&
        cell.workingDaysBefore[all[index + 1]] === cellsWorkingDayNumber) ||
      index === all.length - 1
  );
};

export const updateChart = (state: RoadMap): RoadmapChart => {
  const scale = state.scale;
  const chart = state.chart;

  chart.cells = getChartCells(
    scale,
    chart.startTs,
    chart.endTs,
    state.today,
    state.workingDays,
    state.minChartWidthPx,
    state.forPrint,
    state.weekStartDate
  );
  const todayCell = chart.cells.find(cell => cell.isToday);
  chart.todayLeftPx = todayCell ? todayCell.leftPx : nearestWorkingDayLeftPx(chart.cells, state.today);

  chart.cellsHeaders = getCellsHeaders(chart.cells, state.scale, state.workingDays);
  chart.cellsMap = chart.cells.reduce((acc, cell) => Object.assign(acc, { [cell.id]: cell }), {});

  chart.itemPositions = calcItemPositions(scale, chart, state.taskTree, state.workingDays);

  chart.width = chart.cells.reduce((acc, item) => acc + item.widthPx, 0);

  return chart;
};

const nearestWorkingDayLeftPx = (cells, today) => {
  const now = moment(today).unix();
  return cells.reduce(
    (acc, item) => {
      const distance = Math.abs(moment(item.id).unix() - now);
      if (acc.distance - distance > 0) {
        acc.distance = distance;
        acc.leftPx = item.leftPx;
      }
      return acc;
    },
    { distance: Infinity, leftPx: 0 }
  ).leftPx;
};

export const getCellsHeaders = defaultMemoize(
  (cells: RoadmapCells[], scale: RoadMapScale, workingDays): RoadmapCellsHeaders[] => {
    if (scale === RoadMapScale.Year) {
      return [
        {
          id: yearGroupId,
          label: 'Project lifetime',
          leftPx: 0,
          widthPx: cells.reduce((acc, cell) => acc + cell.widthPx, 0)
        }
      ];
    }

    if (scale === RoadMapScale.Day || scale === RoadMapScale.Month || scale === RoadMapScale.Quarter) {
      const labelFormat = scale === RoadMapScale.Day ? 'MMMM' : 'YYYY';
      return objectToArray(
        cells.reduce((acc, cell) => {
          if (!acc[cell.groupId]) {
            acc[cell.groupId] = {
              id: cell.groupId,
              label: moment(cell.groupId).format(labelFormat),
              leftPx: cell.leftPx,
              widthPx: cell.widthPx
            };
          } else {
            acc[cell.groupId].widthPx += cell.widthPx;
          }
          return acc;
        }, {})
      );
    }

    return objectToArray(
      cells.reduce((acc, cell) => {
        const getGroupId = getGroupsIdFn[cell.type];
        if (!acc[cell.groupId]) {
          acc[cell.groupId] = {
            id: cell.groupId,
            label: moment(cell.groupId).format('MMMM YYYY'),
            leftPx: cell.leftPx,
            widthPx: 0
          };
        }

        const start = moment(cell.id);
        let end = moment(cell.id).endOf('week');
        if (start.isSame(end, 'month')) {
          acc[cell.groupId].widthPx += cell.widthPx;
          return acc;
        }
        end = moment(cell.id)
          .endOf('month')
          .add({ d: 1 });
        const workingDaysDiff = workingDaysDifference(
          start.format(BACKEND_DATE_FORMAT),
          end.format(BACKEND_DATE_FORMAT),
          workingDays
        );

        const endingMonthWidth = cell.widthPx * (workingDaysDiff.daysCapacity / cell.daysCapacity);
        acc[cell.groupId].widthPx += endingMonthWidth;

        // next group;
        const nextGroupId = getGroupId(moment(cell.groupId).add({ month: 1 }));
        if (!acc[nextGroupId]) {
          acc[nextGroupId] = {
            id: nextGroupId,
            label: moment(nextGroupId).format('MMMM YYYY'),
            leftPx: cell.leftPx + endingMonthWidth,
            widthPx: cell.widthPx - endingMonthWidth
          };
        }
        return acc;
      }, {})
    );
  }
);

const workingDaysDifference = (day1: string, day2: string, workingDays) => {
  let capacity = 0;
  const day = moment(day1).startOf('day');
  const endTs = moment(day2)
    .startOf('day')
    .unix();
  const workingDaysBefore = {};
  do {
    workingDaysBefore[day.format(BACKEND_DATE_FORMAT)] = capacity;
    capacity += +isWorkingDay(day, workingDays);
    day.add({
      day: 1
    });
  } while (day.unix() < endTs);
  return { workingDaysBefore, daysCapacity: capacity };
};

export const getCellDaysCapacity = (cell: RoadmapCells, workingDays) => {
  const workingDaysBefore = {
    [cell.id]: 0
  };
  const scale = <unitOfTime.StartOf>cellStepName[cell.type];
  if (cell.type === RoadMapScale.Day) {
    return { workingDaysBefore, daysCapacity: 1 };
  }

  return workingDaysDifference(
    cell.id,
    moment(cell.id)
      .endOf(scale)
      .add({ d: 1 })
      .format(BACKEND_DATE_FORMAT),
    workingDays
  );
};

export const getChartCells = defaultMemoize(
  (
    scale: RoadMapScale,
    startTs: number,
    endTs: number,
    today: string,
    workingDays: WorkingDays,
    minChartWidthPx: number,
    forPrint: boolean,
    weekStartDay: number
  ): RoadmapCells[] => {
    if (!startTs || !endTs) {
      return [];
    }
    if (startTs > endTs) {
      throw new Error('Chart cell calculation, endTs < startTs');
    }
    const stepName = <unitOfTime.StartOf>cellStepName[scale];
    const cellFilter = <Function>cellFilters[scale](workingDays);
    const cellWidthPx = <number>cellWidthsPx[scale];
    const cellLabel = <Function>cellLabels[scale];
    const getGroupSeparator = getGroupsSeparatorFn[scale];
    const getGroupId = getGroupsIdFn[scale];
    const roundBy = <unitOfTime.StartOf>roundStepByName[scale];

    const todayMoment = moment(today);

    const day = moment
      .unix(startTs)
      .add({
        [roundBy]: forPrint ? 0 : -1
      })
      .startOf(stepName);

    const endDay = moment
      .unix(endTs)
      .add({
        [roundBy]: forPrint ? 0 : -1
      })
      .endOf(stepName);
    if (scale === RoadMapScale.Day) {
      day.startOf('month');
      endDay.endOf('month');
    }
    const cells = [];
    let leftPx = 0;
    do {
      if (cellFilter(day)) {
        cells.push({
          id: day.format(BACKEND_DATE_FORMAT),
          type: scale,
          label: cellLabel(day),
          groupId: getGroupId(day),
          leftPx,
          widthPx: cellWidthPx,
          daysCapacity: 0,
          workingDaysBefore: {},
          isSeparator: getGroupSeparator(day, weekStartDay || 0),
          isToday: todayMoment.isSame(day, stepName)
        });
        leftPx += cellWidthPx;
      }
      day.add({
        [stepName]: 1
      });
    } while (day.unix() < endDay.unix() || leftPx < minChartWidthPx);

    cells.forEach(cell => Object.assign(cell, getCellDaysCapacity(cell, workingDays)));

    //

    // mark cell on border of different periods
    cells.forEach((cell, index, list: RoadmapCells[]) => {
      if (list.length === index + 1) {
        return (cell.isSeparator = true);
      }
      cell.isSeparator = cell.isSeparator !== list[index + 1].isSeparator;
    });

    return cells;
  }
);
