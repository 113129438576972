<div class="default_colors_filter"
     (click)="onShowColorsPopup()"
     [ngClass]="{'active': showColorsPopUp}"
>
  Colors
</div>

<div *ngFor="let color of selectedColors$ | async"
     class="color_filter_tag"
     (click)="onToggleColor(color)"
>
  <div class="color_box" [style.background-color]="color"></div>
  <div class="clear_block"></div>
</div>

<div a-context-popup [className]="'thin_popup'" *ngIf="showColorsPopUp"
     [title]="'Color'"
     class="default_colors_list_popup"
     (close)="onHideColorsPopup()"
>
  <div class="color_list_container">
    <div *ngFor="let item of (colorsWithStatus$ | async)"
         class="color_list_item"
         [style.background-color]="item.color"
         [ngClass]="{active: item.isActive}"
         (click)="onToggleColor(item.color)"
    ></div>
  </div>
</div>
