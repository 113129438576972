<div a-context-popup
     class="duedate_popup"
     className="medium_popup"
     [title]="'Log Work'"
     (close)="onCloseLogworkPopup()"
>
  <task-logwork
    [taskId]="task.id"
    (closePopupEvent)="onCloseLogworkPopup()"
    (resetFocus)="onChildChangedFocus($event)"
  ></task-logwork>
</div>
