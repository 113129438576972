import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Board } from '../../../interfaces';
import { Observable } from 'rxjs/Observable';
import { CustomField, fromCustomFields } from '../../../ngrx/reducers/custom-field.reducer';
import { AppState } from '../../../ngrx/state';
import { Store } from '@ngrx/store';
import { switchMap, tap } from 'rxjs/operators';
import { CustomFieldMoveAction } from '../../../ngrx/actions/custom-field.actions';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

const preventDrop = e => {
  e.preventDefault();
};

@Component({
  selector: 'board-fields-manager',
  templateUrl: './board-fields-manager.component.html',
  styleUrls: ['./board-fields-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoardFieldsManagerComponent implements OnInit, OnDestroy {
  @Input() isNotGuest: boolean;
  @Input() board: Board;

  @Output() close = new EventEmitter();

  public isAddingField: boolean;
  public customFields$: Observable<CustomField[]>;
  public dragOverId: number;
  public dragStartId: number;
  public dragType: string;
  public firstFieldId: number;
  public dragPointerOrder = -2;

  public editingItemId = 0;
  public editingItem: CustomField;

  public board$: BehaviorSubject<Board> = new BehaviorSubject<Board>(null);

  constructor(private _store: Store<AppState>) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('board') && changes['board'].currentValue) {
      this.board$.next(changes['board'].currentValue);
    }
  }

  ngOnInit() {
    document.addEventListener('drop', preventDrop);
    this.customFields$ = this.board$.pipe(
      switchMap(board =>
        this._store
          .select(fromCustomFields.getByBoardId(board.id))
          .pipe(tap(arr => arr[0] && (this.firstFieldId = arr[0].id)))
      )
    );
  }

  onClose() {
    this.close.emit();
  }

  onStartAddingField() {
    this.isAddingField = true;
  }
  onEndAddingField() {
    this.isAddingField = false;
  }

  onDragStart(event, item) {
    this.dragOverId = -1;
    this.dragPointerOrder = -2;
    this.dragType = 'customField';
    event.dataTransfer.setData('text', item.name);
    this.dragStartId = item.id;
  }

  onDragEnd() {
    if (this.dragType !== 'customField') {
      return;
    }
    const payload = { id: this.dragStartId };
    if (this.dragOverId) {
      payload['insertAfter'] = this.dragOverId;
    } else {
      payload['insertBefore'] = this.firstFieldId;
    }
    this._store.dispatch(new CustomFieldMoveAction(payload));

    this.dragOverId = -1;
    this.dragStartId = -1;
    this.dragPointerOrder = -2;
  }

  onDragOver(event, id, order) {
    event.preventDefault();
    if (this.dragOverId !== id && this.dragType === 'customField') {
      this.dragOverId = id;
      this.dragPointerOrder = order;
    }
  }

  onStartExternalDrag(type) {
    this.dragType = type;
  }

  onEditItem(item: CustomField) {
    this.editingItem = item;
    this.editingItemId = item.id;
  }
  onEditingEnd() {
    this.editingItem = null;
    this.editingItemId = 0;
  }

  ngOnDestroy() {
    document.removeEventListener('drop', preventDrop);
  }
}
