<!--suppress ALL -->
<div class="chart">
  <svg [attr.width]="defaultWidth" [attr.height]="defaultHeight">
    <svg:g class="g-x-axis"
           [attr.transform]="'translate(' + margin.left + ', ' + (+margin.top + +height) + ')'"></svg:g>
    <svg:g class="g-x-axis-background" [attr.transform]="'translate(' + margin.left + ', ' + margin.top + ')'">
      <svg:rect *ngFor="let dayOff of dayOffs; trackBy : trackById;" [attr.width]="dayOff.width" [attr.height]="height"
                [attr.x]="dayOff.x" y="0" fill="#ecf0f2"/>
    </svg:g>
    <svg:g class="g-y-axis" [attr.transform]="'translate(' + margin.left + ', ' + margin.top + ')'"></svg:g>

    <svg:g class="g-x-axis-label"
           [attr.transform]="'translate(' + (width/2) + ', ' + (height + margin.top + 40) + ')'">
      <text>Time</text>
    </svg:g>
    <svg:g class="g-y-axis-label" [attr.transform]="'translate(' + 10 + ', ' + ((height / 2) + 10) + ')'">
      <text [attr.transform]="'rotate(-90)'" text-anchor="middle">{{yAxisLabel}}</text>
    </svg:g>

    <svg:g class="g-grid-lines" [attr.transform]="'translate(' + margin.left + ', ' + margin.top + ')'"></svg:g>

    <path class="ideal-line" [attr.d]="idealLine"
          [attr.transform]="'translate(' + margin.left + ', ' + margin.top + ')'"></path>
    <path class="work-log-line" [attr.d]="actualLine"
          [attr.transform]="'translate(' + margin.left + ', ' + margin.top + ')'"></path>

    <circle *ngFor="let activity of groupedActivitiesByDate;"
            class="activity-circle"
            r="5"
            [attr.cx]="xScale(activity.logDate)"
            [attr.cy]="yScale(activity.value)"
            [attr.transform]="'translate(' + margin.left + ', ' + margin.top + ')'"
            (click)="onClickOpen($event, activity)"
            [ngClass]="{'active_circle': selectedActivity === activity}"
    ></circle>
  </svg>
</div>

<div
  *ngIf="(isOpenActivityTooltip$ | async)"
  [class]="tooltipPositionClass"
  [ngStyle]="{'top' : topPxActivityTooltip + 'px', 'left': leftPxActivityTooltip + 'px'}"
  [clickOut]="true"
  (clickOutEvent)="onClickOut()"
>
  <div class="main_tooltip_container">
    <div *ngFor="let activity of tasksActivityTooltipData" class="activities_container">
      <div *ngIf="BurnEventType.logwork == activity.burnEvent.type"><user-name-wrapper [userId]="activity.burnEvent.userId"></user-name-wrapper> logged {{activity.burn * 3600 | toHms}} in {{activity.sprintsTask.historyTitle}}</div>
      <div *ngIf="BurnEventType.done == activity.burnEvent.type">{{activity.sprintsTask.historyTitle}} was done</div>
    </div>
  </div>
</div>
