import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MENTIONS_PATTERN } from '../../../helpers/mention';

export interface Piece {
  type: string;
  value: string;
  props?: {};
}

const parseString = (piece: Piece, component): Piece[] => {
  const { regExp, propsHandler, name } = component;
  let lastMatchIndex = 0;
  let matchResult;
  const acc = [];
  // this was got from example
  //  @see https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/RegExp/exec
  //  noinspection TsLint
  while ((matchResult = regExp.exec(piece.value)) !== null) {
    if (lastMatchIndex - matchResult.index) {
      acc.push({ type: 'string', value: piece.value.substring(lastMatchIndex, matchResult.index) });
    }
    lastMatchIndex = regExp.lastIndex;
    acc.push({ type: name, value: matchResult[0], props: propsHandler(matchResult) });
  }

  if (lastMatchIndex !== piece.value.length) {
    acc.push({ type: 'string', value: piece.value.substring(lastMatchIndex, piece.value.length) });
  }
  return acc;
};

const parse = (source: Piece[], component) => {
  return source.reduce((acc, piece: Piece) => {
    (piece.type === 'string' ? parseString(piece, component) : [piece]).map(v => acc.push(v));
    return acc;
  }, []);
};

const handlers = [
  {
    name: 'smart-br',
    regExp: /\r?\n/g,
    propsHandler: () => {}
  },
  {
    name: 'smart-task-link',
    // regExp recognizes only tasks links for current company by checking current domain
    // we should check old & new format of tasks urls
    regExp: new RegExp(
      'https?://' +
        location.origin.substr(location.protocol.length + 2) +
        '\\S+((\\(popup:tasks/([\\d]+)\\))|t/(\\S+-\\d+|\\d+))',
      'g'
    ),
    propsHandler: result => ({
      taskSlug: result[3] || decodeURIComponent(result[4])
    })
  },
  {
    name: 'smart-link',
    regExp: /https?:\/\/\S+/g,
    propsHandler: () => {}
  },
  {
    name: 'smart-mention',
    regExp: MENTIONS_PATTERN,
    propsHandler: () => {}
  },
  {
    name: 'smart-logged-time',
    regExp: /\[time:(\d*?)\]/gi,
    propsHandler: result => {
      return {
        time: +result[1]
      };
    }
  },
  {
    name: 'smart-no-brackets',
    regExp: /(\[[^\]]+\])/gi,
    propsHandler: () => {}
  }
];

// regExp recognizes only tasks links for current company by checking current domain
// we should check old & new format of tasks urls
export const smartTaskLinkPattern =
  '(\\[.*\\]\\()?https?://' +
  location.origin.substr(location.protocol.length + 2) +
  '\\S+((\\(popup:tasks/([\\d]+)\\))|t/(\\S+-\\d+|\\d+))(\\))?';

const taskLinksOnlyHandlers = [
  {
    name: 'smart-task-link',
    regExp: new RegExp(smartTaskLinkPattern, 'g'),
    propsHandler: result => {
      if (result[1] && result[6]) {
        return {
          taskSlug: ''
        };
      } else {
        return {
          taskSlug: result[4] || decodeURIComponent(result[5])
        };
      }
    }
  }
];

const rootParse = (source: any) =>
  handlers.reduce((acc, component) => parse(acc, component), [{ type: 'string', value: String(source) }]);

const parseTasksOnly = (source: any) =>
  taskLinksOnlyHandlers.reduce((acc, component) => parse(acc, component), [{ type: 'string', value: String(source) }]);

@Component({
  selector: 'smart-text',
  template: `
    <piece [pieces]="smartPieces$ | async" [taskLinksOnly]="taskLinksOnly" (smartTaskLinkUpdated)="onSmartTaskLinkUpdated()"></piece>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartTextComponent implements OnChanges {
  @Input() text = '';
  @Input() taskLinksOnly = false;
  @Output() smartTaskLinkUpdated = new EventEmitter();
  public smartPieces$: BehaviorSubject<any[]> = new BehaviorSubject([]);

  ngOnChanges(changes: SimpleChanges) {
    if (changes['text'].previousValue !== changes['text'].currentValue) {
      if (this.taskLinksOnly) {
        this.smartPieces$.next(
          parseTasksOnly(this.text).filter(smartLink => smartLink.props && smartLink.props.taskSlug)
        );
      } else {
        this.smartPieces$.next(rootParse(this.text));
      }
    }
  }
  onSmartTaskLinkUpdated() {
    this.smartTaskLinkUpdated.emit();
  }
}
