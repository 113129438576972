import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { createNewEvent } from '../../../polyfills';

export enum TOOLBAR_BUTTONS {
  bold,
  italic,
  underline,
  strikethrough,
  img,
  link,
  code,
  blockqoute,
  heading,
  ol,
  ul,
  hr,
  preview
}

@Component({
  selector: 'markdown-toolbar',
  templateUrl: './markdown-toolbar.component.html',
  styleUrls: ['./markdown-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarkdownToolbarComponent {
  @Input() linkToTextarea;
  @Output() valuePatched = new EventEmitter();
  public TOOLBAR_BUTTONS = TOOLBAR_BUTTONS;
  public previewText = '';
  public isPreviewVisible = false;
  public originalTextareaPosition = 'static';
  public inputEvent = createNewEvent('input');

  static updateSelection(element: HTMLTextAreaElement, start: number, end: number, paddingLength: number) {
    element.selectionStart = start + paddingLength;
    element.selectionEnd = end + paddingLength;
  }

  constructor() {}
  onMarkdownBarClick(event) {
    const textEl = this.linkToTextarea.nativeElement;
    const start = textEl.selectionStart;
    const end = textEl.selectionEnd;
    textEl.focus();
    switch (+event.target.dataset.modifier) {
      case TOOLBAR_BUTTONS.bold: {
        this.updateTextareaValue('**', start, end, '**');
        MarkdownToolbarComponent.updateSelection(textEl, start, end, 2);
        break;
      }
      case TOOLBAR_BUTTONS.italic: {
        this.updateTextareaValue('*', start, end, '*');
        MarkdownToolbarComponent.updateSelection(textEl, start, end, 1);
        break;
      }
      case TOOLBAR_BUTTONS.strikethrough: {
        this.updateTextareaValue('~~', start, end, '~~');
        MarkdownToolbarComponent.updateSelection(textEl, start, end, 2);
        break;
      }
      case TOOLBAR_BUTTONS.img: {
        this.updateTextareaValue('![img](', start, end, ')');
        MarkdownToolbarComponent.updateSelection(textEl, start, end, 7);
        break;
      }
      case TOOLBAR_BUTTONS.code: {
        this.updateTextareaValue('```xxxx\n', start, end, '\n```');
        MarkdownToolbarComponent.updateSelection(textEl, start, end, 8);
        break;
      }
      case TOOLBAR_BUTTONS.link: {
        this.updateTextareaValue('[Link Text](Link URL', start, end, ')');
        MarkdownToolbarComponent.updateSelection(textEl, start, end, 20);
        break;
      }
      case TOOLBAR_BUTTONS.hr: {
        this.updateTextareaValue('\n___\n', start, end);
        MarkdownToolbarComponent.updateSelection(textEl, start, end, 5);
        break;
      }
      case TOOLBAR_BUTTONS.blockqoute: {
        this.updateTextareaValue('>', start, end, '\n');
        MarkdownToolbarComponent.updateSelection(textEl, start, end, 1);
        break;
      }
      case TOOLBAR_BUTTONS.ol: {
        this.updateTextareaValue('\n1. ', start, end);
        MarkdownToolbarComponent.updateSelection(textEl, start, end, 4);
        break;
      }
      case TOOLBAR_BUTTONS.ul: {
        this.updateTextareaValue('\n* ', start, end);
        MarkdownToolbarComponent.updateSelection(textEl, start, end, 3);
        break;
      }
      case TOOLBAR_BUTTONS.heading: {
        const isBeforeHeadingMark = textEl.value.substr(start - 2, 1) === '#';
        if (isBeforeHeadingMark) {
          textEl.selectionStart -= 1;
          this.updateTextareaValue('#', start - 1, end);
          MarkdownToolbarComponent.updateSelection(textEl, start, end, 1);
        } else {
          this.updateTextareaValue('# ', start, end);
          MarkdownToolbarComponent.updateSelection(textEl, start, end, 2);
        }
        break;
      }
      case TOOLBAR_BUTTONS.preview: {
        this.previewText = textEl.value;
        this.togglePreview(textEl);
      }
    }
    textEl.dispatchEvent(this.inputEvent);
    this.valuePatched.emit(textEl.value);
  }
  updateTextareaValue(prefix: string, start: number, end: number, suffix?: string): void {
    const textEl = this.linkToTextarea.nativeElement;
    textEl.value =
      textEl.value.slice(0, start) + prefix + textEl.value.slice(start, end) + (suffix || '') + textEl.value.slice(end);
  }
  togglePreview(textEl) {
    if (this.isPreviewVisible) {
      textEl.style.cssText = `visibility: visible; position: ${this.originalTextareaPosition};`;
    } else {
      this.originalTextareaPosition = textEl.style.position;
      textEl.style.cssText = 'visibility: hidden; position: absolute;';
    }
    this.isPreviewVisible = !this.isPreviewVisible;
  }
}
