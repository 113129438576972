<form [formGroup]="form">
  <div class="row">
    <div class="label">
      Board
    </div>
    <div class="select">
      <select formControlName="board">
        <option *ngFor="let board of  boards$ | async" [value]="board.id">{{board.name}}</option>
      </select>
    </div>
  </div>
  <validation-hint [control]="form.get('board')"></validation-hint>
  <div class="row" *ngIf="showSwimlanePicker$ | async">
    <div class="label">
      Swimlane
    </div>
    <div class="select">
      <select formControlName="swimlane">
        <option *ngFor="let board of  swimlanes$ | async" [value]="board.id">{{board.name}}</option>
      </select>
    </div>
  </div>
  <validation-hint [control]="form.get('swimlane')"></validation-hint>
  <div class="row" *ngIf="columns$ | async | getProperty : 'length'">
    <div class="label">
      Column
    </div>
    <div class="select">
      <select formControlName="column">
        <option *ngFor="let column of  columns$ | async" [value]="column.id">{{column.name}}</option>
      </select>
    </div>
  </div>
  <validation-hint [control]="form.get('column')"></validation-hint>
</form>
