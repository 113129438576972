import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Board, Collection } from '../../../interfaces';
import { AppState } from '../../../ngrx/state';
import { BoardAssignCollectionsAction } from '../../../ngrx/actions/board.actions';
import { RightMenuCollectionsListComponent } from '../right-menu-collections-list/right-menu-collections-list.component';

@Component({
  selector: 'right-menu-collections-popup',
  templateUrl: './right-menu-collections-popup.component.html',
  styleUrls: ['./right-menu-collections-popup.component.scss']
})
export class RightMenuCollectionsPopupComponent {
  @ViewChild(RightMenuCollectionsListComponent) rightMenuCollectionsListComponent: RightMenuCollectionsListComponent;

  @Input() collections: Collection[];
  @Input() boardCollections: Collection[];
  @Input() selectedBoard: Board;
  @Input() boardId: number;

  @Output() close = new EventEmitter();

  constructor(private _store: Store<AppState>) {}

  onAssignCollectionAndClosePopup() {
    if (this.rightMenuCollectionsListComponent.noEmptyCollectionsQuery()) {
      this._store.dispatch(
        new BoardAssignCollectionsAction({
          id: this.boardId,
          collections: this.rightMenuCollectionsListComponent.getCollectionsQuery()
        })
      );
      this.rightMenuCollectionsListComponent.reInitComponent();
    }
    this.close.emit();
  }
}
