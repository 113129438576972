import { Injectable } from '@angular/core';
import { CompanyService } from '../../../shared/services/app/company.service';
import { Company } from '../../../interfaces/company';
import { Moment } from 'moment-mini-ts';
import * as moment from 'moment-mini-ts';
import { CustomValidators } from '../../../shared/validators/custom-validators';

export enum CompanyTimeFormat {
  '12H' = '12H',
  '24H' = '24H'
}

export enum CompanyDateFormat {
  'US' = 'US',
  'EU' = 'EU'
}

const defaultTimeFormat = CompanyTimeFormat['12H'];
const defaultDateFormat = CompanyDateFormat['US'];

export const TIME_FORMATS = {
  [CompanyTimeFormat['12H']]: 'h:mm A',
  [CompanyTimeFormat['24H']]: 'H:mm'
};

export const DATE_FORMATS = {
  [CompanyDateFormat.US]: {
    input: {
      date: 'MM.DD.YYYY'
    },
    output: {
      fullDate: 'MMM DD, YYYY',
      shortDate: 'MMM DD'
    }
  },
  [CompanyDateFormat.EU]: {
    input: {
      date: 'DD.MM.YYYY'
    },
    output: {
      fullDate: 'DD MMM. YYYY',
      shortDate: 'DD MMM.'
    }
  }
};

@Injectable()
export class DateTimeFormatterService {
  get DATE_FORMAT() {
    return DATE_FORMATS[this.dateFormat]['output']['fullDate'];
  }

  get SHORT_DATE_FORMAT() {
    return DATE_FORMATS[this.dateFormat]['output']['shortDate'];
  }

  get TIME_FORMAT() {
    return TIME_FORMATS[this.timeFormat];
  }

  get DATETIME_FORMAT() {
    return this.DATE_FORMAT + ' [at] ' + this.TIME_FORMAT;
  }

  get SHORT_DATETIME_FORMAT() {
    return this.SHORT_DATE_FORMAT + ' [at] ' + this.TIME_FORMAT;
  }

  get INPUT_DATE_FORMAT() {
    return DATE_FORMATS[this.dateFormat]['input']['date'];
  }

  get INPUT_TIME_FORMAT() {
    return this.TIME_FORMAT;
  }

  get INPUT_DATE_FORMAT_FOR_PICKER() {
    return this.INPUT_DATE_FORMAT.toLocaleLowerCase();
  }

  get INPUT_DATETIME_FORMAT() {
    return this.INPUT_DATE_FORMAT + ' ' + this.INPUT_TIME_FORMAT;
  }

  get dateValidator() {
    return CustomValidators.date(this.INPUT_DATE_FORMAT);
  }
  get timeValidator() {
    return CustomValidators.date(this.INPUT_TIME_FORMAT);
  }

  get is24HoursFormat() {
    return this.timeFormat === CompanyTimeFormat['24H'];
  }

  private timeFormat: CompanyTimeFormat = defaultTimeFormat;
  private dateFormat: CompanyDateFormat = defaultDateFormat;

  constructor(private _company: CompanyService) {
    this.init();
  }

  init() {
    this._company.currentCompany$.subscribe((company: Company) => {
      this.timeFormat = <CompanyTimeFormat>company.timeFormat || defaultTimeFormat;
      this.dateFormat = <CompanyDateFormat>company.dateFormat || defaultDateFormat;
    });
  }

  getSuitableDateFormat(momentObj: Moment) {
    return momentObj.isSame(moment(), 'year') ? this.SHORT_DATE_FORMAT : this.DATE_FORMAT;
  }

  getSuitableDateTimeFormat(momentObj: Moment) {
    return momentObj.isSame(moment(), 'year') ? this.SHORT_DATETIME_FORMAT : this.DATETIME_FORMAT;
  }
}
