import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment-mini-ts';
import { CompanyService } from '../../shared/services/app/company.service';
import { KeyCode } from '../../constants';
import MaterialDateTimePicker from 'material-datetime-picker';
import { DateTimeFormatterService } from '../../libs/date-time-formatter/services/date-time-formatter.service';

/**
 * WARNING
 * Original component emits close event during submit, so in (cancel) handler you should check is it real cancel or a part of submit
 * @example prevent close event if submit started
 * onClose() {
      if (this.isPending) {
        return;
      }
      this.close.emit();
    }

   onSubmit() {
      this.isPending = true;
      this.form.markAsTouched();
      his._formService.submit();
    }
 */

@Component({
  selector: 'a-date-time-inputless',
  templateUrl: './a-date-time-inputless.component.html',
  styleUrls: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ADateTimeInputlessComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ADateTimeInputlessComponent implements OnInit {
  @Input() pickerId: string;
  @Input() time = true;
  @Input() isClearBtn = true;
  @Input() defaultDate = 0;

  @Output() change = new EventEmitter();
  @Output() submitValue = new EventEmitter();
  @Output() cancel = new EventEmitter();

  private _currentValue;
  private picker;

  set currentValue(value) {
    this._currentValue = value;
  }

  get currentValue() {
    return this._currentValue;
  }

  private propagateChangeFn = (_: any) => {};
  private propagateTouched = () => {};
  constructor(private _dateTimeFormatter: DateTimeFormatterService, private _company: CompanyService) {}

  public writeValue(value: number | undefined | null) {
    this.currentValue = value;
    this.picker.setDate(+moment(this._currentValue ? this._currentValue : this.defaultDate, 'X'));
  }

  public registerOnChange(fn: any) {
    this.propagateChangeFn = fn;
  }

  public registerOnTouched(fn: any) {
    this.propagateTouched = fn;
  }

  private propagateChange() {
    this.propagateChangeFn(this.currentValue);
    this.change.emit(this.currentValue);
  }

  private clickListener = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
  };

  private clearBtnListener = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    this.currentValue = 0;
    this.propagateChange();
    this.submitValue.emit();
    this.picker.close();
  };

  private keyListener = (event: KeyboardEvent) => {
    if (event.keyCode === KeyCode.KEY_ESCAPE) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      if (this.picker) {
        this.picker.close();
      }
    }
  };

  ngOnInit() {
    this.propagateTouched();

    const picker = new MaterialDateTimePicker({
      default: moment(this._currentValue ? this._currentValue : this.defaultDate, 'X'),
      twentyFourHours: this._dateTimeFormatter.is24HoursFormat,
      format: this._dateTimeFormatter.DATE_FORMAT,
      weekStart: this._company.weekStartDay
    })
      .on('submit', val => {
        this.currentValue = val.unix();
        this.propagateChange();
        this.submitValue.emit();
      })
      .on('open', () => {
        document.body.addEventListener('mousedown', this.clickListener);
        document.body.addEventListener('keydown', this.keyListener, true);
        if (!this.time) {
          setTimeout(() => {
            const timeEl = document.getElementsByClassName('c-datepicker__header-date__time')[0];
            timeEl.innerHTML = '';
            const showTimeEl = document.getElementsByClassName('c-datepicker--show-time')[0];
            showTimeEl.innerHTML = '';
            showTimeEl.classList.add('no_after');
            const showCalendarEl = document.getElementsByClassName('c-datepicker--show-calendar')[0];
            showCalendarEl.innerHTML = '';
            showCalendarEl.classList.add('no_after');
          });
        }
        if (this.isClearBtn) {
          setTimeout(() => {
            const deleteBtn = document.createElement('a');
            deleteBtn.innerHTML = 'Clear';
            deleteBtn.className = 'c-btn c-btn--flat';
            deleteBtn.style.marginRight = '80px';
            deleteBtn.style.color = 'red';
            let parentEl = document.getElementsByClassName('modal-btns')[0];
            parentEl.insertBefore(deleteBtn, parentEl.firstChild);
            deleteBtn.addEventListener('click', this.clearBtnListener);
          });
        }
      })
      .on('close', () => {
        document.body.removeEventListener('mousedown', this.clickListener);
        document.body.removeEventListener('keydown', this.keyListener, true);
        this.cancel.emit();
        this.picker = undefined;
      });
    picker.open();
    this.picker = picker;
  }

  ngOnDestroy() {
    if (this.picker) {
      this.picker.close();
    }
  }
}
