import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observer } from 'rxjs';
import { Task } from '../../interfaces';
import { ScalePokerValues, ScaleType, TASK_PL } from '../../constants';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/state';
import { FormComponent, FormSaveType, FormServiceParams, FormV2Service } from '../../shared/services/form-v2.service';
import { HandleResponseAction } from '../../ngrx/actions/root.action';
import { JsonApiSingeModelResponse } from '../../shared/services/app/web-socket/http-response';
import { AppUrls } from '../../app-urls';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { filter, map, pluck, switchMap, take, tap } from 'rxjs/operators';
import { fromBoards } from '../../ngrx/reducers/board.reducer';
import { isPresent } from '../../../helpers';
import { getMaxScoreRangeValue, getTaskAxisSentValue, getVisibleTaskAxisScore } from '../../../helpers/task';
import { GuiStateSetBoardViewMode } from '../../ngrx/actions/gui-state-memorized.actions';
import { BoardViewMode } from '../../ngrx/reducers/gui-state-memorized.reducer';
import { RouterNavigateService } from '../../shared/services/router-navigate.service';
import { Router } from '@angular/router';

@Component({
  providers: [FormV2Service],
  selector: 'score-form',
  templateUrl: './score-form.component.html',
  styleUrls: ['./score-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScoreFormComponent implements OnInit, OnChanges, FormComponent {
  @Input() task: Task;
  @Input() editPermissions: boolean;
  @ViewChild('backlog_score_y') backlog_score_y: ElementRef;

  public appUrls = AppUrls;
  public boardId$ = new BehaviorSubject(null);
  public scoreXLabel$;
  public scoreYLabel$;
  public scoreXType$;
  public scoreYType$;
  public maxScoreY$;
  public maxScoreX$;

  public scoreXType;
  public scoreYType;

  public getVisibleScore = getVisibleTaskAxisScore;
  public getSentValue = getTaskAxisSentValue;

  formObserver: Observer<any> = {
    next: (resp: JsonApiSingeModelResponse<any>) => {
      this._store.dispatch(new HandleResponseAction(resp));
      console.log(resp, 'next on component');
      // dispatch Action
    },
    error: error => {
      console.log(error, 'error on component');
      // display Error
    },
    complete: () => {
      console.log('complete component');
      // ex. destroy component
    }
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.edit,
    entityToEdit: TASK_PL,
    formObserver: this.formObserver,
    prepareFormValue: formValue => {
      return {
        id: formValue['id'],
        backlogScoreY: getTaskAxisSentValue(formValue['backlogScoreY'], this.scoreYType),
        backlogScoreX: getTaskAxisSentValue(formValue['backlogScoreX'], this.scoreXType)
      };
    }
  };

  form: FormGroup;

  constructor(
    private _fb: FormBuilder,
    public _formService: FormV2Service,
    private _store: Store<AppState>,
    private _router: Router
  ) {}

  ngOnInit(): any {
    this.scoreXLabel$ = this.boardId$.pipe(
      filter(isPresent),
      switchMap(id => this._store.select(fromBoards.get(id))),
      pluck('backlogScoreXLabel')
    );
    this.scoreYLabel$ = this.boardId$.pipe(
      filter(isPresent),
      switchMap(id => this._store.select(fromBoards.get(id))),
      pluck('backlogScoreYLabel')
    );
    this.scoreXType$ = this.boardId$.pipe(
      filter(isPresent),
      switchMap(id => this._store.select(fromBoards.get(id))),
      pluck('backlogScoreXType'),
      tap(type => (this.scoreXType = type))
    );
    this.scoreYType$ = this.boardId$.pipe(
      filter(isPresent),
      switchMap(id => this._store.select(fromBoards.get(id))),
      pluck('backlogScoreYType'),
      tap(type => (this.scoreYType = type))
    );

    this.scoreXType$.pipe(take(1)).subscribe();
    this.scoreYType$.pipe(take(1)).subscribe();

    this.maxScoreY$ = this.scoreYType$.pipe(map(getMaxScoreRangeValue));
    this.maxScoreX$ = this.scoreXType$.pipe(map(getMaxScoreRangeValue));

    this.form = this._fb.group({
      id: [this.task.id],
      backlogScoreY: [this.getValueForRangeInput(this.task.backlogScoreY, this.scoreYType)],
      backlogScoreX: [this.getValueForRangeInput(this.task.backlogScoreX, this.scoreXType)]
    });
    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.task && this.form) {
      if (change.task.currentValue.backlogScoreY !== change.task.previousValue.backlogScoreY) {
        this.form
          .get('backlogScoreY')
          .setValue(this.getValueForRangeInput(change.task.currentValue.backlogScoreY, this.scoreYType));
      }
      if (change.task.currentValue.backlogScoreX !== change.task.previousValue.backlogScoreX) {
        this.form
          .get('backlogScoreX')
          .setValue(this.getValueForRangeInput(change.task.currentValue.backlogScoreX, this.scoreXType));
      }
    }
    if (change.task.currentValue) {
      this.boardId$.next(change.task.currentValue.board);
    }
  }

  onSubmit() {
    return this._formService.submit();
  }

  public getValueForRangeInput(value, type) {
    if (type === ScaleType.poker) {
      const result = ScalePokerValues.findIndex(item => item === value);
      return result > -1 ? result : value;
    }
    return value;
  }

  onViewOnPriorityMatrix(event) {
    event.preventDefault();
    this._store.dispatch(new GuiStateSetBoardViewMode({ id: this.task.board, mode: BoardViewMode.priorityChart }));
    this._router.navigate(AppUrls.getUrlBacklogChartWithClosedTask(this.task.board), {
      fragment: this.task.taskKey || this.task.id + ''
    });
  }
}
