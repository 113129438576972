
import {distinctUntilChanged, combineLatest} from 'rxjs/operators';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as d3 from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3Array from 'd3-array';
import * as d3Axis from 'd3-axis';
import { Observable ,  BehaviorSubject ,  Subscription } from 'rxjs';
import { EstimationType } from '../../../../constants';
import { VelocityVersion } from '../../../../interfaces/version';

export const valuesForStoryPoints = {
  yLabel: 'Story Points',
  tickFormatFn: d => d,
  firstBarProp: 'storyPoints',
  secondBarProp: 'storyPointsDone',
  valuesPostfix: ''
};
export const valuesForHours = {
  yLabel: 'Hours',
  tickFormatFn: d => d + 'h',
  firstBarProp: 'hours',
  secondBarProp: 'hoursDone',
  valuesPostfix: 'h'
};
@Component({
  selector: 'velocity-report-d3',
  templateUrl: './velocity-report-d3.component.html',
  styleUrls: ['./velocity-report-d3.component.scss']
})
export class VelocityReportD3Component implements OnInit, OnDestroy {
  @Input() type$: Observable<string>;
  @Input() chartData$: Observable<VelocityVersion[]>;
  @Input() chartItemsScales$ = new BehaviorSubject([]);
  @ViewChild('chartContainer') chartContainerRef: ElementRef;
  subs: Subscription[] = [];
  public defaultWidth = 960;
  public defaultHeight = 500;
  public defaultMarginBottom = 50;
  public margin = { top: 20, right: 20, bottom: this.defaultMarginBottom, left: 55 };
  public width = this.defaultWidth - this.margin.left - this.margin.right;
  public height = this.defaultHeight - this.margin.top - this.margin.bottom;
  public chartSettings = valuesForStoryPoints;

  public xScale;
  public yScale;
  private svg: any;

  public tooltip: any = { visible: false };

  constructor() {}

  ngOnInit() {
    this.subs.push(
      this.chartData$.pipe(
        combineLatest(this.type$, (data, type) => ({ data: data, type: type })),
        distinctUntilChanged(),)
        .subscribe(chartData => {
          this.initSvg(chartData);
          this.initAxis(chartData.data, chartData.type);
          this.drawAxis();
          this.drawBars(chartData.data);
        })
    );
  }

  initSvg(chartData) {
    this.width =
      (this.defaultWidth / chartData.data.length > 30 ? this.defaultWidth : chartData.data.length * 30) -
      this.margin.left -
      this.margin.right;
    this.chartSettings = chartData.type === EstimationType.storyPoints ? valuesForStoryPoints : valuesForHours;
    const xMaxLabelLength = Math.max(...chartData.data.map(item => item.version.length));
    if (xMaxLabelLength > 6) {
      this.margin.bottom = this.defaultMarginBottom + Math.min((xMaxLabelLength - 6) * 5, 300);
    }
    this.svg = d3.select('svg#velocity-report');
  }

  initAxis(data, type) {
    this.xScale = d3Scale
      .scaleBand()
      .rangeRound([0, this.width])
      .padding(0.1)
      .domain(data.map(d => d.version));
    this.yScale = d3Scale.scaleLinear().rangeRound([this.height, 0]);
    this.yScale.domain([0, d3Array.max(data, d => d[type])]);
  }

  drawAxis() {
    d3
      .select('svg#velocity-report .g-x-axis')
      .call(d3Axis.axisBottom(this.xScale))
      .selectAll('text')
      .attr('y', 0)
      .attr('x', -7)
      .attr('text-anchor', 'end')
      .attr('dy', '.8em')
      .attr('transform', 'rotate(-45)');
    d3
      .select('svg#velocity-report .g-y-axis')
      .call(d3Axis.axisLeft(this.yScale).tickFormat(this.chartSettings.tickFormatFn));
  }

  drawBars(data) {
    const bandWidth = this.xScale.bandwidth();
    const barWidth = Math.min(bandWidth / 2, 100);
    this.chartItemsScales$.next(
      data.map((versionData: VelocityVersion) => ({
        ...versionData,
        allX: this.xScale(versionData.version) + bandWidth / 2 - barWidth + this.margin.left,
        allY: this.yScale(versionData[this.chartSettings.firstBarProp]) + this.margin.top,
        allWidth: barWidth,
        allHeight: this.height - this.yScale(versionData[this.chartSettings.firstBarProp]),
        doneX: this.xScale(versionData.version) + bandWidth / 2 + this.margin.left,
        doneY: this.yScale(versionData[this.chartSettings.secondBarProp]) + this.margin.top,
        doneWidth: barWidth,
        doneHeight: this.height - this.yScale(versionData[this.chartSettings.secondBarProp])
      }))
    );
  }

  onHoverVersion(version) {
    this.tooltip = {
      visible: true,
      x: version.doneX - 70 - this.chartContainerRef.nativeElement.scrollLeft,
      y: Math.min(version.allY, version.doneY) - 70,
      estimated: version[this.chartSettings.firstBarProp] + this.chartSettings.valuesPostfix,
      completed: version[this.chartSettings.secondBarProp] + this.chartSettings.valuesPostfix
    };
  }

  onLeaveVersion() {
    this.tooltip.visible = false;
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
