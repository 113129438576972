import { Action } from '../unsafe-action';

export enum DefaultTasksQuadsFilterActionTypes {
  TOGGLE_QUAD = '[DEFAULT_QUADS_FILTER] TOGGLE_QUAD',
  RESET = '[DEFAULT_QUADS_FILTER] RESET'
}

export class DefaultQuadFilterToggleQuadsAction implements Action {
  readonly type = DefaultTasksQuadsFilterActionTypes.TOGGLE_QUAD;

  constructor(public payload: string) {}
}

export class DefaultQuadsFilterResetAction implements Action {
  readonly type = DefaultTasksQuadsFilterActionTypes.RESET;
}

// prettier-ignore
export type Actions
  = DefaultQuadFilterToggleQuadsAction
  | DefaultQuadsFilterResetAction
  ;
