import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BillingSubscriptionEntity } from '@atlaz/billing/interfaces/billing-subscription';
import { Observer } from 'rxjs';
import { JsonApiSingeModelResponse } from '../../../shared/services/app/web-socket/http-response';
import { HandleResponseAction } from '../../../ngrx/actions/root.action';
import {
  FormComponent,
  FormSaveType,
  FormServiceParams,
  FormV2Service
} from '../../../shared/services/form-v2.service';
import { SUBSCRIPTION_PATH } from '@atlaz/billing/constants/billing-subscription';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { RouterNavigateService } from '../../../shared/services/router-navigate.service';
import { PhoneCodesService } from '../../../shared/services/phone-codes.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'edit-subscribtion-form',
  templateUrl: './edit-subscribtion-form.component.html',
  styleUrls: ['./edit-subscribtion-form.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSubscribtionFormComponent implements OnInit, FormComponent {
  @Input() billingSub: BillingSubscriptionEntity;

  formObserver: Observer<any> = {
    next: (resp: JsonApiSingeModelResponse<any>) => {
      this._store.dispatch(new HandleResponseAction(resp));
      // TODO: add 3 types of message depends on billing status
      this._toastr.success('Data has been successfully saved');
      this._routerNav.navigateToBilling();
    },

    error: error => {},

    complete: () => {}
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.edit,
    entityToEdit: SUBSCRIPTION_PATH,
    formObserver: this.formObserver,
    prepareFormValue: formValue => {
      const value = {
        id: this.billingSub.id,
        action: 'additional-data',
        additionalData: { ...formValue }
      };
      delete value.additionalData['token'];
      delete value.additionalData['id'];

      return value;
    }
  };

  public form: FormGroup;
  public countries$ = this._phoneCodesService.getCountriesInputOptions();

  constructor(
    private _fb: FormBuilder,
    private _store: Store<AppState>,
    public _formService: FormV2Service,
    private _toastr: ToastrService,
    private _routerNav: RouterNavigateService,
    private _phoneCodesService: PhoneCodesService
  ) {}

  ngOnInit() {
    this.form = this._fb.group({
      company_name: [''],
      card_holder_name: [''],
      address_line1: [''],
      address_city: [''],
      address_state: [''],
      address_zip: [''],
      address_country: ['US'],
      phone_number: [''],
      email: ['', [Validators.required, Validators.email]]
    });

    Object.keys(this.billingSub.additionalData).forEach(key => {
      if (this.form.contains(key)) {
        this.form.get(key).setValue(this.billingSub.additionalData[key]);
      }
    });

    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  onSubmit() {
    if (this.form.invalid) {
      return false;
    }

    this._formService.submit();

    return false;
  }
}
