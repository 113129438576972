import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomField } from '../../../../ngrx/reducers/custom-field.reducer';
import { AtlazApiV2Service } from '../../../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { ScoringType } from '../../../../constants';
import { Board } from '../../../../interfaces';

@Component({
  selector: 'list-board-header',
  templateUrl: './list-board-header.component.html',
  styleUrls: ['./list-board-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListBoardHeaderComponent implements OnInit {
  @Input() board: Board;
  @Input() isNotGuest: boolean;
  @Input() groupWidth = 'auto';
  @Input() groupsLength: number;
  @Input() customFields: CustomField[];
  @Input() isGroupedBySwimlane: boolean;
  @Input() scoringColumns: any[];

  @Output() openFieldsManager = new EventEmitter();

  public scoringColumnsLabels: string[];
  public ScoringType = ScoringType;
  public isAddSwimlanePopupVisible: boolean;

  constructor(protected _atlazApi: AtlazApiV2Service) {}

  ngOnInit() {}

  ngOnChanges() {
    this.scoringColumnsLabels = this.getScoringLabels();
  }

  onOpenFieldManager() {
    this.openFieldsManager.emit();
  }

  onAddGroup() {
    this.isAddSwimlanePopupVisible = true;
  }
  onCloseAddGroup() {
    this.isAddSwimlanePopupVisible = false;
  }

  getScoringLabels() {
    switch (this.board.scoringType) {
      case ScoringType.basic: {
        return this.scoringColumns.map(item => item.label);
      }
      case ScoringType.ICE: {
        return this.scoringColumns.map(item => item.label);
      }
      case ScoringType.RICE: {
        return this.scoringColumns.map(item => item.label);
      }
      case ScoringType.advanced: {
        return this.scoringColumns.map(item => item.name);
      }
      default: {
        return this.scoringColumns;
      }
    }
  }
}
