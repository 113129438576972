import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Board, Column, Label, PartOfEntity, Swimlane, Task, User, Version } from '../../interfaces';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject, combineLatest, fromEvent, Subscription } from 'rxjs/index';
import { AppUrls } from '../../app-urls';
import { boardType, ScaleTypeLabel, ScoringType } from '../../constants';
import { IceFactors, RiceFactors, ScoringFactor } from '../backlog-board/constants/backlog.constants';
import { ScoringCriteria, SeparatedCriteria } from '../../interfaces/ScoringCriteria';
import { AppState } from '../../ngrx/state';
import { Store } from '@ngrx/store';
import { getTasksByUsersFilter, inBoard, isActiveTask } from '../../ngrx/reducers/task.reducer';
import {
  distinctUntilChanged,
  filter,
  map,
  pluck,
  publishReplay,
  refCount,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { fromScoringCriteria } from '../../ngrx/reducers/scoring-criteria.reducer';
import { getVisibleTaskAxisScore } from '../../../helpers/task';
import { allPass, getVisibleScoringVal, naturalSort, sortByField } from '../../../helpers';
import { fromLabels } from '../../ngrx/reducers/label.reducer';
import { fromUsers } from '../../ngrx/reducers/user.reducer';
import {
  getTaskPossibleUsers,
  getTaskProjectOnlyUsers,
  getTaskUsers,
  getVersionsListByBoardId
} from '../../ngrx/functions/crossed.selector';
import { BoardAssignUsersAction } from '../../ngrx/actions/board.actions';
import { TaskAssignUsersAction, TaskDeleteAction, TaskEditAction } from '../../ngrx/actions/task.actions';
import { fromGuiState } from '../../ngrx/reducers/gui-state-memorized.reducer';
import {
  GuiStateQuickTaskEditShow,
  GuiStateToggleVersionVisibility
} from '../../ngrx/actions/gui-state-memorized.actions';
import { VersionAssignTaskAction } from '../../ngrx/actions/version.actions';
import { fromColumns } from '../../ngrx/reducers/column.reducer';
import { fromSwimlanes } from '../../ngrx/reducers/swimlane.reducer';
import { createMapFromArr } from 'helpers/array';
import { GlobalHotkeysService } from '../../libs/global-hotkeys/global-hotkeys.service';
import { RouterNavigateService } from '../../shared/services/router-navigate.service';
import { fromTasksCustomFields, TasksCustomField } from '../../ngrx/reducers/tasks-custom-field.reducer';
import { CustomField, CustomFieldTypes, fromCustomFields } from '../../ngrx/reducers/custom-field.reducer';
import { SecondsToDatePipe } from '../../shared/pipes';
import { BoardInProjectAccess } from '../../interfaces/board';
import getHiddenVersions = fromGuiState.getHiddenVersions;

interface CalculatedTask {
  id: number;
  title: string;
  users?: User[] | {}[];
  labels?: Label[] | {}[];
  version?: number;
  backlogScoreX?: string | number;
  backlogScoreY?: string | number;
  iceImpact?: string | number;
  iceConfidence?: string | number;
  iceEase?: string | number;
  riceReach?: string | number;
  riceImpact?: string | number;
  riceConfidence?: string | number;
  riceEffort?: string | number;
  confidence?: number;
  scoringValuesMap?: any;
  score?: string | number;
  position?: number;
  estimate?: number;
  storyPoints?: number;
  startDate: number;
  dueDate: number;
  column: string;
  swimlane: string;
}

interface BoardWeights {
  valueWeight: number;
  effortsWeight: number;
}

@Component({
  selector: 'board-versions-view',
  templateUrl: './board-versions-view.component.html',
  styleUrls: ['./board-versions-view.component.scss'],
  providers: [SecondsToDatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoardVersionsViewComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() board$: Observable<Board>;
  @Input() isNotGuest: boolean;
  public boardId$: Observable<number>;
  public tasks$: Observable<Task[]>;
  public type$: Observable<ScoringType>;
  public calculatedTasks$: Observable<CalculatedTask[]>;
  public calculatedTasksByVersion$: Observable<{ [version: number]: CalculatedTask[] }>;
  public filterValue$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public getTaskLink = AppUrls.getUrlTask;
  public isScoreEditing$ = new BehaviorSubject(false);
  public editingTask$ = new BehaviorSubject(null);
  public editingTitleId: number;
  public editingTitleValue: string;
  public editingTitleHeight: number;
  public editingFactor$ = new BehaviorSubject(null);
  public editingCriterion$ = new BehaviorSubject(null);
  public editingAxis$ = new BehaviorSubject(null);
  public showSwimlanes$: Observable<'yes' | 'no'>;

  public factors$;
  public versions$: Observable<(Version | { id: number; name: string })[]>;
  public labels$: Observable<Label[]>;
  public users$: Observable<User[]>;
  public columns$: Observable<Column[]>;
  public swimlanes$: Observable<Swimlane[]>;
  subs: Subscription[] = [];
  public ScoringType = ScoringType;
  public boardType = boardType;

  public iceFactors = IceFactors;
  public riceFactors = RiceFactors;

  public boardCriteria$: Observable<SeparatedCriteria>;
  public crsMap;
  public versionsMap: { [prop: number]: Version };
  public labelsMap: { [prop: number]: Label };
  public usersMap: { [prop: number]: User };
  public columnsMap: { [prop: number]: Column };
  public swimlanesMap: { [id: number]: Swimlane };
  public boardWeights$: Observable<BoardWeights>;
  public criteriaValues$: Observable<ScoringCriteria[]>;
  public criteriaEfforts$: Observable<ScoringCriteria[]>;

  public customFields$: Observable<CustomField[]>;
  public tasksCustomFields$: Observable<TasksCustomField[]>;
  public customFieldsMap: { [id: number]: CustomField };
  public tasksCustomFieldsMap: {
    [custmFieldId: number]: { [taskId: number]: { visibleValue: string; value: number | string } };
  };

  public isPublicBoard$: Observable<boolean>;
  public taskUsers$: Observable<User[]>;
  public possibleUsers$: Observable<User[]>;
  public projectOnlyUsers$: Observable<User[]>;
  public isMembersPopupVisible$ = new BehaviorSubject(false);
  public isLabelsPopupVisible$ = new BehaviorSubject(false);
  public isAddTaskPopupVisible$ = new BehaviorSubject(false);
  public isStartDatePopupVisible$ = new BehaviorSubject(false);
  public isDueDatePopupVisible$ = new BehaviorSubject(false);
  public isColumnPopupVisible$ = new BehaviorSubject(false);
  public editCustomFieldTaskId$ = new BehaviorSubject(0);
  public tasksCustomFieldClientRect;
  public editingCustomFieldValue: TasksCustomField | PartOfEntity;
  public editingCustomField: CustomField;

  public hiddenVersions = {};
  public newTaskInitialVersion: number;
  public insertToVersionId: number;

  public visibleMenuVersionId;
  public addVersionLink$;
  public taskToDelete: Task;

  public CustomFieldTypes = CustomFieldTypes;

  constructor(
    private _globalHotKeys: GlobalHotkeysService,
    private _elRef: ElementRef,
    private _store: Store<AppState>,
    private _routerNav: RouterNavigateService,
    private _toDate: SecondsToDatePipe
  ) {}

  ngOnInit() {
    this.boardId$ = this.board$.pipe(map(board => (board ? board.id : 0)));
    this.isPublicBoard$ = this.board$.pipe(map(board => board && board.access === BoardInProjectAccess.public));

    this.subs.push(
      this.boardId$
        .pipe(distinctUntilChanged(), withLatestFrom(this._store.select(getHiddenVersions)))
        .subscribe(([id, hiddenVersions]) =>
          Object.keys(hiddenVersions).forEach(key => (this.hiddenVersions[key] = hiddenVersions[key]))
        )
    );

    this.swimlanes$ = this.boardId$.pipe(switchMap(id => this._store.select(fromSwimlanes.getByBoardId(id))));
    this.showSwimlanes$ = this.swimlanes$.pipe(
      map(swimlanes => (Array.isArray(swimlanes) && swimlanes.length > 1 ? 'yes' : 'no'))
    );

    this.versions$ = this.boardId$.pipe(
      switchMap(id => this._store.select(getVersionsListByBoardId(id))),
      map(versions => versions.filter(v => !v.released)),
      map(versions => [...versions, { id: -1, name: 'Unversioned' }])
    );
    this.labels$ = this.boardId$.pipe(
      switchMap(id => this._store.select(fromLabels.getByBoardId(id))),
      map(labels => {
        let i = 1;
        return labels.map(label => {
          if (!label.name) {
            const result = { ...label };
            result.name = 'Untitled Label ' + i;
            i++;
            return result;
          }
          return label;
        });
      })
    );
    this.users$ = this.board$.pipe(
      switchMap(board => this._store.select(fromUsers.getByIds(board.usersIds ? board.usersIds : [])))
    );
    this.columns$ = this._store.select(fromColumns.getListWithFullNames);
    this.type$ = this.board$.pipe(pluck('scoringType'));

    this.customFields$ = this.boardId$.pipe(
      switchMap(id => this._store.select(fromCustomFields.getByBoardId(id))),
      publishReplay(1),
      refCount()
    );
    this.tasksCustomFields$ = this._store.select(fromTasksCustomFields.getAll).pipe(publishReplay(1), refCount());

    this.subs.push(
      this.labels$.subscribe(labels => {
        this.labelsMap = createMapFromArr(labels);
      }),
      this.users$.subscribe(users => {
        this.usersMap = createMapFromArr(users);
      }),
      this.columns$.subscribe(columns => {
        this.columnsMap = createMapFromArr(columns);
      }),
      this.swimlanes$.subscribe(swimlanes => {
        this.swimlanesMap = createMapFromArr(swimlanes);
      }),
      this.customFields$.subscribe(customFields => {
        this.customFieldsMap = createMapFromArr(customFields);
      }),
      combineLatest(this.customFields$, this.tasksCustomFields$).subscribe(([customFields, tasksCustomFields]) => {
        this.tasksCustomFieldsMap = customFields.reduce((acc, item) => {
          acc[item.id] = {};
          return acc;
        }, {});
        tasksCustomFields.forEach(item => {
          if (!this.tasksCustomFieldsMap[item.customField]) {
            return;
          }
          this.tasksCustomFieldsMap[item.customField][item.task] = {
            visibleValue:
              this.customFieldsMap[item.customField].type === CustomFieldTypes.date
                ? this._toDate.transform(item.value, 'dueDate')
                : item.value,
            value: item.value
          };
        });
      })
    );

    this.boardCriteria$ = this.boardId$.pipe(
      switchMap(id => this._store.select(fromScoringCriteria.getByBoard(id))),
      map(crs =>
        crs.reduce(
          (acc, item) => {
            if (item.factor === ScoringFactor.Value) {
              acc[ScoringFactor.Value].push(item);
            } else {
              acc[ScoringFactor.Efforts].push(item);
            }
            return acc;
          },
          { [ScoringFactor.Value]: [], [ScoringFactor.Efforts]: [] }
        )
      )
    );

    this.boardWeights$ = this.board$.pipe(
      map(board => ({ valueWeight: board.advancedValueWeight, effortsWeight: board.advancedEffortWeight }))
    );

    this.criteriaValues$ = this.boardCriteria$.pipe(
      map((sepCrs: SeparatedCriteria) => (sepCrs ? sepCrs[ScoringFactor.Value] : [])),
      publishReplay(1),
      refCount()
    );

    this.criteriaEfforts$ = this.boardCriteria$.pipe(
      map((sepCrs: SeparatedCriteria) => (sepCrs ? sepCrs[ScoringFactor.Efforts] : [])),
      publishReplay(1),
      refCount()
    );

    this.tasks$ = this.tasks$ = this.boardId$.pipe(
      distinctUntilChanged(),
      switchMap(boardId =>
        this._store.pipe(
          getTasksByUsersFilter(
            allPass([inBoard(boardId), task => task.type !== 'group' && !task.linkToTask, isActiveTask])
          )
        )
      ),
      map(sortByField('position', 'asc')),
      publishReplay(1),
      refCount()
    );

    this.factors$ = this.board$.pipe(
      map(board => {
        if (board.scoringType === ScoringType.ICE) {
          return this.iceFactors;
        } else if (board.scoringType === ScoringType.RICE) {
          return this.riceFactors;
        } else if (board.scoringType === ScoringType.basic) {
          return [
            {
              type: board.backlogScoreXType,
              prop: 'backlogScoreX',
              label: board.backlogScoreXLabel,
              editInfo: ScaleTypeLabel[board.backlogScoreXType]
            },
            {
              type: board.backlogScoreYType,
              prop: 'backlogScoreY',
              label: board.backlogScoreYLabel,
              editInfo: ScaleTypeLabel[board.backlogScoreYType]
            }
          ];
        }
      })
    );

    this.calculatedTasks$ = combineLatest([
      this.tasks$,
      this.type$,
      this.board$,
      this.boardCriteria$,
      this.boardWeights$
    ]).pipe(
      tap(
        ([tasks, scoringType, board, sepCrs, boardWeights]: [
          Task[],
          ScoringType,
          Board,
          SeparatedCriteria,
          BoardWeights
        ]) => {
          if (scoringType === ScoringType.advanced) {
            this.crsMap = {};
            sepCrs[ScoringFactor.Value].forEach(cr => (this.crsMap[cr.id] = cr));
            sepCrs[ScoringFactor.Efforts].forEach(cr => (this.crsMap[cr.id] = cr));
          }
        }
      ),
      map(
        ([tasks, type, board, sepCrs, boardWeights]: [
          Task[],
          ScoringType,
          Board,
          SeparatedCriteria,
          BoardWeights
        ]): CalculatedTask[] =>
          tasks.map((task: Task): CalculatedTask => {
            const result: CalculatedTask = {
              id: task.id,
              title: task.title,
              position: task.position,
              version: task.version,
              estimate: task.estimate,
              storyPoints: task.storyPoints,
              startDate: task.startDate,
              dueDate: task.dueDate,
              column: this.columnsMap[task.column] ? this.columnsMap[task.column].name : '',
              swimlane: this.swimlanesMap[task.swimlane] ? this.swimlanesMap[task.swimlane].name : '',
              labels: (task.labelsIds || []).map(id => this.labelsMap[id] || {}),
              users: (task.usersIds || []).map(id => this.usersMap[id] || {})
            };
            switch (type) {
              case ScoringType.ICE: {
                result.iceImpact = Number.isFinite(task.iceImpact) ? task.iceImpact : '?';
                result.iceConfidence = Number.isFinite(task.iceConfidence) ? task.iceConfidence : '?';
                result.iceEase = Number.isFinite(task.iceEase) ? task.iceEase : '?';
                result.score = this.getICEScore(result);
                break;
              }
              case ScoringType.RICE: {
                result.riceReach = Number.isFinite(task.riceReach) ? task.riceReach : '?';
                result.riceImpact = Number.isFinite(task.riceImpact) ? task.riceImpact : '?';
                result.riceConfidence = Number.isFinite(task.riceConfidence) ? task.riceConfidence : '?';
                result.riceEffort = Number.isFinite(task.riceEffort) ? task.riceEffort : '?';
                result.score = this.getRICEScore(result);
                break;
              }
              case ScoringType.basic: {
                result.backlogScoreX = getVisibleTaskAxisScore(task.backlogScoreX, board.backlogScoreXType);
                result.backlogScoreY = getVisibleTaskAxisScore(task.backlogScoreY, board.backlogScoreYType);
                break;
              }
              case ScoringType.advanced: {
                result.confidence = task.confidence;
                result.scoringValuesMap = this.getScoringValuesMap(task);
                result.score = this.getTaskAdvancedScore(result, sepCrs, boardWeights);
                break;
              }
            }
            return result;
          })
      ),
      switchMap((calcTasks: CalculatedTask[]) =>
        this.filterValue$.pipe(
          map(filterVal => {
            if (filterVal) {
              filterVal = filterVal.toUpperCase();
              return calcTasks.filter(item => item.title.toUpperCase().indexOf(filterVal) > -1);
            }
            return calcTasks;
          })
        )
      ),
      publishReplay(1),
      refCount()
    );

    this.calculatedTasksByVersion$ = combineLatest([this.versions$, this.calculatedTasks$]).pipe(
      map(([versions, calcTasks]) => {
        this.versionsMap = createMapFromArr(versions);
        const versionsObj = { [-1]: [] };
        [...calcTasks].sort((a, b) => a['position'] - b['position']).forEach(item => {
          if (item['version']) {
            if (!versionsObj[item['version']]) {
              versionsObj[item['version']] = [];
            }
            versionsObj[item['version']].push(item);
          } else {
            versionsObj[-1].push(item);
          }
        });
        return versionsObj;
      }),
      publishReplay(1),
      refCount()
    );

    this.subs.push(
      this.calculatedTasks$.subscribe(() => {
        setTimeout(() => this.setHeight());
      })
    );

    this.addVersionLink$ = this.board$.pipe(
      filter(board => !!(board && board.projectsIds)),
      map((board: Board) => AppUrls.getUrlNewVersion([...board.projectsIds].join(',')))
    );
  }

  ngAfterViewInit() {
    this.setHeight();
    this.subs.push(
      fromEvent(window, 'resize').subscribe(_ => {
        this.setHeight();
      })
    );
  }

  setHeight() {
    if (this._elRef.nativeElement) {
      const hostRect = this._elRef.nativeElement.getBoundingClientRect();
      const availableViewport = window.innerHeight - hostRect.top - 32;
      if (this._elRef.nativeElement.scrollHeight >= availableViewport) {
        this._elRef.nativeElement.style.height = availableViewport - 2 + 'px';
      }
    }
  }

  getICEScore(taskFactors) {
    const result = taskFactors.iceImpact * taskFactors.iceConfidence * taskFactors.iceEase;
    return Number.isFinite(result) ? result : '?';
  }

  getRICEScore(taskFactors) {
    const result =
      taskFactors.riceReach * taskFactors.riceImpact * (taskFactors.riceConfidence / 100) / taskFactors.riceEffort;
    return Number.isFinite(result) ? Math.round(result) : '?';
  }

  getTaskAdvancedScore(calcTask: CalculatedTask, sepCrs: SeparatedCriteria, boardWeights: BoardWeights) {
    const values = sepCrs[ScoringFactor.Value].reduce((acc, item) => {
      if (calcTask.scoringValuesMap[item.id] && Number.isFinite(calcTask.scoringValuesMap[item.id].value)) {
        acc += item.scoringDirection * (item.weight / 100) * calcTask.scoringValuesMap[item.id].value;
      } else {
        return NaN;
      }
      return acc;
    }, 0);
    const efforts = sepCrs[ScoringFactor.Efforts].reduce((acc, item) => {
      if (calcTask.scoringValuesMap[item.id]) {
        acc += item.scoringDirection * (item.weight / 100) * calcTask.scoringValuesMap[item.id].value;
      } else {
        return NaN;
      }
      return acc;
    }, 0);
    const score = Math.round(
      (values * (boardWeights.valueWeight / 100) + efforts * (boardWeights.effortsWeight / 100)) *
        (calcTask.confidence / 100)
    );
    return Number.isFinite(score) ? score : '?';
  }

  getScoringValuesMap(task) {
    return task.scoringValues.reduce((acc, item) => {
      if (item && item.criterion) {
        acc[item.criterion] = {
          value: item.value,
          visibleValue: this.crsMap[item.criterion]
            ? getVisibleScoringVal(item.value, this.crsMap[item.criterion].dataType)
            : '?'
        };
      }
      return acc;
    }, {});
  }

  onChangeFilter(event) {
    this.filterValue$.next(event.currentTarget.value);
  }

  onResetFilter(input) {
    if (input) {
      input.value = '';
      input.focus();
    }
    this.filterValue$.next('');
  }

  onTitleClick(event, id, title) {
    event.stopPropagation();
    if (!this.isNotGuest) {
      this._routerNav.navigateToTask(id);
      return;
    }
    this._globalHotKeys.isIgnoreKeyBoardEvents = true;
    if (this.editingTitleId) {
      this._store.dispatch(new TaskEditAction({ id: this.editingTitleId, title: this.editingTitleValue }));
    }
    this.editingTitleHeight = event.target.getBoundingClientRect().height;
    this.editingTitleId = id;
    this.editingTitleValue = title;
  }

  onOpenQuickActions(event, taskId) {
    event.stopPropagation();
    this._store.dispatch(
      new GuiStateQuickTaskEditShow({
        id: taskId,
        rect: event.currentTarget.parentElement.getBoundingClientRect(),
        isExactRect: true
      })
    );
  }

  onOpenDeleteConfirm(e, task) {
    e.preventDefault();
    e.stopPropagation();
    this.taskToDelete = task;
  }

  onDeleteTask() {
    this._store.dispatch(new TaskDeleteAction(this.taskToDelete));
    this.onCloseDeletePopup();
  }

  onCloseDeletePopup() {
    this.taskToDelete = null;
  }

  onSaveTaskTitle() {
    if (!this.isNotGuest) {
      return;
    }
    if (this.editingTitleId) {
      this._store.dispatch(new TaskEditAction({ id: this.editingTitleId, title: this.editingTitleValue }));
      this.onCancelTitleEdit();
    }
  }

  onCancelTitleEdit() {
    this._globalHotKeys.isIgnoreKeyBoardEvents = false;
    this.editingTitleId = NaN;
    this.editingTitleValue = '';
    this.editingTitleHeight = 0;
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  onMembersClick(taskId) {
    if (!this.isNotGuest) {
      this._routerNav.navigateToTask(taskId);
      return;
    }
    this.tasks$.pipe(take(1)).subscribe(tasks => {
      const taskToEdit = tasks.filter(item => item.id === taskId)[0];
      this.editingTask$.next(taskToEdit);
      this.taskUsers$ = this._store.select(getTaskUsers(taskId)).pipe(map(naturalSort('fullname')));
      this.possibleUsers$ = this._store.select(getTaskPossibleUsers(taskId));
      this.projectOnlyUsers$ = this._store.select(getTaskProjectOnlyUsers(taskId));

      this.isMembersPopupVisible$.next(true);
    });
  }

  onLabelsClick(taskId) {
    if (!this.isNotGuest) {
      this._routerNav.navigateToTask(taskId);
      return;
    }
    this.tasks$.pipe(take(1)).subscribe(tasks => {
      const taskToEdit = tasks.filter(item => item.id === taskId)[0];
      this.editingTask$.next(taskToEdit);
      this.isLabelsPopupVisible$.next(true);
    });
  }

  onStartDateClick(taskId) {
    if (!this.isNotGuest) {
      this._routerNav.navigateToTask(taskId);
      return;
    }
    this.tasks$.pipe(take(1)).subscribe(tasks => {
      const taskToEdit = tasks.filter(item => item.id === taskId)[0];
      this.editingTask$.next(taskToEdit);
      this.isStartDatePopupVisible$.next(true);
    });
  }

  onDueDateClick(taskId) {
    if (!this.isNotGuest) {
      this._routerNav.navigateToTask(taskId);
      return;
    }
    this.tasks$.pipe(take(1)).subscribe(tasks => {
      const taskToEdit = tasks.filter(item => item.id === taskId)[0];
      this.editingTask$.next(taskToEdit);
      this.isDueDatePopupVisible$.next(true);
    });
  }

  onColumnClick(taskId) {
    if (!this.isNotGuest) {
      this._routerNav.navigateToTask(taskId);
      return;
    }
    this.tasks$.pipe(take(1)).subscribe(tasks => {
      const taskToEdit = tasks.filter(item => item.id === taskId)[0];
      this.editingTask$.next(taskToEdit);
      this.isColumnPopupVisible$.next(true);
    });
  }

  onFactorClick(taskId, prop?) {
    if (!this.isNotGuest) {
      this._routerNav.navigateToTask(taskId);
      return;
    }
    combineLatest([this.tasks$, this.type$])
      .pipe(take(1))
      .subscribe(([tasks, type]: any[]) => {
        const taskToEdit = tasks.filter(item => item.id === taskId)[0];
        if (taskToEdit) {
          this.editingTask$.next(taskToEdit);
          switch (type) {
            case ScoringType.ICE:
            case ScoringType.RICE: {
              this.editingFactor$.next(prop);
              break;
            }
            case ScoringType.advanced: {
              this.editingCriterion$.next(prop === 'confidence' ? 'confidence' : +prop);
              break;
            }
            case ScoringType.basic: {
              this.editingAxis$.next(prop === 'backlogScoreX' ? 'x' : 'y');
              break;
            }
          }
          this.isScoreEditing$.next(true);
        }
      });
  }

  onCustomFieldClick(event, taskId, cfId) {
    this.editCustomFieldTaskId$.next(taskId);
    this.tasksCustomFieldClientRect = event.target.getBoundingClientRect();
    const values = this.tasksCustomFieldsMap[cfId][taskId];
    this.editingCustomFieldValue = {
      task: taskId,
      customField: cfId,
      value: values ? values.value + '' : ''
    };
    this.editingCustomField = this.customFieldsMap[cfId];
  }

  onVersionClick(version) {
    this.hiddenVersions[version.id] = !this.hiddenVersions[version.id];
    this._store.dispatch(new GuiStateToggleVersionVisibility(version.id));
  }

  onCloseEditingScoring() {
    this.isScoreEditing$.next(false);
    this.editingTask$.next(null);
    this.editingCriterion$.next(null);
    this.editingFactor$.next(null);
  }

  onCloseMembersPopup() {
    this.isMembersPopupVisible$.next(false);
  }

  onCloseLabelsPopup() {
    this.isLabelsPopupVisible$.next(false);
  }

  onCloseStartDatePopup() {
    this.isStartDatePopupVisible$.next(false);
  }

  onCloseDueDatePopup() {
    this.isDueDatePopupVisible$.next(false);
  }

  onCloseColumnPopup() {
    this.isColumnPopupVisible$.next(false);
  }

  onCloseAddTaskPopup() {
    this.isAddTaskPopupVisible$.next(false);
  }

  onCloseCustomFieldPopup() {
    this.editCustomFieldTaskId$.next(0);
  }

  onCreateNewTask(versionId) {
    this.newTaskInitialVersion = versionId;
    this.isAddTaskPopupVisible$.next(true);
  }

  onUpdateUsersList(userListUpdate) {
    const task = this.editingTask$.value;
    if (task) {
      if (!this.isNotGuest) {
        this._routerNav.navigateToTask(task.id);
        return;
      }
      this._store.dispatch(new TaskAssignUsersAction({ id: task.id, users: userListUpdate }));
    }
  }

  onAddUserToBoard(user: User) {
    const task = this.editingTask$.value;
    if (task) {
      if (!this.isNotGuest) {
        this._routerNav.navigateToTask(task.id);
        return;
      }
      this._store.dispatch(new BoardAssignUsersAction({ id: task.board, users: { add: [user.id] } }));
    }
  }

  onDragTaskStart(event, taskTitle) {
    event.dataTransfer.setData('text', taskTitle);
    this.insertToVersionId = 0;
  }

  onDragTaskEnd(taskId, versionId) {
    if (versionId !== this.insertToVersionId && this.insertToVersionId) {
      const payload =
        this.insertToVersionId > 0
          ? { id: this.insertToVersionId, tasks: { add: [taskId] } }
          : { id: versionId, tasks: { remove: [taskId] } };
      this._store.dispatch(new VersionAssignTaskAction(payload));
    }
    this.insertToVersionId = 0;
  }

  onDragTaskOver(event, toVersionId) {
    event.preventDefault();
    this.insertToVersionId = toVersionId;
  }

  onDragLeave() {
    this.insertToVersionId = 0;
  }

  onDragTaskOverVersion(event, versionId) {
    event.preventDefault();
    this.insertToVersionId = versionId;
  }

  onToggleVersionMenu(id) {
    this.visibleMenuVersionId = this.visibleMenuVersionId === id ? 0 : id;
  }

  ngOnDestroy() {
    this._globalHotKeys.isIgnoreKeyBoardEvents = false;
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
