<input #filterRef class="menu_form_input" [ngClass]="{hide_element: !(labels$ | async)?.length}" (keyup)="0" [focus]="true" placeholder="Search label...">
<div class="menu_label_list">
  <ng-container *ngFor="let label of tags$ | async | searchByField : 'name' : filterRef.value">
    <div class="menu_label_item"
         (click)="onClickOnLabel(label.id)">
      <div class="menu_label_color">
        <div [class]="label.color">
        </div>
      </div>
      <div class="menu_label_name">{{label.name}}</div>
      <div class="ico_pencil"></div>
    </div>

    <sidebar-labels-edit-popup
      *ngIf="popUpVisible && editedLabel && editedLabel.id === label.id"
      [isEdit]="true"
      [label]="editedLabel"
      [boardId]="(board$ | async)?.id"
      [existedLabels]="labels$ | async"
      (close)="onCloseEditPopUp()"
    ></sidebar-labels-edit-popup>

  </ng-container>
  <div class="menu_label_item" (click)="createLabel()">
    <div class="ico_add_label"></div>
    <div class="menu_label_create">Create new Label</div>
  </div>
  <sidebar-labels-edit-popup
    *ngIf="popUpVisible && !editedLabel"
    [isEdit]="false"
    [boardId]="(board$ | async)?.id"
    [existedLabels]="labels$ | async"
    (close)="onCloseEditPopUp()"
  ></sidebar-labels-edit-popup>

</div>
