import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkingDaysComponent } from './components/working-days/working-days.component';
import { WorkingDaysService } from './services/working-days.service';

const sharedComponents = [WorkingDaysComponent];

@NgModule({
  imports: [CommonModule],
  declarations: sharedComponents,
  exports: sharedComponents
})
export class WorkingDaysModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: WorkingDaysModule,
      providers: [WorkingDaysService]
    };
  }
}
