import { Action } from '../../../ngrx/actions/unsafe-action';
import { RoadMapAddFormTypes, RoadMapScale } from '../constants/roadmap.constants';
import { RoadMapItem } from '../interfaces/roadmap.interface';
import { WorkingDays } from '@atlaz/working-days/interfaces/working-days.interface';

export enum RoadMapActionTypes {
  INITIAL = '[ROADMAP] INITIAL VALUE',
  OPEN = '[ROADMAP] OPEN ROADMAP',
  CLOSE = '[ROADMAP] CLOSE ROADMAP',
  DATA_CHANGED = '[ROADMAP] DATA CHANGED',
  TODAY_DATE_CHANGED = '[ROADMAP] TODAY DATE CHANGED',
  WORKING_DAYS_CHANGED = '[ROADMAP]  WORKING DAYS CHANGED',
  WEEK_START_CHANGED = '[ROADMAP]  WEEK START CHANGED',
  TASK_TREE_CHANGED = '[ROADMAP] TASK TREE CHANGED',
  HIDE_ITEMS = '[ROADMAP] HIDE ITEMS',
  ADD_HIDDEN_ITEMS = '[ROADMAP] ADD_HIDDEN_ITEMS',
  SHOW_HIDDEN_ITEMS = '[ROADMAP] SHOW HIDDEN ITEMS',
  TASK_SEARCH_CHANGED = '[ROADMAP] TASK SEARCH CHANGED',
  DELETE_GROUP = '[ROADMAP] DELETE GROUP',
  CHANGE_TITLE = '[ROADMAP] CHANGE TITLE',
  CHANGE_TASK_STATUS = '[ROADMAP] CHANGE TASK STATUS',
  OPEN_ADD_FORM = '[ROADMAP] OPEN_ADD_FORM',
  CLOSE_ADD_FORM = '[ROADMAP] CLOSE_ADD_FORM',
  SET_SCALE = '[ROADMAP] SET_SCALE',
  RESIZE_TASK = '[ROADMAP] RESIZE_TASK',
  MOVE_TASK = '[ROADMAP] MOVE_TASK',
  APPLY_CURRENT_SCALE = '[ROADMAP] APPLY CURRENT SCALE',
  SET_CHART_MIN_WIDTH = '[ROADMAP] SET CHART MIN WIDTH'
}

export class RoadMapOpenAction implements Action {
  readonly type = RoadMapActionTypes.OPEN;

  // required boardId as payload
  constructor(public payload: { id: number; forPrint: boolean }) {}
}
// it's the same as RoadMapOpenAction just with empty id
export class RoadMapCloseAction implements Action {
  readonly type = RoadMapActionTypes.CLOSE;
  constructor() {}
}

// action which set inital state
// DON'T use it directly!
// it should be used in effects, as a chain of a certain action
export class RoadMapInitAction implements Action {
  readonly type = RoadMapActionTypes.INITIAL;
  constructor() {}
}

export class RoadMapDataChangedAction implements Action {
  readonly type = RoadMapActionTypes.DATA_CHANGED;
  // required TaskId as payload
  constructor(public payload: any) {}
}

export class RoadMapTodayDateChangedAction implements Action {
  readonly type = RoadMapActionTypes.TODAY_DATE_CHANGED;
  // todayDateId
  constructor(public payload: string) {}
}

export class RoadMapWorkingDaysChangedAction implements Action {
  readonly type = RoadMapActionTypes.WORKING_DAYS_CHANGED;
  // todayDateId
  constructor(public payload: WorkingDays) {}
}

export class RoadMapWeekStartChangedAction implements Action {
  readonly type = RoadMapActionTypes.WEEK_START_CHANGED;
  // todayDateId
  constructor(public payload: number) {}
}

export class RoadMapTaskTreeChangedAction implements Action {
  readonly type = RoadMapActionTypes.TASK_TREE_CHANGED;
  // required TaskId as payload
  constructor(
    public payload: {
      taskTree: RoadMapItem[];
      minStartDate: number;
      maxEndDate: number;
      forPrint: boolean;
    }
  ) {}
}

export class RoadMapAddHiddenItemsAction implements Action {
  readonly type = RoadMapActionTypes.ADD_HIDDEN_ITEMS;

  // required itemID as payload
  constructor(public payload: number) {}
}

export class RoadMapHideItemsAction implements Action {
  readonly type = RoadMapActionTypes.HIDE_ITEMS;

  // required itemID[] as payload
  constructor(public payload: number[]) {}
}

export class RoadmapBoardShowAllHiddenItemsAction implements Action {
  readonly type = RoadMapActionTypes.SHOW_HIDDEN_ITEMS;

  constructor() {}
}

export class RoadmapBoardTaskSearchChangedAction implements Action {
  readonly type = RoadMapActionTypes.TASK_SEARCH_CHANGED;

  constructor(public payload: string) {}
}

export class RoadmapBoardDeleteGroupAction implements Action {
  readonly type = RoadMapActionTypes.DELETE_GROUP;

  constructor(public payload: number) {}
}

export class RoadmapBoardChangeTitleAction implements Action {
  readonly type = RoadMapActionTypes.CHANGE_TITLE;

  constructor(public payload: any) {}
}

export class RoadmapBoardChangeTaskStatusAction implements Action {
  readonly type = RoadMapActionTypes.CHANGE_TASK_STATUS;

  constructor(public payload: any) {}
}

export class RoadmapBoardOpenAddFormAction implements Action {
  readonly type = RoadMapActionTypes.OPEN_ADD_FORM;

  constructor(
    public payload: {
      parent: number;
      type: RoadMapAddFormTypes;
      insertAfter?: number;
    }
  ) {}
}

export class RoadmapBoardClosesAddFormAction implements Action {
  readonly type = RoadMapActionTypes.CLOSE_ADD_FORM;

  constructor() {}
}

export class SetScale implements Action {
  readonly type = RoadMapActionTypes.SET_SCALE;

  constructor(public payload: RoadMapScale) {}
}

export class ApplyCurrentScale implements Action {
  readonly type = RoadMapActionTypes.APPLY_CURRENT_SCALE;

  constructor() {}
}

export class SetChartMinWidth implements Action {
  readonly type = RoadMapActionTypes.SET_CHART_MIN_WIDTH;

  constructor(public payload: number) {}
}

export class ResizeTask implements Action {
  readonly type = RoadMapActionTypes.RESIZE_TASK;

  constructor(
    public payload: {
      itemId: number;
      leftPx?: number;
      rightPx?: number;
    }
  ) {}
}

export class MoveTask implements Action {
  readonly type = RoadMapActionTypes.MOVE_TASK;

  constructor(
    public payload: {
      itemId: number;
      offset: number;
    }
  ) {}
}

// prettier-ignore
export type RoadmapBoardAction =
  | RoadMapInitAction
  | RoadMapOpenAction
  | RoadMapCloseAction
  | RoadMapDataChangedAction
  | RoadMapTodayDateChangedAction
  | RoadMapWorkingDaysChangedAction
  | RoadMapWeekStartChangedAction
  | RoadMapTaskTreeChangedAction
  | RoadMapAddHiddenItemsAction
  | RoadMapHideItemsAction
  | RoadmapBoardShowAllHiddenItemsAction
  | RoadmapBoardTaskSearchChangedAction
  | RoadmapBoardDeleteGroupAction
  | RoadmapBoardChangeTitleAction
  | RoadmapBoardChangeTaskStatusAction
  | RoadmapBoardOpenAddFormAction
  | RoadmapBoardClosesAddFormAction
  | SetScale
  | ApplyCurrentScale
  | SetChartMinWidth
  | ResizeTask
  | MoveTask
;
