import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'statuses-filter-list',
  templateUrl: './statuses-filter-list.component.html',
  styleUrls: ['./statuses-filter-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusesFilterListComponent implements OnInit {
  @Input() statuses = [];
  @Input() selectedStatuses = [];

  @Output() addStatus = new EventEmitter();
  @Output() removeStatus = new EventEmitter();

  public checkSelectedStatus: { [type: string]: boolean } = {};

  constructor() {}

  ngOnInit() {
    this.selectedStatuses.forEach((status: { type: string; name: string }) => {
      this.checkSelectedStatus[status.type] = true;
    });
  }

  onSetFilter(status) {
    if (!this.checkSelectedStatus[status.type]) {
      this.checkSelectedStatus[status.type] = true;
      this.addStatus.emit(status);
    } else {
      this.checkSelectedStatus[status.type] = false;
      this.removeStatus.emit(status);
    }
  }
}
