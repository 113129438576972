import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '../../../interfaces';
import { differenceWith } from 'ramda';
import { AppUrls } from '../../../app-urls';
import { PermissionsService } from '../../../permissions/permissions.service';

@Component({
  selector: 'right-menu-users-list',
  templateUrl: './right-menu-users-list.component.html',
  styleUrls: ['./right-menu-users-list.component.scss']
})
export class RightMenuUsersListComponent implements OnInit {
  @Input() boardId = 0;
  @Input() boardUsers: User[];
  @Input() users: User[];
  @Input() showInvite = false;

  @Output() addUser = new EventEmitter();
  @Output() removeUser = new EventEmitter();

  public nonSelectedUsers: User[];
  public appUrls = AppUrls;
  public checkSelectedUser: { [id: number]: boolean } = {};
  public isSuperUser$ = this._permissions.isSuperUser$;

  constructor(private _permissions: PermissionsService) {}

  ngOnInit() {
    this.boardUsers.forEach((user: User) => {
      this.checkSelectedUser[user.id] = true;
    });

    this.nonSelectedUsers = differenceWith((x, y) => x.id === y.id, this.users, this.boardUsers);
  }

  onSelectUser(user: User) {
    if (!this.checkSelectedUser[user.id]) {
      this.addUser.emit(user);
      this.checkSelectedUser[user.id] = true;
    } else {
      this.removeUser.emit(user);
      this.checkSelectedUser[user.id] = false;
    }
  }
}
