<div [ngClass]="{sidebar_block: true, editable: editPermissions}">
  <div [ngClass]="{sidebar_block_link: true, editable: editPermissions}" class="sidebar_block_link" (click)="onOpenLabelsPopup()"></div>
  <div *ngIf="editPermissions" class="task_sidebar_edit_link" (click)="onOpenLabelsPopup()">
    Edit
  </div>
  <h5 class="sidebar_block_title">Labels</h5>
  <sidebar-labels-popup
    *ngIf="editPermissions && isLabelsPopupVisible"
    (close)="onCloseLabelsPopup()"
    [task]="task"
  ></sidebar-labels-popup>
  <div class="labels_block" (click)="onOpenLabelsPopup()">
    <div [ngClass]="{label: true, editable: editPermissions}" *ngFor="let label of taskLabels">
      <span class="label_square" [ngClass]="label.color"></span>
      <div class="label_input">
        <span class="label_text">{{label.name}}</span>
      </div>
    </div>
  </div>

</div>
<div class="divider"></div>
