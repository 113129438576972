import { createSelector } from 'reselect';

import { GITLAB_ASSOCIATED_REPOSITORY_PL } from '../../../../constants';
import { removeOne } from '../../../../ngrx/functions/reducer';
import { getEmptyESState } from '../../../../ngrx/state';
import { createCommonSelectors } from '../../../../util/store';
import { ESGitlabAssociatedRepository, GitlabAssociatedRepository } from '../../models/git-repository';
import * as gitlabCompany from '../gitlab-company/gitlab-company.actions';
import * as gitlabAssociatedRepository from './gitlab-associated-repository.actions';

export interface State extends ESGitlabAssociatedRepository {
  isLoading: boolean;
}

const initialState: State = {
  ...getEmptyESState<GitlabAssociatedRepository>(),
  isLoading: false
};

export function reducer(
  state = initialState,
  action: gitlabAssociatedRepository.Actions | gitlabCompany.Actions
): State {
  switch (action.type) {
    case gitlabAssociatedRepository.GET_INDEX: {
      return { ...state, isLoading: true };
    }

    case gitlabAssociatedRepository.GET_INDEX_FAIL:
    case gitlabAssociatedRepository.GET_INDEX_COMPLETE: {
      return { ...state, isLoading: false };
    }

    case gitlabAssociatedRepository.REMOVE: {
      return removeOne(state, action.payload.id);
    }

    case gitlabCompany.CLEAR_SETTINGS:
    case gitlabCompany.REMOVE_ALL_COMPLETE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const { getState, getEntities, getIds, getAll, isEmpty } = createCommonSelectors<
  GitlabAssociatedRepository,
  State
>(GITLAB_ASSOCIATED_REPOSITORY_PL);

export const isLoading = createSelector(getState, state => state.isLoading);

export const getAssociatedRepoMap = createSelector(getAll, repos =>
  repos.reduce((map, repo) => Object.assign(map, { [repo.repositoryId]: repo }), {})
);
