import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Board, Column, Swimlane, Task } from '../../../interfaces';
import { columnTypes } from '../../../constants';

@Component({
  selector: 'smart-task-link-inner',
  templateUrl: './smart-task-link-inner.component.html',
  styleUrls: ['./smart-task-link-inner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartTaskLinkInnerComponent {
  @Input() title: string;
  @Input() taskKey: string;
  @Input() link: string | string[];
  @Input() task: Task;
  @Input() board: Board;
  @Input() column: Column;
  @Input() swimlane: Swimlane;
  @Input() parentColumn: Column;
  @Input() directLink = true;

  get isDone() {
    return this.column.type === columnTypes.done;
  }

  get inComposite() {
    return this.parentColumn && this.column;
  }

  public Math = Math;
}
