<form-errors></form-errors>
<p class="form_subtitle">
  {{hasLimits
  ? 'Update work in progress limits to this column.'
  : 'Add work in progress limits to this column.'
  }}
</p>
<form [formGroup]="formWip" (ngSubmit)="onSave()">
  <div class="input_block">
    <label [attr.for]="'min'">Minimum</label>
    <input type="text" formControlName="minTasksCount" [focus]="true" [hotKeys]="hotKeys" (hotKey)="onSave()" name="min">
  </div>
  <div class="input_block">
    <label [attr.for]="'max'">Maximum</label>
    <input type="text" formControlName="maxTasksCount" [hotKeys]="hotKeys" (hotKey)="onSave()" name="max">
  </div>

  <button a-button
          class="btn_primary button"
          [disabled]="formWip.invalid"
          [pending]="savePending && (_formService.isPending$ | async)"
    (click)="onSave()">{{hasLimits ? 'Change' : 'Add'}}
  </button>
  <button
    a-button
    class="btn_secondary cancel_btn"
    [disabled]="disabled"
    [pending]="removePending && (_formService.isPending$ | async)"
    (click)="onRemove()" *ngIf="hasLimits">Remove
  </button>
</form>

