<form [formGroup]="qFilterForm" (ngSubmit)="onSubmitQFilterForm()">
  <div class="menu_item">
    <div class="form_label">Name</div>
    <input type="text" [formControl]="qFilterForm.get('name')" id="filter_name" #filter_name="ngForm"
           checkTouch
           [focus]="true"
           class="menu_form_input"
           placeholder="Enter Filter Name">
    <help-block></help-block>
  </div>
  <div class="divider"></div>
  <div class="menu_item">
    <div class="form_label">Members</div>

    <user-labels
      [users]="qFilterForm.get('member').value"
      (onOpenPopupEvent)="showMembersPopUp = true"
      (removeUser)="onRemoveUser($event)"
    ></user-labels>
    <div class="clear"></div>

    <right-menu-users-popup
      *ngIf="showMembersPopUp"
      (close)="onCloseMembersPopUp()"
      [boardUsers]="qFilterForm.get('member').value"
      (changeUsersList)="onMembersChanged($event)"
      [users]="boardUsers"
    ></right-menu-users-popup>
  </div>
  <div class="divider"></div>
  <div class="menu_item">
    <div class="form_label">Versions</div>
    <div class="filters">
      <div class="add_btn" (click)="showVersionPopUp = true"></div>
      <a-tag *ngFor="let version of qFilterForm.get('version').value"
             [title]="version.name"
             (remove)="onRemoveVersion(version)">
      </a-tag>
    </div>
    <div a-context-popup
         *ngIf="showVersionPopUp"
         [title]="'Add Version'"
         (close)="onCloseVersionPopUp()"
    >
      <sidebar-version-list
        [selected]="selectedVersionIds"
        [multiply]="true"
        [versions]="versions"
        (selectVersion)="onVersionChanged($event)"
      ></sidebar-version-list>

    </div>

  </div>
  <button a-button class="btn_primary" [disabled]="!qFilterForm.valid" (click)="onSubmitQFilterForm()">Save Filter</button>
</form>
