import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateSubscriptionComponent } from '@atlaz/billing/containers/create-subscription/create-subscription.component';
import { PricingPageComponent } from '@atlaz/billing/containers/pricing-page/pricing-page.component';

import * as BillingUrl from './util/billing-url';
import { AuthGuard } from '../shared/services/guards/auth-guard.service';
import { PaymentFormGuardService } from '@atlaz/billing/guards/payment-form-guard.service';
import { EditSubscriptionComponent } from '@atlaz/billing/containers/edit-subscription/edit-subscription.component';
import { BillingGlobalComponent } from '@atlaz/billing/containers/billing-overlay/billing-overlay.component';
import { ChangeCardComponent } from '@atlaz/billing/containers/change-card/change-card.component';
import { ThereDSecurePopupComponent } from '@atlaz/billing/containers/there-d-secure-popup/there-d-secure-popup.component';
import { ThereDSecureBackUrlComponent } from '@atlaz/billing/containers/there-d-secure-back-url/there-d-secure-back-url.component';
import { POPUP_OUTLET } from '../path.routing';

const routes: Routes = [
  {
    path: BillingUrl.THERE_D_SECURE_POPUP,
    canActivate: [AuthGuard, PaymentFormGuardService],
    component: ThereDSecurePopupComponent,
    outlet: POPUP_OUTLET
  },
  {
    path: BillingUrl.BILLING,
    canActivate: [AuthGuard, PaymentFormGuardService],
    component: BillingGlobalComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: BillingUrl.PRICING },
      { path: BillingUrl.PRICING, component: PricingPageComponent },
      { path: BillingUrl.PAYMENT_FORM, component: CreateSubscriptionComponent },
      { path: BillingUrl.EDIT_ADDITIONAL_DATA_FORM, component: EditSubscriptionComponent },
      { path: BillingUrl.CHANGE_CARD, component: ChangeCardComponent },
      { path: BillingUrl.THERE_D_SECURE_BACK_URL, component: ThereDSecureBackUrlComponent },
      { path: BillingUrl.THERE_D_SECURE_BACK_URL + '/:plan', component: ThereDSecureBackUrlComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BillingRoutingModule {}
