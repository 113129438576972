import { compose } from '@ngrx/store';
import { ChecklistItemActionTypes } from '../actions/checklist-item.actions';
import { ESInterface } from '../state';
import { ChecklistItem, PartOfEntity } from '../../interfaces';
import { getAllEntitiesAsArray, getEntitySelector } from '../functions/selectors';
import { share } from '../functions/util';
import { CHECKLISTS_ITEM_PL } from '../../constants';
import { deleteEntityState, editEntityState, moveEntityAfter } from '../functions/reducer';
import { ChecklistItemActions } from '../actions/checklist-item.actions';

const initialState: ESInterface<ChecklistItem> = {
  ids: [],
  entities: {},
  selectedEntityId: null
};

export function reducer(state = initialState, action: ChecklistItemActions): ESInterface<ChecklistItem> {
  switch (action.type) {
    case ChecklistItemActionTypes.EDIT: {
      const partOfItem = <PartOfEntity>action.payload;
      return editEntityState(state, partOfItem);
    }

    case ChecklistItemActionTypes.DELETE: {
      const item = <ChecklistItem>action.payload;
      return deleteEntityState(state, item.id);
    }

    default: {
      return state;
    }
  }
}

export const getAllChecklistItems = share(compose(getAllEntitiesAsArray, getEntitySelector(CHECKLISTS_ITEM_PL)));
