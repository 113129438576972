import { Entity } from '../app/interfaces/entity';
import { NaturalSort as NaturalSortLib } from 'angular2-natural-sort';

export const findIndexById = (array: Entity[], itemId: number): number => {
  return array.findIndex(entity => entity.id === itemId);
};

export const removeById = (array: any[], itemId: number): Entity[] => {
  const items: Entity[] = array.slice();

  items.splice(this.findIndexById(items, itemId), 1);
  return items;
};

export const arrayMerge = (array1: any[], array2: any[]): any[] => {
  return array1.concat(
    array2.filter(item => {
      return array1.includes(item) !== true;
    })
  );
};

export const difference = (array1: any[], array2: any[]): any[] => {
  const map1 = new Map();
  const map2 = new Map();
  const result = [];
  let value, i;
  for (i = 0; i < array1.length; i++) {
    value = array1[i];
    map1.set(value, value);
  }
  for (i = 0; i < array2.length; i++) {
    value = array2[i];
    map2.set(value, value);
  }
  for (i = 0; i < array2.length; i++) {
    value = array2[i];
    if (!map1.get(value)) {
      result.push(value);
    }
  }
  for (i = 0; i < array1.length; i++) {
    value = array1[i];
    if (!map2.get(value)) {
      result.push(value);
    }
  }
  return result;
};

export const replaceIds = (ids: number[] = [], idsToReplace: { add?: number[]; remove?: number[] }): number[] => {
  let newIds = ids.filter(itemId => (!idsToReplace.remove ? true : idsToReplace.remove.includes(itemId) === false));
  newIds = arrayMerge(newIds, idsToReplace.add || []);
  return newIds;
};

const compareElementsObject = (array1: any[], array2: any[]): boolean =>
  !array1.some((value, index) => value !== array2[index]);

export const compareArrays = <T extends any>(array1: T[], array2: T[]) => {
  switch (true) {
    case array1 === array2: {
      return true;
    }
    case array1.length !== array2.length: {
      return false;
    }
    case array1.length === 0: {
      return true;
    }
    case !compareElementsObject(array1, array2): {
      return false;
    }
    default: {
      return true;
    }
  }
};
export const arrayEquals = compareArrays;

export function sortBy(fieldName, desc: 'asc' | 'desc' = 'asc') {
  const direction = desc === 'asc' ? 1 : -1;

  return (a, b) => {
    if (!a) {
      return -1;
    }
    if (!b) {
      return 1;
    }

    let result = 0;
    const aFieldName = typeof a[fieldName] === 'string' ? a[fieldName].trim() : a[fieldName];
    const bFieldName = typeof b[fieldName] === 'string' ? b[fieldName].trim() : b[fieldName];

    if (aFieldName < bFieldName) {
      result = -1;
    } else if (aFieldName > bFieldName) {
      result = 1;
    }

    return result * direction;
  };
}

export function sortByField(field, desc: 'asc' | 'desc' = 'asc') {
  return array => array.sort(sortBy(field, desc));
}

export const naturalSortComparator = fieldName => (a: any, b: any) => NaturalSortLib.SORT(a[fieldName], b[fieldName]);

export const naturalSortComparatorNested = (fieldName, nestedFieldName) => (a: any, b: any) =>
  NaturalSortLib.SORT(a[fieldName][nestedFieldName], b[fieldName][nestedFieldName]);

export function naturalSort(fieldName) {
  return array => {
    return array.slice().sort(naturalSortComparator(fieldName));
  };
}

export const createMapFromArr = arr =>
  arr.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});

/**
 * and condition
 */
export const andCondition = (...functions) => (...params) => functions.every(fn => fn(params));
export const orCondition = (...functions) => (...params) => functions.some(fn => fn(params));

// filtering duplicate values only string[] or number[], object[] can not!!!
export const filterDuplicateItem = (item: number | string, index, inputArray) => inputArray.indexOf(item) === index;

export const uniqBy = (array, propName) => {
  const map = array.reduce((acc, item) => {
    acc[item[propName]] = item;
    return acc;
  }, {});
  return Object.values(map);
};

export const fieldSum = field => (list: any[]) => +list.reduce((acc, item) => item[field] + acc, 0);
