import { catchError, switchMap, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import { HttpQueryParam, PartOfEntity } from '../../interfaces';
import { COLUMN_PL } from '../../constants';

import { defaultErrorHandler } from './root.effect';
import { HandleResponseAction } from '../actions/root.action';
import { ColumnActionTypes, ColumnSortFinishedAction } from '../actions/column.actions';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { of } from 'rxjs/internal/observable/of';

@Injectable()
export class ColumnEffects {
  @Effect()
  getColumnList$ = this.actions$
    .ofType(ColumnActionTypes.GET)
    .pipe(
      switchMap(({ type, payload }: { type: string; payload: HttpQueryParam }) =>
        this._atlazApiV2
          .get([COLUMN_PL], payload)
          .pipe(map(resp => new HandleResponseAction(resp)), catchError(defaultErrorHandler(type, payload)))
      )
    );

  @Effect()
  editColumn$ = this.actions$
    .ofType(ColumnActionTypes.EDIT)
    .pipe(
      switchMap(({ type, payload }: { type: string; payload: PartOfEntity }) =>
        this._atlazApiV2
          .patch([COLUMN_PL], payload)
          .pipe(map(resp => new HandleResponseAction(resp)), catchError(defaultErrorHandler(type, payload)))
      )
    );

  @Effect()
  sortColumn$ = this.actions$
    .ofType(ColumnActionTypes.SORT_BY_PRIORITY)
    .pipe(
      switchMap(({ type, payload }: { type: string; payload: PartOfEntity }) =>
        this._atlazApiV2
          .patch(['tasks-reorder'], payload)
          .pipe(
            switchMap(resp => of(new HandleResponseAction(resp), new ColumnSortFinishedAction())),
            catchError(defaultErrorHandler(type, payload))
          )
      )
    );

  @Effect()
  addColumn$ = this.actions$
    .ofType(ColumnActionTypes.ADD)
    .pipe(
      switchMap(({ type, payload }: { type: string; payload: PartOfEntity }) =>
        this._atlazApiV2
          .post([COLUMN_PL], payload)
          .pipe(map(resp => new HandleResponseAction(resp)), catchError(defaultErrorHandler(type, payload)))
      )
    );

  @Effect()
  loadColumn$ = this.actions$
    .ofType(ColumnActionTypes.LOAD)
    .pipe(
      mergeMap(({ type, payload }: { type: string; payload: HttpQueryParam }) =>
        this._atlazApiV2
          .get([COLUMN_PL, +payload.id], payload.params)
          .pipe(map(resp => new HandleResponseAction(resp)), catchError(defaultErrorHandler(type, payload)))
      )
    );

  constructor(private actions$: Actions, private _atlazApiV2: AtlazApiV2Service) {}
}
