import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { of } from 'rxjs';
import { map ,  switchMap } from 'rxjs/operators';

import { toDashedFromCamelCase } from '../../../../../helpers/';
import { GITHUB_REPOSITORY_PL } from '../../../../constants/';
import { GetCompleteAction } from '../../../../ngrx/actions/root.action';
import { AppState } from '../../../../ngrx/state/';
import { jsonApiToEntityState } from '../../../../shared/services/app/web-socket/http-response';
import { AtlazApiV2Service } from '../../../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { ESGithubRepository } from '../../models/git-repository';
import * as githubRepo from '../github-repository/github-repository.actions';
import { getFirstId } from '../github-company/github-company.reducer';

@Injectable()
export class GithubRepositoryEffects {
  private githubRepoKey = toDashedFromCamelCase(GITHUB_REPOSITORY_PL);

  readonly defaultErrorHandler = (action, err) => {
    console.error('effect', action, err);
    return new githubRepo.GetIndexFail();
  };

  @Effect()
  getMany$ = this.data.fetch(githubRepo.GET_INDEX, {
    run: (action, store) =>
      this.api
        .get([this.githubRepoKey], { githubCompany: getFirstId(store) })
        .pipe(
          map(jsonApiToEntityState),
          switchMap((state: ESGithubRepository) =>
            of(...[new GetCompleteAction(state), new githubRepo.GetIndexComplete(state)])
          )
        ),
    onError: this.defaultErrorHandler
  });

  constructor(private data: DataPersistence<AppState>, private api: AtlazApiV2Service) {}
}
