
import {map, combineLatest, distinctUntilChanged} from 'rxjs/operators';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { Label } from '../../../interfaces/';
import { compareArrays } from '../../../../helpers/';
import { compareLabels, getLabelEntities } from '../../../ngrx/reducers/label.reducer';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state/';

@Component({
  selector: 'short-labels-list',
  templateUrl: './short-labels-list.component.html',
  styleUrls: ['./short-labels-list.component.scss']
})
export class ShortLabelsListComponent implements OnInit, OnChanges {
  @Input() labelIds: number[];
  public ids$ = new BehaviorSubject([]);
  public labels$: Observable<Label[]>;

  constructor(private _store: Store<AppState>) {}

  ngOnInit(): any {
    this.labels$ = <Observable<Label[]>>this.ids$.pipe(
      distinctUntilChanged(compareArrays),
      combineLatest(
        this._store.pipe((getLabelEntities)),
        (ids: number[], labels) => (ids ? ids.map(id => labels[id]).filter(x => x) : [])
      ),
      map((labels: Label[]) => labels.sort(compareLabels)),);
  }

  ngOnChanges() {
    this.ids$.next(!!this.labelIds ? this.labelIds : []);
  }
}
