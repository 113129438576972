<div>
  <div *ngIf="attachment.type == 'document'" class="attach_doc_preview">
    <p>{{attachment.extension}}</p>
  </div>
</div>

<div class="pending_block" *ngIf="attachment.createdAt == null">
	<rolling-loader-svg></rolling-loader-svg>
</div>

<div class="attachment_block">
  <a href="{{attachment.path}}" target="_blank" rel="noopener" [ngClass]="{transparent: !isImage}" class="attach_link" (click)="onOpenSlideshow($event, isImage)"></a>
  <div class="attach_controll" (click)="onToggleAttachContext()">
    <div a-context-menu class="slim attach_context_menu" *ngIf="isAttachContextVisible"
         (close)="onToggleAttachContext()">
      <a href="{{attachment.path}}" target="_blank" rel="noopener"><p a-context-menu-item>View</p></a>
      <p a-context-menu-item (click)="onCopyLinkStart(attachment.path)">Copy Link</p>
      <a [href]="attachment.downloadLink" target="_blank" rel="noopener" download *ngIf="isDownloadable"><p a-context-menu-item>Download</p></a>
      <p a-context-menu-item *ngIf="editPermissions && isImage" (click)="onToggleCover()">{{isCover ? 'Remove Cover' : 'Make Cover'}}</p>
      <p a-context-menu-item *ngIf="editPermissions" class="delete_link" (click)="onDelete(attachment)">Delete</p>
    </div>
  </div>
  <div class="attach_cover_controll" *ngIf="isImage && isCover" (click)="onToggleCoverMenu()">
    <div *ngIf="isCoverMenuVisible && editPermissions" a-context-menu class="slim attach_cover_menu"
         (close)="onToggleCoverMenu()"
    >
      <p a-context-menu-item (click)="onToggleCover()">Remove Cover</p>
    </div>
  </div>
  <div class="tooltip">
    <p>{{attachment.name}}</p>
  </div>
</div>
<img *ngIf="attachment.type == 'image'" [src]="attachment.previews['75']" class="image_preview">

<copy-text *ngIf="isCopyingLink" [title]="'Link Copied:'" [textToCopy]="textForCopy" (close)="onCopyLinkEnd()"></copy-text>
