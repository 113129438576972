
import {interval as observableInterval, empty as observableEmpty, fromEvent as observableFromEvent, of as observableOf,  Observable ,  Subject ,  BehaviorSubject } from 'rxjs';

import {map, take, takeUntil, merge, tap, filter} from 'rxjs/operators';
import { Injectable, NgZone } from '@angular/core';
import { blockWidth, ScrollDirection } from '../constants/roadmap.constants';
import { SyncRoadmapGuiStateService } from './sync-roadmap-gui-state-service';
import { Store } from '@ngrx/store';
import { BugTrackerService } from '@atlaz/core/services/bag-tracker.service';
import { AppState } from '../../../ngrx/state/';
import { RoadMapAddHiddenItemsAction, RoadmapBoardShowAllHiddenItemsAction } from '../store/roadmap-board.action';
import { RoadMapItem } from '../interfaces/roadmap.interface';
import { RoadmapScrollService } from './roadmap-scroll.service';
import { getEventOffsetX, getEventOffsetY, getEventPath } from '../../../../helpers/event';
import { mouseButtons } from '../../../constants/mouse-buttons';
import { animationFrame } from 'rxjs/scheduler/animationFrame';

export enum DraggedActualPositions {
  before = 0,
  after = 1,
  insideFirst = 2,
  insideLast = 3
}

export interface DraggedItemActualPosition {
  id: number;
  item?: RoadMapItem;
  position: DraggedActualPositions;
}

export interface RoadmapDragOverEvent {
  event: MouseEvent;
  target: HTMLDivElement;
  item: RoadMapItem;
}

const DraggedItemActualPositionNullObj: DraggedItemActualPosition = { id: 0, position: DraggedActualPositions.before };

export interface RoadmapDropEvent {
  item: RoadMapItem;
  whereTo: DraggedItemActualPosition;
}

@Injectable()
export class RoadmapDragService {
  public dragOver$ = new Subject<RoadmapDragOverEvent>();
  public busy$ = new BehaviorSubject(false);
  public actualPosition$ = new BehaviorSubject<DraggedItemActualPosition>(DraggedItemActualPositionNullObj);
  public onDrop$ = new Subject<RoadmapDropEvent>();

  public afterDropCleanUp = () => {
    this.actualPosition$.next(DraggedItemActualPositionNullObj);
  };

  private dropContainers = [];

  readonly defaultErrorHandler = (err): Observable<any> => {
    this._bugTracker.error(err, 'RoadmapDragService');
    return observableOf({});
  };

  constructor(
    private _syncService: SyncRoadmapGuiStateService,
    private _scrollService: RoadmapScrollService,
    private _zone: NgZone,
    private _bugTracker: BugTrackerService,
    private _store: Store<AppState>
  ) {}

  findValidDropTarget(element: any) {
    if (this.dropContainers.includes(element)) {
      return element;
    }
    if (element && element.parentNode) {
      return this.findValidDropTarget(element.parentNode);
    }
    return null;
  }

  registerDropContainer(container, item) {
    this.dropContainers.push(container);
  }

  unRegisterDropContainer(container) {
    this.dropContainers = this.dropContainers.filter(c => c !== container);
  }

  detectDragDirection(initialEvent: MouseEvent): Observable<{ event: MouseEvent; direction: ScrollDirection }> {
    if (
      initialEvent.button !== mouseButtons.left ||
      // to disable drag detectong on input textarea & etc
      getEventPath(initialEvent).some((el: HTMLInputElement) => el.readOnly === false)
    ) {
      return observableEmpty();
    }

    return observableFromEvent(document, 'mousemove').pipe(
      filter(
        (event: MouseEvent) =>
          Math.abs(event.screenX - initialEvent.screenX) * 2 > blockWidth ||
          Math.abs(event.screenY - initialEvent.screenY) * 2 > blockWidth
      ),
      map((event: MouseEvent) => {
        return {
          event,
          direction:
            Math.abs(event.screenX - initialEvent.screenX) * 2 > blockWidth
              ? ScrollDirection.horizontal
              : ScrollDirection.vertical
        };
      }),
      take(1),
      takeUntil(observableFromEvent(document, 'mouseup')),);
  }

  start(initialEvent: MouseEvent, nativeElement: HTMLElement, item: RoadMapItem) {
    console.warn('star drag');
    this.actualPosition$.next(DraggedItemActualPositionNullObj);

    /**
     * TODO: review: is it nessesary while you fix axis of dragging
     * Disclaimer
     * to prevent app getting stuck while drag we have to disable mousewheel event for mac. To be on the safe side we will do it for everyone.
     * It happens, because scroll works out through all scrollable components under cursor pointer.
     * It causes slowing down of emitting mousemove events if scroll using mouse wheel.
     */
    this._syncService.disableNativeScrolling();

    // disabling text selection
    this._syncService.disableBodySelection();

    this.busy$.next(true);

    const mirror = <HTMLDivElement>nativeElement.cloneNode(true);
    const bodyOverlay = document.createElement('div');
    mirror.classList.add('gu-mirror');

    mirror.style['top'] = -getEventOffsetY(initialEvent) + 'px';
    mirror.style['left'] = -getEventOffsetX(initialEvent) + 'px';
    //crt.style.width = target.clientWidth + 'px';
    //crt.style.height = target.clientHeight + 'px';
    mirror.style.position = 'fixed';
    mirror.style.cursor = 'move';
    mirror.style['z-index'] = 1000000;

    bodyOverlay.style['top'] = '0';
    bodyOverlay.style['left'] = '0';
    bodyOverlay.style.bottom = '0';
    bodyOverlay.style.right = '0';
    bodyOverlay.style['z-index'] = 1000001;
    bodyOverlay.style.position = 'fixed';
    bodyOverlay.style.cursor = 'move';

    const patchMirrorPosition = (event: MouseEvent) => {
      const value = 'translate3d(' + event.clientX + 'px,' + event.clientY + 'px, 0)';

      mirror.style.transform = value;
      mirror.style['-o-transform'] = value;
      mirror.style['-ms-transform'] = value;
      mirror.style['-moz-transform'] = value;
      mirror.style['-webkit-transform'] = value;
    };

    const checkDragOver = (event: MouseEvent) => {
      mirror.style.display = 'none';
      bodyOverlay.style.display = 'none';
      const target = document.elementFromPoint(event.clientX, event.clientY);
      mirror.style.display = 'block';
      bodyOverlay.style.display = 'block';
      const validDropTarget = this.findValidDropTarget(target);
      if (validDropTarget) {
        this.dragOver$.next({ event, target: validDropTarget, item });
      }
    };

    this._zone.runOutsideAngular(() => {
      patchMirrorPosition(initialEvent);
      bodyOverlay.onscroll = e => console.log(e);
      document.body.appendChild(bodyOverlay);
      document.body.appendChild(mirror);
    });

    this._store.dispatch(new RoadMapAddHiddenItemsAction(item.id));

    const stop = () => {
      mirror && mirror.parentNode && mirror.parentNode.removeChild(mirror);
      bodyOverlay && bodyOverlay.parentNode && bodyOverlay.parentNode.removeChild(bodyOverlay);
      this.actualPosition$.pipe(
        take(1),
        filter(xs => xs.id !== 0),)
        .subscribe(whereTo => {
          this.onDrop$.next({ item, whereTo });
        });
    };

    this._zone.runOutsideAngular(() => {
      const stop$ = observableFromEvent(document, 'mouseup').pipe(
        merge(observableFromEvent(window, 'blur')),
        tap((e: MouseEvent) => {
          e.preventDefault ? e.preventDefault() : null;
          setTimeout(() => {
            this._store.dispatch(new RoadmapBoardShowAllHiddenItemsAction());
            this.busy$.next(false);
          }, 101);
          this._syncService.enableBodySelection();
          this._syncService.enableNativeScrolling();
        }),);

      const mouseMove$ = <Observable<MouseEvent>>observableFromEvent(document, 'mousemove');

      this._scrollService.starScrollOnMove(ScrollDirection.vertical, mouseMove$);

      let lastMove = initialEvent;
      let lastHandledMouseMove = lastMove;

      mouseMove$.pipe(takeUntil(stop$)).subscribe((event: MouseEvent) => (lastMove = event), this.defaultErrorHandler, stop);

      observableInterval(1, animationFrame).pipe(
        takeUntil(stop$))
        .subscribe(() => {
          if (lastMove !== lastHandledMouseMove) {
            lastHandledMouseMove = lastMove;
            patchMirrorPosition(lastMove);
            checkDragOver(lastMove);
          }
        });
    });
  }
}
