<div *ngFor="let field of (customFieldsArr$ | async)" [class.editable]="canEditTask">
  <div class="custom_field_item" (click)="onEditFieldValue(field)">
    <span class="edit">Edit</span>
    <h5 class="field_title">{{field.name}}</h5>
    <div class="field_value">
      <span *ngIf="field.type !== CustomFieldTypes.date">{{customFieldValuesMap[field.id] ? customFieldValuesMap[field.id].value : ''}}</span>
      <span class="date" *ngIf="field.type === CustomFieldTypes.date">{{(customFieldValuesMap[field.id] ? +customFieldValuesMap[field.id].value : 0) | toDate: 'dueDate'}}</span>
    </div>
  </div>
  <div class="divider"></div>
</div>

<tasks-custom-field-edit
  *ngIf="isEditing"
  [customFieldValue]="editingFieldValue"
  [customField]="editingField"
  [taskId]="taskId"
  (close)="onEditingEnd()"
></tasks-custom-field-edit>
