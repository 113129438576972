
import {combineLatest as observableCombineLatest,  Observable ,  Subscription } from 'rxjs';

import {distinctUntilChanged, tap, map, take, switchMap, filter} from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { isPresent } from '../../../../helpers';
import { Column } from '../../../interfaces';
import { AppState } from '../../../ngrx/state';
import { Store } from '@ngrx/store';
import { getColumnsByBoard } from '../../../ngrx/reducers/column.reducer';
import {
  DefaultColumnsFilterResetAction,
  DefaultTaskColumnsFilterToggleItemAction
} from '../../../ngrx/actions/task-filters/default-columns-filter.actions';
import { getDefaultColumnsFilterIds } from '../../../ngrx/reducers/task-filters/default-columns-filter.reducer';

@Component({
  selector: 'default-columns-filter',
  templateUrl: './default-columns-filter.component.html',
  styleUrls: ['./default-columns-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultColumnsFilterComponent implements OnInit, OnDestroy {
  @Input() boardId$: Observable<number>;
  subs: Subscription[] = [];

  public boardColumns$: Observable<Column[]>;
  public selectedColumns$: Observable<Column[]>;
  public selectedColumnsIds$: Observable<number[]>;
  public storeColumnsFilterValue$: Observable<number[]>;
  public showColumnsPopUp = false;

  constructor(private _store: Store<AppState>) {}

  ngOnInit(): void {
    this.storeColumnsFilterValue$ = this._store.pipe((getDefaultColumnsFilterIds));

    this.boardColumns$ = this.boardId$.pipe(
      filter(isPresent),
      distinctUntilChanged(),
      switchMap(id => this._store.pipe((getColumnsByBoard(id)))),);

    this.selectedColumns$ = observableCombineLatest(
      this.boardColumns$,
      this.storeColumnsFilterValue$,
      (columns: Column[], selectedIds: number[]) => {
        const columnsWithSubColumns = [];
        columns.forEach((column: Column) => {
          if (column.subColumnsIds.length) {
            column.subColumns.data.forEach(subColumn =>
              columnsWithSubColumns.push({ ...subColumn, name: column.name + ' (' + subColumn.name + ')' })
            );
          } else {
            columnsWithSubColumns.push(column);
          }
        });
        return {
          selectedIds: selectedIds,
          columns: columnsWithSubColumns.filter((column: Column) => selectedIds.includes(column.id))
        };
      }
    ).pipe(
      tap(({ selectedIds, columns }) => {
        if (selectedIds.length && !columns.length) {
          this._store.dispatch(new DefaultColumnsFilterResetAction());
        }
      }),
      map(({ columns }) => columns),);

    this.selectedColumnsIds$ = this.selectedColumns$.pipe(map((columns: Column[]) => columns.map(item => item.id)));
  }

  onAddColumn(column: Column) {
    this.selectedColumnsIds$.pipe(take(1)).subscribe(ids => {
      this._store.dispatch(new DefaultTaskColumnsFilterToggleItemAction([...ids, column.id]));
    });
  }

  onRemoveColumn(column: Column) {
    this.selectedColumnsIds$.pipe(take(1)).subscribe(ids => {
      this._store.dispatch(new DefaultTaskColumnsFilterToggleItemAction(ids.filter(id => id !== column.id)));
    });
  }

  onShowColumnsPopup() {
    this.showColumnsPopUp = true;
  }

  onHideColumnsPopup() {
    this.showColumnsPopUp = false;
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
