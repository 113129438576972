import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppContainersModule } from '../containers/containers.module';
import { SharedModule } from '../shared/shared.module';
import { appRoutingProviders, routing } from '../app.routing';
import { NewBoardComponent } from '../board/new-board/new-board.component';
import { SocketService } from '../shared/services/app/web-socket/web-socket.service';
import { NgrxModule } from '../ngrx/ngrx.module';
import { LeftSidenavButtonComponent } from '../containers/header/left-sidenav-button/left-sidenav-button.component';
import { GlobalSearchModule } from '../global-search/global-search.module';
import { LogoutComponent } from '../login/logout.component';
import { BoardModule } from '../board/board.module';
import { OverviewModule } from '../overview/overview.module';
import { AtlazBnpComponent } from './atlaz-bnp/atlaz-bnp.component';
import { CommonModule } from '@angular/common';
import { TaskModule } from '../task/task.module';
import { LeftMenuModule } from '../left-menu/left-menu.module';
import { ImportModule } from '../company-settings/settings/import/import.module';
import { AtlazGuiModule } from '../atlaz-gui/atlaz-gui.module';
import { LoginErrorComponent } from '../login/login-error/login-error.component';
import { CompanyAccessDeniedComponent } from '../login/company-access-denied/company-access-denied.component';
import { BurndownChartModule } from '../burndown-chart/burndown-chart.module';
import { PermissionsModule } from '../permissions/permissions.module';
import { DataSyncService } from '../shared/services/app/web-socket/data-sync.service';
import { TimesheetModule } from '../timesheet/timesheet.module';
import { CollectionsSettingsComponent } from '../collections/collections-settings/collections-settings.component';
import { CollectionBoardListComponent } from '../collections/edit-collection-form/collection-board-list/collection-board-list.component';
import { EditCollectionFormComponent } from '../collections/edit-collection-form/edit-collection-form.component';
import { ForbiddenPageComponent } from './forbidden-page/forbidden-page.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { IntercomService } from './services/intergations/intercom/intercom.service';
import { environment } from '../../environments/environment';
import { IntercomDummyService } from './services/intergations/intercom/intercom-dummy.service';
import { InviteMemberPopupComponent } from '../company-settings/settings/members-settings/invite-member-popup/invite-member-popup.component';
import { InviteHasBeenSentComponent } from '../company-settings/settings/members-settings/invite-has-been-sent/invite-has-been-sent.component';
import { SegmentService } from './services/intergations/segment/segment.service';
import { SegmentDummyService } from './services/intergations/segment/segment-dummy.service';
import { AtlazDevtoolsService } from '../../helpers/devtools';
import { BillingModule } from '../billing/billing.module';
import { TaboolaService } from './services/intergations/taboola/taboola.service';
import { FbPixelService } from './services/intergations/fb-pixel/fb-pixel.service';
import { GoogleAnalyticsService } from './services/intergations/google-analytics/google-analytics.service';
import { PhoneCodesService } from '../shared/services/phone-codes.service';
import { MassInviteComponent } from './mass-invite/mass-invite.component';
import { ManyAtOnceComponent } from './mass-invite/many-at-once/many-at-once.component';
import { IntegrationsService } from './services/intergations/integrations.service';
import { WorkingDaysModule } from '@atlaz/working-days/working-days.module';
import { DateTimeFormatterModule } from '../libs/date-time-formatter/date-time-formatter.module';
import { InviteLinkPopupModule } from '../invite-link-popup/invite-link-popup.module';
import { ADragModule } from '../shared/a-drag/a-drag.module';
import { PaywallModule } from '../libs/paywall/paywall.module';
import { LoadErrorPageComponent } from './load-error-page/load-error-page.component';
import { MarkdownModule } from 'ngx-markdown-hyggerio';
import { HttpRequestErrorsComponent } from './http-request-errors/http-request-errors.component';
import { HttpRequestErrorsService } from './http-request-errors/http-request-errors.service';
import { ReloadOnConnectionChangedService } from './services/reload-on-connection-changed.service';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { AppOverflowElementsComponent } from './app-overflow-elements/app-overflow-elements.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    AtlazGuiModule,
    routing,
    PaywallModule.forRoot(),
    ADragModule.forRoot(),
    SharedModule.forRoot(),
    WorkingDaysModule.forRoot(),
    DateTimeFormatterModule.forRoot(),
    NgrxModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-center',
      preventDuplicates: true
    }),
    BoardModule,
    GlobalSearchModule,
    OverviewModule,
    AppContainersModule,
    ImportModule,
    TaskModule,
    LeftMenuModule,
    PermissionsModule.forRoot(),
    MarkdownModule.forRoot(),
    BurndownChartModule,
    AtlazGuiModule,
    TimesheetModule,
    InviteLinkPopupModule,
    BillingModule
  ],
  declarations: [
    AtlazBnpComponent,
    NewBoardComponent,
    LoginErrorComponent,
    CompanyAccessDeniedComponent,
    LogoutComponent,
    LeftSidenavButtonComponent,
    CollectionsSettingsComponent,
    EditCollectionFormComponent,
    CollectionBoardListComponent,
    ForbiddenPageComponent,
    NotFoundPageComponent,
    LoadErrorPageComponent,
    InviteMemberPopupComponent,
    InviteHasBeenSentComponent,
    MassInviteComponent,
    ManyAtOnceComponent,
    HttpRequestErrorsComponent,
    AppOverflowElementsComponent
  ],
  providers: [
    appRoutingProviders,
    SocketService,
    PhoneCodesService,
    DataSyncService,
    AtlazDevtoolsService,
    { provide: IntercomService, useClass: environment.intercomAppId ? IntercomService : IntercomDummyService },
    { provide: SegmentService, useClass: environment.ENV === 'prod' ? SegmentService : SegmentDummyService },
    TaboolaService,
    FbPixelService,
    GoogleAnalyticsService,
    IntegrationsService,
    HttpRequestErrorsService,
    ReloadOnConnectionChangedService
  ],
  exports: [AtlazBnpComponent],
  bootstrap: []
})
export class AtlazBnPModule {}
