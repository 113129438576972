import { Action } from './unsafe-action';
import { labelAction } from '../functions/action';
import { PartOfEntity, Column, HttpQueryParam, entityId } from '../../interfaces';
import { COLUMN } from '../../constants';

export const ColumnActionTypes = {
  LOAD: labelAction(COLUMN, 'LOAD'),
  GET: labelAction(COLUMN, 'GET'),
  GET_COMPLETE: labelAction(COLUMN, 'GET_COMPLETE'),
  GET_FAIL: labelAction(COLUMN, 'GET_FAIL'),
  ADD: labelAction(COLUMN, 'ADD'),
  ADD_COMPLETE: labelAction(COLUMN, 'ADD_COMPLETE'),
  ADD_FAIL: labelAction(COLUMN, 'ADD_FAIL'),
  EDIT: labelAction(COLUMN, 'EDIT'),
  EDIT_COMPLETE: labelAction(COLUMN, 'EDIT_COMPLETE'),
  EDIT_FAIL: labelAction(COLUMN, 'EDIT_FAIL'),
  DELETE: labelAction(COLUMN, 'DELETE'),
  DELETE_COMPLETE: labelAction(COLUMN, 'DELETE_COMPLETE'),
  DELETE_FAIL: labelAction(COLUMN, 'DELETE_FAIL'),
  REMOVE_FROM_STORE: labelAction(COLUMN, 'REMOVE_FROM_STORE'),
  SORT_BY_PRIORITY: labelAction(COLUMN, 'SORT_BY_PRIORITY'),
  SORT_FINISHED: labelAction(COLUMN, 'SORT_FINISHED')
};

export class ColumnLoadAction implements Action {
  type = ColumnActionTypes.LOAD;

  constructor(public payload: HttpQueryParam) {}
}

export class ColumnGetAction implements Action {
  type = ColumnActionTypes.GET;

  constructor(public payload?: HttpQueryParam) {}
}

export class ColumnGetCompleteAction implements Action {
  type = ColumnActionTypes.GET_COMPLETE;

  constructor(public payload: Column[]) {}
}

export class ColumnEditAction implements Action {
  type = ColumnActionTypes.EDIT;
  constructor(public payload: PartOfEntity) {}
}

export class ColumnEditCompleteAction implements Action {
  type = ColumnActionTypes.EDIT_COMPLETE;

  constructor(public payload: Column) {}
}

export class ColumnDeleteAction implements Action {
  type = ColumnActionTypes.DELETE;

  constructor(public payload: number) {}
}

export class ColumnDeleteCompleteAction implements Action {
  type = ColumnActionTypes.DELETE_COMPLETE;

  constructor(public payload?: any) {}
}

export class ColumnAddAction implements Action {
  type = ColumnActionTypes.ADD;

  constructor(public payload: PartOfEntity) {}
}

export class ColumnAddCompleteAction implements Action {
  type = ColumnActionTypes.ADD_COMPLETE;

  constructor(public payload: Column) {}
}

export class ColumnRemoveFromStoreAction implements Action {
  type = ColumnActionTypes.REMOVE_FROM_STORE;

  constructor(public payload: entityId[]) {}
}

export class ColumnSortByPriorityAction implements Action {
  type = ColumnActionTypes.SORT_BY_PRIORITY;

  constructor(public payload: { tasks: number[]; id: number }) {}
}
export class ColumnSortFinishedAction implements Action {
  type = ColumnActionTypes.SORT_FINISHED;

  constructor() {}
}

export type ColumnActions =
  | ColumnGetAction
  | ColumnLoadAction
  | ColumnGetCompleteAction
  | ColumnEditAction
  | ColumnEditCompleteAction
  | ColumnDeleteAction
  | ColumnDeleteCompleteAction
  | ColumnAddAction
  | ColumnSortFinishedAction
  | ColumnSortByPriorityAction
  | ColumnAddCompleteAction;
