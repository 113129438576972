import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[selectionRange]'
})
export class SelectionRangeDirective implements AfterViewInit {
  @Input()
  selectionRange: {
    start: number;
    end: number;
  };

  constructor(private _elR: ElementRef) {}

  ngAfterViewInit(): void {
    this._elR.nativeElement.setSelectionRange(+this.selectionRange.start, +this.selectionRange.end);
  }
}
