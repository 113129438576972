<div (click)="onMarkdownBarClick($event)">
  <button [attr.data-modifier]="TOOLBAR_BUTTONS.heading" class="ico-heading" type="button">&nbsp;</button>
  <button [attr.data-modifier]="TOOLBAR_BUTTONS.bold" class="ico-bold" type="button">&nbsp;</button>
  <button [attr.data-modifier]="TOOLBAR_BUTTONS.italic" class="ico-italic" type="button">&nbsp;</button>
  <button [attr.data-modifier]="TOOLBAR_BUTTONS.strikethrough" class="ico-strike" type="button">&nbsp;</button>
  <button [attr.data-modifier]="TOOLBAR_BUTTONS.blockqoute" class="ico-quote" type="button">&nbsp;</button>
  <button [attr.data-modifier]="TOOLBAR_BUTTONS.ol" class="ico-ol" type="button">&nbsp;</button>
  <button [attr.data-modifier]="TOOLBAR_BUTTONS.ul" class="ico-ul" type="button">&nbsp;</button>
  <button [attr.data-modifier]="TOOLBAR_BUTTONS.link" type="button" class="ico-link">&nbsp;</button>
  <button [attr.data-modifier]="TOOLBAR_BUTTONS.img" type="button" class="ico-img">&nbsp;</button>
  <button [attr.data-modifier]="TOOLBAR_BUTTONS.hr" type="button" class="ico-hr">&nbsp;</button>
  <button [attr.data-modifier]="TOOLBAR_BUTTONS.code" class="ico-code" type="button">&nbsp;</button>
  <button [attr.data-modifier]="TOOLBAR_BUTTONS.preview" class="ico-eye" [ngClass]="{'active': isPreviewVisible}" type="button">&nbsp;</button>
</div>
<markdown *ngIf="isPreviewVisible" [data]="previewText | markdownBreak" [isTargetBlankLinks]="true"></markdown>
