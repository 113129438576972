<div class="avatar_block">
  <user-avatar-wrapper [userId]="workLog.user"></user-avatar-wrapper>
</div>
<p class="comment_text"><span>{{(user$ | async)?.fullname}}</span> logged {{workLog.loggedTime | loggedTime}}</p>
<span class="activity_date">
      {{workLog.logDate |  toDate : 'mediumDate'}}
</span>
<div class="controls" *ngIf="isCurrentUser">
  <a (click)="onToggleEditLogwork()">Edit</a>
  <a (click)="onSwitchDeleteLogwork()">Delete</a>
  <div a-context-popup [title]="'Edit Logged Time'"
       *ngIf="isLogworkPopupVisible"
       (close)="onToggleEditLogwork()"
       class="edit_logwork_confirm"
       className="medium_popup"
  >
    <task-logwork
      [taskId]="workLog.task"
      [worklog]="workLog"
      (closePopupEvent)="onToggleEditLogwork()"
      (resetFocus)="onChildChangedFocus($event)"
    ></task-logwork>
  </div>
  <div a-context-popup
       [title]="'Delete Logged Time'"
       *ngIf="isDeleteLogworkVisible"
       (close)="onSwitchDeleteLogwork()"
       class="delete_logwork_confirm"
  >
    <p class="delete_message">The changes can not be reverted.</p>
    <div a-button class="btn_warn" (click)="onDeleteLogwork()">Delete</div>
    <div a-button (click)="onSwitchDeleteLogwork()" class="btn_secondary">Cancel</div>
  </div>
</div>
<div class="comment" *ngIf="workLog.logComment">
  <smart-text [text]="workLog.logComment | textToHtml"></smart-text>
</div>

