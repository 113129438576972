import { Component, HostListener, Input, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import { BoardActivity, User } from '../../../interfaces';
import { AppState } from '../../../ngrx/state';
import { Observable } from 'rxjs';
import { getUserById } from '../../../ngrx/reducers/user.reducer';
import { RouterNavigateService } from '../../../shared/services/router-navigate.service';
import { LoggedTimePipe } from '../../../shared/pipes';
import { ActivatedRoute } from '@angular/router';
import { AppUrls } from '../../../app-urls';
import { activityMessageType } from '../../../constants/activity-type';

@Component({
  selector: 'activity-preview',
  templateUrl: './activity-preview.component.html',
  styleUrls: ['./activity-preview.component.scss']
})
export class ActivityPreviewComponent implements OnInit {
  @Input() activity: BoardActivity;

  public activityText: string;
  public user$: Observable<User>;
  public appUrls = AppUrls;

  public taskListClass = 'task_link';

  constructor(
    private _store: Store<AppState>,
    private _routerNav: RouterNavigateService,
    private _route: ActivatedRoute
  ) {}

  get iconClass() {
    switch (this.activity.type) {
      case activityMessageType.BOARD_TASK_ATTACHMENT_ADDED:
        return 'ico_link';
      case activityMessageType.BOARD_TASK_LOGGED_TIME:
        return 'ico_time';
      case activityMessageType.BOARD_TASK_SWIMLANE_CHANGED:
      case activityMessageType.MOVED_INSIDE_BOARD:
        return 'ico_move';
      case activityMessageType.BOARD_TASK_ARCHIVED:
      case activityMessageType.BOARD_COLUMN_ARCHIVED:
        return 'ico_archive_mini';
      default:
        return null;
    }
  }

  get haveIcon() {
    return this.iconClass;
  }

  ngOnInit() {
    this.user$ = <Observable<User>>this._store.pipe((getUserById(this.activity.user)));

    const dict = this.getPlaceholders(this.activity.text, this.activity.preparedText);
    this.activityText = this.replaceText(this.activity.text, dict);
  }

  public getPlaceholders(text, preparedText) {
    // everythint in []
    const regexp = /\[[\s\S]*?]/gim;

    const keys = text.match(regexp);
    const values = preparedText.match(regexp);

    return keys.reduce((dictionary, key, currentIndex) => {
      dictionary[key] = values[currentIndex];
      return dictionary;
    }, {});
  }

  public replaceText(text, dictionary) {
    return Object.keys(dictionary).reduce((resultText, placeholder) => {
      return resultText.replace(placeholder, this.prepareInsertingValue(placeholder, dictionary[placeholder]));
    }, text);
  }

  public prepareInsertingValue(placeholder, value) {
    switch (true) {
      case placeholder === '[task.title]': {
        // dirty hack
        value = `<a class="${this.taskListClass}">${value}</a>`;
      }
      case placeholder === '[loggedTime]': {
        const loggedTimePipe = new LoggedTimePipe();
        value = loggedTimePipe.transform(value);
      }
      default: {
        value = value;
      }
    }

    return value.replace(/\[|]/g, '');
  }
}
