import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { TasksSavedListResetAction } from '../actions/task-filters/tasks-saved-list.actions';
import { DefaultTasksTimeOnColumnFilterActionTypes } from '../actions/task-filters/default-time-on-column-filter.actions';

@Injectable()
export class DefaultTasksTimeOnColumnFilterEffects {
  @Effect()
  DefaultTimeOnColumnFilterToggleItemAction = this._actions$
    .ofType(DefaultTasksTimeOnColumnFilterActionTypes.TOGGLE_ITEM_ID)
    .pipe(map(_ => new TasksSavedListResetAction()));

  constructor(private _actions$: Actions) {}
}
