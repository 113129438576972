import { List, User, Column, Project, Swimlane, Group } from './index';
import { Entity } from './entity';
import { BoardFirstOpenType } from '../constants/board-type';
import { EstimationType, ScaleType, ScoringType } from '../constants';
import { BoardViewMode } from '../ngrx/reducers/gui-state-memorized.reducer';

export interface Board extends Entity {
  access: BoardInProjectAccess;
  company: number;
  creator: number;
  collection: number;
  collectionsIds: number[];

  name: string;
  type: string;

  closed: number;
  projectsCount: number;
  estimatedBy: EstimationType;
  sprintName?: string;
  isPreset?: number;

  columns?: List<Column>;
  users?: List<User>;
  projects?: List<Project>;
  swimlanes?: List<Swimlane>;
  groups?: List<Group>;

  // backlog
  scoringType?: ScoringType;
  advancedRiskWeight?: number;
  advancedValueWeight?: number;
  advancedEffortWeight?: number;
  backlogScoreXLabel: string;
  backlogScoreYLabel: string;
  backlogScoreXType: ScaleType;
  backlogScoreYType: ScaleType;
  showPopup: number;
  boardTemplateViewType?: BoardFirstOpenType;
  kvValues: string[];

  subscribersIds?: number[];
  usersIds?: number[];
  labelsIds?: number[];
  groupsIds?: number[];
  projectsIds?: number[];
  swimlanesIds?: number[];

  // if type sprint
  sprintStartDate?: number;
  sprintEndDate?: number;
  sprintReleased?: boolean | number;

  theme: number;

  showProps: string; //string of flags: showProps[0] - show time on board, showProps[1] - show time in column; default: '11'
}

export interface BoardFilterInfo {
  id: number;
  type: string;
  scoringType: ScoringType;
  boardView: BoardViewMode;
  backlogScoreXLabel?: string;
  backlogScoreYLabel?: string;
  showProps: string; //string of flags: showProps[0] - show time on board, showProps[1] - show time in column; default: '11'
}

export enum BoardInProjectAccess {
  public = 'PUBLIC',
  private = 'PRIVATE'
}
