import { ESGithubRepository } from '../../models/git-repository';
import { Action } from '../../../../ngrx/actions/unsafe-action';

export const UPDATED = '[github-repository.actions] UPDATED';
export const GET_INDEX = '[github-repository.actions] GET_INDEX';
export const GET_INDEX_COMPLETE = '[github-repository.actions] GET_INDEX_COMPLETE';
export const GET_INDEX_FAIL = '[github-repository.actions] GET_INDEX_FAIL';

export class GetIndex implements Action {
  readonly type = GET_INDEX;
}

export class Updated implements Action {
  readonly type = UPDATED;

  constructor(public payload: ESGithubRepository) {}
}

export class GetIndexComplete implements Action {
  readonly type = GET_INDEX_COMPLETE;

  constructor(public payload: ESGithubRepository) {}
}

export class GetIndexFail implements Action {
  readonly type = GET_INDEX_FAIL;
}

// prettier-ignore
export type Actions
  = GetIndex
  | Updated
  | GetIndexComplete
  ;
