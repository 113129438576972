<span class="block_title">Show Time</span>

<div a-context-popup
     class="duedate_popup"
     className="medium_popup"
     *ngIf="showPopup"
     [title]="'Show Time'"
     (close)="onClose()"
>
  <ul class="props_list">
    <li (click)="onPropClick(0)">
        <span class="prop_title">Time on board</span>
        <span class="check_label" *ngIf="board.showProps && board.showProps[0] === '1'"></span>
    </li>
    <li (click)="onPropClick(1)">
        <span class="prop_title">Time in column</span>
        <span class="check_label" *ngIf="board.showProps && board.showProps[1] === '1'"></span>
    </li>
  </ul>
</div>
<div class="click_handler" (click)="onOpen()"></div>
