<input #nameFilterRef name="name filter" [focus]="true" placeholder="Search for Version" (keyup)="0">
<div *ngIf="versionsState.ids.length" class="versions_inner">
  <p
    *ngFor="let versionId of versionsState.ids
    | filterEntitiesByField : versionsState.entities : 'name' : nameFilterRef.value"
    (click)="onSelectVersion(versionId)"
  >
    {{versionsState.entities[versionId]?.name}}
    <span *ngIf="isSelectedVersion(versionId)" class="select_marker"></span>
  </p>
</div>
<div *ngIf="!versionsState.ids.length">
  There are no versions available
</div>
