import { Action } from '../../../ngrx/actions/unsafe-action';

export enum OpenedTaskActionTypes {
  INITIAL = '[TASK], INITIAL VALUE',
  OPEN = '[TASK] OPEN TASK',
  CLOSE = '[TASK] CLOSE TASK',
  DATA_CHANGED = '[TASK] OPENED TASK DATA CHANGED',
  DELETE = '[TASK] OPENED TASK DELETE',
  MARK_AS_LOADED = '[TASK] MARK AS LOADED',
  ASSIGN_PROJECT = '[TASK] ADD/REMOVE PROJECT TO TASK'
}

export class OpenedTaskOpenAction implements Action {
  readonly type = OpenedTaskActionTypes.OPEN;
  // required TaskId as payload
  constructor(public payload: number) {}
}

export class OpenedTaskMarkAsLoadedAction implements Action {
  readonly type = OpenedTaskActionTypes.MARK_AS_LOADED;
  // required TaskId as payload
  constructor(public payload: number) {}
}

export class OpenedTaskCloseAction implements Action {
  readonly type = OpenedTaskActionTypes.CLOSE;
  constructor() {}
}

export class OpenedTaskInitAction implements Action {
  readonly type = OpenedTaskActionTypes.INITIAL;
  constructor() {}
}

export class OpenedTaskDataChangedAction implements Action {
  readonly type = OpenedTaskActionTypes.DATA_CHANGED;
  // required TaskId as payload
  constructor(public payload: any) {}
}

export class OpenedTaskDeleteAction implements Action {
  readonly type = OpenedTaskActionTypes.DELETE;
  // required TaskId as payload
  constructor(public payload: number) {}
}

export class OpenedTaskAssignProject implements Action {
  readonly type = OpenedTaskActionTypes.ASSIGN_PROJECT;
  // required TaskId as payload
  constructor(public payload: { project: number }) {}
}

// prettier-ignore
export type Actions
  = OpenedTaskOpenAction
  | OpenedTaskMarkAsLoadedAction
  | OpenedTaskCloseAction
  | OpenedTaskInitAction
  | OpenedTaskDataChangedAction
  | OpenedTaskDeleteAction
  | OpenedTaskAssignProject
  ;
