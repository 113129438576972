import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'githubColor'
})
export class GithubColor implements PipeTransform {
  private colorLabelMap = {
    ee0701: '#fff',
    cccccc: '#333333',
    '84b6eb': '#1c2733',
    '128A0C': '#fff',
    e6e6e6: '#333333',
    cc317c: '#fff',
    ffffff: '#333333'
  };

  private defColor: '#333';

  transform(value) {
    return this.colorLabelMap[value] || this.defColor;
  }
}
