import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchByField'
})
export class SearchByFieldPipe implements PipeTransform {
  transform<T>(value: T[], fieldName, partOfFieldValue): T[] {
    return !value || (partOfFieldValue && partOfFieldValue.length < 1)
      ? value
      : value.filter(item => item && item[fieldName].toLowerCase().indexOf(partOfFieldValue.toLowerCase()) > -1);
  }
}
