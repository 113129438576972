import { combineLatest, filter, map, shareReplay, take } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Board, OverviewActivity, Project } from '../../../interfaces';

import { Store } from '@ngrx/store';

import * as overviewActivity from '../../../ngrx/overview-activity/overview-activity.actions';
import * as fromOverviewActivity from '../../../ngrx/overview-activity/overview-activity.reducer';

import { AppState } from '../../../ngrx/state';

import { getAllProjectsActive } from '../../../ngrx/reducers/project.reducer';
import { naturalSort } from '../../../../helpers';
import { fromBoards } from '../../../ngrx/reducers/board.reducer';
import { OverviewFilterActivityType, OverviewFilterTimeInterval } from '../../../interfaces/overview-filters';
import { Moment } from 'moment-mini-ts';

interface FilterDateType {
  name: OverviewFilterTimeInterval;
  value: string;
}

const activityTypes = {
  all: 'Activity',
  completed_tasks: 'Completed Tasks'
};

const selectFilterTypes = [
  { name: 'all', value: 'Activity', activitiesValue: '' },
  { name: 'completed_tasks', value: 'Completed Tasks', activitiesValue: 'dones' }
];

const filterDateTypes: { name: OverviewFilterTimeInterval; value: string }[] = [
  { name: 'all_time', value: 'All Time' },
  { name: 'today', value: 'Today' },
  { name: 'yesterday', value: 'Yesterday' },
  { name: 'last_week', value: 'Last Week' },
  { name: 'last_month', value: 'Last Month' },
  { name: 'pick_a_date', value: 'Pick a Date' }
];

const EMPTY_STATE_MESSAGE = {
  [fromOverviewActivity.EmptyStateMessageType.noItemsByFilter]: 'There are no tasks matching your filter',
  [fromOverviewActivity.EmptyStateMessageType.noCompletedTasks]: 'There are no completed-table-view tasks',
  [fromOverviewActivity.EmptyStateMessageType.noActivities]: 'There is no activity yet'
};

@Component({
  selector: 'task-feed',
  templateUrl: './task-feed.component.html',
  styleUrls: ['./task-feed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskFeedComponent implements OnInit {
  @ViewChildren('date_from_filter,date_to_filter') filterCmps: QueryList<any>;

  @Input() rightMenuContent: boolean;

  public loadMore$: Observable<boolean>;
  public isActivityMenuVisible = false;

  public isFilterDateVisible = false;
  public isFilterVisible = false;
  public filterDateTypes = filterDateTypes;
  public projects$: Observable<Project[]>;
  public boards$: Observable<Board[]>;
  public overviewActivities$: Observable<OverviewActivity[]>;

  public activityTypes = selectFilterTypes;

  public activityType$: Observable<string>;
  public projectFilter$: Observable<number[]>;
  public boardFilter$: Observable<number[]>;
  public dateFromFilter$: Observable<number>;
  public dateToFilter$: Observable<number>;
  public dateIntervalFilter$: Observable<OverviewFilterTimeInterval>;
  public groupFilter$: Observable<number>;
  public usersFilter$: Observable<number[]>;

  public dateFromToFormVisible$ = new BehaviorSubject(false);
  public isPickADateFilter$: Observable<boolean>;

  public isAppliedFilter$: Observable<boolean>;
  public activityEmptyState$: Observable<string>;
  public reloading$: Observable<boolean>;

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this._store.dispatch(new overviewActivity.LoadFilters());

    this.activityType$ = this._store
      .select(fromOverviewActivity.getActivityTypeFilter)
      .pipe(map(type => activityTypes[type]), shareReplay(1));

    this.projectFilter$ = this._store.select(fromOverviewActivity.getProjectFilter).pipe(shareReplay(1));

    this.boardFilter$ = this._store.select(fromOverviewActivity.getBoardsFilter).pipe(shareReplay(1));

    this.dateFromFilter$ = this._store
      .select(fromOverviewActivity.getDateFromFilter)
      .pipe(map(value => value || undefined), shareReplay(1));

    this.dateToFilter$ = this._store
      .select(fromOverviewActivity.getDateToFilter)
      .pipe(map(value => value || undefined), shareReplay(1));

    const dateIntervalFilter$ = this._store.select(fromOverviewActivity.getDateIntervalFilter).pipe(shareReplay(1));

    this.dateIntervalFilter$ = <Observable<OverviewFilterTimeInterval>>dateIntervalFilter$.pipe(
      combineLatest(
        this.dateFromToFormVisible$,
        (intervalName, isDateFormVisible) => (isDateFormVisible ? 'pick_a_date' : intervalName)
      ),
      map(intervalName => filterDateTypes.find(item => item.name === intervalName)),
      map(interval => (interval ? interval.value : '')),
      shareReplay(1)
    );

    this.groupFilter$ = this._store.select(fromOverviewActivity.getGroupFilter).pipe(shareReplay(1));

    this.usersFilter$ = this._store.select(fromOverviewActivity.getUsersFilter).pipe(shareReplay(1));

    this.isPickADateFilter$ = dateIntervalFilter$.pipe(map(value => value === 'pick_a_date'));

    this.loadMore$ = this._store.select(fromOverviewActivity.getLoadMore);

    this.overviewActivities$ = this._store.select(fromOverviewActivity.getAllActivities).pipe(shareReplay(1));

    this.projects$ = <Observable<Project[]>>this._store.pipe(getAllProjectsActive, map(naturalSort('name')));

    this.boards$ = <Observable<Board[]>>this._store.select(fromBoards.getAllAvailable);

    this.isAppliedFilter$ = this._store.select(fromOverviewActivity.isAppliedLeastOneFilter);
    this.activityEmptyState$ = this._store
      .select(fromOverviewActivity.getEmptyStateMessageType)
      .pipe(map(messageType => EMPTY_STATE_MESSAGE[messageType]), shareReplay(1));

    this.reloading$ = this._store.select(fromOverviewActivity.isReloading);
  }

  onFiltersToggle() {
    this.isFilterVisible = !this.isFilterVisible;
  }

  onClearFilters() {
    this._store.dispatch(new overviewActivity.ClearFilter());
  }

  onApplyDateFromFilter(date: Moment) {
    const timestamp = date ? date.startOf('day').unix() : 0;
    this._store.dispatch(new overviewActivity.EditFilters({ timeFrom: timestamp, timeInterval: 'pick_a_date' }));
  }

  onApplyDateToFilter(date: Moment) {
    const timestamp = date ? date.endOf('day').unix() : 0;
    this._store.dispatch(new overviewActivity.EditFilters({ timeTo: timestamp, timeInterval: 'pick_a_date' }));
  }

  onApplyProjectFilter(projectIds: number[]) {
    this._store.dispatch(new overviewActivity.EditFilters({ projectsIds: projectIds }));
  }

  onApplyUserFilter(userFilter: { groups: number[]; users: number[] }) {
    this._store.dispatch(
      new overviewActivity.EditFilters({
        usersIds: userFilter.users,
        groupsIds: userFilter.groups
      })
    );
  }
  onApplyBoardFilter(boardIds: number[]) {
    this._store.dispatch(new overviewActivity.EditFilters({ boardsIds: boardIds }));
  }

  onToggleActivityMenu() {
    this.isActivityMenuVisible = !this.isActivityMenuVisible;
  }

  onSelectActivityType(activityType: { value: string; name: OverviewFilterActivityType }) {
    this._store.dispatch(new overviewActivity.EditFilters({ activity: activityType.name }));
  }

  onToggleFilterDateMenu() {
    this.isFilterDateVisible = !this.isFilterDateVisible;
  }

  onSelectFilterDateType(filterType: FilterDateType) {
    this.onToggleFilterDateMenu();
    if (filterType.name === 'pick_a_date') {
      this.dateFromToFormVisible$.next(true);
    } else {
      this._store.dispatch(new overviewActivity.EditFilters({ timeTo: 0, timeFrom: 0, timeInterval: filterType.name }));
      this.dateFromToFormVisible$.next(false);
    }
  }

  onLoadMore() {
    this._store
      .select(fromOverviewActivity.isLoading)
      .pipe(filter(loading => loading === false), take(1))
      .subscribe(_ => this._store.dispatch(new overviewActivity.LoadMore()));
  }
}
