<div class="content"
  tabindex="-1">
  <div class="header_container">
    <div *ngIf="!isHideCloseBtn" class="close"
      (click)="onCancelContextPopup($event)"></div>
    <ng-template [ngTemplateOutlet]="titleTpl"></ng-template>
  </div>
  <ng-content></ng-content>
</div>

<ng-template #titleTpl>
  <ng-container *ngIf="isTemplateTitle; else simple_header">
    <ng-template [ngTemplateOutlet]="title"></ng-template>
  </ng-container>
</ng-template>

<ng-template #simple_header>
  <h3>{{title}}</h3>
</ng-template>
