
import {merge as observableMerge,  Observable ,  Subject ,  Subscription } from 'rxjs';

import {throttleTime, filter, auditTime} from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { isNumber } from '../../../helpers/index';

function getScrollParent(node) {
  if (node === null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight || node.scrollTop) {
    return node;
  } else {
    return getScrollParent(node.parentNode);
  }
}

@Component({
  selector: 'a-infinite-loader',
  templateUrl: './a-infinite-loader.component.html',
  styleUrls: ['./a-infinite-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AInfiniteLoaderComponent implements OnChanges, OnInit, OnDestroy {
  @Input() pending: boolean;
  @Input() defaultLoadOffset: number;
  @Output() loadMore = new EventEmitter();

  private checkSub$ = new Subject();
  private scrollListener = () => this.checkSub$.next();
  private sub: Subscription;

  constructor(private _elRef: ElementRef) {}

  ngOnInit() {
    // Observable.fromEvent doesn't support useCapture parameter
    window.addEventListener('scroll', this.scrollListener, true);

    // do check
    this.sub = observableMerge(this.checkSub$.pipe(auditTime(100)), this.checkSub$.pipe(throttleTime(100))).pipe(
      filter(() => !this.pending))
      .subscribe(() => {
        const { bottom, height } = this._elRef.nativeElement.getBoundingClientRect();
        const container = getScrollParent(this._elRef.nativeElement);

        const containerBottom = container ? container.getBoundingClientRect().bottom : window.innerHeight;
        const startScrollHeight =
          bottom - height - (isNumber(this.defaultLoadOffset) ? this.defaultLoadOffset : height * 3);

        console.log('infinite scroll', containerBottom > startScrollHeight);

        if (containerBottom > startScrollHeight) {
          this.loadMore.emit();
        }
      });
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollListener, true);
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['pending'] && changes['pending'].currentValue !== changes['pending'].previousValue) {
      this.checkSub$.next();
    }
  }
}
