
import {distinctUntilChanged, pluck} from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../../ngrx/state/app-state';
import { BOARD_PL, ENTITY_PL } from '../../../../../../../constants/entity';
import { AppUrls } from '../../../../../../../app-urls';

@Component({
  selector: 'board-list',
  templateUrl: './board-list.component.html',
  styleUrls: ['./board-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoardListComponent implements OnInit {
  @Input() ids: number[];

  public boardEntities$;

  constructor(private _store: Store<AppState>) {}

  url(id) {
    return AppUrls.getUrlBoard(id);
  }

  ngOnInit() {
    this.boardEntities$ = this._store.pipe(pluck(BOARD_PL, ENTITY_PL),distinctUntilChanged(),);
  }
}
