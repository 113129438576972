<div [ngClass]="{sidebar_block: true, editable: editPermissions}">
  <div [ngClass]="{start_date_overlay_link: true, editable: editPermissions}" (click)="onOpenStartDatePopup()"></div>
  <div class="task_sidebar_edit_link" (click)="onOpenStartDatePopup()">
    Edit
  </div>
  <h5 class="sidebar_block_title">Start Date</h5>
  <div class="start_date">{{task.startDate | toDate: 'dueDate'}}</div>

  <sidebar-start-date-popup
    *ngIf="editPermissions && isEditStartDatePopupVisible"
    [task]="task"
    (onClosePopup)="onCloseStartDatePopup()"
  ></sidebar-start-date-popup>

</div>
<div class="divider"></div>
