<ng-container>
  <div class="default_time_on_column_filter"
       (click)="onShowTimeOnColumnPopup()"
       [ngClass]="{'active': showTimeOnColumnPopUp}"
  >
    Time in column
  </div>

  <div *ngIf="(storeTimeOnColumnFilterValue$ | async).moreThan || (storeTimeOnColumnFilterValue$ | async).lessThan" class="time_on_column_filter_tag"
       (click)="onResetFilter()">
    <p class="time_on_column_title" *ngIf="(storeTimeOnColumnFilterValue$ | async).moreThan && (storeTimeOnColumnFilterValue$ | async).lessThan">
      between {{(storeTimeOnColumnFilterValue$ | async).moreThan}} and {{(storeTimeOnColumnFilterValue$ | async).lessThan}}
    </p>
    <p class="time_on_column_title" *ngIf="(storeTimeOnColumnFilterValue$ | async).moreThan && !(storeTimeOnColumnFilterValue$ | async).lessThan">
      {{(storeTimeOnColumnFilterValue$ | async).moreThan}} or more
    </p>
    <p class="time_on_column_title" *ngIf="!(storeTimeOnColumnFilterValue$ | async).moreThan && (storeTimeOnColumnFilterValue$ | async).lessThan">
      {{(storeTimeOnColumnFilterValue$ | async).lessThan}} or less
    </p>
    <div class="clear_block"></div>
  </div>

  <div a-context-popup *ngIf="showTimeOnColumnPopUp"
       [title]="'Time in column'"
       class="default_time_on_column_popup"
       (close)="onHideTimeOnColumnPopup()"
  >
    <time-on-column-form (cancel)="onHideTimeOnColumnPopup()"></time-on-column-form>
  </div>
</ng-container>
