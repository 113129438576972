import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AppState } from '../../../ngrx/state/';
import { Store } from '@ngrx/store';

import * as fromBillingSubscription from '@atlaz/billing/store/subscription/subscription.reducer';
import { ActivatedRoute } from '@angular/router';
import { BillingPeriod, BillingPlanName } from '@atlaz/billing/constants/billing-subscription';
import { environment } from '../../../../environments/environment';

@Component({
  templateUrl: './create-subscription.component.html',
  styleUrls: ['./create-subscription.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateSubscriptionComponent implements OnInit {
  public billingSub$;

  constructor(private _store: Store<AppState>, private _router: ActivatedRoute) {}

  get period() {
    if (environment.billing.dailySubscriptionsOnly) {
      return BillingPeriod.Day;
    }
    return this._router.snapshot.queryParams['isMonthly'] === 'false' ? BillingPeriod.Year : BillingPeriod.Month;
  }

  get plan() {
    return this._router.snapshot.queryParams['plan'] || BillingPlanName.Basic;
  }

  ngOnInit() {
    this.billingSub$ = this._store.select(fromBillingSubscription.getCompanyBillingSubscription);
  }
}
