<div [ngSwitch]="column.kind">

  <div *ngSwitchCase="columnKinds.simple">
    <column-task-list
      [parentColumn]="parentColumn"
      [swimlane]="swimlane"
      [column]="column"
      [board]="board"
      [boardUsersMap]="boardUsersMap"
      [boardLabelsMap]="boardLabelsMap"
      [newNotifyMap]="newNotifyMap"
      [activeUserId]="activeUserId"
      [scoreMap]="scoreMap"
    ></column-task-list>
  </div>

  <div *ngSwitchCase="columnKinds.composite">
    <div *ngFor="let subColumn of subColumns$ | async; trackBy: trackTasksById"
         class="subcolumn">

      <!-- recurcive rendering subcolumns at composite column -->
      <column-detail
        [parentColumn]="column"
        [swimlane]="swimlane"
        [column]="subColumn"
        [board]="board"
        [boardUsersMap]="boardUsersMap"
        [boardLabelsMap]="boardLabelsMap"
        [newNotifyMap]="newNotifyMap"
        [activeUserId]="activeUserId"
        [scoreMap]="scoreMap"
      >
      </column-detail>

    </div>
  </div>

</div>
