<page-with-permissions-wrapper>
  <top-menu-container heightBind (heightChanged)="onHeightChanged($event)" #topMenu>
    <div class="header">
      <div class="arrow_back" *ngIf="!!(boardId$ | async)" [routerLink]="appUrls.getUrlBoard(boardId$ | async)"></div>
      <p class="board_name">
        <br>
        <span>{{boardName$ | async}} / Scoring Table</span>
      </p>
      <div *ngIf="false" class="legend_link" [ngClass]="{'hovered': isShowHelpText}" (click)="onToggleHelp()">
        <span class="icon_quote"></span>
        How to Read this Chart?
        <span class="icon_expand" [ngClass]="{'expanded': isShowHelpText}"></span>
      </div>
    </div>
    <board-quick-filters [boardInfo$]="boardInfo$"></board-quick-filters>
    <div class="legend_body" [ngClass]="{'expanded': isShowHelpText}">
      <!--Place table description there-->
    </div>
  </top-menu-container>
  <ng-container *ngIf="(boardScoringType$ | async) === ScoringType.advanced">
    <div class="scoring-table-wrapper" *ngIf="(isShowScoringTable$ | async);else cantShowTable">
      <scoring-table #scoringTable [boardId]="boardId$ | async" [boardCriteria$]="boardCriteria$" [tasks$]="boardTasks$"></scoring-table>
      <div class="link_text add_task" (click)="onClickAddTask()">+ Add Task</div>
    </div>
  </ng-container>
  <ng-container *ngIf="(boardScoringType$ | async) === ScoringType.ICE || (boardScoringType$ | async) === ScoringType.RICE">
    <div class="scoring-table-wrapper">
      <scoring-table-ice-rice #scoringTable [boardId]="boardId$ | async" [type$]="boardScoringType$" [tasks$]="boardTasks$"></scoring-table-ice-rice>
      <div class="link_text add_task" (click)="onClickAddTask()">+ Add Task</div>
    </div>
  </ng-container>
  <backlog-add-task-popup
    *ngIf="addTaskPopup"
    (closeAddTask)="onCloseAddTaskPopup()"
    [boardId]="boardId$ | async"
  ></backlog-add-task-popup>
</page-with-permissions-wrapper>

<ng-template #cantShowTable>
  <div class="cant_show_scoring_table">
    To see Scoring Table you need to set up at least one Criterion
  </div>
</ng-template>
