
import {switchMapTo, switchMap, catchError, tap, filter, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import { HttpQueryParam } from '../../interfaces';
import { normalizeServerResponse } from '../../../helpers/entity';
import { GROUP_PL } from '../../constants';
import { GetCompleteAction } from '../actions/root.action';
import { GroupActionTypes } from '../actions/group.actions';
import { defaultErrorHandler } from './root.effect';
import { Store } from '@ngrx/store';
import { AppState } from '../state/app-state';
import { isPresent } from '../../../helpers/object';
import { AuthService } from '../../shared/services/app/auth.service';
import { getAllActiveUsers } from '../reducers/user.reducer';
import { GROUP, USER_PL } from '../../constants/entity';
import { CompanyService } from '../../shared/services/app/company.service';
import { EVERYONE_ID, EVERYONE_NAME } from '../reducers/group.reducer';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { Group } from '../../interfaces/group';
import { HandleResponseAction } from '../actions/root.action';
import { GroupGetAction } from '../actions/group.actions';

@Injectable()
export class GroupEffects {
  @Effect()
  init$ = this._authService.userInActiveCompany$.pipe(
    filter(isPresent),
    map(() => new GroupGetAction({ expand: [USER_PL].toString() })),);

  @Effect({ dispatch: false })
  initEveryOne$ = this._authService.userInActiveCompany$.pipe(
    filter(isPresent),
    tap(_ => this._store.dispatch(new GetCompleteAction(normalizeServerResponse(this.getEveryOneObj(), GROUP_PL)))),
    switchMapTo(this._store.pipe((getAllActiveUsers))),
    map(users => {
      const usersIds: number[] = [];
      users.map(user => usersIds.push(user.id));
      this._store.dispatch(new GetCompleteAction(normalizeServerResponse(this.getEveryOneObj(usersIds), GROUP_PL)));
    }),);

  @Effect()
  getGroups$ = this.actions$
    .ofType(GroupActionTypes.GET).pipe(
    switchMap(({ type, payload: httpParams }: { type: string; payload: HttpQueryParam }) => {
      return this._atlazApi2
        .get(GROUP_PL, httpParams).pipe(
        map(resp => new HandleResponseAction(resp)),
        catchError(defaultErrorHandler(type, httpParams)),);
    }));

  @Effect()
  editGroup$ = this.actions$
    .ofType(GroupActionTypes.EDIT).pipe(
    switchMap(({ type, payload }: { type: string; payload: Partial<Group> }) => {
      return this._atlazApi2
        .patch([GROUP_PL], payload).pipe(
        map(resp => new HandleResponseAction(resp)),
        catchError(defaultErrorHandler(type, payload)),);
    }));

  @Effect({ dispatch: false })
  updateGroup$ = this.actions$
    .ofType(GroupActionTypes.ASSIGN_USERS).pipe(
    switchMap(
      ({ type, payload }: { type: string; payload: { id: number; users: { add?: number[]; remove?: number[] } } }) => {
        return this._atlazApi2
          .patch(GROUP_PL, payload).pipe(
          map(resp => new HandleResponseAction(resp)),
          catchError(defaultErrorHandler(type, payload)),);
      }
    ));

  constructor(
    private actions$: Actions,
    protected _atlazApi2: AtlazApiV2Service,
    private _store: Store<AppState>,
    private _authService: AuthService,
    private _companyService: CompanyService
  ) {}

  private getEveryOneObj(usersIds: number[] = []) {
    return {
      object: GROUP,
      creator: this._authService.activeUserId,
      id: EVERYONE_ID,
      name: EVERYONE_NAME,
      company: this._companyService.id,
      usersIds: usersIds
    };
  }
}
