import { Features } from '../libs/paywall/features.constants';

export const boardType = {
  kanban: 'kanban',
  sprint: 'sprint',
  roadmap: 'roadmap',
  backlog: 'backlog',
  list: 'list'
};

export enum ScoringType {
  basic = 'BASIC',
  ICE = 'ICE',
  RICE = 'RICE',
  advanced = 'ADVANCED',
  off = 'OFF'
}

export const NonBasicScoringTypes = [ScoringType.ICE, ScoringType.RICE, ScoringType.advanced];

export const SegmentScoringType = {
  [ScoringType.basic]: 'matrix',
  [ScoringType.ICE]: 'ice',
  [ScoringType.RICE]: 'rice',
  [ScoringType.advanced]: 'weighted'
};

export const ScoringTypeFeatures = {
  [ScoringType.ICE]: Features.Ice,
  [ScoringType.RICE]: Features.Rice,
  [ScoringType.advanced]: Features.AdvancedPrioritization
};

export enum ScaleType {
  basic = 'BASIC',
  poker = 'BASIC_PLANNING_POKER',
  shirt = 'BASIC_T_SHIRT'
}

export const QuadNames = ['Big Bets', 'Quick Wins', 'Maybes', 'Time Sinks'];

export const ScaleTypeLabel = {
  [ScaleType.basic]: '0-100',
  [ScaleType.poker]: 'Planning Poker',
  [ScaleType.shirt]: 'T-Shirt Size'
};

export const ScalePokerValues = [0, 1, 2, 3, 5, 8, 13, 20, 40, 100];
export const ScaleShirtValues = ['?', 'S', 'M', 'L', 'XL', 'XXL'];

export enum BoardFirstOpenType {
  unset = 'not_set',
  chooser = 'popup',
  scoring = 'table'
}
