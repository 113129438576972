import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Board } from '../../../interfaces';
import { Observable } from 'rxjs/Observable';
import { ScoringFactor } from '../../../board/backlog-board/constants/backlog.constants';
import { Router } from '@angular/router';
import { AppUrls } from '../../../app-urls';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { fromScoringCriteria } from '../../../ngrx/reducers/scoring-criteria.reducer';
import { ScoringCriteria } from '../../../interfaces/ScoringCriteria';
import { map } from 'rxjs/operators/map';
import { toDashedFromCamelCase, trackById } from '../../../../helpers';
import { distinctUntilChanged } from 'rxjs/operators/distinctUntilChanged';
import { startWith } from 'rxjs/operators/startWith';
import { of } from 'rxjs/observable/of';
import { FormComponent, FormSaveType, FormV2Service } from '../../../shared/services/form-v2.service';
import { Observer } from 'rxjs/Observer';
import { JsonApiSingeModelResponse } from '../../../shared/services/app/web-socket/http-response';
import { HandleResponseAction } from '../../../ngrx/actions/root.action';
import { BOARD_PL, ScoringType, SegmentScoringType } from '../../../constants';
import { take } from 'rxjs/operators/take';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { switchMap } from 'rxjs/operators/switchMap';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { throwError } from 'rxjs/internal/observable/throwError';
import { SegmentService } from '../../../atlaz-bnp/services/intergations/segment/segment.service';
import { publishReplay, refCount } from 'rxjs/operators';
import { PaywallService } from '../../../libs/paywall/paywall.service';

@Component({
  selector: 'right-menu-advanced-scoring-settings',
  templateUrl: './right-menu-advanced-scoring-settings.component.html',
  styleUrls: ['./right-menu-advanced-scoring-settings.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightMenuAdvancedScoringSettingsComponent implements OnInit, OnChanges, OnDestroy, FormComponent {
  @Input() board: Board;
  @Output() confirmCloseFn = new EventEmitter();

  public ScoringFactor = ScoringFactor;
  public BoardScoringType = ScoringType;
  public trackById = trackById;

  public form: FormGroup;
  public scoringType$: Observable<ScoringType>;
  public isAdvanced$: Observable<boolean>;
  public isBasic$: Observable<boolean>;
  public isICE$: Observable<boolean>;
  public isRICE$: Observable<boolean>;
  public criterias$: Observable<ScoringCriteria[]>;
  public sortedCriterias$: Observable<ScoringCriteria[]>;
  public valueCriterias$: Observable<ScoringCriteria[]>;
  public effortsCriterias$: Observable<ScoringCriteria[]>;
  public riskCriterias$: Observable<ScoringCriteria[]>;
  private subs = [];
  private initialValue: { [prop: string]: any } = {};

  public hasChanges$: Observable<boolean>;
  public positions$ = new BehaviorSubject({});
  public prevScoringValue: ScoringType;

  formObserver: Observer<any> = {
    next: (resp: JsonApiSingeModelResponse<any>) => {
      this._store.dispatch(new HandleResponseAction(resp));
      // it's required to wait, when new board is pushed from parent component.
      setTimeout(() => {
        this.initForm();
      });
    },

    error: error => {},

    complete: () => {}
  };

  formServiceParams = {
    saveType: FormSaveType.edit,
    entityToEdit: toDashedFromCamelCase(BOARD_PL),
    formObserver: this.formObserver,
    prepareFormValue: (formValue: any) => {
      // position is dummy, we get it later
      let { id, scoringType, valueWeight, effortWeight, positions, ...weights } = formValue;
      positions = this.positions;
      let result;
      switch (scoringType) {
        case ScoringType.basic: {
          result = {
            id,
            scoringType: ScoringType.basic
          };
          break;
        }
        case ScoringType.advanced: {
          result = {
            id,
            scoringType: ScoringType.advanced,
            advancedValueWeight: valueWeight,
            advancedEffortWeight: effortWeight
          };

          const changedWeights = Object.keys(weights).filter(key => weights[key] != this.initialValue[key]);
          if (changedWeights.length) {
            result['scoringWeights'] = changedWeights.reduce((acc, key) => {
              acc[key] = weights[key];
              return acc;
            }, {});
          }

          const changedPositions = Object.keys(positions).filter(
            key => positions[key] != this.initialValue.positions[key]
          );
          if (changedPositions.length) {
            result['scoringPositions'] = changedPositions.reduce((acc, key) => {
              acc[key] = positions[key];
              return acc;
            }, {});
          }
          break;
        }
        default: {
          result = {
            id,
            scoringType: scoringType
          };
        }
      }
      return result;
    }
  };

  positions = {};

  constructor(
    private _fb: FormBuilder,
    private _router: Router,
    private _store: Store<AppState>,
    public _formService: FormV2Service,
    private _segment: SegmentService,
    private _paywall: PaywallService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.hasOwnProperty('board') &&
      !changes.board.firstChange &&
      changes.board.currentValue.scoringType !== changes.board.previousValue.scoringType &&
      this.form
    ) {
      this.form.get('scoringType').patchValue(changes.board.currentValue.scoringType);
    }
  }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  initForm() {
    this.subs.forEach(sub => sub.unsubscribe());
    this.prevScoringValue = this.board.scoringType;
    this.initialValue = {
      id: this.board.id,
      scoringType: this.board.scoringType,
      valueWeight: this.board.advancedValueWeight,
      effortWeight: this.board.advancedEffortWeight,
      riskWeight: this.board.advancedRiskWeight,
      positions: {}
    };
    this.form = this._fb.group(this.initialValue);
    this.scoringType$ = this.form.valueChanges.pipe(
      startWith(this.form.value),
      map(value => value.scoringType),
      publishReplay(1),
      refCount()
    );
    this.isAdvanced$ = this.scoringType$.pipe(map(type => type === ScoringType.advanced));
    this.isBasic$ = this.scoringType$.pipe(map(type => type === ScoringType.basic));
    this.isICE$ = this.scoringType$.pipe(map(type => type === ScoringType.ICE));
    this.isRICE$ = this.scoringType$.pipe(map(type => type === ScoringType.RICE));
    this.criterias$ = this._store.select(fromScoringCriteria.getByBoard(this.board.id));

    this.subs.push(
      this.criterias$.subscribe((cr: ScoringCriteria[]) => {
        cr.forEach(criteria => {
          if (!this.form.contains(criteria.id.toString())) {
            this.form.addControl(criteria.id.toString(), this._fb.control(criteria.weight));
            this.initialValue[criteria.id] = criteria.weight;
            this.initialValue.positions[criteria.id] = criteria.position;
            this.positions[criteria.id] = criteria.position;
          }
        });
      })
    );
    this.positions$.next(this.positions);
    this.sortedCriterias$ = this.positions$.pipe(
      switchMap(positions =>
        this.criterias$.pipe(map((cr: ScoringCriteria[]) => cr.sort((a, b) => positions[a.id] - positions[b.id])))
      )
    );
    this.valueCriterias$ = this.sortedCriterias$.pipe(
      map((cr: ScoringCriteria[]) => cr.filter(v => v.factor === ScoringFactor.Value))
    );
    this.riskCriterias$ = this.sortedCriterias$.pipe(
      map((cr: ScoringCriteria[]) => cr.filter(v => v.factor === ScoringFactor.Risk))
    );
    this.effortsCriterias$ = this.sortedCriterias$.pipe(
      map((cr: ScoringCriteria[]) => cr.filter(v => v.factor === ScoringFactor.Efforts))
    );
    this.hasChanges$ = combineLatest(this.positions$, this.form.valueChanges.pipe(startWith(this.form.value))).pipe(
      map(
        ([positions, values]) =>
          Object.keys(values)
            .filter(v => v !== 'scoringType' && v !== 'positions')
            .some(
              key =>
                values[key] !== undefined &&
                this.initialValue[key] !== undefined &&
                values[key] != this.initialValue[key]
            ) ||
          Object.keys(positions).some(
            key =>
              positions[key] !== undefined &&
              this.initialValue.positions[key] !== undefined &&
              positions[key] != this.initialValue.positions[key]
          )
      ),
      distinctUntilChanged(),
      startWith(false)
    );
    this.subs.push(
      this.hasChanges$.subscribe(hasChanges =>
        this.confirmCloseFn.emit(
          hasChanges
            ? () => (confirm('Are you sure you want leave without saving?') ? of(true) : throwError(false))
            : () => of(true)
        )
      )
    );
    this.subs.push(
      this.form
        .get('scoringType')
        .valueChanges.pipe(distinctUntilChanged())
        .subscribe(mode => {
          setTimeout(() => {
            this._segment.track('BacklogBoardScoringChanged', {
              from: SegmentScoringType[this.initialValue.scoringType],
              to: SegmentScoringType[mode]
            });
            this.onSubmit();
          });
        })
    );
  }

  onSubmit() {
    this._formService.initFormParams(this.form, this.formServiceParams);
    this._formService.submit();
  }

  moveUp(id) {
    this.move(id, -1);
  }

  moveDown(id) {
    this.move(id, 1);
  }

  move(id, direction) {
    this.criterias$.pipe(take(1)).subscribe((cr: ScoringCriteria[]) => {
      const whatMove = cr.find(x => x.id === id);
      if (!(direction > 0)) {
        cr = cr.reverse();
      }
      const next = cr.find(
        direction > 0
          ? criteria => criteria.factor === whatMove.factor && this.positions[criteria.id] > this.positions[id]
          : criteria => criteria.factor === whatMove.factor && this.positions[criteria.id] < this.positions[id]
      );
      if (next) {
        const tmp = this.positions[whatMove.id];
        this.positions[whatMove.id] = this.positions[next.id];
        this.positions[next.id] = tmp;
        this.positions$.next(this.positions);
      }
    });
  }

  onAddCriteria(boardId, type) {
    this._router.navigate(AppUrls.getCriteriaDetails(boardId, type), {
      skipLocationChange: true
    });
  }

  onEditCriteria(boardId, id) {
    this._router.navigate(AppUrls.getCriteriaDetails(boardId, id), {
      skipLocationChange: true
    });
  }

  isSelected(type) {
    return this.form.value.scoringType === type;
  }
}
