import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Task } from '../../../interfaces';

@Component({
  selector: 'sidebar-story-points',
  templateUrl: './sidebar-story-points.component.html',
  styleUrls: ['./sidebar-story-points.component.scss']
})
export class SidebarStoryPointsComponent {
  @Input() task: Task;
  @Input() editPermissions = false;
  @Output() resetFocus = new EventEmitter();

  public isPointsPopupVisible = false;

  constructor() {}

  onOpenPointsPopup() {
    if (!this.editPermissions) {
      return false;
    }
    this.isPointsPopupVisible = true;
  }

  onCloseEstimatePopup() {
    this.isPointsPopupVisible = false;
  }

  onChildChangedFocus() {
    this.resetFocus.emit({});
  }
}
