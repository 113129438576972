<div class="root" [ngClass]="{visible: this.showContent}">
  <div *ngIf="showContent">
    <div [ngSwitch]="board.type">
      <div *ngSwitchCase="boardType.sprint">
        <right-menu *ngIf="!board.sprintReleased"></right-menu>
        <released-sprint-right-menu [board]="board" *ngIf="board.sprintReleased"></released-sprint-right-menu>
      </div>
      <div *ngSwitchDefault>
        <right-menu></right-menu>
      </div>
    </div>
  </div>
</div>
<div class="overlay" *ngIf="isVisibleRightMenu$ | async" (click)="onCloseRightMenu()"><div></div></div>
