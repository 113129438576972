import { FormControl, ValidatorFn } from '@angular/forms';

export function validatePositiveFactory(): ValidatorFn {
  return (c: FormControl) => {
    return c.value > 0
      ? null
      : {
          positiveValidator: {
            valid: false
          }
        };
  };
}
