import { ESGithubCommit } from '../../models/git-commit';
import { Action } from '../../../../ngrx/actions/unsafe-action';

export const UPDATED = '[github-commit.actions] UPDATED GITHUB COMMITS';
export const GET_TASK_COMMIT = '[github-commit.actions] get github commits by task';
export const GET_TASK_COMMIT_COMPLETE = '[github-commit.actions] get taskCommit complete';
export const DELETE_TASK_COMMIT = '[github-commit.actions] remove taskCommit';
export const GET_TASK_COMMIT_FAIL = '[github-commit.actions] GET_TASK_COMMIT_FAIL';

export class GetTaskCommit implements Action {
  readonly type = GET_TASK_COMMIT;

  constructor(public payload: { task: number }) {}
}

export class Updated implements Action {
  readonly type = UPDATED;

  constructor(public payload: ESGithubCommit) {}
}

export class GetTaskCommitComplete implements Action {
  readonly type = GET_TASK_COMMIT_COMPLETE;

  constructor(public payload: { taskId: number; entities: ESGithubCommit }) {}
}

export class GetTaskCommitFail implements Action {
  readonly type = GET_TASK_COMMIT_FAIL;
}

export class DeleteTaskCommit implements Action {
  readonly type = DELETE_TASK_COMMIT;

  constructor(public payload: { id: number; taskId: number }) {}
}

// prettier-ignore
export type Actions
  = GetTaskCommit
  | Updated
  | GetTaskCommitComplete
  | DeleteTaskCommit
  | GetTaskCommitFail
  ;
