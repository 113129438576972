
import {filter, take} from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppState } from '../../ngrx/state/';
import { Store } from '@ngrx/store';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { Board } from '../../interfaces/';
import { BoardActionTypes, BoardOpenAction, BoardReopenAction } from '../../ngrx/actions/board.actions';
import { Actions } from '@ngrx/effects';

@Component({
  selector: 'close-board-page',
  templateUrl: './close-board-page.component.html',
  styleUrls: ['./close-board-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CloseBoardPageComponent {
  @Input() board: Board;

  public runLoader = false;

  constructor(private _store: Store<AppState>, private _apiV2Service: AtlazApiV2Service, private actions$: Actions) {}

  onReopenBoard() {
    this.runLoader = true;
    this._store.dispatch(new BoardReopenAction(this.board.id));
    this.actions$
      .ofType(BoardActionTypes.REOPEN_COMPLETE, BoardActionTypes.REOPEN_FAILED).pipe(
      take(1),
      filter(() => !!this.board),)
      .subscribe(() => {
        this.runLoader = false;
        this._store.dispatch(new BoardOpenAction(this.board.id));
      });
  }
}
