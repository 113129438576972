<svg viewBox="0 0 10 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <filter x="-1.8%" y="-10.4%" width="103.5%" height="129.2%" filterUnits="objectBoundingBox" id="filter-1">
      <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
      <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
      <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
      <feMerge>
        <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
        <feMergeNode in="SourceGraphic"></feMergeNode>
      </feMerge>
    </filter>
  </defs>
  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Desktop-HD-#21" transform="translate(-523.000000, -455.000000)">
      <g id="task" filter="url(#filter-1)" transform="translate(520.000000, 450.000000)">
        <g id="icon" transform="translate(0.000000, 4.000000)">
          <rect id="Rectangle" fill-opacity="0" fill="#D8D8D8" x="0" y="0" width="16" height="16"></rect>
          <path d="M5,2 L5,14 L7,14 L7,2 L5,2 Z M11,14 L11,2 L9,2 L9,14 L11,14 Z" id="Shape" class="color" fill="#FFFFFF" fill-rule="nonzero"></path>
        </g>
      </g>
    </g>
  </g>
</svg>
