import { Moment } from 'moment-mini-ts';
import { WorkingDays } from '../interfaces/working-days.interface';

export const isDayOff = (day: Moment, workingDays: WorkingDays) => {
  return !workingDays[day.format('ddd').toLowerCase()];
};

export const isWorkingDay = (day: Moment, workingDays: WorkingDays) => {
  return !isDayOff(day, workingDays);
};
