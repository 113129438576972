import { Component, OnInit, Input, ChangeDetectionStrategy, OnChanges } from '@angular/core';

@Component({
  selector: '[chart-tooltip]',
  template: `
    <div class="tooltip-wrapper" [ngStyle]="style">
      <ng-content></ng-content>
    </div>`,
  styles: ['.tooltip-wrapper {position: fixed; overflow-y: auto}'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TooltipComponent implements OnInit, OnChanges {
  @Input() topPx: number;
  @Input() bottomPx: number;
  @Input() leftPx: number;

  public style = {};

  ngOnInit(): any {}

  ngOnChanges() {
    this.style = {
      'top.px': this.topPx,
      'bottom.px': this.bottomPx,
      'left.px': this.leftPx
    };
  }
}
