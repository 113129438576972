
    <input type="text" #nameFilterRef name="name" [focus]="true" placeholder="Search for Projects" (keyup)="0" #input_search_projects/>
    <div class="projects_container">
      <div *ngFor="let projectId of projectsState.ids
      | filterEntitiesByField : projectsState.entities : 'name' : nameFilterRef.value"
           [ngClass]="{project_title: true, disabled_project_title: disabledProjects.includes(projectId)}"
           class="project_title "
           (click)="onSelectProject(projectId)"
      >
        <p>
          {{projectsState.entities[projectId]?.name}}
          <span class="check_label" *ngIf="isSelectedProject(projectId)"></span>
        </p>
      </div>
    </div>
  