import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Notification } from '../../../../../interfaces/notification';
import { Observable } from 'rxjs';
import { User } from '../../../../../interfaces/user';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../ngrx/state/app-state';
import { MarkAsTypes, NotificationMarkAsAction } from '../../../../../ngrx/actions/notification.actions';
import { getUserById } from '../../../../../ngrx/reducers/user.reducer';

@Component({
  selector: 'single-notification-preview',
  templateUrl: './single-notification-preview.component.html',
  styleUrls: ['../../_notification_common.scss', './single-notification-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleNotificationPreviewComponent implements OnInit {
  @Input() notification: Notification;
  @Input() url: string;
  @Input() hasTitle: boolean;

  public user$: Observable<User>;

  get hasUrl() {
    return this.url && this.url.length > 0;
  }

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this.user$ = <Observable<User>>this._store.pipe((getUserById(this.notification.creator)));
  }

  markAsSeen() {
    this._store.dispatch(
      new NotificationMarkAsAction({ markType: MarkAsTypes.seen, notificationIds: [this.notification.id] })
    );
  }

  onClick(event: MouseEvent) {
    if (event.srcElement && event.srcElement['href']) {
      event.stopImmediatePropagation();
    }
  }

  onArchiveNotification() {
    this._store.dispatch(
      new NotificationMarkAsAction({
        markType: MarkAsTypes.archive,
        notificationIds: [this.notification.id]
      })
    );
  }
}
