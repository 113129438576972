<input class="quick_search_columns"
       [focus]="true"
       placeholder="Search Column..."
       #input_seacrh_columns
       (keyup)="0"
>
<div class="columns_container">
  <div *ngFor="let column of visibleColumns | searchByField: 'name': input_seacrh_columns.value"
       class="column_title"
       (click)="onSetFilter(column)"
  >
    <p>{{column.name}}</p>
    <span class="check_label" *ngIf="checkSelectedColumn[column.id]"></span>
  </div>
</div>
