import { labelAction } from '../../../../../ngrx/functions/action';
import { IMPORT } from '../../../../../constants';
import { Action } from '../../../../../ngrx/actions/unsafe-action';
export const ActiveImportActionTypes = {
  INITIAL: labelAction(IMPORT, 'INITIAL VALUE'),
  DATA_CHANGED: labelAction(IMPORT, 'ACTIVE IMPORT CHANGED'),
  CANCEL_IMPORT: labelAction(IMPORT, 'CANCEL'),
  START_TRELLO_IMPORT: labelAction(IMPORT, 'START_TRELLO_IMPORT'),
  CONFIRM_IMPORT: labelAction(IMPORT, 'CONFIRM_IMPORT')
};

export class ActiveImportInitAction implements Action {
  type = ActiveImportActionTypes.INITIAL;
  constructor() {}
}

export class ActiveImportDataChangedAction implements Action {
  type = ActiveImportActionTypes.DATA_CHANGED;
  // required TaskId as payload
  constructor(public payload: any) {}
}

export class ActiveImportCancelAction implements Action {
  type = ActiveImportActionTypes.CANCEL_IMPORT;
  constructor() {}
}

export class ActiveImportConfirmAction implements Action {
  type = ActiveImportActionTypes.CONFIRM_IMPORT;
  constructor(public payload: any) {}
}
