
import {map, distinctUntilChanged, pluck, publishReplay, refCount} from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { Checklist } from '../../interfaces';
import { AppState } from '../../ngrx/state';
import { Store } from '@ngrx/store';

import { CHECKLIST_PL, ENTITY_PL } from '../../constants';
import { ADragService, DragItem } from '../../shared/a-drag/a-drag.service';
import { PatchEntityPosition } from '../../ngrx/actions/root.action';
import { CHECKLISTS_ITEM_PL } from '../../constants/entity';
import { entityStateToArray } from '../../ngrx/state/app-state';
import { sortByField } from '../../../helpers/array';

@Component({
  selector: 'task-checklist',
  templateUrl: './task-checklist.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskChecklistComponent implements OnInit, OnDestroy {
  @Input() taskId: number;
  @Input() editPermissions = false;

  public taskChecklists$: Observable<{ [id: number]: Checklist }>;
  public taskChecklistsIds$: Observable<number[]>;

  public dragItemType = CHECKLIST_PL;

  private subs = [];

  constructor(private _store: Store<AppState>, private _dragService: ADragService) {}

  ngOnInit(): any {
    let checkLists$ = this._store.pipe(
      pluck(CHECKLIST_PL),
      distinctUntilChanged(),
      map(entityStateToArray),
      map(list => list.filter((item: Checklist) => item.task === this.taskId)),
      map(sortByField('position')),);

    checkLists$ = this._dragService
      .filterItems(checkLists$, this.dragItemType).pipe(
      publishReplay(1),
      refCount(),);

    this.taskChecklistsIds$ = checkLists$.pipe(map(xs => xs.map(x => x.id)));

    this.taskChecklists$ = <Observable<{ [id: number]: Checklist }>>this._store.pipe(
      pluck(CHECKLIST_PL, ENTITY_PL),
      distinctUntilChanged(),);

    this.subs.push(this._dragService.finallyMoved$.subscribe((v: DragItem) => this.handleDrop(v)));
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  handleDrop(value: DragItem) {
    console.warn('dispatch drop', value);
    switch (value.type) {
      case CHECKLISTS_ITEM_PL: {
        this._store.dispatch(new PatchEntityPosition({ value, entityPl: CHECKLISTS_ITEM_PL, insertSuffix: 'ItemId' }));
        break;
      }
      case CHECKLIST_PL: {
        this._store.dispatch(new PatchEntityPosition({ value, entityPl: CHECKLIST_PL, insertSuffix: 'Checklist' }));
        break;
      }
      default:
      // there's nothing to do
    }
  }
}
