<div class="default_labels_filter"
     (click)="onShowLabelsPopup()"
     [ngClass]="{'active': showLabelsPopUp}"
>
	Labels
</div>

<div *ngFor="let label of selectedLabelTags$ | async"
     class="label_filter_tag"
     (click)="onRemoveLabel(label)"
>
	<div class="label_color_box" [ngClass]="label.color"></div>
	<p class="label_title">
		{{label.name}}
	</p>
	<div class="clear_block"></div>
</div>

<div a-context-popup *ngIf="showLabelsPopUp"
     [title]="'Labels'"
     class="default_labels_list_popup"
     (close)="onHideLabelsPopup()"
>
	<labels-filter-list
			[tags]="labelTags$ | async"
			[selectedLabels]="selectedLabelTags$ | async"
			(addLabel)="onAddLabel($event)"
			(removeLabel)="onRemoveLabel($event)"
	></labels-filter-list>
</div>
