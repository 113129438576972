import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';

import {
  AppState,
  COMPONENT,
  DATA_SYNC,
  DEFAULT_COLORS_FILTER,
  DEFAULT_COLUMNS_FILTER,
  DEFAULT_FILTER_STATE,
  DEFAULT_LABELS_FILTER,
  DEFAULT_MEMBERS_FILTER,
  DEFAULT_PROJECTS_FILTER,
  DEFAULT_QUADS_FILTER,
  DEFAULT_TIME_ON_BOARD_FILTER,
  DEFAULT_TIME_ON_COLUMN_FILTER,
  DEFAULT_STATUSES_FILTER,
  DEFAULT_SWIMLANES_FILTER,
  DEFAULT_VERSIONS_FILTER,
  getEmptyESState,
  GLOBAL_SEARCH,
  GUI_STATE_MEMORIZED,
  TASKS_SAVED_LIST,
  REPORT_MEMBER_FILTER,
  REPORT_PROJECT_FILTER,
  REPORT_BOARD_FILTER,
  REPORT_PERIOD_FILTER,
  REPORT_GROUP_FILTER
} from '../state';
import * as E from '../../constants/entity';
import { RootActionTypes, UNDO_ACITON } from '../actions/root.action';
import { ACTIVE_IMPORT, AppPages, CURRENT_COMPANY, ROADMAP_BOARD } from '../../constants';

import { createReducer } from './reducer-factory';
import {
  editEntityState,
  mergeStates,
  removeEntitiesFromStore,
  removeEntityFromStore,
  removeMany
} from '../functions/reducer';
import * as fromSlackAlert from '../../integrations/settings/slack-alert/store/slack-alert.reducer';
import * as fromIntegrations from '../../integrations/core/store/integration.reducer';

import { reducer as globalSearch } from './global-search.reducer';
import { excludeSwimlaneIdInBoards, injectSwimlanesIdsInBoards, reducer as boardReducer } from './board.reducer';
import { reducer as swimlaneReducer } from './swimlane.reducer';
import { fixTasksKeys, reducer as taskReducer } from './task.reducer';
import { reducer as defaultCollectionsReducer } from './default-collection.reducer';
import { reducer as qFilterReducer } from './quick-filter.reducer';
import { reducer as taskQuickFilterReducer } from './task-filters/quick-task-filter.reducer';
import { injectUsersCompanyAttributes, reducer as userReducer } from './user.reducer';
import { reducer as columnReducer } from './column.reducer';
import { reducer as groupReducer } from './group.reducer';
import { reducer as projectReducer } from './project.reducer';
import { reducer as checklistReducer } from './checklist.reducer';
import { reducer as checklistItemReducer } from './checklist-item.reducer';
import { reducer as taskActivityReducer } from './task-activity.reducer';
import { reducer as overviewActivityReducer } from '../overview-activity/overview-activity.reducer';
import { reducer as taskAttachmentsReducer } from './task-attachments.reducer';
import { reducer as versionReducer } from './version.reducer';
import { reducer as componentReducer } from './component.reducer';
import { reducer as notificationReducer } from './notification.reducer';
import { reducer as labelReducer } from './label.reducer';
import { reducer as guiStateMemorized } from './gui-state-memorized.reducer';
import { reducer as workLogReducer } from './worklog.reducer';
import { reducer as collectionReducer } from './collection.reducer';
import { reducer as boardActivityReducer } from './board-activity.reducer';
import { reducer as taskDefaultFilterReducer } from './task-filters/default-task-filter.reducer';
import { activeImportReducer } from '../../company-settings/settings/import/ngrx/reducers/active-import.reducer';
import { reducer as defaultMembersFilterReducer } from './task-filters/default-members-filter.reducer';
import { reducer as defaultLabelsFilterReducer } from './task-filters/default-labels-filter.reducer';
import { reducer as defaultColorsFilterReducer } from './task-filters/default-colors-filter.reducer';
import { reducer as defaultQuadsFilterReducer } from './task-filters/default-quads-filter.reducer';
import { reducer as defaultColumnsFilterReducer } from './task-filters/default-columns-filter.reducer';
import { reducer as defaultSwimlanesFilterReducer } from './task-filters/default-swimlanes-filter.reducer';
import { reducer as defaultVersionsFilterReducer } from './task-filters/default-versions-filter.reducer';
import { reducer as defaultProjectsFilterReducer } from './task-filters/default-projects-filter.reducer';
import { reducer as defaultTimeOnBoardFilterReducer } from './task-filters/default-time-on-board-filter.reducer';
import { reducer as defaultTimeOnColumnFilterReducer } from './task-filters/default-time-on-column-filter.reducer';
import { reducer as defaultStatusesFilterReducer } from './task-filters/default-statuses-filter.reducer';
import { reducer as reportProjectsFilterReducer } from './report-filters/report-projects-filter.reducer';
import { reducer as reportBoardsFilterReducer } from './report-filters/report-boards-filter.reducer';
import { reducer as reportPeriodFilterReducer } from './report-filters/report-period-filter.reducer';
import { reducer as reportMembersFilterReducer } from './report-filters/report-members-filter.reducer';
import { reducer as reportGroupsFilterReducer } from './report-filters/report-groups-filter.reducer';
import { reducer as intercomCompanies } from '../../integrations/settings/intercom-settings/ngrx/intercom-company.reducer';
import { reducer as jiraCompanies } from '../../integrations/settings/jira-settings/ngrx/jira-company.reducer';
import { reducer as taskSavedListReducer } from './task-filters/tasks-saved-list.reducer';
import { reducer as companyDomainReducer } from './company-domain.reducer';
import { openedTaskReducer } from '../../task/ngrx/reducers/opened-task.reducer';
import { PERMISSIONS } from '../../permissions/ngrx/permission.action';
import { reducer as permissionReducer } from '../../permissions/ngrx/permission.reducer';
import { reducer as dataSyncReducer } from './data-sync.reducer';
import * as fromBillingPlan from '@atlaz/billing/store/plan/plan.reducer';
import * as fromBillingSubscription from '@atlaz/billing/store/subscription/subscription.reducer';
import { roadmapBoardReducer } from '../../board/roadmap-board/store/roadmap-board.reducer';
import * as fromLoadedPages from '../../loaded-data/store/loaded-data.reducer';
import * as fromGitReducers from '../../integrations/git/store';
import { currentCompanyReducer } from './current-company.reducer';
import { BOARD_PL, SWIMLANE } from '../../constants';
import { Action } from '../actions/unsafe-action';
import { environment } from '../../../environments/environment';

export const appReducers: ActionReducerMap<AppState> = {
  [COMPONENT]: componentReducer,
  [GLOBAL_SEARCH]: globalSearch,
  taskQuickFilter: taskQuickFilterReducer,
  [DEFAULT_FILTER_STATE]: taskDefaultFilterReducer,
  [E.BOARD_PL]: boardReducer,
  [E.SWIMLANE_PL]: swimlaneReducer,
  [E.TASK_PL]: taskReducer,
  [E.DEFAULT_COLLECTION_PL]: defaultCollectionsReducer,
  [E.FILTER_PL]: qFilterReducer,
  [E.CHECKLIST_PL]: checklistReducer,
  [E.CHECKLISTS_ITEM_PL]: checklistItemReducer,
  [E.USER_PL]: userReducer,
  [E.PROJECT_PL]: projectReducer,
  [E.GROUP_PL]: groupReducer,
  [E.TASK_ACTIVITY_PL]: taskActivityReducer,
  [E.TASKS_ATTACHMENT_PL]: taskAttachmentsReducer,
  [E.VERSION_PL]: versionReducer,
  [E.COMPANY_DOMAINS_PL]: companyDomainReducer,
  [E.LABEL_PL]: labelReducer,
  [E.COLUMN_PL]: columnReducer,
  [E.NOTIFICATION_PL]: notificationReducer,
  [GUI_STATE_MEMORIZED]: guiStateMemorized,
  [E.COLLECTION_PL]: collectionReducer,
  [E.WORKLOG_PL]: workLogReducer,
  [E.BOARD_ACTIVITY_PL]: boardActivityReducer,
  [E.OVERVIEW_ACTIVITY_PL]: overviewActivityReducer,
  [CURRENT_COMPANY]: currentCompanyReducer,
  [ACTIVE_IMPORT]: activeImportReducer,
  [AppPages.Task]: openedTaskReducer,
  [ROADMAP_BOARD]: roadmapBoardReducer,
  [DEFAULT_MEMBERS_FILTER]: defaultMembersFilterReducer,
  [DEFAULT_LABELS_FILTER]: defaultLabelsFilterReducer,
  [DEFAULT_COLUMNS_FILTER]: defaultColumnsFilterReducer,
  [DEFAULT_COLORS_FILTER]: defaultColorsFilterReducer,
  [DEFAULT_QUADS_FILTER]: defaultQuadsFilterReducer,
  [DEFAULT_SWIMLANES_FILTER]: defaultSwimlanesFilterReducer,
  [DEFAULT_VERSIONS_FILTER]: defaultVersionsFilterReducer,
  [DEFAULT_PROJECTS_FILTER]: defaultProjectsFilterReducer,
  [DEFAULT_TIME_ON_BOARD_FILTER]: defaultTimeOnBoardFilterReducer,
  [DEFAULT_TIME_ON_COLUMN_FILTER]: defaultTimeOnColumnFilterReducer,
  [DEFAULT_STATUSES_FILTER]: defaultStatusesFilterReducer,
  [REPORT_PROJECT_FILTER]: reportProjectsFilterReducer,
  [REPORT_BOARD_FILTER]: reportBoardsFilterReducer,
  [REPORT_PERIOD_FILTER]: reportPeriodFilterReducer,
  [REPORT_MEMBER_FILTER]: reportMembersFilterReducer,
  [REPORT_GROUP_FILTER]: reportGroupsFilterReducer,
  [TASKS_SAVED_LIST]: taskSavedListReducer,
  [PERMISSIONS]: permissionReducer,
  [DATA_SYNC]: dataSyncReducer,
  [E.BILLING_PLAN_PL]: fromBillingPlan.reducer,
  [E.BILLING_SUBSCRIPTION_PL]: fromBillingSubscription.reducer,
  ...fromSlackAlert.reducers,
  ...fromIntegrations.reducers,
  [fromLoadedPages.LOADED_DATA]: fromLoadedPages.reducer,
  ...fromGitReducers.reducers,
  [E.INTERCOM_COMPANY_PL]: intercomCompanies,
  [E.JIRA_COMPANY_PL]: jiraCompanies,
  [E.COMPANY_PL]: createReducer(getEmptyESState(), E.COMPANY_PL),
  [E.SPRINT_TASK_PL]: createReducer(getEmptyESState(), E.SPRINT_TASK_PL),
  [E.CLIENT_PL]: createReducer(getEmptyESState(), E.CLIENT_PL),
  [E.TAG_PL]: createReducer(getEmptyESState(), E.TAG_PL),
  [E.USER_COMPANY_PL]: createReducer(getEmptyESState(), E.USER_COMPANY_PL),
  [E.RECENTLY_COMPLETED_TASK_PL]: createReducer(getEmptyESState(), E.RECENTLY_COMPLETED_TASK_PL),
  [E.SUBSCRIBER_PL]: createReducer(getEmptyESState(), E.SUBSCRIBER_PL),
  [E.COMMENT_PL]: createReducer(getEmptyESState(), E.COMMENT_PL),
  [E.IMPORT_PL]: createReducer(getEmptyESState(), E.IMPORT_PL),
  [E.COMPANY_FEATURE_PL]: createReducer(getEmptyESState(), E.COMPANY_FEATURE_PL),
  [E.RECENT_TASK_PL]: createReducer(getEmptyESState(), E.RECENT_TASK_PL),
  [E.TASKS_LINKS_TYPE_PL]: createReducer(getEmptyESState(), E.TASKS_LINKS_TYPE_PL),
  [E.TASKS_LINK_PL]: createReducer(getEmptyESState(), E.TASKS_LINK_PL),
  [E.QUICK_BACKUP_PL]: createReducer(getEmptyESState(), E.QUICK_BACKUP_PL),
  [E.BOARDS_TEMPLATE_PL]: createReducer(getEmptyESState(), E.BOARDS_TEMPLATE_PL),
  [E.HARVEST_BOARD_PL]: createReducer(getEmptyESState(), E.HARVEST_BOARD_PL),
  [E.SCORING_CRITERIA_PL]: createReducer(getEmptyESState(), E.SCORING_CRITERIA_PL),
  [E.HYGGER_THEME_PL]: createReducer(getEmptyESState(), E.HYGGER_THEME_PL),
  [E.CUSTOM_FIELD_PL]: createReducer(getEmptyESState(), E.CUSTOM_FIELD_PL),
  [E.TASKS_CUSTOM_FIELD_PL]: createReducer(getEmptyESState(), E.TASKS_CUSTOM_FIELD_PL),
  [E.EXTERNAL_USER_PL]: createReducer(getEmptyESState(), E.EXTERNAL_USER_PL)
};

export function getReducers() {
  return appReducers;
}

export const metaReducers: MetaReducer<any>[] =
  environment.ENV === 'prod' ? [handleUndo, metaReducer] : [handleUndo, storeFreeze, metaReducer];

export function metaReducer(reducer) {
  return function(state, action) {
    switch (action.type) {
      case RootActionTypes.GET_COMPLETE: {
        let newState = mergeStates(state, action.payload);
        if (action.payload && (action.payload.tasks || action.payload.projects)) {
          newState = fixTasksKeys(action.payload.tasks, action.payload.projects, newState);
        }
        if (action.payload && action.payload.swimlanes) {
          newState = injectSwimlanesIdsInBoards(action.payload.swimlanes, newState);
        }
        if (action.payload && (action.payload.users || action.payload.usersCompanies)) {
          newState = injectUsersCompanyAttributes(
            action.payload.users,
            action.payload.usersCompanies,
            state.currentCompany.id,
            newState
          );
        }
        return reducer(newState, action);
      }
      case RootActionTypes.SELECT_ENTITY: {
        const stateCopy = Object.assign({}, state);
        stateCopy[action.payload.entityName] = Object.assign({}, stateCopy[action.payload.entityName]);
        stateCopy[action.payload.entityName].selectedEntityId = action.payload.id;
        return stateCopy;
      }
      case RootActionTypes.REMOVE_ENTITY_FROM_STORE: {
        return removeEntityFromStore(action.payload, state);
      }
      case RootActionTypes.REMOVE_ENTITIES_FROM_STORE: {
        return removeEntitiesFromStore(action.payload, state);
      }
      case RootActionTypes.REMOVE_ENTITIES_BY_IDS: {
        let newState = state;
        if (action.payload && Array.isArray(action.payload)) {
          const swimlanesPayload = action.payload.find(diff => diff.object === SWIMLANE);
          if (swimlanesPayload) {
            swimlanesPayload.ids.forEach(id => {
              newState = excludeSwimlaneIdInBoards(id, newState[BOARD_PL]);
            });
          }
        }
        newState = removeMany(action.payload, state);
        return newState;
      }

      case RootActionTypes.EDIT_MODEL:
      case RootActionTypes.PATCH_ENTITY: {
        const stateCopy = Object.assign({}, state);
        const { entityName, data } = action.payload;
        stateCopy[entityName] = editEntityState(stateCopy[entityName], data);
        return stateCopy;
      }
      default: {
        return reducer(state, action);
      }
    }
  };
}

export function handleUndo(rootReducer: ActionReducer<AppState>, bufferSize = 100): ActionReducer<AppState> {
  let executedActions: Array<Action> = [];
  let initialState;
  return (state: AppState, action: Action) => {
    if (action.type === UNDO_ACITON) {
      // if the action is UNDO_ACTION,
      // then call all the actions again on the rootReducer,
      // except the one we want to rollback
      let newState: any = initialState;
      executedActions = executedActions.filter(eAct => eAct !== action.payload);
      // update the state for every action untill we get the
      // exact same state as before, but without the action we want to rollback
      executedActions.forEach(executedAction => (newState = rootReducer(newState, executedAction)));
      return newState;
    }
    // push every action that isn't an UNDO_ACTION to the executedActions property
    executedActions.push(action);
    const updatedState = rootReducer(state, action);
    if (executedActions.length === bufferSize + 1) {
      const firstAction = executedActions[0];
      // calculate the state x (buffersize) actions ago
      initialState = rootReducer(initialState, firstAction);
      // keep the correct actions
      executedActions = executedActions.slice(1, bufferSize + 1);
    }
    return updatedState;
  };
}
