import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sidebar-repeat-add-popup',
  templateUrl: './sidebar-repeat-add-popup.component.html',
  styleUrls: ['./sidebar-repeat-add-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarRepeatAddPopupComponent {
  @Input() task: number;
  @Output() close = new EventEmitter();

  constructor() {
  }

  onCloseTaskLinks() {
    this.close.emit();
  }
}
