<div class="search-dropdown-container" (click)="onOpenItemsPopup()" >
  <p class="selected-value">{{selectedItem?.title}}</p>
  <div class="search-dropdown-items-popup" *ngIf="isItemsPopupVisible"
       [clickOut]="isItemsPopupVisible"
       (clickOutEvent)="onCloseItemsPopup()"
  >
    <input type="text" #input_search_items (keyup)="0" [focus]="true"
           placeholder="Search..." class="search-box">
    <div class="items-block">
      <div
        class="item"
        *ngFor="let topItem of topItems"
        (click)="onSelectItem($event, topItem)"
      >
        <p *ngIf="input_search_items.value === ''" >
          {{topItem.title}}
        </p>
      </div>
      <div class="item"
           *ngFor="let item of items | searchByField: 'title': input_search_items.value"
           (click)="onSelectItem($event, item)">
        <p>
          {{item.title}}
        </p>
      </div>
    </div>
  </div>
</div>
