import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EnterHotkey, HotKey } from '../../../directives/';
import { Column } from '../../../../interfaces';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { User } from '../../../../interfaces/';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../ngrx/state/';
import { getBoardUsers } from '../../../../ngrx/reducers/user.reducer';
import { KeyCode } from '../../../../constants/';
import { PatternValidator } from '../../../validators/custom-validators/';
import { TaskUnsavedDataService } from '../../../../task/shared/services/task-unsaved-data.service';
import { mouseWheelEventName } from '../../../../../helpers/event';

@Component({
  selector: 'add-new-task',
  templateUrl: './add-new-task.component.html',
  styleUrls: ['./add-new-task.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddNewTaskComponent implements OnChanges, OnDestroy {
  @ViewChild('taskTitleInput') elementRef: ElementRef;

  @Input() initialValue: string;
  @Input() column: Column;
  @Input() swimlaneId: number;
  @Input() isTaskLastInSwimlane: boolean;
  @Input() addAttrForHotKeys: boolean;

  @Output() addTaskEvent = new EventEmitter();

  isFormContainerVisible = false;

  public fireSelectEvent$ = new BehaviorSubject(null);
  public inlineForm: FormGroup;
  public selectedMention = -1;
  public filteredMentions = [];
  public mentions$: Observable<User[]>;
  public checkSize$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public subs = Subscription.EMPTY;
  public title: string;

  get postHotKeys(): HotKey[] {
    return EnterHotkey;
  }

  mouseWheelEventName = mouseWheelEventName;

  constructor(
    private _store: Store<AppState>,
    private _ngZone: NgZone,
    private _fb: FormBuilder,
    private _taskUnsavedDataService: TaskUnsavedDataService
  ) {}

  ngOnChanges() {
    this.initTitle();

    this.inlineForm = this._fb.group({
      title: [
        this.title,
        Validators.compose([Validators.required, PatternValidator.pattern(/[^\s]/), Validators.maxLength(1000)])
      ],
      column: [this.column.id, Validators.required],
      swimlane: [this.swimlaneId ? this.swimlaneId : 0],
      inSwimlanePosition: [this.isTaskLastInSwimlane ? 'last' : 'first']
    });

    this.inlineForm.valueChanges
      .subscribe(value =>
        this._taskUnsavedDataService.setUnsavedCreateNewTask(this.column.id, this.swimlaneId, value.title)
      )
      .add(this.subs);

    this.mentions$ = <Observable<User[]>>this._store.pipe(getBoardUsers);
  }

  scrollToEditForm() {
    let taskEl = this.elementRef.nativeElement;
    let wrapEl = document.getElementById('columns_wrapper');
    if (taskEl && wrapEl) {
      const taskRect = taskEl.getBoundingClientRect();
      const wrapRect = wrapEl.getBoundingClientRect();
      if (taskRect.top < wrapRect.top) {
        wrapEl.scrollTop = wrapEl.scrollTop - (wrapRect.top - taskRect.top) - 30;
      }
      if (taskRect.bottom > wrapRect.bottom) {
        wrapEl.scrollTop = wrapEl.scrollTop - (wrapRect.bottom - taskRect.bottom) + 20;
      }
      if (taskRect.right > wrapRect.right) {
        wrapEl.scrollLeft = wrapEl.scrollLeft - (wrapRect.right - taskRect.right) + 20;
      }
      if (taskRect.left < wrapRect.left) {
        wrapEl.scrollLeft = wrapEl.scrollLeft - (wrapRect.left - taskRect.left);
      }
    }
  }

  initTitle() {
    const unsavedNewtask = this._taskUnsavedDataService.unsavedCreateNewTasks.getValue()[
      this.column.id + '-' + this.swimlaneId
    ];

    this.title = unsavedNewtask ? unsavedNewtask : '';
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onEditToggle() {
    this.isFormContainerVisible = !this.isFormContainerVisible;
    if (this.isFormContainerVisible) {
      this.setInputFocus();
      setTimeout(() => this.scrollToEditForm());
    }
  }

  onCancelNewTask() {
    if (this.selectedMention === -1) {
      this.inlineForm.get('title').patchValue('');
      this.saveUnsaveData();
      this.isFormContainerVisible = false;
    }
  }

  onClickOut() {
    setTimeout(() => {
      this.saveUnsaveData();
      this.isFormContainerVisible = false;
    });
  }

  onAddButtonKeyDown(event: KeyboardEvent, selectedMention: number) {
    if (event.keyCode === KeyCode.KEY_ENTER || event.keyCode === KeyCode.KEY_SPACE) {
      event.preventDefault();
      this.onSaveChanges(selectedMention);
    }
  }

  onCancelButtonKeyDown(event: KeyboardEvent) {
    if (event.keyCode === KeyCode.KEY_ENTER || event.keyCode === KeyCode.KEY_SPACE) {
      event.preventDefault();
      this.onCancelNewTask();
    }
  }

  saveUnsaveData() {
    this._taskUnsavedDataService.setUnsavedCreateNewTask(this.column.id, this.swimlaneId, this.inlineForm.value.title);
    this._taskUnsavedDataService.saveUnsavedNewTask();
    this.initTitle();
  }

  setInputFocus() {
    this._ngZone.runOutsideAngular(() => {
      setTimeout(() => this.elementRef.nativeElement.focus());
    });
  }

  onSelectedMention(selectedMention: number) {
    this.selectedMention = selectedMention;
  }

  onFilteredMentions(mentions: User[]) {
    this.filteredMentions = mentions;
  }

  onPatchValue({ newValue, cursorPosition }) {
    this.inlineForm.get('title').patchValue(newValue);
    setTimeout(() => {
      this.checkSize$.next(true);
      this.elementRef.nativeElement.focus();
      this.elementRef.nativeElement.setSelectionRange(cursorPosition, cursorPosition);
    }, 300);
  }

  onSaveChanges(selectedMention: number) {
    if (this.selectedMention === -1) {
      const newValue = this.inlineForm.value;
      newValue['board'] = this.column.board;
      this.addTaskEvent.emit(newValue);
      this.inlineForm.get('title').reset();
      this.setInputFocus();
      this.checkSize$.next(true);
    } else {
      this.onSelectedMention(selectedMention);
    }
  }

  onAddMention(user: User) {
    this.fireSelectEvent$.next(user);
  }

  onOpenMentionsPopup() {
    document.querySelector('board-swimlane[swimlane="' + this.swimlaneId + '"] .columns_swimlane_container')[
      'style'
    ].overflow =
      'unset';
  }
  onCloseMentionsPopup() {
    document.querySelector('board-swimlane[swimlane="' + this.swimlaneId + '"] .columns_swimlane_container')[
      'style'
    ].overflow =
      'hidden';
    this.selectedMention = -1;
  }
}
