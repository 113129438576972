<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <form-errors></form-errors>
  <div class="row title_row">
    <div class="task_status_indicator" [class.active]="task && task.doneDate">
      <svg width="14" height="10">
        <use xlink:href="#check"></use>
      </svg>
    </div>
    <textarea
      autosize
      (keydown.enter)="onEnter($event)"
      [focus]="true"
      [formControl]="form.get('title')"
      [selectionRange]="{
            start: form.get('title').value?.length,
            end: form.get('title').value?.length
          }"
      placeholder="Write a task name"
      checkTouch
      rows="1"
      class="title_input"
    ></textarea>
    <help-block
      [control]="form.get('title')"
    >
    </help-block>
  </div>
  <div class="row grid_fields">
    <div class="label grid_item">Members</div>
    <div class="grid_item members">
      <atlaz-members
        id="members"
        (addUserToBoard)="onAddUserToBoard($event)"
        (updateUserSelection)="onUpdateUserSelection($event)"
        [isPublicBoard]="isPublicBoard$ | async"
        [possibleUsers]="taskPossibleUsers$ | async"
        [selectedUsers]="selectedUsers"
        [projectOnlyUsers]="projectsOnlyUsers$ | async"
        [editPermissions]="true"
        [hideHeader]="true"
        [isSmallItems]="true"
      ></atlaz-members>
    </div>
    <label class="grid_item" for="due-date" [class.focused]="focusedId === 'due-date'">Due Date</label>
    <a-datetime-picker class="grid_item"
                       id="due-date"
                       name="dueDate"
                       [isClearPopUpBtnEnabled]="true"
                       [formControlName]="'dueDate'"
                       [isListBoardStyle]="true"
                       [showPlaceholder]="true"
                       [placeholder]="'Select a date…'"
    ></a-datetime-picker>
    <label class="grid_item" for="description" [class.focused]="focusedId === 'description'">Description</label>
    <textarea
      id="description"
      class="grid_item title_input"
      placeholder="Add extra details…"
      autosize
      [formControl]="form.get('description')"
      [selectionRange]="{
            start: form.get('description').value?.length,
            end: form.get('description').value?.length
          }"
      checkTouch
      rows="1"
      (focus)="onFocus($event)"
      (blur)="onBlur()"
    ></textarea>
  </div>
  <div class="buttons_row">
    <button a-button class="btn_primary" [pending]="isPending$ | async">{{task ? 'Save' : 'Add Task'}}</button>
    <button a-button class="btn_text" (click)="onClose()">Cancel</button>
  </div>
</form>
