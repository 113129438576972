
import {takeUntil, filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Board } from '../../../interfaces/';

import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state/';
import { getBoardById } from '../../../ngrx/reducers/board.reducer';
import { isPresent } from '../../../../helpers/';
import { compose } from '@ngrx/store';
import { BehaviorSubject ,  Observable } from 'rxjs';

export const boardActivateStop$ = new BehaviorSubject(false);

@Injectable()
export class CanActiveBoard implements CanActivate {
  private board$: Observable<Board>;

  constructor(private _titleService: Title, private _store: Store<AppState>) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const boardId = route.params['id'];

    boardActivateStop$.next(false);

    if (boardId) {
      const tabTitle = board => board.name + ' | Hygger';
      const setTitle = titleService => title => titleService.setTitle(title);

      this._store.pipe(
        (getBoardById(boardId)),
        filter(isPresent),
        takeUntil(boardActivateStop$.pipe(filter(isPresent))),)
        .subscribe(compose(setTitle(this._titleService), tabTitle));
    }

    return true;
  }
}
