import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { SprintBoardModule } from './sprint-board/sprint-board.module';
import { PushAllTasksPopupComponent } from './backlog-board/push-all-tasks-popup/push-all-tasks-popup.component';
import { PushAllTasksExistBoardFormComponent } from './backlog-board/push-all-tasks-popup/push-all-tasks-exist-board-form/push-all-tasks-exist-board-form.component';
import { BoardDetailPageComponent } from './board-detail-page/board-detail-page.component';
import { BoardDetailsComponent } from './board-details/board-details.component';
import { AppContainersModule } from '../containers/containers.module';
import { BoardLabelComponent } from './board-label.component';
import { BoardRenameComponent } from './board-rename/board-rename.component';
import { SprintBoardControlsComponent } from '../containers/header/board-header-controls/sprint-board-controls/sprint-board-controls.component';
import { BoardSubHeaderComponent } from './board-detail-page/board-sub-header/board-sub-header.component';
import { ColumnListComponent } from '../columns/column-list/column-list.component';
import { ColumnHeaderComponent } from '../columns/column-header/column-header.component';
import { ColumnEditFormComponent } from '../columns/column-edit-form/column-edit-form.component';
import { MassTasksActionComponent } from '../columns/mass-tasks-actions-form/mass-tasks-actions-form.component';
import { WipLimitsComponent } from '../columns/wip-limits/wip-limits.component';
import { ColumnCapacityIndicatorComponent } from '../columns/column-capacity/column-capacity-indicator/column-capacity-indicator.component';
import { BoardSwimlaneComponent } from '../columns/board-swimlanes/board-swimlane.component';
import { ColumnDetailComponent } from '../columns/column-detail/column-detail.component';
import { ColumnTaskListComponent } from '../columns/board-task-list/column-task-list.component';
import { AddNewTaskComponent } from '../shared/components/inline-edit/add-new-task/add-new-task.component';
import { SprintBoardDetailsComponent } from './sprint-board/sprint-board-details/sprint-board-details.component';
import { BacklogChartModule } from './backlog-chart/backlog-chart.module';
import { RightMenuModule } from '../right-menu/right-menu.module';
import { SwimlaneRenameComponent } from './swimlane-rename/swimlane-rename.component';
import { ColumnWipEditFormComponent } from '../columns/column-wip-edit-form/column-wip-edit-form.component';
import { CustomScrollsModule } from '../custom-scrolls/custom-scrolls.module';
import { ADragModule } from '../shared/a-drag/a-drag.module';
import { AtlazGuiModule } from '../atlaz-gui/atlaz-gui.module';
import { ColumnCapacityFormComponent } from '../columns/column-capacity/column-capacity-form/column-capacity-form.component';
import { CloseBoardPageComponent } from './board-detail-page/close-board-page.component';
import { RoadmapBoardModule } from './roadmap-board/roadmap-board.module';
import { BoardFavoriteStarComponent } from './board-favorite-star/board-favorite-star.component';
import { ClosedBoardPopupComponent } from './closed-board-popup/closed-board-popup.component';
import { CopyColumnFormComponent } from '../columns/copy-column-form/copy-column-form.component';
import { SwimlaneListMultiselectComponent } from '../columns/mass-tasks-actions-form/swimlane-list-multiselect/swimlane-list-multiselect.component';
import { ColumnsWidthHelperComponent } from '../columns/column-list/columns-width-helper/columns-width-helper.component';
import { ColumnTasksCounterComponent } from '../columns/column-header/column-tasks-counter/column-tasks-counter.component';
import { SwimlaneTasksCounterComponent } from '../columns/board-swimlanes/swimlane-tasks-counter/swimlane-tasks-counter.component';
import { QuickFiltersModule } from '../containers/header/quick-filters/quick-filters.module';
import { AddColumnOrSwimlaneComponent } from '../columns/add-column-or-swimlane/add-column-or-swimlane.component';
import { QuickTaskEditComponent } from './board-detail-page/quick-task-edit/quick-task-edit.component';
import { BacklogCriteriaPopUpComponent } from '../right-menu/right-menu-advanced-scoring/backlog-criteria-pop-up/backlog-criteria-pop-up.component';
import { BacklogScoringModule } from './backlog-scoring/backlog-scoring.module';
import { BoardTableViewComponent } from './board-table-view/board-table-view.component';
import { LabelsCellComponent } from './board-table-view/labels-cell/labels-cell.component';
import { MembersCellComponent } from './board-table-view/members-cell/members-cell.component';
import { BoardVersionsViewComponent } from './board-versions-view/board-versions-view.component';
import { VersionTasksCounterComponent } from './board-versions-view/version-tasks-counter/version-tasks-counter.component';
import { VersionContextMenuComponent } from './board-versions-view/version-context-menu/version-context-menu.component';
import { NewTaskInlineFormComponent } from './board-table-view/new-task-inline-form/new-task-inline-form.component';
import { BoardPdfExportComponent } from './board-pdf-export/board-pdf-export.component';
import { ListBoardModule } from './list-board/list-board.module';
import { BatchActionsComponent } from './board-detail-page/batch-actions/batch-actions.component';

const sharedComponents = [
  PushAllTasksExistBoardFormComponent,
  BacklogCriteriaPopUpComponent,
  PushAllTasksPopupComponent,
  BoardDetailPageComponent,
  BoardDetailsComponent,
  BoardLabelComponent,
  BoardRenameComponent,
  SprintBoardControlsComponent,
  BoardSubHeaderComponent,
  ColumnListComponent,
  ColumnHeaderComponent,
  ColumnTasksCounterComponent,
  SwimlaneTasksCounterComponent,
  ColumnEditFormComponent,
  MassTasksActionComponent,
  SwimlaneListMultiselectComponent,
  WipLimitsComponent,
  ColumnCapacityIndicatorComponent,
  BoardSwimlaneComponent,
  ColumnDetailComponent,
  ColumnTaskListComponent,
  AddNewTaskComponent,
  SprintBoardDetailsComponent,
  SwimlaneRenameComponent,
  ColumnWipEditFormComponent,
  CloseBoardPageComponent,
  ColumnCapacityFormComponent,
  BoardFavoriteStarComponent,
  ClosedBoardPopupComponent,
  ColumnsWidthHelperComponent,
  CopyColumnFormComponent,
  AddColumnOrSwimlaneComponent,
  BoardTableViewComponent,
  LabelsCellComponent,
  MembersCellComponent,
  BoardPdfExportComponent
];

@NgModule({
  imports: [
    CommonModule,
    AtlazGuiModule,
    SharedModule,
    ADragModule,
    AppContainersModule,
    SprintBoardModule,
    RoadmapBoardModule,
    ListBoardModule,
    BacklogChartModule,
    BacklogScoringModule,
    RightMenuModule,
    QuickFiltersModule,
    CustomScrollsModule
  ],
  declarations: [
    ...sharedComponents,
    QuickTaskEditComponent,
    BoardVersionsViewComponent,
    VersionTasksCounterComponent,
    VersionContextMenuComponent,
    NewTaskInlineFormComponent,
    BatchActionsComponent
  ],
  exports: [...sharedComponents, SprintBoardModule]
})
export class BoardModule {}
