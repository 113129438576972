import { distinctUntilChanged, map, pluck } from 'rxjs/operators';
import { Action } from '../actions/unsafe-action';
import {
  AppState,
  CHECKLIST_HIDE_COMPLETED,
  COLLAPSED_GROUPS,
  GUI_STATE_MEMORIZED,
  HIDDEN_SWIMLANES,
  HOTKEYS_TARGET_TASK,
  QUICK_TASK_EDIT,
  BOARDS_VIEW_MODE,
  SPRINT_PROGRESS_TYPE,
  HIDDEN_VERSIONS,
  ROADMAP_ZOOM_MODE,
  BOARDS_GROUPING_PROP,
  BATCH_EDIT
} from '../state';
import { GuiStateMemorizedActionTypes } from '../actions/gui-state-memorized.actions';
import { runReducer, share } from '../functions/util';
import { Observable } from 'rxjs';
import { isObject } from 'util';
import { progressTypes, progressTypesArray } from '../../board/sprint-board/sprint-percent-indicator/percent-types';
import { createSelector } from 'reselect';
import { RoadMapScale } from '../../board/roadmap-board/constants/roadmap.constants';

export interface State {
  [componentName: string]: any | { [id: number]: boolean };
}

export enum BoardViewMode {
  board = '',
  table = 'table',
  version = 'version',
  priorityChart = 'priorityChart'
}
export const BoardViewModeTitle = {
  [BoardViewMode.board]: 'Board',
  [BoardViewMode.table]: 'Table',
  [BoardViewMode.version]: 'By version',
  [BoardViewMode.priorityChart]: 'Priority Matrix'
};

const initialState = {
  [COLLAPSED_GROUPS]: {},
  [HIDDEN_SWIMLANES]: {},
  [HIDDEN_VERSIONS]: {},
  [CHECKLIST_HIDE_COMPLETED]: {},
  [SPRINT_PROGRESS_TYPE]: progressTypesArray[0],
  [BOARDS_VIEW_MODE]: {},
  [BOARDS_GROUPING_PROP]: {},
  [ROADMAP_ZOOM_MODE]: {},
  [QUICK_TASK_EDIT]: null,
  [HOTKEYS_TARGET_TASK]: null,
  isBoardViewSelectorOpened: false
};

const pureToggleFunction = key => (state, payload: number) => {
  const newState = Object.assign({}, state);
  newState[key] = Object.assign({}, state[key] ? state[key] : {});
  newState[key][payload] = !newState[key][payload];

  return newState;
};

const reducerAction = {
  [GuiStateMemorizedActionTypes.INIT]: (state, payload) => payload,
  [GuiStateMemorizedActionTypes.TOGGLE_GROUP_VISIBILITY]: pureToggleFunction(COLLAPSED_GROUPS),
  [GuiStateMemorizedActionTypes.TOGGLE_SWIMLANE_VISIBILITY]: pureToggleFunction(HIDDEN_SWIMLANES),
  [GuiStateMemorizedActionTypes.TOGGLE_VERSION_VISIBILITY]: pureToggleFunction(HIDDEN_VERSIONS),
  [GuiStateMemorizedActionTypes.CHANGE_EXPANDED_SWIMLANE_STATE]: (state, payload: { id: number; flag: boolean }) => {
    const newState = Object.assign({}, state);
    newState[HIDDEN_SWIMLANES] = Object.assign({}, state[HIDDEN_SWIMLANES] ? state[HIDDEN_SWIMLANES] : {});
    newState[HIDDEN_SWIMLANES][payload.id] = payload.flag;

    return newState;
  },
  [GuiStateMemorizedActionTypes.TOGGLE_HIDE_COMPLETED]: (state, payload: number) => {
    const newState = Object.assign({}, state);
    newState[CHECKLIST_HIDE_COMPLETED] = Object.assign(
      {},
      state[CHECKLIST_HIDE_COMPLETED] ? state[CHECKLIST_HIDE_COMPLETED] : {}
    );
    newState[CHECKLIST_HIDE_COMPLETED][payload] = !newState[CHECKLIST_HIDE_COMPLETED][payload];
    return newState;
  },
  [GuiStateMemorizedActionTypes.STORE_SPRINT_PROGRESS_TYPE]: (state, payload: string) =>
    Object.assign({}, state, {
      [SPRINT_PROGRESS_TYPE]: payload
    }),
  [GuiStateMemorizedActionTypes.QUICK_TASK_EDIT_SHOW]: (
    state,
    payload: { id: number; rect: ClientRect; activate?: string; isExactRect: boolean }
  ) => Object.assign({}, state, { [QUICK_TASK_EDIT]: payload }),
  [GuiStateMemorizedActionTypes.QUICK_TASK_EDIT_HIDE]: state => Object.assign({}, state, { [QUICK_TASK_EDIT]: null }),
  [GuiStateMemorizedActionTypes.BATCH_EDIT_SHOW]: (state, payload: { ids: number[]; top: number; left: number }) =>
    Object.assign({}, state, { [BATCH_EDIT]: payload }),
  [GuiStateMemorizedActionTypes.BATCH_EDIT_HIDE]: state => Object.assign({}, state, { [BATCH_EDIT]: null }),
  [GuiStateMemorizedActionTypes.SET_HOTKEYS_TARGET_TASK]: (state, payload: number | null) =>
    Object.assign({}, state, { [HOTKEYS_TARGET_TASK]: payload }),
  [GuiStateMemorizedActionTypes.IS_OPEN_BOARD_TYPE_SELECTOR]: (state, payload: boolean) => ({
    ...state,
    isBoardViewSelectorOpened: payload
  }),
  [GuiStateMemorizedActionTypes.SET_BOARD_VIEW_MODE]: (state, payload: { id: number; mode: string }) => {
    const newState = Object.assign({}, state);
    newState[BOARDS_VIEW_MODE] = Object.assign({}, state[BOARDS_VIEW_MODE] ? state[BOARDS_VIEW_MODE] : {});
    newState[BOARDS_VIEW_MODE][payload.id] = payload.mode || undefined;
    return newState;
  },
  [GuiStateMemorizedActionTypes.SET_BOARD_GROUPING]: (state, payload: { id: number; mode: string | number }) => {
    const newState = Object.assign({}, state);
    newState[BOARDS_GROUPING_PROP] = Object.assign({}, state[BOARDS_GROUPING_PROP] ? state[BOARDS_GROUPING_PROP] : {});
    newState[BOARDS_GROUPING_PROP][payload.id] = payload.mode || undefined;
    return newState;
  },
  [GuiStateMemorizedActionTypes.SET_ROADMAP_ZOOM_MODE]: (state, payload: { id: number; scale: RoadMapScale }) => {
    const newState = Object.assign({}, state);
    newState[ROADMAP_ZOOM_MODE] = Object.assign({}, state[ROADMAP_ZOOM_MODE] ? state[ROADMAP_ZOOM_MODE] : {});
    newState[ROADMAP_ZOOM_MODE][payload.id] = payload.scale || RoadMapScale.Day;
    return newState;
  }
};

export function reducer(state = initialState, action: Action): State {
  return runReducer(reducerAction, state, action);
}

export const getHiddenSwimlanesState = share((state$: Observable<AppState>) => {
  return state$.pipe(
    pluck(GUI_STATE_MEMORIZED, HIDDEN_SWIMLANES),
    distinctUntilChanged(),
    map(value => (isObject(value) ? value : {}))
  );
});

export const getChecklistHideCompletedState = share((state$: Observable<AppState>) => {
  return state$.pipe(
    pluck(GUI_STATE_MEMORIZED, CHECKLIST_HIDE_COMPLETED),
    distinctUntilChanged(),
    map(value => (isObject(value) ? value : {}))
  );
});

export const getSprintProgressType = share((state$: Observable<AppState>) => {
  return state$.pipe(
    pluck(GUI_STATE_MEMORIZED, SPRINT_PROGRESS_TYPE),
    distinctUntilChanged(),
    map((value: progressTypes) => {
      return progressTypesArray.includes(value) ? value : progressTypesArray[0];
    })
  );
});

export namespace fromGuiState {
  export const getState = (store: AppState) => store[GUI_STATE_MEMORIZED];

  export const getQuickEditTaskParams = createSelector(getState, state => state[QUICK_TASK_EDIT]);
  export const getBatchEditParams = createSelector(getState, state => state[BATCH_EDIT]);
  export const getHotkeysTargetTask = createSelector(getState, state => state[HOTKEYS_TARGET_TASK]);
  export const getHiddenSwimlanes = createSelector(getState, state => state[HIDDEN_SWIMLANES] || {});
  export const getHiddenVersions = createSelector(getState, state => state[HIDDEN_VERSIONS] || {});
  export const getBoardViewMode = id => createSelector(getState, state => (state[BOARDS_VIEW_MODE] || {})[id] || '');
  export const getBoardGrouping = id =>
    createSelector(getState, state => (state[BOARDS_GROUPING_PROP] || {})[id] || 'swimlane');
  export const getRoadMapZoomMode = id =>
    createSelector(getState, state => (state[ROADMAP_ZOOM_MODE] || {})[id] || RoadMapScale.Day);
  export const getIsBoardViewSelectorOpened = createSelector(getState, state => state['isBoardViewSelectorOpened']);
}
