<form [formGroup]="logWorkForm" class="logwork_form">

  <div class="row">
    <div class="block">
      <label class="label">Date</label>
      <a-datetime-picker formControlName="logDate" name="logDate"></a-datetime-picker>
    </div>
    <div class="block">
      <label for="logWorkFormTime">Time</label>
      <input formControlName="time"
             [focus]="true"
             autocomplete="off"
             checkTouch
             tabindex="2"
             id="logWorkFormTime"
             placeholder="e.g. 1:30"
      >
    </div>
  </div>

  <label for="commentArea">Add comment</label>
  <textarea autosize focus formControlName="comment"
            tabindex="3"
            [hotKeys]="postHotKeys"
            (hotKey)="onSubmit()"
            #comment id="commentArea" cols="30" rows="1"
            placeholder="Write your comment..."
  ></textarea>
  <button a-button class="btn_primary" tabindex="0"
          [pending]="(_formService.isPending$ | async)"
          (click)="onSubmit()">
    {{worklog ? 'Save Changes' : 'Log Time'}}
  </button>
</form>
