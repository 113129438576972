<label for="sort_select">Sort:</label>
<select #selectRef id="sort_select" [value]="sortParam$ | async">
  <option value="">Most Relevant</option>
  <option value="createdAt">Created</option>
  <option value="updatedAt">Updated</option>
  <option value="status">Status</option>
  <option value="dueDate">Due Date</option>
  <option value="versionName">Version</option>
</select>
<div class="sort_control" #orderButtonRef
     [ngClass]="{
            asc: (sortOrder$ | async) === 'asc',
            desc: (sortOrder$ | async) === 'desc',
            disabled: (sortParam$ | async)===''
    }"
>
  <svg width="15" height="15" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
      <path id="arrow_up" fill-rule="nonzero" d="M10 3.99V15h2V3.99h3L11 0 7 3.99"/>
      <path id="arrow_down" d="M8 11.01H5V0H3v11.01H0L4 15"/>
    </g>
  </svg>
</div>
