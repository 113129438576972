import { interval as observableInterval, fromEvent as observableFromEvent, Observable, Subscription } from 'rxjs';

import { takeUntil, filter, map, withLatestFrom, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ScrollDirection } from '../constants/roadmap.constants';
import { AppState } from '../../../ngrx/state/';
import { Store } from '@ngrx/store';
import { fromRoadmapBoard } from '../store/roadmap-board.reducer';
import { animationFrame } from 'rxjs/scheduler/animationFrame';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

// px
const minDistance = 90;
const distanceSep = 30;
// px per frame
const speedStep = 6;

const calcSpeed = distance => Math.ceil(distance / distanceSep) * speedStep;

@Injectable()
export class RoadmapScrollService {
  private containers: HTMLElement[] = [];
  private timeSheetContainer: HTMLDivElement;
  public scrollWasSetToToday$ = new BehaviorSubject(false);

  private sub: Subscription;

  constructor(private _store: Store<AppState>) {}

  public scrollTimeSheet(deltaX, deltaY) {
    this.timeSheetContainer.scrollTop += deltaY;
    this.timeSheetContainer.scrollLeft += deltaX;
  }

  public registerTimeSheetContainer(container) {
    this.timeSheetContainer = container;
    this.containers = this.containers.filter(x => x !== container);
    this.sub = observableFromEvent(container, 'scroll').subscribe(() => this.syncScrollPosition());
  }

  public registerContainer(container) {
    this.containers.push(container);
  }

  public unRegisterContainer(container) {
    this.containers = this.containers.filter(x => container);
    if (container === this.timeSheetContainer && this.sub) {
      this.sub.unsubscribe();
    }
  }

  public syncScrollPosition() {
    this.containers.forEach(client => {
      client.scrollLeft = this.timeSheetContainer.scrollLeft;
      client.scrollTop = this.timeSheetContainer.scrollTop;
      client.style.right = '0px';
      if (client.scrollLeft !== this.timeSheetContainer.scrollLeft) {
        client.style.right = this.timeSheetContainer.scrollLeft - client.scrollLeft + 'px';
      }
    });
  }

  public scrollTimeSheetToToday() {
    this._store
      .select(fromRoadmapBoard.todayOffset)
      .pipe(take(1))
      .subscribe(todayOffset => {
        this.timeSheetContainer.scrollLeft = todayOffset - this.timeSheetContainer.clientWidth / 2;
        this.scrollWasSetToToday$.next(true);
      });
  }

  public scrollTimeSheetToBottom() {
    this.timeSheetContainer.scrollTop = this.timeSheetContainer.scrollHeight;
  }

  public starScrollOnMove(direction: ScrollDirection, mouseMoveStream$?: Observable<MouseEvent>) {
    const rect = this.timeSheetContainer.getBoundingClientRect();

    const getSpeed =
      direction === ScrollDirection.horizontal
        ? (event: MouseEvent) => {
            if (event.clientX < rect.left + minDistance) {
              return -calcSpeed(rect.left + minDistance - event.clientX);
            }
            if (event.clientX > rect.right - minDistance) {
              return calcSpeed(event.clientX - rect.right + minDistance);
            }
            return false;
          }
        : (event: MouseEvent) => {
            if (event.clientY < rect.top + minDistance) {
              return -calcSpeed(rect.top + minDistance - event.clientY);
            }
            if (event.clientY > rect.bottom - minDistance) {
              return calcSpeed(event.clientY - rect.bottom + minDistance);
            }
            return false;
          };

    mouseMoveStream$ = mouseMoveStream$ || observableFromEvent<MouseEvent>(document, 'mousemove');

    observableInterval(1, animationFrame)
      .pipe(
        withLatestFrom(mouseMoveStream$, (_, e) => e),
        map(getSpeed),
        filter(speed => speed !== false),
        takeUntil(observableFromEvent(document, 'mouseup'))
      )
      .subscribe(
        (speed: number) =>
          direction === ScrollDirection.horizontal
            ? (this.timeSheetContainer.scrollLeft += speed)
            : (this.timeSheetContainer.scrollTop += speed)
      );
  }
}
