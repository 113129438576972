import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class HttpRequestErrorsService {
  public lastError$ = new BehaviorSubject(null);
  public clearErrors$ = new BehaviorSubject(null);

  constructor() {}

  notifyUser(response) {
    this.lastError$.next(response);
  }

  denotifyUser() {
    this.clearErrors$.next(true);
  }
}
