<div *ngIf="!createVersion" a-context-popup
     class="duedate_popup"
     [title]="'Add Version'"
     (close)="onCloseVersionPopup(0)"
>
  <sidebar-version-list
    [selected]="task.version"
    [versions]="versions"
    (selectVersion)="onSelect($event)"
  ></sidebar-version-list>
  <div class="create_wrapper">
    <button a-button class="btn_primary" (click)="onToggleCreateVersion()">+ Create new version</button>
  </div>
</div>
<div *ngIf="createVersion" a-context-popup
     class="duedate_popup"
     [title]="'Create New Version'"
     (close)="onCloseVersionPopup(0)"
>
  <add-project-version-form [project]="task.project" [possibleProjects]="possibleProjects" (close)="onCloseVersionPopup($event)"></add-project-version-form>
</div>
