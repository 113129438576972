<div class="filter_container">
  <h3>{{filterName}}</h3>
  <div *ngFor="let item of selectedItems$ | async">
    <div class="item_tag">
      {{item[fieldForTag]}}
      <span class="close_filter_tag" (click)="onDeleteFromFilter(item)"></span>
    </div>
  </div>
  <div class="select_btn" (click)="onOpenDropdown()"></div>
  <div class="filter_popup" *ngIf="isDropdownVisible"
       [clickOut]="true"
       (clickOutEvent)="onCloseDropdown()"
  >
    <div class="close_popup" (click)="onCloseDropdown()"></div>
    <div class="title">{{filterName}}</div>
    <div class="divider"></div>
    <input #input_search_projects (keyup)="0" [focus]="true"
           placeholder="Search Projects...">
    <div class="items_block">
      <div class="single_item"
           *ngFor="let item of items | searchByField: fieldForDropdown: input_search_projects.value"
           (click)="onSelectItem(item)">
        <p>
          {{item[fieldForDropdown]}}
        </p>
        <span *ngIf="selectedIdsMap[item.id]" class="select_check"> </span>
      </div>
    </div>
  </div>
</div>
