<div class="header">
  <div class="back_block">
    <div class="back_arrow" [routerLink]="boardUrl$ | async"></div>
    <div class="board_name">{{(board$ | async)?.name}}</div>
  </div>
  <div class="title">Released Sprints</div>
</div>
<div class="content">
  <div class="filters">
    <input class="filter" placeholder="Find Sprint…" (input)="onChange($event.target.value)">
  </div>

  <div class="sprints_table">
    <div class="table_header">
      <div class="table_header_inner" [style.padding-right.px]="scrollbarWidth">
        <div class="cell"><span>Sprint</span></div>
        <div class="cell"><span>Start Date</span></div>
        <div class="cell"><span>Release Date</span></div>
        <div class="cell"><span>Duration, days</span></div>
        <div class="cell"><span>Story Points Completed</span></div>
        <div class="cell"><span>Hours Completed</span></div>
      </div>
    </div>
    <div class="table_body">
      <a class="table_row"
         *ngFor="let version of versions$ | async"
         [routerLink]="appUrls.getUrlReleaseReport(version.id)"
         target="_blank"
         rel="noopener"
      >
        <div class="cell"><span>{{version.name}}</span></div>
        <div class="cell"><span>{{version.startDate | toDate }}</span></div>
        <div class="cell"><span>{{version.releasedDate | toDate }}</span></div>
        <div class="cell"><span>{{version['workingDaysDuration']}}</span></div>
        <div class="cell"><span>{{version.storyPointsCompleted}}</span></div>
        <div class="cell"><span>{{version.hoursCompleted}}</span></div>
        <div class="action-button">
          <div a-context-controll
               orientation="vertical"
               class="content_menu"
               [menuSlim]="true"
               [menuRight]="true"
               [menuTop]="true"
               (click)="onToggleContextMenu($event, version.id)"
               [isMenuVisible]="openedMenuId === version.id"
          >
            <a a-context-menu-item
               [routerLink]="appUrls.getUrlReleaseReport(version.id)"
               target="_blank"
               rel="noopener"
            >Release Report</a>
          </div>
        </div>
      </a>
      <div class="empty_state" *ngIf="noMatch$ | async">
        There's no sprints matched your request.
      </div>
      <div class="empty_state" *ngIf="empty$ | async">
        There's no released sprints yet.
      </div>
      <a-infinite-loader
        *ngIf="(showLoader$ | async)"
        [pending]="_paginatorService.pending$ | async"
        (loadMore)="onLoadMore()"
      >
      </a-infinite-loader>
      <a-infinite-loader
        *ngIf="(_paginatorService.pending$ | async)"
        [pending]="true"
      >
      </a-infinite-loader>
    </div>

  </div>


</div>
