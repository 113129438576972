<h3>DROP-DOWN OPTIONS</h3>
<ul class="chosen_items" [class.white]="isWhite">
  <li class="chosen_item" *ngFor="let item of chosenItems;let i = index"
      [attr.draggable]="editItemIndex !== i ? true : null"
      (dragstart)="onDragStart($event, item, i)"
      (dragover)="onDragOver($event, i)"
      (dragend)="onDragEnd()"
  >
    <div class="item_wrapper" [class.dragOver]="dragOverId === i">
      <span class="drag_btn">
         <svg>
            <use xlink:href="#drag-icon"></use>
         </svg>
      </span>
      <input class="item_value" [focus]="editItemIndex === i" [value]="item" (focus)="setEditTo(i)" (blur)="setEditTo(-1)" (change)="onChangeItem($event, i)">
      <span  class="delete_btn" (click)="onDelete(i)">
      <svg width="12" height="14">
        <use xlink:href="#bucket"></use>
      </svg>
    </span>
    </div>
  </li>
</ul>
<div aDraggableItem  [item]="{item: 'fake', id: chosenItems.length}" [itemType]="'dd-options'">
  <a class="add_task_btn" (click)="onAddEmptyItem()">
  <span class="add_task_btn_icon">
    <svg width="10" height="10">
      <use xlink:href="#add"></use>
    </svg>
  </span>
    Add new option
  </a>
</div>
