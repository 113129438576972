import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isMoment, Moment } from 'moment-mini-ts';
import { DateTimeFormatterService } from '../../libs/date-time-formatter/services/date-time-formatter.service';
import * as moment from 'moment-mini-ts';
import MaterialDateTimePicker from 'material-datetime-picker';
import { BehaviorSubject } from 'rxjs';
import { KeyCode } from '../../constants';
import { CompanyService } from '../../shared/services/app/company.service';
import { Observable } from 'rxjs/Observable';
import { distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs/index';

@Component({
  selector: 'a-datetime-picker',
  templateUrl: './a-datetime-picker.component.html',
  styleUrls: ['./a-datetime-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ADatetimePickerComponent),
      multi: true
    }
  ]
})
export class ADatetimePickerComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() isClearBtnEnabled = false;
  @Input() isClearPopUpBtnEnabled = false;
  @Input() time = true;
  @Input() showPlaceholder = true;
  @Input() defaultDate: number;
  @Input() outerUpdater$: Observable<number>;
  @Input() isListBoardStyle: boolean;
  @Input() placeholder: string;

  @Output() change = new EventEmitter();

  public formattedValue$ = new BehaviorSubject('');
  public disabled = false;
  public outputFormat;

  private _currentValue;
  private picker;
  subs: Subscription[] = [];

  set currentValue(value) {
    if (value && (isMoment(value) ? value.unix() : true)) {
      this._currentValue = (isMoment(value) ? value : moment(value)).clone();
      this.formattedValue$.next(this._currentValue.format(this.outputFormat));
    } else {
      this._currentValue = undefined;
      this.formattedValue$.next('');
    }
  }

  get currentValue() {
    return this._currentValue ? this._currentValue.clone() : this._currentValue;
  }

  get isClearBtnVisible() {
    return this.isClearBtnEnabled && !this.disabled && this._currentValue;
  }

  private propagateChangeFn = (_: any) => {};
  private propagateTouched = () => {};

  constructor(private _dateTimeFormatter: DateTimeFormatterService, private _company: CompanyService) {}

  public writeValue(value: Moment | undefined | null) {
    this.currentValue = value;
  }

  public registerOnChange(fn: any) {
    this.propagateChangeFn = fn;
  }

  public registerOnTouched(fn: any) {
    this.propagateTouched = fn;
  }

  public setDisabledState(isDisabled: boolean) {
    if (this.disabled !== isDisabled) {
      this.formattedValue$.next(this.formattedValue$.getValue());
    }
    this.disabled = isDisabled;
    if (isDisabled && this.picker) {
      this.picker.close();
    }
  }

  private propagateChange() {
    this.propagateChangeFn(this.currentValue);
    this.change.emit(this.currentValue);
  }

  private clickListener = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
  };

  private keyListener = (event: KeyboardEvent) => {
    if (event.keyCode === KeyCode.KEY_ESCAPE) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      if (this.picker) {
        this.picker.close();
      }
    }
  };

  ngOnInit() {
    this.outputFormat = this.time
      ? this._dateTimeFormatter.INPUT_DATETIME_FORMAT
      : this._dateTimeFormatter.INPUT_DATE_FORMAT;
    if (this.defaultDate) {
      this.currentValue = moment(this.defaultDate * 1000);
    }
    if (this.outerUpdater$) {
      this.subs.push(
        this.outerUpdater$.pipe(distinctUntilChanged()).subscribe(value => (this.currentValue = moment(value * 1000)))
      );
    }
  }

  ngOnDestroy() {
    if (this.picker) {
      this.picker.close();
    }
    this.subs.forEach(sub => sub.unsubscribe());
  }

  onClick() {
    if (this.disabled) {
      return;
    }
    this.propagateTouched();

    const picker = new MaterialDateTimePicker({
      default: this.currentValue,
      twentyFourHours: this._dateTimeFormatter.is24HoursFormat,
      format: this._dateTimeFormatter.DATE_FORMAT,
      weekStart: this._company.weekStartDay
    })
      .on('submit', val => {
        this.currentValue = val;
        this.propagateChange();
      })
      .on('open', () => {
        document.body.addEventListener('mousedown', this.clickListener);
        document.body.addEventListener('keydown', this.keyListener, true);

        if (!this.time) {
          setTimeout(() => {
            const timeEl = document.getElementsByClassName('c-datepicker__header-date__time')[0];
            timeEl.innerHTML = '';
            const showTimeEl = document.getElementsByClassName('c-datepicker--show-time')[0];
            showTimeEl.innerHTML = '';
            showTimeEl.classList.add('no_after');
            const showCalendarEl = document.getElementsByClassName('c-datepicker--show-calendar')[0];
            showCalendarEl.innerHTML = '';
            showCalendarEl.classList.add('no_after');
          });
        }

        if (this.isClearPopUpBtnEnabled) {
          setTimeout(() => {
            const deleteBtn = document.createElement('a');
            deleteBtn.innerHTML = 'Clear';
            deleteBtn.className = 'c-btn c-btn--flat';
            deleteBtn.style.marginRight = '80px';
            deleteBtn.style.color = 'red';
            let parentEl = document.getElementsByClassName('modal-btns')[0];
            parentEl.insertBefore(deleteBtn, parentEl.firstChild);
            deleteBtn.addEventListener('click', this.clearBtnListener);
          });
        }
      })
      .on('close', () => {
        document.body.removeEventListener('mousedown', this.clickListener);
        document.body.removeEventListener('keydown', this.keyListener, true);
        this.picker = undefined;
      });
    picker.open();
    this.picker = picker;

    // show picker;
  }

  private clearBtnListener = (event: MouseEvent) => {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.picker.close();
    this.onClear();
  };

  onClear() {
    this.currentValue = undefined;
    this.propagateChange();
  }
}
