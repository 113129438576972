import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from '../../../ngrx/state';
import { CompanyService } from '../../../shared/services/app/company.service';
import { RouterNavigateService } from '../../../shared/services/router-navigate.service';
import * as gitlabCompany from '../../git/store/gitlab-company/gitlab-company.actions';
import { IntegrationAuthAdapterService } from './integration-auth-adapter.service';

@Injectable()
export class GitlabAuthAdapterService extends IntegrationAuthAdapterService {
  constructor(companyService: CompanyService, private _store: Store<AppState>, private nav: RouterNavigateService) {
    super(companyService);
    this.authUrl = companyService.getGitlabUrl();
    this.popupWindowTitle = 'GitLab Setup';
  }

  protected receiveMessage(event: MessageEvent) {
    // Do we trust the sender of this message?  (might be
    // different from what we originally opened, for example).
    if (super.receiveMessage(event) === false) {
      return false;
    }
    const companyId = super.getCompanyId(event.data);
    if (companyId) {
      this._store.dispatch(new gitlabCompany.Get({ id: companyId }));
      this.nav.navigateToGitlabManageRepos();
      super.closeChild();
    }

    return null;
  }
}
