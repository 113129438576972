import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { isProductionMode } from './environments/helper';
if (isProductionMode()) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
