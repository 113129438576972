import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppState } from '../../../ngrx/state';
import { Store } from '@ngrx/store';
import { getSwimlanesByBoard } from '../../../ngrx/reducers/swimlane.reducer';
import { Observable } from 'rxjs';
import { Column, Swimlane } from '../../../interfaces';
import { getTargetColumns } from '../../../ngrx/reducers/column.reducer';
import { map } from 'rxjs/operators';
import { sortByField } from '../../../../helpers';

@Component({
  selector: 'backlog-add-task-popup',
  templateUrl: './backlog-add-task-popup.component.html',
  styleUrls: ['./backlog-add-task-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BacklogAddTaskPopupComponent implements OnInit {
  @Input() boardId: number;
  @Input() source: 'BacklogChartTaskCreated' | '';
  @Input() backlogScoreX = 0;
  @Input() backlogScoreY = 0;
  @Output() closeAddTask = new EventEmitter();

  public swimlanes$: Observable<Swimlane[]>;
  public columns$: Observable<Column[]>;

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this.swimlanes$ = this._store.pipe(getSwimlanesByBoard(this.boardId), map(sortByField('position', 'asc')));
    this.columns$ = this._store.pipe(getTargetColumns(this.boardId));
  }

  onCloseAddTask() {
    this.closeAddTask.emit();
  }
}
