import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable ,  Subject } from 'rxjs';
import { AppState, runParametricalSelector } from '../../../../../ngrx/state/';
import * as fromIntegration from '../../../../core/store/integration.reducer';
import { GithubCommit } from '../../../models/git-commit';
import * as githubCommit from '../../../store/github-commit/github-commit.actions';
import * as fromGithubCommit from '../../../store/github-commit/github-commit.reducer';
import { IntegrationNames } from '../../../../core/integraition-list';

@Component({
  selector: 'github-task-commit-list',
  templateUrl: './github-task-commit-list.component.html',
  styleUrls: ['./github-task-commit-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GithubTaskCommitComponent implements OnInit, OnDestroy {
  @Input() taskId$: Observable<number>;

  public taskGitCommits$: Observable<GithubCommit[]>;
  public isInstalled$: Observable<boolean> = this._store.select(fromIntegration.getIsInstalledGithub);
  public name = IntegrationNames.Github;

  private destroy$ = new Subject();

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this.taskGitCommits$ = runParametricalSelector(this._store.select(fromGithubCommit.getTaskCommits), this.taskId$);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  onRemove(commit: GithubCommit) {
    this._store.dispatch(new githubCommit.DeleteTaskCommit({ id: commit.id, taskId: commit.task }));
  }
}
