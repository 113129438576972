import { createSelector } from 'reselect';
import { HARVEST_BOARD_PL } from '../../constants';
import { AppState, ESInterface } from '../state';
import { HarvestBoard } from '../../interfaces/harvest-board';

export const getHarvestBoardsState = (state: AppState) => <ESInterface<HarvestBoard>>state[HARVEST_BOARD_PL];

export const getHarvestBoardByBoard = boardId =>
  createSelector(
    getHarvestBoardsState,
    (harvestBoards: ESInterface<HarvestBoard>) =>
      harvestBoards.ids.map(id => harvestBoards.entities[id]).find(item => item.board === boardId) || {}
  );

export const isHarvestInstalledForBoard = boardId =>
  createSelector(getHarvestBoardByBoard(boardId), (harvestBoard: HarvestBoard): boolean => harvestBoard.enabled);
