import {
  distinctUntilChanged,
  filter,
  map,
  pluck,
  publishReplay,
  refCount,
  startWith,
  switchMap,
  take
} from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { combineLatest as observableCombineLatest, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { getProjectsByBoard } from '../ngrx/reducers/board.reducer';

import { Board, Label, PartOfEntity, Project, Task, User, Version } from '../interfaces';
import { BOARD, boardType, ScoringType } from '../constants';
import { AppState } from '../ngrx/state';
import { getAllUsers } from '../ngrx/reducers/user.reducer';

import { RouterNavigateService } from '../shared/services/router-navigate.service';
import { getTaskNotifications } from '../ngrx/reducers/notification.reducer';
import { TaskUnsavedDataService } from './shared/services/task-unsaved-data.service';
import {
  isEqualType,
  isHoursEstimateAvailable,
  isPointsEstimateAvailable,
  isPresent,
  naturalSort
} from '../../helpers';
import { PermissionsService } from '../permissions/permissions.service';
import { fromOpenedTask } from './ngrx/reducers/opened-task.reducer';
import { TaskAssignUsersAction, TaskEditAction } from '../ngrx/actions/task.actions';
import { MarkAsTypes, NotificationMarkAsAction } from '../ngrx/actions/notification.actions';
import { NavigationEnd, Router } from '@angular/router';
import { AppUrls } from '../app-urls';
import { PaywallService } from '../libs/paywall/paywall.service';
import { Features } from '../libs/paywall/features.constants';
import { combineLatest } from 'rxjs/index';
import { AuthService } from '../shared/services/app/auth.service';
import { isHarvestInstalledForBoard } from '../ngrx/reducers/harvest-board.reducer';
import { BoardAssignUsersAction } from '../ngrx/actions/board.actions';
import { of } from 'rxjs/internal/observable/of';
import { taskPopupCodes, TaskSidebarService } from './shared/services/task-sidebar.service';
import { BoardInProjectAccess } from '../interfaces/board';
import { getTaskPossibleUsers, getTaskProjectOnlyUsers } from '../ngrx/functions/crossed.selector';

@Component({
  selector: 'task-expanded',
  templateUrl: './task-expanded.component.html',
  styleUrls: ['./task-expanded.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskExpandedComponent implements OnInit, OnDestroy {
  @ViewChild('task_popup') taskPopupRef: ElementRef;

  public attachments;
  public taskId$: Observable<number>;
  public version$: Observable<Version>;
  public taskLabels$: Observable<Label[]>;
  public isSubscribed$: Observable<boolean>;
  public selectedTask$: Observable<Task>;
  public taskForTimeTracking$: Observable<Task>;
  public taskProject$: Observable<Project>;
  public taskTitle$: Observable<string>;
  public boardUsers$: Observable<User[]>;
  public taskPossibleUsers$: Observable<User[]>;
  public projectOnlyUsers$: Observable<User[]>;
  public boardProjects$: Observable<Project[]>;
  public taskUsers$: Observable<User[]>;
  public isScoringOff$: Observable<boolean>;
  public boardProjectMember$: Observable<boolean>;
  public taskBoard$: Observable<Board>;
  public isShowStoryPointsBlock$: Observable<boolean>;
  public canEditTasksProjects$: Observable<boolean>;
  public isOnNoBoardPage$: Observable<boolean>;
  public isHarvestAvailable$: Observable<boolean>;

  public allUsers$: Observable<User[]>;
  public taskEstimate$: Observable<number>;
  public taskLoggedTime$: Observable<number>;
  public taskProjectNumber$: Observable<string | number>;
  public subs: Subscription[] = [];
  public taskId: number;
  public appUrls = AppUrls;
  public isBitBucketIntegrationEnabled: boolean;
  public isGitHubIntegrationEnabled: boolean;
  public isGitLabIntegrationEnabled: boolean;

  public showPercent$: Observable<boolean>;
  public isPublicBoard$: Observable<boolean>;
  public isNotGuest$ = this._permissions$.isNotGuest$;
  public canEditTask$: Observable<boolean>;

  constructor(
    private _routerNav: RouterNavigateService,
    private _store: Store<AppState>,
    private _taskUnsavedDataService: TaskUnsavedDataService,
    private _taskSidebarService: TaskSidebarService,
    private _permissions$: PermissionsService,
    private _paywall: PaywallService,
    private _router: Router,
    private _authService: AuthService,
    public elementRef: ElementRef
  ) {}

  ngOnInit() {
    this.taskBoard$ = this._store.select(fromOpenedTask.getBoard);
    this.isPublicBoard$ = this.taskBoard$.pipe(map(board => board && board.access === BoardInProjectAccess.public));
    this.isBitBucketIntegrationEnabled = this._paywall.isFeatureEnabled(Features.Bitbucket);
    this.isGitHubIntegrationEnabled = this._paywall.isFeatureEnabled(Features.Github);
    this.isGitLabIntegrationEnabled = this._paywall.isFeatureEnabled(Features.Gitlab);
    this.taskId$ = this._store.select(fromOpenedTask.getId);
    this.taskProject$ = this._store.select(fromOpenedTask.getProject);
    this.selectedTask$ = this._store.select(fromOpenedTask.getTask).pipe(filter(isPresent));
    this.version$ = this._store.select(fromOpenedTask.getVersion).pipe(filter(isPresent));
    this.taskLabels$ = this._store.select(fromOpenedTask.getTaskLabels);
    this.taskUsers$ = this._store.select(fromOpenedTask.getTaskUsers).pipe(map(naturalSort('fullname')));
    this.boardUsers$ = this._store.select(fromOpenedTask.getBoardUsers).pipe(map(naturalSort('fullname')));
    this.isSubscribed$ = combineLatest(
      this._store.select(fromOpenedTask.getSubscribersIds),
      this._authService.activeUserId$
    ).pipe(map(([getSubscribersIds, userId]: [number[], number]) => getSubscribersIds.includes(userId)));

    this.taskPossibleUsers$ = this.taskId$.pipe(switchMap(taskId => this._store.select(getTaskPossibleUsers(taskId))));
    this.projectOnlyUsers$ = this.taskId$.pipe(
      switchMap(taskId => this._store.select(getTaskProjectOnlyUsers(taskId)))
    );
    this.allUsers$ = <Observable<User[]>>this._store.pipe(getAllUsers);
    this.taskForTimeTracking$ = observableCombineLatest(this.selectedTask$, this.taskBoard$).pipe(
      map(([task, board]) => ({
        ...task,
        estimate: isHoursEstimateAvailable(board) ? task.estimate : 0
      }))
    );
    this.isHarvestAvailable$ = this.taskBoard$.pipe(
      filter(isPresent),
      map(board => board.id),
      switchMap(boardId => this._store.select(isHarvestInstalledForBoard(boardId))),
      map(isAvailableForBoard => (isAvailableForBoard ? this._paywall.isFeatureEnabled(Features.Harvest) : false))
    );

    this.isScoringOff$ = this.taskBoard$.pipe(map(board => !board || board.scoringType === ScoringType.off));

    this.boardProjects$ = <Observable<Project[]>>this.selectedTask$.pipe(
      pluck(BOARD),
      distinctUntilChanged(),
      switchMap(boardId => this._store.pipe(getProjectsByBoard(boardId)))
    );

    this.taskEstimate$ = this.selectedTask$.pipe(map(task => (task ? task.estimate : 0)));
    this.taskLoggedTime$ = this.selectedTask$.pipe(map(task => (task ? task.loggedTime : 0)));
    this.taskTitle$ = this.selectedTask$.pipe(map(task => (task ? task.title : '')));
    this.taskProjectNumber$ = this.selectedTask$.pipe(map(task => (task ? task.numberInProject : '')));

    this.isShowStoryPointsBlock$ = observableCombineLatest(this.selectedTask$, this.taskBoard$).pipe(
      map(([task, board]) => task.storyPoints && isPointsEstimateAvailable(board))
    );

    this.archiveNotification();
    this.boardProjectMember$ = this.selectedTask$.pipe(
      filter(isPresent),
      switchMap(task => this._permissions$.boardProjectMember(task.board))
    );
    this.canEditTask$ = combineLatest(this.boardProjectMember$, this.isNotGuest$).pipe(
      map(([v1, v2]) => v1 && v2),
      distinctUntilChanged(),
      publishReplay(1),
      refCount()
    );
    this.canEditTasksProjects$ = this.taskBoard$.pipe(
      map(isEqualType(boardType.sprint)),
      switchMap(isSprint => this.canEditTask$.pipe(map(canEditTask => !isSprint && canEditTask)))
    );
    this.subs.push(this.taskId$.subscribe(id => (this.taskId = id)));

    this.isOnNoBoardPage$ = observableCombineLatest(
      this.selectedTask$,
      this._router.events.pipe(filter(event => event instanceof NavigationEnd), startWith({}))
    ).pipe(
      map(
        ([task]: [Task]) =>
          !this._router.isActive(
            this.appUrls
              .getUrlBoard(task.board)
              .filter(item => item !== '/')
              .join('/'),
            false
          )
      )
    );
    this.showPercent$ = this.taskBoard$.pipe(
      filter(isPresent),
      switchMap(
        board =>
          board.type === boardType.roadmap
            ? of(true)
            : this.selectedTask$.pipe(map(task => task.roadmapBoards && !!task.roadmapBoards.length))
      )
    );
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
    this._taskSidebarService.setState(taskPopupCodes.SHOW_NONE);
  }

  onChangeTaskTitle(taskTitle: number) {
    // if we use take operator we can skip saving subscription and unsubscribe with OnDestroy
    this.taskId$.pipe(take(1)).subscribe(taskId => {
      const data = {
        id: taskId,
        title: taskTitle
      };
      this.onUpdateTask(data);
    });
  }

  onUpdateTask(taskData: PartOfEntity) {
    this.updateTask(taskData);
  }

  onUpdateUserSelection(userListUpdate) {
    this._store.dispatch(new TaskAssignUsersAction({ id: this.taskId, users: userListUpdate.difference }));
  }

  onAddUserToBoard(user: User) {
    this.taskBoard$.pipe(take(1)).subscribe(board => {
      this._store.dispatch(new BoardAssignUsersAction({ id: board.id, users: { add: [user.id] } }));
    });
  }

  public updateTask(taskData: PartOfEntity) {
    this._store.dispatch(new TaskEditAction(taskData));
  }

  public archiveNotification() {
    const sub = this.taskId$
      .pipe(
        switchMap(taskId =>
          this._store.pipe(
            getTaskNotifications(),
            map(notifications => {
              const taskNotifications = notifications[taskId] || [];
              return taskNotifications.filter(notification => !notification.seen).map(notification => notification.id);
            })
          )
        ),
        take(1),
        filter(ids => ids.length > 0)
      )
      .subscribe(notificationIds =>
        this._store.dispatch(
          new NotificationMarkAsAction({ markType: MarkAsTypes.seen, notificationIds: notificationIds })
        )
      );

    this.subs.push(sub);
  }
}
