import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Project } from '../../../../interfaces/project';
import { HotKey } from '../../../directives/hot-key.directive';
import { KeyCode } from '../../../../constants/key-code';

@Component({
  selector: 'inplace-edit',
  templateUrl: './inplace-edit.component.html',
  styleUrls: ['./inplace-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InplaceEditComponent implements OnChanges {
  @Input() submitOnEnter = false;
  @Input() initialValue: string;
  @Input() taskProject: Project;
  @Input() validationMessage: string;
  @Input() displayValue: string;
  @Input() isEditable = false;

  @Output() formSubmit: EventEmitter<string> = new EventEmitter<string>();

  public isEdit = false;

  public isEditContainerVisible = false;

  public inlineForm: FormGroup;

  constructor(private _fb: FormBuilder) {}
  get hotKeys(): HotKey[] | boolean {
    return this.submitOnEnter ? [{ keyCode: KeyCode.KEY_ENTER }] : false;
  }

  get value() {
    return this.displayValue || this.initialValue;
  }

  onHotKey() {
    if (this.submitOnEnter) {
      this.onSaveChanges();
    }
  }

  initForm() {
    this.inlineForm = this._fb.group({
      name: [this.initialValue, Validators.compose([Validators.required, Validators.maxLength(1000)])]
    });
  }

  ngOnChanges() {
    this.initForm();
  }

  onEditToggle() {
    if (!this.isEditable) {
      return false;
    }
    this.isEdit = !this.isEdit;
    if (this.isEdit) {
      this.initForm();
    }
  }

  onSaveChanges() {
    const newValue = this.inlineForm.get('name').value;
    if (!this.inlineForm.valid || newValue === this.initialValue) {
      this.onEditToggle();
      return false;
    }
    this.isEdit = newValue === this.initialValue;
    this.formSubmit.emit(newValue);
    this.isEditContainerVisible = false;
  }
}
