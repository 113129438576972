import { Component, OnInit, Output, Input, EventEmitter, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Observable, Subscription } from 'rxjs';

const ACTIVE = 'active';
const INACTIVE = 'inactive';

@Component({
  selector: 'search-button',
  templateUrl: './search-button.component.html',
  styleUrls: ['./search-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('openSearchInLeftMenuState', [
      state(
        INACTIVE,
        style({
          right: '-100%'
        })
      ),
      state(
        ACTIVE,
        style({
          right: 0
        })
      ),
      transition('* => ' + ACTIVE, animate('250ms ease-in')),
      transition(ACTIVE + ' => ' + INACTIVE, animate('250ms ease-out'))
    ])
  ]
})
export class SearchButtonComponent implements OnInit, OnDestroy {
  @Output() changeSearch = new EventEmitter();
  @Output() openSearch = new EventEmitter();

  @Input() closeButton = true;
  @Input() searchIcon = true;
  @Input() placeholder = 'Search phrase...';
  @Input() closeOnClickOut = true;
  @Input() openFromParent$: Observable<boolean>;

  public isSearchOpened = false;
  public state: string = INACTIVE;
  private currentSearchPhrase = '';

  subs: Subscription[] = [];

  constructor() {}

  ngOnInit(): any {
    this.subs.push(
      this.openFromParent$.subscribe(v => {
        if (v && this.state === INACTIVE) {
          this.onOpenSearch();
        }
      })
    );
  }

  onChangeSearch(searchString) {
    this.currentSearchPhrase = searchString;
    this.changeSearch.emit(this.currentSearchPhrase);
  }

  onOpenSearch() {
    this.state = ACTIVE;
    this.openSearch.emit(true);
  }

  onCloseSearch() {
    this.state = INACTIVE;
  }

  onClickOut() {
    if (this.currentSearchPhrase === '') {
      this.onCloseSearch();
    }
  }

  animationStarted($event) {
    if (this.state === ACTIVE) {
      this.isSearchOpened = true;
    }
  }

  animationDone($event) {
    if (this.state === INACTIVE) {
      this.isSearchOpened = false;
      this.currentSearchPhrase = '';
      this.changeSearch.emit(this.currentSearchPhrase);
    }
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
