import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../../shared/services/form-v2.service';
import { Observer, Subscription } from 'rxjs/index';
import { DataTypeLabels, ScoringDataType, ScoringFactor } from '../../backlog-board/constants/backlog.constants';
import { AppState } from '../../../ngrx/state';
import { Task } from '../../../interfaces';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TASK_PL } from '../../../constants';
import { ScoringCriteria } from '../../../interfaces/ScoringCriteria';
import { Store } from '@ngrx/store';
import { JsonApiSingeModelResponse } from '../../../shared/services/app/web-socket/http-response';
import { HandleResponseAction } from '../../../ngrx/actions/root.action';
import { fromScoringCriteria } from '../../../ngrx/reducers/scoring-criteria.reducer';
import { take } from 'rxjs/operators';
import { SegmentService } from '../../../atlaz-bnp/services/intergations/segment/segment.service';

@Component({
  selector: 'edit-task-scoring',
  templateUrl: './edit-task-scoring.component.html',
  styleUrls: ['./edit-task-scoring.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTaskScoringComponent implements OnInit {
  public possibleCriteria = [];
  public valuesCriteria = [];
  public effortsCriteria = [];

  @Input() task: Task;
  @Input() focusOn: number | string;

  @Output() close = new EventEmitter();

  public dataTypeLabels = DataTypeLabels;
  public scoringDataType = ScoringDataType;

  formObserver: Observer<any> = {
    next: (resp: JsonApiSingeModelResponse<any>) => {
      try {
        if (this.task.confidence !== resp.data.attributes.confidence) {
          this._segment.track('AdvScoringConfidenceSet');
        }
        this._store
          .select(fromScoringCriteria.getByBoard(this.task.board))
          .pipe(take(1))
          .subscribe((crs: ScoringCriteria[]) => {
            const oldValuesMap = this.task.scoringValues.reduce((acc, item) => {
              acc[item.criterion] = item.value;
              return acc;
            }, {});
            const newValuesMap = resp.data.attributes.scoringValues.reduce((acc, item) => {
              acc[item.criterion] = item.value;
              return acc;
            }, {});
            crs.forEach(item => {
              if (oldValuesMap[item.id] !== newValuesMap[item.id]) {
                this._segment.track('AdvScoringCriteriaEdited', { source: 'table', factor: item.factor.toLowerCase() });
              }
            });
          });
      } catch (e) {
        console.log('sending track AdvScoringConfidenceSet/AdvScoringCriteriaEdited', e);
      }

      this._store.dispatch(new HandleResponseAction(resp));
      this.onClose();
    },

    error: () => {},

    complete: () => {}
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.edit,
    entityToEdit: [TASK_PL],
    formObserver: this.formObserver,
    prepareFormValue: formValue => {
      const result = {
        id: this.task.id,
        scoringValues: [],
        confidence: formValue['confidence']
      };
      Object.keys(formValue).forEach(key => {
        if (key !== 'confidence' && formValue[key] !== null) {
          result.scoringValues.push({ criterion: +key, value: +formValue[key] });
        }
      });
      return result;
    }
  };

  form: FormGroup;
  subs: Subscription[] = [];

  constructor(
    private _fb: FormBuilder,
    private _store: Store<AppState>,
    public _formService: FormV2Service,
    private _cd: ChangeDetectorRef,
    private _segment: SegmentService
  ) {}

  ngOnInit() {
    this._store
      .select(fromScoringCriteria.getByBoard(this.task.board))
      .pipe(take(1))
      .subscribe((crs: ScoringCriteria[]) => {
        this.possibleCriteria = [...crs];
        this.valuesCriteria = this.possibleCriteria.filter(item => item.factor === ScoringFactor.Value);
        this.effortsCriteria = this.possibleCriteria.filter(item => item.factor === ScoringFactor.Efforts);
        const taskCrsValuesMap = this.task.scoringValues.reduce((acc, item) => {
          if (item && item.hasOwnProperty('criterion')) {
            acc[item.criterion] = item.value;
          }
          return acc;
        }, {});
        const formConfig = crs.reduce(
          (acc, item) => {
            acc[item.id] = taskCrsValuesMap[item.id];
            return acc;
          },
          { confidence: this.task.confidence }
        );
        this.form = this._fb.group(formConfig);

        this._formService.initFormParams(this.form, this.formServiceParams);
        this._cd.detectChanges();
      });
  }

  onValidateType(type, formPropName?) {
    formPropName = formPropName + '';
    if (type !== 'confidence' && (this.form || this.form.get(formPropName).value === null)) {
      return;
    }
    switch (type) {
      case ScoringDataType.ZeroTen: {
        const val = parseInt(this.form.get(formPropName).value) || 0;
        this.form.get(formPropName).patchValue(val < 0 ? 0 : val > 10 ? 10 : val);
        break;
      }
      case ScoringDataType.USD: {
        const val = Math.floor(parseFloat(this.form.get(formPropName).value) * 100) / 100 || 0;
        this.form.get(formPropName).patchValue(val < 0 ? 0 : val);
        break;
      }
      case 'confidence': {
        const val = parseInt(this.form.get('confidence').value) || 0;
        this.form.get('confidence').patchValue(val < 0 ? 0 : val > 100 ? 100 : val);
        break;
      }
      default: {
        this.form.get(formPropName).patchValue(parseInt(this.form.get(formPropName).value) || 0);
        break;
      }
    }
  }

  onSubmit() {
    this.form.markAsTouched();
    this.possibleCriteria.forEach(item => {
      this.onValidateType(item.dataType, item.id);
    });
    this.onValidateType('confidence');
    this._formService.submit();
  }

  onClose() {
    this.close.emit();
  }
}
