import { ChecklistActionTypes } from '../actions/checklist.actions';
import { ESInterface } from '../state';
import { Checklist } from '../../interfaces';
import { getEntitySelector } from '../functions/selectors';
import { compose } from '@ngrx/store';
import { CHECKLIST_PL } from '../../constants';
import { deleteEntityState, addEntityState, editEntityState } from '../functions/reducer';
import { getAllEntitiesAsArray } from '../functions/selectors';
import { ChecklistActions } from '../actions/checklist.actions';

const initialState: ESInterface<Checklist> = {
  ids: [],
  entities: {},
  selectedEntityId: null
};

export function reducer(state = initialState, action: ChecklistActions): ESInterface<Checklist> {
  switch (action.type) {
    case ChecklistActionTypes.ADD_COMPLETE: {
      return addEntityState(state, action.payload);
    }

    case ChecklistActionTypes.EDIT: {
      return editEntityState(state, action.payload);
    }

    case ChecklistActionTypes.DELETE: {
      return deleteEntityState(state, action.payload.id);
    }

    default: {
      return state;
    }
  }
}

export const getAllChecklists = compose(getAllEntitiesAsArray, getEntitySelector(CHECKLIST_PL));
