
import {of as observableOf, fromEvent as observableFromEvent,  Observable ,  BehaviorSubject ,  Subscription } from 'rxjs';

import {distinctUntilChanged, map, switchMap, filter, combineLatest, debounceTime, withLatestFrom} from 'rxjs/operators';
import { Directive, Input, Output, EventEmitter, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { KeyCode } from '../../constants';
import { User } from '../../interfaces';

@Directive({
  selector: '[mentionsPicker]'
})
export class MentionDirective implements OnInit, OnDestroy {
  @Input() mentionsPicker: Observable<User[]>;
  @Input() fireSelectEvent: BehaviorSubject<User>;

  @Output() selectedMention = new EventEmitter();
  @Output() filteredMentions = new EventEmitter();
  @Output() patchValue = new EventEmitter();

  public mentionsHadToBeVisible$ = new BehaviorSubject(false);
  public mentionsVisible$: Observable<Boolean>;
  public filteredMentions$: Observable<User[]>;
  public filteringValue$ = new BehaviorSubject('');
  public selectedMention$ = new BehaviorSubject(-1);
  public mouseUpEvent$;

  private mouseDown = false;

  private subscriptions = [];
  private subs = Subscription.EMPTY;

  constructor(private _element: ElementRef) {
    observableFromEvent(document, 'mousedown')
      .subscribe(() => (this.mouseDown = true))
      .add(this.subs);
    this.mouseUpEvent$ = observableFromEvent(document, 'click').pipe(
      combineLatest(observableFromEvent(document, 'mouseup')),
      debounceTime(100),);
    this.mouseUpEvent$.subscribe(() => (this.mouseDown = false)).add(this.subs);
  }

  get mentions$() {
    return this.mentionsPicker;
  }

  get fireSelectEvent$() {
    return this.fireSelectEvent;
  }

  // value from @ to closest space or endline

  checkPosition(withDetails = false): boolean | { text: string; startPosition: number } {
    let text = this._element.nativeElement.value;
    const before: string = text.slice(0, this._element.nativeElement.selectionStart);
    const after: string = text.slice(this._element.nativeElement.selectionStart);
    text = '';
    let i;
    for (i = before.length; i > -1; i--) {
      //noinspection TsLint
      if (before[i] === ' ' || before[i] === '\n') {
        return false;
      }
      if (before[i] === '@') {
        //noinspection TsLint
        if (i > 0 && before[i - 1] !== ' ' && before[i - 1] !== '\n') {
          return false;
        }
        text = before.substr(i);
        break;
      }
    }
    if (!text.length) {
      return false;
    }
    const filteringValueStartPosition = i;

    for (i = 0; i < after.length; i++) {
      //noinspection TsLint
      if (after[i] === ' ' || after[i] === '\n') {
        break;
      }
    }
    text += after.substr(0, i);
    this.filteringValue$.next(text);

    return withDetails
      ? {
          text: text,
          startPosition: filteringValueStartPosition
        }
      : text.length > 0;
  }

  ngOnInit() {
    this.filteredMentions$ = this.filteringValue$.pipe(
      distinctUntilChanged(),
      combineLatest(this.mentions$, (filteringValue: string, members: User[]) => {
        const value = filteringValue.substr(1);
        return value.length === 0
          ? members
          : members.filter(
              member =>
                member.fullname.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
                member.firstname.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
                member.lastname.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
                member.nickname.toLowerCase().indexOf(value.toLowerCase()) > -1
            );
      }),);

    this.mentionsVisible$ = this.mentionsHadToBeVisible$.pipe(combineLatest(
      this.filteredMentions$,
      (visible: boolean, mentions: User[]) => {
        return visible && mentions.length > 0;
      }
    ));

    this.subscriptions.push(
      this.selectedMention$.pipe(distinctUntilChanged()).subscribe(value => this.selectedMention.emit(value))
    );

    this.subscriptions.push(
      this.filteredMentions$.pipe(
        map(mentions => mentions.length),
        distinctUntilChanged(),
        combineLatest(this.mentionsVisible$, (_, visible: boolean) => visible),)
        .subscribe(visible => {
          this.selectedMention$.next(visible ? 0 : -1);
        })
    );

    this.subscriptions.push(
      this.filteredMentions$.subscribe(members => {
        this.filteredMentions.emit(members);
      })
    );

    this.subscriptions.push(
      observableFromEvent(this._element.nativeElement, 'blur').pipe(
        debounceTime(100),
        switchMap(() => (this.mouseDown ? this.mouseUpEvent$ : observableOf({}))),)
        .subscribe(() => {
          this.mentionsHadToBeVisible$.next(false);
        })
    );

    this.subscriptions.push(
      observableFromEvent(this._element.nativeElement, 'keyup').pipe(
        filter((event: KeyboardEvent) => KeyCode.KEY_ESCAPE === event.keyCode),
        withLatestFrom(this.mentionsVisible$, (event: KeyboardEvent, visible: boolean) => visible),
        filter(visible => visible),)
        .subscribe(() => {
          event.stopPropagation();
          event.preventDefault();
          this.mentionsHadToBeVisible$.next(false);
        })
    );

    this.subscriptions.push(
      observableFromEvent(this._element.nativeElement, 'keydown').pipe(
        filter((event: KeyboardEvent) =>
          [KeyCode.KEY_ARROW_DOWN, KeyCode.KEY_ARROW_UP, KeyCode.KEY_ENTER].includes(event.keyCode)
        ),
        withLatestFrom(
          this.mentionsVisible$,
          this.selectedMention$,
          this.filteredMentions$,
          (event: KeyboardEvent, visible: boolean, selectedMention: number, filteredMentions: User[]) => {
            return { event, visible, selectedMention, filteredMentions };
          }
        ),
        filter(({ visible }) => visible),)
        .subscribe(({ event, selectedMention, filteredMentions }) => {
          event.stopPropagation();
          event.preventDefault();

          if (event.keyCode === KeyCode.KEY_ENTER) {
            this.mentionsHadToBeVisible$.next(false);
            this.fireSelectEvent$.next(filteredMentions[selectedMention]);
          }

          if (event.keyCode === KeyCode.KEY_ARROW_UP || event.keyCode === KeyCode.KEY_ARROW_DOWN) {
            if (event.keyCode === KeyCode.KEY_ARROW_UP) {
              console.log('press up');
              selectedMention--;
              if (selectedMention < 0) {
                selectedMention = filteredMentions.length - 1;
              }
            } else if (event.keyCode === KeyCode.KEY_ARROW_DOWN) {
              console.log('press down');
              selectedMention++;
              selectedMention = selectedMention % filteredMentions.length;
            }
            this.selectedMention$.next(selectedMention);
          }
        })
    );

    this.subscriptions.push(
      this.fireSelectEvent$.subscribe(value => {
        if (value) {
          this.doPatchValue(value);
        }
      })
    );

    this.subscriptions.push(
      observableFromEvent(this._element.nativeElement, 'input').subscribe(() => {
        this.mentionsHadToBeVisible$.next(!!this.checkPosition());
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  doPatchValue(user: User) {
    const position = <{ text: string; startPosition: number }>this.checkPosition(true);

    if (position && user) {
      const cursorPosition = position.startPosition + 2 + user.nickname.length;
      const value = this._element.nativeElement.value;
      const newValue =
        value.substr(0, position.startPosition) +
        '@' +
        user.nickname +
        ' ' +
        value.substr(position.text.length + position.startPosition);
      console.log(newValue);

      this.selectedMention$.next(-1);
      this.patchValue.emit({
        newValue,
        cursorPosition
      });
    }
  }
}
