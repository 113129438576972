import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { User } from '../../../interfaces';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state/app-state';
import { PatchQueryService } from '../../../shared/services/patch-query.service';
import { Project } from '../../../interfaces/project';

@Component({
  selector: 'right-menu-users-popup',
  templateUrl: './right-menu-users-popup.component.html',
  styleUrls: ['./right-menu-users-popup.component.scss'],
  providers: [PatchQueryService]
})
export class RightMenuUsersPopupComponent implements OnInit, OnChanges {
  @Input() isShowHelpText = false;
  @Input() boardId: number;
  @Input() boardProjects: Project[] = [];
  @Input() boardUsers: User[];
  @Input() users: User[];

  @Output() close = new EventEmitter();
  @Output() changeUsersList = new EventEmitter();

  public boardProjectsNameString = '';

  constructor(private patchQueryService: PatchQueryService, private _store: Store<AppState>) {}

  ngOnInit() {
    this.setInitItemIds(this.boardUsers);
  }

  ngOnChanges() {
    this.boardProjectsNameString = this.boardProjects.map(project => project.name.toUpperCase()).join(', ');
  }

  setInitItemIds(items: User[]) {
    this.patchQueryService.setInitItemIds(items);
  }

  addUser(user: User) {
    this.patchQueryService.addItemId(user.id);
  }

  removeUser(user: User) {
    this.patchQueryService.removeItemId(user.id);
  }

  assignUsers() {
    this.changeUsersList.emit(this.patchQueryService.getItemIdsQuery());
  }

  onCloseMembersPopup() {
    const usersToAdd = this.patchQueryService.getItemIdsQuery().add.length;
    const usersToRemove = this.patchQueryService.getItemIdsQuery().remove.length;

    if (usersToAdd > 0 || usersToRemove > 0) {
      this.assignUsers();
    }
    this.close.emit();
  }
}
