<table class="table_wrapper unselectable" [ngClass]="type$ | async" [class.user_can_edit]="isNotGuest" [attr.data-has-multiple-swimlanes]="showSwimlanes$ | async">
  <thead>
  <tr *ngIf="(type$ | async) !== ScoringType.advanced;else advancedHead">
    <th class="sticky_left filter_cell">
      <span>Tasks</span>
      <div class="search_wrapper">
        <input type="text" ref-searchInput class="search_input" (keydown.esc)="onResetFilter(searchInput)" (input)="onChangeFilter($event)" placeholder="Search Tasks">
        <div *ngIf="searchInput.value" class="search_reset" (click)="onResetFilter(searchInput)"></div>
      </div>
    </th>
    <th>Members</th>
    <th>Labels</th>
    <th>Start Date</th>
    <th>Due Date</th>
    <th class="swimlane-cell">Swimlane</th>
    <th>{{(board$ | async)?.type === boardType.roadmap ? 'Status' : 'Column'}}</th>
    <th *ngFor="let item of (customFields$ | async)" >{{item.name}}</th>
    <ng-container *ngIf="(type$ | async) !== ScoringType.off">
      <th *ngFor="let factor of (factors$ | async)">{{factor.label}}</th>
      <th *ngIf="(type$ | async) !== ScoringType.basic">Score</th>
    </ng-container>
  </tr>
  </thead>
  <tbody [clickOut]="true" (clickOutEvent)="onSaveTaskTitle()" (click)="onSaveTaskTitle()">
  <ng-container [ngSwitch]="type$ | async">
    <ng-container *ngSwitchCase="ScoringType.off">
      <ng-container *ngFor="let version of versions$ | async">
        <tr class="version_row"
            [ngClass]="{closed: hiddenVersions[version.id], 'opened_menu': visibleMenuVersionId === version.id, 'insert_target': insertToVersionId === version.id}"
            (click)="onVersionClick(version)"
            (dragover)="onDragTaskOverVersion($event, version.id)"
            (dragleave)="onDragLeave()"
        >
          <td class="sticky_left">
            {{version.name}}
            <span class="version_planned_date" *ngIf="version.plannedDate">{{version.plannedDate | toDate}}</span>
            <version-tasks-counter [tasksByVersion$]="calculatedTasksByVersion$" [versionId]="version.id"></version-tasks-counter>
            <version-context-menu *ngIf="version.id > 0 && isNotGuest" [version]="version" [visibleMenuVersionId]="visibleMenuVersionId" (onToggle)="onToggleVersionMenu($event)"></version-context-menu>
          </td>
          <td [colSpan]="6 + (customFields$ | async)?.length"></td>
        </tr>
        <ng-container *ngIf="!hiddenVersions[version.id]">
          <tr *ngFor="let task of ((calculatedTasksByVersion$ | async) || {})[version.id];let i = index"
              [attr.draggable]="isNotGuest"
              (dragstart)="onDragTaskStart($event, task.title)"
              (dragend)="onDragTaskEnd(task.id, version.id)"
              (dragover)="onDragTaskOver($event, version.id)"
              (dragleave)="onDragLeave()"
          >
            <td class="sticky_left combined_td">
              <div class="wrapper" [style.min-height.px]="task.labels.length * 19 + 30" [routerLink]="getTaskLink(task.id)">
                <span class="task_index">#{{i+1}}</span>
                <span class="task_title">
                  {{task.title}}
                  <div class="quick_edit_button" (click)="onOpenQuickActions($event, task.id)"></div>
                  <div class="quick_edit_button delete_btn" (click)="onOpenDeleteConfirm($event, task)"></div>
                </span>
              </div>
            </td>
            <td class="users_cell" (click)="onMembersClick(task.id)"><members-cell [members]="task.users"></members-cell></td>
            <td (click)="onLabelsClick(task.id)"><labels-cell [labels]="task.labels"></labels-cell></td>
            <td (click)="onStartDateClick(task.id)">{{task.startDate | toDate: 'dueDate'}}</td>
            <td (click)="onDueDateClick(task.id)">{{task.dueDate | toDate: 'dueDate'}}</td>
            <td class="swimlane-cell" (click)="onColumnClick(task.id)">{{task.swimlane}}</td>
            <td (click)="onColumnClick(task.id)">{{task.column}}</td>
            <td *ngFor="let item of (customFields$ | async)" (click)="onCustomFieldClick($event, task.id, item.id)" >{{tasksCustomFieldsMap[item.id][task.id] ? tasksCustomFieldsMap[item.id][task.id].visibleValue : ''}}</td>
          </tr>
          <tr *ngIf="isNotGuest" (dragover)="onDragTaskOverVersion($event, version.id)" (dragleave)="onDragLeave()">
            <td class="sticky_left add-task-td" (click)="onCreateNewTask(version.id)">
              <span>+</span>Add Task
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="swimlane-cell"></td>
            <td></td>
            <td *ngFor="let item of (customFields$ | async)"></td>
          </tr>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ScoringType.basic">
      <ng-container *ngFor="let version of versions$ | async">
        <tr class="version_row"
            [ngClass]="{closed: hiddenVersions[version.id], 'opened_menu': visibleMenuVersionId === version.id, 'insert_target': insertToVersionId === version.id}"
            (click)="onVersionClick(version)"
            (dragover)="onDragTaskOverVersion($event, version.id)"
            (dragleave)="onDragLeave()"
        >
          <td class="sticky_left">
            {{version.name}}
            <span class="version_planned_date" *ngIf="version.plannedDate">{{version.plannedDate | toDate}}</span>
            <version-tasks-counter [tasksByVersion$]="calculatedTasksByVersion$" [versionId]="version.id"></version-tasks-counter>
            <version-context-menu *ngIf="version.id > 0 && isNotGuest" [version]="version" [visibleMenuVersionId]="visibleMenuVersionId" (onToggle)="onToggleVersionMenu($event)"></version-context-menu>
          </td>
          <td [colSpan]="8 + (customFields$ | async)?.length"></td>
        </tr>
        <ng-container *ngIf="!hiddenVersions[version.id]">
          <tr *ngFor="let task of ((calculatedTasksByVersion$ | async) || {})[version.id];let i = index"
              [attr.draggable]="isNotGuest"
              (dragstart)="onDragTaskStart($event, task.title)"
              (dragend)="onDragTaskEnd(task.id, version.id)"
              (dragover)="onDragTaskOver($event, version.id)"
              (dragleave)="onDragLeave()"
          >
            <td class="sticky_left combined_td">
              <div class="wrapper" [style.min-height.px]="task.labels.length * 19 + 30" [routerLink]="getTaskLink(task.id)">
                <span class="task_index">#{{i+1}}</span>
                <span class="task_title">
                  {{task.title}}
                  <div class="quick_edit_button" (click)="onOpenQuickActions($event, task.id)"></div>
                  <div class="quick_edit_button delete_btn" (click)="onOpenDeleteConfirm($event, task)"></div>
                </span>
              </div>
            </td>
            <td class="users_cell" (click)="onMembersClick(task.id)"><members-cell [members]="task.users"></members-cell></td>
            <td (click)="onLabelsClick(task.id)"><labels-cell [labels]="task.labels"></labels-cell></td>
            <td (click)="onStartDateClick(task.id)">{{task.startDate | toDate: 'dueDate'}}</td>
            <td (click)="onDueDateClick(task.id)">{{task.dueDate | toDate: 'dueDate'}}</td>
            <td class="swimlane-cell" (click)="onColumnClick(task.id)">{{task.swimlane}}</td>
            <td (click)="onColumnClick(task.id)">{{task.column}}</td>
            <td *ngFor="let item of (customFields$ | async)" (click)="onCustomFieldClick($event, task.id, item.id)" >{{tasksCustomFieldsMap[item.id][task.id] ? tasksCustomFieldsMap[item.id][task.id].visibleValue : ''}}</td>
            <td *ngFor="let fr of (factors$ | async)" (click)="onFactorClick(task.id, fr.prop)">{{task[fr.prop]}}</td>
          </tr>
          <tr *ngIf="isNotGuest" (dragover)="onDragTaskOverVersion($event, version.id)" (dragleave)="onDragLeave()">
            <td class="sticky_left add-task-td" (click)="onCreateNewTask(version.id)">
              <span>+</span>Add Task
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="swimlane-cell"></td>
            <td></td>
            <td *ngFor="let item of (customFields$ | async)"></td>
            <td></td>
            <td></td>
          </tr>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ScoringType.advanced">
      <ng-container *ngFor="let version of versions$ | async">
        <tr class="version_row"
            [ngClass]="{closed: hiddenVersions[version.id], 'opened_menu': visibleMenuVersionId === version.id, 'insert_target': insertToVersionId === version.id}"
            (click)="onVersionClick(version)"
            (dragover)="onDragTaskOverVersion($event, version.id)"
            (dragleave)="onDragLeave()"
        >
          <td class="sticky_left">
            {{version.name}}
            <span class="version_planned_date" *ngIf="version.plannedDate">{{version.plannedDate | toDate}}</span>
            <version-tasks-counter [tasksByVersion$]="calculatedTasksByVersion$" [versionId]="version.id"></version-tasks-counter>
            <version-context-menu *ngIf="version.id > 0 && isNotGuest" [version]="version" [visibleMenuVersionId]="visibleMenuVersionId" (onToggle)="onToggleVersionMenu($event)"></version-context-menu>
          </td>
          <td [colSpan]="(customFields$ | async)?.length + (criteriaValues$ | async)?.length + (criteriaEfforts$ | async)?.length + 8"></td>
        </tr >
        <ng-container *ngIf="!hiddenVersions[version.id]">
          <tr *ngFor="let task of ((calculatedTasksByVersion$ | async) || {})[version.id];let i = index"
              [attr.draggable]="isNotGuest"
              (dragstart)="onDragTaskStart($event, task.title)"
              (dragend)="onDragTaskEnd(task.id, version.id)"
              (dragover)="onDragTaskOver($event, version.id)"
              (dragleave)="onDragLeave()"
          >
            <td class="sticky_left combined_td">
              <div class="wrapper" [style.min-height.px]="task.labels.length * 19 + 30" [routerLink]="getTaskLink(task.id)">
                <span class="task_index">#{{i+1}}</span>
                <span class="task_title">
                  {{task.title}}
                  <div class="quick_edit_button" (click)="onOpenQuickActions($event, task.id)"></div>
                  <div class="quick_edit_button delete_btn" (click)="onOpenDeleteConfirm($event, task)"></div>
                </span>
              </div>
            </td>
            <td class="users_cell" (click)="onMembersClick(task.id)"><members-cell [members]="task.users"></members-cell></td>
            <td (click)="onLabelsClick(task.id)"><labels-cell [labels]="task.labels"></labels-cell></td>
            <td (click)="onStartDateClick(task.id)">{{task.startDate | toDate: 'dueDate'}}</td>
            <td (click)="onDueDateClick(task.id)">{{task.dueDate | toDate: 'dueDate'}}</td>
            <td class="swimlane-cell" (click)="onColumnClick(task.id)">{{task.swimlane}}</td>
            <td (click)="onColumnClick(task.id)">{{task.column}}</td>
            <td *ngFor="let item of (customFields$ | async)" (click)="onCustomFieldClick($event, task.id, item.id)" >{{tasksCustomFieldsMap[item.id][task.id] ? tasksCustomFieldsMap[item.id][task.id].visibleValue : ''}}</td>
            <td *ngFor="let cr of (criteriaValues$ | async)" (click)="onFactorClick(task.id, cr.id)">{{task.scoringValuesMap[cr.id] ? task.scoringValuesMap[cr.id].visibleValue : '?'}}</td>
            <td *ngFor="let cr of (criteriaEfforts$ | async)" (click)="onFactorClick(task.id, cr.id)">{{task.scoringValuesMap[cr.id] ? task.scoringValuesMap[cr.id].visibleValue : '?'}}</td>
            <td (click)="onFactorClick(task.id, 'confidence')">{{task.confidence}}</td>
            <td class="sticky_right" (click)="onFactorClick(task.id, 'confidence')">{{task.score}}</td>
          </tr>
          <tr *ngIf="isNotGuest" (dragover)="onDragTaskOverVersion($event, version.id)" (dragleave)="onDragLeave()">
            <td class="sticky_left add-task-td" (click)="onCreateNewTask(version.id)">
              <span>+</span>Add Task
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="swimlane-cell"></td>
            <td></td>
            <td *ngFor="let item of (customFields$ | async)"></td>
            <td *ngFor="let cr of (criteriaValues$ | async)"></td>
            <td *ngFor="let cr of (criteriaEfforts$ | async)"></td>
            <td></td>
            <td class="sticky_right"></td>
          </tr>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngFor="let version of versions$ | async">
        <tr class="version_row"
            [ngClass]="{closed: hiddenVersions[version.id], 'opened_menu': visibleMenuVersionId === version.id, 'insert_target': insertToVersionId === version.id}"
            (click)="onVersionClick(version)"
            (dragover)="onDragTaskOverVersion($event, version.id)"
            (dragleave)="onDragLeave()"
        >
          <td class="sticky_left">
            {{version.name}}
            <span class="version_planned_date" *ngIf="version.plannedDate">{{version.plannedDate | toDate}}</span>
            <version-tasks-counter [tasksByVersion$]="calculatedTasksByVersion$" [versionId]="version.id"></version-tasks-counter>
            <version-context-menu *ngIf="version.id > 0 && isNotGuest" [version]="version" [visibleMenuVersionId]="visibleMenuVersionId" (onToggle)="onToggleVersionMenu($event)"></version-context-menu>
          </td>
          <td [colSpan]="(customFields$ | async)?.length + (factors$ | async)?.length + 7"></td>
        </tr>
        <ng-container *ngIf="!hiddenVersions[version.id]">
          <tr *ngFor="let task of ((calculatedTasksByVersion$ | async) || {})[version.id];let i = index"
              [attr.draggable]="isNotGuest"
              (dragstart)="onDragTaskStart($event, task.title)"
              (dragend)="onDragTaskEnd(task.id, version.id)"
              (dragover)="onDragTaskOver($event, version.id)"
              (dragleave)="onDragLeave()"
          >
            <td class="sticky_left combined_td">
              <div class="wrapper" [style.min-height.px]="task.labels.length * 19 + 30" [routerLink]="getTaskLink(task.id)">
                <span class="task_index">#{{i+1}}</span>
                <span class="task_title">
                  {{task.title}}
                  <div class="quick_edit_button" (click)="onOpenQuickActions($event, task.id)"></div>
                  <div class="quick_edit_button delete_btn" (click)="onOpenDeleteConfirm($event, task)"></div>
                </span>
              </div>
            </td>
            <td class="users_cell" (click)="onMembersClick(task.id)"><members-cell [members]="task.users"></members-cell></td>
            <td (click)="onLabelsClick(task.id)"><labels-cell [labels]="task.labels"></labels-cell></td>
            <td (click)="onStartDateClick(task.id)">{{task.startDate | toDate: 'dueDate'}}</td>
            <td (click)="onDueDateClick(task.id)">{{task.dueDate | toDate: 'dueDate'}}</td>
            <td class="swimlane-cell" (click)="onColumnClick(task.id)">{{task.swimlane}}</td>
            <td (click)="onColumnClick(task.id)">{{task.column}}</td>
            <td *ngFor="let item of (customFields$ | async)" (click)="onCustomFieldClick($event, task.id, item.id)" >{{tasksCustomFieldsMap[item.id][task.id] ? tasksCustomFieldsMap[item.id][task.id].visibleValue : ''}}</td>
            <td *ngFor="let fr of (factors$ | async)" (click)="onFactorClick(task.id, fr.prop)">{{task[fr.prop]}}</td>
            <td (click)="onFactorClick(task.id)">{{task.score}}</td>
          </tr>
          <tr *ngIf="isNotGuest" (dragover)="onDragTaskOverVersion($event, version.id)" (dragleave)="onDragLeave()">
            <td class="sticky_left add-task-td" (click)="onCreateNewTask(version.id)">
              <span>+</span>Add Task
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td class="swimlane-cell"></td>
            <td></td>
            <td *ngFor="let item of (customFields$ | async)"></td>
            <td *ngFor="let fr of (factors$ | async)"></td>
            <td></td>
          </tr>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  </tbody>
</table>

<ng-template #advancedHead>
  <tr>
    <th rowspan="2" class="sticky_left filter_cell">
      <span>Tasks</span>
      <div class="search_wrapper">
        <input type="text" ref-searchInput class="search_input" (keydown.esc)="onResetFilter(searchInput)" (input)="onChangeFilter($event)" placeholder="Search Tasks">
        <div *ngIf="searchInput.value" class="search_reset" (click)="onResetFilter(searchInput)"></div>
      </div>
    </th>
    <th rowspan="2">Members</th>
    <th rowspan="2">Labels</th>
    <th rowspan="2">Start Date</th>
    <th rowspan="2">Due Date</th>
    <th rowspan="2" class="swimlane-cell">Swimlane</th>
    <th rowspan="2">{{(board$ | async)?.type === boardType.roadmap ? 'Status' : 'Column'}}</th>
    <th rowspan="2" *ngFor="let item of (customFields$ | async)" >{{item.name}}</th>
    <th *ngIf="(criteriaValues$ | async).length" [colSpan]="(criteriaValues$ | async).length">Value</th>
    <th *ngIf="(criteriaEfforts$ | async).length" [colSpan]="(criteriaEfforts$ | async).length">Efforts</th>
    <th rowspan="2">Confidence</th>
    <th rowspan="2" class="sticky_right">Score</th>
  </tr>
  <tr>
    <th *ngFor="let cr of (criteriaValues$ | async)">
      {{cr.name}}
      <span class="info_icon" [a-tooltip]="cr.description || 'No Description'" position="top"></span>
    </th>
    <th *ngFor="let cr of (criteriaEfforts$ | async)">
      {{cr.name}}
      <span class="info_icon" [a-tooltip]="cr.description || 'No Description'" position="top"></span>
    </th>
  </tr>
</ng-template>


<ng-template #editTtile>
  <textarea focus="true"
            class="task_title"
            [style.height.px]="editingTitleHeight"
            [(ngModel)]="editingTitleValue"
            (click)="stopPropagation($event)"
            (keydown)="stopPropagation($event)"
            (keydown.enter)="onSaveTaskTitle()"
            (keydown.esc)="onCancelTitleEdit()"
  ></textarea>
</ng-template>

<ng-container *ngIf="isMembersPopupVisible$ | async">
  <a-center-container-scrollable class="edit_field_popup">
    <members-popup
      (addUserToBoard)="onAddUserToBoard($event)"
      (close)="onCloseMembersPopup()"
      (update) = "onUpdateUsersList($event)"
      [isPublicBoard]="isPublicBoard$ | async"
      [possibleUsers]="possibleUsers$ | async"
      [selectedUsers]="taskUsers$ | async"
      [projectOnlyUsers]="projectOnlyUsers$ | async"
    ></members-popup>
  </a-center-container-scrollable>
  <overlay (click)="onCloseMembersPopup()"></overlay>
</ng-container>

<ng-container *ngIf="isLabelsPopupVisible$ | async">
  <a-center-container-scrollable class="edit_field_popup">
    <sidebar-labels-popup
      (close)="onCloseLabelsPopup()"
      [task]="editingTask$ | async"
    ></sidebar-labels-popup>
  </a-center-container-scrollable>
  <overlay (click)="onCloseLabelsPopup()"></overlay>
</ng-container>

<ng-container [ngSwitch]="type$ | async">
  <ng-container *ngSwitchCase="ScoringType.advanced">
    <edit-task-scoring
      *ngIf="isScoreEditing$ | async"
      [focusOn]="editingCriterion$ | async"
      [task]="editingTask$ | async"
      (close)="onCloseEditingScoring()"
    ></edit-task-scoring>
  </ng-container>
  <ng-container *ngSwitchCase="ScoringType.basic">
    <edit-basic-scoring
      *ngIf="isScoreEditing$ | async"
      [focusOn]="editingAxis$ | async"
      [board]="board$ | async"
      [task]="editingTask$ | async"
      (close)="onCloseEditingScoring()"
    ></edit-basic-scoring>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <edit-ice-rice-scoring
      *ngIf="isScoreEditing$ | async"
      [focusOn]="editingFactor$ | async"
      [task]="editingTask$ | async"
      [type]="type$ | async"
      (close)="onCloseEditingScoring()"
    ></edit-ice-rice-scoring>
  </ng-container>
</ng-container>

<a-center-popup *ngIf="isAddTaskPopupVisible$ | async"
     class="add-task-popup"
     [attr.draggable-disabled]="true"
     [title]="'Add New Task'"
     (close)="onCloseAddTaskPopup()"
>
  <global-add-task-form
    (close)="onCloseAddTaskPopup()"
    [initialVersion]="newTaskInitialVersion"
    [showColumn]="true"
    [showSwimlane]="true"
    [showVersion]="true"
    [cacheKey]="'add-task-to-version'"
    [fixedBoard]="boardId$ | async"
  ></global-add-task-form>
</a-center-popup>

<sidebar-duedate-popup
  *ngIf="isDueDatePopupVisible$ | async"
  (onClosePopup)="onCloseDueDatePopup()"
  [task]="editingTask$ | async"
></sidebar-duedate-popup>

<sidebar-start-date-popup
  *ngIf="isStartDatePopupVisible$ | async"
  (onClosePopup)="onCloseStartDatePopup()"
  [task]="editingTask$ | async"
></sidebar-start-date-popup>

<a-center-popup *ngIf="isColumnPopupVisible$ | async"
                [title]="'Move Task'"
                (close)="onCloseColumnPopup()"
                class="move_task_popup"
>
  <task-action-popup [task]="editingTask$ | async"
                     actionName="move"
                     (close)="onCloseColumnPopup()"
  ></task-action-popup>
</a-center-popup>

<div class="edit_custom_field_wrapper" *ngIf="editCustomFieldTaskId$ | async" [style.left.px]="tasksCustomFieldClientRect.x || 0" [style.top.px]="tasksCustomFieldClientRect.y || 0">
  <tasks-custom-field-edit [useContextPopUp]="true"
                           [taskId]="editCustomFieldTaskId$ | async"
                           [customFieldValue]="editingCustomFieldValue"
                           [customField]="editingCustomField"
                           (close)="onCloseCustomFieldPopup()"
  ></tasks-custom-field-edit>
</div>

<div a-confirmation-popup
     *ngIf="taskToDelete"
     [confirmationTitle]="'Delete Task'"
     [actionButtonText]="'Delete'"
     (confirm)="onDeleteTask()"
     (close)="onCloseDeletePopup()"
>
  <p>Are You Sure You Want to Delete this task?</p>
  <p>All task data including comments, attachments and statistics will be lost.</p>
  <p>There is no undo.</p>
</div>

<div class="footer">
  <button class="add_btn" *ngIf="isNotGuest" [routerLink]="addVersionLink$ | async" [skipLocationChange]="true"><span>+</span>Add Version</button>
</div>
