import { Component, Input } from '@angular/core';
import { Project, Task } from '../../../../interfaces';

@Component({
  selector: 'sidebar-project',
  templateUrl: './sidebar-project.component.html',
  styleUrls: ['./sidebar-project.component.scss']
})
export class SidebarProjectComponent {
  @Input() taskProject: Project;
  @Input() task: Task;
  @Input() boardProjects: Project[];
  @Input() editPermissions = false;

  public isProjectsPopupVisible = false;

  onOpenProjectsPopup() {
    if (!this.editPermissions) {
      return false;
    }
    this.isProjectsPopupVisible = true;
  }
  onCloseProjectsPopup() {
    this.isProjectsPopupVisible = false;
  }
}
