import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'a-loader',
  templateUrl: './a-loader.component.html',
  styleUrls: ['./a-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ALoaderComponent {
  @Input() inline = false;

  @HostBinding('class.inline')
  get isInline() {
    return this.inline;
  }
}
