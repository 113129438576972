import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { AppContainersModule } from '../containers/containers.module';
import { RightMenuComponent } from './right-menu.component';
import { OverviewModule } from '../overview/overview.module';
import { BoardActivityComponent } from '../board/board-activity/board-activity.component';
import { ActivityPreviewComponent } from '../board/board-activity/activity-preview/activity-preview.component';
import { QuickfilterFormComponent } from '../quick-filters/quickfilter-form.component';
import { ReleasedTasksListComponent } from './released-tasks-list/released-tasks-list.component';
import { ArchivedItemsComponent } from './archived-items/archived-items.component';
import { ArchivedTaskListComponent } from './archived-items/task-list/archived-task-list.component';
import { ArchivedColumnListComponent } from './archived-items/column-list/archived-column-list.component';
import { SprintBoardModule } from '../board/sprint-board/sprint-board.module';
import { AtlazGuiModule } from '../atlaz-gui/atlaz-gui.module';
import { RightMenuMembersComponent } from './right-menu-members/right-menu-members.component';
import { RightMenuUsersPopupComponent } from './right-menu-members/right-menu-users-popup/right-menu-users-popup.component';
import { RightMenuUsersListComponent } from './right-menu-members/right-menu-users-list/right-menu-users-list.component';
import { RightMenuProjectsComponent } from './right-menu-projects/right-menu-projects.component';
import { RightMenuProjectsPopupComponent } from './right-menu-projects/right-menu-projects-popup/right-menu-projects-popup.component';
import { RightMenuProjectListComponent } from './right-menu-projects/right-menu-project-list/right-menu-project-list.component';
import { RightMenuCollectionsComponent } from './right-menu-collections/right-menu-collections.component';
import { RightMenuCollectionsPopupComponent } from './right-menu-collections/right-menu-collections-popup/right-menu-collections-popup.component';
import { RightMenuCollectionsListComponent } from './right-menu-collections/right-menu-collections-list/right-menu-collections-list.component';
import { RightMenuRootComponent } from './right-menu-root/right-menu-root.component';
import { RightMenuContainerComponent } from './right-menu-container/right-menu-container.component';
import { ReleasedSprintRightMenuComponent } from './released-sprint-right-menu/released-sprint-right-menu.component';
import { IntegrationsPublicModule } from '../integrations/settings/public/shared.module';
import { CopyBoardComponent } from '../board/copy-board/copy-board.component';
import { RightMenuAdvancedScoringComponent } from './right-menu-advanced-scoring/right-menu-advanced-scoring.component';
import { RightMenuAdvancedScoringSettingsComponent } from './right-menu-advanced-scoring/right-menu-advanced-scoring-settings/right-menu-advanced-scoring-settings.component';
import { ICalendarSyncPopupComponent } from './icalendar-sync-popup/icalendar-sync-popup.component';
import { PriorityChartScalesSettingsComponent } from './right-menu-advanced-scoring/priority-chart-scales-settings/priority-chart-scales-settings.component';
import { RightMenuBoardColorComponent } from './right-menu-board-color/right-menu-board-color.component';
import { RightMenuShowPropsComponent } from './right-menu-show-props/right-menu-show-props.component';
import { PdfExportPopupComponent } from './pdf-export-popup/pdf-export-popup.component';
import { ListBoardModule } from '../board/list-board/list-board.module';
import { BoardSharedModule } from '../board/board-shared/board-shared.module';
import { RightMenuCustomFieldsComponent } from './right-menu-custom-fields/right-menu-custom-fields.component';

const sharedComponents = [
  RightMenuComponent,
  BoardActivityComponent,
  ActivityPreviewComponent,
  QuickfilterFormComponent,
  ReleasedTasksListComponent,
  ArchivedItemsComponent,
  ArchivedTaskListComponent,
  ArchivedColumnListComponent,
  RightMenuAdvancedScoringComponent,
  RightMenuAdvancedScoringSettingsComponent,
  RightMenuMembersComponent,
  RightMenuUsersPopupComponent,
  RightMenuUsersListComponent,
  RightMenuProjectsComponent,
  RightMenuProjectsPopupComponent,
  RightMenuProjectListComponent,
  RightMenuCollectionsComponent,
  RightMenuCollectionsPopupComponent,
  RightMenuCollectionsListComponent,
  ReleasedSprintRightMenuComponent,
  RightMenuContainerComponent,
  RightMenuRootComponent,
  CopyBoardComponent,
  ICalendarSyncPopupComponent,
  PriorityChartScalesSettingsComponent,
  PdfExportPopupComponent,
  RightMenuCustomFieldsComponent
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    AppContainersModule,
    OverviewModule,
    SprintBoardModule,
    AtlazGuiModule,
    IntegrationsPublicModule,
    BoardSharedModule,
    ListBoardModule
  ],
  declarations: [...sharedComponents, RightMenuBoardColorComponent, RightMenuShowPropsComponent],
  exports: [RightMenuRootComponent, RightMenuContainerComponent]
})
export class RightMenuModule {}
