import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { Board, Label, Task, User } from '../../../interfaces';
import { AuthService } from '../../services/app/auth.service';
import { AppUrls } from '../../../app-urls';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import { GuiStateQuickTaskEditShow } from '../../../ngrx/actions/gui-state-memorized.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { getVisibleTaskAxisScore } from '../../../../helpers/task';
import { ScoringType } from '../../../constants';
import { isPresent } from '../../../../helpers';

@Component({
  selector: 'task-preview-dumb',
  templateUrl: './task-preview-dumb.component.html',
  styleUrls: ['./task-preview-dumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskPreviewDumbComponent implements OnChanges {
  @Input() task: Task;
  @Input() board: Board;
  @Input()
  showEstimates = {
    isShowHoursEstimate: true,
    isShowPointsEstimate: true
  };
  @Input() boardUsersMap: { [id: string]: User };
  @Input() boardLabelsMap: { [id: string]: Label };
  @Input() newNotifyMap: { [id: string]: boolean };
  @Input() activeUserId;
  @Input() score;

  public isTaskOverdone: boolean;
  public isTaskCloseToOverdone: boolean;
  public isSubscribe: boolean;
  public hasNotifications: boolean;
  public taskUrl;
  public taskUsers;
  public taskLabels;
  public scoreYVisible;
  public scoreXVisible;
  public isBasicScoring;
  public isScoringOff;

  constructor(
    private _userAuthService: AuthService,
    private _store: Store<AppState>,
    private _elR: ElementRef,
    private _renderer: Renderer2
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!this.board || !this.task || !this.activeUserId) {
      return;
    }
    if (changes.hasOwnProperty('task')) {
      this.taskUrl = AppUrls.getUrlTask(this.task.taskKey ? this.task.taskKey : this.task.id);
      this.dueDateCalculate(this.task.dueDate, this.task.doneDate);
      if (this.board.scoringType === ScoringType.basic) {
        this.scoreXVisible = getVisibleTaskAxisScore(this.task.backlogScoreX, this.board.backlogScoreXType);
        this.scoreYVisible = getVisibleTaskAxisScore(this.task.backlogScoreY, this.board.backlogScoreYType);
      }
    }
    if (changes.hasOwnProperty('board')) {
      if (this.board.scoringType === ScoringType.off) {
        this.isScoringOff = true;
      } else {
        this.isScoringOff = false;
        if (this.board.scoringType === ScoringType.basic) {
          this.scoreXVisible = getVisibleTaskAxisScore(this.task.backlogScoreX, this.board.backlogScoreXType);
          this.scoreYVisible = getVisibleTaskAxisScore(this.task.backlogScoreY, this.board.backlogScoreYType);
          this.isBasicScoring = true;
        } else {
          this.isBasicScoring = false;
        }
      }
    }
    if (changes.hasOwnProperty('newNotifyMap') && this.newNotifyMap) {
      this.hasNotifications = this.newNotifyMap[this.task.id];
    }
    if (changes.hasOwnProperty('task') || changes.hasOwnProperty('activeUserId')) {
      this.subscribeStatus();
    }
    if ((changes.hasOwnProperty('task') || changes.hasOwnProperty('boardUsersMap')) && this.boardUsersMap) {
      this.taskUsers = this.task.usersIds ? this.task.usersIds.map(id => this.boardUsersMap[id]).filter(isPresent) : [];
    }
    if ((changes.hasOwnProperty('task') || changes.hasOwnProperty('boardLabelsMap')) && this.boardLabelsMap) {
      this.taskLabels = this.task.labelsIds
        ? this.task.labelsIds.map(id => this.boardLabelsMap[id]).filter(isPresent)
        : [];
    }
  }

  /**
   * This method calls only from external components
   */
  public scrollIntoViewAndHighlight() {
    scrollIntoViewIfNeeded(this._elR.nativeElement, { duration: 300 });
    this.highlightScrolledTask();
  }

  highlightScrolledTask() {
    this._renderer.addClass(this._elR.nativeElement, 'scrollHighlightClass');
    //this.taskLink.nativeElement.focus();
    setTimeout(() => {
      this._renderer.removeClass(this._elR.nativeElement, 'scrollHighlightClass');
      window.location.hash = '';
    }, 3000);
  }

  onOpenQuickActions(event) {
    this._store.dispatch(
      new GuiStateQuickTaskEditShow({ id: this.task.id, rect: event.currentTarget.getBoundingClientRect() })
    );
  }

  dueDateCalculate(dueDate, doneDate) {
    const diffTime = dueDate * 1000 - Date.now();
    this.isTaskOverdone = dueDate && !doneDate && diffTime < 0;
    this.isTaskCloseToOverdone = dueDate && !doneDate && diffTime < 86400 && diffTime > -1;
  }

  subscribeStatus() {
    this.isSubscribe =
      this.task.subscribersIds && this.task.subscribersIds.some(userId => userId === this.activeUserId);
  }
}
