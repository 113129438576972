import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linkFromText'
})
export class LinkFromTextPipe implements PipeTransform {
  transform(value) {
    let result: string, regexp: RegExp, links: string[], href: string, linkReplacements: string[];

    linkReplacements = [' ', ',', '\r', '\n', '\r\n'];

    result = value;

    regexp = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s,^,]{2,}|www\.[^\s]+\.[^\s,^,]{2,})/g;
    links = value.match(regexp) || [];

    links.forEach(link => {
      href =
        '<a href="' +
        link.replace(/(\.{0,}?)[.,',"]*$/, '$1') +
        '" target="_blank" rel="noopener" class="comment_link" style="text-decoration: none; color: #3966d0;">' +
        link +
        '</a>';
      if (result.indexOf(link) === 0) {
        result = result.replace(link, href);
      }
      linkReplacements.forEach(replacement => {
        result = result.replace(replacement + link, replacement + href);
      });
    });

    return result;
  }
}
