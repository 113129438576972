import { Action } from './unsafe-action';
import { labelAction } from '../functions/action';
import { HttpQueryParam, PartOfEntity, QuickFilter } from '../../interfaces';
import { FILTER } from '../../constants';

export const QuickFilterActionTypes = {
  LOAD: labelAction(FILTER, 'LOAD'),
  GET: labelAction(FILTER, 'GET'),
  GET_COMPLETE: labelAction(FILTER, 'GET_COMPLETE'),
  GET_FAIL: labelAction(FILTER, 'GET_FAIL'),
  ADD: labelAction(FILTER, 'ADD'),
  ADD_COMPLETE: labelAction(FILTER, 'ADD_COMPLETE'),
  ADD_FAIL: labelAction(FILTER, 'ADD_FAIL'),
  EDIT: labelAction(FILTER, 'EDIT'),
  EDIT_COMPLETE: labelAction(FILTER, 'EDIT_COMPLETE'),
  EDIT_FAIL: labelAction(FILTER, 'EDIT_FAIL'),
  DELETE: labelAction(FILTER, 'DELETE'),
  DELETE_COMPLETE: labelAction(FILTER, 'DELETE_COMPLETE'),
  DELETE_FAIL: labelAction(FILTER, 'DELETE_FAIL'),
  ACTIVATE: labelAction(FILTER, 'ACTIVATE'),
  DEACTIVATE: labelAction(FILTER, 'DEACTIVATE'),
  TOGGLE: labelAction(FILTER, 'TOGGLE')
};

export class QuickFilterLoadAction implements Action {
  type = QuickFilterActionTypes.LOAD;

  constructor(public payload: number) {}
}

export class QuickFilterGetAction implements Action {
  type = QuickFilterActionTypes.GET;

  constructor(public payload?: HttpQueryParam) {}
}

export class QuickFilterGetCompleteAction implements Action {
  type = QuickFilterActionTypes.GET_COMPLETE;

  constructor(public payload: QuickFilter[]) {}
}

export class QuickFilterEditAction implements Action {
  type = QuickFilterActionTypes.EDIT;
  constructor(public payload: PartOfEntity) {}
}

export class QuickFilterEditCompleteAction implements Action {
  type = QuickFilterActionTypes.EDIT_COMPLETE;

  constructor(public payload: QuickFilter) {}
}

export class QuickFilterDeleteAction implements Action {
  type = QuickFilterActionTypes.DELETE;

  constructor(public payload: number) {}
}

export class QuickFilterDeleteCompleteAction implements Action {
  type = QuickFilterActionTypes.DELETE_COMPLETE;

  constructor(public payload?: any) {}
}

export class QuickFilterAddAction implements Action {
  type = QuickFilterActionTypes.ADD;

  constructor(public payload: PartOfEntity) {}
}

export type QuickFilterActions =
  | QuickFilterGetAction
  | QuickFilterLoadAction
  | QuickFilterGetCompleteAction
  | QuickFilterEditAction
  | QuickFilterEditCompleteAction
  | QuickFilterDeleteAction
  | QuickFilterDeleteCompleteAction
  | QuickFilterAddAction;
