import { Injectable } from '@angular/core';
import { isProductionMode } from '../../../../../environments/helper';

type Instance = Function;

const fakeInstance = () => null;

const debugInstance = (...arg) => console.log('FbPixel', arg);

@Injectable()
export class FbPixelService {
  private instance: Instance;

  constructor() {
    if (isProductionMode()) {
      if (window['fbq']) {
        this.instance = window['fbq'];
      } else {
        console.warn('dataLayer is missed');
        this.instance = fakeInstance;
      }
    } else {
      this.instance = debugInstance;
    }
  }

  get fbq() {
    return this.instance;
  }
}
