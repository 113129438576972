import { label } from './util';

export const GET = 'GET';
export const GET_FAIL = 'GET_FAIL';
export const GET_COMPLETE = 'GET_COMPLETE';
export const ADD = 'ADD';
export const ADD_FAIL = 'ADD_FAIL';
export const ADD_COMPLETE = 'ADD_COMPLETE';
export const EDIT = 'EDIT';
export const EDIT_FAIL = 'EDIT_FAIL';
export const EDIT_COMPLETE = 'EDIT_COMPLETE';
export const DELETE = 'DELETE';
export const DELETE_FAIL = 'DELETE_FAIL';
export const DELETE_COMPLETE = 'DELETE_COMPLETE';

export interface ReduxActionType {
  GET: string;
  GET_COMPLETE: string;
  GET_FAIL: string;
  ADD: string;
  ADD_COMPLETE: string;
  ADD_FAIL: string;
  EDIT: string;
  EDIT_COMPLETE: string;
  EDIT_FAIL: string;
  DELETE: string;
  DELETE_COMPLETE: string;
  DELETE_FAIL: string;
}

export const CRUD_ACTIONS = [
  GET,
  GET_COMPLETE,
  GET_FAIL,
  ADD,
  ADD_COMPLETE,
  ADD_FAIL,
  EDIT,
  EDIT_COMPLETE,
  EDIT_FAIL,
  DELETE,
  DELETE_COMPLETE,
  DELETE_FAIL
];

export function generateActionList(entityCode: string, initActionList: any = {}): { [actionCode: string]: any } {
  return CRUD_ACTIONS.reduce((acc, actionCode) => {
    const action = {
      [actionCode]: labelAction(entityCode, actionCode)
    };
    acc = Object.assign({}, acc, action);
    return acc;
  }, initActionList);
}

export function labelAction(entityCode: string, actionCode: string): string {
  return label(`[${entityCode}] ${actionCode}`);
}
