
import {fromEvent as observableFromEvent,  Observable ,  Subscription } from 'rxjs';
import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';

@Directive({
  selector: '[digitsOnlyInput]'
})
export class DigitsOnlyInputDirective implements AfterViewInit, OnDestroy {
  subs: Subscription[] = [];

  constructor(private _elRef: ElementRef) {}

  ngAfterViewInit() {
    this.subs.push(
      observableFromEvent(this._elRef.nativeElement, 'input').subscribe((e: UIEvent) => {
        const value = e.target['value'] || '';
        if (!value.match(/^[\d]*$/)) {
          const digits = value.match(/\d+/g);
          e.target['value'] = digits ? digits.join('') : '';
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
