import { Component, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { FormV2Service } from '../../shared/services/form-v2.service';
import { NavigateToOnboardingTaskService } from '../../shared/services/navigate-to-onboarding-task.service';
import { RouterNavigateService } from '../../shared/services/router-navigate.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { AppState } from '../../ngrx/state';
import { Store } from '@ngrx/store';
import { fromBoards } from '../../ngrx/reducers/board.reducer';
import { boardType } from '../../constants';
import { filter, map, take } from 'rxjs/operators';

const TEMPLATE_CHOOSER_ERR_TEXT = 'Choose at least one template to continue or skip';
@Component({
  selector: 'initiate-boards',
  templateUrl: './initiate-boards.component.html',
  styleUrls: ['./initiate-boards.component.scss'],
  providers: [FormV2Service, NavigateToOnboardingTaskService]
})
export class InitiateBoardsComponent implements OnDestroy {
  public selectedTemplates = [];
  public showTemplateChooseErr$ = new BehaviorSubject('');
  public isCreateProject: boolean;
  public isValidForm;
  public isSkipPending = false;
  public submit$ = new Subject();
  subs: Subscription[] = [];
  constructor(
    public _formService: FormV2Service,
    private _onboardingNavigateService: NavigateToOnboardingTaskService,
    private _navigate: RouterNavigateService,
    private _store: Store<AppState>
  ) {}

  onSelectionUpdated(newSelection: any[]) {
    this.selectedTemplates = newSelection;
  }

  onSkip() {
    this.isSkipPending = true;
    this._store
      .select(fromBoards.getAllAvailable)
      .pipe(map(boards => boards.filter(board => board.isPreset && board.type === boardType.kanban)), take(1))
      .subscribe(boards => {
        if (Array.isArray(boards) && boards.length) {
          this._navigate.navigateToBoard(boards[0].id).then(() => {
            //this.subs.push(this._onboardingNavigateService.checkTaskToOpen().subscribe());
            this._navigate.deactivatePopupOutlet();
          });
        } else {
          this._navigate.navigateToOverview().then(() => {
            //this.subs.push(this._onboardingNavigateService.checkTaskToOpen().subscribe());
            this._navigate.deactivatePopupOutlet();
          });
        }
      });
  }

  onUseTemplates() {
    if (this.selectedTemplates.length) {
      this.isCreateProject = true;
      this.showTemplateChooseErr$.next('');
    } else {
      this.showTemplateChooseErr$.next(TEMPLATE_CHOOSER_ERR_TEXT);
    }
  }

  onBackToTemplates() {
    this.isCreateProject = false;
  }

  onSubmit() {
    this.submit$.next(this.selectedTemplates);
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
