import { FormControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment-mini-ts';
import { isObject } from '../../../../helpers';

const replace = (needle, to, str: string) => str.split(needle).join(to);

const formatFactory = canonicalFormat => {
  let all = [];
  all.push(canonicalFormat);
  all.push(replace('.', '\\', canonicalFormat));
  all.push(replace('.', '/', canonicalFormat));
  all.push(replace('.', '-', canonicalFormat));
  all.push(replace('hh', 'h', canonicalFormat));
  all.push(replace('mm', 'm', canonicalFormat));

  let toAdd = all.map(rule => replace('DD', 'D', rule));
  all = [...all, ...toAdd];
  toAdd = all.map(rule => replace('MM', 'M', rule));
  all = [...all, ...toAdd];

  return all;
};

export function toMoment(date: string, dateFormat) {
  const allowedFormats = formatFactory(dateFormat);
  if (!date) {
    return null;
  }
  if (isObject(date) && date['date']) {
    return moment()
      .year(date['date'].year)
      .date(date['date'].day)
      .month(date['date'].month - 1);
  }
  let input = '';
  input = date['formatted'] || date;
  input = input && input.trim ? input.trim() : '';

  let result;
  return allowedFormats.some(format => (result = moment(input, format)).format(format) === input) ? result : null;
}

export function validateDateFactory(dateFormat): ValidatorFn {
  const allowedFormats = formatFactory(dateFormat);

  return (c: FormControl) => {
    let trimmedValue = typeof c.value === 'string' ? c.value.trim() : c.value;
    // for validation datepicker's value
    if (c && c['formatted']) {
      trimmedValue = c['formatted'];
    }
    return allowedFormats.some(format => moment(trimmedValue, format).format(format) === trimmedValue)
      ? null
      : {
          invalidDateFormat: true
        };
  };
}
