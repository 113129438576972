import { createSelector } from '@ngrx/store';
import { AppState, REPORT_PERIOD_FILTER } from '../../state';
import * as reportPeriodFilter from '../../actions/report-filters/report-period-filter.action';
import { ReportPeriodFilterActionTypes } from '../../actions/report-filters/report-period-filter.action';

export interface ReportPeriodFilter {
  period: {
    type?: string;
    from?: number;
    to?: number;
  };
}

const initState: ReportPeriodFilter = {
  period: {
    type: null,
    from: null,
    to: null
  }
};

export function reducer(
  state = initState,
  action: reportPeriodFilter.Actions
): ReportPeriodFilter {
  switch (action.type) {
    case ReportPeriodFilterActionTypes.SELECT: {
      state = { ...state, ...action['payload'] };
      return state;
    }

    case ReportPeriodFilterActionTypes.RESET: {
      state = initState;
      return initState;
    }

    default: {
      return state;
    }
  }
}

export namespace fromReportPeriod {
  export const getState = (state: AppState) =>
    <ReportPeriodFilter>state[REPORT_PERIOD_FILTER];

  export const getRange = createSelector(
    getState,
    state => state.period
  );
}
