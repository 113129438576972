import { Label, LabelTag } from '../app/interfaces/label';
export const labelsToLabelTag = (labels: Label[]): LabelTag[] => {
  let index = 1;
  return labels.map(label => {
    return {
      label,
      id: label.id,
      color: label.color,
      name: label.name.trim().length ? label.name : 'Untitled Label ' + index++,
      checked: false
    };
  });
};
