import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CUSTOM_FIELD_PL } from '../../../../constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../../../shared/services/form-v2.service';
import { Observer } from 'rxjs/Observer';
import { HandleResponseAction, RemoveEntityFromStoreAction } from '../../../../ngrx/actions/root.action';
import { AppState } from '../../../../ngrx/state';
import { Store } from '@ngrx/store';
import { toDashedFromCamelCase } from '../../../../../helpers';
import { CustomField, CustomFieldTypes } from '../../../../ngrx/reducers/custom-field.reducer';
import { CustomFieldActionTypes, CustomFieldDeleteAction } from '../../../../ngrx/actions/custom-field.actions';
import { Actions } from '@ngrx/effects';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'new-custom-field',
  templateUrl: './new-custom-field.component.html',
  styleUrls: ['./new-custom-field.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewCustomFieldComponent implements OnInit, OnDestroy {
  @Input() boardId: number;
  @Input() dragType: number;
  @Input() customField: CustomField;

  @Output() startDrag = new EventEmitter();
  @Output() close = new EventEmitter();

  public isDescription: boolean;
  public isDeleting: boolean;
  public isDeletePending: boolean;

  public CustomFieldTypes = CustomFieldTypes;

  public sub: Subscription;

  private formObserver: Observer<any> = {
    next: response => {
      this._store.dispatch(new HandleResponseAction(response));
      this.onClose();
    },

    error: error => {
      console.log(error, 'error on component');
    },

    complete: () => {}
  };

  public formServiceParams: FormServiceParams;

  public form: FormGroup;
  constructor(
    private _fb: FormBuilder,
    public _formService: FormV2Service,
    private _store: Store<AppState>,
    private actions$: Actions
  ) {}

  ngOnInit() {
    this.formServiceParams = {
      saveType: this.customField ? FormSaveType.edit : FormSaveType.add,
      entityToEdit: toDashedFromCamelCase(CUSTOM_FIELD_PL),
      formObserver: this.formObserver,
      prepareFormValue: formValue => {
        const result = { ...formValue };
        if (
          (formValue['type'] === CustomFieldTypes.select ||
            (this.customField && this.customField.type === CustomFieldTypes.select)) &&
          Array.isArray(formValue['values'])
        ) {
          result['values'] = JSON.stringify(formValue['values']);
        } else {
          result['values'] = '';
        }
        return result;
      }
    };

    let config;
    if (this.customField) {
      let initialValues;
      if (this.customField.values) {
        try {
          initialValues = JSON.parse(this.customField.values);
        } catch (e) {
          console.log('Parse initial values of custom field error', e);
          initialValues = [];
        }
      } else {
        initialValues = [];
      }
      config = {
        id: [this.customField.id],
        name: [this.customField.name, Validators.required],
        type: [{ value: this.customField.type, disabled: true }, Validators.required],
        values: [initialValues],
        description: [this.customField.description || '']
      };
      this.isDescription = !!this.customField.description;
    } else {
      config = {
        name: ['', Validators.required],
        type: [CustomFieldTypes.text, Validators.required],
        board: [this.boardId, Validators.required],
        values: [''],
        description: ['']
      };
    }

    this.form = this._fb.group(config);

    this._formService.initFormParams(this.form, this.formServiceParams);

    this.sub = this.actions$
      .ofType(CustomFieldActionTypes.DELETE_COMPLETE)
      .subscribe(({ payload }: { type: string; payload: number }) => {
        if (this.customField && payload === this.customField.id) {
          this.onCloseConfirmPopup();
          this._store.dispatch(
            new RemoveEntityFromStoreAction({
              entityName: CUSTOM_FIELD_PL,
              predicate: entity => entity.id === this.customField.id
            })
          );
        }
      });
  }

  onClose() {
    this.close.emit();
  }

  onShowDescription() {
    this.isDescription = true;
  }

  onSubmit() {
    if (this.isDeletePending) {
      return;
    }
    this._formService.markAsDirty();
    if (this.form.valid) {
      this._formService.submit();
      return false;
    }
  }

  onStartDrag(event) {
    this.startDrag.emit(event);
  }

  onConfirmAction() {
    this.isDeletePending = true;
    this._store.dispatch(new CustomFieldDeleteAction(this.customField.id));
  }

  onCloseConfirmPopup() {
    this.isDeleting = false;
    this.isDeletePending = false;
  }

  onDelete() {
    this.isDeleting = true;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
