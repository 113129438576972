import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AppUrls } from '../../app-urls';
import { ComponentToggleAction } from '../../ngrx/actions/component.actions';
import { AppState, LEFT_MENU } from '../../ngrx/state';
import { Store } from '@ngrx/store';
import { PermissionsService } from '../../permissions/permissions.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'left-menu-footer',
  templateUrl: './left-menu-footer.component.html',
  styleUrls: ['./left-menu-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeftMenuFoterComponent implements OnInit {
  @Input() isLeftMenu$: Observable<boolean>;
  public appUrls = AppUrls;
  public isCreateSectionVisible = false;

  public hasPermission$: Observable<boolean>;

  constructor(
    private _permissions: PermissionsService,
    private _store: Store<AppState>,
    private _cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.hasPermission$ = this._permissions.isSuperUser$;
    this.isLeftMenu$.subscribe(isVisible => {
      if (!isVisible) {
        this.isCreateSectionVisible = false;
        this._cd.detectChanges();
      }
    });
  }

  toggleLeftMenu() {
    this._store.dispatch(new ComponentToggleAction({ name: LEFT_MENU }));
  }
}
