import { distinctUntilChanged, pluck, publishReplay, refCount } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/state';
import {
  GlobalSearchLeaveSearchPageAction,
  GlobalSearchLoadMoreAction,
  GlobalSearchStartAction
} from '../../ngrx/actions/global-search.actions';
import { SearchStateInterface } from '../../ngrx/reducers/global-search.reducer';
import * as fromComponent from '../../ngrx/reducers/component.reducer';
import { isEquals } from '../../../helpers';
import { SegmentService } from '../../atlaz-bnp/services/intergations/segment/segment.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
  public searchQuery: string;
  public searchState: SearchStateInterface;

  public canShowMore = false;
  public showSearchResults = false;

  public routeSubscription;
  public storeSubscription;

  public stickyFlagEnabled$: Observable<0 | 1>;

  public filterParams;
  public isEmptySearchQAvailable = false;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _segment: SegmentService,
    private _store: Store<AppState>
  ) {}

  ngOnInit(): any {
    this.stickyFlagEnabled$ = this._store.select(fromComponent.isStickyLeftMenu);

    // we have to track when user enters this page
    const initialQuery = (this.routeSubscription = this._activatedRoute.snapshot.params['query'] || '');
    this._segment.track('GlobalSearchStarted', { searchQuery: initialQuery });

    this.routeSubscription = this._activatedRoute.params.pipe(publishReplay(1), refCount()).subscribe(params => {
      // do action to find tasks
      // the only place where we handle current url params
      // & dispatch the startSearchAction if data in url and in store isn't equals
      this.isEmptySearchQAvailable = !!params.isEmptyQAvailable;
      const filterParams = Object.assign({}, params);
      delete filterParams['query'];
      ['users', 'boards', 'projects', 'versions'].forEach(paramName => {
        filterParams[paramName] = filterParams[paramName] ? filterParams[paramName].split(',').map(i => +i) : [];
      });

      const sortParams = {};
      if (params['sortParam']) {
        sortParams['sortParam'] = params['sortParam'];
      }
      if (params['sortOrder']) {
        sortParams['sortOrder'] = params['sortOrder'];
      }

      if (
        this.searchQuery === params['query'] &&
        isEquals(this.filterParams, filterParams) &&
        isEquals(this.searchState.sortParams, sortParams)
      ) {
        return;
      }

      this.searchQuery = params['query'] ? params['query'] : '';
      this._store.dispatch(
        new GlobalSearchStartAction({
          searchQuery: this.searchQuery,
          filterParams: filterParams,
          sortParams: sortParams
        })
      );
    });

    this.storeSubscription = this._store
      .pipe(pluck('search'), distinctUntilChanged())
      .subscribe((state: SearchStateInterface) => {
        this.searchState = state;
        this.filterParams = state.filterParams;
        this.canShowMore =
          !state.errorMessage.length &&
          state.result.length > 0 &&
          state.nextOffset < state.count &&
          !state.pending &&
          (state.searchQuery.length > 0 || this.isEmptySearchQAvailable);

        this.showSearchResults = state.completed && (state.searchQuery.length > 0 || this.isEmptySearchQAvailable);
      });
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
    this.storeSubscription.unsubscribe();
    this._store.dispatch(new GlobalSearchLeaveSearchPageAction());
  }

  onClickLoadMore() {
    this._store.dispatch(new GlobalSearchLoadMoreAction());
  }
}
