import { distinctUntilChanged, distinctUntilKeyChanged, filter, mapTo, pluck, switchMap } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/state';
import { ActivatedRoute } from '@angular/router';
import { getBoardById } from '../../ngrx/reducers/board.reducer';
import { Board } from '../../interfaces';
import { SelectEntityAction } from '../../ngrx/actions/root.action';
import { BOARD_PL } from '../../constants';
import { isNotPresent, isPresent } from '../../../helpers/';
import { SegmentService } from '../../atlaz-bnp/services/intergations/segment/segment.service';
import { RouterNavigateService } from '../../shared/services/router-navigate.service';
import { SegmentTrackBoardOpenedAction } from '../../ngrx/actions/segment.actions';
import { BoardViewMode, fromGuiState } from '../../ngrx/reducers/gui-state-memorized.reducer';
import { PermissionsService } from '../../permissions/permissions.service';

@Component({
  templateUrl: './board-detail-page.component.html',
  styleUrls: ['./board-detail-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoardDetailPageComponent implements OnInit, OnDestroy {
  public boardWrapperTop: number;

  public subs: Subscription[] = [];
  public board$: Observable<Board>;
  public boardId$: Observable<number>;
  public viewMode$: Observable<string>;
  public isNotGuest$ = this._permissions.isNotGuest$;
  public BoardViewMode = BoardViewMode;

  constructor(
    private _store: Store<AppState>,
    private _segment: SegmentService,
    private _navigate: RouterNavigateService,
    private _activatedRoute: ActivatedRoute,
    private _permissions: PermissionsService
  ) {}

  ngOnInit() {
    this.boardId$ = <Observable<number>>this._activatedRoute.params.pipe(pluck('id'), distinctUntilChanged());

    this.board$ = this.boardId$.pipe(switchMap(id => this._store.pipe(getBoardById(id))));

    this.viewMode$ = this.boardId$.pipe(switchMap(id => this._store.select(fromGuiState.getBoardViewMode(id))));

    this.subs.push(
      this.board$
        .pipe(filter(isPresent), switchMap(board => this.board$.pipe(filter(isNotPresent), mapTo(board.name))))
        // someone deleted board
        .subscribe((boardName: string) =>
          this._navigate.navigateToOverview().then(() => this._navigate.navigateToCloseBoard(boardName))
        ),

      this.board$
        .pipe(filter(isPresent), distinctUntilKeyChanged('id'))
        .subscribe(board => this._store.dispatch(new SegmentTrackBoardOpenedAction({ boardType: board.type })))
    );
  }

  onHeightChanged(value) {
    this.boardWrapperTop = value;
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
    this._store.dispatch(new SelectEntityAction({ entityName: BOARD_PL, id: null }));
  }
}
