import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../../../services/form-v2.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppState } from '../../../../../ngrx/state';
import { RouterNavigateService } from '../../../../services/router-navigate.service';
import { AtlazApiV2Service } from '../../../../services/atlaz-api/v2/atlaz-api-v2.service';
import { Store } from '@ngrx/store';
import { Task } from '../../../../../interfaces';
import { Observable } from 'rxjs/Observable';
import * as fromJiraCompanies from '../../../../../integrations/settings/jira-settings/ngrx/jira-company.reducer';
import { JiraProject } from '../../../../../integrations/settings/jira-settings/ngrx/jira-company.reducer';
import { JiraCloud, JiraCompany } from '../../../../../integrations/settings/jira-settings/ngrx/jira-company.actions';
import { distinctUntilChanged, map, take } from 'rxjs/operators';
import { JIRA_COMPANY_PL } from '../../../../../constants/entity';
import { HandleResponseAction } from '../../../../../ngrx/actions/root.action';
import { JsonApiSingeModelResponse } from '../../../../services/app/web-socket/http-response';
import { Observer } from 'rxjs/Observer';
import { toDashedFromCamelCase } from '../../../../../../helpers';

@Component({
  selector: 'push-to-jira-form',
  templateUrl: './push-to-jira-form.component.html',
  styleUrls: ['./push-to-jira-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormV2Service]
})
export class PushToJiraFormComponent implements OnInit {
  @Input() task: Task;
  @Output() close = new EventEmitter();

  form: FormGroup;
  formObserver: Observer<any> = {
    next: (resp: JsonApiSingeModelResponse<any>) => {
      this._store.dispatch(new HandleResponseAction(resp));
      this.close.emit();
    },
    error: error => {},
    complete: () => {}
  };
  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.edit,
    entityToEdit: toDashedFromCamelCase(JIRA_COMPANY_PL),
    formObserver: this.formObserver
  };
  public jiraProjects$: Observable<JiraProject[]>;
  public jiraCompany$: Observable<JiraCompany>;
  public clouds$: Observable<JiraCloud[]>;

  constructor(
    private _store: Store<AppState>,
    private _atlazApi: AtlazApiV2Service,
    private _routerNav: RouterNavigateService,
    private _fb: FormBuilder,
    public _formService: FormV2Service
  ) {}

  ngOnInit() {
    this.jiraCompany$ = this._store.select(fromJiraCompanies.getAll).pipe(map(all => all[0]));
    this.clouds$ = this.jiraCompany$.pipe(
      map((v: JiraCompany) => {
        let clouds = [];
        try {
          clouds = [...JSON.parse(v.clouds)];
        } catch (e) {
          console.error('Fail to parse jira clouds');
        }
        return clouds;
      })
    );

    this.jiraCompany$.pipe(take(1)).subscribe(company => {
      const config = {
        id: [company.id],
        task: [this.task.id],
        title: [this.task.title, Validators.compose([Validators.required, Validators.maxLength(255)])],
        cloud: ['', Validators.required],
        jiraProject: ['', Validators.required]
      };
      if (Array.isArray(company.jiraProjects) && company.jiraProjects.find(item => item.id === company.jiraProject)) {
        config.jiraProject = [company.jiraProject];
      }
      this.form = this._fb.group(config);

      this._formService.initFormParams(this.form, this.formServiceParams);

      this.jiraProjects$ = this.form.get('cloud').valueChanges.pipe(
        distinctUntilChanged(),
        map(cloud => {
          const c = cloud || company.cloud;
          const prjs = company.jiraProjects && Array.isArray(company.jiraProjects[c]) ? company.jiraProjects[c] : [];
          const prevOrFirstInCloudPrj = prjs.find(p => p.id === company.jiraProject)
            ? company.jiraProject
            : prjs[0] ? prjs[0].id : null;
          this.form.get('jiraProject').patchValue(prevOrFirstInCloudPrj);
          return prjs;
        })
      );
      setTimeout(() => this.form.get('cloud').patchValue(company.cloud));
    });
  }

  onSubmit() {
    this.form.get('title').patchValue(this.form.get('title').value.trim());
    this._formService.markAsDirty();
    if (this.form.valid) {
      this._formService.submit();
    }
  }
}
