import { of as observableOf, from as observableFrom, Observable } from 'rxjs';
import { tap, mapTo, filter, take, catchError, switchMap, map, switchMapTo } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { AppState } from '../state';
import { OVERVIEW_ACTIVITY_PL, OVERVIEW_ACTIVITY } from '../../constants';
import { GetCompleteAction } from '../actions/root.action';
import { HttpQueryParam } from '../../interfaces';
import { defaultErrorHandler } from '../effects/root.effect';

import * as overviewActivity from './overview-activity.actions';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { toDashedFromCamelCase } from '../../../helpers/string';
import { COLUMN_PL, OVERVIEW_FILTER_PL } from '../../constants/entity';
import { OverviewActivityFilters } from '../../shared/services/app/models/overview-activity';
import {
  getFirst,
  JsonApiSingeModelResponse,
  jsonApiToEntityState,
  modelToEntity
} from '../../shared/services/app/web-socket/http-response';
import { AuthService } from '../../shared/services/app/auth.service';
import { defaultFilterValues, getActivityQueryParams } from './overview-activity.reducer';
import { ESInterface } from '../state/app-state';
import { OverviewActivity } from '../../interfaces/overview-activity';
import { getColumnState } from '../reducers/column.reducer';
import { GetByIdsAction } from 'app/ngrx/actions/root.action';
import { isPresent } from '../../../helpers/object';
import { Action } from '../actions/unsafe-action';

function removeIdsFromKey(obj) {
  return Object.keys(obj).reduce((newObj, key) => {
    const newKey = key.replace('Ids', '');
    newObj[newKey] = obj[key];
    return newObj;
  }, {});
}

@Injectable()
export class OverviewActivityEffects {
  readonly overviewFilterPath = toDashedFromCamelCase(OVERVIEW_FILTER_PL);
  readonly overviewPath = toDashedFromCamelCase(OVERVIEW_ACTIVITY);

  @Effect()
  getActivities$ = this.actions$.ofType(overviewActivity.GET).pipe(
    switchMapTo(this._store.select(getActivityQueryParams).pipe(take(1))),
    map(removeIdsFromKey),
    switchMap(queryParams => this._atlazApi2.get(this.overviewPath, queryParams)),
    map(jsonApiToEntityState),
    switchMap(activityState => {
      const itemsCount = activityState[OVERVIEW_ACTIVITY_PL] ? activityState[OVERVIEW_ACTIVITY_PL]['ids'].length : 0;
      return observableFrom([new GetCompleteAction(activityState), new overviewActivity.GetComplete({ itemsCount })]);
    }),
    catchError(e => {
      console.log('error when load activities');
      console.error(e);
      return observableOf({ type: 'error when load activities', payload: e });
    })
  );

  @Effect()
  loadFilter$ = this.actions$.ofType(overviewActivity.LOAD_FILTERS).pipe(
    switchMap(action => this._authService.userInActiveCompany$.pipe(filter(isPresent), mapTo(action))),
    switchMap(({ type, payload: httpParams }: { type: string; payload: HttpQueryParam }) => {
      return this._atlazApi2.get(this.overviewFilterPath).pipe(
        map((response: JsonApiSingeModelResponse<OverviewActivityFilters>) => getFirst(response)),
        // to be on the safe side if something went wrong
        tap(initialValue => {
          if (!initialValue) {
            throw Error('empty overview filter initial value');
          }
        }),
        map((filterModel: OverviewActivityFilters) => modelToEntity(filterModel)),
        map(filtersData => new overviewActivity.UpdatedFilters(filtersData)),
        catchError(() => {
          return observableOf(new overviewActivity.ClearFilter());
        })
      );
    })
  );

  @Effect()
  editFilter$ = this.actions$.ofType(overviewActivity.EDIT_FILTERS).pipe(
    switchMap(({ type, payload }: Action) => {
      return observableFrom([
        new overviewActivity.ClearActivities(),
        new overviewActivity.GetActivities(),
        new overviewActivity.SaveFilter(payload)
      ]);
    })
  );

  @Effect()
  clearFilter$ = this.actions$
    .ofType(overviewActivity.CLEAR_FILTER)
    .pipe(map(_ => new overviewActivity.EditFilters(defaultFilterValues)));

  @Effect({ dispatch: false })
  saveFilter$ = this.actions$.ofType(overviewActivity.SAVE_FILTER).pipe(
    switchMap(({ type, payload }: Action) => {
      const preparedPayload = removeIdsFromKey(payload);

      return this._atlazApi2
        .patch(this.overviewFilterPath, { id: this._authService.activeUserId, ...preparedPayload })
        .pipe(catchError(defaultErrorHandler(type, payload)));
    })
  );

  @Effect()
  updatedFilter$ = this.actions$
    .ofType(overviewActivity.UPDATED_FILTERS, overviewActivity.LOAD_MORE)
    .pipe(map(_ => new overviewActivity.GetActivities()));

  @Effect()
  updatedStat$ = this.actions$.ofType(overviewActivity.UPDATED_STATE).pipe(
    map(({ type, payload: activityState }: { type: string; payload: ESInterface<OverviewActivity> }) => {
      const columnIds = activityState.ids
        .map(id => activityState.entities[id])
        .reduce(this.getColumnIdsFromActivity, []);
      return columnIds;
    }),
    switchMap((columnIds: number[]) => this.getNoLoadedColumns(columnIds)),
    filter(noLoadedIds => noLoadedIds.length > 0),
    map(columnIds => new GetByIdsAction({ entity: COLUMN_PL, ids: columnIds }))
  );

  constructor(
    private actions$: Actions,
    private _store: Store<AppState>,
    private _atlazApi2: AtlazApiV2Service,
    private _authService: AuthService
  ) {}

  private getColumnIdsFromActivity(prevColumnIds: number[], activity: OverviewActivity) {
    const newColIds = [
      activity.columnFrom,
      activity.columnFromParent,
      activity.columnTo,
      activity.columnToParent
    ].filter(id => id && !prevColumnIds.includes(id));
    return [...prevColumnIds, ...newColIds];
  }

  private getNoLoadedColumns(columnIds): Observable<number[]> {
    return this._store.pipe(
      getColumnState,
      take(1),
      map(columnState => columnIds.filter(columnId => columnState.entities[columnId] === undefined))
    );
  }
}
