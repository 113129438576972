import { AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[selectText]'
})
export class ElementSelectTextDirective implements OnChanges, AfterViewInit {
  @Input() selectText: boolean;
  private alreadyAllowed = false;

  constructor(private _element: ElementRef) {}
  ngAfterViewInit() {
    setTimeout(() => {
      if (this.selectText === true) {
        this._element.nativeElement.select();
      }
      this.alreadyAllowed = true;
    }, 250);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.selectText === true && this.alreadyAllowed) {
      this._element.nativeElement.select();
    }
  }
}
