import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';

import { IntegrationAuthAdapterService } from './services/integration-auth-adapter.service';
import { IntegrationsEffects } from './store/integrations.effects';
import { SlackTeamExistsGuard } from './guards/slack-team-exists.guard';
import { SlackAuthAdapterService } from './services/slack-auth-adapter.service';
import { BoardIdGuard } from './guards/board-id.guard';
import { SlackBoardAdditionalService } from './services/slack-board-additional.service';
import { GithubCompanyExistsGuard } from './guards/github-company-exists.guard';
import { GithubAuthAdapterService } from './services/github-auth-adapter.service';
import { BitbucketAuthAdapterService } from './services/bitbucket-auth-adapter.service';
import { BitbucketCompanyExistsGuard } from './guards/bitbucket-company-exists.guard';
import { GitModule } from '../git/git.module';
import { GitlabAuthAdapterService } from './services/gitlab-auth-adapter.service';
import { GitlabCompanyExistsGuard } from './guards/gitlab-company-exists.guard';
import { IntercomAuthAdapterService } from './services/intercom-auth-adapter.service';
import { IntercomCompanyEffects } from '../settings/intercom-settings/ngrx/intercom-company.effects';
import { JiraAuthAdapterService } from './services/jira-auth-adapter.service';
import { JiraCompanyEffects } from '../settings/jira-settings/ngrx/jira-company.effects';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([IntegrationsEffects, IntercomCompanyEffects, JiraCompanyEffects]),
    GitModule
  ],
  declarations: [],
  exports: []
})
export class IntegrationsModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: IntegrationsModule,
      providers: [
        IntegrationAuthAdapterService,
        SlackTeamExistsGuard,
        SlackAuthAdapterService,
        GithubAuthAdapterService,
        BitbucketAuthAdapterService,
        BitbucketCompanyExistsGuard,
        GitlabAuthAdapterService,
        GitlabCompanyExistsGuard,
        BoardIdGuard,
        SlackBoardAdditionalService,
        GithubCompanyExistsGuard,
        IntercomAuthAdapterService,
        JiraAuthAdapterService
      ]
    };
  }
}
