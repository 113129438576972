import { createCommonSelectors } from '../../../../util/store';
import { getEmptyESState } from '../../../../ngrx/state';
import { JIRA_COMPANY_PL } from '../../../../constants';

import * as jiraCompany from './jira-company.actions';
import { ESJiraCompany, JiraCompany } from './jira-company.actions';
import { createSelector } from 'reselect';
import { prop } from '../../../../../helpers';

export interface State extends ESJiraCompany {
  isLoading: boolean;
}

export interface JiraProject {
  id: string;
  name: string;
  expand: string;
  key: string;
  projectTypeKey: string;
  self: string;
  simplified: boolean;
  style: string;
}

const initialState: State = {
  ...getEmptyESState<JiraCompany>(),
  isLoading: true
};

export function reducer(state = initialState, action: jiraCompany.Actions): State {
  switch (action.type) {
    case jiraCompany.GET_INDEX: {
      return { ...state, isLoading: true };
    }

    case jiraCompany.GET_INDEX_FAIL:
    case jiraCompany.GET_INDEX_COMPLETE: {
      return { ...state, isLoading: false };
    }

    case jiraCompany.CLEAR_SETTINGS:
    case jiraCompany.REMOVE_ALL_COMPLETE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const { getState, getEntities, getIds, getAll, isEmpty } = createCommonSelectors<JiraCompany, State>(
  JIRA_COMPANY_PL
);

export const getFirstId = createSelector(getIds, ids => ids[0]);
export const isLoading = createSelector(getState, prop('isLoading'));
