import { Entity, PartOfEntity } from '../../interfaces';
import { arrayMerge, removeProperty } from '../../../helpers';
import { deepCopyEntity, entityCodesPlMap } from '../../../helpers/entity';
import { AppState, EntityStateModel, ESInterface } from '../state';
import { RemoveEntityPayload } from '../actions/root.action';
import { isObject } from 'util';
import { IDS } from '../../constants';
import { WebSocketDeleteAnswer } from '../reducers/data-sync.reducer';

export function addEntityState<T extends Entity>(
  oldState: ESInterface<T>,
  newEntity: T,
  toStart = false
): ESInterface<T> {
  const newState: ESInterface<T> = <ESInterface<T>>{
    ids: [newEntity.id],
    entities: { [newEntity.id]: newEntity },
    selectedEntityId: oldState.selectedEntityId
  };
  return insertReplaceState(oldState, newState, toStart);
}

export function editEntityState<T extends Entity>(
  oldState: ESInterface<T>,
  partOfEntity: PartOfEntity
): ESInterface<T> {
  const entity: T = oldState.entities[partOfEntity.id];

  if (!entity) {
    return oldState;
  }

  const newEntity: T = <T>Object.assign({}, entity);
  Object.keys(partOfEntity).forEach(key => {
    if (isObject(partOfEntity[key]) && (partOfEntity[key].add || partOfEntity[key].remove)) {
      if (!Array.isArray(newEntity[key + IDS])) {
        newEntity[key + IDS] = [];
      }
      if (partOfEntity[key].add) {
        partOfEntity[key].add.forEach(toAdd => (newEntity[key + IDS] = [newEntity[key + IDS], ...toAdd]));
      }
      if (partOfEntity[key].remove) {
        partOfEntity[key].remove.forEach(
          toRemove => (newEntity[key + IDS] = newEntity[key + IDS].filter(v => toRemove !== v))
        );
      }
    } else {
      newEntity[key] = partOfEntity[key];
    }
  });

  const newState = <ESInterface<T>>{
    entities: { [partOfEntity.id]: newEntity },
    ids: [],
    selectedEntityId: oldState.selectedEntityId
  };

  return insertReplaceState(oldState, newState);
}
interface EditArrayEntitiesParams {
  ids: number[];
  value: any;
  propName: string;
}
export function editArrayOfEntityStates<T extends Entity>(
  oldState: ESInterface<T>,
  params: EditArrayEntitiesParams
): ESInterface<T> {
  const newState = <ESInterface<T>>{
    entities: { ...oldState.entities },
    ids: [...oldState.ids],
    selectedEntityId: oldState.selectedEntityId
  };
  params.ids.forEach(id => {
    if (newState.entities[id]) {
      newState.entities[id] = <T>{ ...(<PartOfEntity>newState.entities[id]), [params.propName]: params.value };
    }
  });
  return newState;
}

/**
 * @deprecated use {@link removeOne} inctead
 * @param oldState
 * @param entityId
 */
export function deleteEntityState<T extends Entity>(oldState: ESInterface<T>, entityId: number): ESInterface<T> {
  const state = { ...oldState };
  state.ids = state.ids.filter(id => id !== entityId);
  state.entities = removeProperty(entityId, state.entities);
  state.selectedEntityId = oldState.selectedEntityId !== entityId ? oldState.selectedEntityId : null;
  return state;
}

export function insertReplaceState<T extends { id: number | string; object: string }>(
  oldState: ESInterface<T>,
  newState: ESInterface<T>,
  toStart: boolean = false
): ESInterface<T> {
  const newIds = toStart === false ? arrayMerge(oldState.ids, newState.ids) : arrayMerge(newState.ids, oldState.ids);
  const idsToReplace = newState.ids.filter(id => oldState.ids.includes(id));
  const entities = deepCopyEntity(oldState.entities, newState.entities, idsToReplace);

  return { ...oldState, ids: newIds, entities: entities };
}

export function selectEntity<T extends Entity>(oldState: ESInterface<T>, entityId: number): ESInterface<T> {
  return {
    ids: oldState.ids,
    entities: oldState.entities,
    selectedEntityId: entityId
  };
}

export function moveEntityAfter<T extends Entity>(
  oldState: ESInterface<T>,
  entityId: number,
  nexEntityId: number
): ESInterface<T> {
  const newIds = oldState.ids.filter(id => id !== entityId);
  const index = newIds.findIndex(id => id === nexEntityId);

  if (index >= 0) {
    newIds.splice(index, 0, entityId);
  } else {
    newIds.push(entityId);
  }

  return {
    ids: newIds,
    entities: oldState.entities,
    selectedEntityId: oldState.selectedEntityId
  };
}

export const mergeStates = (firstState = {}, secondState = {}) => {
  const stateCopy = Object.assign({}, firstState);
  for (const enityCode of Object.keys(secondState)) {
    const prevState = stateCopy[enityCode] || new EntityStateModel();
    stateCopy[enityCode] = insertReplaceState(prevState, secondState[enityCode]);
  }
  return stateCopy;
};

const mutableRemove = (removePayload: RemoveEntityPayload, state) => {
  const { entityName, predicate } = removePayload;
  if (state[entityName]) {
    state[entityName].ids
      .filter(id => state[entityName].entities[id] && predicate(state[entityName].entities[id]))
      .forEach(id => (state[entityName] = deleteEntityState(state[entityName], id)));
  }
  return state;
};

export const removeEntityFromStore = (removePayload: RemoveEntityPayload, state) => {
  const stateCopy = Object.assign({}, state);

  return mutableRemove(removePayload, stateCopy);
};

export const removeEntitiesFromStore = (removePayloads: RemoveEntityPayload[], state) => {
  const stateCopy = Object.assign({}, state);

  return removePayloads.reduce((removedState, removePayload) => mutableRemove(removePayload, removedState), stateCopy);
};

/**
 * @whatItDoes remove on entity from ESInterface
 * ## Example:
 * ```typescript
 * case githubCommit.DELETE_TASK_COMMIT: {
 *    return removeOne(state, action.payload.commitId);
 * }
 * ```
 *
 * @param removeFrom old State
 * @param entityId id of entity
 */
export function removeOne<S extends any, T extends Entity>(removeFrom: S, entityId: number | string): S {
  const removeFromCopy: S = Object.assign({}, removeFrom);
  removeFromCopy.ids = removeFromCopy.ids.filter(id => id !== entityId);
  removeFromCopy.entities = { ...removeFrom.entities, [entityId]: undefined };
  removeFromCopy.selectedEntityId = removeFrom.selectedEntityId !== entityId ? removeFrom.selectedEntityId : undefined;
  return removeFromCopy;
}

export function removeArray<S extends any, T extends Entity>(removeFrom: S, entityIds: Array<number | string>): S {
  const removeFromCopy: S = Object.assign({}, removeFrom);
  removeFromCopy.ids = removeFromCopy.ids.filter(id => !entityIds.includes(+id));
  removeFromCopy.entities = {};
  removeFromCopy.ids.forEach(id => (removeFromCopy.entities[id] = removeFrom.entities[id]));
  removeFromCopy.selectedEntityId = entityIds.includes(removeFrom.selectedEntityId)
    ? undefined
    : removeFrom.selectedEntityId;
  return removeFromCopy;
}

export function removeMany(entities: WebSocketDeleteAnswer[], state: AppState): AppState {
  const newState = { ...state };
  entities.forEach(entity => {
    const plCode = entityCodesPlMap[entity.object];
    const newPartOfState = <EntityStateModel<Entity>>{ ...newState[plCode] };
    if (entity.ids.includes(newPartOfState.selectedEntityId)) {
      newPartOfState.selectedEntityId = undefined;
    }
    newPartOfState.ids = (newPartOfState.ids || []).filter(id => !entity.ids.includes(id));
    newPartOfState.entities = { ...newPartOfState.entities };
    entity.ids.forEach(id => (newPartOfState.entities[id] = undefined));
    newState[plCode] = newPartOfState;
  });
  return newState;
}
