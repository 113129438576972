import { Component, ChangeDetectionStrategy, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SearchInLeftMenuService } from '../../shared/services/app/search-in-left-menu.service';

@Component({
  selector: 'search-in-left-menu',
  templateUrl: './search-in-left-menu.component.html',
  styleUrls: ['./search-in-left-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchInLeftMenuComponent implements OnInit {
  @Output() onEsc = new EventEmitter();
  @ViewChild('search_input') searchInput: ElementRef;

  public stringSearch$: BehaviorSubject<string>;

  constructor(private _searchInLeftMenuService: SearchInLeftMenuService) {}

  ngOnInit() {
    this.stringSearch$ = this._searchInLeftMenuService.stringSearch$;
  }

  onWrite(string) {
    const searchText = string.trim();
    this._searchInLeftMenuService.setStringSearch = searchText;
    if (searchText.length === 0) {
      this.searchInput.nativeElement.value = '';
    }
  }

  onClearSearch() {
    this._searchInLeftMenuService.setStringSearch = '';
    this.searchInput.nativeElement.focus();
  }
  onEscDown(event) {
    event.stopImmediatePropagation();
    this.onClearSearch();
    this.onEsc.emit();
  }
}
