
import {combineLatest as observableCombineLatest,  Observable ,  Subscription } from 'rxjs';

import {distinctUntilChanged, map, tap, take, switchMap, filter} from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Swimlane } from '../../../interfaces';
import { AppState } from '../../../ngrx/state';
import { isPresent } from '../../../../helpers';
import { getSwimlanesByBoard } from '../../../ngrx/reducers/swimlane.reducer';
import {
  DefaultSwimlanesFilterResetAction,
  DefaultTaskSwimlanesFilterToggleItemAction
} from '../../../ngrx/actions/task-filters/default-swimlanes-filter.actions';
import { getDefaultSwimlanesFilterIds } from '../../../ngrx/reducers/task-filters/default-swimlanes-filter.reducer';

@Component({
  selector: 'default-swimlanes-filter',
  templateUrl: './default-swimlanes-filter.component.html',
  styleUrls: ['./default-swimlanes-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultSwimlanesFilterComponent implements OnInit, OnDestroy {
  @Input() boardId$: Observable<number>;
  subs: Subscription[] = [];

  public boardSwimlanes$: Observable<Swimlane[]>;
  public selectedSwimlanes$: Observable<Swimlane[]>;
  public selectedSwimlanesIds$: Observable<number[]>;
  public storeSwimlanesFilterValue$: Observable<number[]>;

  public showSwimlanesPopUp = false;
  public visibleFilter$: Observable<boolean>;

  constructor(private _store: Store<AppState>) {}

  ngOnInit(): void {
    this.storeSwimlanesFilterValue$ = this._store.pipe((getDefaultSwimlanesFilterIds));

    this.boardSwimlanes$ = this.boardId$.pipe(
      filter(isPresent),
      distinctUntilChanged(),
      switchMap(id => this._store.pipe((getSwimlanesByBoard(id)))),);

    this.visibleFilter$ = this.boardSwimlanes$.pipe(
      map(list => list && list.length > 1),
      distinctUntilChanged(),
      tap(visible => {
        if (!visible) {
          this.onHideSwimlanesPopup();
        }
      }),);

    this.selectedSwimlanes$ = observableCombineLatest(
      this.storeSwimlanesFilterValue$,
      this.boardSwimlanes$,
      (selectedIds: number[], swimlanes: Swimlane[]) => {
        return {
          selectedIds: selectedIds,
          swimlanes: swimlanes.filter((swimlane: Swimlane) => selectedIds.includes(swimlane.id))
        };
      }
    ).pipe(
      tap(({ selectedIds, swimlanes }) => {
        if (selectedIds.length && !swimlanes.length) {
          this._store.dispatch(new DefaultSwimlanesFilterResetAction());
        }
      }),
      map(({ swimlanes }) => swimlanes),);

    this.selectedSwimlanesIds$ = this.selectedSwimlanes$.pipe(map((swimlane: Swimlane[]) => swimlane.map(item => item.id)));
  }

  onAddSwimlane(swimlane: Swimlane) {
    this.selectedSwimlanesIds$.pipe(take(1)).subscribe(ids => {
      this._store.dispatch(new DefaultTaskSwimlanesFilterToggleItemAction([...ids, swimlane.id]));
    });
  }

  onRemoveSwimlane(swimlane: Swimlane) {
    this.selectedSwimlanesIds$.pipe(take(1)).subscribe(ids => {
      this._store.dispatch(new DefaultTaskSwimlanesFilterToggleItemAction(ids.filter(id => id !== swimlane.id)));
    });
  }

  onShowSwimlanesPopup() {
    this.showSwimlanesPopUp = true;
  }
  onHideSwimlanesPopup() {
    this.showSwimlanesPopUp = false;
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
