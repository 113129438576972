import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormV2Service } from '../../../services/form-v2.service';

@Component({
  selector: 'form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormErrorsComponent {
  constructor(public _formService: FormV2Service) {}
}
