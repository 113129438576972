import {
  combineLatest,
  map,
  pluck,
  refCount,
  publishReplay,
  filter,
  switchMap,
  distinctUntilChanged
} from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { EstimationType } from '../../../constants';
import { Observable, BehaviorSubject } from 'rxjs';
import { SprintsTasks } from '../../../interfaces/sprints-tasks';
import { AppState, EntityState } from '../../../ngrx/state';
import { Store } from '@ngrx/store';
import { getSprintTasksState, getVersionById } from '../../../ngrx/reducers/version.reducer';
import { getBoardById } from '../../../ngrx/reducers/board.reducer';
import { BACKEND_DATE_FORMAT } from '../../../libs/date-time-formatter/constants/date-time-formats';
import * as moment from 'moment-mini-ts';
import { fieldSum, isPresent, sortBy } from '../../../../helpers';

export enum BurnEventType {
  done = 'done',
  logwork = 'logwork'
}

export interface BurnEvent {
  date: string;
  type: BurnEventType;
  // hours or story points
  burn: number;
  sprintsTask: SprintsTasks;
  userId?: number;
}

interface Activity {
  type: 'done' | 'logwork';
  text: string;
}

interface Day {
  date: string;
  isDayOff: boolean;
}

const timestampToDate = (ts: number) =>
  moment
    .unix(ts)
    .startOf('day')
    .format(BACKEND_DATE_FORMAT);

@Component({
  selector: 'burndown-chart-wrapper',
  templateUrl: './burndown-chart-wrapper.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BurndownChartWrapper implements OnInit {
  @Input()
  set boardId(v: number) {
    this.boardId$.next(v);
  }

  @Input()
  set mode(v: EstimationType) {
    this.mode$.next(v);
  }

  @Input()
  set versionId(v: number) {
    this.versionId$.next(v);
  }

  public boardId$ = new BehaviorSubject(0);
  public versionId$ = new BehaviorSubject(0);
  public mode$ = new BehaviorSubject(EstimationType.storyPoints);

  public sumToBurn$: Observable<number>;
  public burnEvents$: Observable<BurnEvent[]>;

  public sprintTasks$: Observable<SprintsTasks[]>;
  public startTs$: Observable<number>;

  public endTs$: Observable<number>;
  public releasedDate$: Observable<number>;

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    const version$ = this.versionId$.pipe(
      distinctUntilChanged(),
      switchMap(versionId => this._store.pipe(getVersionById(versionId))),
      filter(isPresent)
    );
    const board$ = this.boardId$.pipe(
      distinctUntilChanged(),
      switchMap(boardId => this._store.pipe(getBoardById(boardId))),
      filter(isPresent)
    );

    this.sprintTasks$ = this.versionId$.pipe(
      combineLatest(
        this.boardId$,
        (versionId, boardId) =>
          versionId
            ? (sprintsTask: SprintsTasks) => sprintsTask.version === versionId // get sprint tasks for certain version
            : (sprintsTask: SprintsTasks) => sprintsTask.board === boardId && !sprintsTask.version // or get only for running sprint
      ),
      switchMap(filter =>
        this._store.select(getSprintTasksState).pipe(
          map(state =>
            EntityState.fromState(state)
              .filter(filter)
              .toArray()
          )
        )
      )
    );
    this.startTs$ = <Observable<number>>this.versionId$.pipe(
      switchMap(versionId => (versionId ? version$.pipe(pluck('startDate')) : board$.pipe(pluck('sprintStartDate'))))
    );
    this.endTs$ = <Observable<number>>this.versionId$.pipe(
      switchMap(versionId => (versionId ? version$.pipe(pluck('releasedDate')) : board$.pipe(pluck('sprintEndDate'))))
    );

    this.releasedDate$ = version$.pipe(pluck('releasedDate'));

    this.sumToBurn$ = this.mode$.pipe(
      switchMap((mode: EstimationType) =>
        this.sprintTasks$.pipe(
          map(fieldSum(mode === EstimationType.storyPoints ? 'storyPoints' : 'remainingEstimate')),
          map(x => (mode === EstimationType.storyPoints ? x : x / 3600))
        )
      )
    );
    this.burnEvents$ = this.mode$.pipe(
      switchMap((mode: EstimationType) =>
        this.sprintTasks$.pipe(
          map(sprintsTasks =>
            sprintsTasks
              .reduce((acc, sprintsTask: SprintsTasks) => {
                if (mode === EstimationType.storyPoints) {
                  if (sprintsTask.doneDate) {
                    acc.push({
                      sprintsTask: sprintsTask,
                      date: timestampToDate(sprintsTask.doneDate),
                      type: BurnEventType.done,
                      burn: sprintsTask.storyPoints
                    });
                  }
                }

                if (mode === EstimationType.hours) {
                  const burnLimitSec = sprintsTask.remainingEstimate;
                  let alreadyBurntSec = 0;
                  if (sprintsTask.remainingEstimate) {
                    (sprintsTask.worklogs || [])
                      .filter(logwork => (sprintsTask.doneDate ? logwork.logDate < sprintsTask.doneDate : true))
                      .some(logwork => {
                        const limitReached = alreadyBurntSec + logwork.loggedTime >= burnLimitSec;
                        const toBurnNowSec = limitReached ? burnLimitSec - alreadyBurntSec : logwork.loggedTime;
                        alreadyBurntSec += toBurnNowSec;
                        acc.push({
                          sprintsTask: sprintsTask,
                          date: timestampToDate(logwork.logDate),
                          type: BurnEventType.logwork,
                          userId: logwork.userId,
                          burn: toBurnNowSec / 3600
                        });
                        return limitReached;
                      });
                  }
                  if ((alreadyBurntSec !== burnLimitSec || !burnLimitSec) && sprintsTask.doneDate) {
                    acc.push({
                      sprintsTask: sprintsTask,
                      date: timestampToDate(sprintsTask.doneDate),
                      type: BurnEventType.done,
                      burn: (burnLimitSec - alreadyBurntSec) / 3600
                    });
                  }
                }

                return acc;
              }, [])
              .sort(sortBy('date', 'asc'))
          )
        )
      ),
      publishReplay(),
      refCount()
    );
  }
}
