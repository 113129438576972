import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { IconArrowFilledComponent } from './icon-arrow-filled/icon-arrow-filled.component';
import { IconPlusComponent } from './icon-plus/icon-plus.component';
import { IconResizeComponent } from './icon-resize/icon-resize.component';
import { IconLinkTaskComponent } from './icon-link-task/icon-link-task.component';
import { IconWarnComponent } from './icon-warn/icon-warn.component';
import { IconInfoComponent } from './icon-info/icon-info.component';

const sharedComponents = [
  IconInfoComponent,
  IconLinkTaskComponent,
  IconArrowFilledComponent,
  IconPlusComponent,
  IconWarnComponent,
  IconResizeComponent
];

@NgModule({
  imports: [CommonModule],
  declarations: [...sharedComponents],
  exports: [...sharedComponents]
})
export class AtlazIconsModule {}
