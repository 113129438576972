import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';

import { DropdownSearchListItem } from './dropdown-search-list';

@Component({
  selector: 'dropdown-serch-list',
  templateUrl: './dropdown-search-list.component.html',
  styleUrls: ['./dropdown-search-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownSearchListComponent implements OnChanges {
  @Input() items: DropdownSearchListItem[];
  @Input() topItems: DropdownSearchListItem[];
  @Input() selectedId: number;
  @Output() changeValue = new EventEmitter<DropdownSearchListItem>();

  public isItemsPopupVisible = false;
  public selectedItem: DropdownSearchListItem;
  constructor() {
    this.selectedId = null;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.selectedId) {
      this.selectedItem = this.getItem(this.selectedId);
    }
  }

  onChangeValue(item: DropdownSearchListItem) {
    this.selectedId = item.id;
    this.selectedItem = item;
    this.changeValue.emit(item);
  }

  onOpenItemsPopup() {
    this.isItemsPopupVisible = true;
  }

  onCloseItemsPopup() {
    this.isItemsPopupVisible = false;
  }

  onSelectItem($event, item: DropdownSearchListItem) {
    $event.stopPropagation();
    this.onCloseItemsPopup();
    this.onChangeValue(item);
  }

  private getItem(id: number): DropdownSearchListItem {
    return this.items.find(item => item.id === id);
  }
}
