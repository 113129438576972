import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { Task } from '../../../interfaces';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { TaskEditAction } from '../../../ngrx/actions/task.actions';
import { AppState } from '../../../ngrx/state';
import { Store } from '@ngrx/store';

@Component({
  selector: 'sidebar-duedate',
  templateUrl: './sidebar-duedate.component.html',
  styleUrls: ['./sidebar-duedate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarDuedateComponent implements OnChanges {
  @Input() task: Task;
  @Input() editPermissions = false;

  public isTaskOverdone: boolean;
  public isTaskCloseToOverdone: boolean;
  public daysLeft: number;

  public isEditDuePopupVisible$ = new BehaviorSubject(false);

  constructor(private _store: Store<AppState>) {}

  ngOnChanges() {
    this.dueDateCalculate();
  }

  dueDateCalculate() {
    const currentTimeInSeconds = moment().unix();
    const diffTime = this.task.dueDate - currentTimeInSeconds;
    const secondsInOneDay = 86400;
    const daysLeft = Math.ceil(diffTime / secondsInOneDay);

    this.isTaskOverdone = currentTimeInSeconds > this.task.dueDate;
    this.isTaskCloseToOverdone = diffTime < secondsInOneDay && diffTime > 0;
    this.daysLeft = daysLeft;
  }

  onOpenDuedatePopup() {
    if (!this.editPermissions) {
      return false;
    }
    this.isEditDuePopupVisible$.next(true);
  }

  onCloseDuedatePopup() {
    this.isEditDuePopupVisible$.next(false);
  }

  onChangeDuedate(val) {
    this._store.dispatch(
      new TaskEditAction({
        id: this.task.id,
        dueDate: val
      })
    );
  }
}
