import { Component, Input, OnChanges } from '@angular/core';
import { Task } from '../../interfaces';
import { IS_COVER, TASK_PL, TASKS_ATTACHMENT_PL } from '../../constants/entity';
import { HandleResponseAction } from '../../ngrx/actions/root.action';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/state/app-state';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { toDashedFromCamelCase } from '../../../helpers/string';
import { JsonApiSingeModelResponse } from '../../shared/services/app/web-socket/http-response';
import { BugTrackerService } from '@atlaz/core/services/bag-tracker.service';
import { defaultMemoize } from 'reselect';

@Component({
  selector: 'task-cover',
  templateUrl: './task-cover.component.html',
  styleUrls: ['./task-cover.component.scss']
})
export class TaskCoverComponent implements OnChanges {
  @Input() task: Task;
  @Input() type: 'open' | 'preview' = 'open';
  @Input() editPermissions = false;

  public checkInvalidTaskCover = defaultMemoize((taskId, coverTaskAttachmentId, coversObj) => {
    if (coverTaskAttachmentId && !coversObj) {
      this._bugTracker.warn('Broken covers for task! TaskId: ', taskId);
    }
  });

  constructor(
    private _atlazApi: AtlazApiV2Service,
    private _store: Store<AppState>,
    private _bugTracker: BugTrackerService
  ) {}

  get hasCover() {
    return this.task.coverAttachmentId && this.task.covers;
  }

  ngOnChanges() {
    this.checkInvalidTaskCover(this.task.id, this.task.coverAttachmentId, this.task.covers);
  }

  /**
   *
   *  onCheckImage function does nothing,
   *  but exists for checking parent nodes to update state
   *  once image is loaded
   *
   *
   */

  onCheckImage() {
    return;
  }

  onClickRemoveCover() {
    this._atlazApi
      .patch([toDashedFromCamelCase(TASKS_ATTACHMENT_PL), { expand: [TASK_PL] }], {
        id: this.task.coverAttachmentId,
        [IS_COVER]: 0
      })
      .subscribe((resp: JsonApiSingeModelResponse<any>) => {
        this._store.dispatch(new HandleResponseAction(resp));
      });
  }
}
