import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Task } from '../../../../interfaces';

@Component({
  selector: 'sidebar-estimate-popup',
  templateUrl: './sidebar-estimate-popup.component.html',
  styleUrls: ['./sidebar-estimate-popup.component.scss']
})
export class SidebarEstimatePopupComponent {
  @Input() task: Task;
  @Input() isStoryPointsMode: boolean;

  @Output() close = new EventEmitter();
  @Output() resetFocus = new EventEmitter();

  get title() {
    return this.isStoryPointsMode
      ? this.task.storyPoints ? 'Edit Story Points' : 'Add Story Points'
      : this.task.estimate ? 'Edit Hours Estimate' : 'Add Hours Estimate';
  }
  constructor() {}

  onCloseEstimatePopup() {
    this.close.emit();
  }

  onChildChangedFocus(event) {
    this.resetFocus.emit(event);
  }
}
