<div class="wip_limits_stripe"
     [ngClass]="{
        'title_stripe' : !withDetails,
        'over_limit' : overLimit$ | async,
        'less_limit' : lessLimit$ | async,
        'half_width': (column.type === 'subcolumn_inprogress' && !withDetails)
     }">
  <div *ngIf="withDetails">
    <span *ngIf="lessLimit$ | async"> {{column.minTasksCount - (count$ | async)}} More Tasks Needed</span>
    <span *ngIf="overLimit$ | async"> {{(count$ | async) - column.maxTasksCount}} Tasks Over The Limit</span>
    <span *ngIf="inLimit$ | async"> WIP limits were set: {{column.minTasksCount}} min/{{column.maxTasksCount}} max </span>
  </div>
</div>


