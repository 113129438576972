<div class="checklist_item_block" [class.no_bg_hover]="isEditMode" [attr.draggable-disabled]="isEditMode">
  <div [ngClass]="{ checkbox: true, editable: editPermissions }" (click)="onChangeCheckboxState()"
       [class.checked_checkbox]="checkbox.completed"></div>
  <label *ngIf="!isEditMode"
         [ngClass]="{editable: editPermissions}"
         (click)="onEdit($event)"
         [class.checked_label]="checkbox.completed"
  >
    <smart-text [text]="checkbox.name"></smart-text>
  </label>

  <div *ngIf="isEditMode">
    <checklist-item-form
      [checklistId]="checkbox.checklist"
      [checkbox]="checkbox"
      (submitForm)="onChangeCheckbox($event)"
      (cancel)="onCancel()"
    ></checklist-item-form>
  </div>
  <checklist-item-menu
    *ngIf="editPermissions"
    [checkListItem]="checkbox"
    (deleteItem)=onDeleteCheckbox(checkbox)
  ></checklist-item-menu>
</div>
