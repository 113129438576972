import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BacklogAddTaskFormComponent } from './backlog-add-task-popup/backlog-add-task-form/backlog-add-task-form.component';
import { BacklogAddTaskPopupComponent } from './backlog-add-task-popup/backlog-add-task-popup.component';
import { SharedModule } from '../../shared/shared.module';
import { AtlazGuiModule } from '../../atlaz-gui/atlaz-gui.module';
import { BoardFieldsManagerComponent } from './board-fields-manager/board-fields-manager.component';
import { NewCustomFieldComponent } from './board-fields-manager/new-custom-field/new-custom-field.component';
import { DropdownOptionsSetterComponent } from './board-fields-manager/new-custom-field/dropdown-options-setter/dropdown-options-setter.component';
import { ADragModule } from '../../shared/a-drag/a-drag.module';

const sharedComponents = [
  BacklogAddTaskPopupComponent,
  BacklogAddTaskFormComponent,
  BoardFieldsManagerComponent,
  DropdownOptionsSetterComponent,
  NewCustomFieldComponent
];

@NgModule({
  imports: [CommonModule, SharedModule, AtlazGuiModule, ADragModule],
  declarations: [...sharedComponents],
  exports: [...sharedComponents]
})
export class BoardSharedModule {}
