<a-context-container>
  <div a-popup [title]="'Export as PDF'" (close)="onClose()">
    <div class="popup_content">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <form-errors></form-errors>
        <div class="row">
          <label for="paper-format" >Page Size</label>
          <select id="paper-format" name="paper-format" [formControl]="form.get('paperFormat')">
            <option value="original">Full Size</option>
            <option value="letter">Letter</option>
            <option value="legal">Legal</option>
            <option value="tabloid">Tabloid</option>
            <option value="ledger">Ledger</option>
            <option value="A4">A4</option>
            <option value="A3">A3</option>
            <option value="A2">A2</option>
            <option value="A1">A1</option>
            <option value="A0">A0</option>
          </select>
        </div>
        <div class="row" *ngIf="landscalablePaper.includes(form.get('paperFormat').value)">
          <label for="is-landscape">Orientation</label>
          <select id="is-landscape" name="is-landscape" [formControl]="form.get('isLandscape')">
            <option [ngValue]="true">Landscape</option>
            <option [ngValue]="false">Portrait</option>
          </select>
        </div>

        <div class="row buttons">
          <button a-button type="submit" class="btn_primary" [pending]="isPending$ | async" (click)="onSubmit()">Export</button>
          <button a-button type="button" class="btn_text" (click)="onClose()">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</a-context-container>
