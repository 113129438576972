import { filter } from 'rxjs/operators';
import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { isPresent } from '../../../helpers';
import { BehaviorSubject } from 'rxjs';

@Directive({
  selector: '[autosize]'
})
export class AutosizeDirective implements OnInit, OnDestroy {
  @Input() checkSize$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private sub;

  constructor(public element: ElementRef) {}

  ngOnInit(): void {
    this.adjust();
    if (this.checkSize$) {
      this.sub = this.checkSize$.pipe(filter(isPresent)).subscribe(() => {
        this.adjust();
      });
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  @HostListener('input', ['$event.target'])
  onInput(textArea: HTMLTextAreaElement): void {
    this.adjust();
  }

  adjust(): void {
    const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0) * 0.8;
    const el = this.element.nativeElement;
    if (el.scrollHeight + el.clientTop * 2 > h + 19) {
      /* 19 - is a line height. Now it's fixed,
      *  but if fixed won't be acceptable you should replace it by
      *  window.getComputedStyle(el).getPropertyValue('line-height')
      * */
      if (el.style.overflow !== 'auto') {
        el.style.overflow = 'auto';
      }
      if (el.style.height !== h + 'px') {
        el.style.height = h + 'px';
      }
      return;
    }
    el.style.overflow = 'hidden';
    el.style.height = 'auto';
    el.style.height = el.scrollHeight + el.clientTop * 2 + 'px';
  }
}
