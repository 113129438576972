import { take } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { RouterNavigateService } from '../../../shared/services/router-navigate.service';
import { AppState } from '../../../ngrx/state';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Observer } from 'rxjs';
import { Board } from '../../../interfaces';
import { boardType } from '../../../constants';
import { getBoardById } from '../../../ngrx/reducers/board.reducer';
import { SegmentService } from '../../../atlaz-bnp/services/intergations/segment/segment.service';
import {
  FormComponent,
  FormSaveType,
  FormServiceParams,
  FormV2Service
} from '../../../shared/services/form-v2.service';
import {
  JsonApiSingeModelResponse,
  jsonApiToSingleEntity
} from '../../../shared/services/app/web-socket/http-response';
import { PaywallService } from '../../../libs/paywall/paywall.service';
import { HandleResponseAction } from '../../../ngrx/actions/root.action';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'push-all-tasks-popup',
  templateUrl: './push-all-tasks-popup.component.html',
  styleUrls: ['./push-all-tasks-popup.component.scss'],
  providers: [FormV2Service]
})
export class PushAllTasksPopupComponent implements OnInit, FormComponent {
  formObserver: Observer<any> = {
    next: (resp: JsonApiSingeModelResponse<any>) => {
      this._store.dispatch(new HandleResponseAction(resp));
      let board: Board;
      if (this.target === this.NEW_BOARD) {
        board = jsonApiToSingleEntity(resp);
        this._segment.boardCreated(board.type, 'pushAllTasks');
      } else {
        this._store
          .pipe(getBoardById(this.form.value['board']), take(1))
          .subscribe((needle: Board) => (board = needle));
      }
      if (board) {
        this._toastr.success('The tasks were pushed into ' + board.name + ' Board');
      }
      this.onClose();
    },
    error: error => {},
    complete: () => {}
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.add,
    entityToEdit: 'tasks-push-all',
    formObserver: this.formObserver,
    prepareFormValue: (value: {}) => {
      value['sourceColumn'] = this._activatedRoute.snapshot.params['columnId'];
      value['id'] = value['sourceColumn'];
      return value;
    }
  };

  availableTypes = Object.keys(boardType).filter(key => boardType[key] !== boardType.backlog);

  constructor(
    private _routerNav: RouterNavigateService,
    public _formService: FormV2Service,
    private _toastr: ToastrService,
    private _store: Store<AppState>,
    private _segment: SegmentService,
    private _paywall: PaywallService,
    private _activatedRoute: ActivatedRoute
  ) {}

  public NEW_BOARD = 'NEW_BOARD';
  public EXIST_ONE = 'EXIST_ONE';

  form: FormGroup;
  target;

  ngOnInit(): any {
    this.target = this.NEW_BOARD;
  }

  onSubmit() {
    this.formServiceParams.saveType = this.target === this.NEW_BOARD ? FormSaveType.add : FormSaveType.edit;
    this._formService.initFormParams(this.form, this.formServiceParams);
    this._formService.markAsDirty();
    if (this.form.valid) {
      this._formService.submit();
    }
  }

  onClose() {
    this._routerNav.deactivatePopupOutlet();
  }
}
