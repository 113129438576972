import { of as observableOf, Observable } from 'rxjs';
import { Component, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';

import { Board, Column, Swimlane } from '../../interfaces';

import { columnKinds, COLUMN_PL } from '../../constants';
import { AppState } from '../../ngrx/state';
import { Store } from '@ngrx/store';
import { getEntitiesByIds } from '../../ngrx/functions/selectors';
import { isCompositeColumn } from '../../../helpers/column';
import { inputChanged, isPresent, trackById } from '../../../helpers';

@Component({
  selector: 'column-detail',
  templateUrl: './column-detail.component.html',
  styleUrls: ['./column-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnDetailComponent implements OnChanges {
  @Input() column: Column;
  @Input() parentColumn: Column;
  @Input() swimlane: Swimlane;
  @Input() scoreMap;
  @Input() board: Board;
  @Input() activeUserId;
  @Input() boardUsersMap;
  @Input() boardLabelsMap;
  @Input() newNotifyMap;

  public columnKinds = columnKinds;
  public subColumns$: Observable<Column[]>;
  public trackTasksById = trackById;

  constructor(private _store: Store<AppState>) {}

  ngOnChanges(changes: SimpleChanges) {
    if (inputChanged('column')) {
      this.subColumns$ =
        isPresent(this.column) && isCompositeColumn(this.column)
          ? <Observable<Column[]>>this._store.pipe(getEntitiesByIds(COLUMN_PL, this.column.subColumnsIds))
          : observableOf([]);
    }
  }
}
