import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { of } from 'rxjs';

import { toDashedFromCamelCase } from '../../../../../helpers';
import { BITBUCKET_ASSOCIATED_REPOSITORY_PL } from '../../../../constants';
import { GetCompleteAction, HandleResponseAction, NoopAction, UndoAction } from '../../../../ngrx/actions/root.action';
import { AppState } from '../../../../ngrx/state/';
import { jsonApiToEntityState } from '../../../../shared/services/app/web-socket/http-response';
import { AtlazApiV2Service } from '../../../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { ESBitbucketAssociatedRepository } from '../../models/git-repository';
import * as bitbucketAssociatedRepository from './bitbucket-associated-repository.actions';
import { map ,  switchMap ,  mapTo } from 'rxjs/operators';

@Injectable()
export class BitbucketAssociatedRepositoryEffects {
  private key = toDashedFromCamelCase(BITBUCKET_ASSOCIATED_REPOSITORY_PL);

  @Effect()
  getMany$ = this.data.fetch(bitbucketAssociatedRepository.GET_INDEX, {
    run: (action, state) => {
      return this.api.get([this.key]).pipe(
        map(jsonApiToEntityState),
        switchMap((newState: ESBitbucketAssociatedRepository) => {
          const actions = [
            new GetCompleteAction(newState),
            new bitbucketAssociatedRepository.GetIndexComplete(newState)
          ];
          return of(...actions);
        })
      );
    },
    onError: (action, error) => {
      this.defaultErrorHandler(action, error);
      return new bitbucketAssociatedRepository.GetIndexFail();
    }
  });

  @Effect()
  remove$ = this.data.optimisticUpdate(bitbucketAssociatedRepository.REMOVE, {
    run: (action: bitbucketAssociatedRepository.Remove, store) =>
      this.api.deleteRequest([this.key, action.payload.id]).pipe(mapTo(new NoopAction())),
    undoAction: (action, err) => {
      console.error('effect', action, err);
      return new UndoAction(action);
    }
  });

  @Effect()
  create$ = this.data.pessimisticUpdate<bitbucketAssociatedRepository.Create>(bitbucketAssociatedRepository.CREATE, {
    run: (action, store) => this.api.post(this.key, action.payload).pipe(map(resp => new HandleResponseAction(resp))),
    onError: (action, error) => {
      console.error('effect', action, error);
    }
  });

  constructor(private data: DataPersistence<AppState>, private api: AtlazApiV2Service) {}

  defaultErrorHandler(action: any, err): void {
    console.error('bitbucket-repository-effects', err, 'action', action);
  }
}
