const getScrollbarWidth = function() {
  let div = document.createElement('div'),
    width;
  div.innerHTML =
    '<div style="width:50px;height:50px;position:absolute;left:-50px;top:-50px;overflow:auto;"><div style="width:1px;height:100px;"></div></div>';
  div = <HTMLDivElement>div.firstChild;
  document.body.appendChild(div);
  width = div.offsetWidth - div.clientWidth;
  document.body.removeChild(div);
  return width;
};

export const scrollbarWidth = getScrollbarWidth();
