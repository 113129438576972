import { Observable, of as observableOf } from 'rxjs';
import { debounceTime, filter, switchMap, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { BOARD, BOARD_PL, defaultExpand, TASK_PL, VERSION_PL } from '../../../constants';
import { getTaskById } from '../../../ngrx/reducers/task.reducer';
import { HandleResponseAction } from '../../../ngrx/actions/root.action';
import { isPresent } from '../../../../helpers';
import { AtlazApiV2Service } from '../../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { MarkAsLoaded, MarkAsUnloaded, MarkAsUnloadedTask } from '../../../loaded-data/store/loaded-data.actions';
import { Task } from '../../../interfaces';
import { BOARD_ASSETS, isNotLoaded, PROJECT_VERSIONS } from '../../../loaded-data/store/loaded-data.reducer';
import { SEARCH } from '../../../path.routing';
import { STATUS_CODES } from '../../../permissions/interfaces/page-status.interface';
import { OpenedTaskMarkAsLoadedAction } from '../../ngrx/actions/opened-task.action';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class TaskDetailPageRelatedDataService {
  constructor(private _atlazApi: AtlazApiV2Service, private _store: Store<AppState>) {}

  loadTaskDetails(taskId): Observable<any> | Promise<any> | any {
    if (!taskId) {
      return observableOf(false);
    }

    this._store
      .pipe(
        getTaskById(taskId),
        take(1),
        filter(isPresent),
        debounceTime(1),
        tap((task: Task) => {
          const boardId = task.board;
          if (boardId) {
            this.loadBoard(boardId, taskId);
          }
        }),
        debounceTime(0)
      )
      .subscribe((task: Task) => {
        if (task.project) {
          this.loadProjectVersions(task.project);
        }
      });

    return observableOf(taskId);
  }

  public loadBoard(boardId, taskId?) {
    if (!this._loadingBoards[boardId]) {
      this._store
        .select(isNotLoaded(BOARD_ASSETS, boardId))
        .pipe(
          take(1),
          filter(isPresent),
          tap(() => (this._loadingBoards[boardId] = true)),
          switchMap(() => this._atlazApi.get(BOARD_PL, { id: boardId, expand: defaultExpand[BOARD] }))
        )
        .subscribe(
          data => {
            this._store.dispatch(new HandleResponseAction(data));
            this._store.dispatch(new MarkAsLoaded({ name: BOARD_ASSETS, id: boardId }));
          },
          err => {
            console.warn(`TaskDetails: failed to loadBoard [${boardId}]`, err);
            if (taskId) {
              this._store.dispatch(new MarkAsUnloadedTask({ taskId }));
            }
            this._store.dispatch(new MarkAsUnloaded({ name: BOARD_ASSETS, id: boardId }));
          },
          () => delete this._loadingBoards[boardId]
        );
    }
  }

  private _loadingBoards = {};

  public loadTaskWithContext(
    taskId,
    expand = defaultExpand[SEARCH]
  ): Observable<{ statusCode: number | string; taskId: number }> {
    if (!this._loadingTask[taskId]) {
      this._loadingTask[taskId] = true;
      const result = new BehaviorSubject(null);
      this._atlazApi.get(TASK_PL, { id: taskId, expand: expand }).subscribe(
        data => {
          this._store.dispatch(new HandleResponseAction(data));
          this._store.dispatch(new OpenedTaskMarkAsLoadedAction(taskId));
          result.next({ statusCode: STATUS_CODES.OK, taskId: taskId });
        },
        err => {
          console.warn(`TaskDetails: failed to loadTaskWithContext [${taskId}]`, expand, err);
          result.next({ statusCode: +err.status || err.status, taskId: taskId });
          delete this._loadingTask[taskId];
        },
        () => delete this._loadingTask[taskId]
      );
      return result;
    }
  }

  private _loadingTask = {};

  private loadProjectVersions(projectId) {
    this._store
      .select(isNotLoaded(PROJECT_VERSIONS, projectId))
      .pipe(take(1), filter(isPresent), switchMap(() => this._atlazApi.get(VERSION_PL, { project: projectId })))
      .subscribe(
        data => {
          this._store.dispatch(new HandleResponseAction(data));
          this._store.dispatch(new MarkAsLoaded({ name: PROJECT_VERSIONS, id: projectId }));
        },
        err => {
          this._store.dispatch(new MarkAsUnloaded({ name: PROJECT_VERSIONS, id: projectId }));
          console.warn(`TaskDetails: failed to ProjectVersions [${projectId}]`, err);
        }
      );
  }
}
