import { compose } from '@ngrx/store';
import { Action } from '../../actions/unsafe-action';
import { getEntitySelector, getAllEntitiesAsArray, mapEntityField } from '../../functions/selectors';
import { Task, QuickFilter } from '../../../interfaces';
import { removeProperty } from '../../../../helpers';

import { TaskFilter } from './index';
import { TaskQuickFilterActionTypes } from '../../actions/task-filters/task-quick-filter.actions';
import { TaskDefaultFilterActionTypes } from '../../actions/task-filters/task-default-filter.actions';

export interface State {
  ids: number[];
  entities: { [filterId: number]: TaskFilter };
}

const initialState: State = {
  ids: [],
  entities: {}
};

function createFilter(quickFilter: QuickFilter): TaskFilter {
  return { func: (task: Task) => activeFilter(task, quickFilter) };
}

function activeFilter(task: Task, quickFilter: QuickFilter): boolean {
  if (task && task.usersIds) {
    const isInField = fieldName => fieldValue =>
      quickFilter.fields[fieldName].length === 0 || quickFilter.fields[fieldName].includes(fieldValue);

    const hasMember = memberIds =>
      memberIds.length > 0 ? memberIds.some(isInField('members')) : quickFilter.fields.members.length === 0;

    const hasVersion = isInField('versions');
    return hasMember(task.usersIds) && hasVersion(task.version);
  }
}

function addFilter(state: State, quickFilter: QuickFilter) {
  return {
    ids: [...state.ids, quickFilter.id],
    entities: Object.assign({}, state.entities, { [quickFilter.id]: createFilter(quickFilter) })
  };
}

function removeFilter(state: State, quickFilter: QuickFilter) {
  return {
    ids: state.ids.filter(id => id !== quickFilter.id),
    entities: removeProperty(quickFilter.id, state.entities)
  };
}

export function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case TaskQuickFilterActionTypes.ADD_FILTER: {
      return addFilter(state, action.payload);
    }

    case TaskQuickFilterActionTypes.REMOVE_FILTER: {
      return removeFilter(state, action.payload);
    }

    case TaskQuickFilterActionTypes.TOGGLE: {
      const quickFilter: QuickFilter = action.payload;
      if (state.ids.includes(quickFilter.id)) {
        return removeFilter(state, quickFilter);
      } else {
        return addFilter(state, quickFilter);
      }
    }

    case TaskQuickFilterActionTypes.RESET: {
      return initialState;
    }

    case TaskDefaultFilterActionTypes.SET: {
      const qf = action['payload'].activeFilters.quickFilters;
      if (qf && qf.length) {
        state = {
          ids: [...qf.map(item => item.id)],
          entities: {}
        };
        qf.forEach(item => (state.entities[item.id] = createFilter(item)));
      } else {
        state = initialState;
      }
      return state;
    }

    default: {
      return state;
    }
  }
}

export const taskQuickFilters = compose(getAllEntitiesAsArray, getEntitySelector('taskQuickFilter'));
export const taskQuickFiltersIds = compose(mapEntityField('ids', []), getEntitySelector('taskQuickFilter'));
