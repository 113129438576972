import { Component, Input, OnChanges } from '@angular/core';

import { Column, Task } from '../../../interfaces';
import { columnKinds } from '../../../constants';
import { AppUrls } from '../../../app-urls';

@Component({
  selector: 'column-capacity-indicator',
  templateUrl: './column-capacity-indicator.component.html',
  styleUrls: ['./column-capacity-indicator.component.scss']
})
export class ColumnCapacityIndicatorComponent implements OnChanges {
  @Input() column: Column;
  @Input() boardTasks: Task[];
  @Input() isHeaderIndicator: boolean;

  public appUrls = AppUrls;
  public taskEstimate: number;
  public spentEstimate: number;

  ngOnChanges() {
    this.calculateTasksEstimate();
    this.calculateSpentEstimate();
  }

  public calculateTasksEstimate() {
    this.taskEstimate = this.boardTasks.reduce(this.sumEstimate.bind(this), 0);
  }

  public calculateSpentEstimate() {
    this.spentEstimate = this.column.backlogCapacity - this.taskEstimate;
  }

  public sumEstimate(estimate, task: Task) {
    const taskEstimate = +task.estimate || 0;
    return this.taskInColumn(this.column, task) && this.taskNotDone(task) ? estimate + taskEstimate : estimate;
  }

  public taskInColumn(column: Column, task: Task): boolean {
    return (
      task.column === column.id || (column.kind === columnKinds.composite && column.subColumnsIds.includes(task.column))
    );
  }

  public taskNotDone(task: Task) {
    return task.doneDate === 0;
  }
}
