import { of as observableOf } from 'rxjs';

import { distinctUntilChanged, switchMapTo, combineLatest, map, filter, switchMap, debounceTime } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CompanyService } from '../../../shared/services/app/company.service';
import { BillingPublicApiService } from '@atlaz/billing/shared/billing-public-api.service';
import { SegmentService } from './segment/segment.service';
import { isEquals, isPresent } from '../../../../helpers/object';
import { BillingSubscriptionEntity } from '@atlaz/billing/interfaces/billing-subscription';
import { Company } from '../../../interfaces/company';
import { IntercomService } from './intercom/intercom.service';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../../shared/services/app/auth.service';
import { User } from '../../../interfaces/user';
//import { Altum } from 'altumanalytics';
import { isProductionMode } from '../../../../environments/helper';

const ucFirst = (str: string) => {
  if (!str) {
    return str;
  }
  return str[0].toUpperCase() + str.slice(1);
};

@Injectable()
export class IntegrationsService {
  constructor(
    private _company: CompanyService,
    private _billing: BillingPublicApiService,
    private _intercom: IntercomService,
    private _segment: SegmentService,
    private _authService: AuthService,
    private _router: Router
  ) {}

  init() {
    this._intercom.init();
    this.initUserUpdates();
    this.initSegmentGroupUpdates();
  }

  initUserUpdates() {
    const user$ = this._authService.activeUser$.pipe(
      filter(isPresent),
      // to init crashTracker first
      debounceTime(0)
    );

    user$
      .pipe(
        map((user: User) => {
          return {
            id: user.id,
            name: user.fullname,
            email: user.email,
            createdAt: user.createdAt
          };
        }),
        distinctUntilChanged(isEquals)
      )
      .subscribe(traits => {
        this._segment.identify(traits.id, traits);
        //if (isProductionMode()) {
        //  Altum.init({ productId: 'HyggerProduction', groupId: this._company.domain, userId: traits.id });
        //}
      });

    user$.pipe(switchMapTo(this._router.events)).subscribe(val => {
      if (val instanceof NavigationEnd) {
        this._segment.fireUrlHasBeenChanged();
      }
    });
  }

  initSegmentGroupUpdates() {
    this._company.currentCompany$
      .pipe(
        filter(isPresent),
        filter(company => isPresent(company.status)),
        map((company: Company) => {
          return {
            companyId: company.id,
            companyName: company.name,
            createdAt: company.createdAt,
            status: ucFirst(company.status.toLowerCase()),
            companySize: company.companySize || '',
            softwarePreferences: company.softwarePreferences || ''
          };
        }),
        combineLatest(
          this._billing.hasAccess$.pipe(
            switchMap(
              hasAccess =>
                hasAccess
                  ? this._billing.billingSubscription$.pipe(
                      filter(isPresent),
                      map((billSub: BillingSubscriptionEntity) => {
                        return {
                          trialEndsAt: billSub.trialEnd,
                          trialOver: !billSub.isTrial,
                          activeUsersCount: billSub.usersCount
                        };
                      })
                    )
                  : observableOf({})
            )
          ),
          (companyInfo, billingInfo) => {
            return {
              ...billingInfo,
              ...companyInfo
            };
          }
        ),
        distinctUntilChanged(isEquals)
      )
      .subscribe(group => {
        this._segment.group(group.companyId, group);
      });
  }
}
