<div [ngClass]="{sidebar_block: true, editable: editPermissions}">
  <div [ngClass]="{project_overlay_link: true, editable: editPermissions}" (click)="onOpenProjectsPopup()"></div>
  <div class="task_sidebar_edit_link" (click)="onOpenProjectsPopup()">
    Edit
  </div>
  <h5 class="sidebar_block_title">Project</h5>

  <div class="project_title">{{taskProject.shortName}}</div>

  <sidebar-project-popup
    *ngIf="isProjectsPopupVisible"
    (close)="onCloseProjectsPopup()"
    [boardProjects]="boardProjects"
    [task]="task"
  ></sidebar-project-popup>

</div>
<div class="divider"></div>

