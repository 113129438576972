import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Task, Version } from '../../../../interfaces';
import { VersionAssignTaskAction } from '../../../../ngrx/actions/version.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../ngrx/state';

@Component({
  selector: 'sidebar-version-popup',
  templateUrl: './sidebar-version-popup.component.html',
  styleUrls: ['./sidebar-version-popup.component.scss']
})
export class SidebarVersionPopupComponent implements OnInit {
  @Input() task: Task;
  @Input() versions: Version[];
  @Input() possibleProjects: number[];
  @Input() isBatchActions: boolean;
  @Input() batchTasks: Task[];
  @Output() close = new EventEmitter();

  public initialValue: number;
  public selectedValue: number;
  public createVersion = false;

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    if (!this.isBatchActions) {
      this.initialValue = this.task.version || 0;
    } else {
      // every task has the same version
      this.initialValue =
        this.batchTasks.length &&
        this.batchTasks[0].version &&
        this.batchTasks.every(item => item.version === this.batchTasks[0].version)
          ? this.batchTasks[0].version
          : 0;
    }
    this.selectedValue = this.initialValue;
  }

  onCloseVersionPopup(versionValue) {
    const value = versionValue || this.selectedValue;
    if (this.initialValue === value) {
      this.close.emit();
      return;
    }
    let payload;
    if (this.isBatchActions) {
      const payloadIdsArr = this.batchTasks.filter(task => task.version !== value).map(task => task.id);

      if (value) {
        payload = { id: value, tasks: { add: payloadIdsArr } };
      } else {
        payload = { id: this.initialValue, tasks: { remove: payloadIdsArr } };
      }
    } else {
      payload = value
        ? { id: value, tasks: { add: [this.task.id] } }
        : { id: this.task.version, tasks: { remove: [this.task.id] } };
    }
    this._store.dispatch(new VersionAssignTaskAction(payload));
    this.close.emit();
  }

  onToggleCreateVersion() {
    this.createVersion = !this.createVersion;
  }

  onSelect(value) {
    this.selectedValue = value;
  }
}
