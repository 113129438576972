import { Action } from '../unsafe-action';
import { ReportMembersFilter } from '../../reducers/report-filters/report-members-filter.reducer';

export enum ReportMemberFilterActionTypes {
  APPLY = '[REPORT_MEMBER_FILTER] APPLY',
  RESET = '[REPORT_MEMBER_FILTER] RESET'
}

export class ReportMemberFilterApplyAction implements Action {
  readonly type = ReportMemberFilterActionTypes.APPLY;

  constructor(public payload: ReportMembersFilter) {}
}

export class ReportMemberFilterResetAction implements Action {
  readonly type = ReportMemberFilterActionTypes.RESET;
}

export type Actions = ReportMemberFilterApplyAction | ReportMemberFilterResetAction;
