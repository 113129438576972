<ng-container *ngIf="visibleFilter$ | async">
  <div class="default_projects_filter"
       (click)="onShowProjectsPopup()"
       [ngClass]="{'active': showProjectsPopUp}"
  >
    Projects
  </div>

  <div *ngFor="let project of selectedProjects$ | async"
       class="project_filter_tag"
       (click)="onRemoveProject(project)"
  >
    <p class="project_title">
      {{project.name}}
    </p>
    <div class="clear_block"></div>
  </div>

  <div a-context-popup *ngIf="showProjectsPopUp"
       [title]="'Project'"
       class="default_projects_list_popup"
       (close)="onHideProjectsPopup()"
  >
    <projects-filter-list
      [projects]="boardProjects$ | async"
      [selectedProjects]="selectedProjects$ | async"
      (addProject)="onAddProject($event)"
      (removeProject)="onRemoveProject($event)"
    ></projects-filter-list>
  </div>
</ng-container>
