<div class="timesheet-container" #container [mouseScroll]="true" [verticallScrollContainer]="container" >
  <h1>
    <span (click)="OnBack()" class="timesheet-back-button"></span>
    Timesheet
  </h1>
  <timesheet-filter
    (changeValue)="OnFilterChanged($event)"
    [fromDate]="filter.fromDate"
    [toDate]="filter.toDate"
    [projectId]="filter.projectId"
    [userId]="filter.userId"
    [groupId]="filter.groupId"
    [showTasks]="filter.showTasks"
    [showLogs]="filter.showLogs"
    [projects$]="projects$"
    [users$]="users$"
    [groups$]="groups$"
    [hideGroup]="hideGroupFilter"
    [hideUser]="hideMemberFilter"
  ></timesheet-filter>
  <timesheet-table
    [data]="timesheet$ | async"
    [showTasks]="filter.showTasks"
    [showLogs]="filter.showLogs"
    [loading]="loading"
    (scrollLogData)="onTableScrolled($event)"
    [logDataLeftInitialScroll]="tableLogDataInitialLeftScroll"
  ></timesheet-table>
</div>

