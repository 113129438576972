<input class="quick_search_projects"
       [focus]="true"
       placeholder="Search Project..."
       #input_seacrh_projects
       (keyup)="0"
>
<div class="projects_container">
  <div *ngFor="let project of projects | searchByField: 'name': input_seacrh_projects.value"
       class="project_title"
       (click)="onSetFilter(project)"
  >
    <p>{{project.name}}</p>
    <span class="check_label" *ngIf="checkSelectedProject[project.id]"></span>
  </div>
</div>
