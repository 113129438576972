import { Component, Input, Output, EventEmitter, OnChanges, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { QuickFilter, PartOfEntity, User, Version } from '../interfaces';
import { Entity } from '../interfaces/entity';

@Component({
  selector: 'quickfilter-form',
  templateUrl: './quickfilter-form.component.html',
  styleUrls: ['./quickfilter-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickfilterFormComponent implements OnInit, OnChanges {
  @Input() boardUsers: User[];
  @Input() quickFilter: QuickFilter = null;
  @Input() versions: Version[];

  @Output() addFilterEvent = new EventEmitter<PartOfEntity>();
  @Output() replaceFilterEvent = new EventEmitter<PartOfEntity>();

  public qFilterForm: FormGroup;
  public showMembersPopUp = false;
  public showVersionPopUp = false;

  get selectedVersionIds() {
    return this.qFilterForm.get('version').value.map(xs => xs.id);
  }

  constructor(private _fb: FormBuilder) {}

  ngOnInit() {
    this.qFilterForm.valueChanges.subscribe(b => console.log(b, 'b'));
  }

  ngOnChanges() {
    this.initQFilterForm();
  }

  initQFilterForm() {
    const selectedMembers = this.getFilterField(this.boardUsers, 'members');
    const selectedVersions = this.getFilterField(this.versions, 'versions');

    this.qFilterForm = this._fb.group({
      name: [this.quickFilter ? this.quickFilter.name : '', Validators.required],
      member: [selectedMembers],
      version: [selectedVersions]
    });
  }

  onSubmitQFilterForm() {
    if (!this.quickFilter) {
      this.addFilter();
    } else {
      this.replaceFilter();
    }
  }

  onRemoveVersion(version) {
    this.qFilterForm.get('version').patchValue(this.qFilterForm.get('version').value.filter(val => val !== version));
  }

  onVersionChanged(newIds) {
    const list = this.versions.filter(version => newIds.includes(version.id));
    this.qFilterForm.get('version').patchValue(list);
  }

  onCloseMembersPopUp() {
    this.showMembersPopUp = false;
  }

  onCloseVersionPopUp() {
    this.showVersionPopUp = false;
  }

  onMembersChanged(changes) {
    let list = [...this.qFilterForm.get('member').value];
    changes.add.forEach(id =>
      this.boardUsers.some(user => {
        if (user.id === id) {
          list.push(user);
          return true;
        }
      })
    );
    changes.remove.forEach(id => (list = list.filter(user => id !== user.id)));
    this.qFilterForm.get('member').patchValue(list);
  }

  onRemoveUser(id) {
    let list = [...this.qFilterForm.get('member').value];
    list = list.filter(user => id !== user.id);
    this.qFilterForm.get('member').patchValue(list);
  }

  onSetFocusInput(searchInput) {
    searchInput.focus(true);
  }

  addFilter() {
    this.addFilterEvent.emit(this.prepareForSave());
  }

  replaceFilter() {
    this.replaceFilterEvent.emit(this.prepareForSave());
  }

  public prepareForSave() {
    const fields = {
      members: this.qFilterForm.get('member').value.map(member => member.id),
      versions: this.qFilterForm.get('version').value.map(version => version.id)
    };

    const qFilterData = {
      name: this.qFilterForm.get('name').value,
      fields: fields
    };
    if (this.quickFilter) {
      qFilterData['id'] = this.quickFilter.id;
    }

    return qFilterData;
  }

  public getFilterField<T extends Entity>(list: T[], fieldName: string) {
    return this.quickFilter ? list.filter(item => this.quickFilter.fields[fieldName].includes(item.id)) : [];
  }
}
