import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ChecklistItem } from '../../../interfaces';
import { HotKey } from '../../../shared/directives';
import { KeyCode } from '../../../constants';
import { OpenedTaskDataChangedAction } from '../../ngrx/actions/opened-task.action';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { BehaviorSubject } from 'rxjs';
import { UnsavedFormChangesService } from '@atlaz/core/services/unsaved-form-changes.service';
import { setFocusToFocusableParent } from '../../../../helpers/event';

@Component({
  selector: 'checklist-item-form',
  templateUrl: './checklist-item-form.component.html',
  styleUrls: ['./checklist-item-form.component.scss']
})
export class ChecklistItemFormComponent implements OnInit, OnDestroy {
  @Input() checkbox: ChecklistItem;
  @Input() checklistId: number;
  @Output() submitForm: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  @ViewChild('name') elementRef: ElementRef;

  public checkSize$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  postHotKeys: HotKey[] = [{ keyCode: KeyCode.KEY_ENTER, shiftKey: false }];

  public checkboxForm: FormGroup;
  public focus = true;

  constructor(
    private _fb: FormBuilder,
    private _store: Store<AppState>,
    private _unsavedFormChangesService: UnsavedFormChangesService
  ) {}

  ngOnInit(): any {
    this.checkboxForm = this._fb.group(this.getFields());
    const unsavedFormChangesConfig = {
      allowedFields: ['name'],
      autoSave: true
    };

    this._unsavedFormChangesService.init(this.checkboxForm, this.unsavedCacheKey(), unsavedFormChangesConfig);
  }

  ngOnDestroy() {
    setFocusToFocusableParent(this.elementRef.nativeElement);
  }

  onSubmit() {
    this.focus = false;
    if (this.checkboxForm.valid) {
      this.submitForm.emit(this.checkboxForm.value);
    }
    if (!this.checkbox) {
      this.checkboxForm.get('name').patchValue('');
    }
    this.checkSize$.next(true);
    this._unsavedFormChangesService.flush();
    // this setTimeout using to refocus input
    setTimeout(() => {
      this.focus = true;
    }, 100);
  }

  onCancel(isFlush?: boolean) {
    this.cancel.emit();
    this._store.dispatch(new OpenedTaskDataChangedAction({ newCreatedCheckList: null }));
    if (isFlush) {
      this._unsavedFormChangesService.flush();
    }
  }

  public getFields() {
    switch (true) {
      case !!this.checkbox:
        return this.getEditFields();
      default:
        return this.getNewFields();
    }
  }

  public getEditFields(): any {
    return {
      id: [this.checkbox.id],
      name: [this.checkbox.name, Validators.required]
    };
  }

  public getNewFields(): any {
    return {
      name: ['', Validators.required]
    };
  }

  private unsavedCacheKey() {
    const defaultText = 'checklist-item';
    return [defaultText, this.checklistId, this.checkbox ? this.checkbox.id : 'new'].join(':');
  }
}
