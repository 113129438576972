import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Board } from '../../interfaces';

@Component({
  selector: 'right-menu-custom-fields',
  templateUrl: './right-menu-custom-fields.component.html',
  styleUrls: ['./right-menu-custom-fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightMenuCustomFieldsComponent implements OnInit {
  @Input() board: Board;

  public isFieldManagerVisible: boolean;
  constructor() {}

  ngOnInit() {}

  onOpenFieldsManager() {
    this.isFieldManagerVisible = true;
  }

  onCloseFieldsManager() {
    this.isFieldManagerVisible = false;
  }
}
