import { getEmptyESState } from '../../../../ngrx/state';
import { isOneToMany, Model } from '../models/index';
import { ENTITY_PL, IDS } from '../../../../constants/entity';
import { Entity } from '../../../../interfaces';
import { arrayMerge } from '../../../../../helpers';
import { entityCodesPlMap } from '../../../../../helpers/entity';

import { getId } from '../../../../../helpers/index';
import { isObject } from '../../../../../helpers/object';

const fieldEqual = {
  // dictionary to convert new FieldName to Old
  // ex. 'OldField': 'newField'
  coverAttachment: 'coverAttachmentId'
};

const getEqualFieldName = (property, fieldsEquivalent) =>
  fieldsEquivalent.hasOwnProperty(property) ? fieldsEquivalent[property] : property;

const getManyToManyFieldName = (property, fieldsEquivalent) =>
  fieldsEquivalent.hasOwnProperty(property) ? fieldsEquivalent[property] : property + IDS;

const convertManyToMany = fieldsEquivalent => (relationName, relationValue) => ({
  [getManyToManyFieldName(relationName, fieldsEquivalent)]: relationValue.reduce((ids, rel) => [...ids, rel.id], [])
});

const convertOneToMany = fieldsEquivalent => (relationName: string, relationValue: any) => ({
  [getEqualFieldName(relationName, fieldsEquivalent)]: getId(relationValue)
});

const convertRelation = (fieldsEquivalent = {}) => (relationName, relationValue) =>
  isOneToMany(relationValue)
    ? convertOneToMany(fieldsEquivalent)(relationName, relationValue.data)
    : convertManyToMany(fieldsEquivalent)(relationName, relationValue.data);

const convertAttribute = (fieldsEquivalent = {}) => (propName, propValue) => ({
  [getEqualFieldName(propName, fieldsEquivalent)]: propValue
});

const appendMappedProperty = (sourceObject, convertCommand) => (target, property, index, sourceProperties) =>
  Object.assign(target, convertCommand(property, sourceObject[property]));

export interface JsonApiSingeModelResponse<M extends Model<any>> {
  meta?: any;
  data: M;
  included: Model<any>[];
}

export interface JsonApiModelsResponse<M extends Model<any>> {
  meta?: any;
  data: M[];
  included: Model<any>[];
}
export const mapAndConvert = (model, convertCommand) =>
  Object.keys(model).reduce(appendMappedProperty(model, convertCommand), {});

export const modelToEntity = <T>(model: Model<T>): Entity => {
  let res;
  res = Object.assign({}, mapAndConvert({ id: model.id, type: model.type }, convertAttribute({ type: 'object' })));
  res = Object.assign(res, mapAndConvert(model.attributes, convertAttribute(fieldEqual)));
  res = Object.assign(res, mapAndConvert(model.relationships, convertRelation(fieldEqual)));

  return res;
};

export const modelsToEntityState = (models: Model<any>[]) => {
  try {
    return models.reduce((prevState, model) => {
      const modelPlCode = entityCodesPlMap[model.type];
      if (modelPlCode === undefined) {
        console.log("can't get entity code from ", model.type);
        return prevState;
      }

      const state = prevState.hasOwnProperty(modelPlCode) ? prevState[modelPlCode] : getEmptyESState();

      state.entities[model.id] = modelToEntity(model);
      state.ids = arrayMerge(state.ids, [model.id]);

      prevState[modelPlCode] = state;
      return prevState;
    }, {});
  } catch (e) {
    console.log(e, 'error convert model to entity state');
    return {};
  }
};

export const jsonApiToEntityState = (
  jsonApi: JsonApiSingeModelResponse<Model<any>> | JsonApiModelsResponse<Model<any>>
) => {
  if (!jsonApi.hasOwnProperty('data')) {
    const batchModels = [];
    Object.values(jsonApi).forEach(batchItem => {
      if (batchItem.hasOwnProperty('data')) {
        batchModels.push(batchItem.data, ...(batchItem.included || []));
      }
    });
    return modelsToEntityState(batchModels);
  }
  const included = jsonApi.included || [];
  const data = Array.isArray(jsonApi.data) ? jsonApi.data : [jsonApi.data];
  const models = [...data, ...included];

  return modelsToEntityState(models);
};

export function getFirst(jsonApi: JsonApiSingeModelResponse<Model<any>> | JsonApiModelsResponse<Model<any>>) {
  return Array.isArray(jsonApi.data) ? jsonApi.data[0] : jsonApi.data;
}

export const jsonApiToSingleEntity = (resp: JsonApiSingeModelResponse<Model<any>>) => {
  if (!isObject(resp.data)) {
    console.log('invalid response interface', resp);
    throw new Error(`'response must meet JsonApiSingeModelResponse interface.`);
  }
  const state = jsonApiToEntityState(resp);
  const plCode = entityCodesPlMap[resp.data.type];
  if (!state || !state[plCode] || !state[plCode][ENTITY_PL][resp.data.id]) {
    console.log('invalid response', resp);
    throw new Error(`'invalid response.`);
  }

  return state[plCode][ENTITY_PL][resp.data.id];
};

export function isEmptyResponse(jsonApi: JsonApiSingeModelResponse<Model<any>> | JsonApiModelsResponse<Model<any>>) {
  const isEmptyArray = Array.isArray(jsonApi.data) && jsonApi.data.length === 0;
  const isEmptyObject = typeof jsonApi.data === 'object' && Object.keys(jsonApi.data).length === 0;

  return isEmptyArray || isEmptyObject;
}
