import { Action } from './unsafe-action';
import { labelAction } from '../functions/action';

import { Notification, HttpQueryParam } from '../../interfaces';
import { NOTIFICATION } from '../../constants';

export const MarkAsTypes = {
  archive: 'archive',
  unarchive: 'unarchive',
  seen: 'seen',
  unseen: 'unseen'
};

export const NotificationActionTypes = {
  LOAD: labelAction(NOTIFICATION, 'LOAD'),
  GET: labelAction(NOTIFICATION, 'GET'),
  GET_COMPLETE: labelAction(NOTIFICATION, 'GET_COMPLETE'),
  MARK_AS: labelAction(NOTIFICATION, 'MARK_AS')
};

export class NotificationLoadAction implements Action {
  type = NotificationActionTypes.LOAD;

  constructor(public payload: { id: number; params?: HttpQueryParam }) {}
}

export class NotificationGetAction implements Action {
  type = NotificationActionTypes.GET;

  constructor(public payload?: HttpQueryParam) {}
}

export class NotificationGetCompleteAction implements Action {
  type = NotificationActionTypes.GET_COMPLETE;

  constructor(public payload: Notification[]) {}
}

export class NotificationMarkAsAction implements Action {
  type = NotificationActionTypes.MARK_AS;

  constructor(public payload: { markType: string; notificationIds: number[] }) {}
}

export type NotificationActions =
  | NotificationGetAction
  | NotificationLoadAction
  | NotificationGetCompleteAction
  | NotificationMarkAsAction;
