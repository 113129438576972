import { debounceTime, distinctUntilChanged, filter, map, merge, pairwise, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppState } from '../../../../ngrx/state';
import { Store } from '@ngrx/store';
import { DiffAnswer } from '../../../../ngrx/effects/data-sync.effect';
import { OBJECT_DELETE, OBJECT_STATE, QUEUE_JOB } from './web-socket.service';
import { consoleLog, getPageGeneratedAt } from '../../../../../helpers';
import { AtlazApiV2Service } from '../../atlaz-api/v2/atlaz-api-v2.service';
import { Model } from '../models';
import { entityCodesPlMap } from '../../../../../helpers/entity';
import {
  DataSyncLoadDiffAction,
  DataSyncObjectDelete,
  DataSyncObjectQueueJob,
  DataSyncObjectState,
  DataSyncSetLastUpdatedTimeAction,
  DataSyncUpdateConnectionStatus
} from '../../../../ngrx/actions/data-sync.action';
import { NetworkConnectionService } from '../network-connection.service';
import { dataSyncState, WebSocketAnswer, WebSocketDeleteAnswer } from '../../../../ngrx/reducers/data-sync.reducer';
import { QueryParams } from '../../../../interfaces';
import { partialLoader } from '../../../../ngrx/effects';
import { ReloadOnConnectionChangedService } from '../../../../atlaz-bnp/services/reload-on-connection-changed.service';
import { HttpRequestErrorsService } from '../../../../atlaz-bnp/http-request-errors/http-request-errors.service';

interface DiffGroup {
  [OBJECT_STATE]?: Model<any>[];
  [OBJECT_DELETE]?: WebSocketDeleteAnswer[];
  [QUEUE_JOB]?: WebSocketAnswer[];
  serverTime?: number;
}

export const toRemovePayload = (
  wsDeleteData: WebSocketDeleteAnswer
): { entityName: string; predicate: (model: Model<any>) => boolean } => {
  let removePayload;
  try {
    removePayload = {
      entityName: entityCodesPlMap[wsDeleteData.object],
      predicate: model => wsDeleteData.ids.includes(model.id)
    };
  } catch (e) {
    removePayload = {};
  }
  return removePayload;
};

@Injectable()
export class DataSyncService {
  static convertToModels(diffs: DiffAnswer[]): DiffGroup {
    let result;

    result = diffs.reduce(
      (acc: DiffGroup, [eventName, eventData]) => {
        acc.serverTime = Math.max(acc.serverTime, eventData.serverTime);
        const events = <any[]>acc[eventName];
        events.push(eventName === QUEUE_JOB ? eventData : eventData.data);
        return acc;
      },
      { [OBJECT_STATE]: [], [OBJECT_DELETE]: [], [QUEUE_JOB]: [], serverTime: 0 }
    );
    return result;
  }

  constructor(
    private _store: Store<AppState>,
    private _atlazApi: AtlazApiV2Service,
    private _netWorkConnection: NetworkConnectionService,
    public _reloadOnConnectionChangedService: ReloadOnConnectionChangedService,
    private _userErrorsNotification: HttpRequestErrorsService
  ) {
    this.init();
  }

  public emulateSocket() {
    return this._store.pipe(
      dataSyncState,
      distinctUntilChanged(),
      debounceTime(10 * 1000),
      filter(dataSync => dataSync.time > 0),
      filter(dataSync => dataSync.isOnline),
      filter(dataSync => !dataSync.isSocketConnected),
      tap(consoleLog('emulateSocket'))
    );
  }

  public loadDiffAfterMissNetworkConnection() {
    return this._store.pipe(
      dataSyncState,
      distinctUntilChanged(),
      pairwise(),
      filter(
        ([prevDataSync, currDataSync]) =>
          prevDataSync.isSocketConnected === false && currDataSync.isSocketConnected === true
      ),
      map(dataSyncPair => dataSyncPair[1]),
      filter(dataSync => dataSync.time > 0),
      filter(dataSync => dataSync.isOnline),
      tap(consoleLog('load diff after missed connection'))
    );
  }

  public loadDiff(queryParams: { fromTime: number; offset?: number; limit?: number; company: number }) {
    const request = (params: QueryParams) => <Observable<any>>this._atlazApi.get('difference', params);
    let serverTime = 0;

    const iterationObserver = {
      next: (diff: DiffAnswer[]) => {
        const diffGroup = DataSyncService.convertToModels(diff);
        serverTime = Math.max(diffGroup.serverTime, serverTime);
        if (diffGroup[OBJECT_STATE].length > 0) {
          this._store.dispatch(new DataSyncObjectState(diffGroup[OBJECT_STATE]));
        }
        if (diffGroup[OBJECT_DELETE].length > 0) {
          this._store.dispatch(new DataSyncObjectDelete(diffGroup[OBJECT_DELETE]));
        }
        if (diffGroup[QUEUE_JOB].length > 0) {
          this._store.dispatch(new DataSyncObjectQueueJob(diffGroup[QUEUE_JOB]));
        }
      },
      error: e => {
        console.log(e, 'error while load diff');
      },
      complete: () => {}
    };
    const pendingObserver = {
      next: isPending => {},
      error: error => {},
      complete: () => {
        this._store.dispatch(new DataSyncSetLastUpdatedTimeAction({ time: serverTime }));
        this._userErrorsNotification.denotifyUser();
      }
    };

    partialLoader(request, queryParams, iterationObserver, pendingObserver);
  }

  private init() {
    this._store.dispatch(new DataSyncSetLastUpdatedTimeAction({ time: getPageGeneratedAt() }));

    this.emulateSocket()
      .pipe(merge(this.loadDiffAfterMissNetworkConnection()))
      .subscribe(dataSync => this._store.dispatch(new DataSyncLoadDiffAction({ time: dataSync.time })));

    this._netWorkConnection.isOnline$.subscribe(isOnline =>
      this._store.dispatch(new DataSyncUpdateConnectionStatus({ isOnline }))
    );

    this._reloadOnConnectionChangedService.init();
  }
}
