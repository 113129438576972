
import {shareReplay, map, switchMap, take} from 'rxjs/operators';
import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';

import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { User } from '../../../interfaces';
import { PatchQueryService } from '../../../shared/services/patch-query.service';
import { Observable } from 'rxjs';
import { USER_PL } from '../../../constants/entity';
import { getEntitiesByIds } from '../../../ngrx/functions/selectors';
import { Group } from '../../../interfaces/group';
import { EVERYONE_ID, getAllGroups, getGroupById } from '../../../ngrx/reducers/group.reducer';
import { getAllActiveUsers, filterBots } from '../../../ngrx/reducers/user.reducer';
import { naturalSort } from '../../../../helpers/array';

@Component({
  selector: 'user-filter',
  templateUrl: './user-filter.component.html',
  providers: [PatchQueryService],
  styleUrls: ['./task-feed-filter.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserFilterComponent implements OnInit {
  @Input() groupId$: Observable<number | string>;
  @Input() userIds$: Observable<number[]>;

  @Output() selectItems = new EventEmitter<{ groups: number[]; users: number[] }>();

  public groups$: Observable<Group[]>;
  public users$: Observable<User[]>;

  public selectedUsers$: Observable<User[]>;
  public selectedGroup$: Observable<Group>;

  public isMembersPopupVisible = false;

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    // exclude every one from groups
    this.groups$ = <Observable<Group[]>>this._store.pipe(
      (getAllGroups),
      map((groups: Group[]) => groups.filter(group => String(group.id) !== EVERYONE_ID)),);

    this.users$ = <Observable<User[]>>this._store.pipe(
      (getAllActiveUsers),
      map(filterBots),
      map(naturalSort('fullname')),);

    this.selectedGroup$ = this.groupId$.pipe(switchMap(groupId => this._store.pipe((getGroupById(groupId)))),shareReplay(1),);

    this.selectedUsers$ = <Observable<User[]>>this.userIds$.pipe(switchMap(userIds =>
      this._store.pipe((getEntitiesByIds(USER_PL, userIds)))
    ));
  }

  onOpenMembersPopup() {
    this.isMembersPopupVisible = true;
  }
  onCloseMembersPopup() {
    this.isMembersPopupVisible = false;
  }

  onSelectGroup(group: Group) {
    this.groupId$.pipe(take(1)).subscribe(groupId => {
      const payload = groupId !== group.id ? { groups: [group.id], users: group.usersIds } : { groups: [], users: [] };

      this.selectItems.emit(payload);
    });

    this.isMembersPopupVisible = false;
  }

  onSelectUser(itemId: number) {
    this.userIds$.pipe(
      take(1),
      map(userIds => {
        const toIncludeInFilter = !userIds.includes(itemId);
        return toIncludeInFilter ? [...userIds, itemId] : userIds.filter(userId => userId !== itemId);
      }),)
      .subscribe(userIds => {
        this.selectItems.emit({
          groups: [],
          users: userIds
        });
      });
  }
}
