import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { AppContainersModule } from '../containers/containers.module';

import { NotificationPreviewComponent } from './notifications/inbox/notification-preview/notification-preview.component';
import { NotificationsInboxComponent } from './notifications/inbox/notifications-inbox.component';
import { SubNotificationPreviewComponent } from './notifications/inbox/notification-preview/subnotification-preview.component';
import { NotificationsListComponent } from './notifications/inbox/notifications-list/notifications-list.component';
import { TaskFeedComponent } from './activity/task-feed/task-feed.component';
import { TaskFeedPreviewComponent } from './activity/task-feed-preview/task-feed-preview.component';
import { TaskListComponent } from './activity/task-list/task-list.component';
import { OverviewDetailPageComponent } from './overview-detail-page/overview-detail-page.component';
import { OverviewDetailComponent } from './overview-detail/overview-detail.component';
import { BoardFilterComponent } from './activity/filters/board-filter.component';
import { AtlazGuiModule } from '../atlaz-gui/atlaz-gui.module';
import { SingleNotificationPreviewComponent } from './notifications/inbox/notification-preview/single-notification-preview/single-notification-preview.component';
import { DefaultNotificationPreviewComponent } from './notifications/inbox/notification-preview/types/default-notification-preview/default-notification-preview.component';
import { BoardNotificationPreviewComponent } from './notifications/inbox/notification-preview/types/board-notification-preview/board-notification-preview.component';
import { ImportNotificationPreviewComponent } from './notifications/inbox/notification-preview/types/import-notification-preview/import-notification-preview.component';
import { BoardListComponent } from './notifications/inbox/notification-preview/types/import-notification-preview/board-list/board-list.component';
import { DropdownFilterComponent } from './activity/filters/dropdown-filter.component';
import { DoneDateFilterComponent } from './activity/filters/done-date-filter.component';
import { DoneDateStartComponent } from './activity/filters/done-date-start-filter.component';
import { ProjectFilterComponent } from './activity/filters/project-filter.component';
import { UserFilterComponent } from './activity/filters/user-filter.component';
import { MarkdownModule } from 'ngx-markdown-hyggerio';
import { InitiateBoardsComponent } from './initiate-boards/initiate-boards.component';

@NgModule({
  imports: [CommonModule, SharedModule, AtlazGuiModule, AppContainersModule, MarkdownModule],
  declarations: [
    //Components
    NotificationsInboxComponent,
    NotificationPreviewComponent,
    SubNotificationPreviewComponent,
    NotificationsListComponent,
    SingleNotificationPreviewComponent,
    BoardNotificationPreviewComponent,
    ImportNotificationPreviewComponent,
    BoardListComponent,
    DefaultNotificationPreviewComponent,
    DoneDateFilterComponent,
    DoneDateStartComponent,
    ProjectFilterComponent,
    UserFilterComponent,
    TaskFeedComponent,
    TaskFeedPreviewComponent,
    TaskListComponent,
    OverviewDetailPageComponent,
    OverviewDetailComponent,
    InitiateBoardsComponent,
    BoardFilterComponent,
    DropdownFilterComponent
  ],
  exports: [NotificationsInboxComponent, TaskFeedComponent]
})
export class OverviewModule {}
