import { AppState, ESInterface } from '../state';
import { BoardsTemplate } from '../../interfaces';
import { createSelector } from 'reselect';
import { boardType } from '../../constants';

export const getBoardsTemplatesState = (state: AppState) => <ESInterface<BoardsTemplate>>state.boardsTemplates;

export namespace fromBoardsTemplates {
  export const getAll = createSelector(getBoardsTemplatesState, boardsTemplatesState =>
    boardsTemplatesState.ids.map((id: number) => boardsTemplatesState.entities[id])
  );
  export const getGrouped = isSprintAvailable =>
    createSelector(getAll, templatesList => {
      const result = [];
      const templates = isSprintAvailable ? templatesList : templatesList.filter(t => t.boardType !== boardType.sprint);
      const templatesMap = templates.sort((a, b) => a.order - b.order).reduce((acc, item) => {
        if (!acc.hasOwnProperty(item.collectionName)) {
          acc[item.collectionName] = [];
        }
        acc[item.collectionName].push(item);
        return acc;
      }, {});
      Object.keys(templatesMap).forEach(key => result.push({ name: key, templates: templatesMap[key] }));
      return result;
    });
  export const getById = (id: number) =>
    createSelector(getBoardsTemplatesState, boardsTemplatesState => boardsTemplatesState.entities[id]);
}
