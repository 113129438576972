import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterNavigateService } from '../../../shared/services/router-navigate.service';

@Component({
  selector: 'billing-overlay',
  templateUrl: './billing-overlay.component.html',
  styleUrls: ['./billing-overlay.component.scss', './billing-other-styles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillingGlobalComponent {
  constructor(private _navigateService: RouterNavigateService) {}

  onGoToBilling() {
    this._navigateService.navigateToBilling();
  }
}
