import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { GithubRepositoryEffects } from './store/github-repository/github-repository.effects';
import { GithubCompanyEffects } from './store/github-company/github-company.effects';
import { GithubCommitEffects } from './store/github-commit/github-commit.effects';
import { GithubPullRequestEffects } from './store/github-pull-request/github-pull-request.effects';
import { GithubAssociatedRepositoryEffects } from './store/github-associated-repository/github-associated-repository.effects';
import { BitbucketCompanyEffects } from './store/bitbucket-company/bitbucket-company.effects';
import { BitbucketAssociatedRepositoryEffects } from './store/bitbucket-associated-repository/bitbucket-associated-repository.effects';
import { BitbucketRepositoryEffects } from './store/bitbucket-repository/bitbucket-repository.effects';
import { BitbucketCommitEffects } from './store/bitbucket-commit/bitbucket-commit.effects';
import { BitbucketPullRequestEffects } from './store/bitbucket-pull-request/bitbucket-pull-request.effects';
import { GitlabCompanyEffects } from './store/gitlab-company/gitlab-company.effects';
import { GitlabAssociatedRepositoryEffects } from './store/gitlab-associated-repository/gitlab-associated-repository.effects';
import { GitlabRepositoryEffects } from './store/gitlab-repository/gitlab-repository.effects';
import { GitlabPullRequestEffects } from './store/gitlab-pull-request/gitlab-pull-request.effects';
import { GitlabCommitEffects } from './store/gitlab-commit/gitlab-commit.effects';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([
      GithubRepositoryEffects,
      GithubCompanyEffects,
      GithubCommitEffects,
      GithubPullRequestEffects,
      GithubAssociatedRepositoryEffects,
      BitbucketCompanyEffects,
      BitbucketAssociatedRepositoryEffects,
      BitbucketRepositoryEffects,
      BitbucketCommitEffects,
      BitbucketPullRequestEffects,
      GitlabCompanyEffects,
      GitlabAssociatedRepositoryEffects,
      GitlabRepositoryEffects,
      GitlabCommitEffects,
      GitlabPullRequestEffects
    ])
  ]
})
export class GitModule {}
