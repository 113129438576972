import { fromEvent as observableFromEvent, Observable, Subscription } from 'rxjs';

import { filter, refCount, publishReplay, map, merge, take } from 'rxjs/operators';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';

import { Board, Column, Swimlane } from '../../interfaces';
import { AppState } from '../../ngrx/state';
import { ADragService } from '../../shared/a-drag/a-drag.service';
import { COLUMN_PL, SWIMLANE_PL, TASK_PL, boardType } from '../../constants';
import { groupByProp, isPresent, onInsertToDom } from '../../../helpers';
import { scrollbarWidth } from '../../../helpers/scroll';
import { SwimlaneCounter } from '../../interfaces/swimlane';
import { ScrollService } from '../../shared/dragula/scroll.service';

@Component({
  selector: 'board-swimlane',
  templateUrl: './board-swimlane.component.html',
  styleUrls: ['./board-swimlane.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoardSwimlaneComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('swimlane_header') swimlaneHeader: ElementRef;
  @ViewChild('header_inner') swimlaneHeaderInner: ElementRef;
  @ViewChild('contextControlEl') contextControlEl: ElementRef;

  @Input() swimlane: Swimlane;
  @Input() columns$: Observable<Column[]>;
  @Input() swimlanesLength: number;
  @Input() tasksCounter: SwimlaneCounter;
  @Input() boardType: string;
  @Input() collapsed: boolean;
  @Input() scrollContainer: HTMLElement;
  @Input() hidden = false;

  @Input() scoreMap;
  @Input() board: Board;
  @Input() activeUserId;
  @Input() boardUsersMap;
  @Input() boardLabelsMap;
  @Input() newNotifyMap;
  @Input() isNotGuest: boolean;

  @Output() toggleSwimlaneCollapseStateEvent: EventEmitter<Swimlane> = new EventEmitter<Swimlane>();
  @Output() expandAll = new EventEmitter();
  @Output() collapseAll = new EventEmitter();

  subs: Subscription[] = [];

  public columnIds$: Observable<number[]>;
  public dragItemType = SWIMLANE_PL;
  public isKanbanBoard = false;
  public isChangeSwimlaneNameVisible = false;
  public draggingColumnId$;
  public scrollbarWidth = scrollbarWidth;
  public containerOffsetTop: number;
  public visibleMenuSwimlaneId: number;

  get isMultipleSwimlanes() {
    return this.swimlanesLength > 1;
  }

  private checkEvent$: Observable<any>;

  get swimlaneHeaderHeigth() {
    return this.swimlaneHeaderInner ? this.swimlaneHeaderInner.nativeElement.offsetHeight : 0;
  }

  public columnsById$;

  constructor(
    private _store: Store<AppState>,
    private _dragService: ADragService,
    private _scrollService: ScrollService,
    private _renderer: Renderer2,
    private _elementRef: ElementRef
  ) {}

  ngOnInit() {
    this.isKanbanBoard = this.boardType === boardType.kanban;
    this.columnIds$ = this.columns$.pipe(map(columns => columns.map(item => item.id)), publishReplay(1), refCount());
    this.columnsById$ = this.columns$.pipe(map(groupByProp('id')), publishReplay(1), refCount());

    this.draggingColumnId$ = this._dragService.getDraggingItemId(COLUMN_PL);
    this.checkEvent$ = this._scrollService.checkScrollEvents$.pipe(
      merge(observableFromEvent(window, 'resize')),
      filter(() => this.isMultipleSwimlanes)
    );

    this.subs.push(
      this.checkEvent$
        .pipe(filter(() => this.swimlaneHeaderInner && this.swimlaneHeaderInner.nativeElement))
        .subscribe(() => {})
    );
  }

  ngAfterViewInit() {
    this.subs.push(
      this.checkEvent$.subscribe(() => {
        const containerScrollTopValue = this.scrollContainer['scrollTop'];
        const containerScrollLeftValue = this.scrollContainer['scrollLeft'];
        const swimlaneFullHeigth = this._elementRef.nativeElement.clientHeight;
        const swimlaneOffsetTop = this._elementRef.nativeElement.offsetTop;

        const bottomPositionFlag =
          containerScrollTopValue + this.swimlaneHeaderHeigth >= swimlaneFullHeigth + swimlaneOffsetTop + 3;
        const fixedPositionFlag = containerScrollTopValue >= swimlaneOffsetTop;
        const defaultPOsitionFlag = containerScrollTopValue < swimlaneOffsetTop;

        if (fixedPositionFlag && !bottomPositionFlag && !this.collapsed) {
          this.setFixedHeaderPosition();
        } else if (bottomPositionFlag && !this.collapsed) {
          this.setBottomHeaderPosition();
        } else if (defaultPOsitionFlag && !this.collapsed) {
          this.setDefaultHeaderPosition();
        } else if (fixedPositionFlag && bottomPositionFlag && this.collapsed) {
          this.setDefaultHeaderPosition();
        } else if (fixedPositionFlag && this.collapsed) {
          this.setDefaultHeaderPosition();
        }

        if (!fixedPositionFlag && !bottomPositionFlag && this.swimlaneHeaderInner) {
          this.swimlaneHeaderInner.nativeElement.scrollLeft =
            this.swimlaneHeaderInner.nativeElement.clientWidth - containerScrollLeftValue;
        } else if (bottomPositionFlag) {
          this.swimlaneHeaderInner.nativeElement.scrollLeft =
            this.swimlaneHeaderInner.nativeElement.clientWidth - containerScrollLeftValue;
        } else if (fixedPositionFlag && this.collapsed && this.swimlaneHeaderInner) {
          this.swimlaneHeaderInner.nativeElement.scrollLeft =
            this.swimlaneHeaderInner.nativeElement.clientWidth - containerScrollLeftValue;
        } else if (fixedPositionFlag && !this.collapsed && this.swimlaneHeaderInner) {
          this.swimlaneHeaderInner.nativeElement.scrollLeft = this.swimlaneHeaderInner.nativeElement.clientWidth;
        }
        this.containerOffsetTop = this.scrollContainer.getBoundingClientRect().top;
      })
    );
    this.checkEvent$.pipe(take(1)).subscribe(() => {
      onInsertToDom(this.swimlaneHeaderInner.nativeElement, () => {
        if (this.swimlaneHeaderInner && this.isMultipleSwimlanes) {
          this.swimlaneHeaderInner.nativeElement.scrollLeft = this.swimlaneHeaderInner.nativeElement.clientWidth;
        }
        this._scrollService.fireCheck();
      });
    });
  }

  ngOnChanges() {
    this._scrollService.fireCheck();
  }

  setFixedHeaderPosition() {
    if (this.swimlaneHeader) {
      this._renderer.addClass(this.swimlaneHeader.nativeElement, 'fixtop_class');
      this._renderer.setStyle(this.swimlaneHeader.nativeElement, 'top', this.containerOffsetTop + 'px');
      this._renderer.setStyle(this._elementRef.nativeElement, 'padding-top', '26px');
      this._renderer.removeStyle(this.swimlaneHeader.nativeElement, 'position');
    }
  }

  setBottomHeaderPosition() {
    this._renderer.removeStyle(this.swimlaneHeader.nativeElement, 'top');
    this._renderer.removeClass(this.swimlaneHeader.nativeElement, 'fixtop_class');
    this._renderer.setStyle(this.swimlaneHeader.nativeElement, 'bottom', 0);
    this._renderer.setStyle(this.swimlaneHeader.nativeElement, 'position', 'absolute');
  }

  setDefaultHeaderPosition() {
    if (this.swimlaneHeader) {
      this._renderer.removeStyle(this.swimlaneHeader.nativeElement, 'top');
      this._renderer.removeStyle(this.swimlaneHeader.nativeElement, 'bottom');
      this._renderer.removeClass(this.swimlaneHeader.nativeElement, 'fixtop_class');
      this._renderer.removeStyle(this.swimlaneHeader.nativeElement, 'position');
      this._renderer.setStyle(this._elementRef.nativeElement, 'padding-top', '0');
      this._renderer.setStyle(this._elementRef.nativeElement, 'position', 'relative');
    }
  }

  onToggleSwimlaneNamePopup(event: MouseEvent) {
    this.isChangeSwimlaneNameVisible = !this.isChangeSwimlaneNameVisible;
  }

  onToggleSwimlaneCollapseState(swimlane: Swimlane) {
    this.toggleSwimlaneCollapseStateEvent.emit(swimlane);
  }

  onToggleSwimlaneMenu(swimlaneId: number) {
    if (this.visibleMenuSwimlaneId) {
      this.contextControlEl['_eref'].nativeElement.style.position = 'relative';
      this.contextControlEl['_eref'].nativeElement.style.left = 'auto';
      this.contextControlEl['_eref'].nativeElement.style.top = 'auto';
      this.visibleMenuSwimlaneId = 0;
    } else {
      const { left, top } = this.contextControlEl['_eref'].nativeElement.getBoundingClientRect();
      this.contextControlEl['_eref'].nativeElement.style.position = 'fixed';
      this.contextControlEl['_eref'].nativeElement.style.left = left + 'px';
      this.contextControlEl['_eref'].nativeElement.style.top = top + 'px';
      this.visibleMenuSwimlaneId = swimlaneId;
    }
  }

  onExpandAll() {
    this.expandAll.emit();
  }

  onCollapseAll() {
    this.collapseAll.emit();
  }

  onDrag() {
    this._dragService.dragging$.pipe(take(1), filter(isPresent)).subscribe(d => {
      if (d.type === TASK_PL && this.collapsed === true) {
        this.collapsed = false;
        this.onToggleSwimlaneCollapseState(this.swimlane);
      }
    });
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
