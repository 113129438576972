import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'task-links-add-popup',
  templateUrl: './task-links-add-popup.component.html',
  styleUrls: ['./task-links-add-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskLinksAddPopupComponent {
  @Input() taskId: number;
  @Input() boardId: number;
  @Input() isAddDependencies: boolean;
  @Output() close = new EventEmitter();

  constructor() {}

  onCloseTaskLinks() {
    this.close.emit();
  }
}
