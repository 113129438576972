import { IntegrationNames } from '../integraition-list';
import { Action } from '../../../ngrx/actions/unsafe-action';

export const INTEGRATION_STATUS_UPDATED = '[integration.action] INTEGRATION_STATUS_UPDATED';

export class IntegrationStatusUpdated implements Action {
  readonly type = INTEGRATION_STATUS_UPDATED;

  constructor(public payload: { name: IntegrationNames; isInstalled: boolean }) {}
}

// prettier-ignore
export type Actions
  = IntegrationStatusUpdated

  ;
