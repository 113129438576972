<div class="menu_activity">
  <div class="menu_item_title">Activity</div>
  <div *ngFor="let activity of boardActivities$ | async">
    <activity-preview
      [activity]="activity"
    ></activity-preview>
  </div>
  <div *ngIf="showLoader" class="loader_wrapper"><a-loader></a-loader></div>
  <ng-container *ngIf="scroll; else button">
    <a-infinite-loader *ngIf="!endOffset" (loadMore)="onLoadMore()"></a-infinite-loader>
  </ng-container>
  <ng-template #button>
  <button class="load_more" *ngIf="!endOffset" (click)="onLoadMore()">Load More</button>
</ng-template>

</div>
