import { compose } from '@ngrx/store';

import { CollectionActions, CollectionActionTypes } from '../actions/collection.actions';
import { AppState, ESInterface } from '../state';
import { Collection } from '../../interfaces';
import { share } from '../functions/util';
import { getAllEntitiesAsArray, getEntityByIdSelector, getEntitySelector } from '../functions/selectors';
import { addEntityState, deleteEntityState, editEntityState } from '../functions/reducer';
import { COLLECTION_PL } from '../../constants';

const initialState: ESInterface<Collection> = {
  ids: [],
  entities: {},
  selectedEntityId: null
};

export const getCollectionById = id =>
  share(compose(getEntityByIdSelector<Collection>(id), getEntitySelector(COLLECTION_PL)));

export function reducer(state = initialState, action: CollectionActions): ESInterface<Collection> {
  switch (action.type) {
    case CollectionActionTypes.ADD_COMPLETE: {
      return addEntityState(state, action.payload, true);
    }

    case CollectionActionTypes.DELETE: {
      return <ESInterface<Collection>>deleteEntityState(state, action.payload);
    }

    case CollectionActionTypes.EDIT: {
      return editEntityState(state, action.payload);
    }

    default: {
      return state;
    }
  }
}

export const getAllCollections = share(compose(getAllEntitiesAsArray, getEntitySelector(COLLECTION_PL)));

export const collectionsState = (store: AppState) => <ESInterface<Collection>>store[COLLECTION_PL];
