<div *ngIf="errorMessage.length > 0">
  {{errorMessage}}
</div>
<div *ngIf="!resultIds.length" class="no-results">
  <div class="no-results-message">
    <svg width="326" height="143" viewBox="0 0 326 143" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M306 141.5C306 63.3517 242.648 0 164.5 0C86.3517 0 23 63.3517 23 141.5" fill="#D2E9F7" fill-opacity="0.430791"/>
      <path d="M2 141H241.002" stroke="#0686D8" stroke-width="4" stroke-linecap="square"/>
      <path d="M270 141H323.46" stroke="#0686D8" stroke-width="4" stroke-linecap="square"/>
      <path d="M252 141H260.062" stroke="#0686D8" stroke-width="4" stroke-linecap="square"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M151 47H218.28C220.886 47 223 49.114 223 51.72V63H105V51.72C105 49.114 107.114 47 109.72 47H120.738" fill="#D2E9F7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M143.614 47H218.28C220.886 47 223 49.114 223 51.72V63H105V51.72C105 49.114 107.114 47 109.72 47H120.738H143.614Z" stroke="#0686D8" stroke-width="4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M105 141V63H223V141H105Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M105 141V63H223V141H105Z" stroke="#0686D8" stroke-width="4"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M131 57H135V53H131V57Z" fill="#5FB0E5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M123 57H127V53H123V57Z" fill="#5FB0E5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M115 57H119V53H115V57Z" fill="#5FB0E5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M178.705 112.575C186.066 104.068 185.372 91.2609 177.136 83.5983C168.9 75.9356 156.076 76.1669 148.121 84.1215C140.167 92.0761 139.936 104.9 147.598 113.136C155.261 121.372 168.068 122.066 176.575 114.705L184.228 122.358C184.806 122.936 185.744 122.936 186.323 122.358C186.899 121.781 186.901 120.847 186.327 120.268L178.705 112.575ZM175.625 111.575C168.629 118.565 157.292 118.561 150.3 111.568C143.309 104.574 143.309 93.2366 150.3 86.2428C157.292 79.2489 168.629 79.2455 175.625 86.2351C182.606 93.2498 182.588 104.593 175.585 111.585L175.625 111.575ZM157.69 91.5997L162.915 96.8251L168.14 91.6001C168.717 91.023 169.653 91.023 170.23 91.6001C170.807 92.1773 170.807 93.113 170.23 93.6901L165.005 98.9151L170.23 104.14C170.807 104.717 170.807 105.653 170.23 106.23C169.653 106.807 168.717 106.807 168.14 106.23L162.915 101.005L157.69 106.231C157.112 106.808 156.177 106.809 155.598 106.233C155.017 105.655 155.016 104.715 155.596 104.136L160.825 98.9151L155.596 93.6947C155.016 93.1156 155.017 92.1754 155.598 91.5972C156.177 91.0213 157.112 91.0224 157.69 91.5997Z" fill="#0686D8"/>
    </svg>
    <h3 class="no-results-header">
      No Search Results!
    </h3>
    <div class="no-results-description">
      <span *ngIf="searchQuery;else withEmptyString">Your search "<strong>{{searchQuery}}</strong>" - did not match any task.</span>
    </div>
  </div>
</div>
<div *ngIf="errorMessage.length == 0 && resultIds.length > 0">
  <div *ngFor="let id of resultIds">
    <search-result-preview [taskId]="id"></search-result-preview>
  </div>
</div>

<ng-template #withEmptyString>
  Your search did not match any task.
</ng-template>
