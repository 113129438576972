import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionsService } from '../permissions.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/state';
import { take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotGuestGuard implements CanActivate {
  constructor(private _permissions: PermissionsService, private _store: Store<AppState>, private _router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._permissions.isNotGuest$.pipe(
      take(1),
      tap(hasPermission => (hasPermission ? null : this._router.navigate(['/403'])))
    );
  }
}
