
import {distinctUntilChanged, map, filter} from 'rxjs/operators';
import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import {
  DraggedActualPositions,
  DraggedItemActualPosition,
  RoadmapDragService
} from '../services/roadmap-drag.service';
import { RoadMapItem } from '../interfaces/roadmap.interface';
import { isPresent } from '../../../../helpers';
import { Subscription } from 'rxjs';

export const defaultDragOverHandler = (
  event: MouseEvent,
  draggedItem: RoadMapItem,
  nativeElement: any,
  item: RoadMapItem
) => {
  if (draggedItem.type === 'group' && item.type === 'task' && item.parent) {
    return;
  }
  const { top, height } = nativeElement.getBoundingClientRect();
  return {
    id: item.id,
    item,
    position: top + height / 2 > event.pageY ? DraggedActualPositions.before : DraggedActualPositions.after
  };
};

const positionClass = {
  [DraggedActualPositions.before]: 'drop_here_before',
  [DraggedActualPositions.after]: 'drop_here_after',
  [DraggedActualPositions.insideFirst]: 'drop_here_inside_first',
  [DraggedActualPositions.insideLast]: 'drop_here_inside_last'
};

@Directive({
  selector: '[roadmapDropContainer]'
})
export class RoadmapDropContainerDirective implements OnInit, OnDestroy {
  @Input() roadmapDropContainer: RoadMapItem;

  @Input()
  dragOverHandler: (
    event: MouseEvent,
    draggedItem: RoadMapItem,
    nativeElement: any,
    item: RoadMapItem
  ) => DraggedItemActualPosition = defaultDragOverHandler;

  private dragOver$;

  subs: Subscription[] = [];

  get item() {
    return this.roadmapDropContainer;
  }

  constructor(
    private _dragService: RoadmapDragService,
    private _elementRef: ElementRef,
    private _rendererV2: Renderer2
  ) {}

  ngOnInit() {
    this._dragService.registerDropContainer(this._elementRef.nativeElement, this.item);
    this.subs.push(
      (this.dragOver$ = this._dragService.dragOver$.pipe(
        filter(({ target }) => target === this._elementRef.nativeElement),
        map(({ event, item }) => this.dragOverHandler(event, item, this._elementRef.nativeElement, this.item)),
        filter(isPresent),
        distinctUntilChanged(
          (a: DraggedItemActualPosition, b: DraggedItemActualPosition) => a.id === b.id && a.position === b.position
        ),)
        .subscribe((pos: DraggedItemActualPosition) => this._dragService.actualPosition$.next(pos)))
    );

    let hasClass = '';
    this.subs.push(
      this._dragService.actualPosition$.subscribe((e: DraggedItemActualPosition) => {
        if (this.item.id === e.id) {
          if (hasClass !== positionClass[e.position]) {
            if (hasClass) {
              this._rendererV2.removeClass(this._elementRef.nativeElement, hasClass);
            }
            hasClass = positionClass[e.position];
            this._rendererV2.addClass(this._elementRef.nativeElement, hasClass);
          }
        } else if (hasClass) {
          this._rendererV2.removeClass(this._elementRef.nativeElement, hasClass);
        }
      })
    );
  }

  ngOnDestroy() {
    this._dragService.unRegisterDropContainer(this._elementRef.nativeElement);
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
