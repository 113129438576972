import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Board, Collection } from '../../interfaces';
import { AppState, LEFT_MENU } from '../../ngrx/state';
import { Store } from '@ngrx/store';
import { SearchInLeftMenuService } from '../../shared/services/app/search-in-left-menu.service';
import { PermissionsService } from '../../permissions/permissions.service';
import { AppUrls } from '../../app-urls';
import { OpenedStateLeftMenuItemsService } from '../shared/services/opened-state-left-menu-items.service';
import { ComponentToggleAction } from '../../ngrx/actions/component.actions';

@Component({
  selector: 'left-collection-item',
  templateUrl: './left-collection-item.component.html',
  styleUrls: ['./left-collection-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeftCollectionItemComponent implements OnInit, OnDestroy {
  @Input() collection: Collection;
  @Input() visibleBoards: Board[];
  @Input() isNotGuest: boolean;

  public appUrls = AppUrls;
  public isVisibleBoards = false;
  public hasPermission$: Observable<boolean>;
  public subscription;
  public openedStateCollectionListKey: string;
  public isContextMenuVisible: boolean;

  constructor(
    private _store: Store<AppState>,
    private _searchInLeftMenuService: SearchInLeftMenuService,
    private _permissions: PermissionsService,
    private _openedStateLeftMenuItemsService: OpenedStateLeftMenuItemsService
  ) {}

  ngOnInit(): any {
    this.openedStateCollectionListKey = this._openedStateLeftMenuItemsService.localStoreKeys.OPENED_COLLECTIONS_LIST;
    this.initIsVisibleBoards();
    this.hasPermission$ = this._permissions.isSuperUser$;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  onToggleLeftMenu() {
    this._store.dispatch(new ComponentToggleAction({ name: LEFT_MENU }));
  }

  onOpenBoardsList() {
    this.isVisibleBoards = !this.isVisibleBoards;
    this._openedStateLeftMenuItemsService.save(
      this.isVisibleBoards,
      this.openedStateCollectionListKey,
      this.collection.id
    );
  }

  initIsVisibleBoards() {
    this.subscription = this._searchInLeftMenuService.isSearchActive$.subscribe(state => {
      this.isVisibleBoards = state;
      if (!state) {
        const list = this._openedStateLeftMenuItemsService.getOpenedProjectsList(this.openedStateCollectionListKey);
        if (!list.includes(this.collection.id.toString())) {
          this.isVisibleBoards = true;
        }
      }
    });
  }

  onSwitchContextMenu() {
    this.isContextMenuVisible = !this.isContextMenuVisible;
  }
}
