<div class="payment_header">
  <div class="payment_header_title" *ngIf="(hasActivePrepaidSubscription$ | async) && (billingSub$ | async)?.period === period && (billingSub$ | async)?.plan === planName;else hasNoPrepaidSub">
    Active Subscription till {{(billingSub$ | async)?.currentPeriodEnd | toDate : "mediumDate"}}
  </div>

  <ng-template #hasNoPrepaidSub>
    <div class="payment_header_title">Your <span *ngIf="(billingSub$ | async)?.period !== period || (billingSub$ | async)?.plan !== planName">New</span> Estimate Price - <b>${{price$ | async}}</b></div>
      <div class="payment_header_subtitle">${{perMemberPrice}} per member / month</div>
  </ng-template>
</div>
