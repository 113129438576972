
import {catchError, switchMap, tap, filter, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { BOARD_PL, PROJECT_PL, USER_PL } from '../../constants';
import { GetGetParams, HttpQueryParam } from '../../interfaces';
import { defaultErrorHandler } from './root.effect';
import { GetCompleteAction } from '../actions/root.action';
import { ProjectActionTypes, ProjectGetAction } from '../actions/project.actions';
import {
  AtlazApiV2Service,
  changeProjectParser,
  noParse
} from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { JsonApiSingeModelResponse, jsonApiToEntityState } from '../../shared/services/app/web-socket/http-response';
import { PaginationLoaderService } from '../../shared/services/paginataion-loader/paginataion-loader.service';
import { Store } from '@ngrx/store';
import { AppState } from '../state';
import { AuthService } from '../../shared/services/app/auth.service';
import { isPresent } from '../../../helpers';
import { Action } from '../actions/unsafe-action';

@Injectable()
export class ProjectEffects {
  @Effect()
  init$ = this._authService.userInActiveCompany$.pipe(
    filter(isPresent),
    map(() => new ProjectGetAction({ expand: [BOARD_PL, USER_PL].toString() })),);

  @Effect({ dispatch: false })
  getProjects$ = this.actions$.ofType(ProjectActionTypes.GET).pipe(tap(({ payload }: Action) => {
    const paginatorService = new PaginationLoaderService(this._atlazApi2, this._store);
    payload = <HttpQueryParam>Object.assign(
      {},
      {
        sort: '-updatedAt'
      },
      payload
    );

    paginatorService.controller = PROJECT_PL;
    paginatorService.limit = 300;
    paginatorService.queryParams = payload;
    paginatorService.loadAll = true;
    paginatorService.loadMore();
  }));

  @Effect()
  getGetProject$ = this.actions$
    .ofType(ProjectActionTypes.GET_GET).pipe(
    switchMap(({ type, payload }: { type: string; payload: GetGetParams }) => {
      return this._atlazApi2
        .get([payload.controller, payload.id], payload.queryParams).pipe(
        catchError(defaultErrorHandler(type, payload)),
        map((project: JsonApiSingeModelResponse<any>) => new GetCompleteAction(jsonApiToEntityState(project))),);
    }));

  @Effect()
  pathUserRoleInProject$ = this.actions$
    .ofType(ProjectActionTypes.CHANGE_ROLE).pipe(
    switchMap(({ type, payload }: { type: string; payload: any }) => {
      return this._atlazApi2
        .patch([PROJECT_PL, payload.id, payload.queryParams], payload.requestPayload, noParse, changeProjectParser).pipe(
        catchError(defaultErrorHandler(type, payload)),
        map((project: JsonApiSingeModelResponse<any>) => new GetCompleteAction(jsonApiToEntityState(project))),);
    }));

  @Effect({ dispatch: false })
  editProject$ = this.actions$
    .ofType(ProjectActionTypes.EDIT, ProjectActionTypes.ASSIGN_USERS).pipe(
    switchMap(({ type, payload }: Action) =>
      this._atlazApi2.patch(PROJECT_PL, payload).pipe(catchError(defaultErrorHandler(type, payload)))
    ));

  constructor(
    private actions$: Actions,
    private _store: Store<AppState>,
    private _authService: AuthService,
    protected _atlazApi2: AtlazApiV2Service
  ) {}
}
