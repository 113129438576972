<a-center-container>
  <div a-popup (close)="onClose($event)" [title]="'3D secure'">
    <div class="content">
      <p>Your card requires 3D secure payment verification. You will be charged 1$ for verification purposes, and we
        will refund it later. Now you will be redirected to your card issuer’s website to verify your identity using 3D Secure.</p>
      <div class="center">
        <a a-button class="btn_primary link" [pending]="!(url$ | async)" *ngIf="url$ | async"  [href]="url$ | async">OK</a>
      </div>
    </div>
  </div>
</a-center-container>
<a-overlay></a-overlay>
