import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { TasksSavedListResetAction } from '../actions/task-filters/tasks-saved-list.actions';
import { DefaultTasksTimeOnBoardFilterActionTypes } from '../actions/task-filters/default-time-on-board-filter.actions';

@Injectable()
export class DefaultTasksTimeOnBoardFilterEffects {
  @Effect()
  DefaultTimeOnBoardFilterToggleAction = this._actions$
    .ofType(DefaultTasksTimeOnBoardFilterActionTypes.TOGGLE_ITEM_ID)
    .pipe(map(_ => new TasksSavedListResetAction()));

  constructor(private _actions$: Actions) {}
}
