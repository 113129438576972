import { HttpQueryParam } from '../../interfaces';
import { OverviewFilter, OverviewFilterFields } from '../../interfaces/overview-filters';
import { ESInterface } from '../state';
import { OverviewActivity } from '../../interfaces';
import { Action } from '../actions/unsafe-action';

export const LOAD_FILTERS = '[OVERVIEW_ACTIVITY] Load Filters';
export const EDIT_FILTERS = '[OVERVIEW_ACTIVITY] Edit Filters';
export const UPDATED_FILTERS = '[OVERVIEW_ACTIVITY] Update Filters';
export const GET_INDEX = '[OVERVIEW_ACTIVITY] Get Index Activities';
export const GET = '[OVERVIEW_ACTIVITY] Get Activities';
export const CLEAR = '[OVERVIEW_ACTIVITY] Clear Activities';
export const CLEAR_FILTER = '[OVERVIEW_ACTIVITY] Clear filters';
export const GET_COMPLETED = '[OVERVIEW_ACTIVITY] get completed-table-view';
export const LOAD_MORE = '[OVERVIEW_ACTIVITY] Load More';
export const SAVE_FILTER = '[OVERVIEW_ACTIVITY] Save Filter on Server';
export const UPDATED_STATE = '[OVERVIEW_ACTIVITY] Entities Updated';

export class GetActivities implements Action {
  readonly type = GET;
}

export class GetComplete implements Action {
  readonly type = GET_COMPLETED;

  constructor(public payload: { itemsCount: number }) {}
}

export class ClearActivities implements Action {
  readonly type = CLEAR;

  constructor(public payload?: HttpQueryParam) {}
}

export class ClearFilter implements Action {
  readonly type = CLEAR_FILTER;
}

export class LoadFilters implements Action {
  readonly type = LOAD_FILTERS;
}

export class EditFilters implements Action {
  readonly type = EDIT_FILTERS;

  constructor(public payload: Partial<OverviewFilterFields>) {}
}

export class UpdatedFilters implements Action {
  readonly type = UPDATED_FILTERS;

  constructor(public payload: any) {}
}

export class LoadMore implements Action {
  readonly type = LOAD_MORE;
}

export class SaveFilter implements Action {
  readonly type = SAVE_FILTER;

  constructor(public payload: Partial<OverviewFilter>) {}
}

export class UpdatedState implements Action {
  readonly type = UPDATED_STATE;

  constructor(public payload: ESInterface<OverviewActivity>) {}
}

export type Actions =
  | ClearActivities
  | LoadFilters
  | UpdatedFilters
  | EditFilters
  | GetActivities
  | GetComplete
  | LoadMore
  | SaveFilter
  | UpdatedState;
