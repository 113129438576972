<div a-context-popup
     class="duedate_popup"
     [title]="'Project'"
     (close)="onCloseProjectPopup()"
>

  <sidebar-projects-list
  [projects]="boardProjects"
  [taskProjectId]="initialValue"
  (select)="onSelect($event)"
  ></sidebar-projects-list>

</div>
