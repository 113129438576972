import { labelAction } from '../functions/action';
import { PartOfEntity, BoardActivity, HttpQueryParam } from '../../interfaces';
import { BOARD_ACTIVITY } from '../../constants';
import { Action } from './unsafe-action';

export const BoardActivityActionTypes = {
  LOAD: labelAction(BOARD_ACTIVITY, 'LOAD'),
  GET: labelAction(BOARD_ACTIVITY, 'GET'),
  GET_COMPLETE: labelAction(BOARD_ACTIVITY, 'GET_COMPLETE'),
  GET_FAIL: labelAction(BOARD_ACTIVITY, 'GET_FAIL'),
  ADD: labelAction(BOARD_ACTIVITY, 'ADD'),
  ADD_COMPLETE: labelAction(BOARD_ACTIVITY, 'ADD_COMPLETE'),
  ADD_FAIL: labelAction(BOARD_ACTIVITY, 'ADD_FAIL'),
  EDIT: labelAction(BOARD_ACTIVITY, 'EDIT'),
  EDIT_COMPLETE: labelAction(BOARD_ACTIVITY, 'EDIT_COMPLETE'),
  EDIT_FAIL: labelAction(BOARD_ACTIVITY, 'EDIT_FAIL'),
  DELETE: labelAction(BOARD_ACTIVITY, 'DELETE'),
  DELETE_COMPLETE: labelAction(BOARD_ACTIVITY, 'DELETE_COMPLETE'),
  DELETE_FAIL: labelAction(BOARD_ACTIVITY, 'DELETE_FAIL')
};

export class BoardActivityLoadAction implements Action {
  type = BoardActivityActionTypes.LOAD;

  constructor(public payload: { id: number; params?: HttpQueryParam }) {}
}

export class BoardActivityGetAction implements Action {
  type = BoardActivityActionTypes.GET;

  constructor(public payload?: HttpQueryParam) {}
}

export class BoardActivityGetCompleteAction implements Action {
  type = BoardActivityActionTypes.GET_COMPLETE;

  constructor() {}
}

export class BoardActivityEditAction implements Action {
  type = BoardActivityActionTypes.EDIT;
  constructor(public payload: PartOfEntity) {}
}

export class BoardActivityEditCompleteAction implements Action {
  type = BoardActivityActionTypes.EDIT_COMPLETE;

  constructor(public payload: BoardActivity) {}
}

export class BoardActivityDeleteAction implements Action {
  type = BoardActivityActionTypes.DELETE;

  constructor(public payload: BoardActivity) {}
}

export class BoardActivityDeleteCompleteAction implements Action {
  type = BoardActivityActionTypes.DELETE_COMPLETE;

  constructor(public payload?: any) {}
}

export class BoardActivityAddAction implements Action {
  type = BoardActivityActionTypes.ADD;

  constructor(public payload: PartOfEntity) {}
}

export class BoardActivityAddCompleteAction implements Action {
  type = BoardActivityActionTypes.ADD_COMPLETE;

  constructor(public payload: BoardActivity) {}
}

export type BoardActivityActions =
  | BoardActivityGetAction
  | BoardActivityLoadAction
  | BoardActivityGetCompleteAction
  | BoardActivityEditAction
  | BoardActivityEditCompleteAction
  | BoardActivityDeleteAction
  | BoardActivityDeleteCompleteAction
  | BoardActivityAddAction
  | BoardActivityAddCompleteAction;
