<div *ngIf="isShowTabs$ | async" class="form_choosing_tabs">
  <h3 [ngClass]="{active: (isHyggerForm$ | async)}" (click)="onShowForm(1)">Hygger</h3>
  <h3 [ngClass]="{active: !(isHyggerForm$ | async)}" (click)="onShowForm(2)">Jira</h3>
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="isHyggerForm$ | async;else jiraForm">
  <general-error-message *ngIf="_formService.isError$ | async">
    <span>{{_formService.serverError.message}}</span>
  </general-error-message>
  <div class="row" *ngIf="form.get('title')">
    <div class="label">Title</div>
    <textarea
      autosize
      rows="1"
      formControlName="title"
      [focus]="true"

    ></textarea>
  </div>

  <div class="row" *ngIf="form.get('board')">
    <label id="board-select">
      Board
    </label>
    <div class="select">
      <a-searchable-select
        formControlName="board"
        [labelId]="'board-select'"
        [inputValues]="boards$ | async"
        [matchProp]="'name'"
      >
      </a-searchable-select>
    </div>
  </div>

  <div class="row" *ngIf="(showSwimlanePicker$ | async) && form.get('swimlane')">
    <div class="label">
      Swimlane
    </div>
    <div class="select">
      <select formControlName="swimlane">
        <option *ngFor="let board of  swimlanes$ | async" [value]="board.id">{{board.name}}</option>
      </select>
    </div>
  </div>

  <div class="row" *ngIf="(columns$ | async | getProperty : 'length') && form.get('column')">
    <div class="label">
      Column
    </div>
    <div class="select">
      <select formControlName="column">
        <option *ngFor="let column of  columns$ | async" [value]="column.id">{{column.name}}</option>
      </select>
    </div>
  </div>

  <div class="row" *ngIf="form.get('position')">
    <div class="label">
      Position
    </div>
    <div class="select">
      <select formControlName="position">
        <option *ngFor="let position of  availablePositions" [value]="position.value">{{position.name}}</option>
      </select>
    </div>
  </div>

  <div class="row" *ngIf="form.get('users')">
    <atlaz-members
      [selectedUsers]="selectedUsers"
      [possibleUsers]="selectedBoardUsers$ | async"
      [projectOnlyUsers]="projectOnlyUsers$ | async"
      [editPermissions]="true"
      (addUserToBoard)="onAddUserToBoard($event)"
      (updateUserSelection)="onUpdateUserSelection($event)"
    >
    </atlaz-members>
  </div>
  <br>
  <button a-button class="btn_primary button"
       [pending]="_formService.isPending$ | async"
       (click)="onSubmit()"
  >{{config.submitButtonName}}</button>
</form>

<ng-template #jiraForm>
  <push-to-jira-form [task]="task" (close)="onClose()"></push-to-jira-form>
</ng-template>
