import { User } from '../app/interfaces/user';

export const MENTIONS_PATTERN = /(\B@[a-z0-9_.-]+)/gi;

export const getMentionsFromText = text => {
  const match = text.match(MENTIONS_PATTERN);
  return match !== null ? match : [];
};

export const issetMentionsInText = (text, exludedUsers: User[]) => {
  let result = false;
  let mentionsFromText = getMentionsFromText(text);

  if (mentionsFromText.length > 0) {
    exludedUsers.forEach(user => {
      mentionsFromText = mentionsFromText.filter(mentionsWithoutUser(user));
    });

    result = mentionsFromText.length >= 1;
  }

  return result;
};

export const mentionsWithoutUser = (user: User) => mention => {
  return mention !== '@' + user.nickname;
};
