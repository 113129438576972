import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formatTimeToSecond, secondsToEdit } from '../../../../helpers';

import { CustomValidators } from '../../../shared/validators/custom-validators';
import * as moment from 'moment-mini-ts';
import { HotKey, HotSpaceAndEnter } from '../../../shared/directives/hot-key.directive';
import { getFormattingHtmlContent } from '../../../../helpers/html-helper';
import { WorkLogFormData } from './work-log-form';

@Component({
  selector: 'work-log-form',
  templateUrl: './work-log-form.component.html',
  styleUrls: ['./work-log-form.component.scss']
})
export class WorkLogFormComponent implements OnInit {
  @Input() logWorkDate: string;
  @Input() loggedTime: number;
  @Input() comment: string;
  @Input() editMode: boolean;
  @Input() payload: any;
  @Output() closePopupEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() submitWorkLog: EventEmitter<WorkLogFormData> = new EventEmitter();

  public logWorkForm: FormGroup;

  constructor(private _fb: FormBuilder) {}

  get postHotKeys(): HotKey[] {
    return HotSpaceAndEnter;
  }

  ngOnInit(): any {
    this.initLogWorkForm();
    if (this.editMode) {
      this.logWorkForm.get('logDate').disable();
    }
  }

  initLogWorkForm() {
    let initialData;
    initialData = {
      logDate: this.payload.logworkLogDate
        ? moment.unix(this.payload.logworkLogDate)
        : moment(this.logWorkDate).set({ h: moment().hours(), m: moment().minutes(), s: 0 }),
      time: this.loggedTime ? secondsToEdit(this.loggedTime) : '',
      comment: getFormattingHtmlContent(this.comment)
    };

    this.logWorkForm = this._fb.group({
      logDate: [initialData.logDate, CustomValidators.composeAnd([Validators.required])],
      time: [initialData.time, Validators.required],
      comment: [initialData.comment]
    });
  }

  onAddLogWork() {
    this.logWorkForm.get('time').markAsDirty();
    if (formatTimeToSecond(this.logWorkForm.value.time) === 0) {
      this.logWorkForm.get('time').setErrors([{ parseTimeFailed: true }]);
    }

    if (this.logWorkForm.invalid) {
      return;
    }
    const workLogData = {
      loggedTime: formatTimeToSecond(this.logWorkForm.value.time),
      logDate: undefined,
      logComment: this.logWorkForm.value.comment,
      editMode: this.editMode,
      payload: this.payload
    };
    if (this.logWorkForm.value.logDate) {
      workLogData.logDate = this.logWorkForm.value.logDate.unix();
    }

    if (workLogData.loggedTime > 0) {
      this.submitWorkLog.emit(workLogData);
    }
    this.closePopupEvent.emit(true);
  }

  onClose() {
    this.closePopupEvent.emit(true);
  }
}
