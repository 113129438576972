
import {map, distinctUntilChanged, filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { AppState } from '../state';
import { Store } from '@ngrx/store';
import { CurrentCompanyUpdatedAction } from '../actions/current-company.actions';
import { isPresent } from '../../../helpers';
import { getCurrentCompany } from '../reducers/current-company.reducer';

@Injectable()
export class CurrentCompanyEffect {
  @Effect()
  updateCurrentCompany$ = this._store
    .select(getCurrentCompany).pipe(
    filter(isPresent),
    distinctUntilChanged(),
    map(company => new CurrentCompanyUpdatedAction(company)),);

  constructor(private _store: Store<AppState>) {}
}
