import { map, switchMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { SWIMLANE_PL } from '../../constants';
import { HttpQueryParam, PartOfEntity } from '../../interfaces';

import { SwimlaneActionTypes, SwimlaneDeleteCompleteAction } from '../actions/swimlane.actions';
import { defaultErrorHandler } from './root.effect';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { HandleResponseAction } from '../actions/root.action';

@Injectable()
export class SwimlaneEffects {
  @Effect()
  getSwimlaneList$ = this.actions$.ofType(SwimlaneActionTypes.GET).pipe(
    switchMap(({ type, payload }: { type: string; payload: HttpQueryParam }) => {
      return this._atlazApi
        .get(SWIMLANE_PL, payload)
        .pipe(map(resp => new HandleResponseAction(resp)), catchError(defaultErrorHandler(type, payload)));
    })
  );

  @Effect()
  editSwimlane$ = this.actions$.ofType(SwimlaneActionTypes.EDIT).pipe(
    switchMap(({ type, payload }: { type: string; payload: PartOfEntity }) => {
      return this._atlazApi
        .patch(SWIMLANE_PL, payload)
        .pipe(map(resp => new HandleResponseAction(resp)), catchError(defaultErrorHandler(type, payload)));
    })
  );
  @Effect()
  deleteSwimlane$ = this.actions$.ofType(SwimlaneActionTypes.DELETE).pipe(
    switchMap(({ type, payload: swimlaneId }: { type: string; payload: number }) => {
      return this._atlazApi
        .deleteRequest([SWIMLANE_PL, swimlaneId])
        .pipe(
          map(_ => new SwimlaneDeleteCompleteAction(swimlaneId)),
          catchError(defaultErrorHandler(type, swimlaneId))
        );
    })
  );

  constructor(private actions$: Actions, private _atlazApi: AtlazApiV2Service) {}
}
