import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'checklist-progress',
  template: `
    <p class="percents" *ngIf="checkboxesCount">{{progressLinePersent}}%</p>
    <div class="progress_bar">        
      <div class="progress_checklist" *ngIf="checkboxesCount" [ngStyle]="progressStyle" (click)="setStyles()"></div>
    </div>
  `,
  styleUrls: ['./checklist-progress.component.scss']
})
export class ChecklistProgressComponent implements OnChanges {
  @Input() checkboxesCount;
  @Input() completedCheckboxes;

  public progressStyle: Object;
  public progressLinePersent: string;

  ngOnChanges(changes: {}) {
    this.setStyles();
  }

  setStyles() {
    const progressLineWidthInPersent = this.completedCheckboxes / this.checkboxesCount * 100 + '%';

    this.progressLinePersent = (this.completedCheckboxes / this.checkboxesCount * 100).toFixed(0);

    this.progressStyle = {
      width: progressLineWidthInPersent
    };
  }
}
