import {AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { Subscription, Observable } from "rxjs";
import { take } from "rxjs/operators";
import { DefaultTaskTimeOnColumnFilterToggleAction } from "../../../../ngrx/actions/task-filters/default-time-on-column-filter.actions";
import { Store } from "@ngrx/store";
import { AppState } from "../../../../ngrx/state";
import { DaysRange } from "../../../../ngrx/actions/task-filters/task-default-filter.actions";
import { getDefaultTimeOnColumnFilterDaysRange } from "../../../../ngrx/reducers/task-filters/default-time-on-column-filter.reducer";

@Component({
  selector: 'time-on-column-form',
  templateUrl: './time-on-column-form.component.html',
  styleUrls: ['./time-on-column-form.component.scss']
})
export class TimeOnColumnFormComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() cancel = new EventEmitter();

  @ViewChild('moreThanInput') moreThanInput: ElementRef;
  @ViewChild('lessThanInput') lessThanInput: ElementRef;

  public storeTimeOnBoardFilterValue$: Observable<DaysRange>;

  subs: Subscription[] = [];

  moreThan: number;
  lessThan: number;

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this.storeTimeOnBoardFilterValue$ = this._store.pipe(getDefaultTimeOnColumnFilterDaysRange);
  }

  ngAfterViewInit() {
    this.storeTimeOnBoardFilterValue$.pipe(take(1)).subscribe((daysRange : DaysRange) => {
      this.moreThan = daysRange.moreThan;
      this.lessThan = daysRange.lessThan;
    });
  }

  onSubmit() {
    this._store.dispatch(new DefaultTaskTimeOnColumnFilterToggleAction({
      moreThan: this.moreThan,
      lessThan: this.lessThan
    }));
    this.cancel.emit();
  }

  onCancel() {
    this.cancel.emit();
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
