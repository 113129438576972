<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <form-errors></form-errors>
  <div>
    <label for="add-task-title">
      Task Title
    </label>
    <textarea id="add-task-title"
              rows="4"
              formControlName="title"
              autosize
              checkTouch
              [focus]="true">
    </textarea>
    <help-block
      [control]="form.get('title')"
    >
    </help-block>
  </div>
  <div class="row" *ngIf="!fixedBoard">
    <label for="add-task-board-select">
      Board
    </label>

    <a-searchable-select
      formControlName="board"
      [labelId]="'add-task-board-select'"
      [inputValues]="targetBoards$ | async"
      [matchProp]="'name'"
    >
    </a-searchable-select>
  </div>
  <div class="row" *ngIf="showColumn">
    <label for="add-task-column-select">
      Column
    </label>
    <select formControlName="column" id="add-task-column-select">
      <option *ngFor="let column of targetColumns$ | async" [value]="column.id">{{column.name}}</option>
    </select>
  </div>
  <div class="row" *ngIf="showSwimlane" [ngClass]="{collapsed: (targetSwimlanes$ | async)?.length === 1}">
    <label for="add-task-swimlane-select">
      Swimlane
    </label>
    <select id="add-task-swimlane-select" formControlName="swimlane">
      <option *ngFor="let swimlane of targetSwimlanes$ | async" [value]="swimlane.id">{{swimlane.name}}</option>
    </select>
  </div>
  <div class="row" *ngIf="showVersion">
    <label for="add-task-version-select">
      Version
    </label>
    <select id="add-task-version-select" formControlName="version">
      <option [value]="0"></option>
      <option *ngFor="let version of targetVersions$ | async" [value]="version.id">{{version.name}}</option>
    </select>
  </div>
  <br>
  <br>
  <button a-button
          type="button"
          class="btn_primary button"
          [pending]="(_formService.isPending$ | async) && !openTaskAfterSave"
          (click)="onSubmit()"
  >
    Save
  </button>
  <button a-button
          *ngIf="!hideSaveAndEdit"
          type="button"
          class="btn_primary button"
          [pending]="(_formService.isPending$ | async) && openTaskAfterSave"
          (click)="onSubmit(true)"
  >
    Save and Edit
  </button>
  <button
    a-button
    type="button"
    (keydown)="onClose(true)"
    (click)="onClose(true)"
    class="btn_text"
  >
    Cancel
  </button>
</form>
