import { AfterViewChecked, Component, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'connecting-line',
  templateUrl: './connecting-line.component.html',
  styleUrls: ['./connecting-line.component.scss']
})
export class ConnectingLineComponent implements AfterViewChecked {
  @Input() startElementId;
  @Input() endElementId;
  constructor(private _elRef: ElementRef) {}

  ngAfterViewChecked() {
    if (!this._elRef.nativeElement) {
      return;
    }
    const startEl = document.querySelector('[data-task-id="' + this.startElementId + '"]');
    const endEl = document.querySelector('[data-task-id="' + this.endElementId + '"]');

    if (startEl && endEl) {
      const SELeft = parseInt(startEl['style'].left);
      const SEWidth = parseInt(startEl['style'].width);
      const SERight = SELeft + SEWidth;
      const SEHeightCenter = startEl.getBoundingClientRect().top + 12;

      const EELeft = parseInt(endEl['style'].left);
      const EEHeightCenter = endEl.getBoundingClientRect().top + 12;

      const pathEl = this._elRef.nativeElement.children[0].children[0];
      let d = '';
      let left;
      let height;
      let width;
      let top;
      if (EEHeightCenter > SEHeightCenter) {
        if (EELeft > SERight) {
          //case
          // sss
          //     eee;
          left = SERight + 'px';
          height = EEHeightCenter - SEHeightCenter + 'px';
          width = EELeft - SERight + 'px';
          top = '26px';
          d = 'M0 0 h ' + (EELeft - SERight - 4) + ' v ' + (EEHeightCenter - SEHeightCenter) + ' h 4';
        } else {
          //case
          //      sss
          // eee
          left = EELeft - 5 + 'px';
          height = EEHeightCenter - SEHeightCenter + 'px';
          width = SERight - EELeft + 10 + 'px';
          top = '26px';
          d =
            'M' +
            (SERight - EELeft + 4) +
            ' 0 h 5 v 21 ' +
            ' h ' +
            (EELeft - SERight - 8) +
            ' v ' +
            (EEHeightCenter - SEHeightCenter - 22) +
            'h 5';
        }
      } else {
        if (EELeft > SERight) {
          //case
          //      eee
          // sss
          left = SERight + 'px';
          height = SEHeightCenter - EEHeightCenter + 2 + 'px';
          width = EELeft - SERight + 'px';
          top = EEHeightCenter - SEHeightCenter + 25 + 'px';
          d =
            'M0 ' +
            (SEHeightCenter - EEHeightCenter) +
            ' h ' +
            (EELeft - SERight - 4) +
            ' v ' +
            (EEHeightCenter - SEHeightCenter) +
            ' h 4';
        } else {
          //case
          // eee
          //      sss
          left = EELeft - 5 + 'px';
          height = SEHeightCenter - EEHeightCenter + 'px';
          width = SERight - EELeft + 10 + 'px';
          top = EEHeightCenter - SEHeightCenter + 25 + 'px';
          d = 'M5 0 h -4 v ' + (SEHeightCenter - EEHeightCenter - 25) + ' h ' + (SERight - EELeft + 8) + ' v 24 h -4';
        }
      }

      Object.assign(this._elRef.nativeElement.style, {
        left: left,
        top: top,
        width: width,
        height: height
      });
      pathEl.setAttribute('d', d);
    }
  }
}
