<div class="task_global_container" tabindex="0" (keydown.esc)="closeOnEsc($event)">
  <overlay tabindex="-1" (click)="onClose()"></overlay>
  <div class="task_popup" #wrap>
    <div class="task_popup_message" *ngIf="(task$ | async)?.archived">Archived</div>
    <div class="task_popup_message" *ngIf="(task$ | async)?.released">Released</div>
    <div class="task_popup_message task_popup_message_done" *ngIf="showCompletedBanner$ | async">Completed</div>
    <div class="task_popup_message" *ngIf="archivedGroup$ | async">This task is in an archived group</div>
    <div class="task_popup_message" *ngIf="archivedColumn$ | async">This task is in an archived column</div>
    <div class="task_popup_wrap">
      <close-button (close)="onClose()" class="close_button"></close-button>
      <task-permission-pages></task-permission-pages>
    </div>
  </div>
</div>
