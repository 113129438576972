import { FormControl } from '@angular/forms';

export class PatternValidator {
  public static pattern(pattern) {
    return (control: FormControl) => {
      if (!pattern.test(control.value)) {
        return {
          PatternValidator: true
        };
      }
    };
  }
}
