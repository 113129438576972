import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { User } from '../../../interfaces';

@Component({
  selector: 'members-list',
  templateUrl: './members-list.component.html',
  styleUrls: ['./members-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MembersListComponent implements OnInit, OnChanges {
  @Input() possibleUsers: User[];
  @Input() selectedUsers: User[];
  @Input() projectOnlyUsers: User[] = [];
  @Input() isPublicBoard = false;

  @Output() addUser = new EventEmitter();
  @Output() addUserToBoard = new EventEmitter();
  @Output() removeUser = new EventEmitter();

  public allUsers: User[];
  public selectedUsersMap: { [id: number]: boolean } = {};
  public isConfirmAddProjectUser = false;
  public userToConfirm: User;
  constructor() {}

  ngOnInit() {
    const addUserToMap = (source = {}, user) => {
      source[user.id] = true;
      return source;
    };
    this.selectedUsersMap = this.selectedUsers.reduce(addUserToMap, {});
    const nonSelectedUsers = this.possibleUsers.filter(user => !this.selectedUsersMap[user.id]);
    this.allUsers = [...this.selectedUsers, ...nonSelectedUsers];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['possibleUsers'] && !changes['possibleUsers'].firstChange) {
      this.allUsers = [
        ...changes['possibleUsers'].currentValue.filter(user => this.selectedUsersMap[user.id]),
        ...changes['possibleUsers'].currentValue.filter(user => !this.selectedUsersMap[user.id])
      ];
    }
  }

  onSelectUser(user: User) {
    if (!this.selectedUsersMap[user.id]) {
      this.addUser.emit(user);
      this.selectedUsersMap[user.id] = true;
    } else {
      this.removeUser.emit(user);
      this.selectedUsersMap[user.id] = false;
    }
  }

  onConfirmProjUser(user: User) {
    this.onSelectUser(user);
    this.addUserToBoard.emit(user);
    this.onToggleConfirm();
  }

  onToggleConfirm(user?) {
    if (user && this.isPublicBoard) {
      this.onSelectUser(user);
      return;
    }
    this.isConfirmAddProjectUser = !this.isConfirmAddProjectUser;
    if (user) {
      this.userToConfirm = user;
    }
  }
}
