import { combineLatest as observableCombineLatest, Observable, of as observableOf } from 'rxjs';

import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  pluck,
  publishReplay,
  refCount,
  switchMap
} from 'rxjs/operators';
import { compose, Store } from '@ngrx/store';
import { AppState, EntityState, ESInterface } from '../state';

import { TaskActions, TaskActionTypes } from '../actions/task.actions';

import { Column, Project, Task } from '../../interfaces';
import {
  addEntityState,
  deleteEntityState,
  editArrayOfEntityStates,
  editEntityState,
  removeArray,
  selectEntity
} from '../functions/reducer';
import {
  getAllEntitiesAsArray,
  getEntitiesByIds,
  getEntityByIdSelector,
  getEntitySelector,
  mapEntityField
} from '../functions/selectors';
import { share } from '../functions/util';
import {
  allPass,
  anyPass,
  compareArrays,
  createFindByPart,
  either,
  getActiveUserId,
  identity,
  isEmpty,
  isEquals,
  isPresent,
  replaceIds
} from '../../../helpers';

import { ScaleType, TASK_PL } from '../../constants';
import { getSelectedBoard, getSelectedBoardId } from './board.reducer';
import { getActiveTaskFilters, TaskFilter } from './task-filters';
import { FILTER_NOTIFICATIONS, SECONDS_IN_ONE_DAY } from './task-filters/default-task-filter.reducer';
import { getTaskNotifications } from './notification.reducer';
import * as moment from 'moment-mini-ts';
import { memoize, Pred } from 'ramda';
import { getDefaultMembersFilterIds } from './task-filters/default-members-filter.reducer';
import { getTaskKey, isTaskKey, parseTaskKey } from '../../../helpers/task';
import { findProjectByShortName, getProjectById } from './project.reducer';
import { getTasksSavedListEntity } from './task-filters/tasks-saved-list.reducer';

import { AppUrls } from '../../app-urls';
import { stateToArrayByFields } from '../../../helpers/entity';
import { getDefaultLabelsFilterIds } from './task-filters/default-labels-filter.reducer';
import { createSelector } from 'reselect';
import { getColumnsState, getGlobalDoneColumnsByBoardId } from './column.reducer';
import { getDefaultColumnsFilterIds } from './task-filters/default-columns-filter.reducer';
import { getDefaultSwimlanesFilterIds } from './task-filters/default-swimlanes-filter.reducer';
import { getDefaultStatusesFilterIds } from './task-filters/default-statuses-filter.reducer';
import { getDefaultColorsFilterValues } from './task-filters/default-colors-filter.reducer';
import { RoadmapDefaultColor, RoadMapItem } from '../../board/roadmap-board/interfaces/roadmap.interface';
import { getDefaultQuadsFilterValues } from './task-filters/default-quads-filter.reducer';
import { getDefaultVersionsFilterIds } from './task-filters/default-versions-filter.reducer';
import { getDefaultProjectsFilterIds } from './task-filters/default-projects-filter.reducer';
import { getDefaultTimeOnBoardFilterDaysRange } from './task-filters/default-time-on-board-filter.reducer';
import { getDefaultTimeOnColumnFilterDaysRange } from './task-filters/default-time-on-column-filter.reducer';
import { DaysRange } from '../actions/task-filters/task-default-filter.actions';

const initialState: ESInterface<Task> = {
  ids: [],
  entities: {},
  selectedEntityId: null
};

export function reducer(state = initialState, action: TaskActions): ESInterface<Task> {
  switch (action.type) {
    case TaskActionTypes.LOAD: {
      const taskId: number = <number>action.payload.id;
      return selectEntity(state, taskId);
    }

    case TaskActionTypes.EDIT_COMPLETE:
    case TaskActionTypes.EDIT: {
      let data = action.payload;

      if (action.payload.hasOwnProperty('loggedTime')) {
        const loggedTime = state.entities[action.payload.id]['loggedTime'] + action.payload['loggedTime'];
        data = Object.assign({}, action.payload, { loggedTime });
      }

      return editEntityState(state, data);
    }

    case TaskActionTypes.ADD_COMPLETE: {
      return addEntityState(state, action.payload, true);
    }

    case TaskActionTypes.DELETE: {
      return deleteEntityState(state, action.payload.id);
    }

    case TaskActionTypes.BATCH_DELETE: {
      return removeArray(state, action.payload);
    }

    case TaskActionTypes.ASSIGN_USERS: {
      const taskId: number = action.payload.id;

      const userIds = replaceIds(state.entities[taskId].usersIds, action.payload.users);
      return editEntityState(state, { id: taskId, usersIds: userIds });
    }

    case TaskActionTypes.BATCH_ASSIGN_USERS: {
      const params = {
        ids: action.payload.ids,
        value: action.payload.users.add || [],
        propName: 'userIds'
      };
      return editArrayOfEntityStates(state, params);
    }

    case TaskActionTypes.ASSIGN_VERSION: {
      return editEntityState(state, {
        id: action.payload.id,
        version: action.payload.version
      });
    }

    case TaskActionTypes.ASSIGN_LABELS: {
      const taskId: number = action.payload.id;

      const labelsIds = replaceIds(state.entities[taskId].labelsIds, action.payload.labels);
      return editEntityState(state, { id: taskId, labelsIds: labelsIds });
    }

    case TaskActionTypes.BATCH_ASSIGN_LABELS: {
      const params = {
        ids: action.payload.ids,
        value: action.payload.labels.add || [],
        propName: 'labelsIds'
      };
      return editArrayOfEntityStates(state, params);
    }

    case TaskActionTypes.ASSIGN_SUBSCRIBERS: {
      const taskId: number = action.payload.id;

      const userIds = replaceIds(state.entities[taskId].subscribersIds, action.payload.subscribers);
      return editEntityState(state, { id: taskId, subscribersIds: userIds });
    }

    case TaskActionTypes.REMOVE_FROM_STORE: {
      return {
        ids: state.ids.filter(id => !action.payload.includes(id)),
        entities: state.entities,
        selectedEntityId: state.selectedEntityId
      };
    }

    case TaskActionTypes.MOVE_FROM_DELETED_SWIMLANE: {
      const taskId: number = action.payload.taskId;
      const moveToSwimlaneId: number = action.payload.moveToSwimlaneId;

      return editEntityState(state, { id: taskId, swimlane: moveToSwimlaneId });
    }

    default: {
      return state;
    }
  }
}

export const fixTasksKeys = (
  updatedTasksState: ESInterface<Task>,
  updatedProjectsStage: ESInterface<Project>,
  newState: any
) => {
  if (updatedTasksState) {
    EntityState.from(updatedTasksState).forEach((task: Task) => {
      newState['tasks']['entities'][task.id] = {
        ...newState['tasks']['entities'][task.id],
        ...{ taskKey: getTaskKey(newState.tasks.entities[task.id], newState.projects.entities[task.project]) }
      };
    });
  }
  if (updatedProjectsStage) {
    EntityState.from(updatedProjectsStage).forEach((project: Project) => {
      if (newState['tasks'].ids) {
        newState['tasks'] = { ...newState['tasks'] };
        newState['tasks'].entities = { ...newState['tasks'].entities };
        newState['tasks'].ids.forEach(id => {
          if (newState['tasks'].entities[id] && newState['tasks'].entities[id].project === project.id) {
            newState['tasks']['entities'][id] = {
              ...newState['tasks']['entities'][id],
              ...{ taskKey: getTaskKey(newState.tasks.entities[id], project) }
            };
          }
        });
      }
    });
  }
  return newState;
};

export const isActiveTask = (task: Task | RoadMapItem) =>
  task && !task.released && !task.archived && !task.boardClosed && !task.columnArchived && !task.projectArchived;
export const inSwimlane = swimlaneId => (task: Task) => task.swimlane === swimlaneId;
export const inColumn = columnId => (task: Task) => task.column === columnId;
export const inBoard = boardId => (task: Task) => task.board === boardId;

export const getTaskState = getEntitySelector(TASK_PL);
export const getAllTasks = share(compose(getAllEntitiesAsArray, getTaskState));
export const getTaskById = id => share(compose(getEntityByIdSelector<Task>(id), getEntitySelector(TASK_PL)));

export const getBoardTasks = share((state$: Observable<AppState>) => {
  return state$.pipe(
    getTaskState,
    combineLatest(state$.pipe(getSelectedBoardId), (tasks: ESInterface<Task>, boardId: number) => {
      return stateToArrayByFields(tasks, { board: boardId, archived: 0, released: 0 });
    }),
    distinctUntilChanged(compareArrays)
  );
});

const relationColumnTasks = share(relationColumnTasksSelector);
const relationSwimlaneTasks = share(relationSwimlaneTasksSelector);

function relationColumnTasksSelector(state$: Observable<AppState>) {
  return state$.pipe(
    getBoardTasks,
    map(tasks => {
      const relation = tasks.reduce((acc: {}, task) => {
        acc[task.column] = acc[task.column] ? [...acc[task.column], task.id] : [task.id];

        return acc;
      }, {});

      return relation;
    }),
    distinctUntilChanged(isEquals)
  );
}

function relationSwimlaneTasksSelector(state$: Observable<AppState>) {
  return state$.pipe(
    getBoardTasks,
    map(tasks => {
      const relation = tasks.reduce((acc: {}, task) => {
        acc[task.swimlane] = acc[task.swimlane] ? [...acc[task.swimlane], task.id] : [task.id];

        return acc;
      }, {});

      return relation;
    }),
    distinctUntilChanged(isEquals)
  );
}

export const getTaskIdsByColumn = columnId => {
  return (state$: Observable<AppState>) => {
    return state$.pipe(relationColumnTasks, mapEntityField(columnId, []));
  };
};

export const getTaskIdsBySwimlane = swimlaneId => {
  return (state$: Observable<AppState>) => {
    return state$.pipe(relationSwimlaneTasks, mapEntityField(swimlaneId, []));
  };
};

export const getTaskIdsByColumnObjectWithComposite = (column: Column) => {
  return (state$: Observable<AppState>) => {
    return state$.pipe(
      relationColumnTasks,
      mapEntityField(column.id.toString(), []),
      map(columnTasks => {
        const filteredTaskIds = columnTasks[column.id] || [];
        if (column.hasOwnProperty('subColumnsIds')) {
          column.subColumnsIds.reduce((acc, currValue) => {
            filteredTaskIds.push(columnTasks[currValue]);
            return filteredTaskIds;
          }, filteredTaskIds);
        }
        return filteredTaskIds;
      })
    );
  };
};

export const getTasksByPredicate = predicate => (state$: Observable<AppState>): Observable<Task[]> =>
  state$.pipe(
    pluck(TASK_PL),
    distinctUntilChanged(),
    map((state: ESInterface<Task>) => {
      const filterEntity = (esi: ESInterface<Task>) => (id: number) =>
        esi.entities[id] && predicate(state.entities[id]);

      const getEntity = (esi: ESInterface<Task>) => (id: number) => esi.entities[id];

      return state.ids.filter(filterEntity(state)).map(getEntity(state));
    })
  );

export const getTasksByColumn = columnId => {
  return (state$: Observable<AppState>) => {
    return state$.pipe(
      getTaskIdsByColumn(columnId),
      switchMap(taskIds => state$.pipe(getEntitiesByIds(TASK_PL, taskIds)))
    );
  };
};

export const getTasksBySwimlane = swimlaneId => {
  return (state$: Observable<AppState>) => {
    return state$.pipe(
      getTaskIdsBySwimlane(swimlaneId),
      switchMap(taskIds => state$.pipe(getEntitiesByIds(TASK_PL, taskIds)))
    );
  };
};

const filterFunc = (filters: TaskFilter[], filterParams: {}) => (task: Task) =>
  filters.every(filter => filter.func(task, filterParams[filter.id]));

const isUserCreateNewTask = (time: number, userId: number) => (task: Task) =>
  time > 0 && task.createdAt > time && task.creator === userId;

const isFilterSelected = (itemIds: number[], field: string) => (source: { id: number }) => {
  return source[field] ? itemIds.some(id => source[field].includes(id)) : false;
};

const isSingleFilterFieldSelected = (itemIds: number[], field: string) => (task: Task, taskLink?: Task) => {
  const source = taskLink ? taskLink : task;
  return source[field] ? itemIds.some(id => source[field] === id) : false;
};
const isProjectSelected = (itemIds: number[]) => (task: Task, taskLink?: Task) => {
  const source = taskLink ? taskLink : task;
  if (itemIds.includes(-1)) {
    return !source.project || itemIds.some(id => source.project === id);
  }
  return source.project ? itemIds.some(id => source.project === id) : false;
};

const isDayRangeFilterSelected = (daysRange: DaysRange, field: string) => (task: Task, taskLink?: Task) => {
  const source = taskLink ? taskLink : task;

  let time;

  if (field === 'boards') {
    time = source.cardTimes[field][source.board];
  } else {
    time = source.cardTimes[field][source.column];
  }

  const days = Math.floor(time / SECONDS_IN_ONE_DAY);

  if (daysRange.moreThan && daysRange.lessThan) {
    return days >= daysRange.moreThan && days <= daysRange.lessThan;
  } else if (daysRange.moreThan) {
    return days >= daysRange.moreThan;
  } else if (daysRange.lessThan) {
    return days <= daysRange.lessThan;
  }

  return false;
};

const isStatusFilterSelected = (statusesTypes: string[], allColumns: ESInterface<Column>) => (
  task: Task,
  taskLink?: Task
) => {
  let typeToCheck;
  if (taskLink) {
    typeToCheck = task.roadMapLinkedTaskFields.columnParentType
      ? task.roadMapLinkedTaskFields.columnParentType
      : task.roadMapLinkedTaskFields.columnType;
  } else {
    const parentColumnId = allColumns.entities[task.column] ? allColumns.entities[task.column].parent : 0;
    typeToCheck = parentColumnId
      ? allColumns.entities[parentColumnId] && allColumns.entities[parentColumnId].type
      : allColumns.entities[task.column] && allColumns.entities[task.column].type;
  }
  return statusesTypes.some(type => type === typeToCheck) || false;
};

const isColorFilterSelected = (items: string[]) => (task: Task) => {
  return items.some(color => {
    switch (color) {
      case RoadmapDefaultColor: {
        return !task.roadmapColor;
      }
      // Uncomment in case the separate colors for in_group and other tasks will be returned
      // case RoadmapDefaultChildColor: {
      //   return !task.roadmapColor && !!task.parent;
      // }
      default: {
        return task.roadmapColor && task.roadmapColor === color;
      }
    }
  });
};

const isQuadFilterSelected = (items: { xMin: number; xMax: number; yMin: number; yMax: number }[]) => (task: Task) =>
  items.some(
    quad =>
      task.backlogScoreX >= quad.xMin &&
      task.backlogScoreX <= quad.xMax &&
      task.backlogScoreY >= quad.yMin &&
      task.backlogScoreY <= quad.yMax
  );

const isTaskInIgnoreList = tasksSavedList => (task: Task) => tasksSavedList.tasksIds.includes(task.id);

const isArrayEmpty = (arr: any[]) => () => isEmpty(arr);

const _getQuadComplianceMap = selectedBoard => {
  const xAxis = { min: 1, mid: 50, max: 100 };
  const yAxis = { min: 1, mid: 50, max: 100 };
  if (selectedBoard.backlogScoreXType === ScaleType.shirt) {
    xAxis.mid = 3;
    xAxis.max = 5;
  }
  if (selectedBoard.backlogScoreYType === ScaleType.shirt) {
    yAxis.mid = 3;
    xAxis.max = 5;
  }
  return [
    {
      xMin: xAxis.mid,
      xMax: xAxis.max,
      yMin: yAxis.mid,
      yMax: yAxis.max
    },
    {
      xMin: xAxis.min,
      xMax: xAxis.mid,
      yMin: yAxis.mid,
      yMax: yAxis.max
    },
    {
      xMin: xAxis.min,
      xMax: xAxis.mid,
      yMin: yAxis.min,
      yMax: yAxis.mid
    },
    {
      xMin: xAxis.mid,
      xMax: xAxis.max,
      yMin: yAxis.min,
      yMax: yAxis.mid
    }
  ];
};

export const getUsersTasksFilter = (state$: Observable<AppState>) =>
  state$.pipe(
    getActiveTaskFilters,
    switchMap((filters: TaskFilter[]) => {
      const currentUserId = getActiveUserId();
      const timeStart = observableCombineLatest(
        state$.pipe(getDefaultMembersFilterIds),
        state$.pipe(getDefaultLabelsFilterIds),
        state$.pipe(getDefaultColumnsFilterIds),
        state$.pipe(getDefaultSwimlanesFilterIds),
        state$.pipe(getDefaultVersionsFilterIds),
        state$.pipe(getDefaultProjectsFilterIds),
        state$.pipe(getDefaultStatusesFilterIds),
        state$.pipe(getDefaultColorsFilterValues),
        state$.pipe(getDefaultQuadsFilterValues),
        state$.pipe(getDefaultTimeOnBoardFilterDaysRange),
        state$.pipe(getDefaultTimeOnColumnFilterDaysRange),
        (
          membersIds: number[],
          labelsIds: number[],
          columnsIds: number[],
          swimlanesIds: number[],
          versionsIds: number[],
          projectsIds: number[],
          statusesTypes: string[],
          colors: string[],
          quads: number[]
        ) => {
          let result;
          if (
            filters.length > 0 ||
            membersIds.length > 0 ||
            labelsIds.length > 0 ||
            columnsIds.length > 0 ||
            swimlanesIds.length > 0 ||
            versionsIds.length > 0 ||
            projectsIds.length > 0 ||
            statusesTypes.length > 0 ||
            colors.length > 0 ||
            quads.length > 0
          ) {
            result = moment.utc().unix();
          } else {
            result = 0;
          }
          return result;
        }
      );

      return observableCombineLatest(
        state$.pipe(getTaskNotifications({ seen: 0 })),
        state$.pipe(getDefaultMembersFilterIds),
        state$.pipe(getTasksSavedListEntity),
        state$.pipe(getDefaultLabelsFilterIds),
        state$.pipe(getDefaultColumnsFilterIds),
        state$.pipe(getDefaultSwimlanesFilterIds),
        state$.pipe(getDefaultVersionsFilterIds),
        state$.pipe(getDefaultProjectsFilterIds),
        state$.pipe(getDefaultStatusesFilterIds),
        state$.pipe(getDefaultColorsFilterValues),
        state$.pipe(getDefaultQuadsFilterValues),
        state$.pipe(getDefaultTimeOnBoardFilterDaysRange),
        state$.pipe(getDefaultTimeOnColumnFilterDaysRange),
        state$.pipe(getSelectedBoard),
        state$.pipe(map(getColumnsState), distinctUntilChanged()),
        timeStart
      ).pipe(
        map(
          ([
            notifications,
            membersIds,
            tasksSavedList,
            labelsIds,
            columnsIds,
            swimlanesIds,
            versionsIds,
            projectsIds,
            statusesTypes,
            colorsValues,
            quadsValues,
            boardDayRange,
            columnDayRange,
            selectedBoard,
            allColumns,
            time
          ]) => {
            let qv = [];
            if (quadsValues.length && selectedBoard) {
              const map = _getQuadComplianceMap(selectedBoard);
              qv = quadsValues.map(item => map[item]);
            }
            return anyPass([
              isTaskInIgnoreList(tasksSavedList),
              isUserCreateNewTask(time, currentUserId),
              allPass([
                filterFunc(filters, { [FILTER_NOTIFICATIONS]: notifications }),
                either(isArrayEmpty(<number[]>membersIds), isFilterSelected(<number[]>membersIds, 'usersIds')),
                either(isArrayEmpty(<number[]>labelsIds), isFilterSelected(<number[]>labelsIds, 'labelsIds')),
                either(isArrayEmpty(<number[]>columnsIds), isSingleFilterFieldSelected(<number[]>columnsIds, 'column')),
                either(
                  isArrayEmpty(<number[]>swimlanesIds),
                  isSingleFilterFieldSelected(<number[]>swimlanesIds, 'swimlane')
                ),
                either(
                  isArrayEmpty(<number[]>versionsIds),
                  isSingleFilterFieldSelected(<number[]>versionsIds, 'version')
                ),
                either(isArrayEmpty(<number[]>projectsIds), isProjectSelected(<number[]>projectsIds)),
                either(
                  isArrayEmpty(<string[]>statusesTypes),
                  isStatusFilterSelected(<string[]>statusesTypes, allColumns)
                ),
                either(isArrayEmpty(<string[]>colorsValues), isColorFilterSelected(<string[]>colorsValues)),
                either(isArrayEmpty(<number[]>quadsValues), isQuadFilterSelected(qv)),
                either(
                  () => !boardDayRange.moreThan && !boardDayRange.lessThan,
                  isDayRangeFilterSelected(boardDayRange, 'boards')
                ),
                either(
                  () => !columnDayRange.moreThan && !columnDayRange.lessThan,
                  isDayRangeFilterSelected(columnDayRange, 'columns')
                )
              ])
            ]);
          }
        )
      );
    })
  );

export const getTasksByUsersFilter = (predicate: Pred = _ => true) => (state$: Observable<AppState>) =>
  state$.pipe(
    getTasksByPredicate(predicate),
    // call filterFn with only argument
    combineLatest(getUsersTasksFilter(state$), (tasks, filterFn) => tasks.filter((task: Task) => filterFn(task)))
  );

export const taskTitle = (task$: Observable<Task>, project$: Observable<Project>) =>
  observableCombineLatest(
    project$,
    task$,
    (project: Project, task: Task) =>
      project && task.project === project.id && task.numberInProject > 0
        ? project.shortName + '-' + task.numberInProject + ': ' + task.title
        : task ? task.title : ''
  ).pipe(distinctUntilChanged(), publishReplay(1), refCount());

export const findTask: any = (findFn = identity) => store$ => store$.pipe(getAllTasks, map(findFn));
export const findTaskByPart: (partOfTask: Partial<Task>) => (store$: Store<AppState>) => Observable<Task> = compose(
  findTask,
  createFindByPart
);

/**
 * @param taskKey - ex. ATLAZ-42
 */
export const findTaskByTaskKey = (taskKey: string) => (store$: Store<AppState>) => {
  const [, projectShortName, numberInProject] = parseTaskKey(taskKey);
  const project$ = store$.pipe(findProjectByShortName(projectShortName));
  return <Observable<Task>>project$.pipe(
    switchMap(
      project =>
        isPresent(project)
          ? store$.pipe(findTaskByPart({ project: project.id, numberInProject: +numberInProject }))
          : observableOf(undefined)
    )
  );
};

/**
 * @param taskSlug
 *  taskId or TaskKey: ex 1024 or ATLAZ-42
 */
export const getTaskBySlug = memoize(
  (taskSlug: string) => (isTaskKey(taskSlug) ? findTaskByTaskKey(taskSlug.toUpperCase()) : getTaskById(taskSlug))
);

export const findTaskKey = memoize((task: Task) => (store$: Store<AppState>): Observable<string> => {
  if (task && isPresent(task.project)) {
    const project$ = store$.pipe(getProjectById(task.project));
    return project$.pipe(filter(isPresent), map((project: Project) => getTaskKey(task, project)));
  } else {
    return observableOf('');
  }
});

export const getTaskUrl = memoize((task: Task) => (store$: Store<AppState>) => {
  return task
    ? store$.pipe(
        findTaskKey(task),
        map(taskKey => AppUrls.getUrlTask(taskKey ? taskKey : task.id)),
        distinctUntilChanged(),
        publishReplay(1),
        refCount()
      )
    : observableOf('');
});
export const getTasksState = (state: AppState) => <ESInterface<Task>>state.tasks;

export const getTasksByBoardId = (boardId: number) =>
  createSelector(getTasksState, tasksState =>
    tasksState.ids.map((taskId: number) => tasksState.entities[taskId]).filter((task: Task) => task.board === boardId)
  );

export const getTasksByRoadmapId = (boardId: number) =>
  createSelector(getTasksState, tasksState =>
    tasksState.ids
      .map((taskId: number) => tasksState.entities[taskId])
      .filter(
        (task: Task) =>
          !task.linkToTask && (task.board === boardId || (task.roadmapBoards && task.roadmapBoards.includes(boardId)))
      )
  );

export const getTasksInGlobalDoneByBoardId = (boardId: number) =>
  createSelector(getGlobalDoneColumnsByBoardId(boardId), getTasksByBoardId(boardId), (columns, tasks) => {
    const columnIds = columns.map(column => column.id);
    return tasks.filter(task => columnIds.includes(task.column));
  });

export const getActiveTasksByBoardIdWithLinked = (boardId: number) =>
  createSelector(getTasksState, tasksState => {
    const boardTasks = tasksState.ids
      .map((taskId: number) => tasksState.entities[taskId])
      .filter((task: Task) => task.board === boardId && isActiveTask(task));
    const linkedTasks = boardTasks
      .filter(task => task.linkToTask && tasksState.entities[task.linkToTask])
      .map(task => tasksState.entities[task.linkToTask]);
    return [...boardTasks, ...linkedTasks];
  });

export namespace fromTask {
  export const taskById = id => (state: AppState) => getTasksState(state).entities[id];

  export const getTaskSubscribersIds = id =>
    createSelector(taskById(id), task => (task && task.subscribersIds ? task.subscribersIds : []));

  export const getByColumnIds = (colIds: number[]) =>
    createSelector(getTasksState, tasks =>
      tasks.ids.map(id => tasks.entities[id]).filter(task => isActiveTask(task) && colIds.includes(task.column))
    );

  export const getByIds = (ids: number[]) =>
    createSelector(getTasksState, tasks => (ids || []).map(id => tasks.entities[id]).filter(task => task));

  export const getByVersionId = (versionId: number) =>
    createSelector(getTasksState, tasks =>
      tasks.ids.map(id => tasks.entities[id]).filter(task => isActiveTask(task) && task.version === versionId)
    );
}
