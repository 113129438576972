import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Label, LabelTag, Task } from '../../../../interfaces';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../ngrx/state';
import { labelsToLabelTag } from '../../../../../helpers/labels';
import { fromLabels } from '../../../../ngrx/reducers/label.reducer';
import { getTaskLabels } from '../../../../ngrx/functions/crossed.selector';
import { TaskBatchAssignLabelsAction } from '../../../../ngrx/actions/task.actions';

@Component({
  selector: 'sidebar-labels-popup',
  templateUrl: './sidebar-labels-popup.component.html',
  styleUrls: ['./sidebar-labels-popup.component.scss']
})
export class SidebarLabelsPopupComponent implements OnInit {
  @Input() task: Task;
  @Input() isBatchActions: boolean;
  @Input() batchTasks: Task[];
  @Input() removeMode: boolean;
  @Output() close = new EventEmitter();

  public labels$: Observable<LabelTag[]>;
  public taskLabelsIds$: Observable<number[]> | BehaviorSubject<number[]>;
  public boardLabels$: Observable<LabelTag[]>;
  private _boardLabels: Label[];
  private isPristine = true;

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    if (this.isBatchActions) {
      this.taskLabelsIds$ = new BehaviorSubject([]);
    } else {
      this.taskLabelsIds$ = this._store
        .select(getTaskLabels(this.task.id))
        .pipe(map(labels => labels.map(label => label.id)));
    }

    this.boardLabels$ = this._store
      .select(fromLabels.getByBoardId(this.task.board))
      .pipe(tap(labels => (this._boardLabels = labels)), map(labelsToLabelTag));

    this.labels$ = combineLatest(this.boardLabels$, this.taskLabelsIds$).pipe(
      map(([boardLabels, taskLabelsIds]: [LabelTag[], number[]]) =>
        boardLabels.map(tag => {
          tag.checked = taskLabelsIds.includes(tag.label.id);
          return tag;
        })
      )
    );
  }

  onToggleBatchItem({ id, isAdding }) {
    this.isPristine = false;
    const oldValue = (<BehaviorSubject<number[]>>this.taskLabelsIds$).value;
    if (isAdding) {
      (<BehaviorSubject<number[]>>this.taskLabelsIds$).next([...oldValue, id]);
    } else {
      (<BehaviorSubject<number[]>>this.taskLabelsIds$).next(oldValue.filter(item => item !== id));
    }
  }
  onCloseLabelsPopup() {
    if (this.isBatchActions && !this.isPristine) {
      const labelIds = (<BehaviorSubject<number[]>>this.taskLabelsIds$).value;
      const ids = this.batchTasks.map(item => item.id);
      const result = { [this.removeMode ? 'remove' : 'add']: [...labelIds] };
      this._store.dispatch(new TaskBatchAssignLabelsAction({ ids, labels: result }));
    }
    this.close.emit();
  }
}
