import { take } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../../../shared/services/form-v2.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observer } from 'rxjs';
import { HandleResponseAction } from '../../../../ngrx/actions/root.action';
import { Store } from '@ngrx/store';
import { boardType, defaultExpand, TASK_PL } from '../../../../constants';
import { AppState } from '../../../../ngrx/state';
import { RoadMap, RoadMapAddFormInterface } from '../../interfaces/roadmap.interface';
import { SyncRoadmapGuiStateService } from '../../services/sync-roadmap-gui-state-service';
import { getTaskIdFromUrl } from '../../../../../helpers/';
import { RoadmapScrollService } from '../../services/roadmap-scroll.service';
import { SegmentTrackTaskCreatedAction } from '../../../../ngrx/actions/segment.actions';
import { SegmentService } from '../../../../atlaz-bnp/services/intergations/segment/segment.service';
import { RoadmapBoardClosesAddFormAction } from '../../store/roadmap-board.action';
import { RoadMapAddFormTypes } from '../../constants/roadmap.constants';
import { fromRoadmapBoard } from '../../store/roadmap-board.reducer';
import { ToastrService } from 'ngx-toastr';
import { Features } from '../../../../libs/paywall/features.constants';
import { PaywallService } from '../../../../libs/paywall/paywall.service';
import { Observable } from 'rxjs/Observable';
import { Project } from '../../../../interfaces';
import { fromProject } from '../../../../ngrx/reducers/project.reducer';
import { Subscription } from 'rxjs/index';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';

const PLACEHOLDERS = {
  [RoadMapAddFormTypes.task]: 'Enter task title',
  [RoadMapAddFormTypes.group]: 'Enter group name'
};

@Component({
  selector: 'add-task-form',
  templateUrl: './add-task-form.component.html',
  styleUrls: ['./add-task-form.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddTaskFormComponent implements OnChanges, OnInit, OnDestroy {
  @Input() params: RoadMapAddFormInterface;

  public form: FormGroup;
  public placeholderText;
  public pending$ = new BehaviorSubject(false);

  public projects$: Observable<Project[]>;
  public projectsMap$;
  private _projectsMap = {};
  subs: Subscription[] = [];

  get parent() {
    return this.params.parent;
  }
  get type() {
    return this.params.type;
  }

  get isLink() {
    return this.params.type === RoadMapAddFormTypes.taskLink;
  }

  get isProjectLink() {
    return this.params.type === RoadMapAddFormTypes.project;
  }

  formObserver: Observer<any> = {
    next: resp => {
      //scroll to date
      this.pending$.next(false);
      this._store.dispatch(new HandleResponseAction(resp));
      if (this.params.insertAfter) {
        this.onClose();
      } else {
        this._syncRoadmapGuiStateService.doWhenGUIReady(() => {
          this._scrollService.scrollTimeSheetToToday();
          scrollIntoViewIfNeeded(this._elRef.nativeElement, { duration: 300 });
        });
      }
    },
    error: originalError => {
      this.pending$.next(false);
      const error = this._formService.normalizeServerErrorResponse(originalError);
      //TODO: properly handling different error types
      this._toastr.error(error.message);
    },
    complete: () => {}
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.add,
    entityToEdit: [TASK_PL, { expand: defaultExpand[TASK_PL] }],
    formObserver: this.formObserver,
    prepareFormValue: value => {
      value = {
        ...value,
        ...{
          title: value['linkToTask'] ? String(value['linkToTask']) : value['title'],
          linkToTask: value['linkToTask']
            ? value['linkToTask']
            : value['linkToProject'] ? null : getTaskIdFromUrl(value['title'].trim())
        }
      };

      if (this.params.insertAfter) {
        delete value['inSwimlanePosition'];
        value['insertAfterTask'] = this.params.insertAfter;
      }

      return value;
    }
  };

  constructor(
    private _fb: FormBuilder,
    private _store: Store<AppState>,
    public _formService: FormV2Service,
    private _toastr: ToastrService,
    private _scrollService: RoadmapScrollService,
    private _syncRoadmapGuiStateService: SyncRoadmapGuiStateService,
    private _segment: SegmentService,
    private _paywall: PaywallService,
    private _elRef: ElementRef
  ) {}

  ngOnChanges() {
    this.placeholderText = PLACEHOLDERS[this.type];
    this._store
      .select(fromRoadmapBoard.roadmap)
      .pipe(take(1))
      .subscribe((state: RoadMap) => {
        this.form = this._fb.group(
          {
            title: [''],
            board: [state.id],
            parent: [this.parent],
            swimlane: [state.defaultSwimlaneId],
            column: [state.defaultColumnId],
            type: [this.type],
            inSwimlanePosition: ['last'],
            linkToTask: null,
            linkToProject: null
          },
          {
            validator: (formGroup: FormGroup) =>
              Validators.required(formGroup.get('linkToTask')) &&
              Validators.required(formGroup.get('linkToProject')) &&
              Validators.required(formGroup.get('title'))
          }
        );
      });
    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  ngOnInit() {
    this.projects$ = this._store.select(fromProject.getActive);
    this.projectsMap$ = this._store.select(fromProject.getState).subscribe(state => {
      this._projectsMap = state.entities;
    });
    this.subs.push(this.projectsMap$);
    if (this.type === RoadMapAddFormTypes.project) {
      this.subs.push(
        this.form.get('linkToProject').valueChanges.subscribe(value => {
          this.form
            .get('title')
            .patchValue(
              this._projectsMap[value] && this._projectsMap[value].name ? this._projectsMap[value].name : 'No Title'
            );
        })
      );
    }
  }

  onClose() {
    this._store.dispatch(new RoadmapBoardClosesAddFormAction());
  }

  onSubmit() {
    this._formService.markAsDirty();
    if (this.form.valid) {
      if (this.type === RoadMapAddFormTypes.project && !this.form.get('linkToProject').value) {
        return;
      }
      if (this.type === RoadMapAddFormTypes.group) {
        this._formService.submit();
        this._segment.track('RoadmapTaskGroupCreated');
        this.onClose();
      } else if (this.form.value.linkToTask) {
        this._formService.submit();
        this._store.dispatch(
          new SegmentTrackTaskCreatedAction({
            boardType: boardType.roadmap,
            taskType: 'link'
          })
        );
        this.onClose();
      } else if (this._paywall.isFeatureEnabled(Features.CanAddTask)) {
        this._formService.submit();
        this._store.dispatch(
          new SegmentTrackTaskCreatedAction({
            boardType: boardType.roadmap,
            taskType: 'native'
          })
        );
        this.pending$.next(true);
        this.form.get('title').patchValue('');
      } else {
        this._paywall.showPayWall(Features.CanAddTask);
        this.onClose();
      }
    }
    return false;
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
