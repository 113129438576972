import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { TimesheetContainerComponent } from './container/timesheet-container.component';
import { TimesheetExportComponent } from './export/timesheet-export.component';
import { TimesheetFilterComponent } from './filter/timesheet-filter.component';
import { TimesheetTableComponent } from './table/timesheet-table.component';
import { TimesheetService } from './timesheet.service';
import { AppContainersModule } from '../containers/containers.module';
import { AtlazGuiModule } from '../atlaz-gui/atlaz-gui.module';

@NgModule({
  imports: [CommonModule, SharedModule, AppContainersModule, AtlazGuiModule],
  declarations: [
    TimesheetContainerComponent,
    TimesheetFilterComponent,
    TimesheetExportComponent,
    TimesheetTableComponent
  ],
  exports: [
    TimesheetContainerComponent,
    TimesheetTableComponent,
    TimesheetExportComponent
  ],
  providers: [TimesheetService]
})
export class TimesheetModule {}
