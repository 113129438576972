import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ListBoardGroup } from '../../list-board-details.component';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../../../../shared/services/form-v2.service';
import { AppState } from '../../../../../ngrx/state';
import { Store } from '@ngrx/store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, Observer, of } from 'rxjs/index';
import { HandleResponseAction } from '../../../../../ngrx/actions/root.action';
import { boardType, TASK_PL } from '../../../../../constants';
import { map, switchMap } from 'rxjs/operators';
import { fromUsers, getBoardUsers } from '../../../../../ngrx/reducers/user.reducer';
import { compareArrays, createMapFromArr, naturalSort, uniqBy } from '../../../../../../helpers';
import { Project, Task, User } from '../../../../../interfaces';
import { fromBoards, getProjectsByBoard } from '../../../../../ngrx/reducers/board.reducer';
import { BoardAssignUsersAction } from '../../../../../ngrx/actions/board.actions';
import { isMoment } from 'moment-mini-ts';
import { SegmentTrackTaskCreatedAction } from '../../../../../ngrx/actions/segment.actions';
import { getTaskPossibleUsers, getTaskProjectOnlyUsers } from '../../../../../ngrx/functions/crossed.selector';

const replaceItems = initItemIds => currentItemIds => {
  let result: { add: any[]; remove: any[] } = { add: [], remove: [] };

  if (compareArrays(initItemIds, currentItemIds)) {
    return result;
  }

  result = initItemIds.reduce((acc, item) => {
    if (!currentItemIds.includes(item)) {
      acc.remove.push(item);
    }
    return acc;
  }, result);

  result = currentItemIds.reduce((acc, item) => {
    if (!initItemIds.includes(item)) {
      acc.add.push(item);
    }
    return acc;
  }, result);

  return result;
};
@Component({
  selector: 'list-board-add-task-form',
  templateUrl: './list-board-add-task-form.component.html',
  styleUrls: ['./list-board-add-task-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormV2Service]
})
export class ListBoardAddTaskFormComponent implements OnInit {
  @Input() boardId: number;
  @Input() group: ListBoardGroup;
  @Input() task: Task;
  @Input() members: User[];
  @Input() toTop: boolean;

  @Output() close = new EventEmitter();

  public form: FormGroup;
  public isPending$ = new BehaviorSubject(false);
  public isMembersPopupVisible = false;
  public isPublicBoard$: Observable<boolean>;
  public boardUsers$: Observable<User[]>;
  public taskPossibleUsers$: Observable<User[]>;
  public projectsUsers$: Observable<User[]>;
  public projectsOnlyUsers$: Observable<User[]>;
  public selectedUsers: User[] = [];
  public focusedId: string;
  public openNewFormAfterSaving: boolean;

  formObserver: Observer<any> = {
    next: resp => {
      this._store.dispatch(new HandleResponseAction(resp));
      this._store.dispatch(
        new SegmentTrackTaskCreatedAction({
          boardType: boardType.list,
          taskType: 'native',
          source: 'board'
        })
      );
      if (this.openNewFormAfterSaving) {
        this.isPending$.next(false);
        this.form.patchValue({ title: '', users: '', description: '', dueDate: '' });
        this.form.markAsPristine();
      } else {
        this.onClose();
      }
    },
    error: error => {
      this.isPending$.next(false);
      console.warn(error);
    },
    complete: () => {}
  };
  public formServiceParams: FormServiceParams;

  constructor(private _store: Store<AppState>, private _fb: FormBuilder, public _formService: FormV2Service) {}

  ngOnInit() {
    if (this.members) {
      this.selectedUsers = [...this.members];
    }
    const onCreateParams = {
      saveType: FormSaveType.add,
      entityToEdit: TASK_PL,
      formObserver: this.formObserver,
      httpQueryParams: { expand: 'tasksCustomFields.customFields' },
      prepareFormValue: formValue => {
        if (Number.isInteger(+this.group.prop)) {
          formValue['customField'] = this.group.prop;
          formValue['value'] = this.group.value;
        } else {
          formValue[this.group.prop] = this.group.value;
        }
        formValue['board'] = this.boardId;
        formValue['inSwimlanePosition'] = this.toTop ? 'first' : 'last';
        if (isMoment(formValue['dueDate'])) {
          formValue['dueDate'] = formValue['dueDate'].unix();
        } else {
          delete formValue['dueDate'];
        }
        if (Array.isArray(formValue['users']) && formValue['users'].length) {
          formValue['title'] += ' ' + formValue['users'].join(' ');
        }
        delete formValue['users'];
        return formValue;
      }
    };
    const onEditParams = {
      saveType: FormSaveType.edit,
      entityToEdit: TASK_PL,
      formObserver: this.formObserver,
      prepareFormValue: formValue => {
        const result = {
          id: formValue['id'],
          title: formValue['title'],
          description: formValue['description'] ? formValue['description'] : ''
        };
        if (Array.isArray(formValue['users'])) {
          const selection = formValue['users'].map(user => user.id);
          result['users'] = replaceItems(this.task.usersIds)(selection);
        }
        if (isMoment(formValue['dueDate'])) {
          result['dueDate'] = formValue['dueDate'].unix();
        }
        return result;
      }
    };

    this.formServiceParams = this.task ? onEditParams : onCreateParams;
    if (this.task) {
      this.form = this._fb.group({
        id: [this.task.id, Validators.required],
        title: [this.task.title, Validators.required],
        users: [''],
        description: [this.task.description],
        dueDate: [Number.isInteger(this.task.dueDate) ? this.task.dueDate * 1000 : 0]
      });
    } else {
      this.form = this._fb.group({
        title: ['', Validators.required],
        users: [''],
        description: [''],
        dueDate: ['']
      });
    }

    this.boardUsers$ = <Observable<User[]>>this._store.pipe(getBoardUsers, map(naturalSort('fullname')));

    this.isPublicBoard$ = this._store.select(fromBoards.isSelectedBoardPublic);

    if (this.task) {
      this.taskPossibleUsers$ = this._store.select(getTaskPossibleUsers(this.task.id));
      this.projectsOnlyUsers$ = this._store.select(getTaskProjectOnlyUsers(this.task.id));
    } else {
      this.projectsUsers$ = this._store.pipe(
        getProjectsByBoard(this.boardId),
        map(
          (projects: Project[]) =>
            projects && projects.length === 1
              ? projects.reduce((acc, item) => [...acc, ...(item && item.usersIds ? item.usersIds : [])], [])
              : []
        ),
        switchMap(ids => this._store.select(fromUsers.getByIds(ids))),
        map(naturalSort('fullname'))
      );
      this.projectsOnlyUsers$ = this.isPublicBoard$.pipe(
        switchMap(
          isPublic =>
            isPublic
              ? of([])
              : combineLatest(this.projectsUsers$, this.boardUsers$).pipe(
                  map(([pu, bu]) => {
                    const puMap = createMapFromArr(pu);
                    const puIds = pu.map(user => user.id);
                    const buIds = bu.map(user => user.id);
                    const diff = puIds.filter(projUserId => !buIds.includes(projUserId));
                    return diff.map(id => puMap[id]);
                  })
                )
        )
      );

      const mergedUsers$ = combineLatest(this.projectsUsers$, this.boardUsers$).pipe(
        map(([pu, bu]) => <User[]>uniqBy([...pu, ...bu], 'id'))
      );

      this.taskPossibleUsers$ = this.isPublicBoard$.pipe(
        switchMap(isPublic => (isPublic ? mergedUsers$ : this.boardUsers$))
      );
    }

    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  onUpdateUserSelection(userListUpdate) {
    this.selectedUsers = [...userListUpdate.value];
    if (!this.task) {
      this.form.get('users').setValue(this.selectedUsers.map(user => '@' + user.nickname));
    } else {
      this.form.get('users').setValue(this.selectedUsers);
    }
  }

  onAddUserToBoard(user: User) {
    this._store.dispatch(new BoardAssignUsersAction({ id: this.boardId, users: { add: [user.id] } }));
  }

  onSubmit() {
    if (this.form.get('title')) {
      this.form.get('title').patchValue(this.form.get('title').value.trim());
    }
    this._formService.markAsDirty();
    if (this.form.invalid) {
      return;
    }
    this.isPending$.next(true);
    this._formService.submit();
  }

  onClose() {
    this.close.emit();
  }

  onFocus(event) {
    this.focusedId = event.target.id;
  }

  onBlur() {
    this.focusedId = '';
  }

  onEnter(event: KeyboardEvent) {
    if (event.shiftKey) {
      return;
    } else {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
    if (!this.task) {
      this.openNewFormAfterSaving = true;
    }
    this.onSubmit();
  }
}
