import { map } from 'rxjs/operators';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'a-searchable-select',
  templateUrl: './a-searchable-select.component.html',
  styleUrls: ['./a-searchable-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASearchableSelectComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ASearchableSelectComponent implements OnInit, ControlValueAccessor, AfterViewInit, OnDestroy {
  /**
   * @example
   * <a-searchable-select
   *  formControlName="department"
   *  [minLengthToStart]="3"
   *  [labelId]="'department'"
   *  [inputValues]="departments"
   *  [matchProp]="'description'"
   * >
   * </a-searchable-select>
   */

  @Input()
  set inputValues(v) {
    this.inputValues$.next(v || []);
  }
  @Input() matchProp: string;
  @Input() labelId: string = null;
  @Input() minLengthToStart = 0;
  @Input() placeholder = '';
  @Input() openAfterInit = false;
  @Input() adaptiveDirection = false;
  @Input() adaptiveDirectionPadding = 0;
  @ViewChild('readOnlyInput') readOnlyInputEl: ElementRef;
  @ViewChild('inputTag') inputEl: ElementRef;
  @ViewChild('possibleValues') ulEl: ElementRef;

  public inputValues$ = new BehaviorSubject([]);
  public possibleValues$: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  public keyUp$: Subject<KeyboardEvent | { target: { value } }> = new Subject();
  public possibleValuesFullList: Array<any>;
  public subscriptions: Subscription[] = [];
  public previousSelectedText: string;
  public visibleValue: string;
  public lastUsedValue;
  public isShowPossibleValues$ = new BehaviorSubject(false);
  public isReversePopUp$ = new BehaviorSubject(false);

  public selectedItemIndex = -1;

  public data: any;
  private propagateChange = (_: any) => {};
  private propagateTouched = (_: any) => {};

  public writeValue(obj: any) {
    this.data = obj;
  }

  public registerOnTouched(fn: any) {
    this.propagateTouched = fn;
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  constructor(private eRef: ElementRef) {}

  ngOnInit() {
    const inputValuesSub = this.inputValues$.subscribe(inputValues => {
      this.possibleValuesFullList = inputValues;
    });
    this.subscriptions.push(inputValuesSub);
    const keyboardSub = this.keyUp$
      .pipe(
        map((event: any) => event.target.value),
        map(
          (value: string) =>
            value.length >= this.minLengthToStart
              ? this.possibleValuesFullList.filter(
                  item => item[this.matchProp].toUpperCase().indexOf(value.toUpperCase()) > -1
                )
              : []
        )
      )
      .subscribe(values => {
        this.selectedItemIndex = -1;
        this.possibleValues$.next(values);
      });
    this.subscriptions.push(keyboardSub);
  }

  ngAfterViewInit() {
    if (this.data) {
      const value = this.possibleValuesFullList.find(item => item.id === this.data);
      if (value === undefined) {
        this.propagateChange(value);
      } else {
        this.visibleValue = this.possibleValuesFullList.find(item => item.id === this.data)[this.matchProp];
      }
    }
    if (this.openAfterInit) {
      setTimeout(() => {
        this.keyUp$.next({ target: { value: '' } });
        this.isShowPossibleValues$.next(true);
        setTimeout(() => {
          this.inputEl.nativeElement.focus();
        });
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  onChange(value) {
    this.visibleValue = this.previousSelectedText = value ? value[this.matchProp] : '';
    this.lastUsedValue = value;
    this.propagateChange(value ? value.id : value);
  }

  onMouseEnter(event) {
    this.selectedItemIndex = +event.target.dataset.index;
    this.selectValueByIndex(this.selectedItemIndex, true);
  }

  onKeyUp(event) {
    switch (event.key) {
      case 'ArrowRight':
      case 'ArrowLeft':
      case 'ArrowDown':
      case 'ArrowUp':
      case 'Shift':
      case 'Enter':
      case 'Tab':
        break;
      case 'Escape': // Clear and close popup, clear value
        this.onChange(null);
        this.inputEl.nativeElement.value = '';
        this.possibleValues$.next([]);
        this.selectedItemIndex = -1;
        break;
      default:
        // Update list of possible values, remove selection in list
        this.keyUp$.next(event);
        this.selectedItemIndex = -1;
    }
  }

  onKeyDown(event) {
    const maxIndex = this.possibleValues$.getValue().length - 1;
    if (maxIndex > -1) {
      switch (event.key) {
        case 'Enter': // Clear and close popup
          this.onClosePopup(event);
          break;
        case 'ArrowDown': // Select value higher in the list
          if (this.selectedItemIndex < maxIndex) {
            this.selectedItemIndex++;
            this.arrowHandlerHelper();
          }
          break;
        case 'ArrowUp': // Select value below in the list
          if (this.selectedItemIndex > 0) {
            this.selectedItemIndex--;
            this.arrowHandlerHelper(true);
          }
          break;
        case 'Tab':
          if (!event.shiftKey) {
            this.onClosePopup(event, true);
          }
          break;
        case 'Escape':
          this.readOnlyInputEl.nativeElement.focus();
          event.preventDefault();
          event.stopImmediatePropagation();
          break;
        default:
          break;
      }
    }
  }

  onTogglePopup(event) {
    if (this.readOnlyInputEl.nativeElement === event.target && this.isShowPossibleValues$.value === true) {
      this.onClosePopup(event, true);
    } else if (this.eRef.nativeElement.contains(event.target)) {
      this.keyUp$.next({ target: { value: '' } });
      this.isShowPossibleValues$.next(true);
      setTimeout(() => {
        this.inputEl.nativeElement.focus();
        if (!this.adaptiveDirection) {
          return;
        }
        const rect = this.inputEl.nativeElement && this.inputEl.nativeElement.parentElement.getBoundingClientRect();
        if (rect.bottom > document.documentElement.clientHeight + this.adaptiveDirectionPadding) {
          this.isReversePopUp$.next(true);
        } else {
          this.isReversePopUp$.next(false);
        }
      });
    } else {
      this.onClosePopup(event, true);
    }
  }

  onClosePopup(event, isDispatchAvailable?) {
    if (!isDispatchAvailable) {
      event.stopImmediatePropagation();
      event.preventDefault();
    }
    this.possibleValues$.next([]);
    this.selectedItemIndex = -1;
    this.isShowPossibleValues$.next(false);
    if (this.lastUsedValue) {
      this.propagateChange(this.lastUsedValue.id);
    }
    this.isReversePopUp$.next(false);
  }

  selectValueByIndex(index: number, isNotChangeFilterInput?) {
    const newValue = this.possibleValues$.getValue()[index];
    this.onChange(newValue);
    if (!isNotChangeFilterInput) {
      this.inputEl.nativeElement.value = newValue[this.matchProp];
    }
  }

  arrowHandlerHelper(isUp?: boolean) {
    this.selectValueByIndex(this.selectedItemIndex, true);
    const ul = this.ulEl.nativeElement;
    const li = this.ulEl.nativeElement.children[this.selectedItemIndex];
    if (isUp) {
      if (ul.scrollTop > li.offsetTop) {
        ul.scrollTop -= li.offsetHeight;
      }
    } else {
      if (ul.scrollTop + ul.clientHeight < li.offsetTop + li.offsetHeight) {
        ul.scrollTop += li.offsetHeight;
      }
    }
  }

  onReadOnlyInputKeyDown(event) {
    switch (event.key) {
      case 'Enter': // Clear and close popup
        this.onTogglePopup(event);
        break;
      case 'Tab':
        if (event.shiftKey) {
          this.onClosePopup(event, true);
        }
        break;
      default:
        break;
    }
  }
}
