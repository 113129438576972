import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { AppState, ESInterface } from '../../../ngrx/state/app-state';
import { getEntitySelector } from '../../../ngrx/functions/selectors';
import { BOARD_PL } from '../../../constants/entity';
import { Board } from '../../../interfaces/board';

@Component({
  selector: 'board-list-independent',
  templateUrl: './board-list-independent.component.html',
  styleUrls: ['./board-list.component.scss']
})
export class BoardListIndependentComponent implements OnInit, OnDestroy {
  @Input() selectedBoards: BehaviorSubject<number[]>;

  public boardsState: ESInterface<Board>;
  public subscription;

  constructor(private _store: Store<AppState>) {}

  get selectedBoards$() {
    return this.selectedBoards;
  }

  ngOnInit(): any {
    this.subscription = this._store.pipe((getEntitySelector(BOARD_PL))).subscribe((state: ESInterface<Board>) => {
      this.boardsState = state;
    });
  }

  ngOnDestroy(): any {
    this.subscription.unsubscribe();
  }

  isSelectedBoard(boardId) {
    return this.selectedBoards$.getValue().includes(boardId);
  }

  onSelectBoard(boardId) {
    let currentValue = this.selectedBoards$.getValue();
    if (currentValue.includes(boardId)) {
      this.selectedBoards$.next(currentValue.filter(existsBoard => existsBoard !== boardId));
    } else {
      currentValue = [...currentValue];
      currentValue.push(boardId);
      this.selectedBoards$.next(currentValue);
    }
  }
}
