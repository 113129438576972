<div class="default_columns_filter"
     (click)="onShowColumnsPopup()"
     [ngClass]="{'active': showColumnsPopUp}"
>
  Columns
</div>

<div *ngFor="let column of selectedColumns$ | async"
     class="column_filter_tag"
     (click)="onRemoveColumn(column)"
>
  <p class="column_title">
    {{column.name}}
  </p>
  <div class="clear_block"></div>
</div>

<div a-context-popup *ngIf="showColumnsPopUp"
     [title]="'Columns'"
     class="default_columns_list_popup"
     (close)="onHideColumnsPopup()"
>
  <columns-filter-list
    [columns]="boardColumns$ | async"
    [selectedColumns]="selectedColumns$ | async"
    (addColumn)="onAddColumn($event)"
    (removeColumn)="onRemoveColumn($event)"
  ></columns-filter-list>
</div>
