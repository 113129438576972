import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { SearchInLeftMenuComponent } from './search-in-left-menu/search-in-left-menu.component';
import { SearchInLeftMenuService } from '../shared/services/app/search-in-left-menu.service';
import { LeftDefaultCollectionItemComponent } from './left-default-collection-item/left-default-collection-item.component';
import { LeftCollectionItemComponent } from './left-collection-item/left-collection-item.component';
import { LeftBoardListComponent } from './left-board-list/left-board-list.component';
import { UnassignedBoardsListComponent } from './unassigned-boards-list/unassigned-boards-list.component';
import { LeftProjectItemComponent } from './left-project-item/left-project-item.component';
import { LeftMenuHeaderComponent } from './left-menu-header/left-menu-header.component';
import { LeftMenuBodyComponent } from './left-menu-body/left-menu-body.component';
import { LeftMenuFoterComponent } from './left-menu-footer/left-menu-footer.component';
import { OpenedStateLeftMenuItemsService } from './shared/services/opened-state-left-menu-items.service';
import { LeftFavoriteStarComponent } from './left-favorite-star/left-favorite-star.component';
import { AtlazGuiModule } from '../atlaz-gui/atlaz-gui.module';
import { LeftMenuContextMenuComponent } from './left-menu-context-menu/left-menu-context-menu.component';

const sharedComponents = [
  LeftMenuComponent,
  SearchInLeftMenuComponent,
  LeftDefaultCollectionItemComponent,
  LeftCollectionItemComponent,
  LeftBoardListComponent,
  UnassignedBoardsListComponent,
  LeftProjectItemComponent,
  LeftMenuHeaderComponent,
  LeftMenuBodyComponent,
  LeftMenuFoterComponent,
  LeftFavoriteStarComponent
];

@NgModule({
  imports: [CommonModule, SharedModule, AtlazGuiModule],
  declarations: [...sharedComponents, LeftMenuContextMenuComponent],
  providers: [SearchInLeftMenuService, OpenedStateLeftMenuItemsService],
  exports: [...sharedComponents]
})
export class LeftMenuModule {}
