
import {filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppState } from '../../../ngrx/state';
import { Store } from '@ngrx/store';
import { fromTask } from '../../../ngrx/reducers/task.reducer';
import { Subscription } from 'rxjs';
import { isNotPresent } from '../../../../helpers';
import { OpenedTaskActionTypes, OpenedTaskCloseAction } from '../../ngrx/actions/opened-task.action';
import { Actions } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class TaskDeletingWatcherService {
  private taskId;

  private watcher: Subscription;

  constructor(private _store: Store<AppState>, private _actions$: Actions, private _toastr: ToastrService) {
    this._actions$.ofType(OpenedTaskActionTypes.CLOSE).subscribe(() => this.stopWatching());
  }

  startWatching(taskId) {
    if (this.watcher) {
      this.stopWatching();
    }
    this.taskId = taskId;
    this.watcher = this._store
      .select(fromTask.taskById(taskId)).pipe(
      filter(isNotPresent))
      .subscribe(() => this.fireDeleted());
  }

  stopWatching() {
    this.taskId = undefined;
    if (this.watcher) {
      this.watcher.unsubscribe();
    }
  }

  fireDeleted() {
    this._toastr.success('Task has been deleted.');
    this._store.dispatch(new OpenedTaskCloseAction());
  }
}
