import { labelAction } from '../../ngrx/functions/action';
import { PermissionModel } from '../interfaces/permission.model';
import { Action } from '../../ngrx/actions/unsafe-action';

export const PERMISSIONS = 'permissions';

export const PermissionActionTypes = {
  INIT: labelAction(PERMISSIONS, 'INIT'),
  GET_COMPLETE: labelAction(PERMISSIONS, 'GET_COMPLETE')
};

export class PermissionInitAction implements Action {
  type = PermissionActionTypes.INIT;

  constructor(public payload?: any) {}
}

export class PermissionGetCompleteAction implements Action {
  type = PermissionActionTypes.GET_COMPLETE;

  constructor(public payload: PermissionModel) {}
}
