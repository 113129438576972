import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { fromGuiState } from '../../../ngrx/reducers/gui-state-memorized.reducer';
import { of } from 'rxjs/internal/observable/of';
import { Observable } from 'rxjs/Observable';
import { Task } from '../../../interfaces';
import { fromTask } from '../../../ngrx/reducers/task.reducer';
import { switchMap } from 'rxjs/operators';
import getBatchEditParams = fromGuiState.getBatchEditParams;

@Component({
  selector: 'batch-actions',
  templateUrl: './batch-actions.component.html',
  styleUrls: ['./batch-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BatchActionsComponent implements OnInit {
  @Input() isNotGuest: boolean;
  @Input() boardId$: Observable<number>;
  public params$;
  public tasks$: Observable<Task[]>;
  public ofFalse$ = of(false);

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this.params$ = this._store.select(getBatchEditParams);
    this.tasks$ = this.params$.pipe(
      switchMap((params: { ids: number[]; top: number; left: number }) =>
        this._store.select(fromTask.getByIds(params ? params.ids : []))
      )
    );
  }
}
