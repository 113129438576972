<form popup-body
      [formGroup]="columnForm"
      (ngSubmit)="onSubmit()"
>
  <general-error-message *ngIf="(formStatus$ | async) == formStatuses.error">
    Some thing went wrong while saving your changes.
  </general-error-message>

  <div class="field_name">
    <label class="first" for="column_name">Name</label>
    <input
      id="column_name"
      formControlName="name"
      [focus]="true"
      placeholder="Enter column name"
      [selectText]="true"
      checkTouch
    >
    <help-block
      class="warning"
      [control]="columnForm.get('name')"
      [messages]="{'required': '*Name is required'}"
    >
    </help-block>
  </div>

  <div class="field_name">
    <label [attr.for]="'column_type'">Type</label>
    <select class="form-control"
            formControlName="type"
            id="column_type">
      <option [value]="columnTypes.todo">To Do</option>
      <option [value]="columnTypes.inprogress">In Progress</option>
      <option [value]="columnTypes.done" *ngIf="(isCanAddDoneColumn$ | async) || column?.type == columnTypes.done">Done
      </option>
    </select>
  </div>

  <div [hidden]="columnForm.get('type').value != columnTypes.inprogress">
    <label for="column_kind">Kind</label>
    <select formControlName="kind" id="column_kind" #column_kind>
      <option [value]="columnKinds.simple">Simple</option>
      <option [value]="columnKinds.composite">Composite</option>
    </select>
  </div>

  <div a-button
    class="btn_primary"
    [disabled]="!columnForm.valid"
    [pending]="(formStatus$ | async) === formStatuses.pending"
    (click)="onSubmit()"
  >
    {{!isNewColumn ? 'Save Changes' : 'Add Column'}}
  </div>

	<div class="help_text_block">
		<p class="help_text" *ngIf="isColumnSelectedTypeToDo"><span>To Do</span> column allows to create task queues.</p>
		<p class="help_text" *ngIf="isColumnSelectedTypeInProgress"><span>In Progress</span> column is necessary to track tasks in work.</p>
		<p class="help_text" *ngIf="isColumnSelectedTypeDone"><span>Done</span> column is necessary to view completed tasks.</p>
		<p class="help_text" *ngIf="isColumnSelectedKindComposite"><span>Composite</span> column consists of 2 sub-columns: In Progress and Done to reflect the status of each task better.</p>
	</div>

</form>
