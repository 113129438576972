import { debounceTime } from 'rxjs/operators';
import { Component, ChangeDetectionStrategy, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchInputComponent implements OnInit, OnDestroy {
  @Output() closeSearch = new EventEmitter();
  @Output() chageSearch = new EventEmitter();
  @Input() closeButton = true;
  @Input() searchIcon = true;
  @Input() placeholder = 'Search phrase...';

  public stringSearch$: BehaviorSubject<string>;
  private stringSearchSubsr: Subscription = null;

  constructor() {}

  ngOnInit() {
    this.stringSearch$ = new BehaviorSubject('');
    this.stringSearchSubsr = this.stringSearch$.pipe(debounceTime(400)).subscribe(value => {
      this.chageSearch.emit(value);
    });
  }

  onWrite(string) {
    this.stringSearch$.next(string.trim());
  }

  onCloseSearch(event) {
    event.stopPropagation();
    event.preventDefault();
    this.stringSearch$.next('');
    this.closeSearch.emit();
  }

  ngOnDestroy() {
    if (this.stringSearchSubsr) {
      this.stringSearchSubsr.unsubscribe();
    }
  }
}
