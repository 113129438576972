import { distinctUntilChanged, filter, map, pluck, take } from 'rxjs/operators';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, COMPONENT, EntityState, RIGHT_MENU } from '../../ngrx/state';
import { Board, User } from '../../interfaces';
import { ADragService, DragItem } from '../../shared/a-drag/a-drag.service';
import { BoardFirstOpenType, boardType, COLUMN_PL, ScoringType, SWIMLANE_PL, TASK_PL } from '../../constants';
import { PatchEntityPosition } from '../../ngrx/actions/root.action';
import { both, sortBy } from '../../../helpers';
import { inColumn, inSwimlane } from '../../ngrx/reducers/task.reducer';
import { RouterNavigateService } from '../../shared/services/router-navigate.service';
import { PermissionsService } from '../../permissions/permissions.service';
import { BoardEditAction } from '../../ngrx/actions/board.actions';
import {
  GuiStateOpenBoardTypeSelectorAction,
  GuiStateSetBoardViewMode
} from '../../ngrx/actions/gui-state-memorized.actions';
import { BoardViewMode } from '../../ngrx/reducers/gui-state-memorized.reducer';
import { Observable } from 'rxjs/index';
import { AuthService } from '../../shared/services/app/auth.service';

@Component({
  selector: 'board-detail',
  templateUrl: './board-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoardDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() board: Board;

  public boardType = boardType;
  public isVisibleRightMenu$;
  public sub;
  public activeUser$: Observable<User> = this._authService.activeUser$;
  private _loadCounter: number;
  public isNotGuest$ = this._permissions.isNotGuest$;

  constructor(
    private _store: Store<AppState>,
    private _dragService: ADragService,
    private _routerNav: RouterNavigateService,
    private _authService: AuthService,
    private _permissions: PermissionsService
  ) {}

  ngOnInit() {
    this._loadCounter = Date.now();
    this.sub = this._dragService.finallyMoved$.subscribe((v: DragItem) => this.handleDrop(v));
    this.isVisibleRightMenu$ = this._store.pipe(pluck(COMPONENT, RIGHT_MENU, 'visible'), distinctUntilChanged());
  }

  ngAfterViewInit() {
    this._loadCounter = Date.now() - this._loadCounter;
    console.log(this._loadCounter);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.board &&
      (!changes.board.previousValue || changes.board.currentValue.id !== changes.board.previousValue.id)
    ) {
      const board = changes.board.currentValue;
      if (!board.showPopup) {
        switch (board.boardTemplateViewType) {
          case BoardFirstOpenType.unset: {
            this._store.dispatch(new BoardEditAction({ id: board.id, showPopup: 1 }));
            break;
          }
          case BoardFirstOpenType.chooser: {
            this._routerNav.navigateToScoringTypeChooser().then(() => {
              this._store.dispatch(new BoardEditAction({ id: board.id, showPopup: 1 }));
            });
            break;
          }
          case BoardFirstOpenType.scoring: {
            this._store.dispatch(new BoardEditAction({ id: board.id, showPopup: 1 }));
            if (board.scoringType === ScoringType.basic) {
              this._store.dispatch(new GuiStateSetBoardViewMode({ id: board.id, mode: BoardViewMode.priorityChart }));
              this.activeUser$.pipe(take(1)).subscribe(user => {
                if (user.createdAt - Date.now() / 1000 < 86400 && !this.board.isPreset) {
                  this._store.dispatch(new GuiStateOpenBoardTypeSelectorAction(true));
                }
                this._routerNav.navigateToPriorityChart(board.id);
              });
            } else if (board.scoringType !== ScoringType.off) {
              this._store.dispatch(new GuiStateSetBoardViewMode({ id: board.id, mode: BoardViewMode.table }));
              setTimeout(() => this._routerNav.navigateToBoard(board.id));
            }
            break;
          }
        }
      }
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  handleDrop(value: DragItem) {
    console.warn('dispatch drop', value);
    switch (value.type) {
      case TASK_PL: {
        const closestItemId = value.position.before || value.position.after;
        // to drop tasks at last position in column if they droped on overlay
        if (!closestItemId) {
          value = { ...value };
          value.position = { ...value.position };
          this._store
            .select(state =>
              EntityState.from(state.tasks)
                .filter(both(inColumn(value.metaData['column']), inSwimlane(value.metaData['swimlane'])))
                .toArray()
                .sort(sortBy('position', 'desc'))
            )
            .pipe(take(1), filter(xs => xs.length > 0), map(xs => xs[0]))
            .subscribe(x => (value.position.after = x.id));
        }
        this._store.dispatch(new PatchEntityPosition({ value, entityPl: TASK_PL, insertSuffix: 'Task' }));
        break;
      }
      case COLUMN_PL: {
        this._store.dispatch(new PatchEntityPosition({ value, entityPl: COLUMN_PL, insertSuffix: 'Column' }));
        break;
      }
      case SWIMLANE_PL: {
        this._store.dispatch(new PatchEntityPosition({ value, entityPl: SWIMLANE_PL, insertSuffix: 'Swimlane' }));
        break;
      }
      default:
      // there's nothing to do
    }
  }
}
