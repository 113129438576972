import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  pluck,
  publishReplay,
  refCount,
  switchMap,
  take
} from 'rxjs/operators';
import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Checklist, ChecklistItem, PartOfEntity, Task } from '../../../interfaces';

import { AppState } from '../../../ngrx/state';
import { Store } from '@ngrx/store';

import {
  ChecklistItemAddAction,
  ChecklistItemDeleteAction,
  ChecklistItemEditAction
} from '../../../ngrx/actions/checklist-item.actions';

import { ChecklistDeleteAction, ChecklistEditAction } from '../../../ngrx/actions/checklist.actions';
import { GuiStateToggleCheckListHideCompleted } from '../../../ngrx/actions/gui-state-memorized.actions';
import { getChecklistHideCompletedState } from '../../../ngrx/reducers/gui-state-memorized.reducer';
import { getEntitiesByFields } from '../../../ngrx/functions/selectors';
import { AppPages, CHECKLIST_PL, CHECKLISTS_ITEM_PL } from '../../../constants';
import { ADragService } from '../../../shared/a-drag/a-drag.service';
import { isPresent, sortByField } from '../../../../helpers';
import { smartTaskLinkPattern } from '../../../shared/smart-text/smart-text.component';
import { getTaskBySlug } from '../../../ngrx/reducers/task.reducer';
import { combineLatest as observableCombineLatest } from 'rxjs/internal/observable/combineLatest';
import { of } from 'rxjs/internal/observable/of';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Component({
  selector: 'checklist-detail',
  templateUrl: './checklist-detail.component.html',
  styleUrls: ['./checklist-detail.component.scss']
})
export class ChecklistDetailComponent implements OnDestroy, OnInit, OnChanges {
  @Input() checklist: Checklist;
  @Input() editPermissions = false;
  @Input() openDirectly;

  public addItemForm = false;
  public addItemTaskForm = false;
  public isDeleteConfirm = false;
  public itemsCount$: Observable<number>;
  public displayChecklistName$: Observable<string>;
  public itemsCompletedCount$: Observable<number>;
  public hideCompleted$: Observable<boolean>;

  public subscriptions: Subscription[] = [];

  public dragItemType = CHECKLISTS_ITEM_PL;
  public dragCheckListType = CHECKLIST_PL;

  public draggingItemId$: Observable<number>;
  public draggingCheckListId$: Observable<number>;
  public visibleItems$: Observable<ChecklistItem[]>;
  public checklistName$ = new BehaviorSubject(this.checklist ? this.checklist.name : '');

  constructor(private _store: Store<AppState>, private _dragService: ADragService, private _elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['checklist'] && changes['checklist'].currentValue) {
      this.checklistName$.next(changes['checklist'].currentValue.name);
    }
  }

  ngOnInit(): any {
    this._store
      .pipe(pluck(AppPages.Task, 'newCreatedCheckList'), take(1), filter(isPresent))
      .subscribe(lastCreatedChecklist => (this.addItemForm = lastCreatedChecklist.id === this.checklist.id));

    this.hideCompleted$ = this._store.pipe(
      getChecklistHideCompletedState,
      map(state => !!state[this.checklist.id]),
      publishReplay(1),
      refCount()
    );

    const taskChecklistItems$ = this._store.pipe(
      getEntitiesByFields(CHECKLISTS_ITEM_PL, { checklist: this.checklist.id }),
      map(sortByField('position')),
      distinctUntilChanged(),
      publishReplay(1),
      refCount()
    );

    this.itemsCount$ = taskChecklistItems$.pipe(map(items => items.length));
    this.itemsCompletedCount$ = taskChecklistItems$.pipe(map(items => items.filter(item => !!item.completed).length));
    const visibleItems$ = taskChecklistItems$.pipe(
      combineLatest(
        this.hideCompleted$,
        (list: ChecklistItem[], hide: boolean) => (hide ? list.filter(item => !item.completed) : list)
      )
    );

    this.displayChecklistName$ = taskChecklistItems$.pipe(
      map(items =>
        items
          .map((item: ChecklistItem) => {
            const smartTaskLinkRegExp = new RegExp(smartTaskLinkPattern, 'g');
            const result = smartTaskLinkRegExp.exec(item.name);
            return result ? result[4] || result[5] : result;
          })
          .filter(isPresent)
          .map(match => this._store.pipe(getTaskBySlug(match)))
      ),
      switchMap(
        taskObservables =>
          taskObservables && taskObservables.length ? observableCombineLatest(taskObservables) : of([])
      ),
      map(tasks => {
        const counter = tasks.reduce(
          (acc, item: Task) => {
            if (item) {
              acc['estimate'] += item.estimate;
              acc['loggedTime'] += item.loggedTime;
            }
            return acc;
          },
          { estimate: 0, loggedTime: 0 }
        );
        if (!counter['estimate'] && !counter['loggedTime']) {
          return '';
        }
        return (
          ' (' +
          (counter['estimate'] ? Math.round(counter['estimate'] / 360) / 10 + 'h' : '-') +
          '/' +
          Math.round(counter['loggedTime'] / 360) / 10 +
          'h)'
        );
      }),
      switchMap(estimateStr => this.checklistName$.pipe(map(name => name + estimateStr)))
    );

    this.draggingItemId$ = this._dragService.getDraggingItemId(this.dragItemType);
    this.draggingCheckListId$ = this._dragService.getDraggingItemId(this.dragCheckListType);
    this.visibleItems$ = this._dragService
      .filterItems(visibleItems$, this.dragItemType, meta => meta.checklist === this.checklist.id)
      .pipe(publishReplay(1), refCount());

    const sub = this.visibleItems$
      .pipe(map(list => list.length === 0), combineLatest(this.draggingItemId$))
      .subscribe(([noVisibleItems, isItemDragging]) => {
        if (isItemDragging && noVisibleItems) {
          if (!this._dragService.getDropContainersMap(this.dragItemType).get(this._elementRef.nativeElement)) {
            this._dragService.getDropContainersMap(this.dragItemType).set(this._elementRef.nativeElement, {
              itemType: this.dragItemType,
              metaData: {
                checklist: this.checklist.id
              }
            });
          }
        } else {
          this._dragService.getDropContainersMap(this.dragItemType).delete(this._elementRef.nativeElement);
        }
      });
    this.subscriptions.push(sub);
  }

  ngOnDestroy() {
    this._dragService.getDropContainersMap(this.dragItemType).delete(this._elementRef.nativeElement);
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  onAddCheckbox(checkboxData: ChecklistItem) {
    const newCheckboxData = {
      checklist: this.checklist.id,
      name: checkboxData.name,
      company: this.checklist.company,
      task: this.checklist.task,
      completed: 0
    };
    this._store.dispatch(new ChecklistItemAddAction(newCheckboxData));
  }
  onEditCheckbox(checkboxData: PartOfEntity) {
    this._store.dispatch(new ChecklistItemEditAction(checkboxData));
  }

  onDeleteCheckbox(checkbox: ChecklistItem) {
    this._store.dispatch(new ChecklistItemDeleteAction(checkbox));
  }

  onHideCompleted() {
    this._store.dispatch(new GuiStateToggleCheckListHideCompleted(this.checklist.id));
  }

  onDeleteChecklist() {
    this._store.dispatch(new ChecklistDeleteAction(this.checklist));
    this.isDeleteConfirm = false;
  }

  onChangeChecklistTitle(checklistTitle: string) {
    const checklistData = {
      id: this.checklist.id,
      name: checklistTitle
    };

    this._store.dispatch(new ChecklistEditAction(checklistData));
  }

  onToggleDeleteConfirm() {
    this.isDeleteConfirm = !this.isDeleteConfirm;
  }

  onCancel() {
    this.addItemForm = false;
  }

  onCancelTask() {
    this.addItemTaskForm = false;
  }
}
