import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx/src/nx.module';
import { of } from 'rxjs';
import { map ,  switchMap } from 'rxjs/operators';

import { fromCamelToDash } from '../../../../../helpers/';
import { BITBUCKET_COMPANY_PL } from '../../../../constants/';
import { GetCompleteAction } from '../../../../ngrx/actions/root.action';
import { AppState } from '../../../../ngrx/state';
import { jsonApiToEntityState } from '../../../../shared/services/app/web-socket/http-response';
import { AtlazApiV2Service } from '../../../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import * as integrations from '../../../core/store/integration.action';
import * as integration from '../../../core/store/integration.action';
import { removeByIdsFn } from '../../../settings/slack-alert/util/uninstall';
import { ESBitbucketCompany } from '../../models/git-company';
import * as bitbucketCompany from './bitbucket-company.actions';
import * as fromBitbucketCompany from './bitbucket-company.reducer';
import { IntegrationNames } from '../../../core/integraition-list';

@Injectable()
export class BitbucketCompanyEffects {
  private key = fromCamelToDash(BITBUCKET_COMPANY_PL);

  defaultErrorHandler(action, error) {
    console.error('effect', action, error);
  }

  @Effect()
  getMany$ = this.data.fetch(bitbucketCompany.GET_INDEX, {
    run: (action, store) =>
      this.api.get([this.key]).pipe(
        map(jsonApiToEntityState),
        switchMap((state: ESBitbucketCompany) => {
          let id;
          const actions: any[] = [
            new GetCompleteAction(state),
            new bitbucketCompany.GetIndexComplete(state[BITBUCKET_COMPANY_PL])
          ];
          if ((id = this.getFirstId(state[BITBUCKET_COMPANY_PL]))) {
            actions.push(
              new integrations.IntegrationStatusUpdated({ name: IntegrationNames.Bitbucket, isInstalled: true })
            );
            actions.push(new bitbucketCompany.SetActive(id));
          }

          return of(...actions);
        })
      ),
    onError: this.defaultErrorHandler
  });

  @Effect()
  removeAll$ = this.data.pessimisticUpdate(bitbucketCompany.REMOVE_ALL, {
    run: (action, store) => {
      const ids = fromBitbucketCompany.getIds(store);
      return this.remover(ids).pipe(
        switchMap(
          _ =>
            <any>of(new bitbucketCompany.RemoveAllComplete(undefined), <any>new integration.IntegrationStatusUpdated({
              name: IntegrationNames.Bitbucket,
              isInstalled: false
            }))
        )
      );
    },
    onError: (action, error) => {
      this.defaultErrorHandler(action, error);
    }
  });

  constructor(private data: DataPersistence<AppState>, private api: AtlazApiV2Service) {}

  private remover = removeByIdsFn(this.api, this.key);

  private getFirstId(state: ESBitbucketCompany) {
    return state && state.ids[0];
  }
}
