import { of as observableOf, BehaviorSubject, Observable, Subscription } from 'rxjs';

import { map, filter, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/state';
import { dataSyncState } from '../../ngrx/reducers/data-sync.reducer';
import { HttpRequestErrorsService } from './http-request-errors.service';
import { isPresent } from '../../../helpers';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'http-request-errors',
  templateUrl: './http-request-errors.component.html',
  styleUrls: ['./http-request-errors.component.scss'],
  animations: [
    trigger('showError', [
      state(
        'hide',
        style({
          opacity: 0
        })
      ),
      state(
        'show',
        style({
          opacity: 1
        })
      ),
      transition('hide => show', animate('1s ease-in')),
      transition('show => hide', animate('1s ease-out'))
    ])
  ]
})
export class HttpRequestErrorsComponent implements OnInit, OnDestroy {
  private dataSync$;
  private subs: Subscription[] = [];
  public isOnline$ = observableOf(true);
  public isSocketConnection$ = observableOf(true);
  public isConnectionProblems$;
  public message$;
  public lastErrorMessage$ = new BehaviorSubject('');
  public animationTimeout;
  public animationState = 'hide';
  constructor(
    private _store: Store<AppState>,
    private _errors: HttpRequestErrorsService,
    private _cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.dataSync$ = this._store.pipe(dataSyncState);
    this.isOnline$ = this.dataSync$.pipe(map(dataSync => dataSync['isOnline']));
    this.isSocketConnection$ = this.dataSync$.pipe(map(dataSync => dataSync['isSocketConnected']));
    this.isConnectionProblems$ = this.dataSync$.pipe(
      map(dataSync => !dataSync['isSocketConnected'] || !dataSync['isOnline'])
    );
    this.message$ = this._errors.lastError$.pipe(
      filter(isPresent),
      map(error => {
        if (error.status === 0) {
          return (
            'There is a problem with your network connection. The changes you have just made may not be saved. ' +
            (error.statusText || '')
          );
        } else {
          return '';
        }
      })
    );
    this.subs.push(this._errors.clearErrors$.pipe(filter(isPresent)).subscribe(_ => this.clearError()));

    this.subs.push(
      this.message$.subscribe(value => {
        this.lastErrorMessage$.next(value);
        this.animationState = 'show';
      })
    );

    this.subs.push(
      this.isConnectionProblems$.pipe(distinctUntilChanged(), debounceTime(4000)).subscribe(isConnectionError => {
        if (!isConnectionError) {
          this.clearError();
        }
      })
    );
  }

  clearError() {
    this.animationState = 'hide';
    this._cd.detectChanges();
    this.animationTimeout = setTimeout(() => {
      this.lastErrorMessage$.next('');
    }, 1000);
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
    clearTimeout(this.animationTimeout);
  }
}
