import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RouterNavigateService } from '../../../shared/services/router-navigate.service';
import {
  FormComponent,
  FormSaveType,
  FormServiceParams,
  FormV2Service
} from '../../../shared/services/form-v2.service';
import { JsonApiSingeModelResponse } from '../../../shared/services/app/web-socket/http-response';
import { Observer } from 'rxjs/Observer';
import { HandleResponseAction, RemoveEntityFromStoreAction } from '../../../ngrx/actions/root.action';
import { SCORING_CRITERIA_PL } from '../../../constants';
import { Store } from '@ngrx/store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppState } from '../../../ngrx/state/';
import { ActivatedRoute } from '@angular/router';
import {
  DataTypeLabels,
  ScoringDataType,
  ScoringDirection,
  ScoringFactor
} from '../../../board/backlog-board/constants/backlog.constants';
import { fromScoringCriteria } from '../../../ngrx/reducers/scoring-criteria.reducer';
import { ScoringCriteria } from '../../../interfaces/ScoringCriteria';
import { toDashedFromCamelCase } from '../../../../helpers';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { AtlazApiV2Service } from '../../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { take } from 'rxjs/operators';
import { SegmentService } from '../../../atlaz-bnp/services/intergations/segment/segment.service';

@Component({
  selector: 'backlog-criteria-pop-up',
  templateUrl: './backlog-criteria-pop-up.component.html',
  styleUrls: ['./backlog-criteria-pop-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormV2Service]
})
export class BacklogCriteriaPopUpComponent implements OnInit, FormComponent {
  formObserver: Observer<any> = {
    next: (resp: JsonApiSingeModelResponse<any>) => {
      if (this.formServiceParams.saveType === FormSaveType.remove) {
        this._store.dispatch(
          new RemoveEntityFromStoreAction({ entityName: SCORING_CRITERIA_PL, predicate: x => x.id === this.id })
        );
      } else {
        this._store.dispatch(new HandleResponseAction(resp));
      }
      try {
        if (this.formServiceParams.saveType === FormSaveType.add) {
          this._segment.track('AdvScoringNewCriteriaAdded', this.getDataForSegmentCreate(resp.data.attributes));
        }
      } catch (e) {
        console.log('sending track AdvScoringNewCriteriaAdded', e);
      }
      this.onClose();
    },

    error: error => {},

    complete: () => {}
  };

  public form: FormGroup;

  formServiceParams: FormServiceParams;

  public boardId: number;
  public isNew = false;
  public id: number;
  public showConfirmDelete$ = new BehaviorSubject(false);

  public dataTypeLabels = Object.keys(DataTypeLabels).map(key => ({ value: key, name: DataTypeLabels[key] }));
  public ScoringDirection = ScoringDirection;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _routerNav: RouterNavigateService,
    private _fb: FormBuilder,
    private _api: AtlazApiV2Service,
    private _store: Store<AppState>,
    public _formService: FormV2Service,
    private _segment: SegmentService
  ) {}

  ngOnInit() {
    const snapshot = this._activatedRoute.snapshot;
    this.boardId = +snapshot.params['boardId'];

    let defaultValues: ScoringCriteria;
    if (ScoringFactor[snapshot.params['criteriaTypeOrId']]) {
      this.isNew = true;
      defaultValues = <ScoringCriteria>{
        factor: snapshot.params['criteriaTypeOrId']
      };
      defaultValues.dataType = ScoringDataType.GeneralNumber;
      defaultValues.scoringDirection =
        defaultValues.factor === ScoringFactor.Value ? ScoringDirection.HigherBetter : ScoringDirection.LowerBetter;
    } else {
      this.id = +snapshot.params['criteriaTypeOrId'];
      this._store
        .select(fromScoringCriteria.getById(this.id))
        .pipe(take(1))
        .subscribe(v => (defaultValues = v));
    }

    this.formServiceParams = {
      saveType: this.isNew ? FormSaveType.add : FormSaveType.edit,
      entityToEdit: toDashedFromCamelCase(SCORING_CRITERIA_PL),
      formObserver: this.formObserver,
      prepareFormValue: (formValue: any) => formValue
    };

    this.form = this._fb.group({
      id: this.id,
      board: this.boardId,
      name: [defaultValues.name, [Validators.required, Validators.maxLength(40)]],
      description: [defaultValues.description, Validators.maxLength(255)],
      dataType: [defaultValues.dataType],
      factor: [defaultValues.factor],
      scoringDirection: [defaultValues.scoringDirection],
      weight: [100]
    });

    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  onClose() {
    this._routerNav.deactivatePopupOutlet();
  }

  onSubmit() {
    this.formServiceParams.saveType = this.isNew ? FormSaveType.add : FormSaveType.edit;
    const currentVal = this.form.value['name'];
    this.form.get('name').patchValue(currentVal ? currentVal.trim() : currentVal);
    this._formService.markAsDirty();
    if (this.form.valid) {
      this._formService.submit();
    }
  }

  hideDeleteConfirm() {
    this.showConfirmDelete$.next(false);
  }

  onDelete() {
    this.showConfirmDelete$.next(true);
  }

  onConfirmDeleting() {
    this.hideDeleteConfirm();
    this.formServiceParams.saveType = FormSaveType.remove;
    this._formService.submit();
  }

  getDataForSegmentCreate(cr) {
    const result = {
      factor: cr.factor.toLowerCase(),
      scoringDirection: cr.scoringDirection === 1 ? 'up' : 'down',
      dataType: undefined
    };
    switch (cr.dataType) {
      case ScoringDataType.ZeroTen: {
        result.dataType = '0to10';
        break;
      }
      case ScoringDataType.GeneralNumber: {
        result.dataType = 'random';
        break;
      }
      case ScoringDataType.Sizes: {
        result.dataType = 'sizes';
        break;
      }
      case ScoringDataType.USD: {
        result.dataType = 'usd';
        break;
      }
      default: {
        result.dataType = 'boolean';
      }
    }
    return result;
  }
}
