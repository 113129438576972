import { combineLatest as observableCombineLatest, Observable, BehaviorSubject } from 'rxjs';

import { switchMap, publishReplay, refCount, map, pluck, filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { filterArchivedProjects, getAllProjects } from '../../../ngrx/reducers/project.reducer';
import { filterClosedBoards, getAllBoards } from '../../../ngrx/reducers/board.reducer';
import { getAllCollections } from '../../../ngrx/reducers/collection.reducer';
import { Board, Collection, Project } from '../../../interfaces';
import { isPresent, naturalSortComparator, naturalSortComparatorNested } from '../../../../helpers';

@Injectable()
export class SearchInLeftMenuService {
  public stringSearch$: BehaviorSubject<string> = new BehaviorSubject('');
  public isSearchActive$: Observable<boolean>;
  public isMatchingSearch$: Observable<boolean>;

  public searchData$;
  public collections$;
  public projects$;
  public unassign$;

  constructor(private _store: Store<AppState>) {
    this.isSearchActive$ = this.isSearchActive();
    this.searchData$ = this.searchItems();
    this.collections$ = this.searchData$.pipe(pluck('collections'));
    this.projects$ = this.searchData$.pipe(pluck('projects'));
    this.unassign$ = this.searchData$.pipe(pluck('unassign'));
    this.isMatchingSearch$ = this.isMatchingSearch();
  }

  get getStringSearch(): string {
    return this.stringSearch$.getValue();
  }

  set setStringSearch(string) {
    this.stringSearch$.next(string);
  }

  searchItems() {
    return this.stringSearch$.pipe(
      switchMap(stringSearch =>
        observableCombineLatest(
          this._store.pipe(getAllCollections),
          this._store.pipe(getAllProjects, map(filterArchivedProjects)),
          this._store.pipe(getAllBoards, map(filterClosedBoards))
        ).pipe(
          map(([allCollections, allProjects, allBoards]) => {
            const searchResult = { collections: [], projects: [], unassign: [] };
            const allBoardsMap = {};
            const allCollectionsMap = {};
            const allProjectsMap = {};
            const visibleBoardsMap = {};
            const visibleCollectionsMap = {};
            const visibleProjectsMap = {};
            allCollections.forEach((col: Collection) => (allCollectionsMap[col.id] = col));
            allProjects.forEach((prj: Project) => (allProjectsMap[prj.id] = prj));
            allBoards.forEach((board: Board) => (allBoardsMap[board.id] = board));

            allCollections.forEach((col: Collection) => {
              if (col.name.toLowerCase().indexOf(stringSearch.toLowerCase()) > -1) {
                visibleCollectionsMap[col.id] = searchResult.collections.length;
                searchResult.collections.push({
                  collection: col,
                  visibleBoard: col.boardsIds
                    ? col.boardsIds.filter(id => allBoardsMap[id]).map(id => {
                        visibleBoardsMap[id] = true;
                        return allBoardsMap[id];
                      })
                    : []
                });
              }
            });
            allProjects.forEach((prj: Project) => {
              if (prj.name.toLowerCase().indexOf(stringSearch.toLowerCase()) > -1) {
                visibleProjectsMap[prj.id] = searchResult.projects.length;
                searchResult.projects.push({
                  project: prj,
                  visibleBoard: prj.boardsIds
                    ? prj.boardsIds.filter(id => allBoardsMap[id]).map(id => {
                        visibleBoardsMap[id] = true;
                        return allBoardsMap[id];
                      })
                    : []
                });
              }
            });

            allBoards.forEach(board => {
              if (!visibleBoardsMap[board.id] && board.name.toLowerCase().indexOf(stringSearch.toLowerCase()) > -1) {
                visibleBoardsMap[board.id] = true;
                if (board.collectionsIds && board.collectionsIds.length) {
                  const colId = board.collectionsIds[0];
                  if (visibleCollectionsMap.hasOwnProperty(colId)) {
                    searchResult.collections[visibleCollectionsMap[colId]].visibleBoard.push(board);
                  } else if (allCollectionsMap[colId]) {
                    visibleCollectionsMap[colId] = searchResult.collections.length;
                    searchResult.collections.push({
                      collection: allCollectionsMap[colId],
                      visibleBoard: [board]
                    });
                  }
                } else if (board.projectsIds && board.projectsIds.length) {
                  const prjId = board.projectsIds[0];
                  if (visibleProjectsMap.hasOwnProperty(prjId)) {
                    searchResult.projects[visibleProjectsMap[prjId]].visibleBoard.push(board);
                  } else if (allProjectsMap[prjId]) {
                    visibleProjectsMap[prjId] = searchResult.projects.length;
                    searchResult.projects.push({
                      project: allProjectsMap[prjId],
                      visibleBoard: [board]
                    });
                  }
                } else {
                  searchResult.unassign.push(board);
                }
              }
            });

            searchResult.collections.sort(naturalSortComparatorNested('collection', 'name')).forEach(item => {
              item.visibleBoard.sort(naturalSortComparator('name'));
            });
            searchResult.projects.sort(naturalSortComparatorNested('project', 'name')).forEach(item => {
              item.visibleBoard.sort(naturalSortComparator('name'));
            });
            searchResult.unassign.sort(naturalSortComparator('name'));

            return searchResult;
          })
        )
      ),
      publishReplay(1),
      refCount()
    );
  }

  isMatchingSearch() {
    return <Observable<boolean>>observableCombineLatest([
      this.projects$.pipe(filter(isPresent), map(match => match['length'] > 0)),
      this.collections$.pipe(filter(isPresent), map(match => match['length'] > 0)),
      this.unassign$.pipe(filter(isPresent), map(match => match['length'] > 0))
    ]).pipe(
      map(
        ([matchingProjects, matchingCollections, matchingUnassignBoards]) =>
          matchingProjects || matchingCollections || matchingUnassignBoards
      )
    );
  }

  isSearchActive() {
    return this.stringSearch$.pipe(map(state => !!state));
  }
}
