<single-notification-preview
  [notification]="notification"
  [hasTitle]="true"
>
  <span single-notification-preview-title [innerHTML]="notification.preparedText | linkFromText | textBreak | loggedTime | noBrackets"></span>
  <div single-notification-preview-text class="text">
    <div *ngIf="(boardsByProjects$ | async)[0]">
      <board-list [ids]="(boardsByProjects$ | async)[0]"></board-list>
    </div>
    <div *ngFor="let project of projects$ | async">
      <p>{{project.name}}:</p>
      <board-list *ngIf="(boardsByProjects$ | async)[project.id]"
                  [ids]="(boardsByProjects$ | async)[project.id]"></board-list>
    </div>
  </div>
</single-notification-preview>
