import { Action } from '../unsafe-action';

import { labelAction } from '../../functions/action';
import { QuickFilter } from '../../../interfaces';

export const TaskQuickFilterActionTypes = {
  ADD_FILTER: labelAction('TASK_QUICK_FILTER', 'ADD_FILTER'),
  REMOVE_FILTER: labelAction('TASK_QUICK_FILTER', 'REMOVE_FILTER'),
  TOGGLE: labelAction('TASK_QUICK_FILTER', 'TOGGLE'),
  RESET: labelAction('TASK_QUICK_FILTER', 'CLEAR')
};

export class TaskQuickFilterAddFilterAction implements Action {
  type = TaskQuickFilterActionTypes.ADD_FILTER;

  constructor(public payload: QuickFilter) {}
}

export class TaskQuickFilterRemoveFilterAction implements Action {
  type = TaskQuickFilterActionTypes.REMOVE_FILTER;

  constructor(public payload: QuickFilter) {}
}

export class TaskQuickFilterToggleAction implements Action {
  type = TaskQuickFilterActionTypes.TOGGLE;

  constructor(public payload: QuickFilter) {}
}

export class TaskQuickFilterResetAction implements Action {
  type = TaskQuickFilterActionTypes.RESET;
}

export type TaskQuickFilterActions =
  | TaskQuickFilterAddFilterAction
  | TaskQuickFilterRemoveFilterAction
  | TaskQuickFilterToggleAction
  | TaskQuickFilterResetAction;
