import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { PaywallService } from '../paywall.service';
import { Features } from '../features.constants';

@Injectable()
export class InviteUsersPayWallGuard implements CanActivate {
  constructor(private _paywall: PaywallService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this._paywall.isFeatureEnabled(Features.CanAddMember)) {
      this._paywall.showPayWall(Features.CanAddMember);
    }
    return this._paywall.isFeatureEnabled(Features.CanAddMember);
  }
}
