import { Action } from '../unsafe-action';
import { DaysRange } from "./task-default-filter.actions";

export enum DefaultTasksTimeOnBoardFilterActionTypes {
  TOGGLE_ITEM_ID = '[DEFAULT_TIME_ON_BOARD_FILTER] TOGGLE_TIME_ON_BOARD',
  RESET = '[DEFAULT_TIME_ON_BOARD_FILTER] RESET'
}

export class DefaultTaskTimeOnBoardFilterToggleAction implements Action {
  readonly type = DefaultTasksTimeOnBoardFilterActionTypes.TOGGLE_ITEM_ID;

  constructor(public payload: DaysRange) {}
}

export class DefaultTimeOnBoardFilterResetAction implements Action {
  readonly type = DefaultTasksTimeOnBoardFilterActionTypes.RESET;
}

// prettier-ignore
export type Actions
  = DefaultTaskTimeOnBoardFilterToggleAction
  | DefaultTimeOnBoardFilterResetAction
  ;
