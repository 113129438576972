import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { TasksCustomField } from '../../../../ngrx/reducers/tasks-custom-field.reducer';
import { CustomField, CustomFieldTypes } from '../../../../ngrx/reducers/custom-field.reducer';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../../services/form-v2.service';
import { JsonApiSingeModelResponse } from '../../../services/app/web-socket/http-response';
import { Observer } from 'rxjs/Observer';
import { TASKS_CUSTOM_FIELD_PL } from '../../../../constants';
import { AppState } from '../../../../ngrx/state';
import { Store } from '@ngrx/store';
import { HandleResponseAction } from '../../../../ngrx/actions/root.action';
import { PartOfEntity } from '../../../../interfaces';
import { fromCamelToDash } from '../../../../../helpers';

@Component({
  selector: 'tasks-custom-field-edit',
  templateUrl: './tasks-custom-field-edit.component.html',
  styleUrls: ['./tasks-custom-field-edit.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TasksCustomFieldEditComponent implements OnInit, OnChanges {
  @Input() customFieldValue: TasksCustomField | PartOfEntity;
  @Input() customField: CustomField;
  @Input() useContextPopUp: boolean;
  @Input() taskId: number;

  @Output() close = new EventEmitter();

  public CustomFieldTypes = CustomFieldTypes;
  public options = [];
  public isPending: boolean;

  public typeLabels = {
    [CustomFieldTypes.text]: 'Text',
    [CustomFieldTypes.select]: 'Select',
    [CustomFieldTypes.number]: 'Number',
    [CustomFieldTypes.date]: ''
  };

  public initialFormValue;

  formObserver: Observer<any> = {
    next: (resp: JsonApiSingeModelResponse<any>) => {
      this._store.dispatch(new HandleResponseAction(resp));
      this.isPending = false;
      this.onClose();
    },

    error: () => {},

    complete: () => {}
  };

  formServiceParams: FormServiceParams;

  form: FormGroup;
  constructor(private _fb: FormBuilder, private _store: Store<AppState>, public _formService: FormV2Service) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.customField.currentValue && changes.customField.currentValue.values) {
      try {
        this.options = JSON.parse(changes.customField.currentValue.values);
      } catch (e) {
        console.log('Edit task custom component error', e);
        this.options = [];
      }
    }
  }

  ngOnInit() {
    this.initialFormValue = this.fromString(
      this.customFieldValue ? this.customFieldValue['value'] : '',
      this.customField.type
    );
    this.formServiceParams = {
      saveType: this.customFieldValue && this.customFieldValue.id ? FormSaveType.edit : FormSaveType.add,
      entityToEdit: [fromCamelToDash(TASKS_CUSTOM_FIELD_PL)],
      formObserver: this.formObserver,
      prepareFormValue: formValue => {
        if (this.customFieldValue && this.customFieldValue.id) {
          return {
            id: this.customFieldValue.id,
            value: this.toStringValue(formValue['value'], this.customField.type)
          };
        } else {
          return {
            customField: this.customField.id,
            task: this.taskId,
            value: this.toStringValue(formValue['value'], this.customField.type)
          };
        }
      }
    };

    this.form = this._fb.group({
      value: [this.initialFormValue]
    });

    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  onClose(lag = 0) {
    if (this.isPending) {
      return;
    }
    setTimeout(() => this.close.emit(), lag);
  }

  onSubmit(lag = 0) {
    this.isPending = true;
    this.form.markAsTouched();
    if (lag) {
      setTimeout(() => this._formService.submit(), lag);
    } else {
      this._formService.submit();
    }
  }

  toStringValue(value, type) {
    switch (type) {
      case CustomFieldTypes.select: {
        return value || '';
      }
      case CustomFieldTypes.text: {
        return value || '';
      }
      case CustomFieldTypes.number: {
        return value || value === 0 ? value + '' : '';
      }
      case CustomFieldTypes.date: {
        return value ? value + '' : '';
      }
      default: {
        return value ? value + '' : '';
      }
    }
  }

  fromString(value, type) {
    switch (type) {
      case CustomFieldTypes.number: {
        return parseFloat(value) || null;
      }
      case CustomFieldTypes.text: {
        return value;
      }
      case CustomFieldTypes.select: {
        return value;
      }
      case CustomFieldTypes.date: {
        return parseInt(value) || Date.now() / 1000;
      }
    }
  }
}
