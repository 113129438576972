import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Task, PartOfEntity } from '../../../interfaces';
import { formatTimeToSecond, secondsToEdit } from '../../../../helpers';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TASK_PL, ESTIMATE, STORY_POINTS } from '../../../constants';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { Observer } from 'rxjs';
import { HandleResponseAction } from '../../../ngrx/actions/root.action';
import { FormV2Service, FormServiceParams, FormSaveType } from '../../../shared/services/form-v2.service';

@Component({
  selector: 'task-estimate',
  templateUrl: './task-estimate.component.html',
  styleUrls: ['./task-estimate.component.scss'],
  providers: [FormV2Service]
})
export class TaskEstimateComponent implements OnInit {
  formObserver: Observer<any> = {
    next: resp => {
      this.deleting = false;
      this.onClose();
      this._store.dispatch(new HandleResponseAction(resp));
    },
    error: error => {
      this.deleting = false;
    },
    complete: () => {
      this.deleting = false;
    }
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.edit,
    entityToEdit: TASK_PL,
    formObserver: this.formObserver,
    prepareFormValue: _ => {
      const valueToSave = { id: this.task.id };
      if (!this.isStoryPointsMode) {
        valueToSave[ESTIMATE] = this.deleting ? 0 : formatTimeToSecond(this.form.value.time);
      } else {
        valueToSave[STORY_POINTS] = this.deleting ? 0 : this.form.value.storyPoints;
      }
      return valueToSave;
    }
  };

  @Input() task: Task;
  @Input() isStoryPointsMode: boolean;
  @Output() submitForm: EventEmitter<PartOfEntity> = new EventEmitter<PartOfEntity>();
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() resetFocus: EventEmitter<any> = new EventEmitter();

  public deleting = false;

  public form: FormGroup;
  public isNewRecord = false;
  public title: string;
  public placeholder: string;

  constructor(private _fb: FormBuilder, private _store: Store<AppState>, public _formService: FormV2Service) {}

  ngOnInit(): any {
    this.isNewRecord = this.isStoryPointsMode ? !this.task.storyPoints : !this.task.estimate;
    this.form = this._fb.group({
      time: [this.task.estimate ? secondsToEdit(this.task.estimate) : ''],
      storyPoints: [
        this.task.storyPoints ? this.task.storyPoints : '',
        Validators.compose([Validators.min(0), Validators.max(100), Validators.pattern(/^\d+$/)])
      ]
    });

    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  onClose() {
    this.close.emit(true);
  }

  onSubmit() {
    if (this.form.pristine) {
      this.resetFocus.emit({});
      this.onClose();
      return false;
    }
    this._formService.submit();
    this.resetFocus.emit({});
    return false;
  }

  onRemove($event) {
    this.deleting = true;
    this._formService.submit();
    this.resetFocus.emit({});
    return false;
  }
}
