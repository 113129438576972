
import {switchMap} from 'rxjs/operators';
import { Actions, Effect } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { AtlazApiV2Service } from '../../../shared/services/atlaz-api/v2/atlaz-api-v2.service';

import * as billingSubscription from '@atlaz/billing/store/subscription/subscription.actions';
import { BillingPublicApiService } from '@atlaz/billing/shared/billing-public-api.service';
import { SUBSCRIPTION_ACTIONS, SUBSCRIPTION_PATH } from '@atlaz/billing/constants/billing-subscription';

@Injectable()
export class SubscriptionEffects {
  @Effect({ dispatch: false })
  saveToken$ = this._actions$
    .ofType(billingSubscription.SAVE_TOKEN).pipe(
    switchMap(({ type, payload }: { type: string; payload: any }) => {
      return this._atlazApi$.patch([SUBSCRIPTION_PATH, payload.id], {
        action: SUBSCRIPTION_ACTIONS.addCard,
        token: payload.token.id
      });
    }));

  constructor(
    private _actions$: Actions,
    private _billingService: BillingPublicApiService,
    private _atlazApi$: AtlazApiV2Service
  ) {}
}
