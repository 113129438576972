
import {fromEvent as observableFromEvent, merge as observableMerge,  Observable ,  BehaviorSubject ,  Subscription } from 'rxjs';

import {pairwise, map, takeUntil, filter, switchMap} from 'rxjs/operators';
import {
  Directive,
  Input,
  ElementRef,
  OnDestroy,
  AfterViewInit,
  SimpleChanges,
  OnChanges,
  Optional
} from '@angular/core';
import { both, compose, either, isLeftMouseButton, not } from '../../../helpers';
import { getEventPath } from '../../../helpers/event';
import { ScrollService } from '../dragula/scroll.service';
import { isDesktopSafari } from '../../../helpers/platform';

export const hasDocumentFocus = () => document.hasFocus();

export const isTextArea = domElement => domElement.tagName.toUpperCase() === 'TEXTAREA';
export const eventTargetIsTextArea = event => isTextArea(event.target);
export const haveDraggableParent = event => getEventPath(event).some(el => el.draggable);

@Directive({
  selector: '[mouseScroll]'
})
export class MouseScrollDirective implements OnChanges, AfterViewInit, OnDestroy {
  @Input() mouseScroll: boolean;

  @Input() verticallScrollContainer;
  @Input() horizontalScrollContainer;

  private documentClick$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private eventSub: Subscription;

  constructor(private _elementRef: ElementRef, @Optional() private _scrollService: ScrollService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('mouseScroll') && changes['mouseScroll'] !== null) {
      this.documentClick$.next(this.mouseScroll);
    }
  }

  ngAfterViewInit() {
    this.eventSub = this.scrollByMouseMove();
  }

  ngOnDestroy() {
    if (this.eventSub) {
      this.eventSub.unsubscribe();
    }
  }

  private scrollByMouseMove() {
    return this.makeDragScrollOnElement(
      this._elementRef.nativeElement,
      this.verticallScrollContainer,
      this.horizontalScrollContainer
    );
  }

  private makeDragScrollOnElement(element, verticalContainer, horizontalContainer) {
    return this.getDragScrollOnElement(
      element
    ).subscribe((ev: { x: number; y: number; scrollLeft: number; scrollTop: number }) => {
      const doScroll = (container, scroll: 'scrollTop' | 'scrollLeft') => {
        if (!!container) {
          container[scroll] += ev[scroll];
        } else {
          document.documentElement[scroll] += ev[scroll];
        }
      };

      doScroll(verticalContainer, 'scrollTop');
      doScroll(horizontalContainer, 'scrollLeft');
      if (this._scrollService && isDesktopSafari) {
        this._scrollService.fireCheck();
      }
    });
  }

  private getDragScrollOnElement(element) {
    const stop$ = observableMerge(
      observableFromEvent(document, 'mouseup'),
      observableFromEvent(window, 'blur'),
      observableFromEvent(window, 'dragstart'),
      this.getInActiveState()
    );

    const mouseMove$ = observableFromEvent<MouseEvent>(element, 'mousemove').pipe(
      pairwise(),
      map((events: MouseEvent[]) => ({
        scrollLeft: events[0].clientX - events[1].clientX,
        scrollTop: events[0].clientY - events[1].clientY
      })),);

    const canStart = both(compose(not, either(haveDraggableParent, eventTargetIsTextArea)), hasDocumentFocus);
    const start$ = observableFromEvent(element, 'mousedown').pipe(
      filter(isLeftMouseButton),
      filter(canStart),);

    return this.getActiveState().pipe(switchMap(_ => start$.pipe(switchMap(ev => mouseMove$.pipe(takeUntil(stop$))))));
  }

  private getActiveState() {
    return this.documentClick$.pipe(filter(isActive => isActive === true));
  }

  private getInActiveState() {
    return this.documentClick$.pipe(filter(isActive => isActive === false));
  }
}
