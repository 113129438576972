
import {takeUntil, map} from 'rxjs/operators';
import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectionStrategy, OnDestroy } from '@angular/core';

import { Observable ,  Subject } from 'rxjs';
import { Entity } from '../../../interfaces/entity';

@Component({
  selector: 'dropdown-filter',
  templateUrl: './dropdown-filter.component.html',
  styleUrls: ['./task-feed-filter.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownFilterComponent<T extends Entity> implements OnInit, OnDestroy {
  @Input() items: T[];
  @Input() selectedItemIds$: Observable<number[]>;

  @Output() changeDropDownFilter = new EventEmitter<number[]>();

  public isDropdownVisible = false;
  public selectedItems$: Observable<T[]>;

  public selectedIdsMap: { [projectId: number]: boolean };

  public fieldForTag: keyof T;
  public fieldForDropdown: keyof T;
  public filterName: string;

  private destroy$ = new Subject();

  ngOnInit(): void {
    this.selectedItems$ = this.selectedItemIds$.pipe(map(itemIds => this.items.filter(item => itemIds.includes(item.id))));

    this.selectedItemIds$.pipe(takeUntil(this.destroy$)).subscribe(projectIds => {
      this.selectedIdsMap = projectIds.reduce((acc, id) => {
        acc[id] = true;
        return acc;
      }, {});
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  onDeleteFromFilter<T extends Entity>(item: T) {
    this.onSelectItem(item);
    this.emitIds();
  }

  onOpenDropdown() {
    this.isDropdownVisible = true;
  }
  onCloseDropdown() {
    this.isDropdownVisible = false;
    this.emitIds();
  }

  onSelectItem<T extends Entity>(item: T) {
    const newFlag = { [item.id]: !this.selectedIdsMap[item.id] };
    this.selectedIdsMap = { ...this.selectedIdsMap, ...newFlag };
  }

  protected initFields<K extends keyof T>(filterTitle: string, filterTagField: K, filterDropdownField: K) {
    this.fieldForTag = filterTagField;
    this.fieldForDropdown = filterDropdownField;
    this.filterName = filterTitle;
  }

  private emitIds() {
    const newSelectedIds = this.getSelectedIdsList();
    this.changeDropDownFilter.emit(newSelectedIds);
  }

  private getSelectedIdsList(): number[] {
    return Object.keys(this.selectedIdsMap).reduce((ids, id) => {
      if (this.selectedIdsMap[id]) {
        // convert numerical sting values to int if possible
        ids.push(+id);
      }
      return ids;
    }, []);
  }
}
