<div class="preview-container" [ngClass]="{unavailable: (isTaskDeleted$ | async)}">
  <div *ngIf="!(isTaskDeleted$ | async); else deletedCard" [routerLink]="taskUrl$ | async" class="preview-wrapper">
    <ng-container *ngTemplateOutlet="previewCard"></ng-container>
  </div>
</div>
<a-center-popup *ngIf="isUnavailableTaskPopupOpened" [title]="'Task was deleted'" (close)="onToggleUnavailableTaskPopup()">
  <p class="popup-message">
    The task <strong>{{task.title}}</strong> was deleted and is no longer available.
    <br>
    <br>
    <button a-button class="btn_primary" (click)="onToggleUnavailableTaskPopup()">Ok</button>
  </p>
</a-center-popup>

<ng-template #deletedCard>
  <div class="preview-wrapper" (click)="onToggleUnavailableTaskPopup()">
    <ng-container *ngTemplateOutlet="previewCard"></ng-container>
  </div>
</ng-template>

<ng-template #previewCard>
  <h2>
    <span>{{task.title}}</span>
  </h2>
  <p *ngIf="task.project || task.board" class="project_line">
    <span *ngIf="task.project" class="project_name">{{projectName$ | async}} - {{task.numberInProject}}</span>
    <span *ngIf="task.project"> | </span>
    <span>{{taskBreadCrumbs$| async}}</span>
  </p>
  <markdown [data]="task.description | textToHtml | markdownBreak" [isTargetBlankLinks]="true"></markdown>
  <div *ngIf="task.usersIds?.length" class="user_list">
    <user-avatar-wrapper class="avatar" *ngFor="let userId of task.usersIds" [userId]="userId"></user-avatar-wrapper>
  </div>
  <p class="dates">
    <span class="duedate_container" [ngClass]="{'overdone': (isTaskOverdone$ | async), 'near_overdone': (isTaskCloseToOverdone$ | async)}" *ngIf="task.dueDate > 0">
        <span class="time"></span>
        <span class="date">{{task.dueDate | toDate:'short'}}</span>
        <span class="mid_dot">&middot;</span>
    </span>
    <span *ngIf="task.updatedAt">Updated {{task.updatedAt | toDate: 'mediumDate'}}</span>
    <span *ngIf="task.createdAt && task.updatedAt" class="mid_dot">&middot;</span>
    <span *ngIf="task.createdAt">Created {{task.createdAt | toDate: 'mediumDate'}}</span>
  </p>
</ng-template>
