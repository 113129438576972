<div *ngIf="project$ | async">
  <div [ngClass]="{sidebar_block: true, editable: editPermissions$ | async}">
    <div *ngIf="editPermissions$ | async" class="project_overlay_link editable" (click)="onOpenPopup()"></div>
    <div *ngIf="editPermissions$ | async" class="task_sidebar_edit_link" (click)="onOpenPopup()">
      Edit
    </div>
    <h5 class="sidebar_block_title">Project Status</h5>

    <div class="project_title">{{projectStatus$ | async}}</div>

    <div a-popup
         [title]="'Edit Project Status'"
         (close)="onClose()"
         *ngIf="isPopupVisible"
    >
      <project-status-editing [project]="project$ | async" (close)="onClose()"></project-status-editing>
    </div>
  </div>
  <div class="divider"></div>

</div>
