
import {filter, mergeMap, map, switchMap, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { NOTIFICATION_PL, TASK } from '../../constants';

import { NotificationActionTypes, NotificationGetAction } from '../actions/notification.actions';
import { HttpQueryParam } from '../../interfaces';
import { defaultErrorHandler } from './root.effect';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { HandleResponseAction } from '../actions/root.action';
import { isPresent } from '../../../helpers';
import { AuthService } from '../../shared/services/app/auth.service';

const NOTIFICATION_GROUP = 'notifications-group';

@Injectable()
export class NotificationEffects {
  @Effect()
  init$ = this._auth.userInActiveCompany$.pipe(
    filter(isPresent),
    map(() => new NotificationGetAction({ archived: 0, sort: '-createdAt', expand: TASK })),);

  @Effect()
  getNotificationList$ = this.actions$
    .ofType(NotificationActionTypes.GET).pipe(
    switchMap(({ type, payload: httpParams }: { type: string; payload: HttpQueryParam }) => {
      return this._atlazApi
        .get(NOTIFICATION_PL, httpParams).pipe(
        map(resp => new HandleResponseAction(resp)),
        catchError(defaultErrorHandler(type, httpParams)),);
    }));

  @Effect({ dispatch: false })
  markAs$ = this.actions$
    .ofType(NotificationActionTypes.MARK_AS).pipe(
    mergeMap(({ type, payload }: { type: string; payload: { markType: string; notificationIds: number[] } }) => {
      const notifyData = {
        [NOTIFICATION_PL]: payload.notificationIds
      };
      return this._atlazApi
        .patch([NOTIFICATION_GROUP, payload.markType], notifyData).pipe(
        map(resp => new HandleResponseAction(resp)),
        catchError(defaultErrorHandler(type, payload)),);
    }));

  constructor(private actions$: Actions, private _auth: AuthService, private _atlazApi: AtlazApiV2Service) {}
}
