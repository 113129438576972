<input type="text" [focus]="true" placeholder="Search label..." (keyup)="0" #input_search_labels/>
<div class="labels_list">
  <div class="label_item" *ngFor="let item of labels | searchByField: 'name': input_search_labels.value">
    <div class="label_wrapper" (click)="onSelectLabel(item)">
      <div class="label_color" [ngClass]="item.label.color">
        <div class="label_check" *ngIf="item.checked"></div>
      </div>
      <div class="label_name">{{item.name}}</div>
    </div>
    <div class="label_pencil" (click)="onEditLabel(item.label)">
      <div class="pencil_icon"></div>
    </div>
  </div>
  <div (click)="onOpenEditPopup()" class="label_item label_new">
    <div class="label_add"></div>
    <div class="label_create">Create new label</div>
  </div>
</div>

<sidebar-labels-edit-popup
  *ngIf="isLabelsEditPopupVisible"
  class="edit_labels_popup"
  [isEdit]="isEditLabel"
  [label]="selectedLabel"
  [boardId]="task.board"
  [existedLabels]="taskLabels$ | async"
  (close)="onCloseEditPopup()"
></sidebar-labels-edit-popup>


