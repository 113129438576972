import { createSelector } from '@ngrx/store';
import { AppState, REPORT_GROUP_FILTER } from '../../state';
import * as reportGroupFilter from '../../actions/report-filters/report-groups-filter.action';
import { ReportGroupFilterActionTypes } from '../../actions/report-filters/report-groups-filter.action';

export interface ReportGroupsFilter {
  selectedAll: boolean,
  groupsIds: number[];
}

const initState: ReportGroupsFilter = {
  selectedAll: false,
  groupsIds: []
};

export function reducer(
  state = initState,
  action: reportGroupFilter.Actions
): ReportGroupsFilter {
  switch (action.type) {
    case ReportGroupFilterActionTypes.APPLY: {
      state = action['payload'];
      return state;
    }

    case ReportGroupFilterActionTypes.RESET: {
      state = initState;
      return initState;
    }

    default: {
      return state;
    }
  }
}

export namespace fromReportGroup {
  export const getState = (state: AppState) =>
    <ReportGroupsFilter>state[REPORT_GROUP_FILTER];

  export const isActive = createSelector(
    getState,
    state => state.groupsIds.length > 0
  );
  export const getIds = createSelector(
    getState,
    state => state.groupsIds
  );
  export const isSelectedAll = createSelector(
    getState,
    state => state.selectedAll
  );
}
