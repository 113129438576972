import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { Task } from '../../../../interfaces';
import { AppState } from '../../../../ngrx/state';
import { BacklogChartService } from '../../backlog-chart.service';
import { getTaskById } from '../../../../ngrx/reducers/task.reducer';
import { filter, switchMap } from 'rxjs/operators';
import { ScaleType } from '../../../../constants';

@Component({
  selector: 'task-drag-tooltip',
  template: `
    <div chart-tooltip class="task_tooltip"
         *ngIf="(_backlogChart.backlogDraggingTask$ | async)"
         [topPx]="(_backlogChart.backlogDraggingTask$ | async).d3Event.sourceEvent.clientY-25"
         [leftPx]="(_backlogChart.backlogDraggingTask$ | async).d3Event.sourceEvent.clientX-25"
    >
      <svg *ngIf="(_backlogChart.backlogDraggingTask$ | async).isInsideInChart">
        <svg:g drag-task-circle
               [axisXLabel]="axisXLabel"
               [axisYLabel]="axisYLabel"
               [axisXType]="axisXType"
               [axisYType]="axisYType"
               [taskDragging]="_backlogChart.backlogDraggingTask$ | async"
               [attr.transform]="'translate(25, 25)'"></svg:g>
      </svg>
      <div *ngIf="!(_backlogChart.backlogDraggingTask$ | async).isInsideInChart" class="list_item">
        <task-list-item 
          [task]="task$ | async"
          [scaleTypeX]="axisXType"
          [scaleTypeY]="axisYType"
        ></task-list-item>
      </div>
    </div>
  `,
  styleUrls: ['./task-drag-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskDragTooltipComponent implements OnInit {
  @Input() axisXLabel: string;
  @Input() axisYLabel: string;
  @Input() axisXType: ScaleType;
  @Input() axisYType: ScaleType;

  public _backlogChart;
  public task$: Observable<Task>;

  constructor(_backlogChart: BacklogChartService, private _store: Store<AppState>) {
    this._backlogChart = _backlogChart;
  }

  ngOnInit(): any {
    this.task$ = this._backlogChart.draggingTaskId$.pipe(
      filter(taskId => !!taskId),
      switchMap(taskId => this._store.pipe(getTaskById(taskId)))
    );
  }
}
