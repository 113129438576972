
import {filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { InitialDataEffects } from '../../../ngrx/effects/initial-data.effects';
import { isPresent } from '../../../../helpers';

@Injectable()
export class AppInitializedGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._initialDataEffects.initialized$.pipe(filter(isPresent));
  }

  constructor(private _initialDataEffects: InitialDataEffects) {}
}
