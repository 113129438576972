import { BillingPlan } from '@atlaz/billing/interfaces/billing-plan';
import { BILLING_PLAN_PL } from '../../../constants';
import { SET_PLANS } from '@atlaz/billing/store/plan/plan.actions';
import { Action } from '../../../ngrx/actions/unsafe-action';

export interface BillingPlanState {
  [planId: string]: BillingPlan;
}

const initialState: BillingPlanState = {};

export function reducer(state = initialState, action: Action): BillingPlanState {
  switch (action.type) {
    case SET_PLANS:
      return { ...action.payload };
    default:
      return state;
  }
}

export namespace fromBillingPlans {
  export const get = id => (state): BillingPlan => state[BILLING_PLAN_PL][id];
  export const getAll = state => state[BILLING_PLAN_PL];
}
