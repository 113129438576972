import { Action } from '../actions/unsafe-action';
import { getEnvironmentParam } from '../../../environments/helper';
import { Company } from '../../interfaces';
import { CurrentCompany } from '../actions/current-company.actions';
import { COMPANY_PL, CURRENT_COMPANY, ENTITY_PL } from '../../constants';
import { AppState } from '../state';

const initialState = window['company'] ? window['company'] : getEnvironmentParam('defaultCompany');

export function currentCompanyReducer(state = initialState, action: Action): Company {
  switch (action.type) {
    case CurrentCompany.UPDATED: {
      return { ...action.payload };
    }
    default: {
      return state;
    }
  }
}

export const getCurrentCompany = (state: AppState): Company => {
  return state[COMPANY_PL][ENTITY_PL][state[CURRENT_COMPANY]['id']];
};
