import { AppState, ESInterface } from '../state';
import { User } from '../../interfaces';
import { UserCompanyActionTypes } from '../actions/user-company.actions';
import { Action } from '../actions/unsafe-action';
import { UsersCompany } from '../../interfaces/users-compnay';
import { editEntityState } from '../functions/reducer';
import { UserActionTypes } from '../actions/user.actions';
import { USER_COMPANY_PL } from '../../constants';

const initialState: ESInterface<User> = {
  ids: [],
  entities: {},
  selectedEntityId: null
};

export function reducer(state = initialState, action: Action): ESInterface<User> {
  switch (action.type) {
    case UserCompanyActionTypes.EDIT_COMPLETE: {
      const userCompany: UsersCompany = action.payload;
      return editEntityState(state, userCompany);
    }

    case UserActionTypes.DELETE: {
      const user = action.payload;
      return editEntityState(state, { id: user.userCompanyId, deleted: 1 });
    }

    default: {
      return state;
    }
  }
}

export namespace fromUserCompanies {
  export const getState = (store: AppState) => {
    return <ESInterface<UsersCompany>>store[USER_COMPANY_PL];
  };
  export const getById = id => (store: AppState) => getState(store).entities[id];
}
