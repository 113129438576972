import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { EstimationType } from '../../constants';

@Component({
  selector: 'mode-view-picker',
  templateUrl: './mode-view-picker.component.html',
  styleUrls: ['./mode-view-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModeViewPickerComponent implements OnChanges {
  @Input() estimatedBy = EstimationType.any;
  @Input() mode = EstimationType.storyPoints;
  @Output() modeChange = new EventEmitter();

  public set modeValue(v: EstimationType) {
    this._modeValue = v;
    this.modeChange.emit(this._modeValue);
  }
  public get modeValue() {
    return this._modeValue;
  }

  public estimationType = EstimationType;

  public modeName = {
    [EstimationType.hours]: 'Hours',
    [EstimationType.storyPoints]: 'Story Points'
  };

  public get canPickMode() {
    return this.estimatedBy === EstimationType.any;
  }

  private _modeValue: EstimationType;
  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['mode'] &&
      (changes['mode'].firstChange || changes['mode'].previousValue !== changes['mode'].currentValue)
    ) {
      this._modeValue = changes['mode'].currentValue;
    }

    if (
      changes['estimatedBy'] &&
      (changes['estimatedBy'].firstChange ||
        changes['estimatedBy'].previousValue !== changes['estimatedBy'].currentValue)
    ) {
      if (this.estimatedBy !== EstimationType.any) {
        this.modeValue = this.estimatedBy;
      }
    }
  }
}
