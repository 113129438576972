import { Component, Input, OnInit } from '@angular/core';
import { Task, Version } from '../../../interfaces';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state/';
import { Observable } from 'rxjs';
import { fromOpenedTask } from '../../ngrx/reducers/opened-task.reducer';

@Component({
  selector: 'sidebar-version',
  templateUrl: './sidebar-version.component.html',
  styleUrls: ['./sidebar-version.component.scss']
})
export class SidebarVersionComponent implements OnInit {
  @Input() task: Task;
  @Input() version: Version;
  @Input() editPermissions = false;

  public projectVersions$: Observable<Version[]>;
  public isVersionPopupVisible = false;

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this.projectVersions$ = this._store.select(fromOpenedTask.getProjectVersions);
  }

  onOpenVersionPopup() {
    if (!this.editPermissions) {
      return false;
    }
    this.isVersionPopupVisible = true;
  }
  onCloseVersionPopup() {
    this.isVersionPopupVisible = false;
  }
}
