import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CustomField, CustomFieldTypes, fromCustomFields } from '../../../ngrx/reducers/custom-field.reducer';
import { AppState } from '../../../ngrx/state';
import { Store } from '@ngrx/store';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { Board, PartOfEntity } from '../../../interfaces';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { fromTasksCustomFields, TasksCustomField } from '../../../ngrx/reducers/tasks-custom-field.reducer';
import { Subscription } from 'rxjs/index';

@Component({
  selector: 'sidebar-custom-fields',
  templateUrl: './sidebar-custom-fields.component.html',
  styleUrls: ['./sidebar-custom-fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarCustomFieldsComponent implements OnInit, OnDestroy {
  @Input() canEditTask: boolean;
  @Input() taskId: number;
  @Input() board: Observable<Board>;

  public customFieldsArr$: Observable<CustomField[]>;
  public customFieldValuesMap: { [fieldId: number]: TasksCustomField } = {};

  public editingField: CustomField;
  public editingFieldValue: TasksCustomField | PartOfEntity;
  public isEditing: boolean;
  public CustomFieldTypes = CustomFieldTypes;

  public subs: Subscription[] = [];

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this.customFieldsArr$ = combineLatest(this.board, this._store.select(fromCustomFields.getAll)).pipe(
      map(
        ([board, fields]) =>
          board ? fields.filter(item => item.board === board.id).sort((a, b) => a.position - b.position) : []
      ),
      publishReplay(1),
      refCount()
    );

    this.subs.push(
      this._store.select(fromTasksCustomFields.getByTaskId(this.taskId)).subscribe(valuesArr => {
        this.customFieldValuesMap = valuesArr.reduce((acc, item) => {
          acc[item.customField] = item;
          return acc;
        }, {});
      })
    );
  }

  onEditFieldValue(field) {
    this.editingField = field;
    this.editingFieldValue = this.customFieldValuesMap[field.id] || {
      customField: field.id,
      task: this.taskId,
      value: null
    };
    this.isEditing = true;
  }

  onEditingEnd() {
    this.isEditing = false;
    this.editingField = null;
    this.editingFieldValue = null;
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
