import { debounceTime, map, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../ngrx/state';
import { Observable, BehaviorSubject } from 'rxjs';
import { Board, Swimlane } from '../../../../interfaces';
import { fromBoards } from '../../../../ngrx/reducers/board.reducer';
import { BOARD_PL, boardType, COLUMN_PL, SWIMLANE_PL } from '../../../../constants';
import { getSwimlanesByBoard } from '../../../../ngrx/reducers/swimlane.reducer';
import { getTargetColumns } from '../../../../ngrx/reducers/column.reducer';
import { AtlazApiV2Service } from '../../../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { JsonApiSingeModelResponse } from '../../../../shared/services/app/web-socket/http-response';
import { HandleResponseAction } from '../../../../ngrx/actions/root.action';

@Component({
  selector: 'push-all-tasks-exist-board-form',
  templateUrl: './push-all-tasks-exist-board-form.component.html',
  styleUrls: ['./push-all-tasks-exist-board-form.component.scss']
})
export class PushAllTasksExistBoardFormComponent implements OnInit {
  @Input() form: FormGroup;

  @Output() formChange = new EventEmitter();

  boards$: Observable<Board[]>;
  swimlanes$;
  columns$;

  selectedBoard$;

  showSwimlanePicker$;

  subscriptions = [];
  loadedBoards = [];

  constructor(private _store: Store<AppState>, private _atlazApiV2: AtlazApiV2Service, private _fb: FormBuilder) {}

  ngOnInit(): any {
    this.boards$ = this._store
      .select(fromBoards.getAllAvailable)
      .pipe(map((boards: Board[]) => boards.filter(board => board.type !== boardType.backlog)));

    this.selectedBoard$ = new BehaviorSubject(0);
    this.swimlanes$ = this.selectedBoard$.pipe(switchMap(boardId => this._store.pipe(getSwimlanesByBoard(boardId))));

    this.showSwimlanePicker$ = this.swimlanes$.pipe(map((swimlanes: Swimlane[]) => swimlanes.length > 1));

    this.columns$ = this.selectedBoard$.pipe(
      distinctUntilChanged(),
      switchMap((boardId: number) => this._store.pipe(getTargetColumns(boardId)))
    );

    this.form = this._fb.group({
      board: [0, Validators.required],
      swimlane: [0, Validators.required],
      column: [0, Validators.required]
    });

    this.formChange.emit(this.form);

    // set default value of swimlane
    this.subscriptions.push(
      this.swimlanes$.subscribe(swimlanes => {
        if (swimlanes.length > 0) {
          // to int
          const selectedSwimlane = +this.form.value.swimlane;
          if (!swimlanes.some(swimlane => selectedSwimlane === swimlane.id)) {
            this.form.get('swimlane').patchValue(swimlanes[0].id);
          }
        }
      })
    );
    // set default value of column
    this.subscriptions.push(
      this.columns$.subscribe(columns => {
        if (columns.length > 0) {
          // to int
          const selectedColumn = +this.form.value.column;
          if (!columns.some(column => selectedColumn === column.id)) {
            this.form.get('column').patchValue(columns[0].id);
          }
        }
      })
    );
    this.subscriptions.push(
      this.form.valueChanges.pipe(debounceTime(100)).subscribe(
        // !important
        // typecast to int boardID, swimlanes store boardId as int value
        // so do columnId
        values => {
          this.loadBoard(values['board']);
          this.selectedBoard$.next(+values['board']);
        }
      )
    );
  }

  loadBoard(boardId) {
    if (this.loadedBoards[boardId]) {
      return;
    }
    this._atlazApiV2
      .get(BOARD_PL, { id: boardId, expand: [COLUMN_PL, SWIMLANE_PL] })
      .subscribe((resp: JsonApiSingeModelResponse<any>) => {
        this.loadedBoards[boardId] = true;
        this._store.dispatch(new HandleResponseAction(resp));
      });
  }
}
