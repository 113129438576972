import { Action } from '../unsafe-action';
import { labelAction } from '../../functions/action';

export const DefaultTasksMembersFilterActionTypes = {
  TOGGLE_MEMBER_ID: labelAction('DEFAULT_MEMBERS_FILTER', 'TOGGLE_MEMBER_IDS'),
  RESET: labelAction('DEFAULT_MEMBERS_FILTER', 'RESET')
};

export class DefaultMemberFilterToggleMembersAction implements Action {
  type = DefaultTasksMembersFilterActionTypes.TOGGLE_MEMBER_ID;

  constructor(public payload: number) {}
}

export class DefaultMemberFilterResetAction implements Action {
  type = DefaultTasksMembersFilterActionTypes.RESET;
}
