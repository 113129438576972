import {
  distinctUntilChanged,
  filter,
  map,
  pluck,
  publishReplay,
  refCount,
  switchMap,
  take,
  takeUntil
} from 'rxjs/operators';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Board, Column, Swimlane, Task } from '../../interfaces';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/state';
import { fromBoards, isBacklogBoardBuilder, isKanbanBoardBuilder } from '../../ngrx/reducers/board.reducer';
import { RouterNavigateService } from '../../shared/services/router-navigate.service';
import { ColumnActionTypes, ColumnEditAction, ColumnSortByPriorityAction } from '../../ngrx/actions/column.actions';
import { getBoardColumnCounter, getColumnByIdIfWIPed } from '../../ngrx/reducers/column.reducer';
import {
  fromTask,
  getBoardTasks,
  getTaskIdsByColumnObjectWithComposite,
  getTasksByColumn,
  getTasksByUsersFilter,
  inBoard,
  isActiveTask
} from '../../ngrx/reducers/task.reducer';
import { both, isPresent } from '../../../helpers';
import { PermissionsService } from '../../permissions/permissions.service';
import { flatten } from 'ramda';
import { getSwimlanesByBoard } from '../../ngrx/reducers/swimlane.reducer';
import { COLUMN_PL, columnKinds, NonBasicScoringTypes, ScoringType } from '../../constants';
import { PaywallService } from '../../libs/paywall/paywall.service';
import { Features } from '../../libs/paywall/features.constants';
import { of } from 'rxjs/internal/observable/of';
import { getScoreByTasksFromSameBoard } from '../../ngrx/functions/crossed.selector';
import { Subscription } from 'rxjs/index';
import { Actions } from '@ngrx/effects';
import { AuthService } from '../../shared/services/app/auth.service';
import { PatchEntityAction } from '../../ngrx/actions/root.action';

@Component({
  selector: 'column-header',
  templateUrl: './column-header.component.html',
  styleUrls: ['./column-header.component.scss']
})
export class ColumnHeaderComponent implements OnInit, OnChanges {
  @Input() column: Column;
  @Input() boardId: number;

  @Output() toggleCreteColumnPopup = new EventEmitter();
  @ViewChild('counter', { read: ElementRef })
  counterEl: ElementRef;

  public columnForWIP$;
  public isBacklogBoard$: Observable<boolean>;
  public isKanbanBoard$: Observable<boolean>;
  public boardId$: BehaviorSubject<number> = new BehaviorSubject(null);
  public isColumnMenuVisible = false;
  public isArchiveColumntPopupVisible = false;
  public isSortColumnVisible$ = new BehaviorSubject(false);
  public sortColumnPopupTitle$ = new BehaviorSubject('');
  public isSortPending$ = new BehaviorSubject(false);
  public board$: Observable<Board>;
  public boardScoringType$: Observable<ScoringType>;
  public isSortByScoreVisible$: Observable<boolean>;
  public sortParam: string;

  public columnEditPopup = false;
  public moveTasksPopup = false;
  public columnCopyPopup = false;
  public columnIsMove = false;
  public columnCapacityPopup = false;
  public changeWipLimitsPopup = false;
  public columnCopyPopupTitle = '';
  public moveActionType: 'move' | 'archive';
  public boardTasks$: Observable<Task[]>;
  public boardTasksFiltered$: Observable<Task[]>;

  public hasLimits$;
  public canBeArchived$;
  public movableTasks$;
  public hasSameTypeColumns$;

  public canEditColumn$: Observable<boolean>;
  public currentBoardSwimlanes$: Observable<Swimlane[]>;
  public columnKinds = columnKinds;
  public titleTextWidth = '100%';
  public ScoringType = ScoringType;
  public subs: Subscription[] = [];

  public isUserSubscribedOnColumn$;

  @HostBinding('attr.draggable-disabled')
  get dragAllowed() {
    return this.isColumnMenuVisible ? 'true' : undefined;
  }

  constructor(
    private _routerNav: RouterNavigateService,
    private _store: Store<AppState>,
    private elRef: ElementRef,
    private _permissions: PermissionsService,
    private actions$: Actions,
    private _authService: AuthService,
    private _paywall: PaywallService
  ) {}

  ngOnInit() {
    this.boardTasks$ = this._store.pipe(getBoardTasks, publishReplay(1), refCount());

    this.boardTasksFiltered$ = this.boardId$.pipe(
      switchMap(boardId => this._store.pipe(getTasksByUsersFilter(both(inBoard(boardId), isActiveTask)))),
      publishReplay(1),
      refCount()
    );

    this.board$ = this.boardId$.pipe(
      filter(isPresent),
      distinctUntilChanged(),
      switchMap(id => this._store.select(fromBoards.get(id)))
    );

    this.boardScoringType$ = this.board$.pipe(pluck('scoringType'));

    this.isSortByScoreVisible$ = this.boardScoringType$.pipe(map(type => NonBasicScoringTypes.includes(type)));

    this.isBacklogBoard$ = this.boardId$.pipe(
      filter(isPresent),
      distinctUntilChanged(),
      switchMap(bId => this._store.pipe(isBacklogBoardBuilder(bId)))
    );

    this.isKanbanBoard$ = this.boardId$.pipe(
      filter(isPresent),
      distinctUntilChanged(),
      switchMap(bId => this._store.pipe(isKanbanBoardBuilder(bId)))
    );

    this.columnForWIP$ = this._store.pipe(
      getColumnByIdIfWIPed(this.column.id),
      takeUntil(this.isBacklogBoard$.pipe(filter(isBacklog => !!isBacklog)))
    );

    this.hasLimits$ = this.columnForWIP$.pipe(
      filter(isPresent),
      map((column: Column) => column.minTasksCount || column.maxTasksCount)
    );

    this.hasSameTypeColumns$ = this._store.pipe(
      getBoardColumnCounter,
      pluck(this.column.type),
      map(count => count > 1),
      distinctUntilChanged()
    );

    this.canBeArchived$ = combineLatest(
      this.isBacklogBoard$,
      this.isKanbanBoard$,
      this.hasSameTypeColumns$,
      (isBacklog, isKanban, hasSameColumns) => isBacklog || isKanban || hasSameColumns
    );

    this.canEditColumn$ = this._permissions.canEditColumn(this.column);

    this.currentBoardSwimlanes$ = combineLatest(
      this._store.pipe(getSwimlanesByBoard(this.boardId)),
      this._store.pipe(getBoardTasks)
    ).pipe(
      map(([swimlanes, tasks]) => {
        const columnIds = [this.column.id, ...this.column.subColumnsIds].reduce((acc, item) => {
          acc[item] = true;
          return acc;
        }, {});
        return [swimlanes, tasks.filter(task => columnIds[task.column])];
      }),
      map(([swimlanes, tasks]) => {
        return swimlanes.filter(swimlane => !!tasks.find(task => !task.archived && swimlane.id === task.swimlane));
      })
    );

    this.isUserSubscribedOnColumn$ = this._authService.activeUserId$.pipe(
      map(userId => this.column && this.column.subscribersIds && this.column.subscribersIds.includes(userId)),
      distinctUntilChanged()
    );
  }

  ngOnChanges() {
    this.boardId$.next(this.boardId);
    if (this.column.subColumnsIds.length) {
      this.movableTasks$ = combineLatest(...this.getParamsForCompositeColumn()).pipe(
        map((tasks: Task[]): Task[] => tasks.filter((task: Task): boolean => !task.archived)),
        publishReplay(1),
        refCount()
      );
    } else {
      this.movableTasks$ = this._store.pipe(
        getTasksByColumn(this.column.id),
        map((tasks: Task[]): Task[] => tasks.filter((task: Task): boolean => !task.archived)),
        publishReplay(1),
        refCount()
      );
    }
  }

  onSubscribeToColumn() {
    this.isUserSubscribedOnColumn$.pipe(take(1)).subscribe(subscribed => {
      this._store.dispatch(
        new PatchEntityAction({
          entityName: COLUMN_PL,
          data: {
            id: this.column.id,
            subscribers: {
              add: subscribed ? [] : [this._authService.activeUserId],
              remove: subscribed ? [this._authService.activeUserId] : []
            }
          }
        })
      );
    });
  }

  onToggleArchiveColumn() {
    this.isArchiveColumntPopupVisible = !this.isArchiveColumntPopupVisible;
  }

  onToColumnActions(columnId: number) {
    this.isColumnMenuVisible = !this.isColumnMenuVisible;
  }

  onOpenChangeWipLimitsPopup() {
    this.changeWipLimitsPopup = true;
  }

  onCloseChangeWipLimitsPopup() {
    this.changeWipLimitsPopup = false;
  }

  onOpenColumnEditPopup(column: Column) {
    this.canEditColumn$.pipe(take(1)).subscribe(canEdit => {
      if (canEdit) {
        this.columnEditPopup = true;
      }
    });
  }

  onToggleCreteColumnPopup(columnId: number) {
    this.canEditColumn$.pipe(take(1)).subscribe(canEdit => {
      if (canEdit) {
        this.toggleCreteColumnPopup.emit({ visibility: true, bindingEl: this.elRef, insertAfter: columnId });
      }
    });
  }

  onCloseEditColumnPopup() {
    this.columnEditPopup = false;
  }

  onOpenColumnCopyPopup(isMove?: boolean) {
    if (this._paywall.isFeatureEnabled(Features.CanAddTask)) {
      this.openCopyPopup(isMove);
    } else {
      this._store.pipe(getTaskIdsByColumnObjectWithComposite(this.column), take(1)).subscribe(ids => {
        if (ids.length) {
          this._paywall.showPayWall(Features.CanAddTask);
        } else {
          this.openCopyPopup(isMove);
        }
      });
    }
  }

  openCopyPopup(isMove: boolean) {
    this.columnIsMove = isMove;
    this.columnCopyPopupTitle = this.columnIsMove ? 'Move Column' : 'Copy Column';
    this.columnCopyPopup = true;
  }

  onCloseCopyColumnPopup() {
    this.columnCopyPopup = false;
    this.columnIsMove = false;
  }

  onOpenMoveTasksPopup(moveType: 'move' | 'archive') {
    this.moveActionType = moveType;
    this.moveTasksPopup = true;
  }

  onCloseMoveTasksPopup() {
    this.moveTasksPopup = false;
  }

  onArchiveColumn() {
    this._store.dispatch(new ColumnEditAction({ id: this.column.id, archived: 1 }));
    this.onToggleArchiveColumn();
  }

  onAddCapacity() {
    this.columnCapacityPopup = true;
  }

  onCloseCapacityMenu() {
    this.columnCapacityPopup = false;
    this.isColumnMenuVisible = false;
  }

  onCloseColumnMenu() {
    this.isColumnMenuVisible = false;
  }

  onPushAllTasks() {
    if (this._paywall.isFeatureEnabled(Features.CanAddTask)) {
      this._routerNav.navigateToPushAllTasks(this.column.id);
    } else {
      this._paywall.showPayWall(Features.CanAddTask);
    }
  }

  onConfirmSortColumn() {
    this._store
      .select(fromTask.getByColumnIds([this.column.id, ...(this.column.subColumnsIds || [])]))
      .pipe(
        switchMap((columnTasks: Task[]) => {
          const tasks = Array.isArray(columnTasks) ? columnTasks : [];
          if (!tasks.length) {
            return of([]);
          }
          if (this.sortParam === 'score') {
            return this._store.select(getScoreByTasksFromSameBoard(tasks)).pipe(
              map(scores =>
                scores
                  .map((score, i) => ({ score: score, id: tasks[i].id }))
                  .sort((a, b) => {
                    const isFiniteB = Number.isFinite(b.score),
                      isFiniteA = Number.isFinite(a.score);
                    if (!isFiniteB && !isFiniteA) {
                      return 0;
                    } else if (isFiniteB && isFiniteA) {
                      return b.score - a.score;
                    } else {
                      return isFiniteB ? 1 : -1;
                    }
                  })
                  .map(item => item.id)
              )
            );
          } else {
            return of(tasks.sort((a, b) => b[this.sortParam] - a[this.sortParam]).map(task => task.id));
          }
        }),
        take(1)
      )
      .subscribe((sortedTasksIds: number[]) => {
        this._store.dispatch(new ColumnSortByPriorityAction({ tasks: sortedTasksIds, id: this.column.id }));
        this.isSortPending$.next(true);
        this.subs.push(
          this.actions$
            .ofType(ColumnActionTypes.SORT_FINISHED)
            .pipe(take(1))
            .subscribe(_ => {
              this.isSortPending$.next(false);
              this.isSortColumnVisible$.next(false);
            })
        );
      });
  }

  onToggleSortColumn(sortByYParam?: boolean) {
    if (!this.isSortColumnVisible$.value) {
      this.board$.pipe(take(1)).subscribe((board: Board) => {
        if (!board) {
          return;
        }
        if (board.scoringType === ScoringType.basic) {
          this.sortParam = sortByYParam ? 'backlogScoreY' : 'backlogScoreX';
          this.sortColumnPopupTitle$.next(
            'Sort By ' + (sortByYParam ? board.backlogScoreYLabel : board.backlogScoreXLabel)
          );
          this.isSortColumnVisible$.next(true);
        } else if (NonBasicScoringTypes.includes(board.scoringType)) {
          this.sortParam = 'score';
          this.sortColumnPopupTitle$.next('Sort By Scoring');
          this.isSortColumnVisible$.next(true);
        } else {
          this.isSortColumnVisible$.next(false);
        }
      });
    } else {
      this.isSortColumnVisible$.next(false);
      this.isSortPending$.next(false);
    }
  }

  getParamsForCompositeColumn() {
    const result = [];
    this.column.subColumnsIds.forEach(subColumnId => {
      result.push(this._store.pipe(getTasksByColumn(subColumnId)));
    });
    result.push((...taskArrs) => {
      return flatten(taskArrs);
    });
    return result;
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
