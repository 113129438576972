import { map, pluck } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Company } from '../../../interfaces';
import {
  companyPortalUrl,
  getApiUrl,
  getBitbucketUrl,
  getExperimentalApiUrl,
  getGithubUrl,
  getGitlabUrl,
  getIntercomUrl,
  getJiraUrl,
  getSlackUrl,
  getSocketUrl
} from '../../../../helpers';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { COMPANY_PL, ENTITY_PL } from '../../../constants';
import { Observable } from 'rxjs';
import { getEnvironmentParam, getParamByCompany } from '../../../../environments/helper';

@Injectable()
export class CompanyService {
  private company: Company;
  private companyId: number;

  public currentCompany$: Observable<Company>;
  public companyBlocked$: Observable<boolean>;
  public isActive$: Observable<boolean>;

  constructor(private _store: Store<AppState>) {
    this.company = window['company'] ? window['company'] : getEnvironmentParam('defaultCompany');
    this.companyId = this.company.id;
    this.currentCompany$ = this._store.pipe(
      pluck(COMPANY_PL, ENTITY_PL, String(this.companyId)),
      map((company: Company) => (!!company ? company : this.company))
    );
    this.currentCompany$.subscribe((company: Company) => {
      this.company = company;
    });
    this.companyBlocked$ = this.currentCompany$.pipe(map((company: Company) => !company.isActive));
    this.isActive$ = this.currentCompany$.pipe(map((company: Company) => !!company.isActive));
  }

  get companySize() {
    return this.company.companySize || '';
  }

  get softwarePreferences() {
    return this.company.softwarePreferences || '';
  }

  getApiUrl(): string {
    return getApiUrl(this.company);
  }

  getPortalUrl(): string {
    return companyPortalUrl(this.company);
  }

  getExperimentalApiUrl() {
    return getExperimentalApiUrl(this.company);
  }

  getSocketUrl(): string {
    return getSocketUrl(this.company);
  }

  getSlackUrl(): string {
    return getSlackUrl(this.company);
  }

  getGithubUrl(): string {
    return getGithubUrl(this.company);
  }

  getBitbucketUrl(): string {
    return getBitbucketUrl(this.company);
  }

  getGitlabUrl(): string {
    return getGitlabUrl(this.company);
  }

  getIntercomUrl(): string {
    return getIntercomUrl(this.company);
  }

  getJiraUrl(): string {
    return getJiraUrl(this.company);
  }

  getGoogleDrivePickerUrl(): string {
    return getParamByCompany('googlePickerOrigin')(this.company);
  }

  get isDemo(): boolean {
    return !!this.company.isDemo;
  }

  get id(): number {
    return +this.company.id;
  }

  get createdAt(): number {
    return +this.company.createdAt;
  }

  get object(): string {
    return this.company.object;
  }

  get name(): string {
    return this.company.name;
  }

  get domain(): string {
    return this.company.domain;
  }

  get employeesCount(): number {
    return this.company.employeesCount;
  }

  get weekStartDay(): number {
    return this.company.weekStart;
  }
}
