import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { User } from '../../../../../interfaces';

@Component({
  selector: 'list-board-members',
  templateUrl: './list-board-members.component.html',
  styleUrls: ['./list-board-members.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListBoardMembersComponent implements OnChanges {
  @Input() isNotGuest: boolean;
  @Input() members: User[];

  @Output() edit = new EventEmitter();
  public membersTail: User[];
  constructor() {}

  ngOnChanges() {
    if (this.members && this.members.length > 4) {
      this.membersTail = this.members.slice(3);
    }
  }

  onOpenEditPopup(event) {
    event.stopPropagation();
    this.edit.emit(event.target.getBoundingClientRect());
  }
}
