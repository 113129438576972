<div (click)="onEditToggle()" id="opened-task-title" *ngIf="!isEdit" [ngClass]="{text: true, editable: editPermissions}">
  {{initialValue}}
</div>

<form [formGroup]="inlineForm" (ngSubmit)="onSaveChanges()" *ngIf="editPermissions && isEdit"
      [clickOut]="isEdit"
      (clickOutEvent)="onSaveChanges()">
        <textarea
          autosize
          type="text"
          [focus]="true"
          [hotKeys]="hotKeys"
          (hotKey)="onHotKey()"
          [formControl]="inlineForm.get('name')"
          [selectionRange]="{
            start: inlineForm.get('name').value?.length,
            end: inlineForm.get('name').value?.length
          }"
          #inlineInput
          cols="3" rows="1"
        ></textarea>
</form>
<div>

  <div class="progress" [class.no_top_margin]="isEdit">
    <div class="column_name">
      <a [routerLink]="appUrls.getUrlBoard(task.board)" *ngIf="isOnNoBoardPage; else elseBlock">
        {{taskBreadCrumbs$ | async}}
      </a>
      <ng-template #elseBlock>{{taskBreadCrumbs$ | async}}</ng-template>
    </div>
    <div class="time_in_inprogress">
      <task-time-in-progress [task]="task"></task-time-in-progress>
    </div>
  </div>
</div>
