import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Collection } from '../../../interfaces';
import { PatchQueryService } from '../../../shared/services/patch-query.service';

@Component({
  selector: 'right-menu-collections-list',
  templateUrl: './right-menu-collections-list.component.html',
  styleUrls: ['./right-menu-collections-list.component.scss'],
  providers: [PatchQueryService]
})
export class RightMenuCollectionsListComponent implements OnInit {
  @Input() collections: any[];
  @Input()
  set selectedCollections(selectedCollections: Collection[]) {
    this._selectedCollections = selectedCollections;
  }

  @Input() multiple = true;
  @Input() required = false;

  @Output() onSelectCollectionEvent = new EventEmitter<Collection>();
  @Output() onDeselectCollectionEvent = new EventEmitter<Collection>();

  get selectedCollections() {
    return this._selectedCollections;
  }
  private _selectedCollections: Collection[];

  constructor(private patchQueryService: PatchQueryService) {}

  ngOnInit() {
    this.patchQueryService.clearQuery();
    this.patchQueryService.setInitItemIds(this.selectedCollections);
  }

  reInitComponent() {
    this.ngOnInit();
  }

  onSelectProject(collection: Collection) {
    if (!this.isSelectedCollection(collection)) {
      this.addCollection(collection);
    } else {
      this.removeProject(collection);
    }
  }

  addCollection(collection: Collection) {
    if (!this.multiple) {
      this.selectedCollections.forEach(proj => {
        this.removeProject(proj, true);
      });
    }

    this.selectedCollections.push(collection);
    this.patchQueryService.addItemId(collection.id);
    this.onSelectCollectionEvent.emit(collection);
  }

  removeProject(collection: Collection, forced = false) {
    if (this.required && !forced && this.selectedCollections.length === 1) {
      return false;
    }
    this.selectedCollections.splice(this.getIndexForSelectedCollection(collection), 1);
    this.patchQueryService.removeItemId(collection.id);
    this.onDeselectCollectionEvent.emit(collection);
  }

  isSelectedCollection(selectedCollection: Collection): boolean {
    return this.getIndexForSelectedCollection(selectedCollection) !== -1;
  }

  getIndexForSelectedCollection(collection: Collection): number {
    let collectionIndex = null;

    this.selectedCollections.find(
      (value, key) => (collectionIndex = collection.id === value.id ? key : collectionIndex)
    );

    return collectionIndex !== null ? collectionIndex : -1;
  }

  getCollectionsQuery() {
    return this.patchQueryService.getItemIdsQuery();
  }

  noEmptyCollectionsQuery() {
    return this.patchQueryService.noEmpty();
  }
}
