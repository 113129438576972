
import {fromEvent as observableFromEvent,  Observable ,  BehaviorSubject ,  Subscription } from 'rxjs';

import {withLatestFrom, tap, filter} from 'rxjs/operators';
import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, Renderer2, TemplateRef } from '@angular/core';
import { APortalService } from '../../atlaz-gui/a-portal/a-portal.service';
import { isNotPresent, isPresent } from '../../../helpers';
import { AContextMenuContentComponent } from '../../atlaz-gui/a-popup/a-context-menu-content/a-context-menu-content.component';

@Directive({
  selector: '[contextMenuTrigger]'
})
export class ContextMenuTriggerDirective implements AfterViewInit, OnDestroy {
  @Input() contextMenuAlign: 'left' | 'right' = 'left';
  @Input() contextMenuTrigger: BehaviorSubject<boolean>;
  @Input() contextMenuTemplate: TemplateRef<any>;
  @Input() contextMenuContent: AContextMenuContentComponent;

  private innerTrigger$ = new BehaviorSubject(false);

  get getContextMenuTemplate() {
    return this.contextMenuTemplate || this.contextMenuContent.template;
  }
  subs: Subscription[] = [];

  get contextMenuTrigger$() {
    return this.contextMenuTrigger || this.innerTrigger$;
  }

  constructor(private _elRef: ElementRef, private _portal: APortalService, private _renderer: Renderer2) {}

  ngAfterViewInit() {
    if (this.contextMenuContent) {
      this.subs.push(this.contextMenuContent.close$.subscribe(() => this.contextMenuTrigger$.next(false)));
    }
    this.subs.push(
      this._portal.onHide(this.getContextMenuTemplate).subscribe(() => this.contextMenuTrigger$.next(false))
    );
    this.subs.push(
      this._portal.onShow(this.getContextMenuTemplate).subscribe(() => this.contextMenuTrigger$.next(true))
    );
    this.subs.push(
      this.contextMenuTrigger$.pipe(filter(isPresent)).subscribe(() => {
        this._renderer.addClass(this._elRef.nativeElement, 'active');
      })
    );
    this.subs.push(
      this.contextMenuTrigger$.pipe(filter(isNotPresent)).subscribe(() => {
        this._renderer.removeClass(this._elRef.nativeElement, 'active');
        this._portal.hide(this.getContextMenuTemplate);
      })
    );
    this.subs.push(
      observableFromEvent(this._elRef.nativeElement, 'click').pipe(
        tap((e: MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();
        }),
        withLatestFrom(this.contextMenuTrigger$),)
        .subscribe(([e, visible]) => {
          if (visible) {
            this._portal.hide(this.getContextMenuTemplate);
          } else {
            const rect = this._elRef.nativeElement.getBoundingClientRect();
            this._portal.show(this.getContextMenuTemplate, {
              top: rect.bottom,
              left: this.contextMenuAlign === 'left' ? rect.left : rect.right
            });
          }
          (<MouseEvent>e).preventDefault();
        })
    );
  }

  ngOnDestroy() {
    this.contextMenuTrigger$.next(false);
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
