<ng-container *ngIf="useContextPopUp;else centerPopUp">
  <div a-context-popup
    *ngIf="customField.type !== CustomFieldTypes.date;else dateTypeEdit"
    [title]="customField.name"
    (close)="onClose()"
  >
    <ng-container [ngTemplateOutlet]="editForm"></ng-container>
  </div>
</ng-container>

<ng-template #centerPopUp>
  <a-center-popup
    *ngIf="customField.type !== CustomFieldTypes.date;else dateTypeEdit"
    [title]="customField.name"
    (close)="onClose()"
  >
    <ng-container [ngTemplateOutlet]="editForm"></ng-container>
  </a-center-popup>
</ng-template>

<ng-template #editForm>
  <form [formGroup]="form" (ngSubmit)="onSubmit">
    <form-errors></form-errors>
    <div class="row">
      <label class="label">{{typeLabels[customField.type]}}</label>

      <div [ngSwitch]="customField.type">
        <input *ngSwitchCase="CustomFieldTypes.number" focus="true" type="number" step="1" [formControlName]="'value'">
        <select *ngSwitchCase="CustomFieldTypes.select" focus="true" [formControlName]="'value'">
          <option value=""></option>
          <option *ngFor="let option of options" [value]="option">{{option}}</option>
        </select>
        <textarea *ngSwitchDefault focus="true" type="text" (keydown.control.enter)="onSubmit()" [formControlName]="'value'"></textarea>
      </div>
    </div>

    <button a-button class="btn_primary"
            [pending]="(_formService.isPending$ | async)"
            (click)="onSubmit($event)">
      Save
    </button>
  </form>
</ng-template>

<ng-template #dateTypeEdit>
  <a-date-time-inputless
    [pickerId]="'custom_field_value'"
    id="custom-field-value"
    [formControl]="form.get('value')"
    [defaultDate]="initialFormValue"
    (submitValue)="onSubmit(250)"
    (cancel)="onClose(250)"
  ></a-date-time-inputless>
</ng-template>

