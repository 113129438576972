import { Component, Input, EventEmitter, Output } from '@angular/core';

import { Task, Project } from '../../../interfaces';

import { Store } from '@ngrx/store';
import { ComponentSetVisibleAction } from '../../../ngrx/actions/component.actions';
import { AppState, RIGHT_MENU } from '../../../ngrx/state';

@Component({
  selector: 'archived-task-list',
  templateUrl: './archived-task-list.component.html',
  styleUrls: ['./archived-task-list.component.scss']
})
export class ArchivedTaskListComponent {
  @Input() archivedItems: Task[];

  @Output() unArchiveTaskEvent = new EventEmitter<Task>();
  @Output() deleteTaskEvent = new EventEmitter<Task>();

  public isDeleteTaskVisible = false;
  public deleteArchivedTaskId: number;

  constructor(private _store: Store<AppState>) {}

  onCloseRightMenu() {
    this._store.dispatch(new ComponentSetVisibleAction({ name: RIGHT_MENU, visible: false }));
  }

  onUnArchive(task: Task) {
    this.unArchiveTaskEvent.emit(task);
  }

  onDelete(task: Task) {
    this.deleteTaskEvent.emit(task);
  }

  onToggleDeleteTask(id) {
    this.isDeleteTaskVisible = !this.isDeleteTaskVisible;
    this.deleteArchivedTaskId = id;
  }
}
