<a-context-container>
  <div a-popup [title]="'Sync tasks with due dates to calendar'" (close)="onClose()">
    <div class="popup_content">
      <div class="link-controls">
        <input type="text" #linkInput [value]="exportICalendarUrl">
        <button a-button class="btn_primary pull_right" (click)="onCopyLink()">Copy Link</button>
      </div>
      <ul>
        <li>Once synced, you will see tasks with due dates from that board as all day events</li>
        <li>You can click each event to visit the task in Hygger</li>
        <li>Only tasks with due dates will sync</li>
        <li>Completed tasks will not sync</li>
        <li>Calendar sync is one-way: updates to your calendar will not be reflected in Hygger</li>
      </ul>
      <button a-button class="btn_text" (click)="onClose()">Cancel</button>
    </div>
  </div>
</a-context-container>
