import { createCommonSelectors } from '../../util/store';
import { CUSTOM_FIELD_PL } from '../../constants';
import { Entity } from '../../interfaces';
import { removeOne } from '../functions/reducer';
import { ESInterface } from '../state';
import { CustomFieldActions, CustomFieldActionTypes } from '../actions/custom-field.actions';
import { createSelector } from 'reselect';

const initialState: ESInterface<CustomField> = {
  ids: [],
  entities: {},
  selectedEntityId: null
};

interface State {
  ids: number[];
  entities: { [id: number]: CustomField };
}

export enum CustomFieldTypes {
  select = 'select',
  text = 'text',
  number = 'number',
  date = 'date'
}

export interface CustomField extends Entity {
  name: string;
  description: string;
  type: CustomFieldTypes;
  position: number;
  values: string;
  board: number;
}

export function reducer(state = initialState, action: CustomFieldActions): ESInterface<CustomField> {
  switch (action.type) {
    case CustomFieldActionTypes.DELETE: {
      return removeOne(state, <number>action.payload);
    }

    default: {
      return state;
    }
  }
}

export namespace fromCustomFields {
  export const { getState, getEntities, getIds, getAll, isEmpty } = createCommonSelectors<CustomField, State>(
    CUSTOM_FIELD_PL
  );

  export const getByBoardId = id =>
    createSelector(
      getAll,
      customFields =>
        customFields ? customFields.filter(item => item.board === id).sort((a, b) => a.position - b.position) : []
    );

  export const getById = id =>
    createSelector(getEntities, customFields => (customFields && customFields[id] ? customFields[id] : {}));
}
