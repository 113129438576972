import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ExternalTaskLink } from '../../../interfaces/tasks-links-type';

@Component({
  selector: 'task-link-item',
  templateUrl: './task-link-item.component.html',
  styleUrls: ['./task-link-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskLinkItemComponent implements OnInit {
  @Input() taskLinkId: number;
  @Input() taskId: number;
  @Input() externalLink: ExternalTaskLink;
  @Input() editPermissions: boolean;

  public isContentMenuVisible = false;
  public isDeletePopupVisible = false;

  constructor() {}

  ngOnInit() {}

  onToggleContentMenu() {
    this.isContentMenuVisible = !this.isContentMenuVisible;
  }

  onToggleDeletePopup() {
    this.isDeletePopupVisible = !this.isDeletePopupVisible;
  }
}
