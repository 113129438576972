import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertFileSize'
})
export class ConvertFileSizePipe implements PipeTransform {
  transform(sizeInBytes: string | number, dimension: 'KB' | 'MB') {
    switch (dimension) {
      case 'KB': {
        return +sizeInBytes / 1024;
      }
      case 'MB':
      default: {
        return +sizeInBytes / 1024 / 1024;
      }
    }
  }
}
