import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { taskPopupCodes, TaskSidebarService } from '../../shared/services/task-sidebar.service';
import { getEnvironmentParam } from '../../../../environments/helper';
import { TaskAttachmentsAddAction } from '../../../ngrx/actions/task-attachments.actions';
import { AppState } from '../../../ngrx/state/';
import { Store } from '@ngrx/store';
import { isExternalFileSourcesAvailable } from './config';
import { CompanyService } from '../../../shared/services/app/company.service';
import { PaywallService } from '../../../libs/paywall/paywall.service';
import { Features } from '../../../libs/paywall/features.constants';

@Component({
  selector: 'add-attachments',
  templateUrl: './add-attachments.component.html',
  styleUrls: ['./add-attachments.component.scss']
})
export class AddAttachmentsComponent implements AfterViewInit {
  @Input() taskId: number;
  @Input() editPermissions = false;
  @Input() commentKey = '';
  @Output() close = new EventEmitter();
  static fileExtRE = /\.([^.]+)?$/gi;
  static getFileExt = (name: string): string => name.match(AddAttachmentsComponent.fileExtRE)[0];

  private gDriveWindow;

  constructor(
    private _taskSidebarService: TaskSidebarService,
    private elementRef: ElementRef,
    private _paywall: PaywallService,
    private _companyService: CompanyService,
    private _store: Store<AppState>
  ) {}

  ngAfterViewInit() {
    if (isExternalFileSourcesAvailable) {
      this.addScriptTagForDropboxChooser();
    } else {
      this.onClickAddFile();
    }
  }

  onClose() {
    this.close.emit();
  }

  onClickAddFile() {
    if (this._paywall.isFeatureEnabled(Features.CanUploadAttachment)) {
      this._taskSidebarService.setState(taskPopupCodes.ATTACHMENT, this.commentKey);
      setTimeout(() => {
        this.close.emit();
      });
    } else {
      this._paywall.showPayWall(Features.CanUploadAttachment);
    }
  }

  onClickDropbox() {
    if (this._paywall.isFeatureEnabled(Features.Dropbox)) {
      if (window['Dropbox']) {
        const options = {
          success: files => {
            const fileToSave = {
              task: this.taskId,
              source: 'dropbox',
              bytes: files[0].bytes,
              icon: files[0].icon,
              id: files[0].id,
              link: files[0].link,
              name: files[0].name
            };
            this._store.dispatch(new TaskAttachmentsAddAction({ file: fileToSave, fromComment: this.commentKey }));
            this.close.emit();
          },
          cancel: () => {},
          linkType: 'preview',
          multiselect: false
        };
        window['Dropbox'].choose(options);
      }
    } else {
      this._paywall.showPayWall(Features.Dropbox);
    }
  }

  getClientIdListner = event => {
    try {
      if (JSON.parse(event.data).id === 'getGooglePickerClientId') {
        this.gDriveWindow.postMessage(
          JSON.stringify({ id: 'openGooglePicker', value: getEnvironmentParam('googleClientId') }),
          '*'
        );
      }
    } catch (e) {}
  };
  fileSelectedListener = event => {
    try {
      if (event.origin === this._companyService.getGoogleDrivePickerUrl()) {
        const data = JSON.parse(event.data);
        if (data.id === 'fileSelected') {
          this.closeGoogleDrivePicker();
          this.googlePickerCallBack(JSON.parse(data.item));
        }
      }
    } catch (e) {}
  };

  onClickGoogle() {
    if (!this._paywall.isFeatureEnabled(Features.GoogleDrive)) {
      this._paywall.showPayWall(Features.GoogleDrive);
      return;
    }
    this.closeGoogleDrivePicker();
    window.addEventListener('message', this.getClientIdListner);
    window.addEventListener('message', this.fileSelectedListener);
    const left = screen.width / 2 - 512;
    const top = screen.height / 2 - 318;
    this.gDriveWindow = window.open(
      this._companyService.getPortalUrl() + '/integrations/gdrive/add',
      'Google Drive',
      'modal,resizable,scrollbars,status,width=1024,height=636,left=' + left + ',top=' + top
    );
    if (this.gDriveWindow) {
      setTimeout(() => {
        this.gDriveWindow.addEventListener('beforeunload', () => {
          this.onCloseGoogleDrivePicker();
        });
      });
    }
    window.addEventListener('beforeunload', () => {
      this.closeGoogleDrivePicker();
    });
  }

  closeGoogleDrivePicker() {
    this.onCloseGoogleDrivePicker();
    if (this.gDriveWindow) {
      this.gDriveWindow.close();
    }
  }

  onCloseGoogleDrivePicker() {
    window.removeEventListener('message', this.getClientIdListner);
    window.removeEventListener('message', this.fileSelectedListener);
  }

  googlePickerCallBack(selectedItem) {
    const fileToSave = {
      task: this.taskId,
      source: 'googledrive',
      bytes: selectedItem.sizeBytes,
      icon: selectedItem.iconUrl,
      id: selectedItem.id,
      link: selectedItem.url,
      name: selectedItem.sizeBytes
        ? selectedItem.name
        : selectedItem.name + AddAttachmentsComponent.getFileExt(selectedItem.mimeType)
    };
    this._store.dispatch(new TaskAttachmentsAddAction({ file: fileToSave, fromComment: this.commentKey }));
    this.close.emit();
  }

  addScriptTagForDropboxChooser() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = getEnvironmentParam('dropboxChooserCodeSrc');
    s.dataset.appKey = getEnvironmentParam('dropboxAppKey');
    s.id = 'dropboxjs';
    this.elementRef.nativeElement.appendChild(s);
  }
}
