import { Component, OnInit } from '@angular/core';
import { RouterNavigateService } from '../../shared/services/router-navigate.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'closed-board-popup',
  template: `
    <a-center-popup [title]="'The Board Was Deleted'" (close)="onClosePopup()">
      <p>
        Someone deleted <b>{{boardName}}</b> board. You are not
        able to edit it anymore.
      </p>
      <button a-button class="btn_primary" (click)="onClosePopup()">OK</button>
    </a-center-popup>
  `,
  styleUrls: ['./closed-board-popup.component.scss']
})
export class ClosedBoardPopupComponent implements OnInit {
  public boardName: string;

  constructor(private _routerNav: RouterNavigateService, private _activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.boardName = this._activatedRoute.snapshot.params['boardName'];
  }

  onClosePopup() {
    this._routerNav.deactivatePopupOutlet();
  }
}
