<div class="selected_mode" [ngClass]="{active: (isSelectOpened$ | async)}" (click)="toggleSelect()">
  <span [ngSwitch]="(currentBoardMode$ | async)">
    <span *ngSwitchCase="BoardViewMode.table" class="icon">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
         <path fill-rule="evenodd" clip-rule="evenodd" d="M3 11.25H15V9.75H3V11.25V11.25ZM3 14.25H15V12.75H3V14.25V14.25ZM3 8.25H15V6.75H3V8.25V8.25ZM3 3.75V5.25H15V3.75H3V3.75Z"/>
      </svg>
    </span>
    <span *ngSwitchCase="BoardViewMode.version" class="icon">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8 4.5L10.5 3H3.75V15.75H5.25V10.5H9.45L9.75 12H15V4.5H10.8Z"/>
      </svg>
    </span>
    <span *ngSwitchCase="BoardViewMode.priorityChart" class="icon">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5 5H6.5V10.5H2V11.5H6.5V16H7.5V11.5H13V13L16 11L13 9V10.5H7.5V5H9L7 2L5 5Z"/>
      </svg>
    </span>
    <span *ngSwitchDefault class="icon">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 3.75H2.25C1.8375 3.75 1.5 4.0875 1.5 4.5V13.5C1.5 13.9125 1.8375 14.25 2.25 14.25H4.5C4.9125 14.25 5.25 13.9125 5.25 13.5V4.5C5.25 4.0875 4.9125 3.75 4.5 3.75V3.75ZM15 3.75H12.75C12.3375 3.75 12 4.0875 12 4.5V13.5C12 13.9125 12.3375 14.25 12.75 14.25H15C15.4125 14.25 15.75 13.9125 15.75 13.5V4.5C15.75 4.0875 15.4125 3.75 15 3.75V3.75ZM9.75 3.75H7.5C7.0875 3.75 6.75 4.0875 6.75 4.5V13.5C6.75 13.9125 7.0875 14.25 7.5 14.25H9.75C10.1625 14.25 10.5 13.9125 10.5 13.5V4.5C10.5 4.0875 10.1625 3.75 9.75 3.75V3.75Z"/>
      </svg>
    </span>
  </span>
  <span class="title">
    {{currentBoardModeTitle$ | async}}
  </span>
</div>
<ul class="mode_list" *ngIf="isSelectOpened$ | async" [clickOut]="true" (clickOutEvent)="toggleSelect()">
  <li [ngClass]="{selected: !(currentBoardMode$ | async)}" (click)="setMode()">
    <span class="icon">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 3.75H2.25C1.8375 3.75 1.5 4.0875 1.5 4.5V13.5C1.5 13.9125 1.8375 14.25 2.25 14.25H4.5C4.9125 14.25 5.25 13.9125 5.25 13.5V4.5C5.25 4.0875 4.9125 3.75 4.5 3.75V3.75ZM15 3.75H12.75C12.3375 3.75 12 4.0875 12 4.5V13.5C12 13.9125 12.3375 14.25 12.75 14.25H15C15.4125 14.25 15.75 13.9125 15.75 13.5V4.5C15.75 4.0875 15.4125 3.75 15 3.75V3.75ZM9.75 3.75H7.5C7.0875 3.75 6.75 4.0875 6.75 4.5V13.5C6.75 13.9125 7.0875 14.25 7.5 14.25H9.75C10.1625 14.25 10.5 13.9125 10.5 13.5V4.5C10.5 4.0875 10.1625 3.75 9.75 3.75V3.75Z"/>
      </svg>
    </span>
    {{BoardViewModeTitle[BoardViewMode.board]}}
  </li>
  <li [ngClass]="{selected: (currentBoardMode$ | async) === BoardViewMode.table}" (click)="setMode(BoardViewMode.table)">
    <span class="icon">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
         <path fill-rule="evenodd" clip-rule="evenodd" d="M3 11.25H15V9.75H3V11.25V11.25ZM3 14.25H15V12.75H3V14.25V14.25ZM3 8.25H15V6.75H3V8.25V8.25ZM3 3.75V5.25H15V3.75H3V3.75Z"/>
      </svg>
    </span>
    {{BoardViewModeTitle[BoardViewMode.table]}}
  </li>
  <li [ngClass]="{selected: (currentBoardMode$ | async) === BoardViewMode.version}" (click)="setMode(BoardViewMode.version)" *ngIf="isByVersionAvailable$ | async">
    <span class="icon">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8 4.5L10.5 3H3.75V15.75H5.25V10.5H9.45L9.75 12H15V4.5H10.8Z"/>
      </svg>
    </span>
    {{BoardViewModeTitle[BoardViewMode.version]}}
  </li>
  <li [ngClass]="{selected: (currentBoardMode$ | async) === BoardViewMode.priorityChart}" (click)="setMode(BoardViewMode.priorityChart)" *ngIf="isPriorityChartAvailable$ | async">
    <span class="icon">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5 5H6.5V10.5H2V11.5H6.5V16H7.5V11.5H13V13L16 11L13 9V10.5H7.5V5H9L7 2L5 5Z"/>
      </svg>
    </span>
    {{BoardViewModeTitle[BoardViewMode.priorityChart]}}
  </li>
</ul>

