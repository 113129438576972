
import {take, mapTo, filter} from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Board, Collection } from '../../interfaces';
import { Store } from '@ngrx/store';
import { AppState, LEFT_MENU } from '../../ngrx/state';
import { getActiveBoardsByCollectionId } from '../../ngrx/functions/crossed.selector';
import { RouterNavigateService } from '../../shared/services/router-navigate.service';
import { ActivatedRoute } from '@angular/router';
import { getCollectionById } from '../../ngrx/reducers/collection.reducer';
import { ComponentListenClickoutAction } from '../../ngrx/actions/component.actions';
import { getAllBoards } from '../../ngrx/reducers/board.reducer';

@Component({
  templateUrl: './collections-settings.component.html',
  styleUrls: ['./collections-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollectionsSettingsComponent implements OnInit {
  public collectionId: number;
  public collection$: Observable<Collection>;
  public selectedBoards$: Observable<Board[]>;
  public issetBoards$: Observable<boolean>;

  constructor(
    private _store: Store<AppState>,
    private _routerNav: RouterNavigateService,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.collectionId = +this._route.snapshot.params['collectionId'];
    this.collection$ = this._store.pipe((getCollectionById(this.collectionId)));
    this.selectedBoards$ = <Observable<Board[]>>this._store.select(getActiveBoardsByCollectionId(this.collectionId));
    this.issetBoards$ = this._store.pipe(
      (getAllBoards),
      filter(boards => boards && boards.length > 0),
      mapTo(true),
      take(1),);
  }

  onClosePopup() {
    this._store.dispatch(new ComponentListenClickoutAction({ name: LEFT_MENU, listenClickout: true }));
    this._routerNav.deactivatePopupOutlet();
  }
}
