import { Component } from '@angular/core';

@Component({
  selector: 'header-roadmap-icon-svg',
  template: `
	  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
		  <style>
			  .st0{fill:none;} .st1{fill:#455A64;}
		  </style>
		  <path id="Shape" class="st0" d="M0 0h24v24H0z"/>
		  <path class="st1" d="M2 4h11v4H2V4zm5 6h11v4H7v-4zm9 6h6v4h-6v-4z"/>
	  </svg>
  `
})
export class HeaderRoadmapSvgComponent {}
