import { Action } from '../unsafe-action';

export enum DefaultTasksStatusesFilterActionTypes {
  TOGGLE_STATUS = '[DEFAULT_STATUSES_FILTER] TOGGLE_STATUS',
  RESET = '[DEFAULT_STATUSES_FILTER] RESET'
}

export class DefaultTaskStatusesFilterToggleItemAction implements Action {
  readonly type = DefaultTasksStatusesFilterActionTypes.TOGGLE_STATUS;

  constructor(public payload: string[]) {}
}

export class DefaultStatusesFilterResetAction implements Action {
  readonly type = DefaultTasksStatusesFilterActionTypes.RESET;
}

// prettier-ignore
export type Actions
  = DefaultTaskStatusesFilterToggleItemAction
  | DefaultStatusesFilterResetAction
  ;
