import { tap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { PermissionsService } from '../permissions.service';

@Injectable()
export class SuperUserGuard implements CanLoad, CanActivate {
  constructor(private _permissions: PermissionsService, private _router: Router) {}

  canLoad(route: Route): Observable<boolean> | boolean | Promise<boolean> {
    return this.guardSuperUser();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.guardSuperUser();
  }

  private guardSuperUser() {
    return this._permissions.isSuperUser$.pipe(
      take(1),
      tap(isSuper => (!isSuper ? this._router.navigate(['/403']) : null))
    );
  }
}
