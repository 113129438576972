<form [formGroup]="form">
  <general-error-message *ngIf="_formService.isError$ | async">
    Some thing went wrong while saving your changes.
  </general-error-message>
  <label>{{isStoryPointsMode ? 'Story Points' : 'Time'}}</label>
  <input
    *ngIf="!isStoryPointsMode"
    [focus]="!isStoryPointsMode"
    autocomplete="off"
    type="text"
    placeholder="1w 4d 7h 59m"
    formControlName="time"
    (keydown.enter)="onSubmit()"
  >
  <input
    *ngIf="isStoryPointsMode"
    [focus]="isStoryPointsMode"
    autocomplete="off"
    type="text"
    placeholder="Positive number up to 100"
    formControlName="storyPoints"
    (keydown.enter)="onSubmit()"
  >
  <div class="row">
    <button a-button class="btn_primary save"
            [disabled]="isStoryPointsMode && !form.valid"
            [pending]="(_formService.isPending$ | async) && !deleting"
            (click)="onSubmit()">{{isNewRecord ? 'Add' : 'Save'}}
    </button>
    <button a-button class="btn_secondary remove" *ngIf="!isNewRecord"
            [pending]="(_formService.isPending$ | async) && deleting"
            (click)="onRemove($event)" type="button">Remove
    </button>
  </div>
</form>
