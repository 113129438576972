import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';

import { BacklogChartPageComponent } from './chart-page/backlog-chart-page.component';
import { BacklogChartComponent } from './chart/backlog-chart.component';
import { TaskCircleComponent } from './chart/task-circle/task-circle.component';
import { BacklogChartService } from './backlog-chart.service';
import { D3DragDirective } from './d3-common/d3-drag.directive';
import { D3AxisDirective } from './d3-common/d3-axis.directive';
import { D3AxisResponsivefy } from './d3-common/d3-responsivefy.directive';
import { TooltipComponent } from './chart-tooltip.component';
import { DragTaskCircleComponent } from './chart/drag-task-circle/drag-task-circle.component';
import { TaskListItemComponent } from './task-list-item/task-list-item.component';
import { TaskDragTooltipComponent } from './chart/task-drag-tooltip/task-drag-tooltip.component';
import { D3ClickDirective } from './d3-common/d3-click.directive';
import { AtlazGuiModule } from '../../atlaz-gui/atlaz-gui.module';
import { BacklogInplaceEditComponent } from './backlog-inplace-edit/backlog-inplace-edit.component';
import { ColumnNameComponent } from './column-name/column-name.component';
import { QuickFiltersModule } from '../../containers/header/quick-filters/quick-filters.module';
import { BoardSharedModule } from '../board-shared/board-shared.module';
import { RightMenuModule } from '../../right-menu/right-menu.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    AtlazGuiModule,
    QuickFiltersModule,
    BoardSharedModule,
    RightMenuModule
  ],
  declarations: [
    BacklogChartPageComponent,
    BacklogChartComponent,
    TaskCircleComponent,
    DragTaskCircleComponent,
    TooltipComponent,
    TaskListItemComponent,
    TaskDragTooltipComponent,
    BacklogInplaceEditComponent,

    D3DragDirective,
    D3ClickDirective,
    D3AxisDirective,
    D3AxisResponsivefy,
    ColumnNameComponent
  ],
  providers: [BacklogChartService],
  exports: [BacklogChartPageComponent]
})
export class BacklogChartModule {}
