
import {empty as observableEmpty,  Observable } from 'rxjs';

import {catchError, switchMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AtlazApiV2Service } from './atlaz-api/v2/atlaz-api-v2.service';
import { RouterNavigateService } from './router-navigate.service';

const ONBOARDING = 'onboarding';
@Injectable()
export class NavigateToOnboardingTaskService {
  constructor(private api: AtlazApiV2Service, private _routerNav: RouterNavigateService) {}

  checkTaskToOpen() {
    return this.api
      .get(ONBOARDING).pipe(
      switchMap(
        resp =>
          resp && resp['meta'] && resp['meta']['onboardingTaskToOpen']
            ? this._routerNav.navigateToTask(resp['meta']['onboardingTaskToOpen'])
            : observableEmpty()
      ),
      catchError(err => observableEmpty()),);
  }
}
