import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { Project } from '../../../interfaces';
import { PatchQueryService } from '../../services/patch-query.service';

@Component({
  selector: 'project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
  providers: [PatchQueryService]
})
export class ProjectListComponent implements OnInit {
  @Input() projects: any[];
  @Input()
  set selectedProjects(selectedProjects: Project[]) {
    this._selectedProjects = selectedProjects;
  }

  @Input() searchText: string;
  @Input() multiple = true;
  @Input() required = false;

  @Output() onSelectProjectEvent = new EventEmitter<Project>();
  @Output() onDeselectProjectEvent = new EventEmitter<Project>();

  get selectedProjects() {
    return this._selectedProjects;
  }
  private _selectedProjects: Project[];

  constructor(private patchQueryService: PatchQueryService) {}

  ngOnInit() {
    this.patchQueryService.clearQuery();
    this.patchQueryService.setInitItemIds(this.selectedProjects);
  }

  reInitComponent() {
    this.ngOnInit();
  }

  onSelectProject(project: Project) {
    if (!this.isSelectedProject(project)) {
      this.addProject(project);
    } else {
      this.removeProject(project);
    }
    console.log(this.patchQueryService.getItemIdsQuery(), 'PROJECT IDS QUERY');
  }

  addProject(project: Project) {
    if (!this.multiple) {
      this.selectedProjects.forEach(proj => {
        this.removeProject(proj, true);
      });
    }

    this.selectedProjects.push(project);
    this.patchQueryService.addItemId(project.id);
    this.onSelectProjectEvent.emit(project);
  }

  removeProject(project: Project, forced = false) {
    if (this.required && !forced && this.selectedProjects.length === 1) {
      return false;
    }
    this.selectedProjects.splice(this.getIndexForSelectedProject(project), 1);
    this.patchQueryService.removeItemId(project.id);
    this.onDeselectProjectEvent.emit(project);
  }

  isSelectedProject(selectedProject: Project): boolean {
    return this.getIndexForSelectedProject(selectedProject) != -1;
  }

  getIndexForSelectedProject(project: Project): number {
    let projectIndex = null;

    this.selectedProjects.find((value, key) => (projectIndex = project.id == value.id ? key : projectIndex));

    return projectIndex !== null ? projectIndex : -1;
  }

  getProjectsQuery() {
    return this.patchQueryService.getItemIdsQuery();
  }

  noEmptyProjectsQuery() {
    return this.patchQueryService.noEmpty();
  }
}
