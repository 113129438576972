import { GITLAB_REPOSITORY_PL } from '../../../../constants';
import { getEmptyESState } from '../../../../ngrx/state';
import { createCommonSelectors } from '../../../../util/store';
import { ESGitlabRepository, GitlabRepository } from '../../models/git-repository';
import * as gitlabCompany from '../gitlab-company/gitlab-company.actions';
import * as gitlabRepository from './gitlab-repository.actions';

export interface State extends ESGitlabRepository {
  loading: boolean;
  isGetError: boolean;
}

const initialState: State = {
  ...getEmptyESState<GitlabRepository>(),
  loading: false,
  isGetError: false
};

export function reducer(state = initialState, action: gitlabRepository.Actions | gitlabCompany.Actions): State {
  switch (action.type) {
    case gitlabRepository.GET_INDEX: {
      return { ...state, loading: true, isGetError: false };
    }
    case gitlabRepository.UPDATED: {
      return state;
    }

    case gitlabRepository.GET_INDEX_COMPLETE: {
      return { ...state, loading: false };
    }

    case gitlabCompany.CLEAR_SETTINGS:
    case gitlabCompany.REMOVE_ALL: {
      return initialState;
    }

    case gitlabRepository.GET_INDEX_FAIL: {
      return { ...state, loading: false, isGetError: true };
    }

    default: {
      return state;
    }
  }
}

export const { getState, getEntities, getIds, getAll } = createCommonSelectors<GitlabRepository, State>(
  GITLAB_REPOSITORY_PL
);

export namespace fromGitlabRepository {
  export const isLoading = state => getState(state).loading;
  export const isGetError = state => getState(state).isGetError;
}
