
import {filter} from 'rxjs/operators';
import { Injectable, TemplateRef } from '@angular/core';
import { Subject ,  BehaviorSubject } from 'rxjs';

export interface PortalLayer {
  view: TemplateRef<any>;
  position: any;
}

@Injectable()
export class APortalService {
  public layers$ = new BehaviorSubject([]);
  public layers: PortalLayer[] = [];
  private onShow$ = new Subject<PortalLayer>();
  private onHide$ = new Subject<PortalLayer>();

  show(view, position) {
    const layer = { view, position };
    this.layers = [...this.layers, layer];
    this.layers$.next(this.layers);
    this.onShow$.next(layer);
  }

  hide(view) {
    const layer = this.layers.find(layer => layer.view === view);
    if (layer) {
      this.layers = this.layers.filter(layer => layer.view !== view);
      this.layers$.next(this.layers);
      this.onHide$.next(layer);
    }
  }

  onShow(view: TemplateRef<any>) {
    return this.onShow$.pipe(filter(layer => layer.view === view));
  }

  onHide(view: TemplateRef<any>) {
    return this.onHide$.pipe(filter(layer => layer.view === view));
  }
}
