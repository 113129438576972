<h1 class="pricing_title">
  {{(isUnpaidSubscription$ | async) ? 'Select Hygger Plan' : 'Hygger Pricing'}}
</h1>
<p class="pricing_subtitle">
  {{(isUnpaidSubscription$ | async) ? 'Upgrade your subscription to unlock Hygger features.' : ''}}
</p>

<!--<pricing-plans-->
  <!--[isUnpaidSubscription]="isUnpaidSubscription$ | async"-->
  <!--[proPlan]="proPlan$ | async"-->
  <!--(onUpgrade)="toPaymentDetails($event)"-->
<!--&gt;-->

<!--</pricing-plans>-->
<pricing-plans-v3 [plans]="plans$ | async" [currentSub]="billingSub$ | async" (onUpgrade)="toPaymentDetails($event)"></pricing-plans-v3>
<pricing-page-slider></pricing-page-slider>
<pricing-faq></pricing-faq>
