import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { PageStatus, STATUS_CODES } from '../../../permissions/interfaces/page-status.interface';
import { SegmentService } from '../../../atlaz-bnp/services/intergations/segment/segment.service';
import { getTaskBySlug } from '../../../ngrx/reducers/task.reducer';
import * as fromLoadedPages from '../../../loaded-data/store/loaded-data.reducer';
import { Store } from '@ngrx/store';
import { AppState, OPENED_TASK } from '../../../ngrx/state';
import { of } from 'rxjs/internal/observable/of';
import { defaultExpand, TASK_PL } from '../../../constants';
import { HandleResponseAction } from '../../../ngrx/actions/root.action';
import { SEARCH } from '../../../path.routing';
import { Features } from '../../../libs/paywall/features.constants';
import { AtlazApiV2Service } from '../../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { PaywallService } from '../../../libs/paywall/paywall.service';
import { BugTrackerService } from '@atlaz/core/services/bag-tracker.service';
import { OpenedTaskMarkAsLoadedAction, OpenedTaskOpenAction } from '../../ngrx/actions/opened-task.action';
import { TaskDetailPageRelatedDataService } from '../services/task-detail-page-related-data.service';
import { TaskDeletingWatcherService } from '../services/task-deleting-watcher.service';
import { concat } from 'rxjs/internal/observable/concat';
import { isPresent } from '../../../../helpers';

@Component({
  selector: 'task-permission-pages',
  templateUrl: './task-permission-pages.component.html',
  styleUrls: ['./task-permission-pages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskPermissionPagesComponent implements OnInit {
  public STATUS_CODES = STATUS_CODES;
  public pageStatus$: Observable<PageStatus>;

  constructor(
    private route: ActivatedRoute,
    private _segment: SegmentService,
    private _store: Store<AppState>,
    private _apiV2: AtlazApiV2Service,
    private _paywall: PaywallService,
    private _bugTracker: BugTrackerService,
    private _deletingWatcher: TaskDeletingWatcherService,
    private _detailLoader: TaskDetailPageRelatedDataService
  ) {}

  ngOnInit() {
    this._deletingWatcher.stopWatching();
    const taskSlug = this.route.snapshot.params.taskSlug;
    this.pageStatus$ = this._store.pipe(
      getTaskBySlug(taskSlug),
      take(1),
      withLatestFrom(this._store.select(fromLoadedPages.wasOpenedTaskFn)),
      switchMap(([task, isLoadedFn]) => {
        if (task && task['id']) {
          this._store.dispatch(new OpenedTaskOpenAction(task['id']));
          if (!isLoadedFn(task['id'])) {
            return concat(
              of({ statusCode: STATUS_CODES.OK, taskId: task['id'] }),
              this._detailLoader
                .loadTaskWithContext(task['id'], [
                  'parent',
                  ...defaultExpand[TASK_PL],
                  ...defaultExpand[SEARCH],
                  ...defaultExpand[OPENED_TASK]
                ])
                .pipe(filter(isPresent))
            );
          }
          return of({ statusCode: STATUS_CODES.OK, taskId: task['id'] });
        } else {
          return this.loadTaskRequest(taskSlug);
        }
      }),
      tap(taskStatus => {
        if (taskStatus['taskId']) {
          this._deletingWatcher.startWatching(taskStatus['taskId']);
        }
      }),
      tap(pageStatus => {
        if (pageStatus && pageStatus.statusCode !== 200) {
          this._segment.track('WhoopsOccured', { statusCode: pageStatus.statusCode });
        }
      })
    );
  }

  loadTaskRequest(taskSlug) {
    return this._apiV2
      .get([TASK_PL, taskSlug], {
        expand: ['parent', ...defaultExpand[TASK_PL], ...defaultExpand[SEARCH], ...defaultExpand[OPENED_TASK]]
      })
      .pipe(
        map(resp => {
          const taskId = resp['data']['id'];
          this._store.dispatch(new HandleResponseAction(resp));
          this._store.dispatch(new OpenedTaskOpenAction(taskId));
          this._store.dispatch(new OpenedTaskMarkAsLoadedAction(taskId));
          return {
            statusCode: STATUS_CODES.OK,
            taskId: taskId
          };
        }),
        catchError(err => {
          // by default unknown error;
          let status = err.status;
          if (status === STATUS_CODES.PAYMENT_REQUIRED) {
            let feature = Features.CanAddTask;
            try {
              feature = JSON.parse(err._body).paywall || feature;
            } catch (e) {
              console.log(e);
            }
            this._paywall.showPayWall(feature, true);
          } else if (
            ![STATUS_CODES.FORBIDDEN, STATUS_CODES.NOT_FOUND, STATUS_CODES.CONNECTION_PROBLEM].includes(status)
          ) {
            status = STATUS_CODES.SERVER_ERROR;
            this._bugTracker.warn('TaskDetailResolver, Server error: unknown status', err);
          }
          return of({
            statusCode: status
          });
        })
      );
  }
}
