import { take, map, tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
  Component,
  Input,
  ChangeDetectionStrategy,
  AfterViewInit,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  QueryList,
  OnDestroy,
  OnChanges,
  SimpleChanges
} from '@angular/core';

import * as d3 from 'd3';
import { Board, Task } from '../../../interfaces';
import {
  backlogFields,
  both,
  calculateBacklogScore,
  compareArrays,
  isInsideRect,
  noScoreFields
} from '../../../../helpers';
import { TaskDragging } from '../custom-types/task-dragging';
import { BacklogChartService } from '../backlog-chart.service';
import { RouterNavigateService } from '../../../shared/services/router-navigate.service';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { QuadNames, ScaleShirtValues, ScaleType } from '../../../constants';
import { roundPokerValue } from '../../../../helpers/task';
import { Observable } from 'rxjs/Observable';

const getSvg = () => d3.select('.chart svg');

const basicRange = [-2, 102];
const basicTicks = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
const basicTicksFn = (item: any) => item;
const pokerRange = [-2, 102];
const pokerTicks = [0, 5, 8, 13, 20, 40, 100];
const pokerTicksFn = (item: any) => item;
const shirtRange = [0.9, 5.1];
const shirtTicks = [1, 2, 3, 4, 5];
const shirtTicksFn = (item: number) => ScaleShirtValues[item];

const axisSettings = {
  [ScaleType.basic]: { range: basicRange, ticks: basicTicks, ticksFn: basicTicksFn },
  [ScaleType.poker]: { range: pokerRange, ticks: pokerTicks, ticksFn: pokerTicksFn },
  [ScaleType.shirt]: { range: shirtRange, ticks: shirtTicks, ticksFn: shirtTicksFn }
};

@Component({
  selector: 'backlog-chart',
  templateUrl: './backlog-chart.component.html',
  styleUrls: ['./backlog-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BacklogChartComponent implements AfterViewInit, OnDestroy, OnChanges {
  @ViewChild('svgChart') svgChart: ElementRef;
  @ViewChild('chartContainer') chartContainer: ElementRef;

  @Input() zoomButtons: QueryList<ElementRef>;
  @Input() tasks: Task[];
  @Input() isNotGuest: boolean;
  @Input() groupedTasks: Map<[number, number], number[]>;
  @Input() axisXLabel: string;
  @Input() axisYLabel: string;
  @Input() axisXType: ScaleType;
  @Input() axisYType: ScaleType;
  @Input() board$: Observable<Board>;

  @Output() updateTask = new EventEmitter();

  /**
   * Chart config
   * @type {number}
   */
  public defaultWidth = 900;
  public defaultHeight = 700;
  public margin = { top: 10, right: 20, bottom: 35, left: 35 };

  public currentZoom$ = new BehaviorSubject(1);
  public disabledZoomOut$ = this.currentZoom$.pipe(map(zIndex => zIndex <= 1));
  public disabledZoomIn$ = this.currentZoom$.pipe(map(zIndex => zIndex >= 40));

  public width = this.defaultWidth - this.margin.left - this.margin.right;
  public height = this.defaultHeight - this.margin.top - this.margin.bottom;

  public defaultRectData = [
    { x: 0, y: 0, title: QuadNames[0], id: 'big_bets' },
    { x: this.width / 2, y: 0, title: QuadNames[1], id: 'quick_wins' },
    { x: this.width / 2, y: this.height / 2, title: QuadNames[2], id: 'maybes' },
    { x: 0, y: this.height / 2, title: QuadNames[3], id: 'time_sinks' }
  ];

  public rectData$: Observable<any[]>;

  public backlogLimitsX = [-2, 102];
  public backlogLimitsY = [-2, 102];

  public xScale;
  public xAxis;
  public yScale;
  public yAxis;

  private zoom;

  circleDragProps = {
    filter: () => (this.isNotGuest ? +d3.event.target.getAttribute('draggable') : 0),
    container: () => this.svgChart.nativeElement
  };

  public highlightedTaskKey = '';

  public subs: Subscription[] = [];

  constructor(
    private _cd: ChangeDetectorRef,
    public _backlogChart: BacklogChartService,
    private _routerNav: RouterNavigateService,
    private _route: ActivatedRoute
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const axisTypeChanged =
      (changes['axisXType'] && !changes['axisXType'].firstChange) ||
      (changes['axisYType'] && !changes['axisYType'].firstChange);
    if (axisTypeChanged) {
      this._scaleAxisWithTypes(this.axisXType, this.axisYType);
      const svg = getSvg();
      svg.select('.g-x-axis').call(this.xAxis);
      svg.select('.g-y-axis').call(this.yAxis);
      this.paintAxisesAndTicks();
    }
  }

  ngOnInit() {
    this.rectData$ = this.board$.pipe(
      map(board => board.kvValues),
      distinctUntilChanged(compareArrays),
      map(kvValues => this.defaultRectData.map((rect, i) => ({ ...rect, title: kvValues[i] || rect.title })))
    );
    this._scaleAxisWithTypes(this.axisXType, this.axisYType);
  }

  ngAfterViewInit() {
    // Zoom and move functionality disabled
    // this.draw();
    this.paintAxisesAndTicks();
    this.subs.push(
      this._route.fragment
        .pipe(
          tap(taskKey => {
            this.highlightedTaskKey = taskKey;
            this._cd.detectChanges();
          }),
          debounceTime(2000)
        )
        .subscribe(() => {
          this.highlightedTaskKey = '';
          this._cd.detectChanges();
        })
    );
  }

  private _scaleAxisWithTypes(horizontalType: ScaleType, verticalType: ScaleType) {
    this.backlogLimitsX = axisSettings[horizontalType].range;
    this.backlogLimitsY = axisSettings[verticalType].range;

    this.xScale = d3
      .scaleLinear()
      .domain([...this.backlogLimitsX].reverse())
      .range([-1, this.width]);
    this.xAxis = d3
      .axisBottom(this.xScale)
      .tickValues(axisSettings[horizontalType].ticks)
      .tickFormat(axisSettings[horizontalType].ticksFn);

    this.yScale = d3
      .scaleLinear()
      .domain(this.backlogLimitsY)
      .range([this.height, -3]);
    this.yAxis = d3
      .axisLeft(this.yScale)
      .tickValues(axisSettings[verticalType].ticks)
      .tickFormat(axisSettings[verticalType].ticksFn);
  }

  public draw() {
    const svg = getSvg();
    this.makeZoom(svg);
  }

  /**
   *
   * return function for Uniformity with methods drawAxises, makeResponsivefy, etc
   * @returns {()=>any}
   */
  public makeZoom(svg) {
    this.zoom = d3
      .zoom()
      .filter(this.allowZoom.bind(this))
      .scaleExtent([1, 40])
      .on('zoom', this.onZoom.bind(this));

    return svg.call(this.zoom).on('dblclick.zoom', null);
  }

  trackById(item, index) {
    return item.id;
  }

  paintAxisesAndTicks() {
    getSvg()
      .selectAll('.g-y-axis path')
      .style('stroke', '#B0BEC5');
    getSvg()
      .selectAll('.g-y-axis-label')
      .attr('style', 'fill: #78909C; text-transform: uppercase; font-weight: 400; font-size: 12px;');
    getSvg()
      .selectAll('.g-x-axis-label')
      .attr('style', 'fill: #78909C; text-transform: uppercase; font-weight: 400; font-size: 12px;');
    getSvg()
      .selectAll('.quater_title')
      .attr('style', 'fill: #78909C; text-transform: uppercase; font-weight: 300; font-size: 10px;');
    getSvg()
      .selectAll('.g-x-axis path')
      .attr('style', 'stroke: #B0BEC5; d: path("M 0 1 V 0 H 845.5 V 0"); overflow: hidden;');
    getSvg()
      .selectAll('.g-x-axis text')
      .style('fill', '#B0BEC5');
    getSvg()
      .selectAll('.g-x-axis line')
      .style('stroke', '#B0BEC5');
    getSvg()
      .selectAll('.g-y-axis path')
      .attr('style', 'stroke: #B0BEC5; d: path("M 0 655 H 0 V 0 H 0");');
    getSvg()
      .selectAll('.g-y-axis text')
      .style('fill', '#B0BEC5');
    getSvg()
      .selectAll('.g-y-axis line')
      .style('stroke', '#B0BEC5')
      .attr('y1', '1')
      .attr('y2', '1');
  }

  onDragStart(dragEvent) {
    this._backlogChart.draggingTaskId$.next(+dragEvent.sourceEvent.target.getAttribute('taskId'));
  }

  onDrag(dragEvent) {
    const isInsideInChart = this.isInsideInChart()(dragEvent.sourceEvent);
    const dragHistory = this._backlogChart.getNewDragHistory(isInsideInChart);

    this._backlogChart.backlogDraggingTask$.next(
      Object.assign(
        { d3Event: dragEvent },
        {
          isInsideInChart: this.isInsideInChart()(dragEvent.sourceEvent),
          isInsideInChartHistory: dragHistory
        },
        this.getBacklogValueFromCoordinates(dragEvent.x, dragEvent.y)
      )
    );
  }

  onDragEnd(dragEvent) {
    const updateTask = () => {
      const itemsContainer: any = d3.select('.items_container').node();
      const isInsideItemsContainer = isInsideRect(itemsContainer.getBoundingClientRect())(dragEvent.sourceEvent);
      const isInsideChart = this.isInsideInChart()(dragEvent.sourceEvent);
      const dragHistory = this._backlogChart.backlogDraggingTask$.getValue()['isInsideInChartHistory'];
      const shouldUpdateTask =
        dragHistory[dragHistory.length - 1] === true ||
        (dragHistory.length === 1 && dragHistory[0] === true) ||
        (dragHistory.length > 1 && dragHistory[0] !== dragHistory[dragHistory.length - 1]);

      if (!shouldUpdateTask || (!isInsideItemsContainer && !isInsideChart)) {
        return;
      }
      this.updateDroppedTask(
        this._backlogChart.draggingTaskId$.getValue(),
        this._backlogChart.backlogDraggingTask$.getValue()
      );
    };

    const handleClick = () => {
      this._routerNav.navigateToTask(this._backlogChart.draggingTaskId$.getValue(), { relativeTo: this._route });
    };

    const clearDragData = () => {
      this._backlogChart.draggingTaskId$.next(0);
      this._backlogChart.backlogDraggingTask$.next(null);
      this._backlogChart.closeDialog();
    };

    this._backlogChart.taskWasDragged$
      .pipe(take(1))
      .subscribe(
        wasDragged => (wasDragged ? updateTask() : handleClick()),
        error => console.log(error, 'error while drag'),
        () => clearDragData()
      );
  }

  public onResetZoom() {
    getSvg()
      .transition()
      .duration(250)
      .call(this.zoom.transform, d3.zoomIdentity);
  }

  public onZoomClick(direction = 1) {
    this.zoom.scaleBy(getSvg(), 1 + 0.5 * direction);
  }

  public updateDroppedTask(taskId: number, droppedTask: TaskDragging) {
    const backlogValues = droppedTask.isInsideInChart
      ? backlogFields(droppedTask.backlogScoreX, droppedTask.backlogScoreY, droppedTask.backlogScore)
      : noScoreFields();

    if (this.axisXType === ScaleType.poker) {
      backlogValues.backlogScoreX = roundPokerValue(backlogValues.backlogScoreX, 0);
    }

    if (this.axisYType === ScaleType.poker) {
      backlogValues.backlogScoreY = roundPokerValue(backlogValues.backlogScoreY, 0);
    }

    backlogValues.backlogScoreY = backlogValues.backlogScoreY > 100 ? 100 : backlogValues.backlogScoreY;
    backlogValues.backlogScoreX = backlogValues.backlogScoreX > 100 ? 100 : backlogValues.backlogScoreX;
    backlogValues.backlogScoreY = backlogValues.backlogScoreY < 0 ? 0 : backlogValues.backlogScoreY;
    backlogValues.backlogScoreX = backlogValues.backlogScoreX < 0 ? 0 : backlogValues.backlogScoreX;

    const updateData = Object.assign({ id: taskId }, backlogValues);
    this.updateTask.emit(updateData);
  }

  public isInsideInChart() {
    return both(
      isInsideRect(this.svgChart.nativeElement.getBoundingClientRect()),
      isInsideRect(this.chartContainer.nativeElement.getBoundingClientRect())
    );
  }

  public getBacklogValueFromCoordinates(x: number, y: number) {
    const efforts = Math.round(this.xScale.invert(x));
    const value = Math.round(this.yScale.invert(y));
    const score = calculateBacklogScore(efforts, value);

    return backlogFields(efforts, value, score);
  }

  private onZoom() {
    const svg = getSvg();

    svg.select('.g-zoom').attr('transform', d3.event.transform);

    this.currentZoom$.next(d3.event.transform.k);

    svg.select('.g-x-axis').call(this.xAxis.scale(d3.event.transform.rescaleX(this.xScale)));
    svg.select('.g-y-axis').call(this.yAxis.scale(d3.event.transform.rescaleY(this.yScale)));

    this.paintAxisesAndTicks();
  }

  private allowZoom() {
    return (
      this._backlogChart.draggingTaskId$.getValue() === 0 &&
      [this.chartContainer.nativeElement, ...this.zoomButtons.map(ref => ref.nativeElement)].some(
        el => el === document.activeElement
      )
    );
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  public shouldHighlight(task) {
    return (task.taskKey && task.taskKey === this.highlightedTaskKey) || task.id === +this.highlightedTaskKey;
  }

  onSVGClick(id) {
    if (!this.isNotGuest) {
      this._routerNav.navigateToTask(id, { relativeTo: this._route });
    }
  }
}
