import { filter, pluck } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { isPresent } from '../../../../helpers';
import { RouterNavigateService } from '../../../shared/services/router-navigate.service';
import * as fromBillingSubscription from '@atlaz/billing/store/subscription/subscription.reducer';
import { BillingPublicApiService } from '@atlaz/billing/shared/billing-public-api.service';
import { BillingPlan } from '@atlaz/billing/interfaces/billing-plan';
import { fromBillingPlans } from '@atlaz/billing/store/plan/plan.reducer';
import { BillingPlanName } from '@atlaz/billing/constants/billing-subscription';
import { BillingSubscriptionEntity } from '@atlaz/billing/interfaces/billing-subscription';

@Component({
  selector: 'pricing-page',
  templateUrl: './pricing-page.component.html',
  styleUrls: ['./pricing-page.component.scss']
})
export class PricingPageComponent implements OnInit {
  public usersCount$: Observable<number>;
  public isUnpaidSubscription$: Observable<boolean>;
  public plans$: Observable<{ [planName: string]: BillingPlan }>;
  public currentPlan$: Observable<BillingPlanName>;
  public billingSub$: Observable<BillingSubscriptionEntity>;

  constructor(
    private _store: Store<AppState>,
    private _routerNav: RouterNavigateService,
    private _billingService: BillingPublicApiService
  ) {}

  ngOnInit() {
    this.isUnpaidSubscription$ = this._billingService.canChoosePlan$;

    this.plans$ = this._store.select(fromBillingPlans.getAll);

    this.billingSub$ = this._store
      .select(fromBillingSubscription.getCompanyBillingSubscription)
      .pipe(filter(isPresent));

    this.usersCount$ = this.billingSub$.pipe(pluck('usersCount'));

    this.currentPlan$ = this.billingSub$.pipe(pluck('plan'));
  }

  toPaymentDetails($event) {
    this._routerNav.navigateEditPaymentDetails($event.isMonthly, $event.plan);
  }
}
