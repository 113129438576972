<div a-context-popup [title]="'Add Members'"
     (close)="onCloseMembersPopup()"
     class="members_popup"
>
  <div *ngIf="isShowHelpText" class="help_text">
    <span *ngIf="boardProjects.length === 0">
      You can add all company members to this board.
    </span>
    <span *ngIf="boardProjects.length >= 1">
      You can add the members of <b>{{boardProjectsNameString}}</b> {{boardProjects.length > 1 ? 'projects' : 'project'}} to this board.
    </span>
  </div>
  <right-menu-users-list
    [boardUsers]="boardUsers"
    [boardId]="boardId"
    [users]="users"
    [showInvite]="true"
    (addUser)="addUser($event)"
    (removeUser)="removeUser($event)"
  ></right-menu-users-list>
</div>
