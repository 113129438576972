<a-center-popup
  [title]="'Weighted Scoring'"
  (close)="onClose()"
>
  <form [formGroup]="form">
    <h4 *ngIf="valuesCriteria.length">Values</h4>
    <div class="row" *ngFor="let criterion of valuesCriteria">
      <label class="label">{{criterion.name + ' (' + dataTypeLabels[criterion.dataType] + ')'}}</label>
      <div [ngSwitch]="criterion.dataType">
        <input [focus]="focusOn === criterion.id" *ngSwitchCase="scoringDataType.ZeroTen" type="number" min="0" max="10" step="1" formControlName="{{criterion.id}}" (blur)="onValidateType(scoringDataType.ZeroTen, criterion.id)">
        <select [focus]="focusOn === criterion.id" *ngSwitchCase="scoringDataType.PlanningPoker" formControlName="{{criterion.id}}">
          <option [ngValue]="null"></option>
          <option [ngValue]="0">0</option>
          <option [ngValue]="1">1</option>
          <option [ngValue]="2">2</option>
          <option [ngValue]="3">3</option>
          <option [ngValue]="5">5</option>
          <option [ngValue]="8">8</option>
          <option [ngValue]="13">13</option>
          <option [ngValue]="20">20</option>
          <option [ngValue]="40">40</option>
          <option [ngValue]="100">100</option>
        </select>
        <input [focus]="focusOn === criterion.id" *ngSwitchCase="scoringDataType.GeneralNumber" type="number" step="1" formControlName="{{criterion.id}}" (blur)="onValidateType(scoringDataType.GeneralNumber, criterion.id)">
        <select [focus]="focusOn === criterion.id" *ngSwitchCase="scoringDataType.Sizes" formControlName="{{criterion.id}}">
          <option [ngValue]="null"></option>
          <option [ngValue]="8">S</option>
          <option [ngValue]="13">M</option>
          <option [ngValue]="20">L</option>
          <option [ngValue]="40">XL</option>
          <option [ngValue]="100">XXL</option>
        </select>
        <input [focus]="focusOn === criterion.id" *ngSwitchCase="scoringDataType.USD" type="number" min="0" step="0.01" formControlName="{{criterion.id}}" (blur)="onValidateType(scoringDataType.USD, criterion.id)">
        <select [focus]="focusOn === criterion.id" *ngSwitchCase="scoringDataType.YesNo" formControlName="{{criterion.id}}">
          <option [ngValue]="null"></option>
          <option [ngValue]="100">Yes</option>
          <option [ngValue]="0">No</option>
        </select>
      </div>
    </div>

    <h4 *ngIf="effortsCriteria.length">Efforts</h4>
    <div class="row" *ngFor="let criterion of effortsCriteria">
      <label class="label">{{criterion.name + ' (' + dataTypeLabels[criterion.dataType] + ')'}}</label>
      <div [ngSwitch]="criterion.dataType">
        <input [focus]="focusOn === criterion.id" *ngSwitchCase="scoringDataType.ZeroTen" type="number" min="0" max="10" step="1" formControlName="{{criterion.id}}" (blur)="onValidateType(scoringDataType.ZeroTen, criterion.id)">
        <select [focus]="focusOn === criterion.id" *ngSwitchCase="scoringDataType.PlanningPoker" formControlName="{{criterion.id}}">
          <option [ngValue]="null"></option>
          <option [ngValue]="0">0</option>
          <option [ngValue]="1">1</option>
          <option [ngValue]="2">2</option>
          <option [ngValue]="3">3</option>
          <option [ngValue]="5">5</option>
          <option [ngValue]="8">8</option>
          <option [ngValue]="13">13</option>
          <option [ngValue]="20">20</option>
          <option [ngValue]="40">40</option>
          <option [ngValue]="100">100</option>
        </select>
        <input [focus]="focusOn === criterion.id" *ngSwitchCase="scoringDataType.GeneralNumber" type="number" step="1" formControlName="{{criterion.id}}" (blur)="onValidateType(scoringDataType.GeneralNumber, criterion.id)">
        <select [focus]="focusOn === criterion.id" *ngSwitchCase="scoringDataType.Sizes" formControlName="{{criterion.id}}">
          <option [ngValue]="null"></option>
          <option [ngValue]="8">S</option>
          <option [ngValue]="13">M</option>
          <option [ngValue]="20">L</option>
          <option [ngValue]="40">XL</option>
          <option [ngValue]="100">XXL</option>
        </select>
        <input [focus]="focusOn === criterion.id" *ngSwitchCase="scoringDataType.USD" type="number" min=0 step="0.01" formControlName="{{criterion.id}}" (blur)="onValidateType(scoringDataType.USD, criterion.id)">
        <select [focus]="focusOn === criterion.id" *ngSwitchCase="scoringDataType.YesNo" formControlName="{{criterion.id}}">
          <option [ngValue]="null"></option>
          <option [ngValue]="100">Yes</option>
          <option [ngValue]="0">No</option>
        </select>
      </div>
    </div>

    <h4>Confidence</h4>
    <div class="row">
      <label class="label">0-100, %</label>
      <input [focus]="focusOn === 'confidence'" type="number" min="0" max="100" step="1" formControlName="confidence" (blur)="onValidateType('confidence')">
    </div>

    <div class="buttons_anti_scroll_wrapper">
      <button type="submit" a-button class="btn_primary"
              [pending]="(_formService.isPending$ | async)"
              (click)="onSubmit()">
        Save
      </button>
      <button type="button" a-button class="btn_text"
              (click)="onClose()">
        Cancel
      </button>
    </div>
  </form>
</a-center-popup>
