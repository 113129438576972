import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { RouterNavigateService } from '../../../shared/services/router-navigate.service';

@Injectable()
export class BoardIdGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const board = +next.params['board'];

    if (!board) {
      this._routerNav.navigate([{ outlets: { popup: '404' } }]);
      return true;
    }
    return true;
  }

  constructor(private _routerNav: RouterNavigateService) {}
}
