import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-body',
  template: `
<router-outlet></router-outlet>
  `,
  styleUrls: ['app-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppBodyComponent {}
