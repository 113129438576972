import { debounceTime, distinctUntilChanged, filter, map, pluck, switchMap, take } from 'rxjs/operators';
import { RouterNavigateService } from '../../../services/router-navigate.service';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Board, Project, Swimlane, Task, User } from '../../../../interfaces';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { BOARD_PL, COLUMN_PL, ENTITY_PL, SWIMLANE_PL, TASK_PL, USER_PL } from '../../../../constants';
import { AppState } from '../../../../ngrx/state';
import { fromBoards, getBoardById } from '../../../../ngrx/reducers/board.reducer';
import { getSwimlanesByBoard } from '../../../../ngrx/reducers/swimlane.reducer';
import { GetAction, GetCompleteAction, HandleResponseAction } from '../../../../ngrx/actions/root.action';
import { getTargetColumns } from '../../../../ngrx/reducers/column.reducer';
import { FormComponent, FormSaveType, FormServiceParams, FormV2Service } from '../../../services/form-v2.service';
import { AtlazApiV2Service } from '../../../services/atlaz-api/v2/atlaz-api-v2.service';
import { JsonApiSingeModelResponse, jsonApiToEntityState } from '../../../services/app/web-socket/http-response';
import { arrayIfEmpty, compareArrays, naturalSort, objectIfEmpty } from '../../../../../helpers';
import { UnsavedFormChangesService } from '@atlaz/core/services/unsaved-form-changes.service';
import { TaskAddCompleteAction } from '../../../../ngrx/actions/task.actions';
import { fromUsers } from '../../../../ngrx/reducers/user.reducer';
import { BoardAssignUsersAction } from '../../../../ngrx/actions/board.actions';
import { fromOpenedTask } from '../../../../task/ngrx/reducers/opened-task.reducer';
import { OpenedTaskDataChangedAction } from '../../../../task/ngrx/actions/opened-task.action';
import { GuiStateBatchEditHide, GuiStateQuickTaskEditHide } from '../../../../ngrx/actions/gui-state-memorized.actions';
import * as fromJiraCompanies from '../../../../integrations/settings/jira-settings/ngrx/jira-company.reducer';

export const TASKS_COPY = 'tasks-copy';

export const insertPosition = {
  first: 'first',
  last: 'last'
};

@Component({
  selector: 'task-action-popup',
  templateUrl: './task-action-popup.component.html',
  styleUrls: ['./task-action-popup.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskActionPopupComponent implements OnInit, FormComponent, OnChanges, OnDestroy {
  @Input() task: Task;
  @Input() isBatchActions: boolean;
  @Input() batchTasks: Task[];
  @Input() actionName: 'copy' | 'push' | 'move' | 'create';

  @Output() close = new EventEmitter();
  @Output() creationComplete = new EventEmitter();

  subs: Subscription[] = [];

  public isShowTabs$: Observable<boolean>;
  public isHyggerForm$ = new BehaviorSubject(true);
  public taskUsers$: Observable<User[]>;
  public selectedBoardUsers$: Observable<User[]>;
  public projectOnlyUsers$: Observable<User[]>;
  public selectedUsers: User[] = [];
  public operationConfig = {
    copy: {
      formObserver: {
        next: resp => {
          this._store.dispatch(new GetCompleteAction(jsonApiToEntityState(resp)));
          this._store.dispatch(new GuiStateQuickTaskEditHide(this.task.id));
          this.onClose();
        },

        error: response => {},

        complete: () => {
          this._store.dispatch(new GetAction({ entity: TASK_PL, id: this.task.id }));
        }
      },
      saveType: FormSaveType.add,
      formFieldsBuilder: (task: Task) => (this.isBatchActions ? {} : { title: [task.title, Validators.required] }),
      submitButtonName: 'Copy'
    },
    push: {
      formObserver: {
        next: (task: Task) => {
          this._store.dispatch(new TaskAddCompleteAction(task));
          this.onClose();
        },

        error: response => {},

        complete: () => {
          this._store.dispatch(new GetAction({ entity: TASK_PL, id: this.task.id }));
        }
      },
      saveType: FormSaveType.add,
      formFieldsBuilder: (task: Task) => (this.isBatchActions ? {} : { title: [task.title, Validators.required] }),
      submitButtonName: 'Push'
    },
    move: {
      formObserver: {
        next: resp => {
          this._store.dispatch(new OpenedTaskDataChangedAction({ highlightAfterClosing: false }));
          this._store.dispatch(new GetCompleteAction(jsonApiToEntityState(resp)));
          this._store.dispatch(new GuiStateQuickTaskEditHide(this.task.id));
          if (this.isBatchActions) {
            try {
              if (this.task.board !== this.form.value.board) {
                this._store.dispatch(new GuiStateBatchEditHide());
              }
            } catch (e) {
              console.log('unable to reset batch actions mode');
            }
          }
          this.onClose();
        },

        error: response => {},

        complete: () => {
          this._routerNav.deactivatePopupOutlet();
        }
      },
      saveType: FormSaveType.edit,
      formFieldsBuilder: (task: Task) => ({ id: [task.id] }),
      submitButtonName: 'Move'
    },
    create: {
      formObserver: {
        next: (task: Task) => {
          this._store.dispatch(new TaskAddCompleteAction(task));
          this.creationComplete.emit();
          this.onClose();
        },
        error: () => {},
        complete: () => {
          this._store.dispatch(new GetAction({ entity: TASK_PL, id: this.task.id }));
        }
      },
      saveType: FormSaveType.add,
      formFieldsBuilder: (task: Task) => ({
        title: [task.title, Validators.required],
        users: [[]]
      }),
      submitButtonName: 'Create'
    }
  };

  /**
   * current config by action type(copy, move, push, create)
   */
  public config;

  formServiceParams: FormServiceParams;
  form: FormGroup;

  public boards$: Observable<Board[]>;
  public swimlanes$: Observable<Swimlane[]>;
  public columns$: Observable<{ id: number; text: string }[]>;
  public showSwimlanePicker$: Observable<Boolean>;
  public selectedBoardId$: BehaviorSubject<number>;
  public availablePositions = [
    { name: 'Top', value: insertPosition.first },
    { name: 'Bottom', value: insertPosition.last }
  ];
  public loadedBoards: { [id: string]: boolean } = {};

  get payloadId() {
    return this.batchTasks.map(item => item.id).join(',');
  }
  get payloadIdsArr() {
    return this.batchTasks.map(item => item.id);
  }

  constructor(
    private _store: Store<AppState>,
    private _atlazApi: AtlazApiV2Service,
    private _routerNav: RouterNavigateService,
    private _fb: FormBuilder,
    private _unsavedFormChangesService: UnsavedFormChangesService,
    public _formService: FormV2Service
  ) {
    this._formService = _formService;
  }

  ngOnChanges(simpleChange: SimpleChanges) {
    if (simpleChange.hasOwnProperty('actionName')) {
      this.config = this.operationConfig[this.actionName];
    }
  }

  ngOnInit(): any {
    this.isShowTabs$ = this._store
      .select(fromJiraCompanies.isEmpty)
      .pipe(map(isEmpty => !isEmpty && this.actionName === 'push'));

    this.form = this._fb.group(Object.assign({}, this.getCommonFormFields(), this.config.formFieldsBuilder(this.task)));

    const unsavedFormChangesConfig = {
      allowedFields: ['board', 'swimlane', 'column', 'position'],
      autoSave: false
    };

    this._unsavedFormChangesService.init(this.form, this.unsavedCacheKey(), unsavedFormChangesConfig);

    this.selectedBoardId$ = new BehaviorSubject(+this.form.get('board').value);

    this.subs.push(
      this.form.valueChanges.pipe(debounceTime(100)).subscribe(
        // !important
        // typecast to int boardID, swimlanes store boardId as int value
        // so do columnId
        values => {
          this.selectedBoardId$.next(+values['board']);
        }
      )
    );

    this.boards$ = <Observable<Board[]>>this._store.select(fromBoards.getAllAvailable);

    this.swimlanes$ = this.selectedBoardId$.pipe(
      distinctUntilChanged(),
      switchMap(boardId => {
        return this._store.pipe(getSwimlanesByBoard(boardId));
      })
    );

    this.showSwimlanePicker$ = this.swimlanes$.pipe(map(swimlanes => swimlanes.length > 1));

    this.columns$ = this.selectedBoardId$.pipe(
      distinctUntilChanged(),
      switchMap(boardId => this._store.pipe(getTargetColumns(boardId, 'position')))
    );

    this.formServiceParams = {
      saveType: this.config.saveType,
      entityToEdit: this.actionName === 'create' ? TASK_PL : TASKS_COPY,
      formObserver: this.config.formObserver,
      prepareFormValue: formValue => {
        if (!this.isBatchActions || this.actionName === 'create') {
          return formValue;
        }
        if (this.actionName === 'move') {
          return { ...formValue, id: this.payloadId };
        }
        const result = { ...formValue, tasks: this.payloadIdsArr };
        delete result['task'];
        return result;
      }
    };

    this._formService.initFormParams(this.form, this.formServiceParams);

    // set default value of swimlane
    this.subs.push(
      this.swimlanes$.subscribe(swimlanes => {
        if (swimlanes.length > 0) {
          // to int
          const selectedSwimlane = +this.form.value.swimlane;
          if (!swimlanes.some(swimlane => selectedSwimlane === swimlane.id)) {
            this.form.get('swimlane').patchValue(swimlanes[0].id);
          }
        }
      })
    );

    // set default value of column
    this.subs.push(
      this.columns$.subscribe(columns => {
        if (columns.length > 0) {
          // to int
          const selectedColumn = +this.form.value.column;
          if (!columns.some(column => selectedColumn === column.id)) {
            this.form.get('column').patchValue(columns[0].id);
          }
        }
      })
    );

    this.subs.push(this.selectedBoardId$.subscribe(id => this.loadBoard(id)));

    this.taskUsers$ = this._store.pipe(
      select(fromOpenedTask.getTaskUsers),
      distinctUntilChanged(),
      map(naturalSort('fullname')),
      take(1)
    );

    this.selectedBoardUsers$ = this.selectedBoardId$.pipe(
      switchMap(boardId => this._store.pipe(getBoardById(boardId))),
      map(objectIfEmpty),
      pluck('usersIds'),
      map(arrayIfEmpty),
      distinctUntilChanged(compareArrays),
      switchMap(ids =>
        this._store.pipe(
          pluck(USER_PL, ENTITY_PL),
          distinctUntilChanged(),
          map(userEntities => ids.map(id => userEntities[id]))
        )
      ),
      map(naturalSort('fullname'))
    );

    this.taskUsers$.pipe(filter(_ => this.actionName === 'create')).subscribe(users => {
      if (!this.task.title.match(/(^|\s)@[\S]/g)) {
        this.selectedUsers = [...users];
        this.form.get('users').setValue(this.selectedUsers.map(user => '@' + user.nickname));
      }
    });

    this.projectOnlyUsers$ = this._store
      .select(fromOpenedTask.getProject)
      .pipe(
        map((project: Project) => (project && project.usersIds ? project.usersIds : [])),
        switchMap(projUsersIds =>
          this.selectedBoardUsers$.pipe(
            map(users => users.map(user => user.id)),
            map((boardUsersIds: number[]) => projUsersIds.filter(projUserId => !boardUsersIds.includes(projUserId)))
          )
        ),
        switchMap(ids => this._store.select(fromUsers.getByIds(ids))),
        map(naturalSort('fullname'))
      );
  }

  onAddUserToBoard(user: User) {
    this._store.dispatch(new BoardAssignUsersAction({ id: this.task.board, users: { add: [user.id] } }));
  }

  onSubmit() {
    if (this.form.valid) {
      if (this.form.get('board').value === this.task.board && this.actionName === 'move') {
        this.formServiceParams.entityToEdit = TASK_PL;
        this.formServiceParams.prepareFormValue = this.prepareTaskDND;
      }
      if (this.actionName === 'create') {
        if (this.form.get('title').value) {
          this.formServiceParams.prepareFormValue = this.prepareTaskToCreate;
        }
      }

      this._unsavedFormChangesService.save();
      this._formService.submit();
    }
    return false;
  }

  onClose() {
    this.close.emit();
  }

  onUpdateUserSelection(userListUpdate) {
    this.selectedUsers = userListUpdate.value;
    this.form.get('users').setValue(this.selectedUsers.map(user => '@' + user.nickname));
  }

  loadBoard(boardId) {
    if (this.loadedBoards[boardId] || !boardId) {
      return;
    }
    this._atlazApi
      .get(BOARD_PL, { id: boardId, expand: [COLUMN_PL, SWIMLANE_PL, USER_PL] })
      .subscribe((resp: JsonApiSingeModelResponse<any>) => {
        this.loadedBoards[boardId] = true;
        this._store.dispatch(new HandleResponseAction(resp));
      });
  }

  public getCommonFormFields() {
    return {
      board: [this.task.board],
      swimlane: [this.task.swimlane],
      column: [this.task.column],
      position: [insertPosition.first],
      task: [this.task.id],
      action: this.actionName
    };
  }

  private prepareTaskDND = formValue => {
    formValue = { ...formValue };
    if (this.isBatchActions) {
      formValue.id = this.payloadId;
    }
    if (formValue.position === insertPosition.first) {
      formValue.insertBeforeTask = 0;
    }
    delete formValue['action'];
    delete formValue['position'];
    delete formValue['board'];
    delete formValue['task'];
    return formValue;
  };

  private prepareTaskToCreate = formValue => {
    formValue = {
      column: formValue.column,
      title: formValue.title + ' ' + formValue.users.join(' '),
      swimlane: formValue.swimlane,
      inSwimlanePosition: formValue.position
    };
    return formValue;
  };

  private unsavedCacheKey() {
    const defaultText = 'task-action';
    return [defaultText, this.actionName, this.task.board].join(':');
  }

  public onShowForm(i) {
    this.isShowTabs$.pipe(take(1)).subscribe(isShowTabs => {
      if (isShowTabs) {
        i === 1 ? this.isHyggerForm$.next(true) : this.isHyggerForm$.next(false);
      }
    });
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
