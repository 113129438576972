<div class="popup_wrap">
  <div class="popup_header">
    <close-button (click)="onClose()"></close-button>
  </div>
  <div class="popup_form">
    <div class="ico_send_invite"></div>
    <p>The invitation has been sent to<br><a [href]="mailto">{{email}}</a></p>
  </div>
</div>
<overlay (click)="onClose()"></overlay>
