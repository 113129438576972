import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Piece } from './smart-text.component';

@Component({
  selector: 'piece',
  templateUrl: './piece.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PieceComponent {
  @Input() pieces: Piece[];
  @Input() taskLinksOnly = false;
  @Output() smartTaskLinkUpdated = new EventEmitter();

  onSmartTaskLinkUpdated() {
    this.smartTaskLinkUpdated.emit();
  }
}
