import { Observable } from 'rxjs';

import { ChangeDetectionStrategy, Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import {
  DefaultTimeOnColumnFilterResetAction
} from '../../../ngrx/actions/task-filters/default-time-on-column-filter.actions';
import { getDefaultTimeOnColumnFilterDaysRange } from '../../../ngrx/reducers/task-filters/default-time-on-column-filter.reducer';
import { DaysRange } from "../../../ngrx/actions/task-filters/task-default-filter.actions";

@Component({
  selector: 'default-time-on-column-filter',
  templateUrl: './default-time-on-column-filter.component.html',
  styleUrls: ['./default-time-on-column-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultTimeOnColumnFilterComponent implements OnInit {
  @Input() boardId$: Observable<number>;

  public storeTimeOnColumnFilterValue$: Observable<DaysRange>;
  public showTimeOnColumnPopUp = false;

  constructor(private _store: Store<AppState>) {}

  ngOnInit(): void {
    this.storeTimeOnColumnFilterValue$ = this._store.pipe(getDefaultTimeOnColumnFilterDaysRange);
  }

  onShowTimeOnColumnPopup() {
    this.showTimeOnColumnPopUp = true;
  }

  onHideTimeOnColumnPopup() {
    this.showTimeOnColumnPopUp = false;
  }

  onResetFilter() {
    this._store.dispatch(new DefaultTimeOnColumnFilterResetAction());
  }
}
