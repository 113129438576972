import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CompanyService } from '../../app/company.service';
import { AuthService } from '../../app/auth.service';
import { isObject } from '../../../../../helpers';
import { RoleProject } from '../../../../permissions/interfaces/roles';
import { JsonApiModelsResponse, JsonApiSingeModelResponse } from '../../app/web-socket/http-response';
import { HttpRequestErrorsService } from '../../../../atlaz-bnp/http-request-errors/http-request-errors.service';
import { PaywallService } from '../../../../libs/paywall/paywall.service';

export type ResponseParser = <T>(res: HttpResponse<any>) => T;

export const patchPath = (path, tail?): any[] => {
  path = Array.isArray(path) ? [...path] : [path];
  if (tail) {
    path.push(tail);
  }
  return path;
};

const pathFromArray = (slugs: any[]) => {
  const clearSlugs = [];
  const params = {};
  slugs.forEach(slug => {
    isObject(slug) ? Object.assign(params, slug) : clearSlugs.push(slug);
  });
  const strParams =
    '?' +
    Object.keys(params)
      .map(
        key =>
          Array.isArray(params[key])
            ? key === 'expand'
              ? key + '=' + encodeURIComponent(params[key].join(','))
              : params[key].map(value => key + '[]=' + encodeURIComponent(value)).join('&')
            : key + '=' + encodeURIComponent(params[key])
      )
      .join('&');
  return clearSlugs.join('/') + (strParams.length > 1 ? strParams : '');
};

export const noParse = res => res;

export const changeProjectParser = (payload: { user: { id: number; role: RoleProject } }) => {
  return [{ op: 'replace', path: `/users/${payload.user.id}`, value: { role: payload.user.role } }];
};

export const commonPatchParser = payload =>
  Object.keys(payload).reduce((acc, prop) => {
    const value = payload[prop];
    let replaceValue = true;
    if (isObject(value)) {
      if (Array.isArray(value['add'])) {
        replaceValue = false;
        value['add'].forEach(v => acc.push({ op: 'add', path: '/' + prop + '/-', value: v }));
      }
      if (Array.isArray(value['remove'])) {
        replaceValue = false;
        value['remove'].forEach(v => acc.push({ op: 'remove', path: '/' + prop + '/' + v }));
      }
    }
    if (replaceValue) {
      acc.push({ op: 'replace', path: '/' + prop, value: value });
    }

    return acc;
  }, []);

// if you need remove one key
export const keyRemoveParser = payload => {
  return [{ op: 'remove', path: '/' + payload.removeKey }];
};

export const getByIdsPath = (entity: string, ids: number[], controller: string) => {
  return controller + '/' + ids.join(';') + '/' + entity;
};

/**
 * @experimental
 *
 * @version it's draft version for task
 *
 * should be full implemented in the future
 * @link https://hq.atlaz.io/boards/1(popup:tasks/65867)
 */
@Injectable()
export class AtlazApiV2Service {
  constructor(
    private _http: HttpClient,
    private _companyService: CompanyService,
    private _authService: AuthService,
    private _errors: HttpRequestErrorsService,
    private _paywall: PaywallService
  ) {}

  private catcher = (method: string, payload?: any) => response => {
    payload ? console.warn('API', method.toUpperCase(), payload) : console.warn('API', method.toUpperCase());
    console.log('API RESPONSE: ', JSON.stringify(response));
    if (
      response.status === 402 &&
      (response && response.error && response.error.paywall) &&
      this._paywall.getFeatureByName(response.error.paywall)
    ) {
      this._paywall.showPayWall(response.error.paywall);
    }

    if (method.toUpperCase() !== 'GET') {
      this._errors.notifyUser(response);
    }

    return observableThrowError(response);
  };

  public get<T extends JsonApiModelsResponse<any> | JsonApiSingeModelResponse<any>>(
    path: any[] | string,
    params?: any
  ): Observable<T>;

  public get(path: any[] | string, params?: any) {
    params = params || {};
    path = patchPath(path);
    const queryParams = Object.assign({}, params);

    if (queryParams.hasOwnProperty('id')) {
      path.push(queryParams.id);
      delete queryParams.id;
    }
    path.push(queryParams);
    const url = this.createUrl(path, queryParams);
    return this._http
      .get(
        url,
        this.getOptions({
          'Content-Type': 'application/json',
          'X-HTTP-Method-Override': 'GET'
        })
      )
      .pipe(catchError(this.catcher('get')));
  }

  public post(path, payload: any = {}) {
    const url = this.createUrl(path);

    return this._http
      .post(
        url,
        payload,
        this.getOptions({
          'Content-Type': 'application/json',
          'X-HTTP-Method-Override': 'POST'
        })
      )
      .pipe(catchError(this.catcher('post', payload)));
  }

  public patch(path, payload: any = {}, parseResponseFn: ResponseParser = noParse, parseRequestFn = commonPatchParser) {
    const url = this.createUrl(path, payload);
    if (payload.hasOwnProperty('id')) {
      payload = { ...payload };
      delete payload.id;
    }

    const patchPayload = parseRequestFn(payload);

    return this._http
      .patch(
        url,
        patchPayload,
        this.getOptions({
          'Content-Type': 'application/json-patch+json',
          'X-HTTP-Method-Override': 'PATCH'
        })
      )
      .pipe(catchError(this.catcher('patch', patchPayload)), map(parseResponseFn));
  }

  public deleteRequest(path: string | any[]) {
    const url = this.createUrl(path);

    return this._http
      .delete(
        url,
        this.getOptions({
          'Content-Type': 'application/json',
          'X-HTTP-Method-Override': 'DELETE'
        })
      )
      .pipe(catchError(this.catcher('delete')));
  }

  private getOptions(customHeaders = {}) {
    const headers = new HttpHeaders({
      'X-XSRF-TOKEN': this._authService.xsrfToken,
      'Atlaz-API-Version': '2016-02-29',
      ...customHeaders
    });
    return { headers: headers, withCredentials: true };
  }

  /**
   * @param path {string | Array} - Path for http request
   * @param payload? {Object} - Object is used only for getting an Id of entity
   * @param payload.id? {number | string} - entity Id
   * @returns {string}
   *
   * @example request all
   * // returns https://a.atlaz.com/api/v2/tasks?access_token=NDNta...
   * createUrl('tasks');
   *
   * @example request item
   * // returns https://a.atlaz.com/api/v2/tasks/some_url/1?access_token=NDNta...
   * createUrl(['tasks', 'some_url'], {id: 1});
   *
   * @example request item with params
   * // returns https://a.atlaz.com/api/v2/tasks/1?param1=value&access_token=NDNta...
   * createUrl(['tasks', {param1: 'value'}], {id: 1});
   *
   * @example request item with params and expanded data
   * // returns https://a.atlaz.com/api/v2/tasks/1?param1=value&expand=users,boards&access_token=NDNta...
   * createUrl(['tasks', {param1: 'value', expand: ['users', 'boards']}], {id: 1});
   *
   * @example request item with params and expanded data
   * // returns https://a.atlaz.com/api/v2/tasks/1?param1=1%2C2&expand=users&access_token=NDNta...
   * createUrl(['tasks', {param1: [1, 2], expand: 'users'}], {id: 1});
   */
  private createUrl(path: any[] | string, payload = {}): string {
    const queryParams = {
      access_token: this._authService.accessToken
    };

    if (payload['id']) {
      path = patchPath(path, payload['id']);
    }

    path = patchPath(path, queryParams);

    return this._companyService.getExperimentalApiUrl() + '/' + pathFromArray(path);
  }
}
