<div [ngClass]="{sidebar_block: true, editable: editPermissions}">
  <div [ngClass]="{project_overlay_link: true, editable: editPermissions}" (click)="onOpenPointsPopup()"></div>
  <div class="task_sidebar_edit_link" (click)="onOpenPointsPopup()">
    Edit
  </div>
  <h5 class="sidebar_block_title">STORY POINTS</h5>

  <div class="points_value">{{task.storyPoints}}</div>

  <sidebar-estimate-popup
    *ngIf="isPointsPopupVisible"
    [task]="task"
    [isStoryPointsMode]="true"
    (close)="onCloseEstimatePopup()"
    (resetFocus)="onChildChangedFocus()"
  ></sidebar-estimate-popup>

</div>
<div class="divider"></div>
