<input class="quick_search_swimlanes"
       [focus]="true"
       placeholder="Search Swimlane..."
       #input_seacrh_swimlanes
       (keyup)="0"
>
<div class="swimlanes_container">
  <div *ngFor="let swimlane of swimlanes | searchByField: 'name': input_seacrh_swimlanes.value"
       class="swimlane_title"
       (click)="onSetFilter(swimlane)"
  >
    <p>{{swimlane.name}}</p>
    <span class="check_label" *ngIf="checkSelectedSwimlane[swimlane.id]"></span>
  </div>
</div>
