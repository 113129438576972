<ng-container *ngFor="let integration of integrationList">
  <integration-label
      (click)="onOpenIntegration(integration.name)"
      class="menu_integration_label"
      [integrationName]="integration.name"
      [srcPath]="integration.logo"
      [text]="integration.label"
      [width]="'48'"
      [height]="'48'"
      [boardId]="boardId"
  >
  </integration-label>
  <ng-template [ngTemplateOutlet]="divider"></ng-template>
</ng-container>
