/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE9, IE10 and IE11 requires all of the following polyfills. **/
import 'core-js/es6/symbol';
import 'core-js/es6/object';
import 'core-js/es6/function';
import 'core-js/es6/parse-int';
import 'core-js/es6/parse-float';
import 'core-js/es6/number';
import 'core-js/es6/math';
import 'core-js/es6/string';
import 'core-js/es6/date';
import 'core-js/es6/array';
import 'core-js/es7/array';
import 'core-js/es6/regexp';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es7/object';

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
import 'classlist.js'; // Run `npm install --save classlist.js`.

/** IE10 and IE11 requires the following to support `@angular/animation`. */
import 'web-animations-js'; // Run `npm install --save web-animations-js`.

/** Evergreen browsers require these. **/
import 'core-js/es6/reflect';
import 'core-js/es7/reflect';

/** ALL Firefox browsers require the following to support `@angular/animation`. **/
/**  it's pollyfill for web animations, it's require by many browsers, Chrome hasn't fully support it yet */
import 'web-animations-js/web-animations-next.min.js'; // Run `npm install --save web-animations-js`.

import 'operators';

/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js/dist/zone'; // Included with Angular CLI.

import * as moment from 'moment-mini-ts';
import { Observable } from 'rxjs';
import { objectToArray } from './helpers';

// it's required to ng2-datetime-compoent
window['moment'] = moment;
import './scroll-into-view-if-needed.polyfill';

import * as scrollPolyfill from 'smoothscroll-polyfill';
scrollPolyfill.polyfill();

/***************************************************************************************************
 * Intersection Observer polyfill for Safari 6+, Edge, IE7+
 */
import 'intersection-observer';

/***************************************************************************************************
 * APPLICATION IMPORTS
 */

/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
import { getEnvironmentParam } from './environments/helper';

(window as any).global = window;

const passedArguments = args => {
  const res = [];
  for (let i = 0; i < args.length; i++) {
    res.push(args[i]);
  }
  return res;
};
export let lastErrorContext = null;

const walkTree = (currentOperator, result = []) => {
  result.push(currentOperator.operator ? currentOperator.operator : currentOperator);
  if (currentOperator.source) {
    return walkTree(currentOperator.source, result);
  }
  return result;
};

objectToArray(Object.keys(Observable.prototype)).forEach(operator => {
  const originalFunction = Observable.prototype[operator];
  Observable.prototype[operator] = function() {
    try {
      return originalFunction.apply(this, arguments);
    } catch (e) {
      lastErrorContext = {
        ThrowOn: operator,
        arguments: arguments,
        operatorsTree: walkTree(this),
        component:
          this && this._ref && this._ref.context && this._ref.context.constructor ? this._ref.context.constructor : null
      };
      console.warn(operator, passedArguments(arguments));
      throw e;
    }
  };
});

let showConsoleAnyway = false;
try {
  showConsoleAnyway = !!localStorage.getItem('AtlazDevtoolsActions');
} catch (e) {
  // nothing
}
try {
  if (localStorage.getItem('muteConsoleLog') !== null) {
    showConsoleAnyway = true;
  }
} catch (e) {
  // nothing
}
if (!showConsoleAnyway && getEnvironmentParam('muteConsoleLog')) {
  window.console.log = function() {};
}

export const createNewEvent = (eventName: string) => {
  let event;
  if (typeof Event === 'function') {
    event = new Event(eventName);
  } else {
    event = document.createEvent('Event');
    event.initEvent(eventName, true, true);
  }
  return event;
};

if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype['matchesSelector'] ||
    Element.prototype['mozMatchesSelector'] ||
    Element.prototype.msMatchesSelector ||
    Element.prototype['oMatchesSelector'] ||
    Element.prototype.webkitMatchesSelector ||
    function(s) {
      const matches = (this.document || this.ownerDocument).querySelectorAll(s);
      let i = matches.length;
      while (--i >= 0 && matches.item(i) !== this) {}
      return i > -1;
    };
}

(function(e) {
  e.closest =
    e.closest ||
    function(css) {
      let node = this;

      while (node) {
        if (node.matches(css)) return node;
        else node = node.parentElement;
      }
      return null;
    };
})(Element.prototype);
