import { map, take, tap } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AppState } from '../../../ngrx/state';
import {
  DefaultStatusesFilterResetAction,
  DefaultTaskStatusesFilterToggleItemAction
} from '../../../ngrx/actions/task-filters/default-statuses-filter.actions';
import { boardType, columnTypes } from '../../../constants';
import { getDefaultStatusesFilterIds } from '../../../ngrx/reducers/task-filters/default-statuses-filter.reducer';
import { BoardFilterInfo } from '../../../interfaces/board';

@Component({
  selector: 'default-statuses-filter',
  templateUrl: './default-statuses-filter.component.html',
  styleUrls: ['./default-statuses-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultStatusesFilterComponent implements OnInit, OnDestroy {
  @Input() boardInfo$: Observable<BoardFilterInfo>;
  subs: Subscription[] = [];

  public columnTypes = columnTypes;
  public boardStatuses = [
    { type: columnTypes.todo, name: 'To Do' },
    { type: columnTypes.inprogress, name: 'In Progress' },
    { type: columnTypes.done, name: 'Done' }
  ];

  public boardStatuses$;
  public selectedStatuses$: Observable<{ type: string; name: string }[]>;
  public selectedStatusesTypes$: Observable<string[]>;
  public showStatusesPopUp = false;

  constructor(private _store: Store<AppState>) {}

  ngOnInit(): void {
    this.boardStatuses$ = this.boardInfo$.pipe(
      map(
        boardInfo =>
          boardInfo && boardInfo.type === boardType.list
            ? [this.boardStatuses[0], this.boardStatuses[2]]
            : this.boardStatuses
      )
    );
    this.selectedStatuses$ = this._store.pipe(
      getDefaultStatusesFilterIds,
      map((selectedTypes: string[]) => {
        return {
          selectedTypes: selectedTypes,
          statuses: this.boardStatuses.filter(status => selectedTypes.includes(status.type))
        };
      }),
      tap(({ selectedTypes, statuses }) => {
        if (selectedTypes.length && !statuses.length) {
          this._store.dispatch(new DefaultStatusesFilterResetAction());
        }
      }),
      map(({ statuses }) => statuses)
    );

    this.selectedStatusesTypes$ = this.selectedStatuses$.pipe(map(statuses => statuses.map(status => status.type)));
  }

  onAddStatus(status) {
    this.selectedStatusesTypes$.pipe(take(1)).subscribe(types => {
      this._store.dispatch(new DefaultTaskStatusesFilterToggleItemAction([...types, status.type]));
    });
  }
  onRemoveStatus(status) {
    this.selectedStatusesTypes$.pipe(take(1)).subscribe(types => {
      this._store.dispatch(new DefaultTaskStatusesFilterToggleItemAction(types.filter(type => type !== status.type)));
    });
  }

  onShowStatusesPopup() {
    this.showStatusesPopUp = true;
  }
  onHideStatusesPopup() {
    this.showStatusesPopUp = false;
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
