import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { compose } from '@ngrx/store';
import { getEntitySelector } from '../../functions/selectors';
import { DEFAULT_VERSIONS_FILTER } from '../../state';
import * as defaultVersionsFilter from '../../actions/task-filters/default-versions-filter.actions';
import { DefaultTasksVersionsFilterActionTypes } from '../../actions/task-filters/default-versions-filter.actions';
import { TaskDefaultFilterActionTypes } from '../../actions/task-filters/task-default-filter.actions';

export interface DefaultVersionsFilter {
  versionsIds: number[];
}

const initState: DefaultVersionsFilter = {
  versionsIds: []
};

export function reducer(state = initState, action: defaultVersionsFilter.Actions): DefaultVersionsFilter {
  switch (action.type) {
    case DefaultTasksVersionsFilterActionTypes.TOGGLE_ITEM_ID: {
      let versionsIds: number[];
      versionsIds = action['payload'];
      state = Object.assign({}, state, { versionsIds: versionsIds });
      return state;
    }

    case DefaultTasksVersionsFilterActionTypes.RESET: {
      return initState;
    }

    case TaskDefaultFilterActionTypes.SET: {
      if (action['payload'].activeFilters.defaultVersionsFilter) {
        state = { versionsIds: [...action['payload'].activeFilters.defaultVersionsFilter] };
      } else {
        state = initState;
      }
      return state;
    }

    default: {
      return state;
    }
  }
}

const isActive = (state$: Observable<DefaultVersionsFilter>) => state$.pipe(map(state => state.versionsIds.length > 0));
const getVersionsIds = (state$: Observable<DefaultVersionsFilter>) => state$.pipe(map(state => state.versionsIds));

export const getDefaultVersionsFilterEntity = getEntitySelector(DEFAULT_VERSIONS_FILTER);
export const isActiveDefaultVersionsFilter = compose(isActive, getDefaultVersionsFilterEntity);
export const getDefaultVersionsFilterIds = compose(getVersionsIds, getDefaultVersionsFilterEntity);
