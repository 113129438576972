import { Component } from '@angular/core';
import { AppUrls } from '../../../app-urls';
import { AuthService } from '../../services/app/auth.service';

@Component({
  selector: 'a-not-found',
  templateUrl: './a-not-found.component.html',
  styleUrls: ['../40x-page.component.scss']
})
export class NotFoundComponent {
  public toOverview = AppUrls.getUrlOverview();
  public overViewAvailable$ = this._auth.userInActiveCompany$;
  constructor(private _auth: AuthService) {}
}
