import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

import { User, Group, Entity } from '../../../interfaces';
import { PatchQueryService } from '../../services/patch-query.service';

@Component({
  selector: 'member-list-with-group-list',
  template: `
    <div style="margin-left: 10px; margin-bottom: -15px"><h4>Members</h4></div>
    <p></p>
    <p  *ngFor="let member of memberItems | usersNameFilter: searchText">
      <span (click)="onSelectMember(member)">{{member.fullname}}</span>
      <span *ngIf="isSelectedMember(member)">&nbsp;&nbsp;&nbsp;&nbsp;&#10003;</span>
    </p>
    <hr>
    <div style="margin-left: 10px; margin-bottom: -15px"><h4>Groups</h4></div>
    <p  *ngFor="let group of groups | searchByField: 'name': searchText">
      <span (click)="onSelectGroup(group)">{{group.name}}</span>
      <span *ngIf="isSelectedGroup(group)">&nbsp;&nbsp;&nbsp;&nbsp;&#10003;</span>
    </p>
  `,
  providers: [PatchQueryService],
  styleUrls: ['./member-list.scss']
})
export class MemberListWithGroupListComponent implements OnChanges {
  @Input() members: User[];
  @Input() selectedMembers: User[] = [];

  @Input() groups: Array<Object> = [];
  @Input() selectedGroups: Group[] = [];

  @Input() searchText = '';
  @Input() filterByMembers: User[] = [];

  @Output() onSelectMemberEvent = new EventEmitter<User>();
  @Output() onDeselectMemberEvent = new EventEmitter<User>();

  @Output() onSelectGroupEvent = new EventEmitter<Object>();
  @Output() onDeselectGroupEvent = new EventEmitter<Object>();

  public memberItems = new Array<User>();

  public patchQueryServiceMembers: PatchQueryService;
  public patchQueryServiceGroups: PatchQueryService;

  constructor() {
    this.patchQueryServiceMembers = new PatchQueryService();
    this.patchQueryServiceGroups = new PatchQueryService();
  }

  ngOnChanges(): void {
    this.setInitItemMembersIds(this.selectedMembers);
    this.setInitItemGroupsIds(this.selectedGroups);
    this.selectedMembers = this.selectedMembers || [];
    this.filterMembers();
  }

  filterMembers(): void {
    const membersToFilterIds = [];

    this.filterByMembers.forEach(member => membersToFilterIds.push(member.id));

    this.memberItems =
      membersToFilterIds.length > 0
        ? this.members.filter(member => membersToFilterIds.indexOf(member.id) === -1)
        : this.members;
  }

  reInitComponent(): void {
    this.clearMembersQuery();
    this.clearGroupsQuery();
    this.ngOnChanges();
  }

  onSelectMember(member: User): void {
    if (!this.isSelectedMember(member)) {
      this.addMember(member);
    } else {
      this.removeMember(member);
    }
    // console.log(this.patchQueryService.getItemIdsQuery(), 'MEMBER IDS QUERY');
  }

  onSelectGroup(group: Group): void {
    if (!this.isSelectedGroup(group)) {
      this.addGroup(group);
    } else {
      this.removeGroup(group);
    }
  }

  addMember(member: User): void {
    this.selectedMembers.push(member);
    this.patchQueryServiceMembers.addItemId(member.id);
    this.onSelectMemberEvent.emit(member);
  }

  removeMember(member: User): void {
    this.selectedMembers.splice(this.getIndexForSelected(member, this.selectedMembers), 1);
    this.patchQueryServiceMembers.removeItemId(member.id);
    this.onDeselectMemberEvent.emit(member);
  }

  addGroup(group: Group): void {
    this.selectedGroups.push(group);
    this.patchQueryServiceGroups.addItemId(group.id);
    this.onSelectGroupEvent.emit(group);
  }

  removeGroup(group: Group): void {
    this.selectedGroups.splice(this.getIndexForSelected(group, this.selectedGroups), 1);
    this.patchQueryServiceGroups.removeItemId(group.id);
    this.onDeselectGroupEvent.emit(group);
  }

  isSelectedMember(selectedMember: User): boolean {
    return this.getIndexForSelected(selectedMember, this.selectedMembers) !== -1;
  }

  isSelectedGroup(selectedGroup: Group): boolean {
    return this.getIndexForSelected(selectedGroup, this.selectedGroups) !== -1;
  }

  getIndexForSelected(object: Entity, selectedList: Entity[]): number {
    let index = null;

    selectedList.find((value, key) => (index = object.id === value.id ? key : index));

    return index !== null ? index : -1;
  }

  getMembersQuery(): { add: number[]; remove: number[] } {
    return this.patchQueryServiceMembers.getItemIdsQuery();
  }

  getGroupsQuery(): { add: number[]; remove: number[] } {
    return this.patchQueryServiceGroups.getItemIdsQuery();
  }

  noEmptyMembersQuery(): boolean {
    return this.patchQueryServiceMembers.noEmpty();
  }

  noEmptyGroupsQuery(): boolean {
    return this.patchQueryServiceGroups.noEmpty();
  }

  clearMembersQuery(): void {
    this.patchQueryServiceMembers.clearQuery();
  }

  clearGroupsQuery(): void {
    this.patchQueryServiceGroups.clearQuery();
  }

  setInitItemMembersIds(items: User[]): void {
    this.patchQueryServiceMembers.setInitItemIds(this.selectedMembers);
  }

  setInitItemGroupsIds(items): void {
    this.patchQueryServiceGroups.setInitItemIds(items);
  }

  removeAllMembers(): void {
    this.selectedMembers = [];
    this.patchQueryServiceMembers.removeAllAddedItems();
  }

  removeAllGroups(): void {
    this.selectedGroups = [];
  }
}
