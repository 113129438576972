import { Component } from '@angular/core';

@Component({
  selector: 'rolling-loader-svg',
  template: `
	  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" class="uil-ring-alt">
		  <path fill="none" class="bk" d="M0 0h100v100H0z"/>
		  <circle cx="50" cy="50" r="40" stroke="#e0e5e8" fill="none" stroke-width="6" stroke-linecap="round"/>
		  <circle cx="50" cy="50" r="40" stroke="#1E88E5" fill="none" stroke-width="6" stroke-linecap="round">
			  <animate attributeName="stroke-dashoffset" dur="2s" repeatCount="indefinite" from="0" to="502"/>
			  <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"/>
		  </circle>
	  </svg>
  `
})
export class RollingLoaderSvgComponent {}
