import { Action } from './unsafe-action';
import { GetGetParams, HttpQueryParam, PartOfEntity, Project } from '../../interfaces';
import { PROJECT } from '../../constants';
import { generateActionList, labelAction, ReduxActionType } from '../functions/action';
import { RoleProject } from '../../permissions/interfaces/roles';

interface ProjectActionType extends ReduxActionType {
  ASSIGN_USERS: string;
  GET_GET: string;
  CHANGE_ROLE: string;
}

export const customProjectActionType = {
  ASSIGN_USERS: labelAction(PROJECT, 'ASSIGN_USERS'),
  GET_GET: labelAction(PROJECT, 'GET_GET'),
  CHANGE_ROLE: labelAction(PROJECT, 'CHANGE_ROLE')
};

export const ProjectActionTypes = <ProjectActionType>generateActionList(PROJECT, customProjectActionType);

export class ProjectGetAction implements Action {
  type = ProjectActionTypes.GET;

  constructor(public payload?: HttpQueryParam) {}
}

export class ProjectGetGetAction implements Action {
  type = ProjectActionTypes.GET_GET;

  constructor(public payload: GetGetParams) {}
}

export class ProjectGetCompleteAction implements Action {
  type = ProjectActionTypes.GET_COMPLETE;

  constructor(public payload: Project[]) {}
}

export class ProjectEditAction implements Action {
  type = ProjectActionTypes.EDIT;
  constructor(public payload: PartOfEntity) {}
}

export class ProjectEditCompleteAction implements Action {
  type = ProjectActionTypes.EDIT_COMPLETE;

  constructor(public payload: Project) {}
}

export class ProjectDeleteAction implements Action {
  type = ProjectActionTypes.DELETE;

  constructor(public payload: number) {}
}

export class ProjectDeleteCompleteAction implements Action {
  type = ProjectActionTypes.DELETE_COMPLETE;

  constructor(public payload?: any) {}
}

export class ProjectAddAction implements Action {
  type = ProjectActionTypes.ADD;

  constructor(public payload: PartOfEntity) {}
}

export class ProjectAddCompleteAction implements Action {
  type = ProjectActionTypes.ADD_COMPLETE;

  constructor(public payload: Project) {}
}

export class ProjectAssignUsersAction implements Action {
  type = ProjectActionTypes.ASSIGN_USERS;

  constructor(public payload: { id: number; users: { add?: number[]; remove?: number[] } }) {}
}

export class ProjectChangeRoleAction implements Action {
  type = ProjectActionTypes.CHANGE_ROLE;

  constructor(
    public payload: {
      id: number;
      requestPayload: {
        user: { id: number; role: RoleProject };
      };
      queryParams: HttpQueryParam;
    }
  ) {}
}

export type ProjectActions =
  | ProjectGetAction
  | ProjectGetCompleteAction
  | ProjectEditAction
  | ProjectEditCompleteAction
  | ProjectDeleteAction
  | ProjectDeleteCompleteAction
  | ProjectAddAction
  | ProjectAddCompleteAction
  | ProjectAssignUsersAction;
