import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'a-center-popup',
  templateUrl: './a-center-popup.component.html',
  styleUrls: ['./a-center-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ACenterPopupComponent {
  @Input() title: string;
  @Input() className = '';
  @Output() close: EventEmitter<any> = new EventEmitter();

  onClose() {
    this.close.emit(null);
  }
}
