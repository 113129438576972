import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Board, Project, User } from '../../interfaces';
import { AppState } from '../../ngrx/state/app-state';
import { BoardAssignUsersAction, BoardEditAction } from '../../ngrx/actions/board.actions';
import { PermissionsService } from '../../permissions/permissions.service';
import { Observable } from 'rxjs/Observable';
import { BoardInProjectAccess } from '../../interfaces/board';
import { map, take } from 'rxjs/operators';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';

@Component({
  selector: 'right-menu-members',
  templateUrl: './right-menu-members.component.html',
  styleUrls: ['./right-menu-members.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightMenuMembersComponent implements OnChanges, OnInit {
  @Input() users: User[];
  @Input() boardProjects: Project[];
  @Input() boardUsers: User[];
  @Input() boardId: number;
  @Input() selectedBoard$: Observable<Board>;

  public isMembersPopupVisible = false;
  public boardEditPermissions$: Observable<boolean>;
  public isPublicBoard$: Observable<boolean>;
  public canManageAccess$: Observable<boolean>;

  constructor(private _store: Store<AppState>, private _permissions: PermissionsService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('boardId') && changes['boardId'].currentValue !== changes['boardId'].previousValue) {
      this.boardEditPermissions$ = this._permissions.canEditBoard(changes['boardId'].currentValue);
    }
  }

  ngOnInit() {
    this.isPublicBoard$ = this.selectedBoard$.pipe(
      map((board: Board) => board && board.access === BoardInProjectAccess.public)
    );

    this.canManageAccess$ = combineLatest(
      this._permissions.isSomeOfProjectsAdmin$((this.boardProjects || []).map(project => project.id)),
      this._permissions.isSuperUser$
    ).pipe(map(([a, b]) => a || b));
  }

  onOpenMemberPopup() {
    this.isMembersPopupVisible = true;
  }

  onCloseMembersPopup() {
    this.isMembersPopupVisible = false;
  }

  onUsersChanged(users) {
    const dataToUpdate = {
      id: this.boardId,
      users
    };
    this._store.dispatch(new BoardAssignUsersAction(dataToUpdate));
  }

  onRemoveUserFromBoard(userId: number) {
    this._store.dispatch(new BoardAssignUsersAction({ id: this.boardId, users: { remove: [userId] } }));
  }

  onChangeBoardAccess() {
    this.isPublicBoard$.pipe(take(1)).subscribe(isPublic => {
      this._store.dispatch(
        new BoardEditAction({
          id: this.boardId,
          access: isPublic ? BoardInProjectAccess.private : BoardInProjectAccess.public
        })
      );
    });
  }

  public onPreventClick(event) {
    event.stopPropagation();
  }
}
