
import {timer as observableTimer,  BehaviorSubject ,  Observable ,  Observer ,  Subject } from 'rxjs';

import {publishReplay, switchMapTo, catchError, retry, switchMap, tap, refCount} from 'rxjs/operators';
import { QueryParams } from '../../interfaces';

export interface Paginator {
  loadMore: () => void;
  hasMore: boolean;
  pending: boolean;
}

/**
 * @example please see frontend/src/app/shared/services/app/web-socket/data-sync.service.ts#DataSyncService.loadDiff
 * @param requestFn function that load data from Http by query parameters
 * @param queryParams query parameters: {param: '', limit: 5, offset: 0}
 * @param iterationObserver: Observer that works with data from request for each iteration
 * @param pendingObserver: Observer that works with stream that emits TRUE before send request and FALSE after receiving response
 */
export const partialLoader = <T extends any[]>(
  requestFn: (queryParams) => Observable<T>,
  queryParams: QueryParams,
  iterationObserver: Observer<T>,
  pendingObserver: Observer<boolean>
): void => {
  const params$ = new BehaviorSubject(queryParams);
  const pending$ = new Subject();
  pending$.subscribe(pendingObserver);

  const mainStream$ = params$.pipe(
    tap(_ => pending$.next(true)),
    switchMap(params =>
      requestFn(params).pipe(catchError((err, stream$) =>
        observableTimer(5000).pipe(
          switchMapTo(stream$),
          retry(0),)
      ))
    ),
    tap(_ => pending$.next(false)),
    publishReplay(1),
    refCount(),);

  mainStream$.subscribe(iterationObserver);

  mainStream$.subscribe(
    result => {
      console.log('ITERATION COMPLETE');
      if (result.length === queryParams.limit) {
        params$.next({ ...queryParams, offset: +params$.getValue().offset + +queryParams.limit });
      } else {
        params$.complete();
      }
    },
    error => console.log(error),
    () => {
      console.log('ALL PARTS FROM SERVER WAS LOADED');
      pending$.complete();
    }
  );
};
