import { AppPages } from '../../constants';
import { AppState } from '../../ngrx/state';
import { createSelector } from 'reselect';
import { getProperty } from '../../../helpers/';
import * as loadedData from './loaded-data.actions';

interface BooleanMap {
  [id: string]: boolean;
  [id: number]: boolean;
}

interface State {
  openedTask: BooleanMap;
  openedBoard: BooleanMap;
  boardAssets: BooleanMap;
  projectVersions: BooleanMap;
  deletedUsers: boolean;
}

export const LOADED_DATA = 'loadedData';
export const BOARD_ASSETS = 'boardAssets';
export const PROJECT_VERSIONS = 'projectVersions';

const initialState: State = {
  openedTask: {},
  openedBoard: {},
  boardAssets: {},
  projectVersions: {},
  deletedUsers: false
};

function addDataToLoaded(state, entityName: string, entityId: number) {
  const newState = { ...state };
  newState[entityName] = { ...newState[entityName] };
  newState[entityName][entityId] = true;

  return newState;
}

function removeDataFromLoaded(state, entityName: string, entityId: number) {
  const newState = { ...state };
  newState[entityName] = { ...newState[entityName] };
  newState[entityName][entityId] = undefined;

  return newState;
}

export function reducer(state = initialState, action: loadedData.Actions): State {
  switch (action.type) {
    case loadedData.ADD_LOADED: {
      return addDataToLoaded(state, action.payload.name, action.payload.id);
    }
    case loadedData.REMOVE_LOADED: {
      return removeDataFromLoaded(state, action.payload.name, action.payload.id);
    }
    case loadedData.ADD_LOADED_TASK: {
      return addDataToLoaded(state, AppPages.Task, action.payload.taskId);
    }
    case loadedData.ADD_LOADED_BOARD: {
      return addDataToLoaded(state, AppPages.Board, action.payload.boardId);
    }
    case loadedData.REMOVE_LOADED_TASK: {
      return removeDataFromLoaded(state, AppPages.Task, action.payload.taskId);
    }
    case loadedData.REMOVE_LOADED_BOARD: {
      return removeDataFromLoaded(state, AppPages.Board, action.payload.boardId);
    }
    case loadedData.ADD_LOADED_DELETED_USERS: {
      return { ...state, deletedUsers: true };
    }
    case loadedData.LOADED_DATA_RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

const getProp = <K extends keyof State>(key: K) => (state: State) => getProperty(state, key);
export const getState = (store: AppState) => <State>store[LOADED_DATA];
export const isLoaded = (name: string, id: string | number) => createSelector(getState, state => state[name][id]);
export const isNotLoaded = (name: string, id: string | number) => createSelector(isLoaded(name, id), v => !v);
export const getOpenedTasks = createSelector(getState, getProp(AppPages.Task));
export const getOpenedBoards = createSelector(getState, getProp(AppPages.Board));

export const wasOpenedTaskFn = createSelector(getOpenedTasks, tasks => id => tasks[id]);
export const wasOpenedBoardFn = createSelector(getOpenedBoards, board => id => board[id]);

export const wasLoadedDeletedUsers = createSelector(getState, state => state.deletedUsers);
