<div class="header">
  <div class="title_part">
    <div class="back_arrow" [routerLink]="boardUrl$ | async"></div>
    <div class="title">
      <div class="board_name" [routerLink]="boardUrl$ | async">{{(board$ | async)?.name}}</div>
      <div class="report_title">
        <h4>Velocity Report</h4>
        <select *ngIf="(availableOptions$ | async)?.length > 1" class="report_type" (change)="onReportTypeChange()" [(ngModel)]="reportType" checkTouch>
          <option *ngFor="let type of (availableOptions$ | async)" [value]="type">{{estimationTypeLabels[type]}}</option>
        </select>
        <span *ngIf="(availableOptions$ | async)?.length === 1">
          {{estimationTypeLabels[(availableOptions$ | async)[0]]}}
        </span>
      </div>
    </div>
  </div>
  <div class="legend_link" [ngClass]="{'hovered': isShowHelpText}" (click)="onToggleHelp()">
    <span class="icon_quote"></span>
    How to Read this Report?
    <span class="icon_expand" [ngClass]="{'expanded': isShowHelpText}"></span>
  </div>
</div>
<div class="content">
  <div class="legend_body" [ngClass]="{'expanded': isShowHelpText}">
    <p>Track the amount of work completed from sprint to sprint. This helps you to determine your team's velocity and estimate the work your team can likely achieve in future sprints.</p>
    <p>If you release sprints with different estimation settings, you’ll see a dropdown menu at the top left corner. Use this menu to switch between story points and hours modes. You’ll only see sprints with estimation settings that correspond to the selected mode.</p>
    <p>Sprints with estimation settings set to 'Story Points and Hours' will be visible in any mode.</p>
  </div>
  <div class="chart-content">
    <velocity-report-d3
      [type$]="reportType$"
      [chartData$]="chartData$"
    >
    </velocity-report-d3>
    <div  class="velocity_stats">
      <h5>Average Velocity</h5>
      <div>
        <p>Per week <span>{{(averageValues$ | async)?.perWeek}}</span></p>
        <p>Per sprint <span>{{(averageValues$ | async)?.perSprint}}</span></p>
      </div>
    </div>
  </div>
</div>
