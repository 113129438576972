export const SWIMLANE_NAME_DEFAULT = 'new Swimlane';
export const DEFAULT_TITLE_TABS = 'Hygger - Project Management Software for Agile Teams';
export const ACTIVE_IMPORT = 'activeImport';
export const CURRENT_COMPANY = 'currentCompany';
export const ROADMAP_BOARD = 'roadmapBoard';

export enum AppPages {
  Task = 'openedTask',
  Board = 'openedBoard'
}

export enum LoadedData {
  DeletedUsers = 'deletedUsers'
}

export * from './activity-type';
export * from './board-type';
export * from './column-kinds';
export * from './column-types';
export * from './repeat-types';
export * from './entity';
export * from './entities';
export * from './default-expand';
export * from './attachments-types';
export * from './key-code';
export * from './form-statuses';
export * from './notification-type';
export * from './estimation-type';
