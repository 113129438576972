import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: '[a-context-popup]',
  templateUrl: './a-context-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AContextPopupComponent {
  @Input() title: string;
  @Input() setFocus = true;
  @Input() className = '';

  @Output() close: EventEmitter<any> = new EventEmitter();

  onCancelContextPopup() {
    this.close.emit(null);
  }
}
