import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Features } from './features.constants';
import { AppState } from '../../ngrx/state';
import { Store } from '@ngrx/store';
import { fromCompanyFeatures } from '../../ngrx/reducers/company-features.reducer';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { CompanyFeatures, CompanyFeaturesMap } from '../../interfaces/company-features';

@Injectable()
export class PaywallService {
  private enabledFeatures;

  private goBackOnHide = false;
  private goToOverviewOnBack = false;

  constructor(private _store: Store<AppState>, private _location: Location, private _router: Router) {
    this._store
      .select(fromCompanyFeatures.get)
      .pipe(
        map(
          (companyFeatures: CompanyFeatures) =>
            companyFeatures && companyFeatures.features ? companyFeatures.features : {}
        )
      )
      .subscribe((features: CompanyFeaturesMap) => (this.enabledFeatures = features));
  }

  public visiblePayWall$ = new BehaviorSubject('');
  public welcomeBackPayWall$ = new BehaviorSubject(false);

  showPayWall(feature, goBackOnHide = false) {
    if (goBackOnHide) {
      this.goBackOnHide = goBackOnHide;
      this.goToOverviewOnBack = !this._router.navigated;
    }
    this.visiblePayWall$.next(feature);
  }

  showWelcomeBack() {
    this.welcomeBackPayWall$.next(true);
  }

  hideAndGoBack() {
    if (this.goToOverviewOnBack) {
      this._router.navigate(['/']).then(() => this.visiblePayWall$.next(''));
    } else if (this.goBackOnHide) {
      this._location.back();
      this.visiblePayWall$.next('');
    } else {
      this.visiblePayWall$.next('');
    }
  }

  hide() {
    this.visiblePayWall$.next('');
    this.welcomeBackPayWall$.next(false);
  }

  public isFeatureEnabled(feature): boolean {
    return !!this.enabledFeatures[feature];
  }

  public getFeatureByName(name) {
    return Object.keys(Features).find(feature => Features[feature] === name);
  }
}
