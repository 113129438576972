import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';

import { CompanyService } from '../../../shared/services/app/company.service';
import { IntegrationAuthAdapterService } from './integration-auth-adapter.service';
import * as bitbucketCompany from '../../git/store/bitbucket-company/bitbucket-company.actions';

@Injectable()
export class BitbucketAuthAdapterService extends IntegrationAuthAdapterService {
  constructor(companyService: CompanyService, private _store: Store<AppState>) {
    super(companyService);
    this.authUrl = companyService.getBitbucketUrl();
    this.popupWindowTitle = 'Bitbucket Setup';
    this.windowSize = 'width=1000,height=400,left=100,top=100';
  }

  protected receiveMessage(event: MessageEvent) {
    // Do we trust the sender of this message?  (might be
    // different from what we originally opened, for example).
    if (super.receiveMessage(event) === false) {
      return false;
    }
    const companyId = super.getCompanyId(event.data);
    if (companyId) {
      this._store.dispatch(new bitbucketCompany.GetIndex());
      super.closeChild();
    }

    return null;
  }
}
