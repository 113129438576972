<div class="menu_tabs">
  <div class="menu_tabs_list">
    <div class="menu_tabs_item" (click)="onSwitchArchive(TASK_PL)">
      <a class="menu_tabs_link" [class.active]="(showingEntity$ | async) === TASK_PL">TASKS</a>
    </div>
    <div class="menu_tabs_item" (click)="onSwitchArchive(COLUMN_PL)">
      <a class="menu_tabs_link" [class.active]="(showingEntity$ | async) === COLUMN_PL">Columns</a>
    </div>
  </div>
</div>

<input (keyup)="0" #input_search_box class="menu_form_input"
       [focus]="!(noItems$ | async)"
       (input)="onApplySearch($event.target.value)"
       [placeholder]="(showingEntity$ | async) === TASK_PL  ? 'Search task...' : 'Search column...'"
       [ngClass]="{hide_element: (noItems$ | async) && (input_search_box.value.length === 0)}"
>
<div [ngSwitch]="showingEntity$ | async">
  <archived-task-list
    *ngSwitchCase="'tasks'"
    [archivedItems]="tasks$ | async"
    (unArchiveTaskEvent)="onUnArchiveTask($event)"
    (deleteTaskEvent)="onDeleteTask($event)"
  ></archived-task-list>
  <archived-column-list *ngSwitchCase="'columns'"
                        [archivedItems]="columns$ | async"
  ></archived-column-list>
</div>
<div class="center" *ngIf="(noItems$ | async) && (input_search_box.value.length === 0)">
  <div class="menu_tabs_noarchive">
    <div class="ico_archive"></div>
    <div class="menu_tabs_noarchive_text">{{
      (showingEntity$ | async) === TASK_PL
      ? 'There are no archived tasks.'
      : 'There are no archived columns.'}}
    </div>
  </div>
</div>
<div class="noresults_text" *ngIf="(noItems$ | async) && (input_search_box.value.length !== 0)">No results</div>
<div class="center" *ngIf="hasMore">
  <a-infinite-loader
    *ngIf="_paginatorService.hasMore$ | async"
    [pending]="_paginatorService.pending$ | async"
    (loadMore)="onLoadMore()"
  >
  </a-infinite-loader>
</div>
