
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { TasksSavedListResetAction } from '../actions/task-filters/tasks-saved-list.actions';
import { DefaultTasksLabelsFilterActionTypes } from '../actions/task-filters/default-labels-filter.action';

@Injectable()
export class DefaultTasksLabelsFilterEffects {
  @Effect()
  DefaultLabelFilterToggleLabelsAction$ = this._actions$
    .ofType(DefaultTasksLabelsFilterActionTypes.TOGGLE_LABEL_ID).pipe(
    map(_ => new TasksSavedListResetAction()));

  constructor(private _actions$: Actions) {}
}
