import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AtlazApiV2Service } from '../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { Observable } from 'rxjs';
import {
  Timesheet,
  TimesheetDate,
  TimesheetDateProject,
  TimesheetDateTask,
  TimesheetDateUser,
  TimesheetLog,
  TimesheetProject,
  TimesheetSpecialProjectIds,
  TimesheetSpecialUserIds,
  TimesheetTask,
  TimesheetUser
} from './timesheet';
import * as moment from 'moment-mini-ts';
import { getActiveUserId } from '../../helpers';
import { clone } from 'ramda';
import { WorkingDaysService } from '@atlaz/working-days/services/working-days.service';
import { BACKEND_DATE_FORMAT } from '../libs/date-time-formatter/constants/date-time-formats';

export const getDateByTimestamp = (timestamp: number) => moment.unix(timestamp).format(BACKEND_DATE_FORMAT);

@Injectable()
export class TimesheetService {
  constructor(private apiV2: AtlazApiV2Service, private _workingDays: WorkingDaysService) {}

  public getTimehseetLogData(
    ts: Timesheet,
    projectTindex: number,
    userId: number,
    taskId: number,
    logId: number
  ): TimesheetLog {
    const task: TimesheetTask = this.getTimesheetTaskData(ts, projectTindex, userId, taskId);
    if (task === undefined) {
      return null;
    }

    const log: TimesheetLog = task.logs.get(logId);
    if (log === undefined) {
      return null;
    }

    return log;
  }

  public getTimesheetTaskData(ts: Timesheet, projectTindex: number, userId: number, taskId: number): TimesheetTask {
    const project: TimesheetProject = ts.projects[projectTindex];
    if (project === undefined) {
      return null;
    }

    const user: TimesheetUser = project.users.get(userId);
    if (user === undefined) {
      return null;
    }

    const task: TimesheetTask = user.tasks.get(taskId);
    if (task === undefined) {
      return null;
    }

    return task;
  }

  get(fromTime: number, toTime: number, projectId: number, users: number[]): Observable<any> {
    const requestParams = {
      fromTime,
      toTime,
      expand: 'user,task.project',
      users: []
    };

    if (projectId === TimesheetSpecialProjectIds.NoProjects) {
      requestParams['projects'] = [0];
    } else if (projectId !== TimesheetSpecialProjectIds.AllProjects) {
      requestParams['projects'] = [projectId];
    }

    users.forEach(u => {
      if (u === TimesheetSpecialUserIds.Me) {
        requestParams['users'] = [getActiveUserId()];
      } else if (u !== TimesheetSpecialUserIds.AllMembers) {
        requestParams['users'].push(u);
      }
    });

    if (requestParams.users.length === 0) {
      delete requestParams.users;
    }

    return this.apiV2.get('timesheet', requestParams).pipe(map(this.normolizeData.bind(this, fromTime, toTime)));
  }

  public getDateTimestamp(d: Date) {
    return Math.round(d.getTime() / 1000 - d.getTimezoneOffset() * 60 + 1);
  }

  public secondsToHours(time: number): number {
    // converts seconds to hours with 2 digits precision
    return Math.round(time / 3600 * 100) / 100;
  }

  public removeExtraDecimals(n: number): number {
    return Math.round(n * 100) / 100;
  }

  private normolizeData(fromDate: number, toDate: number, responseData) {
    if (responseData.data instanceof Array === false) {
      throw new TimesheetExcepton('data is not an array');
    }

    const timesheet = new Timesheet();
    if (responseData.data.length === 0) {
      return timesheet;
    }
    this.initDays(timesheet, fromDate, toDate);

    if (responseData.included instanceof Array === false) {
      throw new TimesheetExcepton('expanded data is not an array');
    }

    if (responseData.included.length === 0) {
      throw new TimesheetExcepton('expanded data is not provided');
    }

    const projects: Map<number, TimesheetProject> = new Map();
    const users: Map<number, TimesheetUser> = new Map();
    const tasks: Map<number, TimesheetTask> = new Map();
    const fakeProject = new TimesheetProject();
    fakeProject.name = 'No projects';

    this.createRelationMaps(responseData.included, tasks, users, projects);
    this.processLogs(responseData.data, tasks, users, projects, fakeProject, timesheet);
    timesheet.projects = Array.from(projects.values()).sort((p1, p2) => {
      return p1.name.toLowerCase() < p2.name.toLowerCase() ? -1 : 1;
    });
    timesheet.projects.forEach(project => {
      timesheet.total = this.removeExtraDecimals(
        timesheet.total + this.sortProjectNestedItemsAndCalculateTotals(project)
      );
    });

    const fakeTotal: number = this.sortProjectNestedItemsAndCalculateTotals(fakeProject);
    if (fakeTotal > 0) {
      timesheet.projects.push(fakeProject);
      timesheet.total = this.removeExtraDecimals(timesheet.total + fakeTotal);
    }

    return timesheet;
  }

  private createRelationMaps(relations, tasks, users, projects): void {
    relations.forEach(item => {
      switch (item.type) {
        case 'project':
          const project = this.createProject(item);
          projects.set(project.id, project);
          break;
        case 'task':
          const task = this.createTask(item);
          tasks.set(task.id, task);
          break;
        case 'user':
          const user = this.createUser(item);
          users.set(user.id, user);
          break;
      }
    });
  }

  private processLogs(logs, tasks, users, projects, fakeProject, timesheet): void {
    logs.forEach(item => {
      if (item.type !== 'worklog') {
        throw new TimesheetExcepton('Response data contains incorrect entity', { item: item });
      }
      const log: TimesheetLog = this.createLog(item);

      const taskData = tasks.get(log.taskId);
      if (taskData instanceof TimesheetTask === false) {
        throw new TimesheetExcepton('log task is not found', { item: item });
      }

      let project = projects.get(taskData.projectId);
      if (project instanceof TimesheetProject === false) {
        project = fakeProject;
      }

      const userData = users.get(log.userId);
      if (userData instanceof TimesheetUser === false) {
        throw new TimesheetExcepton('log user is not found', { item: item });
      }

      let projectUser: TimesheetUser = project.users.get(log.userId);

      if (projectUser instanceof TimesheetUser === false) {
        projectUser = new TimesheetUser();
        projectUser.id = userData.id;
        projectUser.name = userData.name;
        projectUser.email = userData.email;
        projectUser.firstname = userData.firstname;
        projectUser.lastname = userData.lastname;
        projectUser.nickname = userData.nickname;
        projectUser.userCompanyId = userData.userCompanyId;
        projectUser.photo = clone(userData.photo);
        project.users.set(projectUser.id, projectUser);
      }

      let userTask: TimesheetTask = projectUser.tasks.get(log.taskId);
      if (userTask instanceof TimesheetTask === false) {
        userTask = new TimesheetTask();
        userTask.id = log.taskId;
        userTask.projectId = taskData.projectId;
        userTask.title = taskData.title;
        userTask.numberInProject = taskData.numberInProject;
        projectUser.tasks.set(userTask.id, userTask);
      }

      userTask.logs.set(log.id, log);
      userTask.total = this.removeExtraDecimals(userTask.total + log.loggedTime);
      const day: TimesheetDate = timesheet.days.get(log.logWorkDate);
      this.increaseDayTotals(day, log, project.id);
    });
  }

  private increaseDayTotals(day, log, projectId): void {
    day.total = this.removeExtraDecimals(day.total + log.loggedTime);

    let dayProject: TimesheetDateProject = day.projects.get(projectId);
    if (dayProject instanceof TimesheetDateProject === false) {
      dayProject = new TimesheetDateProject();
      day.projects.set(projectId, dayProject);
    }

    dayProject.total = this.removeExtraDecimals(dayProject.total + log.loggedTime);

    let dayProjectUser: TimesheetDateUser = dayProject.users.get(log.userId);
    if (dayProjectUser instanceof TimesheetDateUser === false) {
      dayProjectUser = new TimesheetDateUser();
      dayProject.users.set(log.userId, dayProjectUser);
    }

    dayProjectUser.total = this.removeExtraDecimals(dayProjectUser.total + log.loggedTime);

    let dayProjectUserTask: TimesheetDateTask = dayProjectUser.tasks.get(log.taskId);
    if (dayProjectUserTask instanceof TimesheetDateTask === false) {
      dayProjectUserTask = new TimesheetDateTask();
      dayProjectUser.tasks.set(log.taskId, dayProjectUserTask);
    }

    dayProjectUserTask.total = this.removeExtraDecimals(dayProjectUserTask.total + log.loggedTime);
    dayProjectUserTask.logs[log.id] = log.loggedTime;
  }

  private initDays(timesheet: Timesheet, fromTime: number, toTime: number) {
    const startDate = moment.unix(fromTime);
    const endDate = moment.unix(toTime);

    for (const i = startDate; i.isSameOrBefore(endDate); i.add({ day: 1 })) {
      const newDay = new TimesheetDate();
      newDay.timestamp = i.unix();
      newDay.monthDay = i.format('MMM D');
      newDay.weekDay = i.format('ddd').toUpperCase();
      newDay.isDayOff = this._workingDays.isDayOff(i);
      // by default we assume that saturday is ( the 6's day of week) end of week;
      newDay.endOfWeek = i.format('ddd').toLowerCase() === 'sat';
      newDay.date = i.format(BACKEND_DATE_FORMAT);
      timesheet.days.set(newDay.date, newDay);
    }

    timesheet.sortedDays = Array.from(timesheet.days.values()).sort(
      (d1: TimesheetDate, d2: TimesheetDate) => (d1.timestamp < d2.timestamp ? -1 : 1)
    );
  }

  /*
  Add to project sorted arrays of users. Add to all nested users sorted arrays of tasks
  Calculate totals;
   */
  private sortProjectNestedItemsAndCalculateTotals(project): number {
    project.users.forEach(user => {
      user.tasks.forEach(task => {
        user.total = this.removeExtraDecimals(user.total + task.total);
        task.sortedLogs = Array.from(task.logs.values()).sort(
          (l1: TimesheetLog, l2: TimesheetLog) => (l1.id < l2.id ? -1 : 1)
        );
        task.sortedLogs.forEach((value: TimesheetLog, index: number) => (value.title = 'Log-' + (index + 1)));
      });
      user.sortedTasks = Array.from(user.tasks.values()).sort((t1: TimesheetTask, t2: TimesheetTask) => {
        return t1.title.toLowerCase() < t2.title.toLowerCase() ? -1 : 1;
      });
      project.total = this.removeExtraDecimals(project.total + user.total);
    });

    project.sortedUsers = Array.from(project.users.values()).sort((u1: TimesheetUser, u2: TimesheetUser) => {
      return u1.name.toLowerCase() < u2.name.toLowerCase() ? -1 : 1;
    });

    return project.total;
  }

  private createProject(item: any) {
    const project = new TimesheetProject();
    if (item.id !== null && !isFinite(item.id)) {
      throw new TimesheetExcepton('project data does not contain id', { item: item });
    }
    project.id = item.id;
    if (item.attributes instanceof Object === false) {
      throw new TimesheetExcepton('project data does not contain attributes', { item: item });
    }

    project.name = item.attributes.name;
    project.shortName = item.attributes.shortName;
    return project;
  }

  private createUser(item: any): TimesheetUser {
    const user = new TimesheetUser();
    if (item.id !== null && !isFinite(item.id)) {
      throw new TimesheetExcepton('user data does not contain id', { item: item });
    }
    user.id = item.id;
    if (item.attributes instanceof Object === false) {
      throw new TimesheetExcepton('user data does not contain attributes', { item: item });
    }

    user.name = item.attributes.fullname;
    user.firstname = item.attributes.firstname;
    user.lastname = item.attributes.lastname;
    user.email = item.attributes.email;
    user.photo = item.attributes.photo;
    user.nickname = item.attributes.nickname;
    try {
      user.userCompanyId = item.relationships.usersCompany.data.data.id;
    } catch (e) {
      console.log('user without user company');
    }
    return user;
  }

  private createTask(item: any): TimesheetTask {
    const task = new TimesheetTask();
    if (item.id !== null && !isFinite(item.id)) {
      throw new TimesheetExcepton('task data does not contain id', { item: item });
    }
    task.id = item.id;

    if (item.attributes instanceof Object === false) {
      throw new TimesheetExcepton('task data does not contain attributes', { item: item });
    }
    task.title = item.attributes.title;
    task.numberInProject = item.attributes.numberInProject;

    if (item.relationships) {
      if (item.relationships.project) {
        if (item.relationships.project.data instanceof Object === true) {
          if (
            Number.isInteger(item.relationships.project.data.id) ||
            item.relationships.project.data.type === 'project'
          ) {
            task.projectId = item.relationships.project.data.id;
          }
        }
      }
    }

    return task;
  }

  public createLog(item: any): TimesheetLog {
    const log = new TimesheetLog();
    if (item.id !== null && !Number.isInteger(item.id)) {
      throw new TimesheetExcepton('log data does not contain id', { item: item });
    }
    log.id = item.id;
    if (item.attributes instanceof Object === false) {
      throw new TimesheetExcepton('log data does not contain attributes', { item: item });
    }

    log.comment = item.attributes.logComment;
    log.loggedTime = this.secondsToHours(item.attributes.loggedTime);
    log.logWorkDate = getDateByTimestamp(item.attributes.logDate);
    log.logDayTimestamp = item.attributes.logDate;

    if (item.relationships instanceof Object === false) {
      throw new TimesheetExcepton('log data does not contain any relationships', { item: item });
    }
    if (item.relationships.task instanceof Object === false) {
      throw new TimesheetExcepton('log data does not relate to any task', { item: item });
    }
    if (item.relationships.task.data instanceof Object === false) {
      throw new TimesheetExcepton('log data task relation object is empty', { item: item });
    }
    log.taskId = item.relationships.task.data.id;

    if (item.relationships.user instanceof Object === false) {
      throw new TimesheetExcepton('log data does not relate to any user', { item: item });
    }
    if (item.relationships.user.data instanceof Object === false) {
      throw new TimesheetExcepton('log data user relation object is empty', { item: item });
    }
    log.userId = item.relationships.user.data.id;

    return log;
  }
}

class TimesheetExcepton {
  constructor(public message: string, public data?: any) {}
}
