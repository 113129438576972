import { Component, Input, OnInit } from '@angular/core';
import { Label } from '../../../interfaces/label';

@Component({
  selector: 'label-tag',
  styleUrls: ['label-tag.component.scss'],
  template: `
    <div [ngClass]="label.color + ' ' +  className" class="label_tag_wrapper" title="{{shortView ? label.name : undefined}}">
      <div class="label_input">
        <p *ngIf="!shortView">
          {{label.name}}
        </p>
      </div>
      <div *ngIf="selected" class="check_controll">&#10003;</div>
    </div>
  `
})
export class LabelTagComponent implements OnInit {
  @Input() label: Label;
  @Input() selected = false;
  @Input() shortView: boolean;
  @Input() className: string;

  constructor() {}

  ngOnInit() {}
}
