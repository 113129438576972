<div *ngFor="let dateInterval of notificationsByDate" class="notifications_list_container">
  <h3 class="date_interval_name">{{dateInterval.name}}
    <span (click)="onArchiveAllToDate(dateInterval)" [ngClass]="{hidden_control: isArchivedFilterActive}"></span>
  </h3>

  <div *ngFor="let notification of dateInterval.notifications;trackBy: trackById">
    <notification-preview
      [notification]="notification"
      [isArchivedFilterActive]="isArchivedFilterActive"
    ></notification-preview>
  </div>
</div>
