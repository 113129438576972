import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Task } from '../../../interfaces';
import { distinctUntilChanged, map, pluck, publishReplay, refCount, switchMap, take, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, fromEvent, Observable, Subscription } from 'rxjs/index';
import { BacklogChartService } from '../../backlog-chart/backlog-chart.service';
import { sortOriginOrder } from '../../helpers';
import { Store } from '@ngrx/store';
import { AppUrls } from '../../../app-urls';
import { IceFactors, RiceFactors } from '../../backlog-board/constants/backlog.constants';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import { AppState } from '../../../ngrx/state';
import { fromColumns } from '../../../ngrx/reducers/column.reducer';
import { fromSwimlanes } from '../../../ngrx/reducers/swimlane.reducer';
import { ScoringType } from '../../../constants';

interface CalculatedTask {
  id: number;
  title: string;
  iceImpact?: string | number;
  iceConfidence?: string | number;
  iceEase?: string | number;
  riceReach?: string | number;
  riceImpact?: string | number;
  riceConfidence?: string | number;
  riceEffort?: string | number;
  score: string | number;
}

@Component({
  selector: 'scoring-table-ice-rice',
  templateUrl: './scoring-table-ice-rice.component.html',
  styleUrls: ['./scoring-table-ice-rice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScoringTableIceRiceComponent implements OnInit, OnDestroy {
  @Input() tasks$: Observable<Task[]>;
  @Input() boardId: number;
  @Input() type$: Observable<ScoringType>;
  public sortedTasks$: Observable<Task[]>;
  public calculatedTasks$: Observable<CalculatedTask[]>;
  public filterValue$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public columnsEntities$;
  public swimlanesEntities$;
  public activeSort$: BehaviorSubject<any> = new BehaviorSubject('');
  public getTaskLink = AppUrls.getUrlTask;
  public isScoreEditing$ = new BehaviorSubject(false);
  public editingTask$ = new BehaviorSubject(null);
  public editingFactor$ = new BehaviorSubject(null);
  public higlightTimeout;
  public factors$;
  subs: Subscription[] = [];

  public iceFactors = IceFactors;
  public riceFactors = RiceFactors;

  constructor(
    private _elRef: ElementRef,
    private _store: Store<AppState>,
    private _renderer: Renderer2,
    private _backLogChartService: BacklogChartService
  ) {}

  ngOnInit() {
    this.factors$ = this.type$.pipe(
      map(type => {
        if (type === ScoringType.ICE) {
          return this.iceFactors;
        } else if (type === ScoringType.RICE) {
          return this.riceFactors;
        } else {
          return [];
        }
      })
    );

    this.columnsEntities$ = this._store.select(fromColumns.getState).pipe(pluck('entities'));
    this.swimlanesEntities$ = this._store.select(fromSwimlanes.getState).pipe(pluck('entities'));
    this.sortedTasks$ = this.tasks$.pipe(
      switchMap((tasks: Task[]) => {
        return combineLatest([this.columnsEntities$, this.swimlanesEntities$, this.activeSort$]).pipe(
          map(([columnsEntities, swimlanesEntities, activeSort]) => {
            if (!activeSort) {
              return sortOriginOrder(columnsEntities, swimlanesEntities)(tasks);
            } else {
              return tasks;
            }
          })
        );
      })
    );

    this.calculatedTasks$ = combineLatest([this.sortedTasks$, this.type$]).pipe(
      map(([tasks, type]: [Task[], ScoringType]): CalculatedTask[] =>
        tasks.map((task: Task): CalculatedTask => {
          if (type === ScoringType.ICE) {
            const result = {
              id: task.id,
              title: task.title,
              iceImpact: Number.isFinite(task.iceImpact) ? task.iceImpact : '?',
              iceConfidence: Number.isFinite(task.iceConfidence) ? task.iceConfidence : '?',
              iceEase: Number.isFinite(task.iceEase) ? task.iceEase : '?',
              score: undefined
            };
            result.score = this.getICEScore(result);
            return result;
          } else {
            const result = {
              id: task.id,
              title: task.title,
              riceReach: Number.isFinite(task.riceReach) ? task.riceReach : '?',
              riceImpact: Number.isFinite(task.riceImpact) ? task.riceImpact : '?',
              riceConfidence: Number.isFinite(task.riceConfidence) ? task.riceConfidence : '?',
              riceEffort: Number.isFinite(task.riceEffort) ? task.riceEffort : '?',
              score: undefined
            };
            result.score = this.getRICEScore(result);
            return result;
          }
        })
      ),
      switchMap((calcTasks: CalculatedTask[]) =>
        this.filterValue$.pipe(
          map(filterVal => {
            if (filterVal) {
              filterVal = filterVal.toUpperCase();
              return calcTasks.filter(item => item.title.toUpperCase().indexOf(filterVal) > -1);
            }
            return calcTasks;
          })
        )
      ),
      switchMap(calcTasks => this.activeSort$.pipe(map(activeSort => this.sortCalcTasks(calcTasks, activeSort)))),
      publishReplay(1),
      refCount()
    );

    this.subs.push(
      this.calculatedTasks$.subscribe(() => {
        setTimeout(() => this.setHeight());
      })
    );
  }

  ngAfterViewInit() {
    this.setHeight();
    this.subs.push(
      fromEvent(window, 'resize').subscribe(_ => {
        this.setHeight();
      })
    );

    this.subs.push(
      this._backLogChartService.lastCreatedByMeTaskId$.pipe(distinctUntilChanged()).subscribe(id => {
        setTimeout(() => {
          const el = document.querySelector('[data-task-id="' + id + '"]');
          if (!el) {
            return;
          }
          scrollIntoViewIfNeeded(el, {
            duration: 100,
            boundary: this._elRef.nativeElement,
            centerIfNeeded: true
          });
          this.highlightScrolledTask(id, el);
          this._backLogChartService.lastCreatedByMeTaskId$.next(0);
        });
      })
    );
  }

  setHeight() {
    if (this._elRef.nativeElement) {
      const hostRect = this._elRef.nativeElement.getBoundingClientRect();
      const availableViewport = window.innerHeight - hostRect.top - 25;
      if (this._elRef.nativeElement.scrollHeight >= availableViewport) {
        this._elRef.nativeElement.style.height = availableViewport - 2 + 'px';
      }
    }
  }

  applySortBy(event, sortParam) {
    this.activeSort$.pipe(take(1)).subscribe(activeSort => {
      const direction = event.target.dataset['sortArrow']
        ? event.target.dataset['sortArrow']
        : activeSort.param === sortParam && activeSort.direction === 'asc' ? 'desc' : 'asc';
      return this.activeSort$.next({
        param: sortParam,
        direction: direction
      });
    });
  }

  sortCalcTasks(calcTasks: CalculatedTask[], activeSort: { param: string; direction: 'desc' | 'asc' }) {
    if (activeSort) {
      const infin = activeSort.direction === 'asc' ? -Infinity : Infinity;
      let result;
      this.replaceNotFinite(infin, calcTasks, activeSort.param);
      calcTasks.sort((a, b) => b[activeSort.param] - a[activeSort.param]);
      result = this.replaceNotFinite('?', calcTasks, activeSort.param);
      return activeSort.direction === 'desc' ? result.reverse() : result;
    } else {
      return calcTasks;
    }
  }

  getICEScore(taskFactors) {
    const result = taskFactors.iceImpact * taskFactors.iceConfidence * taskFactors.iceEase;
    return Number.isFinite(result) ? result : '?';
  }

  getRICEScore(taskFactors) {
    const result =
      taskFactors.riceReach * taskFactors.riceImpact * (taskFactors.riceConfidence / 100) / taskFactors.riceEffort;
    return Number.isFinite(result) ? Math.round(result) : '?';
  }

  replaceNotFinite(replacer, array, prop) {
    return array.map(item => {
      if (Number.isFinite(item[prop])) {
        return item;
      } else {
        item[prop] = replacer;
        return item;
      }
    });
  }

  resetSort() {
    this.activeSort$.next('');
  }

  onChangeFilter(event) {
    this.filterValue$.next(event.currentTarget.value);
  }

  onEditTaskFactor(event) {
    if (event.target.dataset.hasOwnProperty('factor') && event.target.dataset.hasOwnProperty('task')) {
      const taskId = +event.target.dataset.task;
      this.tasks$.pipe(take(1)).subscribe((tasks: Task[]) => {
        const taskToEdit = tasks.filter(item => item.id === taskId)[0];
        if (taskToEdit) {
          this.editingTask$.next(taskToEdit);
          this.editingFactor$.next(event.target.dataset.factor);
          this.isScoreEditing$.next(true);
        }
      });
    }
  }

  onCloseEditing() {
    this.isScoreEditing$.next(false);
    this.editingTask$.next(null);
    this.editingFactor$.next(null);
  }

  highlightScrolledTask(id, element) {
    this._renderer.addClass(element, 'scrollHighlightClass');
    this.higlightTimeout = setTimeout(() => {
      const el = document.querySelector('[data-task-id="' + id + '"]');
      if (el) {
        this._renderer.removeClass(el, 'scrollHighlightClass');
      }
    }, 3000);
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
    if (this.higlightTimeout) {
      clearTimeout(this.higlightTimeout);
    }
  }
}
