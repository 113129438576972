import { combineLatest, debounceTime, filter, map, publishReplay, refCount, switchMapTo, tap } from 'rxjs/operators';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { BehaviorSubject, Observable, combineLatest as observableCombineLatest } from 'rxjs';
import { Board, Column, PartOfEntity, Swimlane, Task } from '../../interfaces';
import { allPass, both, isPresent, sortByField, trackById } from '../../../helpers';

import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/state';
import {
  getBoardTasks,
  getTasksByUsersFilter,
  inColumn,
  inSwimlane,
  isActiveTask
} from '../../ngrx/reducers/task.reducer';
import { TaskAddAction } from '../../ngrx/actions/task.actions';
import { ADragService } from '../../shared/a-drag/a-drag.service';
import { TASK_PL } from '../../constants';
import { TaskUnsavedDataService } from '../../task/shared/services/task-unsaved-data.service';
import { PermissionsService } from '../../permissions/permissions.service';
import { TaskPreviewComponent } from '../../shared/components/task-preview/task-preview.component';
import { ActivatedRoute } from '@angular/router';
import { getShowEstimateValuesByBoardId } from '../../ngrx/reducers/board.reducer';
import { animationFrame } from 'rxjs/scheduler/animationFrame';
import { GlobalHotkeysService } from '../../libs/global-hotkeys/global-hotkeys.service';
import { fromGuiState } from '../../ngrx/reducers/gui-state-memorized.reducer';
import { Features } from '../../libs/paywall/features.constants';
import { PaywallService } from '../../libs/paywall/paywall.service';
import { Subscription } from 'rxjs/index';

@Component({
  selector: 'column-task-list',
  templateUrl: 'column-task-list.component.html',
  styleUrls: ['column-task-list.component.scss']
})
export class ColumnTaskListComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() column: Column;
  @Input() parentColumn: Column;
  @Input() swimlane: Swimlane;
  @Input() scoreMap;
  @Input() board: Board;
  @Input() activeUserId;
  @Input() boardUsersMap;
  @Input() boardLabelsMap;
  @Input() newNotifyMap;

  @ViewChild('overlay') overlay: ElementRef;
  @ViewChildren('task_previews') taskPreviews: QueryList<TaskPreviewComponent>;

  public tasks$: Observable<Task[]>;
  public dragItemType = TASK_PL;

  private _draggingItemId$;
  public draggingItemId;
  public canEditTasks: { [taskId: number]: boolean } = {};
  public canAddNewTask$: Observable<boolean>;
  public boardTasks$: Observable<Task[]>;
  public hotKeysTargetId: number | null;

  public subs: Subscription[] = [];
  public allowedDragOver = false;
  public showEstimates$;

  public renderedHighlightOverlayValue$ = new BehaviorSubject(false);

  private highlightOverlay = false;

  get rootColumn() {
    return this.parentColumn || this.column;
  }

  get isWippedColumn() {
    return !!(this.column.maxTasksCount || this.column.minTasksCount);
  }

  get hasCapasity() {
    return this.rootColumn.backlogCapacity > 0;
  }

  public trackById = trackById;

  constructor(
    private _store: Store<AppState>,
    private _element: ElementRef,
    private _cd: ChangeDetectorRef,
    private _zone: NgZone,
    private _dragService: ADragService,
    private _taskUnsavedDataService: TaskUnsavedDataService,
    private _permissions: PermissionsService,
    private _route: ActivatedRoute,
    private _globalHotKeys: GlobalHotkeysService,
    private _paywall: PaywallService
  ) {}

  ngOnInit() {
    this.showEstimates$ = this._store.select(getShowEstimateValuesByBoardId(this.column.board));
    this.boardTasks$ = this._store.pipe(getBoardTasks, publishReplay(1), refCount());

    const filteredAndSortedTasks$ = this._store.pipe(
      getTasksByUsersFilter(allPass([inSwimlane(this.swimlane.id), inColumn(this.column.id), isActiveTask])),
      map(sortByField('position', 'asc')),
      publishReplay(1),
      refCount()
    );

    this.tasks$ = this._dragService
      .filterItems<Task>(
        filteredAndSortedTasks$,
        this.dragItemType,
        both(inColumn(this.column.id), inSwimlane(this.swimlane.id))
      )
      .pipe(tap(tasks => (this.allowedDragOver = tasks.length === 0)));

    this._draggingItemId$ = this._dragService.getDraggingItemId(this.dragItemType).pipe(publishReplay(1), refCount());

    this.subs.push(
      this._draggingItemId$.subscribe(id => (this.draggingItemId = id)),
      this._store.select(fromGuiState.getHotkeysTargetTask).subscribe(id => {
        this.hotKeysTargetId = id;
        this._cd.detectChanges();
      })
    );

    this.subs.push(
      this._draggingItemId$
        .pipe(
          filter(isPresent),
          switchMapTo(this.tasks$),
          debounceTime(0),
          tap(() => this._cd.detectChanges()),
          debounceTime(1, animationFrame)
        )
        .subscribe((tasks: any[]) => {
          if (this.highlightOverlay && tasks.length) {
            this.renderedHighlightOverlayValue$.next(true);
            this._cd.detectChanges();
          }
        })
    );

    this._zone.runOutsideAngular(() => {
      this.subs.push(
        this._draggingItemId$.subscribe(isItemDragging => {
          if (isItemDragging) {
            if (!this._dragService.getDropContainersMap(this.dragItemType).get(this.overlay.nativeElement)) {
              this._dragService.getDropContainersMap(this.dragItemType).set(this.overlay.nativeElement, {
                itemType: this.dragItemType,
                metaData: {
                  swimlane: this.swimlane.id,
                  column: this.column.id
                },
                onLeave: () => {
                  this.overlay.nativeElement.classList.remove('with-hover');
                },
                onEnter: () => {
                  this.overlay.nativeElement.classList.add('with-hover');
                }
              });
            }
          } else {
            this.overlay.nativeElement.classList.remove('with-hover');
            this._dragService.getDropContainersMap(this.dragItemType).delete(this.overlay.nativeElement);
          }
        })
      );

      this.subs.push(
        this.tasks$
          .pipe(map(list => list.length === 0), combineLatest(this._draggingItemId$))
          .subscribe(([noVisibleItems, isItemDragging]) => {
            if (isItemDragging && noVisibleItems) {
              if (!this._dragService.getDropContainersMap(this.dragItemType).get(this._element.nativeElement)) {
                this._dragService.getDropContainersMap(this.dragItemType).set(this._element.nativeElement, {
                  itemType: this.dragItemType,
                  metaData: {
                    swimlane: this.swimlane.id,
                    column: this.column.id
                  }
                });
              }
            } else {
              this._dragService.getDropContainersMap(this.dragItemType).delete(this._element.nativeElement);
            }
          })
      );
    });

    this._taskUnsavedDataService.initUnsavedCreateNewTasks();

    this.subs.push(
      this._permissions
        .canEditTasks(this.tasks$)
        .subscribe(tasksPermission => (this.canEditTasks = tasksPermission || {}))
    );

    this.canAddNewTask$ = observableCombineLatest(
      this._permissions.isBoardMember(this.column.board),
      this._permissions.isNotGuest$
    ).pipe(map(([v1, v2]) => v1 && v2));
  }

  ngAfterViewInit() {
    this.subs.push(
      this._route.fragment
        .pipe(map(taskSlug => this.taskPreviews.find(item => String(item.task.id) === taskSlug)), filter(isPresent))
        .subscribe(taskPreviewCmp => taskPreviewCmp.scrollIntoViewAndHighlight())
    );
  }

  onAddTask(data: PartOfEntity) {
    if (this._paywall.isFeatureEnabled(Features.CanAddTask)) {
      this._store.dispatch(new TaskAddAction(data));
    } else {
      this._paywall.showPayWall(Features.CanAddTask);
    }
  }

  setTaskAsHotKeysTarget(id) {
    this._globalHotKeys.setTargetTaskByMouse(id);
  }
  clearHotKeysTarget() {
    this._globalHotKeys.clearTargetByMouse();
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
