<form [formGroup]="form" (ngSubmit)="onSubmit()">

  <form-errors></form-errors>

  <div class="form_group">
    <input *ngIf="!isLink && !isProjectLink" [formControlName]="'title'" type="text" [focus]="true" [placeholder]="placeholderText">
    <link-task-input *ngIf="isLink" [formControlName]="'linkToTask'" (ngSubmit)="onSubmit()"></link-task-input>
    <a-searchable-select
      *ngIf="isProjectLink"
      [formControlName]="'linkToProject'"
      [labelId]="'link-to-project'"
      [inputValues]="projects$ | async"
      [matchProp]="'name'"
      [adaptiveDirection]="true"
      [adaptiveDirectionPadding]="40"
    ></a-searchable-select>
  </div>

  <button a-button class="btn_primary" [pending]="pending$ | async" type="submit">Add</button>
  <button a-button class="btn_secondary" (click)="onClose()">Cancel</button>
</form>
