import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../../shared/services/form-v2.service';
import { Observer, Subscription } from 'rxjs/index';
import { JsonApiSingeModelResponse } from '../../../shared/services/app/web-socket/http-response';
import { HandleResponseAction } from '../../../ngrx/actions/root.action';
import {
  BOARD_PL,
  BoardFirstOpenType,
  QuadNames,
  ScaleType,
  ScoringType,
  ScoringTypeFeatures,
  SegmentScoringType
} from '../../../constants';
import { AppState } from '../../../ngrx/state';
import { RouterNavigateService } from '../../../shared/services/router-navigate.service';
import { Store } from '@ngrx/store';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Board } from '../../../interfaces';
import { fromBoards } from '../../../ngrx/reducers/board.reducer';
import { filter, pluck, take } from 'rxjs/operators';
import { SegmentService } from '../../../atlaz-bnp/services/intergations/segment/segment.service';
import { isPresent } from '../../../../helpers';
import { ROLE_COMPANY_SUPER_OWNER } from '../../../permissions/interfaces/roles';
import { AppUrls } from '../../../app-urls';
import { getPermissionState } from '../../../permissions/ngrx/permission.reducer';
import { PaywallService } from '../../../libs/paywall/paywall.service';

@Component({
  selector: 'scoring-type-chooser',
  templateUrl: './scoring-type-chooser.component.html',
  styleUrls: ['./scoring-type-chooser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormV2Service]
})
export class ScoringTypeChooserComponent implements OnInit {
  public isSuperOwner = false;
  public shouldGoToTheScoringPage = false;
  formObserver: Observer<any> = {
    next: (resp: JsonApiSingeModelResponse<any>) => {
      this._store.dispatch(new HandleResponseAction(resp));
      try {
        const mode = resp.data.attributes['scoringType'];
        this._segment.track('BacklogBoardScoringSet', { scoringType: SegmentScoringType[mode] });
        if (this.shouldGoToTheScoringPage) {
          this._routerNav
            .deactivatePopupOutlet()
            .then(() =>
              this._routerNav.navigate(
                mode === ScoringType.basic
                  ? AppUrls.getUrlBacklogChart(resp.data.id)
                  : AppUrls.getUrlBacklogScoring(resp.data.id)
              )
            );
        } else {
          this._routerNav.deactivatePopupOutlet();
        }
      } catch (e) {
        console.log("Can't sent data to segment form scoring-type-chooser");
      }
    },

    error: () => {},

    complete: () => {}
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.edit,
    entityToEdit: BOARD_PL,
    formObserver: this.formObserver,
    prepareFormValue: formValue => {
      const result = {
        id: formValue['id'],
        showPopup: 1,
        scoringType: formValue['scoringType']
      };

      this.shouldGoToTheScoringPage =
        formValue['boardTemplateViewType'] !== BoardFirstOpenType.unset || formValue['isPreset'];
      if (formValue['scoringType'] === 'eisen') {
        result['backlogScoreXLabel'] = 'Urgency';
        result['backlogScoreYLabel'] = 'Importance';
        result['scoringType'] = ScoringType.basic;
        result['kvValues'] = ['Do Now', 'Schedule', 'Eliminate', 'Delegate'];
      }
      if (formValue['scoringType'] === 'risk') {
        result['backlogScoreXLabel'] = 'Risk';
        result['backlogScoreYLabel'] = 'Value';
        result['scoringType'] = ScoringType.basic;
        result['kvValues'] = [...QuadNames];
      }
      if (formValue['scoringType'] === ScoringType.basic) {
        result['backlogScoreXLabel'] = 'Effort';
        result['backlogScoreYLabel'] = 'Value';
        result['scoringType'] = ScoringType.basic;
        result['kvValues'] = [...QuadNames];
      }
      return result;
    }
  };
  subs: Subscription[] = [];
  public form: FormGroup;
  public board: Board;
  public BoardScoringType = ScoringType;
  constructor(
    private _routerNav: RouterNavigateService,
    private _store: Store<AppState>,
    public _formService: FormV2Service,
    private _fb: FormBuilder,
    private _segment: SegmentService,
    private _paywall: PaywallService
  ) {}

  ngOnInit() {
    this._store
      .select(fromBoards.getSelectedBoard)
      .pipe(take(1))
      .subscribe(board => {
        this.form = this._fb.group({
          id: [board.id],
          scoringType: [this.getInitialScoringType(board)],
          isPreset: [board.isPreset],
          showPopup: [board.showPopup],
          boardTemplateViewType: [board.boardTemplateViewType]
        });
        this._formService.initFormParams(this.form, this.formServiceParams);
      });
    this._store.pipe(getPermissionState, pluck('companyRole'), filter(isPresent), take(1)).subscribe(role => {
      this.isSuperOwner = role === ROLE_COMPANY_SUPER_OWNER;
    });
  }

  onClose() {
    this._routerNav.deactivatePopupOutlet();
  }

  onSubmit() {
    const feature = ScoringTypeFeatures[this.form.get('scoringType').value];
    if (!feature || this._paywall.isFeatureEnabled(feature)) {
      this._formService.markAsDirty();
      this._formService.submit();
    } else {
      this._paywall.showPayWall(feature);
    }
  }

  isSelected(type) {
    return this.form && this.form.value && this.form.value.scoringType === type;
  }

  isLocked(scoringType) {
    return this._paywall.isFeatureEnabled(ScoringTypeFeatures[scoringType]) ? null : true;
  }

  getInitialScoringType(board) {
    if (
      board.scoringType === ScoringType.basic &&
      board.backlogScoreXLabel === 'Urgency' &&
      board.backlogScoreYLabel === 'Importance'
    ) {
      return 'eisen';
    }
    if (
      board.scoringType === ScoringType.basic &&
      board.backlogScoreXLabel === 'Value' &&
      board.backlogScoreYLabel === 'Risk'
    ) {
      return 'eisen';
    }
    return board.scoringType;
  }
}
