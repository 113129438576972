<table class="table_wrapper unselectable" [attr.data-has-version]="showVersion$ | async" [class.user_can_edit]="isNotGuest" [attr.data-has-multiple-swimlanes]="showSwimlanes$ | async" [ngClass]="type$ | async">
  <thead>
    <tr *ngIf="(type$ | async) !== ScoringType.advanced;else advancedHead">
      <th class="sticky_left filter_cell">
        <span (click)="applySortBy($event, 'title', false, true)" class="sortable">
          Tasks
          <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === 'title'}">
            <span data-sort-arrow="desc" class="arrow-up"></span>
            <span data-sort-arrow="asc" class="arrow-down"></span>
          </div>
        </span>
        <div class="search_wrapper">
          <input type="text" ref-searchInput class="search_input" (keydown.esc)="onResetFilter(searchInput)" (input)="onChangeFilter($event)" placeholder="Search Tasks">
          <div *ngIf="searchInput.value" class="search_reset" (click)="onResetFilter(searchInput)"></div>
        </div>
      </th>
      <th (click)="applySortBy($event, 'users', false, true, 'nickname')" class="sortable">
        Members
        <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === 'users'}">
          <span data-sort-arrow="desc" class="arrow-up"></span>
          <span data-sort-arrow="asc" class="arrow-down"></span>
        </div>
      </th>
      <th (click)="applySortBy($event, 'labels', false, true, 'name')" class="sortable">
        Labels
        <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === 'labels'}">
          <span data-sort-arrow="desc" class="arrow-up"></span>
          <span data-sort-arrow="asc" class="arrow-down"></span>
        </div>
      </th>
      <th (click)="applySortBy($event, 'version', false, true)" class="sortable version-th">
        Version
        <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === 'version'}">
          <span data-sort-arrow="desc" class="arrow-up"></span>
          <span data-sort-arrow="asc" class="arrow-down"></span>
        </div>
      </th>
      <th (click)="applySortBy($event, 'startDate', false, false)" class="sortable">
        Start Date
        <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === 'startDate'}">
          <span data-sort-arrow="desc" class="arrow-up"></span>
          <span data-sort-arrow="asc" class="arrow-down"></span>
        </div>
      </th>
      <th (click)="applySortBy($event, 'dueDate', false, false)" class="sortable">
        Due Date
        <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === 'dueDate'}">
          <span data-sort-arrow="desc" class="arrow-up"></span>
          <span data-sort-arrow="asc" class="arrow-down"></span>
        </div>
      </th>
      <th (click)="applySortBy($event, 'swimlane', false, true)" class="sortable swimlane-th">
        Swimlane
        <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === 'swimlane'}">
          <span data-sort-arrow="desc" class="arrow-up"></span>
          <span data-sort-arrow="asc" class="arrow-down"></span>
        </div>
      </th>
      <th (click)="applySortBy($event, 'column', false, true)" class="sortable">
        {{(board$ | async)?.type === boardType.roadmap ? 'Status' : 'Column'}}
        <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === 'column'}">
          <span data-sort-arrow="desc" class="arrow-up"></span>
          <span data-sort-arrow="asc" class="arrow-down"></span>
        </div>
      </th>
      <th *ngFor="let item of (customFields$ | async)" (click)="applySortBy($event, 'cf:' + item.id, true, item.type === CustomFieldTypes.select || item.type === CustomFieldTypes.text)"  class="sortable">
        {{item.name}}
        <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === 'cf:' + item.id}">
          <span data-sort-arrow="desc" class="arrow-up"></span>
          <span data-sort-arrow="asc" class="arrow-down"></span>
        </div>
      </th>
      <ng-container *ngIf="(type$ | async) !== ScoringType.off">
        <th *ngFor="let factor of (factors$ | async)" [ngClass]="{'basic_scoring': !NonBasicScoringTypes.includes(type$ | async)}" (click)="applySortBy($event, factor.prop)" class="sortable">
          {{factor.label}}
          <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === factor.prop}">
            <span data-sort-arrow="desc" class="arrow-up"></span>
            <span data-sort-arrow="asc" class="arrow-down"></span>
          </div>
        </th>
      </ng-container>
      <th *ngIf="NonBasicScoringTypes.includes(type$ | async)" class="sticky_right sortable" (click)="applySortBy($event, 'score')">
        Score
        <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === 'score'}">
          <span data-sort-arrow="desc" class="arrow-up"></span>
          <span data-sort-arrow="asc" class="arrow-down"></span>
        </div>
      </th>
    </tr>
  </thead>
  <tbody (click)="onTaskRowClick($event)"
         [clickOut]="true"
         (clickOutEvent)="onSaveTaskTitle()"
  >
    <ng-container [ngSwitch]="type$ | async">
      <ng-container *ngSwitchCase="ScoringType.off">
        <tr *ngFor="let task of (calculatedTasks$ | async);let i = index;trackBy: task?.id" [attr.data-task]="task.id">
          <td class="sticky_left combined_td">
            <div class="wrapper" [style.min-height.px]="task.labels.length * 19 + 30" [routerLink]="getTaskLink(task.id)">
              <span class="task_index">
                #{{i+1}}
                <span class="task_checkbox" *ngIf="isBatchEdit" [class.selected]="selectedTasksMap[task.id]" (click)="toggleTaskSelection($event, task.id)"></span>
              </span>
              <span class="task_title">
                {{task.title}}
                <div class="quick_edit_button" (click)="onOpenQuickActions($event, task.id)"></div>
                <div class="quick_edit_button delete_btn" (click)="onOpenDeleteConfirm($event, task)"></div>
              </span>
            </div>
          </td>
          <td data-field="users" (click)="onComplexCellClick($event)"><members-cell [members]="task.users"></members-cell></td>
          <td data-field="labels" (click)="onComplexCellClick($event)"><labels-cell [labels]="task.labels"></labels-cell></td>
          <td data-field="version">{{versionsMap[task.version]?.name}}</td>
          <td data-field="startDate">{{task.startDate | toDate: 'dueDate'}}</td>
          <td data-field="dueDate">{{task.dueDate | toDate: 'dueDate'}}</td>
          <td data-field="swimlane">{{task.swimlane}}</td>
          <td data-field="column">{{task.column}}</td>
          <td *ngFor="let item of (customFields$ | async)" [attr.data-custom-field]="item.id">{{tasksCustomFieldsMap[item.id][task.id] ? tasksCustomFieldsMap[item.id][task.id].visibleValue : ''}}</td>
        </tr>
      </ng-container>
      <ng-container *ngSwitchCase="ScoringType.ICE">
        <tr *ngFor="let task of (calculatedTasks$ | async);let i = index;trackBy: task?.id" [attr.data-task]="task.id">
          <td class="sticky_left combined_td">
            <div class="wrapper" [style.min-height.px]="task.labels.length * 19 + 30" [routerLink]="getTaskLink(task.id)">
              <span class="task_index">
                <span class="task_checkbox" *ngIf="isBatchEdit" [class.selected]="selectedTasksMap[task.id]" (click)="toggleTaskSelection($event, task.id)"></span>
                #{{i+1}}
              </span>
              <span class="task_title">
                {{task.title}}
                <div class="quick_edit_button" (click)="onOpenQuickActions($event, task.id)"></div>
                <div class="quick_edit_button delete_btn" (click)="onOpenDeleteConfirm($event, task)"></div>
              </span>
            </div>
          </td>
          <td data-field="users" (click)="onComplexCellClick($event)"><members-cell [members]="task.users"></members-cell></td>
          <td data-field="labels" (click)="onComplexCellClick($event)"><labels-cell [labels]="task.labels"></labels-cell></td>
          <td data-field="version">{{versionsMap[task.version]?.name}}</td>
          <td data-field="startDate">{{task.startDate | toDate: 'dueDate'}}</td>
          <td data-field="dueDate">{{task.dueDate | toDate: 'dueDate'}}</td>
          <td data-field="swimlane">{{task.swimlane}}</td>
          <td data-field="column">{{task.column}}</td>
          <td *ngFor="let item of (customFields$ | async)" [attr.data-custom-field]="item.id">{{tasksCustomFieldsMap[item.id][task.id] ? tasksCustomFieldsMap[item.id][task.id].visibleValue : ''}}</td>
          <td *ngFor="let fr of (factors$ | async)" [attr.data-factor]="fr.prop">{{task[fr.prop]}}</td>
          <td [attr.data-factor]="(factors$ | async)[0]?.prop" class="sticky_right">{{task.score}}</td>
        </tr>
      </ng-container>
      <ng-container *ngSwitchCase="ScoringType.RICE"> <!-- the same as ICE, we can't use default, cause default for basic type -->
        <tr *ngFor="let task of (calculatedTasks$ | async);let i = index;trackBy: task?.id" [attr.data-task]="task.id">
          <td class="sticky_left combined_td">
            <div class="wrapper" [style.min-height.px]="task.labels.length * 19 + 30" [routerLink]="getTaskLink(task.id)">
              <span class="task_index">
                <span class="task_checkbox" *ngIf="isBatchEdit" [class.selected]="selectedTasksMap[task.id]" (click)="toggleTaskSelection($event, task.id)"></span>
                #{{i+1}}
              </span>
              <span class="task_title">
                {{task.title}}
                <div class="quick_edit_button" (click)="onOpenQuickActions($event, task.id)"></div>
                <div class="quick_edit_button delete_btn" (click)="onOpenDeleteConfirm($event, task)"></div>
              </span>
            </div>
          </td>
          <td data-field="users" (click)="onComplexCellClick($event)"><members-cell [members]="task.users"></members-cell></td>
          <td data-field="labels" (click)="onComplexCellClick($event)"><labels-cell [labels]="task.labels"></labels-cell></td>
          <td data-field="version">{{versionsMap[task.version]?.name}}</td>
          <td data-field="startDate">{{task.startDate | toDate: 'dueDate'}}</td>
          <td data-field="dueDate">{{task.dueDate | toDate: 'dueDate'}}</td>
          <td data-field="swimlane">{{task.swimlane}}</td>
          <td data-field="column">{{task.column}}</td>
          <td *ngFor="let item of (customFields$ | async)" [attr.data-custom-field]="item.id">{{tasksCustomFieldsMap[item.id][task.id] ? tasksCustomFieldsMap[item.id][task.id].visibleValue : ''}}</td>
          <td *ngFor="let fr of (factors$ | async)" [attr.data-factor]="fr.prop">{{task[fr.prop]}}</td>
          <td [attr.data-factor]="(factors$ | async)[0]?.prop" class="sticky_right">{{task.score}}</td>
        </tr>
      </ng-container>
      <ng-container *ngSwitchCase="ScoringType.advanced">
        <tr *ngFor="let task of (calculatedTasks$ | async);let i = index;trackBy: task?.id" [attr.data-task]="task.id">
          <td class="sticky_left combined_td">
            <div class="wrapper" [style.min-height.px]="task.labels.length * 19 + 30" [routerLink]="getTaskLink(task.id)">
              <span class="task_index">
                <span class="task_checkbox" *ngIf="isBatchEdit" [class.selected]="selectedTasksMap[task.id]" (click)="toggleTaskSelection($event, task.id)"></span>
                #{{i+1}}
              </span>
              <span class="task_title">
                {{task.title}}
                <div class="quick_edit_button" (click)="onOpenQuickActions($event, task.id)"></div>
                <div class="quick_edit_button delete_btn" (click)="onOpenDeleteConfirm($event, task)"></div>
              </span>
            </div>
          </td>
          <td data-field="users" (click)="onComplexCellClick($event)"><members-cell [members]="task.users"></members-cell></td>
          <td data-field="labels" (click)="onComplexCellClick($event)"><labels-cell [labels]="task.labels"></labels-cell></td>
          <td data-field="version">{{versionsMap[task.version]?.name}}</td>
          <td data-field="startDate">{{task.startDate | toDate: 'dueDate'}}</td>
          <td data-field="dueDate">{{task.dueDate | toDate: 'dueDate'}}</td>
          <td data-field="swimlane">{{task.swimlane}}</td>
          <td data-field="column">{{task.column}}</td>
          <td *ngFor="let item of (customFields$ | async)" [attr.data-custom-field]="item.id">{{tasksCustomFieldsMap[item.id][task.id] ? tasksCustomFieldsMap[item.id][task.id].visibleValue : ''}}</td>
          <td *ngFor="let cr of (criteriaValues$ | async)" [attr.data-criterion]="cr.id">{{task.scoringValuesMap[cr.id] ? task.scoringValuesMap[cr.id].visibleValue : '?'}}</td>
          <td *ngFor="let cr of (criteriaEfforts$ | async)" [attr.data-criterion]="cr.id">{{task.scoringValuesMap[cr.id] ? task.scoringValuesMap[cr.id].visibleValue : '?'}}</td>
          <td data-criterion="confidence">{{task.confidence}}</td>
          <td data-criterion="confidence" class="sticky_right">{{task.score}}</td>
        </tr>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <tr *ngFor="let task of (calculatedTasks$ | async);let i = index;trackBy: task?.id" [attr.data-task]="task.id">
          <td class="sticky_left combined_td">
            <div class="wrapper" [style.min-height.px]="task.labels.length * 19 + 30" [routerLink]="getTaskLink(task.id)">
              <span class="task_index">
                <span class="task_checkbox" *ngIf="isBatchEdit" [class.selected]="selectedTasksMap[task.id]" (click)="toggleTaskSelection($event, task.id)"></span>
                #{{i+1}}
              </span>
              <span class="task_title">
                {{task.title}}
                <div class="quick_edit_button" (click)="onOpenQuickActions($event, task.id)"></div>
                <div class="quick_edit_button delete_btn" (click)="onOpenDeleteConfirm($event, task)"></div>
              </span>
            </div>
          </td>
          <td data-field="users" (click)="onComplexCellClick($event)"><members-cell [members]="task.users"></members-cell></td>
          <td data-field="labels" (click)="onComplexCellClick($event)"><labels-cell [labels]="task.labels"></labels-cell></td>
          <td data-field="version">{{versionsMap[task.version]?.name}}</td>
          <td data-field="startDate">{{task.startDate | toDate: 'dueDate'}}</td>
          <td data-field="dueDate">{{task.dueDate | toDate: 'dueDate'}}</td>
          <td data-field="swimlane">{{task.swimlane}}</td>
          <td data-field="column">{{task.column}}</td>
          <td *ngFor="let item of (customFields$ | async)" [attr.data-custom-field]="item.id">{{tasksCustomFieldsMap[item.id][task.id] ? tasksCustomFieldsMap[item.id][task.id].visibleValue : ''}}</td>
          <td class="basic_scoring" *ngFor="let fr of (factors$ | async)" [attr.data-factor]="fr.prop">{{task[fr.prop]}}</td>
        </tr>
      </ng-container>
    </ng-container>
    <tr *ngIf="!(calculatedTasks$ | async)?.length && (filterValue$ | async)" class="no_found">
      <td class="sticky_left">For request: <strong>{{filterValue$ | async}}</strong> nothing is found</td>
      <td [colSpan]="(factors$ | async)?.length + 2"></td>
    </tr>
  </tbody>
</table>


<ng-template #advancedHead>
  <tr>
    <th rowspan="2" class="sticky_left filter_cell">
      <span (click)="applySortBy($event, 'title', false, true)" class="sortable">
          Tasks
          <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === 'title'}">
            <span data-sort-arrow="desc" class="arrow-up"></span>
            <span data-sort-arrow="asc" class="arrow-down"></span>
          </div>
        </span>
      <div class="search_wrapper">
        <input type="text" ref-searchInput class="search_input" (keydown.esc)="onResetFilter(searchInput)" (input)="onChangeFilter($event)" placeholder="Search Tasks">
        <div *ngIf="searchInput.value" class="search_reset" (click)="onResetFilter(searchInput)"></div>
      </div>
    </th>
    <th rowspan="2" (click)="applySortBy($event, 'users', false, true, 'name')" class="sortable">
      Members
      <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === 'users'}">
        <span data-sort-arrow="desc" class="arrow-up"></span>
        <span data-sort-arrow="asc" class="arrow-down"></span>
      </div>
    </th>
    <th rowspan="2" (click)="applySortBy($event, 'labels', false, true, 'name')" class="sortable">
      Labels
      <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === 'labels'}">
        <span data-sort-arrow="desc" class="arrow-up"></span>
        <span data-sort-arrow="asc" class="arrow-down"></span>
      </div>
    </th>
    <th rowspan="2" (click)="applySortBy($event, 'version', false, true)" class="sortable version-th">
      Version
      <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === 'version'}">
        <span data-sort-arrow="desc" class="arrow-up"></span>
        <span data-sort-arrow="asc" class="arrow-down"></span>
      </div>
    </th>
    <th rowspan="2" (click)="applySortBy($event, 'startDate', false, false)" class="sortable">
      Start Date
      <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === 'startDate'}">
        <span data-sort-arrow="desc" class="arrow-up"></span>
        <span data-sort-arrow="asc" class="arrow-down"></span>
      </div>
    </th>
    <th rowspan="2" (click)="applySortBy($event, 'dueDate', false, false)" class="sortable">
      Due Date
      <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === 'dueDate'}">
        <span data-sort-arrow="desc" class="arrow-up"></span>
        <span data-sort-arrow="asc" class="arrow-down"></span>
      </div>
    </th>
    <th rowspan="2" (click)="applySortBy($event, 'swimlane', false, true)" class="sortable swimlane-th">
      Swimlane
      <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === 'swimlane'}">
        <span data-sort-arrow="desc" class="arrow-up"></span>
        <span data-sort-arrow="asc" class="arrow-down"></span>
      </div>
    </th>
    <th rowspan="2" (click)="applySortBy($event, 'column', false, true)" class="sortable">
      {{(board$ | async)?.type === boardType.roadmap ? 'Status' : 'Column'}}
      <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === 'column'}">
        <span data-sort-arrow="desc" class="arrow-up"></span>
        <span data-sort-arrow="asc" class="arrow-down"></span>
      </div>
    </th>
    <th rowspan="2" *ngFor="let item of (customFields$ | async)" (click)="applySortBy($event, 'cf:' + item.id, true, item.type === CustomFieldTypes.select || item.type === CustomFieldTypes.text)"  class="sortable">
      {{item.name}}
      <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === 'cf:' + item.id}">
        <span data-sort-arrow="desc" class="arrow-up"></span>
        <span data-sort-arrow="asc" class="arrow-down"></span>
      </div>
    </th>
    <th *ngIf="(criteriaValues$ | async).length" [colSpan]="(criteriaValues$ | async).length">Value</th>
    <th *ngIf="(criteriaEfforts$ | async).length" [colSpan]="(criteriaEfforts$ | async).length">Effort</th>
    <th rowspan="2" (click)="applySortBy($event, 'confidence', true)" class="sortable">
      Confidence
      <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === 'confidence'}">
        <span data-sort-arrow="desc" class="arrow-up"></span>
        <span data-sort-arrow="asc" class="arrow-down"></span>
      </div>
    </th>
    <th rowspan="2" class="sticky_right sortable" (click)="applySortBy($event, 'score', true)">
      Score
      <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === 'score'}">
        <span data-sort-arrow="desc" class="arrow-up"></span>
        <span data-sort-arrow="asc" class="arrow-down"></span>
      </div>
    </th>
  </tr>
  <tr>
    <th *ngFor="let cr of (criteriaValues$ | async)" (click)="applySortBy($event, cr.id, true)" class="sortable">
      {{cr.name}}
      <span class="info_icon" [a-tooltip]="cr.description || 'No Description'" position="top"></span>
      <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === cr.id}">
        <span data-sort-arrow="desc" class="arrow-up"></span>
        <span data-sort-arrow="asc" class="arrow-down"></span>
      </div>
    </th>
    <th *ngFor="let cr of (criteriaEfforts$ | async)" (click)="applySortBy($event, cr.id, true)" class="sortable">
      {{cr.name}}
      <span class="info_icon" [a-tooltip]="cr.description || 'No Description'" position="top"></span>
      <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === cr.id}">
        <span data-sort-arrow="desc" class="arrow-up"></span>
        <span data-sort-arrow="asc" class="arrow-down"></span>
      </div>
    </th>
  </tr>
</ng-template>

<ng-container [ngSwitch]="type$ | async">
  <ng-container *ngSwitchCase="ScoringType.advanced">
    <edit-task-scoring
      *ngIf="isScoreEditing$ | async"
      [focusOn]="editingCriterion$ | async"
      [task]="editingTask$ | async"
      (close)="onCloseEditingScoring()"
    ></edit-task-scoring>
  </ng-container>
  <ng-container *ngSwitchCase="ScoringType.ICE">
    <edit-ice-rice-scoring
      *ngIf="isScoreEditing$ | async"
      [focusOn]="editingFactor$ | async"
      [task]="editingTask$ | async"
      [type]="type$ | async"
      (close)="onCloseEditingScoring()"
    ></edit-ice-rice-scoring>
  </ng-container>
  <ng-container *ngSwitchCase="ScoringType.RICE">
    <edit-ice-rice-scoring
      *ngIf="isScoreEditing$ | async"
      [focusOn]="editingFactor$ | async"
      [task]="editingTask$ | async"
      [type]="type$ | async"
      (close)="onCloseEditingScoring()"
    ></edit-ice-rice-scoring>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <edit-basic-scoring
      *ngIf="isScoreEditing$ | async"
      [focusOn]="editingAxis$ | async"
      [board]="board$ | async"
      [task]="editingTask$ | async"
      (close)="onCloseEditingScoring()"
    ></edit-basic-scoring>
  </ng-container>
</ng-container>

<ng-container *ngIf="isMembersPopupVisible$ | async">
  <a-center-container-scrollable class="edit_field_popup">
    <members-popup
      (addUserToBoard)="onAddUserToBoard($event)"
      (close)="onCloseMembersPopup()"
      (update) = "onUpdateUsersList($event)"
      [isPublicBoard]="isPublicBoard$ | async"
      [possibleUsers]="possibleUsers$ | async"
      [selectedUsers]="taskUsers$ | async"
      [projectOnlyUsers]="projectOnlyUsers$ | async"
    ></members-popup>
  </a-center-container-scrollable>
  <overlay (click)="onCloseMembersPopup()"></overlay>
</ng-container>

<ng-container *ngIf="isLabelsPopupVisible$ | async">
  <a-center-container-scrollable class="edit_field_popup">
    <sidebar-labels-popup
      (close)="onCloseLabelsPopup()"
      [task]="editingTask$ | async"
    ></sidebar-labels-popup>
  </a-center-container-scrollable>
  <overlay (click)="onCloseLabelsPopup()"></overlay>
</ng-container>

<ng-container *ngIf="isVersionPopupVisible$ | async">
  <a-center-container-scrollable class="edit_field_popup">
    <sidebar-version-popup
      [possibleProjects]="(board$ | async)?.projectsIds"
      [versions]="boardVersions$ | async"
      [task]="editingTask$ | async"
      (close)="onCloseVersionPopup()"
    ></sidebar-version-popup>
  </a-center-container-scrollable>
  <overlay (click)="onCloseLabelsPopup()"></overlay>
</ng-container>

<sidebar-duedate-popup *ngIf="isDueDatePopupVisible$ | async"
                       (onClosePopup)="onCloseDueDatePopup()"
                       [task]="editingTask$ | async"
></sidebar-duedate-popup>

<sidebar-start-date-popup *ngIf="isStartDatePopupVisible$ | async"
                          (onClosePopup)="onCloseStartDatePopup()"
                          [task]="editingTask$ | async"
></sidebar-start-date-popup>

<a-center-popup *ngIf="isColumnPopupVisible$ | async"
                [title]="'Move Task'"
                (close)="onCloseColumnPopup()"
                class="move_task_popup"
>
  <task-action-popup [task]="editingTask$ | async"
                     actionName="move"
                     (close)="onCloseColumnPopup()"
  ></task-action-popup>
</a-center-popup>

<div class="edit_custom_field_wrapper" *ngIf="editCustomFieldTaskId$ | async" [style.left.px]="tasksCustomFieldClientRect.x || 0" [style.top.px]="tasksCustomFieldClientRect.y || 0">
  <tasks-custom-field-edit [useContextPopUp]="true"
                           [taskId]="editCustomFieldTaskId$ | async"
                           [customFieldValue]="editingCustomFieldValue"
                           [customField]="editingCustomField"
                           (close)="onCloseCustomFieldPopup()"
  ></tasks-custom-field-edit>
</div>

<ng-template #editTtile>
  <textarea focus="true"
            class="task_title"
            [style.height.px]="editingTitle.height"
            [(ngModel)]="editingTitle.title"
            (click)="stopPropagation($event)"
            (keydown)="stopPropagation($event)"
            (keydown.enter)="onSaveTaskTitle()"
            (keydown.esc)="onCancelTitleEdit()"
  ></textarea>
</ng-template>

<div a-confirmation-popup
     *ngIf="taskToDelete"
     [confirmationTitle]="'Delete Task'"
     [actionButtonText]="'Delete'"
     (confirm)="onDeleteTask()"
     (close)="onCloseDeletePopup()"
>
  <p>Are You Sure You Want to Delete this task?</p>
  <p>All task data including comments, attachments and statistics will be lost.</p>
  <p>There is no undo.</p>
</div>

<div class="footer">
  <div *ngIf="showNewTaskForm$ | async" class="new_task_wrapper">
    <new-task-inline-form [board]="board$ | async" (close)="closeNewTaskForm()"></new-task-inline-form>
  </div>
  <button class="add_btn" *ngIf="isNotGuest" (click)="openNewTaskForm()"><span>+</span>Add Task</button>
  <button class="add_btn" *ngIf="isNotGuest" (click)="toggleBatchEdit()">{{isBatchEdit ? 'Exit Batch Edit' : 'Batch Edit'}}</button>
</div>
