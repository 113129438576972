import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { Observable } from 'rxjs/Observable';
import { map, publishReplay, refCount, switchMap, tap } from 'rxjs/operators';
import { CustomFieldTypes, fromCustomFields } from '../../../ngrx/reducers/custom-field.reducer';
import { GuiStateSetBoardGrouping } from '../../../ngrx/actions/gui-state-memorized.actions';
import { fromGuiState } from '../../../ngrx/reducers/gui-state-memorized.reducer';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'group-by-selector',
  templateUrl: './group-by-selector.component.html',
  styleUrls: ['./group-by-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupBySelectorComponent implements OnInit, OnDestroy {
  @Input() boardId$: Observable<number>;

  public selectedGrouping$: Observable<string | number>;
  public showGroupByPopUp: boolean;
  public commonValues = [{ id: 'swimlane', name: 'Default' }];
  public selectedGrouping = this.commonValues[0];
  public props$: Observable<any[]>;
  private _boardId: number;
  sub: Subscription;

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this.props$ = this.boardId$.pipe(
      tap(id => (this._boardId = id)),
      switchMap(id => this._store.select(fromCustomFields.getByBoardId(id))),
      map(customFields => [
        ...customFields.filter(item => item.type === CustomFieldTypes.select),
        ...this.commonValues
      ]),
      publishReplay(1),
      refCount()
    );

    this.selectedGrouping$ = this.boardId$.pipe(switchMap(id => this._store.select(fromGuiState.getBoardGrouping(id))));
    this.sub = combineLatest(this.props$, this.selectedGrouping$).subscribe(([props, selectedGrouping]) => {
      this.selectedGrouping = props.find(item => item.id === selectedGrouping) || this.commonValues[0];
    });
  }

  onCloseGroupByPopup() {
    this.showGroupByPopUp = false;
  }
  onOpenGroupByPopup() {
    this.showGroupByPopUp = true;
  }

  onSetProp(prop) {
    this.selectedGrouping = prop;
    this._store.dispatch(new GuiStateSetBoardGrouping({ id: this._boardId, mode: prop.id }));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
