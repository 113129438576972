
import {never as observableNever, from as observableFrom,  Observable } from 'rxjs';

import {switchMapTo, pluck, distinctUntilChanged, filter, catchError, map, mergeMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';

import { isPresent } from '../../../../helpers';
import { AppState } from '../../../ngrx/state';
import { INTEGRATION_MAP } from '../integraition-list';
import * as integrations from './integration.action';
import { Store } from '@ngrx/store';
import { InitialDataEffects } from '../../../ngrx/effects/initial-data.effects';

@Injectable()
export class IntegrationsEffects {
  readonly defaultErrorHandler = (action, error) => {
    console.error('effect', action, error);
  };

  @Effect()
  isInstalled$ = this._initEffect.initialized$.pipe(
    filter(isPresent),
    switchMapTo(observableFrom(Object.keys(INTEGRATION_MAP).map(key => INTEGRATION_MAP[key]))),
    mergeMap(integration =>
      this.store.pipe(
        pluck(integration.entityCode, 'ids'),
        distinctUntilChanged(),
        map((ids: any[]) => ({
          name: integration.name,
          isInstalled: ids.length > 0
        })),)
    ),
    map(payload => new integrations.IntegrationStatusUpdated(payload)),
    catchError(err => {
      console.warn('Failed To load integrations', err);
      return observableNever();
    }),);

  constructor(private _initEffect: InitialDataEffects, private store: Store<AppState>) {}
}
