import { FormControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment-mini-ts';
import { DATES_FORMATS } from '../../../constants/dates-formats';

const format = DATES_FORMATS.TIME_FORMAT;
const allowedFormats = [format, format.split('hh').join('h'), format.split('mm').join('m')];

export function validateTimeFactory() {
  return (c: FormControl) => {
    const trimmedValue = typeof c.value === 'string' ? c.value.trim() : c.value;

    return allowedFormats.some(format => moment(trimmedValue, format).format(format) === trimmedValue)
      ? null
      : {
          invalidDateFormat: true
        };
  };
}
