import { take } from 'rxjs/operators';
import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppState } from '../../ngrx/state/app-state';
import { Store } from '@ngrx/store';
import { LeftMenuStickyAction } from '../../ngrx/actions/component.actions';
import * as fromComponent from '../../ngrx/reducers/component.reducer';
import { Observable } from 'rxjs';

const ACTIVE = 'active';
const INACTIVE = 'inactive';

@Component({
  selector: 'left-menu-header',
  templateUrl: './left-menu-header.component.html',
  styleUrls: ['./left-menu-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('openSearchInLeftMenuState', [
      state(
        INACTIVE,
        style({
          right: '-100%'
        })
      ),
      state(
        ACTIVE,
        style({
          right: 0
        })
      ),
      transition('* => ' + ACTIVE, animate('250ms ease-in')),
      transition(ACTIVE + ' => ' + INACTIVE, animate('250ms ease-out'))
    ])
  ]
})
export class LeftMenuHeaderComponent implements OnInit {
  @Output() closeLeftMenu = new EventEmitter();

  public isSearchOpened = false;
  public state: string = INACTIVE;

  public stickyFlagEnabled$: Observable<0 | 1>;

  constructor(private _store: Store<AppState>) {}

  ngOnInit(): any {
    this.stickyFlagEnabled$ = this._store.select(fromComponent.isStickyLeftMenu);
  }

  onCloseLeftMenu() {
    if (document.activeElement['blur']) {
      document.activeElement['blur']();
    }
    this.closeLeftMenu.emit();
  }

  onOpenSearch() {
    this.state = ACTIVE;
  }
  onCloseSearch() {
    this.state = INACTIVE;
  }

  onToggleSticky() {
    this.stickyFlagEnabled$
      .pipe(take(1))
      .subscribe(flag => this._store.dispatch(new LeftMenuStickyAction(flag === 1 ? 0 : 1)));
  }

  animationStarted($event) {
    if (this.state === ACTIVE) {
      this.isSearchOpened = true;
    }
  }

  animationDone($event) {
    if (this.state === INACTIVE) {
      this.isSearchOpened = false;
    }
  }
}
