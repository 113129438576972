import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TopMenuContainerComponent } from '../../../containers/header/top-menu-container/top-menu-container.component';
import { Board, Task } from '../../../interfaces';
import { Observable, Subscription } from 'rxjs/index';
import { AppState } from '../../../ngrx/state';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { getTasksByBoardId, getUsersTasksFilter, isActiveTask } from '../../../ngrx/reducers/task.reducer';
import { map, pluck, publishReplay, refCount, switchMap, take } from 'rxjs/operators';
import { AppUrls } from '../../../app-urls';
import { getBoardById } from '../../../ngrx/reducers/board.reducer';
import { SeparatedCriteria } from '../../../interfaces/ScoringCriteria';
import { fromScoringCriteria } from '../../../ngrx/reducers/scoring-criteria.reducer';
import { ScoringFactor } from '../../backlog-board/constants/backlog.constants';
import { ScoringTableComponent } from '../scoring-table/scoring-table.component';
import { SegmentService } from '../../../atlaz-bnp/services/intergations/segment/segment.service';
import { SelectEntityAction } from '../../../ngrx/actions/root.action';
import { BOARD_PL, ScoringType, SegmentScoringType } from '../../../constants';
import { BoardFilterInfo } from '../../../interfaces/board';
import { BoardEditAction } from '../../../ngrx/actions/board.actions';
import { BoardViewMode } from '../../../ngrx/reducers/gui-state-memorized.reducer';

@Component({
  templateUrl: './scoring-page.component.html',
  styleUrls: ['./scoring-page.component.scss']
})
export class ScoringPageComponent implements OnInit, OnDestroy {
  @ViewChild('topMenu') topMenu: TopMenuContainerComponent;
  @ViewChild('scoringTable') scoringTable: ScoringTableComponent;

  public appUrls = AppUrls;
  public boardId$: Observable<number>;
  public boardInfo$: Observable<BoardFilterInfo>;
  public boardTasks$: Observable<Task[]>;
  public isShowHelpText = false;
  public containerTop;
  public boardName$: Observable<string>;
  public boardCriteria$: Observable<SeparatedCriteria>;
  public boardScoringType$: Observable<ScoringType>;
  public addTaskPopup = false;
  public isShowScoringTable$: Observable<boolean>;
  public subs: Subscription[] = [];
  public ScoringType = ScoringType;

  constructor(
    private _route: ActivatedRoute,
    private _store: Store<AppState>,
    private _cd: ChangeDetectorRef,
    private _segment: SegmentService
  ) {}

  ngOnInit() {
    this.boardId$ = <Observable<number>>this._route.params.pipe(map(params => +params['boardId']));
    this.subs.push(
      this.boardId$.subscribe(boardId =>
        this._store.dispatch(new SelectEntityAction({ id: boardId, entityName: BOARD_PL }))
      )
    );

    const board$ = this.boardId$.pipe(
      switchMap(id => this._store.pipe(getBoardById(id))),
      publishReplay(1),
      refCount()
    );

    this.subs.push(
      board$.subscribe((board: Board) => {
        if (!board.showPopup) {
          this._store.dispatch(new BoardEditAction({ id: board.id, showPopup: 1 }));
        }
      })
    );

    this.boardName$ = board$.pipe(pluck('name'));
    this.boardScoringType$ = board$.pipe(pluck('scoringType'));
    this.boardScoringType$.pipe(take(1)).subscribe(scoringType => {
      this._segment.track('AhaMoment', { aha_place: SegmentScoringType[scoringType] });
    });

    this.boardInfo$ = this.boardId$.pipe(
      switchMap(id =>
        board$.pipe(
          map(board => ({
            id: id,
            type: 'scoringTable',
            scoringType: board.scoringType,
            boardView: BoardViewMode.board,
            showProps: board.showProps
          }))
        )
      )
    );
    const taskByBoard$ = this.boardId$.pipe(
      switchMap(boardId => this._store.select(getTasksByBoardId(boardId)).pipe(map(task => task.filter(isActiveTask)))),
      publishReplay(1),
      refCount()
    );

    this.boardTasks$ = taskByBoard$.pipe(
      switchMap(tasks =>
        this._store.pipe(
          getUsersTasksFilter,
          map((filterFn: Function) =>
            tasks.filter(
              // direct calling is required to forced pass the only parameter
              task => filterFn(task)
            )
          )
        )
      )
    );

    this.boardCriteria$ = this.boardId$.pipe(
      switchMap(id => this._store.select(fromScoringCriteria.getByBoard(id))),
      map(crs =>
        crs.reduce(
          (acc, item) => {
            if (item.factor === ScoringFactor.Value) {
              acc[ScoringFactor.Value].push(item);
            } else {
              acc[ScoringFactor.Efforts].push(item);
            }
            return acc;
          },
          { [ScoringFactor.Value]: [], [ScoringFactor.Efforts]: [] }
        )
      )
    );

    this.isShowScoringTable$ = this.boardCriteria$.pipe(
      map(crsObj => !!(crsObj[ScoringFactor.Value].length + crsObj[ScoringFactor.Efforts].length))
    );
  }

  onHeightChanged(top) {
    this.containerTop = top;
  }

  onToggleHelp() {
    this.isShowHelpText = !this.isShowHelpText;
    setTimeout(() => {
      this.onHeightChanged(this.topMenu._elRef.nativeElement.offsetHeight);
      this._cd.detectChanges();
      window.dispatchEvent(new Event('resize'));
    }, 210);
  }

  onClickAddTask() {
    this.addTaskPopup = true;
  }

  onCloseAddTaskPopup() {
    this.addTaskPopup = false;
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
