import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Board } from '../../interfaces';
import { Observable } from 'rxjs/Observable';
import { BoardThemeManagerService } from '../../libs/board-theme-manager/board-theme-manager.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/state';
import { fromBoardThemes } from '../../ngrx/reducers/board-theme.reducer';
import { BoardTheme } from '../../interfaces/board-theme';
import { BoardEditAction } from '../../ngrx/actions/board.actions';

@Component({
  selector: 'right-menu-board-color',
  templateUrl: './right-menu-board-color.component.html',
  styleUrls: ['./right-menu-board-color.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightMenuBoardColorComponent implements OnInit {
  @Input() board$: Observable<Board>;

  public currentTheme$: Observable<BoardTheme>;
  public currentColor$: Observable<string>;
  public allThemes$: Observable<BoardTheme[]>;
  public isThemeChoosing = false;
  private _boardId: number;
  private _newThemeId: number;
  private _hasChanges: boolean;

  constructor(private _themeManager: BoardThemeManagerService, private _store: Store<AppState>) {}

  ngOnInit() {
    this.allThemes$ = this._store.select(fromBoardThemes.getAll);
    this.currentTheme$ = this.board$.pipe(
      tap(board => (this._boardId = board ? board.id : 0)),
      switchMap(
        (board: Board) =>
          board
            ? this._store.select(fromBoardThemes.getById(board.theme))
            : this.allThemes$.pipe(map(all => all.find(t => t.name === 'default')))
      )
    );
    this.currentColor$ = this.currentTheme$.pipe(map(theme => theme.headerColor));
  }

  onCloseThemeChoosingPopup() {
    this.isThemeChoosing = false;
    if (this._hasChanges) {
      this._store.dispatch(new BoardEditAction({ id: this._boardId, theme: this._newThemeId }));
    }
  }

  onSetBoardTheme(theme: BoardTheme) {
    this._themeManager.setTheme(theme);
    this._newThemeId = theme.id;
    this._hasChanges = true;
  }
}
