import { combineLatest as observableCombineLatest, Observable } from 'rxjs';

import { publishReplay, distinctUntilChanged, refCount } from 'rxjs/operators';
import { QuickFilterActionTypes } from '../actions/quick-filter.actions';
import { AppState, ESInterface } from '../state';
import { QuickFilter } from '../../interfaces';
import { deleteEntityState, editEntityState, selectEntity } from '../functions/reducer';
import { getAllEntitiesAsArray, getEntitySelector } from '../functions/selectors';
import { FILTER_PL } from '../../constants';
import { compose } from '@ngrx/store';
import { getSelectedBoardId } from './board.reducer';
import { taskQuickFiltersIds } from './task-filters/quick-task-filter.reducer';
import { Action } from '../actions/unsafe-action';
import { compareArrays } from '../../../helpers';

const initialState: ESInterface<QuickFilter> = {
  ids: [],
  entities: {},
  selectedEntityId: null
};

export function reducer(state = initialState, action: Action): ESInterface<QuickFilter> {
  switch (action.type) {
    case QuickFilterActionTypes.LOAD: {
      const boardId: number = <number>action.payload;

      return selectEntity(state, boardId);
    }

    case QuickFilterActionTypes.EDIT: {
      return editEntityState(state, action.payload);
    }

    case QuickFilterActionTypes.DELETE: {
      return deleteEntityState(state, action.payload);
    }

    default: {
      return state;
    }
  }
}

export const getQuickFilters = compose(getAllEntitiesAsArray, getEntitySelector(FILTER_PL));

export function getBoardQuickFilters(state$: Observable<AppState>) {
  return observableCombineLatest(
    getQuickFilters(state$),
    getSelectedBoardId(state$),
    taskQuickFiltersIds(state$),
    (quickFilters: QuickFilter[], boardId: number, activeFilterIds: number[]) => {
      const boardQuickFilters = quickFilters.filter(filter => filter.board === boardId);
      return boardQuickFilters.map(filter =>
        Object.assign({}, filter, { isActive: activeFilterIds.includes(filter.id) })
      );
    }
  ).pipe(distinctUntilChanged(compareArrays), publishReplay(1), refCount());
}
export function getAllBoardsQuickFilters(state$: Observable<AppState>) {
  return observableCombineLatest(
    getQuickFilters(state$),
    taskQuickFiltersIds(state$),
    (quickFilters: QuickFilter[], activeFilterIds: number[]) => {
      return quickFilters.map(filter => Object.assign({}, filter, { isActive: activeFilterIds.includes(filter.id) }));
    }
  ).pipe(distinctUntilChanged(compareArrays), publishReplay(1), refCount());
}
