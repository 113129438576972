<a-center-popup
  [title]="'Create New Collection'"
  (close)="onCancel()"
>
  <form [formGroup]='myForm'>
    <div class='popup_form_group'>
      <div class="error">
        <general-error-message *ngIf="hasErrorCreateNewCollectionForm | async">
          <span *ngFor="let error of createNewCollectionFormErrorMessage">{{ error }}</span>
        </general-error-message>
      </div>
      <label for='name'>Collection Name</label>
      <input [formControl]="myForm.get('name')" id='name' #name='ngForm'
             [focus]="true"
             autocomplete="off"
             (keyup.esc)="onCancel()"
             checkTouch
             placeholder='Example "Hygger Collection 1.0"'/>
      <help-block [control]="myForm.get('name')" [messages]="{'maxlength': 'Maximum length of 50 symbols is exceed'}"></help-block>
    </div>
    <div class="popup_form_group">
      <label>Add Boards</label>
      <board-input-picker
        [selectedBoards]="selectedBoards$"
        className="board_collection_popup"
        popupClassName="popup_collection_boards_list"
      ></board-input-picker>
    </div>
    <button a-button (click)="onAddCollection()" tabindex="0" class="btn_primary">Create</button>
    <button a-button (click)="onCancel()" class='btn_text'>Cancel</button>
  </form>
</a-center-popup>
