<div [ngClass]="{'group-header': true, showHover: (isHoverOnHeader$ | async), collapsed: collapsed}"
     #header
     class="group-header"
     (mousedown)="onMouseDown($event)"
     (click)="toggleCollapsedState()"
     (mouseenter)="onMouseEnter(item.id, 'group')"
     (mouseleave)="onMouseOut()"
     [roadmapDropContainer]="item"
     [dragOverHandler]="defaultDragOverHeader"
>
  <div class="icon" [class.collapsed]="collapsed">
    <icon-arrow-filled></icon-arrow-filled>
  </div>
  <div class="title">
    <inplace-string-edit [value]="title" [edit]="editTitle$ | async" (changeValue)="onChangeTitle($event)" (close)="onCloseEdit()">
    </inplace-string-edit>
  </div>
  <div></div>
  <div
    *ngIf="isNotGuest"
    class="menu-block"
    contextMenuAlign="right"
    contextMenuTrigger
    [contextMenuContent]="menuTemplate"
  >
  </div>

  <a-context-menu-content #menuTemplate>
    <a-menu>
      <p a-context-menu-item (click)="onEditTitle()">Edit</p>
      <p a-context-menu-item (click)="onShowDeleteConfirmationDialog()">Archive</p>
      <p a-context-menu-item (click)="onAddNew(RoadMapAddFormTypes.task)">Insert Task Below</p>
      <p a-context-menu-item (click)="onAddNew(RoadMapAddFormTypes.taskLink)">Insert Link Below</p>
      <p a-context-menu-item (click)="onAddNew(RoadMapAddFormTypes.group)">Insert Group Below</p>
      <p a-context-menu-item (click)="onAddNew(RoadMapAddFormTypes.project)">Insert Project Below</p>
    </a-menu>
  </a-context-menu-content>

  <a-show-in-portal *ngIf="showDeleteConfirmDialog$ | async">
    <div a-confirmation-popup
         [confirmationTitle]="'Archive Group'"
         [actionButtonText]="'Archive'"
         (confirm)="onDelete(item.id)"
         (close)="onCloseDeleteConfirmDialog()"
    >
      <p>Are you sure you want to archive this group with all tasks?</p>
    </div>
  </a-show-in-portal>
  <ng-container *ngIf="isNotGuest && !(editTitle$ | async)">
    <div class="quick_edit_button" (click)="onEditTitle($event)"></div>
    <div class="quick_edit_button delete_btn" (click)="onShowDeleteConfirmationDialog($event)"></div>
  </ng-container>
  <div class="drop_line"></div>
</div>
<div class="group-content" [class.collapsed-container]="collapsed">
  <div class="border-for-header"></div>
  <task-roadmap-preview *ngFor="let subItem of item.subItems;trackBy: trackById;"
                        class="in_group"
                        [isNotGuest]="isNotGuest"
                        [hiddenItems]="hiddenItems"
                        [item]="subItem"
                        [task]="subItem.task"
                        [isEditPercent]="subItem.task.id === editingTaskPercentId"
                        (editTaskPercent)="setEditingTaskPercentId($event)"
                        (openAddForm)="onAddNew($event.type, $event.insertAfter)"
  >
  </task-roadmap-preview>
  <div *ngIf="isNotGuest" (mouseenter)="onMouseEnter(item.id, 'push')"
       (mouseleave)="onMouseOut()"
       [roadmapDropContainer]="item"
       [dragOverHandler]="defaultDragOverAddButtonHandler"
       class="add-form-block"
       [class.showHoverPush]="(isHoverOnPush$ | async)"
       [class.showForm]="(addFormVisible$ | async)"
  >
    <div class="drop_line"></div>
    <ng-container *ngIf="isNotGuest;else emptyGuestGroup">
      <add-button (click)="onOpenPushForm(RoadMapAddFormTypes.task)" *ngIf="(addFormHidden$ | async)">Task</add-button>
      <add-button (click)="onOpenPushForm(RoadMapAddFormTypes.taskLink)" *ngIf="(addFormHidden$ | async)">Link</add-button>
      <add-button (click)="onOpenPushForm(RoadMapAddFormTypes.project)" *ngIf="(addFormHidden$ | async)">Project</add-button>
      <add-task-form
        [params]="addFormParams$ | async"
        *ngIf="(addFormVisible$ | async)"
        [clickOut]="true"
        (clickOutEvent)="onAddFormClickOut($event)"
      >
      </add-task-form>
    </ng-container>
  </div>
</div>
<ng-template #emptyGuestGroup>
  <div class="empty_guest_group" (click)="toggleCollapsedState()">Collapse group</div>
</ng-template>
