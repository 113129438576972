import { combineLatest as observableCombineLatest, Observable } from 'rxjs';

import { distinctUntilChanged, map, pluck } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import * as ROUTE_PATH from '../../../path.routing';
import { Board, User } from '../../../interfaces/';
import { CompanyService } from '../../../shared/services/app/company.service';
import { PermissionsService } from '../../../permissions/permissions.service';
import { accountServiceUrl } from '../../../../helpers/';
import { BillingPublicApiService } from '@atlaz/billing/shared/billing-public-api.service';
import { getAllBoards } from '../../../ngrx/reducers/board.reducer';
import { Store } from '@ngrx/store';
import { AppState, EntityState } from '../../../ngrx/state';
import { AuthService } from '../../../shared/services/app/auth.service';
import { COMPANY_PL, PRESET_PL } from '../../../constants/';
import { JsonApiSingeModelResponse } from '../../../shared/services/app/web-socket/http-response';
import { HandleResponseAction } from '../../../ngrx/actions/root.action';
import { AtlazApiV2Service } from '../../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { Company } from '../../../interfaces';
import { companyPortalUrl, isPresent, naturalSort } from '../../../../helpers';
import { UsersCompany } from '../../../interfaces/users-compnay';
import { USER_COMPANY_PL } from '../../../constants';

@Component({
  selector: 'profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProfileMenuComponent implements OnInit {
  @Input() user: User;
  @Output() closeMenu = new EventEmitter<boolean>();

  public path: { [path: string]: any } = ROUTE_PATH;
  public toUserProfile = accountServiceUrl + '/profile/general';
  public isSuperUser$ = this._permissions.isSuperUser$;
  public userInActiveCompany$ = this._authService.userInActiveCompany$;
  public companies$;
  readonly hasBillingAccess$ = this._billingService.hasAccess$;

  readonly showDeletePresetButton$ = observableCombineLatest(
    this.userInActiveCompany$,
    this.isSuperUser$,
    this._store.pipe(getAllBoards, map((boards: Board[]) => boards.some((board: Board) => !!board.isPreset))),
    (userInActiveCompany, isSuperUser, hasPresets) => {
      return userInActiveCompany && isSuperUser && hasPresets && !this._companyService.isDemo;
    }
  );

  public isDeletePresetsPopupVisible = false;

  constructor(
    private _authService: AuthService,
    private _atlazApi: AtlazApiV2Service,
    public _companyService: CompanyService,
    private _store: Store<AppState>,
    private _permissions: PermissionsService,
    private _billingService: BillingPublicApiService
  ) {}

  ngOnInit() {
    this.companies$ = observableCombineLatest(
      this._store.pipe(pluck(USER_COMPANY_PL), distinctUntilChanged(), map(EntityState.fromState)),
      this._store.pipe(pluck(COMPANY_PL), distinctUntilChanged(), map(EntityState.fromState)),
      (usersCompnanies, companies: EntityState<Company>) =>
        usersCompnanies
          .toArray()
          .filter(
            (userCompany: UsersCompany) =>
              userCompany.deleted === 0 && userCompany.user === this._authService.activeUserId && userCompany.confirmed
          )
          .map((userCompany: UsersCompany) => {
            const company = companies.get(userCompany.company);
            return company.deleted
              ? undefined
              : {
                  name: company.name,
                  id: company.id,
                  link: companyPortalUrl(company)
                };
          })
          .filter(isPresent)
    ).pipe(map(naturalSort('name')));
  }

  onDeletePresetsToggle() {
    this.isDeletePresetsPopupVisible = !this.isDeletePresetsPopupVisible;
  }

  onDeletePresets() {
    this._atlazApi
      .patch(COMPANY_PL, {
        id: this._companyService.id,
        [PRESET_PL]: { remove: [1] }
      })
      .subscribe(
        (resp: JsonApiSingeModelResponse<any>) => {
          this._store.dispatch(new HandleResponseAction(resp));
        },
        err => console.log('failed to delete presets', err)
      );
    this.onDeletePresetsToggle();
    this.onCloseMenu();
  }

  onCloseMenu() {
    this.closeMenu.emit(false);
  }
}
