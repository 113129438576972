<div *ngIf="noData === false" class="timesheet-table-container" >
  <div class="timesheet-table-data">
    <div class="timesheet-table-data-titles">
      <table>
        <thead>
          <tr class="timesheet-data-titles-fixed-header">
            <th></th>
          </tr>
          <tr>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of tableRows">
           <td class="timesheet-{{row.type}}-title" [routerLink]="row.taskUrl ? row.taskUrl : null">
             <a-avatar *ngIf="row.type === 'user'"
               [user]="data.projects[row.projectIndex].users.get(row.userId)"
             ></a-avatar>
             <div class="timesheet-table-tooltip-container">
               <div class="timesheet-table-truncated-text"
                    [ngClass]="{
                      'timesheet-table-no-comment': row.title === 'No comment'
                    }"
                >
                 <span>{{row.title | textToHtml}}</span>
                 <div *ngIf="!!row.tooltip && row.type !== 'user'" class="timesheet-table-tooltip">
                   <smart-text [text]="row.tooltip | textToHtml" ></smart-text>
                 </div>
               </div>
             </div>
           </td>
          </tr>
          <tr><td class="timesheet-title-total">Total hours:</td></tr>
        </tbody>
      </table>
    </div>
    <div class="timesheet-table-data-logs">
      <div

        class="timesheet-data-logs-table-container"

      >
        <table class="timesheet-table-logs">
          <thead #dataHeader scrollSynchro [scrollSourceRef]="dataTable" [style.width.px]="width$ | async">
            <tr>
              <th *ngFor="let day of data.sortedDays"
                  [class.timesheet-table-day-off]="day.isDayOff"
                  [class.timesheet-table-end-of-week]="day.endOfWeek">
                <div>{{day.weekDay}}</div>
                <div>{{day.monthDay}}</div>
              </th>
            </tr>
          </thead>
          <tbody
            [style.width.px]="width$ | async"
            #dataTable [mouseScroll]="true"
            [horizontalScrollContainer]="dataTable"
            (scroll)="onLogDataScroll($event)"
            initialScroll [scrollLeft]="logDataLeftInitialScroll"
          >
            <tr *ngFor="let row of tableRows; let rowIndex=index">
              <td
                *ngFor="let day of data.sortedDays"
                [ngClass]="{
                  'timesheet-table-saturday': day.isDayOff,
                  'timesheet-table-end-of-week': day.endOfWeek,
                  'timesheet-project-data':   row.type === 'project',
                  'timesheet-user-data':      row.type === 'user',
                  'timesheet-task-data':      row.type === 'task',
                  'timesheet-log-data':       row.type === 'log'
                  }"
                (dblclick)="onDataCellDblClick($event, row, rowIndex, day)"
              >
                <span *ngIf="row.type==='project'">{{getDayProjectTotal(row.projectId, day)}}</span>
                <span *ngIf="row.type==='user'">{{getDayProjectUserTotal(row.projectId, row.userId, day)}}</span>
                <span *ngIf="row.type==='task'">{{getDayProjectUserTaskTotal(row.projectId,row.userId, row.taskId, day)}}</span>
                <span *ngIf="row.type==='log'">{{getDayProjectUserTaskLogValue(row.projectId, row.userId, row.taskId, day, row.logId)}}</span>
              </td>
            </tr>
            <tr>
              <td *ngFor="let day of data.sortedDays" class="timesheet-data-total"
                  [ngClass]="{'timesheet-table-saturday': day.isDayOff,
                  'timesheet-table-end-of-week': day.endOfWeek }">
                {{day.total === 0 ? '' : roundTime(day.total)}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      </div>
  </div>
  <div class="timesheet-table-totals">
    <table>
      <thead>
      <tr>
        <th>Total</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let row of tableRows">
        <td class="timesheet-{{row.type}}-total">
          {{ row.type !== 'log' ? row.total : '' }}
        </td>
      </tr>
      <tr>
        <td class="timesheet-totals-total">{{data.total}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<div *ngIf="noData === true && loading === false" class="timesheet-table-container timesheet-no-data" app-body-inner>
  <div class="timesheet-no-data-message">
    <svg width="326" height="143" viewBox="0 0 326 143" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M306 141.5C306 63.3517 242.648 0 164.5 0C86.3517 0 23 63.3517 23 141.5" fill="#D2E9F7" fill-opacity="0.430791"/>
      <path d="M2 141H241.002" stroke="#0686D8" stroke-width="4" stroke-linecap="square"/>
      <path d="M270 141H323.46" stroke="#0686D8" stroke-width="4" stroke-linecap="square"/>
      <path d="M252 141H260.062" stroke="#0686D8" stroke-width="4" stroke-linecap="square"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M163.255 120C185.346 120 203.255 102.091 203.255 80C203.255 57.9086 185.346 40 163.255 40C141.163 40 123.255 57.9086 123.255 80C123.255 102.091 141.163 120 163.255 120Z" fill="white" stroke="#1E88E5" stroke-width="8" stroke-linecap="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M163.255 100C174.301 100 183.255 91.0457 183.255 80C183.255 68.9543 174.301 60 163.255 60C152.209 60 143.255 68.9543 143.255 80C143.255 91.0457 152.209 100 163.255 100Z" fill="#D2EAFF"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M163.255 88C167.673 88 171.255 84.4183 171.255 80C171.255 75.5817 167.673 72 163.255 72C158.837 72 155.255 75.5817 155.255 80C155.255 84.4183 158.837 88 163.255 88Z" fill="#1E88E5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M183.255 58L163.514 75.0161L167.514 79.0161L183.255 58Z" fill="#1E88E5"/>
      <path d="M155.255 28C155.255 25.7909 157.046 24 159.255 24H167.255C169.464 24 171.255 25.7909 171.255 28V32H155.255V28Z" fill="#1E88E5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M120.828 48.8873C119.266 47.3252 119.266 44.7926 120.828 43.2305L126.485 37.5736C128.047 36.0115 130.58 36.0115 132.142 37.5736L134.971 40.402L123.657 51.7158L120.828 48.8873Z" fill="#1E88E5"/>
      <path d="M194.368 37.5736C195.93 36.0115 198.462 36.0115 200.024 37.5736L205.681 43.2305C207.243 44.7926 207.243 47.3252 205.681 48.8873L202.853 51.7157L191.539 40.402L194.368 37.5736Z" fill="#1E88E5"/>
      <rect x="159.255" y="32" width="8" height="8" fill="#1E88E5"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M167.255 48H159.255V56H167.255V48ZM195.255 84V76H187.255V84H195.255ZM167.255 112H159.255V104H167.255V112ZM131.255 76V84H139.255V76H131.255Z" fill="#1E88E5"/>
    </svg>

    <h3 class="timesheet-no-data-header">
        No time logs
    </h3>
    <div class="timesheet-no-data-description">
      Start logging time or change filter settings.
<!--        <p>Create a work log. <a href="https://hygger.zendesk.com/hc/en-us/articles/115002441049-Task-Overview-and-Elements">How to create work log?</a></p>-->
    </div>
  </div>
</div>

<a-fixed-container [left]="dblClickedLeft" [top]="dblClickedTop" *ngIf="showLogworkPopUp" class="timesheet-fixed-container">
  <a-context-container>
    <div a-context-popup
         class="logwork-popup"
         className="medium_popup"
         [title]="'Log Work'"
         (close)="onCloseLogworkPopUp()"
    >
      <work-log-form
        (closePopupEvent)="onCloseLogworkPopUp()"
        (submitWorkLog)="onSubmitWorklog($event)"
        [editMode]="dblClickedRow.type === 'log'"
        [logWorkDate]="dblClickedDay.date"
        [loggedTime]="dblClickedLogData !== null ? dblClickedLogData.loggedTime * 3600 : null"
        [comment]="dblClickedLogData !== null ? dblClickedLogData.comment : ''"
        [payload]="{ projectId: dblClickedRow.projectId,
                     logworkLogDate:  dblClickedLogData? dblClickedLogData.logDayTimestamp : null,
                     dblClickedRowIndex: dblClickedRowIndex,
                     projectIndex: dblClickedRow.projectIndex,
                     taskId: dblClickedRow.taskId,
                     logId: dblClickedLogData !== null ? dblClickedLogData.id : null
                    }"
      ></work-log-form>
    </div>

  </a-context-container>
</a-fixed-container>
