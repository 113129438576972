<label for="search_input">
  <input #search_input
         id="search_input"
         type="text"
         [focus]="true"
         placeholder="{{placeholder}}"
         (input)="onWrite(search_input.value)"
         [value]="(stringSearch$ | async)"
         [ngClass]="{search_input_background: searchIcon, search_input: true}"
  >
  <span *ngIf="closeButton" class="ico_clear" (click)="onCloseSearch($event)"></span>
</label>
