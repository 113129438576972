import { Component, Input, OnInit } from '@angular/core';
import { Observer } from 'rxjs';
import { SprintBoard } from '../../../../interfaces';
import { BOARD_PL, EstimationType } from '../../../../constants';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../ngrx/state';
import { ModelValidators } from '../../../../shared/validators/model-validators';
import {
  FormComponent,
  FormSaveType,
  FormServiceParams,
  FormV2Service
} from '../../../../shared/services/form-v2.service';
import { HandleResponseAction } from '../../../../ngrx/actions/root.action';
import { JsonApiSingeModelResponse } from '../../../../shared/services/app/web-socket/http-response';
import * as moment from 'moment-mini-ts';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'sprint-boundaries',
  templateUrl: './sprint-boundaries.component.html',
  styleUrls: ['./sprint-boundaries.component.scss'],
  providers: [FormV2Service]
})
export class SprintBoundariesComponent implements OnInit, FormComponent {
  @Input() board: SprintBoard;

  formObserver: Observer<any> = {
    next: (resp: JsonApiSingeModelResponse<any>) => {
      this._store.dispatch(new HandleResponseAction(resp));
      this._toastr.success('All changes saved.');
    },
    error: () => {},
    complete: () => {}
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.edit,
    entityToEdit: BOARD_PL,
    formObserver: this.formObserver,
    prepareFormValue: valueToSend => ({ ...valueToSend, sprintEndDate: valueToSend['sprintEndDate'].unix() })
  };

  customErrorMessages = {
    tooSmallDate: 'End date has to be at least - today',
    required: 'Field is required',
    maxlength: 'Sprint name exceeds the maximum length (50 symbols)'
  };

  form: FormGroup;

  public estimationType = EstimationType;

  constructor(
    private _fb: FormBuilder,
    public _formService: FormV2Service,
    private _store: Store<AppState>,
    private _toastr: ToastrService
  ) {}

  ngOnInit(): any {
    this.form = this._fb.group({
      id: [this.board.id],
      sprintName: [this.board.sprintName, ModelValidators.version.name],
      sprintEndDate: [
        { value: moment.unix(this.board.sprintEndDate), disabled: false },
        ModelValidators.board.sprintEndDate
      ],
      estimatedBy: this.board.estimatedBy || this.estimationType.any
    });
    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  onSubmit() {
    this._formService.markAsDirty();
    if (this.form.valid) {
      return this._formService.submit();
    }
  }
}
