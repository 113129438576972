<form [formGroup]="form">
  <form-errors></form-errors>

  <label for="version-name">Version Name:</label>
  <input formControlName="sprintName"
         type="text"
         id="version-name"
         [focus]="true"
         placeholder='Example "Sprint 1.0"'
         checkTouch
  />
  <help-block [control]="form.get('sprintName')" [messages]="errorMessages"></help-block>
  <div *ngIf="howManyTasksIsUnDone > 0">
    <p class="warning_title"><strong>{{howManyTasksIsUnDone + ' '}}</strong><span>{howManyTasksIsUnDone, plural, =1 {task was incomplete} other {tasks were incomplete}}</span></p>
    <p>Select one of the options below:</p>
    <div class="radio_wrapper">
      <input type="radio" id="release-completed" formControlName="release" [value]="releaseTypes.completed">
      <label for="release-completed">
        <span class="main_label">Release only completed tasks</span><br>
        <span class="additional_label">Incomplete tasks won't be released and will remain on the current board</span>
      </label>
    </div>
    <div class="radio_wrapper">
      <input type="radio" id="release-all" formControlName="release" [value]="releaseTypes.all">
      <label for="release-all">
        <span class="main_label">Release all tasks from this board</span><br>
        <span class="additional_label">All tasks from this board will be marked as 'Done' and released</span>
      </label>
    </div>
  </div>
  <div class="divider"></div>
  <div a-button
       class="btn_primary"
       [pending]="(_formService.isPending$ | async)"
       (click)="onReleaseSprint()">
    Release Sprint
  </div>
</form>
