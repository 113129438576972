
import {fromEvent as observableFromEvent,  Observable } from 'rxjs';

import {filter} from 'rxjs/operators';
import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';

@Directive({
  selector: '[stopChildEventPropagation]'
})
export class StopChildEventPropagationDirective implements AfterViewInit, OnDestroy {
  @Input() stopChildEventPropagation = '';

  sub;

  constructor(private _elRef: ElementRef) {}

  ngAfterViewInit() {
    if (!this.stopChildEventPropagation) {
      return console.warn('stopChildEventPropagation: event name is required');
    }
    this.sub = observableFromEvent(this._elRef.nativeElement, this.stopChildEventPropagation).pipe(
      filter((event: UIEvent) => event.target !== this._elRef.nativeElement))
      .subscribe((event: UIEvent) => {
        event.stopPropagation();
        event.stopImmediatePropagation();
      });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
