import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'smart-no-brackets',
  template: '<b>{{text}}</b>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartNoBracketsComponent implements OnInit {
  @Input() value = '';

  public text: string;

  ngOnInit() {
    this.text = this.value.replace(/\[|]/g, '');
  }
}
