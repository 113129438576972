import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { User } from '../../../interfaces';

@Component({
  selector: 'user-labels',
  templateUrl: './user-labels.component.html',
  styleUrls: ['./user-labels.component.scss']
})
export class UserLabelsComponent {
  @Input() users: User[];
  @Input() editPermissions = true;
  @Input() isBoard = false;
  @Input() isSmallItems: boolean;

  @Output() close = new EventEmitter();
  @Output() onOpenPopupEvent = new EventEmitter();
  @Output() removeUser = new EventEmitter();

  @ViewChild('wrapper') wrapper: ElementRef;

  public userPreviewIndex = -1;

  onOpenUserPreview(index: number) {
    this.userPreviewIndex = index;
  }

  onCloseAllUserPreviews() {
    this.userPreviewIndex = -1;
  }

  onOpenPopup() {
    this.onOpenPopupEvent.emit(true);
  }
  onRemoveUserFromTask(userId: number) {
    this.removeUser.emit(userId);
  }

  onWrapperClick(event) {
    if (event.target === this.wrapper.nativeElement && this.editPermissions) {
      this.onOpenPopup();
    }
  }
}
