import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { User } from '../../interfaces';
import { UserPreviewService } from '../services/user-preview.service';

@Component({
  selector: 'app-overflow-elements',
  templateUrl: './app-overflow-elements.component.html',
  styleUrls: ['./app-overflow-elements.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppOverflowElementsComponent implements OnInit {
  public user$: Observable<User | null>;
  public top$: Observable<number>;
  public left$: Observable<number>;

  constructor(private _userPreview: UserPreviewService) {}

  public ngOnInit() {
    this.user$ = this._userPreview.user$;
    this.top$ = this._userPreview.top$;
    this.left$ = this._userPreview.left$;
  }

  public hidePreview(event) {
    this._userPreview.hidePreview(event);
  }
}
