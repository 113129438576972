import { Pipe, PipeTransform } from '@angular/core';
import { Entity } from '../../interfaces/entity';

@Pipe({
  name: 'filterEntitiesByField'
})
export class FilterEntitiesByFieldPipe implements PipeTransform {
  transform(value: number[], entities: { [id: number]: Entity }, fieldName: string, partOfFieldValue: any) {
    return !value || (partOfFieldValue && partOfFieldValue.length < 1)
      ? value
      : value.filter(itemId => {
          const item = entities[itemId];
          if (item && item[fieldName]) {
            return item[fieldName].toLowerCase().indexOf(partOfFieldValue.toLowerCase()) > -1;
          }
        });
  }
}
