import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { User } from '../../interfaces/user';

@Component({
  selector: 'a-multiple-avatars',
  templateUrl: './a-multiple-avatars.component.html',
  styleUrls: ['./a-multiple-avatars.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AMultipleAvatarsComponent {
  @Input() users: User[];
  @Input() showDetails = true;

  get shouldFullListRender() {
    return this.users && this.users.length > 1 && this.showDetails;
  }
}
