import { getParamByCompany, getEnvironmentParam } from '../environments/helper';

export const authApiUrl = getEnvironmentParam('authApiUrl');
export const accountServiceUrl = getEnvironmentParam('accountServiceUrl');
export const SERVICE_URL = getEnvironmentParam('serviceUrl');

export const getApiUrl = getParamByCompany('apiUrl');
export const getExperimentalApiUrl = getParamByCompany('experimentalApiUrl');
export const companyPortalUrl = getParamByCompany('companyPortalUrl');
export const getSocketUrl = getParamByCompany('socketUrl');
export const getSlackUrl = getParamByCompany('slackUrl');
export const getGithubUrl = getParamByCompany('githubUrl');
export const getBitbucketUrl = getParamByCompany('bitbucketUrl');
export const getGitlabUrl = getParamByCompany('gitlabUrl');
export const getIntercomUrl = getParamByCompany('intercomUrl');
export const getJiraUrl = getParamByCompany('jiraUrl');
