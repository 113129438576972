import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, ESInterface } from '../../../../ngrx/state/app-state';
import { getEntitySelector } from '../../../../ngrx/functions/selectors';
import { PROJECT_PL } from '../../../../constants/entity';
import { BehaviorSubject } from 'rxjs';
import { Project } from '../../../../interfaces/project';

@Component({
  selector: 'project-input-picker',
  templateUrl: './project-input-picker.component.html',
  styleUrls: ['./project-input-picker.component.scss']
})
export class ProjectInputPickerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() selectedProjects: BehaviorSubject<number[]>;
  @Input() className = 'projects_pick_popup';
  @Input() multiple = true;
  @Input() validation = true;
  @Input() disabledProjects: number[] = [];

  public projectsState: ESInterface<Project>;
  public showAddPopUp = false;
  public subscription;
  public projectsEntities: { [id: number]: Project };
  public hideAddProjectBtn = false;

  constructor(private _store: Store<AppState>) {}

  ngOnInit(): any {
    this.subscription = this._store.pipe((getEntitySelector(PROJECT_PL))).subscribe((state: ESInterface<Project>) => {
      this.projectsState = state;
      this.projectsEntities = state.entities;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['multiple'] &&
      changes['multiple']['currentValue'] !== changes['multiple']['previousValue'] &&
      !this.multiple &&
      this.selectedProjects$.getValue().length > 1
    ) {
      this.selectedProjects$.next([this.selectedProjects$.getValue()[0]]);
    }
    this.isHideAddProjectBtn();
  }

  ngOnDestroy(): any {
    this.subscription.unsubscribe();
  }

  get selectedProjects$() {
    return this.selectedProjects;
  }

  onRemoveProject(projectId) {
    this.selectedProjects$.next(this.selectedProjects$.getValue().filter(existsProject => existsProject !== projectId));
  }

  onOpenPopup() {
    this.showAddPopUp = true;
  }

  onClosePopup() {
    this.showAddPopUp = false;
  }

  isHideAddProjectBtn() {
    this.hideAddProjectBtn = !this.multiple && this.disabledProjects.length > 0;
  }
}
