import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { compose } from '@ngrx/store';
import { getEntitySelector } from '../../functions/selectors';
import { DEFAULT_TIME_ON_COLUMN_FILTER } from '../../state';
import * as defaultTimeOnColumnFilter from '../../actions/task-filters/default-time-on-column-filter.actions';
import { DefaultTasksTimeOnColumnFilterActionTypes } from '../../actions/task-filters/default-time-on-column-filter.actions';
import { TaskDefaultFilterActionTypes } from '../../actions/task-filters/task-default-filter.actions';
import { DaysRange } from '../../actions/task-filters/task-default-filter.actions';

export interface DefaultTimeOnColumnFilter {
  columnDaysRange: DaysRange;
}

const initState: DefaultTimeOnColumnFilter = {
  columnDaysRange: {
    moreThan: null,
    lessThan: null
  }
};

export function reducer(state = initState, action: defaultTimeOnColumnFilter.Actions): DefaultTimeOnColumnFilter {
  switch (action.type) {
    case DefaultTasksTimeOnColumnFilterActionTypes.TOGGLE_ITEM_ID: {
      let columnDaysRange: DaysRange;
      columnDaysRange = action['payload'];
      state = Object.assign({}, state, { columnDaysRange: columnDaysRange });
      return state;
    }

    case DefaultTasksTimeOnColumnFilterActionTypes.RESET: {
      return initState;
    }

    case TaskDefaultFilterActionTypes.SET: {
      if (action['payload'].activeFilters.defaultTimeOnColumnFilter) {
        state = { columnDaysRange: action['payload'].activeFilters.defaultTimeOnColumnFilter };
      } else {
        state = initState;
      }
      return state;
    }

    default: {
      return state;
    }
  }
}

const isActive = (state$: Observable<DefaultTimeOnColumnFilter>) => state$.pipe(
  map(state => state.columnDaysRange.moreThan || state.columnDaysRange.lessThan)
);
const getColumnDaysRange = (state$: Observable<DefaultTimeOnColumnFilter>) => state$.pipe(map(state => state.columnDaysRange));

export const getDefaultTimeOnColumnFilterEntity = getEntitySelector(DEFAULT_TIME_ON_COLUMN_FILTER);
export const isActiveDefaultTimeOnColumnFilter = compose(isActive, getDefaultTimeOnColumnFilterEntity);
export const getDefaultTimeOnColumnFilterDaysRange = compose(getColumnDaysRange, getDefaultTimeOnColumnFilterEntity);
