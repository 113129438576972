<div class="full_view" [ngClass]="{left: !isBoard, right: isBoard}">
  <div class="main_personal">
    <img class="w200" [src]="preview200Url">
    <div class="initials">
      {{initials}}
    </div>
    <div class="contact_data">
      <div>{{(user.firstname || user.lastname) ? (user.firstname + ' ' + user.lastname) : ('@' + user.nickname)}}</div>
      <div class="phone">{{user.phone}}</div>
    </div>
  </div>
  <div class="additional_data">
    <div *ngIf="canRemove" class="remove_link" (click)="onRemove()">Remove from {{isBoard ? 'Board' : 'Task'}}</div>
    <div class="scrollable_content">
      <div class="user_role" *ngIf="(userCompany$ | async)?.profileRole">{{(userCompany$ | async)?.profileRole}}</div>
      <div class="user_department" *ngIf="(userCompany$ | async)?.profileDepartment">{{(userCompany$ | async)?.profileDepartment}}</div>
      <div class="nickname" *ngIf="user.firstname || user.lastname" >@{{user.nickname}}</div>
      <div class="guest_user" *ngIf="user.role === ROLE_COMPANY_GUEST" >(Guest User)</div>
      <div class="user_about" *ngIf="(userCompany$ | async)?.profileAbout">{{(userCompany$ | async)?.profileAbout}}</div>
      <div class="scroll_shadow">
        <svg width="180" height="10" viewBox="0 0 180 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="180" height="10" fill="black" fill-opacity="0.01"/>
          <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="180" height="10">
            <rect width="180" height="10" fill="white"/>
          </mask>
          <g mask="url(#mask0)">
            <g filter="url(#filter0_d)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M90 20C155.787 20 209.118 17.7614 209.118 15C209.118 12.2386 155.787 10 90 10C24.2131 10 -29.1177 12.2386 -29.1177 15C-29.1177 17.7614 24.2131 20 90 20Z" fill="black" fill-opacity="0.01"/>
            </g>
          </g>
          <defs>
            <filter id="filter0_d" x="-33.1177" y="6" width="246.235" height="18" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
              <feOffset/>
              <feGaussianBlur stdDeviation="2"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
            </filter>
          </defs>
        </svg>
      </div>
      <div class="shadow_hider"><div class="shadow_overlay"></div></div>
    </div>
  </div>
</div>
