import { of as observableOf, Observable } from 'rxjs';

import { switchMap, take, tap, catchError, map, mapTo, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { BugTrackerService } from '@atlaz/core/services/bag-tracker.service';

import { AtlazApiV2Service } from '../../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { PageStatus, STATUS_CODES } from '../../../permissions/interfaces/page-status.interface';
import { defaultExpand, TASK_PL } from '../../../constants/';
import { getTaskBySlug } from '../../../ngrx/reducers/task.reducer';
import { HandleResponseAction } from '../../../ngrx/actions/root.action';
import { AppState, OPENED_TASK } from '../../../ngrx/state';
import { Task } from '../../../interfaces/';
import { TaskDetailPageRelatedDataService } from './task-detail-page-related-data.service';
import { TaskDetailPageNavigateService } from './task-detail-page-navigate.service';
import { OpenedTaskOpenAction } from '../../ngrx/actions/opened-task.action';
import { SegmentService } from '../../../atlaz-bnp/services/intergations/segment/segment.service';
import * as fromLoadedPages from '../../../loaded-data/store/loaded-data.reducer';
import { TaskDeletingWatcherService } from './task-deleting-watcher.service';
import { Features } from '../../../libs/paywall/features.constants';
import { PaywallService } from '../../../libs/paywall/paywall.service';
import { SEARCH } from '../../../path.routing';

interface TaskPageStatus extends PageStatus {
  taskId?: number;
}

@Injectable()
export class TaskDetailPageResolver implements Resolve<TaskPageStatus> {
  constructor(
    private _apiV2: AtlazApiV2Service,
    private _store: Store<AppState>,
    private _segment: SegmentService,
    private _paywall: PaywallService,
    private _bugTracker: BugTrackerService,
    private _taskNavValidator: TaskDetailPageNavigateService,
    private _deletingWatcher: TaskDeletingWatcherService,
    private _detailLoader: TaskDetailPageRelatedDataService
  ) {}

  readonly er500 = err => {
    this._bugTracker.error(err);
    return observableOf({ statusCode: STATUS_CODES.SERVER_ERROR });
  };

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TaskPageStatus> {
    // taskSlug is <PROJECT_SHORT_NAME>-<NUMBER-IN-PROJECT> (ex. ATLAZ-43) or just taskId
    const taskSlug = route.params['taskSlug'];
    this._deletingWatcher.stopWatching();
    if (!taskSlug) {
      return observableOf({
        statusCode: STATUS_CODES.NOT_FOUND
      });
    }

    return this.checkPermissions(taskSlug).pipe(
      map(status => {
        if (!status.taskId) {
          throw status;
        }
        return status;
      }),
      switchMap((status: TaskPageStatus) =>
        this._taskNavValidator.checkNavigation(taskSlug, state, status).pipe(catchError(this.er500), mapTo(status))
      ),
      switchMap(this.loadDetails.bind(this)),
      tap((status: TaskPageStatus) => {
        this._store.dispatch(
          new OpenedTaskOpenAction(status.taskId && status.statusCode === STATUS_CODES.OK ? status.taskId : null)
        );
        if (status.taskId && status.statusCode === STATUS_CODES.OK) {
          this._deletingWatcher.startWatching(status.taskId);
        }
      }),
      catchError(status => {
        return observableOf(status);
      })
    );
  }

  checkPermissions(taskSlug): Observable<TaskPageStatus> {
    // take one is required to complete the stream
    return this._store.pipe(
      getTaskBySlug(taskSlug),
      take(1),
      withLatestFrom(
        this._store.select(fromLoadedPages.wasOpenedTaskFn),
        (task, isLoaded) => (task && isLoaded(task['id']) ? task : undefined)
      ),
      switchMap(
        (task: Task) =>
          task ? observableOf({ statusCode: STATUS_CODES.OK, taskId: task.id }) : this.loadTaskRequest(taskSlug)
      )
    );
  }

  loadTaskRequest(taskSlug) {
    return this._apiV2
      .get([TASK_PL, taskSlug], {
        expand: ['parent', ...defaultExpand[TASK_PL], ...defaultExpand[SEARCH], ...defaultExpand[OPENED_TASK]]
      })
      .pipe(
        map(resp => {
          this._store.dispatch(new HandleResponseAction(resp));
          return {
            statusCode: STATUS_CODES.OK,
            taskId: resp['data']['id']
          };
        }),
        catchError(err => {
          // by default unknown error;
          let status = err.status;
          if (status === STATUS_CODES.PAYMENT_REQUIRED) {
            let feature = Features.CanAddTask;
            try {
              feature = JSON.parse(err._body).paywall || feature;
            } catch (e) {
              console.log(e);
            }
            this._paywall.showPayWall(feature, true);
          } else if (
            ![STATUS_CODES.FORBIDDEN, STATUS_CODES.NOT_FOUND, STATUS_CODES.CONNECTION_PROBLEM].includes(status)
          ) {
            status = STATUS_CODES.SERVER_ERROR;
            this._bugTracker.warn('TaskDetailResolver, Server error: unknown status', err);
          }

          return observableOf({
            statusCode: status
          });
        })
      );
  }

  private doLoadDetail(status: TaskPageStatus) {
    return this._detailLoader.loadTaskDetails(status.taskId).pipe(catchError(this.er500), mapTo(status));
  }

  private loadDetails(status: TaskPageStatus) {
    return this._store.select(fromLoadedPages.wasOpenedTaskFn).pipe(
      take(1),
      switchMap(isOpenedFn => (isOpenedFn(status.taskId) ? observableOf(status) : this.doLoadDetail(status))),
      catchError(e => {
        console.log(e);
        throw e;
      })
    );
  }
}
