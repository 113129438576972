
import {tap, take, switchMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { TASKS } from '../../../path.routing';
import { AppState } from '../../../ngrx/state';
import { findTaskKey, getTaskById } from '../../../ngrx/reducers/task.reducer';
import { RouterNavigateService } from '../../../shared/services/router-navigate.service';

@Injectable()
export class TaskDetailPageNavigateService {
  constructor(private _store: Store<AppState>, private _routerNav: RouterNavigateService) {}

  checkNavigation(taskSlug, state, status) {
    return this._store.pipe(
      (getTaskById(status.taskId)),
      switchMap(task => this._store.pipe((findTaskKey(task)))),
      take(1),
      tap((taskKey: string) => {
        if (taskKey && taskKey !== '' && taskKey !== taskSlug) {
          this.navitateToTask(state, taskKey);
        }
      }),);
  }

  private navitateToTask(state: RouterStateSnapshot, taskKey: string) {
    if (this._routerNav.navigated) {
      this._routerNav.navigateToTask(taskKey);
    } else {
      const taskPathRegext = new RegExp(`(\/|:)(${TASKS})\/.+$`);
      const stateUrl = state.url.replace(taskPathRegext, '/$2/' + taskKey);
      this._routerNav.navigateByUrl(stateUrl);
    }
  }
}
