import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { getEntitySelector } from '../../../../ngrx/functions/selectors';
import { COLLECTION_PL } from '../../../../constants';
import { Collection } from '../../../../interfaces';
import { AppState, ESInterface } from '../../../../ngrx/state';
import { Store } from '@ngrx/store';

@Component({
  selector: 'collection-list-independent',
  templateUrl: 'collection-list-independent.html',
  styleUrls: ['./collection-list-independent.component.scss']
})
export class CollectionListIndependentComponent implements OnInit, OnDestroy {
  @Input() selectedCollections$: BehaviorSubject<number[]>;

  public collectionState: ESInterface<Collection>;
  public subscription;
  public isCreateCollection = false;

  constructor(private _store: Store<AppState>) {}

  ngOnInit(): any {
    this.subscription = this._store.pipe(
      (getEntitySelector(COLLECTION_PL)))
      .subscribe((state: ESInterface<Collection>) => {
        this.collectionState = state;
      });
  }

  ngOnDestroy(): any {
    this.subscription.unsubscribe();
  }

  isSelectedCollection(collectionId) {
    return this.selectedCollections$.getValue().includes(collectionId);
  }

  onSelectCollection(collectionId) {
    if (this.isSelectedCollection(collectionId)) {
      this.selectedCollections$.next(this.selectedCollections$.getValue().filter(id => id !== collectionId));
    } else {
      this.selectedCollections$.next([...this.selectedCollections$.getValue(), collectionId]);
    }
  }

  onToggleCreateCollection() {
    this.isCreateCollection = !this.isCreateCollection;
  }
}
