<ng-container *ngIf="showForm$ | async">
  <form [formGroup]="form"
        (ngSubmit)="onSubmit()"
  >
    <form-errors></form-errors>
    <div class="form_group">
      <label for="link-type">Dependency Type</label>
      <select id="link-type" formControlName="type">
        <option *ngFor="let type of  possibleTypes$ | async" [ngValue]="type.id">{{type.name}}</option>
      </select>
    </div>
    <div class="form_group">
      <span class="label">Tasks</span>
      <div class="form_group" formArrayName="inwardTasks" *ngFor="let item of form.get('inwardTasks')['controls']; let i = index;">
        <!--[initialFocusFalse]="!i"-->
        <a-searchable-select
          formControlName="{{i}}"
          [labelId]="i"
          [inputValues]="possibleTasks$ | async"
          [matchProp]="'title'"
          [openAfterInit]="true"
        >
        </a-searchable-select>
      </div>
      <help-block [control]="form.get('inwardTasks')" [messages]="{arrayNotEmpty: 'Choose at least one task.'}"></help-block>
      <span class="pseudo_link" (click)="onAddTaskInput()">+ Add Task</span>
    </div>
    <br>
    <button a-button class="btn_primary button" type="submit" (click)="onSubmit()">Save</button>
    <button a-button class="btn_text button" type="button" (click)="onCancel()">Cancel</button>
  </form>
</ng-container>

<div *ngIf="createTaskPopup"
     class="add-task-popup"
     a-context-popup
     [attr.draggable-disabled]="true"
     [title]="'Add New Task'"
     (close)="onCloseCreateTaskPopup()"
>
  <global-add-task-form
    [hideSaveAndEdit]="true"
    [showColumn]="true"
    [showSwimlane]="true"
    [cacheKey]="'addTaskFromTaskLink'"
    (close)="onCloseCreateTaskPopup()"
    (taskCreated)="onTaskCreated($event)"
  ></global-add-task-form>
</div>
