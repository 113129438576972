import * as ROUTER_PATH from './path.routing';
import { Task } from './interfaces';
import { Router } from '@angular/router';
import { ScoringFactor } from './board/backlog-board/constants/backlog.constants';
import { BACKLOG_CRITERIA } from './path.routing';

export class AppUrls {
  static getUrlOverview() {
    return [ROUTER_PATH.SEP];
  }

  static getUrlOverviewWithBoardsCreating() {
    return [
      {
        outlets: {
          [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.INITIATE_BOARDS]
        }
      }
    ];
  }

  static getUrlBoards(): string | any[] {
    return [ROUTER_PATH.BOARDS];
  }

  static getUrlProjectSettings(projectId): string | any[] {
    return [ROUTER_PATH.SEP, ROUTER_PATH.PROJECT, projectId];
  }

  static getUrlVersionTasks(versionId): string | any[] {
    return [ROUTER_PATH.SEP, ROUTER_PATH.VERSION, versionId];
  }

  static getUrlBoard(boardId) {
    return [ROUTER_PATH.SEPARATOR, ROUTER_PATH.BOARDS, boardId];
  }

  static taskUrl(task: Task) {
    return AppUrls.getUrlTask(
      task.roadMapLinkedTaskFields && task.roadMapLinkedTaskFields.taskShortName
        ? task.roadMapLinkedTaskFields.taskShortName
        : task.taskKey || task.id
    );
  }

  static getUrlTask(taskId) {
    return [ROUTER_PATH.SEP, { outlets: { [ROUTER_PATH.OPENED_TASK_OUTLET]: [ROUTER_PATH.TASKS, taskId] } }];
  }

  static getUrlTaskOnBoard(boardId, taskId) {
    return [
      {
        outlets: {
          primary: [ROUTER_PATH.BOARDS, boardId],
          [ROUTER_PATH.OPENED_TASK_OUTLET]: [ROUTER_PATH.TASKS, taskId]
        }
      }
    ];
  }

  static getCriteriaDetails(boardId: number, criteriaIdOrType: number | ScoringFactor) {
    return [
      ROUTER_PATH.SEP,
      { outlets: { [ROUTER_PATH.POPUP_OUTLET]: [BACKLOG_CRITERIA, boardId, criteriaIdOrType] } }
    ];
  }

  static getImportStartedPopup() {
    return [ROUTER_PATH.SEP, { outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.IMPORT_HAS_STARTED] } }];
  }

  static getImportFromGetStarted() {
    return [ROUTER_PATH.SEP, ROUTER_PATH.GET_STARTED, ROUTER_PATH.IMPORT];
  }

  static getUrlGlobalSearch(filterParams: any) {
    return [ROUTER_PATH.SEARCH, filterParams];
  }

  static getUrlDeactivateOutlet(outletName: string) {
    return [ROUTER_PATH.SEP, { outlets: { [outletName]: null } }];
  }

  static getUrlLoginAccessError() {
    return [ROUTER_PATH.SEPARATOR, ROUTER_PATH.ACCESS_TO_COMPANY_DENIED];
  }

  static getUrlLoginError() {
    return [ROUTER_PATH.SEPARATOR, ROUTER_PATH.LOGIN_ERROR];
  }

  static getLogout() {
    return [ROUTER_PATH.SEPARATOR, ROUTER_PATH.LOGOUT];
  }

  static getUrlJiraInviteMembers() {
    return [ROUTER_PATH.SEP, { outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.JIRA_INVITE_MEMBERS] } }];
  }

  static getUrlNewBoard() {
    return [ROUTER_PATH.SEP, { outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.BOARDS, ROUTER_PATH.NEW] } }];
  }

  static getUrlCopyBoard(boardId) {
    return [
      ROUTER_PATH.SEP,
      { outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.BOARDS, ROUTER_PATH.COPY, boardId] } }
    ];
  }

  static getUrlNewProject() {
    return [ROUTER_PATH.SEP, { outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.PROJECT, ROUTER_PATH.NEW] } }];
  }

  static getUrlNewCollection() {
    return [ROUTER_PATH.SEP, { outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.COLLECTIONS, ROUTER_PATH.NEW] } }];
  }

  static getUrlNewVersion(projects) {
    return [
      ROUTER_PATH.SEP,
      { outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.VERSIONS, ROUTER_PATH.NEW, projects] } }
    ];
  }

  static getUrlMassInviteMembers() {
    return [ROUTER_PATH.SEP, { outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.MASS_INVITE, ROUTER_PATH.NEW] } }];
  }

  static getUrlCollectionSettings(collectionId) {
    return [
      ROUTER_PATH.SEP,
      { outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.COLLECTION_SETTINGS, collectionId] } }
    ];
  }

  static getUrlStartSprint(boardId) {
    return [ROUTER_PATH.SEP, { outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.SPRINT_START, boardId] } }];
  }

  static getUrlStopSprint(boardId) {
    return [ROUTER_PATH.SEP, { outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.SPRINT_STOP, boardId] } }];
  }

  static getUrlReleaseSprint(boardId) {
    return [ROUTER_PATH.SEP, { outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.SPRINT_RELEASE, boardId] } }];
  }

  static getUrlClosedBoard(boardName) {
    return [ROUTER_PATH.SEP, { outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.CLOSED_BOARD, boardName] } }];
  }

  static getUrlWipForm(boardId: number, columnId: number) {
    return [
      ROUTER_PATH.SEP,
      {
        outlets: {
          [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.BOARDS, boardId, ROUTER_PATH.COLUMN, columnId, ROUTER_PATH.WIP]
        }
      }
    ];
  }

  static getUrlPushAllTasks(columnId) {
    return [ROUTER_PATH.SEP, { outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.PUSH_ALL_TASKS, columnId] } }];
  }

  static getUrlBacklogChart(boardId: number) {
    return [ROUTER_PATH.SEPARATOR, ROUTER_PATH.BACKLOG_CHART, boardId];
  }

  static getUrlBacklogChartWithClosedTask(boardId: number) {
    return [
      ROUTER_PATH.SEPARATOR,
      {
        outlets: {
          [ROUTER_PATH.OPENED_TASK_OUTLET]: null,
          primary: [ROUTER_PATH.BACKLOG_CHART, boardId]
        }
      }
    ];
  }

  static getUrlBacklogScoring(boardId: number) {
    return [ROUTER_PATH.SEPARATOR, ROUTER_PATH.SCORING_TABLE, boardId];
  }

  static getUrlBurndownChart(boardId: number) {
    return [ROUTER_PATH.SEPARATOR, ROUTER_PATH.BURNDOWN_CHART, boardId];
  }

  static getUrlSprintBoardHistory(boardId: number) {
    return [ROUTER_PATH.SEPARATOR, ROUTER_PATH.SPRINT_BOARD_HISTORY, boardId];
  }

  static getUrlVelocityReport(boardId: number) {
    return [ROUTER_PATH.SEPARATOR, ROUTER_PATH.VELOCITY_REPORT, boardId];
  }

  static getUrlReleaseReport(versionId: number) {
    return [ROUTER_PATH.SEPARATOR, ROUTER_PATH.SPRINT_RELEASE_REPORT, versionId];
  }

  static getUrlCompanySettingsImport() {
    return [ROUTER_PATH.COMPANY_SETTINGS, ROUTER_PATH.IMPORT];
  }

  static getUrlCompanySettingsBilling() {
    return [ROUTER_PATH.SEPARATOR, ROUTER_PATH.COMPANY_SETTINGS, ROUTER_PATH.BILLING];
  }

  static getUrlSlackIndex(boardId: number) {
    return [{ outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.SLACK, { board: boardId }] } }];
  }

  static getUrlNewSlackAlert(boardId: number) {
    return [
      { outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.SLACK, { board: boardId }, ROUTER_PATH.SLACK_NEW] } }
    ];
  }

  static getUrlAddSlackTeam(boardId: number) {
    return [
      { outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.SLACK, ROUTER_PATH.ADD_INTEGRATION, { board: boardId }] } }
    ];
  }

  static getUrlAttachSlideshow(taskId: number, attachId: number) {
    return [
      ROUTER_PATH.SEP,
      {
        outlets: {
          [ROUTER_PATH.POPUP_OUTLET]: ['attach', { task: taskId, attach: attachId }]
        }
      }
    ];
  }

  static getUrlInviteLinkPopup() {
    return ['', { outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.INVITE_LINK_POPUP] } }];
  }

  static getUrlGithubSettingsIndex() {
    return [{ outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.GITHUB_SETTINGS] } }];
  }

  static getUrlGithubSettingsAddCompany() {
    return [{ outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.GITHUB_SETTINGS, ROUTER_PATH.ADD_INTEGRATION] } }];
  }

  static getUrlGithubManageRepos() {
    return [{ outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.GITHUB_SETTINGS, ROUTER_PATH.GIT_MANATE_REPOS] } }];
  }

  static getUrlBitbucketSettingsIndex() {
    return [{ outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.BITBUCKET_SETTINGS] } }];
  }

  static getUrlBitbucketSettingsAddCompany() {
    return [{ outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.BITBUCKET_SETTINGS, ROUTER_PATH.ADD_INTEGRATION] } }];
  }

  static getUrlGitlabSettingsIndex() {
    return [{ outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.GITLAB_SETTINGS] } }];
  }

  static getUrlGitlabSettingsAddCompany() {
    return [{ outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.GITLAB_SETTINGS, ROUTER_PATH.ADD_INTEGRATION] } }];
  }

  static getUrlGitlabManageRepos() {
    return [{ outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.GITLAB_SETTINGS, ROUTER_PATH.GIT_MANATE_REPOS] } }];
  }

  static getUrlIntercomSettingsIndex() {
    return [{ outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.INTERCOM_SETTINGS] } }];
  }

  static getUrlIntercomSettingsAddCompany() {
    return [{ outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.INTERCOM_SETTINGS, ROUTER_PATH.ADD_INTEGRATION] } }];
  }

  static getUrlJiraSettingsIndex() {
    return [{ outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.JIRA_SETTINGS, ROUTER_PATH.ADD_INTEGRATION] } }];
  }

  static getUrlJiraSettingsAddCompany() {
    return [{ outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.JIRA_SETTINGS, ROUTER_PATH.ADD_INTEGRATION] } }];
  }

  static getDirectTaskUrl(router: Router, id: number | string, boardId: number) {
    return window.location.origin + router.serializeUrl(router.createUrlTree(AppUrls.getUrlTaskOnBoard(boardId, id)));
  }

  static getUrlScoringTypeChooser() {
    return [{ outlets: { [ROUTER_PATH.POPUP_OUTLET]: [ROUTER_PATH.SCORING_TYPE_CHOOSER] } }];
  }
}
