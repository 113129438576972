import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Column } from '../../../../interfaces';

@Component({
  selector: 'columns-filter-list',
  templateUrl: './columns-filter-list.component.html',
  styleUrls: ['./columns-filter-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnsFilterListComponent implements OnInit {
  @Input()
  set columns(v: Column[]) {
    this.visibleColumns = [];
    v.forEach(column => {
      if (column.subColumnsIds.length) {
        column.subColumns.data.forEach(subColumn =>
          this.visibleColumns.push({ ...subColumn, name: column.name + ' (' + subColumn.name + ')' })
        );
      } else {
        this.visibleColumns.push(column);
      }
    });
  }
  @Input() selectedColumns: Column[];

  @Output() addColumn = new EventEmitter();
  @Output() removeColumn = new EventEmitter();
  public visibleColumns = [];

  public checkSelectedColumn: { [id: number]: boolean } = {};

  constructor() {}

  ngOnInit() {
    this.selectedColumns.forEach((column: Column) => {
      this.checkSelectedColumn[column.id] = true;
    });
  }

  onSetFilter(column: Column) {
    if (!this.checkSelectedColumn[column.id]) {
      this.checkSelectedColumn[column.id] = true;
      this.addColumn.emit(column);
    } else {
      this.checkSelectedColumn[column.id] = false;
      this.removeColumn.emit(column);
    }
  }
}
