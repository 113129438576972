
import {filter, distinctUntilChanged, combineLatest, pluck, take} from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable ,  Observer } from 'rxjs';
import { User } from '../../../../../interfaces/user';
import { AppState } from 'app/ngrx/state';
import { Store } from '@ngrx/store';
import { getAllUsers } from '../../../../../ngrx/reducers/user.reducer';
import { Import } from '../../../../../interfaces/import';
import { ACTIVE_IMPORT } from '../../../../../constants/index';
import { isPresent } from '../../../../../../helpers/object';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../../../../shared/services/form-v2.service';
import { JsonApiSingeModelResponse } from '../../../../../shared/services/app/web-socket/http-response';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { RouterNavigateService } from '../../../../../shared/services/router-navigate.service';
import { UserGetIndexAction } from '../../../../../ngrx/actions/user.actions';
import { IMPORT } from '../../../../../constants/entity';
import { ActiveImportConfirmAction } from '../../ngrx/action/active-import.action';
import { isAbsent } from '../../../../../../helpers';

@Component({
  selector: 'jira-invite-members',
  templateUrl: './jira-invite-members.component.html',
  styleUrls: ['./jira-invite-members.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormV2Service]
})
export class JiraInviteMembersComponent implements OnInit {
  formObserver: Observer<any> = {
    next: (resp: JsonApiSingeModelResponse<any>) => {
      this._routerNav.deactivatePopupOutlet();
    },
    error: error => {},
    complete: () => {}
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.edit,
    entityToEdit: IMPORT,
    formObserver: this.formObserver,
    prepareFormValue: formValue => formValue
  };

  public members$: Observable<User[]>;
  public form: FormGroup;
  public isCheckedAll = false;

  private import$: Observable<Import>;
  private importId: number = null;

  constructor(
    private _store: Store<AppState>,
    private _fb: FormBuilder,
    private _formService: FormV2Service,
    private _routerNav: RouterNavigateService
  ) {}

  ngOnInit() {
    this.initImport();
    this.initMembers();
    this.initForm();
    this.import$.pipe(
      take(1),
      filter(isAbsent),)
      .subscribe(() => {
        this._routerNav.deactivatePopupOutlet();
      });
  }

  initForm() {
    this.form = this._fb.group({
      id: '',
      action: 'confirm',
      inviteUsers: this._fb.array([])
    });
    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  initImport() {
    this.import$ = this._store.pipe(pluck<AppState, Import>(ACTIVE_IMPORT, 'import'),distinctUntilChanged(),);
    this.import$.pipe(
      take(1),
      filter(isPresent),)
      .subscribe(activeImport => {
        this.importId = activeImport.id;
        this._store.dispatch(
          new UserGetIndexAction({ importId: activeImport.id, getAllUsers: 1, offser: 0, limit: 300 })
        );
      });
  }

  initMembers() {
    this.members$ = <Observable<User[]>>this._store.pipe(
      (getAllUsers),
      combineLatest(this.import$.pipe(filter(isPresent)), (users: User[], activeImport: Import) =>
        users.filter(user => user.importId === activeImport.id)
      ),);
  }

  checkboxChecked(id: number, isChecked: boolean) {
    const inviteUsers = <FormArray>this.form.controls.inviteUsers;
    const index = inviteUsers.controls.findIndex(x => x.value === id);

    if (isChecked) {
      if (index === -1) {
        inviteUsers.push(new FormControl(id));
      }
    } else {
      inviteUsers.removeAt(index);
    }
  }

  onClickCheckbox(id: number, isChecked: boolean) {
    this.checkboxChecked(id, isChecked);
  }

  checkAll(isChecked) {
    this.isCheckedAll = isChecked;

    this.members$.pipe(
      take(1),
      filter(isPresent),)
      .subscribe(members => {
        members.forEach((member: User) => this.checkboxChecked(member.id, isChecked));
      });
  }

  onSubmit() {
    this.formServiceParams.prepareFormValue = formValue => {
      const copyFormValue = { ...formValue };
      copyFormValue['id'] = this.importId;
      copyFormValue['inviteUsers'] = { add: this.form.value['inviteUsers'] };
      formValue = copyFormValue;
      return formValue;
    };

    this._formService.submit();
  }

  onPopUpCloseEvent(event: UIEvent) {
    const target = <HTMLDivElement>event.target;
    if (target && target.classList && target.classList.contains('close')) {
      this.onSkip();
    }
  }

  onSkip() {
    this.import$.pipe(
      take(1),
      filter(isPresent),)
      .subscribe(activeImport => {
        this._store.dispatch(new ActiveImportConfirmAction({ id: activeImport.id }));
      });
    this._routerNav.deactivatePopupOutlet();
  }
}
