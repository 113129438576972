<a-center-container-scrollable>
  <div class="choose_templates_form" [ngClass]="{hidden: isCreateProject}">
    <div class="header_container">Choose Board Templates</div>
    <div class="form_body">
      <board-template-chooser [isOnBoarding]="true" [chooseTemplatesError]="showTemplateChooseErr$ | async" (changeSelection)="onSelectionUpdated($event)"></board-template-chooser>
    </div>
    <div class="form_footer">
      <button a-button type="button" class="btn_primary" (click)="onUseTemplates()">Use Templates {{selectedTemplates.length ? '(' + selectedTemplates.length + ')' : ''}}</button>
      <button a-button type="button" class="btn_text" [pending]="isSkipPending" (click)="onSkip()">Continue without templates</button>
    </div>
  </div>
  <div [ngClass]="{hidden: !isCreateProject}">
    <div a-popup [title]="'Create Project for Selected Templates'" [isHideCloseBtn]="true">
      <create-new-project-form [submit$]="submit$"
                               [source]="'boardTemplates'"
                               [autoFocus]="isCreateProject"
                               (isValidForm)="isValidForm = $event"
      ></create-new-project-form>
      <div class="create_proj_buttons">
        <button a-button class="btn_text" tabindex="0" (click)="onBackToTemplates()">
          Back to Templates
        </button>
        <button a-button class="btn_primary"
                (click)="onSubmit()"
                [pending]="_formService.isPending$ | async"
        >
          Create Project
        </button>
      </div>
    </div>
  </div>
</a-center-container-scrollable>
<overlay></overlay>
