import { SimpleChanges } from '@angular/core';

export * from './date';
export * from './array';
export * from './get-api-url';
export * from './get-stack-trace';
export * from './object';
export * from './object-to-array';
export * from './html-helper';
export * from './uuid';
export * from './client-uuid';
export * from './string';
export * from './backlog';
export * from './url';
export * from './sprint';

import * as R from 'ramda';
import { isPresent } from './object';
import { mouseButtons } from '../app/constants/mouse-buttons';

export const isLeftMouseButton = (event: MouseEvent) => event.button === mouseButtons.left;
export const isNumericalString = (str: string) => /^\d+$/.test(str);
export const toBoolean = value => !!value;
export const invertBoolean = value => !value;
export const identity = R.identity;
export const complement = R.complement;
export const invertObj = R.invertObj;

export const isInsideRect = (rect: ClientRect) => (event: MouseEvent) => {
  const insideByX: boolean = event.clientX > rect.left && event.clientX < rect.right;
  const insideByY: boolean = event.clientY > rect.top && event.clientY < rect.bottom;
  return insideByX && insideByY;
};

export const objectIfEmpty = xs => (xs ? xs : {});
export const arrayIfEmpty = xs => (xs ? xs : []);

export const allPass = R.allPass;
export const anyPass = R.anyPass;
export const filter = R.filter;
export const whereEq = R.whereEq;
export const both = R.both;
export const not = R.not;
export const compose = R.compose;
export const either = R.either;
export const memoize = R.memoize;
export const curry = R.curry;
export const isEmpty = R.isEmpty;

export const consoleLog = (...note) => value => {
  console.log(value, ...note);
  return value;
};

export const defaultValue = defValue => (value: any) => value || defValue;
export const compareObjectKeys = (keys: string[]) => (a: any, b: any) => keys.some(key => a[key] === b[key]);

export const comparePlainObjects = (a, b) =>
  Object.keys(a).length === Object.keys(b).length && Object.keys(a).every(key => a[key] === b[key]);
export const compareObjectByKeys = (keys: string[]) => (a: any, b: any) => keys.every(key => a[key] === b[key]);

export const trackById = (index, item) => {
  return item.id;
};
export const trackByName = (index, item) => {
  return item.name;
};

export const inputChanged = inputName => (changes: SimpleChanges) =>
  changes[inputName] && changes[inputName].currentValue !== changes[inputName].previousValue;

export const getLength = x => x.length;
export const isAbsent = x => !x;
export const sort = compareFn => sortableItem => sortableItem.sort(compareFn);
export const isEqualId = id => (xs: {}) => xs && xs['id'] === id;
export const isNotEqualId = id => (xs: {}) => xs && xs['id'] !== id;
export const isUnequalType = type => (xs: {}) => xs && xs['type'] !== type;
export const isEqualType = type => (xs: {}) => xs && xs['type'] === type;
export const toArray = xs => xs.toArray();

export const getFromLocalStorage = key => localStorage.getItem(key);
export const getFeatureAvailability = key => localStorage.getItem('experimental_' + key);
export const setToLocalStorage = key => value => localStorage.setItem(key, value);
// it should be function to work correctly after that user logged in
export const getActiveUserId = () => +getFromLocalStorage('activeUserId');
export const isEnableCopyTaskTitleWithUrl = () => getFromLocalStorage('HIDDEN_FEATURE_COPY_TASK_TITLE_WITH_URL');

export const createFilterByPart = (part: {}) => filter(whereEq(part));
export const createFilterByBoardId = boardId => createFilterByPart({ board: boardId });
export const createFilterByTaskId = taskId => createFilterByPart({ task: taskId });
export const groupByProp = (prop: string) => (list: any[]): { [id: number]: any } =>
  list.reduce((acc, item) => {
    acc[item[prop]] = item;
    return acc;
  }, {});

export const createFindByPart = partObject => R.find(whereEq(partObject));
export const createFindByShortName = shortName => createFindByPart({ shortName: shortName });
export const createFindByProjectId = projectId => createFilterByPart({ project: projectId });

/**
 * @input state: ESInterface {[id:number] : Entity}
 *
 * @return (id) -> entity
 */
export const getById = modelState => R.flip(R.prop)(modelState);
export const getModelsByIds = ids => modelState => ids.map(R.flip(R.prop)(modelState));

export const getModelStateByIds = ids => modelState =>
  ids.reduce((state, id) => Object.assign(state, { [id]: modelState.entities[id] }), {});
export const flip = R.flip;

/**
 *
 * @param fn : firstArg => secondArg => returnValue;
 * ex. const hasProperty = object => prop => object.hasOwnProperty(prop);
 *
 * flip(hasProperty) is equal prop => object => object.hasOwnProperty(prop);
 *
 */
export const flipArrow = fn => second => first => fn(first)(second);

export const isNumber = R.is(Number);
export const getId = R.ifElse(isPresent, R.prop('id'), R.always(null));

export const isNotEmpty = R.complement(R.isEmpty);
export const toString = val => val + '';

export const groupByRelation = relationKey => relationItems =>
  relationItems.reduce((acc, relationItem: any) => {
    if (!relationItem[relationKey]) {
      return acc;
    }

    if (Array.isArray(relationItem[relationKey])) {
      relationItem[relationKey].forEach(item => {
        if (!acc[item]) {
          acc[item] = [];
        }
        acc[item].push(relationItem);
      });
    } else {
      if (!acc[relationItem[relationKey]]) {
        acc[relationItem[relationKey]] = [];
      }
      acc[relationItem[relationKey]].push(relationItem);
    }

    return acc;
  }, {});

export const fromCamelToDash = url => url.replace(/([A-Z])/g, $1 => '-' + $1.toLowerCase());

export function getPageGeneratedAt() {
  return Math.round(Date.now() / 1000);
}

export function getProperty<T extends {}, K extends keyof T = keyof T>(source: T, key: K): T[K] {
  return source ? source[key] : undefined;
}

export function prop<K extends string>(key: K) {
  return <T extends Record<K, {}>>(source: T) => getProperty<T, K>(source, key);
}
