<div [ngSwitch]="notification.entityType" [class.archived_list]="isArchivedFilterActive">
  <import-notification-preview *ngSwitchCase="ENTITIES.IMPORT" [notification]="notification"></import-notification-preview>
  <board-notification-preview *ngSwitchCase="ENTITIES.BOARD" [notification]="notification"></board-notification-preview>

  <div *ngSwitchDefault class="notification_card" [ngClass]="{bg_none: isGroupNotificationOpened}">

    <div class="viewed_marker" *ngIf="!notification.seen && !notification.archived"></div>

    <div (click)="onArchiveNotification()" class="archive_notif_controll"
         [ngClass]="{
          hidden_control: isGroupNotificationOpened,
          archive_group: notification.subNotifications,
          archived_control: isArchivedFilterActive
       }">
    </div>

    <a *ngIf="hasUrl$ | async" class="task_link_block" [routerLink]="(url$ | async)?.link" [fragment]="(url$ | async)?.fragment" (click)="markAsSeen()"></a>
    <div class="avatar_container" *ngIf="!isGroupNotificationOpened">
      <div class="author_avatar">
        <a-avatar class="notification_avatar"
                  [user]="user$ | async"
        ></a-avatar>
      </div>
    </div>
    <div (click)="onExpandNotification()"
         *ngIf="notification.subNotifications && !isGroupNotificationOpened"
         class="group_expand_notif">
    </div>
    <div *ngIf="!isGroupNotificationOpened" class="main_info_container"
         [ngClass]="{'info_viewed': notification.seen && !notification.archived, 'archived_title': notification.archived}">
      <p *ngIf="!isGroupNotificationOpened" class="task_title">
        <span class="title_text">{{title$ | async}}</span>
        <span *ngIf="notification.subNotifications" class="num_subtnotif">
          {{notification.subNotifications.length > 99 ? '+99': notification.subNotifications.length}}
        </span>
      </p>
      <p class="author_name"
         *ngIf="!isGroupNotificationOpened">
        <span>{{(user$ | async)?.fullname}}</span>
        | <span>{{notification.createdAt | timeAgo : "short"}}</span>
      </p>
      <ng-container *ngIf="!isGroupNotificationOpened">
        <ng-container *ngIf="notification.type === 'MENTION' || notification.type === 'COMMENT'; else noMarkdownText">
          <markdown class="notification_text" [data]="notification.preparedText | textToHtml | markdownBreak | markdownMention"></markdown>
        </ng-container>
        <ng-template #noMarkdownText>
          <div class="notification_text" [innerHTML]="notification.preparedText | linkFromText | textBreak | loggedTime | noBrackets"></div>
        </ng-template>
      </ng-container>
    </div>
    <div [hidden]="!isGroupNotificationOpened" class="group_notif_block">
      <div class="group_notif_header" (click)="onExpandNotification()">
        <div (click)="onArchiveNotification()" class="archive_notif_controll"
             [ngClass]="{
              hidden_control: !isGroupNotificationOpened,
              archive_group: notification.subNotifications,
              archived_control: isArchivedFilterActive
            }">
        </div>
        <user-multiple-avatars-wrapper
          class="multiple_avatars"
          [userIds]="subNotificationsUsersIds"
        ></user-multiple-avatars-wrapper>
        <p [ngClass]="{'group_title_viewed': !notification.seen && !notification.archived}">{{title$ | async}}</p>
      </div>
      <div *ngFor="let subNotification of notification.subNotifications"
           [routerLink]="(url$ | async)?.link"
           [fragment]="(url$ | async)?.fragment"
           class="notification_card"
           [ngClass]="{sub_notification_card: subNotification != null}">
        <subnotification-preview
          [subNotification]="subNotification"
          [isArchivedFilterActive]="isArchivedFilterActive"
        ></subnotification-preview>
      </div>
    </div>
  </div>
</div>
