import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtlazGuiModule } from '../../../atlaz-gui/atlaz-gui.module';
import { BillingActionsButtonsComponent } from '@atlaz/billing/shared/components/billing-actions-buttons/billing-actions-buttons.component';
import { PaymentSuccessPopupComponent } from '@atlaz/billing/shared/components/payment-success-popup/payment-success-popup.component';
import { PaymentDeniedPopupComponent } from '@atlaz/billing/shared/components/payment-denied-popup/payment-denied-popup.component';
import { SharedModule } from '../../../shared/shared.module';
import { PricingPlansComponent } from '@atlaz/billing/shared/components/pricing-plans/pricing-plans.component';
import { PricingPlansV3Component } from '@atlaz/billing/shared/components/pricing-plans-v3/pricing-plans-v3.component';
import { PricingFaqComponent } from '@atlaz/billing/shared/components/pricing-faq/pricing-faq.component';
import { PricingPageSliderComponent } from '@atlaz/billing/shared/components/pricing-page-slider/pricing-page-slider.component';
import { BillingTitleComponent } from '@atlaz/billing/shared/components/billing-title/billing-title.component';

const sharedComponents = [
  BillingActionsButtonsComponent,
  PaymentSuccessPopupComponent,
  PaymentDeniedPopupComponent,
  PricingPlansComponent,
  PricingPlansV3Component,
  PricingFaqComponent,
  PricingPageSliderComponent,
  BillingTitleComponent
];

@NgModule({
  imports: [CommonModule, AtlazGuiModule, SharedModule],
  declarations: sharedComponents,
  exports: sharedComponents
})
export class BillingSharedModule {}
