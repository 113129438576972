<input type="text" [focus]="true" placeholder="Search collections..." (keyup)="0" #input_search_collections/>
<div class="projects_container">
  <div *ngFor="let collection of collections | searchByField: 'name': input_search_collections.value"
       class="project_title"
       (click)="onSelectProject(collection)"
  >
    <p>
      {{collection['name']}}
      <span class="check_label" *ngIf="isSelectedCollection(collection)"></span>
    </p>
  </div>
</div>
