import { COMPANY_FEATURE_PL } from '../../constants';
import { labelAction } from '../functions/action';
import { HttpQueryParam } from '../../interfaces';
import { Action } from './unsafe-action';

export const InitialDataActionTypes = {
  GET: labelAction(COMPANY_FEATURE_PL, 'GET'),
  GET_COMPLETE: labelAction(COMPANY_FEATURE_PL, 'GET_COMPLETE'),
  GET_FAIL: labelAction(COMPANY_FEATURE_PL, 'GET_FAIL')
};

export class InitialDataGetAction implements Action {
  type = InitialDataActionTypes.GET;

  constructor(public payload?: HttpQueryParam) {}
}

export class InitialDataGetCompleteAction implements Action {
  type = InitialDataActionTypes.GET_COMPLETE;

  constructor(public payload?: HttpQueryParam) {}
}

export class InitialDataGetFailAction implements Action {
  type = InitialDataActionTypes.GET_FAIL;

  constructor(public payload?: HttpQueryParam) {}
}
