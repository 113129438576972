import { compareArrays } from './array';
import { complement } from 'ramda';

export const removeProperty = (property: any, object: any) => {
  const newObject = {};
  for (const prop of Object.keys(object)) {
    if (prop !== property.toString()) {
      newObject[prop] = object[prop];
    }
  }

  return newObject;
};
export const objectEqualProperty = (object: {}, propName, propValue) => {
  return object.hasOwnProperty(propName) && object[propName] === propValue;
};

/**
 *
 * @param object
 * @param partOfObject
 * @returns {boolean}
 *
 * example:
 * objectEqualProperties({id: 1, name: 'MainName', order: 3}, {id: 1, order: 3}) -> returns true
 * objectEqualProperties({id: 1, name: 'MainName', order: 3}, {id: 1, order: 2}) -> returns false
 * objectEqualProperties({id: 1, name: 'MainName', order: 3}, {notExists: true, id: 1, order: 3}) -> returns false
 */
export const objectEqualProperties = (object: {}, partOfObject) => {
  return Object.keys(partOfObject).every(propName => objectEqualProperty(object, propName, partOfObject[propName]));
};

/**
 * remove object's properties with values "", 0, null, undefined
 * return new object instance
 * @param obj {}
 * @returns {}
 */
export const removeEmptyProperties = (obj: {}): {} => {
  const clearObj = {};
  for (const i in obj) {
    if (obj[i]) {
      if (Array.isArray(obj[i])) {
        if (obj[i].length > 0) {
          clearObj[i] = obj[i];
        }
      } else {
        clearObj[i] = obj[i];
      }
    }
  }
  return clearObj;
};

const isEqualsTwoObjects = (obj1, obj2) => {
  let i;
  if (!obj1 || !obj2) {
    return false;
  }
  for (i in obj1) {
    if (Array.isArray(obj1[i]) && Array.isArray(obj2[i])) {
      if (!compareArrays(obj1[i], obj2[i])) {
        return false;
      }
    } else if (obj1[i] !== obj2[i]) {
      return false;
    }
  }
  for (i in obj2) {
    if (Array.isArray(obj1[i]) && Array.isArray(obj2[i])) {
      if (!compareArrays(obj1[i], obj2[i])) {
        return false;
      }
    } else if (obj1[i] !== obj2[i]) {
      return false;
    }
  }
  return true;
};

/**
 * compare object properties values
 * @returns {boolean}
 */
export function isEquals(...args: any[]): boolean {
  if (arguments.length < 2) {
    throw new Error('IsEquals is required two or more arguments');
  }

  for (let i = 1; i < arguments.length; i++) {
    if (!isEqualsTwoObjects(arguments[i - 1], arguments[i])) {
      return false;
    }
  }

  return true;
}

export function isPresent(val: any): boolean {
  return !!val;
}
export function isNotPresent(val: any): boolean {
  return !val;
}

export function isObject(val) {
  return val != null && typeof val === 'object' && Array.isArray(val) === false;
}

export const isEmpty = (obj: {}): boolean => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const isNotEmpty = complement(isEmpty);
