
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { TasksSavedListResetAction } from '../actions/task-filters/tasks-saved-list.actions';
import { TaskQuickFilterActionTypes } from '../actions/task-filters/task-quick-filter.actions';
import { DefaultTasksMembersFilterActionTypes } from '../actions/task-filters/default-members-filter.actions';

@Injectable()
export class DefaultTasksMembersFilterEffects {
  @Effect()
  defaultTasksMembersFilterSetMemberIds$ = this.actions$
    .ofType(DefaultTasksMembersFilterActionTypes.TOGGLE_MEMBER_ID).pipe(
    map(_ => {
      return new TasksSavedListResetAction();
    }));

  constructor(private actions$: Actions) {}
}
