import { createCommonSelectors } from '../../../../util/store/index';
import { getEmptyESState } from '../../../../ngrx/state/index';
import { BITBUCKET_COMPANY_PL } from '../../../../constants/index';
import { ESBitbucketCompany, BitbucketCompany } from '../../models/git-company';

import * as bitbucketCompany from './bitbucket-company.actions';
import { createSelector } from 'reselect';
import { prop } from '../../../../../helpers/index';

export interface State extends ESBitbucketCompany {
  isLoading: boolean;
}

const initialState: State = {
  ...getEmptyESState<BitbucketCompany>(),
  isLoading: true
};

export function reducer(state = initialState, action: bitbucketCompany.Actions): State {
  switch (action.type) {
    case bitbucketCompany.GET_INDEX: {
      return { ...state, isLoading: true };
    }

    case bitbucketCompany.GET_INDEX_FAIL:
    case bitbucketCompany.GET_INDEX_COMPLETE: {
      return { ...state, isLoading: false };
    }

    case bitbucketCompany.CLEAR_SETTINGS:
    case bitbucketCompany.REMOVE_ALL_COMPLETE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const { getState, getEntities, getIds, getAll, isEmpty } = createCommonSelectors<BitbucketCompany, State>(
  BITBUCKET_COMPANY_PL
);

export const getFirstId = createSelector(getIds, ids => ids[0]);
export const isLoading = createSelector(getState, prop('isLoading'));

export const isFirstLoading = createSelector(isEmpty, isLoading, (e, l) => l && e);
