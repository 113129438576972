<input type="text" #nameFilterRef name="name" [focus]="true" placeholder="Search Collections..." (keyup)="0" #input_search_projects/>
<div class="collections_container">
  <div *ngFor="let collectionId of collectionState.ids | filterEntitiesByField : collectionState.entities : 'name' : nameFilterRef.value"
       class="collection_title"
       (click)="onSelectCollection(collectionId)"
  >
    <p>
      {{collectionState.entities[collectionId]?.name}}
      <span class="check_label" *ngIf="isSelectedCollection(collectionId)"></span>
    </p>
  </div>
</div>
<div class="create_wrapper">
  <button a-button class="btn_primary" type="button" (click)="onToggleCreateCollection()">+ Create new collection</button>
</div>
<create-new-collection *ngIf="isCreateCollection" [notOutlet]="true" (cancel)="onToggleCreateCollection()"></create-new-collection>
