
import {combineLatest} from 'rxjs/operators';
import { Component, Input, OnChanges, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject ,  Observable ,  Observer } from 'rxjs';

import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { COLUMN_PL } from '../../../constants';
import { Column } from '../../../interfaces';
import { formatTimeToSecond, secondsToEdit } from '../../../../helpers';
import {
  FormV2Service,
  FormComponent,
  FormSaveType,
  FormServiceParams
} from '../../../shared/services/form-v2.service';
import { HandleResponseAction } from 'app/ngrx/actions/root.action';
import { JsonApiSingeModelResponse } from '../../../shared/services/app/web-socket/http-response';

type ButtonSubmitName = 'save' | 'remove';

@Component({
  selector: 'column-capacity-form',
  templateUrl: 'column-capacity-form.component.html',
  styleUrls: ['column-capacity-form.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnCapacityFormComponent implements OnChanges, FormComponent {
  @Input() column: Column;
  @Output() afterSubmit = new EventEmitter();

  form: FormGroup;

  public isAddCapacity = true;
  public clickedSubmitButton$ = new BehaviorSubject<ButtonSubmitName | ''>('');
  public isSavePending$: Observable<boolean>;
  public isRemovePending$: Observable<boolean>;

  prepareFormValue = (formValue: {}) => {
    return Object.keys(formValue).reduce((preparedData, formKey) => {
      switch (true) {
        case formKey === 'backlogCapacity': {
          preparedData[formKey] = formValue[formKey] === 0 ? 0 : formatTimeToSecond(formValue[formKey]);
          break;
        }
        default: {
          preparedData[formKey] = formValue[formKey];
          break;
        }
      }
      return preparedData;
    }, {});
  };

  formObserver: Observer<any> = {
    next: (resp: JsonApiSingeModelResponse<any>) => {
      this._store.dispatch(new HandleResponseAction(resp));
    },

    error: error => {
      console.log(error, 'error on component');
    },

    complete: () => {
      this.afterSubmit.emit(true);
    }
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.edit,
    entityToEdit: COLUMN_PL,
    formObserver: this.formObserver,
    prepareFormValue: this.prepareFormValue
  };
  constructor(private _fb: FormBuilder, private _store: Store<AppState>, public _formService: FormV2Service) {
    this.isSavePending$ = this.pendingForSubmitButton('save');
    this.isRemovePending$ = this.pendingForSubmitButton('remove');
  }

  get backlogCapacity() {
    return this.form.get('backlogCapacity');
  }

  ngOnChanges(): any {
    this.initForm();
    this.isAddCapacity = !this.column.backlogCapacity;
  }

  onSubmit() {
    this.clickedSubmitButton$.next('save');
    this.submit();
  }

  onRemove() {
    this.clickedSubmitButton$.next('remove');
    this.form.get('backlogCapacity').patchValue(0);

    this.submit();
  }

  public submit() {
    if (this.form.valid) {
      this._formService.submit();
    }
  }

  public initForm() {
    this.form = this._fb.group({
      id: [this.column.id],
      backlogCapacity: [
        this.column.backlogCapacity > 0 ? secondsToEdit(this.column.backlogCapacity) : '',
        Validators.pattern(/^(([0-9]{1,3})(:[0-9]{2})*)$/)
      ]
    });
    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  public pendingForSubmitButton(buttonName: ButtonSubmitName) {
    return this.clickedSubmitButton$.pipe(combineLatest(
      this._formService.isPending$,
      (clickedSubmitButton, isPending) => clickedSubmitButton === buttonName && isPending
    ));
  }
}
