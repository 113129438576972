<input
  #readOnlyInput
  class="form_page_input"
  type="text"
  [id]="labelId"
  (document:click)="onTogglePopup($event)"
  (keydown)="onReadOnlyInputKeyDown($event)"
  readonly
  [(ngModel)]="visibleValue"
>
<div class="list-wrapper" [ngClass]="{'reverse-direction': isReversePopUp$ | async}" *ngIf="isShowPossibleValues$ | async">
  <input
    #inputTag
    class="form_page_input"
    type="text"
    (keyup)="onKeyUp($event)"
    (keydown)="onKeyDown($event)"
    autocomplete="off"
    placeholder="Filter items..."
    autocapitalize="none">
  <ul
    #possibleValues
    (click)="onClosePopup($event)"
  >
    <li
      *ngFor="let item of (possibleValues$ | async); let i = index"
      (mouseenter)="onMouseEnter($event)"
      [attr.data-index]="i"
      [ngClass]="{'hovered': i === selectedItemIndex}"
    >{{matchProp ? item[matchProp] : item}}</li>
  </ul>
</div>
