import { map, switchMap } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ExternalTaskLink, TaskLink } from '../../../interfaces/tasks-links-type';
import { getTaskLinksByTask } from '../../../ngrx/reducers/task-links.reducer';
import { AppState } from '../../../ngrx/state';
import { Store } from '@ngrx/store';
import { getTasksLinksTypesState } from '../../../ngrx/reducers/tasks-links-type.reducer';
import { isPresent } from '../../../../helpers';
import { getTasksState } from '../../../ngrx/reducers/task.reducer';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';

@Component({
  selector: 'relative-task-links',
  templateUrl: './relative-task-links.component.html',
  styleUrls: ['./relative-task-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RelativeTaskLinksComponent implements OnInit {
  @Input() taskId: number;
  @Input() boardId: number;
  @Input() editPermissions = false;

  public linksArray$: Observable<TaskLink[]>;
  public taskLinks$: Observable<
    { name: string; links: { task: number; id: number; externalLink?: ExternalTaskLink }[] }[]
  >;
  public showAddPopUp = false;

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this.linksArray$ = this._store.select(getTaskLinksByTask(this.taskId));
    this.taskLinks$ = combineLatest(this.linksArray$, this._store.select(getTasksState)).pipe(
      map(([links, tasks]) => {
        return links.sort((a, b) => {
          const aTaskId = a.inwardTask === this.taskId ? a.outwardTask : a.inwardTask;
          const bTaskId = b.inwardTask === this.taskId ? b.outwardTask : b.inwardTask;
          let result = 0;
          try {
            result = tasks.entities[bTaskId].board - tasks.entities[aTaskId].board;
          } catch (e) {}
          return result;
        });
      }),
      map((links: TaskLink[]) =>
        links.reduce((acc, item) => {
          if (item.outwardTask === this.taskId) {
            if (!acc[item.tasksLinksTypes]) {
              acc[item.tasksLinksTypes] = [];
            }
            if (item.type === 'HYGGER') {
              acc[item.tasksLinksTypes].push({ task: item.inwardTask, id: item.id });
            } else {
              const externalLink = {
                type: item.type,
                externalId: item.externalId,
                externalLink: item.externalLink,
                externalTitle: item.externalTitle,
                externalStatus: item.externalStatus
              };
              acc[item.tasksLinksTypes].push({ task: item.inwardTask, id: item.id, externalLink: externalLink });
            }
          } else {
            if (!acc[-item.tasksLinksTypes]) {
              acc[-item.tasksLinksTypes] = [];
            }
            acc[-item.tasksLinksTypes].push({ task: item.outwardTask, id: item.id });
          }
          return acc;
        }, {})
      ),
      switchMap(taskLinks =>
        this._store.select(getTasksLinksTypesState).pipe(
          map(typesState => {
            return Object.keys(taskLinks)
              .map(key => {
                if (typesState.entities[Math.abs(+key)]) {
                  return {
                    name: +key > 0 ? typesState.entities[key].outwardDesc : typesState.entities[-key].inwardDesc,
                    links: taskLinks[key]
                  };
                } else {
                  console.warn('There is no task link entity with id ' + Math.abs(+key));
                  return undefined;
                }
              })
              .filter(isPresent);
          })
        )
      )
    );
  }

  onToggleAddPopUp() {
    if (this.editPermissions) {
      this.showAddPopUp = !this.showAddPopUp;
    }
  }
}
