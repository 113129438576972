
import {filter} from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { CustomValidators } from '../../shared/validators/custom-validators';
import { Column } from '../../interfaces';
import { COLUMN_PL, KeyCode } from '../../constants';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/state/';
import { getColumnByIdIfWIPed } from '../../ngrx/reducers/column.reducer';
import { RouterNavigateService } from '../../shared/services/router-navigate.service';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { Observer } from 'rxjs';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../shared/services/form-v2.service';
import { HandleResponseAction } from '../../ngrx/actions/root.action';

@Component({
  selector: 'column-wip-edit-form',
  templateUrl: './column-wip-edit-form.component.html',
  styleUrls: ['./column-wip-edit-form.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnWipEditFormComponent implements OnInit {
  formObserver: Observer<any> = {
    next: resp => {
      this._store.dispatch(new HandleResponseAction(resp));
      this.cancelPopup.emit();
      this.savePending = false;
      this.removePending = false;
    },
    error: error => {
      this.savePending = false;
      this.removePending = false;
    },
    complete: () => {}
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.edit,
    entityToEdit: COLUMN_PL,
    formObserver: this.formObserver
  };

  @Input() columnId: number;
  @Input() hasLimits: boolean;

  @Output() cancelPopup = new EventEmitter();

  public formWip: FormGroup;
  public error = false;

  public column: Column;
  public savePending = false;
  public removePending = false;

  public hotKeys = [{ keyCode: KeyCode.KEY_ENTER }];

  constructor(
    private _routerNav: RouterNavigateService,
    public _formService: FormV2Service,
    private _fb: FormBuilder,
    private _cd: ChangeDetectorRef,
    private _atlazApi: AtlazApiV2Service,
    private _store: Store<AppState>
  ) {}

  ngOnInit(): any {
    this._store.pipe(
      (getColumnByIdIfWIPed(this.columnId)),
      filter(x => !!x),)
      .subscribe(column => {
        this.column = column;
        this.formWip = this._fb.group({
          id: [this.columnId],
          minTasksCount: [
            !this.column.minTasksCount ? '' : this.column.minTasksCount,
            CustomValidators.composeOr([
              CustomValidators.empty,
              CustomValidators.zero,
              CustomValidators.composeAnd([CustomValidators.integer, CustomValidators.positive])
            ])
          ],
          maxTasksCount: [
            !this.column.maxTasksCount ? '' : this.column.maxTasksCount,
            CustomValidators.composeOr([
              CustomValidators.empty,
              CustomValidators.zero,
              CustomValidators.composeAnd([CustomValidators.integer, CustomValidators.positive])
            ])
          ]
        });
        this._cd.markForCheck();
      })
      .unsubscribe();
  }

  get disabled() {
    return this.savePending || this.removePending;
  }

  onSave() {
    if (this.formWip.valid && !this.disabled) {
      this.savePending = true;
      this.formServiceParams.prepareFormValue = formValue => {
        const copyFormValue = { ...formValue };
        copyFormValue['minTasksCount'] = !parseInt(copyFormValue['minTasksCount'], 10)
          ? 0
          : copyFormValue['minTasksCount'];
        copyFormValue['maxTasksCount'] = !parseInt(copyFormValue['maxTasksCount'], 10)
          ? 0
          : copyFormValue['maxTasksCount'];
        formValue = copyFormValue;
        return formValue;
      };
      this._formService.initFormParams(this.formWip, this.formServiceParams);
      this._formService.submit();
    }
  }

  onRemove() {
    if (!this.disabled) {
      this.removePending = true;
      this.formServiceParams.prepareFormValue = formValue => {
        const copyFormValue = { ...formValue };
        copyFormValue['minTasksCount'] = 0;
        copyFormValue['maxTasksCount'] = 0;
        formValue = copyFormValue;
        return formValue;
      };
      this._formService.initFormParams(this.formWip, this.formServiceParams);
      this._formService.submit();
    }
  }
}
