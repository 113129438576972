<div class="item"
     *ngFor="let item of items; trackBy : trackById;"
     [class.task]="item.type === 'task'"
     [class.group]="item.type === 'group'"
     [class.in_group]="inGroup"
     [class.collapsed-container]="hiddenItems[item.id]"
>
  <div [ngClass]="{timesheet_task_preview: true, show_hover: (hoverItem$ | async).id === item.id && (hoverItem$ | async).type === 'task'}"
       *ngIf="item.type === 'task'"
       (mouseenter)="onMouseEnter(item.id, item.type)"
       (mouseleave)="onMouseOut()"
       [roadmapDropContainer]="item"
       stopChildEventPropagation="mousedown"
  >
    <timesheet-task-preview
      [isNotGuest]="isNotGuest"
      [attr.data-task-id]="item.task.id"
      [item]="item"
      [itemPositions]="itemPositions$ | async"
      [class.in_group]="inGroup"
      (startMoving)="onHideConnectionLines()"
      (endMoving)="onShowConnectionLines()"
    ></timesheet-task-preview>
    <ng-container *ngIf="isShowConnectionLines">
      <connecting-line [style.color]="item.task.roadmapColor || '#009f84'" *ngFor="let endEl of (outwardDependenciesMap$ | async)[item.task.id]" [startElementId]="item.task.id" [endElementId]="endEl"></connecting-line>
    </ng-container>
  </div>

  <div [ngClass]="{group: item.type === 'group', show_hover: (hoverItem$ | async).id === item.id && (hoverItem$ | async).type === 'group', collapsed: item.collapsed}"
       *ngIf="item.type === 'group'"
       [roadmapDropContainer]="item"
       [dragOverHandler]="defaultDragOverHeader"
       (mouseenter)="onMouseEnter(item.id, item.type)"
       (mouseleave)="onMouseOut()"
       stopChildEventPropagation="mousedown"
  >
    <timesheet-group-header *ngIf="item.type === 'group' && item.subItems.length" [itemPositions]="itemPositions$ | async" [item]="item"></timesheet-group-header>
  </div>
  <div *ngIf="item.type === 'group'" class="group-content" [class.collapsed-container]="item.collapsed">
    <div class="border-for-header"></div>
    <day-items *ngIf="item.subItems.length" [itemPositions$]="itemPositions$" [items]="item.subItems" [inGroup]="true" [class.collapsed]="item.collapsed" [hiddenItems]="hiddenItems" [isNotGuest]="isNotGuest" [hideEmptyRows]="hideEmptyRows"></day-items>
    <div *ngIf="!hideEmptyRows && item.type === 'group'"
         [roadmapDropContainer]="item"
         [dragOverHandler]="defaultDragOverAddButtonHandler"
         (mouseenter)="onMouseEnter(item.id, 'push')"
         (mouseleave)="onMouseOut()"
         [class.add-form-push-block-group]="true"
         [class.collapsed]="item.collapsed"
         [class.has-child-items]="item.subItems.length"
         [class.show_hover]="(hoverItem$ | async).id === item.id && (hoverItem$ | async).type === 'push'"
         [class.open_push_form]="(syncOpenPushForm$ | async) && (syncOpenPushForm$ | async) === item.id"
         [class.show_hover_with_open_push_form]="(syncOpenPushForm$ | async) && (syncOpenPushForm$ | async) === item.id"
    ></div>
  </div>
</div>
