import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'inplace-string-edit',
  templateUrl: './inplace-string-edit.component.html',
  styleUrls: ['./inplace-string-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InplaceStringEditComponent implements OnChanges {
  @Input() value = '';
  @Input() edit = false;
  @Output() changeValue = new EventEmitter();
  @Output() close = new EventEmitter();

  public editValue = '';
  private focused = false;

  constructor() {}

  ngOnChanges() {
    this.editValue = this.value;
  }

  onFocus() {
    this.focused = true;
  }

  onSave() {
    this.focused = false;
    this.changeValue.emit(this.editValue);
  }

  onClickOut($event) {
    if (this.focused) {
      this.close.emit({});
    }
  }
}
