<div class="sub_note_container">
  <div class="avatar_container">
    <div class="author_avatar">
      <a-avatar
        [user]="user$ | async"
      ></a-avatar>
    </div>
  </div>
  <div (click)="onArchiveNotification()" class="archive_notif_controll"
    [ngClass]="{hidden_control: isArchivedFilterActive}"></div>
  <div class="main_info_container">
    <p class="author_name">
      <span class="user_name">{{(user$ | async)?.fullname}}</span><br>
      <span>{{subNotification.createdAt | timeAgo : "short"}}</span>
    </p>
    <ng-container *ngIf="subNotification.type === 'MENTION' || subNotification.type === 'COMMENT'; else noMarkdownText">
      <markdown class="notification_text" [data]="subNotification.preparedText | textToHtml | markdownBreak | markdownMention"></markdown>
    </ng-container>
    <ng-template #noMarkdownText>
      <div [innerHTML]="subNotification.preparedText | linkFromText | textBreak | loggedTime | noBrackets"
           class="notification_text"></div>
    </ng-template>
  </div>
</div>
