import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaywallService } from './paywall.service';
import { PaywallLayoutComponent } from './paywall-layout/paywall-layout.component';
import { AtlazGuiModule } from '../../atlaz-gui/atlaz-gui.module';
import { BillingSharedModule } from '@atlaz/billing/shared/components/billing-shared.module';
import { InviteUsersPayWallGuard } from './guards/invite-users-pay-wall-guard.service';
import { FeaturePayWallGuardService } from './guards/feature-pay-wall-guard.service';

const sharedComponents = [PaywallLayoutComponent];

@NgModule({
  imports: [CommonModule, AtlazGuiModule, BillingSharedModule],
  declarations: sharedComponents,
  exports: sharedComponents
})
export class PaywallModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: PaywallModule,
      providers: [PaywallService, InviteUsersPayWallGuard, FeaturePayWallGuardService]
    };
  }
}
