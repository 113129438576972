import { combineLatest as observableCombineLatest, Observable, Subscription } from 'rxjs';

import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Project } from '../../../interfaces';
import { AppState } from '../../../ngrx/state';
import { isPresent } from '../../../../helpers';
import {
  DefaultTaskProjectsFilterToggleItemAction,
  DefaultProjectsFilterResetAction
} from '../../../ngrx/actions/task-filters/default-projects-filter.actions';
import { getDefaultProjectsFilterIds } from '../../../ngrx/reducers/task-filters/default-projects-filter.reducer';
import { fromProject } from '../../../ngrx/reducers/project.reducer';

@Component({
  selector: 'default-projects-filter',
  templateUrl: './default-projects-filter.component.html',
  styleUrls: ['./default-projects-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultProjectsFilterComponent implements OnInit, OnDestroy {
  @Input() boardId$: Observable<number>;
  subs: Subscription[] = [];

  public boardProjects$: Observable<any[]>;
  public selectedProjects$: Observable<Project[]>;
  public selectedProjectsIds$: Observable<number[]>;
  public storeProjectsFilterValue$: Observable<number[]>;

  public showProjectsPopUp = false;
  public visibleFilter$: Observable<boolean>;

  constructor(private _store: Store<AppState>) {}

  ngOnInit(): void {
    this.storeProjectsFilterValue$ = this._store.pipe(getDefaultProjectsFilterIds);

    this.boardProjects$ = this.boardId$.pipe(
      filter(isPresent),
      distinctUntilChanged(),
      switchMap(id => this._store.select(fromProject.getByBoardId(id))),
      map(projects => [...projects.filter(v => !v.archived), { id: -1, name: 'Not set' }])
    );

    this.visibleFilter$ = this.boardProjects$.pipe(
      map(list => list && list.length > 1),
      distinctUntilChanged(),
      tap(visible => {
        if (!visible) {
          this.onHideProjectsPopup();
        }
      })
    );

    this.selectedProjects$ = observableCombineLatest(
      this.storeProjectsFilterValue$,
      this.boardProjects$,
      (selectedIds: number[], projects: Project[]) => {
        return {
          selectedIds: selectedIds,
          projects: projects.filter((project: Project) => selectedIds.includes(project.id))
        };
      }
    ).pipe(
      tap(({ selectedIds, projects }) => {
        if (selectedIds.length && !projects.length) {
          this._store.dispatch(new DefaultProjectsFilterResetAction());
        }
      }),
      map(({ projects }) => projects)
    );

    this.selectedProjectsIds$ = this.selectedProjects$.pipe(map((project: Project[]) => project.map(item => item.id)));
  }

  onAddProject(project: Project) {
    this.selectedProjectsIds$.pipe(take(1)).subscribe(ids => {
      this._store.dispatch(new DefaultTaskProjectsFilterToggleItemAction([...ids, project.id]));
    });
  }

  onRemoveProject(project: Project) {
    this.selectedProjectsIds$.pipe(take(1)).subscribe(ids => {
      this._store.dispatch(new DefaultTaskProjectsFilterToggleItemAction(ids.filter(id => id !== project.id)));
    });
  }

  onShowProjectsPopup() {
    this.showProjectsPopUp = true;
  }
  onHideProjectsPopup() {
    this.showProjectsPopUp = false;
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
