import { createSelector } from 'reselect';

import { BITBUCKET_ASSOCIATED_REPOSITORY_PL } from '../../../../constants/index';
import { removeOne } from '../../../../ngrx/functions/reducer';
import { getEmptyESState } from '../../../../ngrx/state/index';
import { createCommonSelectors } from '../../../../util/store/index';
import { ESBitbucketAssociatedRepository, BitbucketAssociatedRepository } from '../../models/git-repository';
import * as bitbucketCompany from '../bitbucket-company/bitbucket-company.actions';
import * as bitbucketAssociatedRepository from './bitbucket-associated-repository.actions';

export interface State extends ESBitbucketAssociatedRepository {
  isLoading: boolean;
}

const initialState: State = {
  ...getEmptyESState<BitbucketAssociatedRepository>(),
  isLoading: false
};

export function reducer(
  state = initialState,
  action: bitbucketAssociatedRepository.Actions | bitbucketCompany.Actions
): State {
  switch (action.type) {
    case bitbucketAssociatedRepository.GET_INDEX: {
      return { ...state, isLoading: true };
    }

    case bitbucketAssociatedRepository.GET_INDEX_FAIL:
    case bitbucketAssociatedRepository.GET_INDEX_COMPLETE: {
      return { ...state, isLoading: false };
    }

    case bitbucketAssociatedRepository.REMOVE: {
      return removeOne(state, action.payload.id);
    }

    case bitbucketCompany.CLEAR_SETTINGS:
    case bitbucketCompany.REMOVE_ALL_COMPLETE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const { getState, getEntities, getIds, getAll, isEmpty } = createCommonSelectors<
  BitbucketAssociatedRepository,
  State
>(BITBUCKET_ASSOCIATED_REPOSITORY_PL);

export const isLoading = createSelector(getState, state => state.isLoading);

export const getAssociatedRepoMap = createSelector(getAll, repos =>
  repos.reduce((map, repo) => Object.assign(map, { [repo.repositoryId]: repo }), {})
);
