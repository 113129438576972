<!--suppress ALL -->
<!-- https://egghead.io/lessons/angular-2-create-elements-from-template-elements-with-ngtemplateoutlet-in-angular-2?course=understand-angular-2-directives-in-depth -->

<svg:g #taskCircle *ngIf="!isGrouped"
        [attr.transform]="transform"
        class="task_circle"
>
  <svg:image
    x="-16"
    y="-16"
    width="141"
    height="28"
    xlink:href="../../../../../../assets/img/backlog/task.svg"
  ></svg:image>
  <ng-container *ngIf="!isGrouped">
    <svg:text *ngIf="task.title.length < 18;else longTitleText" font-size="10" line-height="12" color="#000000" [attr.x]="10" [attr.y]="circleAttrY">
      {{title}}
    </svg:text>
  </ng-container>
  <ng-template [ngTemplateOutlet]="simple_circle"
            [ngTemplateOutletContext]="{score: taskDragging ? taskDragging.backlogScore : task.backlogScore}"></ng-template>
</svg:g>

<svg:g #taskCircle *ngIf="isGrouped" [attr.transform]="transform" class="task_circle" (mouseenter)="onShowTooltip($event)" >
  <svg:image
    x="-16"
    y="-16"
    width="60"
    height="27"
    xlink:href="../../../../../../assets/img/backlog/task-group.svg"
  ></svg:image>
  <svg:g class="task_ellipse" [ngClass]="{highlight: highlight}">
    <svg:rect width="15" height="15" x="-8" y="-12" rx="10" ry="10" fill="#1e88e5" />
    <svg:text font-size="10" [attr.x]="-0.5" [attr.y]="circleAttrY" fill="#ffffff">{{countInGroup}}</svg:text>
  </svg:g>
  <svg:text *ngIf="isGrouped" font-size="10" [attr.x]="10" [attr.y]="circleAttrY" fill="#000000">
    Tasks
  </svg:text>
  <ng-template [ngTemplateOutlet]="group_circle"></ng-template>
</svg:g>

<ng-template #simple_circle let-score="score" let-cssClass="cssClass">
  <svg:rect x="-16" y="-16" height="28" rx="12px" ry="12px" fill="transparent"
      [ngClass]="{highlight: highlight}"
      class="transparent"
      [attr.draggable]="isGrouped ? 0 : 1"
      [attr.width]="isGrouped ? 60 : 141"
      [attr.height]="isGrouped ? 27 : 28"
      [attr.taskId]="task.id"
      (mouseenter)="onShowTooltip($event)"
      (mouseleave)="onHideTooltip()"
  />
</ng-template>

<ng-template #group_circle>
  <ng-template [ngTemplateOutlet]="simple_circle" [ngTemplateOutletContext]="{score: task.backlogScore, cssClass: 'group_circle'}"></ng-template>
</ng-template>

<ng-template #longTitleText>
  <svg:text font-size="10" line-height="12" color="#000000" [attr.x]="10" [attr.y]="-3 + circleAttrY">
    {{task.title.slice(0, 17)}}
  </svg:text>
  <svg:text font-size="10" line-height="12" color="#000000" [attr.x]="10" [attr.y]="8 + circleAttrY">
    {{longTitle}}
  </svg:text>
</ng-template>
