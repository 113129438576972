import { Pipe, PipeTransform } from '@angular/core';
import { secondsToEdit } from '../../../helpers';

@Pipe({
  name: 'toHms'
})
export class SecondsToHmsPipe implements PipeTransform {
  transform(dateTimeIntervalInSeconds: number, format: 'short' | 'long' = 'long'): string {
    switch (format) {
      case 'short': {
        return secondsToEdit(dateTimeIntervalInSeconds);
      }
      case 'long': {
        return this.parseSecondsToString(dateTimeIntervalInSeconds);
      }
      default: {
        throw new Error('invalid output time format');
      }
    }
  }

  protected parseSecondsToString(seconds: number): string {
    const directiconSign = seconds > 0 ? '' : '-';

    const hours = Math.floor(Math.abs(seconds) / 3600);
    const minutes = Math.floor(Math.abs(seconds) / 60) % 60;
    seconds = Math.floor(Math.abs(seconds) % 60);

    const result = this.toString(hours, minutes, seconds);

    return directiconSign + result;
  }

  protected toString(hours: number, minutes: number, seconds: number): string {
    const hoursLabel = hours !== 0 ? hours + 'h' : '';
    const minutesLabel = minutes !== 0 ? minutes + 'm' : '';
    const secondsLabel = seconds !== 0 ? seconds + 's' : '';

    return `${hoursLabel} ${minutesLabel} ${secondsLabel}`;
  }
}
