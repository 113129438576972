<ng-container *ngFor="let piece of pieces">
  <ng-container *ngIf="!taskLinksOnly" [ngSwitch]="piece.type">
    <smart-task-link *ngSwitchCase="'smart-task-link'" [value]="piece.value" [props]="piece.props"></smart-task-link>
    <smart-link *ngSwitchCase="'smart-link'" [value]="piece.value"></smart-link>
    <smart-mention *ngSwitchCase="'smart-mention'" [value]="piece.value"></smart-mention>
    <br *ngSwitchCase="'smart-br'"/>
    <smart-logged-time *ngSwitchCase="'smart-logged-time'" [seconds]="piece.props.time"></smart-logged-time>
    <smart-no-brackets *ngSwitchCase="'smart-no-brackets'" [value]="piece.value"></smart-no-brackets>
    <ng-container *ngSwitchDefault>{{piece.value}}</ng-container>
  </ng-container>
  <ng-container *ngIf="taskLinksOnly" [ngSwitch]="piece.type">
    <smart-task-link (update)="onSmartTaskLinkUpdated()" *ngSwitchCase="'smart-task-link'" [value]="piece.value" [props]="piece.props"></smart-task-link>
  </ng-container>
</ng-container>
