import { Component } from '@angular/core';

@Component({
  selector: 'header-help-icon-svg',
  template: `
	  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
		  <style>
			  .st0{fill:none;} .st1{fill:#455A64;}
		  </style>
		  <path id="Shape" class="st0" d="M0 0h24v24H0z"/>
		  <path class="st1" d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm-1-2v-2h2v2h-2zm1-12c2.2 0 4 1.8 4 4 0 2.5-3 2.8-3 5h-2c0-3.2 3-3 3-5 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.2 1.8-4 4-4z"/>
	  </svg>
  `
})
export class HeaderHelpSvgComponent {}
