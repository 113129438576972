
import {pluck, filter} from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CompanyService } from '../../../../shared/services/app/company.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../ngrx/state';
import { Observable } from 'rxjs';
import * as fromBillingSubscription from '@atlaz/billing/store/subscription/subscription.reducer';
import { isPresent } from '../../../../../helpers';

@Component({
  selector: 'billing-title',
  templateUrl: './billing-title.component.html',
  styleUrls: ['./billing-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillingTitleComponent implements OnInit {
  public usersCount$: Observable<number>;
  public companyName = this._companyService.name;

  constructor(private _companyService: CompanyService, private _store: Store<AppState>) {}

  ngOnInit() {
    this.usersCount$ = this._store
      .select(fromBillingSubscription.getCompanyBillingSubscription).pipe(
      filter(isPresent),
      pluck('usersCount'),);
  }
}
