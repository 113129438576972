import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterNavigateService } from '../../services/router-navigate.service';
import { Subject } from 'rxjs';
import { ComponentListenClickoutAction } from '../../../ngrx/actions/component.actions';
import { AppState, LEFT_MENU } from '../../../ngrx/state';
import { Store } from '@ngrx/store';
import { FormV2Service } from '../../services/form-v2.service';
import * as ROUTER_PATH from '../../../path.routing';

@Component({
  templateUrl: './create-new-project-popup.component.html',
  styleUrls: ['./create-new-project-popup.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateNewProjectPopupComponent {
  public isValidForm;
  public submit$ = new Subject();
  public companySettingsRouterLink = [
    ROUTER_PATH.SEP,
    {
      outlets: { primary: [ROUTER_PATH.COMPANY_SETTINGS, ROUTER_PATH.IMPORT], [ROUTER_PATH.POPUP_OUTLET]: null }
    }
  ];

  constructor(private _routerNav: RouterNavigateService, private _store: Store<AppState>) {}

  onClosePopup() {
    this._store.dispatch(new ComponentListenClickoutAction({ name: LEFT_MENU, listenClickout: true }));
    this._routerNav.deactivatePopupOutlet();
  }
}
