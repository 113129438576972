import { Observable } from 'rxjs';
import { TaskActivityActions, TaskActivityActionTypes } from '../actions/task-activity.actions';
import { ESInterface, AppState } from '../state';
import { TaskActivity } from '../../interfaces';
import { getAllEntitiesAsArray, getEntitySelector } from '../functions/selectors';
import { editEntityState, deleteEntityState } from '../functions/reducer';

import { share } from '../functions/util';
import { compose } from '@ngrx/store';
import { TASK_ACTIVITY_PL } from '../../constants';

const initialState: ESInterface<TaskActivity> = {
  ids: [],
  entities: {},
  selectedEntityId: null
};

export function reducer(state = initialState, action: TaskActivityActions): ESInterface<TaskActivity> {
  switch (action.type) {
    case TaskActivityActionTypes.EDIT: {
      return editEntityState(state, action.payload);
    }

    case TaskActivityActionTypes.DELETE: {
      return deleteEntityState(state, action.payload.id);
    }

    default: {
      return state;
    }
  }
}

export const getAllTaskActivities = share(compose(getAllEntitiesAsArray, getEntitySelector(TASK_ACTIVITY_PL)));
