import { Action } from './unsafe-action';
import { entityId, HttpQueryParam, PartOfEntity, Task } from '../../interfaces';
import { ESInterface } from '../state';

export enum TaskActionTypes {
  LOAD = '[TASK] LOAD',
  LOAD_COMPLETED = '[TASK] LOAD_COMPLETED',
  GET = '[TASK] GET',
  GET_COMPLETE = '[TASK] GET_COMPLETE',
  GET_FAIL = '[TASK] GET_FAIL',
  ADD = '[TASK] ADD',
  ADD_COMPLETE = '[TASK] ADD_COMPLETE',
  ADD_FAIL = '[TASK] ADD_FAIL',
  EDIT = '[TASK] EDIT',
  EDIT_COMPLETE = '[TASK] EDIT_COMPLETE',
  EDIT_FAIL = '[TASK] EDIT_FAIL',
  DELETE = '[TASK] DELETE',
  BATCH_DELETE = '[TASK] BATCH_DELETE',
  DELETE_COMPLETE = '[TASK] DELETE_COMPLETE',
  DELETE_FAIL = '[TASK] DELETE_FAIL',
  ASSIGN_USERS = '[TASK] ASSIGN_USERS',
  BATCH_ASSIGN_USERS = '[TASK] BATCH_ASSIGN_USERS',
  ASSIGN_LABELS = '[TASK] ASSIGN_LABELS',
  BATCH_ASSIGN_LABELS = '[TASK] BATCH_ASSIGN_LABELS',
  ASSIGN_VERSION = '[TASK] ASSIGN_VERSION',
  ASSIGN_SUBSCRIBERS = '[TASK] ASSIGN_SUBSCRIBERS',
  REMOVE_FROM_STORE = '[TASK] REMOVE_FROM_STORE',
  REMOVE_RELEASED_FROM_STORE = '[TASK] REMOVE_RELEASED_TASKS_FROM_STORE',
  TASK_EDIT_BACKLOG_VALUES = '[TASK] TASK_EDIT_BACKLOG_VALUES',
  MOVE_FROM_DELETED_SWIMLANE = '[TASK] MOVE_FROM_DELETED_SWIMLANE'
}

export const TASK_UPDATED = '[TASKS] task updated';

export class TaskLoadAction implements Action {
  readonly type = TaskActionTypes.LOAD;

  constructor(public payload: { id: number; params?: HttpQueryParam }) {}
}

export class TaskLoadCompletedAction implements Action {
  readonly type = TaskActionTypes.LOAD_COMPLETED;

  constructor(public payload?: HttpQueryParam) {}
}

export class TaskGetAction implements Action {
  readonly type = TaskActionTypes.GET;

  constructor(public payload?: HttpQueryParam) {}
}

export class TaskGetCompleteAction implements Action {
  readonly type = TaskActionTypes.GET_COMPLETE;

  constructor(public payload: Task[]) {}
}

export class TaskEditAction implements Action {
  readonly type = TaskActionTypes.EDIT;
  constructor(public payload: PartOfEntity) {}
}

export class TaskEditCompleteAction implements Action {
  readonly type = TaskActionTypes.EDIT_COMPLETE;

  constructor(public payload: Task) {}
}

export class TaskDeleteAction implements Action {
  readonly type = TaskActionTypes.DELETE;

  constructor(public payload: Task) {}
}

export class TaskBatchDeleteAction implements Action {
  readonly type = TaskActionTypes.BATCH_DELETE;

  constructor(public payload: Array<number | string>) {}
}

export class TaskDeleteCompleteAction implements Action {
  readonly type = TaskActionTypes.DELETE_COMPLETE;

  constructor(public payload?: any) {}
}

export class TaskAddAction implements Action {
  readonly type = TaskActionTypes.ADD;

  constructor(public payload: PartOfEntity) {}
}

export class TaskAddCompleteAction implements Action {
  readonly type = TaskActionTypes.ADD_COMPLETE;

  constructor(public payload: Task) {}
}

export class TaskAssignUsersAction implements Action {
  readonly type = TaskActionTypes.ASSIGN_USERS;

  constructor(public payload: { id: number; users: { add?: number[]; remove?: number[] } }) {}
}

export class TaskBatchAssignUsersAction implements Action {
  readonly type = TaskActionTypes.BATCH_ASSIGN_USERS;

  constructor(public payload: { ids: Array<number>; users: { add?: number[]; remove?: number[] } }) {}
}

export class TaskAssignLabelsAction implements Action {
  readonly type = TaskActionTypes.ASSIGN_LABELS;

  constructor(public payload: { id: number; labels: { add?: number[]; remove?: number[] } }) {}
}

export class TaskBatchAssignLabelsAction implements Action {
  readonly type = TaskActionTypes.BATCH_ASSIGN_LABELS;

  constructor(public payload: { ids: Array<number>; labels: { add?: number[]; remove?: number[] } }) {}
}

export class TaskAssignVersionAction implements Action {
  readonly type = TaskActionTypes.ASSIGN_VERSION;

  constructor(public payload: { id: number; version: number }) {}
}

export class TaskAssignSubscriberAction implements Action {
  readonly type = TaskActionTypes.ASSIGN_SUBSCRIBERS;

  constructor(public payload: { id: number; subscribers: { add?: number[]; remove?: number[] } }) {}
}

export class TaskRemoveReleasedTaskFromStore implements Action {
  readonly type = TaskActionTypes.REMOVE_RELEASED_FROM_STORE;
}

export class TaskRemoveFromStoreAction implements Action {
  readonly type = TaskActionTypes.REMOVE_FROM_STORE;

  // payload is taskIds[]
  constructor(public payload: entityId[]) {}
}

export class TaskMoveFromDeletedSwimlaneAction implements Action {
  readonly type = TaskActionTypes.MOVE_FROM_DELETED_SWIMLANE;

  constructor(public payload: { taskId: number; moveToSwimlaneId: number }) {}
}

export class TaskUpdated implements Action {
  readonly type = TASK_UPDATED;

  constructor(public payload: ESInterface<Task>) {}
}

// prettier-ignore
export type TaskActions =
  | TaskGetAction
  | TaskLoadAction
  | TaskLoadCompletedAction
  | TaskGetCompleteAction
  | TaskEditAction
  | TaskEditCompleteAction
  | TaskDeleteAction
  | TaskBatchDeleteAction
  | TaskDeleteCompleteAction
  | TaskAddAction
  | TaskAddCompleteAction
  | TaskAssignUsersAction
  | TaskBatchAssignUsersAction
  | TaskAssignLabelsAction
  | TaskBatchAssignLabelsAction
  | TaskAssignVersionAction
  | TaskAssignSubscriberAction
  | TaskRemoveReleasedTaskFromStore
  | TaskRemoveFromStoreAction
  | TaskMoveFromDeletedSwimlaneAction
  | TaskUpdated;
