import { Component, OnInit, EventEmitter, Output, ChangeDetectionStrategy, AfterViewInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { getApplicationDomain } from '../../../../helpers/get-application-domain';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';

const INITIAL = 'initial';
const animationDuration = 350;
const animationDestroyDelay = 2500;

@Component({
  selector: 'content-copy-link',
  templateUrl: './content-copy-link.component.html',
  styleUrls: ['./content-copy-link.component.scss'],
  animations: [
    trigger('copyLinkState', [
      state(INITIAL, style({})),
      transition(
        '* => ' + INITIAL,
        animate(
          animationDuration,
          keyframes([
            style({ opacity: 0, transform: 'translateY(-100px)', offset: 0 }),
            style({ opacity: 1, transform: 'translateY(25px)', offset: 0.7 }),
            style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 })
          ])
        )
      )
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentCopyLinkComponent implements OnInit, AfterViewInit {
  @Input() path: string;
  @Input() isFullPath: boolean;
  @Output() close = new EventEmitter();

  public copyUrl: string;
  public state: string;

  constructor(private _router: Router) {}

  ngOnInit() {
    this.state = INITIAL;

    const applicationDomain = getApplicationDomain();
    const urlToTask = this.path === undefined ? this._router.url.toString() : this.path;

    this.copyUrl = this.isFullPath && this.path ? urlToTask : applicationDomain + urlToTask;
  }

  ngAfterViewInit() {
    const copyUrl = document.querySelector('.link_to_copy');
    const range = document.createRange();

    range.selectNode(copyUrl);

    const selected = window.getSelection();

    selected.removeAllRanges();
    selected.addRange(range);

    copyUrl.innerHTML.trim();

    document.execCommand('copy');
  }

  onCompleteAnimation() {
    setTimeout(() => {
      this.onCloseCopyLink();
    }, animationDestroyDelay);
  }

  onCloseCopyLink() {
    this.close.emit();
  }
}
