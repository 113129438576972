import { combineLatest as observableCombineLatest, Observable, of as observableOf, Subscription } from 'rxjs';

import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FiltersInLocalStorage,
  TaskDefaultFilterSetAction,
  TaskDefaultFilterToggleAction
} from '../../ngrx/actions/task-filters/task-default-filter.actions';
import { TaskQuickFilterToggleAction } from '../../ngrx/actions/task-filters/task-quick-filter.actions';
import { DefaultTaskFilter } from '../../ngrx/reducers/task-filters';
import {
  getAllBoardsQuickFilters,
  getBoardQuickFilters,
  getQuickFilters
} from '../../ngrx/reducers/quick-filter.reducer';
import {
  DefaultMembersFilter,
  getDefaultMembersFilterEntity,
  isActiveDefaultMembersFilter
} from '../../ngrx/reducers/task-filters/default-members-filter.reducer';
import {
  FILTER_COLORS,
  FILTER_COLUMNS,
  FILTER_LABELS,
  FILTER_MEMBERS,
  FILTER_NO_EFFORTS,
  FILTER_NO_VALUE,
  FILTER_PROJECTS,
  FILTER_QUADS,
  FILTER_TIME_ON_BOARD,
  FILTER_TIME_ON_COLUMN,
  FILTER_STATUSES,
  FILTER_SWIMLANES,
  FILTER_VERSIONS,
  filtersByBoard,
  filtersByView,
  filtersForbiddenForBoard,
  getTaskDefaultFilterList,
  UncustomizableDefaultFilters
} from '../../ngrx/reducers/task-filters/default-task-filter.reducer';
import {
  DefaultLabelsFilter,
  getDefaultLabelsFilterEntity,
  isActiveDefaultLabelsFilter
} from '../../ngrx/reducers/task-filters/default-labels-filter.reducer';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/state';
import { Board, QuickFilter } from '../../interfaces';
import { SegmentService } from '../../atlaz-bnp/services/intergations/segment/segment.service';
import { trackByName } from '../../../helpers';
import {
  DefaultColumnsFilter,
  getDefaultColumnsFilterEntity,
  isActiveDefaultColumnsFilter
} from '../../ngrx/reducers/task-filters/default-columns-filter.reducer';
import {
  DefaultSwimlanesFilter,
  getDefaultSwimlanesFilterEntity,
  isActiveDefaultSwimlanesFilter
} from '../../ngrx/reducers/task-filters/default-swimlanes-filter.reducer';
import {
  DefaultStatusesFilter,
  getDefaultStatusesFilterEntity,
  isActiveDefaultStatusesFilter
} from '../../ngrx/reducers/task-filters/default-statuses-filter.reducer';
import { UsersCacheService } from '@atlaz/core/services/users-cache.service';
import {
  DefaultColorsFilter,
  getDefaultColorsFilterEntity,
  isActiveDefaultColorsFilter
} from '../../ngrx/reducers/task-filters/default-colors-filter.reducer';
import { BoardFilterInfo } from '../../interfaces/board';
import { ScoringType, boardType } from '../../constants';
import {
  DefaultQuadsFilter,
  getDefaultQuadsFilterEntity,
  isActiveDefaultQuadsFilter
} from '../../ngrx/reducers/task-filters/default-quads-filter.reducer';
import {
  DefaultVersionsFilter,
  getDefaultVersionsFilterEntity,
  isActiveDefaultVersionsFilter
} from '../../ngrx/reducers/task-filters/default-versions-filter.reducer';
import {
  DefaultProjectsFilter,
  getDefaultProjectsFilterEntity,
  isActiveDefaultProjectsFilter
} from '../../ngrx/reducers/task-filters/default-projects-filter.reducer';
import {
  DefaultTimeOnBoardFilter,
  getDefaultTimeOnBoardFilterEntity,
  isActiveDefaultTimeOnBoardFilter
} from '../../ngrx/reducers/task-filters/default-time-on-board-filter.reducer';
import {
  DefaultTimeOnColumnFilter,
  getDefaultTimeOnColumnFilterEntity,
  isActiveDefaultTimeOnColumnFilter
} from '../../ngrx/reducers/task-filters/default-time-on-column-filter.reducer';

@Component({
  selector: 'board-quick-filters',
  templateUrl: './board-quick-filters.component.html',
  styleUrls: ['./board-quick-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoardQuickFiltersComponent implements OnInit, OnDestroy {
  @Input() boardInfo$: Observable<BoardFilterInfo>;
  @Input() preventNavFromSelector: boolean;
  public boardType = boardType;

  public quickFilters$: Observable<QuickFilter[]>;
  public allQuickFilters$: Observable<QuickFilter[]>;
  public defaultFilters$: Observable<{ name: string; isActive: boolean }[]>;
  public appliedFilter$: Observable<boolean>;
  public allFiltersValues$;

  public isDefaultMembersFilter$: Observable<boolean>;
  public isDefaultLabelsFilter$: Observable<boolean>;
  public isDefaultColumnsFilter$: Observable<boolean>;
  public isDefaultSwimlanesFilter$: Observable<boolean>;
  public isDefaultVersionsFilter$: Observable<boolean>;
  public isDefaultProjectsFilter$: Observable<boolean>;
  public isDefaultStatusesFilter$: Observable<boolean>;
  public isDefaultColorsFilter$: Observable<boolean>;
  public isDefaultQuadsFilter$: Observable<boolean>;

  public defaultMembersFilter$: Observable<DefaultMembersFilter>;
  public defaultLabelsFilter$: Observable<DefaultLabelsFilter>;
  public defaultColumnsFilter$: Observable<DefaultColumnsFilter>;
  public defaultSwimlanesFilter$: Observable<DefaultSwimlanesFilter>;
  public defaultVersionsFilter$: Observable<DefaultVersionsFilter>;
  public defaultProjectsFilter$: Observable<DefaultProjectsFilter>;
  public defaultStatusesFilter$: Observable<DefaultStatusesFilter>;
  public defaultColorsFilter$: Observable<DefaultColorsFilter>;
  public defaultQuadsFilter$: Observable<DefaultQuadsFilter>;
  public defaultTimeOnBoardFilter$: Observable<DefaultTimeOnBoardFilter>;
  public defaultTimeOnColumnFilter$: Observable<DefaultTimeOnColumnFilter>;

  public boardId$: Observable<number>;

  public trackByName = trackByName;
  public FILTER_MEMBERS = FILTER_MEMBERS;
  public FILTER_LABELS = FILTER_LABELS;
  public FILTER_COLUMNS = FILTER_COLUMNS;
  public FILTER_SWIMLANES = FILTER_SWIMLANES;
  public FILTER_VERSIONS = FILTER_VERSIONS;
  public FILTER_PROJECTS = FILTER_PROJECTS;
  public FILTER_STATUSES = FILTER_STATUSES;
  public FILTER_COLORS = FILTER_COLORS;
  public FILTER_NO_EFFORTS = FILTER_NO_EFFORTS;
  public FILTER_NO_VALUE = FILTER_NO_VALUE;
  public FILTER_QUADS = FILTER_QUADS;
  public FILTER_TIME_ON_BOARD = FILTER_TIME_ON_BOARD;
  public FILTER_TIME_ON_COLUMN = FILTER_TIME_ON_COLUMN;

  public boardInfo: BoardFilterInfo;

  subs: Subscription[] = [];

  constructor(
    private _store: Store<AppState>,
    private _segment: SegmentService,
    private _usersCacheService: UsersCacheService
  ) {}

  ngOnInit() {
    this.boardId$ = this.boardInfo$.pipe(map(({ id }) => id));

    this.isDefaultMembersFilter$ = this._store.pipe(isActiveDefaultMembersFilter);
    this.isDefaultLabelsFilter$ = this._store.pipe(isActiveDefaultLabelsFilter);
    this.isDefaultColumnsFilter$ = this._store.pipe(isActiveDefaultColumnsFilter);
    this.isDefaultSwimlanesFilter$ = this._store.pipe(isActiveDefaultSwimlanesFilter);
    this.isDefaultVersionsFilter$ = this._store.pipe(isActiveDefaultVersionsFilter);
    this.isDefaultProjectsFilter$ = this._store.pipe(isActiveDefaultProjectsFilter);
    this.isDefaultStatusesFilter$ = this._store.pipe(isActiveDefaultStatusesFilter);
    this.isDefaultColorsFilter$ = this._store.pipe(isActiveDefaultColorsFilter);
    this.isDefaultQuadsFilter$ = this._store.pipe(isActiveDefaultQuadsFilter);
    this.quickFilters$ = this._store.pipe(getBoardQuickFilters);
    this.allQuickFilters$ = this._store.pipe(getAllBoardsQuickFilters);
    this.defaultFilters$ = <Observable<{ name: string; isActive: boolean }[]>>this._store.pipe(
      getTaskDefaultFilterList
    );

    this.defaultMembersFilter$ = this._store.pipe(getDefaultMembersFilterEntity);
    this.defaultLabelsFilter$ = this._store.pipe(getDefaultLabelsFilterEntity);
    this.defaultColumnsFilter$ = this._store.pipe(getDefaultColumnsFilterEntity);
    this.defaultSwimlanesFilter$ = this._store.pipe(getDefaultSwimlanesFilterEntity);
    this.defaultVersionsFilter$ = this._store.pipe(getDefaultVersionsFilterEntity);
    this.defaultProjectsFilter$ = this._store.pipe(getDefaultProjectsFilterEntity);
    this.defaultStatusesFilter$ = this._store.pipe(getDefaultStatusesFilterEntity);
    this.defaultColorsFilter$ = this._store.pipe(getDefaultColorsFilterEntity);
    this.defaultQuadsFilter$ = this._store.pipe(getDefaultQuadsFilterEntity);
    this.defaultTimeOnBoardFilter$ = this._store.pipe(getDefaultTimeOnBoardFilterEntity);
    this.defaultTimeOnColumnFilter$ = this._store.pipe(getDefaultTimeOnColumnFilterEntity);

    this.appliedFilter$ = observableCombineLatest(
      this.quickFilters$,
      this.defaultFilters$,
      this.isDefaultMembersFilter$,
      this.isDefaultLabelsFilter$,
      this.isDefaultColumnsFilter$,
      this.isDefaultSwimlanesFilter$,
      this.isDefaultVersionsFilter$,
      this.isDefaultProjectsFilter$,
      this.isDefaultStatusesFilter$,
      this.isDefaultColorsFilter$,
      this.isDefaultQuadsFilter$,
      BoardQuickFiltersComponent.isFiltersApplied
    ).pipe(distinctUntilChanged());

    this.allFiltersValues$ = observableCombineLatest(
      this.defaultFilters$,
      this.defaultMembersFilter$,
      this.defaultLabelsFilter$,
      this.defaultColumnsFilter$,
      this.defaultSwimlanesFilter$,
      this.defaultVersionsFilter$,
      this.defaultProjectsFilter$,
      this.defaultStatusesFilter$,
      this.defaultColorsFilter$,
      this.defaultQuadsFilter$,
      this.defaultTimeOnBoardFilter$,
      this.defaultTimeOnColumnFilter$,
      this.allQuickFilters$,
      this.boardInfo$,
      this.reduceFiltersForLocalStorage
    ).pipe(distinctUntilChanged());

    this.initFilter();

    this.subs.push(
      this.allFiltersValues$.subscribe(filters => {
        this._usersCacheService.set(BoardQuickFiltersComponent.getCacheKey(this.boardInfo.id), filters);
      })
    );
  }

  initFilter() {
    this.subs.push(
      this.boardInfo$
        .pipe(
          distinctUntilChanged(),
          tap(info => (this.boardInfo = info)),
          switchMap(({ id, type, scoringType, boardView }) =>
            this._usersCacheService.get(BoardQuickFiltersComponent.getCacheKey(id)).pipe(
              map(filter => ({
                activeFilters: filter || {},
                type: type,
                scoringType: scoringType,
                boardView: boardView
              }))
            )
          ),
          switchMap(payload => {
            if (payload.activeFilters.quickFilters) {
              return this._store.pipe(
                getQuickFilters,
                map(items => items.filter((item: QuickFilter) => payload.activeFilters.quickFilters.includes(item.id))),
                map(quickFilters => ({
                  type: payload.type,
                  scoringType: payload.scoringType,
                  boardView: payload.boardView,
                  activeFilters: { ...payload.activeFilters, quickFilters: quickFilters }
                }))
              );
            } else {
              return observableOf(payload);
            }
          })
        )
        .subscribe(payload => {
          this._store.dispatch(new TaskDefaultFilterSetAction(payload));
        })
    );
  }

  onApplyQFilter(filter: QuickFilter) {
    if (!filter.isActive) {
      this._segment.boardFilterUsed('Custom Filter');
    }
    this._store.dispatch(new TaskQuickFilterToggleAction(filter));
  }

  onApplyDefaultFilter(filter: QuickFilter) {
    // by strange coincidence of circumstances defaultFilterID is his name. I just leave it here.
    // It should be fixed within i18n issue
    const defaultFilterID: string = filter.name;
    if (!filter.isActive) {
      this._segment.boardFilterUsed(defaultFilterID);
    }
    this._store.dispatch(new TaskDefaultFilterToggleAction({ filterID: defaultFilterID }));
  }

  reduceFiltersForLocalStorage(
    defaultFilters: DefaultTaskFilter[],
    defaultMembersFilter,
    defaultLabelsFilter,
    defaultColumnsFilter,
    defaultSwimlanesFilter,
    defaultVersionsFilter,
    defaultProjectsFilter,
    defaultStatusesFilter,
    defaultColorsFilter,
    defaultQuadsFilter,
    defaultTimeOnBoardFilter,
    defaultTimeOnColumnFilter,
    quickFilters: QuickFilter[],
    boardInfo: BoardFilterInfo
  ) {
    const filters: FiltersInLocalStorage = {};
    const forbiddenFilters = {};
    if (this.boardInfo && filtersForbiddenForBoard[this.boardInfo.type]) {
      filtersForbiddenForBoard[this.boardInfo.type].forEach(item => (forbiddenFilters[item] = true));
    }
    const uncustomizable = defaultFilters
      .filter(item => item.isActive && UncustomizableDefaultFilters.includes(item.name))
      .map(item => item.name);

    if (uncustomizable.length) {
      filters.uncustomizable = uncustomizable;
    }

    if (defaultMembersFilter.membersIds && defaultMembersFilter.membersIds.length) {
      filters.defaultMembersFilter = defaultMembersFilter.membersIds;
    }

    if (defaultLabelsFilter.labelsIds && defaultLabelsFilter.labelsIds.length) {
      filters.defaultLabelsFilter = defaultLabelsFilter.labelsIds;
    }

    if (
      boardInfo.boardView &&
      !filtersForbiddenForBoard[FILTER_COLUMNS] &&
      (filtersByView[boardInfo.boardView] || []).includes(FILTER_COLUMNS) &&
      defaultColumnsFilter.columnsIds &&
      defaultColumnsFilter.columnsIds.length
    ) {
      filters.defaultColumnsFilter = defaultColumnsFilter.columnsIds;
    }

    if (
      boardInfo.boardView &&
      (filtersByView[boardInfo.boardView] || []).includes(FILTER_SWIMLANES) &&
      defaultSwimlanesFilter.swimlanesIds &&
      defaultSwimlanesFilter.swimlanesIds.length
    ) {
      filters.defaultSwimlanesFilter = defaultSwimlanesFilter.swimlanesIds;
    }
    if (
      boardInfo.boardView &&
      !filtersForbiddenForBoard[FILTER_VERSIONS] &&
      (filtersByView[boardInfo.boardView] || []).includes(FILTER_VERSIONS) &&
      defaultVersionsFilter.versionsIds &&
      defaultVersionsFilter.versionsIds.length
    ) {
      filters.defaultVersionsFilter = defaultVersionsFilter.versionsIds;
    }
    if (
      boardInfo.type &&
      (filtersByBoard[boardInfo.type] || []).includes(FILTER_PROJECTS) &&
      defaultProjectsFilter.projectsIds &&
      defaultProjectsFilter.projectsIds.length
    ) {
      filters.defaultProjectsFilter = defaultProjectsFilter.projectsIds;
    }
    if (
        boardInfo.type &&
        (filtersByBoard[boardInfo.type] || []).includes(FILTER_TIME_ON_BOARD) &&
        defaultTimeOnBoardFilter.boardDaysRange.moreThan ||
        defaultTimeOnBoardFilter.boardDaysRange.lessThan
    ) {
      filters.defaultTimeOnBoardFilter = defaultTimeOnBoardFilter.boardDaysRange;
    }
    if (
        boardInfo.type &&
        (filtersByBoard[boardInfo.type] || []).includes(FILTER_TIME_ON_COLUMN) &&
        defaultTimeOnColumnFilter.columnDaysRange.moreThan ||
        defaultTimeOnColumnFilter.columnDaysRange.lessThan
    ) {
      filters.defaultTimeOnColumnFilter = defaultTimeOnColumnFilter.columnDaysRange;
    }

    if (defaultStatusesFilter.statusesTypes && defaultStatusesFilter.statusesTypes.length) {
      filters.defaultStatusesFilter = defaultStatusesFilter.statusesTypes;
    }

    if (defaultColorsFilter.colors && defaultColorsFilter.colors.length) {
      filters.defaultColorsFilter = defaultColorsFilter.colors;
    }

    if (boardInfo.scoringType === ScoringType.basic && defaultQuadsFilter.quads && defaultQuadsFilter.quads.length) {
      filters.defaultQuadsFilter = defaultQuadsFilter.quads;
    }

    const activeQuickFilters = quickFilters.filter(item => item.isActive);
    if (activeQuickFilters.length) {
      filters.quickFilters = activeQuickFilters.map(item => item.id);
    }

    return filters;
  }

  static isFiltersApplied(
    quickFilters,
    defaultFilters: DefaultTaskFilter[],
    isDefaultMembersFilter: boolean,
    isDefaultLabelsFilter: boolean,
    isDefaultColumnsFilter: boolean,
    isDefaultSwimlanesFilter: boolean,
    isDefaultVersionsFilter: boolean,
    isDefaultProjectsFilter: boolean,
    isDefaultStatusesFilter: boolean,
    isDefaultColorsFilter: boolean,
    isDefaultQuadsFilter: boolean
  ) {
    return (
      defaultFilters.findIndex(f => f.isActive === true) >= 0 ||
      quickFilters.findIndex(f => f.isActive === true) >= 0 ||
      isDefaultMembersFilter ||
      isDefaultLabelsFilter ||
      isDefaultColumnsFilter ||
      isDefaultSwimlanesFilter ||
      isDefaultVersionsFilter ||
      isDefaultProjectsFilter ||
      isDefaultStatusesFilter ||
      isDefaultColorsFilter ||
      isDefaultQuadsFilter
    );
  }

  static getCacheKey(id: string | number) {
    return 'boardFilters:' + id;
  }

  public getDefaultFilterLabel(name) {
    switch (name) {
      case this.FILTER_NO_EFFORTS: {
        return this.boardInfo.backlogScoreXLabel ? 'No ' + this.boardInfo.backlogScoreXLabel : FILTER_NO_EFFORTS;
      }
      case FILTER_NO_VALUE: {
        return this.boardInfo.backlogScoreYLabel ? 'No ' + this.boardInfo.backlogScoreYLabel : FILTER_NO_VALUE;
      }
      default: {
        return name;
      }
    }
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
