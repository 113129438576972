<ng-container *ngIf="visibleFilter$ | async">
<div class="default_swimlanes_filter"
     (click)="onShowSwimlanesPopup()"
     [ngClass]="{'active': showSwimlanesPopUp}"
>
  Swimlanes
</div>

<div *ngFor="let swimlane of selectedSwimlanes$ | async"
     class="swimlane_filter_tag"
     (click)="onRemoveSwimlane(swimlane)"
>
  <p class="swimlane_title">
    {{swimlane.name}}
  </p>
  <div class="clear_block"></div>
</div>

<div a-context-popup *ngIf="showSwimlanesPopUp"
     [title]="'Swimlane'"
     class="default_swimlanes_list_popup"
     (close)="onHideSwimlanesPopup()"
>
  <swimlanes-filter-list
    [swimlanes]="boardSwimlanes$ | async"
    [selectedSwimlanes]="selectedSwimlanes$ | async"
    (addSwimlane)="onAddSwimlane($event)"
    (removeSwimlane)="onRemoveSwimlane($event)"
  ></swimlanes-filter-list>
</div>
</ng-container>
