<div class="users_avatar_block" *ngIf="users">
  <div class="full_list" *ngIf="shouldFullListRender">
    <div class="triangle_with_shadow"></div>
    <div class="users_tooltip_container">
      <div class="task_user" *ngFor="let user of users">
        <a-avatar class="full_list_user_avatar"
                  [user]="user"
        ></a-avatar>
        <p>{{user.fullname}}</p>
        <div class="divider"></div>
      </div>
    </div>

  </div>
  <div class="person_avatar">
    <a-avatar *ngFor="let user of users" [ngClass]="{group_avatars: users.length > 1}"
              [user]="user"
    ></a-avatar>
    <div class="hovers_block" [ngClass]="{show_hovers: users.length > 1, hide_hovers: users.length <2 }">
      <div class="hover"></div>
      <div class="hover"></div>
      <div class="hover"></div>
      <div class="hover"></div>
    </div>
  </div>
</div>
