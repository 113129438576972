import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable ,  Subject } from 'rxjs';
import { AppState, runParametricalSelector } from '../../../../../ngrx/state/';
import { IntegrationNames } from '../../../../core/integraition-list';
import * as fromIntegration from '../../../../core/store/integration.reducer';
import { GitlabPullRequest } from '../../../models/git-pull-request';
import * as fromAssocGitlabRepo from '../../../store/gitlab-associated-repository/gitlab-associated-repository.reducer';
import * as gitlabPullRequest from '../../../store/gitlab-pull-request/gitlab-pull-request.actions';
import * as fromGitlabPullrRequest from '../../../store/gitlab-pull-request/gitlab-pull-request.reducer';

@Component({
  selector: 'gitlab-task-pull-request-list',
  templateUrl: './gitlab-task-pull-request-list.component.html',
  styleUrls: ['./gitlab-task-pull-request-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GitlabTaskPullRequestListComponent implements OnInit, OnDestroy {
  @Input() taskId$: Observable<number>;

  public pullRequests$: Observable<GitlabPullRequest[]>;
  public isInstalled$: Observable<boolean> = this._store.select(fromIntegration.getIsInstalledGitlab);
  public asscocRepoList$ = this._store.select(fromAssocGitlabRepo.getEntities);
  public name = IntegrationNames.Gitlab;

  private destroy$ = new Subject();

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this.pullRequests$ = runParametricalSelector(
      this._store.select(fromGitlabPullrRequest.getTaskPullRequests),
      this.taskId$
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  onRemove(item: GitlabPullRequest) {
    this._store.dispatch(new gitlabPullRequest.DeleteTaskPullRequest({ id: item.id, task: item.task }));
  }
}
