
import {fromEvent as observableFromEvent, timer as observableTimer,  Observable ,  Subscription } from 'rxjs';

import {filter} from 'rxjs/operators';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SliderClient, SliderClients } from './slider-clients/slider-clients';

const SLIDER_AUTOPLAY_TIME = 10000;
const SLIDES_NUMBER = 4;

@Component({
  selector: 'pricing-page-slider',
  templateUrl: './pricing-page-slider.component.html',
  styleUrls: ['./pricing-page-slider.component.scss']
})
export class PricingPageSliderComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('clients_inner') clientsInnerEl: ElementRef;
  @ViewChild('slider_container') sliderContainerEl: ElementRef;
  @ViewChild('slider_item') sliderItemEl: ElementRef;

  subs: Subscription[] = [];

  public sliderClients: SliderClient[] = [];
  public sliderScrollDistance: number;
  public slideWidth: number;
  public lastSlideDistance: number;
  public sliderFullWidth: number;

  private isAutoplayOn = false;

  constructor(private _renderer: Renderer2) {}

  ngOnInit() {
    this.sliderClients = SliderClients;
  }

  ngAfterViewInit() {
    const singleClientWidth = this.sliderItemEl.nativeElement.clientWidth + 10;

    this.sliderFullWidth = this.sliderClients.length * singleClientWidth;
    this.slideWidth = singleClientWidth * SLIDES_NUMBER;
    this.sliderScrollDistance = singleClientWidth * SLIDES_NUMBER;

    this.lastSlideDistance = -Math.abs(Math.floor(this.sliderClients.length / SLIDES_NUMBER) * this.slideWidth);

    this._renderer.setStyle(this.clientsInnerEl.nativeElement, 'width', this.sliderFullWidth + 'px');
    this.subs.push(
      observableTimer(0, SLIDER_AUTOPLAY_TIME).pipe(
        filter(_ => this.isAutoplayOn))
        .subscribe(_ => {
          this.isLastSlidePosition()
            ? (this.sliderScrollDistance = 0)
            : (this.sliderScrollDistance = this.getDistanceToScrollForward());
          this.sliderScroll();
        })
    );

    this.subs.push(
      observableFromEvent(this.sliderContainerEl.nativeElement, 'mouseenter').subscribe(_ => {
        this.isAutoplayOn = false;
      })
    );
    this.subs.push(
      observableFromEvent(this.sliderContainerEl.nativeElement, 'mouseleave').subscribe(_ => {
        this.isAutoplayOn = true;
      })
    );
  }

  onClickPrev() {
    if (this.sliderScrollDistance === 0) {
      this.sliderScrollDistance = this.lastSlideDistance;
    } else {
      this.sliderScrollDistance = this.sliderScrollDistance + this.slideWidth;
    }
    this.sliderScroll();
  }
  onClickNext() {
    if (this.sliderScrollDistance <= this.slideWidth - this.sliderFullWidth) {
      this.sliderScrollDistance = 0;
    } else {
      this.sliderScrollDistance = this.getDistanceToScrollForward();
    }
    this.sliderScroll();
  }

  sliderScroll() {
    this._renderer.setStyle(this.clientsInnerEl.nativeElement, 'left', this.sliderScrollDistance + 'px');
  }

  getDistanceToScrollForward() {
    return this.sliderScrollDistance - this.slideWidth;
  }

  isLastSlidePosition() {
    return Math.abs(this.sliderScrollDistance - this.slideWidth) >= this.sliderFullWidth;
  }
  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
