import { switchMap, take } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RouterNavigateService } from '../../../shared/services/router-navigate.service';
import { BillingPublicApiService } from '@atlaz/billing/shared/billing-public-api.service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { BugTrackerService } from '@atlaz/core/services/bag-tracker.service';
import { THERE_D_SECURE_BACK_URL } from '@atlaz/billing/util/billing-url';
import { getBackUrl } from '../../../../helpers/url';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'there-d-secure-popup',
  templateUrl: './there-d-secure-popup.component.html',
  styleUrls: ['./there-d-secure-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThereDSecurePopupComponent implements OnInit {
  public url$: Observable<string>;
  private stripe = (<any>window).Stripe(environment.billing.stripePublicKey);

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _routerNav: RouterNavigateService,
    private _billingSerice: BillingPublicApiService,
    private _bugTracker: BugTrackerService
  ) {}

  ngOnInit() {
    this.url$ = this._billingSerice.billingSubscription$.pipe(
      take(1),
      switchMap(billingSub => {
        const sourceData = {
          type: 'three_d_secure',
          amount: 100,
          currency: 'usd',
          three_d_secure: {
            customer: billingSub.stripeCustomerId,
            card: billingSub.sourceData['id']
          },
          redirect: {
            return_url:
              getBackUrl() +
              '/billing/' +
              THERE_D_SECURE_BACK_URL +
              '/' +
              (this._activatedRoute.snapshot.queryParams['plan']
                ? this._activatedRoute.snapshot.queryParams['plan'].toLowerCase()
                : '') +
              '-' +
              (this._activatedRoute.snapshot.queryParams['period'] || '')
          }
        };
        if (
          this._activatedRoute.snapshot.queryParams &&
          this._activatedRoute.snapshot.queryParams['plan'] &&
          this._activatedRoute.snapshot.queryParams['period']
        ) {
          sourceData['metadata'] = this._activatedRoute.snapshot.queryParams;
        }
        return this.stripe.createSource(sourceData).then(result => {
          if (result.error) {
            this._bugTracker.critical('Creating 3d secure source failed', billingSub, result.error);
            //this.onClose();
          } else {
            return result.source.redirect.url;
          }
        });
      })
    );
  }

  onClose(event: UIEvent) {
    // ignore on esc closing & missclick event
    if (['mousedown', 'keydown'].includes(event.type)) {
      return;
    }
    this._routerNav.deactivatePopupOutlet();
  }
}
