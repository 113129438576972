
import {map, filter, switchMap, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { isNotPresent } from '../../../../helpers/index';
import { AppState } from '../../../ngrx/state/app-state';
import { RouterNavigateService } from '../../../shared/services/router-navigate.service';
import * as bitbucketAssocRepo from '../../git/store/bitbucket-associated-repository/bitbucket-associated-repository.actions';
import * as bitbucketCompany from '../../git/store/bitbucket-company/bitbucket-company.actions';
import * as fromBitbucketCompany from '../../git/store/bitbucket-company/bitbucket-company.reducer';
import * as bitbucketRepo from '../../git/store/bitbucket-repository/bitbucket-repository.actions';

@Injectable()
export class BitbucketCompanyExistsGuard implements CanActivate {
  constructor(private routerNav: RouterNavigateService, private store: Store<AppState>) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.store.dispatch(new bitbucketCompany.GetIndex());

    const loadedEvent$ = this.store.select(fromBitbucketCompany.isFirstLoading).pipe(filter(isNotPresent));
    const isEmpty$ = loadedEvent$.pipe(switchMap(_ => this.store.select(fromBitbucketCompany.isEmpty)),take(1),);

    return isEmpty$.pipe(map(this.navigateSideEffect.bind(this)));
  }

  private navigateSideEffect(isEmpty: boolean): true {
    if (isEmpty) {
      this.routerNav.navigateToBitbucketAddCompany();
      return true;
    }

    this.store.dispatch(new bitbucketAssocRepo.GetIndex());
    this.store.dispatch(new bitbucketRepo.GetIndex());

    return true;
  }
}
