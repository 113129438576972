
import {filter, distinctUntilChanged, switchMap, map} from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Observable ,  Subscription ,  BehaviorSubject ,  combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { FormComponent, FormSaveType, FormServiceParams, FormV2Service } from '../../shared/services/form-v2.service';
import { Board, Column } from '../../interfaces';
import { AppState } from '../../ngrx/state';
import {
  fromBoards,
  getBoardById,
  isBacklogBoardBuilder,
  isKanbanBoardBuilder
} from '../../ngrx/reducers/board.reducer';
import { getBoardColumnCounter, getTargetColumns } from '../../ngrx/reducers/column.reducer';
import { ColumnGetAction } from '../../ngrx/actions/column.actions';
import { COPY_COLUMNS } from '../../path.routing';
import { boardType, COLUMN_PL } from '../../constants';
import { JsonApiSingeModelResponse } from '../../shared/services/app/web-socket/http-response';
import { HandleResponseAction } from '../../ngrx/actions/root.action';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'copy-column-form',
  templateUrl: './copy-column-form.component.html',
  styleUrls: ['./copy-column-form.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyColumnFormComponent implements OnInit, FormComponent, OnDestroy {
  @Input() boardId: number;
  @Input() column: Column;
  @Input() isMove = true;
  @Output() closePopup = new EventEmitter();

  subs: Subscription[] = [];

  public currentBoard$: Observable<Board>;
  public targetBoards$: Observable<Board[]>;
  public isColumnCanBeMovedFromBoard$: Observable<any>;
  public form: FormGroup;
  public availablePositions$ = new BehaviorSubject([]);
  public lastColumnId: number;
  public formServiceCopyParams: FormServiceParams = {
    formObserver: {
      next: _ => {},
      error: _ => {},
      complete: () => {
        this._toastr.success('Copying of the column has started. This may take a few seconds');
        this.onClose();
      }
    },
    saveType: FormSaveType.add,
    entityToEdit: COPY_COLUMNS,
    prepareFormValue: formValue => {
      return {
        fromColumn: this.column.id,
        toBoard: formValue['targetBoard'],
        name: formValue['name'],
        insertBeforeColumn: formValue['targetPosition'],
        withTasks: 1
      };
    }
  };
  public formServiceMovingParams: FormServiceParams = {
    formObserver: {
      next: (resp: JsonApiSingeModelResponse<any>) => {
        this._store.dispatch(new HandleResponseAction(resp));
      },
      error: _ => {},
      complete: () => {
        this.onClose();
      }
    },
    saveType: FormSaveType.edit,
    entityToEdit: COLUMN_PL,
    prepareFormValue: formValue => {
      if (this.boardId === formValue['targetBoard'] || !formValue['targetBoard']) {
        const value = {
          id: this.column.id
        };
        if (formValue['targetPosition']) {
          value['insertBeforeColumn'] = formValue['targetPosition'];
        } else {
          value['insertAfterColumn'] = this.lastColumnId;
        }
        return value;
      }
      return {
        id: this.column.id,
        board: formValue['targetBoard'],
        positionNumber: formValue['targetPosition']
      };
    }
  };
  get formServiceParams() {
    return this.isMove ? this.formServiceMovingParams : this.formServiceCopyParams;
  }

  constructor(
    private _store: Store<AppState>,
    private _fb: FormBuilder,
    public _formService: FormV2Service,
    private _toastr: ToastrService
  ) {}

  ngOnInit() {
    this.form = this._fb.group({
      name: [this.isMove ? this.column.name : this.column.name + ' Copy'],
      targetBoard: [0],
      targetPosition: [null]
    });

    const targetBoardColumns$ = this.form
      .get('targetBoard')
      .valueChanges.pipe(distinctUntilChanged(),
      switchMap((boardId: number) => this._store.pipe((getBoardById(+boardId)))),
      switchMap(board => {
        if (!board['columnsIds']) {
          this._store.dispatch(
            new ColumnGetAction({
              board: board.id,
              sort: 'position'
            })
          );
        }
        return this._store.pipe((getTargetColumns(board.id, 'position')));
      }),);

    this.currentBoard$ = this._store.pipe((getBoardById(this.boardId)));
    this.targetBoards$ = this._store
      .select(fromBoards.getAllAvailable).pipe(
      map((boards: Board[]) => boards.filter(board => board.type !== boardType.roadmap)));

    this.subs.push(
      targetBoardColumns$.subscribe((targetBoardColumns: Column[]) => {
        const targetBoardId = this.form.get('targetBoard').value;
        const targetBoardColumnIds = targetBoardColumns.map(column => column.parent || column.id);
        this.availablePositions$.next(this.generatePositions(targetBoardId, targetBoardColumns, this.isMove));
        this.lastColumnId = targetBoardColumns.length ? targetBoardColumnIds[targetBoardColumnIds.length - 1] : null;
        if (this.isMove && this.boardId !== targetBoardId) {
          this.form.get('targetPosition').setValue(1);
        } else {
          this.form.get('targetPosition').setValue(targetBoardColumnIds[0] || null);
        }
      })
    );

    this.form.get('targetBoard').setValue(this.boardId);
    this.isColumnCanBeMovedFromBoard$ = combineLatest(
      this._store.pipe((isKanbanBoardBuilder(this.boardId))),
      this._store.pipe((isBacklogBoardBuilder(this.boardId))),
      this._store.pipe((getBoardColumnCounter),map(counter => counter[this.column.type] > 1),),
      (isKanban, isBacklog, counter) => isKanban || isBacklog || counter
    );

    this.subs.push(
      this.isColumnCanBeMovedFromBoard$.pipe(
        distinctUntilChanged(),
        filter((movable: boolean) => !movable && this.isMove),)
        .subscribe(() => {
          this.form.get('targetBoard').setValue(this.boardId);
          this.form.get('targetBoard').disable();
        })
    );
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  onSubmit() {
    this._formService.initFormParams(this.form, this.formServiceParams);
    if (this.form.valid) {
      this._formService.submit();
    }
  }

  onClose() {
    this.closePopup.emit();
  }

  generatePositions(forBoardId: number, existingColumns: Column[], isMove: boolean) {
    const result = [];
    const existingColumnIds = existingColumns
      .map(column => column.parent || column.id)
      .filter((item, i, arr) => !i || item !== arr[i - 1]);
    const columnCount = existingColumnIds.length;
    const generateForCurrentBoard = () => {
      for (let i = 0, j = 0; i < columnCount; i++, j++) {
        if (existingColumnIds[j] === this.column.id) {
          result.push({ name: j + 1 + ' (current)', value: existingColumnIds[i] });
          i++;
        } else {
          result.push({ name: j + 1, value: existingColumnIds[i] });
        }
      }
      // if last is not current
      if (result[result.length - 1].value !== this.column.id || result.length < columnCount) {
        result.push({ name: columnCount, value: null });
      }
    };

    if (isMove) {
      if (this.boardId === forBoardId) {
        generateForCurrentBoard();
      } else {
        existingColumnIds.forEach((item, i) => {
          result.push({ name: i + 1, value: i + 1 });
        });
        result.push({ name: result.length + 1, value: result.length + 1 });
      }
    } else {
      result.splice(0, 0, { name: 'Beginning', value: existingColumnIds[0] }, { name: 'End', value: null });
      if (forBoardId === this.boardId) {
        const nextIndex = existingColumnIds.findIndex(item => item === this.column.id) + 1;
        result.splice(1, 0, { name: 'After', value: existingColumnIds[nextIndex] || null });
      }
    }
    return result;
  }
}
