import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RouterNavigateService } from '../../../../shared/services/router-navigate.service';
import { GetCompleteAction } from '../../../../ngrx/actions/root.action';
import { BOARD_PL } from '../../../../constants';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../ngrx/state';
import { Observer ,  combineLatest ,  Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModelValidators } from '../../../../shared/validators/model-validators';
import { Board, Task } from '../../../../interfaces';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../../../shared/services/form-v2.service';
import {
  JsonApiSingeModelResponse,
  jsonApiToEntityState
} from '../../../../shared/services/app/web-socket/http-response';
import { getTasksByBoardId, getTasksInGlobalDoneByBoardId } from '../../../../ngrx/reducers/task.reducer';

@Component({
  selector: 'release-form',
  templateUrl: './release-form.component.html',
  styleUrls: ['./release-form.component.scss'],
  providers: [FormV2Service]
})
export class ReleaseFormComponent implements OnInit, OnDestroy {
  formObserver: Observer<any> = {
    next: (resp: JsonApiSingeModelResponse<any>) => {
      this._store.dispatch(new GetCompleteAction(jsonApiToEntityState(resp)));
      this.onClose();
    },

    error: error => {
      console.warn(error);
    },

    complete: () => {}
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.edit,
    entityToEdit: BOARD_PL,
    formObserver: this.formObserver
  };

  @Input() board: Board;
  subs: Subscription[] = [];

  public form: FormGroup;
  public howManyTasksIsUnDone = 0;
  public releaseTypes = {
    all: 'all',
    completed: 'completed'
  };

  public errorMessages = {
    required: 'Version Name can’t be empty',
    maxlength: 'Version name exceeds the maximum length (50 symbols)'
  };

  constructor(
    private _routerNav: RouterNavigateService,
    private _store: Store<AppState>,
    public _formService: FormV2Service,
    private _fb: FormBuilder
  ) {}

  ngOnInit() {
    this.subs.push(
      combineLatest(
        this._store.select(getTasksByBoardId(this.board.id)),
        this._store.select(getTasksInGlobalDoneByBoardId(this.board.id)),
        (all, unDone) => {
          return all.filter((task: Task) => !task.version).length - unDone.filter((task: Task) => !task.version).length;
        }
      ).subscribe(count => {
        this.howManyTasksIsUnDone = count;
      })
    );
    this.form = this._fb.group({
      id: [this.board.id],
      release: [this.releaseTypes.completed],
      sprintName: [this.board.sprintName, ModelValidators.version.name]
    });
    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  onClose() {
    this._routerNav.deactivatePopupOutlet();
  }

  onReleaseSprint() {
    if (this.form.valid) {
      return this._formService.submit();
    }
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
