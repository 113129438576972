import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateTimeFormatterService } from './services/date-time-formatter.service';

const sharedComponents = [];

@NgModule({
  imports: [CommonModule],
  declarations: sharedComponents,
  exports: sharedComponents
})
export class DateTimeFormatterModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: DateTimeFormatterModule,
      providers: [DateTimeFormatterService]
    };
  }
}
