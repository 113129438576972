import { distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { AppState, LEFT_MENU } from '../../ngrx/state';
import { AuthService } from '../../shared/services/app/auth.service';

import { AppUrls } from '../../app-urls';
import { ComponentToggleAction } from '../../ngrx/actions/component.actions';
import { Observable } from 'rxjs';
import { Board, Company, User } from '../../interfaces';
import { GlobalSearchStopAndGoBackAction } from '../../ngrx/actions/global-search.actions';
import { getUnseenUserNotificationsCount } from '../../ngrx/reducers/notification.reducer';
import { Router } from '@angular/router';
import { GET_STARTED, LOAD_ERROR_PAGE, LOGIN } from '../../path.routing';
import { isStickyLeftMenu } from '../../ngrx/reducers/component.reducer';
import { PermissionsService } from '../../permissions/permissions.service';
import { CompanyService } from '../../shared/services/app/company.service';
import { Features } from '../../libs/paywall/features.constants';
import { PaywallService } from '../../libs/paywall/paywall.service';
import { BoardThemeManagerService } from '../../libs/board-theme-manager/board-theme-manager.service';
import { fromBoards } from '../../ngrx/reducers/board.reducer';
import { fromBoardThemes } from '../../ngrx/reducers/board-theme.reducer';
import { BoardTheme } from '../../interfaces/board-theme';
import { isPresent } from '../../../helpers';
import { of } from 'rxjs/internal/observable/of';
import { GlobalHotkeysService } from '../../libs/global-hotkeys/global-hotkeys.service';
import { BillingSubscriptionEntity } from '@atlaz/billing/interfaces/billing-subscription';
import * as fromBillingSubscription from '@atlaz/billing/store/subscription/subscription.reducer';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppHeaderComponent implements OnInit {
  public isAuth$: Observable<boolean> = this._authService.isLoggedIn$;
  public activeUser$: Observable<User> = this._authService.activeUser$;
  public userInActiveCompany$ = this._authService.userInActiveCompany$;

  public appUrls = AppUrls;
  public isVisibleProfileMenu = false;
  public isHeaderMenuVisible = false;
  public isMenuIconArrowBack = false;
  public notificationsCount$: Observable<number>;
  public isAllowPage$: Observable<boolean>;

  public isStickyLeft$: Observable<boolean>;
  public isSuperUser$ = this._permissions.isSuperUser$;
  public isNotGuest$ = this._permissions.isNotGuest$;
  public logoUrl$: Observable<any>;
  public logoSrc$: Observable<string>;

  public trialEndDate$: Observable<number>;
  public trialEndDateCount$: Observable<number | string>;

  constructor(
    private _authService: AuthService,
    private _store: Store<AppState>,
    private _permissions: PermissionsService,
    private _router: Router,
    private _company: CompanyService,
    private _paywall: PaywallService,
    private _themeManager: BoardThemeManagerService,
    private _globalHotKeys: GlobalHotkeysService //launch singleton
  ) {}

  ngOnInit() {
    this.trialEndDate$ = this._store
      .select(fromBillingSubscription.getCompanyBillingSubscription)
      .pipe(filter(isPresent), map((billingSub: BillingSubscriptionEntity) => billingSub.trialEnd));
    this.trialEndDateCount$ = this.trialEndDate$.pipe(
      map(trialEnd => {
        const diff = trialEnd - Math.round(+new Date() / 1000);
        return diff > 0 ? Math.ceil(diff / 86400) : -1;
      }),
      map(days => {
        if (days === -1) {
          return '';
        } else if (days === 0) {
          return 'today';
        } else {
          return 'in ' + days + ' day' + (days === 1 ? '' : 's');
        }
      })
    );
    this._store
      .select(fromBoards.getSelectedBoard)
      .pipe(
        distinctUntilChanged(),
        switchMap(
          (board: Board) => (board && board.theme ? this._store.select(fromBoardThemes.getById(board.theme)) : of(null))
        )
      )
      .subscribe((theme: BoardTheme) => {
        if (theme) {
          this._themeManager.setTheme(theme);
        } else {
          this._themeManager.resetTheme();
        }
      });

    this.notificationsCount$ = this._store.pipe(getUnseenUserNotificationsCount);
    const isAllowPage = () =>
      this._router.navigated
        ? !this._router.isActive(GET_STARTED, false) &&
          !this._router.isActive(LOAD_ERROR_PAGE, false) &&
          !this._router.isActive(LOGIN, false)
        : false;

    this.logoSrc$ = this._company.currentCompany$.pipe(
      map(
        (company: Company) =>
          company &&
          company.isActive &&
          company['companyLogo'] &&
          this._paywall.isFeatureEnabled(Features.BrandedWorkspace)
            ? 'url(' + company['companyLogo'] + ')'
            : 'url(/assets/img/logo.svg)'
      )
    );
    this.isAllowPage$ = this._router.events.pipe(map(isAllowPage), startWith(isAllowPage()));

    this.isStickyLeft$ = this._store.select(isStickyLeftMenu);

    this.logoUrl$ = this.userInActiveCompany$.pipe(map(active => (active ? AppUrls.getUrlOverview() : undefined)));
  }

  onToggleUserMenu() {
    this.isVisibleProfileMenu = !this.isVisibleProfileMenu;
  }
  onToggleHeaderMenu() {
    this.isHeaderMenuVisible = !this.isHeaderMenuVisible;
  }
  onToggleLeftMenu() {
    if (this.isMenuIconArrowBack) {
      this._store.dispatch(new GlobalSearchStopAndGoBackAction());
      this.isMenuIconArrowBack = false;
    } else {
      document.getElementById('search_input_left_menu').focus();
      this._store.dispatch(new ComponentToggleAction({ name: LEFT_MENU }));
    }
  }

  onAnimateMenu(value: boolean) {
    this.isMenuIconArrowBack = value;
  }
}
