
import {debounceTime, merge} from 'rxjs/operators';
import { Component, Input, OnChanges, SimpleChanges, ChangeDetectorRef, OnDestroy, HostBinding } from '@angular/core';
import { FormControl } from '@angular/forms';

const defaultErrorMessages: { [validator: string]: any } = {
  invalidDateFormat: 'Invalid date Format',
  email: 'Please enter a valid email address.',
  required: 'Field is required!'
};

@Component({
  selector: 'validation-hint',
  templateUrl: './validation-hint.component.html',
  styleUrls: ['./validation-hint.component.scss']
})
/**
 * @deprecated use help-block instead
 */
export class ValidationHintComponent implements OnChanges, OnDestroy {
  @Input() control: FormControl;
  @Input() countLimit = 1;
  /**
   * specify custom error messages for control
   */
  @Input() messages: { [validator: string]: any } = {};

  sub;
  defaultErrorMessages = defaultErrorMessages;

  errors: { [validator: string]: any };
  validators: string[];

  constructor(private _cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['control'] && changes['control'].previousValue !== changes['control'].currentValue) {
      setTimeout(() => {
        this.sub && this.sub.unsubscribe();
        this.sub = this.control.valueChanges.pipe(
          merge(this.control.statusChanges),
          debounceTime(200),)
          .subscribe(() => {
            this.errors = this.control.errors;
            this.validators =
              this.control.touched && this.control.dirty && this.control.errors
                ? Object.keys(this.control.errors).slice(0, this.countLimit)
                : [];
            this._cd.detectChanges();
          });
      }, 500);
    }
  }

  ngOnDestroy() {
    this.sub && this.sub.unsubscribe();
  }
}
