import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Project } from '../../../../../interfaces';

@Component({
  selector: 'sidebar-projects-list',
  templateUrl: './sidebar-projects-list.component.html',
  styleUrls: ['./sidebar-projects-list.component.scss']
})
export class SidebarProjectListComponent implements OnInit {
  @Input() taskProjectId: number;
  @Input() projects: Project[];

  @Output() select = new EventEmitter();

  public selectedProject: number;

  constructor() {}

  ngOnInit() {
    this.selectedProject = this.taskProjectId ? this.taskProjectId : null;
  }

  onSelectProject(project: Project) {
    this.selectedProject = this.selectedProject === project.id ? 0 : project.id;
    this.select.emit(this.selectedProject);
  }
}
