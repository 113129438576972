import { TestCheklist } from '../../ngrx/fixtures/checklist.fixture';
import { createSelector, Selector } from 'reselect';
import { compose, getProperty } from '../../../helpers/index';

/**
 * @description
 * creates Selector from objectSelector and property string
 *
 * Selector can contain object or function that creates object
 * @example
 * #1
 * const getProject = createSelector(
 * getState,
 * getSelectedId,
 * (state, id) => state[id]
 * );
 *
 * const getProjectShortName = createPropertySelector(project, 'shortName')
 *
 * #2
 * const getProject = createSelector(
 * getState,
 * state => id => state[id]
 * );
 *
 * const getProjectShortName = createPropertySelector(project, 'shortName')
 */

export type Lens<T, P> = (args: P) => T;

function _getProperty<T extends {}, K extends keyof T, P>(source: Lens<T, P> | T, key: K): Lens<T[K], P> | T[K] {
  if (typeof source === 'object' && source[key] !== undefined) {
    return <T[K]>getProperty(source, key);
  } else if (typeof source === 'function') {
    return <Lens<T[K], P>>compose(r => getProperty(r, key), source);
  } else {
    throw new Error('you should path function of object here');
  }
}

function _simplyGetProperty<T extends {}, K extends keyof T, P>(source: T, key: K): T[K] {
  return getProperty(source, key);
}

export type PropertyGetter = <T, K extends keyof T, P>(obj: T | Lens<T, P>, prop: K) => Lens<T[K], P> | T[K];

export function getPropertySelectorCreator(defaultPropertyGetter: PropertyGetter) {
  return function<I, R, K extends keyof R, P>(selector: Selector<I, Lens<R, P> | R>, property: K) {
    return createSelector(selector, source => defaultPropertyGetter<R, K, P>(source, property));
  };
}

export const createPropertySelector = getPropertySelectorCreator(_getProperty);
