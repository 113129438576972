import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { BillingPlan } from '@atlaz/billing/interfaces/billing-plan';
import { BillingPlanName } from '@atlaz/billing/constants/billing-subscription';
import { BillingSubscriptionEntity } from '@atlaz/billing/interfaces/billing-subscription';
import { PaywallService } from '../../../../libs/paywall/paywall.service';

@Component({
  selector: 'pricing-plans-v3',
  templateUrl: './pricing-plans-v3.component.html',
  styleUrls: ['./pricing-plans-v3.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PricingPlansV3Component implements OnChanges {
  @Input() plans: { [planName: string]: BillingPlan };
  @Input() currentSub: BillingSubscriptionEntity;
  @Input() showFreePlan: boolean;
  @Output() onUpgrade = new EventEmitter();

  public isMonthly = true;
  public BillingPlanName = BillingPlanName;

  constructor(private _paywall: PaywallService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentSub && changes.currentSub.currentValue && changes.currentSub.currentValue.period === 'year') {
      this.isMonthly = false;
    }
  }

  onSetMonthly(value) {
    this.isMonthly = value;
  }

  onUpgradeToBasic() {
    if (this.isBasicAvailable()) {
      this.onUpgrade.emit({ plan: BillingPlanName.Basic, isMonthly: this.isMonthly });
    }
  }

  onUpgradeToPremium() {
    if (this.isPremiumAvailable()) {
      this.onUpgrade.emit({ plan: BillingPlanName.Premium, isMonthly: this.isMonthly });
    }
  }

  isPremiumAvailable() {
    if (!this.currentSub) {
      return true;
    }
    const isActive = this.currentSub.status === 'active' || this.currentSub.status === 'past_due';
    const isPremiumNow = this.currentSub.plan === BillingPlanName.Premium;
    const isLowerPlan = this.currentSub.plan !== BillingPlanName.Premium;
    const isAvailableToYearUpgrade = this.currentSub.period !== 'year' && !this.isMonthly;
    return !isActive || (isPremiumNow && isAvailableToYearUpgrade) || isLowerPlan;
  }

  isBasicAvailable() {
    if (!this.currentSub) {
      return true;
    }
    const isActive = this.currentSub.status === 'active' || this.currentSub.status === 'past_due';
    const isBasicNow = this.currentSub.plan === BillingPlanName.Basic;
    const isLowerPlan =
      this.currentSub.plan !== BillingPlanName.Premium && this.currentSub.plan !== BillingPlanName.Basic;
    const isAvailableToYearUpgrade = this.currentSub.period !== 'year' && !this.isMonthly;
    return !isActive || (isBasicNow && isAvailableToYearUpgrade) || isLowerPlan;
  }

  getBasicBtnTitle() {
    if (!this.currentSub) {
      return 'UPGRADE';
    }
    const isActive = this.currentSub.status === 'active' || this.currentSub.status === 'past_due';
    const isBasicNow = this.currentSub.plan === BillingPlanName.Basic;
    const isLowerPlan =
      this.currentSub.plan !== BillingPlanName.Premium && this.currentSub.plan !== BillingPlanName.Basic;
    const isAvailableToYearUpgrade = this.currentSub.period !== 'year' && !this.isMonthly;
    if (!isActive && isBasicNow) {
      return 'RENEW SUBSCRIPTION';
    } else if (isBasicNow && isAvailableToYearUpgrade) {
      return 'SWITCH TO YEARLY';
    } else if (isBasicNow) {
      return 'YOUR CURRENT PLAN';
    } else if (isLowerPlan) {
      return 'UPGRADE';
    } else {
      return 'YOU HAVE ALL FEATURES';
    }
  }

  getPremiumBtnTitle() {
    if (!this.currentSub) {
      return 'UPGRADE';
    }
    const isActive = this.currentSub.status === 'active' || this.currentSub.status === 'past_due';
    const isPremiumNow = this.currentSub.plan === BillingPlanName.Premium;
    const isLowerPlan = this.currentSub.plan !== BillingPlanName.Premium;
    const isAvailableToYearUpgrade = this.currentSub.period !== 'year' && !this.isMonthly;
    if (!isActive && isPremiumNow) {
      return 'RENEW SUBSCRIPTION';
    } else if (isPremiumNow && isAvailableToYearUpgrade) {
      return 'SWITCH TO YEARLY';
    } else if (isPremiumNow) {
      return 'YOUR CURRENT PLAN';
    } else if (isLowerPlan) {
      return 'UPGRADE';
    } else {
      return 'YOU HAVE ALL FEATURES';
    }
  }

  stayFree() {
    this._paywall.hide();
  }
}
