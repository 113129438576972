import { withLatestFrom, take } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observer } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { JsonApiSingeModelResponse } from '../../../shared/services/app/web-socket/http-response';
import { HandleResponseAction } from '../../../ngrx/actions/root.action';
import {
  FormComponent,
  FormSaveType,
  FormServiceParams,
  FormV2Service
} from '../../../shared/services/form-v2.service';
import { AppState } from '../../../ngrx/state/';
import { ActivatedRoute } from '@angular/router';
import { RouterNavigateService } from '../../../shared/services/router-navigate.service';
import { BillingPublicApiService } from '@atlaz/billing/shared/billing-public-api.service';
import { BillingPeriod, SUBSCRIPTION_PATH } from '@atlaz/billing/constants/billing-subscription';
import { BillingSubscriptionEntity } from '@atlaz/billing/interfaces/billing-subscription';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'there-d-secure-back-url.component',
  templateUrl: './there-d-secure-back-url.component.html',
  styleUrls: ['./there-d-secure-back-url.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThereDSecureBackUrlComponent implements OnInit, FormComponent {
  formObserver: Observer<any> = {
    next: (resp: JsonApiSingeModelResponse<any>) => {
      this._store.dispatch(new HandleResponseAction(resp));
      this._toastr.success('Card has been successfully verified');
      this._routeNav.navigateToBilling();
    },

    error: originalError => {
      const error = this._formService.normalizeServerErrorResponse(originalError);
      this._toastr.error(error.message);
      this._billingService.billingSubscription$.pipe(take(1)).subscribe((sub: BillingSubscriptionEntity) => {
        const params = this._route.params['value'];
        const plan = params.plan;
        let planName = plan && plan.split('-')[0];
        planName = planName && planName.toUpperCase();
        const planPeriod = plan && plan.split('-')[1];
        const isMonthly = (!planPeriod && sub.period === BillingPeriod.Month) || planPeriod === BillingPeriod.Month;
        this._routeNav.navigateEditPaymentDetails(isMonthly, planName);
      });
    },

    complete: () => {}
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.edit,
    entityToEdit: SUBSCRIPTION_PATH,
    formObserver: this.formObserver
  };

  form: FormGroup;

  constructor(
    private _fb: FormBuilder,
    public _formService: FormV2Service,
    private _route: ActivatedRoute,
    private _routeNav: RouterNavigateService,
    private _billingService: BillingPublicApiService,
    private _toastr: ToastrService,
    private _store: Store<AppState>
  ) {}

  ngOnInit() {
    this._billingService.billingSubscription$
      .pipe(take(1), withLatestFrom(this._route.queryParams))
      .subscribe(([sub, queryParams]) => {
        this.form = this._fb.group({
          id: [sub.id],
          action: ['three-d-secure-charge'],
          source: [queryParams['source']]
        });
        this._formService.initFormParams(this.form, this.formServiceParams);
        this.onSubmit();
      });
  }

  onSubmit() {
    this.form.markAsTouched();
    if (this.form.invalid) {
      return;
    }
    this._formService.submit();
  }
}
