<div a-context-popup
     [title]="'Add Member'"
     *ngIf="isConfirmAddProjectUser"
     (close)="onToggleConfirm()"
>
  <div class="popup_content">
    <p>
      We will grant <strong>{{userToConfirm.fullname || userToConfirm.nickname}}</strong> access to this board.<br>
      Are you sure?
    </p>
    <button type="button" a-button class="btn_primary" (click)="onConfirmProjUser(userToConfirm)">Confirm</button>
    <button type="button" a-button (click)="onToggleConfirm()" class="btn_text">Cancel</button>
  </div>
</div>

<input [focus]="true" placeholder="Search Members..." (keyup)="0" #input_search_members/>
<div class="users_wrapper">
  <h4 *ngIf="!isPublicBoard">Board Members</h4>
  <div *ngFor="let user of allUsers | usersNameFilter: input_search_members.value"
       class="user_title"
       (click)="onSelectUser(user)"
  >
    <div class="avatar_container">
      <a-avatar
        [user]="user"
      ></a-avatar>
    </div>
    <div class="member_info">
      <div class="member_info_name"> {{user.firstname}} {{user.lastname}}</div>
      <div class="member_info_text">@{{user.nickname}}</div>
    </div>
    <span class="check_label" *ngIf="selectedUsersMap[user.id]"></span>
  </div>

  <ng-container *ngIf="projectOnlyUsers.length && !isPublicBoard">
    <h4>Project Members</h4>
    <div *ngFor="let user of projectOnlyUsers | usersNameFilter: input_search_members.value"
         class="user_title"
         (click)="onToggleConfirm(user)"
    >
      <div class="avatar_container">
        <a-avatar
          [user]="user"
        ></a-avatar>
      </div>
      <div class="member_info">
        <div class="member_info_name"> {{user.firstname}} {{user.lastname}}</div>
        <div class="member_info_text">@{{user.nickname}}</div>
      </div>
      <span class="check_label" *ngIf="selectedUsersMap[user.id]"></span>
    </div>
  </ng-container>
</div>
