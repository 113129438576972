
import {fromEvent as observableFromEvent,  Subscription ,  Observable } from 'rxjs';
import { AfterViewInit, Directive, ElementRef, Input, NgZone, OnDestroy } from '@angular/core';
import { RoadmapScrollService } from '../services/roadmap-scroll.service';
import { mouseWheelEventName, getScrollDelta } from '../../../../helpers/event';

@Directive({
  selector: '[multiplyScrollSynchro]'
})
export class MultiplyScrollSynchroDirective implements AfterViewInit, OnDestroy {
  @Input() syncScrollLeft;
  @Input() syncScrollTop;

  private sub: Subscription;

  constructor(private elementRef: ElementRef, private _zone: NgZone, private _scrollService: RoadmapScrollService) {}

  ngAfterViewInit() {
    const source = this.elementRef.nativeElement;

    // registering listener
    this._scrollService.registerContainer(source);

    this._zone.runOutsideAngular(() => {
      this.sub = observableFromEvent(source, mouseWheelEventName).subscribe((event: MouseWheelEvent) => {
        const { deltaX, deltaY } = getScrollDelta(event);
        event.stopPropagation();
        event.preventDefault();
        this._scrollService.scrollTimeSheet(deltaX, deltaY);
      });
    });
  }

  ngOnDestroy() {
    this._scrollService.unRegisterContainer(this.elementRef.nativeElement);

    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
