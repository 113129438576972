import { of as observableOf, Observable, BehaviorSubject } from 'rxjs';

import { take, map, distinctUntilChanged, publishReplay, refCount, switchMap, pluck } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { Column, Project, Task } from '../../../interfaces/';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state/';
import { AuthService } from '../../../shared/services/app/auth.service';
import { SyncRoadmapGuiStateService } from '../services/sync-roadmap-gui-state-service';
import { getColumnById, getColumnsByBoard } from '../../../ngrx/reducers/column.reducer';
import { RoadmapDragService } from '../services/roadmap-drag.service';
import { HiddenItems, RoadMapItem } from '../interfaces/roadmap.interface';
import { TaskDeleteAction } from '../../../ngrx/actions/task.actions';
import { RoadmapBoardChangeTaskStatusAction } from '../store/roadmap-board.action';
import { FormControl } from '@angular/forms';
import { OpenedTaskDataChangedAction } from '../../../task/ngrx/actions/opened-task.action';
import { SegmentTrackRoadmapTaskStatusChanged } from '../../../ngrx/actions/segment.actions';
import { columnTypes } from '../../../constants';
import { fromProject } from '../../../ngrx/reducers/project.reducer';
import { GuiStateQuickTaskEditShow } from '../../../ngrx/actions/gui-state-memorized.actions';
import { RoadMapAddFormTypes } from '../constants/roadmap.constants';

const convertNullToEmptyCoulumn = (column: Column) => {
  return !!column ? column : { name: '', parent: 0 };
};

const statusMap = {
  [columnTypes.done]: 'done',
  [columnTypes.todo]: 'todo'
};

const statusTooltipMap = {
  todo: 'To Do',
  done: 'Done',
  inprogress: 'In Progress'
};

@Component({
  selector: 'task-roadmap-preview',
  templateUrl: './task-roadmap-preview.component.html',
  styleUrls: ['./task-roadmap-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskRoadmapPreviewComponent implements OnChanges {
  @Input() item: RoadMapItem;
  @Input() task: Task;
  @Input() hiddenItems: HiddenItems = {};
  @Input() isNotGuest: boolean;
  @Input() isEditPercent: number;

  @Output() editTaskPercent = new EventEmitter();
  @Output() openAddForm = new EventEmitter();

  get isTaskLink() {
    return this.item.isLink;
  }
  get isProjectLink() {
    return this.item.isProject;
  }

  public usersIds = [];

  public column$: Observable<any>;
  public status$: Observable<any>;
  public statusIconClass$: Observable<any>;
  public statusTooltip$: Observable<string>;
  public boardColumns$: Observable<any>;
  public isTaskContextMenuVisible$ = new BehaviorSubject(false);
  public taskStatusControl: FormControl;
  public showDeleteConfirmDialog$ = new BehaviorSubject(false);
  public showStatusChangeDialog$ = new BehaviorSubject(false);
  public project$: Observable<Project>;
  public deleteOriginalTask: boolean;
  public RoadMapAddFormTypes = RoadMapAddFormTypes;

  public isHoverOnTask$ = this._syncRoadmapGuiStateService.hoverItem$.pipe(
    map(hoveredItem => hoveredItem.id === this.item.id && hoveredItem.type === 'task'),
    distinctUntilChanged()
  );

  user = this._auth.activeUser;

  constructor(
    private _store: Store<AppState>,
    private _auth: AuthService,
    private _elementRef: ElementRef,
    private _roadmapDragService: RoadmapDragService,
    private _syncRoadmapGuiStateService: SyncRoadmapGuiStateService
  ) {}

  @HostBinding('attr.draggable') draggable = false;

  @HostBinding('class.collapsed-container')
  get isHidden() {
    return this.hiddenItems[this.item.id];
  }

  ngOnChanges() {
    this.column$ = this._store.pipe(getColumnById(this.task.column), map(convertNullToEmptyCoulumn));
    this.boardColumns$ = this._store.pipe(getColumnsByBoard(this.task.board), publishReplay(1), refCount());

    this.status$ = observableOf(this.item).pipe(
      switchMap(
        item => (item.isLink ? observableOf(item.roadMapLinkedTaskFields.columnType) : this.column$.pipe(pluck('type')))
      ),
      map((status: string) => statusMap[status] || 'inprogress')
    );

    this.statusIconClass$ = this.status$.pipe(map(className => className + ' status_icon'));
    this.statusTooltip$ = this.status$.pipe(map((status: string) => statusTooltipMap[status]));
    this.usersIds = this.task.usersIds || [];
    if (this.task.linkToProject) {
      this.project$ = this._store.select(fromProject.getById(this.task.linkToProject));
    }
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    this._syncRoadmapGuiStateService.hoverItem$.next({ id: this.item.id, type: this.item.type });
  }

  @HostListener('mouseleave')
  onMouseOut() {
    this._syncRoadmapGuiStateService.clearHoverItem();
  }

  onDeleteTask() {
    this._store.dispatch(new TaskDeleteAction(this.item.taskLinkObject));
    this.onCloseDeleteConfirmDialog();
  }

  onShowChangeStatusDialog() {
    this.taskStatusControl = new FormControl(this.task.column);
    this.isTaskContextMenuVisible$.next(false);
    this.showStatusChangeDialog$.next(true);
  }

  onCloseChangeStatusDialog() {
    this.showStatusChangeDialog$.next(false);
  }

  onShowDeleteConfirmationDialog() {
    this.isTaskContextMenuVisible$.next(false);
    this.showDeleteConfirmDialog$.next(true);
  }

  onCloseDeleteConfirmDialog() {
    this.showDeleteConfirmDialog$.next(false);
  }

  onClickLink() {
    if (this.item.isLink) {
      this._store.dispatch(new OpenedTaskDataChangedAction({ lastOpenedLinkTask: this.item.task.id }));
    }
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(initialEvent: MouseEvent) {
    if (!this.isNotGuest) {
      return;
    }
    this._roadmapDragService.detectDragDirection(initialEvent).subscribe(({ event }) => {
      this._roadmapDragService.start(event, this._elementRef.nativeElement, this.item);
    });
  }

  onSaveStatus() {
    const value = +this.taskStatusControl.value;
    this._store.dispatch(
      new RoadmapBoardChangeTaskStatusAction({
        id: this.item.id,
        status: value,
        oldStatus: this.task.column
      })
    );
    this.boardColumns$
      .pipe(map((columns: Column[]) => columns.find(column => column.id === value)), take(1))
      .subscribe((column: Column) =>
        this._store.dispatch(new SegmentTrackRoadmapTaskStatusChanged({ taskStatus: column.name }))
      );
    this.onCloseChangeStatusDialog();
  }

  onClickPercent(event, task) {
    if (event.target.tagName !== 'OVERLAY') {
      this.editTaskPercent.emit(task.id);
    }
    event.preventDefault();
    event.stopPropagation();
  }
  onClosePercentEdit() {
    this.editTaskPercent.emit(0);
  }

  onOpenQuickActions(event) {
    event.preventDefault();
    event.stopPropagation();
    this._store.dispatch(
      new GuiStateQuickTaskEditShow({
        id: this.task.id,
        rect: event.currentTarget.parentElement.getBoundingClientRect(),
        isExactRect: true
      })
    );
  }
  onDeleteOriginalOrLinkTask(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.item.isLink) {
      this.onShowDeleteConfirmationDialog();
    } else {
      this.deleteOriginalTask = true;
    }
  }

  onCloseDeleteOriginalPopup() {
    this.deleteOriginalTask = false;
  }

  onDeleteOriginalTaskConfirm() {
    this._store.dispatch(new TaskDeleteAction(this.task));
    this.onCloseDeleteOriginalPopup();
  }

  onAddNew(type) {
    this.openAddForm.emit({ insertAfter: this.task.id, type });
    this.isTaskContextMenuVisible$.next(false);
  }
}
