import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Label } from '../../../../../interfaces';

@Component({
  selector: 'list-board-labels',
  templateUrl: './list-board-labels.component.html',
  styleUrls: ['./list-board-labels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListBoardLabelsComponent implements OnInit {
  @Input() isNotGuest: boolean;
  @Input() labels: Label[];
  constructor() {}

  ngOnInit() {}
}
