<ng-container *ngIf="visibleFilter$ | async">
  <div class="default_versions_filter"
       (click)="onShowVersionsPopup()"
       [ngClass]="{'active': showVersionsPopUp}"
  >
    Versions
  </div>

  <div *ngFor="let version of selectedVersions$ | async"
       class="version_filter_tag"
       (click)="onRemoveVersion(version)"
  >
    <p class="version_title">
      {{version.name}}
    </p>
    <div class="clear_block"></div>
  </div>

  <div a-context-popup *ngIf="showVersionsPopUp"
       [title]="'Version'"
       class="default_versions_list_popup"
       (close)="onHideVersionsPopup()"
  >
    <versions-filter-list
      [versions]="boardVersions$ | async"
      [selectedVersions]="selectedVersions$ | async"
      (addVersion)="onAddVersion($event)"
      (removeVersion)="onRemoveVersion($event)"
    ></versions-filter-list>
  </div>
</ng-container>
