import { fromEvent as observableFromEvent, Subscription } from 'rxjs';
import { AfterViewChecked, AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';

@Directive({
  selector: '[heightBind]'
})
export class HeightBindDirective implements AfterViewInit, AfterViewChecked, OnDestroy {
  @Output() heightChanged = new EventEmitter();

  subs: Subscription[] = [];

  /**
   * WARNING: use setter! don't use this variable directly
   * @type {number}
   * @private
   */
  private _clientHeight = 0;

  constructor(private elRef: ElementRef) {}

  public setHeight(newHeight) {
    if (this._clientHeight !== newHeight) {
      this._clientHeight = newHeight;
      this.heightChanged.emit(this._clientHeight);
    }
  }

  ngAfterViewInit() {
    this.subs.push(
      observableFromEvent(window, 'resize').subscribe(_ => {
        this.setHeight(this.elRef.nativeElement.clientHeight);
      })
    );
  }

  ngAfterViewChecked() {
    requestAnimationFrame(() => this.setHeight(this.elRef.nativeElement.clientHeight));
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
