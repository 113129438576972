export interface EnvironmentUrl {
  domain: string;
  apiUrl: string;
  experimentalApiUrl: string;
  publicApiUrl: string;
  socketUrl: string;
  authApiUrl: string;
  accountServiceUrl: string;
  companyPortalUrl: string;
  slackUrl: string;
  serviceUrl: string;
  googlePickerOrigin: string;
  githubUrl: string;
  bitbucketUrl: string;
  gitlabUrl: string;
  intercomUrl: string;
  jiraUrl: string;
}

export const URLS: { [p: string]: EnvironmentUrl } = {
  dev: {
    domain: 'atlaz-stage.com',
    apiUrl: 'https://[company.domain].atlaz-stage.com/api/v1',
    experimentalApiUrl: 'https://[company.domain].atlaz-stage.com/api/v2',
    publicApiUrl: 'https://api.atlaz-stage.com/public-api',
    socketUrl: 'wss://[company.domain].atlaz-stage.com',
    // socketUrl: 'ws://localhost:3003', // hygger-socket.io settings. docker run --name some-redis -d -p 6379:6379 redis
    authApiUrl: 'https://accounts.atlaz-stage.com/api',
    accountServiceUrl: 'https://accounts.atlaz-stage.com',
    companyPortalUrl: 'https://[company.domain].atlaz-stage.com',
    slackUrl: 'https://[company.domain].atlaz-stage.com/slack/add',
    serviceUrl: 'https://services.atlaz-stage.com',
    googlePickerOrigin: 'https://service.atlaz-stage.com',
    githubUrl: ' https://[company.domain].atlaz-stage.com/github/add',
    bitbucketUrl: 'https://[company.domain].atlaz-stage.com/bitbucket/add',
    gitlabUrl: ' https://[company.domain].atlaz-stage.com/integrations/gitlab/add',
    intercomUrl: ' https://[company.domain].atlaz-stage.com/integrations/intercom/add',
    jiraUrl: ' https://[company.domain].atlaz-stage.com/integrations/jira/add'
  },

  stage: {
    domain: 'atlaz-stage.com',
    apiUrl: 'https://[company.domain].atlaz-stage.com/api/v1',
    experimentalApiUrl: 'https://[company.domain].atlaz-stage.com/api/v2',
    publicApiUrl: 'https://api.atlaz-stage.com/public-api',
    socketUrl: 'wss://[company.domain].atlaz-stage.com',
    authApiUrl: 'https://accounts.atlaz-stage.com/api',
    accountServiceUrl: 'https://accounts.atlaz-stage.com',
    companyPortalUrl: 'https://[company.domain].atlaz-stage.com',
    slackUrl: 'https://[company.domain].atlaz-stage.com/slack/add',
    serviceUrl: 'https://services.atlaz-stage.com',
    googlePickerOrigin: 'https://service.atlaz-stage.com',
    githubUrl: ' https://[company.domain].atlaz-stage.com/github/add',
    bitbucketUrl: 'https://[company.domain].atlaz-stage.com/bitbucket/add',
    gitlabUrl: ' https://[company.domain].atlaz-stage.com/integrations/gitlab/add',
    intercomUrl: ' https://[company.domain].atlaz-stage.com/integrations/intercom/add',
    jiraUrl: ' https://[company.domain].atlaz-stage.com/integrations/jira/add'
  },

  prod: {
    domain: 'hygger.io',
    apiUrl: 'https://[company.domain].hygger.io/api/v1',
    experimentalApiUrl: 'https://[company.domain].hygger.io/api/v2',
    publicApiUrl: 'https://api.hygger.io/public-api',
    socketUrl: 'wss://[company.domain].hygger.io',
    authApiUrl: 'https://accounts.hygger.io/api',
    accountServiceUrl: 'https://accounts.hygger.io',
    companyPortalUrl: 'https://[company.domain].hygger.io',
    slackUrl: 'https://[company.domain].hygger.io/slack/add',
    serviceUrl: 'https://services.hygger.io',
    googlePickerOrigin: 'https://service.hygger.io',
    githubUrl: ' https://[company.domain].hygger.io/github/add',
    bitbucketUrl: 'https://[company.domain].hygger.io/bitbucket/add',
    gitlabUrl: ' https://[company.domain].hygger.io/integrations/gitlab/add',
    intercomUrl: ' https://[company.domain].hygger.io/integrations/intercom/add',
    jiraUrl: ' https://[company.domain].hygger.io/integrations/jira/add'
  }
};
