import { VERSION_TASK_UPDATED, VersionActions, VersionActionTypes } from '../actions/version.actions';
import { AppState, EntityState, ESInterface } from '../state';
import { Version } from '../../interfaces';
import { share } from '../functions/util';
import { addEntityState, deleteEntityState, editEntityState } from '../functions/reducer';
import {
  entityMapper,
  getAllEntitiesAsArray,
  getEntityByIdSelector,
  getEntitySelector,
  selectEntities
} from '../functions/selectors';
import { VERSION_PL } from '../../constants';
import { compose } from '@ngrx/store';
import { replaceIds } from '../../../helpers/';
import { createSelector, defaultMemoize } from 'reselect';
import { SprintsTasks } from '../../interfaces/sprints-tasks';

const initialState: ESInterface<Version> = {
  ids: [],
  entities: {},
  selectedEntityId: null
};

export function reducer(state = initialState, action: VersionActions): ESInterface<Version> {
  switch (action.type) {
    case VersionActionTypes.ADD_COMPLETE: {
      return addEntityState(state, action.payload, true);
    }

    case VersionActionTypes.DELETE: {
      return <ESInterface<Version>>deleteEntityState(state, action.payload);
    }

    case VERSION_TASK_UPDATED:
    case VersionActionTypes.EDIT: {
      return editEntityState(state, action.payload);
    }

    case VersionActionTypes.ASSIGN_TASK: {
      const versionId = action.payload.id;
      if (state.entities[versionId]) {
        const tasksIds = replaceIds(state.entities[versionId].tasksIds, action.payload.tasks);

        return editEntityState(state, { id: versionId, tasksIds: tasksIds });
      } else {
        return state;
      }
    }

    default: {
      return state;
    }
  }
}

export const getAllVersions = share(compose(getAllEntitiesAsArray, getEntitySelector(VERSION_PL)));
export const getVersionEntities = share(compose(selectEntities, getEntitySelector(VERSION_PL)));
export const versionMapper = mapperFunc => compose(entityMapper(mapperFunc), getAllVersions);
export const getVersionById = id => share(compose(getEntityByIdSelector<Version>(id), getEntitySelector(VERSION_PL)));

export const getSprintTasksState = (state: AppState) => <ESInterface<SprintsTasks>>state.sprintsTasks;
export const getVersionsState = (state: AppState) => <ESInterface<Version>>state.versions;

export const getSprintTasksByVersionId = defaultMemoize(versionId =>
  createSelector(getSprintTasksState, state =>
    EntityState.fromState(state)
      .filter((sprintTask: SprintsTasks) => sprintTask.version === versionId)
      .toArray()
  )
);

export const getSprintTasksByBoardId = defaultMemoize(boardId =>
  createSelector(getSprintTasksState, state =>
    EntityState.fromState(state)
      .filter((sprintTask: SprintsTasks) => sprintTask.board === boardId)
      .toArray()
  )
);

export const getVersionsByBoardId = defaultMemoize(boardId =>
  createSelector(getVersionsState, state =>
    EntityState.fromState(state)
      .filter((version: Version) => version.board === boardId)
      .toArray()
  )
);

export const getVersionsList = defaultMemoize(
  createSelector(getVersionsState, state => EntityState.fromState(state).toArray())
);
