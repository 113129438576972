
import {fromEvent as observableFromEvent,  Subscription ,  Observable } from 'rxjs';
import { Directive, Input, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[sizeSynchro]'
})
export class SizeSynchroDirective implements AfterViewInit, OnDestroy {
  @Input() sizeSourceRef;
  @Input() widthDiff = 0;
  @Input() minWidth = 0;
  private sizeSource: Subscription;
  private sourceElementRef: HTMLElement;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    if (!this.sizeSourceRef) {
      this.sourceElementRef = document.documentElement;
    } else {
      this.sourceElementRef = this.sizeSourceRef.nativeElement;
    }

    this.resize();

    this.sizeSource = observableFromEvent(window, 'resize').subscribe(_ => {
      this.resize();
    });
  }

  ngOnDestroy() {
    if (!!this.sizeSource) {
      this.sizeSource.unsubscribe();
    }
  }

  private resize() {
    let width: number = this.sourceElementRef.clientWidth - this.widthDiff;
    if (width < this.minWidth) {
      width = this.minWidth;
    }
    this.elementRef.nativeElement.style.width = width + 'px';
  }
}
