import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from '../../../interfaces/user';

@Component({
  selector: 'default-filter-member-list',
  templateUrl: './default-filter-member-list.component.html',
  styleUrls: ['./default-filter-member-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultFilterMemberListComponent {
  @Input() members: User[];
  @Input() selectedMemberId: number;

  @Output() selectMember = new EventEmitter<User>();

  onSelectMember(member: User) {
    this.selectMember.emit(this.selectedMemberId !== member.id ? member : undefined);
  }
}
