import { filter, map } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../../interfaces';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state/';
import { BehaviorSubject, Observable } from 'rxjs';
import { filterBots, getAllUsers } from '../../../ngrx/reducers/user.reducer';
import { naturalSort } from '../../../../helpers/';

@Component({
  selector: 'user-list-independent',
  template: `
    <input #nameFilterRef name="name filter" [focus]="true" placeholder="Search for Members" (keyup)="0">
    <div class="list_inner">
      <div *ngFor="let user of usersState$ | async | usersNameFilter: nameFilterRef.value"
           class="user_title"
           (click)="onSelectUser(user.id)"
      >
        <div class="avatar_container">
          <a-avatar
            [user]="user"
          ></a-avatar>
        </div>
        <div class="member_info">
          <div class="member_info_name"> {{user.firstname}} {{user.lastname}}</div>
          <div class="member_info_text">@{{user.nickname}}</div>
        </div>
        <span class="check_label" *ngIf="isSelectedUser(user.id)"></span>
      </div>
    </div>
  `,
  styleUrls: ['./member-list.scss']
})
export class UserListIndependentComponent implements OnInit {
  @Input() selectedUsers: BehaviorSubject<number[]>;
  @Input() usersFilter = () => true;

  public usersState$: Observable<User[]>;

  constructor(private _store: Store<AppState>) {}

  get selectedUsers$() {
    return this.selectedUsers;
  }

  ngOnInit(): any {
    this.usersState$ = this._store.pipe(
      getAllUsers,
      map(filterBots),
      filter(this.usersFilter),
      map(users => Array.isArray(users) && users.filter(user => user && !user.deleted)),
      map(naturalSort('nickname'))
    );
  }

  isSelectedUser(userId) {
    return this.selectedUsers$.getValue().includes(userId);
  }

  onSelectUser(userId) {
    let currentValue = this.selectedUsers$.getValue();
    if (currentValue.includes(userId)) {
      this.selectedUsers$.next(currentValue.filter(existsUser => existsUser !== userId));
    } else {
      currentValue = [...currentValue];
      currentValue.push(userId);
      this.selectedUsers$.next(currentValue);
    }
  }
}
