import { createSelector } from 'reselect';
import { AppState, ESInterface } from '../state';
import { RECENT_TASK_PL } from '../../constants';
import { getTasksState } from './task.reducer';
import { isPresent } from '../../../helpers';
import { Entity, Task } from '../../interfaces';

type openedTaskHistory = number[];

const HISTORY_LIMIT = 100;

export const getRecentTasksState = (state: AppState) => <ESInterface<Entity>>state[RECENT_TASK_PL];

export const getOpenedTaskHistory = createSelector(
  getRecentTasksState,
  (recentTasks: ESInterface<Entity>): openedTaskHistory => {
    return [...recentTasks['ids']]
      .sort((a, b) => recentTasks['entities'][b]['createdAt'] - recentTasks['entities'][a]['createdAt'])
      .map(recentTaskId => +recentTasks['entities'][recentTaskId]['taskId'])
      .slice(0, HISTORY_LIMIT);
  }
);

export const getLatestOpenedTasks = createSelector(
  getOpenedTaskHistory,
  getTasksState,
  (ids: openedTaskHistory, tasksState: ESInterface<Task>) => {
    return ids.map(id => tasksState.entities[id]).filter(isPresent);
  }
);
