
import {distinctUntilChanged, combineLatest, map, publishReplay, refCount} from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { PROJECT_PL, USER_PL } from '../../../constants';
import { Board } from '../../../interfaces';
import { BoardEditAction, BoardGetAction, BoardReopenAction } from '../../../ngrx/actions/board.actions';
import { naturalSort } from '../../../../helpers';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { AppUrls } from '../../../app-urls';
import { CompanyService } from '../../services/app/company.service';

@Component({
  selector: 'boards-list',
  templateUrl: './boards-list.component.html',
  styleUrls: ['./boards-list.component.scss']
})
export class BoardsListComponent implements OnInit {
  @Input() allBoards$: Observable<Board[]>;
  @Input() typeFilter$ = new BehaviorSubject([]);
  @Input() statusFilter$ = new BehaviorSubject('');
  @Input() textFilter$ = new BehaviorSubject('');

  public boards$: Observable<Board[]>;

  public ACTIVE = 'ACTIVE';
  public CLOSED = 'CLOSED';
  public confirmationTitle: string;
  public confirmationText: string;
  public confirmButtonText: string;
  public appUrls = AppUrls;
  public targetBoardId: number = null;
  public hoveredItemId = 0;
  public exportCSVUrlBasis = '';

  static checkBoardType(board: Board, types: string[]): boolean {
    return !types.length || types.includes(board.type);
  }
  static checkBoardName(board: Board, text: string): boolean {
    return !text.length || board.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
  }

  constructor(private _store: Store<AppState>, private _companyService: CompanyService) {}

  ngOnInit() {
    this._store.dispatch(new BoardGetAction({ closed: 1, expand: [USER_PL, PROJECT_PL].toString() }));
    this.boards$ = this.allBoards$.pipe(
      combineLatest(
        this.typeFilter$,
        this.statusFilter$,
        this.textFilter$.pipe(distinctUntilChanged()),
        (boards: Board[], types: string[], status: string, textFilter: string) =>
          boards.filter(
            (board: Board) =>
              BoardsListComponent.checkBoardType(board, types) &&
              this.checkBoardStatus(board, status) &&
              BoardsListComponent.checkBoardName(board, textFilter)
          )
      ),
      map(naturalSort('name')),
      publishReplay(1),
      refCount(),);

    this.exportCSVUrlBasis = this._companyService.getPortalUrl() + '/export/board/';
  }

  checkBoardStatus(board: Board, status: string): boolean {
    switch (status) {
      case this.ACTIVE:
        return !board.closed;
      case this.CLOSED:
        return !!board.closed;
      default:
        return true;
    }
  }

  onCloseBoard(boardId) {
    this._store.dispatch(new BoardEditAction({ id: boardId, closed: 1 }));
    this.targetBoardId = null;
  }

  onReopenBoard(boardId) {
    this._store.dispatch(new BoardReopenAction(boardId));
  }

  onStopSprint(boardId) {
    this._store.dispatch(new BoardEditAction({ id: boardId, sprintStartDate: 0 }));
    this.targetBoardId = null;
  }

  onOpenConfirmPopup(boardId, type) {
    if (type === 'close') {
      this.confirmationTitle = 'Close board';
      this.confirmationText = 'You can reopen the board in Company Settings section.';
      this.confirmButtonText = 'Close';
      this.onConfirmAction = this.onCloseBoard;
    } else if (type === 'stop') {
      this.confirmationTitle = 'Stop sprint';
      this.confirmationText = 'You can start new sprint on board page.';
      this.confirmButtonText = 'Stop';
      this.onConfirmAction = this.onStopSprint;
    }
    if (type) {
      this.targetBoardId = boardId;
    }
  }

  onCloseConfirmPopup() {
    this.targetBoardId = null;
  }

  onConfirmAction(boardId) {}

  onToggleContentMenu(boardId) {
    this.hoveredItemId = this.hoveredItemId === boardId ? 0 : boardId;
  }
}
