import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TaskDragging } from '../../custom-types/task-dragging';
import { ScaleType } from '../../../../constants';
import { getVisibleTaskAxisScore } from '../../../../../helpers/task';

@Component({
  selector: 'g[drag-task-circle]',
  templateUrl: './drag-task-circle.component.html',
  styleUrls: ['./drag-task-circle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DragTaskCircleComponent {
  @Input() taskDragging: TaskDragging;
  @Input() axisYLabel: string;
  @Input() axisXLabel: string;
  @Input() axisYType: ScaleType;
  @Input() axisXType: ScaleType;

  public getVisibleTaskAxisScore = getVisibleTaskAxisScore;

  get transform() {
    return this.translate(0, 0);
  }

  public tooltipPaddingLeft = 5;
  public tooltipPaddingBottom = 20;

  get transformTooltip() {
    return this.translate(this.tooltipPaddingLeft, -this.tooltipPaddingBottom);
  }

  constructor() {}

  public translate(x: number, y: number): string {
    return `translate(${x}, ${y})`;
  }
}
