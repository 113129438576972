import { Component, Input } from '@angular/core';
import { Board, Collection } from '../../interfaces';

@Component({
  selector: 'right-menu-collections',
  templateUrl: './right-menu-collections.component.html',
  styleUrls: ['./right-menu-collections.component.scss']
})
export class RightMenuCollectionsComponent {
  @Input() collections: Collection[];
  @Input() boardCollections: Collection[];
  @Input() selectedBoard: Board;
  @Input() boardId: number;

  public isCollectionsPopupVisible = false;

  constructor() {}

  onCloseCollectionsPopup() {
    this.isCollectionsPopupVisible = false;
  }

  collectionsPopupToggle() {
    this.isCollectionsPopupVisible = !this.isCollectionsPopupVisible;
  }
}
