import { ModuleWithProviders, NgModule } from '@angular/core';
import { ADragService } from './a-drag.service';
import { ADraggableItemDirective } from './a-draggable-item.dirvective';

@NgModule({
  exports: [ADraggableItemDirective],
  declarations: [ADraggableItemDirective]
})
export class ADragModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ADragModule,
      providers: [ADragService]
    };
  }
}
