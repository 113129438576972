export enum ScoringFactor {
  Value = 'Value',
  Efforts = 'Efforts',
  Risk = 'Risk'
}

export enum ScoringDataType {
  ZeroTen = '0-10',
  PlanningPoker = 'PlanningPoker',
  GeneralNumber = 'GeneralNumber',
  Sizes = 'Sizes',
  USD = 'USD',
  YesNo = 'YesNo'
}
export enum ScoringDirection {
  LowerBetter = -1,
  HigherBetter = 1
}

export const DataTypeLabels = {
  [ScoringDataType.ZeroTen]: '0-10',
  [ScoringDataType.PlanningPoker]: 'Planning Poker',
  [ScoringDataType.GeneralNumber]: 'General Number',
  [ScoringDataType.Sizes]: 'Sizes',
  [ScoringDataType.USD]: 'USD',
  [ScoringDataType.YesNo]: 'Yes or No'
};

export const ScoringSizes = {
  [8]: 'S',
  [13]: 'M',
  [20]: 'L',
  [40]: 'XL',
  [100]: 'XXL'
};

export enum FactorType {
  ice = 'ice',
  generalNum = 'generalNum',
  select = 'select',
  percent = 'percent'
}
export enum FactorTypeLabel {
  ice = '0-10',
  generalNum = 'General Number',
  select = 'Select',
  percent = '0-100, %'
}

export const IceFactors = [
  { type: FactorType.ice, prop: 'iceImpact', label: 'Impact', editInfo: FactorTypeLabel.ice },
  { type: FactorType.ice, prop: 'iceConfidence', label: 'Confidence', editInfo: FactorTypeLabel.ice },
  { type: FactorType.ice, prop: 'iceEase', label: 'Ease', editInfo: FactorTypeLabel.ice }
];
export const RiceFactors = [
  { type: FactorType.generalNum, prop: 'riceReach', label: 'Reach', editInfo: FactorTypeLabel.generalNum },
  { type: FactorType.select, prop: 'riceImpact', label: 'Impact', editInfo: FactorTypeLabel.select },
  { type: FactorType.percent, prop: 'riceConfidence', label: 'Confidence', editInfo: FactorTypeLabel.percent },
  { type: FactorType.generalNum, prop: 'riceEffort', label: 'Effort', editInfo: FactorTypeLabel.generalNum }
];
