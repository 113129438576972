import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as COLORS from '../../../../constants/label-colors';
import { Label } from '../../../../interfaces';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../ngrx/state';
import { LabelsCreateAction, LabelsDeleteAction, LabelsUpdateAction } from '../../../../ngrx/actions/label.actions';
import { KeyCode } from '../../../../constants';
import { HotKey, HotSpaceAndEnter } from '../../../directives/hot-key.directive';

@Component({
  selector: 'sidebar-labels-edit-popup',
  templateUrl: './sidebar-labels-edit-popup.component.html',
  styleUrls: ['./sidebar-labels-edit-popup.component.scss']
})
export class SidebarLabelsEditPopupComponent implements OnInit {
  @Input() isEdit: boolean;
  @Input() boardId: number;
  @Input() label: Label | null;
  @Input() existedLabels: Label[];

  @Output() close = new EventEmitter();

  public selectedColor: string;
  public name: string;
  public isDeleteConfirm = false;

  get postHotKeys(): HotKey[] {
    return HotSpaceAndEnter;
  }
  get colors() {
    return Object.keys(COLORS).map(key => COLORS[key]);
  }

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this.name = this.label ? this.label.name : '';
    this.selectedColor = this.label ? this.label.color : this.getFreeColor();
  }

  getFreeColor() {
    const busyColors = this.existedLabels.reduce((acc, label: Label) => {
      acc[label.color] = true;
      return acc;
    }, {});

    let freeColor = Object.keys(COLORS).find(color => !busyColors[COLORS[color]]);
    if (!freeColor) {
      freeColor = Object.keys(COLORS).shift();
    }
    return COLORS[freeColor];
  }

  selectColor(color) {
    this.selectedColor = color;
  }

  onCreateNewLabel() {
    if (this.isEdit) {
      this._store.dispatch(
        new LabelsUpdateAction({
          id: this.label.id,
          name: this.name,
          color: this.selectedColor
        })
      );
    } else {
      this._store.dispatch(
        new LabelsCreateAction({
          name: this.name,
          color: this.selectedColor,
          board: this.boardId
        })
      );
    }
    this.onCloseLabelsEditPopup();
  }
  onSaveOnKey(e: KeyboardEvent) {
    if (e.keyCode === KeyCode.KEY_ENTER) {
      e.preventDefault();
      this.onCreateNewLabel();
    }
  }

  onRemoveLabel() {
    this.onCloseLabelsEditPopup();
    this._store.dispatch(new LabelsDeleteAction(this.label));
  }
  onToggleDeleteConfirm() {
    this.isDeleteConfirm = !this.isDeleteConfirm;
  }

  onCloseLabelsEditPopup() {
    this.close.emit();
  }
}
