import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChecklistItem } from '../../../../interfaces/checklist-item';
import { AppState } from '../../../../ngrx/state/app-state';
import { select, Store } from '@ngrx/store';
import { isPresent } from '../../../../../helpers/object';
import { Observable } from 'rxjs';
import { Task } from '../../../../interfaces/task';
import { fromOpenedTask } from '../../../ngrx/reducers/opened-task.reducer';
import { Features } from '../../../../libs/paywall/features.constants';
import { PaywallService } from '../../../../libs/paywall/paywall.service';

@Component({
  selector: 'checklist-item-menu',
  templateUrl: './checklist-item-menu.component.html',
  styleUrls: ['./checklist-item-menu.component.scss']
})
export class ChecklistItemMenuComponent implements OnInit {
  @Input() checkListItem: ChecklistItem;
  @Output() deleteItem = new EventEmitter();
  public task$: Observable<Task>;
  public isContentMenuVisible = false;
  public isConvertToTaskVisible = false;

  constructor(private _store: Store<AppState>, private _paywall: PaywallService) {}

  ngOnInit() {
    this.task$ = this._store.pipe(
      select(fromOpenedTask.getTask),
      distinctUntilChanged(),
      filter(isPresent),
      map((task: Task) => {
        return { ...task, title: this.checkListItem.name };
      })
    );
  }

  onToggleContentMenu() {
    this.isContentMenuVisible = !this.isContentMenuVisible;
  }

  onToggleConvertToTask() {
    if (this._paywall.isFeatureEnabled(Features.CanAddTask)) {
      this.isConvertToTaskVisible = !this.isConvertToTaskVisible;
    } else {
      this._paywall.showPayWall(Features.CanAddTask);
    }
  }

  onDeleteItem(triggerMenuClosing?: boolean) {
    this.deleteItem.emit();
    if (triggerMenuClosing) {
      this.isContentMenuVisible = false;
    }
  }
}
