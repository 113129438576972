export enum STATUS_CODES {
  CONNECTION_PROBLEM = 0,
  OK = 200,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  SERVER_ERROR = 500
}

export interface PageStatus {
  statusCode: STATUS_CODES;
  errorMessage?: string;
}
