<a a-context-menu-item (click)="onCloseMenu()" [href]="toUserProfile" target="_blank" rel="noopener">
  <p>My Profile Settings</p>
</a>
<p a-context-menu-item [divider]="true" *ngIf="user?.usersCompaniesIds?.length > 1"></p>
<a a-context-menu-item *ngIf="(companies$ | async)?.length > 1">
  <p class="submenu">{{(_companyService.currentCompany$ | async)?.name}} <span class="check_icon"></span></p>
  <companies-sub-menu [companies$]="companies$" (click)="onCloseMenu()"></companies-sub-menu>
</a>
<a a-context-menu-item *ngIf="isSuperUser$ | async" (click)="onCloseMenu()" [routerLink]="'/' + path.COMPANY_SETTINGS">
  <p>
    Company Settings
  </p>
</a>
<a a-context-menu-item *ngIf="showDeletePresetButton$ | async" (click)="onDeletePresetsToggle()">
  <p>
    Delete Presets
  </p>
</a>

<p a-context-menu-item *ngIf="hasBillingAccess$ | async" class="upgrade_btn">
  <billing-actions-buttons></billing-actions-buttons>
</p>
<p a-context-menu-item [divider]="true"></p>
<a a-context-menu-item (click)="onCloseMenu()" [routerLink]="['/' + path.LOGOUT]">
  <p>Log Out</p>
</a>

<div a-confirmation-popup *ngIf="isDeletePresetsPopupVisible" [confirmationTitle]="'Delete Presets'" [actionButtonText]="'Delete'"
     (confirm)="onDeletePresets()" (close)="onDeletePresetsToggle()">
  Are you sure you want to delete preset boards? This will delete all preset boards (Backlog, Kanban, Roadmap, Sprint).
</div>
