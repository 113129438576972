import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: '[a-confirmation-popup]',
  templateUrl: './a-confirmation-popup.component.html',
  styleUrls: ['./a-confirmation-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AConfirmationPopupComponent {
  @Input() confirmationTitle = '';
  @Input() actionButtonText = 'Confirm';
  @Input() dismissButtonText = 'Cancel';
  @Input() actionButtonClass = 'btn_warn';
  @Input() dismissButtonClass = 'btn_secondary';
  @Input() isPending = false;

  @Output() confirm = new EventEmitter();
  @Output() close = new EventEmitter();

  onConfirm() {
    this.confirm.emit();
  }

  onDismiss() {
    this.close.emit();
  }
}
