import { Component, Input, OnChanges } from '@angular/core';
import { Task } from '../../../interfaces';
import * as moment from 'moment';

@Component({
  selector: 'sidebar-start-date',
  templateUrl: './sidebar-start-date.component.html',
  styleUrls: ['./sidebar-start-date.component.scss']
})
export class SidebarStartDateComponent implements OnChanges {
  @Input() task: Task;
  @Input() editPermissions = false;

  public isEditStartDatePopupVisible = false;
  public isTaskOverdone: boolean;
  public isTaskCloseToOverdone: boolean;

  constructor() {}

  ngOnChanges() {
    this.startDateCalculate();
  }

  onOpenStartDatePopup() {
    if (!this.editPermissions) {
      return false;
    }
    this.isEditStartDatePopupVisible = true;
  }
  onCloseStartDatePopup() {
    this.isEditStartDatePopupVisible = false;
  }

  startDateCalculate() {
    const currentTimeInSeconds = moment().unix();
    const diffTime = this.task.startDate - currentTimeInSeconds;
    const secondsInOneDay = 86400;

    this.isTaskOverdone = currentTimeInSeconds > this.task.startDate;
    this.isTaskCloseToOverdone = diffTime < secondsInOneDay && diffTime > 0;
  }
}
