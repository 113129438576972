import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AppState } from '../../../../ngrx/state';
import { Store } from '@ngrx/store';
import { PercentStringValues } from '../../../helpers';
import { Task } from '../../../../interfaces';
import { TaskEditAction } from '../../../../ngrx/actions/task.actions';

@Component({
  selector: 'task-percent-inplace-edit',
  templateUrl: './task-percent-inplace-edit.component.html',
  styleUrls: ['./task-percent-inplace-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskPercentInplaceEditComponent {
  @Input() task: Task;
  @Input() isEdit = false;

  @Output() close = new EventEmitter();

  public percentValues = PercentStringValues;
  constructor(private _store: Store<AppState>) {}

  onChange(value) {
    this._store.dispatch(new TaskEditAction({ id: this.task.id, percent: +value || 0 }));
    this.close.emit();
  }
  onClose() {
    this.close.emit();
  }
}
