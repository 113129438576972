import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { TaskAttachment } from '../../../interfaces';
import { attachmentTypes } from '../../../constants';
import { AppState } from '../../../ngrx/state';
import { AtlazApiV2Service } from '../../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { AppUrls } from '../../../app-urls';
import { Router } from '@angular/router';
import { TaskAttachmentMakeCoverAction } from '../../../ngrx/actions/task-attachments.actions';
import { skipLocationChange } from '../../../attach-slideshow/config';

@Component({
  selector: 'attachment-item',
  templateUrl: './attachment-item.component.html',
  styleUrls: ['./attachment-item.component.scss']
})
export class TaskAttachmentItemComponent {
  @Input() attachment: TaskAttachment;
  @Input() editPermissions = false;

  @Output() deleteAttachmentEvent: EventEmitter<TaskAttachment> = new EventEmitter<TaskAttachment>();

  public isAttachContextVisible = false;
  public isCoverMenuVisible = false;
  public isPending = false;
  public isCopyingLink = false;
  public textForCopy = '';

  constructor(private _atlazApi: AtlazApiV2Service, private _store: Store<AppState>, private _router: Router) {}

  get isImage() {
    return this.attachment.type === attachmentTypes.image;
  }

  get isCover() {
    return this.attachment.isCover;
  }
  get isDownloadable() {
    return this.attachment.storage !== 'googledrive';
  }

  onToggleAttachContext() {
    this.isAttachContextVisible = !this.isAttachContextVisible;
  }
  onToggleCoverMenu() {
    this.isCoverMenuVisible = !this.isCoverMenuVisible;
  }

  onDelete(attachment: TaskAttachment) {
    this.deleteAttachmentEvent.emit(attachment);
  }

  onToggleCover() {
    const coverData = {
      id: this.attachment.id,
      cover: this.attachment.isCover ? 0 : 1
    };
    this._store.dispatch(new TaskAttachmentMakeCoverAction(coverData));
  }

  onOpenSlideshow(event: UIEvent, isImage) {
    if (isImage) {
      event.preventDefault();
      this._router.navigate(AppUrls.getUrlAttachSlideshow(this.attachment.task, this.attachment.id), {
        skipLocationChange
      });
    } else {
      event.stopImmediatePropagation();
    }
  }

  onCopyLinkStart(path) {
    this.isCopyingLink = true;
    this.textForCopy = path;
  }

  onCopyLinkEnd() {
    this.isCopyingLink = false;
  }
}
