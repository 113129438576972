import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'invite-has-been-sent',
  templateUrl: './invite-has-been-sent.component.html',
  styleUrls: ['./invite-has-been-sent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InviteHasBeenSentComponent implements OnInit {
  @Input() email: string;
  @Output() close = new EventEmitter();

  get mailto() {
    return 'mailto:' + this.email;
  }

  ngOnInit() {
    setTimeout(() => this.onClose(), 4000);
  }

  onClose() {
    this.close.emit();
  }
}
