import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormComponent,
  FormSaveType,
  FormServiceParams,
  FormV2Service
} from '../../../../shared/services/form-v2.service';
import { Observer } from 'rxjs';
import { JsonApiSingeModelResponse } from '../../../../shared/services/app/web-socket/http-response';
import { HandleResponseAction } from '../../../../ngrx/actions/root.action';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../ngrx/state';
import { BugTrackerService } from '@atlaz/core/services/bag-tracker.service';
import { BillingSubscriptionEntity } from '@atlaz/billing/interfaces/billing-subscription';
import { SUBSCRIPTION_PATH } from '@atlaz/billing/constants/billing-subscription';
import { RouterNavigateService } from '../../../../shared/services/router-navigate.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'delete-card-confirmation',
  templateUrl: './delete-card-confirmation.component.html',
  styleUrls: ['./delete-card-confirmation.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteCardConfirmationComponent implements OnInit, FormComponent {
  @Input() billingSub: BillingSubscriptionEntity;

  @Output() close = new EventEmitter();

  formObserver: Observer<any> = {
    next: (resp: JsonApiSingeModelResponse<any>) => {
      this._toastr.success('Card has been successfully deleted');
      this._store.dispatch(new HandleResponseAction(resp));
      this.close.emit();
      this._routerNav.navigateToBilling();
    },

    error: originalError => {
      const error = this._formService.normalizeServerErrorResponse(originalError);
      this._bugTracker.error('error while deleting card', originalError, error);
      this._toastr.error(error.message);
    },

    complete: () => {}
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.edit,
    entityToEdit: [SUBSCRIPTION_PATH],
    formObserver: this.formObserver,
    prepareFormValue: () => {
      return {
        id: this.billingSub.id,
        action: 'deactivate'
      };
    }
  };

  form: FormGroup;

  constructor(
    private _fb: FormBuilder,
    private _routerNav: RouterNavigateService,
    private _bugTracker: BugTrackerService,
    private _toastr: ToastrService,
    public _formService: FormV2Service,
    private _store: Store<AppState>
  ) {}

  onDismiss() {
    this.close.emit();
  }

  ngOnInit() {
    this.form = this._fb.group({});
    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  onSubmit() {
    this.form.markAsTouched();
    if (this.form.invalid) {
      return;
    }
    this._formService.submit();
  }
}
