import { Component, OnInit } from '@angular/core';

import { AuthService } from '../shared/services/app/auth.service';

@Component({
  selector: 'logout-component',
  template: `<a-loader></a-loader>`
})
export class LogoutComponent implements OnInit {
  constructor(private _authService: AuthService) {}

  ngOnInit(): void {
    this._authService.logout();
  }
}
