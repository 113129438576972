import { Component } from '@angular/core';
import { RouterNavigateService } from '../../../shared/services/router-navigate.service';

@Component({
  selector: 'stop-sprint-popup',
  templateUrl: './stop-sprint-popup.component.html',
  styleUrls: ['./stop-sprint-popup.component.scss']
})
export class StopSprintPopupComponent {
  constructor(private _routerNav: RouterNavigateService) {}

  onClose() {
    this._routerNav.deactivatePopupOutlet();
  }
}
