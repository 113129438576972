import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Task, Project } from '../../../../../interfaces';
import { TaskEditAction } from '../../../../../ngrx/actions/task.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../ngrx/state/app-state';

@Component({
  selector: 'sidebar-project-popup',
  templateUrl: './sidebar-project-popup.component.html',
  styleUrls: ['./sidebar-project-popup.component.scss']
})
export class SidebarProjectPopupComponent implements OnInit {
  @Input() task: Task;
  @Input() boardProjects: Project[];
  @Input() isBatchActions: boolean;
  @Input() batchTasks: Task[];
  @Output() close = new EventEmitter();

  public initialValue: number;
  public selectedValue: number;

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    if (!this.isBatchActions) {
      this.initialValue = this.task.project || 0;
    } else {
      // every task has the same project
      this.initialValue =
        this.batchTasks.length &&
        this.batchTasks[0].project &&
        this.batchTasks.every(item => item.project === this.batchTasks[0].project)
          ? this.batchTasks[0].project
          : 0;
    }
    this.selectedValue = this.initialValue;
  }

  onCloseProjectPopup() {
    if (this.initialValue === this.selectedValue) {
      this.close.emit();
      return;
    }
    let payloadId;
    if (this.isBatchActions) {
      payloadId = this.batchTasks
        .filter(task => task.project !== this.selectedValue)
        .map(task => task.id)
        .join(',');
    } else {
      payloadId = this.task.id;
    }
    if (payloadId) {
      this._store.dispatch(new TaskEditAction({ id: payloadId, project: this.selectedValue }));
    }
    this.close.emit();
  }

  onSelect(value: number) {
    this.selectedValue = value;
  }
}
