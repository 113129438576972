import { from as observableFrom, of as observableOf } from 'rxjs';

import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  pluck,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { Board, HttpQueryParam, PartOfEntity } from '../../interfaces';
import { BOARD_PL, COLLECTION_PL, defaultExpand, PROJECT_PL, TASK_PL, USER_PL } from '../../constants';
import { AppState } from '../state';
import {
  BoardActionTypes,
  BoardGetAction,
  BoardReopenCompleteAction,
  BoardReopenFailedAction
} from '../actions/board.actions';
import { defaultErrorHandler } from './root.effect';
import { PaginationLoaderService } from '../../shared/services/paginataion-loader/paginataion-loader.service';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { HandleResponseAction, SelectEntityAction } from '../actions/root.action';
import { AuthService } from '../../shared/services/app/auth.service';
import * as fromLoadedData from '../../loaded-data/store/loaded-data.reducer';
import { TaskGetAction } from '../actions/task.actions';
import { MarkAsUnloadedBoard } from '../../loaded-data/store/loaded-data.actions';
import { PaywallService } from '../../libs/paywall/paywall.service';
import { boardsState, fromBoards, isActiveBoard } from '../reducers/board.reducer';
import { DefaultCollectionEditAction } from '../actions/default-collection.actions';
import { defaultCollectionTypes } from '../../constants/default-collections';
import { Action } from '../actions/unsafe-action';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class BoardEffects {
  @Effect()
  init$ = this._authService.authIsReady$.pipe(
    map(() => new BoardGetAction({ expand: [USER_PL, PROJECT_PL].toString() }))
  );

  @Effect({ dispatch: false })
  getBoards$ = this.actions$.ofType(BoardActionTypes.GET).pipe(
    tap(({ payload }: Action) => {
      const paginatorService = new PaginationLoaderService(this._atlazApiV2, this._store);
      payload = <HttpQueryParam>Object.assign(
        {},
        {
          sort: '-updatedAt'
        },
        payload
      );

      paginatorService.controller = BOARD_PL;
      paginatorService.limit = 300;
      paginatorService.queryParams = payload;
      paginatorService.loadAll = true;
      paginatorService.loadMore();
    })
  );

  @Effect()
  reopenBoard$ = this.actions$.ofType(BoardActionTypes.REOPEN).pipe(
    withLatestFrom(this._store.select(boardsState), (action: Action, boardsState) => {
      const board = boardsState.entities[action.payload];
      return { action, board };
    }),
    switchMap(
      ({ action, board }: { action: any; board: Board }) =>
        board
          ? this._atlazApiV2
              .patch([BOARD_PL, { expand: [COLLECTION_PL + '.id', PROJECT_PL + '.id'] }], {
                id: action.payload,
                closed: 0
              })
              .pipe(
                switchMap(resp =>
                  observableFrom([new HandleResponseAction(resp), new BoardReopenCompleteAction(action.payload)])
                ),
                tap(() => this._store.dispatch(new MarkAsUnloadedBoard({ boardId: action.payload }))),
                catchError(err => {
                  this._toastr.error('Failed to reopen board');
                  console.warn('failed to reopen board', err);
                  return observableOf(new BoardReopenFailedAction(action.payload));
                })
              )
          : observableOf(new BoardReopenFailedAction(action.payload))
    )
  );

  @Effect()
  updateBoard$ = this.actions$
    .ofType(
      BoardActionTypes.EDIT,
      BoardActionTypes.ASSIGN_USERS,
      BoardActionTypes.ASSIGN_GROUPS,
      BoardActionTypes.ASSIGN_USERS_GROUPS,
      BoardActionTypes.ASSIGN_COLLECTIONS,
      BoardActionTypes.ASSIGN_PROJECTS
    )
    .pipe(
      mergeMap(({ type, payload: boardData }: { type: string; payload: PartOfEntity }) => {
        return this._atlazApiV2
          .patch([BOARD_PL, { expand: [COLLECTION_PL + '.id', PROJECT_PL + '.id'] }], boardData)
          .pipe(map(resp => new HandleResponseAction(resp)), catchError(defaultErrorHandler(type, boardData)));
      })
    );

  @Effect()
  storeRecentBoard$ = this.actions$.ofType(BoardActionTypes.OPEN).pipe(
    pluck('payload'),
    // do it async for better performance
    debounceTime(0),
    switchMap(id =>
      this._store.select(fromBoards.get(id)).pipe(
        take(1),
        filter(isActiveBoard),
        map(
          () =>
            new DefaultCollectionEditAction({
              id: defaultCollectionTypes.recent,
              boards: { add: [id] }
            })
        )
      )
    )
  );

  @Effect({ dispatch: false })
  loadOpenedBoardData$ = this.actions$.ofType(BoardActionTypes.OPEN).pipe(
    pluck('payload'),
    withLatestFrom(this._store.select(fromLoadedData.wasOpenedBoardFn), (boardId: number, loadedBoards: any) => {
      return { boardId, loadedBoards };
    }),
    tap(({ boardId, loadedBoards }: { boardId: number; loadedBoards: any }) => {
      this._store.dispatch(new SelectEntityAction({ id: boardId, entityName: BOARD_PL }));
      if (boardId && !loadedBoards(boardId)) {
        // the only
        this._store.dispatch(new TaskGetAction({ board: boardId, expand: defaultExpand[TASK_PL] }));
      }
    })
  );

  @Effect()
  loadBoard$ = this.actions$
    .ofType(BoardActionTypes.LOAD)
    .pipe(
      switchMap(({ type, payload }: { type: string; payload: HttpQueryParam }) =>
        this._atlazApiV2
          .get([BOARD_PL, +payload.id, payload.params])
          .pipe(map(resp => new HandleResponseAction(resp)), catchError(defaultErrorHandler(type, payload)))
      )
    );

  constructor(
    private actions$: Actions,
    private _toastr: ToastrService,
    private _store: Store<AppState>,
    private _authService: AuthService,
    private _paywall: PaywallService,
    private _atlazApiV2: AtlazApiV2Service
  ) {}
}
