
import {fromEvent as observableFromEvent,  Observable ,  Subscription } from 'rxjs';
import { AfterViewInit, Directive, ElementRef, Input, OnChanges, OnDestroy } from '@angular/core';

@Directive({
  selector: '[showBoundToEl]'
})
export class ShowBoundToElDirective implements OnChanges, AfterViewInit, OnDestroy {
  @Input() showBoundToEl: ElementRef;
  @Input() boundElementTopPadding = 0;
  private sub: Subscription;

  constructor(private elRef: ElementRef) {}

  ngOnChanges() {
    this.calculatePosiotion();
  }

  ngAfterViewInit() {
    this.sub = observableFromEvent(window, 'resize').subscribe(() => {
      this.calculatePosiotion();
    });
  }

  calculatePosiotion() {
    const bindingElRect = this.showBoundToEl.nativeElement.getBoundingClientRect();
    const elRect = this.elRef.nativeElement.getBoundingClientRect();
    const howManyPixelsOverflows = elRect.width - bindingElRect.left - bindingElRect.width;
    this.elRef.nativeElement.style.position = 'fixed';
    this.elRef.nativeElement.style.right = 'auto';
    this.elRef.nativeElement.style.left = Math.max(-howManyPixelsOverflows, 10) + 'px';
    this.elRef.nativeElement.style.top = bindingElRect.top + this.boundElementTopPadding + 'px';
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
