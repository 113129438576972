<div class="description" [ngClass]="{bottom_space: (isDescriptionFormPopup$ | async), editable: editPermissions}">

  <div class="add_description"
       *ngIf="editPermissions && isEmptyDescription && !(isDescriptionFormPopup$ | async)"
       (click)="onOpenDescriptionFormPopup()"
  >Add Description
  </div>

  <div>
    <p *ngIf="!isEmptyDescription"
       [ngClass]="{hide_text: (isDescriptionFormPopup$ | async)}" (click)="onOpenDescriptionFormPopup($event)">
      <markdown-smart-task-link *ngIf="enableMarkdown" [data]="selectedTask.description | textToHtml | markdownBreak">
        <smart-text [taskLinksOnly]="true" [text]="selectedTask.description | textToHtml"></smart-text>
      </markdown-smart-task-link>
      <smart-text *ngIf="!enableMarkdown" [text]="selectedTask.description | textToHtml"></smart-text>
    </p>
    <div *ngIf="isUnsavedDescription && !(isDescriptionFormPopup$ | async)" class="unsaved_data">
      <span (click)="onOpenDescriptionFormPopup()">View unsaved edits</span>
      <span (click)="onDiscardUnsavedDescription()">Discard</span>
    </div>
  </div>

  <div *ngIf="editPermissions && (isDescriptionFormPopup$ | async)" class="description_inplace_container"
       [clickOut]="isDescriptionFormPopup$ | async"
       (clickOutEvent)="onCloseDescriptionFormPopup()"
  >
    <form [formGroup]="taskDescriptionForm" (ngSubmit)="onAddDescription()">
    <markdown-toolbar *ngIf="enableMarkdown" [linkToTextarea]="descriptionTextArea" (valuePatched)="onDescriptionChange($event)"></markdown-toolbar>
    <textarea autosize focus [formControl]="taskDescriptionForm.get('description')"
              [focus]="true"
              [hotKeys]="submitHotkeys"
              (hotKey)="onAddDescription()"
              #description cols="30" rows="1"
              placeholder="Add Description"
              [selectionRange]="{
                start: taskDescriptionForm.get('description').value?.length,
                end: taskDescriptionForm.get('description').value?.length}"
    ></textarea>
      <button a-button tabindex="0" class="btn_primary">Save</button>
    </form>
    <div a-button class="btn_text" (click)="onCancelDescriptionForm()">Cancel</div>
    <span class="helper_link" (click)="onToggleMarkdownHelp()">Text Formatting</span>
    <div *ngIf="isMarkdownGuideVisible">
        <markdown-guide (closePopup)="onToggleMarkdownHelp()"></markdown-guide>
    </div>
  </div>
</div>
