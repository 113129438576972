import { createSelector } from '@ngrx/store';
import { AppState, REPORT_BOARD_FILTER } from '../../state';
import * as reportBoardFilter from '../../actions/report-filters/report-boards-filter.action';
import { ReportBoardFilterActionTypes } from '../../actions/report-filters/report-boards-filter.action';

export interface ReportBoardsFilter {
  selectedAll: boolean;
  boardsIds: number[];
}

const initState: ReportBoardsFilter = {
  selectedAll: false,
  boardsIds: []
};

export function reducer(
  state = initState,
  action: reportBoardFilter.Actions
): ReportBoardsFilter {
  switch (action.type) {
    case ReportBoardFilterActionTypes.APPLY: {
      state = action['payload'];
      return state;
    }

    case ReportBoardFilterActionTypes.RESET: {
      state = initState;
      return initState;
    }

    default: {
      return state;
    }
  }
}

export namespace fromReportBoard {
  export const getState = (state: AppState) =>
    <ReportBoardsFilter>state[REPORT_BOARD_FILTER];

  export const isActive = createSelector(
    getState,
    state => state.boardsIds.length > 0
  );
  export const getIds = createSelector(
    getState,
    state => state.boardsIds
  );
  export const isSelectedAll = createSelector(
    getState,
    state => state.selectedAll
  );
}
