<div class="layout" *ngIf="visible$ | async">
  <div class="inner">
    <div class="back_button_block">
      <button a-button class="back_button" (click)="goBack()">« Back to Hygger</button>
    </div>
    <h1>{{title$ | async}}</h1>
    <p class="subtitle">{{subtitle$ | async}}</p>
    <!--<pricing-plans-->
      <!--[isUnpaidSubscription]="isUnpaidSubscription$ | async"-->
      <!--(onUpgrade)="toPricing($event)"-->
    <!--&gt;-->

    <!--</pricing-plans>-->
    <pricing-plans-v3 [plans]="plans$ | async" [currentSub]="billingSub$ | async" (onUpgrade)="toPaymentDetails($event)"></pricing-plans-v3>
    <pricing-page-slider></pricing-page-slider>
    <pricing-faq></pricing-faq>

  </div>
</div>
<div class="layout" *ngIf="welcomeBackVisible$ | async">
  <div class="inner">
    <h1>Welcome back, <strong>{{(activeUser$ | async)?.firstname}}</strong></h1>
    <p class="subtitle">Get more out of HYGGER with right plan</p>

    <pricing-plans-v3 [showFreePlan]="true" [plans]="plans$ | async" [currentSub]="billingSub$ | async" (onUpgrade)="toPaymentDetails($event)" ></pricing-plans-v3>
    <pricing-faq [bgColor]="'white'"></pricing-faq>
  </div>
</div>
