import { Observable, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, LEFT_MENU } from '../../ngrx/state';
import { ComponentToggleAction } from '../../ngrx/actions/component.actions';
import { AppUrls } from '../../app-urls';
import { PermissionsService } from '../../permissions/permissions.service';
import { isStickyLeftMenu, isVisibleLeftMenu } from '../../ngrx/reducers/component.reducer';

const ACTIVE = 'active';
const INACTIVE = 'inactive';

@Component({
  selector: 'left-menu',
  templateUrl: 'left-menu.component.html',
  styleUrls: ['left-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeftMenuComponent implements OnInit {
  public appUrls = AppUrls;
  public selectedCollectionId: number;

  public state: string = INACTIVE;

  public isLeftMenuVisible$: Observable<boolean>;
  public isOverlay$: Observable<boolean>;
  public isPinned$: Observable<boolean>;
  public isCreateSectionVisible = false;
  public isNotGuest$ = this._permissions.isNotGuest$;
  public isSuperUser$: Observable<boolean> = this._permissions.isSuperUser$;

  constructor(private _store: Store<AppState>, private _permissions: PermissionsService) {}

  ngOnInit() {
    this.isPinned$ = this._store.select(isStickyLeftMenu);
    this.isLeftMenuVisible$ = this._store.select(isVisibleLeftMenu).pipe(
      switchMap(visible =>
        this.isPinned$.pipe(
          map((pinned: boolean[]) => {
            const isVisible = visible || pinned;
            this.state = isVisible ? ACTIVE : INACTIVE;
            return isVisible;
          })
        )
      )
    );
    this.isOverlay$ = combineLatest(this.isPinned$, this.isLeftMenuVisible$).pipe(
      map(([pinned, visible]) => !pinned && visible)
    );
  }

  onToggleLeftMenu() {
    this.isCreateSectionVisible = false;
    this.selectedCollectionId = null;
    this._store.dispatch(new ComponentToggleAction({ name: LEFT_MENU }));
  }

  collectionEdit(colId: number) {
    this.selectedCollectionId = colId;
  }
}
