<ng-content select="[title]"></ng-content>

<div class="git_pullrequest" *ngFor="let pr of sortedTaskPullRequests" (click)="onOpenPr(pr)">
  <div class="git_pullrequest_status">
    <svg aria-hidden="true" class="octicon octicon-git-pull-request open" height="16" version="1.1" viewBox="0 0 12 16" width="12">
      <path fill-rule="evenodd" d="M11 11.28V5c-.03-.78-.34-1.47-.94-2.06C9.46 2.35 8.78 2.03 8 2H7V0L4 3l3 3V4h1c.27.02.48.11.69.31.21.2.3.42.31.69v6.28A1.993 1.993 0 0 0 10 15a1.993 1.993 0 0 0 1-3.72zm-1 2.92c-.66 0-1.2-.55-1.2-1.2 0-.65.55-1.2 1.2-1.2.65 0 1.2.55 1.2 1.2 0 .65-.55 1.2-1.2 1.2zM4 3c0-1.11-.89-2-2-2a1.993 1.993 0 0 0-1 3.72v6.56A1.993 1.993 0 0 0 2 15a1.993 1.993 0 0 0 1-3.72V4.72c.59-.34 1-.98 1-1.72zm-.8 10c0 .66-.55 1.2-1.2 1.2-.65 0-1.2-.55-1.2-1.2 0-.65.55-1.2 1.2-1.2.65 0 1.2.55 1.2 1.2zM2 4.2C1.34 4.2.8 3.65.8 3c0-.65.55-1.2 1.2-1.2.65 0 1.2.55 1.2 1.2 0 .65-.55 1.2-1.2 1.2z"></path>
    </svg>
  </div>
  <div class="git_pullrequest_details">
    <div class="git_pullrequest_details_title">
      <a [href]="pr.htmlUrl" target="_blank" rel="noopener">{{pr.title}}</a>
      <div *ngIf="pr && pr.labels" class="git_pullrequest_details_wrap_label">
        <span *ngFor="let label of pr.labels"
              [style.background-color]="'#' + label.color"
              [style.color]="label.color | githubColor"
              class="git_pullrequest_label">
          {{label.name}}
        </span>
      </div>
    </div>
    <div class="git_pullrequest_details_additional">
      <span>
        <b>{{assocRepoMap[pr.githubAssociatedRepository]?.repositoryName}}</b> #{{pr.number}} opened by {{pr.authorLogin}}
      </span>
    </div>
    <div class="git_pullrequest_details_additional">
      <span>
        merge
        <span class="branch">{{pr.headLabel | replace : pr.authorLogin + ':' : ''}}</span> into
        <span class="branch">{{pr.baseLabel | replace : pr.authorLogin + ':' : ''}}</span>
      </span>
    </div>
  </div>
  <div class="git_pullrequest_meta">
    <div>
      <span class="comments_count" *ngIf="pr.commentsCount > 0">
        <i class="ico_comments"></i>
        <span class="comments_count_text">{{pr.commentsCount}}</span>
      </span>
      <div class="assignee_wrap">
        <span class="assignee" *ngIf="pr.assigneeAvatarUrl">
          <img [src]="pr.assigneeAvatarUrl" />
        </span>
      </div>
    </div>
    <div class="edit_container" (click)="onRemove(pr, $event)"></div>
  </div>
</div>
