import { getEmptyESState, ESInterface, AppState } from '../../../../../ngrx/state/app-state';
import { createSelector } from 'reselect';

import * as slackCompany from './slack-company.actions';
import * as slackAlert from '../slack-alert.action';
import { SlackBoard, ESSlackBoards } from '../../models/slack-boards';
import { SLACK_COMPANY, SLACK_COMPANY_PL } from '../../../../../constants/entity';
import { createCommonSelectors } from '../../../../../util/store/index';
import { SlackCompany, ESSlackCompany } from '../../models/slack-company';

const initialState = {
  ...getEmptyESState<SlackCompany>()
};

export function reducer(state = initialState, action: slackCompany.Actions | slackAlert.Actions): ESSlackCompany {
  switch (action.type) {
    case slackAlert.CLEAR: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const { getState, getEntities, getIds, getAll } = createCommonSelectors<SlackCompany>(SLACK_COMPANY_PL);

export const isEmpty = createSelector(getIds, ids => ids.length === 0);
