<div a-context-popup
     [title]="isAddDependencies ? 'Add Dependencies' : 'Link Tasks'"
     (close)="onCloseTaskLinks()"
>
  <task-links-add-form
    *ngIf="!isAddDependencies;else dependenciesForm"
    [taskId]="taskId"
    [boardId]="boardId"
    (close)="onCloseTaskLinks()"
  ></task-links-add-form>

</div>

<ng-template #dependenciesForm>
  <dependencies-add-form
    [taskId]="taskId"
    [boardId]="boardId"
    (close)="onCloseTaskLinks()"
  ></dependencies-add-form>
</ng-template>
