
import {of as observableOf,  Observable ,  Subscription ,  ReplaySubject } from 'rxjs';

import {map, tap, distinctUntilChanged, combineLatest, pluck, switchMap} from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Task, User } from '../../../interfaces';
import { Store } from '@ngrx/store';
import { AppState, ESInterface } from '../../../ngrx/state';
import { getActiveTasksByBoardIdWithLinked } from '../../../ngrx/reducers/task.reducer';
import { USER_PL } from '../../../constants';
import { isPresent, objectToArray } from '../../../../helpers';
import {
  DefaultMemberFilterResetAction,
  DefaultMemberFilterToggleMembersAction
} from '../../../ngrx/actions/task-filters/default-members-filter.actions';
import { getDefaultMembersFilterIds } from '../../../ngrx/reducers/task-filters/default-members-filter.reducer';
import { SegmentService } from '../../../atlaz-bnp/services/intergations/segment/segment.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { filterBots, getUserById } from '../../../ngrx/reducers/user.reducer';

const SELECTED = 'selected';
const EMPTY = 'empty';

const AnimationDuration = '150ms';

@Component({
  selector: 'default-members-filter',
  templateUrl: './default-members-filter.component.html',
  styleUrls: ['./default-members-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('defaultMembersFilterAnimation', [
      state(
        EMPTY,
        style({
          padding: '0 10px'
        })
      ),
      state(
        SELECTED,
        style({
          padding: '0 29px 0px 34px',
          backgroundColor: '#E0E6E8',
          borderRadius: '3px',
          border: '1px solid #E0E6E8',
          lineHeight: '24px',
          fontWeight: '500',
          color: '#546e7a'
        })
      ),
      transition('* => ' + SELECTED, animate(AnimationDuration + ' linear')),
      transition(SELECTED + ' => ' + EMPTY, animate(AnimationDuration + ' linear'))
    ]),
    trigger('defaultMembersAvatarAnimation', [
      state(
        EMPTY,
        style({
          left: '-30px'
        })
      ),
      state(
        SELECTED,
        style({
          left: '-6px'
        })
      ),
      transition('* => ' + SELECTED, animate(AnimationDuration + ' linear')),
      transition(EMPTY + '=> ' + SELECTED, animate(AnimationDuration + ' linear')),
      transition(SELECTED + ' => ' + EMPTY, animate(AnimationDuration + ' linear'))
    ]),
    trigger('defaultMembersClearAnimation', [
      state(
        EMPTY,
        style({
          right: '-24px',
          opacity: '0'
        })
      ),
      state(
        SELECTED,
        style({
          right: '0px',
          opacity: '1'
        })
      ),
      transition('* => ' + SELECTED, animate(AnimationDuration + ' linear')),
      transition(EMPTY + '=> ' + SELECTED, animate(AnimationDuration + ' linear')),
      transition(SELECTED + ' => ' + EMPTY, animate(AnimationDuration + ' linear'))
    ])
  ]
})
export class DefaultMembersFilterComponent implements OnInit, OnDestroy {
  @Input() boardId$: Observable<number>;
  public showAddPopUp = false;
  public members$: Observable<User[]>;
  public subs: Subscription[] = [];

  public state$: Observable<string>;
  public localUserFilter$ = new ReplaySubject<User>(1);
  public visibleUserName$: Observable<string>;

  constructor(private _store: Store<AppState>, private _segment: SegmentService) {}

  ngOnInit() {
    this.initMembers();
    this.subs.push(
      this._store.pipe(
        (getDefaultMembersFilterIds),
        switchMap(
          (userIds: number[]) =>
            userIds.length > 0
              ? this._store.pipe((getUserById(userIds[0])),tap(user => {
                  if (!user) {
                    this._store.dispatch(new DefaultMemberFilterResetAction());
                  }
                }),)
              : observableOf(undefined)
        ),)
        .subscribe(user => this.localUserFilter$.next(user))
    );

    this.state$ = this.localUserFilter$.pipe(map(filterUser => (filterUser ? SELECTED : EMPTY)),distinctUntilChanged(),);

    this.visibleUserName$ = this.localUserFilter$.pipe(map(
      user => (user ? (user.fullname ? user.fullname : '@' + user.nickname) : 'Members')
    ));
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  initMembers() {
    this.members$ = this._store.pipe(
      pluck(USER_PL),
      distinctUntilChanged(),
      combineLatest(
        this.boardId$.pipe(switchMap(id => this._store.select(getActiveTasksByBoardIdWithLinked(id)))),
        (users: ESInterface<User>, tasks: Task[]) => {
          const membersOnBoard = (boardMembers, task: Task) => {
            if (task.usersIds && task.usersIds.length > 0) {
              task.usersIds.forEach(id => (boardMembers[id] = users.entities[id]));
            }

            return boardMembers;
          };

          const filterMembers = tasks.reduce(membersOnBoard, {});

          return objectToArray(filterMembers);
        }
      ),
      map(list => list.filter(isPresent)),
      map(filterBots),);
  }

  onSelectMember(user: User) {
    if (user) {
      this._segment.boardFilterUsed('Members');
      this._store.dispatch(new DefaultMemberFilterToggleMembersAction(user.id));
    } else {
      this._store.dispatch(new DefaultMemberFilterResetAction());
    }

    this.onHidePopup();
  }

  onClearFilter(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.onSelectMember(undefined);
    return false;
  }

  onShowPopup() {
    this.showAddPopUp = true;
  }

  onHidePopup() {
    this.showAddPopUp = false;
  }
}
