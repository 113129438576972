import { ChangeDetectionStrategy, Component, forwardRef, HostBinding, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'a-toggle',
  templateUrl: './a-toggle.component.html',
  styleUrls: ['./a-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AToggleComponent),
      multi: true
    }
  ]
})
export class AToggleComponent implements OnInit, ControlValueAccessor {
  @Input() defaultValue = false;

  @HostBinding('class.on')
  get isOn() {
    return this.value;
  }
  @HostBinding('class.off')
  get isOff() {
    return !this.value;
  }

  public value;
  private propagateChange = (_: any) => {};
  private propagateTouched = (_: any) => {};

  constructor() {}

  public writeValue(obj: any) {
    this.value = obj;
  }

  public registerOnTouched(fn: any) {
    this.propagateTouched = fn;
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  ngOnInit() {
    this.value = this.defaultValue;
  }

  onToggle(event: UIEvent) {
    this.propagateTouched(event);
    if (event.preventDefault) {
      event.preventDefault();
    }
    event.stopPropagation();
    event.stopImmediatePropagation();
    this.value = !this.value;
    this.propagateChange(this.value);
  }
}
