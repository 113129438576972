<general-error-message *ngIf="_formService.isError$ | async">
  Some thing went wrong while saving your changes.
</general-error-message>

<form [formGroup]="form" (ngSubmit)="onSubmit()">

  <div class="fields">
    <p class="field_label">{{isAddCapacity ? 'Add' : 'Update'}} estimation capacity to this column.</p>
    <label for="column_capacity">Capacity</label>
    <input id="column_capacity" formControlName="backlogCapacity" [focus]="true" placeholder="1:30" />
    <div class="note">hours</div>
    <div class="clear"></div>
    <span class="invalid_field" *ngIf="!backlogCapacity.pristine && !backlogCapacity.valid">Please check Capacity format. Should be number or hh:mm.</span>
  </div>

  <div class="btns_block">
    <button a-button tabindex="0" class="btn_primary"
            (click)="onSubmit()"
            [pending]="isSavePending$ | async"
    >{{isAddCapacity ? 'Add' : 'Change'}}</button>

    <button a-button tabindex="0" class="btn_secondary"
            *ngIf="!isAddCapacity"
            (click)="onRemove()"
            [pending]="isRemovePending$ | async"
    >Remove</button>
  </div>

</form>
