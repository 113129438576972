import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { APortalService } from '../a-portal.service';

@Component({
  selector: 'a-show-in-portal',
  template: '<ng-template #template><ng-content></ng-content></ng-template>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AShowInPortalComponent implements OnInit, OnDestroy {
  @ViewChild('template') private templateTpl: TemplateRef<any>;

  constructor(private _portal: APortalService) {}

  ngOnInit() {
    this._portal.show(this.templateTpl, {});
  }

  ngOnDestroy() {
    this._portal.hide(this.templateTpl);
  }
}
