import { Component, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'top-menu-container',
  templateUrl: './top-menu-container.component.html',
  styleUrls: ['./top-menu-container.component.scss']
})
export class TopMenuContainerComponent implements OnInit {
  constructor(public _elRef: ElementRef) {}
  ngOnInit(): any {}
}
