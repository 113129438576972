import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { User } from '../../../interfaces';

@Component({
  selector: 'members-cell',
  templateUrl: './members-cell.component.html',
  styleUrls: ['./members-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MembersCellComponent {
  @Input() members: User[];

  constructor() {}
}
