import { labelAction } from '../functions/action';
import { WORKLOG } from '../../constants';
import { WorkLog } from '../../interfaces';
import { Action } from './unsafe-action';

export const WorkLogActionTypes = {
  DELETE: labelAction(WORKLOG, 'DELETE'),
  DELETE_COMPLETE: labelAction(WORKLOG, 'DELETE_COMPLETE'),
  DELETE_FAIL: labelAction(WORKLOG, 'DELETE_FAIL')
};

export class LogWorkDeleteAction implements Action {
  type = WorkLogActionTypes.DELETE;

  constructor(public payload: WorkLog) {}
}

export class LogWorkDeleteCompleteAction implements Action {
  type = WorkLogActionTypes.DELETE_COMPLETE;

  constructor(public payload: WorkLog) {}
}

export class LogWorkDeleteFailedAction implements Action {
  type = WorkLogActionTypes.DELETE_FAIL;

  constructor(public payload: WorkLog) {}
}
