
import {from as observableFrom,  of ,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx/src/data-persistence';
import { map ,  switchMap ,  mapTo } from 'rxjs/operators';

import { toDashedFromCamelCase } from '../../../../../helpers/';
import { GITLAB_ASSOCIATED_REPOSITORY, GITLAB_PULL_REQUEST_PL } from '../../../../constants/';
import { GetCompleteAction, NoopAction, UndoAction } from '../../../../ngrx/actions/root.action';
import { AppState, getEmptyESState } from '../../../../ngrx/state/';
import { jsonApiToEntityState } from '../../../../shared/services/app/web-socket/http-response';
import { AtlazApiV2Service } from '../../../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import * as gitlabPullRequest from './gitlab-pull-request.actions';
import { PaywallService } from '../../../../libs/paywall/paywall.service';
import { Features } from '../../../../libs/paywall/features.constants';

@Injectable()
export class GitlabPullRequestEffects {
  private key = toDashedFromCamelCase(GITLAB_PULL_REQUEST_PL);

  readonly defaultErrorHandler = <T>(action, error) => {
    console.error('effect', action, error);
  };

  @Effect()
  getByTaskMock$ = this._paywall.isFeatureEnabled(Features.Gitlab)
    ? this.d.fetch<gitlabPullRequest.GetTaskPullRequest>(gitlabPullRequest.GET_TASK_PULL_REQUEST, {
        run: (action, store) =>
          this.api.get([this.key], { ...action.payload, expand: [GITLAB_ASSOCIATED_REPOSITORY] }).pipe(
            map(jsonApiToEntityState),
            switchMap(state => {
              const actions = [
                new GetCompleteAction(state),
                new gitlabPullRequest.GetTaskPullRequestComplete({
                  taskId: action.payload.task,
                  entities: state[GITLAB_PULL_REQUEST_PL] || getEmptyESState()
                })
              ];
              return of(...actions);
            })
          ),
        onError: (action, error) => {
          this.defaultErrorHandler(action, error);
          return new gitlabPullRequest.GetTaskPullRequestFail();
        }
      })
    : observableFrom([]);

  @Effect()
  remove$ = this._paywall.isFeatureEnabled(Features.Gitlab)
    ? this.d.optimisticUpdate(gitlabPullRequest.DELETE_TASK_PULL_REQUEST, {
        run: (action: gitlabPullRequest.DeleteTaskPullRequest, store) =>
          this.api.deleteRequest([this.key, action.payload.id]).pipe(mapTo(new NoopAction())),
        undoAction: (action, error) => {
          console.log('handle error!', error);
          return new UndoAction(action);
        }
      })
    : observableFrom([]);

  constructor(private api: AtlazApiV2Service, private d: DataPersistence<AppState>, private _paywall: PaywallService) {}
}
