import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2
} from '@angular/core';

const defaultPadding = 20;

@Component({
  selector: 'a-context-container',
  template: '<ng-content></ng-content>',
  styleUrls: ['./a-context-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AContextContainerComponent implements AfterViewInit, OnDestroy {
  @Input() usedInPortal = false;

  private _timeout;

  constructor(private _eref: ElementRef, private _renderer: Renderer2, private _cd: ChangeDetectorRef) {}

  checkPosition() {
    const { bottom, right, top, left, height, width } = this._eref.nativeElement.getBoundingClientRect();

    if (top < 0) {
      this._renderer.setStyle(this._eref.nativeElement, 'top', `6px`);
    }

    if (bottom + defaultPadding > window.innerHeight) {
      const newTop = window.innerHeight - (defaultPadding + height);
      this._renderer.setStyle(this._eref.nativeElement, 'top', `${newTop}px`);
    }

    if (right + defaultPadding > window.innerWidth) {
      const newLeft = window.innerWidth - (defaultPadding + width);
      this._renderer.setStyle(this._eref.nativeElement, 'left', `${newLeft}px`);
    } else if (defaultPadding > left) {
      this._renderer.setStyle(this._eref.nativeElement, 'left', `${defaultPadding}px`);
    }
  }

  init() {
    const { top, left } = this._eref.nativeElement.getBoundingClientRect();
    this._renderer.setStyle(this._eref.nativeElement, 'top', `${top}px`);
    this._renderer.setStyle(this._eref.nativeElement, 'left', `${left}px`);
    this._renderer.setStyle(this._eref.nativeElement, 'position', `fixed`);

    this._timeout = setTimeout(() => {
      this.checkPosition();
      this._renderer.setStyle(this._eref.nativeElement, 'opacity', 1);
      this._cd.markForCheck();
      this._cd.detectChanges();
    });
  }
  ngAfterViewInit() {
    if (this.usedInPortal) {
      setTimeout(() => this.init());
    } else {
      this.init();
    }
  }

  ngOnDestroy() {
    if (this._timeout) {
      clearTimeout(this._timeout);
    }
  }
}
