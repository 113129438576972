<div class="wrapper" *ngIf="(props$ | async)?.length > 1" [class.opened]="showGroupByPopUp" (click)="onOpenGroupByPopup()">
  <span class="title">Group by:</span><span>{{selectedGrouping ? selectedGrouping.name : 'Default'}}</span>
</div>

<div a-context-popup *ngIf="showGroupByPopUp"
     [title]="'Group By'"
     class="default_statuses_list_popup"
     (close)="onCloseGroupByPopup()"
>
  <div class="props_container">
    <div *ngFor="let prop of (props$ | async)"
         class="props_title"
         (click)="onSetProp(prop)"
    >
      <p>{{prop.name}}</p>
      <span class="check_label" *ngIf="selectedGrouping && prop.id === selectedGrouping.id"></span>
    </div>
  </div>
</div>
