<form [formGroup]="filterForm" class="timesheet-filter_form">
  <div class="timesheet-filter">
      <div class="timesheet-filter-left-cont">
          <div class="timesheet-filter-project">
            <label class="label">Project</label>
            <dropdown-serch-list
              [items]="projectFilterItems$ | async"
              [topItems]="projectFilterTopItems"
              (changeValue)="onProjectChanged($event)"
              [selectedId]="filterValue.projectId">
            </dropdown-serch-list>
          </div>
          <!--<div *ngIf="hideGroup === false" class="timesheet-filter-group"> -->
          <div class="timesheet-filter-group">
            <label class="label">Member Group</label>
            <dropdown-serch-list
              [items]="groupFilterItems$ | async"
              [topItems]="groupFilterTopItems"
              (changeValue)="onGroupChanged($event)"
              [selectedId]="filterValue.groupId">
            </dropdown-serch-list>
          </div>
          <!--<div *ngIf="hideUser === false" class="timesheet-filter-user">-->
          <div class="timesheet-filter-user">
            <label class="label">Member</label>
            <dropdown-serch-list
              [items]="userFilterItems$ | async"
              [topItems]="userFilterTopItems"
              (changeValue)="onUserChanged($event)"
              [selectedId]="filterValue.userId">
            </dropdown-serch-list>
          </div>
          <div class="timesheet_filter_tasks">
            <label class="label">
              <input type="checkbox" [formControl]="filterForm.controls['showTasks']" name="showTasks" />
              Show Tasks
            </label>
          </div>
          <div class="timesheet_filter_logs">
            <label class="label" [ngClass]="{disabled: filterValue.showTasks === false}">
              <input type="checkbox" [formControl]="filterForm.controls['showLogs']" name="showLogs" />
              Show Work Logs
            </label>
          </div>
      </div>
      <div class="timesheet-filter-period">
        <div class="timesheet-filter-from-date">
          <label class="label">From</label>
          <a-datetime-picker id="from-date" formControlName="fromDate" name="fromDate" [time]="false"></a-datetime-picker>
        </div>
        <div class="timesheet-filter-to-date">
          <label class="label">To</label>
          <a-datetime-picker id="to-date" formControlName="toDate" name="toDate" [time]="false"></a-datetime-picker>
        </div>
      </div>
  </div>
</form>
