import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../../interfaces';

/**
 * Filter users by fullname and nickname
 */
@Pipe({
  name: 'usersNameFilter'
})
export class UsersNameFilterPipe implements PipeTransform {
  transform(value: User[], query: string): User[] {
    return !value || (query && query.length < 1)
      ? value
      : value.filter(
          item =>
            item &&
            ((item.fullname && item.fullname.toLowerCase().indexOf(query.toLowerCase()) > -1) ||
              (item.nickname && item.nickname.toLowerCase().indexOf(query.toLowerCase()) > -1))
        );
  }
}
