import { ESGitlabCommit } from '../../models/git-commit';
import { Action } from '../../../../ngrx/actions/unsafe-action';

export const UPDATED = '[gitlab-commit.actions] UPDATED GITLAB COMMITS';
export const GET_TASK_COMMIT = '[gitlab-commit.actions] get gitlab commits by task';
export const GET_TASK_COMMIT_COMPLETE = '[gitlab-commit.actions] get taskCommit complete';
export const DELETE_TASK_COMMIT = '[gitlab-commit.actions] remove taskCommit';
export const GET_TASK_COMMIT_FAIL = '[gitlab-commit.actions] GET_TASK_COMMIT_FAIL';

export class GetTaskCommit implements Action {
  readonly type = GET_TASK_COMMIT;

  constructor(public payload: { task: number }) {}
}

export class Updated implements Action {
  readonly type = UPDATED;

  constructor(public payload: ESGitlabCommit) {}
}

export class GetTaskCommitComplete implements Action {
  readonly type = GET_TASK_COMMIT_COMPLETE;

  constructor(public payload: { taskId: number; entities: ESGitlabCommit }) {}
}

export class GetTaskCommitFail implements Action {
  readonly type = GET_TASK_COMMIT_FAIL;
}

export class DeleteTaskCommit implements Action {
  readonly type = DELETE_TASK_COMMIT;

  constructor(public payload: { id: number | string; taskId: number }) {}
}

// prettier-ignore
export type Actions
  = GetTaskCommit
  | Updated
  | GetTaskCommitComplete
  | DeleteTaskCommit
  | GetTaskCommitFail
  ;
