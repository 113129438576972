
import {map} from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Board, Collection } from '../../../interfaces';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from '../../../ngrx/state';
import { filterClosedBoards, getAllBoards } from '../../../ngrx/reducers/board.reducer';
import { naturalSort } from '../../../../helpers/array';

@Component({
  selector: 'collection-board-list',
  templateUrl: './collection-board-list.component.html',
  styleUrls: ['./collection-board-list.component.scss']
})
export class CollectionBoardListComponent implements OnInit {
  @Input() selectedCollection: Collection;
  @Input() selectedBoards: Board[];

  @Output() manageBoardsCloseEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() addBoardEvent: EventEmitter<Board> = new EventEmitter<Board>();
  @Output() removeBoardEvent: EventEmitter<Board> = new EventEmitter<Board>();

  public allBoards$: Observable<Board[]>;
  public checkSelectedBoard: { [id: number]: boolean } = {};

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this.allBoards$ = <Observable<Board[]>>this._store.pipe(
      (getAllBoards),
      map(filterClosedBoards),
      map(naturalSort('name')),);
    this.selectedBoards.forEach((board: Board) => {
      this.checkSelectedBoard[board.id] = true;
    });
  }

  onSelectBoard(board: Board) {
    if (!this.checkSelectedBoard[board.id]) {
      this.addBoard(board);
    } else {
      this.removeBoard(board);
    }
  }

  addBoard(board: Board) {
    this.addBoardEvent.emit(board);
    this.checkSelectedBoard[board.id] = true;
  }

  removeBoard(board: Board) {
    this.removeBoardEvent.emit(board);
    this.checkSelectedBoard[board.id] = false;
  }

  onClose() {
    this.manageBoardsCloseEvent.emit();
  }
}
