<a [routerLink]="
activity?.relatedData?.task
? appUrls.getUrlTask(activity?.relatedData?.task)
: appUrls.getUrlBoard(activity.board)">
  <div class="menu_item_activity">
    <div class="menu_item_activity_user">
      <div class="menu_item_activity_avatar">
        <a-avatar
          [user]="user$ | async"
        ></a-avatar>
      </div>
      <div class="menu_item_activity_name">{{(user$ | async)?.fullname}}</div>
    </div>
    <div [ngClass]="{
    menu_item_activity_time__showico: haveIcon,
    menu_item_activity_time: !haveIcon
    }">
      <div [class]="iconClass"></div>
      <span [innerHTML]="activityText"></span>
      <span class="menu_item_activity_date">&nbsp;{{activity.createdAt | timeAgo}}</span>
    </div>
    <div class="menu_item_activity_text">
      <task-title-wrapper [taskId]="activity?.relatedData?.task"></task-title-wrapper>
    </div>
  </div>
</a>
