import { URLS } from './urls';
import { DEFAULT_COMPANY } from './companies';
import { EnvironmentParams } from './environment-params';
import buildInfo from './build-info.json';

export const environment: EnvironmentParams = {
  ENV: 'prod',
  buildInfo,
  billing: {
    enabled: true,
    stripePublicKey: 'pk_live_WOEOWkE16i14Ea6raUpMbOlA'
  },
  rollbarConfig: {
    environment: 'prod',
    payload: {
      buildInfo,
      client: {
        javascript: {
          code_version: buildInfo.revision,
          version: buildInfo.revision
        }
      }
    },
    accessToken: '8e00c25cbac845a4a50399974dbd2f09',
    hostBlackList: ['.satismeter.com', 'localhost', 'js.intercomcdn.com', 'cdn.segment.com', '.kaspersky-labs.com'],
    scrubTelemetryInputs: true,
    captureUncaught: true,
    captureUnhandledRejections: true
  },
  intercomAppId: 'msihppqs',
  ...URLS['prod'],
  defaultCompany: DEFAULT_COMPANY['prod'],
  pathToAssets: '/assets/img',
  muteConsoleLog: true,
  googlePickerAppKey: 'AIzaSyAzF-QPS-_ri2ujjoa2AEjXABaYmtfzDGo',
  googleClientId: '99980416761-u091gq7rj1fumpatnuklfloclfiisjs8.apps.googleusercontent.com',
  dropboxAppKey: 'h3ul1iw298x4ay8',
  dropboxChooserCodeSrc: 'https://www.dropbox.com/static/api/2/dropins.js',
  //  noinspection TsLint
  inspectletCode: `(function() {
var insp_ab_loader = true; // set this boolean to false to disable the A/B testing loader
window.__insp = window.__insp || [];
__insp.push(['wid', 200381466]);
var ldinsp = function(){
if(typeof window.__inspld != "undefined") return; window.__inspld = 1; var insp = document.createElement('script'); insp.type = 'text/javascript'; insp.async = true; insp.id = "inspsync"; insp.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js?wid=200381466&r=' + Math.floor(new Date().getTime()/3600000); var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(insp, x);if(typeof insp_ab_loader != "undefined" && insp_ab_loader){ var adlt = function(){ var e = document.getElementById('insp_abl'); if(e){ e.parentNode.removeChild(e); __insp.push(['ab_timeout']); }}; var adlc = "body{ visibility: hidden !important; }"; var adln = typeof insp_ab_loader_t != "undefined" ? insp_ab_loader_t : 1200; insp.onerror = adlt; var abti = setTimeout(adlt, adln); window.__insp_abt = abti; var abl = document.createElement('style'); abl.id = "insp_abl"; abl.type = "text/css"; if(abl.styleSheet) abl.styleSheet.cssText = adlc; else abl.appendChild(document.createTextNode(adlc)); document.head.appendChild(abl); } };
setTimeout(ldinsp, 0);
})`
};
