import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AppState, RIGHT_MENU } from '../../ngrx/state';
import { Store } from '@ngrx/store';
import { ComponentSetVisibleAction } from '../../ngrx/actions/component.actions';
import { KeyCode } from '../../constants/';
import { of } from 'rxjs/observable/of';
import { Observable } from 'rxjs/Observable';

const ACTIVE = 'active';
const INACTIVE = 'inactive';

@Component({
  selector: 'right-menu-container',
  templateUrl: './right-menu-container.component.html',
  styleUrls: ['./right-menu-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('rightMenuAnimation', [
      state(
        ACTIVE,
        style({
          right: 0
        })
      ),
      state(
        INACTIVE,
        style({
          right: '-305px'
        })
      ),
      transition(INACTIVE + ' => ' + ACTIVE, animate('300ms ease-in')),
      transition(ACTIVE + ' => ' + INACTIVE, animate('300ms ease-out'))
    ])
  ]
})
export class RightMenuContainerComponent implements OnInit, AfterContentChecked {
  @Input() title: boolean;
  @Input() showBackArrow = true;
  @Input() confirmLeave: () => Observable<any> = () => of(true);

  @ViewChild('wrap') public wrap: ElementRef;

  @Output() close = new EventEmitter();

  public state = INACTIVE;

  get visible() {
    return this.state === ACTIVE;
  }

  constructor(private _store: Store<AppState>) {}

  ngOnInit(): any {
    this.state = ACTIVE;
  }

  onClickBack() {
    this.confirmLeave().subscribe(
      () => {
        console.warn('Leave confirmed');
        this.state = INACTIVE;
      },
      () => console.warn('Leave unconfirmed')
    );
  }

  animationDone(event) {
    if (event.fromState === ACTIVE && event.toState === INACTIVE) {
      this.close.emit();
    }
  }

  onCloseRightMenu() {
    this.confirmLeave().subscribe(
      () => {
        console.warn('Leave confirmed');
        this._store.dispatch(new ComponentSetVisibleAction({ name: RIGHT_MENU, visible: false }));
      },
      () => console.warn('Leave unconfirmed')
    );
  }

  ngAfterContentChecked() {
    setTimeout(() => {
      if (document.activeElement === document.body) {
        //make active
        this.wrap.nativeElement.focus();
      }
    });
  }

  @HostListener('keyup', ['$event'])
  onKeyUP(event: KeyboardEvent) {
    if (event.keyCode === KeyCode.KEY_ESCAPE) {
      if (event.preventDefault) {
        event.preventDefault();
      }
      event.stopPropagation();
      event.stopImmediatePropagation();
      this.onCloseRightMenu();
      return false;
    }
  }
}
