
import {interval as observableInterval,  Observable ,  BehaviorSubject } from 'rxjs';

import {combineLatest, startWith, map, publishReplay, refCount, distinctUntilChanged, pluck, filter, switchMap} from 'rxjs/operators';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment-mini-ts';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { getBoardById } from '../../../ngrx/reducers/board.reducer';
import { isPresent } from '../../../../helpers';
import { WorkingDaysService } from '@atlaz/working-days/services/working-days.service';

const TYPE_TODAY = 'TYPE_TODAY';
const TYPE_TOMORROW = 'TYPE_TOMORROW';
const TYPE_IN_PROGRESS = 'TYPE_IN_PROGRESS';
const TYPE_OVERDUE = 'TYPE_OVERDUE';

@Component({
  selector: 'sprint-end-countdown-timer',
  templateUrl: './sprint-end-countdown-timer.component.html',
  styleUrls: ['./sprint-end-countdown-timer.component.scss']
})
export class SprintEndCountdownTimerComponent implements OnInit {
  @Input()
  set boardId(v: number) {
    this.boardId$.next(v);
  }

  private boardId$ = new BehaviorSubject(0);
  private endTs$;

  private now$ = observableInterval(60 * 1000).pipe(
    startWith(0),
    map(() => moment().unix()),
    publishReplay(1),
    refCount(),);

  message$: Observable<string>;

  constructor(private _store: Store<AppState>, private _workingDays: WorkingDaysService) {}

  ngOnInit() {
    this.endTs$ = this.boardId$.pipe(distinctUntilChanged(),switchMap(id =>
      this._store.pipe(
        (getBoardById(id)),
        filter(isPresent),
        pluck('sprintEndDate'),)
    ),);

    this.message$ = this.now$.pipe(combineLatest(this.endTs$),map(([nowTs, endTs]) => {
      const now = moment.unix(nowTs);
      const end = moment.unix(endTs);
      if (nowTs > endTs) {
        return 'Overdue';
      }
      if (now.isSame(end, 'day')) {
        return 'Ends today';
      }
      if (now.add({ day: 1 }).isSame(end, 'day')) {
        return 'Ends tomorrow';
      }

      const days = this._workingDays.workingDaysBetween(nowTs, endTs);
      if (days < 1) {
        return 'Ends less than in one working day';
      }
      return days + ' working days till end';
    }),);
  }
}
