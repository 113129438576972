import { environment } from './environment';
import { Company } from '../app/interfaces/company';
import { EnvironmentParams } from './environment-params';

const isMode = mode => () => environment.ENV === mode;
export const isProductionMode = isMode('prod');
export const isStageMode = isMode('stage');
export const isDevelopmentMode = isMode('dev');

export function getEnvironmentParam(key: keyof EnvironmentParams) {
  return environment[key] || '';
}
export function getParamByCompany(keyName: keyof EnvironmentParams) {
  return (company: Company) => {
    const param = getEnvironmentParam(keyName);
    return replacePlaceholder(param, company, 'company');
  };
}

function replacePlaceholder(str, obj, prefix) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      str = str.replace(`[${prefix}.${key}]`, obj[key]);
    }
  }

  return str;
}
