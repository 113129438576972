<div a-context-controll
     orientation="vertical"
     class="content_menu"
     [menuSlim]="true"
     [menuRight]="true"
     [menuTop]="true"
     [isMenuVisible]="isContentMenuVisible"
     (click)="onToggleContentMenu()"
>
  <p a-context-menu-item (click)="onToggleConvertToTask()">Convert to a task</p>
  <p a-context-menu-item (click)="onDeleteItem()" class="delete_link">Delete an item</p>
</div>

<div a-context-popup *ngIf="isConvertToTaskVisible"
     [title]="'Convert To A Task'"
     (close)="onToggleConvertToTask()"
     class="move_task_popup"
>
  <task-action-popup [task]="task$ | async"
                     actionName="create"
                     (creationComplete)=onDeleteItem(true)
                     (close)="onToggleConvertToTask()"
  ></task-action-popup>
</div>
