import { GITHUB_REPOSITORY_PL } from '../../../../constants/index';
import { getEmptyESState } from '../../../../ngrx/state/index';
import { createCommonSelectors } from '../../../../util/store/index';
import { ESGithubRepository, GithubRepository } from '../../models/git-repository';
import * as githubCompany from '../github-company/github-company.actions';
import * as githubRepository from './github-repository.actions';

export interface State extends ESGithubRepository {
  loading: boolean;
}

const initialState: State = {
  ...getEmptyESState<GithubRepository>(),
  loading: false
};

export function reducer(state = initialState, action: githubRepository.Actions | githubCompany.Actions): State {
  switch (action.type) {
    case githubRepository.GET_INDEX: {
      return { ...state, loading: true };
    }
    case githubRepository.UPDATED: {
      return state;
    }

    case githubRepository.GET_INDEX_COMPLETE: {
      return { ...state, loading: false };
    }

    case githubCompany.CLEAR_SETTINGS:
    case githubCompany.REMOVE_ALL: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const { getState, getEntities, getIds, getAll } = createCommonSelectors<GithubRepository, State>(
  GITHUB_REPOSITORY_PL
);
