<div *ngIf="isMultipleSwimlanes" class="swimlane_header"
     #swimlane_header
     currentTarget="true"
     aDraggableItem="true"
     [attr.draggable-disabled]="!isNotGuest"
     [itemType]="dragItemType"
     [item]="swimlane"
     axis="y"
     (dragover)="onDrag($event)"
     stopChildEventPropagation="mousedown"
>
  <div class="header_inner" #header_inner>

	  <div class="header_scroll">
		  <div class="move_icon drag_element" (click)="onToggleSwimlaneCollapseState(swimlane)"></div>

		  <div class="expand_controll"
		       [class.expand_controll_closed]="collapsed"
		  ></div>
		  <p class="swimlane_name" (click)="onToggleSwimlaneCollapseState(swimlane)">{{swimlane.name}}</p>
      <div class="menu_container">
        <div a-context-controll
             #contextControlEl
             orientation="vertical"
             class="content_menu"
             [menuSlim]="true"
             [menuTop]="true"
             [isMenuVisible]="visibleMenuSwimlaneId === swimlane.id"
             (click)="onToggleSwimlaneMenu(swimlane.id)"
        >
          <p a-context-menu-item *ngIf="isNotGuest" (click)="onToggleSwimlaneNamePopup($event)">Edit Swimlane</p>
          <p a-context-menu-item (click)="onExpandAll($event)">Expand All</p>
          <p a-context-menu-item (click)="onCollapseAll($event)">Collapse All</p>
        </div>
      </div>
		  <swimlane-edit-popup
				  class="near_by_name"
				  *ngIf="isChangeSwimlaneNameVisible"
				  [swimlane]="swimlane"
				  [attr.draggable-disabled]="true"
				  (close)="onToggleSwimlaneNamePopup()"
		  >
		  </swimlane-edit-popup>

		  <swimlane-tasks-counter
          [swimlaneId]="swimlane.id"
				  [countedTasks]="tasksCounter"
		  ></swimlane-tasks-counter>
		  <!--<div class="notifications"></div>

			NOTIFICATIONS ICON FOR SWIMLANE
			-->
	  </div>

  </div>
</div>

<div *ngIf="hidden === false" class="columns_swimlane_container" data-column-create-on-dclick="true">
  <div *ngFor="let columnId of columnIds$ | async; let i = index; let last = last;"
       class="container container_{{(columnsById$ | async)[columnId].kind}}"
       [class.is-dragging]="columnId === (draggingColumnId$ | async)"
       [style.marginRight.px]="last ? (87 - scrollbarWidth) : undefined"
  >
    <column-detail
      [column]="(columnsById$ | async)[columnId]"
      [swimlane]="swimlane"
      [board]="board"
      [boardUsersMap]="boardUsersMap"
      [boardLabelsMap]="boardLabelsMap"
      [newNotifyMap]="newNotifyMap"
      [activeUserId]="activeUserId"
      [scoreMap]="scoreMap"
    ></column-detail>
  </div>
</div>

