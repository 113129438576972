
import {empty as observableEmpty,  Observable } from 'rxjs';

import {catchError, map, switchMap, debounceTime, filter, distinctUntilChanged, pluck} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { AuthService } from '../../shared/services/app/auth.service';
import { isPresent, toDashedFromCamelCase } from '../../../helpers';
import { Store } from '@ngrx/store';
import { AppState } from '../state';
import { RECENT_TASK_PL } from '../../constants';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { HandleResponseAction } from '../actions/root.action';
import { OpenedTaskActionTypes } from '../../task/ngrx/actions/opened-task.action';

@Injectable()
export class OpenedTaskHistoryEffect {
  @Effect()
  addToRecentTasks = this.actions$
    .ofType(OpenedTaskActionTypes.OPEN).pipe(
    pluck('payload'),
    distinctUntilChanged(),
    filter(isPresent),
    // do it async for better performance
    debounceTime(0),
    switchMap((payload: number) => this._api.post(toDashedFromCamelCase(RECENT_TASK_PL), { taskId: payload })),
    map(response => new HandleResponseAction(response)),
    catchError(err => {
      console.log('failed to write opened task', err);
      return observableEmpty();
    }),);

  constructor(
    private actions$: Actions,
    private _authService: AuthService,
    private _store: Store<AppState>,
    private _api: AtlazApiV2Service
  ) {}
}
