<div a-context-popup
     class="duedate_popup"
     [title]="'Add Members'"
     (close)="onCloseMembersPopup()"
>
  <members-list
    [possibleUsers]="possibleUsers"
    [selectedUsers]="isBatchActions ? [] : selectedUsers"
    [projectOnlyUsers]="projectOnlyUsers"
    [isPublicBoard]="isPublicBoard"
    (addUser)="addUser($event)"
    (addUserToBoard)="onAddUserToBoard($event)"
    (removeUser)="removeUser($event)"
  ></members-list>
</div>
