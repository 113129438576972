<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row">
    <p *ngIf="actionName === 'archive'">
      All tasks will be removed from the board. To view and restore archived cards, go to <br> “Menu” > “Archived Items.”
    </p>
  </div>
  <div *ngIf="currentSubColumns.length > 0 || currentBoardSwimlanes.length > 1">
    <h4>From</h4>
    <div class="row" *ngIf="currentSubColumns.length > 0">
      <div class="label">
        Subcolumn
      </div>
      <div class="select">
        <select formControlName="subColumn">
          <option [ngValue]="0" *ngIf="currentSubColumns.length > 1">All</option>
          <option *ngFor="let item of  currentSubColumns" [ngValue]="item.id">{{item.name}}</option>
        </select>
      </div>
    </div>
    <div class="row" *ngIf="currentBoardSwimlanes.length > 1">
      <div class="label">
        Swimlane
      </div>
      <swimlane-list-multiselect [boardId]="boardId" [swimlanes]="currentBoardSwimlanes"
                                 formControlName="swimlane"></swimlane-list-multiselect>
      <help-block [control]="form.get('swimlane')" [messages]="{emptyArray: 'Field is required'}"></help-block>
    </div>
    <div class="divider"></div>
  </div>
  <div *ngIf="actionName !== 'archive'">
    <h4>To</h4>
    <div class="row">
      <div class="label">
        Board
      </div>
      <div class="select">
        <select formControlName="targetBoard">
          <option *ngFor="let board of  targetBoards$ | async" [value]="board.id">{{board.name}}</option>
        </select>
      </div>
    </div>
    <div class="row" *ngIf="showTargetSwimlanes$ | async">
      <div class="label">
        Swimlane
      </div>
      <div class="select">
        <select formControlName="targetSwimlane">
          <option *ngFor="let swimlane of targetSwimlanes$ | async" [value]="swimlane.id">{{swimlane.name}}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="label">
        Column
      </div>
      <div class="select">
        <select formControlName="targetColumn">
          <option *ngFor="let column of targetColumns$ | async" [value]="column.id">{{column.name}}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="label">
        Position
      </div>
      <div class="select">
        <select formControlName="targetPosition">
          <option *ngFor="let position of  availablePositions" [value]="position.value">{{position.name}}</option>
        </select>
      </div>
    </div>
  </div>
  <br>
  <button *ngIf="actionName === 'move'"
    a-button
    class="btn_primary button"
    [pending]="_formService.isPending$ | async"
  >Move
  </button>
  <button *ngIf="actionName === 'archive'"
    a-button
    class="btn_warn button"
    [pending]="_formService.isPending$ | async"
    (click)= onSubmit()
  >Archive
  </button>
  <button
    a-button
    (keydown)="onClose()"
    (click)="onClose()"
    class="btn_text"
  >
    Cancel
  </button>
</form>
