import { compose } from '@ngrx/store';

import { ENTITY_PL_CODES, IDS } from '../app/constants';
import { COLUMN_PL, TASK_ACTIVITY_PL, TASK_PL } from '../app/constants/entity';
import * as entityCodes from '../app/constants/entity';
import { Entity } from '../app/interfaces';
import { ESInterface } from '../app/ngrx/state/app-state';
import { arrayMerge } from './array';
import { invertObj } from './index';
import { isNotPresent, isPresent, objectEqualProperties } from './object';

/**
 * we are use  following format below
 * {
 *  "property with related data" : "data type name"
 * }
 * For instance
 * {
 *  users: USER_PL,
 *  projects: PROJECT_PL,
 *  subColumns: COLUMN_PL
 * }
 * After response normalizing, origin object have following changed props
 * {
 *  userIds: [1,2,3]
 *  subColumnsIds: [1,5]
 * }
 */
// specify custom schema rules
const normalizeSchema = {
  subColumns: COLUMN_PL,
  columnFrom: COLUMN_PL,
  columnTo: COLUMN_PL,
  task: TASK_PL,
  replays: TASK_ACTIVITY_PL
};

//
ENTITY_PL_CODES.forEach(entityCode => {
  normalizeSchema[entityCode] = entityCode;
});

/**
 * @param entityListOrSingleEntity you can pass single entity for handling or object with many entities.
 * See examples below:
 *  Single Entity:
 *  normalizeServerResponse({
 *  id: 1,
 *  name : "someName",
 *  users : [
 *   {object: "user", id: 1, name: "userName"}
 *   {object: "user", id: 2, name: "userName2"}
 *  ]
 *  }, 'groups')
 *
 *  List of entities
 *
 *  normalizeServerResponse({
 *  type: "list",
 *  count: 2,
 *  data [
 *      {id: 1,
 *      name : "someName",
 *      users : [
 *        {object: "user", id: 1, name: "userName"}
 *        {object: "user", id: 2, name: "userName2"}
 *      ]},
 *      {id: 2,
 *      name : "someName2",
 *      users : [
 *        {object: "user", id: 1, name: "userName"}
 *        {object: "user", id: 2, name: "userName2"}
 *      ]}
 *  ]
 *  }, 'groups')
 *
 *
 * @param entityPlCode
 * @param initValue
 * @returns {{}}
 */
export const normalizeServerResponse = (entityListOrSingleEntity: any, entityPlCode: string, initValue: {} = {}) => {
  const entityRow: Entity[] =
    entityListOrSingleEntity.object === 'list' ? entityListOrSingleEntity.data : [entityListOrSingleEntity];

  const entities = entityRow.reduce((acc, entityItem) => {
    acc[entityPlCode] = acc[entityPlCode] || {
      entities: {},
      ids: []
    };

    // entityItem = Object.assign({}, entityItem);

    for (const propertyName of Object.keys(normalizeSchema)) {
      const entityCode = normalizeSchema[propertyName];
      if (entityItem.hasOwnProperty(propertyName)) {
        if (entityItem[propertyName].hasOwnProperty('data')) {
          entityItem[propertyName + IDS] = entityItem[propertyName].data.map(item => item.id);

          acc = normalizeServerResponse(entityItem[propertyName], entityCode, acc);
          entityItem[propertyName] = undefined;
        } else if (typeof entityItem[propertyName] === 'object') {
          acc = normalizeServerResponse(entityItem[propertyName], entityCode, acc);
          entityItem[propertyName] = entityItem[propertyName].id;
        }
      }
    }

    acc[entityPlCode].entities[entityItem.id] = entityItem;
    acc[entityPlCode].ids = arrayMerge(acc[entityPlCode].ids, [entityItem.id]);

    return acc;
  }, initValue);
  return entities;
};

export const getRelatedEntitiesByFields = (fields: string[]): string[] => {
  return ENTITY_PL_CODES.filter(plCode => fields.includes(plCode + IDS));
};

export const getRelatedEntities = <T extends Entity>(entity: T): string[] => {
  return getRelatedEntitiesByFields(Object.keys(entity));
};

export const deepCopyEntity = <T extends { id: number | string; object: string }>(
  oldEntities: { [p: number]: T },
  newEntities: { [p: number]: T },
  idsToReplace: number[]
): { [p: number]: T } => {
  const replacedEntities = {};

  idsToReplace.reduce((acc, idToReplace) => {
    return (replacedEntities[idToReplace] = Object.assign({}, oldEntities[idToReplace], newEntities[idToReplace]));
  }, replacedEntities);

  return Object.assign({}, oldEntities, newEntities, replacedEntities);
};

/**
 *
 * @param ids
 * @param entities
 * @param fields
 * @returns {Array}
 *
 * example:
 * getEntityArrayByFields(
 *   [1,2],
 *   {
 *     1: {id:1, name: 'name1'},
 *     2: {id:2, name: 'name2'}
 *   },
 *   {id: 1, name: 'name1'}
 * ) returns [{id:1, name: 'name1'}]
 *
 * getEntityArrayByFields(
 *   [1,2],
 *   {
 *     1: {id:1, name: 'name1'},
 *     2: {id:2, name: 'name2'}
 *   },
 *   {id: 1, name: 'name1', order: 3}
 * ) -> returns []
 */
export function getEntityArrayByFields<T extends Entity>(
  ids: number[],
  entities: { [id: number]: T },
  fields: { [field: string]: any }
) {
  return ids.reduce((acc, id) => {
    const entity = entities[id];

    if (objectEqualProperties(entity, fields)) {
      acc.push(entity);
    }
    return acc;
  }, []);
}

export function stateToArrayByFields<T extends Entity>(state: ESInterface<T>, fields: { [field: string]: any }) {
  return getEntityArrayByFields(state.ids, state.entities, fields);
}

export const entityCodesPlMap = Object.keys(entityCodes).reduce((acc, key) => {
  if (key.substr(-3) === '_PL') {
    acc[entityCodes[key.slice(0, -3)]] = entityCodes[key];
  }
  return acc;
}, {});
