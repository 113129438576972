import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BillingPlan } from '@atlaz/billing/interfaces/billing-plan';
import { Observable } from 'rxjs';
import { fromBillingPlans } from '@atlaz/billing/store/plan/plan.reducer';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../ngrx/state';
import { BillingPlanName } from '@atlaz/billing/constants/billing-subscription';

@Component({
  selector: 'pricing-faq',
  templateUrl: './pricing-faq.component.html',
  styleUrls: ['./pricing-faq.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PricingFaqComponent implements OnInit {
  @Input() proPlan: BillingPlan;
  @Input() bgColor: string;

  public basicPlan$: Observable<BillingPlan>;
  public proPlan$: Observable<BillingPlan>;
  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this.basicPlan$ = this._store.select(fromBillingPlans.get(BillingPlanName.Basic));
    this.proPlan$ = this._store.select(fromBillingPlans.get(BillingPlanName.Premium));
  }
}
