
import {of as observableOf,  Observable } from 'rxjs';

import {map, pluck, distinctUntilChanged, publishReplay, refCount} from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Board, OverviewActivity, User } from '../../../interfaces';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { ENTITY_PL, PROJECT_PL, USER_PL } from '../../../constants/';
import { getBoardById } from '../../../ngrx/reducers/board.reducer';
import { getEntitiesByIds } from '../../../ngrx/functions/selectors';
import { getUserById } from '../../../ngrx/reducers/user.reducer';
import { getFeatureAvailability, toString } from '../../../../helpers/';
import { fromColumns } from '../../../ngrx/reducers/column.reducer';
import { AppUrls } from '../../../app-urls';

@Component({
  selector: 'task-feed-preview',
  templateUrl: './task-feed-preview.component.html',
  styleUrls: ['./task-feed-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskFeedPreviewComponent implements OnInit {
  @Input() activity: OverviewActivity;
  @Input() rightMenuContent: boolean;

  public columnFromName$: Observable<string>;
  public columnToName$: Observable<string>;

  public taskUrl$: Observable<any>;
  public projectShortName$: Observable<string>;
  public projectShortNameForView$: Observable<string>;
  public board$: Observable<Board>;

  public users$: Observable<User[]>;
  public movedUser$: Observable<User>;

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this.projectShortName$ = <Observable<string>>this._store.pipe(
      pluck(PROJECT_PL, ENTITY_PL, toString(this.activity.project), 'shortName'),
      distinctUntilChanged(),
      publishReplay(1),
      refCount(),);
    this.projectShortNameForView$ = getFeatureAvailability('overviewTaskNames')
      ? this.projectShortName$
      : observableOf(null);
    this.board$ = <Observable<Board>>this._store.pipe((getBoardById(this.activity.board)));
    this.users$ = <Observable<User[]>>this._store.pipe((getEntitiesByIds(USER_PL, this.activity.usersIds)));
    this.movedUser$ = <Observable<User>>this._store.pipe((getUserById(this.activity.movedUser)));

    this.columnFromName$ = this._store.select(fromColumns.getColumnFullNameById(this.activity.columnFrom));
    this.columnToName$ = this._store.select(fromColumns.getColumnFullNameById(this.activity.columnTo));
    this.taskUrl$ = this.projectShortName$.pipe(map(shortName =>
      AppUrls.getUrlTask(shortName ? shortName + '-' + this.activity.numberInProject : this.activity.task)
    ));
  }

  get isActionDone() {
    return this.activity.action === 'done';
  }

  get isActionMove() {
    return this.activity.action === 'move';
  }

  get isLogWork() {
    return this.activity.action === 'log';
  }
}
