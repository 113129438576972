import { Component } from '@angular/core';

@Component({
  selector: 'header-university-icon-svg',
  template: `    
    <svg id="University_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180.207 180.207" width="24px" height="24px" style="fill:#455A64;">
      <path d="M176.805,62.738L92.701,34.067c-1.361-0.464-2.834-0.464-4.195,0L4.402,62.738C1.77,63.636-0.001,66.11,0,68.892
          c0.001,2.783,1.772,5.256,4.407,6.152l32.694,11.121v33.883c0,1.62,0.604,3.181,1.695,4.378
          c13.356,14.657,32.383,23.063,52.2,23.063c19.622,0,37.853-7.858,51.333-22.127c1.141-1.207,1.775-2.804,1.775-4.464V86.215
          l13.498-4.612v16.343c-1.52,1.19-2.5,3.037-2.5,5.116v15.855c0,3.59,2.91,6.5,6.5,6.5s6.5-2.91,6.5-6.5v-15.855
          c0-2.08-0.98-3.927-2.5-5.116V78.87l11.205-3.828c2.631-0.899,4.399-3.373,4.398-6.153
          C181.206,66.108,179.437,63.635,176.805,62.738z M131.105,118.235c-10.799,10.501-24.944,16.253-40.108,16.253
          c-15.348,0-30.114-6.18-40.896-17.036V90.587l38.597,13.128c0.679,0.231,1.386,0.346,2.093,0.346c0.71,0,1.421-0.116,2.102-0.349
          l38.213-13.056V118.235z M90.786,90.694L26.664,68.883l63.939-21.797l63.979,21.811L90.786,90.694z"/>
    </svg>
  `
})
export class HeaderUniversitySvgComponent {}
