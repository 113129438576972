<a-center-container-scrollable>
  <div a-popup class="width_640" [ngClass]="{'visible-left-menu': (isPinned$ | async)}" [title]="'New Board'" (close)="onClose()">
    <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
      <form-errors></form-errors>
      <div class="form_wrap">
        <div class="form_mode" *ngIf="isModeSelectorAvailable$ | async">
            <ul class="nav_tabs">
              <li><a [ngClass]="{'active_state': (isTypeChooserAvailable$ | async)}" (click)="setTemplateChoosing(false)">BLANK</a></li>
              <li><a [ngClass]="{'active_state': !(isTypeChooserAvailable$ | async)}" (click)="setTemplateChoosing(true)">BY TEMPLATE</a></li>
            </ul>
        </div>

        <div class="form_inputs">
          <div class="form-group" *ngIf="isCommonFieldsVisible$ | async">
            <label for="board_name">Board Name</label>
            <input
              #boardName
              placeholder="Example: Development"
              id="board_name"
              formControlName="name"
              [focus]="true"
              checkTouch
            />
            <help-block
              [control]="form.get('name')"
              [messages]="{'maxlength': 'Maximum length of 50 symbols is exceed'}"
            >
            </help-block>
          </div>
          <div class="form-group" [ngClass]="{hidden: !(isTypeChooserAvailable$ | async)}">
            <label>Board Type</label>
            <board-type-radio-chooser [isSprintAvailable]="(isSuperUser$ | async) || (isProjAdmin$ | async)" (changeSelection)="onChangeSelectedType($event)" [initialBoardType]="initialBoardType"></board-type-radio-chooser>
          </div>
          <div [ngClass]="{hidden: !(isTemplateChooserAvailable$ | async)}">
            <board-template-chooser [isSprintAvailable]="(isSuperUser$ | async) || (isProjAdmin$ | async)" [isSingleSelect]="true" [chooseTemplatesError]="showTemplateChooseErr$ | async" (changeSelection)="onChangeSelectedTemplate($event)"></board-template-chooser>
            <div class="use_template_wrap">
              <button type="button" a-button class="btn btn_primary" (click)="onUseTemplate()">Use Template</button>
            </div>
          </div>
          <ng-container *ngIf="isCommonFieldsVisible$ | async">
            <div class="form-group" *ngIf="isSuperUser$ | async">
              <label>Collection</label>
              <collection-input-picker
                [selectedCollections$]="selectedCollections$"
                className="new_board_collection_input_picker"
              >
              </collection-input-picker>
              <help-block [control]="form.get('collections')"></help-block>
            </div>
            <div class="form-group" *ngIf="(isSuperUser$ | async) || (isProjAdmin$ | async)">
              <label>Projects</label>
              <project-input-picker
                [multiple]="boardTypes.sprint !== form.get('type').value"
                [selectedProjects]="selectedProjects$"
                [disabledProjects]="disabledProjects"
                className="new_board_project_input_picker"
                [validation]="form.get('projects').valid"
              >
              </project-input-picker>
              <help-block [control]="form.get('projects')" [messages]="projectErrorMessages"></help-block>
            </div>
            <div class="buttons_block">
              <button type="button" a-button class="btn btn_primary" [pending]="_formService.isPending$ | async" (click)="onSubmit()">Create</button>
              <button type="button" a-button class="btn btn_text" (click)="onClose()">Cancel</button>
              <label class="without-tasks" *ngIf="form.get('template').value" [class.selected]="form.get('withoutTasks').value">
                <input type="checkbox" [formControlName]="'withoutTasks'">
                <span>Include test data</span>
              </label>
            </div>
          </ng-container>
        </div>
      </div>
    </form>
  </div>
</a-center-container-scrollable>
<overlay (click)="onClose()"></overlay>
