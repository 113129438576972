<ng-container  *ngIf="!showSent">
<div class="popup_wrap" [clickOut]="true" (clickOutEvent)="onClose()">
  <div class="popup_header">
    <div class="popup_title">Invite Member</div>
    <close-button (click)="onClose()"></close-button>
  </div>
  <div class="divider"></div>
  <div class="popup_form">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <form-errors></form-errors>
      <div class="popup_form_group">
        <span class="popup_form_label">Member's email:</span>
        <div class="popup_form_input_container">
          <input formControlName="email" [focus]="true" class="popup_form_input" checkTouch placeholder="email@example.com">
          <help-block [control]="form.get('email')"></help-block>
        </div>
      </div>
      <div class="popup_form_group">
        <span class="popup_form_label">Role:</span>
        <div class="popup_form_input_container">
          <select formControlName="role" class="popup_form_select">
            <option *ngFor="let role of roles" [value]="role.role">{{role.name}}</option>
          </select>
        </div>
      </div>
    </form>
  </div>
  <div class="popup_create">
    <button a-button class=" btn_primary popup_create_btn" [pending]="_formService.pending$ | async" (click)="onSubmit()">Send Invite</button>
  </div>
</div>
<overlay (click)="onClose()"></overlay>
</ng-container>
<invite-has-been-sent *ngIf="showSent" [email]="form.value.email" (close)="onClose()"></invite-has-been-sent>
