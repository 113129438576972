<div [ngClass]="'boards_container ' + className">
  <div class="tags_container">
    <div class="board_tag"
         *ngFor="let id of selectedBoards$ | async">
      <p>{{boardsEntities[id]?.name}}</p>
      <div (click)="onRemoveMember(id)" class="remove_board_control"></div>
    </div>
    <div class="add_btn" (click)="showAddPopUp=true"></div>
    <div a-context-popup
         [title]="'Boards'"
         *ngIf="showAddPopUp"
         (close)="showAddPopUp=false"
         class="boards_popup"
    >
      <board-list-independent [selectedBoards]="selectedBoards$"></board-list-independent>
    </div>
  </div>
</div>
