import { createSelector } from 'reselect';

import { isPresent, prop } from '../../../../../helpers/index';
import { GITHUB_COMMIT_PL } from '../../../../constants/index';
import { RootActions, RootActionTypes } from '../../../../ngrx/actions/root.action';
import { insertReplaceState, removeOne } from '../../../../ngrx/functions/reducer';
import { ESInterface, getEmptyESState } from '../../../../ngrx/state/index';
import { createCommonSelectors, getByIds } from '../../../../util/store/index';
import { ESGithubCommit, GithubCommit } from '../../models/git-commit';
import * as githubCompany from '../github-company/github-company.actions';
import { createMapWithArray } from '../util';
import * as githubCommit from './github-commit.actions';

export interface State extends ESGithubCommit {
  taskCommits: {
    [taskId: number]: number[];
  };
  isLoading: boolean;
}

const initialState: State = {
  ...getEmptyESState<GithubCommit>(),
  taskCommits: {},
  isLoading: false
};

export function reducer(
  state = initialState,
  action: githubCommit.Actions | githubCompany.Actions | RootActions
): State {
  switch (action.type) {
    case githubCommit.GET_TASK_COMMIT: {
      return { ...state, isLoading: true };
    }
    case githubCommit.UPDATED: {
      return state;
    }

    case githubCommit.GET_TASK_COMMIT_COMPLETE: {
      const taskCommits = {
        ...state.taskCommits,
        [action.payload.taskId]: action.payload.entities.ids
      };
      return {
        ...state,
        taskCommits: taskCommits,
        isLoading: false
      };
    }

    case githubCommit.GET_TASK_COMMIT_FAIL: {
      return { ...state, isLoading: false };
    }

    case githubCommit.DELETE_TASK_COMMIT: {
      const newState = removeOne(state, action.payload.id);
      const taskCommits = newState.taskCommits[action.payload.taskId] || [];
      newState.taskCommits = {
        ...newState.taskCommits,
        [action.payload.taskId]: taskCommits.filter(cId => cId !== action.payload.id)
      };
      return newState;
    }

    case githubCompany.REMOVE_ALL_COMPLETE: {
      return initialState;
    }

    case RootActionTypes.GET_COMPLETE: {
      if (GITHUB_COMMIT_PL in action.payload) {
        const updatedState: ESInterface<GithubCommit> = action.payload[GITHUB_COMMIT_PL];
        const taskCommits = updatedState.ids.reduce(
          createMapWithArray(updatedState.entities, 'task', 'id'),
          Object.assign({}, state.taskCommits)
        );
        const newESState = <State>insertReplaceState(state, updatedState);
        return {
          ...newESState,
          taskCommits,
          isLoading: false
        };
      }

      return state;
    }

    default: {
      return state;
    }
  }
}

export const { getState, getEntities, getIds, getAll, isEmpty } = createCommonSelectors<GithubCommit, State>(
  GITHUB_COMMIT_PL
);

export const getTaskCommitMap = createSelector(getState, prop('taskCommits'));

export const getTaskCommitIds = createSelector(getTaskCommitMap, state => taskId => state[taskId] || []);
export const getTaskCommits = createSelector(
  getTaskCommitIds,
  getEntities,
  (commitIdsFn, entities) => (taskId: number) => getByIds(commitIdsFn(taskId), entities).filter(isPresent)
);

const isLoading = createSelector(getState, state => state.isLoading);

export const getTaskCommitsLoading = createSelector(isLoading, getTaskCommitIds, (isLoadingFlag, idsFn) => taskId =>
  isLoadingFlag && idsFn(taskId).length === 0
);
