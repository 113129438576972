
import {map, filter, switchMap, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from '../../../ngrx/state/app-state';
import { RouterNavigateService } from '../../../shared/services/router-navigate.service';
import * as slackAlert from '../../settings/slack-alert/store/slack-alert.action';
import * as fromSlackAlert from '../../settings/slack-alert/store/slack-alert.reducer';
import * as slackBoard from '../../settings/slack-alert/store/slack-board/slack-board.actions';
import * as slackCompany from '../../settings/slack-alert/store/slack-company/slack-company.actions';
import * as fromSlackCompany from '../../settings/slack-alert/store/slack-company/slack-company.reducer';

@Injectable()
export class SlackTeamExistsGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const board = +next.params['board'];

    this._store.dispatch(new slackCompany.GetIndex());
    const loadedEvent$ = this._store.select(fromSlackAlert.isCompanyLoadingFirst).pipe(filter(loading => loading === false));

    const isEmpty$ = loadedEvent$.pipe(switchMap(_ => this._store.select(fromSlackCompany.isEmpty)),take(1),);

    return isEmpty$.pipe(map(emptyState => this.navigateSideEffect(emptyState, board)));
  }

  constructor(private _store: Store<AppState>, private _routerNav: RouterNavigateService) {}

  private navigateSideEffect(isEmpty: boolean, board: number): true {
    if (isEmpty) {
      this._store.dispatch(new slackBoard.GetIndexCompleted(null));
      this._routerNav.navigateToAddSlackTeam(board);
      return true;
    }

    this._store.dispatch(new slackAlert.SetActiveBoard(board));
    this._store.dispatch(new slackBoard.GetIndex({ board }));
    return true;
  }
}
