import { boardsState, fromBoards, isActiveBoard } from '../reducers/board.reducer';
import { createSelector, defaultMemoize } from 'reselect';
import { collectionsState } from '../reducers/collection.reducer';
import { createMapFromArr, isPresent, naturalSort, uniqBy } from '../../../helpers';
import { fromUsers } from '../reducers/user.reducer';
import { fromTask, getTasksState, isActiveTask } from '../reducers/task.reducer';
import { fromLabels } from '../reducers/label.reducer';
import { fromScoringCriteria } from '../reducers/scoring-criteria.reducer';
import { ScoringFactor } from '../../board/backlog-board/constants/backlog.constants';
import { ScoringType } from '../../constants';
import { fromColumns } from '../reducers/column.reducer';
import { fromSwimlanes } from '../reducers/swimlane.reducer';
import { getVersionsList } from '../reducers/version.reducer';
import { TaskLink } from '../../interfaces/tasks-links-type';
import { getTaskLinks } from '../reducers/task-links.reducer';
import { getDependenciesTypes } from '../reducers/tasks-links-type.reducer';
import { Task, TasksLinksType, User } from '../../interfaces';
import { ESInterface } from '../state';
import { fromProject } from '../reducers/project.reducer';
import { BoardInProjectAccess } from '../../interfaces/board';

export const getActiveBoardsByCollectionId = collectionId =>
  createSelector(collectionsState, boardsState, (collections, boards) =>
    (collections.entities[collectionId] ? collections.entities[collectionId]['boardsIds'] : [])
      .map(boardId => boards.entities[boardId])
      .filter(isActiveBoard)
  );

export const getSelectedBoardCollections = createSelector(
  fromBoards.getSelectedBoard,
  collectionsState,
  (board, collections) =>
    board ? board.collectionsIds.map(collectionId => collections.entities[collectionId]).filter(isPresent) : []
);

export const getAdvancedScoreByTask = task =>
  defaultMemoize(
    createSelector(
      fromBoards.get(task && task.board),
      fromScoringCriteria.getByBoard(task && task.board),
      (board, crs) => _getScoreForTask(board, crs, task)
    )
  );

export const getScoreByTasksFromSameBoard = (tasks: Task[]) =>
  defaultMemoize(
    createSelector(
      fromBoards.get(tasks[0] && tasks[0].board),
      fromScoringCriteria.getByBoard(tasks[0] && tasks[0].board),
      (board, crs) => tasks.map(task => _getScoreForTask(board, crs, task))
    )
  );

export const getScoreMapByTasksFromSameBoard = (tasks: Task[]) =>
  defaultMemoize(
    createSelector(
      fromBoards.get(tasks[0] && tasks[0].board),
      fromScoringCriteria.getByBoard(tasks[0] && tasks[0].board),
      (board, crs) =>
        tasks.reduce((acc, task) => {
          acc[task.id] = _getScoreForTask(board, crs, task);
          return acc;
        }, {})
    )
  );

export const getSelectedBoardColumns = createSelector(
  fromBoards.getSelectedBoard,
  fromColumns.getState,
  (board, columnsState) => board && Object.values(columnsState.entities).filter(column => column.board === board.id)
);

export const getSelectedBoardSwimlanes = createSelector(
  fromBoards.getSelectedBoard,
  fromSwimlanes.getState,
  (board, swimlanesState) =>
    board && Object.values(swimlanesState.entities).filter(swim => swim && swim.board === board.id)
);

export const getTaskUsers = taskId =>
  createSelector(fromTask.taskById(taskId), fromUsers.getState, (task, usersState) => {
    return task && task.usersIds ? task.usersIds.map(id => usersState.entities[id]).filter(isPresent) : [];
  });

export const getSelectedBoardUsers = createSelector(
  fromBoards.getSelectedBoard,
  fromUsers.getState,
  (board, usersState) => {
    return board && board.usersIds
      ? board.usersIds.map(id => usersState.entities[id]).filter(user => user && !user.deleted)
      : [];
  }
);

const getBoardByTaskId = taskId =>
  defaultMemoize(
    createSelector(
      fromTask.taskById(taskId),
      boardsState,
      (task, boards) => (task ? boards.entities[task.board] : null)
    )
  );

const taskUnmergedUsers = id =>
  defaultMemoize(
    createSelector(
      fromTask.taskById(id),
      fromProject.getState,
      fromUsers.getState,
      getBoardByTaskId(id),
      (task, projectState, usersState, board) => {
        if (!task || !board) {
          return { boardUsers: [], projectUsers: [], isBoardPublic: false };
        }
        const boardUsers =
          board && board.usersIds
            ? board.usersIds.map(userId => usersState.entities[userId]).filter(user => user && !user.deleted)
            : [];
        let projectUsers = [];
        if (task && task.project) {
          const usersIds = projectState.entities[task.project].usersIds || [];
          projectUsers = usersIds.map(userId => usersState.entities[userId]).filter(user => user && !user.deleted);
        }
        return { boardUsers, projectUsers, isBoardPublic: board.access === BoardInProjectAccess.public };
      }
    )
  );

export const getTaskPossibleUsers = id =>
  defaultMemoize(
    createSelector(taskUnmergedUsers(id), input =>
      naturalSort('fullname')(
        input.isBoardPublic ? uniqBy([...input.boardUsers, ...input.projectUsers], 'id') : [...input.boardUsers]
      )
    )
  );

export const getTaskProjectOnlyUsers = id =>
  defaultMemoize(
    createSelector(taskUnmergedUsers(id), input => {
      if (input.isBoardPublic) {
        return [];
      }
      const projectUsersMap = createMapFromArr(input.projectUsers);
      const projectUsersIds = input.projectUsers.map(user => user.id);
      const boardUsersIds = input.boardUsers.map(user => user.id);
      const diff = projectUsersIds.filter(projUserId => !boardUsersIds.includes(projUserId));
      return naturalSort('fullname')(diff.map(userId => projectUsersMap[userId]));
    })
  );

export const getTaskLabels = taskId =>
  createSelector(fromTask.taskById(taskId), fromLabels.getState, (task, labelsState) => {
    return task && task.labelsIds ? task.labelsIds.map(id => labelsState.entities[id]).filter(isPresent) : [];
  });

export const getVersionsListByBoardId = boardId =>
  defaultMemoize(
    createSelector(fromBoards.get(boardId), getVersionsList, (board, versions) =>
      versions.filter(item => board && board['projectsIds'] && board['projectsIds'].includes(item.project))
    )
  );

export const getOutwardDependenciesByTaskIds = (taskIds: number[]) =>
  defaultMemoize(
    createSelector(
      getDependenciesTypes,
      getTaskLinks,
      getTasksState,
      (depTypes: TasksLinksType[], links: TaskLink[], tasksState: ESInterface<Task>): TaskLink[] => {
        const depTypesIds = depTypes.map(dt => dt.id);
        return links.filter(
          (link: TaskLink) =>
            taskIds.includes(link.outwardTask) &&
            depTypesIds.includes(link.tasksLinksTypes) &&
            isActiveTask(tasksState.entities[link.inwardTask])
        );
      }
    )
  );

const _getScoreForTask = (board, crs, task) => {
  if (!board) {
    return '?';
  }
  let score;
  if (board.scoringType === ScoringType.advanced) {
    if (!task || !crs || !crs.length || !board) {
      return '?';
    }
    const crMap = (Array.isArray(task.scoringValues) ? task.scoringValues : []).reduce((acc, item) => {
      acc[item.criterion] = item.value;
      return acc;
    }, {});
    const crsValues = crs.map(cr => {
      const val = Number.isFinite(crMap[cr.id]) ? crMap[cr.id] : '?';
      return { ...cr, value: val };
    });

    const separateValues = crsValues.reduce(
      (acc, item) => {
        if (item.factor === ScoringFactor.Value) {
          acc[ScoringFactor.Value] += item.scoringDirection * (item.weight / 100) * item['value'];
        } else {
          acc[ScoringFactor.Efforts] += item.scoringDirection * (item.weight / 100) * item['value'];
        }
        return acc;
      },
      { [ScoringFactor.Value]: 0, [ScoringFactor.Efforts]: 0 }
    );
    score = Math.round(
      (separateValues[ScoringFactor.Value] * (board.advancedValueWeight / 100) +
        separateValues[ScoringFactor.Efforts] * (board.advancedEffortWeight / 100)) *
        (task.confidence / 100)
    );
  } else if (board.scoringType === ScoringType.ICE) {
    score = parseInt(task.iceImpact) * parseInt(task.iceConfidence) * parseInt(task.iceEase);
  } else if (board.scoringType === ScoringType.RICE) {
    score = Math.round(
      parseInt(task.riceReach) *
        parseFloat(task.riceImpact) *
        (parseInt(task.riceConfidence) / 100) /
        parseInt(task.riceEffort)
    );
  }
  return Number.isFinite(score) ? score : '?';
};
