import { Action } from '../unsafe-action';

export enum DefaultTasksLabelsFilterActionTypes {
  TOGGLE_LABEL_ID = '[DEFAULT_LABELS_FILTER] TOGGLE_LABEL_IDS',
  RESET = '[DEFAULT_LABELS_FILTER] RESET'
}

export class DefaultLabelFilterToggleLabelsAction implements Action {
  readonly type = DefaultTasksLabelsFilterActionTypes.TOGGLE_LABEL_ID;

  constructor(public payload: number[]) {}
}

export class DefaultLabelsFilterResetAction implements Action {
  readonly type = DefaultTasksLabelsFilterActionTypes.RESET;
}

// prettier-ignore
export type Actions
  = DefaultLabelFilterToggleLabelsAction
  | DefaultLabelsFilterResetAction
  ;
