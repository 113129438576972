import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ListBoardGroup, ListBoardTasks } from '../list-board-details.component';
import { Board, PartOfEntity, Swimlane, Task, User } from '../../../../interfaces';
import { CustomField } from '../../../../ngrx/reducers/custom-field.reducer';
import { BehaviorSubject, Observable, Subscription } from 'rxjs/index';
import { fromBoards } from '../../../../ngrx/reducers/board.reducer';
import { map, switchMap } from 'rxjs/operators';
import { naturalSort } from '../../../../../helpers';
import { getBoardUsers } from '../../../../ngrx/reducers/user.reducer';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../ngrx/state';
import {
  getTaskPossibleUsers,
  getTaskProjectOnlyUsers,
  getTaskUsers
} from '../../../../ngrx/functions/crossed.selector';
import { BoardAssignUsersAction } from '../../../../ngrx/actions/board.actions';
import { TaskAssignUsersAction, TaskDeleteAction, TaskEditAction } from '../../../../ngrx/actions/task.actions';
import * as moment from 'moment';
import { fromTasksCustomFields, TasksCustomField } from '../../../../ngrx/reducers/tasks-custom-field.reducer';
import { getHiddenSwimlanesState } from '../../../../ngrx/reducers/gui-state-memorized.reducer';
import {
  GuiStateChangeSwimlaneVisibility,
  GuiStateQuickTaskEditShow
} from '../../../../ngrx/actions/gui-state-memorized.actions';
import { SwimlaneEditAction } from '../../../../ngrx/actions/swimlane.actions';
import { ScoringType } from '../../../../constants';

interface EditEvent {
  id: number;
  field: string | number | CustomField;
  clientRect?: {
    x: number;
    y: number;
  };
}

const preventDrop = e => {
  e.preventDefault();
};

@Component({
  selector: 'list-board-content',
  templateUrl: './list-board-content.component.html',
  styleUrls: ['./list-board-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListBoardContentComponent implements OnInit, OnChanges, OnDestroy {
  @Input() board: Board;
  @Input() isNotGuest: boolean;
  @Input() groupedTasks: ListBoardTasks;
  @Input() allGroups: ListBoardGroup[];
  @Input() boardUsersMap;
  @Input() boardLabelsMap;
  @Input() customFields: CustomField[];
  @Input() groupWidth = 'auto';
  @Input() doneColumnId: number;
  @Input() isGroupedBySwimlane: boolean;
  @Input() scoringColumns: any[];

  @Output() completeTask = new EventEmitter();

  public swimlane: Swimlane;
  public swimlaneEditingId: number;

  public editingTaskId: number;

  public isClosed: { [value: number]: boolean } = {};
  public groupsArr;

  public isScoreEditing$ = new BehaviorSubject(false);
  public editingTask$ = new BehaviorSubject(null);
  public editingCriterion$ = new BehaviorSubject(null);

  public editMembersTaskId$ = new BehaviorSubject(0);
  public editLabelsTaskId$ = new BehaviorSubject(0);
  public editDueDateTaskId$ = new BehaviorSubject(0);
  public editCustomFieldTaskId$ = new BehaviorSubject(0);
  public editingFieldTask: Task;
  public dueDateValue;
  public customFieldValue: CustomField;
  public tasksCustomFieldValue: TasksCustomField | PartOfEntity;
  public membersClientRect;
  public labelsClientRect;
  public tasksCustomFieldClientRect;
  public isPublicBoard$: Observable<boolean>;
  public boardUsers$: Observable<User[]>;
  public taskUsers$: Observable<User[]>;
  public taskPossibleUsers$: Observable<User[]>;
  public projectsOnlyUsers$: Observable<User[]>;
  public tasksCustomFieldsMap: { [taskId: number]: { [fieldId: number]: TasksCustomField } } = {};

  public dragAllowed;
  public dragStartTask: Task;
  public dragOverTask: Task;
  public dragStartGroup: ListBoardGroup;
  public dragOverGroup: ListBoardGroup;
  public dragOverH3FromGroup: number;
  public dragPointerOrder = -2;

  public dragType: string;

  public showDeleteConfirmDialog$ = new BehaviorSubject(false);
  public taskToDelete: Task;

  public subs: Subscription[] = [];

  public ScoringType = ScoringType;

  constructor(private _store: Store<AppState>, private _cd: ChangeDetectorRef) {}

  ngOnInit() {
    document.addEventListener('drop', preventDrop);
    this.boardUsers$ = <Observable<User[]>>this._store.pipe(getBoardUsers, map(naturalSort('fullname')));

    this.isPublicBoard$ = this._store.select(fromBoards.isSelectedBoardPublic);

    this.taskPossibleUsers$ = this.editMembersTaskId$.pipe(
      switchMap(taskId => this._store.select(getTaskPossibleUsers(taskId)))
    );
    this.projectsOnlyUsers$ = this.editMembersTaskId$.pipe(
      switchMap(taskId => this._store.select(getTaskProjectOnlyUsers(taskId)))
    );

    this.taskUsers$ = this.editMembersTaskId$.pipe(
      switchMap(taskId => this._store.select(getTaskUsers(taskId)).pipe(map(naturalSort('fullname'))))
    );

    this.subs.push(
      this._store.select(fromTasksCustomFields.getAll).subscribe(valuesArr => {
        this.tasksCustomFieldsMap = valuesArr.reduce((acc, item) => {
          if (!acc[item.task]) {
            acc[item.task] = {};
          }
          acc[item.task][item.customField] = item;
          return acc;
        }, {});
      }),
      this._store.pipe(getHiddenSwimlanesState).subscribe((idsMap: { [value: number]: boolean }) => {
        if (this.isGroupedBySwimlane) {
          this.isClosed = { ...idsMap };
          this._cd.detectChanges();
        }
      })
    );
  }

  ngOnChanges() {
    this.groupsArr = Object.keys(this.groupedTasks);
    this.dragAllowed = this.isGroupedBySwimlane && this.isNotGuest ? true : null;
  }

  onToggleState(value) {
    if (this.isGroupedBySwimlane) {
      this._store.dispatch(
        new GuiStateChangeSwimlaneVisibility({
          id: value,
          flag: !this.isClosed[value]
        })
      );
    } else {
      this.isClosed[value] = !this.isClosed[value];
    }
  }

  onCompleteTask(task) {
    this.completeTask.emit(task);
  }

  onStartEdit(event: EditEvent, task) {
    switch (event.field) {
      case 'members': {
        this.openMembersPopUp(event.id, event.clientRect);
        break;
      }
      case 'labels': {
        this.openLabelsPopUp(event.id, event.clientRect, task);
        break;
      }
      case 'dueDate': {
        this.openDueDatePopUp(event.id, task);
        break;
      }
      case 'general': {
        if (this.editingTaskId !== event.id) {
          this.editingTaskId = event.id;
        }
        break;
      }
      default: {
        this.openCustomFieldPopUp(event.id, event.clientRect, task, event.field);
      }
    }
  }

  onEndEdit(event: EditEvent) {
    switch (event.field) {
      case 'members': {
        this.onCloseMembersPopup();
        break;
      }
      case 'labels': {
        this.onCloseLabelsPopup();
        break;
      }
      case 'general': {
        if (this.editingTaskId === event.id) {
          this.editingTaskId = 0;
        }
        break;
      }
    }
  }

  onEditScoring(event) {
    if (!event.task) {
      return;
    }
    this.editingTask$.next(event.task);
    if (!event.scoringColumn) {
      // do nothing;
    } else if (event.scoringColumn === 'confidence') {
      this.editingCriterion$.next('confidence');
    } else if (event.scoringColumn.id) {
      this.editingCriterion$.next(event.scoringColumn.id);
    } else if (event.scoringColumn.factor) {
      this.editingCriterion$.next(event.scoringColumn.factor.slice(-1).toLocaleLowerCase());
    } else if (event.scoringColumn.prop) {
      this.editingCriterion$.next(event.scoringColumn.prop);
    }
    this.isScoreEditing$.next(true);
  }

  onCloseEditingScoring() {
    this.isScoreEditing$.next(false);
    this.editingTask$.next(null);
    this.editingCriterion$.next(null);
  }

  onEditSwimlane(event, group) {
    event.stopPropagation();
    this.swimlane = {
      object: 'swimlane',
      id: group.value,
      name: group.title,
      board: this.board.id
    };
    this.swimlaneEditingId = group.value;
  }

  onCloseSwimlaneEdit() {
    this.swimlaneEditingId = 0;
  }

  openMembersPopUp(taskId, clientRect = {}) {
    this.editMembersTaskId$.next(taskId);
    this.membersClientRect = clientRect;
  }

  onCloseMembersPopup() {
    this.editMembersTaskId$.next(0);
  }

  onAddUserToBoard(user: User) {
    this._store.dispatch(new BoardAssignUsersAction({ id: this.board.id, users: { add: [user.id] } }));
  }

  onUpdateUsersList(userListUpdate) {
    if (!this.isNotGuest) {
      return;
    }
    const taskId = this.editMembersTaskId$.value;
    if (taskId) {
      this._store.dispatch(new TaskAssignUsersAction({ id: taskId, users: userListUpdate }));
    }
  }

  openLabelsPopUp(taskId, clientRect = {}, task?) {
    this.editLabelsTaskId$.next(taskId);
    this.labelsClientRect = clientRect;
    this.editingFieldTask = task;
  }

  onCloseLabelsPopup() {
    this.editLabelsTaskId$.next(0);
    this.editingFieldTask = null;
  }

  openDueDatePopUp(taskId, task) {
    this.dueDateValue =
      task.dueDate ||
      moment()
        .add(1, 'days')
        .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
        .unix();
    this.editDueDateTaskId$.next(taskId);
    this.editingFieldTask = task;
  }

  onSubmitDueDate(delay) {
    setTimeout(() => {
      this._store.dispatch(new TaskEditAction({ id: this.editDueDateTaskId$.value, dueDate: this.dueDateValue }));
      this.onCloseDueDatePopup(0);
    }, delay);
  }

  onCloseDueDatePopup(delay) {
    setTimeout(() => {
      this.editDueDateTaskId$.next(0);
      this.editingFieldTask = null;
    }, delay);
  }

  openCustomFieldPopUp(taskId, clientRect = {}, task, field) {
    this.editCustomFieldTaskId$.next(taskId);
    this.tasksCustomFieldClientRect = clientRect;
    this.editingFieldTask = task;
    this.customFieldValue = field;
    this.tasksCustomFieldValue = this.tasksCustomFieldsMap[taskId]
      ? this.tasksCustomFieldsMap[taskId][field.id]
      : {
          value: '',
          customField: field.id,
          task: taskId
        };
  }

  onCloseCustomFieldPopup() {
    this.editCustomFieldTaskId$.next(0);
  }

  onDragStart(event: DragEvent, task: Task) {
    event.stopPropagation();
    this.dragType = 'ddTask';
    event.dataTransfer.setData('text', task.title);
    this.dragStartTask = task;
    this.dragOverH3FromGroup = 0;
  }

  onDragEnd(event) {
    if (this.dragType !== 'ddTask') {
      return;
    }
    event.stopPropagation();
    if (this.dragOverTask) {
      const payload = {
        id: this.dragStartTask.id,
        swimlane: this.dragOverTask.swimlane,
        insertAfterTask: this.dragOverTask.id
      };
      this._store.dispatch(new TaskEditAction(payload));
      this.dragOverTask = null;
    } else if (
      this.dragOverGroup &&
      this.dragOverGroup.prop === 'swimlane' &&
      (this.dragStartTask.swimlane !== this.dragOverGroup.value || this.dragOverH3FromGroup)
    ) {
      this._store.dispatch(
        new TaskEditAction({ id: this.dragStartTask.id, swimlane: this.dragOverGroup.value, insertBeforeTask: 0 })
      );
    }
    this.dragType = '';
  }

  onDragOver(event, task) {
    event.preventDefault();
    event.stopPropagation();
    if ((!this.dragOverTask || this.dragOverTask.id !== task.id) && this.dragType === 'ddTask') {
      this.dragOverTask = task;
    }
    this.dragOverH3FromGroup = 0;
  }

  onDragOverGroup(event, group, order) {
    event.preventDefault();
    event.stopPropagation();
    if (this.dragType === 'ddTask') {
      this.dragOverTask = null;
      this.dragOverGroup = group;
    } else if (this.dragType === 'ddGroup') {
      this.dragPointerOrder = order;
      this.dragOverGroup = group;
    }
    this.dragOverH3FromGroup = 0;
  }

  onDragOverH3(event, group) {
    event.preventDefault();
    event.stopPropagation();
    if (this.dragType === 'ddTask') {
      this.dragOverTask = null;
      this.dragOverGroup = group;
      this.dragOverH3FromGroup = group.value;
    }
  }

  onDragStartGroup(event, group) {
    event.stopPropagation();
    this.dragPointerOrder = -2;
    this.dragType = 'ddGroup';
    event.dataTransfer.setData('text', group.title);
    this.dragStartGroup = group;
    this.dragOverH3FromGroup = 0;
  }

  onDragEndGroup(event) {
    if (this.dragType !== 'ddGroup') {
      return;
    }
    event.stopPropagation();
    this.dragPointerOrder = -2;
    if (this.dragOverGroup) {
      const payload = {
        id: this.dragStartGroup.value,
        [this.dragOverGroup.value ? 'insertAfterSwimlane' : 'insertBeforeSwimlane']: this.dragOverGroup.value
          ? this.dragOverGroup.value
          : this.allGroups[0].value
      };
      this._store.dispatch(new SwimlaneEditAction(payload));
      this.dragOverGroup = null;
    }
    this.dragStartGroup = null;
    this.dragType = '';
  }

  onOpenQuickActions({ event, id }) {
    event.stopPropagation();
    this._store.dispatch(
      new GuiStateQuickTaskEditShow({
        id,
        rect: event.currentTarget.parentElement.getBoundingClientRect(),
        isExactRect: true
      })
    );
  }

  onOpenDeleteConfirm(task) {
    this.taskToDelete = task;
    this.showDeleteConfirmDialog$.next(true);
  }

  onDeleteTaskConfirm() {
    if (this.taskToDelete) {
      this._store.dispatch(new TaskDeleteAction(this.taskToDelete));
    }
    this.onCloseDeleteConfirmDialog();
  }

  onCloseDeleteConfirmDialog() {
    this.showDeleteConfirmDialog$.next(false);
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
    document.removeEventListener('drop', preventDrop);
  }

  isSwimlaneDragOveredByTask(group) {
    return (
      this.dragStartTask &&
      this.dragOverGroup &&
      !this.dragOverTask &&
      this.dragType === 'ddTask' &&
      this.dragOverGroup.value === group.value &&
      (this.dragStartTask.swimlane !== this.dragOverGroup.value || this.dragOverH3FromGroup)
    );
  }
}
