import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Version } from '../../../interfaces';
import { VersionDeleteAction, VersionEditAction } from '../../../ngrx/actions/version.actions';
import { AppState } from '../../../ngrx/state';
import { Store } from '@ngrx/store';
import { DateTimeFormatterService } from '../../../libs/date-time-formatter/services/date-time-formatter.service';
import * as moment from 'moment';
import { CompanyService } from '../../../shared/services/app/company.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'version-context-menu',
  templateUrl: './version-context-menu.component.html',
  styleUrls: ['./version-context-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VersionContextMenuComponent implements OnInit {
  @Input() version: Version;
  @Input() visibleMenuVersionId: number;

  @Output() onToggle = new EventEmitter();

  @HostListener('click', ['$event'])
  stopPropagation($event) {
    $event.stopPropagation();
  }

  @HostBinding('class.visible') isMenuHidden: boolean;

  public isDeleting: boolean;
  public isEditing: boolean;
  versionForm: FormGroup;

  constructor(
    private _store: Store<AppState>,
    private _dateTimeFormatter: DateTimeFormatterService,
    private _company: CompanyService,
    private _fb: FormBuilder
  ) {}

  ngOnInit() {
    this.versionForm = this._fb.group({
      plannedDate: [this.version.plannedDate ? moment.unix(this.version.plannedDate) : 0],
      versionName: [this.version.name, Validators.compose([Validators.required, Validators.maxLength(50)])]
    });
  }

  onToggleVersionMenu(event) {
    event.stopPropagation();
    this.onToggle.emit(this.version.id);
  }

  onEdit(event) {
    event.stopPropagation();
    this.isMenuHidden = true;
    this.isEditing = true;
  }

  onConfirmEdit() {
    const name = this.versionForm.get('versionName').value.trim();
    this.versionForm.get('versionName').patchValue(name);
    Object.keys(this.versionForm.controls).map(controlName => {
      this.versionForm.get(controlName).markAsDirty();
      this.versionForm.get(controlName).markAsTouched();
      this.versionForm.get(controlName).updateValueAndValidity();
    });
    if (!this.versionForm.valid) {
      return;
    }
    const date = this.versionForm.get('plannedDate').value;
    this._store.dispatch(
      new VersionEditAction({
        id: this.version.id,
        plannedDate: date ? date.unix() : 0,
        name: name
      })
    );
    this.isEditing = false;
    this.isMenuHidden = false;
    this.onToggle.emit(this.version.id);
  }

  onDeclineEdit() {
    this.isEditing = false;
    this.isMenuHidden = false;
    this.onToggle.emit(this.version.id);
  }

  onDelete(event) {
    event.stopPropagation();
    this.isMenuHidden = true;
    this.isDeleting = true;
  }

  onConfirmDelete() {
    this.isDeleting = false;
    this.isMenuHidden = false;
    this._store.dispatch(new VersionDeleteAction(this.version.id));
    this.onToggle.emit(this.version.id);
  }

  onDeclineDelete() {
    this.isDeleting = false;
    this.isMenuHidden = false;
    this.onToggle.emit(this.version.id);
  }
}
