<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row" *ngIf="!isMove">
    <label for="copy-column-name">
      Name
    </label>
    <input id="copy-column-name" type="text" formControlName="name">
  </div>

  <div class="row">
    <label for="copy-column-board">
      Board
    </label>
    <select id="copy-column-board" formControlName="targetBoard">
      <option *ngFor="let board of  targetBoards$ | async" [ngValue]="board.id">{{board.name}}</option>
    </select>
  </div>

  <div class="row">
    <label for="copy-column-position">
      Position
    </label>
    <select id="copy-column-position" formControlName="targetPosition">
      <option *ngFor="let position of  availablePositions$ | async" [ngValue]="position.value">{{position.name}}</option>
    </select>
  </div>

  <br>
  <button
    a-button
    class="btn_primary button"
    [pending]="_formService.isPending$ | async"
  >{{isMove ? 'Move' : 'Copy'}}
  </button>
  <button
    a-button
    type="button"
    (keydown)="onClose()"
    (click)="onClose()"
    class="btn_text"
  >
    Cancel
  </button>
</form>
