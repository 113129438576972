import * as moment from 'moment-mini-ts';

export const SEC_IN_WEEK = 144000;
export const SEC_IN_DAY = 28800;
export const SEC_IN_HOURS = 3600;
export const SEC_IN_MINUTS = 60;

// only digits (8) -> equivalent 8h
export const onlyDigitsRegExp = new RegExp('^\\d+$');

const parseDigits = (timeString: string): number => {
  const result = timeString.match(/\d+/);
  return +(result ? result[0] : false);
};

const getSecondsFromLine = (line: string) => {
  const week = line.match(/(\d+)w/);
  const day = line.match(/(\d+)d/);
  const hour = line.match(/(\d+)h/);
  const minute = line.match(/(\d+)m/);

  return (
    (week ? +week[1] * SEC_IN_WEEK : 0) +
    (day ? +day[1] * SEC_IN_DAY : 0) +
    (hour ? +hour[1] * SEC_IN_HOURS : 0) +
    (minute ? +minute[1] * SEC_IN_MINUTS : 0)
  );
};

export const formatTimeToSecond = (time: string): number => {
  const trimLeft = time.match(/\D*(\d.*)/);
  if (trimLeft && trimLeft[1]) {
    time = trimLeft[1];
  }

  const finalValue = getSecondsFromLine(time);
  if (finalValue) {
    return finalValue;
  }

  if (onlyDigitsRegExp.test(time.trim())) {
    return parseDigits(time.trim()) * SEC_IN_HOURS;
  }

  const factors = [SEC_IN_HOURS, SEC_IN_MINUTS];
  return time
    .trim()
    .split(/\D+/)
    .slice(0, 2)
    .map(x => parseDigits(x))
    .reduce((acc, d) => {
      return (acc += d * factors.shift());
    }, 0);
};

export const secondsToEdit = (seconds: number): string => {
  // force typecast to int
  seconds = +seconds;
  let min = Math.floor(seconds / 60);
  const h = Math.floor(min / 60);
  min = min % 60;
  return h + ':' + (min < 10 ? '0' : '') + min;
};

export const pickerFormat = moment => {
  return {
    date: {
      year: moment.year(),
      // momentjs month range is from 0 to 11
      month: moment.month() + 1,
      day: moment.date()
    },
    epoc: moment.startOf('day').unix()
  };
};

export const parseMomentFromPiker = pikerValue => {
  const date = pikerValue.date;
  return moment()
    .year(date.year)
    .month(date.month - 1)
    .date(date.day);
};

/**
 * IE 11+
 * @returns {string}
 */
export const getBrowsersTimeZoneName = () =>
  Intl && Intl.DateTimeFormat() && Intl.DateTimeFormat().resolvedOptions()
    ? Intl.DateTimeFormat().resolvedOptions().timeZone
    : undefined;
