import { Observable } from 'rxjs';

import { ChangeDetectionStrategy, Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import {
  DefaultTimeOnBoardFilterResetAction
} from '../../../ngrx/actions/task-filters/default-time-on-board-filter.actions';
import { getDefaultTimeOnBoardFilterDaysRange } from '../../../ngrx/reducers/task-filters/default-time-on-board-filter.reducer';
import { DaysRange } from "../../../ngrx/actions/task-filters/task-default-filter.actions";

@Component({
  selector: 'default-time-on-board-filter',
  templateUrl: './default-time-on-board-filter.component.html',
  styleUrls: ['./default-time-on-board-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultTimeOnBoardFilterComponent implements OnInit {
  @Input() boardId$: Observable<number>;

  public storeTimeOnBoardFilterValue$: Observable<DaysRange>;
  public showTimeOnBoardPopUp = false;

  constructor(private _store: Store<AppState>) {}

  ngOnInit(): void {
    this.storeTimeOnBoardFilterValue$ = this._store.pipe(getDefaultTimeOnBoardFilterDaysRange);
  }

  onShowTimeOnBoardPopup() {
    this.showTimeOnBoardPopUp = true;
  }

  onHideTimeOnBoardPopup() {
    this.showTimeOnBoardPopUp = false;
  }

  onResetFilter() {
    this._store.dispatch(new DefaultTimeOnBoardFilterResetAction());
  }
}
