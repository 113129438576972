import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { User } from '../../../../interfaces/user';
import { Store } from '@ngrx/store';
import { AppState, ESInterface } from '../../../../ngrx/state/app-state';
import { getEntitySelector } from '../../../../ngrx/functions/selectors';
import { USER_PL } from '../../../../constants/entity';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'user-input-picker',
  templateUrl: './user-input-picker.component.html',
  styleUrls: ['./user-input-picker.component.scss']
})
export class UserInputPickerComponent implements OnInit, OnDestroy {
  @Input() selectedUsers: BehaviorSubject<number[]>;

  public usersState: ESInterface<User>;

  public showAddPopUp = false;
  public subscription;
  public usersEntities: { [id: number]: User };

  constructor(private _store: Store<AppState>) {}

  ngOnInit(): any {
    this.subscription = this._store.pipe((getEntitySelector(USER_PL))).subscribe((state: ESInterface<User>) => {
      this.usersState = state;
      this.usersEntities = state.entities;
    });
  }

  ngOnDestroy(): any {
    this.subscription.unsubscribe();
  }

  get selectedUsers$() {
    return this.selectedUsers;
  }

  onRemoveMember(userId) {
    this.selectedUsers$.next(this.selectedUsers$.getValue().filter(existsUser => existsUser !== userId));
  }
}
