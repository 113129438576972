<div class="close_board_bg">
  <div class="content">
    <div class="content_done">
      <div class="ico_closed"></div>
      <div class="content_done_text">
        Board <span class="medium">“{{board.name}}”</span> is closed.
      </div>
    </div>

    <div class="content_button">
      <button a-button
              [pending]="runLoader"
              class="content_button_reopen"
              (click)="onReopenBoard()"
      >
        <span *ngIf="!runLoader">Reopen board</span>
      </button>
    </div>

  </div>
</div>

