import { Component } from '@angular/core';

@Component({
  selector: 'header-feature-icon-svg',
  template: `
	  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" enable-background="new 0 0 24 24" width="24px" height="24px">
		  <style>
			  .sts0{filter:url(#Adobe_OpacityMaskFilter);} .sts1{fill:#FFFFFF;} .sts2{mask:url(#mask-2);fill:#455A64;}
		  </style>
		  <defs>
			  <filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="5" y="2" width="14" height="20">
				  <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
			  </filter>
		  </defs>
		  <mask maskUnits="userSpaceOnUse" x="5" y="2" width="14" height="20" id="mask-2">
			  <g class="sts0">
				  <path id="path-1" class="sts1" d="M0 0h24v24H0z"/>
			  </g>
		  </mask>
		  <path id="Shape" class="sts2" d="M9 21c0 .5.4 1 1 1h4c.6 0 1-.5 1-1v-1H9v1zm3-19C8.1 2 5 5.1 5 9c0 2.4 1.2 4.5 3 5.7V17c0 .5.4 1 1 1h6c.6 0 1-.5 1-1v-2.3c1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7zm2.9 11.1l-.9.6V16h-4v-2.3l-.9-.6C7.8 12.2 7 10.6 7 9c0-2.8 2.2-5 5-5s5 2.2 5 5c0 1.6-.8 3.2-2.1 4.1z"/>
	  </svg>
  `
})
export class HeaderFeatureSvgComponent {}
