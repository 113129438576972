import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppState } from '../../../ngrx/state';
import { Store } from '@ngrx/store';
import { fromGuiState } from '../../../ngrx/reducers/gui-state-memorized.reducer';
import { Observable } from 'rxjs/Observable';
import { Task } from '../../../interfaces';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { fromTask } from '../../../ngrx/reducers/task.reducer';
import { of } from 'rxjs/internal/observable/of';
import { GuiStateQuickTaskEditHide } from '../../../ngrx/actions/gui-state-memorized.actions';
import { TaskEditAction } from '../../../ngrx/actions/task.actions';
import { BehaviorSubject, Subscription } from 'rxjs/index';

@Component({
  selector: 'quick-task-edit',
  templateUrl: './quick-task-edit.component.html',
  styleUrls: ['./quick-task-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickTaskEditComponent implements OnInit, OnDestroy {
  @Input() isNotGuest: boolean;
  @ViewChild('taskTitleTextArea') taskTitleTextArea: ElementRef;
  public editingTaskParams$: Observable<{ id: number; rect: ClientRect; activate?: string; isExactRect?: boolean }>;
  public editingTask$: Observable<Task | null>;
  public taskTitle = ''; // prevents title updating while user edits title
  public subs: Subscription[] = [];
  public initPosition = { top: 0, left: 0, width: 270, height: 86 };
  public texteareaPosition$;
  public textareaWidth$: Observable<string>;
  public checkTextareaSize$ = new BehaviorSubject(true);
  public contentMenuRight$: Observable<number>;
  public membersPopupOpener$: Observable<boolean>;
  public dueDatePopupOpener$: Observable<boolean>;

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this.editingTaskParams$ = this._store.select(fromGuiState.getQuickEditTaskParams);
    this.texteareaPosition$ = this.editingTaskParams$.pipe(
      map(params => {
        const rect = params && params.rect ? params.rect : this.initPosition;
        if (params && params.isExactRect) {
          return { top: rect.top, left: rect.left, width: rect.width };
        }
        return { top: rect.top ? rect.top - 5 : rect.top, left: (rect['right'] ? rect['right'] : 265) - 265 };
      })
    );
    this.textareaWidth$ = this.texteareaPosition$.pipe(map(position => position['width'] + 'px' || 'auto'));
    this.contentMenuRight$ = this.texteareaPosition$.pipe(
      map(
        rect =>
          rect['left'] + 435 > Math.max(document.documentElement.clientWidth, window.innerWidth || 0) ? 280 : -170
      )
    );
    this.editingTask$ = this.editingTaskParams$.pipe(
      switchMap(params => (params ? this._store.select(fromTask.taskById(params.id)) : of(null))),
      tap(task => {
        if (!task) {
          this.taskTitle = '';
        }
      })
    );
    this.subs.push(
      this.editingTask$.subscribe(task => {
        if (!this.taskTitle && task && task.title) {
          this.taskTitle = task.title;
        }
        setTimeout(() => {
          this.checkTextareaSize$.next(true);
        });
      })
    );
    this.membersPopupOpener$ = this.editingTaskParams$.pipe(
      filter(params => params && params.activate === 'members'),
      map(v => !!v)
    );
    this.dueDatePopupOpener$ = this.editingTaskParams$.pipe(
      filter(params => params && params.activate === 'due_date'),
      map(v => !!v)
    );
  }

  onCloseQuickEdit() {
    this.editingTask$.pipe(take(1)).subscribe(task => {
      if (task) {
        this._store.dispatch(new GuiStateQuickTaskEditHide(task.id));
      }
    });
  }

  onSaveTaskTitle(event?) {
    if (event) {
      event.stopPropagation();
    }
    this.editingTaskParams$.pipe(take(1)).subscribe(params => {
      const title = this.taskTitle.trim();
      if (title.length && title.length <= 1024) {
        this._store.dispatch(new TaskEditAction({ id: params.id, title: title }));
      }
      this.onCloseQuickEdit();
    });
  }

  onCloseMenuAction() {
    this.taskTitleTextArea && this.taskTitleTextArea.nativeElement && this.taskTitleTextArea.nativeElement.focus();
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
