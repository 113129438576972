
import {of as observableOf,  Observable ,  BehaviorSubject } from 'rxjs';

import {switchMap, map, catchError, filter, tap} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { TimesheetService } from '../timesheet.service';
import { Timesheet, TimesheetSpecialGroupIds, TimesheetSpecialProjectIds, TimesheetSpecialUserIds } from '../timesheet';
import { TimesheetFilter } from '../filter/timesheet-filter';
import { getAllProjects } from '../../ngrx/reducers/project.reducer';
import { filterNotDeletedUsers, getAllUsers } from '../../ngrx/reducers/user.reducer';
import { getAllGroups } from '../../ngrx/reducers/group.reducer';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/state/app-state';
import * as moment from 'moment-mini-ts';
import { Group, Project, User } from '../../interfaces';
import { Location } from '@angular/common';
import { AuthService } from '../../shared/services/app/auth.service';
import { SegmentService } from '../../atlaz-bnp/services/intergations/segment/segment.service';

@Component({
  selector: 'timesheet-container',
  templateUrl: './timesheet-container.component.html',
  styleUrls: ['./timesheet-container.component.scss']
})
export class TimesheetContainerComponent implements OnInit {
  public timesheet$: Observable<Timesheet>;
  public filter: TimesheetFilter;
  private filter$: BehaviorSubject<TimesheetFilter>;
  public projects$: Observable<Project[]>;
  public users$: Observable<User[]>;
  public groups$: Observable<Group[]>;
  public loading: boolean;
  public hideGroupFilter = false;
  public hideMemberFilter = false;
  public tableLogDataCurrentLeftScroll = 0;
  public tableLogDataInitialLeftScroll = 0;

  constructor(
    private _segment: SegmentService,
    private timesheetService: TimesheetService,
    private store: Store<AppState>,
    private location: Location,
    private authService: AuthService
  ) {
    this.filter = new TimesheetFilter();
    this.filter.toDate = moment()
      .endOf('day')
      .unix();
    this.filter.fromDate = moment()
      .subtract(1, 'months')
      .startOf('day')
      .unix();
    this.filter.projectId = TimesheetSpecialProjectIds.AllProjects;
    this.filter.userId = TimesheetSpecialUserIds.AllMembers;
    this.filter.groupId = TimesheetSpecialGroupIds.NoGroup;
    this.filter.showTasks = false;
    this.filter.showLogs = false;
    this.loading = false;
    this.filter$ = new BehaviorSubject<TimesheetFilter>(this.filter);
  }

  ngOnInit() {
    this._segment.track('TimesheetVisited');
    this.hideMemberFilter = this.authService.activeUser.role === 'employee';
    this.hideGroupFilter = this.authService.activeUser.role === 'employee';

    this.timesheet$ = this.filter$.pipe(
      tap(_ => (this.loading = true)),
      filter(filter => !!(filter.fromDate && filter.toDate)),
      switchMap(filter =>
        this.timesheetService
          .get(filter.fromDate, filter.toDate, filter.projectId, this.getUserIdsFromFilter(filter)).pipe(
          map(data => (data.days.size === 0 ? null : data)),
          catchError(() => observableOf(null)),
          tap(() => (this.loading = false)),)
      ),);

    this.projects$ = <Observable<Project[]>>this.store.pipe((getAllProjects));
    this.users$ = <Observable<User[]>>this.store.pipe((getAllUsers),(filterNotDeletedUsers),);
    this.groups$ = <Observable<Group[]>>this.store.pipe((getAllGroups));
  }

  onTableScrolled(leftScroll) {
    this.tableLogDataCurrentLeftScroll = leftScroll;
  }

  OnFilterChanged(filter: TimesheetFilter) {
    if (this.filter.toDate !== filter.toDate || this.filter.fromDate !== filter.fromDate) {
      this.tableLogDataInitialLeftScroll = 0;
    } else {
      this.tableLogDataInitialLeftScroll = this.tableLogDataCurrentLeftScroll;
    }

    this.filter = filter;
    this.filter$.next(this.filter);
  }

  OnBack() {
    this.location.back();
  }

  private getUserIdsFromFilter(filter: TimesheetFilter): number[] {
    if (
      filter.userId === TimesheetSpecialUserIds.AllMembers ||
      (Number.isInteger(filter.userId) === false && Number.isInteger(filter.groupId))
    ) {
      return filter.groupUsers;
    } else if (Number.isInteger(filter.userId)) {
      return [filter.userId];
    } else {
      return [];
    }
  }
}
