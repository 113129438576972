import { BehaviorSubject, combineLatest, Observable, of as observableOf } from 'rxjs';

import { filter, map, switchMap } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PaywallService } from '../paywall.service';
import { isPresent, toBoolean } from '../../../../helpers';
import { RouterNavigateService } from '../../../shared/services/router-navigate.service';
import { getCompanyBillingSubscription } from '@atlaz/billing/store/subscription/subscription.reducer';
import { AppState } from '../../../ngrx/state';
import { Store } from '@ngrx/store';
import { Features } from '../features.constants';
import { BillingPlanName } from '@atlaz/billing/constants/billing-subscription';
import { InitialDataEffects } from '../../../ngrx/effects/initial-data.effects';
import * as fromBillingSubscription from '@atlaz/billing/store/subscription/subscription.reducer';
import { fromBillingPlans } from '@atlaz/billing/store/plan/plan.reducer';
import { BillingPlan } from '@atlaz/billing/interfaces/billing-plan';
import { BillingSubscriptionEntity } from '@atlaz/billing/interfaces/billing-subscription';
import { AuthService } from '../../../shared/services/app/auth.service';
import { User } from '../../../interfaces';
import { PDF_EXPORT } from '../../../path.routing';

@Component({
  selector: 'paywall-layout',
  templateUrl: './paywall-layout.component.html',
  styleUrls: ['./paywall-layout.component.scss']
})
export class PaywallLayoutComponent implements OnInit, OnDestroy {
  public messages = {
    [Features.AdvancedPrioritization]: {
      title: 'Upgrade to use Weighted Scoring',
      subtitle: 'You need to upgrade your subscription for using Weighted Scoring.'
    },
    [Features.AdvancedSearch]: {
      title: 'Upgrade to use Advanced Search',
      subtitle: 'You need to upgrade your subscription to use Advanced Search.'
    },
    [Features.Backlog]: {
      title: 'Upgrade to use Backlog and Priority Matrix',
      subtitle: 'You need to upgrade your subscription to use Backlog and Priority Matrix.'
    },
    [Features.Bitbucket]: {
      title: 'Upgrade to use Bitbucket Integration',
      subtitle: 'You need to upgrade your subscription to use Bitbucket integration.'
    },
    [Features.BrandedWorkspace]: {
      title: 'Upgrade to have Branded Workspace',
      subtitle: 'You need to upgrade to Enterprise Plan for having Branded Workspace.'
    },
    [Features.CanAddMember]: {
      title: 'Upgrade to invite more members',
      subtitle: 'You need to upgrade your subscription to invite more members.'
    },
    [Features.CanAddTask]: {
      title: 'Upgrade to add more tasks',
      subtitle: 'You need to upgrade your subscription to add more tasks.'
    },
    [Features.CanChangeAuthenticationSettings]: {
      title: 'Upgrade to change authentication settings',
      subtitle: 'You need to upgrade your subscription to Enterprise to change authentication settings.'
    },
    [Features.CanUploadAttachment]: {
      title: 'Upgrade to upload more attachments',
      subtitle: 'You need to upgrade your subscription to upload more attachments.'
    },
    [Features.Dropbox]: {
      title: 'Upgrade to use Dropbox Integration',
      subtitle: 'You need to upgrade your subscription to use Dropbox integration.'
    },
    [Features.Github]: {
      title: 'Upgrade to use GitHub Integration',
      subtitle: 'You need to upgrade your subscription to use GitHub integration.'
    },
    [Features.Gitlab]: {
      title: 'Upgrade to use GitLab Integration',
      subtitle: 'You need to upgrade your subscription to use GitLab integration.'
    },
    [Features.GoogleDrive]: {
      title: 'Upgrade to use Google Drive Integration',
      subtitle: 'You need to upgrade your subscription to use Google Drive integration.'
    },
    [Features.Harvest]: {
      title: 'Upgrade to use Harvest Integration',
      subtitle: 'You need to upgrade your subscription to use Harvest integration.'
    },
    [Features.Ice]: {
      title: 'Upgrade to use ICE Scoring',
      subtitle: 'You need to upgrade your subscription for using ICE Scoring.'
    },
    [Features.Rice]: {
      title: 'Upgrade to use RICE Scoring',
      subtitle: 'You need to upgrade your subscription for using RICE Scoring.'
    },
    [Features.ICalendar]: {
      title: 'Upgrade to use Calendar Sync',
      subtitle: 'You need to upgrade your subscription to use Calendar sync.'
    },

    [Features.MultipleProjects]: {
      title: 'Upgrade to assign Multiple Projects per board',
      subtitle: 'You need to upgrade to Enterprise Plan for assigning Multiple Projects per board.'
    },
    [Features.PriorityChart]: {
      title: 'Upgrade to use Backlog and Priority Matrix',
      subtitle: 'You need to upgrade your subscription to use Backlog and Priority Matrix.'
    },
    [Features.Roadmap]: {
      title: 'Upgrade to use Roadmapping',
      subtitle: 'You need to upgrade your subscription to use Roadmapping.'
    },
    [Features.SprintsHistory]: {
      title: 'Upgrade to use Sprints History',
      subtitle: 'You need to upgrade your subscription to use Sprints History.'
    },
    [Features.Slack]: {
      title: 'Upgrade to use Slack Integration',
      subtitle: 'You need to upgrade your subscription to use Slack integration.'
    },
    [Features.TimesheetReport]: {
      title: 'Upgrade to use Timesheet Reporting',
      subtitle: 'You need to upgrade to Enterprise Plan for using Timesheet Reporting.'
    },
    [Features.SprintsVelocityChart]: {
      title: 'Upgrade to use Sprints Velocity Chart',
      subtitle: 'You need to upgrade your subscription to use Sprints Velocity Chart.'
    },
    ['default']: {
      title: 'Upgrade to Enterprise Plan',
      subtitle: 'Upgrade your subscription to unlock all the Enterprise Plan features.'
    }
  };
  public visible$ = this._initEffect.initialized$.pipe(
    switchMap((x: boolean) => (x ? this._paywall.visiblePayWall$.pipe(map(toBoolean)) : observableOf(false)))
  );
  public layout$ = this._paywall.visiblePayWall$;

  public title$ = this.layout$.pipe(map(feature => (this.messages[feature] || this.messages['default']).title));
  public subtitle$ = this.layout$.pipe(map(feature => (this.messages[feature] || this.messages['default']).subtitle));
  public isUnpaidSubscription$: Observable<boolean>;

  public plans$: Observable<{ [planName: string]: BillingPlan }>;
  public billingSub$: Observable<BillingSubscriptionEntity>;
  public activeUser$: Observable<User> = this._authService.activeUser$;
  public welcomeBackVisible$ = this._paywall.welcomeBackPayWall$.pipe(
    switchMap(
      (v: boolean) =>
        v
          ? this.plans$.pipe(map(plans => plans[BillingPlanName.Premium] && plans[BillingPlanName.Basic]))
          : observableOf(false)
    )
  );
  public oneHourTimer = new BehaviorSubject(true);
  private _oneOurInterval;

  public subtitle;
  constructor(
    private _authService: AuthService,
    private _paywall: PaywallService,
    private _routerNav: RouterNavigateService,
    private _initEffect: InitialDataEffects,
    private _store: Store<AppState>
  ) {}

  ngOnInit() {
    this._oneOurInterval = setInterval(() => {
      this.oneHourTimer.next(true);
    }, 3600000);
    this.isUnpaidSubscription$ = this._store
      .select(getCompanyBillingSubscription)
      .pipe(filter(isPresent), map(item => item.plan === BillingPlanName.Unpaid));

    this.plans$ = this._store.select(fromBillingPlans.getAll);

    this.billingSub$ = this._store
      .select(fromBillingSubscription.getCompanyBillingSubscription)
      .pipe(filter(isPresent));

    combineLatest(this._authService.activeUser$, this.billingSub$, this.oneHourTimer).subscribe(([user, sub]) => {
      const isPdfExport = window.location.href.includes(PDF_EXPORT + '/');
      if (
        !isPdfExport &&
        sub &&
        (sub.plan === 'UNPAID' || sub.isTrial) &&
        user &&
        (user.role === 'super_owner' || user.role === 'owner') &&
        +localStorage.getItem(user.id + ':lastShowWelcomeBack') + 86400000 < Date.now()
      ) {
        localStorage.setItem(user.id + ':lastShowWelcomeBack', Date.now() + '');
        this._paywall.showWelcomeBack();
      }
    });
  }

  toPaymentDetails(event: { isMonthly: boolean; plan: BillingPlanName }) {
    this._routerNav.navigateEditPaymentDetails(event.isMonthly, event.plan).then(() => {
      this._paywall.hide();
    });
  }
  goBack() {
    this._paywall.hideAndGoBack();
  }

  ngOnDestroy() {
    clearInterval(this._oneOurInterval);
  }
}
