
import {never as observableNever, of as observableOf,  Observable ,  BehaviorSubject } from 'rxjs';

import {pluck, take, debounceTime, switchMapTo, filter, combineLatest, switchMap, map, distinctUntilChanged} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../../../shared/services/app/auth.service';
import { CompanyService } from '../../../../shared/services/app/company.service';
import { isPresent } from '../../../../../helpers/object';
import { USER_COMPANY_PL } from '../../../../constants/entity';
import { AppState, entityStateToArray } from '../../../../ngrx/state/app-state';
import { UsersCompany } from '../../../../interfaces/users-compnay';
import { Store } from '@ngrx/store';
import { BillingPublicApiService } from '@atlaz/billing/shared/billing-public-api.service';
import { environment } from '../../../../../environments/environment';
import { User } from '../../../../interfaces/user';
import { Company } from '../../../../interfaces/company';
import * as moment from 'moment-mini-ts';

@Injectable()
export class IntercomDummyService {
  protected isLauncherDisabled$ = new BehaviorSubject(false);
  protected isVisibleIcon$ = new BehaviorSubject(false);
  private intercomContainerStyles;

  private roles$ = this._authService.activeUserId$.pipe(
    switchMap(id =>
      this._store.pipe(
        pluck(USER_COMPANY_PL),
        distinctUntilChanged(),
        map(entityStateToArray),
        map((list: UsersCompany[]) => list.filter((uc: UsersCompany) => uc.user === id)),
        map((list: UsersCompany[]) =>
          Object.keys(
            list.reduce((acc, x) => {
              acc[x.role] = true;
              return acc;
            }, {})
          )
        ),)
    ),
    map(roles => roles.join('; ')),
    distinctUntilChanged(),);

  private trialEnds$ = this._billing.hasAccess$.pipe(
    switchMap(hasAccess => (hasAccess ? this._billing.billingSubscription$.pipe(pluck('trialEnd')) : observableOf(0))),
    distinctUntilChanged(),);

  public userInfoStream$ = this._authService.isLoggedIn$.pipe(
    debounceTime(0),
    switchMapTo(this._authService.activeUser$),
    filter(isPresent),
    combineLatest(
      this._companyService.currentCompany$.pipe(filter(isPresent)),
      this.trialEnds$,
      this.roles$,
      (user: User, company: Company, endTrial: number, roles: string) => {
        let resultCompany = {
          id: company.id,
          name: company.name,
          domain: company.domain,
          created_at: company.createdAt
        };
        if (endTrial) {
          /**
           * we convert endTrial in GMT +3 to our support team can see an correct human readable date
           */
          resultCompany['trialEnds'] = moment
            .unix(endTrial)
            .add({ h: 3 })
            .format('ll');
        }
        if (company.isDemo) {
          resultCompany = undefined;
        }
        return {
          app_id: environment.intercomAppId,
          id: user.id,
          user_id: user.id,
          name: user.fullname,
          email: user.email,
          buildId: environment.buildInfo.id,
          created_at: user.createdAt,
          roles: roles,
          company: resultCompany
        };
      }
    ),);

  constructor(
    private _router: Router,
    protected _authService: AuthService,
    private _store: Store<AppState>,
    private _billing: BillingPublicApiService,
    protected _companyService: CompanyService
  ) {
    this._router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.fireUrlHasBeenChanged();
      }
    });
  }

  get intercom() {
    return (event: string, data?: any) => console.log('Intercom', event, data);
  }

  getUserInfo(eventName, userData: any) {
    this.intercom(eventName, userData);
  }

  /**
   * .debounceTime(500) is required so that we instantly wouldn't disable and instantly reenable the Intercom for pages which requires the Intercom
   * in other word we should disable & reenable the Intercom while switching between pages which are both requires the Intercom
   */
  init() {
    console.log('Intercom init');
    this.isLauncherDisabled$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(disabled => (disabled ? observableNever() : this.userInfoStream$.pipe(take(1)))),)
      .subscribe(this.getUserInfo.bind(this, 'update'));

    this.isLauncherDisabled$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      filter(isPresent),)
      .subscribe(() => this.intercom('shutdown'));

    this.isVisibleIcon$.pipe(
      debounceTime(500),
      distinctUntilChanged(),)
      .subscribe(visible => {
        if (!visible) {
          const css = `div#intercom-container {display: none!important}`,
            head = document.head || document.getElementsByTagName('head')[0],
            style = document.createElement('style');

          style.type = 'text/css';
          if (style['styleSheet']) {
            style['styleSheet'].cssText = css;
          } else {
            style.appendChild(document.createTextNode(css));
          }

          head.appendChild(style);
          this.intercomContainerStyles = style;
          console.log(`Intercom: Icon has been hidden`);
        } else {
          if (this.intercomContainerStyles && this.intercomContainerStyles.parentNode) {
            this.intercomContainerStyles.parentNode.removeChild(this.intercomContainerStyles);
          }
          console.log(`Intercom: Icon has been shown`);
        }
      });
  }

  fireUrlHasBeenChanged() {
    this.intercom('update');
  }

  disable() {
    console.log('Intercom: Disable');
    this.isLauncherDisabled$.next(true);
  }

  enable() {
    console.log('Intercom: Enable');
    this.isLauncherDisabled$.next(false);
  }

  hideChatIcon() {
    console.log('Intercom: Hide Icon');
    this.isVisibleIcon$.next(false);
  }

  showChatIcon() {
    console.log('Intercom: Show Icon');
    this.isVisibleIcon$.next(true);
  }
}
