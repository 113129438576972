import { label } from '../functions/util';
import { Action } from './unsafe-action';
import { State, WebSocketAnswer, WebSocketDeleteAnswer } from '../reducers/data-sync.reducer';
import { Model } from '../../shared/services/app/models';

export const DataSyncActionTypes = {
  LOAD_DIFF: label('[DATA_SYNC] LOAD_DIFF'),
  SET_LAST_UPDATED_TIME: label('[DATA_SYNC] SET_TIME'),
  UPDATE_CONNECTION_STATUS: label('[DATA_SYNC] UPDATE_CONNECTION_STATUS'),
  OBJECT_STATE: label('[DATA_SYNC] OBJECT_STATE'),
  OBJECT_DELETE: label('[DATA_SYNC] OBJECT_DELETE'),
  QUEUE_JOB: label('[DATA_SYNC] QUEUE_JOB')
};

export class DataSyncLoadDiffAction implements Action {
  type = DataSyncActionTypes.LOAD_DIFF;

  constructor(public payload: { time: number }) {}
}

export class DataSyncSetLastUpdatedTimeAction implements Action {
  type = DataSyncActionTypes.SET_LAST_UPDATED_TIME;

  constructor(public payload: { time: number }) {}
}

export class DataSyncUpdateConnectionStatus implements Action {
  type = DataSyncActionTypes.UPDATE_CONNECTION_STATUS;

  constructor(public payload: Partial<State>) {}
}

export class DataSyncObjectState implements Action {
  type = DataSyncActionTypes.OBJECT_STATE;

  constructor(public payload: Model<any>[]) {}
}

export class DataSyncObjectDelete implements Action {
  type = DataSyncActionTypes.OBJECT_DELETE;

  constructor(public payload: WebSocketDeleteAnswer[]) {}
}

export class DataSyncObjectQueueJob implements Action {
  type = DataSyncActionTypes.QUEUE_JOB;

  constructor(public payload: WebSocketAnswer[]) {}
}
