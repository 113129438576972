import { Component } from '@angular/core';

@Component({
  selector: 'a-no-connection',
  templateUrl: './a-no-connection.component.html',
  styleUrls: ['../40x-page.component.scss']
})
export class NoConnectionComponent {
  constructor() {}
}
