<div a-popup [title]="'Markdown Formatting'" (close)="onClose()">
  <div class="guide-inner">
    <section>
      <h4 class="section-heading">Line Break</h4>
      <div class="content">
        <div class="source">
          <p>
            Two spaces at the end of a line leave a⋅⋅<br>
            line break.
          </p>
        </div>
        <div class="result">
          <p>
            Two spaces at the end of a line leave a<br>
            line break.
          </p>
        </div>
      </div>
    </section>
    <section>
      <h4 class="section-heading">Heading</h4>
      <div class="content">
        <div class="source">
          # First level<br>
          ## Second level<br>
          ### Third level
        </div>
        <div class="result">
          <h1>First level</h1>
          <h2>Second level</h2>
          <h3>Third level</h3>
        </div>
      </div>
    </section>
    <section>
      <h4 class="section-heading">Bold Text</h4>
      <div class="content">
        <div class="source">
          **Bold**
        </div>
        <div class="result">
          <strong>Bold</strong>
        </div>
      </div>
    </section>
    <section>
      <h4 class="section-heading">Italic Text</h4>
      <div class="content">
        <div class="source">
          *Italic*<br>
          _Italic_
        </div>
        <div class="result">
          <em>Italic</em>
        </div>
      </div>
    </section>
    <section>
      <h4 class="section-heading">Strikethrough</h4>
      <div class="content">
        <div class="source">
          ~~Scratch this~~
        </div>
        <div class="result">
          <del>Scratch this</del>
        </div>
      </div>
    </section>
    <section>
      <h4 class="section-heading">Inline Code</h4>
      <div class="content">
        <div class="source">
          `Inline Code`
        </div>
        <div class="result">
          <markdown>
            <code>i++</code>
          </markdown>
        </div>
      </div>
    </section>
    <section>
      <h4 class="section-heading">Code Paragraph</h4>
      <div class="content">
        <div class="source">
          ```javascript<br>
          m = "# Markdown";<br>
          return 0;<br>
          ```
        </div>
        <div class="result">
          <markdown>
            ```javascript
            m = "# Markdown";
            return 0;
            ```
          </markdown>
        </div>
      </div>
    </section>
    <section>
      <h4 class="section-heading">Named Link</h4>
      <div class="content">
        <div class="source">
          [Link](http://hygger.io)
        </div>
        <div class="result">
          <a href="http://hygger.io">Link</a>
        </div>
      </div>
    </section>
    <section>
      <h4 class="section-heading">Image</h4>
      <div class="content">
        <div class="source">
          ![alt text](https://
          hygger.io/img/rocket.png "Title Text 1")
        </div>
        <div class="result">
          <img src="https://hygger.io/img/rocket.png" alt="alt text" title="Title Text 1">
        </div>
      </div>
    </section>
    <section>
      <h4 class="section-heading">Block Quotes</h4>
      <div class="content">
        <div class="source">
          > This is a very long line that will still be quoted properly when it wraps.
        </div>
        <div class="result">
          <markdown>
            > This is a very long line that will still be quoted properly when it wraps.
          </markdown>
        </div>
      </div>
    </section>
    <section>
      <h4 class="section-heading">Numbered List</h4>
      <div class="content">
        <div class="source">
          1. First ordered list item<br>
          2. Another item<br>
          ⋅⋅* Unordered sub-list.<br>
          1. Actual numbers don't matter, just that it's a number<br>
          ⋅⋅1. Ordered sub-list
        </div>
        <div class="result">
          <ol>
            <li>First ordered list item</li>
            <li>Another item
              <ul>
                <li>Unordered sub-list.</li>
              </ul>
            </li>
            <li>Actual numbers don't matter, just that it's a number
              <ol>
                <li>Ordered sub-list</li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </section>
    <section>
      <h4 class="section-heading">Numbered List</h4>
      <div class="content">
        <div class="source">
          * Unordered list can use asterisks<br>
          - Or minuses<br>
          + Or pluses<br>
        </div>
        <div class="result">
          <ul>
            <li>Unordered list can use asterisks</li>
            <li>Or minuses</li>
            <li>Or pluses</li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</div>
