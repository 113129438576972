import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Notification } from '../../../../../../interfaces/notification';
import { AppUrls } from '../../../../../../app-urls';

@Component({
  selector: 'board-notification-preview',
  templateUrl: './board-notification-preview.component.html',
  styleUrls: ['./board-notification-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoardNotificationPreviewComponent {
  @Input() notification: Notification;

  get url() {
    return AppUrls.getUrlBoard(this.notification.entityId);
  }
}
