<div *ngIf="(taskActivity.type === 'activity') || (taskActivity.type === 'comment')"
     class="{{taskActivity.type}}" [class.reply]="taskActivity.parent"
>
  <div class="avatar_block">
    <div *ngIf="externalUser$ | async;else avatar" class="intercom_logo"></div>
  </div>
  <p *ngIf="isComment" class="user_name">{{visibleName$ | async}}</p>
  <span class="comment_date" *ngIf="isComment">
      {{taskActivity.createdAt | timeAgo}}
  </span>

  <div class="comment_text" *ngIf="!isEditForm">
    <span [ngSwitch]="isComment">
      <markdown-smart-task-link *ngSwitchCase="true" [data]="taskActivity.preparedText| textToHtml | markdownBreak | markdownMention">
        <smart-text [taskLinksOnly]="true" [text]="taskActivity.preparedText | textToHtml"></smart-text>
      </markdown-smart-task-link>
      <smart-text *ngSwitchCase="false" [text]="taskActivity.preparedText | textToHtml"></smart-text>
    </span>

    <div *ngIf="editPermissions" class="activity_comment_edit">
      <span *ngIf="isComment && activeUserId === taskActivity.user" class="edit_link"
            (click)="isEditForm = true">Edit</span>
      <span *ngIf="isComment && activeUserId === taskActivity.user" class="delete_link"
            (click)="onToggleDeleteComment()">
        Delete
      </span>
      <div a-context-popup
           [title]="'Delete Comment'"
           *ngIf="isDeleteCommentVisible"
           (close)="onToggleDeleteComment()"
           class="delete_comment_confirm"
      >
        <p class="delete_message">The changes can not be reverted.</p>
        <div a-button class="btn_warn" (click)="onDeleteComment()">Delete</div>
        <div a-button (click)="onToggleDeleteComment()" class="btn_secondary">Cancel</div>
      </div>
      <span *ngIf="isComment" class="replay_link" (click)="onClickReply()">Reply</span>
      <span *ngIf="isComment && canReplayAll" class="replay_link" (click)="onClickReplyALL()">Reply All</span>
    </div>

    <span class="activity_date" *ngIf="taskActivity.type === 'activity'">
      {{taskActivity.createdAt | timeAgo}}
    </span>
  </div>

  <div *ngIf="isUnsavedComment && (!isEditForm && !isReplyForm)" class="unsaved_data">
     <span (click)="isEditForm = true">View Edits</span> - <span (click)="onDiscardUnsavedEditComment()">Discard</span>
  </div>

  <!-- edit comment form-->
  <comment-form class="edit_form"
                *ngIf="isEditForm"
                [taskComment]="taskActivity"
                [taskId]="taskActivity.task"
                [isEditForm]="isEditForm"
                [mentions]="boardUsers"
                (submitForm)="onSaveComment($event); isEditForm = false;"
                (cancel)="onClickOut()"
                [clickOut]="isEditForm"
                (close)="onClickOut()"
                (clickOutEvent)="onClickOut()"
                (resetFocus)="onChildChangedFocus($event)"
  ></comment-form>

  <!-- reply comment form-->
  <comment-form class="reply_form"
                *ngIf="isReplyForm"
                [taskReply]="taskActivity"
                [isReplyAll]="isReplyAllForm"
                [taskId]="taskActivity.task"
                [commentUser]="taskActivity.externalUser ? null : (commentUser$ | async)"
                [mentions]="boardUsers"
                (submitForm)="onSaveComment($event); isReplyForm= false;"
                (cancel)="onCancelReplyForm()"
                [clickOut]="isReplyForm"
                (clickOutEvent)="isReplyForm = false;"
                (resetFocus)="onChildChangedFocus($event)"
  ></comment-form>

  <!-- replays -->
  <div *ngIf="isComment">
    <div *ngFor="let replay of replays" [scrollToElement]="replay.id | commentUrlPipe">
      <activity-detail
        [taskActivity]="replay"
        [boardUsers]="boardUsers"
        [editPermissions]="editPermissions"
        (saveComment)="emitSaveComment($event)"
        (deleteComment)="emitDeleteComment($event)"
        (resetFocus)="onChildChangedFocus($event)"
      ></activity-detail>
    </div>
  </div>
</div>

<ng-template #avatar>
  <a-avatar
    [user]="commentUser$ | async"
  ></a-avatar>
</ng-template>
