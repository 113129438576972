import { Company } from '../app/interfaces';

export const DEFAULT_COMPANY: { [env: string]: Partial<Company> } = {
  dev: {
    object: 'company',
    id: 30,
    name: 'test',
    domain: 'test',
    createdAt: 0,
    employeesCount: 2,
    projectsCount: 1,
    boardsCount: 0,
    collectionsCount: 0,
    isActive: 1,
    owner: 10
  },

  stage: {
    object: 'company',
    id: 30,
    name: 'test',
    domain: 'test',
    createdAt: 0,
    employeesCount: 2,
    projectsCount: 1,
    boardsCount: 0,
    collectionsCount: 0,
    isActive: 1,
    owner: 10
  },

  prod: {
    object: 'company',
    id: 22,
    name: 'test',
    domain: 'test',
    created_at: 1500896805,
    isActive: 1,
    employeesCount: 48
  },

  ds: {
    id: 399,
    name: 'DS',
    domain: 'ds',
    created_at: 1513329162,
    isActive: 1,
    employeesCount: 1
  },

  ds3: {
    id: 1018,
    name: 'ds3',
    domain: 'ds3',
    created_at: 1570268633,
    isActive: 1,
    employeesCount: 2
  },

  testx1: {
    id: 1006,
    name: 'testx1',
    domain: 'testx1',
    created_at: 1560324288,
    isActive: 1,
    employeesCount: 3
  },

  dc: {
    id: 411,
    name: 'DC',
    domain: 'dc',
    created_at: 1513329162,
    isActive: 1,
    employeesCount: 1
  }
};
