<page-with-permissions-wrapper>
  <top-menu-container heightBind (heightChanged)="onHeightChanged($event)" #topMenu>
    <div class="header">
      <!--<div class="arrow_back" *ngIf="!!(boardId$ | async)" [routerLink]="appUrls.getUrlBoard(boardId$ | async)"></div>-->
      <p class="board_name">{{boardName$ | async}}</p>
      <!-- Zoom and move functionality disabled -->
      <!--<div class="zoom_buttons">-->
        <!--<button #zoomButton class="ico_zoom_out" [disabled]="backlogChartComp?.disabledZoomOut$ | async" (click)="onZoomClick(-1)"></button>-->
        <!--<button #zoomButton class="ico_zoom_in" [disabled]="backlogChartComp?.disabledZoomIn$ | async" (click)="onZoomClick(+1)"></button>-->
        <!--<button class="ico_reset" #zoomButton (click)="onResetZoom()"></button>-->
      <!--</div>-->
      <div style="visibility: hidden;" class="legend_link" [ngClass]="{'hovered': isShowHelpText}" (click)="onToggleHelp()">
        <span class="icon_quote"></span>
        How to Read this Chart?
        <span class="icon_expand" [ngClass]="{'expanded': isShowHelpText}"></span>
      </div>
      <div class="right_menu" *ngIf="isNotGuest$ | async" (click)="onToggleRightMenu()">Menu</div>
    </div>
    <board-quick-filters [boardInfo$]="boardInfo$"></board-quick-filters>
    <div class="legend_body" [ngClass]="{'expanded': isShowHelpText}">
      <p><strong>Quick Wins</strong> – ideas with a really high value and low efforts. These are the low-hanging fruits that should be worked on immediately. The earlier you finish the earlier you will get results.</p>
      <p><strong>Big Bets</strong> – ideas with high value and efforts. You may do them in the second place. They will take more working time than Quick Wins but they will bring the same profit. Try to break down these features into smaller, bite-sized tasks and remap them against other features.</p>
      <p><strong>Maybes</strong> – these features offer lower value for customers but are easy to implement. They are great fillers which can be slotted in when there are small amounts of downtime, or between larger features. It’s also a great way to ensure continued momentum.</p>
      <p><strong>Time Sinks</strong> –  ideas with low value but significant effort to deliver. Whilst still offering some benefits to the customer these shouldn’t be prioritised at this point in time.</p>
    </div>
    <right-menu-root id="right_menu_root" *ngIf="board$ | async" [board]="board$ | async"></right-menu-root>
  </top-menu-container>
  <div class="chart_global_container" [style.top.px]="containerTop">
    <div class="task_list_container">

      <div class="filters_header">
        <div class="filter_order">
          <div class="filter_order_type">
            <p (click)="onToggleFilterOrderMenu()">{{(sortType$ | async).value}}</p>
            <div a-context-menu-legasy *ngIf="isFilterOrderVisible" class="orders_dropdown"
                 (close)="onToggleFilterOrderMenu()"
            >
              <p *ngFor="let order of (sortOrderTypes$ | async)" (click)="onSortOnOrder(order)">{{order.value}}</p>
            </div>
          </div>
        </div>
        <div class="search_container">
          <input placeholder="Search Idea" (keydown.esc)="onResetSearch(seach_task)" #seach_task (keyup)="0">
          <div *ngIf="seach_task.value" class="search_reset" (click)="onResetSearch(seach_task)" ></div>
        </div>
      </div>
      <div class="items_container_header">
        <div class="task">Task</div>
        <div class="value">{{scoreYLabel$ | async}}</div>
        <div class="effort">{{scoreXLabel$ | async}}</div>
      </div>
      <div *ngIf="(isNotGuest$ | async);else guestList"
           class="items_container"
           [d3DragDrop]="listItemDragProps"
           (d3DragStart)="onDragStart($event)"
           (d3Drag)="onDrag($event)"
           (d3DragEnd)="onDragEnd($event)"
      >
        <div *ngIf="(boardTasks$ | async).length === 0" class="empty_list_message">No ideas to show</div>
        <task-list-item
          *ngFor="let task of boardTasks$ | async | searchByField: 'title': seach_task.value; trackBy: trackById"
          [task]="task"
          [scaleTypeX]="scoreXType$ | async"
          [scaleTypeY]="scoreYType$ | async"
        >
        </task-list-item>
      </div>

      <div *ngIf="isNotGuest$ | async" class="add_task_container">
        <p (click)="onToggleAddTask()" class="add_task_btn">
          <span>+</span>
          Add Task</p>
      </div>
    </div>

    <div class="chart_container">
      <backlog-add-task-popup
        *ngIf="isAddTaskVisible"
        (closeAddTask)="onToggleAddTask()"
        [backlogScoreX]="formbacklogScoreX"
        [backlogScoreY]="formbacklogScoreY"
        [boardId]="boardId$ | async"
        [source]="'BacklogChartTaskCreated'"
      ></backlog-add-task-popup>
      <backlog-chart [tasks]="scoredTasks$ | async"
                     [isNotGuest]="isNotGuest$ | async"
                     [zoomButtons]="getZoomButtons"
                     [groupedTasks]="groupByEffortValue$ | async"
                     [board$]="board$"
                     [axisXLabel]="(boardInfo$ | async).backlogScoreXLabel"
                     [axisYLabel]="(boardInfo$ | async).backlogScoreYLabel"
                     [axisXType]="scoreXType$ | async"
                     [axisYType]="scoreYType$ | async"
                     (updateTask)="onUpdateTask($event)"
      ></backlog-chart>
    </div>

    <div *ngIf="(isVisibleTooltip$ | async) && (isGroupedTooltip$ | async)"
         (mouseenter)="onCloseDialog()" class="tooltip_overlay"
    ></div>

    <div *ngIf="(isVisibleTooltip$ | async) && (isGroupedTooltip$ | async)"
         [ngStyle]="{
           position: 'fixed',
           top: (_backlogChart.groupTaskDialog$ | async).rect.top - 2 + 'px',
           left: (_backlogChart.groupTaskDialog$ | async).rect.left + 'px',
           width: (_backlogChart.groupTaskDialog$ | async).rect.width + 'px',
           height: (_backlogChart.groupTaskDialog$ | async).rect.height + 4 + 'px',
           background: 'transparent',
           'cursor': 'pointer',
           'z-index': 1
          }"
    ></div>

    <div *ngIf="isVisibleTooltip$ | async"
         chart-tooltip
         [topPx]="windowHeight < (_backlogChart.groupTaskDialog$ | async).rect.bottom + 370 ? undefined : ((_backlogChart.groupTaskDialog$ | async).rect.bottom + 2)"
         [bottomPx]="windowHeight > (_backlogChart.groupTaskDialog$ | async).rect.bottom + 370 ? undefined : (windowHeight - (_backlogChart.groupTaskDialog$ | async).rect.top + 2)"
         [leftPx]="windowWidth < 240 + (_backlogChart.groupTaskDialog$ | async).rect.left ? (_backlogChart.groupTaskDialog$ | async).rect.right - 240 : (_backlogChart.groupTaskDialog$ | async).rect.left"
         [ngStyle]="{
           'position': 'absolute',
           'cursor': 'pointer',
           'z-index': 1
         }"
    >
      <div class="tooltip_container" *ngIf="(isNotGuest$ | async);else guestTooltip">
        <div class="tooltip_header"></div>
        <div class="group_dialog_task_list">
          <div *ngFor="let task of tasksForTooltip$ | async; let i = index;"
               [d3DragDrop]="listItemDragProps"
               (d3DragStart)="onDragStart($event)"
               (d3Drag)="onDrag($event)"
               (d3DragEnd)="onDragEnd($event)"
          >
            <div *ngIf="i === 0" class="tooltip_group_score">
              <p>{{(boardInfo$ | async)?.backlogScoreYLabel}}: {{getVisibleTaskAxisScore(task.backlogScoreY, axisYType)}}</p>
              <p>{{(boardInfo$ | async)?.backlogScoreXLabel}}: {{getVisibleTaskAxisScore(task.backlogScoreX, axisXType)}}</p>
            </div>
            <task-list-item [task]="task"
                            [draggable]="isGroupedTooltip$ | async"
                            [itemPoints]="true"
                            [scaleTypeX]="scoreXType$ | async"
                            [scaleTypeY]="scoreYType$ | async"
            >
            </task-list-item>
          </div>
        </div>
        <div class="tooltip_bottom" *ngIf="(isGroupedTooltip$ | async)">
          <p (click)="onToggleAddTask()" class="add_task_btn">
            <span>+</span>
            Add Task</p>
        </div>
      </div>
    </div>

    <task-drag-tooltip
      [axisXLabel]="(boardInfo$ | async).backlogScoreXLabel"
      [axisYLabel]="(boardInfo$ | async).backlogScoreYLabel"
      [axisXType]="scoreXType$ | async"
      [axisYType]="scoreYType$ | async"
    ></task-drag-tooltip>
  </div>
</page-with-permissions-wrapper>


<ng-template #guestList>
  <div class="items_container">
    <div *ngIf="(boardTasks$ | async).length === 0" class="empty_list_message">No ideas to show</div>
    <task-list-item
      *ngFor="let task of boardTasks$ | async | searchByField: 'title': seach_task.value; trackBy: trackById"
      [draggable]="false"
      [task]="task"
      [scaleTypeX]="scoreXType$ | async"
      [scaleTypeY]="scoreYType$ | async"
    >
    </task-list-item>
  </div>
</ng-template>

<ng-template #guestTooltip>
  <div class="tooltip_container">
    <div class="tooltip_header"></div>
    <div class="group_dialog_task_list">
      <div *ngFor="let task of tasksForTooltip$ | async; let i = index;">
        <div *ngIf="i === 0" class="tooltip_group_score">
          <p>{{(boardInfo$ | async)?.backlogScoreYLabel}}: {{getVisibleTaskAxisScore(task.backlogScoreY, axisYType)}}</p>
          <p>{{(boardInfo$ | async)?.backlogScoreXLabel}}: {{getVisibleTaskAxisScore(task.backlogScoreX, axisXType)}}</p>
        </div>
        <task-list-item [task]="task"
                        [draggable]="false"
                        [itemPoints]="true"
                        [scaleTypeX]="scoreXType$ | async"
                        [scaleTypeY]="scoreYType$ | async"
        >
        </task-list-item>
      </div>
    </div>
  </div>
</ng-template>
