import { FormControl, ValidatorFn } from '@angular/forms';

const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidEmail = (email: string) => (email || '').match(emailRegExp);

export class EmailValidator {
  public static emailIsValid(control: FormControl): { [s: string]: boolean } {
    if (!control.value.match(emailRegExp)) {
      return {
        valid: true
      };
    }
  }
}

export function validateEmailFactory(): ValidatorFn {
  return (control: FormControl) => {
    if (!emailRegExp.test(control.value)) {
      return {
        EmailValidator: true
      };
    }
  };
}
