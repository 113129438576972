import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../../shared/services/form-v2.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Board } from '../../../interfaces';
import { HandleResponseAction } from '../../../ngrx/actions/root.action';
import { BehaviorSubject, Observer } from 'rxjs/index';
import { defaultExpand, TASK_PL } from '../../../constants';
import { AppState } from '../../../ngrx/state';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { PaywallService } from '../../../libs/paywall/paywall.service';
import { Features } from '../../../libs/paywall/features.constants';

@Component({
  selector: 'new-task-inline-form',
  templateUrl: './new-task-inline-form.component.html',
  styleUrls: ['./new-task-inline-form.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewTaskInlineFormComponent implements OnInit {
  @Input() board: Board;
  @Output() close = new EventEmitter();

  @ViewChild('titleInput') titleInput: ElementRef;

  public pending$ = new BehaviorSubject(false);
  public form: FormGroup;

  formObserver: Observer<any> = {
    next: resp => {
      //scroll to date
      this._store.dispatch(new HandleResponseAction(resp));
      this.pending$.next(false);
      this.form.get('title').patchValue('');
      this.form.get('title').markAsPristine();
      this.form.get('title').markAsUntouched();
      if (this.titleInput.nativeElement) {
        this.titleInput.nativeElement.focus();
      }
    },
    error: originalError => {
      this.pending$.next(false);
      const error = this._formService.normalizeServerErrorResponse(originalError);
      //TODO: properly handling different error types
      this._toastr.error(error.message);
    },
    complete: () => {}
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.add,
    entityToEdit: [TASK_PL, { expand: defaultExpand[TASK_PL] }],
    formObserver: this.formObserver
  };

  constructor(
    private _fb: FormBuilder,
    private _store: Store<AppState>,
    public _formService: FormV2Service,
    private _toastr: ToastrService,
    private _paywall: PaywallService
  ) {}

  ngOnInit() {
    this.form = this._fb.group({
      title: ['', Validators.compose([Validators.required, Validators.maxLength(1024)])],
      board: [this.board.id]
    });
    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  onSubmit() {
    this.form.get('title').patchValue(this.form.get('title').value.trim());
    this._formService.markAsDirty();
    if (this.form.valid) {
      if (this._paywall.isFeatureEnabled(Features.CanAddTask)) {
        this._formService.submit();
        this.pending$.next(true);
      } else {
        this._paywall.showPayWall(Features.CanAddTask);
      }
    }
    return false;
  }

  onClose() {
    this.close.emit();
  }
}
