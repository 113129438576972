import { Validators } from '@angular/forms';
import { PatternValidator } from '../custom-validators/pattern-validator';
export const user = {
  nickname: Validators.compose([
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(50),
    PatternValidator.pattern(/^[a-zA-Z0-9_.-]+$/)
  ])
};
