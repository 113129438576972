<p class="drag_column_title_element"
   [attr.draggable-disabled]="!(canEditColumn$ | async)"
   [ngClass]="{
                'column_title' : true,
                'no_wip' : !column.hasWipLimits,
                'with_wip' : column.hasWipLimits,
                'with_capacity' : +column.backlogCapacity > 0,
                'title_composite' : column.kind === columnKinds.composite
             }"
   (click)="onOpenColumnEditPopup(column)"
>
  <span class="column_title_text" [style.maxWidth]="titleTextWidth">{{column.name}}</span>
  <span class="column_title_counter" *ngIf="column.kind !== columnKinds.composite">
    <column-tasks-counter
      #counter
      [columnId]="column.id"
      [tasks$]="boardTasksFiltered$"
    >
    </column-tasks-counter>
  </span>
</p>

<a-context-container *ngIf="columnEditPopup">
  <div context-popup class="edit_column_popup" [attr.draggable-disabled]="true"
       [title]="'Edit Column'"
       (cancelPopup)="onCloseEditColumnPopup()"
  >
    <column-edit-form
      [column]="column"
      [boardId]="boardId"
      (submitForm)="onCloseEditColumnPopup()"
      (closePopup)="onCloseEditColumnPopup()"
    ></column-edit-form>
  </div>
</a-context-container>

<div a-context-popup *ngIf="moveTasksPopup" class="edit_column_popup" [attr.draggable-disabled]="true"
     [title]="this.moveActionType === 'move' ? 'Move Tasks' : 'Archive Tasks'"
     (close)="onCloseMoveTasksPopup()"
>
  <mass-tasks-actions-form
    [actionName]="moveActionType"
    [tasks]="movableTasks$ | async"
    [column]="column"
    [boardId]="boardId"
    [currentBoardSwimlanes]="currentBoardSwimlanes$ | async"
    (close)="onCloseMoveTasksPopup()"
    (submitForm)="onCloseMoveTasksPopup()"
  ></mass-tasks-actions-form>
</div>

<a-context-container *ngIf="columnCopyPopup">
  <div context-popup class="edit_column_popup" [attr.draggable-disabled]="true"
       [title]="columnCopyPopupTitle"
       (cancelPopup)="onCloseCopyColumnPopup()"
  >
    <copy-column-form
      [column]="column"
      [boardId]="boardId"
      [isMove]="columnIsMove"
      (submitForm)="onCloseCopyColumnPopup()"
      (closePopup)="onCloseCopyColumnPopup()"
    ></copy-column-form>
  </div>
</a-context-container>

<a-context-container *ngIf="changeWipLimitsPopup">
  <div context-popup class="edit_column_popup" [attr.draggable-disabled]="true"
       [title]="'WIP Limits'"
       (cancelPopup)="onCloseChangeWipLimitsPopup()"
  >
    <column-wip-edit-form
      [columnId]="(columnForWIP$ | async)?.id"
      [hasLimits]="hasLimits$ | async"
      (cancelPopup)="onCloseChangeWipLimitsPopup()"
    ></column-wip-edit-form>
  </div>
</a-context-container>

<a-context-container *ngIf="columnCapacityPopup">
  <div context-popup
       [title]="'Estimation Capacity'"
       (cancelPopup)="onCloseCapacityMenu()"
       class="capacity_popup"
  >
    <column-capacity-form
      [column]="column"
      (afterSubmit)="onCloseCapacityMenu()"
    ></column-capacity-form>
  </div>
</a-context-container>


<div *ngIf="column.kind === columnKinds.composite" class="subtitle_composite">
  <span>
    In progress
    <column-tasks-counter
      class="in_sub_column"
      [columnId]="column.subColumnsIds[0]"
      [tasks$]="boardTasksFiltered$"
    >
    </column-tasks-counter>
  </span>
  <span>
    Done
    <column-tasks-counter
      class="in_sub_column"
      [columnId]="column.subColumnsIds[1]"
      [tasks$]="boardTasksFiltered$"
    >
    </column-tasks-counter>
  </span>
</div>
<wip-limits *ngIf="column.hasWipLimits"
            [column]="column.wippedColumn"
            [withDetails]="false"
>
</wip-limits>
<column-capacity-indicator class="capacity_block"
                           *ngIf="+column.backlogCapacity > 0"
                           [column]="column"
                           [boardTasks]="boardTasks$ | async"
                           [isHeaderIndicator]="true"
></column-capacity-indicator>

<div *ngIf="canEditColumn$ | async" (click)="onToColumnActions(column.id)" class="add_swimlane_controller"
     [attr.draggable-disabled]="true">
  <div a-context-menu *ngIf="isColumnMenuVisible"
       class="context_menu"
       (close)="onCloseColumnMenu()"
  >
    <input [focus]="true" class="hidden_input">
    <p (click)="onSubscribeToColumn()" [class.check]="isUserSubscribedOnColumn$ | async">{{(isUserSubscribedOnColumn$ | async) ? 'Unsubscribe' : 'Subscribe'}}</p>
    <p (click)="onToggleCreteColumnPopup(column.id)">Add Column</p>
    <p (click)="onOpenColumnEditPopup(column)">Edit Column</p>

    <p (click)="onOpenColumnCopyPopup()">Copy Column</p>
    <p (click)="onOpenColumnCopyPopup(true)">Move Column</p>

    <p *ngIf="(boardScoringType$ | async) === ScoringType.basic" (click)="onToggleSortColumn(true)">Sort By {{(board$ | async)?.backlogScoreYLabel}}</p>
    <p *ngIf="(boardScoringType$ | async) === ScoringType.basic" (click)="onToggleSortColumn()">Sort By {{(board$ | async)?.backlogScoreXLabel}}</p>
    <p *ngIf="isSortByScoreVisible$ | async" (click)="onToggleSortColumn()">Sort By Score</p>
    <p *ngIf="!!(movableTasks$ | async).length" (click)="onOpenMoveTasksPopup('move')">Move Tasks</p>
    <p *ngIf="!!(movableTasks$ | async).length" (click)="onOpenMoveTasksPopup('archive')">Archive Tasks</p>
    <p *ngIf="canBeArchived$ | async" (click)="onToggleArchiveColumn()">Archive Column</p>
    <p *ngIf="columnForWIP$ | async" (click)="onOpenChangeWipLimitsPopup()">{{(hasLimits$ | async) ? 'Change' : 'Add'}}
      WIP Limits</p>
    <p *ngIf="isBacklogBoard$ | async" (click)="onAddCapacity()">{{( +column.backlogCapacity > 0) ? 'Change' : 'Add'}}
      Backlog Capacity</p>
    <p (click)="onPushAllTasks()">Push All Tasks</p>
  </div>
</div>

<div a-confirmation-popup
     *ngIf="isArchiveColumntPopupVisible"
     [confirmationTitle]="'Archive Column'"
     [actionButtonText]="'Archive'"
     (confirm)="onArchiveColumn()"
     (close)="onToggleArchiveColumn()"
>
  Are you sure you want to archive <b>{{column.name}}</b> column?
</div>


<div a-confirmation-popup
     *ngIf="isSortColumnVisible$ | async"
     [confirmationTitle]="sortColumnPopupTitle$ | async"
     [actionButtonText]="'Sort'"
     [isPending]="isSortPending$ | async"
     (confirm)="onConfirmSortColumn()"
     (close)="onToggleSortColumn()"
>
  You will change the order of tasks in the column and will not be able to return to the original order. Continue?
</div>
