
import {from as observableFrom,  Observable } from 'rxjs';

import {mergeMap, switchMap, catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { CHECKLIST_PL, CHECKLISTS_ITEM_PL } from '../../constants';
import { Checklist, ChecklistItem, HttpQueryParam, PartOfEntity } from '../../interfaces';
import { HandleResponseAction } from '../actions/root.action';
import { ChecklistActionTypes } from '../actions/checklist.actions';
import { ChecklistItemActionTypes } from '../actions/checklist-item.actions';
import { defaultErrorHandler } from './root.effect';
import { OpenedTaskDataChangedAction } from '../../task/ngrx/actions/opened-task.action';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';

@Injectable()
export class ChecklistEffects {
  @Effect()
  loadCollection$ = this.actions$
    .ofType(ChecklistActionTypes.GET).pipe(
    switchMap(({ type, payload: httpParams }: { type: string; payload: HttpQueryParam }) => {
      return this._atlazApi
        .get(CHECKLIST_PL, httpParams).pipe(
        map(resp => new HandleResponseAction(resp)),
        catchError(defaultErrorHandler(type, httpParams)),);
    }));

  @Effect()
  addChecklist$ = this.actions$
    .ofType(ChecklistActionTypes.ADD).pipe(
    switchMap(({ type, payload }: { type: string; payload: PartOfEntity }) => {
      return this._atlazApi
        .post(CHECKLIST_PL, payload).pipe(
        switchMap((newChecklist: Checklist) => {
          return observableFrom([
            new OpenedTaskDataChangedAction({ newCreatedCheckList: newChecklist }),
            new HandleResponseAction(newChecklist)
          ]);
        }),
        catchError(defaultErrorHandler(type, payload)),);
    }));

  @Effect({ dispatch: false })
  editChecklist$ = this.actions$
    .ofType(ChecklistActionTypes.EDIT).pipe(
    switchMap(({ type, payload: checklistData }: { type: string; payload: Partial<Checklist> }) => {
      return this._atlazApi
        .patch(CHECKLIST_PL, checklistData).pipe(
        map(resp => new HandleResponseAction(resp)),
        catchError(defaultErrorHandler(type, checklistData)),);
    }));

  @Effect({ dispatch: false })
  removeChecklist$ = this.actions$
    .ofType(ChecklistActionTypes.DELETE).pipe(
    switchMap(({ type, payload }: { type: string; payload: Checklist }) => {
      return this._atlazApi.deleteRequest([CHECKLIST_PL, payload.id]).pipe(catchError(defaultErrorHandler(type, payload)));
    }));

  @Effect({ dispatch: false })
  editChecklistItem$ = this.actions$
    .ofType(ChecklistItemActionTypes.EDIT).pipe(
    mergeMap(({ type, payload: checklistItemData }: { type: string; payload: PartOfEntity }) => {
      const checklistId = checklistItemData['checklist'];
      const data = {
        [CHECKLISTS_ITEM_PL + '/' + checklistItemData['id']]: checklistItemData
      };
      return this._atlazApi
        .patch([CHECKLIST_PL, checklistId], data).pipe(
        catchError(defaultErrorHandler(type, checklistItemData)));
    }));

  @Effect({ dispatch: false })
  deleteChecklistItem$ = this.actions$
    .ofType(ChecklistItemActionTypes.DELETE).pipe(
    mergeMap(({ type, payload: checkbox }: { type: string; payload: ChecklistItem }) => {
      const checklistId = checkbox.checklist;
      const data = {
        [CHECKLISTS_ITEM_PL]: { remove: [checkbox.id] }
      };
      return this._atlazApi
        .patch([CHECKLIST_PL, checklistId, { expand: CHECKLISTS_ITEM_PL }], data).pipe(
        catchError(defaultErrorHandler(type, checkbox)));
    }));

  @Effect()
  addChecklistItem$ = this.actions$
    .ofType(ChecklistItemActionTypes.ADD).pipe(
    mergeMap(({ type, payload: checklisItemtData }: { type: string; payload: PartOfEntity }) => {
      const checklistId = checklisItemtData['checklist'];
      const data = {
        [CHECKLISTS_ITEM_PL]: { add: [checklisItemtData] }
      };
      return this._atlazApi
        .patch([CHECKLIST_PL, checklistId, { expand: CHECKLISTS_ITEM_PL }], data).pipe(
        map(resp => new HandleResponseAction(resp)),
        catchError(defaultErrorHandler(type, checklisItemtData)),);
    }));

  @Effect()
  moveChecklistItem$ = this.actions$
    .ofType(ChecklistItemActionTypes.MOVE).pipe(
    switchMap(({ type, payload }: { type: string; payload: any }) => {
      const moveData: { checkboxId: number; nextCheckboxId: number; checklistItem: ChecklistItem } = payload;
      const itemData = {
        [CHECKLISTS_ITEM_PL]: Object.assign({}, moveData.checklistItem, { insertBeforeItem: moveData.nextCheckboxId })
      };
      return this._atlazApi
        .patch([CHECKLIST_PL, moveData.checklistItem.checklist, { expand: CHECKLISTS_ITEM_PL }], itemData).pipe(
        map(resp => new HandleResponseAction(resp)),
        catchError(defaultErrorHandler(type, payload)),);
    }));

  constructor(private actions$: Actions, protected _atlazApi: AtlazApiV2Service) {}
}
