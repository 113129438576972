import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { User } from '../../../../interfaces/user';
import {
  ROLE_COMPANY_EMPLOYEE,
  ROLE_COMPANY_GUEST,
  ROLE_COMPANY_OWNER,
  ROLE_COMPANY_SUPER_OWNER,
  ROLE_COMPANY_TITLES,
  ROLE_PROJECT_MEMBER
} from '../../../../permissions/interfaces/roles';
import { PermissionsService } from '../../../../permissions/permissions.service';
import { Store } from '@ngrx/store';

import { AppState } from '../../../../ngrx/state/app-state';
import { UserCompanyEditAction } from '../../../../ngrx/actions/user-company.actions';

const optionsRolesFilter = role =>
  role !== ROLE_COMPANY_SUPER_OWNER && role !== ROLE_COMPANY_OWNER && role !== ROLE_COMPANY_GUEST;

@Component({
  selector: 'user-company-role-select',
  templateUrl: './user-company-role-select.component.html',
  styleUrls: ['./user-company-role-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserCompanyRoleSelectComponent implements OnInit {
  @Input() user: User;

  public options = ROLE_COMPANY_TITLES;
  public userOwner = ROLE_COMPANY_SUPER_OWNER;
  public userGuest = ROLE_COMPANY_GUEST;

  public optionsRolesFilter = optionsRolesFilter;
  public isUpgradeDialog = false;

  constructor(public permissions: PermissionsService, private _store: Store<AppState>) {}

  ngOnInit() {}

  public onSaveNewRole(user: User, newRole: string) {
    this._store.dispatch(new UserCompanyEditAction({ id: user.userCompanyId, role: newRole }));
  }

  onUpgrade() {
    this.isUpgradeDialog = true;
  }

  onConfirmUpgrade() {
    this._store.dispatch(new UserCompanyEditAction({ id: this.user.userCompanyId, role: ROLE_COMPANY_EMPLOYEE }));
    this.onCloseConfirmDialog();
  }

  onCloseConfirmDialog() {
    this.isUpgradeDialog = false;
  }
}
