import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { compose } from '@ngrx/store';
import { getEntitySelector } from '../../functions/selectors';
import { DEFAULT_TIME_ON_BOARD_FILTER } from '../../state';
import * as defaultTimeOnBoardFilter from '../../actions/task-filters/default-time-on-board-filter.actions';
import { DefaultTasksTimeOnBoardFilterActionTypes } from '../../actions/task-filters/default-time-on-board-filter.actions';
import { TaskDefaultFilterActionTypes } from '../../actions/task-filters/task-default-filter.actions';
import { DaysRange } from '../../actions/task-filters/task-default-filter.actions';

export interface DefaultTimeOnBoardFilter {
  boardDaysRange: DaysRange;
}

const initState: DefaultTimeOnBoardFilter = {
  boardDaysRange: {
    moreThan: null,
    lessThan: null
  }
};

export function reducer(state = initState, action: defaultTimeOnBoardFilter.Actions): DefaultTimeOnBoardFilter {
  switch (action.type) {
    case DefaultTasksTimeOnBoardFilterActionTypes.TOGGLE_ITEM_ID: {
      let boardDaysRange: DaysRange;
      boardDaysRange = action['payload'];
      state = Object.assign({}, state, { boardDaysRange: boardDaysRange });
      return state;
    }

    case DefaultTasksTimeOnBoardFilterActionTypes.RESET: {
      return initState;
    }

    case TaskDefaultFilterActionTypes.SET: {
      if (action['payload'].activeFilters.defaultTimeOnBoardFilter) {
        state = { boardDaysRange: action['payload'].activeFilters.defaultTimeOnBoardFilter };
      } else {
        state = initState;
      }
      return state;
    }

    default: {
      return state;
    }
  }
}

const isActive = (state$: Observable<DefaultTimeOnBoardFilter>) => state$.pipe(
  map(state => state.boardDaysRange.moreThan || state.boardDaysRange.lessThan)
);
const getBoardDaysRange = (state$: Observable<DefaultTimeOnBoardFilter>) => state$.pipe(map(state => state.boardDaysRange));

export const getDefaultTimeOnBoardFilterEntity = getEntitySelector(DEFAULT_TIME_ON_BOARD_FILTER);
export const isActiveDefaultTimeOnBoardFilter = compose(isActive, getDefaultTimeOnBoardFilterEntity);
export const getDefaultTimeOnBoardFilterDaysRange = compose(getBoardDaysRange, getDefaultTimeOnBoardFilterEntity);
