export const activityType = {
  comment: 'comment',
  activity: 'activity',
  donetask: 'done-task',
  inprogress: 'inprogress'
};

export const activityMessageType = {
  LOGGED_TIME: 'LOGGED_TIME',
  DONE_TASK: 'DONE_TASK',
  ARCHIVED: 'ARCHIVED',
  BOARD_TASK_ATTACHMENT_ADDED: 'BOARD_TASK_ATTACHMENT_ADDED',
  BOARD_TASK_LOGGED_TIME: 'BOARD_TASK_LOGGED_TIME',
  MOVED_INSIDE_BOARD: 'BOARD_TASK_MOVED_INSIDE_BOARD',
  BOARD_TASK_SWIMLANE_CHANGED: 'BOARD_TASK_SWIMLANE_CHANGED',
  BOARD_TASK_ARCHIVED: 'BOARD_TASK_ARCHIVED',
  BOARD_COLUMN_ARCHIVED: 'BOARD_COLUMN_ARCHIVED'
};
