import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { User } from '../../interfaces';

@Component({
  selector: 'atlaz-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MembersComponent implements OnInit, OnChanges {
  @Input() selectedUsers: User[];
  @Input() possibleUsers: User[];
  @Input() projectOnlyUsers: User[];
  @Input() hideHeader: boolean;
  @Input() isSmallItems: boolean;
  @Input() headerClassName?: string;
  @Input() editPermissions = false;
  @Input() isPublicBoard = false;
  @Output() addUserToBoard: EventEmitter<any> = new EventEmitter();
  @Output() removeUser: EventEmitter<any> = new EventEmitter();
  @Output() updateUserSelection: EventEmitter<any> = new EventEmitter();

  @ViewChild('wrapper') wrapper: ElementRef;

  public isMembersPopupVisible = false;

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {
    this.selectedUsers = this.selectedUsers.filter((user: User) =>
      this.possibleUsers.find((possibleUser: User) => possibleUser.id === user.id)
    );
  }

  onOpenMemberPopup() {
    if (this.editPermissions) {
      this.isMembersPopupVisible = true;
    }
  }

  onCloseMembersPopup() {
    this.isMembersPopupVisible = false;
  }

  onUpdateUsersList(usersListUpdate) {
    let newSelection = [];
    newSelection = [...this.selectedUsers];
    usersListUpdate.remove.forEach(removeId => {
      newSelection = newSelection.filter(user => user.id !== removeId);
    });
    usersListUpdate.add.forEach(addId => {
      newSelection.push(this.possibleUsers.find(item => item.id === addId));
    });
    this.updateUserSelection.emit({ difference: usersListUpdate, value: newSelection });
  }

  onRemoveUserFromTask(userId: number) {
    this.updateUserSelection.emit({
      difference: { add: [], remove: [userId] },
      value: this.selectedUsers.filter(item => item.id !== userId)
    });
  }

  onWrapperClick(event) {
    if (event.targer === this.wrapper.nativeElement) {
      this.onOpenMemberPopup();
    }
  }

  onAddUserToBoard(user: User) {
    this.addUserToBoard.emit(user);
  }
}
