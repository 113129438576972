import { AppState, ESInterface } from '../../../ngrx/state/app-state';
import { BillingSubscriptionEntity } from '../../interfaces/billing-subscription';

import * as billingSubscription from './subscription.actions';
import { HttpQueryParam } from '../../../interfaces/http-query';
import { BILLING_SUBSCRIPTION_PL } from '../../../constants/entity';

export interface BillingSubscriptionState extends ESInterface<BillingSubscriptionEntity> {
  d?: any;
  queryParams: HttpQueryParam;
}

const initialState: BillingSubscriptionState = {
  ids: [],
  entities: {},
  queryParams: { id: 'get' }
};

export function reducer(state = initialState, action: billingSubscription.Actions): BillingSubscriptionState {
  switch (action.type) {
    default: {
      return state;
    }
  }
}

export const getSubscriptionId = (store: AppState) => {
  const subState = <BillingSubscriptionState>store[BILLING_SUBSCRIPTION_PL];
  return subState.ids[0];
};

export const getCompanyBillingSubscription = (store: AppState) => {
  const subState = <BillingSubscriptionState>store[BILLING_SUBSCRIPTION_PL];
  const id = subState.ids[0];
  return subState.entities[id];
};

export const getCompanyStatus = (store: AppState) => {
  const subState = <BillingSubscriptionState>store[BILLING_SUBSCRIPTION_PL];
  const subId = getSubscriptionId(store);

  return subState.entities[subId].status;
};
