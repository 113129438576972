<!--suppress ALL -->
<!-- https://egghead.io/lessons/angular-2-create-elements-from-template-elements-with-ngtemplateoutlet-in-angular-2?course=understand-angular-2-directives-in-depth -->

<svg:g #taskCircle
        [attr.transform]="transform"
        class="task_circle draggable"
        [tooltip]="drag_tootip" [byFlagOnly]="true"
>
  <ng-template [ngTemplateOutlet]="simple_circle"
            [ngTemplateOutletContext]="{score: taskDragging.backlogScore}"></ng-template>
</svg:g>

<ng-template #drag_tootip>
  <svg:g [attr.transform]="transformTooltip">
    <svg:image
      class="drag_tooltip"
      x="0"
      y="0"
      width="128"
      height="40"
      xlink:href="../../../../../../assets/img/backlog/drag-tooltip_long.svg"
    ></svg:image>
    <text font-size="10" x="23" y="17">{{axisYLabel}}: {{getVisibleTaskAxisScore(taskDragging?.backlogScoreY, axisYType)}}</text>
    <text font-size="10" x="23" y="28">{{axisXLabel}}: {{getVisibleTaskAxisScore(taskDragging?.backlogScoreX, axisXType)}}</text>
  </svg:g>
</ng-template>

<ng-template #simple_circle let-score="score">
  <svg:circle [attr.cx]="0" [attr.cy]="0" [attr.r]="10"/>
</ng-template>
