import { combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/state';
import { Column, Task } from '../../interfaces';
import { TaskUnsavedDataService } from '../shared/services/task-unsaved-data.service';
import { isPresent } from '../../../helpers';
import { getTaskById } from '../../ngrx/reducers/task.reducer';
import { getColumnById } from '../../ngrx/reducers/column.reducer';
import { columnTypes } from '../../constants';
import { OpenedTaskCloseAction } from '../ngrx/actions/opened-task.action';
import { fromOpenedTask } from '../ngrx/reducers/opened-task.reducer';
import { isTaskClickOutListens } from '../../ngrx/reducers/component.reducer';

@Component({
  selector: 'task-detail-page.component',
  templateUrl: './task-detail-page.component.html',
  styleUrls: ['../task-expanded.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskDetailPageComponent implements OnInit, OnDestroy, AfterContentChecked, AfterViewInit {
  @ViewChild('wrap') public wrap: ElementRef;

  public task$: Observable<Task>;
  public taskSafe$: Observable<Task>;
  public archivedGroup$: Observable<boolean>;
  public archivedColumn$: Observable<boolean>;
  public isTaskInDoneColumn$: Observable<boolean>;
  public showCompletedBanner$: Observable<boolean>;
  public taskClickOut$: Observable<number>;

  constructor(
    private _store: Store<AppState>,
    private _taskUnsavedDataService: TaskUnsavedDataService,
    private _cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.task$ = this._store.select(fromOpenedTask.getTask);
    this.taskSafe$ = this.task$.pipe(filter(isPresent));

    this.taskClickOut$ = this._store.select(isTaskClickOutListens);

    this.archivedGroup$ = this.taskSafe$.pipe(
      map(task => task.parent),
      switchMap(parentId => this._store.pipe(getTaskById(parentId))),
      map((task: Task) => task && !!task.archived)
    );

    this.isTaskInDoneColumn$ = this.taskSafe$.pipe(
      map(task => task.column),
      switchMap(columnId => this._store.pipe(getColumnById(columnId))),
      map((column: Column) => column && column.type === columnTypes.done)
    );

    this.archivedColumn$ = this.taskSafe$.pipe(
      map(task => task.column),
      switchMap(columnId => this._store.pipe(getColumnById(columnId))),
      map((column: Column) => column && !!column.archived)
    );

    this.showCompletedBanner$ = observableCombineLatest(
      this.taskSafe$,
      this.archivedGroup$,
      this.archivedColumn$,
      this.isTaskInDoneColumn$
    ).pipe(map(([v1, v2, v3, v4]) => v1 && !v1.archived && !v1.released && !v2 && !v3 && v4));

    this.initUnsavedData();
  }

  ngOnDestroy() {}

  initUnsavedData() {
    this.task$
      .pipe(take(1), filter(isPresent))
      .subscribe(
        task => this._taskUnsavedDataService.initUnsavedData(task.id),
        e => console.log(e, 'error when try to init unsaved Data')
      );
  }

  onClose() {
    this._store.dispatch(new OpenedTaskCloseAction());
  }

  closeOnEsc($event) {
    if (!['INPUT', 'SELECT', 'TEXTAREA'].includes($event.target.nodeName)) {
      this.onClose();
    }
  }

  ngAfterContentChecked() {
    setTimeout(() => {
      if (document.activeElement === document.body) {
        // make active
        this.wrap.nativeElement.focus();
      }
    });
  }

  ngAfterViewInit() {
    try {
      this.wrap.nativeElement.parentElement.focus();
      this._cd.detectChanges();
    } catch (e) {
      console.warn('Error on focus task after view init', e);
    }
  }
}
