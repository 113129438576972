
import {distinctUntilChanged, map} from 'rxjs/operators';
import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { SyncRoadmapGuiStateService } from '../services/sync-roadmap-gui-state-service';
import { Subscription } from 'rxjs';
@Directive({
  selector: '[cellHighlight]'
})
export class CellHighlightDirective implements OnDestroy, AfterViewInit {
  @Input() cellHighlight: string;

  public shouldBeHighLighted$ = this._guiSyncService.hoveredCellId$.pipe(
    map(hovered => hovered === this.cellHighlight),
    distinctUntilChanged(),);
  private sub: Subscription;

  constructor(
    private _guiSyncService: SyncRoadmapGuiStateService,
    private _renderer: Renderer2,
    private elRef: ElementRef
  ) {}

  ngAfterViewInit() {
    this.sub = this.shouldBeHighLighted$.subscribe(addClass => {
      if (addClass) {
        this._renderer.addClass(this.elRef.nativeElement, 'hover_day');
      } else {
        this._renderer.removeClass(this.elRef.nativeElement, 'hover_day');
      }
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
