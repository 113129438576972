import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../../../../shared/services/form-v2.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SWIMLANE_PL } from '../../../../../constants';
import { HandleResponseAction } from '../../../../../ngrx/actions/root.action';
import { BehaviorSubject, Observer } from 'rxjs/index';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../ngrx/state';

@Component({
  selector: 'add-swimlane',
  templateUrl: './add-swimlane.component.html',
  styleUrls: ['./add-swimlane.component.scss'],
  providers: [FormV2Service]
})
export class AddSwimlaneComponent implements OnInit {
  @Input() boardId: number;
  @Input() initName: string;
  @Output() close = new EventEmitter();

  public pending$ = new BehaviorSubject(false);
  public form: FormGroup;

  formObserver: Observer<any> = {
    next: resp => {
      this._store.dispatch(new HandleResponseAction(resp));
    },
    error: error => {
      this.pending$.next(false);
      console.log(error);
    },
    complete: () => {
      this.onClose();
    }
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.add,
    entityToEdit: SWIMLANE_PL,
    formObserver: this.formObserver
  };

  constructor(private _store: Store<AppState>, public _formService: FormV2Service, private _fb: FormBuilder) {}

  ngOnInit() {
    this.form = this._fb.group({
      board: [this.boardId],
      name: [this.initName, Validators.required]
    });

    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  onSubmit() {
    this._formService.markAsDirty();
    this.form.markAsDirty();
    if (!this.form.valid) {
      return;
    }
    this.pending$.next(true);
    this._formService.submit();
  }

  onClose() {
    this.close.emit();
  }
}
