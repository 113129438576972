<overlay (click)="onToggleLeftMenu()" *ngIf="isOverlay$ | async"></overlay>
<div
  class="left_menu_block"
  [ngClass]="{'is-visible': isLeftMenuVisible$ | async}"
>
  <div class="global_menu_container">
    <left-menu-header
      (closeLeftMenu)="onToggleLeftMenu()"
    ></left-menu-header>

    <left-menu-body
      [isNotGuest]="isNotGuest$ | async"
      [class.has_footer]="isSuperUser$ | async"
      (editCollection)="collectionEdit($event)"
    ></left-menu-body>

    <left-menu-footer *ngIf="isSuperUser$ | async" [isLeftMenu$]="isLeftMenuVisible$"></left-menu-footer>
  </div>
</div>
