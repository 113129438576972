import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Board, Project } from '../../../interfaces';
import { AppState } from '../../../ngrx/state/app-state';
import { BoardAssignProjectsAction } from '../../../ngrx/actions/board.actions';
import { boardType } from '../../../constants/board-type';
import { RightMenuProjectListComponent } from '../right-menu-project-list/right-menu-project-list.component';
import { PaywallService } from '../../../libs/paywall/paywall.service';
import { Features } from '../../../libs/paywall/features.constants';

@Component({
  selector: 'right-menu-projects-popup',
  templateUrl: './right-menu-projects-popup.component.html',
  styleUrls: ['./right-menu-projects-popup.component.scss']
})
export class RightMenuProjectsPopupComponent {
  @ViewChild(RightMenuProjectListComponent) rightMenuProjectListComponent: RightMenuProjectListComponent;

  @Input() projects: Project[];
  @Input() boardProjects: Project[];
  @Input() selectedBoard: Board;
  @Input() boardId: number;

  @Output() close = new EventEmitter();

  public boardTypes = boardType;
  public isMultiSelect: boolean;

  constructor(private _store: Store<AppState>, private _paywall: PaywallService) {}

  ngOnInit() {
    const isNotSprint = this.selectedBoard && this.selectedBoard.type !== this.boardTypes.sprint;
    this.isMultiSelect = this._paywall.isFeatureEnabled(Features.MultipleProjects) && isNotSprint;
  }

  onAssignProjectAndClosePopup() {
    if (this.rightMenuProjectListComponent.noEmptyProjectsQuery()) {
      this._store.dispatch(
        new BoardAssignProjectsAction({
          id: this.boardId,
          projects: this.rightMenuProjectListComponent.getProjectsQuery()
        })
      );
      this.rightMenuProjectListComponent.reInitComponent();
    }
    this.close.emit();
  }
}
