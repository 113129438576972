
import {map} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { compose } from '@ngrx/store';
import { share } from '../../functions/util';
import { getEntitySelector } from '../../functions/selectors';
import { DEFAULT_LABELS_FILTER } from '../../state';
import * as defaultLabelFilter from '../../actions/task-filters/default-labels-filter.action';
import { DefaultTasksLabelsFilterActionTypes } from '../../actions/task-filters/default-labels-filter.action';
import { TaskDefaultFilterActionTypes } from '../../actions/task-filters/task-default-filter.actions';

export interface DefaultLabelsFilter {
  labelsIds: number[];
}

const initState: DefaultLabelsFilter = {
  labelsIds: []
};

export function reducer(state = initState, action: defaultLabelFilter.Actions): DefaultLabelsFilter {
  switch (action.type) {
    case DefaultTasksLabelsFilterActionTypes.TOGGLE_LABEL_ID: {
      let labelIds: number[];
      labelIds = action['payload'];
      state = Object.assign({}, state, { labelsIds: labelIds });
      return state;
    }

    case DefaultTasksLabelsFilterActionTypes.RESET: {
      state = initState;
      return initState;
    }

    case TaskDefaultFilterActionTypes.SET: {
      if (action['payload'].activeFilters.defaultLabelsFilter) {
        state = { labelsIds: [...action['payload'].activeFilters.defaultLabelsFilter] };
      } else {
        state = initState;
      }
      return state;
    }

    default: {
      return state;
    }
  }
}

const isActive = (state$: Observable<DefaultLabelsFilter>) => state$.pipe(map(state => state.labelsIds.length > 0));
const getLabelsIds = (state$: Observable<DefaultLabelsFilter>) => state$.pipe(map(state => state.labelsIds));

export const getDefaultLabelsFilterEntity = getEntitySelector(DEFAULT_LABELS_FILTER);
export const isActiveDefaultLabelsFilter = compose(isActive, getDefaultLabelsFilterEntity);
export const getDefaultLabelsFilterIds = share(compose(getLabelsIds, getDefaultLabelsFilterEntity));
