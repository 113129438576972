import { ChangeDetectionStrategy, Component, HostListener, Input, OnInit } from '@angular/core';
import { distinctUntilChanged, map, take } from 'rxjs/operators';
import { CounterModes, getNextCounterMode, secondsToText } from '../../../../helpers/task';
import { Task } from '../../../interfaces';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs/index';
import { UsersCacheService } from '@atlaz/core/services/users-cache.service';

const KEY_LOCAL_STORE_VERSION_COUNTERS = 'versionCountersState';

@Component({
  selector: 'version-tasks-counter',
  templateUrl: './version-tasks-counter.component.html',
  styleUrls: ['./version-tasks-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VersionTasksCounterComponent implements OnInit {
  @Input() tasksByVersion$;
  @Input() versionId: number;

  public counterText$: Observable<string | number>;
  public modes = CounterModes;
  public currentMode = CounterModes.Count;
  public currentMode$: BehaviorSubject<CounterModes>;

  public tasks$: Observable<Task[]>;
  public versionTasksLength$: Observable<number>;
  public versionTasksEstimateSum$: Observable<string>;
  public versionTasksPointsSum$: Observable<string>;
  constructor(private _usersCacheService: UsersCacheService) {}

  ngOnInit() {
    this.currentMode$ = new BehaviorSubject(CounterModes.Count);
    this._usersCacheService
      .get(KEY_LOCAL_STORE_VERSION_COUNTERS)
      .pipe(take(1))
      .subscribe(state => {
        const currentState = state || {};
        this.currentMode = currentState[this.versionId] ? currentState[this.versionId] : CounterModes.Count;
        this.currentMode$.next(this.currentMode);
      });

    this.tasks$ = this.tasksByVersion$.pipe(map(v => v[this.versionId] || []));

    this.versionTasksLength$ = this.tasks$.pipe(map((tasks: Task[]) => tasks.length));

    this.versionTasksEstimateSum$ = this.tasks$.pipe(
      map((tasks: Task[]) => tasks.map(task => task.estimate).reduce((acc, item) => acc + item, 0)),
      distinctUntilChanged(),
      map(estimate => secondsToText(estimate))
    );

    this.versionTasksPointsSum$ = this.tasks$.pipe(
      map((tasks: Task[]) => tasks.map(task => task.storyPoints).reduce((acc, item) => acc + item, 0)),
      distinctUntilChanged(),
      map(points => points + 'sp')
    );

    this.counterText$ = combineLatest(
      this.currentMode$,
      this.versionTasksLength$,
      this.versionTasksEstimateSum$,
      this.versionTasksPointsSum$
    ).pipe(
      map(([v1, v2, v3, v4]) => {
        switch (v1) {
          case CounterModes.Count: {
            return v2;
          }
          case CounterModes.Hours: {
            return v3;
          }
          case CounterModes.Points: {
            return v4;
          }
          default: {
            return v2;
          }
        }
      })
    );
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event.stopPropagation();
    this.currentMode = getNextCounterMode(this.currentMode);
    this.currentMode$.next(this.currentMode);
    this._usersCacheService
      .get(KEY_LOCAL_STORE_VERSION_COUNTERS)
      .pipe(take(1))
      .subscribe(state => {
        const previousState = state || {};
        this._usersCacheService.set(KEY_LOCAL_STORE_VERSION_COUNTERS, {
          ...previousState,
          [this.versionId]: this.currentMode
        });
      });
  }
}
