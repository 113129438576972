import { combineLatest as observableCombineLatest, Observable, Subscription } from 'rxjs';

import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Version } from '../../../interfaces';
import { AppState } from '../../../ngrx/state';
import { isPresent } from '../../../../helpers';
import {
  DefaultTaskVersionsFilterToggleItemAction,
  DefaultVersionsFilterResetAction
} from '../../../ngrx/actions/task-filters/default-versions-filter.actions';
import { getDefaultVersionsFilterIds } from '../../../ngrx/reducers/task-filters/default-versions-filter.reducer';
import { getVersionsListByBoardId } from '../../../ngrx/functions/crossed.selector';

@Component({
  selector: 'default-versions-filter',
  templateUrl: './default-versions-filter.component.html',
  styleUrls: ['./default-versions-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultVersionsFilterComponent implements OnInit, OnDestroy {
  @Input() boardId$: Observable<number>;
  subs: Subscription[] = [];

  public boardVersions$: Observable<Version[]>;
  public selectedVersions$: Observable<Version[]>;
  public selectedVersionsIds$: Observable<number[]>;
  public storeVersionsFilterValue$: Observable<number[]>;

  public showVersionsPopUp = false;
  public visibleFilter$: Observable<boolean>;

  constructor(private _store: Store<AppState>) {}

  ngOnInit(): void {
    this.storeVersionsFilterValue$ = this._store.pipe(getDefaultVersionsFilterIds);

    this.boardVersions$ = this.boardId$.pipe(
      filter(isPresent),
      distinctUntilChanged(),
      switchMap(id => this._store.select(getVersionsListByBoardId(id))),
      map(versions => versions.filter(v => !v.released))
    );

    this.visibleFilter$ = this.boardVersions$.pipe(
      map(list => list && !!list.length),
      distinctUntilChanged(),
      tap(visible => {
        if (!visible) {
          this.onHideVersionsPopup();
        }
      })
    );

    this.selectedVersions$ = observableCombineLatest(
      this.storeVersionsFilterValue$,
      this.boardVersions$,
      (selectedIds: number[], versions: Version[]) => {
        return {
          selectedIds: selectedIds,
          versions: versions.filter((version: Version) => selectedIds.includes(version.id))
        };
      }
    ).pipe(
      tap(({ selectedIds, versions }) => {
        if (selectedIds.length && !versions.length) {
          this._store.dispatch(new DefaultVersionsFilterResetAction());
        }
      }),
      map(({ versions }) => versions)
    );

    this.selectedVersionsIds$ = this.selectedVersions$.pipe(map((version: Version[]) => version.map(item => item.id)));
  }

  onAddVersion(version: Version) {
    this.selectedVersionsIds$.pipe(take(1)).subscribe(ids => {
      this._store.dispatch(new DefaultTaskVersionsFilterToggleItemAction([...ids, version.id]));
    });
  }

  onRemoveVersion(version: Version) {
    this.selectedVersionsIds$.pipe(take(1)).subscribe(ids => {
      this._store.dispatch(new DefaultTaskVersionsFilterToggleItemAction(ids.filter(id => id !== version.id)));
    });
  }

  onShowVersionsPopup() {
    this.showVersionsPopUp = true;
  }
  onHideVersionsPopup() {
    this.showVersionsPopUp = false;
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
