import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { defaultErrorHandler } from './root.effect';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HandleResponseAction } from '../actions/root.action';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { CustomFieldActionTypes, CustomFieldDeleteCompleteAction } from '../actions/custom-field.actions';
import { CUSTOM_FIELD_PL } from '../../constants';
import { fromCamelToDash } from '../../../helpers';

@Injectable()
export class CustomFieldEffects {
  @Effect()
  moveCustomField$ = this.actions$
    .ofType(CustomFieldActionTypes.MOVE)
    .pipe(
      switchMap(({ type, payload }: { type: string; payload: any }) =>
        this._atlazApi
          .patch([fromCamelToDash(CUSTOM_FIELD_PL)], payload)
          .pipe(map(resp => new HandleResponseAction(resp)), catchError(defaultErrorHandler(type, payload)))
      )
    );

  @Effect()
  deleteCustomField$ = this.actions$
    .ofType(CustomFieldActionTypes.DELETE)
    .pipe(
      switchMap(({ type, payload }: { type: string; payload: number }) =>
        this._atlazApi
          .deleteRequest([fromCamelToDash(CUSTOM_FIELD_PL), payload])
          .pipe(map(_ => new CustomFieldDeleteCompleteAction(payload)), catchError(defaultErrorHandler(type, payload)))
      )
    );

  constructor(private actions$: Actions, protected _atlazApi: AtlazApiV2Service) {}
}
