import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { SprintBoard } from '../../../interfaces';
import { Subscription } from 'rxjs';
import { ComponentSetVisibleAction } from '../../../ngrx/actions/component.actions';
import { RIGHT_MENU } from '../../../ngrx/state/';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'sprint-board-details',
  templateUrl: './sprint-board-details.component.html',
  styleUrls: ['./sprint-board-details.component.scss']
})
export class SprintBoardDetailsComponent implements OnInit, OnChanges {
  @Input() board: SprintBoard;

  public subs: Subscription[] = [];

  constructor(private _store: Store<AppState>, private _toastr: ToastrService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['board'].currentValue && changes['board'].previousValue) {
      const prev: SprintBoard = changes['board'].previousValue;
      const current: SprintBoard = changes['board'].currentValue;
      if (prev.id === current.id) {
        // if someone start sprint
        if (!prev.sprintStartDate && prev.sprintStartDate !== current.sprintStartDate && current.sprintStartDate) {
          setTimeout(() => this._toastr.success(`Sprint '${current.sprintName}' Started!`));
        }
      }
    }
  }

  ngOnInit(): any {}

  onCloseRightMenu() {
    this._store.dispatch(new ComponentSetVisibleAction({ name: RIGHT_MENU, visible: false }));
  }
}
