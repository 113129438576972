// synced with backend
export enum BILLING_SUBSCRIPTION_STATUS {
  AWAITING_FOR_PAYMENTS_DETAILS = 'awaiting-for-payment-details'
}

export const SUBSCRIPTION_PATH = 'billing-subscriptions';
export const SUBSCRIPTION_ACTIONS = {
  addCard: 'add-card',
  renew: 'renew',
  deactivate: 'deactivate'
};

export enum BillingPeriod {
  Month = 'month',
  Year = 'year',
  Day = 'day'
}

export enum BillingPlanName {
  Unpaid = 'UNPAID',
  Basic = 'BASIC',
  Premium = 'PREMIUM'
}

export const DefaultStripeId = 'none';
