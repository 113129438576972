import { distinctUntilChanged, pluck } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { Board } from '../../interfaces/';
import { boardType } from '../../constants/';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';
import { AppState, COMPONENT, RIGHT_MENU } from '../../ngrx/state';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ComponentSetVisibleAction } from '../../ngrx/actions/component.actions';
import { CanDeactivateBoard } from '../../shared/services/guards/can-deactivate-board.service';

const ACTIVE = 'active';
const INACTIVE = 'inactive';

@Component({
  selector: 'right-menu-root',
  templateUrl: './right-menu-root.component.html',
  styles: [
    `

    :host ::ng-deep [a-context-popup].fixed {
      right: 20px;
    }

    .overlay {
      position: fixed;
      z-index: 799;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
    }

    div.root {
      position: fixed;
      top: 60px;
      right: 0;
      bottom: 0;
      display: block;
      width: 0;
      z-index: 820;
    }
    div.root.visible {
      width: 300px;
    }
    `
  ]
})
export class RightMenuRootComponent implements OnInit {
  @Input() board: Board;
  public show = false;

  public isVisibleRightMenu$: Observable<boolean>;

  public boardType = boardType;
  public state = INACTIVE;

  public showContent = false;

  constructor(private _store: Store<AppState>, private _canDeactivateBoard: CanDeactivateBoard) {}

  ngOnInit() {
    this.isVisibleRightMenu$ = this._store.pipe(
      pluck<AppState, boolean>(COMPONENT, RIGHT_MENU, 'visible'),
      distinctUntilChanged()
    );
    this.isVisibleRightMenu$.subscribe(visible => {
      this.showContent = visible;
    });
  }

  onCloseRightMenu() {
    this._canDeactivateBoard
      .canDeactivateRaw()
      .subscribe(
        () => this._store.dispatch(new ComponentSetVisibleAction({ name: RIGHT_MENU, visible: false })),
        () => console.warn('Leave unconfirmed')
      );
  }
}
