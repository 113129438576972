export const GUI_STATE_MEMORIZED = 'guiStateMemorized';
export const COMPONENT = 'component';
export const LEFT_MENU = 'leftMenu';
export const RIGHT_MENU = 'rightMenu';
export const OPENED_TASK = 'openedTask';
export const QUICK_FILTER = 'quickFilter';
export const HIDDEN_SWIMLANES = 'hiddenSwimlanes';
export const HIDDEN_VERSIONS = 'hiddenVersions';
export const COLLAPSED_GROUPS = 'collapsedGroups';
export const CHECKLIST_HIDE_COMPLETED = 'checklistHideCompleted';
export const SPRINT_PROGRESS_TYPE = 'sprintProgressType';
export const BOARDS_VIEW_MODE = 'selectedBoardViewMode';
export const BOARDS_GROUPING_PROP = 'selectedBoardGrouping';
export const ROADMAP_ZOOM_MODE = 'roadmapZoomMode';
export const GLOBAL_SEARCH = 'search';
export const DEFAULT_MEMBERS_FILTER = 'defaultMembersFilter';
export const DEFAULT_LABELS_FILTER = 'defaultLabelssFilter';
export const DEFAULT_COLORS_FILTER = 'defaultColorsFilter';
export const DEFAULT_QUADS_FILTER = 'defaultQuadsFilter';
export const DEFAULT_COLUMNS_FILTER = 'defaultColumnsFilter';
export const DEFAULT_SWIMLANES_FILTER = 'defaultSwimlanesFilter';
export const DEFAULT_VERSIONS_FILTER = 'defaultVersionsFilter';
export const DEFAULT_PROJECTS_FILTER = 'defaultProjectsFilter';
export const DEFAULT_STATUSES_FILTER = 'defaultStatusesFilter';
export const DEFAULT_FILTER_STATE = 'taskDefaultFilter';
export const DEFAULT_TIME_ON_BOARD_FILTER = 'defaultTimeOnBoardFilter';
export const DEFAULT_TIME_ON_COLUMN_FILTER = 'defaultTimeOnColumnFilter';
export const REPORT_PROJECT_FILTER = 'reportProjectFilter';
export const REPORT_BOARD_FILTER = 'reportBoardFilter';
export const REPORT_PERIOD_FILTER = 'reportPeriodFilter';
export const REPORT_MEMBER_FILTER = 'reportMemberFilter';
export const REPORT_GROUP_FILTER = 'reportGroupFilter';
export const TASKS_SAVED_LIST = 'tasksSavedList';
export const DATA_SYNC = 'dataSync';
export const QUICK_TASK_EDIT = 'quickTaskEdit';
export const BATCH_EDIT = 'batchEdit';
export const HOTKEYS_TARGET_TASK = 'hotkeysTargetTask';
export const REPORT_PROJECTS_FILTER = 'reportProjectsFilter';
export const REPORT_BOARDS_FILTER = 'reportBoardsFilter';
export const REPORT_MEMBERS_FILTER = 'reportMembersFilter';
export const REPORT_GROUPS_FILTER = 'reportGroupsFilter';
