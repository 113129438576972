import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { Task } from '../../../interfaces';
import { ScoringCriteria, SeparatedCriteria } from '../../../interfaces/ScoringCriteria';
import { ScoringFactor } from '../../backlog-board/constants/backlog.constants';
import { BehaviorSubject, combineLatest, fromEvent, Observable, Subscription } from 'rxjs/index';
import {
  distinctUntilChanged,
  filter,
  map,
  pluck,
  publishReplay,
  refCount,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { getVisibleScoringVal } from '../../../../helpers';
import { AppState } from '../../../ngrx/state';
import { Store } from '@ngrx/store';
import { fromBoards } from '../../../ngrx/reducers/board.reducer';
import { sortOriginOrder } from '../../helpers';
import { fromColumns } from '../../../ngrx/reducers/column.reducer';
import { fromSwimlanes } from '../../../ngrx/reducers/swimlane.reducer';
import { AppUrls } from '../../../app-urls';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import { BacklogChartService } from '../../backlog-chart/backlog-chart.service';

interface BoardWeights {
  valueWeight: number;
  effortsWeight: number;
}
interface CalculatedTask {
  id: number;
  title: string;
  confidence: number;
  scoringValuesMap: any;
  score?: string | number;
}
@Component({
  selector: 'scoring-table',
  templateUrl: './scoring-table.component.html',
  styleUrls: ['./scoring-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScoringTableComponent implements OnInit {
  @Input() tasks$: Observable<Task[]>;
  @Input() boardId: number;
  @Input() boardCriteria$: Observable<SeparatedCriteria>;
  public sortedTasks$: Observable<Task[]>;
  public criteriaValues$: Observable<ScoringCriteria[]>;
  public criteriaEfforts$: Observable<ScoringCriteria[]>;
  public calculatedTasks$: Observable<CalculatedTask[]>;
  public boardWeights$: Observable<BoardWeights>;
  public filterValue$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public columnsEntities$;
  public swimlanesEntities$;
  public crsMap;
  public activeSort$: BehaviorSubject<any> = new BehaviorSubject('');
  public getTaskLink = AppUrls.getUrlTask;
  public isScoreEditing$ = new BehaviorSubject(false);
  public editingTask$ = new BehaviorSubject(null);
  public editingCriterion$ = new BehaviorSubject(null);
  public higlightTimeout;
  subs: Subscription[] = [];

  constructor(
    private _elRef: ElementRef,
    private _store: Store<AppState>,
    private _renderer: Renderer2,
    private _backLogChartService: BacklogChartService
  ) {}

  ngOnInit() {
    this.columnsEntities$ = this._store.select(fromColumns.getState).pipe(pluck('entities'));
    this.swimlanesEntities$ = this._store.select(fromSwimlanes.getState).pipe(pluck('entities'));
    this.sortedTasks$ = this.tasks$.pipe(
      switchMap((tasks: Task[]) => {
        return combineLatest([this.columnsEntities$, this.swimlanesEntities$, this.activeSort$]).pipe(
          map(([columnsEntities, swimlanesEntities, activeSort]) => {
            if (!activeSort) {
              return sortOriginOrder(columnsEntities, swimlanesEntities)(tasks);
            } else {
              return tasks;
            }
          })
        );
      })
    );
    this.boardWeights$ = this._store
      .select(fromBoards.get(this.boardId))
      .pipe(map(board => ({ valueWeight: board.advancedValueWeight, effortsWeight: board.advancedEffortWeight })));
    this.criteriaValues$ = this.boardCriteria$.pipe(
      map((sepCrs: SeparatedCriteria) => (sepCrs ? sepCrs[ScoringFactor.Value] : [])),
      publishReplay(1),
      refCount()
    );
    this.criteriaEfforts$ = this.boardCriteria$.pipe(
      map((sepCrs: SeparatedCriteria) => (sepCrs ? sepCrs[ScoringFactor.Efforts] : [])),
      publishReplay(1),
      refCount()
    );
    this.calculatedTasks$ = combineLatest(this.sortedTasks$, this.boardCriteria$, this.boardWeights$).pipe(
      tap(([tasks, sepCrs]: [Task[], SeparatedCriteria]) => {
        this.crsMap = {};
        sepCrs[ScoringFactor.Value].forEach(cr => (this.crsMap[cr.id] = cr));
        sepCrs[ScoringFactor.Efforts].forEach(cr => (this.crsMap[cr.id] = cr));
      }),
      map(([tasks, sepCrs, boardWeights]: [Task[], SeparatedCriteria, BoardWeights]): CalculatedTask[] => {
        return tasks.map(task => {
          const result: CalculatedTask = {
            id: task.id,
            title: task.title,
            confidence: task.confidence,
            scoringValuesMap: this.getScoringValuesMap(task)
          };
          result.score = this.getTaskScore(result, sepCrs, boardWeights);
          return result;
        });
      }),
      switchMap((calcTasks: CalculatedTask[]) =>
        this.filterValue$.pipe(
          map(filterVal => {
            if (filterVal) {
              filterVal = filterVal.toUpperCase();
              return calcTasks.filter(item => item.title.toUpperCase().indexOf(filterVal) > -1);
            }
            return calcTasks;
          })
        )
      ),
      switchMap(calcTasks => this.activeSort$.pipe(map(activeSort => this.sortCalcTasks(calcTasks, activeSort)))),
      publishReplay(1),
      refCount()
    );

    this.subs.push(
      this.calculatedTasks$.subscribe(() => {
        setTimeout(() => this.setHeight());
      })
    );
  }

  ngAfterViewInit() {
    this.setHeight();
    this.subs.push(
      fromEvent(window, 'resize').subscribe(_ => {
        this.setHeight();
      })
    );

    this.subs.push(
      this._backLogChartService.lastCreatedByMeTaskId$.pipe(distinctUntilChanged()).subscribe(id => {
        setTimeout(() => {
          const el = document.querySelector('[data-task-id="' + id + '"]');
          if (!el) {
            return;
          }
          scrollIntoViewIfNeeded(el, {
            duration: 100,
            boundary: this._elRef.nativeElement,
            centerIfNeeded: true
          });
          this.highlightScrolledTask(id, el);
          this._backLogChartService.lastCreatedByMeTaskId$.next(0);
        });
      })
    );
  }

  getTaskScore(calcTask: CalculatedTask, sepCrs: SeparatedCriteria, boardWeights: BoardWeights) {
    const values = sepCrs[ScoringFactor.Value].reduce((acc, item) => {
      if (calcTask.scoringValuesMap[item.id] && Number.isFinite(calcTask.scoringValuesMap[item.id].value)) {
        acc += item.scoringDirection * (item.weight / 100) * calcTask.scoringValuesMap[item.id].value;
      } else {
        return NaN;
      }
      return acc;
    }, 0);
    const efforts = sepCrs[ScoringFactor.Efforts].reduce((acc, item) => {
      if (calcTask.scoringValuesMap[item.id]) {
        acc += item.scoringDirection * (item.weight / 100) * calcTask.scoringValuesMap[item.id].value;
      } else {
        return NaN;
      }
      return acc;
    }, 0);
    let score = Math.round(
      (values * (boardWeights.valueWeight / 100) + efforts * (boardWeights.effortsWeight / 100)) *
        (calcTask.confidence / 100)
    );
    return Number.isFinite(score) ? score : '?';
  }

  getScoringValuesMap(task) {
    return (Array.isArray(task.scoringValues) ? task.scoringValues : []).reduce((acc, item) => {
      if (item && item.criterion) {
        acc[item.criterion] = {
          value: item.value,
          visibleValue: this.crsMap[item.criterion]
            ? getVisibleScoringVal(item.value, this.crsMap[item.criterion].dataType)
            : '?'
        };
      }
      return acc;
    }, {});
  }

  setHeight() {
    if (this._elRef.nativeElement) {
      const hostRect = this._elRef.nativeElement.getBoundingClientRect();
      const availableViewport = window.innerHeight - hostRect.top - 25;
      if (this._elRef.nativeElement.scrollHeight >= availableViewport) {
        this._elRef.nativeElement.style.height = availableViewport - 2 + 'px';
      }
    }
  }

  applySortBy(event, sortParam) {
    this.activeSort$.pipe(take(1)).subscribe(activeSort => {
      const direction = event.target.dataset['sortArrow']
        ? event.target.dataset['sortArrow']
        : activeSort.param === sortParam && activeSort.direction === 'asc' ? 'desc' : 'asc';
      return this.activeSort$.next({
        param: sortParam,
        direction: direction
      });
    });
  }

  sortCalcTasks(calcTasks: CalculatedTask[], activeSort: { param: string; direction: 'desc' | 'asc' }) {
    if (activeSort) {
      const infin = activeSort.direction === 'asc' ? -Infinity : Infinity;
      let result;
      if (activeSort.param === 'confidence') {
        this.replaceNotFinit(infin, calcTasks, 'confidence');
        calcTasks.sort((a, b) => b.confidence - a.confidence);
        result = this.replaceNotFinit('?', calcTasks, 'confidence');
      } else if (activeSort.param === 'score') {
        this.replaceNotFinit(infin, calcTasks, 'score');
        calcTasks.sort((a, b) => +b.score - +a.score);
        result = this.replaceNotFinit('?', calcTasks, 'score');
      } else {
        this.replaceNotFinit(infin, calcTasks, 'scoringValuesMap', activeSort.param);
        calcTasks.sort((a, b) => {
          const aCompareVal =
            a.scoringValuesMap.hasOwnProperty(activeSort.param) &&
            Number.isFinite(a.scoringValuesMap[activeSort.param].value)
              ? a.scoringValuesMap[activeSort.param].value
              : infin;
          const bCompareVal =
            b.scoringValuesMap.hasOwnProperty(activeSort.param) &&
            Number.isFinite(b.scoringValuesMap[activeSort.param].value)
              ? b.scoringValuesMap[activeSort.param].value
              : infin;
          return bCompareVal === aCompareVal ? 0 : bCompareVal - aCompareVal; // for preventing case of Infinity - Infinity => NaN
        });
        result = this.replaceNotFinit('?', calcTasks, 'scoringValuesMap', activeSort.param);
      }
      result.map(item => {
        if (Number.isFinite(item.score)) {
          return item;
        }
        item.score = '?';
        return item;
      });
      return activeSort.direction === 'desc' ? result.reverse() : result;
    } else {
      return calcTasks;
    }
  }

  replaceNotFinit(replacer, array, prop, subProp?) {
    if (subProp) {
      return array.map(item => {
        if (!item[prop][subProp] || Number.isFinite(item[prop][subProp].value)) {
          return item;
        } else {
          item[prop][subProp].value = replacer;
          return item;
        }
      });
    } else {
      return array.map(item => {
        if (Number.isFinite(item[prop])) {
          return item;
        } else {
          item[prop] = replacer;
          return item;
        }
      });
    }
  }

  resetSort() {
    this.activeSort$.next('');
  }

  onChangeFilter(event) {
    this.filterValue$.next(event.currentTarget.value);
  }

  onEditTaskCriterion(event) {
    if (event.target.dataset.hasOwnProperty('criterion') && event.target.dataset.hasOwnProperty('task')) {
      const taskId = +event.target.dataset.task;
      const cr = event.target.dataset.criterion === 'confidence' ? 'confidence' : +event.target.dataset.criterion;
      this.tasks$.pipe(take(1)).subscribe((tasks: Task[]) => {
        const taskToEdit = tasks.filter(item => item.id === taskId)[0];
        if (taskToEdit) {
          this.editingTask$.next(taskToEdit);
          this.editingCriterion$.next(cr);
          this.isScoreEditing$.next(true);
        }
      });
    }
  }

  onCloseEditingCrs() {
    this.isScoreEditing$.next(false);
    this.editingTask$.next(null);
    this.editingCriterion$.next(null);
  }

  highlightScrolledTask(id, element) {
    this._renderer.addClass(element, 'scrollHighlightClass');
    this.higlightTimeout = setTimeout(() => {
      const el = document.querySelector('[data-task-id="' + id + '"]');
      if (el) {
        this._renderer.removeClass(el, 'scrollHighlightClass');
      }
    }, 3000);
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
    if (this.higlightTimeout) {
      clearTimeout(this.higlightTimeout);
    }
  }
}
