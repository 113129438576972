<div class="notification_card" (click)="markAsSeen()">
  <div class="viewed_marker" *ngIf="!notification.seen && !notification.archived"></div>
  <a class="task_link_block" *ngIf="hasUrl" [routerLink]="url" (click)="markAsSeen()"></a>
  <div (click)="onArchiveNotification()" class="archive_notif_controll">
  </div>
  <div class="avatar_container">
    <div class="author_avatar">
      <a-avatar class="notification_avatar"
                [user]="user$ | async"
      ></a-avatar>
    </div>
  </div>
  <div class="main_info_container" [ngClass]="{'info_viewed': notification.seen && !notification.archived, 'archived_title': notification.archived}">
    <p *ngIf="hasTitle" class="task_title">
      <ng-content select="[single-notification-preview-title]"></ng-content>
    </p>
    <p class="author_name">
      <span *ngIf="user$ | async">{{(user$ | async)?.fullname}} | </span><span>{{notification.createdAt | timeAgo : "short"}}</span>
    </p>
    <div class="notification_text" (click)="onClick($event)">
      <ng-content select="[single-notification-preview-text]"></ng-content>
    </div>
  </div>
</div>
