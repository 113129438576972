
import {timer as observableTimer, never as observableNever, interval as observableInterval,  Observable ,  ReplaySubject } from 'rxjs';

import {switchMap, takeWhile, startWith, catchError, filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { JsonApiSingeModelResponse } from './web-socket/http-response';
import { AtlazApiV2Service } from '../atlaz-api/v2/atlaz-api-v2.service';
import { QUEUE_JOB, QUEUE_JOB_PL } from './web-socket/web-socket.service';

@Injectable()
export class JobStatusService {
  constructor(private _api: AtlazApiV2Service) {}

  public isJob = (resp: JsonApiSingeModelResponse<any>): boolean =>
    resp.data && !Array.isArray(resp.data) && resp.data.type === QUEUE_JOB;

  public trackJobStatus = (response: JsonApiSingeModelResponse<any>) => {
    const job$ = new ReplaySubject(1);
    const job = response.data;
    let done = false;
    observableInterval(3000).pipe(
      takeWhile(() => !done),
      startWith(0),
      switchMap(_ =>
        this._api
          .get([QUEUE_JOB_PL, job.id]).pipe(
          filter((resp: JsonApiSingeModelResponse<any>) => resp.data.attributes.status === 'done'),
          catchError(() => observableNever()),)
      ),
      takeWhile(() => !done),)
      .subscribe(resp => {
        done = true;
        job$.next(resp);
        job$.complete();
      });

    observableTimer(60 * 1000).pipe(
      takeWhile(() => !done))
      .subscribe(() => {
        done = true;
        job$.error({ code: 'timeout', job: job, message: 'Timeout error.' });
        job$.complete();
      });

    return job$;
  };
}
