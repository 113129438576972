
import {mergeMap, map, switchMap, catchError, tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';

import { TaskAttachment, PartOfEntity } from '../../interfaces';

import { defaultErrorHandler } from './root.effect';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { TaskActivityActionTypes } from '../actions/task-activity.actions';
import { GetCompleteAction } from '../actions/root.action';
import { JsonApiSingeModelResponse, jsonApiToEntityState } from '../../shared/services/app/web-socket/http-response';
import { Model } from '../../shared/services/app/models/index';
import { toDashedFromCamelCase } from '../../../helpers/string';
import { TASK_ACTIVITY_PL } from '../../constants/entity';

@Injectable()
export class TaskActivityEffects {
  private path = toDashedFromCamelCase(TASK_ACTIVITY_PL);

  @Effect({ dispatch: false })
  remove$ = this.actions$
    .ofType(TaskActivityActionTypes.DELETE).pipe(
    switchMap(({ type, payload: comment }: { type: string; payload: TaskAttachment }) => {
      return this._atlazApiV2.deleteRequest([this.path, comment.id]).pipe(catchError(defaultErrorHandler(type, comment)));
    }));

  @Effect()
  add$ = this.actions$
    .ofType(TaskActivityActionTypes.ADD).pipe(
    mergeMap(({ type, payload }: { type: string; payload: PartOfEntity }) => {
      return this._atlazApiV2
        .post(this.path, payload).pipe(
        catchError(defaultErrorHandler(type, payload)),
        tap(e => console.log(e, 'add-task-activity')),
        map((e: JsonApiSingeModelResponse<Model<'tasksActivity'>>) => new GetCompleteAction(jsonApiToEntityState(e))),);
    }));

  @Effect()
  edit$ = this.actions$
    .ofType(TaskActivityActionTypes.EDIT).pipe(
    mergeMap(({ type, payload }: { type: string; payload: PartOfEntity }) => {
      return this._atlazApiV2
        .patch(this.path, payload).pipe(
        catchError(defaultErrorHandler(type, payload)),
        map((e: JsonApiSingeModelResponse<Model<'tasksActivity'>>) => new GetCompleteAction(jsonApiToEntityState(e))),);
    }));

  constructor(private actions$: Actions, private _atlazApiV2: AtlazApiV2Service) {}
}
