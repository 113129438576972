
import {fromEvent as observableFromEvent,  Subscription ,  Observable } from 'rxjs';
import { Directive, Input, ElementRef, OnDestroy, AfterViewInit, NgZone } from '@angular/core';
import { getScrollDelta, mouseWheelEventName } from '../../../helpers/event';

@Directive({
  selector: '[scrollSynchro]'
})
export class ScrollSynchroDirective implements AfterViewInit, OnDestroy {
  @Input() scrollSourceRef;
  private subs: Subscription[] = [];

  constructor(private elementRef: ElementRef, private _zone: NgZone) {}

  ngAfterViewInit() {
    this._zone.runOutsideAngular(() => {
      let sub = observableFromEvent(this.scrollSourceRef, mouseWheelEventName).subscribe((e: MouseWheelEvent) => {
        const { deltaX, deltaY } = getScrollDelta(e);
        e.preventDefault();
        e.stopPropagation();
        this.scrollSourceRef.scrollLeft += deltaX;
        this.scrollSourceRef.scrollTop += deltaY;
      });

      this.subs.push(sub);

      sub = observableFromEvent(this.scrollSourceRef, 'scroll').subscribe((e: any) => {
        this.elementRef.nativeElement.scrollLeft = e.target['scrollLeft'];
        this.elementRef.nativeElement.scrollTop = e.target['scrollTop'];
      });
      this.subs.push(sub);
    });
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
