
import {interval as observableInterval,  Observable } from 'rxjs';

import {startWith} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BugTrackerService } from '@atlaz/core/services/bag-tracker.service';
import { AppState } from '../app/ngrx/state/';
import { AtlazApiV2Service } from '../app/shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { Actions } from '@ngrx/effects';
import { AuthService } from '../app/shared/services/app/auth.service';
import { CompanyService } from '../app/shared/services/app/company.service';
import { Company } from '../app/interfaces/';
import { environment } from '../environments/environment';
import { DateTimeFormatterService } from '../app/libs/date-time-formatter/services/date-time-formatter.service';
import * as Rollbar from 'rollbar';

export let atlazDevtools = {};

window['atlazDevtools'] = atlazDevtools;

@Injectable()
export class AtlazDevtoolsService {
  private customStylesNode;

  constructor(
    private _store: Store<AppState>,
    private _actions$: Actions,
    private _authService: AuthService,
    private _companyService: CompanyService,
    private _atlazApiV2: AtlazApiV2Service,
    private _bugTracker: BugTrackerService,
    private _dateTimeFormatter: DateTimeFormatterService
  ) {}

  init() {
    atlazDevtools['_store'] = this._store;
    atlazDevtools['_apiV2'] = this._atlazApiV2;
    atlazDevtools['_actions$'] = this._actions$;
    atlazDevtools['_authService'] = this._authService;
    atlazDevtools['_companyService'] = this._companyService;
    atlazDevtools['_dateTimeFormatter'] = this._dateTimeFormatter;
    atlazDevtools['devService'] = this;
    atlazDevtools['authErrors'] = [];
    atlazDevtools['environment'] = environment;
    atlazDevtools['trackError'] = this.trackError;
    this._store.subscribe(v => (atlazDevtools['storeState'] = v));
    let withState;
    try {
      if ((withState = localStorage.getItem('AtlazDevtoolsActions'))) {
        if (withState === 'withState') {
          this._actions$.subscribe(action => console.log('action', action, atlazDevtools['storeState']));
        } else {
          this._actions$.subscribe(action => console.log('action', action));
        }
      }
    } catch (e) {
      // nothing
    }

    if (localStorage.getItem('AtlazDevtoolsCustomStyles')) {
      this.customCss = localStorage.getItem('AtlazDevtoolsCustomStyles');
    }
    this.initUserDataForBugTracker();
    this.appCacheChecker();
  }

  public trackError = (level: Rollbar.Level, ...args: Rollbar.LogArgument[]) => {
    return this._bugTracker[level](args);
  };

  public logAuthError(err) {
    atlazDevtools['authErrors'].push(err);
  }

  set customCss(css) {
    try {
      if (this.customStylesNode) {
        this.customStylesNode.parentNode.removeChild(this.customStylesNode);
      }
      const head = document.head || document.getElementsByTagName('head')[0],
        style = document.createElement('style');

      style.type = 'text/css';
      if (style['styleSheet']) {
        style['styleSheet'].cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }

      head.appendChild(style);
      this.customStylesNode = style;
      localStorage.setItem('AtlazDevtoolsCustomStyles', css);
    } catch (e) {
      // nothing
    }
  }

  get customCss() {
    try {
      return localStorage.getItem('AtlazDevtoolsCustomStyles') || '';
    } catch (e) {
      return '';
    }
  }

  initUserDataForBugTracker() {
    this._companyService.currentCompany$.subscribe((company: Company) => {
      this._bugTracker.updateUserData({ company });
    });

    this._authService.activeUser$.subscribe(activeUser => {
      this._bugTracker.updateUserData({ person: activeUser });
    });
  }

  appCacheChecker() {
    const appCache = window.applicationCache;
    try {
      appCache.swapCache();
    } catch (e) {
      console.log(e.toString().split('\n')[0]);
    }

    observableInterval(60 * 1000).pipe(
      startWith(0))
      .subscribe(() => {
        try {
          appCache.update();
        } catch (e) {
          console.log(e.toString().split('\n')[0]);
        }
      });
  }
}
