import { take, filter, map } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observer, Observable } from 'rxjs';
import { RouterNavigateService } from '../../shared/services/router-navigate.service';
import { FormComponent, FormSaveType, FormServiceParams, FormV2Service } from '../../shared/services/form-v2.service';
import { COPY_BOARDS } from '../../path.routing';
import { Board } from '../../interfaces';
import { getBoardById } from '../../ngrx/reducers/board.reducer';
import { AppState } from '../../ngrx/state';
import { Store } from '@ngrx/store';
import { BoardGetAction } from '../../ngrx/actions/board.actions';
import { HandleResponseAction } from '../../ngrx/actions/root.action';
import { ToastrService } from 'ngx-toastr';
import { PaywallService } from '../../libs/paywall/paywall.service';
import { Features } from '../../libs/paywall/features.constants';

@Component({
  templateUrl: './copy-board.component.html',
  styleUrls: ['./copy-board.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyBoardComponent implements FormComponent, OnInit {
  formObserver: Observer<any> = {
    next: resp => {
      this._store.dispatch(new HandleResponseAction(resp));
    },
    error: _ => {},
    complete: () => {
      this._toastr.success('The board copying started. This may take a few seconds for large one');
      this.onClose();
    }
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.add,
    entityToEdit: COPY_BOARDS,
    formObserver: this.formObserver,
    prepareFormValue: (formValue: any) => {
      formValue.board = +this._activatedRoute.snapshot.params['boardId'];
      return formValue;
    }
  };

  form: FormGroup;

  public initialValues$: Observable<Partial<Board>>;
  public isWithTasksAvailable: boolean;

  constructor(
    private _store: Store<AppState>,
    private _toastr: ToastrService,
    public _formService: FormV2Service,
    private _routerNav: RouterNavigateService,
    private _activatedRoute: ActivatedRoute,
    private _paywall: PaywallService
  ) {}

  onClose() {
    return this._routerNav.deactivatePopupOutlet();
  }

  onSubmit() {
    this._formService.initFormParams(this.form, this.formServiceParams);
    this._formService.markAsDirty();
    this.form.markAsDirty();
    if (this.form.valid) {
      this._formService.submit();
    }
  }

  ngOnInit() {
    this.isWithTasksAvailable = this._paywall.isFeatureEnabled(Features.CanAddTask);
    this.initialValues$ = this._store.pipe(
      getBoardById(this._activatedRoute.snapshot.params['boardId']),
      map((board: Board) => {
        if (!board) {
          this._store.dispatch(new BoardGetAction({ id: this._activatedRoute.snapshot.params['boardId'] }));
        }
        return board;
      }),
      filter(board => !!board),
      take(1),
      map((board: Board) => {
        return {
          type: board.type,
          name: 'Copy ' + board.name
        };
      })
    );
  }
}
