import { FormControl, ValidatorFn } from '@angular/forms';

export function validateZeroFactory(): ValidatorFn {
  return (c: FormControl) => {
    return c.value === 0 || c.value === '0'
      ? null
      : {
          zeroValidator: {
            valid: false
          }
        };
  };
}
