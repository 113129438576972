import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScoringPageComponent } from './scoring-page/scoring-page.component';
import { QuickFiltersModule } from '../../containers/header/quick-filters/quick-filters.module';
import { SharedModule } from '../../shared/shared.module';
import { AtlazGuiModule } from '../../atlaz-gui/atlaz-gui.module';
import { RouterModule } from '@angular/router';
import { ScoringTableComponent } from './scoring-table/scoring-table.component';
import { BoardSharedModule } from '../board-shared/board-shared.module';
import { ScoringTypeChooserComponent } from './scoring-type-chooser/scoring-type-chooser.component';
import { ScoringTableIceRiceComponent } from './scoring-table-ice-rice/scoring-table-ice-rice.component';

@NgModule({
  imports: [CommonModule, SharedModule, RouterModule, AtlazGuiModule, QuickFiltersModule, BoardSharedModule],
  declarations: [
    ScoringPageComponent,
    ScoringTableComponent,
    ScoringTypeChooserComponent,
    ScoringTableIceRiceComponent
  ],
  exports: []
})
export class BacklogScoringModule {}
