import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Task } from '../../../interfaces';
import { KeyCode } from '../../../constants/key-code';

@Component({
  selector: 'checklist-form',
  templateUrl: './checklist-form.component.html',
  styleUrls: ['./checklist-form.component.scss']
})
export class ChecklistFormComponent implements OnInit {
  @Input() task: Task;

  @Output() addChecklist: EventEmitter<string> = new EventEmitter<string>();

  public checklistForm: FormGroup;

  constructor(private _fb: FormBuilder) {}

  ngOnInit(): any {
    this.checklistForm = this._fb.group({
      task: [this.task.id],
      name: ['Checklist', Validators.required]
    });
  }

  onSaveOnKey(e: KeyboardEvent) {
    if (e.keyCode === KeyCode.KEY_ENTER) {
      e.preventDefault();
      this.onSubmit();
    }
  }
  onSubmit() {
    this.addChecklist.emit(this.checklistForm.value);
  }
}
