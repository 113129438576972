import { Action } from '../unsafe-action';

import { labelAction } from '../../functions/action';

export const TasksSavedListActionType = {
  SET_TASKS_IDS: labelAction('TASKS_SAVED_LIST', 'SET_TASKS_IDS'),
  RESET: labelAction('TASKS_SAVED_LIST', 'RESET')
};

export class TasksSavedListSetTaskAction implements Action {
  type = TasksSavedListActionType.SET_TASKS_IDS;

  constructor(public payload: number | number[]) {}
}

export class TasksSavedListResetAction implements Action {
  type = TasksSavedListActionType.RESET;
}
