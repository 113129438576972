<div id="notifications-inbox-container">
  <div class="tabs_block">
    <div class="content_title" (click)="onToggleInboxMenu()">
      <span>{{inboxFilterTitle}}</span>
      <span>{{notificationsCount$ | async}}</span>
      <div class="ico_arrow_open"></div>
      <div a-context-menu class="slim inbox_context" *ngIf="isInboxMenuVisible" (close)="onToggleInboxMenu()">
        <p a-context-menu-item (click)="onInboxTab()">Inbox</p>
        <p a-context-menu-item (click)="onArchiveTab()">Archive</p>
      </div>
    </div>
    <div a-context-controll
         *ngIf="!(showArchived$ | async)"
         orientation="vertical"
         class="content_menu"
         [menuSlim]="true"
         [menuRight]="true"
         [isMenuVisible]="isArchiveSelectDropdownVisible"
         (click)="onOpenArchiveSelect()"
    >
      <p a-context-menu-item (click)="onArchiveAllNotifications()" class="remove_item">Archive All</p>
    </div>
  </div>
  <div class="notification_content_container">
      <notifications-list
        [notificationsList]="notifications$ | async"
        [isArchivedFilterActive]="showArchived$ | async"
      ></notifications-list>
      <button a-button class="btn_text"
              *ngIf="(showArchived$ | async) && endOffset === false && ((archivedNotifications$ | async).length !== 0)"
              (click)="onLoadMore()"
      >
        Show more...
      </button>
  </div>
  <div class="message_container"  *ngIf="(notifications$ | async).length === 0 && !(showArchived$ | async)">
    <div class="content_clean_wrap">
      <div class="content_clean">
        <img src="/assets/img/content-clean.png" alt="">
        <h3>Keep your Inbox clean</h3>
        <span>Archive notifications when you’re done with them.</span>
      </div>
    </div>
  </div>
  <div class="message_container"  *ngIf="(notifications$ | async).length === 0 && (showArchived$ | async)">
    <div class="content_clean_wrap">
      <img src="/assets/img/ic-archive-empty-state.png" class="archive_empty" alt="">
      <p>Archived notifications are stored here</p>
    </div>
  </div>
</div>
