export type ModelId = string | number;

export interface Model<T> {
  id: string | number;
  type: T;

  attributes: any;
  relationships: any;
}

export interface Relation<T> {
  id: ModelId;
  type: T;
}

export interface OneToMany<T> {
  data: Relation<T>;
}

export interface ManyToMany<T> {
  data: Relation<T>[];
}

export enum RelationType {
  OneToMany = 1,
  ManyToMany = 2
}

export const getRelationType = relation =>
  Array.isArray(relation.data) ? RelationType.ManyToMany : RelationType.OneToMany;

const checkRelation = (relation, expectedType) => getRelationType(relation) === expectedType;

export const isOneToMany = relation => checkRelation(relation, RelationType.OneToMany);
export const isManyToMany = relation => checkRelation(relation, RelationType.ManyToMany);
