export class TimesheetFilter {
  fromDate: number = null;
  toDate: number = null;
  projectId: number = null;
  userId: number = null;
  groupId: number = null;
  showLogs = false;
  showTasks = false;
  groupUsers: number[] = [];
}
