
    <div chart-tooltip class="task_tooltip"
         *ngIf="(_backlogChart.backlogDraggingTask$ | async)"
         [topPx]="(_backlogChart.backlogDraggingTask$ | async).d3Event.sourceEvent.clientY-25"
         [leftPx]="(_backlogChart.backlogDraggingTask$ | async).d3Event.sourceEvent.clientX-25"
    >
      <svg *ngIf="(_backlogChart.backlogDraggingTask$ | async).isInsideInChart">
        <svg:g drag-task-circle
               [axisXLabel]="axisXLabel"
               [axisYLabel]="axisYLabel"
               [axisXType]="axisXType"
               [axisYType]="axisYType"
               [taskDragging]="_backlogChart.backlogDraggingTask$ | async"
               [attr.transform]="'translate(25, 25)'"></svg:g>
      </svg>
      <div *ngIf="!(_backlogChart.backlogDraggingTask$ | async).isInsideInChart" class="list_item">
        <task-list-item 
          [task]="task$ | async"
          [scaleTypeX]="axisXType"
          [scaleTypeY]="axisYType"
        ></task-list-item>
      </div>
    </div>
  