<div a-context-popup
  [title]="'Create New Project'"
  (close)="onClose()"
>
  <create-new-project-form [submit$]="submit$"
                           [source]="'header'"
                           (isValidForm)="isValidForm = $event"
                           (closePopup)="onClose()"
  ></create-new-project-form>
  <div class="import_project_link">
    <span>or </span><a [routerLink]="companySettingsRouterLink" target="_blank" rel="noopener">Import Project</a>
  </div>
  <create-new-project-popup-buttons [isValidForm]="isValidForm"
                                    (cancel)="onClose()"
                                    (submit)="submit$.next()"
  ></create-new-project-popup-buttons>
</div>
