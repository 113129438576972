<div *ngIf="(isSearchActive$ | async) && !(isMatchingSearch$ | async)" class="search-not-found">
  <span>For request: <b>{{stringSearch$ | async}}</b> nothing is found</span>
</div>

<div *ngIf="!(isSearchActive$ | async) || (isSearchActive$ | async) && (isMatchingSearch$ | async)">
  <ul class="static_links">
    <li (click)="onSetVisibleLeftMenu(false)" [routerLink]="appUrls.getUrlOverview()">
      <svg width="18" height="18">
        <use xlink:href="#bell"></use>
      </svg>
      <span>
        Inbox
      </span>
      <div class="notifications_marker" *ngIf="notificationsCount$ | async">
        <span>{{notificationsCount$ | async}}</span>
      </div>
    </li>
    <li (click)="onClickMyTasksToDo()">
      <svg width="18" height="18">
        <use xlink:href="#my-tasks"></use>
      </svg>
      <a>My Tasks</a>
    </li>
    <li (click)="onSetVisibleLeftMenu(false)" [routerLink]="'/reporting'">
      <svg viewBox="0 0 6 9" width="18" height="21">
        <use xlink:href="#report"></use>
      </svg>
      <a>Reports</a>
    </li>
  </ul>

  <div *ngIf="!(isSearchActive$ | async)">
    <left-default-collection-item id="favorites"></left-default-collection-item>
    <left-default-collection-item id="recent"></left-default-collection-item>
  </div>

  <div class="collections_wrapper" [class.closed_state]="!isCollectionsOpened">
    <div class="collection_title" (click)="onToggleCollections()">
      <h3>COLLECTIONS</h3>
      <div class="arrow_state_icon" [class.closed_state]="!isCollectionsOpened">
        <svg width="18" height="18">
          <use xlink:href="#triangle"></use>
        </svg>
      </div>
    </div>
    <ng-container *ngIf="isCollectionsOpened">
      <div *ngFor="let item of collections$ | async" class="collection_list">
        <left-collection-item
          [class.opened]="item.visibleBoard"
          [isNotGuest]="isNotGuest"
          [collection]="item.collection"
          [visibleBoards]="item.visibleBoard"
        ></left-collection-item>
      </div>
      <div class="add_new_wrapper" *ngIf="isNotGuest && isCollectionsOpened && (isSuperUser$ | async)">
        <div class="add_new" [routerLink]="appUrls.getUrlNewCollection()">
          <div class="add_icon">
            <svg width="18" height="18">
              <use xlink:href="#add-inverted"></use>
            </svg>
          </div>
          <span>Add Collection</span>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="collections_wrapper" [class.closed_state]="!isProjectsOpened">
    <div class="collection_title" (click)="onToggleProjects()">
      <h3>PROJECTS</h3>
      <div class="arrow_state_icon" [class.closed_state]="!isProjectsOpened">
        <svg width="18" height="18">
          <use xlink:href="#triangle"></use>
        </svg>
      </div>
    </div>
    <ng-container *ngIf="isProjectsOpened">
      <div *ngFor="let item of projects$ | async" class="collection_list">
        <left-project-item
          [class.opened]="item.visibleBoard"
          [project]="item.project"
          [visibleBoards]="item.visibleBoard"
        ></left-project-item>
      </div>
      <div class="add_new_wrapper" *ngIf="isNotGuest && isProjectsOpened && (isSuperUser$ | async)">
        <div class="add_new" [routerLink]="appUrls.getUrlNewProject()">
          <div class="add_icon">
            <svg width="18" height="18">
              <use xlink:href="#add-inverted"></use>
            </svg>
          </div>
          <span>Add Project</span>
        </div>
      </div>
    </ng-container>
  </div>

  <div *ngIf="(unassign$ | async)?.length > 0">
    <unassigned-boards-list
      [unassignBoards]="unassign$ | async"
    ></unassigned-boards-list>
  </div>
</div>
