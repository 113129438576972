
import {switchMap} from 'rxjs/operators';
import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { getUsersByIds } from '../../../ngrx/reducers/user.reducer';
import { User } from '../../../interfaces/user';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state/app-state';
import { Observable ,  ReplaySubject } from 'rxjs';

@Component({
  selector: 'user-multiple-avatars-wrapper',
  template: `<a-multiple-avatars [users]="users$ | async" [showDetails]="showDetails"></a-multiple-avatars>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserMultipleAvatarsWrapperComponent implements OnInit {
  @Input() showDetails = true;

  @Input()
  set userIds(value) {
    this.usersIds$.next(value);
  }
  readonly usersIds$: ReplaySubject<number[]> = new ReplaySubject(1);

  public users$: Observable<User[]>;

  constructor(private _store: Store<AppState>) {}

  ngOnInit(): any {
    this.users$ = this.usersIds$.pipe(switchMap(userIds => this._store.pipe((getUsersByIds(userIds)))));
  }
}
