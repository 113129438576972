import { Action } from '../unsafe-action';

import { labelAction } from '../../functions/action';
import { ScoringType } from '../../../constants';
import { BoardViewMode } from '../../reducers/gui-state-memorized.reducer';

export interface DefaultTaskFilterPayload {
  filterID: string;
}

export interface DaysRange {
  moreThan: number;
  lessThan: number;
}

export interface FiltersInLocalStorage {
  uncustomizable?: string[];
  defaultMembersFilter?: number[];
  defaultLabelsFilter?: number[];
  defaultColumnsFilter?: number[];
  defaultSwimlanesFilter?: number[];
  defaultVersionsFilter?: number[];
  defaultProjectsFilter?: number[];
  defaultStatusesFilter?: string[];
  defaultColorsFilter?: string[];
  defaultQuadsFilter?: number[];
  defaultTimeOnBoardFilter?: DaysRange;
  defaultTimeOnColumnFilter?: DaysRange;
  quickFilters?: number[];
}

export const TaskDefaultFilterActionTypes = {
  ADD_FILTER: labelAction('TASK_DEFAULT_FILTER', 'ADD_FILTER'),
  REMOVE_FILTER: labelAction('TASK_DEFAULT_FILTER', 'REMOVE_FILTER'),
  TOGGLE: labelAction('TASK_DEFAULT_FILTER', 'TOGGLE'),
  RESET: labelAction('TASK_DEFAULT_FILTER', 'RESET'),
  INIT: labelAction('TASK_DEFAULT_FILTER', 'INIT'),
  SET: labelAction('TASK_DEFAULT_FILTER', 'SET')
};

export class TaskDefaultFilterInitAction implements Action {
  type = TaskDefaultFilterActionTypes.INIT;

  constructor(public payload: { boardType: string; scoringType: ScoringType; boardView: BoardViewMode }) {}
}

export class TaskDefaultFilterAddAction implements Action {
  type = TaskDefaultFilterActionTypes.ADD_FILTER;

  constructor(public payload: DefaultTaskFilterPayload) {}
}

export class TaskDefaultFilterRemoveAction implements Action {
  type = TaskDefaultFilterActionTypes.REMOVE_FILTER;

  constructor(public payload: DefaultTaskFilterPayload) {}
}

export class TaskDefaultFilterToggleAction implements Action {
  type = TaskDefaultFilterActionTypes.TOGGLE;

  constructor(public payload: DefaultTaskFilterPayload) {}
}

export class TaskDefaultFilterResetAction implements Action {
  type = TaskDefaultFilterActionTypes.RESET;
}

export class TaskDefaultFilterSetAction implements Action {
  type = TaskDefaultFilterActionTypes.SET;
  constructor(
    public payload: {
      activeFilters: FiltersInLocalStorage;
      type?: string;
      scoringType?: ScoringType;
      boardView?: BoardViewMode;
    }
  ) {}
}

export type TaskDefaultFilterActions =
  | TaskDefaultFilterAddAction
  | TaskDefaultFilterInitAction
  | TaskDefaultFilterRemoveAction
  | TaskDefaultFilterToggleAction
  | TaskDefaultFilterResetAction
  | TaskDefaultFilterSetAction;
