<a class="task_preview_link"
   draggable="false"
   [ngClass]="{'task-link': isTaskLink}"
   [routerLink]="item.url"
   [class.showHover]=" (isHoverOnTask$ | async)"
   [class.show_quick_edit]="isNotGuest && !item.isProject"
   [roadmapDropContainer]="item"
   (click)="onClickLink()"
   (dragstart)="$event.preventDefault()"
>
  <div [class]="statusIconClass$ | async" [title]="statusTooltip$ | async"></div>
  <div class="title">
    <div #titleText>
      {{task.title}}
      <div class="full_title" *ngIf="titleText.offsetHeight > 55" a-tooltip="{{task.title}}">show more</div>
    </div>
  </div>
  <icon-link-task *ngIf="isTaskLink || isProjectLink"></icon-link-task>
  <div class="users_wrapper">
    <user-multiple-avatars-wrapper *ngIf="usersIds && usersIds.length" [userIds]="usersIds" [showDetails]="false"></user-multiple-avatars-wrapper>
  </div>
  <div
    *ngIf="isNotGuest"
    class="menu-block"
    contextMenuAlign="right"
    contextMenuTrigger
    [contextMenuContent]="taskMenu"
  >
  </div>
  <div class="percent" (click)="onClickPercent($event, task)">
    <task-percent-inplace-edit
      [isEdit]="isEditPercent"
      [task]="task"
      (close)="onClosePercentEdit()"
    ></task-percent-inplace-edit></div>
  <div class="quick_edit_button" (click)="onOpenQuickActions($event)"></div>
  <div class="quick_edit_button delete_btn" (click)="onDeleteOriginalOrLinkTask($event)"></div>
  <div class="drop_line"></div>
</a>

<a-context-menu-content #taskMenu>
  <a-menu>
    <p a-context-menu-item *ngIf="isTaskLink" (click)="onShowDeleteConfirmationDialog()">Delete Task</p>
    <p a-context-menu-item *ngIf="!isTaskLink" (click)="onShowChangeStatusDialog()">Change Status</p>
    <p a-context-menu-item (click)="onAddNew(RoadMapAddFormTypes.task)">Insert Task Below</p>
    <p a-context-menu-item (click)="onAddNew(RoadMapAddFormTypes.taskLink)">Insert Link Below</p>
    <p a-context-menu-item *ngIf="!item.parent" (click)="onAddNew(RoadMapAddFormTypes.group)">Insert Group Below</p>
    <p a-context-menu-item (click)="onAddNew(RoadMapAddFormTypes.project)">Insert Project Below</p>
  </a-menu>
</a-context-menu-content>

<a-show-in-portal *ngIf="deleteOriginalTask">
  <div a-confirmation-popup
       [confirmationTitle]="'Delete Task'"
       [actionButtonText]="'Delete'"
       (confirm)="onDeleteOriginalTaskConfirm()"
       (close)="onCloseDeleteOriginalPopup()"
  >
    <p>Are You Sure You Want to Delete this task?</p>
    <p>All task data including comments, attachments and statistics will be lost.</p>
    <p>There is no undo.</p>
  </div>
</a-show-in-portal>
<a-show-in-portal *ngIf="showDeleteConfirmDialog$ | async">
  <div a-confirmation-popup
       [confirmationTitle]="'Delete task link'"
       [actionButtonText]="'Delete'"
       (confirm)="onDeleteTask(item.id)"
       (close)="onCloseDeleteConfirmDialog()"
  >
    <p>Remove this task from Roadmap?<br/> The task will be available at the original board.</p>
  </div>
</a-show-in-portal>
<a-show-in-portal *ngIf="showStatusChangeDialog$ | async">
  <a-center-popup
    *ngIf="!item.isProject;else projStatusPopup"
    [title]="'Change Task Status'"
    (close)="onCloseChangeStatusDialog()"
  >
    <div class="popup_body">
      <div class="roadmap-task-status">
        <select [formControl]="taskStatusControl">
          <option *ngFor="let column of  boardColumns$ | async" [value]="column.id">{{column.name}}</option>
        </select>
      </div>
      <div class="buttons_block">
        <button a-button class="btn_primary" (click)="onSaveStatus()">Save</button>
        <button a-button class="btn_text" (click)="onCloseChangeStatusDialog()" >Cancel</button>
      </div>
    </div>
  </a-center-popup>
</a-show-in-portal>

<ng-template #projStatusPopup>
  <a-center-popup [title]="'Change Project Status'" (close)="onCloseChangeStatusDialog()">
    <project-status-editing [project]="project$ | async" (close)="onCloseChangeStatusDialog()"></project-status-editing>
  </a-center-popup>
</ng-template>
