import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Swimlane } from '../../../../interfaces';

@Component({
  selector: 'swimlanes-filter-list',
  templateUrl: './swimlanes-filter-list.component.html',
  styleUrls: ['./swimlanes-filter-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwimlanesFilterListComponent implements OnInit {
  @Input() swimlanes: Swimlane[];
  @Input() selectedSwimlanes: Swimlane[];

  @Output() addSwimlane = new EventEmitter();
  @Output() removeSwimlane = new EventEmitter();

  public checkSelectedSwimlane: { [id: number]: boolean } = {};

  constructor() {}

  ngOnInit() {
    this.selectedSwimlanes.forEach((swimlane: Swimlane) => {
      this.checkSelectedSwimlane[swimlane.id] = true;
    });
  }

  onSetFilter(swimlane: Swimlane) {
    if (!this.checkSelectedSwimlane[swimlane.id]) {
      this.checkSelectedSwimlane[swimlane.id] = true;
      this.addSwimlane.emit(swimlane);
    } else {
      this.checkSelectedSwimlane[swimlane.id] = false;
      this.removeSwimlane.emit(swimlane);
    }
  }
}
