import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'a-context-menu-content',
  template: `
    <ng-template #template>
      <div (click)="onClose()">
        <a-overlay class="transparent" tabindex="1"></a-overlay>
        <a-context-container [usedInPortal]="true">
          <ng-content></ng-content>
        </a-context-container>
      </div>
    </ng-template>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * experimental
 */
export class AContextMenuContentComponent {
  @ViewChild('template') public template: TemplateRef<any>;

  public close$ = new Subject();

  onClose() {
    this.close$.next();
  }
}
