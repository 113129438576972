import { ESGithubAssociatedRepository } from '../../models/git-repository';
import { Action } from '../../../../ngrx/actions/unsafe-action';

export const CREATE = '[github-associated-repository.actions] CREATE';
export const UPDATED = '[github-associated-repository.actions] UPDATED GITHUB REPOSITORYS';
export const GET_INDEX = '[github-associated-repository.actions] GET_INDEX';
export const GET_INDEX_COMPLETE = '[github-associated-repository.actions] GET_INDEX_COMPLETE';
export const REMOVE = '[github-associated-repository.actions] REMOVE';
export const GET_INDEX_FAIL = '[github-associated-repository.actions] GET_INDEX_FAIL';

export class GetIndex implements Action {
  readonly type = GET_INDEX;
}

export class Updated implements Action {
  readonly type = UPDATED;

  constructor(public payload: ESGithubAssociatedRepository) {}
}

export class GetIndexComplete implements Action {
  readonly type = GET_INDEX_COMPLETE;

  constructor(public payload: ESGithubAssociatedRepository) {}
}

export class GetIndexFail implements Action {
  readonly type = GET_INDEX_FAIL;

  constructor(public payload?: any) {}
}

export class Remove implements Action {
  readonly type = REMOVE;

  constructor(public payload: { id: number }) {}
}

export class Create implements Action {
  readonly type = CREATE;

  constructor(public payload: { repositoryId: number; repositoryName: string; githubCompany: number }) {}
}

// prettier-ignore
export type Actions
  = GetIndex
  | Updated
  | GetIndexComplete
  | GetIndexFail
  | Remove
  | Create
  ;
