<form [formGroup]="checklistForm">
  <label for="checklist_input">Title</label>
  <input [formControl]="checklistForm.get('name')"
         [selectText]="true"
         (keydown)="onSaveOnKey($event)"
         autocomplete="off"
         type="text"
         id="checklist_input"
  />
  <div a-button class="btn_primary" (click)="onSubmit()" [disabled]="!checklistForm.valid">Add Checklist</div>
</form>
