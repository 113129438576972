
import {combineLatest, map, switchMap, distinctUntilChanged, pluck, filter} from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable ,  BehaviorSubject } from 'rxjs';
import { Board, Version } from '../../../../interfaces';
import { AppState } from '../../../../ngrx/state/';
import { Store } from '@ngrx/store';
import { getBoardById } from '../../../../ngrx/reducers/board.reducer';
import { AppUrls } from '../../../../app-urls';
import { SprintsTasks } from '../../../../interfaces/sprints-tasks';
import { WorkingDaysService } from '@atlaz/working-days/services/working-days.service';
import { EstimationType } from '../../../../constants';
import { fieldSum, getSprintsTaskCompletedSec, isPresent } from '../../../../../helpers';
import { getSprintTasksByVersionId } from '../../../../ngrx/reducers/version.reducer';

const isCompleted = task => task.doneDate > 0;
const isInComplete = task => !task.doneDate;

const isCompleteTasksFilter = list => list.filter(isCompleted);
const isInCompleteTasksFilter = list => list.filter(isInComplete);

@Component({
  selector: 'release-details',
  templateUrl: './release-details.component.html',
  styleUrls: ['./release-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReleaseDetailsComponent implements OnInit {
  @Input() version$: Observable<Version>;

  public versionId$: Observable<number>;
  public board$: Observable<Board>;
  public backUrl$: Observable<any>;
  public workingDaysDuration$: Observable<number>;

  public sprintTasks$: Observable<SprintsTasks[]>;

  public completedTasks$: Observable<SprintsTasks[]>;
  public inCompleteTasks$: Observable<SprintsTasks[]>;

  public filteredCompletedTasks$: Observable<SprintsTasks[]>;
  public filteredInCompleteTasks$: Observable<SprintsTasks[]>;

  public storyPointsEstimated$: Observable<number>;
  public storyPointsCompleted$: Observable<number>;

  public hoursEstimated$: Observable<number>;
  public hoursCompleted$: Observable<number>;
  public hoursLogged$: Observable<number>;

  public appUrls = AppUrls;
  public estimationType = EstimationType;
  public mode: EstimationType = EstimationType.storyPoints;

  public estimatedBy$: Observable<EstimationType>;

  private filter$ = new BehaviorSubject('');

  constructor(private _store: Store<AppState>, private _workingDays: WorkingDaysService) {}

  ngOnInit() {
    this.estimatedBy$ = <Observable<EstimationType>>this.version$.pipe(
      filter(isPresent),
      pluck('estimatedBy'),
      distinctUntilChanged(),);

    this.versionId$ = <Observable<number>>this.version$.pipe(
      filter(isPresent),
      pluck('id'),
      distinctUntilChanged(),);
    this.board$ = this.version$.pipe(switchMap(version => this._store.pipe((getBoardById(version.board)))));
    this.workingDaysDuration$ = this.version$.pipe(map(version =>
      this._workingDays.workingDaysBetween(version.startDate, version.releasedDate)
    ));
    this.backUrl$ = this.version$.pipe(map(version => AppUrls.getUrlSprintBoardHistory(version.board)));
    this.sprintTasks$ = this.versionId$.pipe(switchMap(versionId =>
      this._store.select(getSprintTasksByVersionId(versionId))
    ));

    this.completedTasks$ = this.sprintTasks$.pipe(map(isCompleteTasksFilter));
    this.inCompleteTasks$ = this.sprintTasks$.pipe(map(isInCompleteTasksFilter));

    const filteredTasks$ = this.sprintTasks$.pipe(combineLatest(this.filter$, (tasks: SprintsTasks[], filterValue: string) =>
      tasks.filter(task => task.historyTitle.indexOf(filterValue) !== -1)
    ));

    this.filteredCompletedTasks$ = filteredTasks$.pipe(map(isCompleteTasksFilter));
    this.filteredInCompleteTasks$ = filteredTasks$.pipe(map(isInCompleteTasksFilter));

    this.storyPointsEstimated$ = this.sprintTasks$.pipe(map(fieldSum('storyPoints')));
    this.storyPointsCompleted$ = this.completedTasks$.pipe(map(fieldSum('storyPoints')));

    const toHours = (seconds: number) => Math.round(seconds / 3600);
    this.hoursEstimated$ = this.sprintTasks$.pipe(map(fieldSum('remainingEstimate')),map(toHours),);
    this.hoursCompleted$ = this.sprintTasks$.pipe(
      map((sprintsTasks: SprintsTasks[]) =>
        sprintsTasks.reduce((acc, item) => acc + getSprintsTaskCompletedSec(item), 0)
      ),
      map(toHours),);
    this.hoursLogged$ = this.sprintTasks$.pipe(map(fieldSum('logged')),map(toHours),);
  }

  onChangeFilterValue(filter: string) {
    this.filter$.next(filter);
  }
}
