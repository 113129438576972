import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'columns-width-helper',
  templateUrl: './columns-width-helper.component.html',
  styleUrls: ['./columns-width-helper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnsWidthHelperComponent {
  @Input() columnsByTypes: string[];
}
