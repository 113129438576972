<div #advancedSearch>
  <form #formRef="ngForm">
    <h2>Advanced</h2>
    <p class="reset_button" (click)="onReset()">Reset</p>

    <div class="members_block">
      <h3>Members</h3>
      <user-input-picker
        [selectedUsers]="selectedUsers$"
      ></user-input-picker>
    </div>

    <div class="filter_value_block">
      <h3>Projects</h3>
      <project-input-picker
        [selectedProjects]="selectedProjects$"
      ></project-input-picker>
    </div>

    <div class="filter_value_block">
      <h3>Board</h3>
      <board-input-picker
        [selectedBoards]="selectedBoards$"
      ></board-input-picker>
    </div>

    <version-filter
      class="version_value_block"
      [selectedProjects]="selectedProjects$"
      [selectedVersionsIds]="selectedVersions$"
    ></version-filter>

  <div class="status_value_block">
    <h3>Status</h3>
    <a-multiselect
      name="status"
      [items]="statusOptions"
      [selectAllOnInit]="false"
      [(ngModel)]="formValues['status']"
    >
    </a-multiselect>
  </div>

    <div class="duedate_value_block">
      <h3>Due Date</h3>
      <div class="overlay_host">
        <select name="dueDate" [(ngModel)]="formValues['dueDate']" [disabled]="!isAdvancedSearchEnabled">
          <option *ngFor="let option of dueDateOptions" [ngValue]="option.id">
            {{option.text}}
          </option>
        </select>
        <div class="block_overlay" (click)="onClickAdvanced($event)" *ngIf="!isAdvancedSearchEnabled"></div>
      </div>
    </div>


    <div class="filter_value_block">
      <label class="h3">
        <span class="checkbox" [ngClass]="{'checked_checkbox': formValues['hideArchived']}"></span>
        <span>Hide Archived Tasks</span>
        <input type="checkbox" name="hideArchived" [(ngModel)]="formValues['hideArchived']">
      </label>
    </div>

    <div class="filter_value_block">
      <label class="h3">
        <span class="checkbox" [ngClass]="{'checked_checkbox': formValues['hideReleased']}"></span>
        <span>Hide Released Tasks</span>
        <input type="checkbox" name="hideReleased" [(ngModel)]="formValues['hideReleased']">
      </label>
    </div>
  </form>
</div>
