import { Action } from '../unsafe-action';

export enum DefaultTasksProjectsFilterActionTypes {
  TOGGLE_ITEM_ID = '[DEFAULT_PROJECTS_FILTER] TOGGLE_VERSION_IDS',
  RESET = '[DEFAULT_PROJECTS_FILTER] RESET'
}

export class DefaultTaskProjectsFilterToggleItemAction implements Action {
  readonly type = DefaultTasksProjectsFilterActionTypes.TOGGLE_ITEM_ID;

  constructor(public payload: number[]) {}
}

export class DefaultProjectsFilterResetAction implements Action {
  readonly type = DefaultTasksProjectsFilterActionTypes.RESET;
}

// prettier-ignore
export type Actions
  = DefaultTaskProjectsFilterToggleItemAction
  | DefaultProjectsFilterResetAction
  ;
