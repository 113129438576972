import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Board } from '../../interfaces';
import { ScoringType } from '../../constants';
import { RouterNavigateService } from '../../shared/services/router-navigate.service';
import { Actions } from '@ngrx/effects';
import { BoardActionTypes } from '../../ngrx/actions/board.actions';
import { Subscription } from 'rxjs/index';

@Component({
  selector: 'right-menu-advanced-scoring',
  templateUrl: './right-menu-advanced-scoring.component.html',
  styleUrls: ['./right-menu-advanced-scoring.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightMenuAdvancedScoringComponent implements OnInit {
  @Input() board: Board;
  @Output() showAdvancedScoringSettings = new EventEmitter();

  subs: Subscription[] = [];

  get modeName() {
    if (!this.board) {
      return 'Off';
    }
    switch (this.board.scoringType) {
      case ScoringType.advanced: {
        return 'Weighted Scoring';
      }
      case ScoringType.ICE: {
        return 'ICE';
      }
      case ScoringType.RICE: {
        return 'RICE';
      }
      case ScoringType.basic: {
        return '2x2 Priority Matrix';
      }
      default: {
        return 'Off';
      }
    }
  }

  constructor(private _routerNav: RouterNavigateService, private actions$: Actions) {}

  ngOnInit() {
    this.subs.push(
      this.actions$.ofType(BoardActionTypes.OPEN_SCORING_SETTINGS).subscribe(() => {
        this.showAdvancedScoringSettings.next(this.board);
      })
    );
  }

  showSettingsForm() {
    if (this.board.scoringType === ScoringType.off) {
      this._routerNav.navigateToScoringTypeChooser();
    }
    this.showAdvancedScoringSettings.next(this.board);
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
