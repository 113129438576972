import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

const INITIAL = 'initial';
const animationDuration = 350;
const animationDestroyDelay = 2500;

@Component({
  selector: 'copy-text',
  templateUrl: './copy-text.component.html',
  styleUrls: ['./copy-text.component.scss'],
  animations: [
    trigger('copyTextState', [
      state(INITIAL, style({})),
      transition(
        '* => ' + INITIAL,
        animate(
          animationDuration,
          keyframes([
            style({ opacity: 0, transform: 'translateY(-100px)', offset: 0 }),
            style({ opacity: 1, transform: 'translateY(25px)', offset: 0.7 }),
            style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 })
          ])
        )
      )
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyTextComponent implements OnInit, AfterViewInit {
  @Input() textToCopy: string;
  @Input() title = 'Copied:';
  @Output() close = new EventEmitter();

  public state: string;

  constructor() {}

  ngOnInit() {
    this.state = INITIAL;
  }

  ngAfterViewInit() {
    const elementWithText = document.querySelector('#text_to_copy_element');
    const range = document.createRange();

    range.selectNode(elementWithText);

    const selected = window.getSelection();

    selected.removeAllRanges();
    selected.addRange(range);

    elementWithText.innerHTML.trim();

    document.execCommand('copy');
  }

  onCompleteAnimation() {
    setTimeout(() => {
      this.onCloseCopyText();
    }, animationDestroyDelay);
  }

  onCloseCopyText() {
    this.close.emit();
  }
}
