import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'loggedTime'
})
export class LoggedTimePipe implements PipeTransform {
  transform(value) {
    if (Number.isInteger(value)) {
      return humanizeLoggedTime(value);
    }

    const result: string = value.replace(
      /\[time:(\d*?)\]/gi,
      (_, timeToReplace) => '<b>' + humanizeLoggedTime(+timeToReplace) + '</b>'
    );
    return result;
  }
}

function toString(hours: number, minutes: number, seconds: number): string {
  const hoursLabel = hours !== 0 ? hours + 'h' : '';
  const minutesLabel = minutes !== 0 ? minutes + 'm' : '';
  const secondsLabel = seconds !== 0 ? seconds + 's' : '';

  return `${hoursLabel} ${minutesLabel} ${secondsLabel}`;
}

export function humanizeLoggedTime(seconds: number): string {
  const directiconSign = seconds > 0 ? '' : '-';

  const hours = Math.floor(Math.abs(seconds) / 3600);
  const minutes = Math.ceil(Math.abs(seconds) / 60) % 60;
  seconds = Math.ceil(Math.abs(seconds) % 60);

  const result = toString(hours, minutes, seconds);

  return directiconSign + result;
}
