import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: '[sub-header]',
  template: `
  <ng-content></ng-content>
  `,
  styleUrls: ['sub-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubHeaderComponent {}
