import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../../shared/services/form-v2.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TASKS_LINK_PL } from '../../../constants';
import { fromCamelToDash } from '../../../../helpers';

@Component({
  selector: 'task-link-delete-form',
  templateUrl: './task-link-delete-form.component.html',
  styleUrls: ['./task-link-delete-form.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskLinkDeleteFormComponent implements OnInit {
  @Input() id: number;
  @Output() success = new EventEmitter();
  @Output() cancel = new EventEmitter();

  public form: FormGroup;
  public formServiceParams: FormServiceParams;

  constructor(public _formService: FormV2Service, private _fb: FormBuilder) {}

  ngOnInit() {
    this.form = this._fb.group({
      id: [this.id]
    });

    this.formServiceParams = {
      formObserver: {
        next: _ => {
          this.success.emit();
        },
        error: _ => {},
        complete: () => {}
      },
      saveType: FormSaveType.remove,
      entityToEdit: fromCamelToDash(TASKS_LINK_PL)
    };

    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  onSubmit() {
    this._formService.submit();
  }

  onCancel() {
    this.cancel.emit();
  }
}
