import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'payment-success-popup',
  templateUrl: './payment-success-popup.component.html',
  styleUrls: ['./payment-success-popup.component.scss']
})
export class PaymentSuccessPopupComponent {
  @Output() closePopup = new EventEmitter();

  onClosePopup() {
    this.closePopup.emit();
  }
}
