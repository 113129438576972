import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormV2Service } from '../../services/form-v2.service';

@Component({
  selector: 'create-new-project-popup-buttons',
  templateUrl: './create-new-project-popup-buttons.component.html',
  styleUrls: ['./create-new-project-popup-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateNewProjectPopupButtonsComponent {
  @Input() isValidForm = false;

  constructor(public _formService: FormV2Service) {}

  @Output() submit: EventEmitter<boolean> = new EventEmitter();
  @Output() cancel: EventEmitter<boolean> = new EventEmitter();

  onSubmit() {
    this.submit.emit(true);
  }

  onCancel() {
    this.cancel.emit(true);
  }
}
