import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Board } from '../../interfaces';
import { Observable } from 'rxjs/Observable';
import { BoardFilterInfo } from '../../interfaces/board';
import { ActivatedRoute } from '@angular/router';
import { AppState } from '../../ngrx/state';
import { Store } from '@ngrx/store';
import { fromBoards } from '../../ngrx/reducers/board.reducer';
import { filter, take } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { BoardViewMode } from '../../ngrx/reducers/gui-state-memorized.reducer';
import { RoadmapScrollService } from '../roadmap-board/services/roadmap-scroll.service';
import { isPresent } from '../../../helpers';
import { IntercomService } from '../../atlaz-bnp/services/intergations/intercom/intercom.service';

@Component({
  selector: 'board-pdf-export',
  templateUrl: './board-pdf-export.component.html',
  styleUrls: ['./board-pdf-export.component.scss']
})
export class BoardPdfExportComponent implements OnInit, AfterViewInit {
  public board: Board;
  public boardInfo$: Observable<BoardFilterInfo>;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _store: Store<AppState>,
    private _roadmapScrollService: RoadmapScrollService,
    private _intercom: IntercomService
  ) {}

  ngOnInit() {
    this._intercom.hideChatIcon();
    this._store
      .select(fromBoards.get(+this._activatedRoute.snapshot.params.boardId))
      .pipe(take(1))
      .subscribe(board => {
        this.board = board;
        this.boardInfo$ = of({
          id: board.id,
          type: board.type,
          scoringType: board.scoringType,
          boardView: BoardViewMode.board,
          showProps: board.showProps
        });
      });
    this._roadmapScrollService.scrollWasSetToToday$.pipe(filter(isPresent), take(1)).subscribe(_ => {
      setTimeout(() => {
        const roadmapPaintFinishedEl = document.createElement('div');
        roadmapPaintFinishedEl.setAttribute('id', 'roadmap-paint-finished');
        document.body.appendChild(roadmapPaintFinishedEl);
        const chartWidth = document.querySelector('day-items').scrollWidth + 580;
        const scale = document.documentElement.clientWidth / chartWidth;
        const el = document.querySelector('roadmap-board-detail');
        el['style'].width = chartWidth + 'px';
        el['style'].transform = 'scale(' + scale + ')';
      }, 1000);
    });
  }
  ngAfterViewInit() {
    document.querySelector('app-header')['style'].display = 'none';
    document.querySelector('app-body')['style'].top = 0;
    document.querySelector('board-quick-filters')['style'].visibility = 'hidden';
  }
}
