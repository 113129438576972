import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';

import * as d3 from 'd3';

@Directive({
  selector: '[d3Responsivefy]'
})
export class D3AxisResponsivefy implements AfterViewInit, OnDestroy {
  @Input() d3Axis;

  private container;

  constructor(private _element: ElementRef) {}

  ngAfterViewInit() {
    d3.select(this._element.nativeElement).call(this.responsivefy);
  }

  private responsivefy = svg => {
    // get container + svg aspect ratio
    const container = d3.select(svg.node().parentNode),
      width = parseInt(svg.style('width'), 10),
      height = parseInt(svg.style('height'), 10),
      aspect = width / height;

    this.container = container;

    // add viewBox and preserveAspectRatio properties,
    // and call resize so that svg resizes on inital page load
    svg
      .attr('viewBox', '0 0 ' + width + ' ' + height)
      .attr('preserveAspectRatio', 'xMinYMin')
      .call(resize);

    // to register multiple listeners for same event type,
    // you need to add namespace, i.e., 'click.foo'
    // necessary if you call invoke this function for multiple svgs
    // api docs: https://github.com/mbostock/d3/wiki/Selections#on
    d3.select(window).on('resize.' + container.attr('id'), resize);
    // get width of container and resize svg to fit it
    function resize() {
      const targetWidth = parseInt(container.style('width'), 10);
      const targetHeight = parseInt(container.style('height'), 10);
      const coefScale = 1.29;
      const svgHeight = targetWidth / coefScale;

      svg.attr('width', Math.round(targetHeight * coefScale - 10));
      svg.attr('height', targetHeight);
    }
  };

  ngOnDestroy() {
    d3.select(window).on('resize.' + this.container.attr('id'), null);
  }
}
