import { Component, OnInit, Input } from '@angular/core';
import { SprintBoard } from '../../../../interfaces/boards/sprint-board';
import { AppUrls } from '../../../../app-urls';
import { Router } from '@angular/router';
import { PermissionsService } from '../../../../permissions/permissions.service';

@Component({
  selector: 'sprint-board-controls',
  templateUrl: './sprint-board-controls.component.html',
  styleUrls: ['./sprint-board-controls.component.scss']
})
export class SprintBoardControlsComponent implements OnInit {
  @Input() board: SprintBoard;

  public isNotGuest$ = this._permissions.isNotGuest$;
  constructor(private _router: Router, private _permissions: PermissionsService) {}

  ngOnInit(): any {}

  onClick() {
    return this._router.navigate(AppUrls.getUrlStartSprint(this.board.id));
  }
}
