<div #title_columns class="columns_titles_block"
     syncHeaderScrollPosition
     [scrollSourceRef]="columns_wrapper">
  <div class="titles_container"
       [ngClass]="{'shadow_bottom': (swimlanesLength$ | async) < 2}"
  >
    <column-header
      *ngFor="let column of columns$ | async; trackBy : trackById;"
      aDraggableItem="true"
      [itemType]="dragItemType"
      [item]="column"
      class="title"
      [class.title_composite]="column.kind === columnKinds.composite"
      [class.title_simple]="column.kind === columnKinds.simple"
      [class.is_dragging]="(draggingColumnId$ | async) === column.id"
      [column]="column"
      [boardId]="boardId"
      (toggleCreteColumnPopup)="onToggleCreateColumnOrSwimlanePopup($event.visibility, $event.bindingEl, $event.insertAfter)"
    ></column-header>

    <div class="add_new_column_trigger">
      <div *ngIf="columnPermissions$ | async"
           class="add_column_btn"
           #add_column_trigger
           (click)="onToggleCreateColumnOrSwimlanePopup(true)"
      ></div>
      <a-context-container *ngIf="createColumnPopupShown">
        <div context-popup class="add_column_popup"
             [showBoundToEl]="addColumnPopupAnchorEl"
             [isCustomHeader]="true"
             [clickOut]="true"
             (clickOutEvent)="onToggleCreateColumnOrSwimlanePopup(false)"
             (cancelPopup)="onToggleCreateColumnOrSwimlanePopup(false)"
        >
          <add-column-or-swimlane
            [boardId]="boardId"
            [insertAfterColumn]="insertNewColumnAfter"
            (close)="onToggleCreateColumnOrSwimlanePopup(false)"
          >

          </add-column-or-swimlane>
        </div>
      </a-context-container>
    </div>
  </div>
</div>

<div id="columns_wrapper"
     #columns_wrapper
     class="column_wrapper"
     (dblclick)="onDoubleClick($event)"
>

  <div id="columns_inner" #columns_inner class="columns_inner" [ngClass]="{'IE': isIE}" data-column-create-on-dclick="true">
    <div #top_axis_scroll class="axis top"></div>
    <div #right_axis_scroll class="axis right"></div>
    <div #bottom_axis_scroll class="axis bottom"></div>
    <div #left_axis_scroll class="axis left"></div>

    <div [ngClass]="{'column-mirror': isColumnDragging}"
         [mouseScroll]="allowMouseScroll$ | async"
         [horizontalScrollContainer]="columns_wrapper"
         [verticallScrollContainer]="columns_wrapper"
         data-column-create-on-dclick="true"
    >
      <board-swimlane
        *ngFor="let swimlane of swimlanes$ | async; trackBy: trackById"
        class="swimlane_container"
        [scrollContainer]="columns_wrapper"
        [class.hide_swimlane_container]="(collapsedSwimlane$ | async)[swimlane.id]"
        [class.last_swimlane_offset]="(collapsedSwimlane$ | async)[swimlane.id]"
        [attr.swimlane]="swimlane.id"
        [hidden]="(collapsedSwimlane$ | async)[swimlane.id]"
        [swimlane]="swimlane"
        [columns$]="columns$"
        [swimlanesLength]="swimlanesLength$ | async"
        [tasksCounter]="(tasksSwCount$ | async)[swimlane.id]"
        [boardType]="boardType"
        [collapsed]="(collapsedSwimlane$ | async)[swimlane.id]"
        [board]="board$ | async"
        [boardUsersMap]="boardUsersMap$ | async"
        [boardLabelsMap]="boardLabelsMap$ | async"
        [newNotifyMap]="newNotifyMap$ | async"
        [activeUserId]="activeUserId$ | async"
        [scoreMap]="scoreMap$ | async"
        [isNotGuest]="isNotGuest$ | async"
        (toggleSwimlaneCollapseStateEvent)="onChangeSwimlaneVisibility($event)"
        (expandAll)="onToggleAllSwimlanes(false)"
        (collapseAll)="onToggleAllSwimlanes(true)"
        #swimlane_item
      ></board-swimlane>

	    <columns-width-helper
			    *ngIf="!(expandedSwimlanesCount$ | async)"
	        [columnsByTypes]="columnsByTypes$ | async"
	    ></columns-width-helper>
    </div>
  </div>

  <!--<custom-scrolls
    [horizontalContainer]="columns_wrapper"
    [verticalContainer]="columns_inner"
    (scrollShownChanged)="onScrollShownChanged($event)"
  ></custom-scrolls>-->

</div>
