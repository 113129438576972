import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { AtlazGuiModule } from '../../atlaz-gui/atlaz-gui.module';
import { RoadmapBoardDetailComponent } from './roadmap-board-detail/roadmap-board-detail.component';
import { TaskRoadmapPreviewComponent } from './task-roadmap-preview/task-roadmap-preview.component';
import { NamedSwimlaneComponent } from './swimlanes/named-swimlane/named-swimlane.component';
import { AddButtonComponent } from './add-button/add-button.component';
import { InplaceStringEditComponent } from './inplace-string-edit/inplace-string-edit.component';
import { AddTaskFormComponent } from './forms/add-task-form/add-task-form.component';
import { EffectsModule } from '@ngrx/effects';
import { RoadmapBoardEffect } from './store/roadmap-board.effect';
import { DayItemsComponent } from './roadmap-board-detail/timesheet/day-items/day-items.component';
import { TimesheetTaskPreviewComponent } from './roadmap-board-detail/timesheet/timesheet-task-preview/timesheet-task-preview.component';
import { TimesheetGroupHeaderComponent } from './roadmap-board-detail/timesheet/timesheet-group-header/timesheet-group-header.component';
import { ResizeTaskService } from './services/resize-task.service';
import { SyncRoadmapGuiStateService } from './services/sync-roadmap-gui-state-service';
import { MoveTaskService } from './services/move-task.service';
import { RoadmapDragService } from './services/roadmap-drag.service';
import { RoadmapDropContainerDirective } from './directives/roadmap-drop-container.directive';
import { MultiplyScrollSynchroDirective } from './directives/multiply-scroll-syncrho.directive';
import { RoadmapScrollService } from './services/roadmap-scroll.service';
import { RoadmapScaleControlsComponent } from './chart/roadmap-scale-controls/roadmap-scale-controls.component';
import { CellHighlightDirective } from './directives/cell-highlight.directive';
import { ConnectingLineComponent } from './roadmap-board-detail/timesheet/connecting-line/connecting-line.component';
import { TaskPercentInplaceEditComponent } from './task-roadmap-preview/task-percent-inplace-edit/task-percent-inplace-edit.component';

const sharedComponents = [
  MultiplyScrollSynchroDirective,
  RoadmapDropContainerDirective,
  CellHighlightDirective,
  RoadmapBoardDetailComponent,
  NamedSwimlaneComponent,
  TaskRoadmapPreviewComponent,
  TimesheetTaskPreviewComponent,
  TimesheetGroupHeaderComponent,
  AddButtonComponent,
  DayItemsComponent,
  ConnectingLineComponent,
  AddTaskFormComponent,
  RoadmapScaleControlsComponent,
  InplaceStringEditComponent,
  TaskPercentInplaceEditComponent
];

@NgModule({
  imports: [CommonModule, EffectsModule.forFeature([RoadmapBoardEffect]), SharedModule, AtlazGuiModule],
  declarations: sharedComponents,
  exports: sharedComponents,
  providers: [ResizeTaskService, MoveTaskService, SyncRoadmapGuiStateService, RoadmapDragService, RoadmapScrollService]
})
export class RoadmapBoardModule {}
