import { Component, Input } from '@angular/core';

import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';

import { TaskAttachment } from '../../../interfaces';
import { TaskAttachmentsDeleteAction } from '../../../ngrx/actions/task-attachments.actions';

@Component({
  selector: 'attachment-list',
  templateUrl: './attachment-list.component.html',
  styleUrls: ['./attachment-list.component.scss']
})
export class AttachmentListComponent {
  @Input() taskAttachments: TaskAttachment[];
  @Input() editPermissions = false;

  constructor(private _store: Store<AppState>) {}

  onDeleteAttachment(attachment: TaskAttachment) {
    this._store.dispatch(new TaskAttachmentsDeleteAction(attachment));
  }
}
