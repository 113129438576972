<div class="menu_icon" (click)="onOpen($event)">
  <svg width="18" height="18">
    <use xlink:href="#vertical-dots"></use>
  </svg>
</div>

<div class="items_list" *ngIf="isOpened" (click)="onClose($event)" [style.top.px]="menuTop" [style.left.px]="menuLeft"
>
  <ng-content></ng-content>
</div>
<div class="overlay" (click)="onClose($event)" *ngIf="isOpened"></div>
