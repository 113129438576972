import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Board, Task } from '../../../interfaces';
import { ScoringCriteria } from '../../../interfaces/ScoringCriteria';
import { Observable } from 'rxjs/Observable';
import { AppState } from '../../../ngrx/state';
import { Store } from '@ngrx/store';
import { fromScoringCriteria } from '../../../ngrx/reducers/scoring-criteria.reducer';
import { filter, map, publishReplay, refCount, switchMap } from 'rxjs/operators';
import {
  FactorType,
  IceFactors,
  RiceFactors,
  ScoringFactor
} from '../../../board/backlog-board/constants/backlog.constants';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { fromTask, getTaskById } from '../../../ngrx/reducers/task.reducer';
import { getVisibleScoringVal, isPresent } from '../../../../helpers';
import { getAdvancedScoreByTask } from '../../../ngrx/functions/crossed.selector';
import { ScoringType } from '../../../constants';

@Component({
  selector: 'sidebar-backlog',
  templateUrl: './sidebar-backlog.component.html',
  styleUrls: ['./sidebar-backlog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarBacklogComponent implements OnInit {
  @Input() task: Task;
  @Input() board: Board;
  @Input() editPermissions: boolean;
  public valueCriterias$: Observable<ScoringCriteria[]>;
  public effortsCriterias$: Observable<ScoringCriteria[]>;
  public allBoardCriteria$: Observable<{ criteria: ScoringCriteria[]; confidence: number }>;
  public isEdit = false;
  public score$: Observable<string | number>;
  public editCriterion$ = new BehaviorSubject('');
  public ScoringType = ScoringType;
  public factorToEdit$ = new BehaviorSubject('');
  public iceFactors = IceFactors;
  public riceFactors = RiceFactors;

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this.allBoardCriteria$ = this._store.select(fromScoringCriteria.getByBoard(this.board.id)).pipe(
      map(crs => crs.sort((a, b) => a.position - b.position)),
      switchMap(crs =>
        this._store.pipe(getTaskById(this.task.id)).pipe(
          filter(isPresent),
          map(task => {
            const crMap = (Array.isArray(task.scoringValues) ? task.scoringValues : []).reduce((acc, item) => {
              acc[item.criterion] = item.value;
              return acc;
            }, {});
            return {
              criteria: crs.map(cr => {
                const val = Number.isFinite(crMap[cr.id]) ? crMap[cr.id] : '?';
                return { ...cr, value: val, visibleValue: getVisibleScoringVal(val, cr.dataType) };
              }),
              confidence: task.confidence
            };
          })
        )
      ),
      publishReplay(1),
      refCount()
    );

    this.valueCriterias$ = this.allBoardCriteria$.pipe(
      map(value => value.criteria.filter(cr => cr.factor === ScoringFactor.Value))
    );
    this.effortsCriterias$ = this.allBoardCriteria$.pipe(
      map(value => value.criteria.filter(cr => cr.factor === ScoringFactor.Efforts))
    );

    this.score$ = this._store
      .select(fromTask.taskById(this.task.id))
      .pipe(switchMap(task => this._store.select(getAdvancedScoreByTask(task))));
  }

  onEditCriterion(cr) {
    if (this.editPermissions) {
      this.editCriterion$.next(cr);
      this.isEdit = true;
    }
  }

  hideCriterionEdit() {
    this.isEdit = false;
  }

  onEditFactor(factor) {
    if (this.editPermissions) {
      this.factorToEdit$.next(factor);
    }
  }

  hideFactorEdit() {
    this.factorToEdit$.next('');
  }

  getFactor(factor, type?) {
    const value = parseFloat(this.task[factor]);
    return Number.isFinite(value) ? value + (type === FactorType.percent ? '%' : '') : '?';
  }
}
