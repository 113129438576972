<span *ngIf="edit === false">{{value}}</span>
<input
  *ngIf="edit === true"
  [type]="'text'"
  [(ngModel)]="editValue"
  (blur)="onSave()"
  [focus]="true"
  (focus)="onFocus()"
  [clickOut]
  (clicOutEvent)="onClickOut($event)"
  (keydown.enter)="onSave()"
  (keydown.esc)="onSave()"
  (keydown.tab)="onSave()"
>
