export class Timesheet {
  days: Map<string, TimesheetDate>;
  sortedDays: Array<TimesheetDate>;
  projects: Array<TimesheetProject>;
  total: number;
  constructor() {
    this.total = 0;
    this.days = new Map();
    this.sortedDays = [];
    this.projects = [];
  }
}

export class TimesheetDate {
  timestamp: number;
  monthDay: string;
  isDayOff: boolean;
  endOfWeek: boolean;
  weekDay: string;
  total: number;
  date: string;
  projects: Map<number, TimesheetDateProject>;
  constructor() {
    this.total = 0;
    this.timestamp = null;
    this.projects = new Map();
    this.weekDay = null;
    this.monthDay = null;
  }
}

export class TimesheetDateProject {
  public users: Map<number, TimesheetDateUser>;
  public total: number;
  constructor() {
    this.total = 0;
    this.users = new Map();
  }
}

export class TimesheetDateUser {
  public tasks: Map<number, TimesheetDateTask>;
  public total: number;
  constructor() {
    this.total = 0;
    this.tasks = new Map();
  }
}

export class TimesheetDateTask {
  public logs: number[];
  public total: number;
  constructor() {
    this.total = 0;
    this.logs = [];
  }
}

export class TimesheetProject {
  public id: number;
  public name: string;
  public shortName: string;
  public users: Map<number, TimesheetUser>;
  public sortedUsers: Array<TimesheetUser>;
  public total: number;

  constructor() {
    this.id = null;
    this.name = '';
    this.shortName = '';
    this.sortedUsers = [];
    this.total = 0;
    this.users = new Map();
  }
}

export class TimesheetUser {
  public id: number;
  public name: string;
  public sortedTasks: Array<TimesheetTask>;
  public tasks: Map<number, TimesheetTask>;
  public total: number;
  public email: string;
  public firstname: string;
  public lastname: string;
  public photo: any;
  public userCompanyId: number;
  public nickname: string;

  constructor() {
    this.id = null;
    this.name = '';
    this.sortedTasks = [];
    this.total = 0;
    this.tasks = new Map();
    this.email = '';
    this.firstname = '';
    this.lastname = '';
    this.photo = null;
    this.userCompanyId = 0;
  }
}

export class TimesheetTask {
  id: number;
  title: string;
  logs: Map<number, TimesheetLog>;
  sortedLogs: Array<TimesheetLog>;
  total: number;
  projectId: number;
  numberInProject: number;

  constructor() {
    this.id = null;
    this.title = '';
    this.logs = new Map();
    this.total = 0;
    this.projectId = null;
    this.sortedLogs = [];
    this.numberInProject = null;
  }
}

export class TimesheetLog {
  id: number;
  logWorkDate: string;
  logDayTimestamp: number;
  loggedTime: number;
  comment: string;
  userId: number;
  taskId: number;
  title: string;
}

export enum TimesheetSpecialProjectIds {
  NoProjects = -1,
  AllProjects = -2
}

export enum TimesheetSpecialUserIds {
  AllMembers = -1,
  Me = -2
}

export enum TimesheetSpecialGroupIds {
  NoGroup = -1
}
