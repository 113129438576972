import { Component, OnInit } from '@angular/core';
import { RouterNavigateService } from '../../../../shared/services/router-navigate.service';
import { HandleResponseAction } from '../../../../ngrx/actions/root.action';
import { BOARD_PL } from '../../../../constants/entity';
import { Store } from '@ngrx/store';
import { AppState } from 'app/ngrx/state';
import { Observer } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormV2Service, FormSaveType, FormServiceParams } from '../../../../shared/services/form-v2.service';
import { JsonApiSingeModelResponse } from '../../../../shared/services/app/web-socket/http-response';

@Component({
  selector: 'stop-sprint-form',
  template: `
    <form [formGroup]="form">
      <div a-button class="btn_warn"
           [pending]="(_formService.isPending$ | async)"
           (click)="onStopSprint()"
      >
        Stop Sprint
      </div>
    </form>
  `,
  styleUrls: ['./stop-sprint-form.component.scss'],
  providers: [FormV2Service]
})
export class StopSprintFormComponent implements OnInit {
  formObserver: Observer<any> = {
    next: (resp: JsonApiSingeModelResponse<any>) => {
      this._store.dispatch(new HandleResponseAction(resp));
      this.onClose();
    },

    error: error => {},

    complete: () => {}
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.edit,
    entityToEdit: BOARD_PL,
    formObserver: this.formObserver
  };

  public form: FormGroup;

  public boardId: number;

  constructor(
    private _routerNav: RouterNavigateService,
    private _store: Store<AppState>,
    public _formService: FormV2Service,
    private _route: ActivatedRoute,
    private _fb: FormBuilder
  ) {}

  ngOnInit() {
    this.boardId = +this._route.snapshot.params['boardId'];

    this.form = this._fb.group({
      id: [this.boardId],
      sprintStartDate: [0]
    });
    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  onClose() {
    this._routerNav.deactivatePopupOutlet();
  }
  onStopSprint() {
    return this._formService.submit();
  }
}
