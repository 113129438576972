import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Swimlane } from '../../../interfaces';

@Component({
  selector: 'swimlane-list-multiselect',
  templateUrl: './swimlane-list-multiselect.component.html',
  styleUrls: ['./swimlane-list-multiselect.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwimlaneListMultiselectComponent),
      multi: true
    }
  ]
})
export class SwimlaneListMultiselectComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() boardId;
  @Input() swimlanes = [];

  public selectedSwimlaneIds: number[] = [];
  public selected: { [id: number]: boolean } = {};
  public swimlanesLabels = '';
  public isSwimlanesSelectOpened$ = new BehaviorSubject(false);
  private propagateChange = (_: any) => {};
  private propagateTouch = () => {};

  constructor() {}

  private setValue(selectedSwimlaneIds: number[], emitChanges = true) {
    if (selectedSwimlaneIds) {
      this.selectedSwimlaneIds = selectedSwimlaneIds;
      this.selected = this.selectedSwimlaneIds.reduce((acc, id) => {
        acc[id] = true;
        return acc;
      }, {});
    }
    this.swimlanesLabels =
      this.selectedSwimlaneIds.length === this.swimlanes.length
        ? 'All'
        : this.swimlanes
            .filter(swimlane => this.selected[swimlane.id])
            .map(item => item.name)
            .join(', ');
    if (emitChanges) {
      this.propagateChange(this.selectedSwimlaneIds);
      this.propagateTouch();
    }
  }

  public writeValue(selectedSwimlaneIds: number[]) {
    this.setValue(this.selectedSwimlaneIds, false);
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn) {
    this.propagateTouch = fn;
  }

  ngOnInit() {
    this.boardId = +this.boardId;
    this.onSelectAll();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isAllSwimlanesShouldBeReselected(changes)) {
      this.setValue(changes.swimlanes.currentValue.map(xs => xs.id));
    }
  }

  onToggleDropdown() {
    this.propagateTouch();
    this.isSwimlanesSelectOpened$.next(!this.isSwimlanesSelectOpened$.getValue());
  }

  onSelectSwimlane(swimlane: Swimlane) {
    const newValue = this.selected[swimlane.id]
      ? this.selectedSwimlaneIds.filter(id => id !== swimlane.id)
      : [...this.selectedSwimlaneIds, swimlane.id];
    this.setValue(newValue);
  }

  onSelectAll() {
    const newValue = this.selectedSwimlaneIds.length !== this.swimlanes.length ? this.swimlanes.map(xs => xs.id) : [];
    this.setValue(newValue);
  }

  isAllSwimlanesShouldBeReselected(changes: SimpleChanges) {
    return (
      changes.swimlanes &&
      !changes.swimlanes.firstChange &&
      changes.swimlanes.currentValue.length !== changes.swimlanes.previousValue.length &&
      this.selectedSwimlaneIds.length === changes.swimlanes.previousValue.length
    );
  }
}
