
import {combineLatest as observableCombineLatest,  Observable } from 'rxjs';

import {distinctUntilChanged} from 'rxjs/operators';

import { Task } from '../../../interfaces';
import { share } from '../../functions/util';
import { AppState } from '../../state';

import { taskQuickFilters } from './quick-task-filter.reducer';
import { getTaskDefaultFilterFunctions } from './default-task-filter.reducer';

export interface TaskFilter {
  id?: string;
  func: (value: Task, filterParams?: any) => any;
}

export interface DefaultTaskFilter {
  name: string;
  isActive: boolean;
  filter: TaskFilter;
}

export const getActiveTaskFilters = share((state$: Observable<AppState>) => {
  return observableCombineLatest(
    getTaskDefaultFilterFunctions(state$),
    taskQuickFilters(state$),
    (defaultFilters: TaskFilter[], quickFilters: TaskFilter[]) => {
      return [...defaultFilters, ...quickFilters];
    }
  ).pipe(distinctUntilChanged());
});
