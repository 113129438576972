import { Action } from '../../../../ngrx/actions/unsafe-action';

export const SET_ACTIVE_BOARD = '[slack-alert.action] set board id from url';
export const SET_DEFAULT_TEAM = '[slack-alert.action] set default team';
export const CLEAR = '[slack-alert.action] clear all slack state';

export class SetActiveBoard implements Action {
  readonly type = SET_ACTIVE_BOARD;

  constructor(public payload: number) {}
}

export class SetActiveTeam implements Action {
  readonly type = SET_DEFAULT_TEAM;

  constructor(public payload: number) {}
}

export class Clear implements Action {
  readonly type = CLEAR;
}

export type Actions = SetActiveBoard | SetActiveTeam | Clear;
