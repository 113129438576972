
import {take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from '../../../ngrx/state';
import { CompanyService } from '../../../shared/services/app/company.service';
import { RouterNavigateService } from '../../../shared/services/router-navigate.service';
import { IntegrationAuthAdapterService } from './integration-auth-adapter.service';
import * as fromSlackBoard from '../../settings/slack-alert/store/slack-board/slack-board.reducer';
import * as slackCompany from '../../settings/slack-alert/store/slack-company/slack-company.actions';
import { SERVICE_URL } from '../../../../helpers/get-api-url';
import * as slackAlert from '../../settings/slack-alert/store/slack-alert.action';
import { SegmentService } from '../../../atlaz-bnp/services/intergations/segment/segment.service';

@Injectable()
export class SlackAuthAdapterService extends IntegrationAuthAdapterService {
  private shouldRedirect = false;
  private board: number;

  constructor(
    private _store: Store<AppState>,
    companyService: CompanyService,
    private _segment: SegmentService,
    private _routerNav: RouterNavigateService
  ) {
    super(companyService);
    this.authUrl = companyService.getSlackUrl();
    this.popupWindowTitle = 'Slack Alert';
  }

  public openAuthWindow(board: number, shouldRedirect = false) {
    this.shouldRedirect = shouldRedirect;
    this.board = board;
    super.openAuthWindow();
  }

  protected receiveMessage(event: MessageEvent) {
    // Do we trust the sender of this message?  (might be
    // different from what we originally opened, for example).
    if (super.receiveMessage(event) === false) {
      return false;
    }
    const companyId = super.getCompanyId(event.data);
    if (companyId) {
      this._store.dispatch(new slackCompany.Get(companyId));
      super.closeChild();
      this._store.dispatch(new slackAlert.SetActiveTeam(companyId));
      if (this.shouldRedirect) {
        this._store
          .select(fromSlackBoard.isEmpty).pipe(
          take(1))
          .subscribe(emptyState => {
            if (emptyState) {
              this._routerNav.navigateToNewSlackAlert(this.board);
              this._segment.track('SlackPluginInstalled');
              return;
            }
            this._routerNav.navigateToSlackIndex(this.board);
          });
      }
    }
  }
}
