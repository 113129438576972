import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Label, LabelTag, Task } from '../../../../interfaces';
import { TaskAssignLabelsAction } from '../../../../ngrx/actions/task.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../ngrx/state';
import { Observable } from 'rxjs';
import { getTaskLabels } from '../../../../ngrx/functions/crossed.selector';

@Component({
  selector: 'sidebar-labels-list',
  templateUrl: './sidebar-labels-list.component.html',
  styleUrls: ['./sidebar-labels-list.component.scss']
})
export class SidebarLabelsListComponent implements OnInit {
  @Input() task: Task;
  @Input() labels: LabelTag[];
  @Input() isBatchActions: boolean;

  @Output() batchToggleItem = new EventEmitter();

  public isEditLabel = false;
  public isLabelsEditPopupVisible = false;
  public taskLabels$: Observable<Label[]>;
  public selectedLabel: Label = null;

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this.taskLabels$ = this._store.select(getTaskLabels(this.task.id));
  }

  onOpenEditPopup() {
    this.isLabelsEditPopupVisible = true;
  }

  onEditLabel(label: Label) {
    this.selectedLabel = label;
    this.isEditLabel = true;
    this.isLabelsEditPopupVisible = true;
  }

  onCloseEditPopup() {
    this.selectedLabel = null;
    this.isEditLabel = false;
    this.isLabelsEditPopupVisible = false;
  }

  onSelectLabel(item: LabelTag) {
    if (this.isBatchActions) {
      this.batchToggleItem.emit({ id: item.label.id, isAdding: !item.checked });
    } else {
      const modify = item.checked ? { remove: [item.label.id], add: [] } : { add: [item.label.id], remove: [] };
      this._store.dispatch(new TaskAssignLabelsAction({ id: this.task.id, labels: modify }));
    }
  }
}
