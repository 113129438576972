import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportComponent } from './import.component';
import { EffectsModule } from '@ngrx/effects';
import { ActiveImportEffects } from './ngrx/effects/active-import.effect';
import { AtlazGuiModule } from '../../../atlaz-gui/atlaz-gui.module';
import { JiraImportProgressComponent } from './jira-import/jira-import-progress/jira-import-progress.component';
import { JiraStartImportPopupComponent } from './jira-import/jira-start-import-popup/jira-start-import-popup.component';
import { SharedModule } from '../../../shared/shared.module';
import { JiraSelectProjectsComponent } from './jira-import/jira-import-progress/jira-select-projects/jira-select-projects.component';
import { JiraConfirmSelectedProjectsComponent } from './jira-import/jira-import-progress/jira-confirm-selected-projects/jira-confirm-selected-projects.component';
import { ImportStartedPopupComponent } from './import-started-popup/import-started-popup.component';
import { JiraImportInProgressComponent } from './jira-import/jira-import-progress/jira-import-in-progress/jira-import-in-progress.component';
import { JiraPrefetchingProjectsComponent } from './jira-import/jira-import-progress/jira-prefetching-projects/jira-prefetching-projects.component';
import { JiraImportFailedComponent } from './jira-import/jira-import-progress/jira-import-failed/jira-import-failed.component';

import { TrelloImportProgressComponent } from './trello-import/trello-import-progress/trello-import-progress.component';

import { TrelloImportFailedComponent } from './trello-import/trello-import-progress/trello-import-failed/trello-import-failed.component';
import { TrelloImportInProgresComponent } from './trello-import/trello-import-progress/trello-import-in-progress/trello-import-in-progres.component';
import { TrelloImportPrefetchingComponent } from './trello-import/trello-import-progress/trello-import-prefetching/trello-import-prefetching.component';
import { TrelloSelectBoardsComponent } from './trello-import/trello-import-progress/trello-select-boards/trello-select-boards.component';
import { TrelloReturnPageComponent } from './trello-import/trello-return-page/trello-return-page.component';
import { TrelloInviteMembersComponent } from './trello-import/trello-import-progress/trello-invite-members/trello-invite-members.component';
import { JiraInviteMembersComponent } from './jira-import/jira-invite-members/jira-invite-members.component';
import { JiraSvgLogoComponent } from './jira-import/jira-svg-logos/jira-svg-logo.component';
import { JiraImportSvgIconComponent } from './jira-import/jira-svg-logos/jira-import-svg-icon.component';

@NgModule({
  imports: [CommonModule, AtlazGuiModule, SharedModule],
  declarations: [
    ImportComponent,
    ImportStartedPopupComponent,
    TrelloSelectBoardsComponent,
    TrelloReturnPageComponent,
    TrelloImportProgressComponent,
    TrelloInviteMembersComponent,
    JiraImportProgressComponent,
    JiraStartImportPopupComponent,
    JiraImportFailedComponent,
    JiraSelectProjectsComponent,
    JiraImportInProgressComponent,
    JiraPrefetchingProjectsComponent,
    JiraConfirmSelectedProjectsComponent,
    JiraInviteMembersComponent,
    TrelloImportFailedComponent,
    TrelloImportInProgresComponent,
    TrelloImportPrefetchingComponent,
    JiraSvgLogoComponent,
    JiraImportSvgIconComponent
  ],
  exports: [ImportComponent, ImportStartedPopupComponent, TrelloReturnPageComponent, JiraInviteMembersComponent]
})
export class ImportModule {}
