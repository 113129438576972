import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toAdhm'
})
export class SecondsToAlternativeDhmPipe implements PipeTransform {
  transform(dateTimeIntervalInSeconds: number): string {
    if (!dateTimeIntervalInSeconds) {
      return '0m';
    }
    if (dateTimeIntervalInSeconds >= 86400) {
      return Math.floor(dateTimeIntervalInSeconds / 86400) + 'd';
    } else {
      return dateTimeIntervalInSeconds >= 3600
        ? Math.floor(dateTimeIntervalInSeconds / 3600) + 'h'
        : Math.floor(dateTimeIntervalInSeconds / 60) + 'm';
    }
  }
}
