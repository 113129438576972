import { map, switchMap } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TimesheetFilter } from './timesheet-filter';

import { TimesheetService } from '../timesheet.service';
import * as moment from 'moment-mini-ts';
import { clone } from 'ramda';
import { AuthService } from '../../shared/services/app/auth.service';

import { Group, Project, User } from '../../interfaces';
import { TimesheetSpecialGroupIds, TimesheetSpecialProjectIds, TimesheetSpecialUserIds } from '../timesheet';
import { DropdownSearchListItem } from '../../shared/components/dropdown-search-list/dropdown-search-list';
import { naturalSort } from '../../../helpers/';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'timesheet-filter',
  templateUrl: './timesheet-filter.component.html',
  styleUrls: ['./timesheet-filter.component.scss']
})
export class TimesheetFilterComponent implements OnDestroy, OnInit {
  @Output() changeValue: EventEmitter<any> = new EventEmitter();
  @Input() fromDate: number;
  @Input() toDate: number;
  @Input() projectId: number;
  @Input() groupId: number;
  @Input() userId: number;
  @Input() showLogs: boolean;
  @Input() showTasks: boolean;
  @Input() projects$: Observable<Project[]>;
  @Input() users$: Observable<User[]>;
  @Input() groups$: Observable<Group[]>;
  @Input() hideUser = false;
  @Input() hideGroup = false;

  public projectFilterItems$: Observable<DropdownSearchListItem[]>;
  public projectFilterTopItems: DropdownSearchListItem[];
  public userFilterItems$: Observable<DropdownSearchListItem[]>;
  public userFilterTopItems: DropdownSearchListItem[];
  public groupFilterItems$: Observable<DropdownSearchListItem[]>;
  public groupFilterTopItems: DropdownSearchListItem[];
  public filterForm: FormGroup;
  public filterValue: TimesheetFilter;

  private formChanges: Subscription;
  private showTasksChanges: Subscription;
  private groupMap: Map<number, Group>;
  private currentGroup$: BehaviorSubject<Group>;

  constructor(private fb: FormBuilder, private timesheetService: TimesheetService, private authService: AuthService) {
    this.showLogs = false;
    this.showTasks = false;
    this.groupMap = new Map();
    this.currentGroup$ = new BehaviorSubject<Group>(null);
  }

  ngOnInit() {
    this.filterValue = new TimesheetFilter();
    this.initFilterForm();
    this.initProjectFilter();
    this.initUserFilter();
    this.initGroupFilter();
  }

  onProjectChanged(event) {
    this.filterValue.projectId = event.id;
    this.changeValue.emit(clone(this.filterValue));
  }

  onUserChanged(event) {
    this.filterValue.userId = event.id;
    this.changeValue.emit(clone(this.filterValue));
  }

  onGroupChanged(event) {
    if (this.filterValue.groupId !== event.id) {
      this.filterValue.userId = TimesheetSpecialUserIds.AllMembers;
    }
    this.filterValue.groupId = event.id;
    const currentGroup: Group = this.groupMap.get(this.filterValue.groupId);
    this.currentGroup$.next(this.filterValue.groupId !== TimesheetSpecialGroupIds.NoGroup ? currentGroup : null);
    if (this.filterValue.groupId !== TimesheetSpecialGroupIds.NoGroup) {
      this.filterValue.groupUsers = currentGroup.usersIds;
      this.userFilterTopItems = this.getTimesheetSpecialUserItems().filter(i => {
        if (
          i.id === TimesheetSpecialUserIds.Me &&
          currentGroup.usersIds.indexOf(this.authService.activeUserId) === -1
        ) {
          return false;
        } else {
          return true;
        }
      });
    } else {
      this.userFilterTopItems = this.getTimesheetSpecialUserItems();
      this.filterValue.groupUsers = [];
    }

    this.changeValue.emit(clone(this.filterValue));
  }

  ngOnDestroy() {
    this.formChanges.unsubscribe();
    this.showTasksChanges.unsubscribe();
    this.groupMap.clear();
  }

  private initProjectFilter() {
    this.projectFilterItems$ = <Observable<DropdownSearchListItem[]>>this.projects$.pipe(
      map(naturalSort('name')),
      map((projects: Project[]) => {
        return projects.filter(project => !project.archived).map(p => {
          const dropdownItem = new DropdownSearchListItem();
          dropdownItem.title = p.name;
          dropdownItem.id = p.id;
          return dropdownItem;
        });
      })
    );

    this.projectFilterTopItems = [
      { title: 'All Projects', id: TimesheetSpecialProjectIds.AllProjects },
      { title: 'No Project', id: TimesheetSpecialProjectIds.NoProjects }
    ];

    this.filterValue.projectId = this.projectId;
  }

  private initUserFilter() {
    const groupMembers$: Observable<User[]> = <Observable<User[]>>this.currentGroup$.pipe(
      switchMap(currentCroup =>
        this.users$.pipe(
          map(users => {
            return (currentCroup ? users.filter(u => currentCroup.usersIds.indexOf(u.id) !== -1) : users).filter(
              u => u.confirmed === 1 && !u.deleted
            );
          })
        )
      )
    );

    this.userFilterItems$ = <Observable<DropdownSearchListItem[]>>groupMembers$.pipe(
      map(naturalSort('fullname')),
      map((users: User[]) => {
        return users.map(u => {
          const dropdownItem = new DropdownSearchListItem();
          dropdownItem.title = u.fullname;
          dropdownItem.id = u.id;
          return dropdownItem;
        });
      })
    );

    this.userFilterTopItems = this.getTimesheetSpecialUserItems();

    this.filterValue.userId = this.userId;
  }

  private getTimesheetSpecialUserItems(): Array<DropdownSearchListItem> {
    return [
      { title: 'Me', id: TimesheetSpecialUserIds.Me },
      { title: 'All Members', id: TimesheetSpecialUserIds.AllMembers }
    ];
  }

  private initGroupFilter() {
    this.groupFilterItems$ = <Observable<DropdownSearchListItem[]>>this.groups$.pipe(
      map(naturalSort('name')),
      map((groups: Group[]) => {
        this.groupMap.clear();
        return groups.map(g => {
          this.groupMap.set(g.id, g);
          if (this.filterValue.groupId === g.id) {
            this.filterValue.groupUsers = g.usersIds;
          }
          const dropdownItem = new DropdownSearchListItem();
          dropdownItem.title = g.name;
          dropdownItem.id = g.id;
          return dropdownItem;
        });
      })
    );

    this.groupFilterTopItems = [{ title: 'No Group', id: TimesheetSpecialGroupIds.NoGroup }];

    this.filterValue.groupId = this.groupId;
  }

  private initFilterForm() {
    this.filterValue.fromDate = this.fromDate;
    this.filterValue.toDate = this.toDate;
    this.filterValue.showLogs = this.showLogs;
    this.filterValue.showTasks = this.showTasks;

    this.filterForm = this.fb.group({
      fromDate: [moment.unix(this.filterValue.fromDate)],
      toDate: [moment.unix(this.filterValue.toDate)],
      showLogs: this.filterValue.showLogs,
      showTasks: this.filterValue.showTasks
    });

    this.filterForm.get('showLogs').disable();

    this.showTasksChanges = this.filterForm.get('showTasks').valueChanges.subscribe(newValue => {
      if (newValue === false) {
        this.filterForm.get('showLogs').disable();
        this.filterForm.get('showLogs').setValue(false);
      } else {
        this.filterForm.get('showLogs').enable();
      }
    });

    this.formChanges = this.filterForm.valueChanges.subscribe(f => {
      this.filterValue.fromDate = f.fromDate ? f.fromDate.startOf('day').unix() : undefined;
      this.filterValue.toDate = f.toDate ? f.toDate.endOf('day').unix() : undefined;
      this.filterValue.showLogs = f.showLogs;
      this.filterValue.showTasks = f.showTasks;
      this.changeValue.emit(clone(this.filterValue));
    });
  }
}
