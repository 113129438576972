import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { APortalService } from '../a-portal.service';

@Component({
  selector: 'a-portal-outlet',
  templateUrl: './a-portal-outlet.component.html',
  styleUrls: ['./a-portal-outlet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class APortalOutletComponent {
  public layers$ = this._portalService.layers$;

  constructor(private _portalService: APortalService) {}
}
