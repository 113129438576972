import { RoadMapActionTypes, RoadmapBoardAction } from './roadmap-board.action';
import { RoadMap, RoadmapChart } from '../interfaces/roadmap.interface';
import * as moment from 'moment-mini-ts';
import { RoadMapScale } from '../constants/roadmap.constants';
import { AppState } from '../../../ngrx/state/';
import { ROADMAP_BOARD } from '../../../constants/';
import { updateChart } from '../chart/utils/chart-cells';

const initialState: RoadMap = {
  id: 0,
  addForm: false,
  defaultColumn: null,
  board: null,
  defaultSwimlane: null,
  defaultColumnId: 0,
  defaultSwimlaneId: 0,
  taskTree: [],
  hiddenItems: {},
  minStartDate: 0,
  maxEndDate: 0,
  tasksLoaded: false,
  minChartWidthPx: 0,
  scale: RoadMapScale.Day,
  today: '',
  chart: {
    startTs: 0,
    endTs: 0,
    width: 0,
    cells: [],
    cellsHeaders: [],
    cellsMap: {},
    todayLeftPx: 0,
    itemPositions: {}
  },
  weekStartDate: 0
};

let actionsLog = [];
const logAction = action => {
  actionsLog.push(action);
  actionsLog = actionsLog.slice(-30);
};

export const roadmapBoardReducer = (state: RoadMap = initialState, action: RoadmapBoardAction): RoadMap => {
  logAction(action);
  try {
    return reducer(state, action);
  } catch (e) {
    console.error(e);
    actionsLog.forEach((action, index) => {
      console.warn('action', index, action.type, action.payload);
    });
    throw e;
  }
};

function reducer(state: RoadMap = initialState, action: RoadmapBoardAction): RoadMap {
  let nextState: RoadMap;

  switch (action.type) {
    case RoadMapActionTypes.INITIAL:
      return {
        ...initialState,
        workingDays: state.workingDays,
        weekStartDate: state.weekStartDate,
        today: state.today,
        minChartWidthPx: state.minChartWidthPx
      };

    case RoadMapActionTypes.OPEN:
      return {
        ...initialState,
        workingDays: state.workingDays,
        weekStartDate: state.weekStartDate,
        today: state.today,
        minChartWidthPx: state.minChartWidthPx,
        id: action.payload.id
      };

    case RoadMapActionTypes.SET_CHART_MIN_WIDTH:
      nextState = <RoadMap>{ ...state, minChartWidthPx: action.payload };

      return nextState;

    case RoadMapActionTypes.SET_SCALE:
      return { ...state, scale: <RoadMapScale>action.payload };

    case RoadMapActionTypes.APPLY_CURRENT_SCALE:
      nextState = <RoadMap>{ ...state };
      nextState.chart = <RoadmapChart>{ ...state.chart };
      nextState.chart = updateChart(nextState);

      return nextState;
    case RoadMapActionTypes.TASK_TREE_CHANGED:
      const payload = action.payload;
      nextState = { ...state, ...action.payload };
      nextState.chart = <RoadmapChart>{ ...state.chart };
      const newMinStartDate = moment
        .unix(payload.minStartDate)
        .startOf('month')
        .add({ month: action.payload.forPrint ? 0 : -1 })
        .unix();

      const newMaxEndDate = moment
        .unix(payload.maxEndDate)
        .endOf('month')
        .add({ month: action.payload.forPrint ? 0 : 1 })
        .unix();
      // 0 - is initial value for startTs, we have to override them
      nextState.chart.startTs = Math.min(nextState.chart.startTs || newMinStartDate, newMinStartDate);
      nextState.chart.endTs = Math.max(newMaxEndDate, nextState.chart.endTs);

      nextState.chart = updateChart(nextState);
      return nextState;

    case RoadMapActionTypes.WORKING_DAYS_CHANGED:
      nextState = <RoadMap>{ ...state };
      nextState.chart = <RoadmapChart>{ ...nextState.chart };

      nextState.workingDays = action.payload;

      nextState.chart = updateChart(nextState);
      return nextState;
    case RoadMapActionTypes.WEEK_START_CHANGED:
      nextState = <RoadMap>{ ...state };
      nextState.chart = <RoadmapChart>{ ...nextState.chart };

      nextState.weekStartDate = action.payload;

      nextState.chart = updateChart(nextState);
      return nextState;
    case RoadMapActionTypes.DATA_CHANGED:
      return { ...state, ...action.payload };
    case RoadMapActionTypes.ADD_HIDDEN_ITEMS: {
      state = { ...state };
      state.hiddenItems = { ...state.hiddenItems };
      state.hiddenItems[action.payload] = true;
      return state;
    }
    case RoadMapActionTypes.HIDE_ITEMS:
      const hiddenItems = action.payload.reduce((acc, id) => {
        acc[id] = true;
        return acc;
      }, {});
      return { ...state, hiddenItems };
    case RoadMapActionTypes.SHOW_HIDDEN_ITEMS:
      return { ...state, hiddenItems: {} };
    case RoadMapActionTypes.OPEN_ADD_FORM: {
      return { ...state, addForm: action.payload };
    }
    case RoadMapActionTypes.CLOSE_ADD_FORM: {
      return { ...state, addForm: false };
    }
    case RoadMapActionTypes.TODAY_DATE_CHANGED: {
      nextState = <RoadMap>{ ...state, today: action.payload };
      nextState.chart = <RoadmapChart>{ ...state.chart };
      nextState.chart = updateChart(nextState);
      return nextState;
    }
    default: {
      return state;
    }
  }
}

export namespace fromRoadmapBoard {
  export const roadmap = (store: AppState) => {
    return <RoadMap>store[ROADMAP_BOARD];
  };
  export const chart = (store: AppState) => (<RoadMap>store[ROADMAP_BOARD]).chart;
  export const getChartCells = (store: AppState) => (<RoadMap>store[ROADMAP_BOARD]).chart.cells;
  export const getChartCellsHeaders = (store: AppState) => (<RoadMap>store[ROADMAP_BOARD]).chart.cellsHeaders;
  export const todayOffset = (store: AppState) => chart(store).todayLeftPx;
  export const getChartWidth = (store: AppState) => chart(store).width;
  export const getId = (store: AppState) => roadmap(store).id;
  export const getScale = (store: AppState) => roadmap(store).scale;
  export const getItemsTree = (store: AppState) => roadmap(store).taskTree;
  export const getHiddenItemsMap = (store: AppState) => roadmap(store).hiddenItems;
  export const getChartItemsPositionsMap = (store: AppState) => chart(store).itemPositions;
  export const getAddForm = (store: AppState) => roadmap(store).addForm;
  export const getItem = (store: AppState) => (itemId: number) => {
    const findFn = (acc, item) => {
      if (item.id === itemId) {
        return item;
      }
      return acc || item.subItems.reduce(findFn, undefined);
    };
    return roadmap(store).taskTree.reduce(findFn, undefined);
  };
}
