import { filter, switchMap, map, distinctUntilChanged } from 'rxjs/operators';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { TaskAttachment } from '../../interfaces';

import { AppState } from '../../ngrx/state';
import { Store } from '@ngrx/store';
import { fromTaskAttachments } from '../../ngrx/reducers/task-attachments.reducer';
import { TaskAttachmentsAddAction } from '../../ngrx/actions/task-attachments.actions';

import { UploadFileService } from '../../shared/services/app/upload-file.service';
import { taskPopupCodes, TaskSidebarService } from '../shared/services/task-sidebar.service';
import { PaywallService } from '../../libs/paywall/paywall.service';
import { Features } from '../../libs/paywall/features.constants';

@Component({
  selector: 'task-attachments',
  templateUrl: './task-attachments.component.html',
  styleUrls: ['./task-attachments.component.scss']
})
export class TaskAttachmentsComponent implements OnInit, OnDestroy {
  @Input() taskId: number;
  @Input() editPermissions = false;

  @ViewChild('uploadnputAttach') uploadInputAttachElementRef;

  public TASK_ATTACHMENT: string = taskPopupCodes.ATTACHMENT;
  public isTitleMenuVisible = false;
  public attachmentList: TaskAttachment[];
  public fileExceeds$ = new BehaviorSubject<boolean>(false);
  public processedFiles: TaskAttachment[] = [];

  // 250MB
  public maxFileSize: number = 1024 * 1024 * 250;

  public taskAttachments$: Observable<TaskAttachment[]>;

  public countHiddenItems$: Observable<number>;
  public listShortMode$ = new BehaviorSubject(true);
  public attachListLength = 11;

  public subscriptions: Subscription[] = [];
  public isAddAttachmentPopUpVisible = false;
  public isDropboxEnabled: boolean;

  constructor(
    private _taskSidebarService: TaskSidebarService,
    private _uploadFileService: UploadFileService,
    private _store: Store<AppState>,
    private _paywall: PaywallService,
    private _cd: ChangeDetectorRef
  ) {}

  ngOnInit(): any {
    this.listenUploadWindow();
    this.isDropboxEnabled = this._paywall.isFeatureEnabled(Features.Dropbox);

    this.taskAttachments$ = <Observable<TaskAttachment[]>>this._store.select(
      fromTaskAttachments.getByTask(this.taskId)
    );

    const attachmentList$ = this.listShortMode$.pipe(
      switchMap(
        mode =>
          mode ? this.taskAttachments$.pipe(map(at => at.slice(0, this.attachListLength))) : this.taskAttachments$
      )
    );

    this.countHiddenItems$ = this.taskAttachments$.pipe(
      map(attachList => Math.max(attachList.length - this.attachListLength)),
      distinctUntilChanged()
    );

    const sub = attachmentList$.subscribe(listByShortMode => {
      this.attachmentList = listByShortMode;
      this._cd.detectChanges();
    });
    this.subscriptions.push(sub);
    document['onpaste'] = event => this.onPaste(event);
  }

  ngOnDestroy() {
    document['onpaste'] = null;
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  onToggleTitleMenu() {
    this.isTitleMenuVisible = !this.isTitleMenuVisible;
  }

  onToggleShortMode() {
    this.listShortMode$.next(!this.listShortMode$.getValue());
  }

  onUploadFilesFromWindow(fileEvent) {
    this.uploadFiles(fileEvent.target.files, this._taskSidebarService.relatedData);
  }

  onUploadFilesFromDragAndDrop(files: FileList) {
    this.uploadFiles(files);
  }

  uploadFiles(files, fromComment?: string) {
    for (const file of files) {
      this.uploadFile(file, fromComment);
    }
  }

  onSwitchAttachmentsPopUp() {
    this.isAddAttachmentPopUpVisible = !this.isAddAttachmentPopUpVisible;
  }

  uploadFile(file, commentKey?: string) {
    if (!this.validateFileSize(file)) {
      this.fileSizeValidationMessage();
      return false;
    }

    this.processedFiles.push(file);

    this._uploadFileService.makeFileRequest([], file).then(
      (result: any) => {
        const fileData = {
          file: result.id,
          task: this.taskId
        };

        this._store.dispatch(new TaskAttachmentsAddAction({ file: fileData, fromComment: commentKey }));
        this.cleanFileInput();
        this.processedFiles = this.processedFiles.filter(fileItem => fileItem !== file);
      },
      error => {
        this.processedFiles = this.processedFiles.filter(fileItem => fileItem !== file);
        console.error(error);
      }
    );
  }

  fileSizeValidationMessage() {
    const DelayTime = 4000;
    this.onShowHideWithDelayExceedPopup(DelayTime);

    this.cleanFileInput();
  }

  onShowHideWithDelayExceedPopup(DelayTime: number) {
    this.fileExceeds$.next(true);
    setTimeout(() => {
      this.onHideExceedPopup();
    }, DelayTime);
  }

  onHideExceedPopup() {
    this.fileExceeds$.next(false);
  }

  public listenUploadWindow() {
    const sub = this._taskSidebarService
      .getState()
      .pipe(filter(state => state === this.TASK_ATTACHMENT))
      .subscribe(state => {
        this.uploadInputAttachElementRef.nativeElement.click();
      });
    this.subscriptions.push(sub);
  }

  public validateFileSize(uploadingFile) {
    return uploadingFile && uploadingFile.size <= this.maxFileSize;
  }

  public cleanFileInput() {
    this.uploadInputAttachElementRef.nativeElement.value = null;
  }

  onPaste(event) {
    if (event.target.tagName === 'INPUT') {
      return;
    }
    Array.from(event.clipboardData.items || []).forEach((item: DataTransferItem) => {
      if (item.kind === 'file') {
        this.uploadFile(item.getAsFile());
      }
    });
  }
}
