<div [formGroup]="form">
  <div class="scoring_type_chooser">
    <h4>Choose Prioritization Type</h4>
    <div class="radio_group">
      <label class="radio" [ngClass]="{selected: isSelected(BoardScoringType.basic)}">
        <input type="radio" name="scoringType" formControlName="scoringType" [value]="BoardScoringType.basic">
        <span class="heading">2x2 Priority Matrix</span>
        <span class="description">Evaluate your tasks using 2 parameters and decide visually what should be done first</span>
      </label>
      <label class="radio" [ngClass]="{selected: isSelected(BoardScoringType.advanced)}">
        <input type="radio" name="scoringType" formControlName="scoringType" [value]="BoardScoringType.advanced">
        <span class="heading">Weighted Scoring</span>
        <span class="description">Manage the criteria for Value and Efforts factors. Final Score is calculated by the formula: (Sum of all Value criteria) minus (Sum of all Effort criteria)</span>
      </label>
      <label class="radio" [ngClass]="{selected: isSelected(BoardScoringType.ICE)}">
        <input type="radio" name="scoringType" formControlName="scoringType" [value]="BoardScoringType.ICE">
        <span class="heading">ICE</span>
        <span class="description">Set the Impact, Confidence and Ease factors for your tasks. Final Score is calculated by the formula: Impact * Confidence * Ease</span>
      </label>
      <label class="radio" [ngClass]="{selected: isSelected(BoardScoringType.RICE)}">
        <input type="radio" name="scoringType" formControlName="scoringType" [value]="BoardScoringType.RICE">
        <span class="heading">RICE</span>
        <span class="description">Set the Reach, Impact, Confidence and Effort factors for your tasks. Final Score is calculated by the formula: Reach * Impact * Confidence / Effort</span>
      </label>
      <label class="radio" [ngClass]="{selected: isSelected(BoardScoringType.off)}">
        <input type="radio" name="scoringType" formControlName="scoringType" [value]="BoardScoringType.off">
        <span class="heading">Off</span>
        <span class="description">Disable scoring on this board</span>
      </label>
    </div>
  </div>

  <div class="block_item" *ngIf="isAdvanced$ | async">
    <div class="divider"></div>
    <div class="block_header">Value and Efforts weights</div>
    <p class="block_hint">You can adjust the influence of the Value and Efforts factors on the final score.</p>
    <div class="block_controls">
      <div class="row">
        <div class="range_item">
          <div class="labels">
            Value <span class="value">{{form.value['valueWeight']}}%</span>
          </div>
          <div class="weight"><input formControlName="valueWeight" type="range" min="0" max="100" step="1"/></div>
        </div>
      </div>
      <div class="row">
        <div class="range_item">
          <div class="labels">
            Efforts <span class="value">{{form.value['effortWeight']}}%</span>
          </div>
          <div class="weight"><input formControlName="effortWeight" type="range" min="0" max="100" step="1"/></div>
        </div>
      </div>
      <div class="row">
        <div class="range_item">
          <div class="labels">
            Confidence
          </div>
          <p class="block_hint">
            You can't adjust weight of the Confidence factor because it reflects your overall confidence in the feature you're evaluating. You can interpret it like overall weight for the whole formula.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="block_item" *ngIf="isAdvanced$ | async">
    <div class="divider"></div>
    <div class="block_header">Value criteria</div>
    <p class="block_hint">
      Manage the criteria for Value factor. Add new ones or adjust the sort order. You can also
      adjust the influence of your criteria on Value factor, which consequently also affects their influence on the
      final score.
    </p>
    <div class="block_controls">
      <div class="row criterion" *ngFor="let criteria of valueCriterias$ | async;trackBy: trackById">
        <div class="buttons">
          <span (click)="moveUp(criteria.id)"></span>
          <span (click)="moveDown(criteria.id)"></span>
        </div>
        <div class="range_item">
          <div class="labels">
            <span class="criterion_name" (click)="onEditCriteria(board.id, criteria.id)">
              {{criteria.name}}
              <span class="info_icon" [a-tooltip]="criteria.description || 'No Description'" [position]="'top-left'"></span>
            </span>
            <span class="value">{{form.value[criteria.id]}}%</span>
          </div>
          <div class="weight"><input [formControlName]="criteria.id" type="range" min="0" max="100" step="1"/></div>
        </div>
      </div>
    </div>
    <button a-button class="add_criteria" (click)="onAddCriteria(board.id, ScoringFactor.Value)">+ ADD CRITERION</button>
    <br>
  </div>
  <div class="block_item last_item" *ngIf="isAdvanced$ | async">
    <div class="divider"></div>
    <div class="block_header">Efforts criteria</div>
    <p class="block_hint">
      Manage the criteria for Efforts factor. Add new ones or adjust the sort order. You can also
      adjust the influence of your criteria on Efforts factor, which consequently also affects their influence on the
      final score.
    </p>
    <div class="block_controls">
      <div class="row criterion" *ngFor="let criteria of effortsCriterias$ | async;trackBy: trackById">
        <div class="buttons">
          <span (click)="moveUp(criteria.id)"></span>
          <span (click)="moveDown(criteria.id)"></span>
        </div>
        <div class="range_item">
          <div class="labels">
            <span class="criterion_name" (click)="onEditCriteria(board.id, criteria.id)">
              {{criteria.name}}
              <span class="info_icon" [a-tooltip]="criteria.description || 'No Description'" [position]="'top-left'"></span>
            </span>
            <span class="value">{{form.value[criteria.id]}}%</span>
          </div>
          <div class="weight"><input [formControlName]="criteria.id" type="range" min="0" max="100" step="1"/></div>
        </div>
      </div>
    </div>
    <button a-button class="add_criteria no_margin_bottom" (click)="onAddCriteria(board.id, ScoringFactor.Efforts)">+ ADD CRITERION</button>
  </div>
  <div class="confirm_container" *ngIf="isAdvanced$ | async" [ngClass]="{'has_changes': (hasChanges$ | async)}">
    <div *ngIf="hasChanges$ | async">
      <span class="unsaved_message">You have unsaved changes</span>
      <form-errors></form-errors>
      <button a-button class="save_changes_btn" (click)="onSubmit()">SAVE CHANGES</button>
      <br>
      <button a-button class="discard_changes_btn" (click)="initForm()">Discard</button>
    </div>
  </div>
  <priority-chart-scales-settings [board]="board" *ngIf="isBasic$ | async"></priority-chart-scales-settings>
</div>
