import { Action } from '../unsafe-action';

export enum DefaultTasksColumnsFilterActionTypes {
  TOGGLE_ITEM_ID = '[DEFAULT_COLUMNS_FILTER] TOGGLE_COLUMN_IDS',
  RESET = '[DEFAULT_COLUMNS_FILTER] RESET'
}

export class DefaultTaskColumnsFilterToggleItemAction implements Action {
  readonly type = DefaultTasksColumnsFilterActionTypes.TOGGLE_ITEM_ID;

  constructor(public payload: number[]) {}
}

export class DefaultColumnsFilterResetAction implements Action {
  readonly type = DefaultTasksColumnsFilterActionTypes.RESET;
}

// prettier-ignore
export type Actions
  = DefaultTaskColumnsFilterToggleItemAction
  | DefaultColumnsFilterResetAction
  ;
