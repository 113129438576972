import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from '../../../ngrx/state';
import { CompanyService } from '../../../shared/services/app/company.service';
import { RouterNavigateService } from '../../../shared/services/router-navigate.service';
import { IntegrationAuthAdapterService } from './integration-auth-adapter.service';
import * as intercomCompany from '../../../integrations/settings/intercom-settings/ngrx/intercom-company.actions';

@Injectable()
export class IntercomAuthAdapterService extends IntegrationAuthAdapterService {
  constructor(
    companyService: CompanyService,
    private _store: Store<AppState>,
    private _routerNav: RouterNavigateService
  ) {
    super(companyService);
    this.authUrl = companyService.getIntercomUrl();
    this.popupWindowTitle = 'Intercom Setup';
  }

  protected receiveMessage(event: MessageEvent) {
    // Do we trust the sender of this message?  (might be
    // different from what we originally opened, for example).
    if (super.receiveMessage(event) === false) {
      return false;
    }
    const companyId = super.getCompanyId(event.data);
    if (companyId) {
      this._routerNav.deactivatePopupOutlet();
      this._store.dispatch(new intercomCompany.GetIndex({ id: companyId }));
      super.closeChild();
    }

    return null;
  }
}
