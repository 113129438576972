import { map, switchMap } from 'rxjs/operators';
import { compose, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState, ESInterface } from '../state';
import { Project } from '../../interfaces';
import { ProjectActionTypes } from '../actions/project.actions';
import { PROJECT_PL } from '../../constants';
import { addEntityState, editEntityState } from '../functions/reducer';
import {
  getAllEntitiesAsArray,
  getEntitiesByFields,
  getEntitiesByIds,
  getEntityByIdSelector,
  getEntitySelector
} from '../functions/selectors';
import { share } from '../functions/util';
import { createFindByShortName, identity, replaceIds } from '../../../helpers';
import { getBoardProjectIds } from './board.reducer';
import { Action } from '../actions/unsafe-action';
import { createSelector } from 'reselect';

const initialState: ESInterface<Project> = {
  ids: [],
  entities: {},
  selectedEntityId: null
};

export function reducer(state = initialState, action: Action): ESInterface<Project> {
  switch (action.type) {
    case ProjectActionTypes.ADD_COMPLETE: {
      return addEntityState(state, action.payload, false);
    }

    case ProjectActionTypes.EDIT:
    case ProjectActionTypes.EDIT_COMPLETE: {
      return editEntityState(state, action.payload);
    }

    case ProjectActionTypes.ASSIGN_USERS: {
      const projectId = action.payload.id;
      const usersIds = replaceIds(state.entities[projectId].usersIds, action.payload.users);

      return editEntityState(state, { id: projectId, usersIds: usersIds });
    }

    default: {
      return state;
    }
  }
}

export const getProjectState = getEntitySelector(PROJECT_PL);
export const getAllProjects = share(compose(getAllEntitiesAsArray, getProjectState));

export const getAllProjectsActive = share(getEntitiesByFields(PROJECT_PL, { archived: 0 }));
export const getAllProjectsArchived = share(getEntitiesByFields(PROJECT_PL, { archived: 1 }));

export const getProjectById = id => share(compose(getEntityByIdSelector<Project>(id), getProjectState));

export const getBoardProjects = share(boardProjectsSelector);

function boardProjectsSelector(state$: Observable<AppState>) {
  return state$.pipe(
    getBoardProjectIds,
    switchMap(projectIds => state$.pipe(getEntitiesByIds(PROJECT_PL, projectIds)))
  );
}

export const filterArchivedProjects = (projects: Project[]) => projects.filter(project => !project.archived);

export const findProject: any = (findFn = identity) => store$ => store$.pipe(getAllProjects, map(findFn));

export const findProjectByShortName: (shortName: string) => (store: Store<AppState>) => Observable<Project> = compose(
  findProject,
  createFindByShortName
);

export namespace fromProject {
  export const getState = (state: AppState) => <ESInterface<Project>>state[PROJECT_PL];
  export const getActive = createSelector(getState, (state: ESInterface<Project>) =>
    Object.values(state.entities).filter(e => e && !e.archived)
  );
  export const getById = id => createSelector(getState, (state: ESInterface<Project>) => state.entities[id]);
  export const getByIds = ids =>
    createSelector(getState, (state: ESInterface<Project>) => ids.map(id => state.entities[id]));
  export const getByBoardId = boardId =>
    createSelector(getState, (state: ESInterface<Project>) =>
      Object.values(state.entities).filter(project => project.boardsIds && project.boardsIds.includes(boardId))
    );
}
