import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'general-error-message',
  template: `<div>
              <ng-content></ng-content>
            </div>
`,
  styleUrls: ['./general-error-message.component.scss']
})
export class GeneralErrorMessageComponent implements OnInit {
  ngOnInit(): any {}
}
