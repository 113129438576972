<div class="pricing_duration_variants" *ngIf="!currentSub || currentSub.period !== 'year'">
  <div [ngClass]="{'active': isMonthly}" class="left_variant" (click)="onSetMonthly(true)">MONTHLY</div>
  <div [ngClass]="{'disable': isMonthly}" class="switcher_control" (click)="onSetMonthly(!isMonthly)">
    <div class="switcher_circle"></div>
  </div>
  <div [ngClass]="{'active': !isMonthly}" class="right_variant" (click)="onSetMonthly(false)">YEARLY&nbsp;&nbsp;<span class="normal_weight">(Save 20%)</span></div>
</div>
<div class="pricings_list">
  <div *ngIf="showFreePlan" class="pricing_banner_item">
    <div class="subtitle">FREE</div>
    <div class="banner_content">
      <div class="banner_price">
        <div class="professional_price">FREE</div>
        <div class="additional_text forever">forever</div>
      </div>
      <div class="stay_free_btn" (click)="stayFree()">
        <span>Stay Free</span>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#FFAB03"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 6L10.9425 7.0575L15.1275 11.25H6V12.75H15.1275L10.9425 16.9425L12 18L18 12L12 6Z" fill="white"/>
        </svg>
      </div>
      <div class="plan_features_divider"></div>
      <ul class="available_features">
        <li><strong>Unlimited</strong> Users</li>
        <li><strong>Unlimited</strong> projects and boards </li>
        <li><strong>100MB</strong> storage</li>
      </ul>
    </div>
  </div>
  <!--<div class="pricing_banner_item" *ngIf="plans[BillingPlanName.Premium].customPlan !== 1">-->
  <div class="pricing_banner_item basic">
    <div class="subtitle">{{plans[BillingPlanName.Basic].title}}</div>
    <div class="banner_content">
      <div class="banner_price">
        <div class="professional_price">${{plans[BillingPlanName.Basic].price[isMonthly ? 'month' : 'year']}}</div>
        <div class="additional_text">per user / monthly</div>
      </div>
      <!--<div class="plan_desc">Ideal for agile product teams and lorem ipsum sir dolor</div>-->
      <span class="confirm_btn" [ngClass]="{disabled: !(isBasicAvailable())}" (click)="onUpgradeToBasic()">{{getBasicBtnTitle()}}</span>
      <div class="plan_features_divider"></div>
      <ul class="available_features">
        <li><strong>Unlimited</strong> Storage</li>
        <li><strong>Unlimited</strong>
          Integrations
        </li>
        <li><strong>Unlimited</strong> tasks
          <span class="plan_info" a-tooltip="Free plans are limited up to 50 tasks.">?</span>
        </li>
      </ul>
    </div>
  </div>
  <div class="pricing_banner_item premium">
    <div class="subtitle">{{plans[BillingPlanName.Premium].title}}</div>
    <div class="banner_content">
      <div class="banner_price">
        <div class="professional_price">${{plans[BillingPlanName.Premium].price[isMonthly ? 'month' : 'year']}}</div>
        <div class="additional_text">per user / monthly</div>
      </div>
      <!--<div class="plan_desc">Ideal for agile product teams and lorem ipsum sir dolor</div>-->
      <span class="confirm_btn show_join" [ngClass]="{disabled: !(isPremiumAvailable())}" (click)="onUpgradeToPremium()">{{getPremiumBtnTitle()}}</span>
      <div class="plan_features_divider"></div>
      <!--<div class="all_basic">ALL STANDARD FEATURES PLUS</div>-->
      <ul class="available_features">
        <li>Multiple projects per board</li>
        <li>Branded Workspace</li>
        <li>SAML SSO</li>
        <li>Google Apps SSO</li>
        <li>Unlimited API Access</li>
        <li>Premium support</li>
      </ul>
    </div>
  </div>
</div>
