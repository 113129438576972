<page-with-permissions-wrapper>
<div *ngIf="(board$ | async) && ((board$ | async).type === 'sprint') && ((board$ | async).sprintStartDate) || (board$ | async) && !((board$ | async).closed); else close_board_page">
  <div sub-header>
    <board-sub-header
      *ngIf="board$ | async"
      [board]="board$ | async"
      heightBind
      (heightChanged)="onHeightChanged($event)"
    ></board-sub-header>
  </div>
  <div app-body-inner [style.top.px]="boardWrapperTop" [ngSwitch]="viewMode$ | async">
    <ng-container *ngIf="board$ | async">
      <div *ngSwitchCase="'loading'"></div>
      <board-table-view [board$]="board$" [isNotGuest]="isNotGuest$ | async" *ngSwitchCase="BoardViewMode.table"></board-table-view>
      <board-versions-view [board$]="board$" [isNotGuest]="isNotGuest$ | async" *ngSwitchCase="BoardViewMode.version"></board-versions-view>
      <board-detail *ngSwitchDefault [board]="board$ | async"></board-detail>
    </ng-container>
  </div>

  <right-menu-root id="right_menu_root" *ngIf="board$ | async" [board]="board$ | async"></right-menu-root>
  <quick-task-edit [isNotGuest]="isNotGuest$ | async"></quick-task-edit>
  <batch-actions [isNotGuest]="isNotGuest$ | async" [boardId$]="boardId$"></batch-actions>
</div>

<ng-template #close_board_page>
  <close-board-page *ngIf="(board$ | async)" [board]="(board$ | async)"></close-board-page>
</ng-template>
</page-with-permissions-wrapper>
