<header
  class="main-header"
  #header
>
  <div class="board_name_conatainer">
    <div class="flex-container">
      <div class="name-flex-container" [ngClass]="{'sprint_board_name': isSprint, 'sprint_in_progress': board.sprintStartDate, 'backlog_name': isBackLog}">
        <div class="board_name">
          <board-label class="board_name_title"
                       (click)="onOpenRenameBoardPopup()"
                       [name]="board.name"
                       [sprintName]="board.sprintName"
                       [sprintStartDate]="board.sprintStartDate"
                       [sprintReleased]="board.sprintReleased"
          ></board-label>
          <board-favorite-star class="favorite" [boardId$]="selectedBoardId$"></board-favorite-star>
        </div>
        <a [routerLink]="appUrls.getUrlBurndownChart(boardId)" class="burndown_chart_btn"
           *ngIf="isSprint && board.sprintStartDate  && !board.sprintReleased">
          <div a-button class="btn_small_contour">Burndown Chart</div>
        </a>
        <div context-popup *ngIf="isChangeBoardNameVisible" class="board_rename_popup"
             title="Edit Board Name"
             (cancelPopup)="onHideRenameBoard()"
        >
          <board-rename
            [board]="board"
            (submitForm)="onRenameBoard($event)"
            (cancelForm)="onHideRenameBoard()"
          ></board-rename>
        </div>
      </div>
      <div class="right_menu" *ngIf="isNotGuest$ | async" (click)="onToggleRightMenu()">

        {{ (isSprint && isClosed)
        ? ((isRightMenuVisible$ | async) ? 'Hide Released Tasks' : 'Show Released Tasks')
        : ((isRightMenuVisible$ | async) ? 'Hide Menu' : 'Menu')
        }}
      </div>
    </div>
    <!--<a [routerLink]="appUrls.getUrlBacklogChart(boardId)" class="backlog_chart" *ngIf="board.scoringType === ScoringType.basic">View Priority Matrix</a>-->

    <sprint-board-controls *ngIf="isOpenedBoard && isSprint" [board]="board"></sprint-board-controls>
  </div>
  <div class=sub_header_filters_container>
    <roadmap-scale-controls [boardId]="boardId" *ngIf="isOpenedBoard && isRoadMap && (isBoardViewMode$ | async)"></roadmap-scale-controls>
    <board-quick-filters [boardInfo$]="boardInfo$" *ngIf="isOpenedBoard" [ngClass]="{'full-width': !isRoadMap}" class="z810"></board-quick-filters>

  </div>
</header>

