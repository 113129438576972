import { AppState, ESInterface } from '../state';
import { TASKS_LINK_PL } from '../../constants';
import { createSelector } from 'reselect';
import { TaskLink } from '../../interfaces/tasks-links-type';

export const getTaskLinksState = (state: AppState) => <ESInterface<TaskLink>>state[TASKS_LINK_PL];

export const getTaskLinks = createSelector(getTaskLinksState, (types: ESInterface<TaskLink>): TaskLink[] =>
  types.ids.map(id => types.entities[id])
);

export const getTaskLinksByTask = (taskId: number) =>
  createSelector(getTaskLinks, (links: TaskLink[]): TaskLink[] =>
    links.filter((link: TaskLink) => link.inwardTask === taskId || link.outwardTask === taskId)
  );
