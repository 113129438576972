import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-mini-ts';
import { Moment } from 'moment-mini-ts';
import { isNumericalString } from '../../../helpers/index';
import { isString } from 'util';
import { DateTimeFormatterService } from '../../libs/date-time-formatter/services/date-time-formatter.service';

@Pipe({
  name: 'toDate'
})
export class SecondsToDatePipe implements PipeTransform {
  constructor(private _dateTimeFormatter: DateTimeFormatterService) {}

  transform(dateInOpc: any, pattern?: string): string {
    if (!dateInOpc) return null;

    const date = isString(dateInOpc) && !isNumericalString(dateInOpc) ? moment(dateInOpc) : moment.unix(dateInOpc);

    return this.format(date, pattern);
  }

  format(momentDate: Moment, pattern: string) {
    switch (pattern) {
      case 'mediumDate':
        return momentDate.format(this._dateTimeFormatter.DATE_FORMAT);
      case 'short':
        return momentDate.format(this._dateTimeFormatter.getSuitableDateFormat(momentDate));
      case 'dueDate':
      default:
        return momentDate.format(this._dateTimeFormatter.getSuitableDateTimeFormat(momentDate));
    }
  }
}
