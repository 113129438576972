import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';

import { isEnableCopyTaskTitleWithUrl } from '../../../../helpers';
import { copyToClipboard } from '../../../../helpers/html-helper';
import { Project, Task } from '../../../interfaces';

@Component({
  selector: 'task-key',
  template: `
    <h3 *ngIf="project">{{taskKey}}</h3>
  `,
  styleUrls: ['./task-key.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskKeyComponent {
  @Input() task: Task;
  @Input() project: Project;

  get taskKey() {
    return `${this.project.shortName}-${this.task.numberInProject}`;
  }

  // hidden feature.
  // uses in changelog.
  @HostListener('click')
  onCopyToClipboard() {
    if (isEnableCopyTaskTitleWithUrl()) {
      const taskKey = this.project ? this.taskKey : '';
      const textToCopy = `${taskKey} ${this.task.title}: ${window.location.href}`;
      copyToClipboard(textToCopy);
    }
  }
}
