
import {catchError, map, switchMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DEFAULT_COLLECTION_PL } from '../../constants';
import { defaultErrorHandler } from './root.effect';
import { DefaultCollectionActionTypes } from '../actions/default-collection.actions';
import { DefaultCollection } from '../../interfaces/default-collection';
import { AuthService } from '../../shared/services/app/auth.service';
import { HandleResponseAction } from '../actions/root.action';
import { toDashedFromCamelCase } from '../../../helpers';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';

@Injectable()
export class DefaultCollectionEffects {
  @Effect()
  edit$ = this.actions$
    .ofType(DefaultCollectionActionTypes.EDIT).pipe(
    switchMap(({ type, payload }: { type: string; payload: Partial<DefaultCollection> }) => {
      return this._atlazApiV2
        .patch([toDashedFromCamelCase(DEFAULT_COLLECTION_PL)], payload).pipe(
        map(resp => new HandleResponseAction(resp)),
        catchError(defaultErrorHandler(type, payload)),);
    }));

  constructor(private actions$: Actions, private _authService: AuthService, private _atlazApiV2: AtlazApiV2Service) {}
}
