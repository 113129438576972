
import {catchError, switchMap, map, pluck, distinctUntilChanged, pairwise} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { UserCompanyActionTypes } from '../actions/user-company.actions';
import { AppState, Entities } from '../state/app-state';
import { ENTITY_PL, USER_COMPANY_PL } from '../../constants/entity';
import { Store } from '@ngrx/store';
import { UserActionTypes, UserSyncWithUserCompanyAction } from '../actions/user.actions';
import { UsersCompany } from '../../interfaces/users-compnay';
import { Entity } from '../../interfaces/entity';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { JsonApiSingeModelResponse } from '../../shared/services/app/web-socket/http-response';
import { UserCompanyModel } from '../../shared/services/app/models/user-company';
import { defaultErrorHandler } from './root.effect';
import { toDashedFromCamelCase } from '../../../helpers/string';
import { HandleResponseAction } from '../actions/root.action';

const dirtyDifference = <T extends Entity>(prevState: Entities<T>, currentState: Entities<T>): Entities<T> => {
  return Object.keys(currentState).reduce((acc, itemKey) => {
    if (prevState[itemKey] !== currentState[itemKey]) {
      acc[itemKey] = currentState[itemKey];
    }

    return acc;
  }, {});
};

@Injectable()
export class UserCompanyEffect {
  @Effect()
  changeUserCompany$ = this.actions$
    .ofType(UserActionTypes.GET_COMPLETE).pipe(
    switchMap(_ =>
      this._store$.pipe(
        pluck(USER_COMPANY_PL, ENTITY_PL),
        distinctUntilChanged(),
        pairwise(),
        map(([prevState, currentState]: [Entities<any>, Entities<any>]): Entities<UsersCompany> =>
          dirtyDifference(prevState, currentState)
        ),)
    ),
    map(changedUsersCompanies => new UserSyncWithUserCompanyAction(changedUsersCompanies)),);

  @Effect()
  userCompanyEdit$ = this.actions$
    .ofType(UserCompanyActionTypes.EDIT).pipe(
    switchMap(({ type, payload: userCompanyPart }: { type: string; payload: Partial<UsersCompany> }) => {
      return this._atlazApi
        .patch([toDashedFromCamelCase(USER_COMPANY_PL)], userCompanyPart).pipe(
        catchError(defaultErrorHandler(type, userCompanyPart)),
        map((resp: JsonApiSingeModelResponse<UserCompanyModel>) => new HandleResponseAction(resp)),);
    }));

  constructor(private actions$: Actions, private _store$: Store<AppState>, private _atlazApi: AtlazApiV2Service) {}
}
