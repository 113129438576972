import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, pluck, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BugTrackerService } from '@atlaz/core/services/bag-tracker.service';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { BOARD, BOARD_PL, defaultExpand } from '../../constants';
import { PageStatus, STATUS_CODES } from '../../permissions/interfaces/page-status.interface';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/state';
import { HandleResponseAction } from '../../ngrx/actions/root.action';
import { isNumericalString } from '../../../helpers';
import * as fromLoadedData from '../../loaded-data/store/loaded-data.reducer';
import { BOARD_ASSETS } from '../../loaded-data/store/loaded-data.reducer';
import { BoardOpenAction } from '../../ngrx/actions/board.actions';
import { PaywallService } from '../../libs/paywall/paywall.service';
import { Features } from '../../libs/paywall/features.constants';
import { getCurrentCompany } from '../../ngrx/reducers/current-company.reducer';
import { MarkAsLoaded, MarkAsUnloaded } from '../../loaded-data/store/loaded-data.actions';

@Injectable()
export class BoardDetailPageResolver implements Resolve<PageStatus> {
  constructor(
    private _apiV2: AtlazApiV2Service,
    private _store: Store<AppState>,
    private _bugTracker: BugTrackerService,
    private _paywall: PaywallService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PageStatus> {
    const id = route.params['id'] || route.params['boardId'];

    return this.checkPermissions(id).pipe(
      tap(status => {
        this._store.dispatch(new BoardOpenAction(status['boardId'] ? status['boardId'] : undefined));
      })
    );
  }

  checkPermissions(id) {
    if (!isNumericalString(id)) {
      return observableOf({
        statusCode: STATUS_CODES.NOT_FOUND
      });
    }

    // return this.loadBoardRequest(id);

    return this._store
      .select(fromLoadedData.wasOpenedBoardFn)
      .pipe(
        map(boardLoadedFn => boardLoadedFn(id)),
        take(1),
        withLatestFrom(this._store.select(getCurrentCompany).pipe(pluck('isActive'))),
        switchMap(
          ([loadedBoard, isActive]) =>
            isActive
              ? loadedBoard ? observableOf({ boardId: id, statusCode: STATUS_CODES.OK }) : this.loadBoardRequest(id)
              : observableOf({ boardId: id, statusCode: STATUS_CODES.FORBIDDEN })
        )
      );

    // take one is required to complete the stream
    // return this._store.let(getBoardById(id)).take(1)
  }

  loadBoardRequest(id) {
    return this._apiV2
      .get(BOARD_PL, {
        id,
        expand: [...defaultExpand[BOARD]]
      })
      .pipe(
        tap(resp => {
          this._store.dispatch(new HandleResponseAction(resp));
          this._store.dispatch(new MarkAsLoaded({ name: BOARD_ASSETS, id }));
        }),
        map(() => {
          return {
            boardId: id,
            statusCode: STATUS_CODES.OK
          };
        }),
        catchError(err => {
          this._store.dispatch(new MarkAsUnloaded({ name: BOARD_ASSETS, id }));
          // by default unknown error;
          let status = err.status;

          if (status === STATUS_CODES.PAYMENT_REQUIRED) {
            let feature = Features.CanAddTask;
            try {
              feature = JSON.parse(err._body).paywall || feature;
            } catch (e) {
              console.log(e);
            }
            this._paywall.showPayWall(feature, true);
          } else if (
            ![STATUS_CODES.FORBIDDEN, STATUS_CODES.NOT_FOUND, STATUS_CODES.CONNECTION_PROBLEM].includes(status)
          ) {
            status = STATUS_CODES.SERVER_ERROR;
            this._bugTracker.warn('BoardResolver, Server error: unknown status', err);
          }

          return observableOf({
            statusCode: status
          });
        })
      );
  }
}
