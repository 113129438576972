import { Component, Input } from '@angular/core';
import { Column } from '../../../interfaces';
import { Store } from '@ngrx/store';
import { ColumnEditAction } from '../../../ngrx/actions/column.actions';
import { AppState } from '../../../ngrx/state/app-state';

@Component({
  selector: 'archived-column-list',
  templateUrl: './archived-column-list.component.html',
  styleUrls: ['./archived-column-list.component.scss']
})
export class ArchivedColumnListComponent {
  @Input() archivedItems: Column[];

  constructor(private _store: Store<AppState>) {}

  onUnArchive(column: Column) {
    this._store.dispatch(
      new ColumnEditAction({
        id: column.id,
        archived: 0
      })
    );
  }
}
