import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BoardViewMode, BoardViewModeTitle, fromGuiState } from '../../../ngrx/reducers/gui-state-memorized.reducer';
import { distinctUntilChanged, map, publishReplay, refCount, switchMap, take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { AppState } from '../../../ngrx/state';
import { Store } from '@ngrx/store';
import {
  GuiStateOpenBoardTypeSelectorAction,
  GuiStateSetBoardViewMode
} from '../../../ngrx/actions/gui-state-memorized.actions';
import { RouterNavigateService } from '../../../shared/services/router-navigate.service';
import { fromBoards } from '../../../ngrx/reducers/board.reducer';
import isBasicScoringSelected = fromBoards.isBasicScoringSelected;
import getSelectedBoard = fromBoards.getSelectedBoard;
import { boardType } from '../../../constants';
import { SegmentService } from '../../../atlaz-bnp/services/intergations/segment/segment.service';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs/internal/observable/of';
import { getVersionsListByBoardId } from '../../../ngrx/functions/crossed.selector';
import { Version } from '../../../interfaces';

@Component({
  selector: 'board-view-selector',
  templateUrl: './board-view-selector.component.html',
  styleUrls: ['./board-view-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoardViewSelectorComponent implements OnInit {
  @Input() boardId$: Observable<number>;
  @Input() preventNav: boolean;

  public currentBoardMode$: Observable<BoardViewMode>;
  public BoardViewMode = BoardViewMode;
  public BoardViewModeTitle = BoardViewModeTitle;
  public currentBoardModeTitle$: Observable<string>;
  public isSelectOpened = false;
  public isSelectOpened$: Observable<boolean>;
  public isPriorityChartAvailable$: Observable<boolean>;
  public isByVersionAvailable$: Observable<boolean>;

  constructor(
    private _store: Store<AppState>,
    private _routerNav: RouterNavigateService,
    private _activatedRoute: ActivatedRoute,
    private _segment: SegmentService
  ) {}

  ngOnInit() {
    this.isSelectOpened$ = this._store
      .select(fromGuiState.getIsBoardViewSelectorOpened)
      .pipe(tap(v => (this.isSelectOpened = v)));

    this.currentBoardMode$ = this.boardId$.pipe(
      switchMap(id => this._store.select(fromGuiState.getBoardViewMode(id)).pipe(map(mode => [mode, id]))),
      distinctUntilChanged(),
      tap(([mode, id]) => {
        if (this.preventNav) {
          return;
        }
        if (mode === this.BoardViewMode.priorityChart) {
          this._routerNav.navigateToPriorityChart(id, { fragment: this._activatedRoute.snapshot.fragment });
        } else {
          this._routerNav.navigateToBoard(id);
        }
      }),
      map(([mode, id]) => mode),
      publishReplay(1),
      refCount()
    );
    this.currentBoardModeTitle$ = this.currentBoardMode$.pipe(map(mode => this.BoardViewModeTitle[mode || '']));

    this.isPriorityChartAvailable$ = this._store.select(isBasicScoringSelected);
    this.isByVersionAvailable$ = this._store.select(getSelectedBoard).pipe(
      switchMap(board => {
        if (!board || board.type === boardType.sprint || !board.projectsIds || !board.projectsIds.length) {
          return of(false);
        } else {
          return this._store
            .select(getVersionsListByBoardId(board.id))
            .pipe(map((list: Version[]) => !!(list ? list.filter(v => !v.released).length : false)));
        }
      })
    );
  }

  setMode(mode) {
    this.boardId$.pipe(take(1)).subscribe(id => {
      this._store.dispatch(new GuiStateSetBoardViewMode({ id: id, mode: mode }));
      this._segment.track('BoardViewChanged', { toView: mode || 'board' }); // Track here (not in the effect) for tracking manual switching only
      this.toggleSelect();
    });
  }

  toggleSelect() {
    this.isSelectOpened = !this.isSelectOpened;
    this._store.dispatch(new GuiStateOpenBoardTypeSelectorAction(this.isSelectOpened));
  }
}
