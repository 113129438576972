
import {combineLatest, map} from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { Task } from '../../../interfaces';
import { AtlazApiV2Service } from '../../services/atlaz-api/v2/atlaz-api-v2.service';
import { SmartTaskLinkComponent } from './smart-task-link.component';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppUrls } from '../../../app-urls';
import { TaskDetailPageRelatedDataService } from '../../../task/task-detail-page/services/task-detail-page-related-data.service';

@Component({
  selector: 'shared-smart-task-link',
  template: `
    <smart-link *ngIf="!(loaded$ | async)" [value]="stringLink$ | async"></smart-link>
    <smart-task-link-inner
      *ngIf="loaded$ | async"
      [link]="directLink ? (stringLink$ | async) : (commands$ | async)"
      [taskKey]="taskKey$ | async"
      [task]="task$ | async"
      [board]="board$ | async"
      [column]="column$ | async"
      [swimlane]="swimlane$ | async"
      [parentColumn]="parentColumn$ | async"
      [directLink]="directLink"
    >
    </smart-task-link-inner>
  `,
  styleUrls: ['./smart-task-link-inner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharedSmartTaskLinkComponent extends SmartTaskLinkComponent implements OnInit {
  @Input()
  set task(identity: Task | number | string) {
    if (identity && typeof identity === 'object') {
      this.taskSlug$.next(identity.id);
    } else {
      this.taskSlug$.next(identity);
    }
  }

  public stringLink$: Observable<string>;
  public commands$: Observable<any[]>;
  constructor(
    protected _store: Store<AppState>,
    protected _apiV2: AtlazApiV2Service,
    protected _detailLoader: TaskDetailPageRelatedDataService,
    private _router: Router
  ) {
    super(_store, _apiV2, _detailLoader);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.directLink) {
      this.commands$ = this.taskSlug$.pipe(combineLatest(this.board$, (slug, board) => {
        return board ? AppUrls.getUrlTaskOnBoard(board.id, slug) : AppUrls.getUrlTask(slug);
      }));
    } else {
      this.commands$ = this.taskSlug$.pipe(map(slug => AppUrls.getUrlTask(slug)));
    }
    this.stringLink$ = this.commands$.pipe(
      map(commands => this._router.createUrlTree(commands)),
      map(tree => window.location.origin + this._router.serializeUrl(tree)),);
  }
}
