import {
  interval as observableInterval,
  fromEvent as observableFromEvent,
  of as observableOf,
  Subscription,
  Observable,
  merge
} from 'rxjs';

import { takeUntil, tap, filter, take } from 'rxjs/operators';
import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  NgZone,
  OnInit,
  Output
} from '@angular/core';
import { ADragService } from './a-drag.service';
import { Entity } from '../../interfaces';
import { isAbsent, isEqualId, isEqualType } from '../../../helpers';
import { getEventOffsetX, getEventOffsetY, getEventPath } from '../../../helpers/event';
import { animationFrame } from 'rxjs/scheduler/animationFrame';

@Directive({
  selector: '[aDraggableItem]'
})
export class ADraggableItemDirective implements OnInit {
  @Input() item: Entity;
  @Input() itemType: string;
  @Input() metaData: {} = {};
  @Input() axis: 'x' | 'y' = 'x';
  @Output() drop = new EventEmitter();

  private allowedDrag: boolean;

  get dropContainersMap() {
    return this._dragService.getDropContainersMap(this.itemType);
  }

  subs: Subscription[] = [];

  constructor(
    private _dragService: ADragService,
    private _element: ElementRef,
    private _cd: ChangeDetectorRef,
    private _zone: NgZone
  ) {}

  ngOnInit(): any {
    let sub = this._dragService.finallyMoved$
      .pipe(filter(isEqualType(this.itemType)), filter(isEqualId(this.item.id)), tap(x => console.warn('emmitdrop', x)))
      .subscribe(value => this.drop.emit(value));
    this.subs.push(sub);
  }

  updateDropContainer() {
    this.dropContainersMap.set(this._element.nativeElement, {
      item: this.item,
      itemType: this.itemType,
      metaData: this.metaData,
      axis: this.axis
    });
  }

  ngAfterViewInit() {
    this.updateDropContainer();
  }

  ngOnChanges() {
    if (this._element.nativeElement) {
      this.updateDropContainer();
    }
  }

  ngOnDestroy() {
    this.dropContainersMap.delete(this._element.nativeElement);
    this.subs.forEach(sub => sub.unsubscribe());
  }

  @HostBinding('draggable')
  get draggable() {
    return this.allowedDrag;
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    const path = getEventPath(event);
    this.allowedDrag = !path.some(
      (el: HTMLElement) =>
        el.hasAttribute && el.hasAttribute('draggable-disabled') && el.getAttribute('draggable-disabled') === 'true'
    );
  }

  readonly defaultErrorHandler = (err): Observable<any> => {
    console.error(err, 'A Draggable Directive');
    return observableOf({});
  };

  findValidDropTarget(element: any) {
    if (this.dropContainersMap.get(element)) {
      return element;
    }
    if (element && element.parentNode) {
      return this.findValidDropTarget(element.parentNode);
    }
    return null;
  }

  @HostListener('dragstart', ['$event'])
  onStartDrag(event: MouseEvent) {
    if (!this.allowedDrag) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      return false;
    }
    const target: any = event.target;

    const mirror = <HTMLDivElement>target.cloneNode(true);
    const bodyOverlay = document.createElement('div');
    mirror.classList.add('gu-mirror');

    mirror.style['top'] = -getEventOffsetY(event) + 'px';
    mirror.style['left'] = -getEventOffsetX(event) + 'px';
    mirror.style.width = target.clientWidth + 'px';
    mirror.style.height = target.clientHeight + 'px';
    mirror.style.background = target.style.background;

    mirror.style.position = 'fixed';
    mirror.style.cursor = 'move';
    mirror.style['z-index'] = 1000000;

    bodyOverlay.style['top'] = '0';
    bodyOverlay.style['left'] = '0';
    bodyOverlay.style.bottom = '0';
    bodyOverlay.style.right = '0';
    bodyOverlay.style['z-index'] = 1000001;
    bodyOverlay.style.position = 'fixed';
    bodyOverlay.style.cursor = 'move';

    const patchMirrorPosition = (event: MouseEvent) => {
      const value = 'translate3d(' + event.clientX + 'px,' + event.clientY + 'px, 0)';

      mirror.style.transform = value;
      mirror.style['-o-transform'] = value;
      mirror.style['-ms-transform'] = value;
      mirror.style['-moz-transform'] = value;
      mirror.style['-webkit-transform'] = value;
    };

    let lastDropTarget;
    const checkDragOver = (event: MouseEvent) => {
      mirror.style.display = 'none';
      bodyOverlay.style.display = 'none';
      const target = document.elementFromPoint(event.clientX, event.clientY);
      mirror.style.display = 'block';
      bodyOverlay.style.display = 'block';
      const validDropTarget = this.findValidDropTarget(target);
      if (validDropTarget) {
        const over = this.dropContainersMap.get(validDropTarget);
        if (lastDropTarget) {
          if (validDropTarget !== lastDropTarget) {
            if (over.onEnter) {
              over.onEnter();
            }
            const oldOver = this.dropContainersMap.get(lastDropTarget);
            if (oldOver && oldOver.onLeave) {
              oldOver.onLeave();
            }
          }
        }
        lastDropTarget = validDropTarget;

        this._dragService.dragOver(
          event,
          over.item,
          over.itemType,
          over.metaData,
          over.axis,
          over.item ? validDropTarget.getBoundingClientRect() : null
        );
      }
    };

    this._zone.runOutsideAngular(() => {
      patchMirrorPosition(event);
      document.body.appendChild(mirror);
    });

    this._zone.runOutsideAngular(() => {
      const stop$ = merge(observableFromEvent(window, 'blur'), observableFromEvent(document, 'mouseup')).pipe(
        tap((e: MouseEvent) => {
          e.preventDefault ? e.preventDefault() : null;
        })
      );

      const mouseMove$ = <Observable<MouseEvent>>observableFromEvent(document, 'mousemove');

      let lastMove = event;
      let lastHandledMouseMove = lastMove;
      const stop = () => {
        this._dragService.stop();
        console.warn('stop');
      };

      mouseMove$
        .pipe(takeUntil(stop$))
        .subscribe((event: MouseEvent) => (lastMove = event), this.defaultErrorHandler, stop);

      observableInterval(1, animationFrame)
        .pipe(takeUntil(stop$))
        .subscribe(() => {
          if (lastMove !== lastHandledMouseMove) {
            lastHandledMouseMove = lastMove;
            patchMirrorPosition(lastMove);
            checkDragOver(lastMove);
          }
        });
    });
    this._dragService.start(this.item, this.itemType, this.metaData);

    this._dragService.dragging$.pipe(filter(isAbsent), take(1)).subscribe(_ => {
      mirror && mirror.parentNode && mirror.parentNode.removeChild(mirror);
    });
    this._cd.markForCheck();
    this._cd.detectChanges();

    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    return false;
  }

  @HostListener('dragstop')
  onStopDrag(event: MouseEvent) {
    this._dragService.stop();
  }

  @HostListener('drop', ['$event'])
  onDrop(event: MouseEvent) {
    if (event.preventDefault) {
      event.preventDefault();
    }
    this._dragService.stop();
  }

  @HostListener('dragend')
  onEndDrop(event: MouseEvent) {
    this._dragService.stop();
  }
}
