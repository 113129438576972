import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'icon-resize',
  templateUrl: './icon-resize.component.html',
  styleUrls: ['./icon-resize.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconResizeComponent {
  @Input() color = '#78909C';
}
