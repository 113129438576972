import { ESInterface, AppState } from '../state';
import { BoardActivity } from '../../interfaces';
import { getEntitiesByFields } from '../functions/selectors';
import { Observable } from 'rxjs';
import { BOARD_ACTIVITY_PL } from '../../constants';
import { Action } from '../actions/unsafe-action';

const initialState: ESInterface<BoardActivity> = {
  ids: [],
  entities: {},
  selectedEntityId: null
};

export function reducer(state = initialState, action: Action): ESInterface<BoardActivity> {
  switch (action.type) {
    default: {
      return state;
    }
  }
}

export const getBoardActivityByBoard = (boardId: number) => (state$: Observable<AppState>) => {
  return state$.pipe((getEntitiesByFields(BOARD_ACTIVITY_PL, { board: boardId })));
};
