import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { StartSprintPopupComponent } from './start-sprint-popup/start-sprint-popup.component';
import { SprintPercentIndicatorComponent } from './sprint-percent-indicator/sprint-percent-indicator.component';
import { SprintEndCountdownTimerComponent } from './sprint-end-countdown-timer/sprint-end-countdown-timer.component';
import { SprintBoundariesComponent } from './right-menu/sprint-boundaries/sprint-boundaries.component';
import { AtlazGuiModule } from '../../atlaz-gui/atlaz-gui.module';
import { ReleaseSprintPopupComponent } from './release-sprint-popup/release-sprint-popup.component';
import { ReleaseFormComponent } from './release-sprint-popup/release-form/release-form.component';
import { StopSprintPopupComponent } from './stop-sprint-popup/stop-sprint-popup.component';
import { StopSprintFormComponent } from './stop-sprint-popup/stop-sprint-form/stop-sprint-form.component';
import { ReleasedSprintsPageComponent } from './released-sprints-page/released-sprints-page.component';
import { ReleasedSprintsComponent } from './released-sprints/released-sprints.component';
import { ReleaseReportPageComponent } from './release-report/release-report-page/release-report-page.component';
import { ReleaseDetailsComponent } from './release-report/release-details/release-details.component';
import { BurndownChartModule } from '../../burndown-chart/burndown-chart.module';
import { VelocityReportPageComponent } from './velocity-report-page/velocity-report-page.component';
import { VelocityReportComponent } from './velocity-report/velocity-report.component';
import { VelocityReportD3Component } from './velocity-report/velocity-report-d3/velocity-report-d3.component';

const sharedComponents = [
  StartSprintPopupComponent,
  ReleaseReportPageComponent,
  ReleaseDetailsComponent,
  ReleasedSprintsPageComponent,
  ReleasedSprintsComponent,
  SprintPercentIndicatorComponent,
  SprintEndCountdownTimerComponent,
  SprintBoundariesComponent,
  ReleaseSprintPopupComponent,
  ReleaseFormComponent,
  StopSprintPopupComponent,
  StopSprintFormComponent,
  VelocityReportPageComponent,
  VelocityReportComponent,
  VelocityReportD3Component
];

@NgModule({
  imports: [CommonModule, SharedModule, AtlazGuiModule, BurndownChartModule],
  declarations: sharedComponents,
  exports: sharedComponents
})
export class SprintBoardModule {}
