<a-center-container>
	<div a-popup [title]="'Oops! Something went wrong'" (close)="onClosePopup()">
		<div class="popup_inner_container">
			<p>Unfortunately the payment with the card ending in {{lastCardNumbers}} failed</p>
			<button a-button class="btn_primary" (click)="onRenewForm()">Renew Again</button>
			<a (click)="onChangePaymentMethod()">Change Payment Details</a>
		</div>
	</div>
</a-center-container>
<overlay (click)="onClosePopup()"></overlay>
