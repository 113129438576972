import { Action } from '../../../ngrx/actions/unsafe-action';

export const GET_SUBSCRIPTION = '[BILLING_SUBSCRIPTION] GET SUBSCRIPTION';
export const SAVE_TOKEN = '[BILLING_SUBSCRIPTION] save stripe token';

export class GetSubscription implements Action {
  readonly type = GET_SUBSCRIPTION;
}

export class SaveToken implements Action {
  readonly type = SAVE_TOKEN;

  constructor(public payload: any) {}
}

export type Actions = GetSubscription;
