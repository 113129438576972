
import {of as observableOf,  BehaviorSubject ,  Observable } from 'rxjs';

import {refCount, publishReplay,  map ,  switchMap ,  tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { COMPANY_PL } from '../../constants';
import { accountServiceUrl } from '../../../helpers/';
import { Company } from '../../interfaces/';
import { CompanyService } from '../../shared/services/app/company.service';
import { JsonApiSingeModelResponse, jsonApiToSingleEntity } from '../../shared/services/app/web-socket/http-response';
import { AtlazApiV2Service, keyRemoveParser } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';

@Injectable()
export class InviteLinkService {
  public company$ = new BehaviorSubject<Company>(null);
  public isLoad$ = new BehaviorSubject<boolean>(true);

  constructor(private _apiV2: AtlazApiV2Service, private _companyService: CompanyService) {}

  public getToken(): Observable<string> {
    return this._apiV2
      .get(COMPANY_PL, { id: this._companyService.id })
      .pipe(
        map((resp: JsonApiSingeModelResponse<any>) => jsonApiToSingleEntity(resp)),
        map((company: Company) => company.invitationLinkToken)
      );
  }

  public getValidInviteLink(): Observable<string> {
    return this.getToken()
      .pipe(
        tap(_ => {
          this.isLoad$.next(true);
        }),
        switchMap(token => {
          return token ? observableOf(token) : this.createToken();
        }),
        map(token => this.createInviteLink(token)),
        tap(_ => this.isLoad$.next(false))
      ).pipe(
      publishReplay(1),
      refCount(),);
  }

  public createToken(): Observable<string> {
    return this._apiV2
      .patch(COMPANY_PL, {
        id: this._companyService.id,
        invitationLink: { add: [1] }
      })
      .pipe(
        map((resp: JsonApiSingeModelResponse<any>) => jsonApiToSingleEntity(resp)),
        map(company => company.invitationLinkToken)
      );
  }

  public removeToken(): void {
    this._apiV2
      .patch('companies', { id: this._companyService.id, removeKey: 'invitationLink' }, undefined, keyRemoveParser)
      .subscribe();
  }

  private createInviteLink(token: string): string {
    return accountServiceUrl + '/invite-by-link/' + token;
  }
}
