<input [focus]="true" placeholder="Search Versions..." (keyup)="0" #input_search_versions/>
<div class="versions_container">
  <div *ngFor="let version of versions | searchByField: 'name': input_search_versions.value"
       class="version_title"
       (click)="onSelectVersion(version)"
  >
    <p>
      {{version.name}}
      <span class="check_label" *ngIf="selectedVersions[version.id]"></span>
    </p>
  </div>
</div>
