
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { TaskDefaultFilterActionTypes } from '../actions/task-filters/task-default-filter.actions';
import { TasksSavedListResetAction } from '../actions/task-filters/tasks-saved-list.actions';

@Injectable()
export class TaskDefaultFilterEffects {
  @Effect()
  taskDefaultFilterToggle$ = this.actions$
    .ofType(TaskDefaultFilterActionTypes.TOGGLE, TaskDefaultFilterActionTypes.SET).pipe(
    map(_ => {
      return new TasksSavedListResetAction();
    }));

  constructor(private actions$: Actions) {}
}
