import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'payment-denied-popup',
  templateUrl: './payment-denied-popup.component.html',
  styleUrls: ['./payment-denied-popup.component.scss']
})
export class PaymentDeniedPopupComponent {
  @Input() lastCardNumbers: string;

  @Output() closePopup = new EventEmitter();
  @Output() resubmitForm = new EventEmitter();
  @Output() changePaymentMethod = new EventEmitter();

  onClosePopup() {
    this.closePopup.emit();
  }
  onRenewForm() {
    this.resubmitForm.emit();
  }
  onChangePaymentMethod() {
    this.changePaymentMethod.emit();
  }
}
