import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';
import * as ROUTER_PATH from '../../../../path.routing';
import { FormV2Service } from '../../../../shared/services/form-v2.service';

@Component({
  selector: 'new-project-context-popup',
  templateUrl: './new-project-context-popup.component.html',
  styleUrls: ['./new-project-context-popup.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewProjectContextPopupComponent {
  @Output() close = new EventEmitter();
  public isValidForm;
  public submit$ = new Subject();
  public companySettingsRouterLink = [
    ROUTER_PATH.SEP,
    {
      outlets: { primary: [ROUTER_PATH.COMPANY_SETTINGS, ROUTER_PATH.IMPORT], [ROUTER_PATH.POPUP_OUTLET]: null }
    }
  ];

  constructor() {}

  onClose() {
    this.close.emit();
  }
}
