import { distinctUntilChanged, map, pluck } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AppState, ESInterface } from '../state';
import { Entity } from '../../interfaces';
import { stateToArrayByFields } from '../../../helpers/entity';
import { compose, Store } from '@ngrx/store';
import { ENTITY_PL } from '../../constants';
import { identity } from 'ramda';
import { DefaultCollection } from '../../interfaces/default-collection';
import { Selector } from './util';

type ByIdSelector = (
  code,
  id: number | string | number[]
) => <T extends Entity>(state: Store<AppState>) => Observable<T | T[]>;

export function getEntitySelector<T extends Entity>(entityPlCode: string, ...paths: any[]): Selector<AppState, any> {
  return (state$: Observable<AppState>): Observable<any> =>
    state$.pipe(pluck(entityPlCode, ...paths), distinctUntilChanged());
}

export function selectEntities<T extends Entity>(state$: Observable<ESInterface<T>>) {
  return state$.pipe(map(state => state.entities), distinctUntilChanged());
}

export function getAllEntities(state$: Observable<ESInterface<Entity>>): Observable<{ [id: number]: Entity }> {
  return state$.pipe(map(state => state.entities), distinctUntilChanged());
}

export function getEntityByIdSelector<T extends Entity>(id: number | string) {
  return (state$: Observable<ESInterface<T>>) =>
    state$.pipe(
      map((state: ESInterface<T>) => {
        return state.entities[id];
      }),
      distinctUntilChanged()
    );
}

export function getEntityByIdsSelector<T>(ids: number[]) {
  return (state$: Observable<ESInterface<T>>) =>
    state$.pipe(
      map(state => {
        return ids.filter(id => state.ids.includes(id)).map(id => state.entities[id]);
      }),
      distinctUntilChanged()
    );
}

export function getAllEntitiesAsArray<T>(state$: Observable<ESInterface<T>>): Observable<T[]> {
  return state$.pipe(map(state => state.ids.map(id => state.entities[id])));
}

export function getSelectedEntity<T>(state$: Observable<ESInterface<T>>): Observable<T> {
  return state$.pipe(
    map(state => {
      return state.ids.includes(state.selectedEntityId) ? state.entities[state.selectedEntityId] : null;
    }),
    distinctUntilChanged()
  );
}

export function getSelectedEntityId<T extends Entity>(state$: Observable<ESInterface<T>>): Observable<number> {
  return <Observable<number>>state$.pipe(pluck('selectedEntityId'), distinctUntilChanged());
}

export function mapEntityField<T extends Entity>(field: string, defaultValue: any) {
  return (state$: Observable<T>) =>
    state$.pipe(
      map(state => (state && state.hasOwnProperty(field) ? state[field] : defaultValue)),
      distinctUntilChanged()
    );
}

/*
 * reinvented selectors
 */
/**
 *
 * @param entityPlCode
 * @param fields
 * @returns {(i:A)=>C}
 *
 * expamle at component:
 * this._store.let(
 *   getEntityRowByFields('tasks', {board: 3, column:1})
 * )
 * -> returns Observable<[{object: 'task', id: 1, board:3, column:1}]
 */
export function getEntitiesByFields(entityPlCode: string, fields: { [field: string]: any }) {
  return compose(entityByFieldsSelector(fields), getEntitySelector(entityPlCode));
}

export const getEntitiesByIds: ByIdSelector = (entityPlCode: string, ids: number[]) => {
  return compose(entityByIdsSelector(ids), getEntitySelector(entityPlCode, ENTITY_PL));
};

export function entityByFieldsSelector<T extends Entity>(fields: { [field: string]: any }) {
  return (state$: Observable<ESInterface<T>>) => state$.pipe(map(state => stateToArrayByFields(state, fields)));
}

export const isBoardInDefaultCollection = boardId => (collection: DefaultCollection) =>
  collection && collection.boardsIds && collection.boardsIds.includes(boardId);

export function entityByIdsSelector<T extends Entity>(ids: number[]) {
  return (state$: Observable<{ [id: number]: T }>) =>
    state$.pipe(map(state => ids.map(id => state[id]).filter(en => !!en)));
}
export const getEntityById: ByIdSelector = (entity, id) => getEntitySelector(entity, ENTITY_PL, String(id));

export const entityMapper = (mapperFn = identity) => state$ => state$.pipe(map(mapperFn));
