import { distinctUntilChanged, map, pluck } from 'rxjs/operators';
import { SwimlaneActionTypes } from '../actions/swimlane.actions';
import { AppState, ESInterface } from '../state';
import { Swimlane } from '../../interfaces';
import { share } from '../functions/util';
import { deleteEntityState } from '../functions/reducer';
import { Observable } from 'rxjs';
import { ENTITY_PL, SWIMLANE_PL } from '../../constants';
import { compose } from '@ngrx/store';
import { Action } from '../actions/unsafe-action';
import { getEntityByIdSelector, getEntitySelector } from '../functions/selectors';
import { createSelector } from 'reselect';

const initialState: ESInterface<Swimlane> = {
  ids: [],
  entities: {},
  selectedEntityId: null
};

export function reducer(state = initialState, action: Action): ESInterface<Swimlane> {
  switch (action.type) {
    case SwimlaneActionTypes.DELETE: {
      return <ESInterface<Swimlane>>deleteEntityState(state, action.payload);
    }

    default: {
      return state;
    }
  }
}

export function getSwimlaneState(state$: Observable<AppState>) {
  return state$.pipe(pluck(SWIMLANE_PL), distinctUntilChanged());
}

export function getSwimlaneEntities(state$: Observable<AppState>) {
  return state$.pipe(pluck(SWIMLANE_PL, ENTITY_PL), distinctUntilChanged());
}

export const getSwimlanesByBoard = boardId => compose(filterSwimlaneByBoardId(boardId), getSwimlaneState);
export const getSwimlaneById = id =>
  share(compose(getEntityByIdSelector<Swimlane>(id), getEntitySelector(SWIMLANE_PL)));

function filterSwimlaneByBoardId(boardId) {
  return (state$: Observable<ESInterface<Swimlane>>): Observable<Swimlane[]> =>
    state$.pipe(map(state => state.ids.map(id => state.entities[id]).filter(swimlane => swimlane.board === boardId)));
}

export namespace fromSwimlanes {
  export const getState = (state: AppState) => <ESInterface<Swimlane>>state[SWIMLANE_PL];
  export const get = id => createSelector(getState, state => state.entities[id] || {});
  export const getByBoardId = (boardId: number) =>
    createSelector(getState, state =>
      state.ids
        .map(id => state.entities[id])
        .filter(swimlane => swimlane.board === boardId)
        .sort((a: Swimlane, b: Swimlane) => a.position - b.position)
    );
}
