import { BehaviorSubject, combineLatest as observableCombineLatest, Observable, Subscription } from 'rxjs';

import { debounceTime, distinctUntilChanged, filter, map, pluck } from 'rxjs/operators';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/state';
import { SearchStateInterface } from '../../ngrx/reducers/global-search.reducer';
import { getAllBoards } from '../../ngrx/reducers/board.reducer';
import { Board, Project, User } from '../../interfaces';
import { columnTypes } from '../../constants';
import { getAllProjects } from '../../ngrx/reducers/project.reducer';
import { getAllUsers } from '../../ngrx/reducers/user.reducer';
import { isEquals, removeEmptyProperties } from '../../../helpers';
import { RouterNavigateService } from '../../shared/services/router-navigate.service';
import { ALL, ANY, DAYS30, DAYS7, NONE, OVERDUE, TODAY } from '../../ngrx/effects/global-search.effect';
import { ActivatedRoute } from '@angular/router';
import { PaywallService } from '../../libs/paywall/paywall.service';
import { Features } from '../../libs/paywall/features.constants';
import { Actions } from '@ngrx/effects';
import { GlobalSearchActionTypes } from '../../ngrx/actions/global-search.actions';

interface SelectOptionsListInterface {
  id: number | string;
  text: string;
}

@Component({
  selector: 'search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('formRef') form;
  @ViewChild('advancedSearch') advancedSearch;

  public subscriptions: Subscription[] = [];

  searchState: SearchStateInterface;
  boards$: Observable<SelectOptionsListInterface[]>;
  versions$: Observable<SelectOptionsListInterface[]>;
  projects$: Observable<Project[]>;
  users$: Observable<User[]>;

  selectedUsers$;
  selectedBoards$;
  selectedProjects$;
  selectedVersions$;
  skipNextSearch;

  formValues = {
    hideArchived: true,
    hideReleased: true
  };

  statusOptions = [
    { id: columnTypes.todo, label: 'To Do' },
    { id: columnTypes.inprogress, label: 'In Progress' },
    { id: columnTypes.done, label: this.capitalizeFirstLetter(columnTypes.done) }
  ];

  dueDateOptions = [
    { id: ALL, text: 'All' },
    { id: ANY, text: 'Any' },
    { id: NONE, text: 'None' },
    { id: TODAY, text: 'Today' },
    { id: DAYS7, text: '7 days' },
    { id: DAYS30, text: '30 days' },
    { id: OVERDUE, text: 'Overdue' }
  ];

  constructor(
    private _store: Store<AppState>,
    private _routerNav: RouterNavigateService,
    private _activatedRoute: ActivatedRoute,
    private _paywall: PaywallService,
    private _cd: ChangeDetectorRef,
    private actions$: Actions
  ) {}

  ngOnInit(): any {
    this.boards$ = <Observable<SelectOptionsListInterface[]>>this._store.pipe(
      getAllBoards,
      map((boards: Board[]) => {
        let result;
        result = <SelectOptionsListInterface[]>boards.map((board: Board) => {
          return { id: board.id, text: board.name };
        });
        result.unshift({ id: 0, text: '' });
        return result;
      })
    );

    this.projects$ = <Observable<Project[]>>this._store.pipe(getAllProjects);
    this.users$ = <Observable<User[]>>this._store.pipe(getAllUsers);

    this.versions$ = <Observable<SelectOptionsListInterface[]>>this._store.pipe(
      getAllBoards,
      map((boards: Board[]) => {
        let result;
        result = <SelectOptionsListInterface[]>boards.map((board: Board) => {
          return { id: board.id, text: board.name };
        });
        result.unshift({ id: 0, text: '' });
        return result;
      })
    );

    this.subscriptions.push(
      this._store.pipe(pluck('search'), distinctUntilChanged()).subscribe((state: SearchStateInterface) => {
        this.searchState = state;
        Object.assign(this.formValues, this.searchState.filterParams);
        this._cd.markForCheck();
      })
    );

    this.selectedUsers$ = new BehaviorSubject(
      this.searchState.filterParams['users'] ? this.searchState.filterParams['users'] : []
    );

    this.subscriptions.push(
      this._store
        .pipe(pluck('search', 'filterParams', 'users'), distinctUntilChanged())
        .subscribe((state: number[]) => this.selectedUsers$.next(state || []))
    );

    this.selectedVersions$ = new BehaviorSubject(
      this.searchState.filterParams['versions'] ? this.searchState.filterParams['versions'] : []
    );

    this.selectedProjects$ = new BehaviorSubject(
      this.searchState.filterParams['projects'] ? this.searchState.filterParams['projects'] : []
    );

    this.selectedBoards$ = new BehaviorSubject(
      this.searchState.filterParams['boards'] ? this.searchState.filterParams['boards'] : []
    );

    // get initial form values
    Object.assign(this.formValues, this.searchState.filterParams);

    this.subscriptions.push(
      this.actions$.pipe(filter(action => action.type === GlobalSearchActionTypes.RESET)).subscribe(_ => {
        this.onReset();
        this.skipNextSearch = true;
      })
    );
  }

  onReset() {
    this.formValues['dueDate'] = null;
    this.formValues['status'] = null;
    this.formValues['hideArchived'] = true;
    this.formValues['hideReleased'] = true;
    this.selectedProjects$.next([]);
    this.selectedVersions$.next([]);
    this.selectedUsers$.next([]);
    this.selectedBoards$.next([]);
  }

  ngAfterViewInit() {
    this.advancedSearch.nativeElement.addEventListener('click', e => this.onClickAdvanced(e), true);
    this.subscriptions.push(
      observableCombineLatest(
        this.selectedUsers$,
        this.selectedBoards$,
        this.selectedVersions$,
        this.selectedProjects$,
        this.form.valueChanges,
        (users, boards, versions, projects, nativeForm) =>
          Object.assign({}, nativeForm, { users: users, projects: projects, versions: versions, boards: boards })
      )
        .pipe(debounceTime(1000))
        .subscribe(formValue => {
          if (this.skipNextSearch) {
            this.skipNextSearch = false;
            return;
          }
          const filterParams = removeEmptyProperties(formValue);
          if (this._activatedRoute.snapshot.params['isEmptyQAvailable']) {
            filterParams['isEmptyQAvailable'] = this._activatedRoute.snapshot.params['isEmptyQAvailable'];
          }
          if (this._activatedRoute.snapshot.params['creator']) {
            filterParams['creator'] = this._activatedRoute.snapshot.params['creator'];
          }
          if (!isEquals(this.searchState.filterParams, { ...filterParams, ...this.searchState.sortParams })) {
            this._routerNav.navigateToSearch(this.searchState.searchQuery, filterParams, this.searchState.sortParams);
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  onClickAdvanced(e) {
    if (!this._paywall.isFeatureEnabled(Features.AdvancedSearch)) {
      e.preventDefault();
      e.stopPropagation();
      this._paywall.showPayWall(Features.AdvancedSearch);
    }
  }

  get isAdvancedSearchEnabled() {
    return this._paywall.isFeatureEnabled(Features.AdvancedSearch);
  }

  capitalizeFirstLetter(textToTransrorm: string) {
    return textToTransrorm.charAt(0).toUpperCase() + textToTransrorm.slice(1);
  }
}
