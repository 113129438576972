import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { compose } from '@ngrx/store';
import { getEntitySelector } from '../../functions/selectors';
import { DEFAULT_PROJECTS_FILTER } from '../../state';
import * as defaultProjectsFilter from '../../actions/task-filters/default-projects-filter.actions';
import { DefaultTasksProjectsFilterActionTypes } from '../../actions/task-filters/default-projects-filter.actions';
import { TaskDefaultFilterActionTypes } from '../../actions/task-filters/task-default-filter.actions';

export interface DefaultProjectsFilter {
  projectsIds: number[];
}

const initState: DefaultProjectsFilter = {
  projectsIds: []
};

export function reducer(state = initState, action: defaultProjectsFilter.Actions): DefaultProjectsFilter {
  switch (action.type) {
    case DefaultTasksProjectsFilterActionTypes.TOGGLE_ITEM_ID: {
      let projectsIds: number[];
      projectsIds = action['payload'];
      state = Object.assign({}, state, { projectsIds: projectsIds });
      return state;
    }

    case DefaultTasksProjectsFilterActionTypes.RESET: {
      return initState;
    }

    case TaskDefaultFilterActionTypes.SET: {
      if (action['payload'].activeFilters.defaultProjectsFilter) {
        state = { projectsIds: [...action['payload'].activeFilters.defaultProjectsFilter] };
      } else {
        state = initState;
      }
      return state;
    }

    default: {
      return state;
    }
  }
}

const isActive = (state$: Observable<DefaultProjectsFilter>) => state$.pipe(map(state => state.projectsIds.length > 0));
const getProjectsIds = (state$: Observable<DefaultProjectsFilter>) => state$.pipe(map(state => state.projectsIds));

export const getDefaultProjectsFilterEntity = getEntitySelector(DEFAULT_PROJECTS_FILTER);
export const isActiveDefaultProjectsFilter = compose(isActive, getDefaultProjectsFilterEntity);
export const getDefaultProjectsFilterIds = compose(getProjectsIds, getDefaultProjectsFilterEntity);
