import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Avatar } from '../../interfaces/avatar';
import { UserPreviewService } from '../../atlaz-bnp/services/user-preview.service';

const combineFromFullName = user => (user.firstname ? user.firstname[0] : '') + (user.lastname ? user.lastname[0] : '');

const combineFromEmail = user => {
  const emailLogin = (user.email || '').split('@')[0];
  let result = '';

  if (emailLogin.length === 1) {
    result = emailLogin[0];
  } else {
    result = emailLogin.slice(0, 2);
  }

  return result;
};

@Component({
  selector: 'a-avatar',
  templateUrl: './a-avatar.component.html',
  styleUrls: ['./a-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AAvatarComponent {
  @Input() user: Avatar;
  @Input() openPreviewOnClick = true;

  constructor(private _userPreview: UserPreviewService) {}

  get photoUrl() {
    return this.user && this.user.photo && this.user.photo.previews && this.user.photo.previews.w100
      ? this.user.photo.previews.w100.path
      : '';
  }
  get initials() {
    if (this.user) {
      return this.user.firstname || this.user.lastname ? combineFromFullName(this.user) : combineFromEmail(this.user);
    }
    return '';
  }

  hideFullView($event) {
    if (this.openPreviewOnClick) {
      this._userPreview.hidePreview($event);
    }
  }

  showFullView($event) {
    if (this.openPreviewOnClick) {
      event.preventDefault();
      event.stopPropagation();
      this._userPreview.openPreview(this.user, $event);
    }
  }
}
