
import {withLatestFrom, filter, pluck, distinctUntilChanged, switchMap, tap, map, combineLatest} from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { consoleLog, fromCamelToDash, isPresent } from '../../../../helpers';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getBoardById } from '../../../ngrx/reducers/board.reducer';
import { STATUS_CODES } from '../../../permissions/interfaces/page-status.interface';
import { SprintBoard } from '../../../interfaces';
import { AppState } from '../../../ngrx/state';
import { PaginationLoaderService } from '../../../shared/services/paginataion-loader/paginataion-loader.service';
import { SPRINT_TASK_PL, VERSION_PL } from '../../../constants';
import { SegmentService } from '../../../atlaz-bnp/services/intergations/segment/segment.service';

@Component({
  templateUrl: './velocity-report-page.component.html',
  styleUrls: ['./velocity-report-page.component.scss'],
  providers: [PaginationLoaderService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VelocityReportPageComponent implements OnInit {
  public isOkStatus$: Observable<boolean>;
  public board$: Observable<SprintBoard>;
  public isAllVersionsLoaded$: Observable<boolean>;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _store: Store<AppState>,
    private _segmet: SegmentService,
    private _paginatorService: PaginationLoaderService
  ) {}

  ngOnInit() {
    this._segmet.track('VelocityReportVisited');
    this.board$ = this._activatedRoute.params.pipe(
      pluck('boardId'),
      distinctUntilChanged(),
      switchMap(id => <Observable<SprintBoard>>this._store.pipe((getBoardById(id)))),);

    this.isOkStatus$ = this._activatedRoute.data.pipe(
      tap(consoleLog('pageStatus')),
      pluck('pageStatus', 'statusCode'),
      map(status => STATUS_CODES.OK === status),
      combineLatest(this.board$.pipe(map(isPresent)), (a, b) => a && b),);

    this.isAllVersionsLoaded$ = this.isOkStatus$.pipe(
      filter(isPresent),
      // sprintBoard have the only project
      withLatestFrom(this.board$, (isOk, board) => ({ projectId: (board.projectsIds || [])[0], boardId: board.id })),
      tap(versionParams => {
        this._paginatorService.controller = fromCamelToDash(VERSION_PL);
        this._paginatorService.limit = 300;
        this._paginatorService.queryParams = {
          sort: '-updatedAt',
          released: 1,
          project: versionParams.projectId,
          board: versionParams.boardId,
          expand: SPRINT_TASK_PL
        };
        this._paginatorService.loadAll = true;
        this._paginatorService.loadMore();
      }),
      switchMap(() => this._paginatorService.hasMore$),
      map(x => !x),);
  }
}
