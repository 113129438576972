import { Action } from './unsafe-action';
import { labelAction } from '../functions/action';
import { PartOfEntity, Version, HttpQueryParam } from '../../interfaces';
import { VERSION } from '../../constants';

export const VersionActionTypes = {
  LOAD: labelAction(VERSION, 'LOAD'),
  GET: labelAction(VERSION, 'GET'),
  GET_GET: labelAction(VERSION, 'GET_GET'),
  GET_COMPLETE: labelAction(VERSION, 'GET_COMPLETE'),
  GET_FAIL: labelAction(VERSION, 'GET_FAIL'),
  ADD: labelAction(VERSION, 'ADD'),
  ADD_COMPLETE: labelAction(VERSION, 'ADD_COMPLETE'),
  ADD_FAIL: labelAction(VERSION, 'ADD_FAIL'),
  EDIT: labelAction(VERSION, 'EDIT'),
  EDIT_COMPLETE: labelAction(VERSION, 'EDIT_COMPLETE'),
  EDIT_FAIL: labelAction(VERSION, 'EDIT_FAIL'),
  DELETE: labelAction(VERSION, 'DELETE'),
  DELETE_COMPLETE: labelAction(VERSION, 'DELETE_COMPLETE'),
  DELETE_FAIL: labelAction(VERSION, 'DELETE_FAIL'),
  ASSIGN_TASK: labelAction(VERSION, 'ASSIGN_TASK')
};

export const VERSION_TASK_UPDATED = '[VERSION] task updated';

export class VersionLoadAction implements Action {
  type = VersionActionTypes.LOAD;

  constructor(public payload?: HttpQueryParam) {}
}

export class VersionGetAction implements Action {
  type = VersionActionTypes.GET;

  constructor(public payload?: HttpQueryParam) {}
}
export class VersionGetGetAction implements Action {
  type = VersionActionTypes.GET_GET;

  constructor(public payload?: HttpQueryParam) {}
}

export class VersionGetCompleteAction implements Action {
  type = VersionActionTypes.GET_COMPLETE;

  constructor(public payload: Version[]) {}
}

export class VersionEditAction implements Action {
  type = VersionActionTypes.EDIT;
  constructor(public payload: PartOfEntity) {}
}

export class VersionEditCompleteAction implements Action {
  type = VersionActionTypes.EDIT_COMPLETE;

  constructor(public payload: Version) {}
}

export class VersionDeleteAction implements Action {
  type = VersionActionTypes.DELETE;

  constructor(public payload: number) {}
}

export class VersionDeleteCompleteAction implements Action {
  type = VersionActionTypes.DELETE_COMPLETE;

  constructor(public payload?: any) {}
}

export class VersionAddAction implements Action {
  type = VersionActionTypes.ADD;

  constructor(public payload: PartOfEntity) {}
}

export class VersionAddCompleteAction implements Action {
  type = VersionActionTypes.ADD_COMPLETE;

  constructor(public payload: Version) {}
}

export class VersionAssignTaskAction implements Action {
  type = VersionActionTypes.ASSIGN_TASK;

  constructor(public payload: { id: number; tasks: { add?: number[]; remove?: number[] } }) {}
}

export class VersionTasksUpdated implements Action {
  readonly type = VERSION_TASK_UPDATED;

  constructor(public payload: { id: number; tasksIds: number[]; countTasks: number }) {}
}

export type VersionActions =
  | VersionGetAction
  | VersionLoadAction
  | VersionGetCompleteAction
  | VersionEditAction
  | VersionEditCompleteAction
  | VersionDeleteAction
  | VersionDeleteCompleteAction
  | VersionAddAction
  | VersionAddCompleteAction
  | VersionAssignTaskAction
  | VersionTasksUpdated;
