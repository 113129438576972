import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'smart-mention',
  template: `<b>{{value}}</b>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartMentionComponent {
  @Input() value = '';
}
