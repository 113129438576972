<form class="payment-form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <form-errors></form-errors>
  <div class="billing-fieldset">
    <div class="form-row">
      <label for="company_name">Company Name</label>
      <input id="company_name" formControlName="company_name" checkTouch/>
    </div>

    <div class="form-row">
      <label for="card_holder_name">Cardholder Name</label>
      <input id="card_holder_name" formControlName="card_holder_name" checkTouch/>
    </div>

    <div class="form-row">
      <label for="billing-address1">Address</label>
      <input id="billing-address1" formControlName="address_line1" checkTouch/>
    </div>

    <div class="form-row">
      <div class="form-col-mid">
        <label for="address_city">City</label>
        <input id="address_city" formControlName="address_city" checkTouch/>
      </div>
      <div class="form-col-mid">
        <label for="address_state">Region/State</label>
        <input id="address_state" formControlName="address_state" checkTouch/>
      </div>
    </div>

    <div class="form-row">
      <div class="form-col-7">
        <label for="address_country">Country</label>
        <select id="address_country" formControlName="address_country" checkTouch>
          <option *ngFor="let country of countries$ | async;" [value]="country.value">{{country.text}}</option>
        </select>
      </div>
      <div class="form-col-3">
        <label for="address_zip">Zip/Postal code</label>
        <input id="address_zip" formControlName="address_zip" checkTouch/>
      </div>
    </div>

    <div class="form-row">
      <label for="email">E-mail</label>
      <input id="email" formControlName="email" checkTouch/>
      <help-block [control]="form.get('email')"></help-block>
    </div>

    <div class="form-row">
      <label for="phone_number">Phone number</label>
      <input id="phone_number" formControlName="phone_number" checkTouch/>
    </div>
  </div>

  <div class="submit-block">
    <button a-button class="btn_primary submit" [pending]="_formService.isPending$ | async">Save
    </button>
  </div>
</form>
