<div class="flex_wrapper">
  <div class="zero_swimlane" (dragover)="onDragOverGroup($event, {value: 0}, -1)">

  </div>
  <section class="lb_group" *ngFor="let group of allGroups; let i = index" [style.width]="groupWidth"
           [style.order]="i"
           [attr.draggable]="dragAllowed"
           (dragstart)="onDragStartGroup($event, group)"
           (dragover)="onDragOverGroup($event, group, i)"
           (dragend)="onDragEndGroup($event)"
  >
    <swimlane-edit-popup
      class="near_by_name"
      *ngIf="swimlaneEditingId === group.value"
      [swimlane]="swimlane"
      [isGroup]="true"
      [isDeleteUnavailable]="allGroups.length < 2"
      [attr.draggable-disabled]="true"
      (close)="onCloseSwimlaneEdit()"
    >
    </swimlane-edit-popup>
    <h3 class="group_header"
        (click)="onToggleState(group.value)"
        (dragover)="onDragOverH3($event, group)"
        [class.dragOver]="isSwimlaneDragOveredByTask(group)"
    >
    <span class="group_state" [class.closed]="isClosed[group.value]">
      <svg>
        <use xlink:href="#triangle"></use>
      </svg>
    </span>
      {{group.title}}
      <svg class="edit_group" *ngIf="isGroupedBySwimlane" (click)="onEditSwimlane($event, group)">
        <use xlink:href="#pencil"></use>
      </svg>
    </h3>
    <div *ngIf="!isClosed[group.value]">
      <list-board-add-task [toTop]="true" *ngIf="isNotGuest && board && groupedTasks.groupsMap[group.value] && groupedTasks.groupsMap[group.value].length > 9" [boardId]="board.id" [group]="group"></list-board-add-task>
      <ul class="tasks_list">
        <li class="task_item" *ngFor="let task of groupedTasks.groupsMap[group.value]"
            [attr.draggable]="dragAllowed"
            (dragstart)="onDragStart($event, task)"
            (dragover)="onDragOver($event, task)"
            (dragend)="onDragEnd($event)"
        >
          <list-board-task-item [task]="task"
                                [scoringType]="board.scoringType"
                                [scoringColumns]="scoringColumns"
                                [customFields]="customFields"
                                [customFieldsValues]="tasksCustomFieldsMap[task.id] || {}"
                                [isNotGuest]="isNotGuest"
                                [isEditing]="editingTaskId === task.id"
                                [boardUsersMap]="boardUsersMap"
                                [boardLabelsMap]="boardLabelsMap"
                                (completeTask)="onCompleteTask($event)"
                                (editingStart)="onStartEdit($event, task)"
                                (editingEnd)="onEndEdit($event)"
                                (editScoring)="onEditScoring($event)"
                                (showDeleteDialog)="onOpenDeleteConfirm($event)"
                                (openQuickActions)="onOpenQuickActions($event)"
                                [class.dragOver]="dragOverTask && dragOverTask.id === task.id"
          ></list-board-task-item>
        </li>
      </ul>
      <list-board-add-task *ngIf="isNotGuest && board" [boardId]="board.id" [group]="group"></list-board-add-task>
    </div>
  </section>
  <div class="drag_pointer" *ngIf="dragPointerOrder > -2" [style.order]="dragPointerOrder"></div>
</div>
<div class="edit_field_wrapper" *ngIf="editMembersTaskId$ | async" [style.left.px]="membersClientRect.x || 0" [style.top.px]="membersClientRect.y || 0" >
  <members-popup
    (addUserToBoard)="onAddUserToBoard($event)"
    (close)="onCloseMembersPopup()"
    (update) = "onUpdateUsersList($event)"
    [isPublicBoard]="isPublicBoard$ | async"
    [possibleUsers]="taskPossibleUsers$ | async"
    [selectedUsers]="taskUsers$ | async"
    [projectOnlyUsers]="projectsOnlyUsers$ | async"
  ></members-popup>
</div>

<div class="edit_field_wrapper" *ngIf="editLabelsTaskId$ | async" [style.left.px]="labelsClientRect.x || 0" [style.top.px]="labelsClientRect.y || 0" >
  <sidebar-labels-popup
    (close)="onCloseLabelsPopup()"
    [task]="editingFieldTask"
  ></sidebar-labels-popup>
</div>

<div class="edit_field_wrapper" *ngIf="editDueDateTaskId$ | async">
  <a-date-time-inputless
    [pickerId]="'due_date_value'"
    [(ngModel)]="dueDateValue"
    [defaultDate]="dueDateValue"
    (submitValue)="onSubmitDueDate(250)"
    (cancel)="onCloseDueDatePopup(250)"
  ></a-date-time-inputless>
</div>

<div class="edit_field_wrapper" *ngIf="editCustomFieldTaskId$ | async" [style.left.px]="tasksCustomFieldClientRect.x || 0" [style.top.px]="tasksCustomFieldClientRect.y || 0">
  <tasks-custom-field-edit [useContextPopUp]="true"
                           [taskId]="editingFieldTask ? editingFieldTask.id : 0"
                           [customFieldValue]="tasksCustomFieldValue"
                           [customField]="customFieldValue"
                           (close)="onCloseCustomFieldPopup()"
  ></tasks-custom-field-edit>
</div>

<div *ngIf="showDeleteConfirmDialog$ | async">
  <div a-confirmation-popup
       [confirmationTitle]="'Delete Task'"
       [actionButtonText]="'Delete'"
       (confirm)="onDeleteTaskConfirm()"
       (close)="onCloseDeleteConfirmDialog()"
  >
    <p>Are You Sure You Want to Delete this task?</p>
    <p>All task data including comments, attachments and statistics will be lost.</p>
    <p>There is no undo.</p>
  </div>
</div>

<ng-container [ngSwitch]="board.scoringType">
  <ng-container *ngSwitchCase="ScoringType.advanced">
    <edit-task-scoring
      *ngIf="isScoreEditing$ | async"
      [focusOn]="editingCriterion$ | async"
      [task]="editingTask$ | async"
      (close)="onCloseEditingScoring()"
    ></edit-task-scoring>
  </ng-container>
  <ng-container *ngSwitchCase="ScoringType.ICE">
    <edit-ice-rice-scoring
      *ngIf="isScoreEditing$ | async"
      [focusOn]="editingCriterion$ | async"
      [task]="editingTask$ | async"
      [type]="board.scoringType"
      (close)="onCloseEditingScoring()"
    ></edit-ice-rice-scoring>
  </ng-container>
  <ng-container *ngSwitchCase="ScoringType.RICE">
    <edit-ice-rice-scoring
      *ngIf="isScoreEditing$ | async"
      [focusOn]="editingCriterion$ | async"
      [task]="editingTask$ | async"
      [type]="board.scoringType"
      (close)="onCloseEditingScoring()"
    ></edit-ice-rice-scoring>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <edit-basic-scoring
      *ngIf="isScoreEditing$ | async"
      [focusOn]="editingCriterion$ | async"
      [board]="board"
      [task]="editingTask$ | async"
      (close)="onCloseEditingScoring()"
    ></edit-basic-scoring>
  </ng-container>
</ng-container>
