<table class="table_wrapper">
  <thead>
    <tr>
      <th rowspan="2" class="sticky_left filter_cell">
        TASKS
        <div class="refresh" a-tooltip="Reset to original order" position="bottom-right" (click)="resetSort()"></div>
        <div>
          <input type="text" class="search_input" (input)="onChangeFilter($event)" placeholder="Search Tasks">
        </div>
      </th>
      <th *ngIf="(criteriaValues$ | async).length" [colSpan]="(criteriaValues$ | async).length">VALUE</th>
      <th *ngIf="(criteriaEfforts$ | async).length" [colSpan]="(criteriaEfforts$ | async).length">EFFORTS</th>
      <th rowspan="2" (click)="applySortBy($event, 'confidence')" class="sortable">
        CONFIDENCE
        <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === 'confidence'}">
          <span data-sort-arrow="desc" class="arrow-up"></span>
          <span data-sort-arrow="asc" class="arrow-down"></span>
        </div>
      </th>
      <th rowspan="2" class="sticky_right sortable" (click)="applySortBy($event, 'score')">
        SCORE
        <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === 'score'}">
          <span data-sort-arrow="desc" class="arrow-up"></span>
          <span data-sort-arrow="asc" class="arrow-down"></span>
        </div>
      </th>
    </tr>
    <tr>
      <th *ngFor="let cr of (criteriaValues$ | async)" (click)="applySortBy($event, cr.id)" class="sortable">
        {{cr.name}}
        <span class="info_icon" [a-tooltip]="cr.description || 'No Description'" position="top"></span>
        <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === cr.id}">
          <span data-sort-arrow="desc" class="arrow-up"></span>
          <span data-sort-arrow="asc" class="arrow-down"></span>
        </div>
      </th>
      <th *ngFor="let cr of (criteriaEfforts$ | async)" (click)="applySortBy($event, cr.id)" class="sortable">
        {{cr.name}}
        <span class="info_icon" [a-tooltip]="cr.description || 'No Description'" position="top"></span>
        <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === cr.id}">
          <span data-sort-arrow="desc" class="arrow-up"></span>
          <span data-sort-arrow="asc" class="arrow-down"></span>
        </div>
      </th>
    </tr>
  </thead>
  <tbody (click)="onEditTaskCriterion($event)">
    <tr *ngFor="let task of (calculatedTasks$ | async)" [attr.data-task-id]="task.id">
      <td class="sticky_left"
          [routerLink]="getTaskLink(task.id)"
      >
        {{task.title}}
      </td>
      <td *ngFor="let cr of (criteriaValues$ | async)" [attr.data-criterion]="cr.id" [attr.data-task]="task.id">{{task.scoringValuesMap[cr.id] ? task.scoringValuesMap[cr.id].visibleValue : '?'}}</td>
      <td *ngFor="let cr of (criteriaEfforts$ | async)" [attr.data-criterion]="cr.id" [attr.data-task]="task.id">{{task.scoringValuesMap[cr.id] ? task.scoringValuesMap[cr.id].visibleValue : '?'}}</td>
      <td [attr.data-criterion]="'confidence'" [attr.data-task]="task.id">{{task.confidence}}</td>
      <td [attr.data-criterion]="'confidence'" [attr.data-task]="task.id" class="sticky_right">{{task.score}}</td>
    </tr>
    <tr *ngIf="!(calculatedTasks$ | async)?.length && (filterValue$ | async)" class="no_found">
      <td class="sticky_left">For request: <strong>{{filterValue$ | async}}</strong> nothing is found</td>
      <td [colSpan]="(criteriaValues$ | async)?.length + (criteriaEfforts$ | async)?.length + 2"></td>
    </tr>
  </tbody>
</table>
<edit-task-scoring (close)="onCloseEditingCrs()" *ngIf="isScoreEditing$ | async" [focusOn]="editingCriterion$ | async" [task]="editingTask$ | async"></edit-task-scoring>
