<div class="list_container" #list_container>
  <div *ngFor="let member of visibleMembers; let i=index"
       class="user_title"
       [ngClass]="{'focus': i === focusedUserNumber}"
       (click)="onSelectMember(member)"
  >
    <a-avatar class="avatar_container" [user]="member"></a-avatar>
    <div class="member_info">
      <div class="member_info_name"> {{member.firstname}} {{member.lastname}}</div>
      <div class="member_info_text">@{{member.nickname}}</div>
    </div>
  </div>
</div>
