import { EstimationType } from '../app/constants';
import { Board } from '../app/interfaces';
import { SprintsTasks } from '../app/interfaces/sprints-tasks';

export const isHoursEstimateAvailable = (board: Board) =>
  board && ([EstimationType.hours, EstimationType.any].includes(board.estimatedBy) || !board.sprintStartDate);
export const isPointsEstimateAvailable = (board: Board) =>
  board && !(board.sprintStartDate && board.estimatedBy === EstimationType.hours);

export const getSprintsTaskCompletedSec = (sprintsTask: SprintsTasks) => {
  let result = 0;
  const limit = sprintsTask.remainingEstimate;
  if (sprintsTask.doneDate || !limit) {
    result = +limit;
  } else {
    (Array.isArray(sprintsTask.worklogs) ? sprintsTask.worklogs : []).some(logwork => {
      const limitReached = result + logwork.loggedTime >= limit;
      result = limitReached ? limit : result + logwork.loggedTime;
      return limit > result;
    });
  }
  return result || 0;
};
