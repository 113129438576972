import { switchMap, map, combineLatest, filter, take } from 'rxjs/operators';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../../shared/services/form-v2.service';
import { JsonApiSingeModelResponse } from '../../../shared/services/app/web-socket/http-response';
import { HandleResponseAction } from '../../../ngrx/actions/root.action';
import { Observer, BehaviorSubject } from 'rxjs';
import { BillingPeriod, BillingPlanName, SUBSCRIPTION_PATH } from '@atlaz/billing/constants/billing-subscription';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { BillingSubscriptionEntity } from '@atlaz/billing/interfaces/billing-subscription';
import { PhoneCodesService } from '../../../shared/services/phone-codes.service';
import { RouterNavigateService } from '../../../shared/services/router-navigate.service';
import { environment } from '../../../../environments/environment';
import { BillingPublicApiService } from '@atlaz/billing/shared/billing-public-api.service';
import { FbPixelService } from '../../../atlaz-bnp/services/intergations/fb-pixel/fb-pixel.service';
import { getCurrentCompany } from '../../../ngrx/reducers/current-company.reducer';
import { Company, User } from '../../../interfaces';
import { getUserById } from '../../../ngrx/reducers/user.reducer';
import { isPresent } from '../../../../helpers';
import { fromBillingPlans } from '@atlaz/billing/store/plan/plan.reducer';
import { ToastrService } from 'ngx-toastr';

declare const window;

@Component({
  selector: 'payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss'],
  providers: [FormV2Service]
})
export class PaymentFormComponent implements OnInit, AfterViewInit {
  @Input() billingSub: BillingSubscriptionEntity;
  @Input() period: BillingPeriod;
  @Input() plan: BillingPlanName;

  formObserver: Observer<any> = {
    next: (resp: JsonApiSingeModelResponse<any>) => {
      this._store.dispatch(new HandleResponseAction(resp));
      this._fbPixel.fbq('track', 'AddPaymentInfo');
      if (resp.meta && resp.meta.there_d_secure_required) {
        this._routerNav.navigate3DSecurePopup({
          plan: this.plan,
          period: this.period
        });
      } else {
        // TODO: add 3 types of message depends on billing status
        this.chargeIsRequired ? this.onShowSuccessPaymentPopup() : this.onCardAddedSuccess();
      }
    },

    error: error => {
      if (!this.chargeIsRequired) {
        this.onCardAddedError();
      }
    },

    complete: () => {}
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.edit,
    entityToEdit: SUBSCRIPTION_PATH,
    formObserver: this.formObserver,
    prepareFormValue: formValue => {
      const value = {
        source: formValue['source'],
        id: formValue['id'],
        action: 'add-card',
        period: this.period,
        plan: this.plan,
        additionalData: { ...formValue }
      };
      delete value.additionalData['source'];
      delete value.additionalData['id'];

      return value;
    }
  };

  public form: FormGroup;
  public stripeErrors$ = new BehaviorSubject('');

  public lastCardNumbers: string;

  public shortView = true;
  public isPaymentSuccessPopupVisible = false;

  public stripeLoading$ = new BehaviorSubject(false);
  public loading$ = this.stripeLoading$.pipe(combineLatest(this._formService.isPending$, (a, b) => a || b));

  public plan$;

  public countries$ = this._phoneCodesService.getCountriesInputOptions();

  readonly chargeIsRequired$ = this._billingService.chargeIsRequired$;

  private stripe = (<any>window).Stripe(environment.billing.stripePublicKey);
  private cardElement;

  private hasStripeError = false;

  private chargeIsRequired = false;

  constructor(
    private _navigate: RouterNavigateService,
    private _fb: FormBuilder,
    private _store: Store<AppState>,
    private _formService: FormV2Service,
    private _fbPixel: FbPixelService,
    private _billingService: BillingPublicApiService,
    private _toastr: ToastrService,
    private _routerNav: RouterNavigateService,
    private _phoneCodesService: PhoneCodesService
  ) {}

  onSwitchShortView() {
    this.shortView = !this.shortView;
  }

  ngOnInit() {
    this.form = this._fb.group({
      id: this.billingSub.id,
      company_name: [''],
      card_holder_name: [''],
      address_line1: [''],
      address_city: [''],
      address_state: [''],
      address_zip: [''],
      address_country: ['US'],
      phone_number: [''],
      source: [''],
      email: ['', [Validators.required, Validators.email]]
    });

    this._store
      .select(getCurrentCompany)
      .pipe(
        map((company: Company) => company.owner),
        switchMap(ownerId => this._store.pipe(getUserById(ownerId))),
        filter(isPresent),
        take(1)
      )
      .subscribe((user: User) => {
        this.form.patchValue({ email: user.email });
      });

    if (this.billingSub.additionalData) {
      Object.keys(this.billingSub.additionalData).forEach(key => {
        if (this.form.contains(key)) {
          this.form.get(key).setValue(this.billingSub.additionalData[key]);
        }
      });
    }

    this.plan$ = this._store.select(fromBillingPlans.get(this.plan));

    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  ngAfterViewInit() {
    this.createdCardElement();
  }

  private createdCardElement() {
    const el = this.stripe.elements();

    const style = {
      base: {
        color: '#32325d',
        lineHeight: '24px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    // Create an instance of the card Element
    this.cardElement = el.create('card', { style: style, hidePostalCode: true });

    // Add an instance of the card Element into the `card-element` <div>
    this.cardElement.mount('#card-element');

    // Handle real-time validation errors from the card Element.
    this.cardElement.addEventListener('change', event => {
      if (event.error) {
        this.hasStripeError = true;
        this.stripeErrors$.next(event.error.message);
      } else {
        this.hasStripeError = false;
        this.stripeErrors$.next('');
      }
    });
  }

  onCardAddedSuccess() {
    this._toastr.success('Card has been successfully added');
    this.onNavigateToBilling();
  }

  onCardAddedError() {
    this._toastr.error('Card has not been added');
  }

  onNavigateToBilling() {
    this._routerNav.navigateToBilling();
  }

  onShowSuccessPaymentPopup() {
    this.isPaymentSuccessPopupVisible = true;
  }

  onSubmit() {
    if (this.hasStripeError || this.form.invalid) {
      return false;
    }

    this.chargeIsRequired$.pipe(take(1)).subscribe(chargeIsRequired => (this.chargeIsRequired = chargeIsRequired));

    this.stripeLoading$.next(true);
    this.stripe.createSource(this.cardElement).then(
      result => {
        if (result.error) {
          // Inform the user if there was an error
          this.stripeErrors$.next(result.error.message);
        } else {
          this.lastCardNumbers = result.source.card.last4;
          // Send the source to your server
          this.form.get('source').patchValue(result.source.id);
          this._formService.submit();
        }
        this.stripeLoading$.next(false);
      },
      e => {
        this.stripeLoading$.next(false);
      }
    );

    return false;
  }
}
