import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observer } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../../../shared/services/form-v2.service';
import { ScoringType, SegmentScoringType, TASK_PL } from '../../../../constants';
import { Column, Swimlane } from '../../../../interfaces';
import { AppState } from '../../../../ngrx/state';
import { PatternValidator } from '../../../../shared/validators/custom-validators';
import { SegmentService } from '../../../../atlaz-bnp/services/intergations/segment/segment.service';
import { BacklogChartService } from '../../../backlog-chart/backlog-chart.service';
import { HandleResponseAction } from '../../../../ngrx/actions/root.action';
import { fromBoards } from '../../../../ngrx/reducers/board.reducer';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'backlog-add-task-form',
  templateUrl: './backlog-add-task-form.component.html',
  styleUrls: ['./backlog-add-task-form.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BacklogAddTaskFormComponent implements OnInit {
  @Input() swimlanes: Swimlane[];
  @Input() columns: Column[];
  @Input() source: 'BacklogChartTaskCreated' | '';
  @Input() backlogScoreX = 0;
  @Input() backlogScoreY = 0;

  @Output() taskCreated = new EventEmitter();

  private formObserver: Observer<any> = {
    next: response => {
      this._backlogChartService.lastCreatedByMeTaskId$.next(response.data.id);
      if (this.source) {
        this._segment.track('BacklogChartTaskCreated');
      }
      try {
        this._store
          .select(fromBoards.get(response.data.relationships.board.data.id))
          .pipe(map(board => board.scoringType), take(1))
          .subscribe(scoringType => {
            if (scoringType !== ScoringType.basic) {
              this._segment.track('AdvScoringTaskAdded', { scoringType: SegmentScoringType[scoringType] });
            }
          });
      } catch (e) {
        console.log('sending track AdvScoringTaskAdded', e);
      }
      this._store.dispatch(new HandleResponseAction(response));
      this.taskCreated.emit();
    },

    error: error => {
      console.log(error, 'error on component');
    },

    complete: () => {}
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.add,
    entityToEdit: TASK_PL,
    formObserver: this.formObserver
  };

  public addTaskForm: FormGroup;
  public title: string;

  constructor(
    private _fb: FormBuilder,
    public _formService: FormV2Service,
    public _backlogChartService: BacklogChartService,
    private _segment: SegmentService,
    private _store: Store<AppState>
  ) {}

  ngOnInit() {
    this.addTaskForm = this._fb.group({
      backlogScoreX: [this.backlogScoreX],
      backlogScoreY: [this.backlogScoreY],
      column: [this.columns[0].id],
      swimlane: [this.swimlanes[0].id],
      title: [
        '',
        Validators.compose([Validators.required, PatternValidator.pattern(/[^\s]/), Validators.maxLength(1000)])
      ],
      inSwimlanePosition: ['last']
    });
    this._formService.initFormParams(this.addTaskForm, this.formServiceParams);
  }

  onSubmit() {
    this._formService.markAsDirty();
    if (this.addTaskForm.valid) {
      this._formService.submit();
      return false;
    }
  }
}
