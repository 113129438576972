import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Swimlane } from '../../interfaces/swimlane';
import { PartOfEntity } from '../../interfaces/part-of-entity';
import { PatternValidator } from '../../shared/validators/custom-validators/pattern-validator';

@Component({
  selector: 'swimlane-rename',
  templateUrl: './swimlane-rename.component.html',
  styleUrls: ['./swimlane-rename.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwimlaneRenameComponent implements OnInit {
  @Input() swimlane: Swimlane;

  @Output() submitForm: EventEmitter<PartOfEntity> = new EventEmitter<PartOfEntity>();

  public swimlaneForm: FormGroup;

  constructor(private _fb: FormBuilder) {}

  ngOnInit(): any {
    this.swimlaneForm = this._fb.group({
      id: [this.swimlane !== null ? this.swimlane.id : 0],
      name: [
        this.swimlane !== null ? this.swimlane.name : '',
        Validators.compose([Validators.required, PatternValidator.pattern(/[^\s]/), Validators.maxLength(50)])
      ]
    });
  }

  onSubmit() {
    if (this.swimlaneForm.valid === true) {
      this.submitForm.emit(this.swimlaneForm.value);
    }
  }
}
