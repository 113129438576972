<!--<a a-context-menu-item (click)="onCloseMenu()" href="https://university.hygger.io/" target="_blank" rel="noopener">-->
<!--	<header-university-icon-svg></header-university-icon-svg>-->
<!--	<p>Hygger University</p>-->
<!--</a>-->
<!--<p a-context-menu-item [divider]="true"></p>-->
<a a-context-menu-item (click)="onCloseMenu()" href="https://university.hygger.io/" target="_blank" rel="noopener">
	<header-help-icon-svg></header-help-icon-svg>
	<p>Help Center</p>
</a>
<p a-context-menu-item [divider]="true"></p>
<div a-context-menu-item (click)="onFeatureRequest()">
	<header-feature-icon-svg></header-feature-icon-svg>
	<p>Feature Request</p>
</div>

