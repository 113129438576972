import { Action } from '../unsafe-action';
import { ReportGroupsFilter } from '../../reducers/report-filters/report-groups-filter.reducer';

export enum ReportGroupFilterActionTypes {
  APPLY = '[REPORT_GROUP_FILTER] APPLY',
  RESET = '[REPORT_GROUP_FILTER] RESET'
}

export class ReportGroupFilterApplyAction implements Action {
  readonly type = ReportGroupFilterActionTypes.APPLY;

  constructor(public payload: ReportGroupsFilter) {}
}

export class ReportGroupFilterResetAction implements Action {
  readonly type = ReportGroupFilterActionTypes.RESET;
}

export type Actions = ReportGroupFilterApplyAction | ReportGroupFilterResetAction;
