import { map } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BillingPublicApiService } from '@atlaz/billing/shared/billing-public-api.service';
import { CompanyService } from '../../../shared/services/app/company.service';
import { BillingPlan } from '@atlaz/billing/interfaces/billing-plan';
import { BillingSubscriptionEntity } from '@atlaz/billing/interfaces/billing-subscription';
import { BillingPeriod, BillingPlanName } from '@atlaz/billing/constants/billing-subscription';
import { calcTotalPrice } from '@atlaz/billing/util/billing-plan';

@Component({
  selector: 'payment-header',
  templateUrl: './payment-header.component.html',
  styleUrls: ['./payment-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentHeaderComponent implements OnInit {
  @Input() period: BillingPeriod;
  @Input() plan: BillingPlan;
  @Input() planName: BillingPlanName;

  get perMemberPrice() {
    return this.plan.price[this.period];
  }

  readonly price$ = this._billingService.billingSubscription$.pipe(
    map((sub: BillingSubscriptionEntity) => calcTotalPrice(sub.usersCount, this.plan, this.period).toFixed(2))
  );
  readonly hasActivePrepaidSubscription$ = this._billingService.hasActivePrepaidSubscription$;
  readonly billingSub$ = this._billingService.billingSubscription$;

  constructor(private _billingService: BillingPublicApiService, private _companyService: CompanyService) {}

  ngOnInit() {}
}
