import { mergeMap, catchError, map, tap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import { IS_COVER, TASK_PL, TASKS_ATTACHMENT_PL } from '../../constants';
import { HttpQueryParam, PartOfEntity, TaskAttachment } from '../../interfaces';

import {
  TaskAttachmentsActionTypes,
  TaskAttachmentsAddFromCommentCompleteAction
} from '../actions/task-attachments.actions';
import { defaultErrorHandler } from './root.effect';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { HandleResponseAction } from '../actions/root.action';
import { toDashedFromCamelCase } from '../../../helpers';
import { Store } from '@ngrx/store';
import { AppState } from '../state';

const attachmentsPath = toDashedFromCamelCase(TASKS_ATTACHMENT_PL);

@Injectable()
export class TaskAttachmentsEffects {
  @Effect()
  loadAttachments$ = this.actions$.ofType(TaskAttachmentsActionTypes.GET).pipe(
    tap(d => console.log(d, 'effect')),
    switchMap(({ type, payload }: { type: string; payload: HttpQueryParam }) => {
      return this._atlazApi
        .get(attachmentsPath, payload)
        .pipe(map(resp => new HandleResponseAction(resp)), catchError(defaultErrorHandler(type, payload)));
    })
  );

  @Effect({ dispatch: false })
  removeTaskAttachment$ = this.actions$.ofType(TaskAttachmentsActionTypes.DELETE).pipe(
    switchMap(({ type, payload: attachment }: { type: string; payload: TaskAttachment }) => {
      return this._atlazApi
        .deleteRequest([attachmentsPath, attachment.id])
        .pipe(catchError(defaultErrorHandler(type, attachment)));
    })
  );

  @Effect()
  addTaskAttachment$ = this.actions$.ofType(TaskAttachmentsActionTypes.ADD).pipe(
    mergeMap(({ type, payload }: { type: string; payload: { file: PartOfEntity; fromComment?: string } }) => {
      return this._atlazApi.post(attachmentsPath, payload.file).pipe(
        tap(resp => {
          if (payload.fromComment) {
            this._store.dispatch(
              new TaskAttachmentsAddFromCommentCompleteAction({
                path: resp['data'].attributes.path,
                name: resp['data'].attributes.name,
                fileType: resp['data'].attributes.type,
                commentKey: payload.fromComment
              })
            );
          } else if (document.activeElement.tagName === 'TEXTAREA') {
            const el = <HTMLInputElement | HTMLTextAreaElement>document.activeElement;
            const prevCursorPosition = el.selectionStart;
            const injection = '![' + resp['data'].attributes.name + '](' + resp['data'].attributes.path + ')';
            el.value = el.value.slice(0, el.selectionStart) + injection + el.value.slice(el.selectionEnd);
            el.selectionStart = el.selectionEnd = prevCursorPosition + injection.length;
            el.dispatchEvent(new Event('input', { bubbles: true }));
          }
        }),
        map(resp => new HandleResponseAction(resp)),
        catchError(defaultErrorHandler(type, payload.file))
      );
    })
  );

  @Effect()
  attachmentMakeCover$ = this.actions$.ofType(TaskAttachmentsActionTypes.MAKE_COVER).pipe(
    switchMap(({ type, payload }: { type: string; payload: any }) =>
      this._atlazApi
        .patch([toDashedFromCamelCase(TASKS_ATTACHMENT_PL), { expand: [TASK_PL] }], {
          id: payload.id,
          [IS_COVER]: payload.cover
        })
        .pipe(map(resp => new HandleResponseAction(resp)), catchError(defaultErrorHandler(type, payload)))
    )
  );

  constructor(private actions$: Actions, protected _atlazApi: AtlazApiV2Service, private _store: Store<AppState>) {}
}
