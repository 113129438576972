import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Column, Project, Task, User } from '../../../../interfaces';
import { AppState } from '../../../../ngrx/state';
import { Store } from '@ngrx/store';
import {
  TaskAssignSubscriberAction,
  TaskAssignUsersAction,
  TaskDeleteAction,
  TaskEditAction
} from '../../../../ngrx/actions/task.actions';
import { combineLatest, Observable } from 'rxjs';
import { AuthService } from '../../../services/app/auth.service';
import { AppUrls } from '../../../../app-urls';
import { filter, map, take } from 'rxjs/operators';
import { fromTask } from '../../../../ngrx/reducers/task.reducer';
import { isPresent, naturalSort } from '../../../../../helpers';
import {
  getTaskPossibleUsers,
  getTaskProjectOnlyUsers,
  getTaskUsers
} from '../../../../ngrx/functions/crossed.selector';
import { BoardAssignUsersAction } from '../../../../ngrx/actions/board.actions';
import { fromColumns, getGlobalDoneColumnsByBoardId } from '../../../../ngrx/reducers/column.reducer';
import { OpenedTaskDataChangedAction } from '../../../../task/ngrx/actions/opened-task.action';
import { Router } from '@angular/router';
import {
  GuiStateQuickTaskEditHide,
  GuiStateSetBoardViewMode
} from '../../../../ngrx/actions/gui-state-memorized.actions';
import { AContextControllComponent } from '../../../../atlaz-gui/a-context-controll/a-context-controll.component';
import { Subscription } from 'rxjs/index';
import { PaywallService } from '../../../../libs/paywall/paywall.service';
import { Features } from '../../../../libs/paywall/features.constants';
import { BoardViewMode } from '../../../../ngrx/reducers/gui-state-memorized.reducer';
import { RouterNavigateService } from '../../../services/router-navigate.service';
import { fromBoards, getProjectsByBoard } from '../../../../ngrx/reducers/board.reducer';
import { BoardInProjectAccess } from '../../../../interfaces/board';

@Component({
  selector: 'content-menu',
  templateUrl: './content-menu.component.html',
  styleUrls: ['./content-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentMenuComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() task: Task;
  @Input() isOnNoBoardPage: boolean;
  @Input() initialState: boolean;
  @Input() noOverlay: boolean;
  @Input() notHideOnClick: boolean;
  @Input() isAdditionalItemsAvailable: boolean;
  @Input() isBottomElementsAlwaysVisible: boolean;
  @Input() membersPopupOutsideOpener$: Observable<boolean>;
  @Input() datePopupOutsideOpener$: Observable<boolean>;
  @Input() editPermissions: boolean;
  @ViewChild('popup') popup: AContextControllComponent;
  @Output() closeMenuItem = new EventEmitter();
  @Output() archiveTask = new EventEmitter();

  public isDeleteTaskVisible = false;
  public isContentMenuVisible = false;
  public isCopyLinkVisible = false;
  public isMoveTaskMenuVisible = false;
  public isPushTaskMenuVisible = false;
  public isCopyTaskMenuVisible = false;
  public isUserSubscribed$: Observable<boolean>;
  public isMembersPopupVisible = false;
  public isProjectsPopupVisible = false;
  public isLabelsPopupVisible = false;
  public taskUsers$: Observable<User[]>;
  public boardProjects$: Observable<Project[]>;
  public possibleUsers$: Observable<User[]>;
  public projectOnlyUsers$: Observable<User[]>;
  public isMarkAsDoneVisible$: Observable<boolean>;
  public isMarkAsDoneErr = false;
  public showDueDatePopUp = false;
  public showRepeatPopUp = false;
  public directUrl: string;
  public isPublicBoard$: Observable<boolean>;

  public appUrls = AppUrls;

  subs: Subscription[] = [];

  constructor(
    private _store: Store<AppState>,
    private _userAuthService: AuthService,
    private _router: Router,
    private _routerNav: RouterNavigateService,
    private _paywall: PaywallService
  ) {}

  ngOnInit() {
    this.isContentMenuVisible = this.initialState;
    this.isMarkAsDoneVisible$ = this._store
      .select(fromColumns.getById(this.task.column))
      .pipe(filter(isPresent), map((column: Column) => !this.task.archived && column.type !== 'done'));

    this.isUserSubscribed$ = combineLatest(
      this._store.select(fromTask.getTaskSubscribersIds(this.task.id)),
      this._userAuthService.activeUserId$
    ).pipe(map(([getSubscribersIds, userId]: [number[], number]) => getSubscribersIds.includes(userId)));

    if (this.isAdditionalItemsAvailable) {
      this.taskUsers$ = this._store.select(getTaskUsers(this.task.id)).pipe(map(naturalSort('fullname')));
      this.possibleUsers$ = this._store.select(getTaskPossibleUsers(this.task.id));
      this.projectOnlyUsers$ = this._store.select(getTaskProjectOnlyUsers(this.task.id));
      this.isPublicBoard$ = this._store
        .select(fromBoards.get(this.task.board))
        .pipe(map(board => board && board.access === BoardInProjectAccess.public));
    }
    if (this.membersPopupOutsideOpener$) {
      this.subs.push(
        this.membersPopupOutsideOpener$.subscribe(value => {
          this.isMembersPopupVisible = this.isContentMenuVisible && value;
        })
      );
    }
    if (this.datePopupOutsideOpener$) {
      this.subs.push(
        this.datePopupOutsideOpener$.subscribe(value => {
          this.showDueDatePopUp = this.isContentMenuVisible && value;
        })
      );
    }

    this.boardProjects$ = <Observable<Project[]>>this._store.pipe(getProjectsByBoard(this.task.board));
  }

  ngAfterViewInit() {
    if (this.isBottomElementsAlwaysVisible) {
      const el = this.popup.getHostElement().children[0];
      const delta = el.getBoundingClientRect().bottom - window.innerHeight;
      if (delta > 30) {
        el.style.position = 'relative';
        el.style.top = '-' + (delta - 30) + 'px';
      }
    }
  }

  onToggleDeleteTask() {
    this.isDeleteTaskVisible = !this.isDeleteTaskVisible;
    if (!this.isDeleteTaskVisible) {
      this.closeMenuItem.emit();
    }
  }

  onSwitchCopyLink() {
    this.directUrl = AppUrls.getDirectTaskUrl(this._router, this.task.id, this.task.board);
    this.isCopyLinkVisible = !this.isCopyLinkVisible;
    this.closeMenuItem.emit();
  }

  onSwitchMoveTaskMenu() {
    this.isMoveTaskMenuVisible = !this.isMoveTaskMenuVisible;
    if (!this.isMoveTaskMenuVisible) {
      this.closeMenuItem.emit();
    }
  }

  onSwitchCopyTaskMenu() {
    if (this._paywall.isFeatureEnabled(Features.CanAddTask)) {
      this.isCopyTaskMenuVisible = !this.isCopyTaskMenuVisible;
      if (!this.isCopyTaskMenuVisible) {
        this.closeMenuItem.emit();
      }
    } else {
      this._paywall.showPayWall(Features.CanAddTask);
    }
  }

  onSwitchPushTaskMenu() {
    if (this._paywall.isFeatureEnabled(Features.CanAddTask)) {
      this.isPushTaskMenuVisible = !this.isPushTaskMenuVisible;
      if (!this.isPushTaskMenuVisible) {
        this.closeMenuItem.emit();
      }
    } else {
      this._paywall.showPayWall(Features.CanAddTask);
    }
  }

  onSwitchContentMenu() {
    this.isContentMenuVisible = !this.isContentMenuVisible;
  }

  onMenuClick() {
    if (!this.notHideOnClick) {
      this.onSwitchContentMenu();
    }
  }

  onArchiveTask() {
    const data = { id: this.task.id, archived: !this.task.archived };
    this._store.dispatch(new TaskEditAction(data));
    this.closeMenuItem.emit();
    this.archiveTask.emit();
  }

  onSubscribeUser() {
    this.isUserSubscribed$.pipe(take(1)).subscribe(isSubs => {
      if (isSubs) {
        this.unsubscribeOnTask();
      } else {
        this.subscribeOnTask();
      }
      this.closeMenuItem.emit();
    });
  }

  subscribeOnTask() {
    const taskData = {
      id: this.task.id,
      subscribers: { add: [this._userAuthService.activeUserId] }
    };
    this._store.dispatch(new TaskAssignSubscriberAction(taskData));
  }

  unsubscribeOnTask() {
    const taskData = {
      id: this.task.id,
      subscribers: { remove: [this._userAuthService.activeUserId] }
    };
    this._store.dispatch(new TaskAssignSubscriberAction(taskData));
  }

  onDeleteTask() {
    this._store.dispatch(new TaskDeleteAction(this.task));
    this.isDeleteTaskVisible = false;
    this.closeMenuItem.emit();
  }

  onSwitchMembers() {
    this.isMembersPopupVisible = !this.isMembersPopupVisible;
    if (!this.isMembersPopupVisible) {
      this.closeMenuItem.emit();
    }
  }

  onSwitchProjects() {
    this.isProjectsPopupVisible = !this.isProjectsPopupVisible;
    if (!this.isProjectsPopupVisible) {
      this.closeMenuItem.emit();
    }
  }

  onUpdateUsersList(userListUpdate) {
    this._store.dispatch(new TaskAssignUsersAction({ id: this.task.id, users: userListUpdate }));
  }

  onAddUserToBoard(user: User) {
    this._store.dispatch(new BoardAssignUsersAction({ id: this.task.board, users: { add: [user.id] } }));
  }

  onSwitchLabels() {
    this.isLabelsPopupVisible = !this.isLabelsPopupVisible;
    if (!this.isLabelsPopupVisible) {
      this.closeMenuItem.emit();
    }
  }

  onMarkAsDone() {
    this._store
      .select(getGlobalDoneColumnsByBoardId(this.task.board))
      .pipe(take(1))
      .subscribe((columns: Column[]) => {
        const targetCol = <Column>columns.reduce(
          (acc, item) => {
            if (!item.archived && item.position > acc.position) {
              return item;
            } else {
              return acc;
            }
          },
          { position: -1 }
        );
        if (targetCol && targetCol.id && !targetCol.archived) {
          this._store.dispatch(new TaskEditAction({ id: this.task.id, column: targetCol.id, insertBeforeTask: 0 }));
          this._store.dispatch(new OpenedTaskDataChangedAction({ highlightAfterClosing: false }));
          this._store.dispatch(new GuiStateQuickTaskEditHide(this.task.id));
        } else {
          this.onToggleMarkAsDoneErr();
        }
        this.closeMenuItem.emit();
      });
  }

  onToggleMarkAsDoneErr() {
    this.isMarkAsDoneErr = !this.isMarkAsDoneErr;
    if (!this.isMarkAsDoneErr) {
      this.closeMenuItem.emit();
    }
  }

  onSwitchDueDate() {
    this.showDueDatePopUp = !this.showDueDatePopUp;
    if (!this.showDueDatePopUp) {
      this.closeMenuItem.emit();
    }
  }

  onSwitchRepeat() {
    this.showRepeatPopUp = !this.showRepeatPopUp;
    if (!this.showRepeatPopUp) {
      this.closeMenuItem.emit();
    }
  }

  moveTask(insertPosition) {
    const payload = {
      id: this.task.id,
      column: this.task.column,
      swimlane: this.task.swimlane
    };
    payload[insertPosition] = 0;
    this._store.dispatch(new TaskEditAction(payload));
    this._store.dispatch(new GuiStateQuickTaskEditHide(this.task.id));
    this.onSwitchContentMenu();
  }

  onViewOnBoard(event) {
    event.preventDefault();
    this._store.dispatch(new GuiStateSetBoardViewMode({ id: this.task.board, mode: BoardViewMode.board }));
    this._routerNav.navigateToBoard(this.task.board);
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
