import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { isEmpty } from '../../../../helpers';

export const TASK_COMMENT_INPUT = 'taskCommentInput';
export const TASK_REPLAY_COMMENT_INPUT = 'taskReplayCommentInput';
export const TASK_TITLE_INPUT = 'taskTitleInput';
export const TASK_DESCRIPTION_INPUT = 'taskDescriptionInput';
export const CREATE_NEW_TASK_INPUT = 'createNewTaskInput';

export const KEY_LOCAL_STORE_UNSAVED_INPUT_FIELDS = 'unsavedTaskInputFields-';
export const KEY_LOCAL_STORE_UNSAVED_REPLAYS_COMMENTS = 'unsavedTaskReplaysComments-';
export const KEY_LOCAL_STORE_UNSAVED_EDIT_COMMENTS = 'unsavedTaskEditComments-';
const KEY_LOCAL_STORE_UNSAVED_CREATE_NEW_TASK = 'unsavedCreateNewTask';

export const UNSAVED_NOTE_TEXT = 'You have unsaved edits in this field.';

@Injectable()
export class TaskUnsavedDataService {
  readonly taskUnsavedInputFields = {
    [TASK_COMMENT_INPUT]: new BehaviorSubject(''),
    [TASK_REPLAY_COMMENT_INPUT]: new BehaviorSubject(''),
    [TASK_TITLE_INPUT]: new BehaviorSubject(''),
    [TASK_DESCRIPTION_INPUT]: new BehaviorSubject('')
  };

  public taskUnsavedReplayComments: BehaviorSubject<{ [key: number]: string }> = new BehaviorSubject({});
  public taskUnsavedEditComments: BehaviorSubject<{ [key: number]: string }> = new BehaviorSubject({});
  public unsavedCreateNewTasks: BehaviorSubject<{ [key: string]: string }> = new BehaviorSubject({});

  initUnsavedData(taskId: number) {
    this.initUnsavedInputFields(taskId);
    this.initUnsavedReplayComments(taskId);
    this.initUnsavedEditComments(taskId);
  }

  initUnsavedReplayComments(taskId: number) {
    const inputFieldFromStore = this.getFromLocalStore(KEY_LOCAL_STORE_UNSAVED_REPLAYS_COMMENTS, taskId);
    if (inputFieldFromStore && isEmpty(this.taskUnsavedReplayComments.getValue())) {
      this.taskUnsavedReplayComments.next(inputFieldFromStore);
    }
  }

  initUnsavedEditComments(taskId: number) {
    const inputFieldFromStore = this.getFromLocalStore(KEY_LOCAL_STORE_UNSAVED_EDIT_COMMENTS, taskId);
    if (inputFieldFromStore && isEmpty(this.taskUnsavedEditComments.getValue())) {
      this.taskUnsavedEditComments.next(inputFieldFromStore);
    }
  }

  initUnsavedInputFields(taskId) {
    const inputFieldFromStore = this.getFromLocalStore(KEY_LOCAL_STORE_UNSAVED_INPUT_FIELDS, taskId);
    if (inputFieldFromStore) {
      Object.keys(this.taskUnsavedInputFields).forEach(key =>
        this.taskUnsavedInputFields[key].next(inputFieldFromStore[key])
      );
    }
  }

  initUnsavedCreateNewTasks() {
    const inputFieldFromStore = this.getUnsavedCreateNewTasksFromLocalStorage();
    if (inputFieldFromStore && isEmpty(this.unsavedCreateNewTasks.getValue())) {
      this.unsavedCreateNewTasks.next(inputFieldFromStore);
    }
  }

  setUnsavedReplayComment(replayId: number, value: string) {
    const unsavedReplayComments = this.taskUnsavedReplayComments.getValue();
    unsavedReplayComments[replayId] = value;
    this.taskUnsavedReplayComments.next(unsavedReplayComments);
  }

  setUnsavedEditComment(taskId: number, value: string) {
    const unsavedEditComments = this.taskUnsavedEditComments.getValue();
    unsavedEditComments[taskId] = value;
    this.taskUnsavedEditComments.next(unsavedEditComments);
  }

  setUnsavedCreateNewTask(columnId: number, swimlaneId: number, value: string) {
    const unsavedCreateNewTaskList = this.unsavedCreateNewTasks.getValue();
    if (value) {
      unsavedCreateNewTaskList[columnId + '-' + swimlaneId] = value;
    } else {
      delete unsavedCreateNewTaskList[columnId + '-' + swimlaneId];
    }

    this.unsavedCreateNewTasks.next(unsavedCreateNewTaskList);
  }

  clearAllUnsavedInputs() {
    Object.keys(this.taskUnsavedInputFields).forEach(key => this.taskUnsavedInputFields[key].next(''));
  }

  clearInput(inputName: string) {
    this.taskUnsavedInputFields[inputName].next('');
  }

  clearUnsavedReplayComment(replayId) {
    const unsavedReplayComments = this.taskUnsavedReplayComments.getValue();
    delete unsavedReplayComments[replayId];
    console.log('unsavedReplayComments rest', unsavedReplayComments);
    this.taskUnsavedReplayComments.next(unsavedReplayComments);
  }

  clearAllUnsavedReplayComments() {
    this.taskUnsavedReplayComments.next({});
  }

  clearUnsavedEditComment(taskId) {
    const unsavedEditComments = this.taskUnsavedEditComments.getValue();
    delete unsavedEditComments[taskId];
    this.taskUnsavedEditComments.next(unsavedEditComments);
  }

  clearAllUnsavedEditComments() {
    this.taskUnsavedReplayComments.next({});
  }

  save(taskId: number) {
    const filterUnsavedInputFields = {};
    Object.keys(this.taskUnsavedInputFields).forEach(key => {
      if (this.taskUnsavedInputFields[key].getValue()) {
        filterUnsavedInputFields[key] = this.taskUnsavedInputFields[key].getValue();
      }
    });

    if (!isEmpty(filterUnsavedInputFields)) {
      window.localStorage.setItem(
        KEY_LOCAL_STORE_UNSAVED_INPUT_FIELDS + taskId,
        JSON.stringify(filterUnsavedInputFields)
      );
    } else {
      window.localStorage.removeItem(KEY_LOCAL_STORE_UNSAVED_INPUT_FIELDS + taskId);
    }

    if (!isEmpty(this.taskUnsavedReplayComments.getValue())) {
      window.localStorage.setItem(
        KEY_LOCAL_STORE_UNSAVED_REPLAYS_COMMENTS + taskId,
        JSON.stringify(this.taskUnsavedReplayComments.getValue())
      );
    } else {
      window.localStorage.removeItem(KEY_LOCAL_STORE_UNSAVED_REPLAYS_COMMENTS + taskId);
    }

    if (!isEmpty(this.taskUnsavedEditComments.getValue())) {
      window.localStorage.setItem(
        KEY_LOCAL_STORE_UNSAVED_EDIT_COMMENTS + taskId,
        JSON.stringify(this.taskUnsavedEditComments.getValue())
      );
    } else {
      window.localStorage.removeItem(KEY_LOCAL_STORE_UNSAVED_EDIT_COMMENTS + taskId);
    }

    this.clearAllUnsavedInputs();
    this.clearAllUnsavedReplayComments();
    this.clearAllUnsavedEditComments();
  }

  saveByKey(taskId: number, key: string, replyId?) {
    switch (key) {
      case KEY_LOCAL_STORE_UNSAVED_REPLAYS_COMMENTS: {
        console.log('saveByKey', this.taskUnsavedReplayComments.getValue());
        const localStorageValue = this.getFromLocalStore(key, taskId);
        const hasOtherValues =
          localStorageValue && Object.keys(localStorageValue).filter(key => +key !== +replyId).length;
        if (!isEmpty(this.taskUnsavedReplayComments.getValue())) {
          let valueToSave = { ...this.taskUnsavedReplayComments.getValue() };
          if (localStorageValue) {
            valueToSave = { ...localStorageValue, ...valueToSave };
          }
          window.localStorage.setItem(KEY_LOCAL_STORE_UNSAVED_REPLAYS_COMMENTS + taskId, JSON.stringify(valueToSave));
        } else if (isEmpty(this.taskUnsavedReplayComments.getValue()) && hasOtherValues) {
          const valueToSave = { ...localStorageValue };
          delete valueToSave[replyId];
          window.localStorage.setItem(KEY_LOCAL_STORE_UNSAVED_REPLAYS_COMMENTS + taskId, JSON.stringify(valueToSave));
        } else {
          window.localStorage.removeItem(KEY_LOCAL_STORE_UNSAVED_REPLAYS_COMMENTS + taskId);
        }
        this.clearAllUnsavedReplayComments();
        break;
      }
      case KEY_LOCAL_STORE_UNSAVED_EDIT_COMMENTS: {
        if (!isEmpty(this.taskUnsavedEditComments.getValue())) {
          window.localStorage.setItem(
            KEY_LOCAL_STORE_UNSAVED_EDIT_COMMENTS + taskId,
            JSON.stringify(this.taskUnsavedEditComments.getValue())
          );
        } else {
          window.localStorage.removeItem(KEY_LOCAL_STORE_UNSAVED_EDIT_COMMENTS + taskId);
        }
        this.clearAllUnsavedEditComments();
        break;
      }
      case KEY_LOCAL_STORE_UNSAVED_INPUT_FIELDS: {
        const filterUnsavedInputFields = {};
        Object.keys(this.taskUnsavedInputFields).forEach(key => {
          if (this.taskUnsavedInputFields[key].getValue()) {
            filterUnsavedInputFields[key] = this.taskUnsavedInputFields[key].getValue();
          }
        });

        if (!isEmpty(filterUnsavedInputFields)) {
          window.localStorage.setItem(
            KEY_LOCAL_STORE_UNSAVED_INPUT_FIELDS + taskId,
            JSON.stringify(filterUnsavedInputFields)
          );
        } else {
          window.localStorage.removeItem(KEY_LOCAL_STORE_UNSAVED_INPUT_FIELDS + taskId);
        }
        this.clearAllUnsavedInputs();
      }
    }
  }

  saveUnsavedNewTask() {
    const data = this.unsavedCreateNewTasks.getValue();
    if (!isEmpty(data)) {
      window.localStorage.setItem(KEY_LOCAL_STORE_UNSAVED_CREATE_NEW_TASK, JSON.stringify(data));
    } else {
      window.localStorage.removeItem(KEY_LOCAL_STORE_UNSAVED_CREATE_NEW_TASK);
    }
  }

  getFromLocalStore(key, taskId) {
    const localStorage = window.localStorage.getItem(key + taskId);
    return localStorage ? JSON.parse(localStorage) : '';
  }

  getUnsavedCreateNewTasksFromLocalStorage() {
    const localStorage = window.localStorage.getItem(KEY_LOCAL_STORE_UNSAVED_CREATE_NEW_TASK);
    return localStorage ? JSON.parse(localStorage) : '';
  }
}
