// Regular Expressions for parsing tags and attributes
const SURROGATE_PAIR_REGEXP = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
// ! to ~ is the ASCII range.
const NON_ALPHANUMERIC_REGEXP = /([^#-~ |!])/g;

const trimDomNodesText = (targetNode: HTMLElement, trimLimit: number) => {
  let currentLen = 0;
  const children = Array.prototype.slice.call(targetNode.childNodes);

  children.forEach((node: HTMLElement) => {
    const nodeText = node.innerText ? node.innerText : node.textContent;
    if (currentLen + nodeText.length > trimLimit) {
      const overLimit = currentLen + nodeText.length - trimLimit;
      trimDomNodesText(node, Math.max(0, nodeText.length - overLimit));
    }
    currentLen += node.innerText ? node.innerText.length : node.textContent.length;
  });
  // if is it text node
  if (targetNode.nodeType === 3 && targetNode.textContent.length > trimLimit) {
    targetNode.textContent = targetNode.textContent.substr(0, trimLimit);
  }
  return targetNode;
};

/**
 * Escapes all potentially dangerous characters, so that the
 * resulting string can be safely inserted into attribute or
 * element text.
 * @param {?} htmlString
 * @return {?}
 */
export let encodeEntities = (htmlString: string): string => {
  return htmlString
    .replace(/&/g, '&amp;')
    .replace(SURROGATE_PAIR_REGEXP, function(match) {
      const /** @type {?} */ hi = match.charCodeAt(0);
      const /** @type {?} */ low = match.charCodeAt(1);
      return '&#' + ((hi - 0xd800) * 0x400 + (low - 0xdc00) + 0x10000) + ';';
    })
    .replace(NON_ALPHANUMERIC_REGEXP, function(match) {
      return '&#' + match.charCodeAt(0) + ';';
    })
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
};

export let plainTextToHtml = (inputText: string, trimLimit: number = 0) => {
  const temporaryTextArea = <HTMLTextAreaElement>document.createElement('textarea');
  temporaryTextArea.innerHTML =
    trimLimit && inputText.length > trimLimit ? inputText.substr(0, trimLimit) + '...' : inputText;
  return temporaryTextArea.value;
};

export const getFormattingHtmlContent = htmlContent => {
  const html = document.createElement('div');
  html.innerHTML = htmlContent;

  return html.textContent;
};

export function copyToClipboard(text: any) {
  const textArea = document.createElement('textarea');

  //
  // *** This styling is an extra step which is likely not required. ***
  //
  // Why is it here? To ensure:
  // 1. the element is able to have focus and selection.
  // 2. if element was to flash render it has minimal visual impact.
  // 3. less flakyness with selection and copying which **might** occur if
  //    the textarea element is not visible.
  //
  // The likelihood is the element won't even render, not even a flash,
  // so some of these are just precautions. However in IE the element
  // is visible whilst the popup box asking the user for permission for
  // the web page to copy to the clipboard.
  //

  // // Place in top-left corner of screen regardless of scroll position.
  // textArea.style.position = 'fixed';
  // textArea.style.top = '0';
  // textArea.style.left = '0';

  // // Ensure it has a small width and height. Setting to 1px / 1em
  // // doesn't work as this gives a negative w/h on some browsers.
  // textArea.style.width = '2em';
  // textArea.style.height = '2em';

  // // We don't need padding, reducing the size if it does flash render.
  // textArea.style.padding = '0';

  // // Clean up any borders.
  // textArea.style.border = 'none';
  // textArea.style.outline = 'none';
  // textArea.style.boxShadow = 'none';

  // // Avoid flash of white box if rendered for any reason.
  // textArea.style.background = 'transparent';

  textArea.value = text;

  document.body.appendChild(textArea);

  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log('Copying text command was ' + msg);
  } catch (err) {
    console.log('Oops, unable to copy');
  }

  document.body.removeChild(textArea);
}

function isInPage(node) {
  return node === document.body ? false : document.body.contains(node);
}

export function onInsertToDom(node, cb: Function) {
  let limit = 0;
  const nextTick = () =>
    limit > 10000
      ? () => console.log("element wasn't inserted after delay")
      : requestAnimationFrame(() => {
          limit++;
          if (isInPage(node)) {
            cb();
          } else {
            nextTick();
          }
        });
  nextTick();
}
