import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Avatar } from '../../interfaces/avatar';
import { User } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class UserPreviewService {
  public user$;
  public top$;
  public left$;
  constructor() {
    this.user$ = new BehaviorSubject(null);
    this.top$ = new BehaviorSubject(0);
    this.left$ = new BehaviorSubject(0);
  }

  public hidePreview(event) {
    if (
      event &&
      event.toElement &&
      (event.toElement.tagName === 'USER-PREVIEW' || event.toElement.closest('user-preview'))
    ) {
      return;
    }
    this.user$.next(null);
  }

  public openPreview(user: User | Avatar, event) {
    const rect = event.currentTarget.getBoundingClientRect();
    this.top$.next(rect.top);
    this.left$.next(Math.min(rect.left, document.documentElement.clientWidth - 200));
    this.user$.next(user);
  }
}
