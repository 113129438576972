import { distinctUntilChanged, map, pluck, switchMap } from 'rxjs/operators';
import { ColumnActionTypes } from '../actions/column.actions';
import { AppState, ESInterface } from '../state';
import { Column } from '../../interfaces';
import { Observable } from 'rxjs';
import { COLUMN_PL, columnTypes } from '../../constants';
import { compose } from '@ngrx/store';
import { getSelectedBoardId } from './board.reducer';
import { Action } from '../actions/unsafe-action';
import { compareArrays } from '../../../helpers';
import { addEntityState, editEntityState } from '../functions/reducer';
import { getAllEntities, getEntitiesByFields, getEntityByIdSelector, getEntitySelector } from '../functions/selectors';
import { share } from '../functions/util';
import { isCompositeColumn, isInProgressColumn, isParentColumn, isSimpleColumn } from '../../../helpers/column';
import { createSelector, defaultMemoize } from 'reselect';

export type columnFilter = (column: Column) => boolean;

const initialState: ESInterface<Column> = {
  ids: [],
  entities: {},
  selectedEntityId: null
};

export function reducer(state = initialState, action: Action): ESInterface<Column> {
  switch (action.type) {
    case ColumnActionTypes.REMOVE_FROM_STORE: {
      return {
        ids: state.ids.filter(id => !action.payload.includes(id)),
        entities: state.entities,
        selectedEntityId: state.selectedEntityId
      };
    }

    case ColumnActionTypes.EDIT_COMPLETE:
    case ColumnActionTypes.EDIT: {
      return editEntityState(state, action.payload);
    }

    case ColumnActionTypes.ADD_COMPLETE: {
      return addEntityState(state, action.payload);
    }

    default: {
      return state;
    }
  }
}

export function getColumnState(state$: Observable<AppState>): Observable<ESInterface<Column>> {
  return state$.pipe(pluck<AppState, ESInterface<Column>>(COLUMN_PL), distinctUntilChanged());
}

export function getColumnWithSubColumns(id: number) {
  return (state$: Observable<ESInterface<Column>>): Observable<Column> =>
    state$.pipe(
      map((state: ESInterface<Column>) => {
        let column = state.entities[id];
        if (column && column.subColumnsIds) {
          column = Object.assign({}, column);
          column.subColumns = { data: [], object: 'list', count: column.subColumnsIds.length };
          column.subColumnsIds.forEach(subColumnId => {
            if (state.entities[subColumnId]) {
              column.subColumns.data.push(state.entities[subColumnId]);
            }
          });
        }
        return column;
      })
    );
}

export const getWIPedColumn = (column: Column) => {
  if (!column) {
    return null;
  }
  if (isSimpleColumn(column) && isInProgressColumn(column)) {
    return column;
  }
  if (isCompositeColumn) {
    return !column.subColumns || !column.subColumns.data ? null : column.subColumns.data.find(isInProgressColumn);
  }
  return null;
};

const hasWIPLimits = (column: Column) =>
  !!(column.wippedColumn && (column.wippedColumn.minTasksCount || column.wippedColumn.maxTasksCount));

export function filterWIPedColumn(state$: Observable<Column>): Observable<Column> {
  return state$.pipe(map(getWIPedColumn));
}

export const getColumnEntities = share(compose(getAllEntities, getEntitySelector(COLUMN_PL)));
export const getColumnById = id => compose(getEntityByIdSelector<Column>(id), getEntitySelector(COLUMN_PL));
export const getColumnsByBoard = boardId => compose(filterColumnsByBoard(boardId), getEntitySelector(COLUMN_PL));

export const getColumnByIdIfWIPed = id =>
  compose(filterWIPedColumn, getColumnWithSubColumns(id), getEntitySelector(COLUMN_PL));

function filterColumnsByBoard(boardId) {
  const filter = (column: Column) => column.board === boardId && column.archived === 0;
  return getColumnsByFiltering(filter);
}

function getColumnsByFiltering(filter: columnFilter) {
  return (state$: Observable<ESInterface<Column>>): Observable<Column[]> =>
    state$.pipe(
      map(state => {
        return state.ids
          .filter(id => state.entities[id] && filter(state.entities[id]))
          .map(id => state.entities[id])
          .filter(isParentColumn)
          .map((column: Column) => {
            column = Object.assign({}, column);
            if (column.subColumnsIds) {
              column.subColumns = { data: [], object: 'list', count: column.subColumnsIds.length };
              column.subColumnsIds.forEach(subColumnId => {
                if (state.entities[subColumnId]) {
                  column.subColumns.data.push(state.entities[subColumnId]);
                }
              });
            }
            return column;
          })
          .map((column: Column) => {
            column.wippedColumn = getWIPedColumn(column);
            column.hasWipLimits = hasWIPLimits(column);
            return column;
          })
          .sort((a, b) => a.position - b.position);
      })
    );
}

/**
 * @deprecated you should use getColumnsByBoard directly whith you board id.
 * Important! this function rely on selectedEntityId which is going to be removed soon.
 * @type {Selector<AppState, V>}
 */
export const getBoardColumns = share((state$: Observable<AppState>) => {
  return state$.pipe(
    getSelectedBoardId,
    switchMap(boardId => state$.pipe(getColumnsByBoard(boardId))),
    distinctUntilChanged(compareArrays)
  );
});

export const getBoardColumnCounter = share(compose(countColumnsByType, getBoardColumns));

export function countColumnsByType(state$: Observable<Column[]>) {
  return state$.pipe(
    map(columns => {
      return columns.reduce(
        (acc, column) => {
          acc[column.type]++;
          return acc;
        },
        {
          [columnTypes.todo]: 0,
          [columnTypes.inprogress]: 0,
          [columnTypes.done]: 0
        }
      );
    })
  );
}

export const getTargetColumns = (boardId: number, sortBy?: string) =>
  compose(
    (state$: Observable<Column[]>) =>
      state$.pipe(
        map((columns: Column[]) =>
          columns.reduce((acc, column: Column) => {
            if (column.subColumnsIds.length > 0) {
              column.subColumnsIds.forEach(subColumnId => {
                const subColumn: Column = columns.find(col => col.id === subColumnId);
                acc.push({
                  id: subColumnId,
                  name: column.name + ' (' + subColumn.name + ')',
                  position: column.position,
                  parent: column.id
                });
              });
            } else if (!column.parent) {
              acc.push({ id: column.id, name: column.name, position: column.position });
            }
            return sortBy ? acc.sort((a, b) => a[sortBy] - b[sortBy]) : acc;
          }, [])
        )
      ),
    getEntitiesByFields(COLUMN_PL, { board: boardId, archived: 0 })
  );
export const getSimpleAndProgressColumns = (boardId: number) =>
  compose(
    (state$: Observable<Column[]>) =>
      state$.pipe(
        map((columns: Column[]) =>
          columns.reduce((acc, column: Column) => {
            if (column.subColumnsIds.length > 0) {
              column.subColumnsIds.forEach(subColumnId => {
                const subColumn: Column = columns.find(col => col.id === subColumnId);
                if (subColumn.type !== 'subcolumn_done') {
                  acc.push({
                    id: subColumnId,
                    name: column.name
                  });
                }
              });
            } else if (!column.parent) {
              acc.push({ id: column.id, name: column.name });
            }
            return acc;
          }, [])
        )
      ),
    getEntitiesByFields(COLUMN_PL, { board: boardId, archived: 0 })
  );

export const getColumnsState = (state: AppState) => <ESInterface<Column>>state.columns;

export const getColumnsByBoardId = (boardId: number) =>
  createSelector(getColumnsState, columns =>
    columns.ids
      .map((columnId: number) => columns.entities[columnId])
      .filter((column: Column) => column.board === boardId)
  );

export const getGlobalDoneColumnsByBoardId = (boardId: number) =>
  createSelector(getColumnsByBoardId(boardId), columns =>
    columns.filter((column: Column) => column.type === columnTypes.done)
  );

export namespace fromColumns {
  export const getState = (state: AppState) => <ESInterface<Column>>state[COLUMN_PL];
  export const getColumnFullNameById = id => (state: AppState) => {
    const column = getState(state).entities[id];
    if (!column) {
      return '';
    }
    if (column.parent) {
      const parentColumn = getState(state).entities[column.parent];
      if (parentColumn) {
        return parentColumn.name + ' (' + column.name + ')';
      }
    }
    return column.name;
  };
  export const getById = id => createSelector(getState, state => state.entities[id]);

  export const getByIds = (ids: number[]) =>
    createSelector(getState, state => ids.map(id => state.entities[id]).filter(Boolean));

  export const getListWithFullNames = defaultMemoize(
    createSelector(getState, state =>
      state.ids.filter(id => state.entities[id]).map(id => {
        const column = { ...state.entities[id] };
        if (column.parent) {
          const parentColumn = state.entities[column.parent];
          if (parentColumn) {
            column.name = parentColumn.name + ' (' + column.name + ')';
          }
        }
        return column;
      })
    )
  );
}
