import { Injectable } from '@angular/core';

@Injectable()
export class PatchQueryService {
  private initItemIds = [];

  private addedItemsIds = [];
  private removedItemIds = [];

  getItemIdsQuery(): { add: number[]; remove: number[] } {
    return { add: this.addedItemsIds, remove: this.removedItemIds };
  }

  addItemId(itemId: number) {
    if (!this.isInitItemId(itemId)) {
      this.insertItemIdTo(itemId, this.addedItemsIds);
    }
    this.dropItemIdFrom(itemId, this.removedItemIds);
  }

  removeItemId(itemId: number) {
    if (this.isInitItemId(itemId)) {
      this.insertItemIdTo(itemId, this.removedItemIds);
    }
    this.dropItemIdFrom(itemId, this.addedItemsIds);
  }

  isInitItemId(itemId: number): boolean {
    return this.initItemIds.indexOf(itemId) > -1;
  }

  insertItemIdTo(itemId: number, itemIds: number[]) {
    if (itemIds.indexOf(itemId) === -1) {
      itemIds.push(itemId);
    }
  }

  dropItemIdFrom(itemId: number, itemIds: number[]) {
    if (itemIds.indexOf(itemId) > -1) {
      itemIds.splice(itemIds.indexOf(itemId), 1);
    }
  }

  noEmpty(): boolean {
    return this.addedItemsIds.length > 0 || this.removedItemIds.length > 0;
  }

  setInitItemIds(items: any[]) {
    this.initItemIds = [];
    items.forEach(value => this.initItemIds.push(value.id));
  }

  clearQuery() {
    this.addedItemsIds = [];
    this.removedItemIds = [];
  }

  removeAllAddedItems() {
    this.addedItemsIds = [];
  }
}
