<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <general-error-message *ngIf="_formService.isError$ | async">
    <span *ngFor="let error of _formService.serverError?.errors">{{error.message}}<br/></span>
  </general-error-message>
  <div>
    <label [attr.for]="'backlog_score_y'">{{scoreYLabel$ | async}}</label>
    <span class="backlog_number">{{getVisibleScore(getSentValue(backlog_score_y.value, scoreYType) , scoreYType)}}</span>
    <input id="backlog_score_y"
           #backlog_score_y type="range"
           step="1"
           min="0"
           [max]="(maxScoreY$ | async)"
           [formControlName]="'backlogScoreY'"
           [attr.disabled]="!editPermissions || null"
           (change)="onSubmit()"
    />
  </div>

  <div>
    <label [attr.for]="'backlog_score_x'">{{scoreXLabel$ | async}}</label>
    <span class="backlog_number">{{getVisibleScore(getSentValue(backlog_score_x.value, scoreXType), scoreXType)}}</span>
    <input id="backlog_score_x"
           #backlog_score_x type="range"
           step="1"
           min="0"
           [max]="(maxScoreX$ | async)"
           [formControlName]="'backlogScoreX'"
           [attr.disabled]="!editPermissions || null"
           (change)="onSubmit()"/>
  </div>
  <div *ngIf="backlog_score_y && backlog_score_x" class="chart_link">
    <a (click)="onViewOnPriorityMatrix($event)">View on Priority Matrix</a>
  </div>
  <div class="divider"></div>
</form>
