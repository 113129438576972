<div class="filter">
  <board-view-selector [preventNav]="preventNavFromSelector" [boardId$]="boardId$"></board-view-selector>
  <group-by-selector *ngIf="(boardInfo$ | async).type == boardType.list" [boardId$]="boardId$"></group-by-selector>
  <div class="filter_name_list">
    <div class="filter_title">Quick filters:</div>
    <div *ngFor="let taskDefaultFilter of defaultFilters$ | async; trackBy: trackByName" [ngSwitch]="taskDefaultFilter.name">
      <default-members-filter *ngSwitchCase="FILTER_MEMBERS" [boardId$]="boardId$"></default-members-filter>
      <default-labels-filter *ngSwitchCase="FILTER_LABELS" [boardId$]="boardId$"></default-labels-filter>
      <default-columns-filter *ngSwitchCase="FILTER_COLUMNS" [boardId$]="boardId$"></default-columns-filter>
      <default-swimlanes-filter *ngSwitchCase="FILTER_SWIMLANES" [boardId$]="boardId$"></default-swimlanes-filter>
      <default-versions-filter *ngSwitchCase="FILTER_VERSIONS" [boardId$]="boardId$"></default-versions-filter>
      <default-projects-filter *ngSwitchCase="FILTER_PROJECTS" [boardId$]="boardId$"></default-projects-filter>
      <default-statuses-filter *ngSwitchCase="FILTER_STATUSES" [boardInfo$]="boardInfo$"></default-statuses-filter>
      <default-colors-filter *ngSwitchCase="FILTER_COLORS" [boardId$]="boardId$"></default-colors-filter>
      <default-quads-filter *ngSwitchCase="FILTER_QUADS" [boardId$]="boardId$"></default-quads-filter>
      <div class="filter_name_item"
           [attr.data-default-filter-name]="taskDefaultFilter.name"
           *ngSwitchDefault
           [ngClass]="{active: taskDefaultFilter.isActive}"
           (click)="onApplyDefaultFilter(taskDefaultFilter)"
      >
        {{getDefaultFilterLabel(taskDefaultFilter.name)}}
      </div>

    </div>
    <ng-container *ngIf="(boardInfo$ | async)?.showProps">
      <default-time-on-board-filter *ngIf="(boardInfo$ | async).showProps[0] === '1'" [boardId$]="boardId$"></default-time-on-board-filter>
      <default-time-on-column-filter *ngIf="(boardInfo$ | async).showProps[1] === '1'" [boardId$]="boardId$"></default-time-on-column-filter>
    </ng-container>
    <div class="filter_name_item"
         *ngFor="let qfilter of quickFilters$ | async" [ngClass]="{single_filter: true, active: qfilter.isActive}"
         (click)="onApplyQFilter(qfilter)">
      {{qfilter.name}}
    </div>
  </div>
</div>
