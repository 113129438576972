import { Component } from '@angular/core';

@Component({
  selector: 'a-front-error',
  templateUrl: './a-front-error.component.html',
  styleUrls: ['../40x-page.component.scss']
})
export class FrontErrorComponent {
  constructor() {}
}
