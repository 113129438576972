import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

import * as d3 from 'd3';

@Directive({
  selector: '[d3Axis]'
})
export class D3AxisDirective implements AfterViewInit {
  @Input() d3Axis;

  constructor(private _element: ElementRef) {}

  ngAfterViewInit() {
    d3.select(this._element.nativeElement).call(this.d3Axis);
  }
}
