import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Board, PartOfEntity, Project, Task, Version } from '../../../interfaces';
import { taskPopupCodes, TaskSidebarService } from '../../shared/services/task-sidebar.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { Observable } from 'rxjs';
import { ChecklistAddAction } from '../../../ngrx/actions/checklist.actions';
import { isEqualType, isHoursEstimateAvailable, isPointsEstimateAvailable } from '../../../../helpers';
import { boardType } from '../../../constants';
import { fromOpenedTask } from '../../ngrx/reducers/opened-task.reducer';
import { take } from 'rxjs/operators';
import { getSelectedBoardId } from '../../../ngrx/reducers/board.reducer';

@Component({
  selector: 'add-button-block',
  styleUrls: ['./add-button-block.component.scss'],
  templateUrl: './add-button-block.component.html'
})
export class AddButtonBlockComponent implements OnInit, OnDestroy, OnChanges {
  @Input() task: Task;
  @Input() board: Board;
  @Input() boardProjects: Project[];

  public projectVersions$: Observable<Version[]>;
  public showDueDatePopUp = false;
  public showRepeatPopUp = false;
  public showStartDatePopUp = false;
  public showEstimatePopUp = false;
  public showProjectsPopUp = false;
  public showVersionsPopUp = false;
  public showLabelsPopUp = false;
  public showChecklistPopUp = false;
  public showLogworkPopUp = false;
  public showAttachmentsPopUp = false;
  public showTaskLinkPopUp = false;
  public showDependenciesPopUp = false;
  public showMenu = false;
  public setEstimateInStoryPoints = false;

  public isNotSprint: boolean;
  public isRoadmapBoard: boolean;
  public isTaskOpenOnRoadmapThatLinked: boolean;
  public isHoursEstimateAvailableForBoard: boolean;
  public isPointsEstimateAvailableForBoard: boolean;
  public selectedBoard$;

  constructor(private _taskSidebarService: TaskSidebarService, private _store: Store<AppState>) {}

  ngOnInit() {
    this.selectedBoard$ = this._store.pipe(getSelectedBoardId);
    this.projectVersions$ = this._store.select(fromOpenedTask.getProjectVersions);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('board') && changes['board'].currentValue) {
      const boardId = changes['board'].currentValue;
      this.isRoadmapBoard = isEqualType(boardType.roadmap)(boardId);
      this.isNotSprint = !isEqualType(boardType.sprint)(boardId);
      this.isHoursEstimateAvailableForBoard = isHoursEstimateAvailable(boardId);
      this.isPointsEstimateAvailableForBoard = isPointsEstimateAvailable(boardId);
    }
    if (changes.hasOwnProperty('task') && changes['task'].currentValue) {
      this._store.pipe(getSelectedBoardId, take(1)).subscribe(id => {
        this.isTaskOpenOnRoadmapThatLinked =
          id && changes['task'].currentValue.roadmapBoards && changes['task'].currentValue.roadmapBoards.includes(id);
      });
    }
  }

  ngOnDestroy() {
    this._taskSidebarService.setState(taskPopupCodes.SHOW_NONE);
  }

  onSwitchMenu() {
    this.showMenu = !this.showMenu;
  }

  hideMenu() {
    this.showMenu = false;
  }

  onSwitchStartDate() {
    this.showStartDatePopUp = !this.showStartDatePopUp;
  }

  onSwitchDueDate() {
    this.showDueDatePopUp = !this.showDueDatePopUp;
  }

  onSwitchRepeat() {
    this.showRepeatPopUp = !this.showRepeatPopUp;
  }

  onSwitchProjects() {
    this.showProjectsPopUp = !this.showProjectsPopUp;
  }

  onSwitchVersions() {
    this.showVersionsPopUp = !this.showVersionsPopUp;
  }

  onCloseEstimatePopUp() {
    this.showEstimatePopUp = false;
  }

  onOpenEstimatePopUpInHours() {
    this.setEstimateInStoryPoints = false;
    this.showEstimatePopUp = true;
  }

  onOpenEstimatePopUpInPoints() {
    this.setEstimateInStoryPoints = true;
    this.showEstimatePopUp = true;
  }

  onSwitchAttachmentsPopUp() {
    this.showAttachmentsPopUp = !this.showAttachmentsPopUp;
  }

  onSwitchLabelsPopUp() {
    this.showLabelsPopUp = !this.showLabelsPopUp;
  }
  onSwitchChecklistMenu() {
    this.showChecklistPopUp = !this.showChecklistPopUp;
  }
  onSwitchLogwork() {
    this.showLogworkPopUp = !this.showLogworkPopUp;
  }

  onAddCheckList(checkListData: PartOfEntity) {
    this._store.dispatch(new ChecklistAddAction(checkListData));
    this.showChecklistPopUp = false;
  }

  onSwitchTaskLinkPopUp() {
    this.showTaskLinkPopUp = !this.showTaskLinkPopUp;
  }

  onSwitchDependenciesPopUp() {
    this.showDependenciesPopUp = !this.showDependenciesPopUp;
  }
}
