
import {refCount, multicast} from 'rxjs/operators';
import { Observable ,  ReplaySubject } from 'rxjs';

const labelCache: { [label: string]: boolean } = {};
export function label<T>(label: T | ''): T {
  if (labelCache[<string>label]) {
    throw new Error(`Action type '${label}' is not unqiue"`);
  }

  labelCache[<string>label] = true;

  return <T>label;
}

export type Selector<T, V> = (input: Observable<T>) => Observable<V>;

export function share<T, V>(selector: Selector<T, V>): Selector<T, V> {
  let result: Observable<V>;

  return function(input: Observable<T>) {
    if (!result) {
      result = selector(input).pipe(
        multicast(() => new ReplaySubject<V>(1)),
        refCount(),);
    }

    return result;
  };
}

export const actionWrapper = action => (state, payload) => action(state, payload);

export const runReducer = (reducer, state, action) => {
  if (reducer.hasOwnProperty(action.type)) {
    return reducer[action.type](state, action.payload);
  }
  return state;
};

export const dispatchIfTrue = (condition, action) => (condition ? action : {});
