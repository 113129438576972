
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { TasksSavedListResetAction } from '../actions/task-filters/tasks-saved-list.actions';
import { TaskQuickFilterActionTypes } from '../actions/task-filters/task-quick-filter.actions';

@Injectable()
export class TaskQuickFilterEffects {
  @Effect()
  taskQuickFilterToggle$ = this.actions$.ofType(TaskQuickFilterActionTypes.TOGGLE).pipe(map(_ => {
    return new TasksSavedListResetAction();
  }));

  constructor(private actions$: Actions) {}
}
