import { Injectable } from '@angular/core';

import { SERVICE_URL } from '../../../../helpers/get-api-url';
import { CompanyService } from '../../../shared/services/app/company.service';

@Injectable()
export class IntegrationAuthAdapterService {
  protected authUrl: string;
  protected readonly serviceUrl = SERVICE_URL;
  protected childWindow;
  protected popupWindowTitle: string;
  protected readonly listener = this.receiveMessage.bind(this);
  protected messageRegexp = /integrations:create:([a-z]+):success:(\d+$)/i;
  protected windowSize = 'width=650,height=800,left=200,top=0';

  constructor(private _companyService: CompanyService) {
    this.serviceUrl = SERVICE_URL;
  }

  public openAuthWindow(...args: any[]) {
    let queryParams = '?';
    if (args[0] && args[0].queryParams) {
      queryParams = Object.keys(args[0].queryParams).reduce(
        (acc, key) => acc + key + '=' + args[0].queryParams[key] + '&',
        '?'
      );
    }
    this.childWindow = window.open(
      this.authUrl + (queryParams === '?' ? '' : queryParams),
      this.popupWindowTitle,
      this.windowSize
    );
    window.addEventListener('message', this.listener, false);
  }

  protected receiveMessage(event: MessageEvent) {
    // Do we trust the sender of this message?  (might be
    // different from what we originally opened, for example).
    if (event.origin !== this.serviceUrl) {
      return false;
    }
    window.removeEventListener('message', this.listener);
  }

  protected getCompanyId(message): number {
    const match = message.match(this.messageRegexp);
    return match ? +match[2] : undefined;
  }

  protected closeChild() {
    if (this.childWindow) {
      this.childWindow.close();
    }
  }
}
