import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/state/app-state';
import { getFavoriteBoard } from '../../ngrx/reducers/default-collection.reducer';

@Component({
  selector: 'left-favorite-star',
  template: `<div class="star" favoriteToggler
                  [boardId]="boardId"
                  [isFavorite]="isFavorite$ | async" 
                  [class.active]="isFavorite$ | async"></div>`,
  styleUrls: ['./left-favorite-star.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeftFavoriteStarComponent implements OnInit {
  @Input() boardId: number;

  public isFavorite$: Observable<boolean>;

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this.isFavorite$ = this._store.pipe((getFavoriteBoard(this.boardId)));
  }
}
