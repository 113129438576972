<div class="task_popup_wrap" *ngIf="selectedTask$ | async">
  <div class="task_popup_content">

    <content-menu
      [editPermissions]="canEditTask$ | async"
      *ngIf="boardProjectMember$ | async"
      [task]="selectedTask$ | async"
      [isOnNoBoardPage]="isOnNoBoardPage$ | async"
    ></content-menu>

    <task-key
      [task]="selectedTask$ | async"
      [project]="taskProject$ | async"
    ></task-key>

    <task-cover *ngIf="(selectedTask$ | async)?.covers"
                [task]="(selectedTask$ | async)"
                [editPermissions]="canEditTask$ | async"
                class="task_cover_container">
    </task-cover>

    <task-title-inplace-edit
      [initialValue]="taskTitle$ | async"
      [task]="selectedTask$ | async"
      [submitOnEnter]="true"
      [editPermissions]="canEditTask$ | async"
      [isOnNoBoardPage]="isOnNoBoardPage$ | async"
      (formSubmit)="onChangeTaskTitle($event)"
    >
    </task-title-inplace-edit>

    <description-form
      [selectedTask]="selectedTask$ | async"
      [editPermissions]="canEditTask$ | async"
      (editDescription)="onUpdateTask($event)"
    ></description-form>

    <task-checklist class="checklist_container"
                    [taskId]="taskId$ | async"
                    [editPermissions]="canEditTask$ | async"
    ></task-checklist>

    <div class="attachment_section">
      <task-attachments
        [taskId]="taskId$ | async"
        [editPermissions]="boardProjectMember$ | async"
      ></task-attachments>
    </div>
    <div class="clear"></div>

    <div class="task_links_section">
      <relative-task-links
        [taskId]="taskId$ | async"
        [boardId]="(taskBoard$ | async)?.id"
        [editPermissions]="canEditTask$ | async"
      ></relative-task-links>
    </div>

    <div *ngIf="isGitHubIntegrationEnabled">
      <github-task-commit-list [taskId$]="taskId$"></github-task-commit-list>
      <github-task-pull-request-list [taskId$]="taskId$"></github-task-pull-request-list>
    </div>
    <div *ngIf="isBitBucketIntegrationEnabled">
      <bitbucket-task-commit-list [taskId$]="taskId$"></bitbucket-task-commit-list>
      <bitbucket-task-pull-request-list [taskId$]="taskId$"></bitbucket-task-pull-request-list>
    </div>
    <div *ngIf="isGitLabIntegrationEnabled">
      <gitlab-task-commit-list [taskId$]="taskId$"></gitlab-task-commit-list>
      <gitlab-task-pull-request-list [taskId$]="taskId$"></gitlab-task-pull-request-list>
    </div>

    <task-activity class="comments"
                   [task]="(selectedTask$ | async)"
                   [taskId]="taskId$ | async"
                   [boardUsers]="boardUsers$"
                   [isNotGuest]="isNotGuest$ | async"
                   [editPermissions]="canEditTask$ | async"
                   [editCommentsPermissions]="boardProjectMember$ | async"
    >
    </task-activity>
  </div>
  <div class="task_sidebar">

    <add-button-block
      *ngIf="canEditTask$ | async"
      [board]="taskBoard$ | async"
      [task]="selectedTask$ | async"
      [boardProjects]="boardProjects$ | async"
    ></add-button-block>

    <div class="sidebar_blocks_list">

      <atlaz-members
        class="lighter-avatars"
        [isPublicBoard]="isPublicBoard$ | async"
        [headerClassName]="'sidebar_block_title'"
        [selectedUsers]="taskUsers$ | async"
        [possibleUsers]="taskPossibleUsers$ | async"
        [projectOnlyUsers]="projectOnlyUsers$ | async"
        [editPermissions]="canEditTask$ | async"
        (addUserToBoard)="onAddUserToBoard($event)"
        (updateUserSelection)="onUpdateUserSelection($event)"
      ></atlaz-members>

      <sidebar-backlog
        *ngIf="!(isScoringOff$ | async)"
        [board]="taskBoard$ | async"
        [task]="selectedTask$ | async"
        [editPermissions]="canEditTask$ | async"
      ></sidebar-backlog>

      <sidebar-time-tracking
        [task]="taskForTimeTracking$ | async"
        [editPermissions]="canEditTask$ | async"
      ></sidebar-time-tracking>

      <sidebar-completeness
        *ngIf="showPercent$ | async"
        [board]="taskBoard$ | async"
        [task]="selectedTask$ | async"
        [editPermissions]="canEditTask$ | async"
      >
      </sidebar-completeness>

      <sidebar-story-points
        *ngIf="isShowStoryPointsBlock$ | async"
        [task]="selectedTask$ | async"
        [editPermissions]="canEditTask$ | async"
      ></sidebar-story-points>

      <sidebar-labels
        *ngIf="(taskLabels$ | async).length"
        [taskLabels]="taskLabels$ | async"
        [editPermissions]="canEditTask$ | async"
        [task]="selectedTask$ | async"
      ></sidebar-labels>

      <sidebar-start-date class="task_sidebar_item"
                          *ngIf="(selectedTask$ | async).startDate > 0"
                          [task]="selectedTask$ | async"
                          [editPermissions]="canEditTask$ | async"
      ></sidebar-start-date>

      <sidebar-duedate class="task_sidebar_item"
                       *ngIf="(selectedTask$ | async).dueDate > 0"
                       [task]="selectedTask$ | async"
                       [editPermissions]="canEditTask$ | async"
      ></sidebar-duedate>

      <sidebar-project
        *ngIf="taskProject$ | async"
        [taskProject]="taskProject$ | async"
        [task]="selectedTask$ | async"
        [boardProjects]="boardProjects$ | async"
        [editPermissions]="canEditTasksProjects$ | async"
      ></sidebar-project>

      <sidebar-project-status
        *ngIf="(selectedTask$ | async)?.linkToProject"
        [task$]="selectedTask$"
      ></sidebar-project-status>

      <sidebar-version
        *ngIf="version$ | async"
        [task]="selectedTask$ | async"
        [version]="version$ | async"
        [editPermissions]="canEditTask$ | async"
      ></sidebar-version>

      <sidebar-harvest *ngIf="isHarvestAvailable$ | async"
                       [task]="selectedTask$ | async"
                       [board]="taskBoard$ | async"
      >
      </sidebar-harvest>

      <sidebar-custom-fields [board]="taskBoard$" [taskId]="taskId$ | async" [canEditTask]="canEditTask$ | async"></sidebar-custom-fields>
    </div>
  </div>
</div>
