import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CompanyService } from '../../shared/services/app/company.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'icalendar-sync-popup',
  templateUrl: './icalendar-sync-popup.component.html',
  styleUrls: ['./icalendar-sync-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ICalendarSyncPopupComponent implements OnInit {
  @Input() boardId: number;
  @Output() close = new EventEmitter();
  @ViewChild('linkInput') linkInput;
  public exportICalendarUrl;
  constructor(private _companyService: CompanyService, private _toastr: ToastrService) {}

  ngOnInit() {
    this.exportICalendarUrl = this._companyService.getPortalUrl() + '/calendar/board/' + this.boardId;
  }

  onClose() {
    this.close.emit();
  }

  onCopyLink() {
    this.linkInput.nativeElement.select();
    document.execCommand('copy');
    this._toastr.success('Link Copied');
  }
}
