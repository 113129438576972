import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { BoardDetailsComponent } from '../../../board/board-details/board-details.component';
import { boardActivateStop$ } from './can-active-board.service';
import { DEFAULT_TITLE_TABS } from '../../../constants/';
import { of } from 'rxjs/observable/of';
import { tap } from 'rxjs/operators';

@Injectable()
export class CanDeactivateBoard implements CanDeactivate<BoardDetailsComponent> {
  private confirmFns = [() => of(true)];

  constructor(private _titleService: Title) {}

  canDeactivate(component: BoardDetailsComponent, route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    boardActivateStop$.next(true);
    return this.canDeactivateRaw();
  }

  canDeactivateRaw() {
    return this.confirmFns[this.confirmFns.length - 1]().pipe(
      tap(() => this._titleService.setTitle(DEFAULT_TITLE_TABS))
    );
  }

  registerConfirmFn(fn) {
    this.unRegisterConfirmFn(fn);
    this.confirmFns.push(fn);
  }

  unRegisterConfirmFn(fn) {
    this.confirmFns = this.confirmFns.filter(x => x !== fn);
  }
}
