import { filter, first } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { AuthService } from '../../shared/services/app/auth.service';
import { CompanyService } from '../../shared/services/app/company.service';
import { copyToClipboard, isPresent } from '../../../helpers';
import { RouterNavigateService } from '../../shared/services/router-navigate.service';
import { SegmentService } from '../../atlaz-bnp/services/intergations/segment/segment.service';
import { AppUrls } from '../../app-urls';
import { InviteLinkService } from '../services/invite-link.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'invite-link-form',
  templateUrl: './invite-link-form.component.html',
  styleUrls: ['./invite-link-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InviteLinkFormComponent implements OnInit {
  @Input() formPlaced = 'app';

  @Output() tokenDeleted = new EventEmitter<boolean>();

  public appUrls = AppUrls;
  public link = '';
  public isLoad$: BehaviorSubject<boolean>;
  public inviteLink$: Observable<string>;

  constructor(
    private _apiV2: AtlazApiV2Service,
    private _authService: AuthService,
    private _companyService: CompanyService,
    private _routerNavigate: RouterNavigateService,
    private _segment: SegmentService,
    private _toastr: ToastrService,
    private _inviteLinkService: InviteLinkService
  ) {}

  ngOnInit() {
    this.isLoad$ = this._inviteLinkService.isLoad$;
    this.inviteLink$ = this._inviteLinkService.getValidInviteLink();
    this.inviteLink$.pipe(filter(isPresent), first()).subscribe(link => (this.link = link));
  }

  onCopyToClipboard() {
    copyToClipboard(this.link);
    this._toastr.success('Link Copied');
    this._segment.track('InvitationLinkCopied', {
      source: this.formPlaced
    });
  }
}
