export const COMPANY = 'company';
export const PROJECT = 'project';
export const USER = 'user';
export const BOARD = 'board';
export const COLLECTION = 'collection';
export const DEFAULT_COLLECTION = 'defaultCollection';
export const COLUMN = 'column';
export const SUB_COLUMN = 'subColumn';
export const TASK = 'task';
export const SCORING_CRITERIA = 'scoringCriteria';
export const HYGGER_THEME = 'hyggerTheme';
export const RECENT_TASK = 'recentTask';
export const RECENT_TASK_PL = 'recentTasks';
export const IMPORT = 'import';
export const GROUP = 'group';
export const CLIENT = 'client';
export const TAG = 'tag';
export const VERSION = 'version';
export const FILTER = 'filter';
export const LABEL = 'label';
export const NOTIFICATION = 'notification';
export const CHECKLIST = 'checklist';
export const CHECKLISTS_ITEM = 'checklistsItem';
export const SWIMLANE = 'swimlane';
export const RECENTLY_COMPLETED_TASK = 'recentlyCompletedTasks';
export const TASKS_ATTACHMENT = 'tasksAttachment';
export const INTEGRATIONS_DATA = 'integrationsData';
export const BOARD_ACTIVITY = 'boardsActivity';
export const FEED_TASK = 'feedTask';
export const OVERVIEW_ACTIVITY = 'overviewActivity';
export const OVERVIEW_FILTER = 'overviewFilter';
export const TEMPORARY_TOKEN = 'temporaryToken';
export const CREDENTIAL = 'credentials';
export const USER_COMPANY = 'usersCompany';
export const BILLING_PLAN = 'billingPlan';
export const BILLING_SUBSCRIPTION = 'billingSubscription';
export const SLACK_BOARD = 'slackBoard';
export const SLACK_CHANNEL = 'slackChannel';
export const SLACK_COMPANY = 'slackCompany';
export const GITHUB_COMPANY = 'githubCompany';
export const GITHUB_REPOSITORY = 'githubRepository';
export const GITHUB_ASSOCIATED_REPOSITORY = 'githubAssociatedRepository';
export const GITHUB_BOARD = 'githubBoard';
export const GITHUB_COMMIT = 'githubCommit';
export const GITHUB_PULL_REQUEST = 'githubPullRequest';
export const BITBUCKET_COMPANY = 'bitbucketCompany';
export const BITBUCKET_REPOSITORY = 'bitbucketRepository';
export const BITBUCKET_BOARD = 'bitbucketBoard';
export const BITBUCKET_COMMIT = 'bitbucketCommit';
export const BITBUCKET_PULL_REQUEST = 'bitbucketPullRequest';
export const BITBUCKET_ASSOCIATED_REPOSITORY = 'bitbucketAssociatedRepository';
export const GITLAB_COMPANY = 'gitlabCompany';
export const GITLAB_REPOSITORY = 'gitlabRepository';
export const GITLAB_BOARD = 'gitlabBoard';
export const GITLAB_COMMIT = 'gitlabCommit';
export const GITLAB_PULL_REQUEST = 'gitlabPullRequest';
export const GITLAB_ASSOCIATED_REPOSITORY = 'gitlabAssociatedRepository';
export const INTERCOM_COMPANY = 'intercomCompany';
export const JIRA_COMPANY = 'jiraCompany';
export const CUSTOM_FIELD = 'customField';
export const TASKS_CUSTOM_FIELD = 'tasksCustomField';
export const COMPANY_DOMAINS = 'companyDomains';

export const TASK_ACTIVITY = 'tasksActivity';
export const EXTERNAL_USER = 'externalUser';
export const SPRINT_TASK = 'sprintsTask';
export const SPRINT_TASK_PL = 'sprintsTasks';

export const TASKS_LINK = 'tasksLink';
export const TASKS_LINK_PL = 'tasksLinks';
export const TASKS_LINKS_TYPE = 'tasksLinksType';
export const TASKS_LINKS_TYPE_PL = 'tasksLinksTypes';

export const COVER = 'cover';
export const IS_COVER = 'is_cover';
export const PRESET_PL = 'presets';
export const PHOTO = 'photo';

export const WORKLOG = 'worklog';
export const ACTIVITY = 'activity';
export const COMMENT = 'comment';

export const SUBSCRIBER = 'subscriber';

export const COMPANY_PL = 'companies';
export const COMPANY_FEATURE_PL = 'companyFeatures';
export const COMPANY_FEATURE = 'companyFeatures';
export const INITIAL_DATA = 'initialData';
export const PROJECT_PL = 'projects';
export const USER_PL = 'users';
export const BOARD_PL = 'boards';
export const COLUMN_PL = 'columns';
export const SUB_COLUMN_PL = 'subColumns';
export const TEMPORARY_TOKEN_PL = 'temporaryTokens';
export const CREDENTIAL_PL = 'credentials_pl';
export const USER_COMPANY_PL = 'usersCompanies';
export const COLLECTION_PL = 'collections';
export const DEFAULT_COLLECTION_PL = 'defaultCollections';
export const TASK_PL = 'tasks';
export const SCORING_CRITERIA_PL = 'scoringCriterias';
export const HYGGER_THEME_PL = 'hyggerThemes';
export const GROUP_PL = 'groups';
export const IMPORT_PL = 'imports';
export const QUICK_BACKUP_PL = 'quickBackups';
export const QUICK_BACKUP = 'quickBackup';
export const CLIENT_PL = 'clients';
export const TAG_PL = 'tags';
export const VERSION_PL = 'versions';
export const FILTER_PL = 'filters';
export const LABEL_PL = 'labels';
export const NOTIFICATION_PL = 'notifications';
export const CHECKLIST_PL = 'checklists';
export const CHECKLISTS_ITEM_PL = 'checklistsItems';
export const SWIMLANE_PL = 'swimlanes';
export const RECENTLY_COMPLETED_TASK_PL = 'recentlyCompletedTasks';
export const TASKS_ATTACHMENT_PL = 'tasksAttachments';
export const FEEDS_TASKS_PL = 'feedsTasks';
export const OVERVIEW_ACTIVITY_PL = 'overviewActivities';
export const OVERVIEW_FILTER_PL = 'overviewFilters';
export const BOARD_ACTIVITY_PL = 'boardsActivities';
export const TASK_ACTIVITY_PL = 'tasksActivities';
export const EXTERNAL_USER_PL = 'externalUsers';
export const BILLING_PLAN_PL = 'billingPlans';
export const BILLING_SUBSCRIPTION_PL = 'billingSubscriptions';
export const SLACK_BOARD_PL = 'slackBoards';
export const SLACK_CHANNEL_PL = 'slackChannels';
export const SLACK_COMPANY_PL = 'slackCompanies';
export const GITHUB_COMPANY_PL = 'githubCompanies';
export const GITHUB_REPOSITORY_PL = 'githubRepositories';
export const GITHUB_ASSOCIATED_REPOSITORY_PL = 'githubAssociatedRepositories';
export const GITHUB_BOARD_PL = 'githubBoards';
export const GITHUB_COMMIT_PL = 'githubCommits';
export const GITHUB_PULL_REQUEST_PL = 'githubPullRequests';
export const BITBUCKET_COMPANY_PL = 'bitbucketCompanies';
export const BITBUCKET_REPOSITORY_PL = 'bitbucketRepositories';
export const BITBUCKET_BOARD_PL = 'bitbucketBoards';
export const BITBUCKET_COMMIT_PL = 'bitbucketCommits';
export const BITBUCKET_PULL_REQUEST_PL = 'bitbucketPullRequests';
export const BITBUCKET_ASSOCIATED_REPOSITORY_PL = 'bitbucketAssociatedRepositories';
export const GITLAB_COMPANY_PL = 'gitlabCompanies';
export const GITLAB_REPOSITORY_PL = 'gitlabRepositories';
export const GITLAB_BOARD_PL = 'gitlabBoards';
export const GITLAB_COMMIT_PL = 'gitlabCommits';
export const GITLAB_PULL_REQUEST_PL = 'gitlabPullRequests';
export const GITLAB_ASSOCIATED_REPOSITORY_PL = 'gitlabAssociatedRepositories';
export const INTERCOM_COMPANY_PL = 'intercomCompanies';
export const JIRA_COMPANY_PL = 'jiraCompanies';
export const HARVEST_BOARD = 'harvestBoard';
export const HARVEST_BOARD_PL = 'harvestBoards';
export const BOARDS_TEMPLATE = 'boardsTemplate';
export const BOARDS_TEMPLATE_PL = 'boardsTemplates';
export const WORKLOG_PL = 'worklogs';
export const SUBSCRIBER_PL = 'subscribers';
export const CUSTOM_FIELD_PL = 'customFields';
export const TASKS_CUSTOM_FIELD_PL = 'tasksCustomFields';
export const COMPANY_DOMAINS_PL = 'companyDomains';

export const COMMENT_PL = 'comments';

export const IDS = 'Ids';
export const ENTITY = 'entity';
export const ENTITY_PL = 'entities';
