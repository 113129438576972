<ng-container>
  <div class="default_time_on_board_filter"
       (click)="onShowTimeOnBoardPopup()"
       [ngClass]="{'active': showTimeOnBoardPopUp}"
  >
    Time on board
  </div>

  <div *ngIf="(storeTimeOnBoardFilterValue$ | async).moreThan || (storeTimeOnBoardFilterValue$ | async).lessThan" class="time_on_board_filter_tag"
       (click)="onResetFilter()">
    <p class="time_on_board_title" *ngIf="(storeTimeOnBoardFilterValue$ | async).moreThan && (storeTimeOnBoardFilterValue$ | async).lessThan">
      between {{(storeTimeOnBoardFilterValue$ | async).moreThan}} and {{(storeTimeOnBoardFilterValue$ | async).lessThan}}
    </p>
    <p class="time_on_board_title" *ngIf="(storeTimeOnBoardFilterValue$ | async).moreThan && !(storeTimeOnBoardFilterValue$ | async).lessThan">
      {{(storeTimeOnBoardFilterValue$ | async).moreThan}} or more
    </p>
    <p class="time_on_board_title" *ngIf="!(storeTimeOnBoardFilterValue$ | async).moreThan && (storeTimeOnBoardFilterValue$ | async).lessThan">
      {{(storeTimeOnBoardFilterValue$ | async).lessThan}} or less
    </p>
    <div class="clear_block"></div>
  </div>

  <div a-context-popup *ngIf="showTimeOnBoardPopUp"
       [title]="'Time on board'"
       class="default_time_on_board_popup"
       (close)="onHideTimeOnBoardPopup()"
  >
    <time-on-board-form (cancel)="onHideTimeOnBoardPopup()"></time-on-board-form>
  </div>
</ng-container>
