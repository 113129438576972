<form [formGroup]="form"
      (ngSubmit)="onSubmit()"
>
  <form-errors></form-errors>
  <div class="form_group">
    <label for="repeat-type">Repeat</label>
    <select id="repeat-type" formControlName="recurrenceType">
      <option [value]="repeatTypes.never">Never</option>
      <option [value]="repeatTypes.daily">Daily</option>
      <option [value]="repeatTypes.periodically">Periodically</option>
      <option [value]="repeatTypes.weekly">Weekly</option>
      <option [value]="repeatTypes.monthly">Monthly</option>
      <option [value]="repeatTypes.yearly">Yearly</option>
    </select>
  </div>

  <div class="form_group" [hidden]="form.get('recurrenceType').value != repeatTypes.periodically">
    <label for="days-after-completion">Interval - days after completion</label>
    <select id="days-after-completion" formControlName="daysAfterCompletion">
      <option *ngFor="let item of daysAfterCompletionRange()" [value]="item">{{item}}</option>
    </select>
  </div>

  <div class="form_group" [hidden]="form.get('recurrenceType').value != repeatTypes.weekly">
    <label for="weeks-frequency">Interval - week on</label>
    <select id="weeks-frequency" formControlName="frequency">
      <option *ngFor="let item of weeksFrequencyRange()" [value]="item">{{item}}</option>
    </select>

    <div class="recurrence-task-weekly-days">
      <div *ngFor="let day of daysOfWeek" (click)="onClickDayOfWeek(day)" class="line">
        <span class="checkbox" [class.checked]="day.checked"></span>
        <span class="title">{{day.label}}</span>
      </div>
    </div>
  </div>

  <div class="form_group" [hidden]="form.get('recurrenceType').value != repeatTypes.monthly">
    <label for="months-frequency">Interval - month on</label>
    <select id="months-frequency" formControlName="frequency">
      <option *ngFor="let month of monthsFrequencyRange()" [value]="month">{{month}}</option>
    </select>
    <label for="month-day">On - day of the month</label>
    <select id="month-day" formControlName="date">
      <option *ngFor="let day of dayOfTheMonthRange()" [value]="day">{{day}}</option>
    </select>
  </div>

  <div class="form_group" [hidden]="form.get('recurrenceType').value != repeatTypes.yearly">
    <label for="yearly-month">Month</label>
    <select id="yearly-month" formControlName="month">
      <option *ngFor="let month of monthsRange()" [value]="month.month">{{month.label}}</option>
    </select>
    <label for="yearly-day">On - day of the month</label>
    <select id="yearly-day" formControlName="date">
      <option *ngFor="let day of dayOfTheMonthRange()" [value]="day">{{day}}</option>
    </select>
  </div>
  <br>
  <button a-button class="btn_primary button" type="submit"
          (click)="onSubmit()"
          [pending]="(_formService.isPending$ | async)">Save</button>
  <button a-button class="btn_text button" type="button" (click)="onCancel()">Cancel</button>
</form>
