import { createSelector } from 'reselect';

import { Entities, getEmptyESState } from '../../../../../ngrx/state/app-state';

import * as slackBoard from './slack-board.actions';
import * as fromUsers from '../../../../../ngrx/reducers/user.reducer';
import * as fromSlackChannels from '../slack-channel/slack-channel.reducer';
import * as slackAlert from '../slack-alert.action';
import { ESSlackBoards, SlackBoard } from '../../models/slack-boards';
import { SLACK_BOARD_PL } from '../../../../../constants/entity';
import { createCommonSelectors } from '../../../../../util/store/index';
import { compose, getProperty } from '../../../../../../helpers/index';
import { deleteEntityState, editEntityState } from '../../../../../ngrx/functions/reducer';
import { createPropertySelector, Lens } from '../../../../../util/store/property-selector';
import { SlackChannel } from '../../models/slack-channel';

const initialState = {
  ...getEmptyESState<SlackBoard>()
};

export function reducer(state = initialState, action: slackBoard.Actions | slackAlert.Actions): ESSlackBoards {
  switch (action.type) {
    case slackBoard.REMOVE_COMPLETED: {
      return deleteEntityState(state, action.payload.id);
    }
    case slackBoard.EDIT: {
      return editEntityState(state, action.payload);
    }

    case slackAlert.CLEAR: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const { getState, getEntities, getIds, getAll } = createCommonSelectors<SlackBoard>(SLACK_BOARD_PL);

export const isEmpty = createSelector(getIds, ids => ids.length === 0);

export const getBoardSlackChannelIds = createSelector(getAll, mapToChannelId);

export namespace ByIdFactory {
  export const getBoard = createSelector(getEntities, entities => id => getProperty(entities, id));

  export const getBoardUserId = createPropertySelector(getBoard, 'creator');

  export const getCreator = createSelector(getBoardUserId, fromUsers.userEntities, getEntityByIdWrapper);

  export const getBoardChannelId = createPropertySelector(getBoard, 'slackChannelId');

  export const getBoardChannel = createSelector(getBoardChannelId, fromSlackChannels.getEntities, getEntityByIdWrapper);
}

function getEntityByIdWrapper<T extends any, E extends {}, P>(getByIdFn: Lens<T, P>, entities: Entities<E>) {
  return compose(getEntityById(entities), getByIdFn);
}

function getEntityById<T extends {}>(entities: Entities<T>) {
  return id => getProperty(entities, id);
}

function mapToChannelId<T extends { slackChannelId: string }>(source: T[]) {
  return source.map(item => item.slackChannelId);
}

function filterByChannelId(channel: SlackChannel) {
  return;
}
