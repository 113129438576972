import { NgModule } from '@angular/core';
import { SmartTextComponent } from './smart-text.component';
import { SmartMentionComponent } from './smart-mention/smart-mention.component';
import { SmartLinkComponent } from './smart-link/smart-link.component';
import { CommonModule } from '@angular/common';
import { PieceComponent } from './piece.component';
import { SmartTaskLinkComponent } from './smart-task-link/smart-task-link.component';
import { SmartTaskLinkInnerComponent } from './smart-task-link/smart-task-link-inner.component';
import { SmartLoggedTimeComponent } from './smart-logged-time/smart-logged-time.component';
import { SmartNoBracketsComponent } from './smart-no-brackets/smart-no-brackets.component';
import { RouterModule } from '@angular/router';
import { SharedSmartTaskLinkComponent } from './smart-task-link/shared-smart-task-link.component';

@NgModule({
  imports: [CommonModule, RouterModule],
  exports: [SmartTextComponent, SharedSmartTaskLinkComponent, SmartTaskLinkInnerComponent],
  declarations: [
    SmartTextComponent,
    PieceComponent,
    SmartMentionComponent,
    SmartLinkComponent,
    SmartTaskLinkComponent,
    SmartTaskLinkInnerComponent,
    SharedSmartTaskLinkComponent,
    SmartLoggedTimeComponent,
    SmartNoBracketsComponent
  ],
  providers: []
})
export class SmartTextModule {}
