import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { FormV2Service } from '../../shared/services/form-v2.service';

@Component({
  selector: 'add-column-or-swimlane',
  templateUrl: './add-column-or-swimlane.component.html',
  styleUrls: ['./add-column-or-swimlane.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddColumnOrSwimlaneComponent implements OnInit {
  @Input() boardId: number;
  @Input() insertAfterColumn: number;
  @Input() isAddColumn = true;

  @Output() close = new EventEmitter();

  public submit$ = new Subject();

  constructor(public _formService: FormV2Service) {}

  ngOnInit() {}

  onClose() {
    this.close.emit();
  }

  onToggleForms() {
    this.isAddColumn = !this.isAddColumn;
  }
}
