<a [routerLink]="taskUrl$ | async">
<div class="task_preview_container" [ngClass]="{right_menu_preview: !rightMenuContent}">
  <div class="users_avatar_block">
    <div class="person_avatar">
      <div *ngIf="isActionDone">
        <a-avatar *ngFor="let user of users$ | async"  [ngClass]="{group_avatars: activity.usersIds.length > 1}"
                  [user]="user"
        ></a-avatar>
        <div class="hovers_block" [ngClass]="{show_hovers: activity.usersIds.length > 1, hide_hovers: activity.usersIds.length == 1, no_avatar: (users$ | async).length == 0 }">
          <div class="hover"></div>
          <div class="hover"></div>
          <div class="hover"></div>
          <div class="hover"></div>
        </div>
      </div>
      <div *ngIf="isActionMove && movedUser$">
        <a-avatar
          [user]="movedUser$ | async"
        ></a-avatar>
      </div>
      <div *ngIf="isLogWork && movedUser$">
        <a-avatar
          [user]="movedUser$ | async"
        ></a-avatar>
      </div>
    </div>
  </div>
  <div class="breadcrumbs">
    <p *ngIf="isActionMove && movedUser$">
      <span class="user_name">{{(movedUser$ | async)?.fullname}}</span> moved task on
      <span class="board_name">{{(board$ | async)?.name}} board</span> - from
      <span class="board_name">{{(columnFromName$ | async)}}</span> to
      <span class="board_name">{{(columnToName$ | async)}}</span>
      <span class="date">{{activity.createdAt | timeAgo : "short"}}</span>
    </p>
    <p *ngIf="isLogWork && movedUser$">
      <span class="user_name">{{(movedUser$ | async)?.fullname}}</span> logged {{activity.loggedTime | toHms}} to task on
      <span class="board_name">{{(board$ | async)?.name}} board</span> in
      <span class="board_name">{{(columnToName$ | async)}}</span>
      <span class="date">{{activity.createdAt | timeAgo : "short"}}</span>
    </p>
    <p *ngIf="isActionDone">
      Completed task on <span class="board_name">{{(board$ | async)?.name}} board</span>
      <span class="date"
      >{{activity.createdAt | timeAgo : "short"}}</span>
    </p>
  </div>
  <p class="card_title"><span *ngIf="(projectShortNameForView$ | async)">{{projectShortNameForView$ | async}}-{{activity.numberInProject}}: </span>{{activity.title}}</p>
</div>
</a>
