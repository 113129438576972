<input class="quick_search_labels"
       [focus]="true"
       placeholder="Search Labels..."
       #input_seacrh_labels
       (keyup)="0"
>
<div class="labels_container">
	<div *ngFor="let label of tags | searchByField: 'name': input_seacrh_labels.value"
	     class="label_single"
	     (click)="onSetFilter(label)"
	>
		<div [ngClass]="label.color" class="label_color_box">
			<label-check-svg *ngIf="checkSelectedLabel[label.id]"
			                 class="label_check_icon"
			></label-check-svg>
		</div>
		<p class="label_title">{{label.name}}</p>
	</div>
</div>

