import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commentUrlPipe'
})
export class CommentUrlPipe implements PipeTransform {
  transform(value) {
    return 'comment' + value;
  }
}
