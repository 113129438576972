
import {interval as observableInterval, fromEvent as observableFromEvent,  Observable ,  BehaviorSubject ,  Subject } from 'rxjs';

import {takeUntil, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RoadMapItem } from '../interfaces/roadmap.interface';
import { blockWidth } from '../constants/roadmap.constants';
import { SyncRoadmapGuiStateService } from './sync-roadmap-gui-state-service';
import { getEventOffsetX } from '../../../../helpers/event';
import { animationFrame } from 'rxjs/scheduler/animationFrame';

export enum RESIZING {
  no = 0,
  right = 1,
  left = 2
}

@Injectable()
export class ResizeTaskService {
  public busy = false;
  public showResizeOverlay$ = new BehaviorSubject(false);
  public mouseMove$: Observable<MouseEvent> = new Subject<MouseEvent>();

  constructor(private _syncService: SyncRoadmapGuiStateService) {}

  registerMouseMoveSubj(sub: Observable<MouseEvent>) {
    this.mouseMove$ = sub;
  }

  public start(_, item: RoadMapItem, param: RESIZING, initialItemPosition) {
    console.warn('start');
    this._syncService.disableBodySelection();
    this.showResizeOverlay$.next(true);
    this.busy = true;
    let actualOffset = 0;

    const changingProp = param === RESIZING.right ? 'rightPx' : 'leftPx';
    const limitValueProp = param === RESIZING.left ? 'rightPx' : 'leftPx';
    const changingLimmitaion = param === RESIZING.left ? 'min' : 'max';

    const position$ = new BehaviorSubject(initialItemPosition);
    const stop$ = observableFromEvent(document, 'mouseup');

    this.mouseMove$.pipe(takeUntil(stop$)).subscribe((event: MouseEvent) => {
      actualOffset = getEventOffsetX(event) + (param === RESIZING.right ? -blockWidth : blockWidth) / 2;
    });

    // to highlight actual line
    this.mouseMove$.pipe(take(1)).subscribe(() => this._syncService.hoverItem$.next({ id: item.id, type: item.type }));
    let lastEmittedPos = 0;
    observableInterval(1, animationFrame).pipe(
      takeUntil(stop$))
      .subscribe(() => {
        if (Math.abs(actualOffset - lastEmittedPos) > 0.1) {
          lastEmittedPos = actualOffset;
          position$.next({
            [limitValueProp]: initialItemPosition[limitValueProp],
            [changingProp]: Math[changingLimmitaion](initialItemPosition[limitValueProp], actualOffset)
          });
        }
      });
    observableFromEvent(document, 'mouseup').pipe(
      take(1))
      .subscribe(() => this.stop());
    return position$.pipe(takeUntil(observableFromEvent(document, 'mouseup')));
  }

  public stop() {
    console.warn('stop resizing task on timesheet');
    this.showResizeOverlay$.next(false);
    this._syncService.enableBodySelection();
    this.busy = false;
  }
}
