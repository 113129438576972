import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntegrationLabelComponent } from './components/integration-label/integration-label.component';
import { RmenuIntegrationListComponent } from './components/rmenu-integration-list/rmenu-integration-list.component';

@NgModule({
  imports: [CommonModule],
  declarations: [IntegrationLabelComponent, RmenuIntegrationListComponent],
  exports: [IntegrationLabelComponent, RmenuIntegrationListComponent]
})
export class IntegrationsPublicModule {}
