<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <general-error-message *ngIf="_formService.isError$ | async">
    <span>{{_formService.serverError.message}}</span>
  </general-error-message>
  <div class="row" *ngIf="form.get('title')">
    <div class="label">Title</div>
    <textarea
      autosize
      rows="1"
      formControlName="title"
      [focus]="true"

    ></textarea>
    <help-block
      [control]="form.get('title')"
      [messages]="{'maxlength': 'Maximum length of 255 symbols is exceed'}"
    >
    </help-block>
  </div>

  <div class="row" *ngIf="(clouds$ | async)?.length > 1">
    <label for="jira-cloud-select">
      Cloud
    </label>
    <select id="jira-cloud-select" formControlName="cloud">
      <option *ngFor="let jiraCloud of  (clouds$ | async)" value="{{jiraCloud.id}}">{{jiraCloud.name}}</option>
    </select>
    <help-block [control]="form.get('cloud')"></help-block>
  </div>

  <div class="row">
    <label for="jira-project-select">
      Project
    </label>
    <select id="jira-project-select" formControlName="jiraProject">
      <option *ngFor="let jiraPrj of  (jiraProjects$ | async)" value="{{jiraPrj.id}}">{{jiraPrj.name}}</option>
    </select>
    <help-block [control]="form.get('jiraProject')"></help-block>
  </div>

  <button a-button class="btn_primary button"
          [pending]="_formService.isPending$ | async"
          (click)="onSubmit()"
  >Push</button>
</form>
