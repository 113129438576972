import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { User } from '../../../interfaces';
import { compareArrays } from '../../../../helpers';

const replaceItems = initItems => currentItems => {
  let result: { add: any[]; remove: any[] } = { add: [], remove: [] };
  const initItemIds = initItems.map(item => item.id);
  const currentItemIds = currentItems.map(item => item.id);

  if (compareArrays(initItems, currentItems)) {
    return result;
  }

  result = initItemIds.reduce((acc, item) => {
    if (!currentItemIds.includes(item)) {
      acc.remove.push(item);
    }
    return acc;
  }, result);

  result = currentItemIds.reduce((acc, item) => {
    if (!initItemIds.includes(item)) {
      acc.add.push(item);
    }
    return acc;
  }, result);

  return result;
};
@Component({
  selector: 'members-popup',
  templateUrl: './members-popup.component.html',
  styleUrls: ['./members-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MembersPopupComponent implements OnInit {
  @Input() possibleUsers: User[];
  @Input() selectedUsers: User[];
  @Input() projectOnlyUsers: User[];
  @Input() isPublicBoard = false;
  @Input() isBatchActions: boolean;
  @Input() removeMode: boolean;

  @Output() addUserToBoard = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() update = new EventEmitter();
  public currentSelection: User[];
  public replaceCollections;
  private isPristine = true;

  constructor() {}

  ngOnInit() {
    if (this.isBatchActions) {
      this.selectedUsers = [];
    }
    this.replaceCollections = replaceItems(this.selectedUsers);
    this.currentSelection = [...this.selectedUsers];
  }

  addUser(user: User) {
    this.isPristine = false;
    this.currentSelection.push(user);
  }

  onAddUserToBoard(user: User) {
    this.addUserToBoard.emit(user);
  }

  removeUser(user: User) {
    this.isPristine = false;
    this.currentSelection = this.currentSelection.filter(item => item.id !== user.id);
  }

  updateUsersList() {
    if (this.isPristine) {
      return;
    }
    if (this.isBatchActions) {
      const result = { [this.removeMode ? 'remove' : 'add']: this.currentSelection.map(user => user.id) };
      this.update.emit(result);
      return;
    }
    const changes = this.replaceCollections(this.currentSelection);
    if ((changes.add && changes.add.length) || (changes.remove && changes.remove.length)) {
      this.update.emit(this.replaceCollections(this.currentSelection));
    }
  }

  onCloseMembersPopup() {
    this.updateUsersList();
    this.close.emit();
  }
}
