import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, Observer, Subscription } from 'rxjs/index';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModelValidators } from '../../validators/model-validators';
import { BOARD_PL, BoardFirstOpenType, boardType, ScoringType } from '../../../constants';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../services/form-v2.service';
import { JsonApiSingeModelResponse, jsonApiToSingleEntity } from '../../services/app/web-socket/http-response';
import { fromBoardsTemplates } from '../../../ngrx/reducers/boards-template.reducer';
import { take } from 'rxjs/operators';
import { Board } from '../../../interfaces';
import { AppUrls } from '../../../app-urls';
import { HandleResponseAction } from '../../../ngrx/actions/root.action';
import { RouterNavigateService } from '../../services/router-navigate.service';
import { SegmentService } from '../../../atlaz-bnp/services/intergations/segment/segment.service';
import { PaywallService } from '../../../libs/paywall/paywall.service';
import { AppState, LEFT_MENU } from '../../../ngrx/state';
import { Store } from '@ngrx/store';
import { ComponentListenClickoutAction } from '../../../ngrx/actions/component.actions';
import { isStickyLeftMenu } from '../../../ngrx/reducers/component.reducer';
import { PermissionsService } from '../../../permissions/permissions.service';

@Component({
  selector: 'new-board-combined',
  templateUrl: './new-board-combined.component.html',
  styleUrls: ['./new-board-combined.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewBoardCombinedComponent implements OnInit, OnDestroy {
  @Input() openFromLeftMenu = true;
  @Output() close = new EventEmitter();
  public boardTypes = boardType;
  public isPinned$: Observable<boolean>;
  public isProjAdmin$: Observable<boolean>;
  public isSuperUser$: Observable<boolean>;
  public fromTemplateOnInit = false;
  public showTemplateChooseErr$ = new BehaviorSubject('');
  public isModeSelectorAvailable$ = new BehaviorSubject(true);
  public isCommonFieldsVisible$ = new BehaviorSubject(true);
  public isTypeChooserAvailable$ = new BehaviorSubject(true);
  public isTemplateChooserAvailable$ = new BehaviorSubject(false);

  public projectErrorMessages = {
    invalidProjectsCount: 'Select single project'
  };

  public disabledProjects: number[] = [];
  public selectedProjects$: BehaviorSubject<number[]> = new BehaviorSubject([]);
  public selectedCollections$: BehaviorSubject<number[]> = new BehaviorSubject([]);
  public initialBoardType = boardType.kanban;

  formObserver: Observer<any> = {
    next: (resp: JsonApiSingeModelResponse<any>) => {
      this._store.dispatch(new HandleResponseAction(resp));
      const board: Board = jsonApiToSingleEntity(resp);
      const source = this._activatedRoute.snapshot.queryParams['source'] || 'leftMenu';
      if (this.form.value.template) {
        this._store
          .select(fromBoardsTemplates.getById(+this.form.value.template))
          .pipe(take(1))
          .subscribe(template => {
            this._segment.boardCreated(board.type, source, template.name, template.id);
          });
      } else {
        this._segment.boardCreated(board.type, source);
      }
      if (this.goToNewBoard) {
        if (board.type === boardType.backlog && board.boardTemplateViewType === BoardFirstOpenType.scoring) {
          this._routerNav
            .navigate(
              board.scoringType === ScoringType.basic
                ? AppUrls.getUrlBacklogChart(board.id)
                : AppUrls.getUrlBacklogScoring(board.id)
            )
            .then(() => this.onClose());
        } else {
          this._routerNav.navigateToBoard(board.id).then(() => this.onClose());
        }
      } else {
        this.onClose();
      }
    },
    error: error => {},
    complete: () => {}
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.add,
    entityToEdit: BOARD_PL,
    formObserver: this.formObserver,
    prepareFormValue: formValue => {
      const result = {
        name: formValue['name'],
        collections: formValue['collections'],
        projects: formValue['projects']
      };
      if (formValue['template']) {
        result['template'] = formValue['template'];
      } else {
        result['type'] = formValue['type'];
      }
      if (formValue['withoutTasks']) {
        result['withoutTasks'] = 1;
      }
      return result;
    }
  };

  form: FormGroup;
  initialValues = {};

  public goToNewBoard: boolean;

  public subs: Subscription[] = [];
  constructor(
    private _fb: FormBuilder,
    public _formService: FormV2Service,
    private _routerNav: RouterNavigateService,
    private _segment: SegmentService,
    private _paywall: PaywallService,
    private _activatedRoute: ActivatedRoute,
    private _store: Store<AppState>,
    private _permissions: PermissionsService
  ) {}

  ngOnInit() {
    this.isSuperUser$ = this._permissions.isSuperUser$;
    this.isProjAdmin$ = this._permissions.isAnyProjectAdmin$;
    this.isPinned$ = this._store.select(isStickyLeftMenu);
    this.fromTemplateOnInit = this._activatedRoute.snapshot.queryParams['fromTemplate'];
    this.isCommonFieldsVisible$.next(!this.fromTemplateOnInit);
    this.isTypeChooserAvailable$.next(!this.fromTemplateOnInit);
    this.isTemplateChooserAvailable$.next(this.fromTemplateOnInit);

    const flag = this._activatedRoute.snapshot.queryParams['goToNewBoard'];
    const projId = this._activatedRoute.snapshot.queryParams['projectId'];
    const collectionId = this._activatedRoute.snapshot.queryParams['collectionId'];
    this.goToNewBoard = flag === 'true' || true; // Always open is experimental feature
    this.initialValues = {
      projects: projId ? [+projId] : [],
      collections: collectionId ? [+collectionId] : []
    };
    const defaultProjectId = +this._activatedRoute.snapshot.queryParams['projectId'];
    if (defaultProjectId) {
      this.selectedProjects$.next([defaultProjectId]);
      this.disabledProjects = [defaultProjectId];
    }

    this.form = this._fb.group({
      name: ['', ModelValidators.board.name],
      type: [this.initialBoardType, Validators.required],
      projects: [
        [...this.initialValues['projects']],
        (c: FormControl) =>
          this.form && this.form.get('type') && this.form.get('type').value === boardType.sprint
            ? Validators.required(c) || c.value.length !== 1 ? { invalidProjectsCount: true } : null
            : Validators.nullValidator(c)
      ],
      collections: [[...this.initialValues['collections']]],
      template: [null],
      withoutTasks: [true]
    });
    this.selectedCollections$.next([...this.initialValues['collections']]);
    this._formService.initFormParams(this.form, this.formServiceParams);
    this.subs.push(
      this.selectedCollections$.subscribe(collections => this.form.get('collections').patchValue(collections)),
      this.selectedProjects$.subscribe(projects => this.form.get('projects').patchValue(projects))
    );
  }

  setTemplateChoosing(isTemplateChoosing) {
    this.isTemplateChooserAvailable$.next(isTemplateChoosing);
    this.isTypeChooserAvailable$.next(!isTemplateChoosing);
    this.isCommonFieldsVisible$.next(!isTemplateChoosing);
  }

  onSubmit() {
    this._formService.markAsDirty();
    this.form.markAsDirty();
    if (this.form.valid) {
      if (this.isTypeChooserAvailable$.value) {
        this.form.get('template').patchValue(null);
      }
      this._formService.submit();
    }
  }

  onClose() {
    if (this.openFromLeftMenu) {
      this._store.dispatch(new ComponentListenClickoutAction({ name: LEFT_MENU, listenClickout: true }));
      return this._routerNav.deactivatePopupOutlet();
    } else {
      this.close.emit();
    }
  }

  onChangeSelectedTemplate(selectedTemplates: number[]) {
    this.form.get('template').patchValue(Array.isArray(selectedTemplates) ? selectedTemplates[0] : null);
  }

  onChangeSelectedType(selectedType) {
    this.form.get('type').patchValue(selectedType);
  }

  onUseTemplate() {
    if (this.form.get('template').value) {
      this.isTemplateChooserAvailable$.next(false);
      this.isModeSelectorAvailable$.next(false);
      this.isCommonFieldsVisible$.next(true);
    } else {
      this.showTemplateChooseErr$.next('No template chosen');
    }
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
