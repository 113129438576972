import { Action } from './unsafe-action';
import { labelAction } from '../functions/action';
import { Checklist, HttpQueryParam, PartOfEntity } from '../../interfaces';
import { CHECKLIST } from '../../constants';

export const ChecklistActionTypes = {
  GET: labelAction(CHECKLIST, 'GET'),
  GET_COMPLETE: labelAction(CHECKLIST, 'GET_COMPLETE'),
  ADD: labelAction(CHECKLIST, 'ADD'),
  ADD_COMPLETE: labelAction(CHECKLIST, 'ADD_COMPLETE'),
  EDIT: labelAction(CHECKLIST, 'EDIT'),
  EDIT_COMPLETE: labelAction(CHECKLIST, 'EDIT_COMPLETE'),
  DELETE: labelAction(CHECKLIST, 'DELETE'),
  DELETE_COMPLETE: labelAction(CHECKLIST, 'DELETE_COMPLETE')
};

export class ChecklistGetAction implements Action {
  type = ChecklistActionTypes.GET;

  constructor(public payload?: HttpQueryParam) {}
}

export class ChecklistGetCompleteAction implements Action {
  type = ChecklistActionTypes.GET_COMPLETE;

  constructor(public payload: Checklist[]) {}
}

export class ChecklistEditAction implements Action {
  type = ChecklistActionTypes.EDIT;
  constructor(public payload: PartOfEntity) {}
}

export class ChecklistEditCompleteAction implements Action {
  type = ChecklistActionTypes.EDIT_COMPLETE;

  constructor(public payload: Checklist) {}
}

export class ChecklistDeleteAction implements Action {
  type = ChecklistActionTypes.DELETE;

  constructor(public payload: Checklist) {}
}

export class ChecklistDeleteCompleteAction implements Action {
  type = ChecklistActionTypes.DELETE_COMPLETE;

  constructor(public payload?: any) {}
}

export class ChecklistAddAction implements Action {
  type = ChecklistActionTypes.ADD;

  constructor(public payload: PartOfEntity) {}
}

export class ChecklistAddCompleteAction implements Action {
  type = ChecklistActionTypes.ADD_COMPLETE;

  constructor(public payload: Checklist) {}
}

export type ChecklistActions =
  | ChecklistGetAction
  | ChecklistGetCompleteAction
  | ChecklistEditAction
  | ChecklistEditCompleteAction
  | ChecklistDeleteAction
  | ChecklistDeleteCompleteAction
  | ChecklistAddAction
  | ChecklistAddCompleteAction;
