import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Version } from '../../../../interfaces';

@Component({
  selector: 'versions-filter-list',
  templateUrl: './versions-filter-list.component.html',
  styleUrls: ['./versions-filter-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VersionsFilterListComponent implements OnInit {
  @Input() versions: Version[];
  @Input() selectedVersions: Version[];

  @Output() addVersion = new EventEmitter();
  @Output() removeVersion = new EventEmitter();

  public checkSelectedVersion: { [id: number]: boolean } = {};

  constructor() {}

  ngOnInit() {
    this.selectedVersions.forEach((version: Version) => {
      this.checkSelectedVersion[version.id] = true;
    });
  }

  onSetFilter(version: Version) {
    if (!this.checkSelectedVersion[version.id]) {
      this.checkSelectedVersion[version.id] = true;
      this.addVersion.emit(version);
    } else {
      this.checkSelectedVersion[version.id] = false;
      this.removeVersion.emit(version);
    }
  }
}
