import { AppState, ESInterface } from '../state';
import { TasksLinksType } from '../../interfaces';
import { TASKS_LINKS_TYPE_PL } from '../../constants';
import { createSelector } from 'reselect';

export const getTasksLinksTypesState = (state: AppState) => <ESInterface<TasksLinksType>>state[TASKS_LINKS_TYPE_PL];

export const getTasksLinksTypes = createSelector(
  getTasksLinksTypesState,
  (types: ESInterface<TasksLinksType>): TasksLinksType[] =>
    types.ids.map(id => types.entities[id]).filter(taskLink => taskLink.kind !== 'DEPEND')
);
export const getDependenciesTypes = createSelector(
  getTasksLinksTypesState,
  (types: ESInterface<TasksLinksType>): TasksLinksType[] =>
    types.ids.map(id => types.entities[id]).filter(taskLink => taskLink.kind === 'DEPEND')
);
