import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../../../shared/services/form-v2.service';
import {CompanyService} from "../../../../shared/services/app/company.service";
import {repeatTypes, TASK_PL} from '../../../../constants';
import {FormBuilder, FormGroup} from "@angular/forms";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../ngrx/state";
import {Observer} from "rxjs";
import {JsonApiSingeModelResponse} from "../../../../shared/services/app/web-socket/http-response";
import {HandleResponseAction} from "../../../../ngrx/actions/root.action";
import {Task} from "../../../../interfaces";

@Component({
  selector: 'sidebar-repeat-add-form',
  templateUrl: './sidebar-repeat-add-form.component.html',
  styleUrls: ['./sidebar-repeat-add-form.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarRepeatAddFormComponent implements OnInit {

  @Output() close = new EventEmitter();
  @Input() task: Task;

  daysOfWeekLabels = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ];

  daysOfWeek = this.daysOfWeekRange();

  monthsLabel = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  formObserver: Observer<any> = {
    next: (resp: JsonApiSingeModelResponse<any>) => {
      this.close.emit();
      this._store.dispatch(new HandleResponseAction(resp));
    },
    error: error => {
      console.log(error, 'error task repeat');
    },
    complete: () => {}
  };

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.edit,
    entityToEdit: TASK_PL,
    formObserver: this.formObserver,
    prepareFormValue: formValue => {
      const recurrenceType = Number(this.form.value.recurrenceType);

      const data = {
        id: this.task.id,
        recurrenceType: recurrenceType,
        recurrenceData: {}
      };

      switch (recurrenceType) {
        case repeatTypes.never:
          break;
        case repeatTypes.daily:
          break;
        case repeatTypes.periodically:
          data.recurrenceData['daysAfterCompletion'] = this.form.value.daysAfterCompletion;
          break;
        case repeatTypes.weekly:
          data.recurrenceData['frequency'] = this.form.value.frequency;
          data.recurrenceData['daysOfWeek'] = this.getSelectedDaysOfWeek();
          break;
        case repeatTypes.monthly:
          data.recurrenceData['frequency'] = this.form.value.frequency;
          data.recurrenceData['date'] = this.form.value.date;
          break;
        case repeatTypes.yearly:
          data.recurrenceData['month'] = this.form.value.month;
          data.recurrenceData['date'] = this.form.value.date;
          break;
      }

      return data;
    }
  };

  form: FormGroup;

  public repeatTypes = repeatTypes;

  constructor(private _fb: FormBuilder,
    public _formService: FormV2Service,
    private _store: Store<AppState>,
    private _company: CompanyService
  ) {}

  ngOnInit() {
    this.daysOfWeek.forEach(day => {
      if (this.task.recurrenceData !== undefined
        && this.task.recurrenceData.daysOfWeek !== undefined
        && this.task.recurrenceData.daysOfWeek.indexOf(day.day) !== -1)
      {
        day.checked = true;
      }
    });

    if (!this.getSelectedDaysOfWeek().length) {
      this.daysOfWeek[6].checked = true;
    }

    this.form = this._fb.group({
      recurrenceType: [this.task.recurrenceType || repeatTypes.never],
      daysAfterCompletion: [this.task.recurrenceData.daysAfterCompletion || 1],
      frequency: [this.task.recurrenceData.frequency || 1],
      date: [this.task.recurrenceData.date || 1],
      month: [this.task.recurrenceData.month || 1],
    });
    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  daysAfterCompletionRange() {
    const days = [];
    for (let i = 1; i <= 30; i++) {
      days.push(i);
    }
    return days;
  }

  weeksFrequencyRange() {
    const weeks = [];
    for (let i = 1; i <= 12; i++) {
      weeks.push(i);
    }
    return weeks;
  }

  daysOfWeekRange() {
    const weekStart = this._company.weekStartDay;
    const days = [];
    for (let i = weekStart; i < 7+weekStart; i++) {
      const day = i % 7;
      days.push({
        day: day,
        label: this.daysOfWeekLabels[day],
        checked: false
      });
    }
    return days;
  }

  getSelectedDaysOfWeek() {
    const days = [];
    this.daysOfWeek.forEach(day => {
      if (day.checked) {
        days.push(day.day);
      }
    });
    return days;
  }

  monthsFrequencyRange() {
    const months = [];
    for (let i = 1; i <= 12; i++) {
      months.push(i);
    }
    return months;
  }

  dayOfTheMonthRange() {
    const days = [];
    for (let i = 1; i <= 28; i++) {
      days.push(i);
    }
    return days;
  }

  monthsRange() {
    const months = [];
    for (let i = 0; i < 12; i++) {
      months.push({
        month: i+1,
        label: this.monthsLabel[i],
      });
    }
    return months;
  }

  onClickDayOfWeek(day) {
    day.checked = +!day.checked;

    if (!day.checked && !this.getSelectedDaysOfWeek().length) {
      day.checked = true;
    }
  }

  onSubmit() {
    if (this.form.invalid) {
      return false;
    }

    this._formService.submit();
    return false;
  }

  onCancel() {
    this.close.emit();
  }
}
