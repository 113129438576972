import { Injectable } from '@angular/core';

import { CompanyService } from '../app/company.service';
import { AuthService } from '../app/auth.service';

@Injectable()
export class UploadFileService {
  private _companyUrl: string;

  constructor(private _companyService: CompanyService, private _userAuthService: AuthService) {
    this._companyUrl = this._companyService.getApiUrl();
  }

  appendTokenToUrl(url, accessToken): string {
    const prefixQuery = url.indexOf('?', url) >= 0 ? '&' : '?';

    url += prefixQuery + 'access_token=' + accessToken;
    return url;
  }

  makeFileRequest(params: Array<string>, files: File): Promise<any> {
    const accessToken = this._userAuthService.accessToken;
    const xsrfToken = this._userAuthService.xsrfToken;

    const url = this.appendTokenToUrl(this._companyUrl + '/files', accessToken);

    const result = {};

    return new Promise((resolve, reject) => {
      const formData = new FormData();
      const xhr = new XMLHttpRequest();

      formData.append('file', files, files.name);

      xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
          if (xhr.status === 201) {
            resolve(JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      };
      xhr.open('POST', url, true);

      xhr.setRequestHeader('X-XSRF-TOKEN', xsrfToken);
      xhr.setRequestHeader('Atlaz-API-Version', '2016-02-29');

      xhr.withCredentials = true;
      xhr.send(formData);
    });
  }
}
