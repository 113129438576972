import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit {
  @Input() errorMessage: string;
  @Input() searchQuery: string;
  @Input() resultIds: number[];

  ngOnInit(): any {}
}
