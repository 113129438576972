import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { HiddenItems, RoadMapItem } from '../../../interfaces/roadmap.interface';
import { trackById } from '../../../../../../helpers/index';
import { COLLAPSED, EXPANDED } from '../../../store/roadmap-board.effect';
import { SyncRoadmapGuiStateService } from '../../../services/sync-roadmap-gui-state-service';
import { DraggedActualPositions, RoadmapDragService } from '../../../services/roadmap-drag.service';
import {
  defaultDragOverAddButtonHandler,
  defaultDragOverHeader
} from '../../../swimlanes/named-swimlane/named-swimlane.component';
import { Observable } from 'rxjs/Observable';
import { TaskLink } from '../../../../../interfaces/tasks-links-type';
import { getOutwardDependenciesByTaskIds } from '../../../../../ngrx/functions/crossed.selector';
import { AppState } from '../../../../../ngrx/state';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { isActiveTask } from '../../../../../ngrx/reducers/task.reducer';

@Component({
  selector: 'day-items',
  templateUrl: './day-items.component.html',
  styleUrls: ['./day-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DayItemsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() items: RoadMapItem[];
  @Input() hiddenItems: HiddenItems = {};
  @Input() inGroup = false;
  @Input() itemPositions$;
  @Input() isNotGuest: boolean;
  @Input() hideEmptyRows: boolean;

  public DraggedActualPositions = DraggedActualPositions;
  public trackById = trackById;
  public hoverItem$ = this._syncRoadmapGuiStateService.hoverItem$;
  public syncOpenPushForm$ = this._syncRoadmapGuiStateService.openPushForm$;
  public actualDragPosition$ = this._dragService.actualPosition$;
  public outwardDependenciesMap$: Observable<{ [id: number]: TaskLink }>;
  public isShowConnectionLines = true;
  public items$ = new BehaviorSubject([]);

  public EXPANDED = EXPANDED;
  public COLLAPSED = COLLAPSED;

  public defaultDragOverHeader = defaultDragOverHeader;
  public defaultDragOverAddButtonHandler = defaultDragOverAddButtonHandler;
  public timeout;

  constructor(
    private _syncRoadmapGuiStateService: SyncRoadmapGuiStateService,
    private _dragService: RoadmapDragService,
    private _store: Store<AppState>,
    private _cd: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('items') && changes['items'].currentValue) {
      this.items$.next([...changes['items'].currentValue]);
    }
  }

  ngOnInit() {
    this.outwardDependenciesMap$ = this.items$.pipe(
      filter(isActiveTask),
      switchMap(items =>
        this._store.select(
          getOutwardDependenciesByTaskIds(
            Array.isArray(items) ? items.map((roadMapItem: RoadMapItem) => roadMapItem.task.id) : []
          )
        )
      ),
      map((deps: TaskLink[]) =>
        deps.reduce((acc, item) => {
          if (!acc[item.outwardTask]) {
            acc[item.outwardTask] = [];
          }
          acc[item.outwardTask].push(item.inwardTask);
          return acc;
        }, {})
      ),
      tap(_ => {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => this._cd.detectChanges());
      })
    );
  }

  ngAfterViewInit() {
    this._cd.detectChanges(); // Draw connection lines first time
  }

  onHideConnectionLines() {
    this.isShowConnectionLines = false;
  }

  onShowConnectionLines() {
    this.isShowConnectionLines = true;
  }

  onMouseEnter(itemId, itemType) {
    this._syncRoadmapGuiStateService.hoverItem$.next({ id: itemId, type: itemType });
  }

  onMouseOut() {
    this._syncRoadmapGuiStateService.clearHoverItem();
  }

  ngOnDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }
}
