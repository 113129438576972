import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AtlazBnPModule } from './atlaz-bnp/atlaz-bnp.module';
import { AtlazBnPUrlSerializer } from './atlaz-bnp-url-serilalizer';
import { UrlSerializer } from '@angular/router';
import { AtlazGuiModule } from './atlaz-gui/atlaz-gui.module';
import { CoreModule } from './core/core.module';

@NgModule({
  imports: [BrowserModule, CoreModule, AtlazGuiModule, AtlazBnPModule, BrowserAnimationsModule],
  declarations: [AppComponent],
  providers: [
    {
      provide: UrlSerializer,
      useClass: AtlazBnPUrlSerializer
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {}
}
