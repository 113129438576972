
import {take, filter, map} from 'rxjs/operators';
import { Directive, ElementRef, HostListener, OnInit, OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { isPresent } from '../../../helpers/object';
import { platform } from '../../../helpers/platform';

@Directive({
  selector: '[checkTouch]'
})
export class CheckTouchDirective implements OnInit, OnDestroy {
  private sub: Subscription;
  private fakeOnChageFixSubscription: Subscription;
  private initValue = '';
  private valueChanged = false;

  constructor(public element: ElementRef, private control: NgControl) {}

  ngOnInit() {
    this.initValue = this.control.value;

    if (platform.name === 'IE') {
      /**
       * Fix for IE11: If an initial value of an input is an empty string and placeholder attribute is set,
       * IE generates a redundant valueChanged event even if user has not changed value of the input. As a control
       * prestine attribute is set to false validators are run. If 'required' validator is set user see an error
       * for a field that was focused but not changed
       */
      this.fakeOnChageFixSubscription = this.control.control.valueChanges.subscribe(v => {
        if (v !== this.initValue) {
          this.valueChanged = true;
        }
        if (this.initValue === '' && this.valueChanged === false && v === '') {
          this.control.control.markAsPristine();
        }
      });
    }
  }

  @HostListener('focus')
  onFocus(): void {
    if (this.control.valid) {
      this.control.control.markAsUntouched();
    } else {
      this.sub = this.control.control.valueChanges.pipe(
        map(() => this.control.valid),
        filter(isPresent),
        take(1),)
        .subscribe(() => {
          this.control.control.markAsUntouched();
          this.sub.unsubscribe();
        });
    }
  }

  @HostListener('blur')
  onBlur() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  ngOnDestroy() {
    if (this.fakeOnChageFixSubscription) {
      this.fakeOnChageFixSubscription.unsubscribe();
    }
  }
}
