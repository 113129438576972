<div a-context-controll
     orientation="vertical"
     class="content_menu"
     [menuSlim]="true"
     [menuRight]="true"
     [noOverlay]="true"
     [isMenuVisible]="isContentMenuVisible"
     #popup
>

  <ng-container *ngIf="editPermissions">
    <div class="info_text">With {{batchTaskIds.length}} {batchTaskIds.length, plural, =1 {task} other {tasks}} selected:</div>
    <div class="group">
      <span class="group_title">Edit Members</span>
      <svg width="18" height="18">
        <use xlink:href="#triangle"></use>
      </svg>
      <div class="group_items">
        <p a-context-menu-item (click)="onSwitchAddMembers()">Add</p>
        <p a-context-menu-item (click)="onSwitchRemoveMembers()">Remove</p>
      </div>
    </div>
    <p a-context-menu-item (click)="onSwitchProjects()">Project</p>
    <p a-context-menu-item (click)="onSwitchVersions()">Version</p>
    <div class="group">
      <span class="group_title">Edit Labels</span>
      <svg width="18" height="18">
        <use xlink:href="#triangle"></use>
      </svg>
      <div class="group_items">
        <p a-context-menu-item (click)="onSwitchAddLabels()">Add</p>
        <p a-context-menu-item (click)="onSwitchRemoveLabels()">Remove</p>
      </div>
    </div>
    <p a-context-menu-item (click)="onSwitchStartDate()">Start Date</p>
    <p a-context-menu-item (click)="onSwitchDueDate()">Due Date</p>
    <p a-context-menu-item (click)="onSwitchMoveTaskMenu()">Move</p>
    <p a-context-menu-item *ngIf="isMarkAsDoneVisible$ | async" (click)="onMarkAsDone()">Mark as Done</p>
    <p a-context-menu-item (click)="onSwitchCopyTaskMenu()">Copy</p>
    <p a-context-menu-item (click)="onSwitchPushTaskMenu()">Push</p>
    <p a-context-menu-item (click)="onToggleArchiveTask()">Archive</p>
    <p a-context-menu-item class="delete_link" (click)="onToggleDeleteTask()">{{'Delete'}}</p>
  </ng-container>
</div>

<div *ngIf="isDeleteTaskVisible">
  <div a-context-popup
       [title]="'Delete'"
       (close)="onToggleDeleteTask()"
       class="delete_task_confirm"
  >
    <p class="delete_task_message">
      Are You sure You want to Delete this <span>{ batchTaskIds.length, plural, =1 {task} other {tasks}}</span>?<br><br>
      All <span>{ batchTaskIds.length, plural, =1 {task} other {tasks}}</span> data including comments, attachments and statistics will be lost.<br><br>
      There is no undo.
    </p>
    <div a-button class="btn_warn" (click)="onDeleteTask()">Delete</div>
    <div a-button (click)="onToggleDeleteTask()" class="btn_secondary">Cancel</div>
  </div>
  <div class="delete_task_overlay"  (click)="onToggleDeleteTask()"></div>
</div>
<div *ngIf="isArchiveTaskVisible">
  <div a-context-popup
       [title]="'Archive'"
       (close)="onToggleArchiveTask()"
       class="delete_task_confirm"
  >
    <p class="delete_task_message">
      Are You sure You want to Archive this <span>{ batchTaskIds.length, plural, =1 {task} other {tasks}}</span>?<br><br>
    </p>
    <div a-button class="btn_warn" (click)="onArchiveTask()">Archive</div>
    <div a-button (click)="onToggleArchiveTask()" class="btn_secondary">Cancel</div>
  </div>
  <div class="delete_task_overlay"  (click)="onToggleArchiveTask()"></div>
</div>
<div a-context-popup *ngIf="isMoveTaskMenuVisible"
     [title]="'Move'"
     (close)="onSwitchMoveTaskMenu()"
     class="move_task_popup"
>
  <task-action-popup [task]="task"
                     [isBatchActions]="true"
                     [batchTasks]="batchTasks$ | async"
                     actionName="move"
                     (close)="onSwitchMoveTaskMenu()"
  ></task-action-popup>
</div>
<div a-context-popup *ngIf="isPushTaskMenuVisible"
     [title]="'Push'"
     (close)="onSwitchPushTaskMenu()"
     class="move_task_popup"
>
  <task-action-popup [task]="task"
                     [isBatchActions]="true"
                     [batchTasks]="batchTasks$ | async"
                     actionName="push"
                     (close)="onSwitchPushTaskMenu()"
  ></task-action-popup>
</div>
<div a-context-popup *ngIf="isCopyTaskMenuVisible"
     [title]="'Copy'"
     (close)="onSwitchCopyTaskMenu()"
     class="move_task_popup"
>
  <task-action-popup [task]="task"
                     [isBatchActions]="true"
                     [batchTasks]="batchTasks$ | async"
                     actionName="copy"
                     (close)="onSwitchCopyTaskMenu()"
  ></task-action-popup>
</div>
<a-center-popup
  class="declined"
  *ngIf="isMarkAsDoneErr"
  [title]="'Action Canceled'"
  (close)="onToggleMarkAsDoneErr()"
>
  <p>
    You have no 'Done' columns on this board.
  </p>
</a-center-popup>
<members-popup
  *ngIf="isAddMembersPopupVisible"
  [isBatchActions]="true"
  (addUserToBoard)="onAddUserToBoard($event)"
  (close)="onSwitchAddMembers()"
  (update) = "onUpdateUsersList($event)"
  [isPublicBoard]="isPublicBoard$ | async"
  [possibleUsers]="possibleUsers$ | async"
  [projectOnlyUsers]="[]"
></members-popup>
<members-popup
  *ngIf="isRemoveMembersPopupVisible"
  [isBatchActions]="true"
  [removeMode]="true"
  (addUserToBoard)="onAddUserToBoard($event)"
  (close)="onSwitchRemoveMembers()"
  (update) = "onUpdateUsersList($event)"
  [isPublicBoard]="isPublicBoard$ | async"
  [possibleUsers]="possibleUsers$ | async"
  [projectOnlyUsers]="[]"
></members-popup>
<sidebar-labels-popup
  *ngIf="isAddLabelsPopupVisible"
  (close)="onSwitchAddLabels()"
  [isBatchActions]="true"
  [batchTasks]="batchTasks$ | async"
  [task]="task"
></sidebar-labels-popup>
<sidebar-labels-popup
  *ngIf="isRemoveLabelsPopupVisible"
  (close)="onSwitchRemoveLabels()"
  [isBatchActions]="true"
  [batchTasks]="batchTasks$ | async"
  [removeMode]="true"
  [task]="task"
></sidebar-labels-popup>
<sidebar-project-popup
  *ngIf="isProjectsPopupVisible"
  (close)="onSwitchProjects()"
  [boardProjects]="boardProjects$ | async"
  [isBatchActions]="true"
  [batchTasks]="batchTasks$ | async"
  [task]="task"
></sidebar-project-popup>
<sidebar-version-popup
  *ngIf="isVersionsPopupVisible"
  (close)="onSwitchVersions()"
  [versions]="boardVersions$ | async"
  [isBatchActions]="true"
  [batchTasks]="batchTasks$ | async"
  [task]="task"
></sidebar-version-popup>
<sidebar-duedate-popup
  *ngIf="showDueDatePopUp"
  (onClosePopup)="onSwitchDueDate()"
  [task]="task"
  [isBatchActions]="true"
  [batchTasks]="batchTasks$ | async"
></sidebar-duedate-popup>
<sidebar-start-date-popup
  *ngIf="showStartDatePopUp"
  (onClosePopup)="onSwitchStartDate()"
  [task]="task"
  [isBatchActions]="true"
  [batchTasks]="batchTasks$ | async"
></sidebar-start-date-popup>
