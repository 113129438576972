import { Column, Task } from '../app/interfaces';
import { Project } from '../app/interfaces';
import { JsonApiSingeModelResponse } from '../app/shared/services/app/web-socket/http-response';
import { TaskModel } from '../app/shared/services/app/models/task';
import { isPresent } from './object';
import { COLUMN, PROJECT, ScalePokerValues, ScaleShirtValues, ScaleType } from '../app/constants';
import { SwimlaneCounter } from '../app/interfaces/swimlane';

const TASK_KEY_REG = /^(.+)-(\d+)$/;

export const countTasksAndEstimatesInSwimlanes = (tasks: Task[]): { [swimlaneId: number]: SwimlaneCounter } => {
  const countedValues = tasks.reduce((counter, task) => {
    if (!counter[task.swimlane]) {
      counter[task.swimlane] = { count: 0, hours: 0, points: 0 };
    }
    counter[task.swimlane].count++;
    counter[task.swimlane].hours += +task.estimate;
    counter[task.swimlane].points += +task.storyPoints;
    return counter;
  }, {});
  Object.keys(countedValues).forEach(swimlaneId => {
    countedValues[swimlaneId].hours = secondsToText(
      countedValues[swimlaneId].hours,
      'No estimated tasks in this swimlane'
    );
    countedValues[swimlaneId].points = countedValues[swimlaneId].points
      ? countedValues[swimlaneId].points + ' story points'
      : 'No estimated tasks in this swimlane';
    countedValues[swimlaneId].count = countedValues[swimlaneId].count
      ? countedValues[swimlaneId].count + ' task' + (countedValues[swimlaneId].count === 1 ? '' : 's')
      : 'No tasks in this swimlane';
  });
  return countedValues;
};

export const secondsToText = (seconds: number, zeroText = '0m'): string => {
  if (seconds === 0) {
    return zeroText;
  } else if (seconds < 3600) {
    return (seconds / 60).toFixed() + 'm';
  } else if (seconds < 35998199) {
    return (seconds / 3600).toFixed() + 'h';
  } else {
    return '9999+ h';
  }
};

export const taskByColumnIdsFilter = (columnIds: number[]) => task => columnIds.includes(task.column);
export const taskByColumnFilter = (columns: Column[]) => taskByColumnIdsFilter(columns.map(column => column.id));

const addBreadcrumb = (breadcrumbs: string, crumb: string) =>
  crumb !== '' ? addCrumb(breadcrumbs, crumb) : breadcrumbs;
const addCrumb = (breadcrumbs, crumb) =>
  breadcrumbs !== '' ? breadcrumbs.concat(' / ').concat(crumb) : breadcrumbs.concat(crumb);

const getNameProp = object => (!!object && !!object.name ? object.name : '');

export const getTaskBreadCrumbs = (task, board, swimlane, column, subColumn) => {
  try {
    if (!!subColumn) {
      const copyColumn = Object.assign({}, column);
      copyColumn['name'] = copyColumn.name + ` (${subColumn.name})`;
      column = copyColumn;
    }
    const crumbs = board.swimlanesIds.length > 1 ? [board, column, swimlane] : [board, column];
    let breadCrumbs = crumbs.map(getNameProp).reduce(addBreadcrumb, '');

    if (!!task && task.archived) {
      breadCrumbs += ` (Archived)`;
    }

    if (!!task && task.released) {
      breadCrumbs += ` (Released)`;
    }

    return breadCrumbs;
  } catch (e) {
    console.log(board, swimlane, column, subColumn, 'arguments');
    console.log(e, 'error create breadcrumbs');
    return '';
  }
};

export const getTaskKey = (task: Task, taskProject: Project) => {
  return taskProject && task && task.numberInProject ? taskProject.shortName + '-' + task.numberInProject : '';
};

export const isTaskKey = taskKey => TASK_KEY_REG.test(taskKey);

export const parseTaskKey = taskKey => taskKey.match(TASK_KEY_REG) || [, undefined, undefined];

export const getTaskKeyFromTaskModel = (taskResponse: JsonApiSingeModelResponse<TaskModel>) => {
  const hasProject = (response: JsonApiSingeModelResponse<TaskModel>) =>
    response.data && isPresent(response.data.relationships.project.data);
  let taskKey = '';

  if (hasProject(taskResponse)) {
    const projectShortName = taskResponse.included.find(relation => relation.type === PROJECT)['attributes'][
      'shortName'
    ];
    const numberInProject = taskResponse.data.attributes.numberInProject;

    taskKey = `${projectShortName}-${numberInProject}`;
  }
  return taskKey;
};

export const prepareTaskExpand = expand => {
  return expand.map(modelKey => {
    switch (modelKey) {
      case COLUMN: {
        return `${COLUMN}.parent`;
      }
      default: {
        return modelKey;
      }
    }
  });
};

export enum CounterModes {
  Count = 'count',
  Hours = 'hours',
  Points = 'points'
}

export const getNextCounterMode = currentMode => {
  switch (currentMode) {
    case CounterModes.Count: {
      return CounterModes.Hours;
    }
    case CounterModes.Hours: {
      return CounterModes.Points;
    }
    case CounterModes.Points: {
      return CounterModes.Count;
    }
    default: {
      return CounterModes.Hours;
    }
  }
};

export const getMaxScoreRangeValue = type => {
  return type === ScaleType.shirt ? 5 : type === ScaleType.poker ? 9 : 100;
};

export const getVisibleTaskAxisScore = (value, type) => {
  if (!+value) {
    return '?';
  }
  switch (type) {
    case ScaleType.basic: {
      return value;
    }
    case ScaleType.poker: {
      return roundPokerValue(value);
    }
    case ScaleType.shirt: {
      return ScaleShirtValues[value] || '?';
    }
    default: {
      return value;
    }
  }
};

export const getTaskAxisSentValue = (value, type) => {
  if (!value) {
    return 0;
  }
  switch (type) {
    case ScaleType.poker: {
      return ScalePokerValues[value];
    }
    case ScaleType.shirt: {
      return value;
    }
    default: {
      return value;
    }
  }
};

export const roundPokerValue = (value: number, elseValue: number | string = '?') => {
  if (value > 69) {
    return 100;
  } else if (value > 29) {
    return 40;
  } else if (value > 16) {
    return 20;
  } else if (value > 9) {
    return 13;
  } else if (value > 6) {
    return 8;
  } else if (value > 4) {
    return 5;
  } else if (value >= 3) {
    return 3;
  } else if (value > 0) {
    return value;
  } else {
    return elseValue;
  }
};
