<a-center-popup
     [title]="'Create New Project'"
     (close)="onClosePopup()"
>
  <create-new-project-form [submit$]="submit$"
                           (isValidForm)="isValidForm = $event"
                           (closePopup)="onClosePopup()"
  ></create-new-project-form>
  <div class="import_project_link">
    <span>or </span><a [routerLink]="companySettingsRouterLink" target="_blank" rel="noopener">Import Project</a>
  </div>
  <create-new-project-popup-buttons [isValidForm]="isValidForm"
                                    (cancel)="onClosePopup()"
                                    (submit)="submit$.next()"
  ></create-new-project-popup-buttons>
</a-center-popup>
