import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
  AfterViewInit,
  OnChanges
} from '@angular/core';

import { User } from '../../../interfaces';
import { PatchQueryService } from '../../services/patch-query.service';

@Component({
  selector: 'member-list',
  templateUrl: './member-list.component.html',
  providers: [PatchQueryService],
  styleUrls: ['./member-list.scss']
})
export class MemberListComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() withNameFilter = false;
  @Input() members: User[];
  @Input() selectedMembers: User[] = new Array<User>();
  @Input() searchText = '';
  @Input() filterByMembers: User[] = new Array<User>();
  @Input() focusedUserNumber = -1;

  @Output() onSelectMemberEvent = new EventEmitter<User>();
  @Output() onDeselectMemberEvent = new EventEmitter<User>();
  @Output() selectedMembersList = new EventEmitter<User[]>();

  @ViewChild('input_search_members') inputSearchMembersRef: ElementRef;
  @ViewChild('list_container') listContainerRef: ElementRef;

  public visibleMembers: User[] = [];

  public memberItems = new Array<User>();

  constructor(private patchQueryService: PatchQueryService, private _renderer: Renderer2) {}

  ngOnInit() {
    this.clearMembersQuery();
    this.setInitItemIds(this.selectedMembers);
  }

  ngAfterViewInit() {
    if (
      this.listContainerRef.nativeElement.getClientRects()[0] &&
      this.listContainerRef.nativeElement.getClientRects()[0].bottom > window.innerHeight
    ) {
      this.listContainerRef.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }

  ngOnChanges() {
    if (this.focusedUserNumber > -1) {
      this.scrollList();
    }
    this.selectedMembers = this.selectedMembers || [];
    this.filterMembers();
    this.filterVisibleMembers();
  }

  filterMembers() {
    const membersToFilterIds = [];

    this.filterByMembers.forEach(member => membersToFilterIds.push(member.id));

    this.memberItems =
      membersToFilterIds.length > 0
        ? this.members.filter(member => membersToFilterIds.indexOf(member.id) === -1)
        : this.members;
  }

  reInitComponent() {
    this.ngOnInit();
  }

  onSelectMember(member: User) {
    if (!this.isSelectedMember(member)) {
      this.addMember(member);
    } else {
      this.removeMember(member);
    }
  }

  addMember(member: User) {
    this.selectedMembers.push(member);
    this.patchQueryService.addItemId(member.id);
    this.onSelectMemberEvent.emit(member);
    this.selectedMembersList.emit(this.selectedMembers);
  }

  removeMember(member: User) {
    this.selectedMembers.splice(this.getIndexForSelectedMember(member), 1);
    this.patchQueryService.removeItemId(member.id);
    this.onDeselectMemberEvent.emit(member);
    this.selectedMembersList.emit(this.selectedMembers);
  }

  isSelectedMember(selectedMember: User): boolean {
    return this.getIndexForSelectedMember(selectedMember) !== -1;
  }

  getIndexForSelectedMember(member: User): number {
    let memberIndex = null;

    this.selectedMembers.find((value, key) => (memberIndex = member.id === value.id ? key : memberIndex));

    return memberIndex !== null ? memberIndex : -1;
  }

  getMembersQuery(): { add: number[]; remove: number[] } {
    return this.patchQueryService.getItemIdsQuery();
  }

  noEmptyMembersQuery() {
    return this.patchQueryService.noEmpty();
  }

  clearMembersQuery() {
    this.patchQueryService.clearQuery();
  }

  setInitItemIds(items: User[]) {
    this.patchQueryService.setInitItemIds(this.selectedMembers);
  }

  removeAllMembers() {
    this.selectedMembers = new Array<User>();
    this.patchQueryService.removeAllAddedItems();
  }

  filterVisibleMembers() {
    let filteringValue;
    if (this.inputSearchMembersRef) {
      filteringValue = this.inputSearchMembersRef.nativeElement.value;
    }
    if (filteringValue) {
      this.visibleMembers = this.members.filter(
        (member: User) => member.fullname.toLowerCase().indexOf(filteringValue.toLowerCase()) > -1
      );
    } else {
      this.visibleMembers = [...this.members];
    }
  }

  scrollList(value?: number) {
    const UserItemHeight = 60;
    const ItemNumberToScroll = 3;
    if (!Number.isInteger(value)) {
      // by default scroll to actual position
      // UserItemHeight - line height of single user item inside list
      // ItemNumberToScroll - number of items to scroll after
      value = (this.focusedUserNumber - ItemNumberToScroll) * UserItemHeight;
    }
    this._renderer.setProperty(this.listContainerRef.nativeElement, 'scrollTop', value);
  }
}
