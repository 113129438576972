import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { Observable } from 'rxjs';
import { AuthService } from '../../../shared/services/app/auth.service';

import { User, WorkLog } from '../../../interfaces';

import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state/app-state';
import { getUserById } from '../../../ngrx/reducers/user.reducer';

import { LogWorkDeleteAction } from '../../../ngrx/actions/worklog.actions';
import { taskPopupCodes, TaskSidebarService } from '../../../task/shared/services/task-sidebar.service';

@Component({
  selector: 'log-work',
  templateUrl: './log-work.component.html',
  styleUrls: ['./log-work.component.scss']
})
export class LogWorkComponent implements OnInit {
  @Input() workLog: WorkLog;
  @Output() resetFocus: EventEmitter<any> = new EventEmitter();

  public isDeleteLogworkVisible = false;
  public user$: Observable<User>;
  public isLogworkPopupVisible = false;

  constructor(
    private _store: Store<AppState>,
    private _authService: AuthService,
    private _taskSidebarService: TaskSidebarService
  ) {}

  get isCurrentUser() {
    return this.workLog.user === this._authService.activeUserId;
  }

  ngOnInit(): any {
    this.user$ = <Observable<User>>this._store.pipe((getUserById(this.workLog.user)));
  }

  onToggleEditLogwork() {
    this._taskSidebarService.setState(taskPopupCodes.LOGWORK, this.workLog);
    this.isLogworkPopupVisible = !this.isLogworkPopupVisible;
  }

  onDeleteLogwork() {
    this._store.dispatch(new LogWorkDeleteAction(this.workLog));
  }

  onSwitchDeleteLogwork() {
    this.isDeleteLogworkVisible = !this.isDeleteLogworkVisible;
  }

  onChildChangedFocus(event) {
    this.resetFocus.emit(event);
  }
}
