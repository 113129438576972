<div class="projects_block">
  <div class="edit_link" (click)="projectsPopupToggle()">Edit</div>
  <div class="overlay_layer" (click)="projectsPopupToggle()"></div>
  <h4>Projects</h4>
  <right-menu-projects-popup
    *ngIf="isProjectsPopupVisible"
    [boardProjects]="boardProjects"
    [projects]="projects"
    [selectedBoard]="selectedBoard"
    [boardId]="boardId"
    (close)="onCloseProjectsPopup()"
  ></right-menu-projects-popup>
  <div class="form_additional_block">
    <div class="projects_avatars_container">
      <div *ngFor="let prj of boardProjects; let i = index" class="project_avatar">
        <p>{{prj.shortName}}</p>
      </div>
    </div>
  </div>
</div>
