import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';

@Directive({
  selector: '[a-tooltip]'
})
export class ATooltipDirective implements OnDestroy {
  constructor(private elementRef: ElementRef) {}

  tooltip: any;
  elemPosition: any;
  tooltipOffset = 8;

  overHostElement = false;
  overTooltipElement = false;

  @Input('a-tooltip') tooltipText = '';
  @Input('a-tooltip-z-index') zIndex = false;
  @Input() position = 'top';

  @HostListener('focusin')
  @HostListener('mouseenter')
  onMouseEnter() {
    this.overHostElement = true;
    this.getElemPosition();

    if (!this.tooltip) {
      this.create();
      this.setPosition();
      this.show();
    }
  }

  @HostListener('focusout')
  @HostListener('mouseleave')
  onMouseLeave() {
    this.overHostElement = false;
    this.hideIfNeeded();
  }

  hideIfNeeded() {
    // we should do this asynchronously, so that two events could be fired before trigger works out
    setTimeout(() => {
      if (!this.overHostElement && !this.overTooltipElement) {
        this.hide();
      }
    });
  }

  ngOnDestroy() {
    this.hide();
  }

  getElemPosition() {
    this.elemPosition = this.elementRef.nativeElement.getBoundingClientRect();
  }

  private create() {
    this.tooltip = document.createElement('span');
    this.tooltip.className += 'ng-tooltip ng-tooltip-' + this.position;
    this.tooltip.innerHTML = this.tooltipText;
    this.tooltip.onmouseenter = () => (this.overTooltipElement = true);
    this.tooltip.onmouseleave = () => {
      this.overTooltipElement = false;
      this.hideIfNeeded();
    };

    if (this.zIndex) {
      this.tooltip.style.zIndex = this.zIndex;
    }
    document.body.appendChild(this.tooltip);
  }

  private show() {
    this.tooltip.className += ' ng-tooltip-show';
  }

  private hide() {
    if (!this.tooltip) {
      return;
    }
    this.tooltip.classList.remove('ng-tooltip-show');
    this.tooltip.parentNode.removeChild(this.tooltip);
    this.tooltip = null;
  }

  setPosition() {
    const elemHeight = this.elementRef.nativeElement.offsetHeight;
    const elemWidth = this.elementRef.nativeElement.offsetWidth;
    const tooltipHeight = this.tooltip.clientHeight;
    const tooltipWidth = this.tooltip.offsetWidth;
    const scrollY = window.pageYOffset;

    if (this.position === 'top' || this.position === 'top-left') {
      this.tooltip.style.top = this.elemPosition.top + scrollY - (tooltipHeight + this.tooltipOffset) + 'px';
    }

    if (this.position === 'bottom' || this.position === 'bottom-right') {
      this.tooltip.style.top = this.elemPosition.top + scrollY + elemHeight + this.tooltipOffset + 'px';
    }

    if (this.position === 'top' || this.position === 'bottom') {
      this.tooltip.style.left = this.elemPosition.left + elemWidth / 2 - tooltipWidth / 2 + 'px';
    }

    if (this.position === 'left') {
      this.tooltip.style.left = this.elemPosition.left - tooltipWidth - this.tooltipOffset + 'px';
    }

    if (this.position === 'right') {
      this.tooltip.style.left = this.elemPosition.left + elemWidth + this.tooltipOffset + 'px';
    }

    if (this.position === 'bottom-right') {
      this.tooltip.style.left = this.elemPosition.left + elemWidth - this.tooltipOffset * 2 + 'px';
    }

    if (this.position === 'top-left') {
      this.tooltip.style.left = this.elemPosition.left - tooltipWidth + elemWidth / 2 + this.tooltipOffset + 'px';
    }

    if (this.position === 'left' || this.position === 'right') {
      this.tooltip.style.top = this.elemPosition.top + scrollY + elemHeight / 2 - this.tooltip.clientHeight / 2 + 'px';
    }
  }
}
