
import {map, switchMap, distinctUntilChanged} from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { AppState } from '../../ngrx/state/app-state';
import { Store } from '@ngrx/store';
import { fromUsers } from '../../ngrx/reducers/user.reducer';
import getById = fromUsers.getById;
import { User } from '../../interfaces';

@Component({
  selector: 'user-name-wrapper',
  template: `{{username$ | async}}`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserNameWrapperComponent implements OnInit {
  @Input()
  set userId(id) {
    this.id$.next(id);
  }

  public username$: Observable<string>;

  private id$ = new BehaviorSubject(0);

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this.username$ = this.id$.pipe(
      distinctUntilChanged(),
      switchMap(id => this._store.select(getById(id))),
      map((user: User) => (user ? user.fullname : '')),
      distinctUntilChanged(),);
  }
}
