/**
 * Created by User on 05.06.2017.
 */
export class TableRow {
  constructor(
    public title: string = '',
    public taskUrl: string = '',
    public logTitle: string = '',
    public taskId: number = null,
    public userId: number = null,
    public projectIndex: number = null,
    public projectId: number = null,
    public type: string = null,
    public logId = null,
    public total: number = 0,
    public tooltip: string = ''
  ) {}
}
