import { getEmptyESState, ESInterface, AppState } from '../../../../../ngrx/state/app-state';

import * as slackChannel from './slack-channel.actions';
import * as slackAlert from '../slack-alert.action';
import { SlackBoard } from '../../models/slack-boards';
import { createSelector } from 'reselect';
import { SLACK_CHANNEL_PL } from '../../../../../constants/entity';
import { SlackChannel } from '../../models/slack-channel';
import { createCommonSelectors, getById } from '../../../../../util/store/index';

const initialState = {
  ...getEmptyESState<SlackChannel>()
};

export function reducer(
  state = initialState,
  action: slackChannel.Actions | slackAlert.Actions
): ESInterface<SlackChannel> {
  switch (action.type) {
    case slackAlert.CLEAR:
    case slackAlert.SET_ACTIVE_BOARD: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const { getState, getEntities, getIds, getAll } = createCommonSelectors<SlackChannel>(SLACK_CHANNEL_PL);
