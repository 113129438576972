<button (click)="onClickAddButton()" class="add_task_btn"></button>

<div a-context-menu class="slim inbox_context" *ngIf="isMenuVisible" (click)="onToggleMenu()">
  <p a-context-menu-item (click)="onClickAddTask()">Task</p>
  <p a-context-menu-item (click)="onClickAddBoard()">Board</p>
  <p a-context-menu-item *ngIf="isSuperUser$ | async" (click)="onClickAddProject()">Project</p>
</div>

<div *ngIf="addTaskPopup"
     class="add-task-popup"
     a-context-popup
     [attr.draggable-disabled]="true"
     [title]="'Add New Task'"
     (close)="onCloseAddTaskPopup()"
>
  <global-add-task-form
    (close)="onCloseAddTaskPopup()"
  ></global-add-task-form>
</div>

<new-board-combined [openFromLeftMenu]="false" *ngIf="addBoardPopup" (close)="onCloseAddBoardPopup()"></new-board-combined>

<new-project-context-popup *ngIf="addProjectPopup" (close)="onCloseAddProjectPopup()"></new-project-context-popup>
