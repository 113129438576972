<h3 [ngClass]="{active: isAddColumn}" (click)="onToggleForms()">Add Column</h3>
<h3 [ngClass]="{active: !isAddColumn}" (click)="onToggleForms()">Add Swimlane</h3>
<div class="close" (click)="onClose()"></div>
<div class="divider"></div>
<column-edit-form
  *ngIf="isAddColumn;else addSwimlane"
  [column]="{}"
  [boardId]="boardId"
  [insertAfterColumn]="insertAfterColumn"
  (submitForm)="onClose()"
  (closePopup)="onClose()"
></column-edit-form>

<ng-template #addSwimlane>
  <swimlane-edit-from [submit$]="submit$" [boardId]="boardId" (close)="onClose()" class="onTab"></swimlane-edit-from>
  <div class="buttons-block">
    <button a-button [pending]="_formService.isPending$ | async" (click)="submit$.next()" class="btn_primary">Add Swimlane</button>
  </div>
</ng-template>
