<div class="header">
  <div class="title">{{(version$ | async).name}}</div>
</div>
<div class="content">
  <div class="inner-container">
    <div class="chart_with_info">
      <div class="chart_container">
        <h2 class="general_header">Statistics</h2>
        <burndown-chart-wrapper
          [versionId]="versionId$ | async"
          [mode]="mode"
        >
        </burndown-chart-wrapper>
      </div>
      <div class="version_info">
        <div class="version_info_inner">
          <div class="block">
            <h2>Dates</h2>
            <p><strong>Start Date</strong>: {{(version$ | async).startDate | toDate}}</p>
            <p><strong>Release Date</strong>: {{(version$ | async).releasedDate | toDate}}</p>
            <p><strong>Duration, days</strong>: {{(workingDaysDuration$ | async)}}</p>
            <p></p>
          </div>
          <div class="block">
            <h2>Tasks</h2>
            <p><strong>Tasks Completed</strong>: {{(completedTasks$ | async).length }}</p>
            <p><strong>Tasks Incomplete</strong>: {{(inCompleteTasks$ | async).length}}</p>
            <p></p>
          </div>
          <div class="block">
            <h2>
              <mode-view-picker [estimatedBy]="estimatedBy$ | async" [(mode)]="mode"></mode-view-picker>
            </h2>
            <div class="story_points" *ngIf="mode === estimationType.storyPoints">
              <p><strong>Story Points Estimated</strong>: {{(storyPointsEstimated$ | async)}}</p>
              <p><strong>Story Points Completed</strong>: {{(storyPointsCompleted$ | async)}}</p>
            </div>
            <div class="hours" *ngIf="mode === estimationType.hours">
              <p><strong>Hours Estimated</strong>: {{(hoursEstimated$ | async) }}</p>
              <p><strong>Hours Completed</strong>: {{(hoursCompleted$ | async) }}</p>
              <p><strong>Hours Logged</strong>: {{(hoursLogged$ | async) }}</p>
            </div>
            <p></p>
          </div>
        </div>
      </div>
    </div>

    <div class="tasks">
      <div class="filters">
        <input class="filter" placeholder="Find Task…" (input)="onChangeFilterValue($event.target.value)">
      </div>
      <div class="tasks_header"><h2>Completed Tasks</h2></div>
      <div class="tasks_list">
        <div class="lists_header">
          <div class="title">Tasks</div>
          <div class="members">Members</div>
        </div>
        <div *ngFor="let task of  filteredCompletedTasks$ | async" class="task"
             [routerLink]="appUrls.getUrlTask(task.task)">
          <div class="title">{{task.historyTitle}}</div>
          <div class="members">
            <user-multiple-avatars-wrapper
              *ngIf="task.usersIds.length"
              [userIds]="task.usersIds"
              [showDetails]="false"></user-multiple-avatars-wrapper>
          </div>
        </div>
      </div>
      <div class="empty_state" *ngIf="(completedTasks$ | async).length === 0">There's no completed task.</div>
      <div class="empty_state"
           *ngIf="(completedTasks$ | async).length > 0 && (filteredCompletedTasks$ | async).length === 0">There's no
        tasks matching to your request.
      </div>
      <div class="tasks_header"><h2>Incomplete Tasks</h2></div>
      <div class="tasks_list">
        <div class="lists_header">
          <div class="title">Tasks</div>
          <div class="members">Members</div>
        </div>
        <div *ngFor="let task of  filteredInCompleteTasks$ | async" class="task"
             [routerLink]="appUrls.getUrlTask(task.task)">
          <div class="title">{{task.historyTitle}}</div>
          <div class="members">
            <user-multiple-avatars-wrapper
              *ngIf="task.usersIds.length"
              [userIds]="task.usersIds"
              [showDetails]="false"></user-multiple-avatars-wrapper>
          </div>
        </div>
        <div class="empty_state" *ngIf="(inCompleteTasks$ | async).length === 0">There's no incomplete tasks.</div>
        <div class="empty_state"
             *ngIf="(inCompleteTasks$ | async).length > 0 && (filteredInCompleteTasks$ | async).length === 0">There's no
          tasks matching to your request.
        </div>
      </div>
    </div>
  </div>
</div>
