import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-arrow-filled',
  templateUrl: './icon-arrow-filled.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconArrowFilledComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
