import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'a-editable-inline',
  template: `
    <ng-content></ng-content>
    <span class="pencil" *ngIf="isEditable">
      <span (click)="pencilClick.emit($event)" class="pencil_icon"></span>
    </span>
  `,
  styleUrls: ['./a-editable-inline.component.scss']
})
export class AEditableInlineComponent {
  @Input() isEditable: boolean;

  @Output() pencilClick = new EventEmitter<any>();

  @HostBinding('attr.editable')
  get canEdit() {
    return this.isEditable;
  }
}
