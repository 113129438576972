import { label } from '../functions/util';
import { Action } from './unsafe-action';
import { Label, PartOfEntity } from '../../interfaces';

export const LabelsActionTypes = {
  LOAD: label('[labels] Load'),
  ADD: label('[labels] add'),
  ADD_COMPLETE: label('[labels] add complite'),
  UPDATE: label('[labels] update'),
  DELETE: label('[labels] delete')
};

export class LabelsLoadAction implements Action {
  type = LabelsActionTypes.LOAD;
  // required BoardID as payload
  constructor(public payload: number) {}
}

export class LabelsCreateAction implements Action {
  type = LabelsActionTypes.ADD;

  constructor(public payload: {}) {}
}

export class LabelsAddCompleteAction implements Action {
  type = LabelsActionTypes.ADD_COMPLETE;

  constructor(public payload: Label) {}
}

export class LabelsUpdateAction implements Action {
  type = LabelsActionTypes.UPDATE;

  constructor(public payload: PartOfEntity) {}
}

export class LabelsDeleteAction implements Action {
  type = LabelsActionTypes.DELETE;

  constructor(public payload: Label) {}
}
