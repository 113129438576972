import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionsService } from './permissions.service';
import { PermissionDeniedComponent } from './components/permission-denied.component';
import { SuperUserGuard } from './guards/super-user-guard.service';
import { AtlazGuiModule } from '../atlaz-gui/atlaz-gui.module';
import { ProjectAdminGuard } from './guards/project-admin-guard.service';
import { ProjectMemberGuard } from './guards/project-member-guard.service';

@NgModule({
  imports: [CommonModule, AtlazGuiModule],
  exports: [PermissionDeniedComponent],
  declarations: [PermissionDeniedComponent],
  providers: []
})
export class PermissionsModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: PermissionsModule,
      providers: [PermissionsService, SuperUserGuard, ProjectAdminGuard, ProjectMemberGuard]
    };
  }
}
