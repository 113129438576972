
import {take, filter} from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { encodeEntities, isPresent, plainTextToHtml } from '../../../helpers';
import { HotKey, HotSpaceAndEnter } from '../../shared/directives';
import { CustomValidators } from '../../shared/validators/custom-validators';
import { isClickLink } from '../../../helpers/links';
import { TASK_DESCRIPTION_INPUT, TaskUnsavedDataService } from '../shared/services/task-unsaved-data.service';
import { Task } from '../../interfaces';
import { enableMarkdown } from '../markdown-toolbar/config';
import { setFocusToFocusableParent } from '../../../helpers/event';

@Component({
  selector: 'description-form',
  templateUrl: './task-description.component.html',
  styleUrls: ['./task-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskDescriptionComponent implements OnInit, OnDestroy {
  @Input() selectedTask: Task;
  @Input() editPermissions = false;

  @Output() editDescription = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @ViewChild('description') descriptionTextArea: ElementRef;

  public description: string;
  public isMarkdownGuideVisible = false;
  public taskDescriptionForm: FormGroup;
  public isUnsavedDescription = false;
  public isDescriptionFormPopup$ = new BehaviorSubject(false);
  public enableMarkdown = enableMarkdown;

  public subs = [];

  constructor(private _fb: FormBuilder, private _taskUnsavedDataService: TaskUnsavedDataService) {}

  get submitHotkeys(): HotKey[] | boolean {
    return HotSpaceAndEnter;
  }

  get isEmptyDescription() {
    return !this.selectedTask.description || this.selectedTask.description.trim().length < 1;
  }

  ngOnInit() {
    const sub = this.isDescriptionFormPopup$.pipe(filter(isPresent)).subscribe(_ => {
      this.initUnsavedInputField();
      this.initDescriptionForm();
    });
    this.subs.push(sub);
    this.initUnsavedInputField();
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  initUnsavedInputField() {
    this._taskUnsavedDataService.taskUnsavedInputFields[TASK_DESCRIPTION_INPUT].pipe(take(1)).subscribe(value => {
      this.description = value ? value : this.selectedTask.description !== null ? this.selectedTask.description : '';
      this.isUnsavedDescription = !!value;
    });
  }

  initDescriptionForm() {
    this.description = this.description !== null ? this.description : '';
    this.taskDescriptionForm = this._fb.group({
      description: [
        plainTextToHtml(this.description),
        CustomValidators.composeOr([Validators.maxLength(0), Validators.maxLength(32768)])
      ]
    });

    const sub = this.taskDescriptionForm.valueChanges.subscribe(value =>
      this._taskUnsavedDataService.taskUnsavedInputFields[TASK_DESCRIPTION_INPUT].next(value.description)
    );

    this.subs.push(sub);
  }

  clearUnsavedDescriptionInputField() {
    this._taskUnsavedDataService.clearInput(TASK_DESCRIPTION_INPUT);
    this.initUnsavedInputField();
  }

  onAddDescription() {
    if (this.taskDescriptionForm.valid) {
      this.editDescription.emit(
        Object.assign(
          {
            id: this.selectedTask.id
          },
          this.taskDescriptionForm.value
        )
      );
    }
    this.clearAndCloseForm();
  }

  onOpenDescriptionFormPopup(event) {
    if (this.editPermissions && !isClickLink(event)) {
      this.isDescriptionFormPopup$.next(!this.isDescriptionFormPopup$.getValue());
    }
  }

  onCloseDescriptionFormPopup() {
    setFocusToFocusableParent(this.descriptionTextArea.nativeElement);
    this.isDescriptionFormPopup$.next(!this.isDescriptionFormPopup$.getValue());
    if (
      !this.isDescriptionFormPopup$.getValue() &&
      this.taskDescriptionForm.value.description !== plainTextToHtml(this.selectedTask.description)
    ) {
      this._taskUnsavedDataService.taskUnsavedInputFields[TASK_DESCRIPTION_INPUT].next(
        encodeEntities(this.taskDescriptionForm.value.description)
      );
      this.initUnsavedInputField();
    } else {
      this.isUnsavedDescription = false;
    }
  }

  onCancelDescriptionForm() {
    this.clearAndCloseForm();
    this.cancel.emit({});
  }

  onDiscardUnsavedDescription() {
    this.clearUnsavedDescriptionInputField();
  }

  onToggleMarkdownHelp() {
    this.isMarkdownGuideVisible = !this.isMarkdownGuideVisible;
  }

  public clearAndCloseForm() {
    this.isDescriptionFormPopup$.next(false);
    setFocusToFocusableParent(this.descriptionTextArea.nativeElement);
    this.clearUnsavedDescriptionInputField();
  }

  onDescriptionChange(event) {
    this.taskDescriptionForm.patchValue({ description: event }, { emitEvent: true });
  }
}
