import { NgModule } from '@angular/core';
import { InviteLinkPopupComponent } from './invite-link-popup.component';
import { InviteLinkService } from './services/invite-link.service';
import { CommonModule } from '@angular/common';
import { AtlazGuiModule } from '../atlaz-gui/atlaz-gui.module';
import { InviteLinkFormComponent } from './invite-link-form/invite-link-form.component';
import { CreateInviteLinkComponent } from './create-invite-link/create-invite-link.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, RouterModule, AtlazGuiModule],
  declarations: [InviteLinkPopupComponent, InviteLinkFormComponent, CreateInviteLinkComponent],
  exports: [InviteLinkFormComponent, InviteLinkPopupComponent],
  providers: [InviteLinkService]
})
export class InviteLinkPopupModule {}
