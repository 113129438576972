import { combineLatest, from as observableFrom, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators/distinctUntilChanged';
import { take } from 'rxjs/operators/take';
import { filter } from 'rxjs/operators/filter';
import { map } from 'rxjs/operators/map';
import { switchMapTo } from 'rxjs/operators/switchMapTo';
import { switchMap } from 'rxjs/operators/switchMap';
import { refCount } from 'rxjs/operators/refCount';
import { publishReplay } from 'rxjs/operators/publishReplay';
import { pluck } from 'rxjs/operators/pluck';
import { tap } from 'rxjs/operators/tap';
import { mergeMap } from 'rxjs/operators/mergeMap';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  Board,
  Collection,
  Group,
  PartOfEntity,
  Project,
  QuickFilter,
  SprintBoard,
  User,
  Version
} from '../interfaces';

import { AppState, COMPONENT, RIGHT_MENU } from '../ngrx/state';
import { Store } from '@ngrx/store';
import {
  QuickFilterAddAction,
  QuickFilterDeleteAction,
  QuickFilterEditAction
} from '../ngrx/actions/quick-filter.actions';
import { BoardAssignUsersAction, BoardEditAction } from '../ngrx/actions/board.actions';
import { filterBots, getAllUsers, getBoardUsers } from '../ngrx/reducers/user.reducer';
import { getAllGroups, getBoardGroups } from '../ngrx/reducers/group.reducer';
import { RouterNavigateService } from '../shared/services/router-navigate.service';
import { getBoardQuickFilters } from '../ngrx/reducers/quick-filter.reducer';
import { getSelectedBoard, getSelectedBoardId } from '../ngrx/reducers/board.reducer';
import { getAllProjectsActive, getBoardProjects } from '../ngrx/reducers/project.reducer';
import { ComponentSetVisibleAction } from '../ngrx/actions/component.actions';
import { BOARD_PL, boardType, VERSION_PL } from '../constants';
import { AppUrls } from '../app-urls';

import { both, filter as ramdaFilter, pluck as ramdaPluck } from 'ramda';
import { versionMapper } from '../ngrx/reducers/version.reducer';
import { compareArrays, createFilterByPart, isEqualType, isPresent, naturalSort } from '../../helpers';
import { PermissionsService } from '../permissions/permissions.service';
import { AuthService } from '../shared/services/app/auth.service';
import { HandleResponseAction, PatchEntityAction } from '../ngrx/actions/root.action';
import { getAllCollections } from '../ngrx/reducers/collection.reducer';
import { CompanyService } from '../shared/services/app/company.service';
import { Features } from '../libs/paywall/features.constants';
import { PaywallService } from '../libs/paywall/paywall.service';
import { MarkAsLoaded, MarkAsUnloaded } from '../loaded-data/store/loaded-data.actions';
import { isNotLoaded, PROJECT_VERSIONS } from '../loaded-data/store/loaded-data.reducer';
import { AtlazApiV2Service } from '../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { getSelectedBoardCollections } from '../ngrx/functions/crossed.selector';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { of } from 'rxjs/observable/of';
import { CanDeactivateBoard } from '../shared/services/guards/can-deactivate-board.service';

const ACTIVE = 'active';
const INACTIVE = 'inactive';

@Component({
  selector: 'right-menu',
  templateUrl: './right-menu.component.html',
  styleUrls: [
    './right-menu.component.scss',
    '../../assets/scss/rmenu-member-popup.scss',
    '../../assets/scss/rmenu-project-popup.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('openMenuState', [
      state(
        INACTIVE,
        style({
          width: '0'
        })
      ),
      state(
        ACTIVE,
        style({
          width: '100%'
        })
      ),
      transition('* => ' + ACTIVE, animate('100ms linear')),
      transition(ACTIVE + '=> ' + INACTIVE, animate('100ms linear'))
    ])
  ]
})
export class RightMenuComponent implements OnInit, OnDestroy {
  public boardUsers$: Observable<User[]>;
  public selectedBoard$: Observable<Board> | Observable<SprintBoard>;
  public boardGroups$: Observable<Group[]>;
  public groups$: Observable<Group[]>;
  public allUsers$: Observable<User[]>;
  public users$: Observable<User[]>;
  public quickFilters$: Observable<QuickFilter[]>;
  public projects$: Observable<Project[]>;
  public collections$: Observable<Collection[]>;
  public boardProjects$: Observable<Project[]>;
  public boardCollections$: Observable<Collection[]>;
  public versions$: Observable<Version[]>;
  public showSprintMenu$: Observable<boolean>;
  public isRoadmapBoard$: Observable<boolean>;
  public isSprintBoard$: Observable<boolean>;

  public boardId = 0;
  public appUrls = AppUrls;
  public selectedFilter = null;
  public selectedBoard: Board = null;
  public state: string = INACTIVE;
  public boardTypes = boardType;

  public isMenuVisible$: Observable<boolean>;
  public isShowActivities: boolean;
  public isSprintBoundariesSubMenuVisible = false;
  public isFiltersRightSubMenuVisible = false;
  public isLabelsRightSubMenuVisible = false;
  public isReleasedRightSubMenuVisible = false;
  public isArchivedRightSubMenuVisible = false;
  public isExportRightSubMenuVisible = false;
  public isCalendarSyncPopupVisible = false;
  public isPDFPopupVisible = false;
  public isQuickFilterMenuVisible = false;
  public isCloseBoardVisible = false;
  public isCreateSwimlaneVisible = false;
  public isDeleteQfilterVisible: number;
  public showAdvancedScoringSettings$ = new BehaviorSubject(false);

  public superUserPermissions$: Observable<boolean>;
  public memberProjectAdminPermissions$: Observable<boolean>;
  public canCloseBoard$: Observable<boolean>;
  public isUserSubscribedOnBoard$;

  public subs: Subscription[] = [];
  public exportCSVUrl: string;

  set subsCollector(sub) {
    this.subs.push(sub);
  }

  constructor(
    private _store: Store<AppState>,
    private _canDeactivateBoard: CanDeactivateBoard,
    private _atlazApi: AtlazApiV2Service,
    private _authService: AuthService,
    private _routerNav: RouterNavigateService,
    private _permissions: PermissionsService,
    private _companyService: CompanyService,
    private _paywall: PaywallService
  ) {}

  ngOnInit(): any {
    this.selectedBoard$ = <Observable<Board>>this._store.pipe(getSelectedBoard);
    this.groups$ = <Observable<Group[]>>this._store.pipe(getAllGroups);
    this.projects$ = <Observable<Project[]>>this._store.pipe(getAllProjectsActive);
    this.collections$ = <Observable<Collection[]>>this._store.pipe(getAllCollections);
    this.boardProjects$ = <Observable<Project[]>>this._store.pipe(getBoardProjects);
    this.boardCollections$ = <Observable<Collection[]>>this._store.select(getSelectedBoardCollections);
    this.allUsers$ = <Observable<User[]>>this._store.pipe(getAllUsers);

    const selectUsersIds = projects =>
      projects.reduce((userIds, project) => {
        return !!project && !!project.usersIds ? [...userIds, ...project.usersIds] : userIds;
      }, []);
    const userIdsInProjects$ = this.boardProjects$.pipe(map(selectUsersIds));
    const notDeleted = user => !user.deleted;
    const includeUser = userIds => user => (userIds.length > 0 ? userIds.includes(user.id) : user);

    this.users$ = combineLatest(this.allUsers$, userIdsInProjects$).pipe(
      map(([users, userIds]) => {
        const projectIncludesUser = includeUser(userIds);
        const userFilter = both(projectIncludesUser, notDeleted);
        return users.filter(userFilter);
      }),
      map(filterBots),
      map(naturalSort('fullname'))
    );

    this.boardUsers$ = <Observable<User[]>>this._store.pipe(getBoardUsers, map(naturalSort('fullname')));
    this.isRoadmapBoard$ = this.selectedBoard$.pipe(map(isEqualType(boardType.roadmap)));
    this.isSprintBoard$ = this.selectedBoard$.pipe(map(isEqualType(boardType.sprint)));
    this.showSprintMenu$ = this.selectedBoard$.pipe(
      map(
        (board: SprintBoard) =>
          board && board.type === boardType.sprint && board.sprintStartDate && !board.sprintReleased
      )
    );

    this.boardGroups$ = this._store.pipe(getBoardGroups);
    this.quickFilters$ = <Observable<QuickFilter[]>>this._store.pipe(
      getBoardQuickFilters,
      publishReplay(1),
      refCount()
    );
    this.isUserSubscribedOnBoard$ = combineLatest(this.selectedBoard$, this._authService.activeUserId$).pipe(
      map(([board, userId]: [Board, number]) => board && board.subscribersIds && board.subscribersIds.includes(userId)),
      distinctUntilChanged()
    );

    this.isMenuVisible$ = this._store.pipe(
      pluck(COMPONENT, RIGHT_MENU),
      distinctUntilChanged(),
      map((new_state: { visible: boolean }) => {
        this.state = new_state.visible ? ACTIVE : INACTIVE;
        return new_state.visible;
      })
    );

    const includes = list => item => list.includes(item.project);
    const boardProjectIds$ = <Observable<number[]>>this.boardProjects$.pipe(
      map(ramdaPluck('id')),
      publishReplay(1),
      refCount()
    );
    const notReleased = createFilterByPart({ released: 0 });

    this.versions$ = <Observable<Version[]>>boardProjectIds$.pipe(
      map(projectIds => ramdaFilter(includes(projectIds))),
      switchMap(filterFn => this._store.pipe(versionMapper(filterFn))),
      map(notReleased)
    );

    this.subsCollector = this.loadVersions(boardProjectIds$);

    this.subsCollector = this._store.pipe(getSelectedBoardId).subscribe(boardId => {
      this.boardId = boardId;
      this.exportCSVUrl = this._companyService.getPortalUrl() + '/export/board/' + boardId;
    });

    this.superUserPermissions$ = this._permissions.isSuperUser$;
    this.memberProjectAdminPermissions$ = this._permissions.memberProjectAdminPermissions(this.selectedBoard);
    this.canCloseBoard$ = this._permissions
      .canCloseBoard(this.selectedBoard)
      .pipe(switchMapTo(this.selectedBoard$), map(board => board && !board.sprintStartDate));
    this.subsCollector = this.selectedBoard$
      .pipe(filter(isPresent), pluck('id'), distinctUntilChanged())
      .subscribe(s => this.hideAdvancedScoringSettings());
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
    this.hideAdvancedScoringSettings();
  }
  showAdvancedScoringSettings(board) {
    this.showAdvancedScoringSettings$.next(true);
    this._canDeactivateBoard.registerConfirmFn(this.confirmationToHideAdvancedScoringSettings);
  }

  hideAdvancedScoringSettings() {
    this.showAdvancedScoringSettings$.next(false);
    this._canDeactivateBoard.unRegisterConfirmFn(this.confirmationToHideAdvancedScoringSettings);
  }

  public updateConfirmationFn(fn: () => Observable<boolean>) {
    this._canDeactivateBoard.unRegisterConfirmFn(this.confirmationToHideAdvancedScoringSettings);
    this.confirmationToHideAdvancedScoringSettings = fn;
    this._canDeactivateBoard.registerConfirmFn(this.confirmationToHideAdvancedScoringSettings);
  }

  // default value
  public confirmationToHideAdvancedScoringSettings = () => {
    return of(true);
  };

  onToggleDeleteQfilterPopup(quickFilterId: number) {
    this.isDeleteQfilterVisible = this.isDeleteQfilterVisible === quickFilterId ? null : quickFilterId;
  }
  onDeleteQfilter(quickFilterId: number) {
    this._store.dispatch(new QuickFilterDeleteAction(quickFilterId));
  }

  onRemoveMember(member: User) {
    this._store.dispatch(
      new BoardAssignUsersAction({
        id: this.boardId,
        users: { remove: [member.id] }
      })
    );
  }

  onCloseBoard() {
    this._store.dispatch(new BoardEditAction({ id: this.boardId, closed: 1 }));
    this.onToBoard();
  }

  onHideCloseBoardPopup() {
    this.isCloseBoardVisible = false;
  }

  goToStopSprint() {
    this.onCloseRightMenu();
    return this._routerNav.navigate(AppUrls.getUrlStopSprint(this.boardId));
  }
  goToReleaseSprint() {
    this.onCloseRightMenu();
    return this._routerNav.navigate(AppUrls.getUrlReleaseSprint(this.boardId));
  }

  onToBoard() {
    this.isCloseBoardVisible = false;
    this.onCloseRightMenu();
    return this._routerNav.navigate(AppUrls.getUrlBoard(this.boardId));
  }

  onSubscribe() {
    this.isUserSubscribedOnBoard$.pipe(take(1)).subscribe(subscribed => {
      this._store.dispatch(
        new PatchEntityAction({
          entityName: BOARD_PL,
          data: {
            id: this.boardId,
            subscribers: {
              add: subscribed ? [] : [this._authService.activeUserId],
              remove: subscribed ? [this._authService.activeUserId] : []
            }
          }
        })
      );
    });
  }

  onCloseRightMenu() {
    this.isReleasedRightSubMenuVisible = false;
    this.isSprintBoundariesSubMenuVisible = false;
    this.isFiltersRightSubMenuVisible = false;
    this.isLabelsRightSubMenuVisible = false;
    this.isArchivedRightSubMenuVisible = false;
    this._store.dispatch(new ComponentSetVisibleAction({ name: RIGHT_MENU, visible: false }));
  }

  onAddQFilter(quickFilter: PartOfEntity) {
    const data = Object.assign({}, quickFilter, { board: this.boardId });
    this._store.dispatch(new QuickFilterAddAction(data));
    this.onCloseQuickFilterForm();
  }

  onReplaceQFilter(quickFilter: PartOfEntity) {
    const data = Object.assign({}, quickFilter, { board: this.boardId });
    this._store.dispatch(new QuickFilterEditAction(data));
    this.onCloseQuickFilterForm();
  }

  onOpenQuickFilterForm(quickFilter: QuickFilter = null) {
    this.selectedFilter = quickFilter;
    this.isQuickFilterMenuVisible = true;
  }

  onCloseQuickFilterForm() {
    this.selectedFilter = null;
    // this.isFiltersRightSubMenuVisible = false;
    this.isQuickFilterMenuVisible = false;
  }

  onCreateSwimlaneToggle() {
    this.isCreateSwimlaneVisible = !this.isCreateSwimlaneVisible;
    return false;
  }

  onTogglePDFExport() {
    this.isPDFPopupVisible = !this.isPDFPopupVisible;
  }

  onToggleSyncCalendar() {
    if (!this.isCalendarSyncPopupVisible && !this._paywall.isFeatureEnabled(Features.ICalendar)) {
      this._paywall.showPayWall(Features.ICalendar);
      return;
    }
    this.isCalendarSyncPopupVisible = !this.isCalendarSyncPopupVisible;
  }

  public loadVersions(projectIds$: Observable<number[]>): Subscription {
    return projectIds$
      .pipe(
        filter(isPresent),
        distinctUntilChanged(compareArrays),
        switchMap(projectIds => observableFrom(projectIds)),
        mergeMap((projectId: number) =>
          this._store.select(isNotLoaded(PROJECT_VERSIONS, projectId)).pipe(
            take(1),
            filter(isPresent),
            switchMap(() => this._atlazApi.get(VERSION_PL, { project: projectId })),
            tap(
              data => {
                this._store.dispatch(new HandleResponseAction(data));
                this._store.dispatch(new MarkAsLoaded({ name: PROJECT_VERSIONS, id: projectId }));
              },
              err => {
                this._store.dispatch(new MarkAsUnloaded({ name: PROJECT_VERSIONS, id: projectId }));
                console.warn(`TaskDetails: failed to ProjectVersions [${projectId}]`, err);
              }
            )
          )
        )
      )
      .subscribe(() => null, () => null);
  }
}
