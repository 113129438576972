import { fromEvent as observableFromEvent, Observable, Subject, Subscription } from 'rxjs';

import { auditTime, distinctUntilChanged, filter, map, merge, startWith, switchMap, take } from 'rxjs/operators';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { scrollbarWidth } from '../../../../helpers/scroll';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state/';
import { isNotPresent, isPresent, onInsertToDom, trackById } from '../../../../helpers/';
import * as roadmap from '../store/roadmap-board.action';
import {
  RoadmapBoardOpenAddFormAction,
  RoadmapBoardTaskSearchChangedAction,
  RoadMapCloseAction,
  RoadMapOpenAction,
  SetChartMinWidth
} from '../store/roadmap-board.action';
import { RoadMapAddFormTypes, RoadMapScale, ScrollDirection } from '../constants/roadmap.constants';
import { ResizeTaskService } from '../services/resize-task.service';
import { MoveTaskService } from '../services/move-task.service';
import { HiddenItems } from '../interfaces/roadmap.interface';
import { SyncRoadmapGuiStateService } from '../services/sync-roadmap-gui-state-service';
import { RoadmapScrollService } from '../services/roadmap-scroll.service';
import { SegmentService } from '../../../atlaz-bnp/services/intergations/segment/segment.service';
import { RoadmapCells, RoadmapCellsHeaders } from '../chart/interfaces/chart.interfaces';
import { fromRoadmapBoard } from '../store/roadmap-board.reducer';
import * as fromLoadedData from '../../../loaded-data/store/loaded-data.reducer';
import { fromGuiState } from '../../../ngrx/reducers/gui-state-memorized.reducer';
import { RoadmapBoardClosesAddFormAction } from '../store/roadmap-board.action';

export enum FOOTER_FORM_IDS {
  footerTaskPushForm = 'footerTaskPushForm',
  footerGroupPushForm = 'footerGroupPushForm',
  footerTaskLinkPushForm = 'footerTaskLinkPushForm'
}

const rootParent = 0;

@Component({
  selector: 'roadmap-board-detail',
  templateUrl: './roadmap-board-detail.component.html',
  styleUrls: ['./roadmap-board-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoadmapBoardDetailComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit, AfterViewChecked {
  @Input() boardId: number;
  @Input() isNotGuest: boolean;
  @Input() forPrint: boolean;

  @ViewChild('timesheetContainer') timesheetContainerRef: ElementRef;
  @ViewChild('timesheetOverlay') timesheetOverlayRef: ElementRef;
  @ViewChild('scrollablecontent') scrollablecontent: ElementRef;
  @ViewChild('scrollableBodyInner') scrollableBodyInner: ElementRef;

  // this is used in html
  public showResizeOverlay$ = this._resizeService.showResizeOverlay$;
  public showMoveOverlay$ = this._moveService.showMoveOverlay$;
  public overlayVisible$ = this.showResizeOverlay$.pipe(merge(this.showMoveOverlay$));
  public mouseMove$: Observable<MouseEvent>;
  public mouseMoveOverChart$: Observable<MouseEvent>;
  public scrollbarWidth = scrollbarWidth;
  public minWidth;
  public FOOTER_FORM_IDS = FOOTER_FORM_IDS;

  public isHoverFakeDayItems$ = this._syncRoadmapGuiStateService.hoverItem$.pipe(
    map(hoverItem => hoverItem.id === 0 && hoverItem.type === 'push')
  );

  public readonly addFormParams$ = this._syncRoadmapGuiStateService.addFormParams(rootParent);
  public readonly addFormVisible$ = this.addFormParams$;
  public readonly createNew$ = this._syncRoadmapGuiStateService.addFormParams(rootParent, true);

  public readonly addFormHidden$ = this.addFormParams$.pipe(map(isNotPresent));

  public RoadMapAddFormTypes = RoadMapAddFormTypes;

  public cells$: Observable<RoadmapCells[]>;
  public cellsHeaders$: Observable<RoadmapCellsHeaders[]>;
  public items$;
  public hiddenItems$: Observable<HiddenItems>;

  public trackById = trackById;

  public itemPositions$;
  public editingTaskPercentId: number;
  public openSearch$ = new Subject();

  constructor(
    private _cd: ChangeDetectorRef,
    private _store: Store<AppState>,
    private _zone: NgZone,
    private _resizeService: ResizeTaskService,
    private _moveService: MoveTaskService,
    private _scrollService: RoadmapScrollService,
    private _syncRoadmapGuiStateService: SyncRoadmapGuiStateService,
    private _segment: SegmentService
  ) {}

  subs: Subscription[] = [];

  ngOnChanges(changes: SimpleChanges) {
    // init proper data handling from store
    // if you what get any roadmap data - use roadmap$ decorator
    if (
      changes['boardId'] &&
      (changes['boardId'].firstChange || changes['boardId'].currentValue !== changes['boardId'].previousValue)
    ) {
      this._store.dispatch(new RoadMapOpenAction({ id: this.boardId, forPrint: true }));
      this._store
        .select(fromGuiState.getRoadMapZoomMode(this.boardId))
        .pipe(take(1))
        .subscribe(scale => {
          if (
            [RoadMapScale.Day, RoadMapScale.Week, RoadMapScale.Month, RoadMapScale.Quarter, RoadMapScale.Year].includes(
              scale
            )
          ) {
            this._store.dispatch(new roadmap.SetScale(scale));
          }
        });
    }
  }

  ngAfterViewChecked() {
    this._syncRoadmapGuiStateService.roadmapUpdated$.next();
  }

  ngAfterViewInit() {
    this._zone.runOutsideAngular(() => {
      this.mouseMove$ = observableFromEvent(this.timesheetOverlayRef.nativeElement, 'mousemove');
    });
    this._resizeService.registerMouseMoveSubj(this.mouseMove$);
    this._moveService.registerMouseMoveSubj(this.mouseMove$);
    this._scrollService.registerTimeSheetContainer(this.scrollablecontent.nativeElement);
    this._syncRoadmapGuiStateService.registerChartElement(this.scrollablecontent.nativeElement);

    // waiting while tasks are loaded
    this.subs.push(
      this._store
        .select(fromRoadmapBoard.getId)
        .pipe(
          distinctUntilChanged(),
          switchMap(boardId =>
            this._store
              .select(fromLoadedData.wasOpenedBoardFn)
              .pipe(map(isLoaded => isLoaded(boardId)), filter(isPresent), take(1))
          )
        )
        .subscribe(() =>
          onInsertToDom(this.timesheetContainerRef.nativeElement, () => this._scrollService.scrollTimeSheetToToday())
        )
    );
    // to extend chart if there are a lot of task range on wide screen size
    this.subs.push(
      observableFromEvent(window, 'resize')
        .pipe(
          startWith(0),
          auditTime(200),
          map(() => {
            if (this.timesheetContainerRef && this.timesheetContainerRef.nativeElement) {
              return this.timesheetContainerRef.nativeElement.clientWidth;
            }
            return 0;
          }),
          filter(isPresent)
        )
        .subscribe((width: number) => {
          console.warn('SetChartMinWidth', width);
          this._store.dispatch(new SetChartMinWidth(width));
        })
    );
  }

  ngOnInit() {
    this.cells$ = this._store.select(fromRoadmapBoard.getChartCells);
    this.cellsHeaders$ = this._store.select(fromRoadmapBoard.getChartCellsHeaders);
    this.items$ = this._store.select(fromRoadmapBoard.getItemsTree);
    this.hiddenItems$ = this._store.select(fromRoadmapBoard.getHiddenItemsMap);
    this.itemPositions$ = this._store.select(fromRoadmapBoard.getChartItemsPositionsMap);

    this.subs.push(
      this.overlayVisible$
        .pipe(filter(isPresent))
        .subscribe(() => this._scrollService.starScrollOnMove(ScrollDirection.horizontal))
    );

    this.subs.push(this._store.select(fromRoadmapBoard.getChartWidth).subscribe(left => (this.minWidth = left)));

    // TODO: make this safe
    document.body.classList.add(this.forPrint ? 'has-scroll' : 'no-scroll');
    this.subs.push(
      observableFromEvent(window, 'resize').subscribe(() => {
        // TODO : check if scroll is visible
        this._cd.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    this._syncRoadmapGuiStateService.unRegisterChartElement();
    // TODO: make this safe
    if (!this.forPrint) {
      document.body.classList.remove(this.forPrint ? 'has-scroll' : 'no-scroll');
    }
    this._store.dispatch(new RoadMapCloseAction());
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  onOpenPushForm(type) {
    this._store.dispatch(
      new RoadmapBoardOpenAddFormAction({
        parent: rootParent,
        type
      })
    );
    setTimeout(() => {
      this.scrollablecontent.nativeElement.scrollTop = this.scrollablecontent.nativeElement.scrollHeight;
      this.scrollableBodyInner.nativeElement.scrollTop = this.scrollableBodyInner.nativeElement.scrollHeight;
    }, 100);
  }

  onAddNew(type, insertAfter = 0) {
    if (!this.isNotGuest) {
      return;
    }
    this._store.dispatch(
      new RoadmapBoardOpenAddFormAction({
        parent: 0,
        insertAfter,
        type
      })
    );
  }

  onAddFormClickOut($event) {
    this._syncRoadmapGuiStateService.handleAddFormMissClick($event);
  }

  onMouseEnterFooterContent() {
    this._syncRoadmapGuiStateService.hoverItem$.next({ id: 0, type: 'push' });
  }

  onMouseLeaveFooterContent() {
    this._syncRoadmapGuiStateService.clearHoverItem();
  }

  onSearchStringChanged(searchString: string) {
    this._store.dispatch(new RoadmapBoardTaskSearchChangedAction(searchString));
  }

  onOpenSearch() {
    this._segment.track('RoadmapSearch');
  }

  setEditingTaskPercentId(value) {
    if (!this.isNotGuest) {
      return;
    }
    this.editingTaskPercentId = value;
    this._cd.detectChanges();
  }

  onPopupFormClose() {
    this._store.dispatch(new RoadmapBoardClosesAddFormAction());
  }

  onTitleClick() {
    this.openSearch$.next(true);
  }
}
