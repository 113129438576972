import { validateIntegerFactory } from './integer-validator';
import { ValidatorFn, AbstractControl, Validators } from '@angular/forms';
import { validatePositiveFactory } from './positive-validator';
import { validateEmptyFactory } from './empty-validator';
import { validateZeroFactory } from './zero-validator';
import { validateDateFactory } from './date-validator';
import { validateTimeFactory } from './time-validator';
import { includesValidator } from './includes-validator';
import { validateEmailFactory } from './email-validator';
import { validateArrayHasTruthyEntitiesFactory } from './array-has-truthy-entities-validator';
export * from './regexp-validator';
export * from './email-validator';
export * from './pattern-validator';

function composeOr(validators: ValidatorFn[]): ValidatorFn {
  return function(control: AbstractControl) {
    let error = null;
    validators.filter(x => !!x).some((validator: ValidatorFn) => {
      return !(error = validator(control));
    });
    return error;
  };
}

export const CustomValidators = {
  composeOr: composeOr,
  composeAnd: Validators.compose,
  includes: includesValidator,
  date: validateDateFactory,
  time: validateTimeFactory(),
  empty: validateEmptyFactory(),
  email: validateEmailFactory(),
  integer: validateIntegerFactory(),
  positive: validatePositiveFactory(),
  zero: validateZeroFactory(),
  arrayNotEmpty: validateArrayHasTruthyEntitiesFactory()
};
