
import {fromEvent as observableFromEvent,  BehaviorSubject ,  Subscription ,  Observable } from 'rxjs';

import {tap, throttleTime, filter} from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'file-drag-and-drop',
  template: `
    <overlay *ngIf="isVisible$ | async" tabindex="-1">
      <p>Drop Files to Upload</p>
    </overlay>
  `,
  styles: [
    `
    overlay {
      z-index: 9999;
      border: 5px solid rgba(144, 206, 154, 0.77);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      font-weight: bold;
      font-size: 36pt;
      color: #e4e4da;
    }
  `
  ]
})
export class FileDragAndDropComponent implements OnInit, OnDestroy {
  @Output() filesForUpload = new EventEmitter<FileList>();

  public isVisible$ = new BehaviorSubject(false);

  public subs: Subscription[] = [];

  ngOnInit(): any {
    const dragleave$ = observableFromEvent(document.body, 'dragleave').pipe(
      throttleTime(50),
      filter(() => this.isVisible$.getValue()),);

    const dragover$ = observableFromEvent(document.body, 'dragover').pipe(
      filter(
        (event: DragEvent) =>
          event.dataTransfer && event.dataTransfer.types && event.dataTransfer.types.includes('Files')
      ),
      tap((e: DragEvent) => e.preventDefault()),);

    const drop$ = observableFromEvent(document.body, 'drop').pipe(filter(() => this.isVisible$.getValue()));

    this.subs.push(
      dragleave$.subscribe((event: DragEvent) => {
        this.isVisible$.next(false);
        event.preventDefault();
      })
    );

    this.subs.push(
      dragover$.subscribe((event: DragEvent) => {
        this.isVisible$.next(true);
      })
    );

    this.subs.push(
      drop$.subscribe((event: DragEvent) => {
        console.log(event, 'drop');
        event.preventDefault();
        this.filesForUpload.emit(event.dataTransfer.files);
        this.isVisible$.next(false);
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
