<form [formGroup]="checkboxForm"
      [clickOut]="true"
      (clickOutEvent)="onCancel(!checkbox && !checkboxForm.get('name').value)"
>
  <textarea
    [focus]="focus"
    autosize
    [checkSize$]="checkSize$"
    [hotKeys]="postHotKeys"
    (hotKey)="onSubmit()"
    type="text"
    [formControl]="checkboxForm.get('name')"
    #name
    rows="1"
  >
  </textarea>
    <button type="submit" a-button class="btn_primary" (click)="onSubmit()">Save</button>
    <button type="button" a-button ngClass="btn_text" (click)="onCancel(true)" class="cancel_btn">Cancel</button>
</form>
