import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'task-popup',
  templateUrl: './task-popup.component.html',
  styleUrls: ['./task-popup.component.scss']
})
export class TaskPopupComponent {
  @Input() title: string;

  @Output() closePopup: EventEmitter<any> = new EventEmitter();

  onClosePopup() {
    this.closePopup.emit(true);
  }
}
