import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ElementRef
} from '@angular/core';

@Component({
  selector: '[context-popup]',
  template: `
    <div class="content">
    <div *ngIf="!isCustomHeader">
      <div class="close" (click)="onCancelContextPopup()"></div>
      <h3>{{title}}</h3>
      <div class="divider"></div>
    </div>
    <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./context-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContextPopupComponent {
  @Input() title: string;
  @Input() isCustomHeader: boolean;

  @Output() cancelPopup: EventEmitter<any> = new EventEmitter();

  constructor(private _eref: ElementRef) {}

  onCancelContextPopup() {
    this.cancelPopup.emit(null);
  }

  @HostListener('click', ['$event'])
  onClick(event) {
    if (event.target === this._eref.nativeElement) {
      event.stopPropagation();
      event.stopImmediatePropagation();
      this.onCancelContextPopup();
      return false;
    }
  }
}
