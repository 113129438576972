import { mergeMap, catchError, take, filter, map, switchMap, pluck, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import { LABEL_PL, TASK_PL } from '../../constants';
import { Board, HttpQueryParam, PartOfEntity, Task } from '../../interfaces';

import { AppState } from '../state';
import { TaskActionTypes, TaskLoadCompletedAction, TaskRemoveFromStoreAction } from '../actions/task.actions';
import { defaultErrorHandler } from './root.effect';
import { getEntitiesByFields } from '../functions/selectors';
import { TasksSavedListSetTaskAction } from '../actions/task-filters/tasks-saved-list.actions';
import { PaginationLoaderService } from '../../shared/services/paginataion-loader/paginataion-loader.service';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { defaultResponseHandler, HandleResponseAction } from '../actions/root.action';
import { isNotPresent, isPresent } from '../../../helpers';
import { SegmentTrackTaskCreatedAction } from '../actions/segment.actions';
import { getBoardById } from '../reducers/board.reducer';
import * as loadedData from '../../loaded-data/store/loaded-data.actions';

@Injectable()
export class TaskEffects {
  private _paginatorService = new PaginationLoaderService(this._atlazApiV2, this._store);

  @Effect()
  tasksLoaded$ = this._paginatorService.hasMore$.pipe(
    filter(isNotPresent),
    map(() => new TaskLoadCompletedAction(this._paginatorService.queryParams))
  );

  @Effect({ dispatch: false })
  getTaskList$ = this.actions$.ofType(TaskActionTypes.GET).pipe(
    tap(({ type, payload }: { type: string; payload: HttpQueryParam }) => {
      payload = Object.assign(
        {},
        {
          sort: '-updatedAt'
        },
        payload
      );

      this._paginatorService.controller = TASK_PL;
      this._paginatorService.limit = 300;
      this._paginatorService.queryParams = payload;
      this._paginatorService.loadAll = true;
      this._paginatorService.loadMore();
    })
  );

  @Effect()
  tasksLoadedEffect$ = this.actions$
    .ofType(TaskActionTypes.LOAD_COMPLETED)
    .pipe(pluck('payload', 'board'), map((boardId: number) => new loadedData.MarkAsLoadedBoard({ boardId })));

  @Effect()
  loadTask$ = this.actions$.ofType(TaskActionTypes.LOAD).pipe(
    switchMap(({ type, payload }: { type: string; payload: { id: number; params?: HttpQueryParam } }) => {
      const taskId: number = +payload.id;
      const params: HttpQueryParam = payload.params;

      return this._atlazApiV2
        .get([TASK_PL, { id: taskId }, params])
        .pipe(map(resp => new HandleResponseAction(resp)), catchError(defaultErrorHandler(type, payload)));
    })
  );

  @Effect()
  addTask$ = this.actions$.ofType(TaskActionTypes.ADD).pipe(
    mergeMap(({ type, payload }: { type: string; payload: PartOfEntity }) => {
      return this._atlazApiV2.post(TASK_PL, payload).pipe(
        tap(() => {
          const board$ = this._store.pipe(getBoardById(payload.board));
          board$
            .pipe(filter(isPresent), take(1))
            .subscribe((board: Board) =>
              this._store.dispatch(new SegmentTrackTaskCreatedAction({ boardType: board.type, taskType: 'native' }))
            );
        }),
        map(defaultResponseHandler),
        catchError(defaultErrorHandler(type, payload))
      );
    })
  );

  @Effect()
  editTask$ = this.actions$.ofType(TaskActionTypes.EDIT, TaskActionTypes.ASSIGN_SUBSCRIBERS).pipe(
    mergeMap(({ type, payload }: { type: string; payload: any }) => {
      return this._atlazApiV2
        .patch(TASK_PL, payload)
        .pipe(map(resp => new HandleResponseAction(resp)), catchError(defaultErrorHandler(type, payload)));
    })
  );

  @Effect()
  assignUsers$ = this.actions$.ofType(TaskActionTypes.ASSIGN_USERS).pipe(
    filter(({ type, payload }: { type: string; payload: any }) => {
      const users = payload.users;
      return users && ((users.add && users.add.length) || (users.remove && users.remove.length));
    }),
    switchMap(({ type, payload }: { type: string; payload: any }) => {
      return this._atlazApiV2
        .patch(TASK_PL, payload)
        .pipe(map(resp => new HandleResponseAction(resp)), catchError(defaultErrorHandler(type, payload)));
    })
  );

  @Effect()
  batchAssignUsers$ = this.actions$.ofType(TaskActionTypes.BATCH_ASSIGN_USERS).pipe(
    filter(({ type, payload }: { type: string; payload: any }) => {
      const users = payload.users;
      return users && ((users.add && users.add.length) || (users.remove && users.remove.length));
    }),
    switchMap(({ type, payload }: { type: string; payload: any }) => {
      const requestPayload = { id: payload.ids.join(','), users: payload.users };
      return this._atlazApiV2
        .patch(TASK_PL, requestPayload)
        .pipe(map(resp => new HandleResponseAction(resp)), catchError(defaultErrorHandler(type, payload)));
    })
  );

  @Effect()
  tasksSaved = this.actions$.ofType(TaskActionTypes.ASSIGN_USERS).pipe(
    map(({ type, payload }: { type: string; payload: any }) => {
      const taskId: number = payload.id;
      return new TasksSavedListSetTaskAction(taskId);
    })
  );

  @Effect()
  batchTasksSaved = this.actions$.ofType(TaskActionTypes.BATCH_ASSIGN_USERS).pipe(
    map(({ type, payload }: { type: string; payload: any }) => {
      const taskIds: number = payload.ids;
      return new TasksSavedListSetTaskAction(taskIds);
    })
  );

  @Effect()
  assignLabels$ = this.actions$.ofType(TaskActionTypes.ASSIGN_LABELS).pipe(
    switchMap(
      ({ type, payload }: { type: string; payload: { id: number; labels: { add?: number[]; remove?: number[] } } }) => {
        return this._atlazApiV2
          .patch([TASK_PL, { expand: LABEL_PL }], payload)
          .pipe(map(resp => new HandleResponseAction(resp)), catchError(defaultErrorHandler(type, payload)));
      }
    )
  );

  @Effect()
  batchAssignLabels$ = this.actions$.ofType(TaskActionTypes.BATCH_ASSIGN_LABELS).pipe(
    switchMap(
      ({
        type,
        payload
      }: {
        type: string;
        payload: { ids: number[]; labels: { add?: number[]; remove?: number[] } };
      }) => {
        const requestPayload = { id: payload.ids.join(','), labels: payload.labels };
        return this._atlazApiV2
          .patch([TASK_PL, { expand: LABEL_PL }], requestPayload)
          .pipe(map(resp => new HandleResponseAction(resp)), catchError(defaultErrorHandler(type, payload)));
      }
    )
  );

  @Effect()
  clearReleasedTasks$ = this.actions$
    .ofType(TaskActionTypes.REMOVE_RELEASED_FROM_STORE)
    .pipe(
      switchMap(_ =>
        this._store.pipe(
          getEntitiesByFields(TASK_PL, { released: 1 }),
          take(1),
          map((entities: Task[]) => entities.map(x => x.id)),
          map(ids => new TaskRemoveFromStoreAction(ids))
        )
      )
    );

  @Effect({ dispatch: false })
  removeTask$ = this.actions$.ofType(TaskActionTypes.DELETE).pipe(
    switchMap(({ type, payload: task }: { type: string; payload: Task }) => {
      return this._atlazApiV2.deleteRequest([TASK_PL, task.id]).pipe(catchError(defaultErrorHandler(type, task.id)));
    })
  );

  @Effect({ dispatch: false })
  removeTasks$ = this.actions$.ofType(TaskActionTypes.BATCH_DELETE).pipe(
    switchMap(({ type, payload: ids }: { type: string; payload: Array<number | string> }) => {
      return this._atlazApiV2.deleteRequest([TASK_PL, ids.join(',')]).pipe(catchError(defaultErrorHandler(type, ids)));
    })
  );

  constructor(private actions$: Actions, private _store: Store<AppState>, private _atlazApiV2: AtlazApiV2Service) {}
}
