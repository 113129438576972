
import {throwError as observableThrowError, of as observableOf,  Observable ,  ReplaySubject } from 'rxjs';

import {tap, pluck, distinctUntilChanged, filter, switchMap, publishReplay, refCount, map, switchMapTo, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Company, User } from '../../../interfaces';
import { CompanyService } from './company.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { getUserById } from '../../../ngrx/reducers/user.reducer';
import { authApiUrl, isPresent } from '../../../../helpers/';
import { jsonApiToEntityState } from './web-socket/http-response';
import { CREDENTIAL_PL, TEMPORARY_TOKEN_PL } from '../../../constants/';
import { ESInterface } from '../../../ngrx/state/';
import { RouterNavigateService } from '../router-navigate.service';
import { GetCompleteAction } from '../../../ngrx/actions/root.action';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const usersDefaultExpands = ['usersCompanies', 'usersCompanies.company'].join(',');

interface Credentional {
  company: number;
  email: string;
  password: string;
  credentialsType: string;
  type: string;
}

interface AccessDataResponse {
  object: string;
  accessToken: string;
  accessTokenCookie: string;
  company: Company;
  socketioAccessToken: string;
  user: number;
  xsrfToken: string;
}

@Injectable()
export class AuthService {
  public isLoggedIn$ = new ReplaySubject<boolean>(1);
  public activeUser$: Observable<User>;
  public activeUser: User;
  public activeUserId$: Observable<number>;
  public authErrors = [];
  public checkAccount = false;
  public authIsReady$ = this.isLoggedIn$.pipe(distinctUntilChanged(),filter(isPresent),);

  public userInActiveCompany$: Observable<boolean>;

  get hasAuthInfo() {
    return !!this.accessToken;
  }

  constructor(
    private _companyService: CompanyService,
    private _store: Store<AppState>,
    private _navigate: RouterNavigateService,
    private _http: HttpClient
  ) {
    this.activeUser$ = this.isLoggedIn$.pipe(
      switchMap(loggedIn => (loggedIn ? this._store.pipe((getUserById(this.activeUserId))) : observableOf(null))),
      publishReplay(1),
      refCount(),);
    this.activeUserId$ = this.activeUser$.pipe(map(user => (user ? user.id : null)));
    this.activeUser$.subscribe(user => (this.activeUser = user));
    this.userInActiveCompany$ = this.isLoggedIn$.pipe(
      switchMapTo(this.activeUser$),
      distinctUntilChanged((a, b) => a && b && a.id === b.id),
      switchMap(
        (user: User) =>
          user
            ? this._companyService.currentCompany$.pipe(map((company: Company) => !!company.isActive))
            : observableOf(false)
      ),
      distinctUntilChanged(),);

    setTimeout(() => {
      if (this.hasAuthInfo) {
        this.fetchAuthorizedUser();
      } else if (!this.checkAccount) {
        this.isLoggedIn$.next(false);
      }
    });
  }

  fetchAuthorizedUser() {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this._http
      .get(
        this._companyService.getExperimentalApiUrl() +
          '/authorization?access_token=' +
          this.accessToken +
          '&expand=' +
          usersDefaultExpands,
        {
          headers: headers,
          withCredentials: true
        }
      ).pipe(
      map(jsonApiToEntityState),
      catchError(err => this.fetchAccessToken()),)
      .subscribe(this.credentialsHandler.bind(this), _ => this.logout());
  }

  credentialsHandler(state: ESInterface<any>) {
    const accessData = state[CREDENTIAL_PL].entities[state[CREDENTIAL_PL].ids[0]];
    this.saveAccessData(accessData);
    this._store.dispatch(new GetCompleteAction(state));
  }

  fetchAccessToken() {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http
      .post(authApiUrl + '/tokens', JSON.stringify({ company: this._companyService.id }), {
        headers: headers,
        withCredentials: true
      }).pipe(
      catchError(response => observableThrowError({ ...response, ...{ status: response.status } })),
      map(jsonApiToEntityState),
      pluck(TEMPORARY_TOKEN_PL),
      map((state: ESInterface<any>) => state.entities[state.ids[0]]),
      switchMap(token =>
        this._http.post(
          this._companyService.getExperimentalApiUrl() + '/authorization?expand=' + usersDefaultExpands,
          JSON.stringify({
            credentialsType: 'authToken',
            user: token.user,
            company: this._companyService.id,
            accessToken: token.temporaryToken
          }),
          {
            headers: headers,
            withCredentials: true
          }
        )
      ),
      map(jsonApiToEntityState),
      tap(this.credentialsHandler.bind(this)),);
  }

  public logout() {
    this.clearAccessData();
    this._navigate.navigateToLogout();
  }

  public clearAccessData() {
    this.removeFromLocalStorage('accessToken');
    this.removeFromLocalStorage('accessTokenCookie');
    this.removeFromLocalStorage('xsrfToken');
    this.removeFromLocalStorage('socketioAccessToken');
    this.removeFromLocalStorage('activeUserId');

    this.isLoggedIn$.next(false);
  }

  public checkCredentionals(): boolean {
    return this.accessToken !== null && this.socketioAccessToken !== null;
  }

  get accessToken(): string {
    return this.getFromLocalStorage('accessToken');
  }

  get accessTokenCookie(): string {
    return this.getFromLocalStorage('accessTokenCookie');
  }

  get xsrfToken(): string {
    return this.getFromLocalStorage('xsrfTocken');
  }

  get socketioAccessToken(): string {
    return this.getFromLocalStorage('socketioAccessToken');
  }

  get activeUserId(): number {
    return this.activeUser ? this.activeUser.id : +this.getFromLocalStorage('activeUserId');
  }

  set accessToken(accessToken: string) {
    this.setToLocalStorage('accessToken', accessToken);
  }

  set accessTokenCookie(accessTokenCookie: string) {
    this.setToLocalStorage('accessTokenCookie', accessTokenCookie);
  }

  set xsrfToken(xsrfToken: string) {
    this.setToLocalStorage('xsrfTocken', xsrfToken);
  }

  set socketioAccessToken(socketioAccessToken: string) {
    this.setToLocalStorage('socketioAccessToken', socketioAccessToken);
  }

  set activeUserId(userId: number) {
    this.setToLocalStorage('activeUserId', userId.toString());
  }

  private saveAccessData(data: AccessDataResponse) {
    this.accessToken = data.accessToken;
    this.accessTokenCookie = data.accessTokenCookie;
    this.xsrfToken = data.xsrfToken;
    this.socketioAccessToken = data.socketioAccessToken;
    this.activeUserId = data.user;

    this.isLoggedIn$.next(true);
  }

  private setToLocalStorage(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  private getFromLocalStorage(key): string {
    return localStorage.getItem(key);
  }

  private removeFromLocalStorage(key) {
    localStorage.removeItem(key);
  }
}
