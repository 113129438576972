import { OverviewActivity } from '../../interfaces/overview-activity';
import { getDateInterval } from '../../../helpers/date-interval';

function projectFilter(activity: OverviewActivity, projectIds: number[]) {
  return projectIds.includes(activity.project);
}

function activityTypeFilter(activity: OverviewActivity, type: string) {
  if (type === 'completed_tasks') {
    return activity.action === 'done';
  }
  return true;
}

function boardFilter(activity: OverviewActivity, boardIds: number[]) {
  return boardIds.includes(activity.board);
}

function userFilter(activity: OverviewActivity, userIds: number[]) {
  return includesLeastOne(activity.usersIds, userIds);
}

function timeFromFilter(activity: OverviewActivity, timeFrom: number) {
  return activity.createdAt >= timeFrom;
}

function timeToFilter(activity: OverviewActivity, timeTo: number) {
  return activity.createdAt <= timeTo;
}

function timeIntervalFilter(
  activity: OverviewActivity,
  timeInterval: 'today' | 'yesterday' | 'last_week' | 'last_month'
) {
  const dateInterval = getDateInterval(timeInterval);
  return activity.createdAt >= dateInterval[0] && activity.createdAt <= dateInterval[1];
}

function includesLeastOne(arr1: any[], arr2: any[]): boolean {
  return arr1.some(item => arr2.includes(item));
}

export const overviewFilterFns = {
  activity: activityTypeFilter,
  projectsIds: projectFilter,
  boardsIds: boardFilter,
  usersIds: userFilter,
  timeTo: timeToFilter,
  timeFrom: timeFromFilter,
  timeInterval: timeIntervalFilter
};

export function acceptedTypes(activity: OverviewActivity) {
  return ['done', 'log', 'move'].includes(activity.action);
}
