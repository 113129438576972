<input #nameFilterRef name="name filter" placeholder="Search for Board" (keyup)="0" [focus]="true">
<div class="items_container">
  <p
    *ngFor="let boardId of boardsState.ids
    | filterEntitiesByField : boardsState.entities : 'name' : nameFilterRef.value"
    (click)="onSelectBoard(boardId)"
  >
    {{boardsState.entities[boardId]?.name}}
    <span *ngIf="isSelectedBoard(boardId)" class="select_marker"></span>
  </p>
</div>

