import { Action } from './unsafe-action';
import { labelAction } from '../functions/action';
import { PartOfEntity, Group, HttpQueryParam } from '../../interfaces';
import { GROUP } from '../../constants';

export const GroupActionTypes = {
  LOAD: labelAction(GROUP, 'LOAD'),
  GET: labelAction(GROUP, 'GET'),
  GET_COMPLETE: labelAction(GROUP, 'GET_COMPLETE'),
  GET_FAIL: labelAction(GROUP, 'GET_FAIL'),
  ADD: labelAction(GROUP, 'ADD'),
  ADD_COMPLETE: labelAction(GROUP, 'ADD_COMPLETE'),
  ADD_FAIL: labelAction(GROUP, 'ADD_FAIL'),
  EDIT: labelAction(GROUP, 'EDIT'),
  EDIT_COMPLETE: labelAction(GROUP, 'EDIT_COMPLETE'),
  EDIT_FAIL: labelAction(GROUP, 'EDIT_FAIL'),
  DELETE: labelAction(GROUP, 'DELETE'),
  DELETE_COMPLETE: labelAction(GROUP, 'DELETE_COMPLETE'),
  DELETE_FAIL: labelAction(GROUP, 'DELETE_FAIL'),
  MOVE: labelAction(GROUP, 'MOVE'),
  ASSIGN_USERS: labelAction(GROUP, 'ASSIGN_USERS')
};

export class GroupLoadAction implements Action {
  type = GroupActionTypes.LOAD;

  constructor(public payload: number) {}
}

export class GroupGetAction implements Action {
  type = GroupActionTypes.GET;

  constructor(public payload?: HttpQueryParam) {}
}

export class GroupGetCompleteAction implements Action {
  type = GroupActionTypes.GET_COMPLETE;

  constructor(public payload: Group[]) {}
}

export class GroupEditAction implements Action {
  type = GroupActionTypes.EDIT;
  constructor(public payload: PartOfEntity) {}
}

export class GroupEditCompleteAction implements Action {
  type = GroupActionTypes.EDIT_COMPLETE;

  constructor(public payload: Group) {}
}

export class GroupDeleteAction implements Action {
  type = GroupActionTypes.DELETE;

  constructor(public payload: number) {}
}

export class GroupDeleteCompleteAction implements Action {
  type = GroupActionTypes.DELETE_COMPLETE;

  constructor(public payload?: any) {}
}

export class GroupAddAction implements Action {
  type = GroupActionTypes.ADD;

  constructor(public payload: PartOfEntity) {}
}

export class GroupAddCompleteAction implements Action {
  type = GroupActionTypes.ADD_COMPLETE;

  constructor(public payload: Group) {}
}

export class GroupAssignUsersAction implements Action {
  type = GroupActionTypes.ASSIGN_USERS;

  constructor(public payload: { id: number; users: { add?: number[]; remove?: number[] } }) {}
}

export type GroupActions =
  | GroupGetAction
  | GroupLoadAction
  | GroupGetCompleteAction
  | GroupEditAction
  | GroupEditCompleteAction
  | GroupDeleteAction
  | GroupDeleteCompleteAction
  | GroupAddAction
  | GroupAddCompleteAction;
