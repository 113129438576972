import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs/index';
import { map, switchMap } from 'rxjs/operators';
import { AppState } from '../../../ngrx/state';
import { fromBoards } from '../../../ngrx/reducers/board.reducer';
import { QuadNames } from '../../../constants';
import {
  DefaultQuadFilterToggleQuadsAction,
  DefaultQuadsFilterResetAction
} from '../../../ngrx/actions/task-filters/default-quads-filter.actions';
import { getDefaultQuadsFilterValues } from '../../../ngrx/reducers/task-filters/default-quads-filter.reducer';

@Component({
  selector: 'default-quads-filter',
  templateUrl: './default-quads-filter.component.html',
  styleUrls: ['./default-quads-filter.component.scss']
})
export class DefaultQuadsFilterComponent implements OnInit, OnDestroy {
  @Input() boardId$: Observable<number>;
  subs: Subscription[] = [];

  public availableQuads$: Observable<string[]>;
  public selectedQuads$: Observable<number[]>;
  public selectedQuadsWithNames$: Observable<{ name: string; index: number }[]>;
  public quadsWithStatus$: Observable<{ name: string; isActive: boolean }[]>;
  public showQuadsPopUp = false;

  constructor(private _store: Store<AppState>) {}

  ngOnInit() {
    this.availableQuads$ = this.boardId$.pipe(
      switchMap(id =>
        this._store
          .select(fromBoards.get(id))
          .pipe(map(board => (board && board.kvValues && board.kvValues.length ? board.kvValues : QuadNames)))
      )
    );
    this.selectedQuads$ = this._store.pipe(getDefaultQuadsFilterValues);
    this.subs.push(
      this.selectedQuads$.subscribe(quads => {
        quads && !quads.length && this._store.dispatch(new DefaultQuadsFilterResetAction());
      })
    );
    this.quadsWithStatus$ = combineLatest(this.selectedQuads$, this.availableQuads$).pipe(
      map(([selected, available]) => available.map((item, i) => ({ name: item, isActive: selected.includes(i) })))
    );
    this.selectedQuadsWithNames$ = this.quadsWithStatus$.pipe(
      map(items => items.map((item, i) => ({ ...item, index: i })).filter(item => item.isActive))
    );
  }

  onToggleQuad(index) {
    this._store.dispatch(new DefaultQuadFilterToggleQuadsAction(index));
  }

  onShowQuadsPopup() {
    this.showQuadsPopUp = true;
  }
  onHideQuadsPopup() {
    this.showQuadsPopUp = false;
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
