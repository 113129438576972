<form id="payment-form" class="payment-form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <form-errors></form-errors>
  <div class="billing-fieldset" *ngIf="last4">

    <div class="form-row">
      <p>Current Payment Method</p>
      <label>Current card</label>
      <input readonly [value]="'**** **** **** ' + last4" (click)="showDetails()"/>
    </div>

  </div>

  <div class="billing-fieldset">
    <div class="form-row">
      <p class="ssl-info">Secure Form (SSL encrypted)</p>
      <p>New Payment Method</p>
      <label>
        Credit or debit card
      </label>
      <div id="card-element">
        <!-- a Stripe Element will be inserted here. -->
      </div>
      <div id="card-errors" role="alert">
        {{stripeErrors$ | async}}
      </div>
    </div>

  </div>
  <div class="submit-block">
    <button a-button class="btn_primary submit" [pending]="loading$ | async">
      {{(chargeIsRequired$ | async) ? 'Submit a Payment' : 'Change Payment Details'}}
    </button>
  </div>
</form>

<a-center-popup
  *ngIf="showCurrentCardDetails$ | async"
  [title]="'Current card details'"
  (close)="hideDetails()"
>
  <div class="popup_content">
    <div class="form-row last4">
      <label>Card Number</label>
      <div>
        {{'**** **** ****' + last4}}
      <span
        class="menu-block"
        contextMenuAlign="right"
        contextMenuTrigger
        [contextMenuContent]="menuTemplate"
      >
      </span>
      <a-context-menu-content #menuTemplate>
        <a-menu>
          <span a-context-menu-item (click)="showConfirmDelete()">Delete</span>
        </a-menu>
      </a-context-menu-content>
      </div>
    </div>


    <div class="form-row">
      <label>Valid Thru</label>
      <div>{{validDate}}</div>
    </div>
    <button a-button class="btn_primary" (click)="hideDetails()">OK</button>
  </div>
</a-center-popup>
<delete-card-confirmation
  *ngIf="showDeleteCardDetailsConfirmation$ | async"
  [billingSub]="billingSub"
  (close)="hideConfirmDelete()"
></delete-card-confirmation>

<payment-success-popup
		*ngIf="isPaymentSuccessPopupVisible"
		(closePopup)="showSuccessCardChange()"
></payment-success-popup>
<payment-denied-popup
		*ngIf="isPaymentDeniedPopupVisible"
		(closePopup)="onCloseDeniedPopup()"
		(resubmitForm)="onRenewForm()"
		(changePaymentMethod)="onCloseDeniedPopup()"
		[lastCardNumbers]="lastCardNumbers"
></payment-denied-popup>
