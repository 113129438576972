import {
  GITHUB_COMPANY_PL,
  BITBUCKET_COMPANY_PL,
  BITBUCKET_REPOSITORY_PL,
  BITBUCKET_ASSOCIATED_REPOSITORY_PL,
  BITBUCKET_COMMIT_PL,
  GITHUB_COMMIT_PL,
  GITHUB_PULL_REQUEST_PL,
  GITHUB_ASSOCIATED_REPOSITORY_PL,
  GITHUB_REPOSITORY_PL,
  BITBUCKET_PULL_REQUEST_PL,
  GITLAB_COMPANY_PL,
  GITLAB_REPOSITORY_PL,
  GITLAB_ASSOCIATED_REPOSITORY_PL,
  GITLAB_COMMIT_PL,
  GITLAB_PULL_REQUEST_PL
} from '../../../constants';

import { reducer as githubCompanyReducer } from './github-company/github-company.reducer';
import { reducer as githubCommitReducer } from './github-commit/github-commit.reducer';
import { reducer as githubPullRequestReducer } from './github-pull-request/github-pull-request.reducer';
import { reducer as githubAssociatedRepoReducer } from './github-associated-repository/github-associated-repository.reducer';
import { reducer as githubRepoReducer } from './github-repository/github-repository.reducer';
import { reducer as bitbucketCompanyReducer } from './bitbucket-company/bitbucket-company.reducer';
import { reducer as bitbucketRepoReducer } from './bitbucket-repository/bitbucket-repository.reducer';
import { reducer as bitbucketAssocRepoReducer } from './bitbucket-associated-repository/bitbucket-associated-repository.reducer';
import { reducer as bitbucketTaskCommit } from './bitbucket-commit/bitbucket-commit.reducer';
import { reducer as bitbucketPullRequest } from './bitbucket-pull-request/bitbucket-pull-request.reducer';
import { reducer as gitlabCompanyReducer } from './gitlab-company/gitlab-company.reducer';
import { reducer as gitlabRepoReducer } from './gitlab-repository/gitlab-repository.reducer';
import { reducer as gitlabAssocRepoReducer } from './gitlab-associated-repository/gitlab-associated-repository.reducer';
import { reducer as gitlabTaskCommit } from './gitlab-commit/gitlab-commit.reducer';
import { reducer as gitlabPullRequest } from './gitlab-pull-request/gitlab-pull-request.reducer';

export const reducers = {
  [GITHUB_COMPANY_PL]: githubCompanyReducer,
  [GITHUB_COMMIT_PL]: githubCommitReducer,
  [GITHUB_PULL_REQUEST_PL]: githubPullRequestReducer,
  [GITHUB_ASSOCIATED_REPOSITORY_PL]: githubAssociatedRepoReducer,
  [GITHUB_REPOSITORY_PL]: githubRepoReducer,
  [BITBUCKET_COMPANY_PL]: bitbucketCompanyReducer,
  [BITBUCKET_REPOSITORY_PL]: bitbucketRepoReducer,
  [BITBUCKET_ASSOCIATED_REPOSITORY_PL]: bitbucketAssocRepoReducer,
  [BITBUCKET_COMMIT_PL]: bitbucketTaskCommit,
  [BITBUCKET_PULL_REQUEST_PL]: bitbucketPullRequest,
  [GITLAB_COMPANY_PL]: gitlabCompanyReducer,
  [GITLAB_REPOSITORY_PL]: gitlabRepoReducer,
  [GITLAB_ASSOCIATED_REPOSITORY_PL]: gitlabAssocRepoReducer,
  [GITLAB_COMMIT_PL]: gitlabTaskCommit,
  [GITLAB_PULL_REQUEST_PL]: gitlabPullRequest
};
