
import {map} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { compose } from '@ngrx/store';
import { getEntitySelector } from '../../functions/selectors';
import { DEFAULT_SWIMLANES_FILTER } from '../../state';
import * as defaultSwimlanesFilter from '../../actions/task-filters/default-swimlanes-filter.actions';
import { DefaultTasksSwimlanesFilterActionTypes } from '../../actions/task-filters/default-swimlanes-filter.actions';
import { TaskDefaultFilterActionTypes } from '../../actions/task-filters/task-default-filter.actions';

export interface DefaultSwimlanesFilter {
  swimlanesIds: number[];
}

const initState: DefaultSwimlanesFilter = {
  swimlanesIds: []
};

export function reducer(state = initState, action: defaultSwimlanesFilter.Actions): DefaultSwimlanesFilter {
  switch (action.type) {
    case DefaultTasksSwimlanesFilterActionTypes.TOGGLE_ITEM_ID: {
      let swimlanesIds: number[];
      swimlanesIds = action['payload'];
      state = Object.assign({}, state, { swimlanesIds: swimlanesIds });
      return state;
    }

    case DefaultTasksSwimlanesFilterActionTypes.RESET: {
      return initState;
    }

    case TaskDefaultFilterActionTypes.SET: {
      if (action['payload'].activeFilters.defaultSwimlanesFilter) {
        state = { swimlanesIds: [...action['payload'].activeFilters.defaultSwimlanesFilter] };
      } else {
        state = initState;
      }
      return state;
    }

    default: {
      return state;
    }
  }
}

const isActive = (state$: Observable<DefaultSwimlanesFilter>) => state$.pipe(map(state => state.swimlanesIds.length > 0));
const getSwimlanesIds = (state$: Observable<DefaultSwimlanesFilter>) => state$.pipe(map(state => state.swimlanesIds));

export const getDefaultSwimlanesFilterEntity = getEntitySelector(DEFAULT_SWIMLANES_FILTER);
export const isActiveDefaultSwimlanesFilter = compose(isActive, getDefaultSwimlanesFilterEntity);
export const getDefaultSwimlanesFilterIds = compose(getSwimlanesIds, getDefaultSwimlanesFilterEntity);
