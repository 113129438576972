<table class="table_wrapper">
  <thead>
  <tr>
    <th class="sticky_left filter_cell">
      TASKS
      <div class="refresh" a-tooltip="Reset to original order" position="bottom-right" (click)="resetSort()"></div>
      <div>
        <input type="text" class="search_input" (input)="onChangeFilter($event)" placeholder="Search Tasks">
      </div>
    </th>
    <th *ngFor="let factor of (factors$ | async)" (click)="applySortBy($event, factor.prop)" class="sortable">
      {{factor.label}}
      <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === factor.prop}">
        <span data-sort-arrow="desc" class="arrow-up"></span>
        <span data-sort-arrow="asc" class="arrow-down"></span>
      </div>
    </th>
    <th class="sticky_right sortable" (click)="applySortBy($event, 'score')">
      SCORE
      <div class="sort_direction" [ngClass]="{'desc': (activeSort$ | async)?.direction === 'desc', 'active': (activeSort$ | async)?.param === 'score'}">
        <span data-sort-arrow="desc" class="arrow-up"></span>
        <span data-sort-arrow="asc" class="arrow-down"></span>
      </div>
    </th>
  </tr>
  </thead>
  <tbody (click)="onEditTaskFactor($event)">
  <tr *ngFor="let task of (calculatedTasks$ | async)" [attr.data-task-id]="task.id">
    <td class="sticky_left"
        [routerLink]="getTaskLink(task.id)"
    >
      {{task.title}}
    </td>
    <td *ngFor="let fr of (factors$ | async)" [attr.data-factor]="fr.prop" [attr.data-task]="task.id">{{task[fr.prop]}}</td>
    <td [attr.data-factor]="(factors$ | async)[0]?.prop" [attr.data-task]="task.id" class="sticky_right">{{task.score}}</td>
  </tr>
  <tr *ngIf="!(calculatedTasks$ | async)?.length && (filterValue$ | async)" class="no_found">
    <td class="sticky_left">For request: <strong>{{filterValue$ | async}}</strong> nothing is found</td>
    <td [colSpan]="(factors$ | async)?.length + 2"></td>
  </tr>
  </tbody>
</table>
<edit-ice-rice-scoring
  *ngIf="isScoreEditing$ | async"
  [focusOn]="editingFactor$ | async"
  [task]="editingTask$ | async"
  [type]="type$ | async"
  (close)="onCloseEditing()"
></edit-ice-rice-scoring>
