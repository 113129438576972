import { Action } from './unsafe-action';
import { DEFAULT_COLLECTION } from '../../constants';
import { labelAction } from '../functions/action';
import { PartOfDefaultCollection } from '../../interfaces/default-collection';
import { HttpQueryParam } from '../../interfaces';

export const DefaultCollectionActionTypes = {
  LOAD: labelAction(DEFAULT_COLLECTION, 'LOAD'),
  GET: labelAction(DEFAULT_COLLECTION, 'GET'),
  GET_COMPLETE: labelAction(DEFAULT_COLLECTION, 'GET_COMPLETE'),
  GET_FAIL: labelAction(DEFAULT_COLLECTION, 'GET_FAIL'),
  ADD: labelAction(DEFAULT_COLLECTION, 'ADD'),
  ADD_COMPLETE: labelAction(DEFAULT_COLLECTION, 'ADD_COMPLETE'),
  ADD_FAIL: labelAction(DEFAULT_COLLECTION, 'ADD_FAIL'),
  EDIT: labelAction(DEFAULT_COLLECTION, 'EDIT'),
  EDIT_COMPLETE: labelAction(DEFAULT_COLLECTION, 'EDIT_COMPLETE'),
  EDIT_FAIL: labelAction(DEFAULT_COLLECTION, 'EDIT_FAIL'),
  DELETE: labelAction(DEFAULT_COLLECTION, 'DELETE'),
  DELETE_COMPLETE: labelAction(DEFAULT_COLLECTION, 'DELETE_COMPLETE'),
  DELETE_FAIL: labelAction(DEFAULT_COLLECTION, 'DELETE_FAIL'),
  MOVE: labelAction(DEFAULT_COLLECTION, 'MOVE')
};

export class DefaultCollectionEditAction implements Action {
  type = DefaultCollectionActionTypes.EDIT;

  constructor(public payload: PartOfDefaultCollection) {}
}

export class DefaultCollectionGetAction implements Action {
  type = DefaultCollectionActionTypes.GET;

  constructor(public payload?: HttpQueryParam) {}
}

export class DefaultCollectionGetCompleteAction implements Action {
  type = DefaultCollectionActionTypes.GET_COMPLETE;

  constructor(public payload?: any) {}
}
