import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { HotKey } from '../../../shared/directives/hot-key.directive';
import { KeyCode } from '../../../constants/key-code';
import { FormV2Service } from '../../../shared/services/form-v2.service';
import { FormControl } from '@angular/forms';
import { ScaleType } from '../../../constants';
import { getVisibleTaskAxisScore } from '../../../../helpers/task';

@Component({
  selector: 'backlog-inplace-edit',
  templateUrl: './backlog-inplace-edit.component.html',
  styleUrls: ['./backlog-inplace-edit.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BacklogInplaceEditComponent implements OnInit, OnChanges {
  @Input() submitOnEnter = false;
  @Input() initialValue: number;
  @Input() isEditable = false;
  @Input() scaleType: ScaleType;

  @Output() formSubmit: EventEmitter<number> = new EventEmitter<number>();

  public isEdit = false;

  public formValue: FormControl;
  public visibleValue;
  public ScaleType = ScaleType;

  get hotKeys(): HotKey[] | boolean {
    return this.submitOnEnter ? [{ keyCode: KeyCode.KEY_ENTER }, { keyCode: KeyCode.KEY_TAB }] : false;
  }

  constructor(private _cd: ChangeDetectorRef, private _elR: ElementRef) {}

  shouldUpdateVisibleValue(changes) {
    return (
      (changes.hasOwnProperty('scaleType') &&
        changes['scaleType'].currentValue !== changes['scaleType'].previousValue) ||
      (changes.hasOwnProperty('initialValue') &&
        changes['initialValue'].currentValue !== changes['initialValue'].previousValue)
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.shouldUpdateVisibleValue(changes)) {
      const value = changes.hasOwnProperty('initialValue') ? changes['initialValue'].currentValue : this.initialValue;
      const type = changes.hasOwnProperty('scaleType') ? changes['scaleType'].currentValue : this.scaleType;
      this.visibleValue = getVisibleTaskAxisScore(value, type);
    }
  }

  ngOnInit() {
    this.formValue = new FormControl(this.initialValue);
  }

  onHotKey() {
    if (this.submitOnEnter) {
      const newValue = +this.formValue.value;
      this.isEdit = !this.isEdit;

      if (newValue !== this.initialValue && newValue < 101 && newValue >= 0) {
        this.formSubmit.emit(+newValue);
      }
      return false;
    }
  }

  checkValidation() {
    const validationValue = +this.formValue.value;

    if (validationValue > 100) {
      this.formValue.patchValue(100);
    } else if (validationValue < 0) {
      this.formValue.patchValue(Math.abs(validationValue));
    }
  }

  onEditToggle() {
    if (!this.isEditable) {
      return false;
    }
    if (this.isEdit) {
      const newValue = +this.formValue.value;
      if (newValue !== this.initialValue && newValue < 101 && newValue >= 0) {
        this.formSubmit.emit(+newValue);
      }
    }
    this.formValue = new FormControl(this.initialValue);
    this.isEdit = !this.isEdit;
  }

  public detectChanges() {
    this._cd.detectChanges();
  }

  public scrollToView() {
    requestAnimationFrame(() => this._elR.nativeElement.scrollIntoViewIfNeeded());
  }
}
