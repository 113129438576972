import { map, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { PermissionsService } from '../permissions.service';
import { AppState } from '../../ngrx/state';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { of } from 'rxjs/internal/observable/of';

@Injectable()
export class ProjectAdminGuard implements CanActivate {
  constructor(private _permissions: PermissionsService, private _store: Store<AppState>, private _router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return combineLatest([
      this._permissions.isSuperUser$,
      +route.queryParams.projectId ? this._permissions.isProjectAdmin(+route.queryParams.projectId) : of(true)
    ]).pipe(
      take(1),
      map(([isSuperUser, isProjectAdmin]) => isSuperUser || isProjectAdmin),
      tap(hasPermission => (hasPermission ? null : this._router.navigate(['/403'])))
    );
  }
}
