<div class="input" tabindex="-1" [focus]="selectedTaskId">
  <input
    class="hidden_input"
    placeholder="Type task name or insert an url"
    #input
    *ngIf="!selectedTaskId"
    [focus]="initialFocusFalse ? false : (focus$ |async)"
    (input)="onInput($event.target.value)"
    (focus)="onFocusInput()">
  <shared-smart-task-link *ngIf="selectedTaskId" [directLink]="isOpenTasksOnBlankPage" [task]="selectedTaskId"></shared-smart-task-link>
  <div *ngIf="selectedTaskId" class="clear-control" (click)="internalSetTaskId(null)"></div>
</div>
<div class="suggestions"
     [ngClass]="{width700: wideWidth}"
     outOfViewport="bottom"
     outOfViewportX="right"
     *ngIf="(showSuggestions$ | async) && tasksIndependentConditions"
     [checkEvent$]="suggestedTasksIds$">
  <div class="no_match" *ngIf="noMatchSuggestions$ | async">
    Couldn't find tasks matching your request.
  </div>
  <p *ngFor="let taskId of suggestedLoadedTasks$ | async" (click)="onSelectTask(taskId)">
    <task-title-wrapper [taskId]="taskId"></task-title-wrapper>
  </p>
  <p class="align-center create_btn" *ngIf="enableCreateBtn" (click)="onCreateNewTask()">Add New Task</p>
</div>
