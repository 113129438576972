import { label } from '../functions/util';
import { Action } from './unsafe-action';

export const GlobalSearchActionTypes = {
  SEARCH_REMEMBER_BACK_URL: label('[search] remember back url'),
  START_SEARCH: label('[search] start'),
  STOP_SEARCH_AND_GO_BACK: label('[search] stop'),
  STOP_PENDING: label('[search] stop pending'),
  LEAVE_SEARCH_PAGE: label('[search] leave search page'),
  LOAD_MORE: label('[search] load more'),
  RESET: label('[search] reset'),
  HANDLE_SEARCH_RESPONSE: label('[search] handle response')
};

export class GlobalSearchStartAction implements Action {
  type = GlobalSearchActionTypes.START_SEARCH;

  constructor(public payload: { searchQuery: string; filterParams?: {}; sortParams: {} }) {}
}

export class GlobalSearchLoadMoreAction implements Action {
  type = GlobalSearchActionTypes.LOAD_MORE;

  constructor(public payload?: any) {}
}

export class GlobalSearchRememberBackUrlAction implements Action {
  type = GlobalSearchActionTypes.SEARCH_REMEMBER_BACK_URL;

  constructor(public payload: string) {}
}

export class GlobalSearchLeaveSearchPageAction implements Action {
  type = GlobalSearchActionTypes.LEAVE_SEARCH_PAGE;

  constructor(public payload?: any) {}
}

export class GlobalSearchHandleResponseAction implements Action {
  type = GlobalSearchActionTypes.HANDLE_SEARCH_RESPONSE;

  constructor(public payload: { resultIds: number[]; count: number; nextOffset: number; replaceResult: boolean }) {}
}

export class GlobalSearchStopAndGoBackAction implements Action {
  type = GlobalSearchActionTypes.STOP_SEARCH_AND_GO_BACK;

  constructor(public payload?: any) {}
}
export class GlobalSearchResetAction implements Action {
  type = GlobalSearchActionTypes.RESET;

  constructor(public payload?: any) {}
}

export type GlobalSearchActions =
  | Action
  | GlobalSearchStartAction
  | GlobalSearchLoadMoreAction
  | GlobalSearchRememberBackUrlAction
  | GlobalSearchLeaveSearchPageAction
  | GlobalSearchHandleResponseAction
  | GlobalSearchResetAction
  | GlobalSearchStopAndGoBackAction;
