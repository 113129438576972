import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Task } from '../../../../interfaces';

@Component({
  selector: 'sidebar-logwork-popup',
  templateUrl: './sidebar-logwork-popup.component.html',
  styleUrls: ['./sidebar-logwork-popup.component.scss']
})
export class SidebarLogworkPopupComponent {
  @Input() task: Task;

  @Output() close = new EventEmitter();
  @Output() resetFocus = new EventEmitter();

  constructor() {}

  onCloseLogworkPopup() {
    this.close.emit();
  }
  onChildChangedFocus(event) {
    this.resetFocus.emit(event);
  }
}
