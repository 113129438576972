import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { RouterNavigateService } from '../../shared/services/router-navigate.service';
import { AppUrls } from '../../app-urls';
import { InviteLinkService } from '../services/invite-link.service';

@Component({
  selector: 'create-invite-link',
  templateUrl: './create-invite-link.component.html',
  styleUrls: ['./create-invite-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateInviteLinkComponent {
  @Output() tokenCreated = new EventEmitter<boolean>();

  public appUrls = AppUrls;

  constructor(private _routerNavigate: RouterNavigateService, private _inviteLinkService: InviteLinkService) {}

  onCreateLink() {
    this._inviteLinkService.createToken().subscribe(_ => this.tokenCreated.emit(true));
  }

  onCancel() {
    this._routerNavigate.deactivatePopupOutlet();
  }
}
