import { distinctUntilChanged, filter, map, pluck, publishReplay, refCount, switchMap } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/app/auth.service';
import { ENTITY_PL, PROJECT_PL, USER_PL } from '../../../constants';

import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { activeUsersFilter } from '../../../ngrx/reducers/user.reducer';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import { GuiStateQuickTaskEditShow } from '../../../ngrx/actions/gui-state-memorized.actions';
import { fromBoards } from '../../../ngrx/reducers/board.reducer';
import { Board, Task, User } from '../../../interfaces';
import { AppState } from '../../../ngrx/state';
import { getEntitiesByIds } from '../../../ngrx/functions/selectors';
import { compareArrays, isPresent, toString } from '../../../../helpers';
import { getSeenTaskNotificationIds } from '../../../ngrx/reducers/notification.reducer';
import { getTaskUrl } from '../../../ngrx/reducers/task.reducer';
import { getAdvancedScoreByTask } from '../../../ngrx/functions/crossed.selector';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { getVisibleTaskAxisScore } from 'helpers/task';
import isBasicScoringSelected = fromBoards.isBasicScoringSelected;
import isScoringOff = fromBoards.isScoringOff;

@Component({
  selector: 'task-preview',
  templateUrl: './task-preview.component.html',
  styleUrls: ['./task-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskPreviewComponent implements OnChanges, OnInit {
  @ViewChild('taskLink') taskLink: ElementRef;

  @Input() task: Task;
  @Input()
  showEstimates = {
    isShowHoursEstimate: true,
    isShowPointsEstimate: true
  };

  public taskUrl$: Observable<string>;
  public isSubscribe = false;
  public isTaskOverdone: boolean;
  public isTaskCloseToOverdone: boolean;
  public projectShortName$: Observable<string>;
  public taskUsers$: Observable<User[]>;
  public hasNotifications$: Observable<boolean>;
  public isScoringOff$: Observable<boolean>;
  public isBasicScoring$: Observable<boolean>;
  public score$: Observable<number | string>;
  public scoreXLabel$: Observable<string>;
  public scoreXVisible$: Observable<string | number>;
  public scoreXAbsolute$ = new BehaviorSubject(0);
  public scoreYLabel$: Observable<string | number>;
  public scoreYVisible$: Observable<string | number>;
  public scoreYAbsolute$ = new BehaviorSubject(0);
  public boardId$ = new BehaviorSubject(null);
  public board$: Observable<Board>;

  constructor(
    private _userAuthService: AuthService,
    private _store: Store<AppState>,
    private _elR: ElementRef,
    private _route: ActivatedRoute,
    private _renderer: Renderer2
  ) {}

  ngOnChanges(change: SimpleChanges) {
    const taskUsersIds = this.task.usersIds || [];
    this.taskUsers$ = <Observable<User[]>>this._store.pipe(
      getEntitiesByIds(USER_PL, taskUsersIds),
      map(activeUsersFilter),
      distinctUntilChanged(compareArrays)
    );

    this.projectShortName$ = <Observable<string>>this._store.pipe(
      pluck(PROJECT_PL, ENTITY_PL, toString(this.task.project), 'shortName'),
      distinctUntilChanged(),
      publishReplay(1),
      refCount()
    );

    this.hasNotifications$ = this._store.pipe(
      getSeenTaskNotificationIds,
      pluck(this.task.id.toString()),
      map((nIds: number[]) => !!nIds && nIds.length > 0)
    );

    this.isScoringOff$ = this._store.select(isScoringOff);
    this.isBasicScoring$ = this._store.select(isBasicScoringSelected);
    this.dueDateCalculate();
    this.subscribeStatus();

    this.taskUrl$ = this._store.pipe(getTaskUrl(this.task));

    this.score$ = this._store.select(getAdvancedScoreByTask(this.task));

    if (change.task) {
      if (change.task.firstChange) {
        this.boardId$.next(change.task.currentValue.board);
        this.scoreXAbsolute$.next(change.task.currentValue.backlogScoreX);
        this.scoreYAbsolute$.next(change.task.currentValue.backlogScoreY);
      } else {
        if (change.task.currentValue.board !== change.task.previousValue.board) {
          this.boardId$.next(change.task.currentValue.board);
        }
        if (change.task.currentValue.backlogScoreX !== change.task.previousValue.backlogScoreX) {
          this.scoreXAbsolute$.next(change.task.currentValue.backlogScoreX);
        }
        if (change.task.currentValue.backlogScoreY !== change.task.previousValue.backlogScoreY) {
          this.scoreYAbsolute$.next(change.task.currentValue.backlogScoreY);
        }
      }
    }
  }

  ngOnInit() {
    this.board$ = this.boardId$.pipe(
      filter(isPresent),
      switchMap(id => this._store.select(fromBoards.get(id))),
      publishReplay(1),
      refCount()
    );
    this.scoreXLabel$ = this.board$.pipe(pluck('backlogScoreXLabel'));
    this.scoreYLabel$ = this.board$.pipe(pluck('backlogScoreYLabel'));
    this.scoreXVisible$ = this.board$.pipe(
      switchMap(board =>
        this.scoreXAbsolute$.pipe(map(scoreX => getVisibleTaskAxisScore(scoreX, board.backlogScoreXType)))
      )
    );
    this.scoreYVisible$ = this.board$.pipe(
      switchMap(board =>
        this.scoreYAbsolute$.pipe(map(scoreY => getVisibleTaskAxisScore(scoreY, board.backlogScoreYType)))
      )
    );
  }

  public scrollIntoViewAndHighlight() {
    scrollIntoViewIfNeeded(this._elR.nativeElement, { duration: 300 });
    this.highlightScrolledTask();
  }

  highlightScrolledTask() {
    this._renderer.addClass(this._elR.nativeElement, 'scrollHighlightClass');
    //this.taskLink.nativeElement.focus();
    setTimeout(() => {
      this._renderer.removeClass(this._elR.nativeElement, 'scrollHighlightClass');
      window.location.hash = '';
    }, 3000);
  }

  dueDateCalculate() {
    const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
    const diffTime = this.task.dueDate - currentTimeInSeconds;
    const secondsInOneDay = 86400;

    this.isTaskOverdone = currentTimeInSeconds > this.task.dueDate;
    this.isTaskCloseToOverdone = diffTime < secondsInOneDay && diffTime > 0;
  }

  subscribeStatus() {
    this.isSubscribe =
      this.task.subscribersIds &&
      this.task.subscribersIds.some(userId => userId === this._userAuthService.activeUserId);
  }

  onOpenQuickActions(event) {
    this._store.dispatch(
      new GuiStateQuickTaskEditShow({ id: this.task.id, rect: event.currentTarget.getBoundingClientRect() })
    );
  }
}
