<a-center-popup
  [title]="'Copy Board'"
  (close)="onClose()"
>
  <form-errors></form-errors>
  <new-board-form [(form)]="form" [initialValues]="initialValues$ | async" [isTypeSelectorHidden]="true" [isWithTasksAvailable]="isWithTasksAvailable"  (submit)="onSubmit()"></new-board-form>
  <p class="form-row small-text">Activity history, <span *ngIf="!isWithTasksAvailable">tasks,</span> members and work logs will not be copied</p>
  <div class="form-row clear-fix">
    <div a-button class="btn_primary" [pending]="_formService.isPending$ | async" (click)="onSubmit()">Copy</div>
    <div a-button class="btn_text" (click)="onClose()">Cancel</div>
  </div>
</a-center-popup>
