
import {switchMap, map, take, filter} from 'rxjs/operators';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Observer ,  Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BOARD_PL, EstimationType } from '../../../constants';
import { AppState } from '../../../ngrx/state';
import { getBoardById } from '../../../ngrx/reducers/board.reducer';
import { ActivatedRoute } from '@angular/router';
import { SprintBoard } from '../../../interfaces';
import * as moment from 'moment-mini-ts';
import { isPresent } from '../../../../helpers';
import { RouterNavigateService } from '../../../shared/services/router-navigate.service';
import { ModelValidators } from '../../../shared/validators/model-validators';
import {
  FormComponent,
  FormSaveType,
  FormServiceParams,
  FormV2Service
} from '../../../shared/services/form-v2.service';
import { JsonApiSingeModelResponse } from '../../../shared/services/app/web-socket/http-response';
import { HandleResponseAction } from '../../../ngrx/actions/root.action';
import { DateTimeFormatterService } from '../../../libs/date-time-formatter/services/date-time-formatter.service';

const defaultDuration = 2;
const CUSTOM_DURATION = 'custom';
const endDateFromDuration = weeks => moment().add({ weeks: +weeks });

@Component({
  selector: 'start-sprint-popup',
  templateUrl: './start-sprint-popup.component.html',
  styleUrls: ['./start-sprint-popup.component.scss'],
  providers: [FormV2Service]
})
export class StartSprintPopupComponent implements OnInit, OnDestroy, FormComponent {
  formObserver: Observer<any> = {
    next: (resp: JsonApiSingeModelResponse<any>) => {
      this._store.dispatch(new HandleResponseAction(resp));
      this.onClose();
    },

    error: error => {
      this.showPopUp = true;
    },

    complete: () => {}
  };

  subs: Subscription[] = [];

  formServiceParams: FormServiceParams = {
    saveType: FormSaveType.edit,
    entityToEdit: BOARD_PL,
    formObserver: this.formObserver,
    prepareFormValue: v => {
      v = Object.assign({}, v);
      v['sprintEndDate'] = (v['duration'] === CUSTOM_DURATION
        ? v['sprintEndDate']
        : endDateFromDuration(v['duration'])
      ).unix();
      delete v['duration'];
      return v;
    }
  };

  customErrorMessages = {
    tooSmallDate: 'End date has to be at least - today',
    maxlength: 'Sprint name exceeds the maximum length (50 symbols)'
  };

  form: FormGroup;
  public dateFormat = this._dateTimeFormatter.INPUT_DATE_FORMAT;
  public defaultDate = endDateFromDuration(defaultDuration);
  public estimationType = EstimationType;

  public CUSTOM_DURATION = CUSTOM_DURATION;
  showPopUp = false;

  constructor(
    private _fb: FormBuilder,
    private _routerNav: RouterNavigateService,
    public _formService: FormV2Service,
    private _route: ActivatedRoute,
    private _cd: ChangeDetectorRef,
    private _store: Store<AppState>,
    private _dateTimeFormatter: DateTimeFormatterService
  ) {}

  ngOnInit(): any {
    const boardId = +this._route.snapshot.params['boardId'];
    this.subs.push(
      this._store.pipe(
        (getBoardById(boardId)),
        filter(isPresent),
        take(1),
        map((board: SprintBoard) => {
          this.form = this._fb.group({
            id: [board.id],
            sprintName: ['', ModelValidators.version.name],
            sprintStartDate: [1],
            duration: [defaultDuration],
            sprintEndDate: [{ value: this.defaultDate, disabled: true }, ModelValidators.board.sprintEndDate],
            estimatedBy: board.estimatedBy || this.estimationType.any
          });
          this._formService.initFormParams(this.form, this.formServiceParams);
          this.showPopUp = true;
          this._cd.markForCheck();
          this._cd.detectChanges();
          return this.form;
        }),
        switchMap((form: FormGroup) => form.get('duration').valueChanges),)
        .subscribe(value => {
          if (value === CUSTOM_DURATION) {
            this.form.get('sprintEndDate').enable();
          } else {
            this.form.get('sprintEndDate').disable();
            this.form.get('sprintEndDate').patchValue(endDateFromDuration(value));
          }
        })
    );
  }

  onClose() {
    this.showPopUp = false;
    this._routerNav.deactivatePopupOutlet();
  }

  onSubmit() {
    this._formService.markAsDirty();
    if (this.form.valid) {
      return this._formService.submit();
    }
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
