export function getTaskIdFromUrl(url: string): any {
  const taskRegExp = new RegExp(getTaskUrlRegExpPattern(true), 'g');
  const parsedUrl = taskRegExp.exec(url);
  if (!!parsedUrl) {
    return decodeURI(parsedUrl[4]);
  } else {
    return null;
  }
}

export function getTaskUrlRegExpPattern(taskUrlOnly = true): string {
  return (
    (taskUrlOnly ? '^' : '') +
    'https?://' +
    location.origin.substr(location.protocol.length + 2) +
    '\\S+((\\(popup:tasks/([\\d]+)\\))|t/(\\S+-\\d+|\\d+))' +
    (taskUrlOnly ? '$' : '')
  );
}

export const getBackUrl = () => document.location.href.split(document.location.pathname)[0];
