
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BugTrackerService } from '@atlaz/core/services/bag-tracker.service';
import { AppState } from '../ngrx/state/app-state';
import { PageStatus, STATUS_CODES } from '../permissions/interfaces/page-status.interface';
import { AtlazApiV2Service } from '../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { Store } from '@ngrx/store';
import { isNumericalString } from '../../helpers/index';
import { VERSION_PL } from '../constants/entity';
import { HandleResponseAction } from '../ngrx/actions/root.action';

@Injectable()
export class VersionPageResolver implements Resolve<PageStatus> {
  public checkPermission$;

  constructor(
    private _apiV2: AtlazApiV2Service,
    private _store: Store<AppState>,
    private _bugTracker: BugTrackerService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PageStatus> {
    const id = route.params['id'] || route.params['versionId'];
    this.checkPermission$ = this.checkPermissions(id);

    return this.checkPermission$;
  }

  checkPermissions(id) {
    if (!isNumericalString(id)) {
      return observableOf({
        statusCode: STATUS_CODES.NOT_FOUND
      });
    }

    // front can't to verify than user have sub permissions or not
    return this.loadVersionRequest(id);
  }

  loadVersionRequest(id) {
    return this._apiV2
      .get(VERSION_PL, {
        id,
        expand: [
          'sprintsTasks',
          'tasks',
          'tasks.column',
          'tasks.column.parent',
          'tasks.users.id',
          'tasks.project',
          'tasks.board',
          'tasks.parent'
        ]
      }).pipe(
      map(resp => {
        this._store.dispatch(new HandleResponseAction(resp));
        return {
          statusCode: STATUS_CODES.OK
        };
      }),
      catchError(err => {
        // by default unknown error;
        let status = err.status;

        if (![STATUS_CODES.FORBIDDEN, STATUS_CODES.NOT_FOUND, STATUS_CODES.CONNECTION_PROBLEM].includes(status)) {
          status = STATUS_CODES.SERVER_ERROR;
          this._bugTracker.warn('VersionPageResolver, Server error: unknown status', err);
        }

        return observableOf({
          statusCode: status
        });
      }),);
  }
}
