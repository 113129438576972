import { BehaviorSubject, Observable, of as observableOf, Subscription } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  pluck,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { getTaskIdFromUrl, isNotPresent, isPresent } from '../../../../helpers';
import { AtlazApiV2Service } from '../../services/atlaz-api/v2/atlaz-api-v2.service';
import { SEARCH } from '../../../path.routing';
import { BOARD_PL, boardType, defaultExpand, ENTITY_PL, KeyCode, TASK_PL } from '../../../constants';
import { HandleResponseAction } from '../../../ngrx/actions/root.action';
import { JsonApiModelsResponse } from '../../services/app/web-socket/http-response';
import { AppState, Entities } from '../../../ngrx/state';
import { select, Store } from '@ngrx/store';
import { isActiveTask } from '../../../ngrx/reducers/task.reducer';
import { Board, Task } from '../../../interfaces';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { getOpenedTaskHistory } from '../../../ngrx/reducers/opened-task-history.reducer';
import { fromOpenedTask } from '../../../task/ngrx/reducers/opened-task.reducer';
import { TaskDetailPageRelatedDataService } from '../../../task/task-detail-page/services/task-detail-page-related-data.service';
import { Features } from '../../../libs/paywall/features.constants';
import { PaywallService } from '../../../libs/paywall/paywall.service';

@Component({
  selector: 'link-task-input',
  templateUrl: './link-task-input.component.html',
  styleUrls: ['./link-task-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LinkTaskInputComponent),
      multi: true
    },
    TaskDetailPageRelatedDataService
  ]
})
export class LinkTaskInputComponent implements AfterViewInit, OnInit, OnDestroy, ControlValueAccessor {
  @Input() isOpenTasksOnBlankPage = false;
  @Input() isMultipleUsage = false;
  @Input() blackList: number[] = [];
  @Input() initialFocusFalse: boolean;
  @Input() enableRoadmapTasks = false;
  @Input() wideWidth = false;
  @Input() enableCreateBtn = false;
  @ViewChild('input') input: ElementRef;
  @Output() ngSubmit = new EventEmitter();
  @Output() createNewTask = new EventEmitter();

  public suggestedTasksIds$ = new BehaviorSubject<boolean | number[]>(false);
  public showSuggestions$;
  public openedTaskHistory$: Observable<boolean | number[]>;
  public hasSuggestions$;
  public noMatchSuggestions$;
  public suggestedLoadedTasks$;

  public focus$;
  public selectedTaskId: number;
  public suggestionsClosed: boolean;

  private inputValue$ = new BehaviorSubject('');
  private propagateChange = (_: any) => {};

  subs: Subscription[] = [];

  constructor(
    private _apiV2: AtlazApiV2Service,
    private _store: Store<AppState>,
    private _elRef: ElementRef,
    private _cd: ChangeDetectorRef,
    private _paywall: PaywallService
  ) {}

  public writeValue(selectedTaskId: number) {
    if (selectedTaskId) {
      this.selectedTaskId = selectedTaskId;
    }
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched() {}

  ngOnInit() {
    this.focus$ = this._store.pipe(
      select(fromOpenedTask.getId),
      distinctUntilChanged(),
      map(v => (this.isOpenTasksOnBlankPage ? true : isNotPresent(v)))
    );

    this.openedTaskHistory$ = this._store
      .select(getOpenedTaskHistory)
      .pipe(map((list: number[]) => (list.length ? list : false)));
    this.openedTaskHistory$.pipe(take(1)).subscribe(ids => this.suggestedTasksIds$.next(ids));
    this.suggestedLoadedTasks$ = this.suggestedTasksIds$.pipe(
      switchMap(
        (ids: number[]) =>
          ids
            ? this._store.pipe(
                pluck(TASK_PL, ENTITY_PL),
                distinctUntilChanged(),
                withLatestFrom(
                  this._store.pipe(pluck(BOARD_PL, ENTITY_PL), distinctUntilChanged()),
                  (taskEntities: Entities<Task>, boardEntities: Entities<Board>) =>
                    ids
                      .map(id => taskEntities[id])
                      .filter(isPresent)
                      .filter(isActiveTask)
                      // we filter tasks from roadmap boards because of we are unable to create link on them
                      .filter(
                        (task: Task) =>
                          boardEntities[task.board] &&
                          (boardEntities[task.board].type !== boardType.roadmap || this.enableRoadmapTasks)
                      )
                      .slice(0, 10)
                      .map(xs => xs.id)
                )
              )
            : observableOf(ids)
      )
    );
    this.hasSuggestions$ = this.suggestedLoadedTasks$.pipe(map(isPresent));
    this.noMatchSuggestions$ = this.suggestedLoadedTasks$.pipe(map(ids => Array.isArray(ids) && ids.length === 0));
    this.showSuggestions$ = this.focus$.pipe(
      switchMap(hasFocus => (hasFocus ? this.hasSuggestions$ : observableOf(false)))
    );
  }

  onInput(value: string) {
    this.inputValue$.next(value);
  }

  ngAfterViewInit() {
    this.subs.push(
      this.inputValue$
        .pipe(
          debounceTime(200),
          startWith(''),
          distinctUntilChanged(),
          map((q: string) => q.trim()),
          // this have side effect. if query looks like link to task we pick this task automatically
          map((q: string) => {
            let taskId;
            if ((taskId = getTaskIdFromUrl(q))) {
              this.onSelectTask(taskId);
              return '';
            }
            return q;
          }),
          switchMap(
            (q: string) =>
              q.length > 0
                ? this._apiV2
                    // we need only active tasks for which we can create link
                    .get(SEARCH, { q, limit: 10, expand: defaultExpand[SEARCH], archived: 0, released: 0 })
                    .pipe(
                      tap(resp => this._store.dispatch(new HandleResponseAction(resp))),
                      map((resp: JsonApiModelsResponse<any>) => resp.data.map(task => task.id)),
                      catchError(() => observableOf([]))
                    )
                : this.openedTaskHistory$
          )
        )
        .subscribe(ids => this.suggestedTasksIds$.next(this.blackListFilter(ids)))
    );
  }

  onSelectTask(taskId) {
    this.internalSetTaskId(taskId);
    if (this.isMultipleUsage) {
      this.ngSubmit.emit();
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.focus$.pipe(take(1), filter(isPresent)).subscribe(() => {
      if (this.selectedTaskId) {
        if ([KeyCode.KEY_BACK_SPACE, KeyCode.KEY_DELETE].includes(event.keyCode)) {
          if (
            !this.isMultipleUsage ||
            document.activeElement === this._elRef.nativeElement ||
            this._elRef.nativeElement.contains(document.activeElement)
          ) {
            this.internalSetTaskId(null);
          }
        } else if ([KeyCode.KEY_ENTER].includes(event.keyCode)) {
          if (!this.isMultipleUsage) {
            this.ngSubmit.emit();
          }
        }
      } else if ([KeyCode.KEY_ENTER].includes(event.keyCode)) {
        {
          this.noMatchSuggestions$
            .pipe(
              take(1),
              filter(isNotPresent),
              switchMap(() => this.inputValue$.pipe(take(1))),
              filter((q: string) => !!q.length),
              switchMap(() => this.suggestedTasksIds$.pipe(take(1)))
            )
            .subscribe((ids: number) => {
              this.onSelectTask(ids[0]);
              event.stopPropagation();
              event.preventDefault();
            });
        }
      }
    });
  }

  onFocusInput() {
    this.suggestionsClosed = false;
    this.detectChanges();
  }

  detectChanges() {
    this._cd.detectChanges();
  }

  clearField(isPropagateChanges?: boolean) {
    this.selectedTaskId = null;
    this.inputValue$.next('');
    if (isPropagateChanges) {
      this.propagateChange(null);
    }
  }

  onCreateNewTask() {
    this.suggestionsClosed = true;
    if (this._paywall.isFeatureEnabled(Features.CanAddTask)) {
      this.createNewTask.emit(this.onSelectTask.bind(this));
    } else {
      this._paywall.showPayWall(Features.CanAddTask);
    }
  }

  get tasksIndependentConditions() {
    if (this.isMultipleUsage) {
      return (
        !this.selectedTaskId &&
        !this.suggestionsClosed &&
        (this._elRef.nativeElement.matches(':hover') ||
          document.activeElement === this._elRef.nativeElement ||
          this._elRef.nativeElement.contains(document.activeElement))
      );
    } else {
      return !this.selectedTaskId;
    }
  }

  private blackListFilter(possibleTasks) {
    return Array.isArray(possibleTasks)
      ? possibleTasks.filter(taskId => !this.blackList.includes(taskId))
      : possibleTasks;
  }

  private internalSetTaskId(taskId) {
    this.selectedTaskId = taskId;
    this.propagateChange(this.selectedTaskId);
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
