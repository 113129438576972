
import {publishReplay, map, combineLatest, refCount, withLatestFrom, take} from 'rxjs/operators';
import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { Board } from '../../../interfaces';
import { Observable ,  Subscription } from 'rxjs';
import { getSprintProgressType } from '../../../ngrx/reducers/gui-state-memorized.reducer';
import { GuiStateStoreSprintProgressType } from '../../../ngrx/actions/gui-state-memorized.actions';
import { progressTypes } from './percent-types';
import { getBoardById } from '../../../ngrx/reducers/board.reducer';
import { EstimationType } from '../../../constants';
import { getSprintTasksByBoardId } from '../../../ngrx/reducers/version.reducer';
import { SprintsTasks } from '../../../interfaces/sprints-tasks';
import { inputChanged } from '../../../../helpers';

@Component({
  selector: 'sprint-percent-indicator',
  templateUrl: './sprint-percent-indicator.component.html',
  styleUrls: ['./sprint-percent-indicator.component.scss']
})
export class SprintPercentIndicatorComponent implements OnChanges, OnDestroy {
  @Input() boardId: number;
  subs: Subscription[] = [];

  progress$: Observable<{
    message: string;
    percent: string;
    showProgressBar: boolean;
    overEstimate: string;
    counter: string;
  }>;
  message$: Observable<string>;
  progressStyle$: Observable<{}>;
  board$: Observable<Board>;
  boardEstimateBy$: Observable<EstimationType>;
  percent$: Observable<string>;
  counter$: Observable<string>;
  overEstimate$: Observable<string>;
  showProgressBar$: Observable<boolean>;
  allSprintsTasks$: Observable<SprintsTasks[]>;
  doneSprintsTasks$: Observable<SprintsTasks[]>;

  currentType$: Observable<string>;
  public progressTypes = progressTypes;

  constructor(private _store: Store<AppState>) {}

  static getAvailableTypes(estimateBy: EstimationType) {
    let result;
    switch (estimateBy) {
      case EstimationType.any: {
        result = [
          progressTypes.tasksProgress,
          progressTypes.pointsProgress,
          progressTypes.hoursProgress,
          progressTypes.daysLeft
        ];
        break;
      }
      case EstimationType.storyPoints: {
        result = [
          progressTypes.tasksProgress,
          progressTypes.pointsProgress,
          progressTypes.hoursCount,
          progressTypes.daysLeft
        ];
        break;
      }
      case EstimationType.hours: {
        result = [progressTypes.tasksProgress, progressTypes.hoursProgress, progressTypes.daysLeft];
        break;
      }
    }
    return result;
  }

  onNextType() {
    this.currentType$.pipe(
      withLatestFrom(this.boardEstimateBy$, (type, estimateBy) => ({ type: type, estimateBy: estimateBy })),
      take(1),)
      .subscribe(value => {
        const availableTypes = SprintPercentIndicatorComponent.getAvailableTypes(value.estimateBy);
        let index = availableTypes.indexOf(value.type);
        index = index === availableTypes.length - 1 ? -1 : index;
        this._store.dispatch(new GuiStateStoreSprintProgressType(availableTypes[++index]));
      });
  }

  onPrevType() {
    this.currentType$.pipe(
      withLatestFrom(this.boardEstimateBy$, (type, estimateBy) => ({ type: type, estimateBy: estimateBy })),
      take(1),)
      .subscribe(value => {
        const availableTypes = SprintPercentIndicatorComponent.getAvailableTypes(value.estimateBy);
        let index = availableTypes.indexOf(value.type) || availableTypes.length;
        this._store.dispatch(new GuiStateStoreSprintProgressType(availableTypes[--index]));
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (inputChanged('boardId')(changes)) {
      this.board$ = this._store.pipe((getBoardById(this.boardId)));
      this.boardEstimateBy$ = this.board$.pipe(map((board: Board) => board.estimatedBy));
      this.allSprintsTasks$ = this._store
        .select(getSprintTasksByBoardId(this.boardId)).pipe(
        map((all: SprintsTasks[]) =>
          all.filter((sprintsTask: SprintsTasks) => sprintsTask.board === this.boardId && !sprintsTask.version)
        ));
      this.doneSprintsTasks$ = this.allSprintsTasks$.pipe(map((sprintsTasks: SprintsTasks[]) =>
        sprintsTasks.filter(sprintsTask => sprintsTask.doneDate)
      ));
      this.currentType$ = this._store.pipe((getSprintProgressType));
      this.subs.push(
        this.boardEstimateBy$.pipe(
          withLatestFrom(this.currentType$, (estimateBy, currentIndicatorType) => ({
            estimateBy: estimateBy,
            currentIndicatorType: currentIndicatorType
          })))
          .subscribe(value => {
            const availableTypes = SprintPercentIndicatorComponent.getAvailableTypes(value.estimateBy);
            if (!availableTypes.includes(value.currentIndicatorType)) {
              this.onNextType();
            }
          })
      );
      this.progress$ = this.doneSprintsTasks$.pipe(
        combineLatest(
          this.allSprintsTasks$,
          this.currentType$,
          (doneSprintsTasks: SprintsTasks[], allSprintsTasks: SprintsTasks[], type: string) => {
            let percent = 0;
            let message = '';
            let counter = '';
            let overEstimate = '';
            let showProgressBar = true;
            switch (type) {
              case progressTypes.tasksProgress: {
                message = 'Tasks Completed';
                counter = doneSprintsTasks.length + '/' + allSprintsTasks.length;
                percent = 100 * doneSprintsTasks.length / allSprintsTasks.length || 0;
                break;
              }
              case progressTypes.pointsProgress: {
                message = 'Story Points Completed';
                const sumAll = allSprintsTasks.reduce((acc, sprintsTask) => acc + sprintsTask.storyPoints, 0);
                const sumDone = doneSprintsTasks.reduce((acc, sprintsTask) => acc + sprintsTask.storyPoints, 0);
                counter = sumDone + '/' + sumAll;
                percent = 100 * sumDone / sumAll || 0;
                break;
              }
              case progressTypes.hoursProgress: {
                message = 'Hours Logged';
                const sumAll = allSprintsTasks.reduce((acc, sprintsTask) => acc + sprintsTask.remainingEstimate, 0);
                const sumLogged = allSprintsTasks.reduce((acc, sprintsTask) => acc + sprintsTask.logged, 0);
                percent = sumAll > 0 ? sumLogged / sumAll * 100 : 0;
                counter = '/' + Math.round(sumAll / 3600) + 'h';
                if (sumLogged > sumAll) {
                  overEstimate = Math.round(sumLogged / 3600) + 'h';
                } else {
                  counter = Math.round(sumLogged / 3600) + 'h' + counter;
                }
                break;
              }
              case progressTypes.hoursCount: {
                const sumLogged = allSprintsTasks.reduce((acc, sprintsTask) => acc + sprintsTask.logged, 0);
                message = 'Hours Logged: ' + Math.round(sumLogged / 3600) + 'h';
                showProgressBar = false;
                break;
              }
              case progressTypes.daysLeft: {
                showProgressBar = false;
                break;
              }
              default: {
              }
            }
            return {
              message: message,
              percent: percent.toFixed(2),
              counter: counter,
              overEstimate: overEstimate,
              showProgressBar: showProgressBar
            };
          }
        ),
        publishReplay(1),
        refCount(),);

      this.message$ = this.progress$.pipe(map(x => x.message));
      this.percent$ = this.progress$.pipe(map(x => x.percent));
      this.counter$ = this.progress$.pipe(map(x => x.counter));
      this.overEstimate$ = this.progress$.pipe(map(x => x.overEstimate));
      this.showProgressBar$ = this.progress$.pipe(map(x => x.showProgressBar));
      this.progressStyle$ = this.progress$.pipe(map(x => ({
        width: x.percent + '%'
      })));
    }
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
