import { SlackBoardAdditional } from '../../models/slack-boards';
import { BOARD } from '../../../../../constants/entity';

export function getDefaultAdditionalData(): SlackBoardAdditional {
  return {
    'atlaz:boardAddMember': 1,
    'atlaz:boardRemoveMember': 1,
    'atlaz:boardRenamed': 1,
    'atlaz:boardAddProject': 1,
    'atlaz:boardRemoveProject': 1,
    'atlaz:boardAddCollection': 1,
    'atlaz:boardChangeCollection': 0,
    'atlaz:boardRemoveCollection': 1,
    'atlaz:boardClosed': 1,
    'atlaz:taskGroupCreated': 1,

    'atlaz:taskCreated': 1,
    'atlaz:taskCreatedByLink': 1,
    'atlaz:taskCopied': 1,
    'atlaz:taskRenamed': 1,
    'atlaz:taskCompleted': 1,
    'atlaz:taskPushed': 1,
    'atlaz:taskRoadmapStatusChanged': 0, // not used in form
    'atlaz:taskMoved': 1,
    'atlaz:taskArchiveChanged': 1,
    'atlaz:taskDescriptionChanged': 1,
    'atlaz:taskDueDateChanged': 1,
    'atlaz:taskEstimateChanged': 1,
    'atlaz:taskProjectChanged': 1,
    'atlaz:taskVersionChanged': 1,
    'atlaz:taskValueOrEffortsChanged': 1,
    'atlaz:taskDeleted': 1,

    'atlaz:columnCreate': 1,
    'atlaz:columnRenamed': 1,
    'atlaz:columnPositionChanged': 0,
    'atlaz:columnArchived': 1,
    'atlaz:columnUnarchived': 1,
    'atlaz:columnWipLimitsChanged': 1,
    'atlaz:columnBacklogCapacityChanged': 0,
    'atlaz:columnTypeOrKindChanged': 1,

    'atlaz:tasksAttachmentCreate': 1,
    'atlaz:tasksAttachmentDelete': 0,
    'atlaz:taskAddComment': 1,
    'atlaz:taskAddLabel': 1,
    'atlaz:taskRemoveLabel': 1,
    'atlaz:taskAddUser': 1,
    'atlaz:taskRemoveUser': 1,
    'atlaz:taskWorkLogCreated': 1,
    'atlaz:taskWorkLogChanged': 1,
    'atlaz:taskWorkLogDeleted': 1,

    'atlaz:taskChecklistCreated': 1,
    'atlaz:taskChecklistDeleted': 1,
    'atlaz:taskChecklistRenamed': 1,
    'atlaz:taskChecklistItemCreated': 1,
    'atlaz:taskChecklistItemDeleted': 1,
    'atlaz:taskChecklistItemRenamed': 1,
    'atlaz:taskChecklistItemCompletedUncompleted': 1,

    'atlaz:swimlaneCreated': 1,
    'atlaz:swimlaneDeleted': 1,
    'atlaz:swimlaneRenamed': 1,
    'atlaz:swimlaneMoved': 1,

    'atlaz:filterCreated': 1,
    'atlaz:filterDeleted': 1,
    'atlaz:filterRenamed': 0,
    'atlaz:filterChanged': 1
  };
}
