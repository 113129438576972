import { AppState, ESInterface } from '../state';
import { ExternalUser } from '../../interfaces/external-user';
import { EXTERNAL_USER_PL } from '../../constants';
import { createSelector } from 'reselect';

export namespace fromExternalUsers {
  export const getState = (store: AppState) => {
    return <ESInterface<ExternalUser>>store[EXTERNAL_USER_PL];
  };
  export const getById = id => (store: AppState) => getState(store).entities[id];
  export const getByIds = ids =>
    createSelector(getState, state => {
      const result = [];
      ids.forEach(id => {
        if (state.entities[id]) {
          result.push(state.entities[id]);
        }
      });
      return result;
    });
}
