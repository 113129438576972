<div class="quick_actions_container" *ngIf="editingTask$ | async">
  <overlay (click)="onCloseQuickEdit()"></overlay>
  <div class="quick_actions_controls" [style.top.px]="(texteareaPosition$ | async).top" [style.left.px]="(texteareaPosition$ | async).left">
    <content-menu
      class="no_background"
      [isAdditionalItemsAvailable]="true"
      [isBottomElementsAlwaysVisible]="true"
      [notHideOnClick]="true"
      [noOverlay]="true"
      [initialState]="true"
      [style.right.px]="contentMenuRight$ | async"
      [task]="editingTask$ | async"
      [membersPopupOutsideOpener$]="membersPopupOpener$"
      [datePopupOutsideOpener$]="dueDatePopupOpener$"
      [editPermissions]="isNotGuest"
      (closeMenuItem)="onCloseMenuAction()"
      (archiveTask)="onCloseQuickEdit()"
    ></content-menu>
    <textarea
      autosize
      #taskTitleTextArea
      [class.padding14]="(editingTaskParams$ | async)?.isExactRect"
      [style.width]="textareaWidth$ | async"
      [checkSize$]="checkTextareaSize$"
      [focus]="!(editingTaskParams$ | async)?.activate"
      [(ngModel)]="taskTitle"
      (keydown.esc)="onCloseQuickEdit()"
      (keydown.enter)="onSaveTaskTitle($event)"
    ></textarea>
    <div (click)="onCloseQuickEdit()">
      <button a-button class="btn_primary" (click)="onSaveTaskTitle($event)">Save</button>
    </div>
  </div>
</div>
