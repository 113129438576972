import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { INTEGRATION_MAP, IntegrationNames } from '../../../../core/integraition-list';

@Component({
  selector: 'git-task-block-title',
  templateUrl: './git-task-block-title.component.html',
  styleUrls: ['./git-task-block-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GitTaskBlockTitleComponent {
  @Input() title: string;
  @Input() integrationName: IntegrationNames;

  get srcPath() {
    return INTEGRATION_MAP[this.integrationName].logo;
  }
}
