export const GREEN = 'green';
export const YELLOW = 'yellow';
export const ORANGE = 'orange';
export const RED = 'red';
export const VIOLET = 'violet';
export const DARK_BLUE = 'darkblue';
export const WHITE_BLUE = 'whiteblue';
export const EMERALD = 'emerald';
export const PINK = 'pink';
export const BLACK = 'black';
