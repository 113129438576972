import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { EffectsModule } from '@ngrx/effects';

import { BillingGlobalComponent } from './containers/billing-overlay/billing-overlay.component';
import { PricingPageComponent } from './containers/pricing-page/pricing-page.component';
import { PaymentFormComponent } from './components/payment-form/payment-form.component';
import { SubscriptionEffects } from './store/subscription/subscription.effects';
import { BillingRoutingModule } from './billing-routing.module';
import { CreateSubscriptionComponent } from './containers/create-subscription/create-subscription.component';
import { BillingHeaderComponent } from './layout/billing-header/billing-header.component';
import { BillingPublicApiService } from './shared/billing-public-api.service';
import { AtlazGuiModule } from '../atlaz-gui/atlaz-gui.module';
import { PaymentFormGuardService } from './guards/payment-form-guard.service';
import { SharedModule } from '../shared/shared.module';
import { EditSubscriptionComponent } from './containers/edit-subscription/edit-subscription.component';
import { EditSubscribtionFormComponent } from './components/edit-subscribtion-form/edit-subscribtion-form.component';
import { ChangeCardComponent } from './containers/change-card/change-card.component';
import { ChangeCardFormComponent } from './components/change-card-form/change-card-form.component';
import { DeleteCardConfirmationComponent } from './components/change-card-form/delete-card-confirmation/delete-card-confirmation.component';
import { PaymentHeaderComponent } from './components/payment-header/payment-header.component';
import { ThereDSecurePopupComponent } from './containers/there-d-secure-popup/there-d-secure-popup.component';
import { ThereDSecureBackUrlComponent } from './containers/there-d-secure-back-url/there-d-secure-back-url.component';
import { BillingSharedModule } from '@atlaz/billing/shared/components/billing-shared.module';

const SHARED_COMPONENTS = [BillingGlobalComponent];

@NgModule({
  imports: [
    CommonModule,
    BillingRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AtlazGuiModule,
    SharedModule,
    BillingSharedModule,
    EffectsModule.forFeature([SubscriptionEffects])
  ],
  declarations: [
    ...SHARED_COMPONENTS,
    CreateSubscriptionComponent,
    ThereDSecurePopupComponent,
    ThereDSecureBackUrlComponent,
    EditSubscriptionComponent,
    EditSubscribtionFormComponent,
    ChangeCardComponent,
    ChangeCardFormComponent,
    DeleteCardConfirmationComponent,
    BillingHeaderComponent,
    PricingPageComponent,
    PaymentFormComponent,
    PaymentHeaderComponent
  ],
  exports: [...SHARED_COMPONENTS],
  providers: [BillingPublicApiService, PaymentFormGuardService]
})
export class BillingModule {}
