import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Board, Collection } from '../../interfaces';
import { CollectionDeleteAction } from '../../ngrx/actions/collection.actions';
import { Store } from '@ngrx/store';
import { Observer } from 'rxjs';

import { AppState } from '../../ngrx/state';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RouterNavigateService } from '../../shared/services/router-navigate.service';
import { COLLECTION_PL } from '../../constants/entity';
import { compareArrays } from '../../../helpers/array';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../shared/services/form-v2.service';

const replaceItems = initItems => currentItems => {
  let result: { add: any[]; remove: any[] } = { add: [], remove: [] };
  const initItemIds = initItems.map(item => item.id);
  const currentItemIds = currentItems.map(item => item.id);

  if (compareArrays(initItems, currentItems)) {
    return result;
  }

  result = initItemIds.reduce((acc, item) => {
    if (!currentItemIds.includes(item)) {
      acc.remove.push(item);
    }
    return acc;
  }, result);

  result = currentItemIds.reduce((acc, item) => {
    if (!initItemIds.includes(item)) {
      acc.add.push(item);
    }
    return acc;
  }, result);

  return result;
};

@Component({
  selector: 'edit-collection-form',
  templateUrl: './edit-collection-form.component.html',
  styleUrls: ['./edit-collection-form.component.scss'],
  providers: [FormV2Service],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditCollectionFormComponent implements OnInit {
  @Input() collection: Collection;
  @Input() selectedBoards: Board[];

  public collectionForm: FormGroup;
  public formService: FormV2Service;
  public isManageBoardsVisible = false;
  public isDeleteCollectionVisible = false;

  private formObserver: Observer<any> = {
    next: () => {
      this.onCLosePopup();
    },

    error: error => {
      console.log(error, 'error on component');
    },

    complete: () => {}
  };

  get boardsControl(): Board[] {
    return this.collectionForm.get('boards').value;
  }

  constructor(
    private _store: Store<AppState>,
    private _fb: FormBuilder,
    private _routerNav: RouterNavigateService,
    formService: FormV2Service
  ) {
    this.formService = formService;
  }

  ngOnInit() {
    this.collectionForm = this._fb.group({
      id: [this.collection.id],
      name: [this.collection.name, Validators.compose([Validators.required, Validators.maxLength(50)])],
      boards: [this.selectedBoards]
    });

    const replaceCollections = replaceItems(this.selectedBoards);
    const prepareFormValue = (formValue: {}) => {
      return Object.keys(formValue).reduce((preparedData, formKey) => {
        switch (true) {
          case formKey === 'boards': {
            preparedData[formKey] = replaceCollections(formValue[formKey]);
            break;
          }
          default: {
            preparedData[formKey] = formValue[formKey];
            break;
          }
        }
        return preparedData;
      }, {});
    };

    const formServiceParams: FormServiceParams = {
      saveType: FormSaveType.edit,
      entityToEdit: COLLECTION_PL,
      formObserver: this.formObserver,
      prepareFormValue: prepareFormValue
    };

    this.formService.initFormParams(this.collectionForm, formServiceParams);
  }
  onCLosePopup() {
    this._routerNav.deactivatePopupOutlet();
  }

  onDeleteCollection() {
    this._store.dispatch(new CollectionDeleteAction(this.collection.id));
    this.onCLosePopup();
    this.onDeleteCollectionToggle();
  }
  onManageBoardsToggle() {
    this.isManageBoardsVisible = !this.isManageBoardsVisible;
  }
  addBoard(board: Board) {
    this.collectionForm.get('boards').patchValue(this.collectionForm.get('boards').value.concat(board));
  }
  removeBoard(boardToRemove: Board) {
    this.collectionForm
      .get('boards')
      .patchValue(this.collectionForm.get('boards').value.filter(board => board.id !== boardToRemove.id));
  }

  onSubmit() {
    if (this.collectionForm.valid) {
      this.formService.submit();
    }
  }

  onDeleteCollectionToggle() {
    this.isDeleteCollectionVisible = !this.isDeleteCollectionVisible;
  }
}
