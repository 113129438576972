<div class="collection_container">
  <div class="project_tag"
       *ngFor="let collectionId of selectedCollections$ | async">
    <p>{{collectionEntities[collectionId]?.name}}</p>
    <div (click)="onRemoveMember(collectionId)" class="remove_project_control"></div>
  </div>
  <div (click)="onOpenPopup()" class="add_btn"></div>
  <div a-context-popup
       [title]="'Add to Collection'"
       *ngIf="showAddPopUp"
       (close)="onClosePopup()"
  >
    <collection-list-independent [selectedCollections$]="selectedCollections$"></collection-list-independent>
  </div>
</div>
