
import {fromEvent as observableFromEvent,  Observable } from 'rxjs';
import { Directive, Input, ElementRef, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { KeyCode } from '../../constants/key-code';

export interface HotKey {
  keyCode: number;
  altKey?: boolean;
  ctrlKey?: boolean;
  metaKey?: boolean;
  shiftKey?: boolean;
}

export const EnterHotkey = [{ keyCode: KeyCode.KEY_ENTER }];

export const HotSpaceAndEnter = [
  { metaKey: true, keyCode: KeyCode.KEY_ENTER },
  { metaKey: true, keyCode: KeyCode.KEY_SPACE },
  { ctrlKey: true, keyCode: KeyCode.KEY_ENTER },
  { ctrlKey: true, keyCode: KeyCode.KEY_SPACE }
];

@Directive({
  selector: '[hotKeys]'
})
export class HotKeyDirective implements OnInit, OnDestroy {
  @Input() hotKeys: HotKey[] | null;
  @Output() hotKey = new EventEmitter<KeyboardEvent>();

  private subscription;

  constructor(private _element: ElementRef) {}

  ngOnInit(): any {
    if (this.hotKeys) {
      this.subscription = observableFromEvent(
        this._element.nativeElement,
        'keydown'
      ).subscribe((event: KeyboardEvent) => {
        if (
          this.hotKeys.some(hotKey => !Object.keys(hotKey).some(hotKeyPart => event[hotKeyPart] !== hotKey[hotKeyPart]))
        ) {
          event.stopPropagation();
          event.preventDefault();
          this.hotKey.emit(event);
        }
      });
    }
  }

  ngOnDestroy(): any {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
