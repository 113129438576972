import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouterNavigateService } from '../shared/services/router-navigate.service';
import { InviteLinkService } from './services/invite-link.service';
import { BehaviorSubject ,  Observable ,  ReplaySubject } from 'rxjs';
import { AppUrls } from '../app-urls';

@Component({
  selector: 'invite-link-popup-component',
  templateUrl: './invite-link-popup.component.html',
  styleUrls: ['./invite-link-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InviteLinkPopupComponent implements OnInit {
  public loaded$ = new BehaviorSubject<boolean>(false);
  public inviteToken$: Observable<string>;
  public tokenExists$ = new ReplaySubject<boolean>(1);
  public showDeactivateConfirmation$ = new BehaviorSubject(false);
  public path: string;
  public massInviteLink = AppUrls.getUrlMassInviteMembers();

  constructor(
    private _router: ActivatedRoute,
    private _routerNavigate: RouterNavigateService,
    private _inviteLinkService: InviteLinkService
  ) {}

  ngOnInit() {
    this.path = this._router.snapshot.url[0].path;

    this.inviteToken$ = this._inviteLinkService.getToken();
    this.inviteToken$.subscribe(token => {
      this.loaded$.next(true);
      this.tokenExists$.next(!!token);
    });
  }

  onTokenDelete() {
    this._inviteLinkService.removeToken();
    this.tokenExists$.next(false);
    this.onCloseDeactivatePopup();
  }

  onTokenCreate() {
    this.tokenExists$.next(true);
  }

  onClose() {
    this._routerNavigate.deactivatePopupOutlet();
  }

  onShowDeactivatePopup() {
    this.showDeactivateConfirmation$.next(true);
  }

  onCloseDeactivatePopup() {
    this.showDeactivateConfirmation$.next(false);
  }
}
