
import {of as observableOf,  BehaviorSubject ,  Observable } from 'rxjs';

import {switchMap, map, tap, distinctUntilChanged, debounceTime, startWith, combineLatest} from 'rxjs/operators';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Column, Task } from '../../interfaces';
import { AppState } from '../../ngrx/state';
import { getSelectedBoardId } from '../../ngrx/reducers/board.reducer';
import { TaskDeleteAction, TaskEditAction } from '../../ngrx/actions/task.actions';
import { RemoveEntityFromStoreAction } from '../../ngrx/actions/root.action';
import { COLUMN_PL, TASK_PL } from '../../constants';
import { Paginator } from '../../ngrx/effects';
import { getEntitiesByFields } from '../../ngrx/functions/selectors';
import { COLUMN, LABEL_PL, SUBSCRIBER_PL, USER_PL } from '../../constants/';
import { SEARCH } from '../../path.routing';
import { PaginationLoaderService } from '../../shared/services/paginataion-loader/paginataion-loader.service';
import { RouterNavigateService } from '../../shared/services/router-navigate.service';
import { isParentColumn } from '../../../helpers/column';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'archived-items',
  templateUrl: './archived-items.component.html',
  styleUrls: ['./archived-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PaginationLoaderService]
})
export class ArchivedItemsComponent implements OnInit, OnDestroy {
  public tasks$: Observable<Task[]>;
  public columns$: Observable<Column[]>;
  public noItems$: Observable<boolean>;

  public showingEntity$ = new BehaviorSubject(TASK_PL);

  private boardId: number;

  query$ = new BehaviorSubject('');
  paginator$: Observable<Paginator>;
  hasMore = true;
  pending = false;

  TASK_PL = TASK_PL;
  COLUMN_PL = COLUMN_PL;

  private loader;

  private _subs = [];

  set subscriptions(sub) {
    this._subs.push(sub);
  }

  constructor(
    private _store: Store<AppState>,
    public _paginatorService: PaginationLoaderService,
    private _cd: ChangeDetectorRef,
    private _routerNav: RouterNavigateService,
    private _toastr: ToastrService
  ) {}

  ngOnInit() {
    this.subscriptions = this._store.pipe((getSelectedBoardId)).subscribe(boardId => (this.boardId = boardId));

    this.subscriptions = this.query$.pipe(
      debounceTime(400),
      startWith(''),
      distinctUntilChanged(),
      combineLatest(this.showingEntity$.pipe(distinctUntilChanged()), (query, entity) => {
        const controllerName = entity === TASK_PL ? (query.trim().length > 0 ? SEARCH : TASK_PL) : entity;
        return {
          query,
          entity,
          controllerName,
          responseHandler: resp => ([TASK_PL, COLUMN_PL].includes(controllerName) ? resp : resp[TASK_PL])
        };
      }),
      tap(_ => {
        const predicate = (entity: Task | Column) => !!entity.archived;
        this._store.dispatch(new RemoveEntityFromStoreAction({ entityName: TASK_PL, predicate: predicate }));
        this._store.dispatch(new RemoveEntityFromStoreAction({ entityName: COLUMN_PL, predicate: predicate }));
      }),)
      .subscribe(({ query, entity, controllerName }) => {
        this._paginatorService.controller = controllerName;
        this._paginatorService.limit = entity === COLUMN_PL ? 20 : 10;
        this._paginatorService.queryParams = {
          q: query,
          expand: [COLUMN, SUBSCRIBER_PL, LABEL_PL, USER_PL].join(','),
          archived: 1,
          board: this.boardId,
          boards: [this.boardId]
        };
        this._paginatorService.loadMore();
      });

    this.tasks$ = this._store.pipe((getEntitiesByFields(TASK_PL, { archived: 1, board: this.boardId })));
    this.columns$ = this._store.pipe(
      (getEntitiesByFields(COLUMN_PL, { archived: 1, board: this.boardId })),
      map(columns => columns.filter(isParentColumn)),);

    this.noItems$ = this._paginatorService.pending$.pipe(switchMap(
      pending =>
        pending
          ? observableOf(false)
          : this.showingEntity$.pipe(switchMap(
              entity =>
                entity === TASK_PL
                  ? this.tasks$.pipe(map(x => !x || x.length === 0))
                  : this.columns$.pipe(map(x => !x || x.length === 0))
            ))
    ));
  }

  ngOnDestroy() {
    this._subs.forEach(sub => sub.unsubscribe());
  }

  onSwitchArchive(switchTo) {
    if (this.showingEntity$.getValue() === switchTo) {
      return;
    }
    this.showingEntity$.next(switchTo);
  }

  onApplySearch(searchText: string) {
    this.query$.next(searchText);
  }

  onUnArchiveTask(task: Task): void {
    this._store.dispatch(new TaskEditAction({ id: task.id, archived: 0 }));
  }

  onDeleteTask(task: Task): void {
    this._toastr.success('Task was deleted');
    this._routerNav.deactivateOpenedTaskOutlet().then(_ => this._store.dispatch(new TaskDeleteAction(task)));
  }

  onLoadMore() {
    this._paginatorService.loadMore();
  }
}
