import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LabelTag } from '../../../../interfaces';

@Component({
  selector: 'labels-filter-list',
  templateUrl: './labels-filter-list.component.html',
  styleUrls: ['./labels-filter-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelsFilterListComponent implements OnInit {
  @Input() tags: LabelTag[];
  @Input() selectedLabels: LabelTag[];

  @Output() addLabel = new EventEmitter();
  @Output() removeLabel = new EventEmitter();

  public checkSelectedLabel: { [id: number]: boolean } = {};

  ngOnInit() {
    this.selectedLabels.forEach((label: LabelTag) => {
      this.checkSelectedLabel[label.id] = true;
    });
  }

  onSetFilter(label: LabelTag) {
    if (!this.checkSelectedLabel[label.id]) {
      this.checkSelectedLabel[label.id] = true;
      this.addLabel.emit(label);
    } else {
      this.checkSelectedLabel[label.id] = false;
      this.removeLabel.emit(label);
    }
  }
}
