<div class="editable_area" (click)="onSetInputFocus()">
  <div *ngFor="let email of manyEmails" class="email_tag">
    <p>{{email}}</p>
    <div class="remove_email_control" (click)="onRemoveEmailTag(email)"></div>
  </div>
  <input [focus]="emailFocus$"
         [formControl]="formValue"
         placeholder="{{manyEmails.length ? 'Add  More...' : ''}}"
         #emailsInput
         (keydown)="onAddEmailEvent($event, $event.target.value)"
         (paste)="onPaste()"
         (input)="onInput($event.target.value)"
         (blur)="onAddEmail($event.target.value)"
         [ngClass]="{not_valid_email: !formValue.valid && currentEmailNotValid}"
  >
</div>
