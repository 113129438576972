import { Component } from '@angular/core';

@Component({
  selector: 'header-bug-icon-svg',
  template: `
	  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
		  <style>
			  .sta0{fill:none;} .sta1{fill:#455A64;}
		  </style>
		  <path id="Shape" class="sta0" d="M0 0h24v24H0z"/>
		  <path id="Shape_1_" class="sta1" d="M20 8h-2.8c-.5-.8-1.1-1.4-1.8-2L17 4.4 15.6 3l-2.2 2.2C13 5.1 12.5 5 12 5s-1 .1-1.4.2L8.4 3 7 4.4 8.6 6c-.7.6-1.3 1.2-1.8 2H4v2h2.1c-.1.3-.1.7-.1 1v1H4v2h2v1c0 .3 0 .7.1 1H4v2h2.8c1 1.8 3 3 5.2 3s4.1-1.2 5.2-3H20v-2h-2.1c0-.3.1-.7.1-1v-1h2v-2h-2v-1c0-.3 0-.7-.1-1H20V8zm-6 8h-4v-2h4v2zm0-4h-4v-2h4v2z"/>
	  </svg>
  `
})
export class HeaderBugSvgComponent {}
