import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'left-menu-context-menu',
  templateUrl: './left-menu-context-menu.component.html',
  styleUrls: ['./left-menu-context-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeftMenuContextMenuComponent {
  @Input() isOpened: boolean;
  @Output() close = new EventEmitter();
  @Output() open = new EventEmitter();

  public menuTop = 0;
  public menuLeft = 0;
  constructor() {}

  onClose(event) {
    event.stopPropagation();
    this.close.emit();
  }

  onOpen(event) {
    const rect = event.currentTarget.getBoundingClientRect();
    this.menuLeft = rect.left - 142;
    this.menuTop = rect.top + 18;
    event.stopPropagation();
    this.open.emit();
  }
}
