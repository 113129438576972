import { Component } from '@angular/core';

@Component({
  selector: 'label-check-svg',
  template: `
	  <svg width="14" height="11" viewBox="0 0 14 11" xmlns="http://www.w3.org/2000/svg">
		  <g fill="none" fill-rule="evenodd">
			  <path d="M-2-4h18v18H-2"/>
			  <path fill="#FFF" fill-rule="nonzero" d="M4.75 8.127L1.623 5 .558 6.057 4.75 10.25l9-9L12.693.192"/>
		  </g>
	  </svg>
  `
})
export class LabelCheckSvgComponent {}
