<a-center-popup
  [title]="(type === ScoringType.ICE ? 'ICE' : 'RICE') + ' Scoring'"
  (close)="onClose()"
>
  <form [formGroup]="form">
    <div class="row" *ngFor="let factor of factors">
      <label class="label">{{factor.label + ' (' + factor.editInfo + ')'}}</label>
      <div [ngSwitch]="factor.type">
        <input [focus]="focusOn === factor.prop" *ngSwitchCase="FactorType.ice" focus="true" type="number" min="0" max="10" step="1" formControlName="{{factor.prop}}" (blur)="onValidateType(factor.type, factor.prop)">
        <input [focus]="focusOn === factor.prop" *ngSwitchCase="FactorType.percent" focus="true" type="number" min="0" max="100" step="1" formControlName="{{factor.prop}}" (blur)="onValidateType(factor.type, factor.prop)">
        <input [focus]="focusOn === factor.prop" *ngSwitchCase="FactorType.generalNum" focus="true" type="number" step="1" formControlName="{{factor.prop}}" (blur)="onValidateType(factor.type, factor.prop)">
        <select [focus]="focusOn === factor.prop" *ngSwitchCase="FactorType.select" focus="true" formControlName="{{factor.prop}}">
          <option [ngValue]="null"></option>
          <option [ngValue]="3">3</option>
          <option [ngValue]="2">2</option>
          <option [ngValue]="1">1</option>
          <option [ngValue]="0.5">0.5</option>
          <option [ngValue]="0.25">0.25</option>
        </select>
      </div>
    </div>

    <div class="buttons_anti_scroll_wrapper">
      <button type="submit" a-button class="btn_primary"
              [pending]="(_formService.isPending$ | async)"
              (click)="onSubmit()">
        Save
      </button>
      <button type="button" a-button class="btn_text"
              (click)="onClose()">
        Cancel
      </button>
    </div>
  </form>
</a-center-popup>
