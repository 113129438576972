import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormV2Service } from '../../shared/services/form-v2.service';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState, RIGHT_MENU } from '../../ngrx/state';
import { ComponentSetVisibleAction } from '../../ngrx/actions/component.actions';

@Component({
  selector: 'create-swimlane-popup',
  templateUrl: './create-swimlane-popup.component.html',
  styleUrls: ['./create-swimlane-popup.component.scss'],
  providers: [FormV2Service]
})
export class CreateSwimlanePopupComponent implements AfterViewInit {
  @Input() boardId: number;
  @Output() close = new EventEmitter();
  public submit$ = new Subject();

  constructor(public _formService: FormV2Service, private _store: Store<AppState>) {}

  ngAfterViewInit() {
    this._formService.registerObserver({
      next: () => {
        this.onClose();
        this._store.dispatch(new ComponentSetVisibleAction({ name: RIGHT_MENU, visible: false }));
      },
      error: () => null,
      complete: () => null
    });
  }

  onClose() {
    this.close.emit();
  }
}
