
import {map, distinctUntilChanged, pluck} from 'rxjs/operators';
import { ESInterface } from '../state';
import { Store } from '@ngrx/store';
import { editEntityState, addEntityState, deleteEntityState } from '../functions/reducer';
import { DefaultCollectionActionTypes } from '../actions/default-collection.actions';
import { DefaultCollection } from '../../interfaces/default-collection';
import { replaceIds } from '../../../helpers';
import { AppState } from '../state';
import { DEFAULT_COLLECTION_PL, ENTITY_PL } from '../../constants';
import { defaultCollectionTypes } from '../../constants/default-collections';
import { isBoardInDefaultCollection } from '../functions/selectors';
import { Action } from '../actions/unsafe-action';

const initialState: ESInterface<DefaultCollection> = {
  ids: [],
  entities: {},
  selectedEntityId: null
};

export function reducer(state = initialState, action: Action): ESInterface<DefaultCollection> {
  switch (action.type) {
    case DefaultCollectionActionTypes.ADD_COMPLETE: {
      return addEntityState(state, action.payload, true);
    }

    case DefaultCollectionActionTypes.DELETE: {
      return <ESInterface<DefaultCollection>>deleteEntityState(state, action.payload);
    }

    case DefaultCollectionActionTypes.GET_COMPLETE: {
      return Object.assign({}, state, {
        entities: Object.assign({}, state.entities, action.payload)
      });
    }

    case DefaultCollectionActionTypes.EDIT: {
      const boardsIds = state.entities[action.payload.id]
        ? replaceIds(state.entities[action.payload.id].boardsIds, action.payload.boards)
        : [];
      return editEntityState(state, Object.assign({}, action.payload, { boardsIds }));
    }

    default: {
      return state;
    }
  }
}

export const getFavoriteBoard = boardId => {
  return (store$: Store<AppState>) => {
    return store$.pipe(
      pluck(DEFAULT_COLLECTION_PL, ENTITY_PL, defaultCollectionTypes.favorites),
      distinctUntilChanged(),
      map(isBoardInDefaultCollection(boardId)),);
  };
};
