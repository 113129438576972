
import {map, distinctUntilChanged, pluck} from 'rxjs/operators';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, EntityState, ESInterface } from '../../../ngrx/state/app-state';
import { getEntitySelector } from '../../../ngrx/functions/selectors';
import { PROJECT_PL } from '../../../constants/entity';
import { BehaviorSubject } from 'rxjs';
import { Project } from '../../../interfaces/project';

@Component({
  selector: 'project-list-independent',
  template: `
    <input type="text" #nameFilterRef name="name" [focus]="true" placeholder="Search for Projects" (keyup)="0" #input_search_projects/>
    <div class="projects_container">
      <div *ngFor="let projectId of projectsState.ids
      | filterEntitiesByField : projectsState.entities : 'name' : nameFilterRef.value"
           [ngClass]="{project_title: true, disabled_project_title: disabledProjects.includes(projectId)}"
           class="project_title "
           (click)="onSelectProject(projectId)"
      >
        <p>
          {{projectsState.entities[projectId]?.name}}
          <span class="check_label" *ngIf="isSelectedProject(projectId)"></span>
        </p>
      </div>
    </div>
  `,
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListIndependentComponent implements OnInit, OnDestroy {
  @Input() selectedProjects: BehaviorSubject<number[]>;
  @Input() disabledProjects: number[];
  @Input() multiple = true;

  public projectsState: ESInterface<Project>;
  public subscription;

  constructor(private _store: Store<AppState>) {}

  get selectedProjects$() {
    return this.selectedProjects;
  }

  ngOnInit(): any {
    this.subscription = this._store.pipe(
      pluck(PROJECT_PL),
      distinctUntilChanged(),
      map(EntityState.from),
      map(state => state.filter((project: Project) => !project.archived)),)
      .subscribe((state: ESInterface<Project>) => (this.projectsState = state));
  }

  ngOnDestroy(): any {
    this.subscription.unsubscribe();
  }

  isSelectedProject(projectId: number) {
    this.selectedProjects$.getValue();
    return this.selectedProjects$.getValue().includes(projectId);
  }

  onSelectProject(projectId: number) {
    if (this.disabledProjects.includes(projectId)) {
      return false;
    }

    let currentValue = this.selectedProjects$.getValue();
    if (currentValue.includes(projectId)) {
      this.selectedProjects$.next(currentValue.filter(existsProject => existsProject !== projectId));
    } else {
      if (!this.multiple) {
        return this.selectedProjects$.next([projectId]);
      }
      currentValue = [...currentValue];
      currentValue.push(projectId);
      this.selectedProjects$.next(currentValue);
    }
  }
}
