<a-center-container>
  <div a-popup
       [title]="confirmationTitle"
       (close)="onDismiss()"
       class="popup"
  >
    <p class="message">
      <ng-content></ng-content>
    </p>
    <div>
      <div a-button [pending]="isPending" [class]="actionButtonClass" (click)="onConfirm()">{{actionButtonText}}</div>
      <div a-button [class]="dismissButtonClass" (click)="onDismiss()">{{dismissButtonText}}</div>
    </div>
  </div>
  <div class="overlay" (click)="onDismiss()"></div>
</a-center-container>
