import { BehaviorSubject, fromEvent as observableFromEvent, interval as observableInterval, Subscription } from 'rxjs';

import {
  bufferTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
  switchMap,
  switchMapTo,
  take,
  takeWhile,
  tap
} from 'rxjs/operators';
import { Injectable, NgZone } from '@angular/core';

import * as io from 'socket.io-client';

import { CompanyService } from '../company.service';
import { AuthService } from '../auth.service';

import { Store } from '@ngrx/store';

import { AppState } from '../../../../ngrx/state/';
import { consoleLog, identity, isNotEmpty, isPresent } from '../../../../../helpers/';

import { NetworkConnectionService } from '../network-connection.service';
import {
  DataSyncObjectDelete,
  DataSyncObjectState,
  DataSyncSetLastUpdatedTimeAction,
  DataSyncUpdateConnectionStatus
} from '../../../../ngrx/actions/data-sync.action';

import { flatten } from 'ramda';
import {
  ObjectDelete,
  ObjectState,
  WebSocketAnswer,
  WebSocketDeleteAnswer,
  WebSocketEvent
} from '../../../../ngrx/reducers/data-sync.reducer';
import { ColumnLoadAction } from '../../../../ngrx/actions/column.actions';
import { BOARD_PL, COLLECTION_PL, SPRINT_TASK_PL, TASK_PL } from '../../../../constants';
import { BoardLoadAction, RoadMapPDFGenerated } from '../../../../ngrx/actions/board.actions';
import { PROJECT_PL } from '../../../../constants/';
import { HandleResponseAction } from '../../../../ngrx/actions/root.action';

export const OBJECT_STATE = 'object.state';
export const OBJECT_DELETE = 'object.delete';
export const QUEUE_JOB = 'queue-job';
export const QUEUE_JOB_PL = 'queue-jobs';
const JOIN_ROOMS = 'JOIN_ROOMS';
const LEAVE_ROOMS = 'LEAVE_ROOMS';

export type SocketEventName = 'object.state' | 'object.delete' | 'queue-job';

const socketManagerConfig: SocketIOClient.ConnectOpts = {
  transports: ['websocket'],
  forceNew: true,
  reconnection: true,
  reconnectionAttempts: 5
};

const createSocketConnection = (url: string) => io(url, socketManagerConfig);

const socketAuthorization = (socket, token) => {
  return socket.emit('authorization', { accessToken: token }, identity);
};

const reconnectToSocket = (socket, token) => {
  socket.connect();
  socketAuthorization(socket, token);
};

const toData = (wsAnswer: WebSocketAnswer) => wsAnswer.data;
const lastServerTime = (wsAnswer: WebSocketEvent[]) =>
  wsAnswer.reduce((newestServerTime, currentEvent) => Math.max(newestServerTime, currentEvent.serverTime), 0);

export const prepareObjectState = (wsAnswer: WebSocketAnswer[]): ObjectState => ({
  models: flatten(wsAnswer.map(toData)),
  serverTime: lastServerTime(wsAnswer)
});

const prepareObjectDelete = (wsAnswer: WebSocketDeleteAnswer[]): ObjectDelete => ({
  serverTime: lastServerTime(wsAnswer),
  deleteData: wsAnswer
});

@Injectable()
export class SocketService {
  private _socketInstance$ = new BehaviorSubject(undefined);
  private _loadColumnParams = { sort: 'position', expand: TASK_PL + ',' + TASK_PL + '.' + SPRINT_TASK_PL };
  private _loadBoardParams = {
    expand: [COLLECTION_PL, PROJECT_PL, PROJECT_PL + '.' + BOARD_PL + '.id', COLLECTION_PL + '.' + BOARD_PL + '.id']
  };
  private _pingTimeout;
  private _pingInterval;

  get socket$() {
    return this._socketInstance$.pipe(filter(isPresent));
  }

  get newState$() {
    return this.socket$.pipe(switchMap(socket => observableFromEvent(socket, OBJECT_STATE)));
  }

  get newQueueJob$() {
    return this.socket$.pipe(switchMap(socket => observableFromEvent(socket, QUEUE_JOB)));
  }

  get deleteModel$() {
    return this.socket$.pipe(switchMap(socket => observableFromEvent(socket, OBJECT_DELETE)));
  }

  get joinRooms$() {
    return this.socket$.pipe(switchMap(socket => observableFromEvent(socket, JOIN_ROOMS)));
  }

  get leaveRooms$() {
    return this.socket$.pipe(switchMap(socket => observableFromEvent(socket, LEAVE_ROOMS)));
  }

  get connect$() {
    return this.socket$.pipe(switchMap(socket => observableFromEvent(socket, 'connect')));
  }

  get disconnect$() {
    return this.socket$.pipe(switchMap(socket => observableFromEvent(socket, 'disconnect')));
  }

  constructor(
    private _authService: AuthService,
    private _companyService: CompanyService,
    private _store: Store<AppState>,
    protected _networkConnection: NetworkConnectionService,
    private _zone: NgZone
  ) {}

  init() {
    this._zone.runOutsideAngular(() => {
      this.connectToSocket();

      this.initOnConnectHandler();
      this.initOnDisconnectHanlder();
    });
    this.onNewState();
    this.onNewQueueJob();
    this.onChangeRooms();
    this.onDelete();
    this.initOnPingResultHandler();
  }

  onNewState(): Subscription {
    return this.newState$
      .pipe(bufferTime(1000), filter(isNotEmpty), map(prepareObjectState), tap(consoleLog(OBJECT_STATE)))
      .subscribe(objectState => {
        this._store.dispatch(new DataSyncObjectState(objectState.models));
        this._store.dispatch(new DataSyncSetLastUpdatedTimeAction({ time: objectState.serverTime }));
      });
  }

  initOnPingResultHandler(): Subscription {
    return this.socket$.pipe(switchMap(socket => observableFromEvent(socket, 'ping-result'))).subscribe(_ => {
      clearTimeout(this._pingTimeout);
    });
  }

  onNewQueueJob(): Subscription {
    return this.newQueueJob$
      .pipe(bufferTime(1000), filter(isNotEmpty), map(prepareObjectState), tap(consoleLog(QUEUE_JOB)))
      .subscribe(jobResult => this.initLoadingOfJobContent(jobResult, this._store));
  }

  onDelete(): Subscription {
    return this.deleteModel$
      .pipe(tap(consoleLog(OBJECT_DELETE)), map(toDelete => prepareObjectDelete([toDelete])))
      .subscribe(objectDelete => {
        this._store.dispatch(new DataSyncObjectDelete(objectDelete.deleteData));
        this._store.dispatch(new DataSyncSetLastUpdatedTimeAction({ time: objectDelete.serverTime }));
      });
  }

  onChangeRooms(): Subscription {
    return this.joinRooms$
      .pipe(merge(this.leaveRooms$), tap(consoleLog('changeRoom')))
      .subscribe(join => console.log(join, 'change rooms'));
  }

  connectToSocket(): Subscription {
    return this._authService.isLoggedIn$
      .pipe(
        filter(isPresent),
        distinctUntilChanged(),
        tap(consoleLog('TRY CONNECT TO SOCKET')),
        map(this.connect.bind(this))
      )
      .subscribe(socket => this._socketInstance$.next(socket));
  }

  initOnConnectHandler(): Subscription {
    return this.connect$.subscribe(_ => {
      this._store.dispatch(new DataSyncUpdateConnectionStatus({ isSocketConnected: true }));
      console.log('SOCKET CONNECTED');
      this.initPing();
    });
  }

  initOnDisconnectHanlder(): Subscription {
    const tryingInterval$ = observableInterval(1000).pipe(
      switchMapTo(this._networkConnection.isOnline$.pipe(takeWhile(identity)))
    );

    return this.disconnect$
      .pipe(
        tap(consoleLog('SOCKET DISCONNECTED')),
        tap(_ => this._store.dispatch(new DataSyncUpdateConnectionStatus({ isSocketConnected: false }))),
        switchMapTo(tryingInterval$),
        switchMapTo(this._socketInstance$.pipe(takeWhile(socket => socket.connected === false))),
        tap(() => clearInterval(this._pingInterval)),
        tap(consoleLog('try to connect'))
      )
      .subscribe(this.reconnect.bind(this));
  }

  initPing() {
    this.socket$.pipe(take(1)).subscribe(socket => {
      clearInterval(this._pingInterval);
      this._pingInterval = setInterval(() => {
        try {
          socket.emit('ping-me', +new Date());
        } catch (e) {
          console.log('Failed to send ping', e);
        }

        this._pingTimeout = setTimeout(() => {
          console.log('Pong has not been recieved');
          try {
            socket.close();
          } catch (e) {
            console.log('Error while closing socket connection', e);
          }
        }, 5000);
      }, 10000);
    });
  }

  public initLoadingOfJobContent(jobResult, store) {
    const attributes = jobResult.models[0]['attributes'];
    if (attributes.status === 'done') {
      switch (attributes.type) {
        case 'tasks-mass-move': {
          store.dispatch(new ColumnLoadAction({ id: attributes.params.fromColumnId, params: this._loadColumnParams }));
          if (attributes.params.fromColumnId !== attributes.params.toColumnId) {
            store.dispatch(new ColumnLoadAction({ id: attributes.params.toColumnId, params: this._loadColumnParams }));
          }
          break;
        }
        case 'column-copy': {
          store.dispatch(new ColumnLoadAction({ id: attributes.params.newColumnId, params: this._loadColumnParams }));
          break;
        }
        case 'board-copy': {
          store.dispatch(new BoardLoadAction({ id: attributes.params.toBoardId, params: this._loadBoardParams }));
          break;
        }
        case 'gitlab-repositories': {
          store.dispatch(new HandleResponseAction({ data: attributes.params.included }));
          break;
        }
        case 'roadmap-pdf-generator': {
          store.dispatch(new RoadMapPDFGenerated(attributes));
          break;
        }
      }
      store.dispatch(new DataSyncSetLastUpdatedTimeAction({ time: jobResult.serverTime }));
    }
  }

  private connect() {
    const socket = createSocketConnection(this._companyService.getSocketUrl());
    socketAuthorization(socket, this._authService.socketioAccessToken);
    return socket;
  }

  private reconnect(socket) {
    reconnectToSocket(socket, this._authService.socketioAccessToken);
  }
}
