<div class="pseudo_select" (click)="onToggleDropdown()">
  <span class="pseudo_select_value">{{swimlanesLabels}}</span>
</div>
<div *ngIf="this.isSwimlanesSelectOpened$ | async" class="dropdown_wrap">
  <div (clickOutEvent)="onToggleDropdown()" class="dropdown">
    <p (click)="onSelectAll()">All</p>
    <p *ngFor="let swimlane of swimlanes" (click)="onSelectSwimlane(swimlane)">
      {{swimlane.name}}
      <span *ngIf="selected[swimlane.id]" class="select_marker"></span>
    </p>
  </div>
  <overlay class="transparent" (click)="onToggleDropdown()"></overlay>
</div>
