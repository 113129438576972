import { FormControl, Validators } from '@angular/forms';
import { CustomValidators, PatternValidator } from '../custom-validators';
import * as moment from 'moment-mini-ts';

export const board = {
  name: Validators.compose([
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(50),
    PatternValidator.pattern(/[^\s]/)
  ]),
  sprintEndDate: CustomValidators.composeAnd([
    Validators.required,
    (c: FormControl) => (c.value && c.value.unix() < moment().unix() ? { tooSmallDate: true } : null)
  ])
};
