<div class="header-container" [class.sticky]="(isStickyLeft$ | async)">
  <div class="header_wrapper clearfix">
    <ng-container *ngIf="(isAllowPage$ | async); else logo">
      <div class="left_block" *ngIf="userInActiveCompany$ | async">
        <div *ngIf="!(isStickyLeft$ | async) || isMenuIconArrowBack" class="menu_nav_block" [ngClass]="{'menu_icon_animated': isMenuIconArrowBack }"
          (click)="onToggleLeftMenu()">
          <div class="notifications_marker" *ngIf="(notificationsCount$ | async) && !isMenuIconArrowBack"></div>
          <div class="menu_nav_trigger"></div>
        </div>
        <search-box *ngIf="isAuth$ | async" (showBackButton)="onAnimateMenu($event)"></search-box>
        <quick-add *ngIf="isNotGuest$ | async"></quick-add>
      </div>
      <a [routerLink]="logoUrl$ | async">
        <ng-template [ngTemplateOutlet]="logo"></ng-template>
      </a>

      <div class="right_block">
        <div class="header_project">

          <!--<a href="https://hygger.zendesk.com/hc/ru" target="_blank" rel="noopener" class="html"></a>  HTML ICON-->
          <!--<a href="mailto:support@hygger.io" class="bitbucket"></a> BITBUCKET ICON-->
          <!--<div class="github"></div>-->
          <!--<div class="trello"></div>-->
          <billing-actions-buttons *ngIf="isSuperUser$ | async" [isUnlockHidden]="true"></billing-actions-buttons>
          <a *ngIf="isSuperUser$ | async" [routerLink]="appUrls.getUrlMassInviteMembers()" [skipLocationChange]="true" class="text_btn invite_members_link">
            <span class="invite_text_link">+ Invite Members</span>
            <span class="invite_icon" a-tooltip="Invite Members" position="bottom">+</span>
          </a>
          <a *ngIf="(isSuperUser$ | async) && (trialEndDate$ | async) === 1544572800 && (trialEndDateCount$ | async)" class="btn_billing"  [routerLink]="appUrls.getUrlCompanySettingsBilling()">
            Your trial ends {{(trialEndDateCount$ | async)}}
          </a>
          <a a-tooltip="Reporting" position="bottom" class="header_icon reporting_icon_link" [routerLink]="'/reporting'" *ngIf="(userInActiveCompany$ | async) && (isNotGuest$ | async)"
             [routerLinkActive]="'reporting-link-active'">
            <svg viewBox="0 0 18 21" width="18" height="21">
              <use xlink:href="#report"></use>
            </svg>
          </a>
	        <div class="header_icon info_icon">
		        <header-info-icon-svg (click)="onToggleHeaderMenu()"></header-info-icon-svg>
            <div a-context-menu (click)="onToggleHeaderMenu()" *ngIf="isHeaderMenuVisible" class="header_menu">
              <header-info-menu (closeMenu)="onToggleHeaderMenu()"></header-info-menu>
            </div>
	        </div>

        </div>
        <div class="user_menu_block" *ngIf="activeUser$ | async">
          <div (click)="onToggleUserMenu()">
            <a-avatar [openPreviewOnClick]="false" class="user_avatar" [user]="activeUser$ | async"></a-avatar>
          </div>
          <div a-context-menu-legasy *ngIf="isVisibleProfileMenu" class="profile_menu right" (close)="onToggleUserMenu($event)">
            <profile-menu [user]="activeUser$ | async" (closeMenu)="onToggleUserMenu($event)">
            </profile-menu>
          </div>
        </div>
        <!--<div class="user_notification"></div> -->
      </div>

    </ng-container>
  </div>
</div>
<div class="header-container-mirror"></div>

<ng-template #logo>
  <div class="logo" [ngStyle]="{'background-image': (logoSrc$ | async)}"></div>
</ng-template>

