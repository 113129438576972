<div class="edit_container">
  <div [ngClass]="{title_block: true, editable: editPermissions}" *ngIf="!isEdit">
    <div class="text" (click)="onEditToggle()">{{visibleValue}}</div>
    <div  class="input_ghost"></div>
  </div>
  <div *ngIf="isEdit"
       [clickOut]="isEdit"
       (clickOutEvent)="onEditToggle()"
  >
    <div [ngSwitch]="scaleType">
      <input *ngSwitchCase="ScaleType.basic"
             [formControl]="formValue"
             type="number"
             min="0" max="100"
             [selectText]="true"
             [hotKeys]="hotKeys"
             (hotKey)="onHotKey()"
             (keyup)="checkValidation()"
      >
      <select *ngSwitchCase="ScaleType.poker"
             [formControl]="formValue"
             (hotKey)="onHotKey()"
      >
        <option [value]="0">?</option>
        <option [value]="1">1</option>
        <option [value]="2">2</option>
        <option [value]="3">3</option>
        <option [value]="5">5</option>
        <option [value]="8">8</option>
        <option [value]="13">13</option>
        <option [value]="20">20</option>
        <option [value]="40">40</option>
        <option [value]="100">100</option>
      </select>
      <select *ngSwitchCase="ScaleType.shirt"
              [formControl]="formValue"
              (hotKey)="onHotKey()"
      >
        <option [value]="0">?</option>
        <option [value]="1">S</option>
        <option [value]="2">M</option>
        <option [value]="3">L</option>
        <option [value]="4">XL</option>
        <option [value]="5">XXL</option>
      </select>
    </div>
  </div>
</div>
