import { take, map } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TasksLinksType } from '../../../interfaces';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../../shared/services/form-v2.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TASKS_LINK_PL } from '../../../constants';
import { fromCamelToDash, isPresent } from '../../../../helpers';
import { Observable } from 'rxjs';
import { getTasksLinksTypes } from '../../../ngrx/reducers/tasks-links-type.reducer';
import { AppState } from '../../../ngrx/state';
import { Store } from '@ngrx/store';
import { CustomValidators } from '../../../shared/validators/custom-validators';
import { HandleResponseAction } from '../../../ngrx/actions/root.action';
import { UnsavedFormChangesService } from '@atlaz/core/services/unsaved-form-changes.service';

@Component({
  selector: 'task-links-add-form',
  templateUrl: './task-links-add-form.component.html',
  styleUrls: ['./task-links-add-form.component.scss'],
  providers: [FormV2Service, UnsavedFormChangesService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskLinksAddFormComponent implements OnInit {
  @Output() close = new EventEmitter();
  @Input() taskId: number;
  @Input() boardId: number;

  public possibleTypes$: Observable<{ id: number; name: string }[]>;
  public form: FormGroup;
  public formServiceParams: FormServiceParams;
  public inputs;
  public createTaskPopup: boolean;
  public createCallBack;
  constructor(
    private _fb: FormBuilder,
    public _formService: FormV2Service,
    private _store: Store<AppState>,
    private _unsavedFormChangesService: UnsavedFormChangesService
  ) {}

  ngOnInit() {
    this.possibleTypes$ = this._store.select(getTasksLinksTypes).pipe(
      map((types: TasksLinksType[]) =>
        types.reduce((acc, item) => {
          acc.push({ id: item.id, name: item.outwardDesc });
          acc.push({ id: -item.id, name: item.inwardDesc });
          return acc;
        }, [])
      )
    );
    this.possibleTypes$.pipe(take(1)).subscribe(possibleTypes => {
      this.form = this._fb.group({
        type: [possibleTypes[0].id, Validators.required],
        outwardTask: [this.taskId, Validators.required],
        inwardTasks: this._fb.array([''], CustomValidators.arrayNotEmpty)
      });
      this.formServiceParams = {
        formObserver: {
          next: resp => {
            this.close.emit();
            this._store.dispatch(new HandleResponseAction(resp));
          },
          error: _ => {},
          complete: () => {}
        },
        saveType: FormSaveType.add,
        entityToEdit: fromCamelToDash(TASKS_LINK_PL),
        prepareFormValue: (formValue: any) => {
          const result = { tasksLinks: [] };
          if (formValue.type > 0) {
            result.tasksLinks = formValue.inwardTasks.filter(isPresent).map(inwardTask => ({
              outwardTask: formValue.outwardTask,
              inwardTask: inwardTask,
              type: formValue.type
            }));
          } else {
            result.tasksLinks = formValue.inwardTasks.filter(isPresent).map(inwardTask => ({
              outwardTask: inwardTask,
              inwardTask: formValue.outwardTask,
              type: -formValue.type
            }));
          }
          return result;
        }
      };
      this._formService.initFormParams(this.form, this.formServiceParams);
      const unsavedFormChangesConfig = {
        allowedFields: ['type'],
        autoSave: false
      };
      this._unsavedFormChangesService.init(this.form, this.unsavedCacheKey(), unsavedFormChangesConfig);
    });
  }

  onSubmit() {
    this._formService.markAsDirty();
    if (this.form.valid) {
      this._unsavedFormChangesService.save();
      this._formService.submit();
    }
  }

  onCancel() {
    this.close.emit();
  }

  onAddTaskInput() {
    this.inputs = this.form.get('inwardTasks') as FormArray;
    this.inputs.push(new FormControl(''));
  }

  onOpenCreateTaskPopup(callBack) {
    this.createTaskPopup = true;
    this.createCallBack = callBack;
  }

  onCloseCreateTaskPopup() {
    this.createTaskPopup = false;
  }
  onTaskCreated(taskId) {
    if (this.createCallBack) {
      this.createCallBack(taskId + '');
    }
  }

  private unsavedCacheKey() {
    return ['add-task-link', this.boardId].join(':');
  }
}
