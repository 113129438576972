import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { distinctUntilChanged, filter, map, publishReplay, refCount, switchMap, take, tap } from 'rxjs/operators';
import { Board, Column, Swimlane, Version } from '../../../../interfaces/index';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormSaveType, FormServiceParams, FormV2Service } from '../../../services/form-v2.service';
import { Store } from '@ngrx/store';
import { UnsavedFormChangesService } from '../../../../core/services/unsaved-form-changes.service';
import { AppState } from '../../../../ngrx/state/index';
import { RouterNavigateService } from '../../../services/router-navigate.service';
import { TASK_PL } from '../../../../constants/index';
import { HandleResponseAction } from '../../../../ngrx/actions/root.action';
import { fromBoards } from '../../../../ngrx/reducers/board.reducer';
import { SegmentTrackTaskCreatedAction } from '../../../../ngrx/actions/segment.actions';
import { ToastrService } from 'ngx-toastr';
import { getSwimlanesByBoard } from '../../../../ngrx/reducers/swimlane.reducer';
import { getTargetColumns } from '../../../../ngrx/reducers/column.reducer';
import { TaskDetailPageRelatedDataService } from '../../../../task/task-detail-page/services/task-detail-page-related-data.service';
import { Subscription } from 'rxjs/Subscription';
import { getVersionsListByBoardId } from '../../../../ngrx/functions/crossed.selector';
import { naturalSort } from '../../../../../helpers';
import { of } from 'rxjs/internal/observable/of';

@Component({
  selector: 'global-add-task-form',
  templateUrl: './global-add-task-form.component.html',
  styleUrls: ['./global-add-task-form.component.scss'],
  providers: [FormV2Service, UnsavedFormChangesService]
})
export class GlobalAddTaskFormComponent implements OnInit, OnDestroy {
  @Input() hideSaveAndEdit: boolean;
  @Input() showColumn: boolean;
  @Input() showSwimlane: boolean;
  @Input() showVersion: boolean;
  @Input() initialVersion: number;
  @Input() fixedBoard: number;
  @Input() cacheKey = 'global-add-task-target';
  @Output() close = new EventEmitter();
  @Output() taskCreated = new EventEmitter();

  public targetBoards$: Observable<Board[]>;
  public targetColumns$: Observable<Column[]>;
  public targetSwimlanes$: Observable<Swimlane[]>;
  public targetVersions$: Observable<Version[]>;
  public _versions: Version[];
  public form: FormGroup;
  public openTaskAfterSave = false;
  public initialColumnValue: number;
  public initialSwimlaneValue: number;
  public subs: Subscription[] = [];

  formObserver: Observer<any> = {
    next: resp => {
      this._store.dispatch(new HandleResponseAction(resp));
      this.targetBoards$.pipe(take(1)).subscribe(boards =>
        boards.some(board => {
          if (board.id === +this.form.get('board').value) {
            this._store.dispatch(
              new SegmentTrackTaskCreatedAction({
                boardType: board.type,
                taskType: 'native',
                source: 'header'
              })
            );
            return true;
          }
        })
      );
      this.initialColumnValue = this.form.value.column;
      this.initialSwimlaneValue = this.form.value.swimlane;
      this.form.get('title').patchValue('');
      this._unsavedFormChangesService.save();
      if (this.openTaskAfterSave) {
        this._routerNav.navigateToTask(resp.data.id);
      } else {
        this._toastr.success('Task has been successfully created');
      }
      this.taskCreated.emit(resp.data.id);
      this.close.emit();
      this.onClose();
    },
    error: error => {
      console.warn(error);
    },
    complete: () => {}
  };

  public formServiceParams: FormServiceParams = {
    saveType: FormSaveType.add,
    entityToEdit: TASK_PL,
    formObserver: this.formObserver,
    prepareFormValue: formValue => {
      if (+formValue['version'] < 0) {
        formValue['version'] = 0;
      }
      if (formValue['version']) {
        try {
          formValue['project'] = this._versions.filter(item => item.id === formValue['version'])[0].project;
        } catch (e) {
          console.log('Error getting project form version ' + formValue['version']);
        }
      }
      return formValue;
    }
  };

  constructor(
    private _store: Store<AppState>,
    private _toastr: ToastrService,
    private _routerNav: RouterNavigateService,
    private _fb: FormBuilder,
    public _formService: FormV2Service,
    private _unsavedFormChangesService: UnsavedFormChangesService,
    private _detailLoader: TaskDetailPageRelatedDataService
  ) {}

  ngOnInit() {
    const controlsConfig = {
      title: ['', Validators.required],
      board: [this.fixedBoard || '', Validators.required],
      inSwimlanePosition: ['last']
    };
    const unsavedFormChangesConfig = {
      allowedFields: ['board', 'title'],
      autoSave: true
    };

    if (this.showColumn) {
      controlsConfig['column'] = ['', Validators.required];
      unsavedFormChangesConfig.allowedFields.push('column');
    }
    if (this.showSwimlane) {
      controlsConfig['swimlane'] = ['', Validators.required];
      unsavedFormChangesConfig.allowedFields.push('swimlane');
    }
    if (this.showVersion) {
      controlsConfig['version'] = [+this.initialVersion || ''];
    }

    this.targetBoards$ = this._store.select(fromBoards.getAllAvailable);
    this.form = this._fb.group(controlsConfig);

    if (this.showColumn || this.showSwimlane || this.showVersion) {
      const targetBoard$ = (this.fixedBoard ? of(this.fixedBoard) : this.form.get('board').valueChanges).pipe(
        publishReplay(1),
        refCount()
      );

      this.subs.push(
        targetBoard$.subscribe(boardId => {
          if (boardId) {
            this._detailLoader.loadBoard(boardId);
          }
        })
      );

      this.targetColumns$ = targetBoard$.pipe(
        distinctUntilChanged(),
        switchMap(boardId => this._store.pipe(getTargetColumns(+boardId, 'position'))),
        tap((columns: Column[]) => {
          if (columns.length) {
            const isSoredValueValid =
              this.initialColumnValue && columns.map(item => item.id).includes(+this.initialColumnValue);
            this.form.get('column').patchValue(isSoredValueValid ? +this.initialColumnValue : columns[0].id);
            this.initialColumnValue = 0;
          }
        })
      );

      this.targetSwimlanes$ = targetBoard$.pipe(
        distinctUntilChanged(),
        switchMap(boardId => this._store.pipe(getSwimlanesByBoard(+boardId))),
        tap((swimlanes: Swimlane[]) => {
          if (swimlanes.length) {
            const isSoredValueValid =
              this.initialSwimlaneValue && swimlanes.map(item => item.id).includes(+this.initialSwimlaneValue);
            this.form.get('swimlane').patchValue(isSoredValueValid ? +this.initialSwimlaneValue : swimlanes[0].id);
            this.initialSwimlaneValue = 0;
          }
        })
      );

      this.targetVersions$ = targetBoard$.pipe(
        distinctUntilChanged(),
        switchMap(boardId => this._store.select(getVersionsListByBoardId(+boardId))),
        tap(versions => (this._versions = versions)),
        map(versions => naturalSort('fullname')(versions.filter(v => !v.released)))
      );
    }

    this._unsavedFormChangesService.init(this.form, this.cacheKey, unsavedFormChangesConfig);

    this.initialColumnValue = this.showColumn ? +this.form.get('column').value : 0;
    this.initialSwimlaneValue = this.showSwimlane ? +this.form.get('swimlane').value : 0;

    this.targetBoards$.pipe(take(1), filter(boards => !!boards.length)).subscribe(boards => {
      const storedBoardValue = +this.form.get('board').value;
      const isLastUsedBoardAvailable = boards.some(board => board.id === storedBoardValue);
      if (!isLastUsedBoardAvailable) {
        this.form.get('board').patchValue(boards[0].id);
      } else {
        this._detailLoader.loadBoard(storedBoardValue);
      }
    });

    this._formService.initFormParams(this.form, this.formServiceParams);
  }

  onClose(isFlushTitle = false) {
    if (isFlushTitle) {
      this.form.get('title').patchValue('');
      this._unsavedFormChangesService.save();
    }
    this.close.emit();
  }

  onSubmit(isOpenAfterSave = false) {
    this.trimTitle();
    this._formService.markAsDirty();
    if (this.form.invalid) {
      return;
    }
    if (isOpenAfterSave) {
      this.openTaskAfterSave = true;
    }
    this._formService.submit();
  }

  trimTitle() {
    if (this.form.get('column')) {
      this.initialColumnValue = this.form.get('column').value;
    }
    if (this.form.get('swimlane')) {
      this.initialSwimlaneValue = this.form.get('swimlane').value;
    }
    if (this.form.get('title')) {
      this.form.get('title').patchValue(this.form.get('title').value.trim());
    }
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
