import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ElementRef,
  HostListener,
  Input,
  Renderer2
} from '@angular/core';

@Component({
  selector: '[a-context-controll]',
  template: `
   <div a-context-menu-legasy class="no_overlay" *ngIf="isMenuVisible"
       (click)="doClose()"
       [ngClass]="{'slim': menuSlim, 'right': menuRight, 'top': menuTop}"
    >
      <ng-content></ng-content>
    </div>
    <div class="overlay" *ngIf="isMenuVisible && !noOverlay"></div>
  `,
  styleUrls: ['./a-context-controll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AContextControllComponent {
  @Input() isMenuVisible: boolean;
  @Input() menuSlim: boolean;
  @Input() menuRight: boolean;
  @Input() menuTop: boolean;
  @Input() noOverlay: boolean;

  @Input()
  get orientation(): string {
    return this.orientation;
  }
  set orientation(value: string) {
    this.setElementColor(value);
  }

  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(private _eref: ElementRef, private _renderer: Renderer2) {}

  doClose() {
    this.close.emit(null);
  }
  setElementColor(orientation: string) {
    this._renderer.addClass(this.getHostElement(), `${orientation}`);
  }

  getHostElement() {
    return this._eref.nativeElement;
  }

  @HostListener('click', ['$event'])
  onClick(event) {
    if (event.target === this._eref.nativeElement) {
      event.stopPropagation();
      event.stopImmediatePropagation();
      this.doClose();
      return false;
    }
  }
}
