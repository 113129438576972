import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Board, Swimlane, Task } from '../../../interfaces';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { map, publishReplay, refCount, switchMap, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../../ngrx/state';
import { getTasksByUsersFilter, inBoard, isActiveTask } from '../../../ngrx/reducers/task.reducer';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { fromSwimlanes } from '../../../ngrx/reducers/swimlane.reducer';
import { BoardInProjectAccess } from '../../../interfaces/board';
import { fromUsers } from '../../../ngrx/reducers/user.reducer';
import { fromLabels } from '../../../ngrx/reducers/label.reducer';
import { CustomField, fromCustomFields } from '../../../ngrx/reducers/custom-field.reducer';
import { DefaultTaskStatusesFilterToggleItemAction } from '../../../ngrx/actions/task-filters/default-statuses-filter.actions';
import { columnTypes } from '../../../constants';
import { both, getVisibleScoringVal } from '../../../../helpers';
import { BoardQuickFiltersComponent } from '../../board-quick-filters/board-quick-filters.component';
import { UsersCacheService } from '@atlaz/core/services/users-cache.service';
import { Subscription } from 'rxjs/index';
import { getColumnsByBoard } from '../../../ngrx/reducers/column.reducer';
import { TaskEditAction } from '../../../ngrx/actions/task.actions';
import { fromGuiState } from '../../../ngrx/reducers/gui-state-memorized.reducer';
import { fromTasksCustomFields } from '../../../ngrx/reducers/tasks-custom-field.reducer';
import { ScoringType } from '../../../constants/board-type';
import { fromScoringCriteria } from '../../../ngrx/reducers/scoring-criteria.reducer';
import { IceFactors, RiceFactors, ScoringFactor } from '../../backlog-board/constants/backlog.constants';
import { ScoringCriteria, SeparatedCriteria } from '../../../interfaces/ScoringCriteria';

export interface ListBoardTasks {
  groupProp: string | number;
  groupsMap: ListBoardGroupsMap;
}

export const emptyGroupName = Symbol('Id for group with groupProp == undefined in task');

export interface ListBoardGroup {
  title: string;
  value: any;
  prop: string;
}

export interface ListBoardGroupsMap {
  [groupId: number]: Task[];
  [groupId: string]: Task[];
  [emptyGroupName]: Task[];
}

@Component({
  selector: 'list-board-details',
  templateUrl: './list-board-details.component.html',
  styleUrls: ['./list-board-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListBoardDetailsComponent implements OnInit, OnChanges {
  @Input() board: Board;
  @Input() isNotGuest: boolean;

  private _boardId$ = new BehaviorSubject(0);
  private _board$ = new BehaviorSubject(null);
  public doneColumnId: number;
  public toDoColumnId: number;
  public tasks$: Observable<Task[]>;
  public groupedTasks$: Observable<ListBoardTasks>;
  public isGroupedBySwimlane$: Observable<boolean>;
  public groups$: Observable<any[]>;
  public groupWidth$: Observable<string>;
  public isFieldManagerVisible: boolean;
  public groupProp$: Observable<number | string>;

  public boardLabelsMap$;
  public boardUsersMap$;
  public customFields$;

  public scoringColumns$: Observable<any[]>;
  public boardCriteriaColumns$;
  subs: Subscription[] = [];

  public calculatedTasks$: Observable<any[]>;
  public boardWeights$;
  public boardCriteria$;
  public crsMap;

  constructor(private _store: Store<AppState>, private _usersCacheService: UsersCacheService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('board')) {
      this._board$.next(changes['board'].currentValue);
      if (changes['board'].firstChange || changes['board'].currentValue.id !== changes['board'].previousValue.id) {
        this._boardId$.next(changes['board'].currentValue.id);

        this._usersCacheService
          .get(BoardQuickFiltersComponent.getCacheKey(changes['board'].currentValue.id))
          .pipe(take(1))
          .subscribe(v => {
            if (!v.defaultStatusesFilter) {
              this._store.dispatch(new DefaultTaskStatusesFilterToggleItemAction([columnTypes.todo]));
            }
          });
      }
    }
  }

  ngOnInit() {
    this.groupProp$ = this._boardId$.pipe(switchMap(id => this._store.select(fromGuiState.getBoardGrouping(id))));
    this.boardCriteriaColumns$ = this._boardId$.pipe(
      switchMap(id => this._store.select(fromScoringCriteria.getByBoard(id)))
    );

    this.scoringColumns$ = combineLatest(<Observable<ScoringCriteria[]>>this.boardCriteriaColumns$, this._board$).pipe(
      map(([boardCriteria, board]) => {
        switch (board.scoringType) {
          case ScoringType.off: {
            return [];
          }
          case ScoringType.basic: {
            return [
              { label: board.backlogScoreYLabel, factor: 'backlogScoreY' },
              { label: board.backlogScoreXLabel, factor: 'backlogScoreX' }
            ];
          }
          case ScoringType.RICE: {
            return [...RiceFactors];
          }
          case ScoringType.ICE: {
            return [...IceFactors];
          }
          case ScoringType.advanced: {
            return [...boardCriteria];
          }
          default: {
            return [];
          }
        }
      })
    );

    this.tasks$ = this._boardId$.pipe(
      switchMap(boardId => this._store.pipe(getTasksByUsersFilter(both(inBoard(boardId), isActiveTask))))
    );

    this.boardWeights$ = this._board$.pipe(
      map(board => ({ valueWeight: board.advancedValueWeight, effortsWeight: board.advancedEffortWeight }))
    );

    this.boardCriteria$ = this._boardId$.pipe(
      switchMap(id => this._store.select(fromScoringCriteria.getByBoard(id))),
      map(crs =>
        crs.reduce(
          (acc, item) => {
            if (item.factor === ScoringFactor.Value) {
              acc[ScoringFactor.Value].push(item);
            } else {
              acc[ScoringFactor.Efforts].push(item);
            }
            return acc;
          },
          { [ScoringFactor.Value]: [], [ScoringFactor.Efforts]: [] }
        )
      )
    );

    this.calculatedTasks$ = combineLatest(this.tasks$, this.boardCriteria$, this.boardWeights$).pipe(
      tap(([tasks, sepCrs]: [Task[], SeparatedCriteria]) => {
        this.crsMap = {};
        sepCrs[ScoringFactor.Value].forEach(cr => (this.crsMap[cr.id] = cr));
        sepCrs[ScoringFactor.Efforts].forEach(cr => (this.crsMap[cr.id] = cr));
      }),
      map(([tasks, sepCrs, boardWeights]: [Task[], SeparatedCriteria, any]): any[] => {
        return tasks.map(task => {
          const result = {
            ...task,
            scoringValuesMap: this.getScoringValuesMap(task)
          };
          result['score'] = this.getTaskScore(result, sepCrs, boardWeights);
          return result;
        });
      })
    );

    this.groupedTasks$ = combineLatest(
      this.groupProp$,
      this.calculatedTasks$,
      this._store.select(fromTasksCustomFields.getEntities)
    ).pipe(
      map(([groupProp, tasks, customFieldValues]) => {
        let result;
        if (Number.isInteger(+groupProp)) {
          const fieldId = +groupProp;
          const valuesOfChosenId = Object.values(customFieldValues).filter(item => item.customField === fieldId);
          result = tasks.reduce(
            (acc, item) => {
              if (!item) {
                return acc;
              }
              const groupPropCustomFieldValue =
                item.tasksCustomFieldsIds &&
                valuesOfChosenId.find(fieldValue => item.tasksCustomFieldsIds.includes(fieldValue.id));
              if (item.tasksCustomFieldsIds && groupPropCustomFieldValue && groupPropCustomFieldValue.value) {
                if (!acc.groupsMap[groupPropCustomFieldValue.value]) {
                  acc.groupsMap[groupPropCustomFieldValue.value] = [];
                }
                acc.groupsMap[groupPropCustomFieldValue.value].push(item);
              } else {
                acc.groupsMap[emptyGroupName].push(item);
              }
              return acc;
            },
            { groupProp: groupProp, groupsMap: { [emptyGroupName]: [] } }
          );
        } else {
          result = tasks.reduce(
            (acc, item) => {
              if (!item) {
                return acc;
              }
              if (!item.hasOwnProperty(groupProp) || (item[groupProp] === undefined && item[groupProp] === null)) {
                acc.groupsMap[emptyGroupName].push(item);
              } else {
                if (!acc.groupsMap[item[groupProp]]) {
                  acc.groupsMap[item[groupProp]] = [];
                }
                acc.groupsMap[item[groupProp]].push(item);
              }
              return acc;
            },
            { groupProp: groupProp, groupsMap: { [emptyGroupName]: [] } }
          );
        }
        Object.values(result.groupsMap).forEach((arr: Task[]) =>
          arr.sort((task1, task2) => task1.position - task2.position)
        );
        result.groupsMap[emptyGroupName].sort((task1, task2) => task1.position - task2.position);

        return result;
      })
    );

    this.isGroupedBySwimlane$ = this.groupProp$.pipe(map(prop => prop === 'swimlane'));

    this.groups$ = this._boardId$.pipe(
      switchMap(id => this.groupProp$.pipe(switchMap(groupProp => this.getGroupsSelector(groupProp, id))))
    );

    this.boardUsersMap$ = this._board$.pipe(
      switchMap(
        (board: Board) =>
          board.access !== BoardInProjectAccess.public
            ? this._store.select(fromUsers.getMapByIds((board && board.usersIds) || []))
            : this._store
                .select(fromUsers.getState)
                .pipe(map(usersState => (usersState && usersState.entities ? usersState.entities : {})))
      )
    );

    this.boardLabelsMap$ = this._board$.pipe(
      switchMap((board: Board) => this._store.select(fromLabels.getMapByIds((board && board.labelsIds) || [])))
    );

    this.customFields$ = combineLatest(this._boardId$, this._store.select(fromCustomFields.getAll)).pipe(
      map(([boardId, fields]) => fields.filter(item => item.board === boardId).sort((a, b) => a.position - b.position)),
      // tap(arr => {
      //   this.customFieldsMap = arr.reduce((acc, item) => {
      //     acc[item.id] = item;
      //     return acc;
      //   }, {});
      // }),
      publishReplay(1),
      refCount()
    );
    this.groupWidth$ = combineLatest(
      <Observable<any[]>>this.scoringColumns$,
      <Observable<CustomField[]>>this.customFields$
    ).pipe(
      map(
        ([scoringColumns, customFields]) =>
          (this.board.scoringType === ScoringType.advanced
            ? 300
            : this.board.scoringType === ScoringType.basic ? 0 : 150) +
          scoringColumns.length * 150 +
          customFields.length * 230 +
          1000 +
          'px'
      ),
      publishReplay(1),
      refCount()
    ); // 1000 = task status (32) + min title (260) + due_date (166) + labels (230) + members (210) + settings (42) + padding (40+20)

    this._boardId$.pipe(switchMap(id => this._store.pipe(getColumnsByBoard(id)))).subscribe(columns => {
      this.doneColumnId = 0;
      this.toDoColumnId = 0;
      columns.forEach(column => {
        if (column.type === columnTypes.done) {
          this.doneColumnId = column.id;
        } else if (column.type === columnTypes.todo) {
          this.toDoColumnId = column.id;
        }
      });
    });
  }

  getGroupsSelector(groupProp, id) {
    switch (groupProp) {
      case 'swimlane': {
        return this._store
          .select(fromSwimlanes.getByBoardId(id))
          .pipe(
            map((swimlanes: Swimlane[]) =>
              swimlanes.map(item => <ListBoardGroup>{ title: item.name, value: item.id, prop: groupProp })
            )
          );
      }
    }
    if (groupProp) {
      return this._store.select(fromCustomFields.getById(groupProp)).pipe(
        map((customField: CustomField) => {
          let values: string[] = [];
          if (customField.values) {
            try {
              values = JSON.parse(customField.values).map(value => ({ title: value, value: value, prop: groupProp }));
            } catch (e) {
              console.log(e);
            }
          }
          return [...values, { title: 'Not Set', value: emptyGroupName, prop: groupProp }];
        })
      );
    }
  }

  onCloseFieldsManager() {
    this.isFieldManagerVisible = false;
  }

  onOpenFieldsManager() {
    this.isFieldManagerVisible = true;
  }

  onCompleteTask(task) {
    if (!this.doneColumnId || !this.toDoColumnId || !task) {
      return;
    }
    if (task.column !== this.doneColumnId) {
      this._store.dispatch(new TaskEditAction({ id: task.id, column: this.doneColumnId, insertBefore: 0 }));
    } else {
      this._store.dispatch(new TaskEditAction({ id: task.id, column: this.toDoColumnId, insertBefore: 0 }));
    }
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  getScoringValuesMap(task) {
    return (Array.isArray(task.scoringValues) ? task.scoringValues : []).reduce((acc, item) => {
      if (item && item.criterion) {
        acc[item.criterion] = {
          value: item.value,
          visibleValue: this.crsMap[item.criterion]
            ? getVisibleScoringVal(item.value, this.crsMap[item.criterion].dataType)
            : '?'
        };
      }
      return acc;
    }, {});
  }

  getTaskScore(calcTask, sepCrs: SeparatedCriteria, boardWeights) {
    const values = sepCrs[ScoringFactor.Value].reduce((acc, item) => {
      if (calcTask.scoringValuesMap[item.id] && Number.isFinite(calcTask.scoringValuesMap[item.id].value)) {
        acc += item.scoringDirection * (item.weight / 100) * calcTask.scoringValuesMap[item.id].value;
      } else {
        return NaN;
      }
      return acc;
    }, 0);
    const efforts = sepCrs[ScoringFactor.Efforts].reduce((acc, item) => {
      if (calcTask.scoringValuesMap[item.id]) {
        acc += item.scoringDirection * (item.weight / 100) * calcTask.scoringValuesMap[item.id].value;
      } else {
        return NaN;
      }
      return acc;
    }, 0);
    let score = Math.round(
      (values * (boardWeights.valueWeight / 100) + efforts * (boardWeights.effortsWeight / 100)) *
        (calcTask.confidence / 100)
    );
    return Number.isFinite(score) ? score : '?';
  }
}
