import { columnTypes } from '../constants';
import { Column, Task } from '../interfaces';
import { sortBy } from '../../helpers';

export type taskSorter = (tasks: Task[]) => Task[];
export type taskSorterFactory = (...args: any[]) => taskSorter;

/**
 *
 * @param backlogData { backlogScoreX: number, backlogScoreY: number }
 * @returns {string}
 */
export const getBacklogTaskKey = backlogData => {
  return backlogData.backlogScoreX + '-' + backlogData.backlogScoreY;
};

const sortByPosition = sortBy('position', 'asc');

export const sortOriginOrder: taskSorterFactory = (columnsEntities, swimlaneEntities) => tasks => {
  const sorter = (task1: Task, task2: Task) => {
    if (task1.swimlane !== task2.swimlane) {
      return sortByPosition(swimlaneEntities[task1.swimlane], swimlaneEntities[task2.swimlane]);
    }

    if (task1.column !== task2.column) {
      const column1 = <Column>columnsEntities[task1.column] || {};
      const column2 = <Column>columnsEntities[task2.column] || {};

      if (!column1['parent'] && !column2['parent']) {
        return sortByPosition(columnsEntities[task1.column], columnsEntities[task2.column]);
      }

      if (column1['parent'] && column2['parent']) {
        return column1['parent'] === column2['parent']
          ? column1['type'] === columnTypes.sub_inprogress ? -1 : 1
          : sortByPosition(columnsEntities[column1['parent']], columnsEntities[column2['parent']]);
      }

      const toCompareColumn1 = column1['parent'] ? columnsEntities[column1['parent']] : column1;
      const toCompareColumn2 = column2['parent'] ? columnsEntities[column2['parent']] : column2;

      return sortByPosition(toCompareColumn1, toCompareColumn2);
    }

    return sortByPosition(task1, task2);
  };

  return tasks.slice().sort(sorter);
};

export const PercentStringValues = new Array(101)
  .join(' ')
  .split(' ')
  .map((item, i) => i + '');
