<div class="wrapper" [style.width]="groupWidth">
  <div>
    <button a-button type="button" *ngIf="isNotGuest && isGroupedBySwimlane" class="btn_primary" (click)="onAddGroup()">Add Group</button>
  </div>
  <div class="columns">
    <div *ngFor="let field of customFields" class="field_wrapper custom_field">
      {{field.name}}
    </div>
    <div class="field_wrapper due_date">
      DUE DATE
    </div>
    <div class="field_wrapper labels">
      LABELS
    </div>
    <div class="field_wrapper members">
      MEMBERS
    </div>
    <div *ngFor="let scoringLabel of scoringColumnsLabels" class="field_wrapper scoring_field">
      {{scoringLabel}}
    </div>
    <div *ngIf="board.scoringType === ScoringType.advanced" class="field_wrapper scoring_field">
      CONFIDENCE
    </div>
    <div *ngIf="board.scoringType !== ScoringType.off && board.scoringType !== ScoringType.basic" class="field_wrapper scoring_field">
      SCORE
    </div>
    <div class="settings_btn" (click)="onOpenFieldManager()">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1593 10.98C17.1993 10.66 17.2293 10.34 17.2293 10C17.2293 9.66 17.1993 9.34 17.1593 9.02L19.2693 7.37C19.4593 7.22 19.5093 6.95 19.3893 6.73L17.3893 3.27C17.2693 3.05 16.9993 2.97 16.7793 3.05L14.2893 4.05C13.7693 3.65 13.2093 3.32 12.5993 3.07L12.2193 0.42C12.1893 0.18 11.9793 0 11.7293 0H7.72933C7.47933 0 7.26933 0.18 7.23933 0.42L6.85933 3.07C6.24933 3.32 5.68933 3.66 5.16933 4.05L2.67933 3.05C2.44933 2.96 2.18933 3.05 2.06933 3.27L0.0693316 6.73C-0.0606684 6.95 -0.000668392 7.22 0.189332 7.37L2.29933 9.02C2.25933 9.34 2.22933 9.67 2.22933 10C2.22933 10.33 2.25933 10.66 2.29933 10.98L0.189332 12.63C-0.000668392 12.78 -0.0506684 13.05 0.0693316 13.27L2.06933 16.73C2.18933 16.95 2.45933 17.03 2.67933 16.95L5.16933 15.95C5.68933 16.35 6.24933 16.68 6.85933 16.93L7.23933 19.58C7.26933 19.82 7.47933 20 7.72933 20H11.7293C11.9793 20 12.1893 19.82 12.2193 19.58L12.5993 16.93C13.2093 16.68 13.7693 16.34 14.2893 15.95L16.7793 16.95C17.0093 17.04 17.2693 16.95 17.3893 16.73L19.3893 13.27C19.5093 13.05 19.4593 12.78 19.2693 12.63L17.1593 10.98ZM9.72933 13.5C7.79933 13.5 6.22933 11.93 6.22933 10C6.22933 8.07 7.79933 6.5 9.72933 6.5C11.6593 6.5 13.2293 8.07 13.2293 10C13.2293 11.93 11.6593 13.5 9.72933 13.5Z" fill="currentColor"/>
      </svg>
    </div>
  </div>
</div>
<add-swimlane *ngIf="isAddSwimlanePopupVisible" [boardId]="board.id" [initName]="'Group Title ' + (this.groupsLength + 1)" (close)="onCloseAddGroup()"></add-swimlane>
