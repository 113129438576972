import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnChanges,
  ViewChild
} from '@angular/core';
import { SmartTaskLinkComponent } from '../../shared/smart-text/smart-task-link/smart-task-link.component';
import { MarkdownComponent } from 'ngx-markdown-hyggerio';
import { SmartTextComponent } from '../../shared/smart-text/smart-text.component';

@Component({
  template: `<div #text hidden>
        <ng-content></ng-content>
      </div>
      <markdown #markdown [data]="data" [isTargetBlankLinks]="true"></markdown>`,
  selector: 'markdown-smart-task-link',
  entryComponents: [SmartTaskLinkComponent, MarkdownComponent, SmartTextComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarkdownSmartTaskLinkComponent implements OnChanges, AfterViewInit {
  @Input() data = '';
  @ViewChild('text') textRef: ElementRef;
  @ViewChild(MarkdownComponent) markDownRef: MarkdownComponent;
  @ContentChild(SmartTextComponent) smartText: SmartTextComponent;
  public currentData = '';
  constructor() {}

  ngOnChanges() {
    this.currentData = this.data;
    this.updateText();
  }
  ngAfterViewInit() {
    this.smartText.smartTaskLinkUpdated.subscribe(_ => this.updateText());
    this.updateText();
  }
  updateText() {
    const smartLinksMap = {};
    let iterationData = this.data;
    const parseTaskLinksFromSmartTextView = () => {
      Array.from(this.textRef.nativeElement.children[0].children).map((piece: any) => {
        return Array.from(piece.children).forEach((smartLink: any) => {
          const smartTaskLinkInner: any = Array.from(smartLink.children).find(
            (item: Element) => item.tagName === 'SMART-TASK-LINK-INNER'
          );
          smartLinksMap[smartTaskLinkInner.children[1].href] = {
            spanClass: smartTaskLinkInner.children[0].className,
            aClass: smartTaskLinkInner.children[1].className,
            href: smartTaskLinkInner.children[1].href,
            text: smartTaskLinkInner.children[1].innerText.trim().replace(/[\n\r]/g, '')
          };
        });
      });
    };
    try {
      parseTaskLinksFromSmartTextView();
      Object.keys(smartLinksMap).forEach(link => {
        iterationData = this.currentData = iterationData.replace(
          new RegExp('(\\[.*\\]\\()?' + link + '(\\))?', 'g'),
          (match, p1, p2, p3) => {
            if (p1 && p2 && p3) {
              return match;
            } else {
              return (
                '<span data-icon="atlaz" class="' +
                smartLinksMap[link].spanClass +
                '"></span><a class="' +
                smartLinksMap[link].aClass +
                '" href="' +
                link +
                '" target="_blank" rel="noopener">' +
                smartLinksMap[link].text +
                '</a>'
              );
            }
          }
        );
      });
    } catch (e) {}
    this.markDownRef.render(this.currentData);
  }
}
