import { BITBUCKET_REPOSITORY_PL } from '../../../../constants/index';
import { getEmptyESState } from '../../../../ngrx/state/index';
import { createCommonSelectors } from '../../../../util/store/index';
import { ESBitbucketRepository, BitbucketRepository } from '../../models/git-repository';
import * as bitbucketCompany from '../bitbucket-company/bitbucket-company.actions';
import * as bitbucketRepository from './bitbucket-repository.actions';

export interface State extends ESBitbucketRepository {
  loading: boolean;
}

const initialState: State = {
  ...getEmptyESState<BitbucketRepository>(),
  loading: false
};

export function reducer(state = initialState, action: bitbucketRepository.Actions | bitbucketCompany.Actions): State {
  switch (action.type) {
    case bitbucketRepository.GET_INDEX: {
      return { ...state, loading: true };
    }
    case bitbucketRepository.UPDATED: {
      return state;
    }

    case bitbucketRepository.GET_INDEX_COMPLETE: {
      return { ...state, loading: false };
    }

    case bitbucketCompany.CLEAR_SETTINGS:
    case bitbucketCompany.REMOVE_ALL: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const { getState, getEntities, getIds, getAll } = createCommonSelectors<BitbucketRepository, State>(
  BITBUCKET_REPOSITORY_PL
);
