import { FormControl } from '@angular/forms';

export class RegExpValidator {
  public static regExpIsValid(pattern, control: FormControl): { [s: string]: boolean } {
    pattern = new RegExp(pattern);

    if (!pattern.test(control.value)) {
      return {
        valid: false
      };
    }
  }
}
