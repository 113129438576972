<div [class.edit_available]="editPermissions">
  <ng-container *ngIf="board&&board.scoringType === ScoringType.basic">
    <h5 class="sidebar_block_title">Rate</h5>
    <score-form
      [task]="task"
      [editPermissions]="editPermissions"
    ></score-form>
  </ng-container>

  <ng-container *ngIf="board&&board.scoringType !== ScoringType.basic">
    <edit-ice-rice-scoring
      *ngIf="factorToEdit$ | async"
      [focusOn]="factorToEdit$ | async"
      [task]="task"
      [type]="board.scoringType"
      (close)="hideFactorEdit()"
    ></edit-ice-rice-scoring>

    <h5 class="sidebar_block_title">Score</h5>
    <div class="sidebar_block_content sidebar_block_score">
      <div>{{score$ | async}}</div>
    </div>
    <div class="divider"></div>
  </ng-container>

  <ng-container *ngIf="board&&board.scoringType === ScoringType.advanced">
    <edit-task-scoring
      *ngIf="isEdit"
      [focusOn]="(editCriterion$ | async)?.id || 'confidence'"
      [task]="task"
      (close)="hideCriterionEdit()"
    >
    </edit-task-scoring>
    <div class="sidebar_sub_block" *ngIf="(valueCriterias$ | async)?.length">
      <h5 class="sidebar_block_title">Value</h5>
      <div class="sidebar_block_content">
        <div class="criteria_block" *ngFor="let cr of (valueCriterias$ | async)">
          <div class="sidebar_sub_block_title">{{cr.name}}</div>
          <div class="sidebar_sub_block_value" (click)="onEditCriterion(cr)">{{cr.visibleValue}}</div>
        </div>
      </div>
      <div class="divider"></div>
    </div>
    <div class="sidebar_sub_block" *ngIf="(effortsCriterias$ | async)?.length">
      <h5 class="sidebar_block_title">Efforts</h5>
      <div class="sidebar_block_content">
        <div class="criteria_block" *ngFor="let cr of (effortsCriterias$ | async)">
          <div class="sidebar_sub_block_title">{{cr.name}}</div>
          <div class="sidebar_sub_block_value" (click)="onEditCriterion(cr)">{{cr.visibleValue}}</div>
        </div>
      </div>
      <div class="divider"></div>
    </div>
    <div class="sidebar_sub_block">
      <h5 class="sidebar_block_title">Confidence</h5>
      <div class="sidebar_block_content">
        <div class="criteria_block">
          <div class="sidebar_sub_block_value" (click)="onEditCriterion({id: 'confidence'})">{{task.confidence}}%</div>
        </div>
      </div>
      <div class="divider"></div>
    </div>
  </ng-container>

  <ng-container *ngIf="board&&board.scoringType === ScoringType.ICE">
    <div class="sidebar_sub_block" *ngFor="let factor of iceFactors">
      <h5 class="sidebar_block_title">{{factor.label}}</h5>
      <div class="sidebar_block_content">
        <div class="criteria_block">
          <div class="sidebar_sub_block_value" (click)="onEditFactor(factor.prop)">{{getFactor(factor.prop)}}</div>
        </div>
      </div>
      <div class="divider"></div>
    </div>
  </ng-container>

  <ng-container *ngIf="board&&board.scoringType === ScoringType.RICE">
    <div class="sidebar_sub_block" *ngFor="let factor of riceFactors">
      <h5 class="sidebar_block_title">{{factor.label}}</h5>
      <div class="sidebar_block_content">
        <div class="criteria_block">
          <div class="sidebar_sub_block_value" (click)="onEditFactor(factor.prop)">{{getFactor(factor.prop, factor.type)}}</div>
        </div>
      </div>
      <div class="divider"></div>
    </div>
  </ng-container>

</div>
