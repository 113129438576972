import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ScoringPageComponent } from '../scoring-page/scoring-page.component';
import { BoardDetailPageComponent } from '../../board-detail-page/board-detail-page.component';

@Injectable({
  providedIn: 'root'
})
export class ScoringTypeChooserGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._getScoringTablePageBoardId(state) || this._getBoardPageBoardId(state);
  }

  private _getScoringTablePageBoardId(state: RouterStateSnapshot) {
    try {
      return (
        state.root.firstChild.firstChild.component.toString() === ScoringPageComponent.toString() &&
        !!+state.root.firstChild.firstChild.params.boardId
      );
    } catch (e) {
      return false;
    }
  }

  private _getBoardPageBoardId(state: RouterStateSnapshot) {
    try {
      return (
        state.root.firstChild.firstChild.firstChild.firstChild.component.toString() ===
          BoardDetailPageComponent.toString() && !!+state.root.firstChild.firstChild.firstChild.firstChild.params.id
      );
    } catch (e) {
      return false;
    }
  }
}
