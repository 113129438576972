import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable ,  BehaviorSubject } from 'rxjs';
import { Board } from '../../../interfaces';
import { BOARD_PL, COLLECTION_PL } from '../../../constants';
import { Store } from '@ngrx/store';
import { AppState, LEFT_MENU } from '../../../ngrx/state';
import { getAllBoards } from '../../../ngrx/reducers/board.reducer';
import { RouterNavigateService } from '../../services/router-navigate.service';
import { SegmentService } from '../../../atlaz-bnp/services/intergations/segment/segment.service';
import { ComponentListenClickoutAction } from '../../../ngrx/actions/component.actions';
import { AtlazApiV2Service } from '../../services/atlaz-api/v2/atlaz-api-v2.service';
import { JsonApiSingeModelResponse } from '../../services/app/web-socket/http-response';
import { HandleResponseAction } from '../../../ngrx/actions/root.action';

@Component({
  selector: 'create-new-collection',
  templateUrl: './new-collection.component.html',
  styleUrls: ['./new-collection.component.scss']
})
export class NewCollectionComponent implements OnInit {
  @Input() notOutlet = false;
  @Output() cancel = new EventEmitter();
  public myForm: FormGroup;
  public error = false;

  public hasErrorCreateNewCollectionForm: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public createNewCollectionFormErrorMessage: Array<string> = [];

  public boards: Board[];
  public selectedBoards$: Observable<number[]>;

  public boards$: Observable<Board[]>;

  constructor(
    private _store: Store<AppState>,
    private _fb: FormBuilder,
    private _routerNav: RouterNavigateService,
    private _segment: SegmentService,
    private _atlazApi: AtlazApiV2Service
  ) {}

  ngOnInit() {
    this.selectedBoards$ = new BehaviorSubject([]);
    this.myForm = this._fb.group({
      name: ['', Validators.compose([Validators.required, Validators.maxLength(50)])]
    });
    this.onGetBoards();
  }

  onCancel() {
    this._store.dispatch(new ComponentListenClickoutAction({ name: LEFT_MENU, listenClickout: true }));
    if (this.notOutlet) {
      this.cancel.emit();
    } else {
      this._routerNav.deactivatePopupOutlet();
    }
  }

  onGetBoards() {
    this.boards$ = <Observable<Board[]>>this._store.pipe((getAllBoards));
  }

  onAddCollection() {
    this.myForm.get('name').markAsDirty();
    this.myForm.get('name').markAsTouched();
    this.myForm.get('name').updateValueAndValidity();
    this.saveCollection(this.myForm);
  }

  saveCollection(form: FormGroup) {
    if (form.valid) {
      this._store.dispatch(new ComponentListenClickoutAction({ name: LEFT_MENU, listenClickout: true }));
      this.hasErrorCreateNewCollectionForm.next(false);

      let selectedBoardsList: number[];
      this.selectedBoards$.subscribe(boards => (selectedBoardsList = boards));

      this._atlazApi
        .post(COLLECTION_PL, { expand: BOARD_PL, name: form.value.name, boards: selectedBoardsList })
        .subscribe(
          (resp: JsonApiSingeModelResponse<any>) => {
            this._store.dispatch(new HandleResponseAction(resp));
            if (this.notOutlet) {
              this.cancel.emit();
            } else {
              this._routerNav.deactivatePopupOutlet();
            }
            this._segment.collectionCreated();
          },
          error => {
            const errorString = JSON.parse(error._body);

            errorString.errors.find(object => {
              this.createNewCollectionFormErrorMessage.push(object.detail);
            });

            this.hasErrorCreateNewCollectionForm.next(true);
          }
        );
    }
  }
}
