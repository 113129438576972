import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListBoardDetailsComponent } from './list-board-details/list-board-details.component';
import { SharedModule } from '../../shared/shared.module';
import { AtlazGuiModule } from '../../atlaz-gui/atlaz-gui.module';
import { ListBoardHeaderComponent } from './list-board-details/list-board-header/list-board-header.component';
import { ListBoardContentComponent } from './list-board-details/list-board-content/list-board-content.component';
import { ListBoardAddTaskComponent } from './list-board-details/list-board-add-task/list-board-add-task.component';
import { ListBoardTaskItemComponent } from './list-board-details/list-board-task-item/list-board-task-item.component';
import { ListBoardAddTaskFormComponent } from './list-board-details/list-board-add-task/list-board-add-task-form/list-board-add-task-form.component';
import { BoardSharedModule } from '../board-shared/board-shared.module';
import { ListBoardMembersComponent } from './list-board-details/list-board-task-item/list-board-members/list-board-members.component';
import { ListBoardLabelsComponent } from './list-board-details/list-board-task-item/list-board-labels/list-board-labels.component';
import { BacklogScoringModule } from '../backlog-scoring/backlog-scoring.module';
import { AddSwimlaneComponent } from './list-board-details/list-board-header/add-swimlane/add-swimlane.component';

const sharedComponents = [
  ListBoardDetailsComponent,
  ListBoardHeaderComponent,
  ListBoardContentComponent,
  ListBoardAddTaskComponent,
  ListBoardTaskItemComponent,
  ListBoardAddTaskFormComponent,
  ListBoardMembersComponent,
  ListBoardLabelsComponent,
  AddSwimlaneComponent
];

@NgModule({
  imports: [CommonModule, SharedModule, AtlazGuiModule, BoardSharedModule, BacklogScoringModule],
  declarations: sharedComponents,
  exports: [sharedComponents],
  providers: []
})
export class ListBoardModule {}
