import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { OverviewActivity } from '../../../interfaces';
import { DateInterval } from '../../../interfaces/date-interval';
import { DATE_INTERVAL, filterValueForDateInterval } from '../../../../helpers/date-interval';
import { trackById } from '../../../../helpers/index';

@Component({
  selector: 'task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskListComponent implements OnChanges {
  @Input() activities: OverviewActivity[];
  @Input() rightMenuContent: boolean;

  public trackById = trackById;
  public trackByName = (index, item) => item.name;
  public tasksByDate: DateInterval[];

  ngOnChanges(changes: SimpleChanges): any {
    if (changes.hasOwnProperty('activities') && changes['activities'].currentValue !== null) {
      this.tasksByDate = this.groupTasksByInterval(this.activities);
    }
  }

  groupTasksByInterval(activities: OverviewActivity[]): DateInterval[] {
    return DATE_INTERVAL.reduce((acc, dateInterval) => {
      const filteredActivities: OverviewActivity[] = activities.filter(
        filterValueForDateInterval(dateInterval.dateStart, dateInterval.dateEnd)
      );

      if (filteredActivities.length > 0) {
        acc.push(Object.assign({}, dateInterval, { activities: filteredActivities }));
      }

      return acc;
    }, []);
  }
}
