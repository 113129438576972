
import {startWith, debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject ,  Observable ,  Subscription } from 'rxjs';
import { Task } from '../../interfaces';
import { TASK_PL } from '../../constants';
import { AppState } from '../../ngrx/state';
import { Store } from '@ngrx/store';
import { TaskRemoveReleasedTaskFromStore } from '../../ngrx/actions/task.actions';
import { getEntitiesByFields } from '../../ngrx/functions/selectors';
import { LABEL_PL, SUBSCRIBER_PL, USER_PL } from '../../constants/';
import { SEARCH } from '../../path.routing';
import { PaginationLoaderService } from '../../shared/services/paginataion-loader/paginataion-loader.service';

@Component({
  selector: 'released-tasks-list',
  templateUrl: './released-tasks-list.component.html',
  styleUrls: ['./released-tasks-list.component.scss'],
  providers: [PaginationLoaderService]
})
export class ReleasedTasksListComponent implements OnInit, OnDestroy {
  @Input() boardId: number;

  sub: Subscription;

  query$ = new BehaviorSubject('');

  tasks$: Observable<Task[]>;

  constructor(public _paginatorService: PaginationLoaderService, private _store: Store<AppState>) {}

  ngOnInit(): any {
    this._store.dispatch(new TaskRemoveReleasedTaskFromStore());
    this.sub = this.query$.pipe(
      debounceTime(400),
      startWith(''),
      distinctUntilChanged(),
      tap(_ => this._store.dispatch(new TaskRemoveReleasedTaskFromStore())),)
      .subscribe(query => {
        this._paginatorService.controller = query.trim().length > 0 ? SEARCH : TASK_PL;
        this._paginatorService.queryParams = {
          q: query,
          released: 1,
          expand: [SUBSCRIBER_PL, LABEL_PL, USER_PL].join(','),
          board: this.boardId,
          boards: [this.boardId]
        };
        this._paginatorService.loadMore();
      });

    this.tasks$ = this._store.pipe((getEntitiesByFields(TASK_PL, { released: 1, board: this.boardId })));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onChange(value) {
    this.query$.next(value);
  }

  onLoadMore() {
    this._paginatorService.loadMore();
  }
}
