<input class="quick_search_versions"
       [focus]="true"
       placeholder="Search Version..."
       #input_seacrh_versions
       (keyup)="0"
>
<div class="versions_container">
  <div *ngFor="let version of versions | searchByField: 'name': input_seacrh_versions.value"
       class="version_title"
       (click)="onSetFilter(version)"
  >
    <p>{{version.name}}</p>
    <span class="check_label" *ngIf="checkSelectedVersion[version.id]"></span>
  </div>
</div>
