<div class="filter_container">
  <h3>Members</h3>

  <div *ngFor="let selectedUser of selectedUsers$ | async"
       (click)="onSelectUser(selectedUser.id)"
  >
    <a-avatar class="user_tag selected"
              [user]="selectedUser"
    ></a-avatar>
  </div>

  <div *ngIf="selectedGroup$ | async as currentGroup" class="group_avatar selected" (click)="onSelectGroup(currentGroup)">
    {{currentGroup.name[0]}}
  </div>
  <div class="select_user_btn" (click)="onOpenMembersPopup()"></div>
  <div class="filter_popup" *ngIf="isMembersPopupVisible"
       [clickOut]="isMembersPopupVisible"
       (clickOutEvent)="onCloseMembersPopup()"
  >
    <div class="close_popup" (click)="onCloseMembersPopup()"></div>
    <div class="title">Members</div>
    <div class="divider"></div>
    <input #input_search_users (keyup)="0" [focus]="true"
           placeholder="Search Member...">

    <div class="items_block">

      <h3 class="items_title">Groups</h3>
      <div class="divider_title"></div>
      <div class="single_group_item"
           *ngFor="let group of groups$ | async | searchByField: 'name': input_search_users.value"
           (click)="onSelectGroup(group)">
          <div class="group_avatar">{{group.name[0]}}</div>
          <p>{{group.name}}</p>
          <span *ngIf="(groupId$ | async) === group.id" class="select_check"></span>
      </div>

      <h3 class="items_title">Members</h3>
      <div class="divider_title"></div>
      <div class="single_user_item"
           *ngFor="let user of users$ | async | usersNameFilter: input_search_users.value"
           (click)="onSelectUser(user.id)">
        <a-avatar class="user_avatar"
                     [user]="user"
        ></a-avatar>
        <p>
          {{user.fullname}}
        </p>
        <span *ngIf="(userIds$ | async).includes(user.id)" class="select_check"></span>
      </div>
    </div>
  </div>
</div>
