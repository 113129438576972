
import {of as observableOf,  BehaviorSubject ,  Observable ,  combineLatest } from 'rxjs';

import {take, map, withLatestFrom, skip, filter, distinctUntilChanged} from 'rxjs/operators';
import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../ngrx/state';
import { columnTypes, columnKinds, COLUMN_PL, TASK_PL, FormStatuses } from '../../constants';
import { Column } from '../../interfaces';
import { TaskRemoveFromStoreAction } from '../../ngrx/actions/task.actions';
import { isEmpty } from '../../../helpers';
import { getTaskIdsByColumnObjectWithComposite } from '../../ngrx/reducers/task.reducer';
import { getBoardColumnCounter } from '../../ngrx/reducers/column.reducer';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { JsonApiSingeModelResponse } from '../../shared/services/app/web-socket/http-response';
import { HandleResponseAction } from '../../ngrx/actions/root.action';
import { isBacklogBoardBuilder, isKanbanBoardBuilder } from '../../ngrx/reducers/board.reducer';

@Component({
  selector: 'column-edit-form',
  templateUrl: './column-edit-form.component.html',
  styleUrls: ['./column-edit-form.component.scss']
})
export class ColumnEditFormComponent implements OnChanges {
  @Input() column: Column;
  @Input() boardId: number;
  @Input() insertAfterColumn: number;
  @Output() submitForm = new EventEmitter();
  @Output() closePopup = new EventEmitter();

  public columnForm: FormGroup;
  public columnTypes = columnTypes;
  public columnKinds = columnKinds;
  public formStatus$: BehaviorSubject<FormStatuses> = new BehaviorSubject(FormStatuses.available);
  public formStatuses = FormStatuses;

  public pending$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isNewColumn: boolean;

  public canChangeType$: Observable<boolean>;
  public isCanAddDoneColumn$: Observable<boolean>;
  public isKanbanOrBacklog$: Observable<boolean>;

  constructor(private _fb: FormBuilder, private _store: Store<AppState>, private _atlazApiV2: AtlazApiV2Service) {}

  ngOnChanges() {
    this.isNewColumn = isEmpty(this.column);
    const columnCounter$ = this._store.pipe((getBoardColumnCounter));
    this.isKanbanOrBacklog$ = combineLatest(
      this._store.pipe((isKanbanBoardBuilder(this.boardId))),
      this._store.pipe((isBacklogBoardBuilder(this.boardId))),
      (isKanban, isBacklog) => isKanban || isBacklog
    );
    this.isCanAddDoneColumn$ = combineLatest(
      this.isKanbanOrBacklog$,
      columnCounter$.pipe(map(counter => counter[columnTypes.done] < 1)),
      (isKanbanOrBacklog, counter) => isKanbanOrBacklog || counter
    );
    this.canChangeType$ = combineLatest(
      observableOf(this.isNewColumn),
      this.isKanbanOrBacklog$,
      columnCounter$.pipe(map(counter => counter[this.column.type] > 1)),
      (isNewColumn, isKanbanOrBacklog, counter) => isNewColumn || isKanbanOrBacklog || counter
    );
    this.initColumnForm();
  }

  onClose() {
    this.closePopup.emit(true);
  }

  onSubmit() {
    if (!this.columnForm.valid || this.formStatus$.getValue() === FormStatuses.pending) {
      return false;
    }
    this.formStatus$.next(FormStatuses.pending);

    if (this.isNewColumn) {
      this.addColumn();
    } else if (this.column.kind !== this.columnForm.get('kind').value) {
      this.editColumnKind();
    } else if (this.column && this.columnForm.valid) {
      this.editColumn();
    }
  }

  get isColumnSelectedTypeToDo() {
    return this.columnForm.get('type').value === this.columnTypes.todo;
  }
  get isColumnSelectedTypeDone() {
    return this.columnForm.get('type').value === this.columnTypes.done;
  }
  get isColumnSelectedTypeInProgress() {
    return this.columnForm.get('type').value === this.columnTypes.inprogress;
  }
  get isColumnSelectedKindComposite() {
    return this.columnForm.get('kind').value === this.columnKinds.composite;
  }

  public editColumn() {
    this._atlazApiV2.patch(COLUMN_PL, this.columnForm.value).subscribe((resp: JsonApiSingeModelResponse<any>) => {
      this._store.dispatch(new HandleResponseAction(resp));
      this.formStatus$.next(FormStatuses.available);
      this.submitForm.emit(true);
    }, this.handleError.bind(this));
  }

  public editColumnKind() {
    this._atlazApiV2
      .patch([COLUMN_PL, { expand: TASK_PL }], this.columnForm.value).pipe(
      withLatestFrom(this._store.pipe((getTaskIdsByColumnObjectWithComposite(this.column)))))
      .subscribe(([resp, taskIds]) => {
        this._store.dispatch(new HandleResponseAction(resp));
        this._store.dispatch(new TaskRemoveFromStoreAction(taskIds));
        this.formStatus$.next(FormStatuses.available);
        this.submitForm.emit(true);
      }, this.handleError.bind(this));
  }

  public addColumn() {
    this._atlazApiV2.post(COLUMN_PL, this.columnForm.value).subscribe((resp: JsonApiSingeModelResponse<any>) => {
      this._store.dispatch(new HandleResponseAction(resp));
      this.submitForm.emit(true);
    }, this.handleError.bind(this));
  }

  public initColumnForm() {
    let fields;
    if (this.isNewColumn) {
      fields = this.getNewFields();
    } else {
      fields = this.getEditFields();
    }

    this.columnForm = this._fb.group(fields);

    this.columnForm
      .get('type')
      .valueChanges.pipe(skip(1),
      distinctUntilChanged(),
      filter(columnType => columnType !== columnType.inprogress),)
      .subscribe(columnType => this.columnForm.get('kind').patchValue(this.columnKinds.simple));

    this.canChangeType$.pipe(
      take(1))
      .subscribe(
        canChange => (canChange ? this.columnForm.get('type').enable() : this.columnForm.get('type').disable())
      );
  }

  public getEditFields() {
    return {
      id: [this.column.id, Validators.required],
      name: [this.column.name, Validators.required],
      type: [this.column.type, Validators.required],
      kind: [this.column.kind, Validators.required]
    };
  }

  public getNewFields() {
    return {
      name: ['', Validators.required],
      type: [this.columnTypes.todo, Validators.required],
      kind: [this.columnKinds.simple, Validators.required],
      board: [this.boardId, Validators.required],
      ...(this.insertAfterColumn && { insertAfterColumn: this.insertAfterColumn })
    };
  }

  public handleError(error) {
    console.log(error, 'error while add column');
    this.formStatus$.next(FormStatuses.error);
  }
}
