
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, tap, map, switchMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { LogWorkDeleteCompleteAction, LogWorkDeleteFailedAction, WorkLogActionTypes } from '../actions/worklog.actions';
import { WORKLOG_PL } from '../../constants';
import { WorkLog } from '../../interfaces';
import { Store } from '@ngrx/store';
import { AppState } from '../state';
import { AtlazApiV2Service } from '../../shared/services/atlaz-api/v2/atlaz-api-v2.service';
import { Action } from '../actions/unsafe-action';

@Injectable()
export class WorkLogEffects {
  @Effect({ dispatch: false })
  delete$ = this.actions$
    .ofType(WorkLogActionTypes.DELETE).pipe(
    map((action: Action) => action.payload),
    switchMap((workLog: WorkLog) => {
      return this._atlazApi
        .deleteRequest([WORKLOG_PL, workLog.id]).pipe(
        tap(() => {
          this._store.dispatch(new LogWorkDeleteCompleteAction(workLog));
        }),
        catchError(() => {
          this._store.dispatch(new LogWorkDeleteFailedAction(workLog));
          return observableOf({});
        }),);
    }),);

  constructor(private actions$: Actions, private _store: Store<AppState>, protected _atlazApi: AtlazApiV2Service) {}
}
