import { createCommonSelectors } from '../../../../util/store';
import { getEmptyESState } from '../../../../ngrx/state';
import { INTERCOM_COMPANY_PL } from '../../../../constants';

import * as intercomCompany from './intercom-company.actions';
import { ESIntercomCompany, IntercomCompany } from './intercom-company.actions';
import { createSelector } from 'reselect';
import { prop } from '../../../../../helpers';

export interface State extends ESIntercomCompany {
  isLoading: boolean;
}

const initialState: State = {
  ...getEmptyESState<IntercomCompany>(),
  isLoading: true
};

export function reducer(state = initialState, action: intercomCompany.Actions): State {
  switch (action.type) {
    case intercomCompany.GET_INDEX: {
      return { ...state, isLoading: true };
    }

    case intercomCompany.GET_INDEX_FAIL:
    case intercomCompany.GET_INDEX_COMPLETE: {
      return { ...state, isLoading: false };
    }

    case intercomCompany.CLEAR_SETTINGS:
    case intercomCompany.REMOVE_ALL_COMPLETE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const { getState, getEntities, getIds, getAll, isEmpty } = createCommonSelectors<IntercomCompany, State>(
  INTERCOM_COMPANY_PL
);

export const getFirstId = createSelector(getIds, ids => ids[0]);
export const isLoading = createSelector(getState, prop('isLoading'));

export const isFirstLoading = createSelector(isEmpty, isLoading, (e, l) => l && e);
