<div a-context-controll
     orientation="vertical"
     class="content_menu"
     [menuSlim]="true"
     [menuRight]="true"
     [menuTop]="true"
     [noOverlay]="noOverlay"
     [isMenuVisible]="isContentMenuVisible"
     (click)="onMenuClick()"
     #popup
>
  <ng-container *ngIf="editPermissions">
    <p a-context-menu-item *ngIf="isAdditionalItemsAvailable" (click)="moveTask('insertBeforeTask')">To Top</p>
    <p a-context-menu-item *ngIf="isAdditionalItemsAvailable" (click)="moveTask('insertAfterTask')">To Bottom</p>
    <p a-context-menu-item *ngIf="isAdditionalItemsAvailable" (click)="onSwitchMembers()">Manage Members</p>
    <p a-context-menu-item *ngIf="isAdditionalItemsAvailable" (click)="onSwitchProjects()">Project</p>
    <p a-context-menu-item *ngIf="isAdditionalItemsAvailable" (click)="onSwitchLabels()">Edit Labels</p>
    <p a-context-menu-item *ngIf="isAdditionalItemsAvailable" (click)="onSwitchDueDate()">Due Date</p>
    <p a-context-menu-item (click)="onSwitchMoveTaskMenu()">Move</p>
    <p a-context-menu-item *ngIf="isMarkAsDoneVisible$ | async" (click)="onMarkAsDone()">Mark as Done</p>
    <p a-context-menu-item (click)="onSwitchCopyTaskMenu()">Copy</p>
    <p a-context-menu-item (click)="onSwitchPushTaskMenu()">Push</p>
    <p a-context-menu-item [divider]="true"></p>
  </ng-container>
  <p a-context-menu-item (click)="onSwitchCopyLink()">Copy Link</p>
  <p a-context-menu-item (click)="onSubscribeUser()">
    {{(isUserSubscribed$ | async) ? 'Unsubscribe' : 'Subscribe'}}
    <span *ngIf="isUserSubscribed$ | async" class="subscribe_indicator"></span>
  </p>
  <ng-container *ngIf="editPermissions">
    <p a-context-menu-item [divider]="true"></p>
    <p a-context-menu-item (click)="onArchiveTask()">{{task.archived ? 'Unarchive' : 'Archive'}}</p>
    <a (click)="onViewOnBoard($event)" *ngIf="isOnNoBoardPage">
      <p a-context-menu-item >View on Board</p>
    </a>
    <p a-context-menu-item class="delete_link" (click)="onToggleDeleteTask()">Delete Task</p>
  </ng-container>
</div>
<content-copy-link *ngIf="isCopyLinkVisible" [isFullPath]="true" [path]="directUrl" (close)="onSwitchCopyLink()"></content-copy-link>

<div a-context-popup
     [title]="'Delete Task'"
     *ngIf="isDeleteTaskVisible"
     (close)="onToggleDeleteTask()"
     class="delete_task_confirm"
>
  <p class="delete_task_message">
    Are You Sure You Want to Delete this task?<br><br>
    All task data including comments, attachments and statistics will be lost.<br><br>
    There is no undo.
  </p>
  <div a-button class="btn_warn" (click)="onDeleteTask()">Delete</div>
  <div a-button (click)="onToggleDeleteTask()" class="btn_secondary">Cancel</div>
</div>
<div class="delete_task_overlay" *ngIf="isDeleteTaskVisible" (click)="onToggleDeleteTask()"></div>

<div a-context-popup *ngIf="isMoveTaskMenuVisible"
     [title]="'Move Task'"
     (close)="onSwitchMoveTaskMenu()"
     class="move_task_popup"
>
  <task-action-popup [task]="task"
                     actionName="move"
                     (close)="onSwitchMoveTaskMenu()"
  ></task-action-popup>
</div>
<div a-context-popup *ngIf="isPushTaskMenuVisible"
     [title]="'Push Task'"
     (close)="onSwitchPushTaskMenu()"
     class="move_task_popup"
>
  <task-action-popup [task]="task"
                     actionName="push"
                     (close)="onSwitchPushTaskMenu()"
  ></task-action-popup>
</div>
<div a-context-popup *ngIf="isCopyTaskMenuVisible"
     [title]="'Copy Task'"
     (close)="onSwitchCopyTaskMenu()"
     class="move_task_popup"
>
  <task-action-popup [task]="task"
                     actionName="copy"
                     (close)="onSwitchCopyTaskMenu()"
  ></task-action-popup>
</div>
<a-center-popup
  class="declined"
  *ngIf="isMarkAsDoneErr"
  [title]="'Action Canceled'"
  (close)="onToggleMarkAsDoneErr()"
>
  <p>
    You have no 'Done' columns on this board.
  </p>
</a-center-popup>

<members-popup
  *ngIf="isMembersPopupVisible"
  (addUserToBoard)="onAddUserToBoard($event)"
  (close)="onSwitchMembers()"
  (update) = "onUpdateUsersList($event)"
  [isPublicBoard]="isPublicBoard$ | async"
  [possibleUsers]="possibleUsers$ | async"
  [selectedUsers]="taskUsers$ | async"
  [projectOnlyUsers]="projectOnlyUsers$ | async"
></members-popup>
<sidebar-project-popup
  *ngIf="isProjectsPopupVisible"
  (close)="onSwitchProjects()"
  [boardProjects]="boardProjects$ | async"
  [task]="task"
></sidebar-project-popup>
<sidebar-labels-popup
  *ngIf="isLabelsPopupVisible"
  (close)="onSwitchLabels()"
  [task]="task"
></sidebar-labels-popup>
<sidebar-duedate-popup
  *ngIf="showDueDatePopUp"
  (onClosePopup)="onSwitchDueDate()"
  [task]="task"
></sidebar-duedate-popup>
