import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { RoadMapItem } from '../../../interfaces/roadmap.interface';

@Component({
  selector: 'timesheet-group-header',
  templateUrl: './timesheet-group-header.component.html',
  styleUrls: ['./timesheet-group-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimesheetGroupHeaderComponent {
  @Input() item: RoadMapItem;
  @Input() itemPositions;

  get showTitle() {
    return this.item.durationDays > 2;
  }

  get itemPosition() {
    return this.itemPositions[this.item.id];
  }

  @HostBinding('style.width.px')
  get right() {
    return this.itemPosition.rightPx - this.itemPosition.leftPx;
  }

  @HostBinding('style.left.px')
  get marginLeft() {
    return this.itemPosition.leftPx;
  }
}
