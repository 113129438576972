import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-mini-ts';

@Pipe({
  name: 'dateDiff'
})
export class DateDiffPipe implements PipeTransform {
  transform(from: number, to: number = null): string {

    if (!to) {
      to = moment().unix();
    }

    if (from > to) {
      return "0";
    }

    const duration =  moment.duration(moment(to*1000).diff(moment(from*1000)));

    let result = '';

    if (duration.get('years')) {
      result += duration.get('years') + ' years ';
    }

    if (duration.get('months')) {
      result += duration.get('months') + ' months ';
    }

    if (duration.get('weeks')) {
      result += duration.get('weeks') + ' weeks ';
    }

    if (duration.get('days')) {
      result += duration.get('days') + ' days ';
    }

    if (duration.get('hours')) {
      result += duration.get('hours') + ' hours ';
    }

    if (!result) {
      result += duration.get('minutes') + ' minutes ';
    }

    return result;
  }
}
