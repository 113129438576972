<form-errors></form-errors>
<form [formGroup]="form">
  <div class="popup_form_group">
    <label>Project Name</label>
    <input [formControl]="form.get('name')"
           [focus]="autoFocus"
           (keyup.esc)="onToBack()"
           placeholder='Example "Hygger Project 1"'
           checkTouch
    >
    <help-block class="error" [control]="form.get('name')"
                [messages]="{'maxlength': 'Maximum length of 50 symbols is exceed'}"></help-block>
  </div>
  <div class="popup_form_group">
    <label>Short Name</label>
    <input class="short_input"
           [formControl]="form.get('shortName')"
           (keyup.esc)="onToBack()"
           placeholder="SHORT"
           checkTouch
    >
    <div class="helper"
         [ngClass]="{'helper_error':
         !form.get('shortName').valid && form.get('shortName').dirty}"
    >max 5 symbols
    </div>
    <help-block class="error" [control]="form.get('shortName')"
                [messages]="{'valid': 'Please enter valid short name'}"></help-block>
  </div>
</form>
