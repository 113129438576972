import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noUserRelation'
})
export class NoUserRelationPipe implements PipeTransform {
  transform(value, userId) {
    return !value || !userId
      ? value
      : value.filter(item => item && (!item['usersIds'] || item['usersIds'].indexOf(userId) === -1));
  }
}
