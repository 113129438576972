import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-info',
  templateUrl: './icon-info.component.html',
  //styleUrls: [ './icon-info.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconInfoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
